import {
  GetFleetCompetencesManagementResponseVehiculos
} from '@/services/V1/fleets/getFleetCompetencesManagementOperation/post';

/** 
 * Model fleet-competences-management-modal
 */
class FleetCompetencesManagementModalModel {

  public vehiculos: GetFleetCompetencesManagementResponseVehiculos[] = [];
}

export default FleetCompetencesManagementModalModel;
