<template>
  <div>
    <ea-card shadow="hidden">
      <div slot="cardbody" class="fleet-info-card p-a-16">
        <h2 class="m-b-8">{{ $t(`fleets.fleetsFlow.fleet-vehicle-data.title`) }}</h2>
        <ea-form
          ref="refFleetUnifiedExpirationForm"
          :model="model.formValues"
          :validationOptions="formValidations"
          :validateOnRuleChange="false"
        >
          <ea-row v-if="!readOnlyOperation" extraClass="d-display-flex d-wrap d-align-items-flex-start">
            <ea-col :span="12">
              <ea-form-item
                :label="$t('fleets.fleetsFlow.fleet-vehicle-data.hasUnifiedExpiration')"
                prop="hasUnifiedExpiration"
                :required="!consultFlow"
              >
                <ea-radio-group
                  direction="horizontal"
                  v-model="model.formValues.hasUnifiedExpiration"
                  :disabled="disabledBase7Mode"
                  @change="onHasUnifiedExpiration(model.formValues.hasUnifiedExpiration)"
                >
                  <ea-radio :label="true" :readonly="consultFlow" class="m-l-0">
                    {{ $t('common.label.yes') }}
                  </ea-radio>
                  <ea-radio :label="false" :readonly="consultFlow" class="m-8">
                    {{ $t('common.label.no') }}
                  </ea-radio>
                </ea-radio-group>
              </ea-form-item>
            </ea-col>
            <ea-col :span="6" v-if="model.formValues.hasUnifiedExpiration != null">
              <ea-form-item
                v-if="showUnifiedExpirationDate"
                :label="$t('fleets.fleetsFlow.fleet-vehicle-data.unifiedExpirationDate.label')"
                prop="unifiedExpirationDate"
                :required="!consultFlow"
              >
                <div slot="tooltipIconInfo">
                  <div slot="content">
                    <span>{{ $t('fleets.fleetsFlow.fleet-vehicle-data.unifiedExpirationDate.tooltip') }}</span>
                  </div>
                </div>
                <div>
                  <ea-date-picker
                    v-model="model.formValues.unifiedExpirationDate"
                    :readonly="consultFlow"
                    @change="onChangeExpirationDate()"
                  ></ea-date-picker>
                </div>
              </ea-form-item>

              <ea-form-item
                v-else
                :label="$t('fleets.fleetsFlow.fleet-vehicle-data.notUnifiedExpirationDate.label')"
                prop="notUnifiedExpirationDate"
                :required="!consultFlow"
              >
                <div slot="tooltipIconInfo">
                  <div slot="content">
                    <span>{{ $t('fleets.fleetsFlow.fleet-vehicle-data.notUnifiedExpirationDate.tooltip') }}</span>
                  </div>
                </div>
                <div>
                  <ea-date-picker
                    v-model="model.formValues.notUnifiedExpirationDate"
                    :readonly="consultFlow"
                    @change="onChangeExpirationDate()"
                  ></ea-date-picker>
                </div>
              </ea-form-item>
            </ea-col>
            <ea-col v-if="showUpdateDateButton"
            :span="4" class="m-t-24">
                <ea-button
                class="m-t-2"
                extraClass="centrado"
                type="secondary"
                @click="updateExpirationDate()">{{ $t('common.label.update') }}
              </ea-button>
            </ea-col>
          </ea-row>

          <ea-row v-else extraClass="d-display-flex d-wrap d-align-items-flex-start">
            <ea-col :span="6">
              <ea-form-item
                :label="$t('fleets.fleetsFlow.fleet-vehicle-data.maintenance.initDate.label')"
                prop="unifiedExpirationDate"
              >
                <ea-date-picker
                  v-model="model.formValues.effectiveDate"
                  readonly
                ></ea-date-picker>
              </ea-form-item>
            </ea-col>
            <ea-col :span="6" v-if="model.formValues.expirationDate != null">
              <ea-form-item
                :label="$t('fleets.fleetsFlow.fleet-vehicle-data.maintenance.expirationDate.label')"
                prop="expirationDate"
              >
                <ea-date-picker
                  v-model="model.formValues.expirationDate"
                  readonly
                ></ea-date-picker>
              </ea-form-item>
            </ea-col>
          </ea-row>
        </ea-form>
      </div>
    </ea-card>
  </div>
</template>

<script lang="ts">
import {
  Component, Prop
} from 'vue-property-decorator';

import {
  mixins
} from 'vue-class-component';

import {
  EABusinessComponent, EAMethod
} from '@zurich-es-npm/ea-front-web-core';

import FleetUnifiedExpirationModel from './fleet-unified-expiration-model';
import {
  EAFormValidationOptions,
  eaRequiredValidation,
  Form,
  EAValidation,
  EAValidationTriggers,
  EAValidationTypes,
} from '@zurich-es-npm/ea-front-web-ui';

@Component({
  name: 'fleet-unified-expiration',
})

/**
 * Business Component fleet-unified-expiration
 */
export default class FleetUnifiedExpirationBusiness extends mixins<EABusinessComponent<FleetUnifiedExpirationModel>>(
  EABusinessComponent
) {
  @Prop()
    disabledBase7Mode!: boolean;

  @Prop()
    readOnlyOperation?: boolean;

  @Prop()
    showingInputVehiclesData?: boolean;

  @Prop()
    consultFlow?: boolean;

  showUpdateDateButton: boolean = false;

  /**
   * Getter for validation options
   * @returns { EAFormValidationOptions }
   */
  formValidations: EAFormValidationOptions = {
    rules: {
      hasUnifiedExpiration: [
        eaRequiredValidation(
          'common.label.validation.fieldRequired',
          EAValidationTriggers.BLUR,
          EAValidationTypes.BOOLEAN
        ),
      ],
      unifiedExpirationDate: [],
      notUnifiedExpirationDate: [],
    },
  };

  /**
   * Returns true if unified expiration date should be shown
   * @returns {boolean}
   */
  get showUnifiedExpirationDate() {
    return this.model.formValues.hasUnifiedExpiration === true;
  }

  /**
   * Hook on created.
   * Get validations
   *
   */
  @EAMethod()
  created() {
    if (!this.readOnlyOperation) {
      this.$nextTick(() => {
        if (typeof this.model.formValues.hasUnifiedExpiration === 'boolean') {
          this.onHasUnifiedExpiration(this.model.formValues.hasUnifiedExpiration);
        }
      });
    }
    
  }

  /**
   * Handler for hasUnifiedExpiration radiobutton change
   * @param {boolean} hasUnifiedExpiration
   * @returns {void}
   */
  onHasUnifiedExpiration(hasUnifiedExpiration: boolean | undefined): void {
    this.resetValidationsAndClearWarnings();
    this.showUpdateDateButton = false;
    if (hasUnifiedExpiration !== undefined) {
    // Con vencimiento unificado
      if (hasUnifiedExpiration) {
        this.formValidations.rules.unifiedExpirationDate.push(
          // eslint-disable-next-line max-len
          eaRequiredValidation('common.label.validation.fieldRequired', EAValidationTriggers.BLUR, EAValidationTypes.DATE)
        );

      // Sin vencimiento unificado
      } else {
        this.formValidations.rules.notUnifiedExpirationDate.push(
          // eslint-disable-next-line max-len
          eaRequiredValidation('common.label.validation.fieldRequired', EAValidationTriggers.BLUR, EAValidationTypes.DATE)
        );
      }

      this.$emit('unifedExpirationChange');
    }
  }

  /**
   *
   */
  updateExpirationDate() {
    this.showUpdateDateButton = false;
    this.$emit('updateExpirationDate');
  }

  /**
   *
   */
  resetValidationsAndClearWarnings() {
    this.formValidations.rules.notUnifiedExpirationDate = [];
    this.formValidations.rules.unifiedExpirationDate = [];
    this.model.formValues.unifiedExpirationDate = null;
    this.model.formValues.notUnifiedExpirationDate = null;
    this.update();

    // Limpiar validaciones
    const form = this.$refs.refFleetUnifiedExpirationForm as Form;
    form.clearValidate();
  }

  /**
   * 
   */
  onChangeExpirationDate() {
    this.showUpdateDateButton = this.showingInputVehiclesData as boolean;
    this.update();
  }

  /**
   * @description: method to validate form in parent component
   * @returns {EAValidation}
   */
  validation(): EAValidation {
    const formFleetUnifiedExpiration: Form = this.$refs.refFleetUnifiedExpirationForm as Form;
    return formFleetUnifiedExpiration.validation();
  }
}
</script>
