<template>
  <ea-form
    ref="refShowPolicyholderForm"
    :model="selectedJuridicalPerson"
    class="m-t-8">
    <div
      v-if="!readOnlyOperation"
      class="m-t-16 d-display-flex d-align-items-flex-end new-search-edition-buttons">
            <!-- Botón nueva búsqueda -->
            <ea-tooltip placement="top">
        <ea-button type="text"
          @click="onNewSearch" v-if="!interveningFlow">
          {{ $t('quoteBuyGenericFlow.searchPerson.newSearch.title') }}
        </ea-button>
        <div slot="content">
          {{ $t('quoteBuyGenericFlow.searchPerson.newSearch.tooltip') }}
        </div>
      </ea-tooltip>
      <!-- Botón editar -->
      <ea-button
        type="text"
        @click="onEditPolicyholder">
        {{ $t('common.label.edit') }}
      </ea-button>
    </div>

    <ea-row extraClass="d-display-flex d-wrap d-align-items-flex-end">
      <!-- tipoDocumento -->
      <ea-col :span="6">
        <ea-form-item
          :label="$t('fleets.fleetsFlow.search-person.documentType')"
          prop="tipoDocumento">
          <ea-select
            :placeholder="$t('common.label.select')"
            v-model="selectedJuridicalPerson.tipoDocumento"
            readonly>
            <ea-option
              v-for="documentType in documentTypeList"
              :key="documentType.value"
              :label="documentType.label"
              :value="documentType.value"/>
          </ea-select>
        </ea-form-item>
      </ea-col>
      <!--documentNumber-->
      <ea-col :span="6">
        <ea-form-item
          :label="$t('fleets.fleetsFlow.search-person.documentNumber')"
          prop="codigoIdentificacionFiscal">
          <ea-input-text
            readonly
            v-model="selectedJuridicalPerson.codigoIdentificacionFiscal"/>
        </ea-form-item>
      </ea-col>

      <ea-col :span="6">
        <ea-form-item
          :label="'Razón Social'">
          <ea-input-text
            readonly
            v-model="selectedJuridicalPerson.razonSocialMatriz"/>
        </ea-form-item>
      </ea-col>
    </ea-row>
    <ea-row >
      <ea-col v-if="selectedJuridicalPerson.tipoViaDireccionLarga" :span="6">
        <ea-form-item
          prop="roadType"
          :label="$t('quoteBuyGenericFlow.propertyData.field.roadType')">
          <ea-select
            v-model="selectedJuridicalPerson.tipoViaDireccionLarga"
            :placeholder="$t('common.label.select')"
            readonly
          >
            <ea-option
              v-for="roadType in addressTypeList"
              :key="roadType.value"
              :label="roadType.label"
              :value="roadType.value"
            />
          </ea-select>
        </ea-form-item>
      </ea-col>
      <ea-col v-if="selectedJuridicalPerson.nombreDireccionTomador" :span="6">
        <ea-form-item
          prop="roadName"
          :label="$t('quoteBuyGenericFlow.propertyData.field.roadName')">
          <ea-input-text readonly v-model="selectedJuridicalPerson.nombreDireccionTomador"/>
        </ea-form-item>
      </ea-col>
      <ea-col v-if="selectedJuridicalPerson.numeroDireccionTomador" :span="6">
        <ea-form-item
          prop="roadNumber"
          :label="$t('quoteBuyGenericFlow.propertyData.field.roadNumber')">
          <ea-input-text
            readonly
            v-model="selectedJuridicalPerson.numeroDireccionTomador"/>
        </ea-form-item>
      </ea-col>
      <ea-col v-if="selectedJuridicalPerson.informacionAdicional" :span="6">
            <ea-form-item
            v-if="selectedJuridicalPerson.informacionAdicional"
              prop="extraInfo"
              :label="$t('quoteBuyGenericFlow.propertyData.field.extraInfo')">
              <ea-input-text
              readonly
              v-model="selectedJuridicalPerson.informacionAdicional" />
            </ea-form-item>
          </ea-col>
    </ea-row>
    <postal-code
      id="policyHolder-postal-code"
      ref="postalCodeComp"
      class="w-100"
      :isReadonly="true"
      v-model="postalCodeModel"/>
  </ea-form>
</template>

<script lang="ts">
import PostCodeLocationInputBusiness
  from '@/business-components/post-code-location-input/post-code-location-input-business.vue';
import PostCodeLocationInputModel from '@/business-components/post-code-location-input/post-code-location-input-model';
import {
  SearchPolicyHolderResponseJuridicalPeople as JuridicalPeople
} from '@/services/V1/fleets/searchPolicyHolderOperation/post/api';
import {
  ParsedTableData
} from '@/utils/corporate-tables';
import Vue from 'vue';
import {
  Component, Prop, Watch
} from 'vue-property-decorator';


@Component({
  name: 'show-person-info',
  components: {
    'postal-code': PostCodeLocationInputBusiness
  }
})

/**
 * Show Person Info Component
 */
export default class ShowPolicyHolderInfoComponent extends Vue {

  @Prop({
    required: true
  })
    selectedJuridicalPerson!: JuridicalPeople;

  @Prop()
    documentTypeList?: ParsedTableData[];

  public postalCodeModel = new PostCodeLocationInputModel();

  @Prop()
    addressTypeList?: ParsedTableData[];

  @Prop()
    readOnlyOperation?: boolean;

  @Prop()
    interveningFlow?: boolean;


  /**
   * Updates model
   */
  @Watch('selectedJuridicalPerson.codigoPostal', {
    deep: true,
    immediate: true,
  })
  public selectedJuridicalPersonChanged(): void {
    this.postalCodeModel.zipCode = this.selectedJuridicalPerson.codigoPostal as string;
    this.postalCodeModel.city = this.selectedJuridicalPerson.nombrePoblacionTomador as string;
    this.postalCodeModel.province = this.selectedJuridicalPerson.nombreProvinciaTomador as string;
  }

  /**
   * Handle cancel button and dispatch the event.
   */
  onEnterEditionMode() {
    this.$emit('enterEditionMode');
  }

  /**
   * Emits event 'resetSelection'
   */
  onNewSearch() {
    this.$emit('resetSelection');
  }

  /**
   * Emits event 'editPolicyholder'
   */
  onEditPolicyholder() {
    this.$emit('editPolicyholder');
  }

}
</script>

<style scoped lang="scss">
  .new-search-edition-buttons {
    position: absolute;
    top: 0;
    right: 0;
  }
</style>
