<template>
  <div>
    <ea-card shadow="hidden">
      <div slot="cardbody" class="p-a-16">
        <h3 class="m-b-0">{{ $t('competencesFlow.competencesView.extraDataEmployee.title') }}</h3>
        <p class="m-b-0 t-weight-normal font-size-14">
          {{ $t('competencesFlow.competencesView.extraDataEmployee.subtitle') }}
        </p>
        <ea-row class="m-t-24 p-a-16">
          <ea-form
            ref="form"
            :model="model"
            :validationOptions="validationOptions"
            :validateOnRuleChange="false"
          >
            <ea-row>
              <ea-col :span="6">
                <ea-form-item
                  :label="$t('competencesFlow.competencesView.extraDataEmployee.label.structureCode')"
                  prop="codigoEstructuraCompetencial">
                  <ea-input-text v-model="competencesData.codigoEstructuraCompetencial" readonly />
                </ea-form-item>
              </ea-col>
              <ea-col :span="6">
                <ea-form-item
                  :label="$t('competencesFlow.competencesView.extraDataEmployee.label.structureName')"
                  prop="descripcionEstructura">
                  <ea-input-text v-model="competencesData.descripcionEstructura" readonly />
                </ea-form-item>
              </ea-col>
              <ea-col :span="12">
                <ea-form-item
                  prop="codes"
                  :label="$t('competencesFlow.competencesView.extraDataEmployee.label.workcCenter')"
                  required>
                  <ea-select
                    id="codes"
                    v-model="model.codes"
                    :placeholder="$t('common.label.select')"
                    :readonly="centroTrabajoListaUnElem"
                    @change="onWorkCenterChange">
                    <ea-option
                      v-for="(centrosTrabajo, index) in workList"
                      :key="`${index}-${centrosTrabajo.codigoCentroTrabajo}`"
                      :label="centrosTrabajo.codigoCentroTramitador + ' ' + centrosTrabajo.descripcionCentroTrabajo"
                      :value="centrosTrabajo.codigosCentro"
                    />
                  </ea-select>
                </ea-form-item>
              </ea-col>
            </ea-row>
            <ea-row v-if="model.codes">
              <ea-col :span="6">
                <ea-form-item prop="selectedRol"
                  :label="$t('competencesFlow.competencesView.extraDataEmployee.label.roles')"
                  required>
                  <ea-select
                    id="selectedRol"
                    v-model="model.selectedRol"
                    :placeholder="$t('common.label.select')"
                    @change="updateValue">
                    <ea-option
                      v-for="(rol, index) in rolesList"
                      :key="`${index}-${rol.codigoUnidadRol}`"
                      :label="rol.descripcionUnidadRol"
                      :value="rol.tipoUnidadRol"
                    />
                  </ea-select>
                </ea-form-item>
              </ea-col>
            </ea-row>
          </ea-form>

        </ea-row>
      </div>
    </ea-card>
  </div>
</template>

<script lang="ts">
import {
  Component, Prop
} from 'vue-property-decorator';

import {
  mixins
} from 'vue-class-component';

import {
  EABusinessComponent, EAMethod, ResponseWithErrors, throwIfResponseHasErrors
} from '@zurich-es-npm/ea-front-web-core';

import CompetencesExtraDataEmployeeModel, {
  WorksCenters
} from './competences-extra-data-employee-model';
import {
  GetCompetencesManagementDataResponse
} from '@/services/V1/quoteAndBuy/getCompetencesManagementDataOperation/post';
import {
  EAGetLoadRolesDataApi, GetLoadRolesDataResponseDatos
} from '@/services/V1/notes/getLoadRolesDataOperation/post';
import {
  EAFormValidationOptions, eaRequiredValidation
} from '@zurich-es-npm/ea-front-web-ui';

@Component({
  name: 'competences-extra-data-employee'
})

/**
 * Business Component competences-extra-data-employee
 */
export default class CompetencesExtraDataEmployeeBusiness extends
  mixins<EABusinessComponent<CompetencesExtraDataEmployeeModel>>(EABusinessComponent) {

  centroTrabajoListaUnElem: boolean = false;

  public workList: WorksCenters[] | undefined = [];

  public rolesList: GetLoadRolesDataResponseDatos[] | undefined = [];
  
  @Prop({
    required: true
  })
    competencesData!: GetCompetencesManagementDataResponse;


  /**
   * Hook create
   */
  async created() {
    // Create codigosCentro to keep the both values
    this.workList = this.competencesData.centrosTrabajoLista?.map(center => {
      return {
        ...center,
        codigosCentro: `${center.codigoCentroTrabajo} ${center.codigoCentroTramitador}`
      };
    });
 
    await this.setCenterSelectedState();
  }

  /**
   * @returns {EAFormValidationOptions}
   */
  get validationOptions(): EAFormValidationOptions {
    return {
      rules: {
        codes: [eaRequiredValidation('common.label.validation.fieldRequired')],
        selectedRol: [eaRequiredValidation('common.label.validation.fieldRequired')]
      }
    };
  }

  /**
   * Srts readonly state for select codes when only one element
   */
  async setCenterSelectedState() {
    this.centroTrabajoListaUnElem = false;
    if (this.workList?.length === 1) {
      this.model.codes = this.workList[0].codigosCentro as string;
      this.centroTrabajoListaUnElem = true;
      await this.getLoadRolesBff();
    }
  }

  /**
   * Method to upate work center
   */
  async onWorkCenterChange() {
    this.model.selectedRol = '';
    this.updateValue();
    await this.getLoadRolesBff();
  }

  /**
   * Event to update model
   *
   */
  updateValue(): void {
    this.$nextTick(() => {
      this.update();
    });
  }

  /**
   * Bff for getLoadRolesDataOperation
   */
  @EAMethod({
    loading: true,
  })
  async getLoadRolesBff() {
    const api = new EAGetLoadRolesDataApi();
    const output = await api.getLoadRolesDataOperation({
      getLoadRolesDataRequest: {
        codigoEstructuraCompetencial: this.competencesData.codigoEstructuraCompetencial || ''
      }
    });

    if (output) {
      throwIfResponseHasErrors(output as ResponseWithErrors);
      if (output.datos) {
        this.rolesList = output.datos;
      }
    }
  }
}
</script>
