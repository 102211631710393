/* eslint-disable */

/**
 * zonazurich
 * Portal de Mediación Digital
 *
 * The version of the OpenAPI document: 1
 * Contact: digitalarchitecture@zurich.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import {
  EASDKConfig,
  EAUrlUtils
} from '@zurich-es-npm/ea-shared-utils-js';

import * as globalImportUrl from 'url';
import {
  Configuration
} from './configuration';
import globalAxios, {
  AxiosPromise,
  AxiosInstance,
  AxiosResponse
} from 'axios';

const BASE_PATH = EAUrlUtils.removeLastForwardSlash('http://localhost:3000');

/**
 *
 * @export
 */
export const COLLECTION_FORMATS = {
  csv: ',',
  ssv: ' ',
  tsv: '\t',
  pipes: '|',
};

/**
 *  
 * @export
 * @interface RequestArgs
 */
export interface RequestArgs {
  url: string;
  options: any;
}

/**
 * 
 * @export
 * @class BaseAPI
 */
export class BaseAPI {
  protected configuration: Configuration | undefined;

  /**
   *Creates an instance of BaseAPI.
   * @param {Configuration} [configuration]
   * @param {string} [basePath=BASE_PATH]
   * @param {AxiosInstance} [axios=globalAxios]
   * @memberof BaseAPI
   */
  public constructor(
    configuration?: Configuration,
    protected basePath: string = BASE_PATH,
    protected axios: AxiosInstance = globalAxios) {
    if (configuration) {
      this.configuration = configuration;
      this.basePath = configuration.basePath || this.basePath;
    }
  }
}

/**
 * 
 * @export
 * @class RequiredError
 * @extends {Error}
 */
export class RequiredError extends Error {
  public name: 'RequiredError' = 'RequiredError';

  public field: string;

  /**
   *Creates an instance of RequiredError.
   * @param {string} field
   * @param {string} [msg]
   * @memberof RequiredError
   */
  public constructor(field: string, msg?: string) {
    super(msg);
    this.field=field;
  }
}

/**
 * 
 * @export
 * @class AsyncRequestError
 * @extends {Error}
 */
export class AsyncRequestError extends Error {
  public name: 'AsyncRequestError' = 'AsyncRequestError';

  /**
   *Creates an instance of AsyncRequestError.
   * @param {string} field
   * @param {string} [msg]
   * @memberof AsyncRequestError
   */
  public constructor(msg?: string) {
    super(msg);
  }
}

/**
 * 
 * @export
 * @interface Error
 */
export interface Error {
    /**
     * 
     * @type {string}
     * @memberof Error
     */
    code: string;
    /**
     * 
     * @type {string}
     * @memberof Error
     */
    message: string;
    /**
     * 
     * @type {number}
     * @memberof Error
     */
    severity: number;
    /**
     * 
     * @type {Array<object>}
     * @memberof Error
     */
    childErrors?: Array<object>;
}/**
 * 
 * @export
 * @interface GetVehicleListFleetRequest
 */
export interface GetVehicleListFleetRequest {
    /**
     * 
     * @type {string}
     * @memberof GetVehicleListFleetRequest
     */
    codigoFlota: string;
    /**
     * 
     * @type {number}
     * @memberof GetVehicleListFleetRequest
     */
    versionFlota?: number;
}/**
 * 
 * @export
 * @interface GetVehicleListFleetResponse
 */
export interface GetVehicleListFleetResponse {
    /**
     * 
     * @type {GetVehicleListFleetResponseFlota}
     * @memberof GetVehicleListFleetResponse
     */
    flota?: GetVehicleListFleetResponseFlota;
    /**
     * 
     * @type {Array<GetVehicleListFleetResponseLista>}
     * @memberof GetVehicleListFleetResponse
     */
    lista?: Array<GetVehicleListFleetResponseLista>;
    /**
     * 
     * @type {Array<Error>}
     * @memberof GetVehicleListFleetResponse
     */
    errors?: Array<Error>;
}/**
 * 
 * @export
 * @interface GetVehicleListFleetResponseFlota
 */
export interface GetVehicleListFleetResponseFlota {
    /**
     * 
     * @type {string}
     * @memberof GetVehicleListFleetResponseFlota
     */
    nombreFlota?: string;
    /**
     * 
     * @type {string}
     * @memberof GetVehicleListFleetResponseFlota
     */
    codigoPoliza?: string;
    /**
     * 
     * @type {number}
     * @memberof GetVehicleListFleetResponseFlota
     */
    versionPoliza?: number;
    /**
     * 
     * @type {string}
     * @memberof GetVehicleListFleetResponseFlota
     */
    codigoTecnicoProducto?: string;
    /**
     * 
     * @type {string}
     * @memberof GetVehicleListFleetResponseFlota
     */
    codigoComercialProducto?: string;
    /**
     * 
     * @type {string}
     * @memberof GetVehicleListFleetResponseFlota
     */
    formaPago?: string;
    /**
     * 
     * @type {string}
     * @memberof GetVehicleListFleetResponseFlota
     */
    formaPagoSucesivos?: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetVehicleListFleetResponseFlota
     */
    vencimientoUnificado?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetVehicleListFleetResponseFlota
     */
    fechaEfectoMovimiento?: string;
    /**
     * 
     * @type {string}
     * @memberof GetVehicleListFleetResponseFlota
     */
    fechaInicioPoliza?: string;
    /**
     * 
     * @type {string}
     * @memberof GetVehicleListFleetResponseFlota
     */
    fechaVencimientoFlota?: string;
    /**
     * 
     * @type {string}
     * @memberof GetVehicleListFleetResponseFlota
     */
    tipoCanalCobro1Recibo?: string;
    /**
     * 
     * @type {string}
     * @memberof GetVehicleListFleetResponseFlota
     */
    tipoCanalCobroSucesivos?: string;
    /**
     * 
     * @type {string}
     * @memberof GetVehicleListFleetResponseFlota
     */
    codigoIntermediario?: string;
    /**
     * 
     * @type {string}
     * @memberof GetVehicleListFleetResponseFlota
     */
    nombreIntermediario?: string;
    /**
     * 
     * @type {string}
     * @memberof GetVehicleListFleetResponseFlota
     */
    indicadorComposicionFlota?: string;
    /**
     * 
     * @type {number}
     * @memberof GetVehicleListFleetResponseFlota
     */
    numeroComposicionFlota?: number;
    /**
     * 
     * @type {number}
     * @memberof GetVehicleListFleetResponseFlota
     */
    numeroDeVehiculos?: number;
    /**
     * 
     * @type {boolean}
     * @memberof GetVehicleListFleetResponseFlota
     */
    indicadorCompromisoComercio?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetVehicleListFleetResponseFlota
     */
    indicadorCompromisoPymes?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetVehicleListFleetResponseFlota
     */
    indicadorCompromisoRespCivil?: boolean;
    /**
     * 
     * @type {number}
     * @memberof GetVehicleListFleetResponseFlota
     */
    primaNetaEstimada?: number;
    /**
     * 
     * @type {boolean}
     * @memberof GetVehicleListFleetResponseFlota
     */
    vehiculosRenting?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetVehicleListFleetResponseFlota
     */
    vehiculoSegundaCategoria?: string;
    /**
     * 
     * @type {string}
     * @memberof GetVehicleListFleetResponseFlota
     */
    ambitoTransporteSegundaCategoria?: string;
    /**
     * 
     * @type {number}
     * @memberof GetVehicleListFleetResponseFlota
     */
    numeroPolizasSme?: number;
    /**
     * 
     * @type {boolean}
     * @memberof GetVehicleListFleetResponseFlota
     */
    conductorReeducacion?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetVehicleListFleetResponseFlota
     */
    controlGerenciaRiesgo?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetVehicleListFleetResponseFlota
     */
    sistemaConduccionAvanzado?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetVehicleListFleetResponseFlota
     */
    conductorInexperto?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetVehicleListFleetResponseFlota
     */
    nombreProducto?: string;
    /**
     * 
     * @type {string}
     * @memberof GetVehicleListFleetResponseFlota
     */
    codigoEstructura?: string;
    /**
     * 
     * @type {string}
     * @memberof GetVehicleListFleetResponseFlota
     */
    codigoNegocio?: string;
    /**
     * 
     * @type {string}
     * @memberof GetVehicleListFleetResponseFlota
     */
    nombreNegocio?: string;
}/**
 * 
 * @export
 * @interface GetVehicleListFleetResponseLista
 */
export interface GetVehicleListFleetResponseLista {
    /**
     * 
     * @type {string}
     * @memberof GetVehicleListFleetResponseLista
     */
    codigoFlota?: string;
    /**
     * 
     * @type {number}
     * @memberof GetVehicleListFleetResponseLista
     */
    versionFlota?: number;
    /**
     * 
     * @type {string}
     * @memberof GetVehicleListFleetResponseLista
     */
    ultimaVersionFlota?: string;
    /**
     * 
     * @type {string}
     * @memberof GetVehicleListFleetResponseLista
     */
    codigoPoliza?: string;
    /**
     * 
     * @type {number}
     * @memberof GetVehicleListFleetResponseLista
     */
    versionPoliza?: number;
    /**
     * 
     * @type {string}
     * @memberof GetVehicleListFleetResponseLista
     */
    codigoTecnicoProducto?: string;
    /**
     * 
     * @type {string}
     * @memberof GetVehicleListFleetResponseLista
     */
    codigoComercialProducto?: string;
    /**
     * 
     * @type {string}
     * @memberof GetVehicleListFleetResponseLista
     */
    codigoEstadoPoliza?: string;
    /**
     * 
     * @type {string}
     * @memberof GetVehicleListFleetResponseLista
     */
    estadoPolizaBatch?: string;
    /**
     * 
     * @type {string}
     * @memberof GetVehicleListFleetResponseLista
     */
    numeroMatriculaSinValidaciones?: string;
    /**
     * 
     * @type {string}
     * @memberof GetVehicleListFleetResponseLista
     */
    codigoAgrupacionFlota?: string;
    /**
     * 
     * @type {number}
     * @memberof GetVehicleListFleetResponseLista
     */
    valorVehiculo?: number;
    /**
     * 
     * @type {number}
     * @memberof GetVehicleListFleetResponseLista
     */
    valorPesoMaximo?: number;
    /**
     * 
     * @type {string}
     * @memberof GetVehicleListFleetResponseLista
     */
    codigoPaquete?: string;
    /**
     * 
     * @type {string}
     * @memberof GetVehicleListFleetResponseLista
     */
    indicadorGarantiasAdicionales?: string;
    /**
     * 
     * @type {number}
     * @memberof GetVehicleListFleetResponseLista
     */
    primaNeta?: number;
    /**
     * 
     * @type {number}
     * @memberof GetVehicleListFleetResponseLista
     */
    importeTotal?: number;
    /**
     * 
     * @type {string}
     * @memberof GetVehicleListFleetResponseLista
     */
    codigoVehiculo?: string;
    /**
     * 
     * @type {string}
     * @memberof GetVehicleListFleetResponseLista
     */
    codigoError?: string;
    /**
     * 
     * @type {string}
     * @memberof GetVehicleListFleetResponseLista
     */
    fechaEfectoMovimiento?: string;
    /**
     * 
     * @type {string}
     * @memberof GetVehicleListFleetResponseLista
     */
    descripcionErrorTarificacion?: string;
    /**
     * 
     * @type {string}
     * @memberof GetVehicleListFleetResponseLista
     */
    fechaVencimiento?: string;
    /**
     * 
     * @type {string}
     * @memberof GetVehicleListFleetResponseLista
     */
    tipoMatricula?: GetVehicleListFleetResponseListaTipoMatriculaEnum;
    /**
     * 
     * @type {string}
     * @memberof GetVehicleListFleetResponseLista
     */
    descripcionMarcaVehiculo?: string;
    /**
     * 
     * @type {string}
     * @memberof GetVehicleListFleetResponseLista
     */
    descripcionModeloVehiculo?: string;
    /**
     * 
     * @type {string}
     * @memberof GetVehicleListFleetResponseLista
     */
    descripcionVersionVehiculo?: string;
    /**
     * 
     * @type {number}
     * @memberof GetVehicleListFleetResponseLista
     */
    valorAccesoriosTotal?: number;
    /**
     * 
     * @type {string}
     * @memberof GetVehicleListFleetResponseLista
     */
    codigoUsoPorMatricula?: string;
    /**
     * 
     * @type {string}
     * @memberof GetVehicleListFleetResponseLista
     */
    codigoNumeroPoliza?: string;
    /**
     * 
     * @type {string}
     * @memberof GetVehicleListFleetResponseLista
     */
    codigoIdentificacionConductor?: string;
    /**
     * 
     * @type {string}
     * @memberof GetVehicleListFleetResponseLista
     */
    nombreConductor?: string;
    /**
     * 
     * @type {string}
     * @memberof GetVehicleListFleetResponseLista
     */
    tipoDocumentoConductor?: string;
    /**
     * 
     * @type {string}
     * @memberof GetVehicleListFleetResponseLista
     */
    primerApellidoConductor?: string;
    /**
     * 
     * @type {string}
     * @memberof GetVehicleListFleetResponseLista
     */
    segundoApellidoConductor?: string;
    /**
     * 
     * @type {string}
     * @memberof GetVehicleListFleetResponseLista
     */
    codigoFiliacionConductor?: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetVehicleListFleetResponseLista
     */
    indicadorConductorNominado?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetVehicleListFleetResponseLista
     */
    tipoDocumentoAseguradoBeneficiario?: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetVehicleListFleetResponseLista
     */
    indicadorAsegurado?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetVehicleListFleetResponseLista
     */
    codigoIdentificacionAsegurado?: string;
    /**
     * 
     * @type {string}
     * @memberof GetVehicleListFleetResponseLista
     */
    nombreAsegurado?: string;
    /**
     * 
     * @type {string}
     * @memberof GetVehicleListFleetResponseLista
     */
    tipoPersonaAsegurada?: string;
    /**
     * 
     * @type {string}
     * @memberof GetVehicleListFleetResponseLista
     */
    primerApellidoAsegurado?: string;
    /**
     * 
     * @type {string}
     * @memberof GetVehicleListFleetResponseLista
     */
    segundoApellidoAsegurado?: string;
    /**
     * 
     * @type {string}
     * @memberof GetVehicleListFleetResponseLista
     */
    codigoFiliacionAsegurado?: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetVehicleListFleetResponseLista
     */
    indicadorPropietario?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetVehicleListFleetResponseLista
     */
    tipoDocumentoPropietario?: string;
    /**
     * 
     * @type {string}
     * @memberof GetVehicleListFleetResponseLista
     */
    tipoPersonaPropietario?: string;
    /**
     * 
     * @type {string}
     * @memberof GetVehicleListFleetResponseLista
     */
    codigoIdentificacionPropietario?: string;
    /**
     * 
     * @type {string}
     * @memberof GetVehicleListFleetResponseLista
     */
    nombrePropietario?: string;
    /**
     * 
     * @type {string}
     * @memberof GetVehicleListFleetResponseLista
     */
    primerApellidoPropietario?: string;
    /**
     * 
     * @type {string}
     * @memberof GetVehicleListFleetResponseLista
     */
    segundoApellidoPropietario?: string;
    /**
     * 
     * @type {string}
     * @memberof GetVehicleListFleetResponseLista
     */
    codigoFiliacionPropietario?: string;
    /**
     * 
     * @type {string}
     * @memberof GetVehicleListFleetResponseLista
     */
    tipoVehiculo?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum GetVehicleListFleetResponseListaTipoMatriculaEnum {
    M1 = 'M1',
    E1 = 'E1',
    TE = 'TE',
    I = 'I',
    C = 'C',
    _1 = '1',
    P = 'P',
    R = 'R',
    D = 'D',
    K = 'K',
    T1 = 'T1',
    T = 'T',
    E = 'E',
    TT = 'TT',
    R1 = 'R1',
    H = 'H',
    M = 'M',
    NOMATRICULA = 'NO-MATRICULA'
}


type getVehicleListFleetOperationParams = {
  getVehicleListFleetRequest: GetVehicleListFleetRequest,
};

/**
 * GetVehicleListFleetApi - axios parameter creator
 * @param {Configuration} [configuration]
 * @returns {any}
 */
const GetVehicleListFleetApiAxiosParamCreator = function(configuration?: Configuration): any {
  return {
    
    /**
     * Devuelve la lista de los vehículos de una flota
     * @param {GetVehicleListFleetRequest} getVehicleListFleetRequest 
     * @param {string} [acceptLanguage] El Accept-Language request HTTP header indica en que lenguaje debe responder el backend.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @returns {RequestArgs}
     */
    getVehicleListFleetOperation(params: getVehicleListFleetOperationParams, options: any = {}): RequestArgs {
      // Verify required parameter 'getVehicleListFleetRequest' is not null or undefined
      if (params.getVehicleListFleetRequest === null || params.getVehicleListFleetRequest === undefined) {
        throw new RequiredError('getVehicleListFleetRequest', 'Required parameter getVehicleListFleetRequest was null or undefined when calling getVehicleListFleetOperation.');
      }
      const localVarPath = `/v1/fleets/getVehicleListFleetOperation`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true) as Partial<globalImportUrl.UrlWithParsedQuery>;
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;


  
      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
      // Fix override query string Detail: https://stackoverflow.com/a/7517673/1077943

      delete localVarUrlObj.search;
      localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
      const needsSerialization = ('GetVehicleListFleetRequest' as any !== 'string') ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization ? JSON.stringify(params.getVehicleListFleetRequest || {}) : params.getVehicleListFleetRequest || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * GetVehicleListFleetApi - functional programming interface
 * @param {Configuration} [configuration]
 * @returns {any}
 */
const GetVehicleListFleetApiFp = function(configuration?: Configuration): any {
  return {
    
    /**
     * Devuelve la lista de los vehículos de una flota
     * @param {GetVehicleListFleetRequest} getVehicleListFleetRequest 
     * @param {string} [acceptLanguage] El Accept-Language request HTTP header indica en que lenguaje debe responder el backend.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @returns {any}
     */
    getVehicleListFleetOperation(params: getVehicleListFleetOperationParams, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetVehicleListFleetResponse> {
      const localVarAxiosArgs = GetVehicleListFleetApiAxiosParamCreator(configuration).getVehicleListFleetOperation(params, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH): any => {
        const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * GetVehicleListFleetApi - factory interface
 * @param {Configuration} configuration
 * @param {string} basePath
 * @param {AxiosInstance} axios
 * @export
 * @returns {any}
 */
const GetVehicleListFleetApiFactory = function(configuration?: Configuration, basePath?: string, axios?: AxiosInstance): any {
  return {
    
    /**
     * Devuelve la lista de los vehículos de una flota
     * @param {GetVehicleListFleetRequest} getVehicleListFleetRequest 
     * @param {string} [acceptLanguage] El Accept-Language request HTTP header indica en que lenguaje debe responder el backend.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @returns {any}
     */
    getVehicleListFleetOperation(params: getVehicleListFleetOperationParams, options?: any): any {
      return GetVehicleListFleetApiFp(configuration).getVehicleListFleetOperation(params, options)(axios, basePath);
    },
  };
};

/** 
 * GetVehicleListFleetApi - object-oriented interface
 * @export
 * @class GetVehicleListFleetApi
 * @extends {BaseAPI}
 */
export class GetVehicleListFleetApi extends BaseAPI {
  
  /**
   * Devuelve la lista de los vehículos de una flota
   * @param {GetVehicleListFleetRequest} getVehicleListFleetRequest 
   * @param {string} [acceptLanguage] El Accept-Language request HTTP header indica en que lenguaje debe responder el backend.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @returns {any}
   * @memberof GetVehicleListFleetApi
   */
  public getVehicleListFleetOperation(params: getVehicleListFleetOperationParams, options?: any): any {
    return GetVehicleListFleetApiFp(this.configuration).getVehicleListFleetOperation(params, options)(this.axios, this.basePath);
  }

}

/**
 * 
 * @export
 * @class EAgetVehicleListFleetOperationInputMessage
 */
export class EAgetVehicleListFleetOperationInputMessage {
  
  /**
   * 
   * @type {GetVehicleListFleetRequest}
   * @memberof EAgetVehicleListFleetOperationInputMessage
   */
  public getVehicleListFleetRequest: GetVehicleListFleetRequest;

  public constructor(getVehicleListFleetRequest: GetVehicleListFleetRequest, ){
  
  this.getVehicleListFleetRequest=getVehicleListFleetRequest;
  } 
}



/** 
 * EAGetVehicleListFleetApi - Architecture client for getVehicleListFleetOperation
 * @export
 * @class EAGetVehicleListFleetApi
 */
export class EAGetVehicleListFleetApi {

  /**
   * 
   * @param {GetVehicleListFleetRequest} getVehicleListFleetRequest
   
   * @param {Configuration} configuration
   * @throws {AsyncRequestError | RequiredError}
   * @returns {Promise<GetVehicleListFleetResponse | null>}
  */ 
  public async getVehicleListFleetOperation(params: getVehicleListFleetOperationParams, configuration: Configuration = {}): Promise<GetVehicleListFleetResponse | null> {
    return this.getVehicleListFleetOperationPromise(params, configuration);
  }

  /**
   * 
   * @param {GetVehicleListFleetRequest} getVehicleListFleetRequest
   
   * @param {Configuration} configuration
   * @param {Function} callback
   * @throws {AsyncRequestError | RequiredError}
   */
  public async getVehicleListFleetOperationSteps(params: getVehicleListFleetOperationParams, configuration: Configuration, callback: (res: AxiosResponse<GetVehicleListFleetResponse | null>) => void) {
    let done: boolean = false;
    const httpClient: AxiosInstance = EASDKConfig.getHttpClient('/v1/fleets/getVehicleListFleetOperation', 'getVehicleListFleet', 'getVehicleListFleetOperation');
    const api = new GetVehicleListFleetApi(configuration, '', httpClient);
    const sleep = async(ms: number) => {
      return new Promise(resolve => setTimeout(resolve, ms));
    };
    const headers: { ['Async-Id']?: string } = {};
    while (!done) {
      const output = await api.getVehicleListFleetOperation(params, { headers }) as AxiosResponse<GetVehicleListFleetResponse | null>;
      callback(output);
      if (output.status === 202) {
        try {
          const retry = parseInt(output.headers['retry']);
          headers['Async-Id'] = output.headers['request-id'];
          if (isNaN(retry) || retry <= 0 || retry > 1000 * 3600) {
            throw new Error(`Retry time not acceptable (in milliseconds): ${retry}`);
          }
          await sleep(retry);
        } catch (err) {
          throw new Error(`Error in Async Request ${err}`);
        }
      } else {
        done = true;
      }
    }
  }

  /**
   * 
   * @param {GetVehicleListFleetRequest} getVehicleListFleetRequest
   
   * @param {Configuration} configuration
   * @throws {AsyncRequestError | RequiredError}
   * @returns {Promise<GetVehicleListFleetResponse | null>}
   */
  public async getVehicleListFleetOperationPromise(params: getVehicleListFleetOperationParams, configuration: Configuration): Promise<GetVehicleListFleetResponse | null> {
    return new Promise((resolve, reject) => {
      this.getVehicleListFleetOperationSteps(params, configuration, (response: AxiosResponse<GetVehicleListFleetResponse | null>) => {
        if (response.status !== 202) {
          resolve(response.data);
        }
      })
      .catch((err) => {
        reject(err);
      });
    });
  } 
}


