/* eslint-disable */

/**
 * zonazurich
 * Portal de Mediación Digital
 *
 * The version of the OpenAPI document: 1
 * Contact: digitalarchitecture@zurich.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import {
  EASDKConfig,
  EAUrlUtils
} from '@zurich-es-npm/ea-shared-utils-js';

import * as globalImportUrl from 'url';
import {
  Configuration
} from './configuration';
import globalAxios, {
  AxiosPromise,
  AxiosInstance,
  AxiosResponse
} from 'axios';

const BASE_PATH = EAUrlUtils.removeLastForwardSlash('http://localhost:3000');

/**
 *
 * @export
 */
export const COLLECTION_FORMATS = {
  csv: ',',
  ssv: ' ',
  tsv: '\t',
  pipes: '|',
};

/**
 *  
 * @export
 * @interface RequestArgs
 */
export interface RequestArgs {
  url: string;
  options: any;
}

/**
 * 
 * @export
 * @class BaseAPI
 */
export class BaseAPI {
  protected configuration: Configuration | undefined;

  /**
   *Creates an instance of BaseAPI.
   * @param {Configuration} [configuration]
   * @param {string} [basePath=BASE_PATH]
   * @param {AxiosInstance} [axios=globalAxios]
   * @memberof BaseAPI
   */
  public constructor(
    configuration?: Configuration,
    protected basePath: string = BASE_PATH,
    protected axios: AxiosInstance = globalAxios) {
    if (configuration) {
      this.configuration = configuration;
      this.basePath = configuration.basePath || this.basePath;
    }
  }
}

/**
 * 
 * @export
 * @class RequiredError
 * @extends {Error}
 */
export class RequiredError extends Error {
  public name: 'RequiredError' = 'RequiredError';

  public field: string;

  /**
   *Creates an instance of RequiredError.
   * @param {string} field
   * @param {string} [msg]
   * @memberof RequiredError
   */
  public constructor(field: string, msg?: string) {
    super(msg);
    this.field=field;
  }
}

/**
 * 
 * @export
 * @class AsyncRequestError
 * @extends {Error}
 */
export class AsyncRequestError extends Error {
  public name: 'AsyncRequestError' = 'AsyncRequestError';

  /**
   *Creates an instance of AsyncRequestError.
   * @param {string} field
   * @param {string} [msg]
   * @memberof AsyncRequestError
   */
  public constructor(msg?: string) {
    super(msg);
  }
}

/**
 * 
 * @export
 * @interface Error
 */
export interface Error {
    /**
     * 
     * @type {string}
     * @memberof Error
     */
    code: string;
    /**
     * 
     * @type {string}
     * @memberof Error
     */
    message: string;
    /**
     * 
     * @type {number}
     * @memberof Error
     */
    severity: number;
    /**
     * 
     * @type {Array<object>}
     * @memberof Error
     */
    childErrors?: Array<object>;
}/**
 * 
 * @export
 * @interface GetVehicleVersionsRequest
 */
export interface GetVehicleVersionsRequest {
    /**
     * 
     * @type {string}
     * @memberof GetVehicleVersionsRequest
     */
    codigoMarcaVehiculo: string;
    /**
     * 
     * @type {string}
     * @memberof GetVehicleVersionsRequest
     */
    codigoModeloVehiculo: string;
}/**
 * 
 * @export
 * @interface GetVehicleVersionsResponse
 */
export interface GetVehicleVersionsResponse {
    /**
     * 
     * @type {Array<GetVehicleVersionsResponseData>}
     * @memberof GetVehicleVersionsResponse
     */
    data?: Array<GetVehicleVersionsResponseData>;
    /**
     * 
     * @type {Array<Error>}
     * @memberof GetVehicleVersionsResponse
     */
    errors?: Array<Error>;
}/**
 * 
 * @export
 * @interface GetVehicleVersionsResponseData
 */
export interface GetVehicleVersionsResponseData {
    /**
     * 
     * @type {string}
     * @memberof GetVehicleVersionsResponseData
     */
    codigoVehiculo?: string;
    /**
     * 
     * @type {string}
     * @memberof GetVehicleVersionsResponseData
     */
    descripcionMarcaVehiculo?: string;
    /**
     * 
     * @type {string}
     * @memberof GetVehicleVersionsResponseData
     */
    descripcionModeloVehiculo?: string;
    /**
     * 
     * @type {string}
     * @memberof GetVehicleVersionsResponseData
     */
    descripcionVersionVehiculo?: string;
    /**
     * 
     * @type {string}
     * @memberof GetVehicleVersionsResponseData
     */
    tipoVehiculo?: string;
    /**
     * 
     * @type {number}
     * @memberof GetVehicleVersionsResponseData
     */
    valorVehiculo?: number;
    /**
     * 
     * @type {number}
     * @memberof GetVehicleVersionsResponseData
     */
    valorPesoMaximo?: number;
}

type getVehicleVersionsOperationParams = {
  getVehicleVersionsRequest: GetVehicleVersionsRequest,
};

/**
 * GetVehicleVersionsApi - axios parameter creator
 * @param {Configuration} [configuration]
 * @returns {any}
 */
const GetVehicleVersionsApiAxiosParamCreator = function(configuration?: Configuration): any {
  return {
    
    /**
     * Devuelve la lista de versiones de vehiculo disponibles en un modelo
     * @param {GetVehicleVersionsRequest} getVehicleVersionsRequest 
     * @param {string} [acceptLanguage] El Accept-Language request HTTP header indica en que lenguaje debe responder el backend.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @returns {RequestArgs}
     */
    getVehicleVersionsOperation(params: getVehicleVersionsOperationParams, options: any = {}): RequestArgs {
      // Verify required parameter 'getVehicleVersionsRequest' is not null or undefined
      if (params.getVehicleVersionsRequest === null || params.getVehicleVersionsRequest === undefined) {
        throw new RequiredError('getVehicleVersionsRequest', 'Required parameter getVehicleVersionsRequest was null or undefined when calling getVehicleVersionsOperation.');
      }
      const localVarPath = `/v1/fleets/getVehicleVersionsOperation`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true) as Partial<globalImportUrl.UrlWithParsedQuery>;
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;


  
      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
      // Fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
      const needsSerialization = ('GetVehicleVersionsRequest' as any !== 'string') ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization ? JSON.stringify(params.getVehicleVersionsRequest || {}) : params.getVehicleVersionsRequest || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * GetVehicleVersionsApi - functional programming interface
 * @param {Configuration} [configuration]
 * @returns {any}
 */
const GetVehicleVersionsApiFp = function(configuration?: Configuration): any {
  return {
    
    /**
     * Devuelve la lista de versiones de vehiculo disponibles en un modelo
     * @param {GetVehicleVersionsRequest} getVehicleVersionsRequest 
     * @param {string} [acceptLanguage] El Accept-Language request HTTP header indica en que lenguaje debe responder el backend.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @returns {any}
     */
    getVehicleVersionsOperation(params: getVehicleVersionsOperationParams, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetVehicleVersionsResponse> {
      const localVarAxiosArgs = GetVehicleVersionsApiAxiosParamCreator(configuration).getVehicleVersionsOperation(params, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH): any => {
        const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * GetVehicleVersionsApi - factory interface
 * @param {Configuration} configuration
 * @param {string} basePath
 * @param {AxiosInstance} axios
 * @export
 * @returns {any}
 */
const GetVehicleVersionsApiFactory = function(configuration?: Configuration, basePath?: string, axios?: AxiosInstance): any {
  return {
    
    /**
     * Devuelve la lista de versiones de vehiculo disponibles en un modelo
     * @param {GetVehicleVersionsRequest} getVehicleVersionsRequest 
     * @param {string} [acceptLanguage] El Accept-Language request HTTP header indica en que lenguaje debe responder el backend.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @returns {any}
     */
    getVehicleVersionsOperation(params: getVehicleVersionsOperationParams, options?: any): any {
      return GetVehicleVersionsApiFp(configuration).getVehicleVersionsOperation(params, options)(axios, basePath);
    },
  };
};

/** 
 * GetVehicleVersionsApi - object-oriented interface
 * @export
 * @class GetVehicleVersionsApi
 * @extends {BaseAPI}
 */
export class GetVehicleVersionsApi extends BaseAPI {
  
  /**
   * Devuelve la lista de versiones de vehiculo disponibles en un modelo
   * @param {GetVehicleVersionsRequest} getVehicleVersionsRequest 
   * @param {string} [acceptLanguage] El Accept-Language request HTTP header indica en que lenguaje debe responder el backend.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @returns {any}
   * @memberof GetVehicleVersionsApi
   */
  public getVehicleVersionsOperation(params: getVehicleVersionsOperationParams, options?: any): any {
    return GetVehicleVersionsApiFp(this.configuration).getVehicleVersionsOperation(params, options)(this.axios, this.basePath);
  }

}

/**
 * 
 * @export
 * @class EAgetVehicleVersionsOperationInputMessage
 */
export class EAgetVehicleVersionsOperationInputMessage {
  
  /**
   * 
   * @type {GetVehicleVersionsRequest}
   * @memberof EAgetVehicleVersionsOperationInputMessage
   */
  public getVehicleVersionsRequest: GetVehicleVersionsRequest;

  public constructor(getVehicleVersionsRequest: GetVehicleVersionsRequest, ){
  
  this.getVehicleVersionsRequest=getVehicleVersionsRequest;
  } 
}



/** 
 * EAGetVehicleVersionsApi - Architecture client for getVehicleVersionsOperation
 * @export
 * @class EAGetVehicleVersionsApi
 */
export class EAGetVehicleVersionsApi {

  /**
   * 
   * @param {GetVehicleVersionsRequest} getVehicleVersionsRequest
   
   * @throws {AsyncRequestError | RequiredError}
   * @returns {Promise<GetVehicleVersionsResponse | null>}
  */ 
  public async getVehicleVersionsOperation(params: getVehicleVersionsOperationParams): Promise<GetVehicleVersionsResponse | null> {
    return this.getVehicleVersionsOperationPromise(params);
  }

  /**
   * 
   * @param {GetVehicleVersionsRequest} getVehicleVersionsRequest
   
   * @param {Function} callback
   * @throws {AsyncRequestError | RequiredError}
   */
  public async getVehicleVersionsOperationSteps(params: getVehicleVersionsOperationParams, callback: (res: AxiosResponse<GetVehicleVersionsResponse | null>) => void) {
    let done: boolean = false;
    const httpClient: AxiosInstance = EASDKConfig.getHttpClient('/v1/fleets/getVehicleVersionsOperation', 'getVehicleVersions', 'getVehicleVersionsOperation');
    const config: Configuration = {};
    const api = new GetVehicleVersionsApi(config, '', httpClient);
    const sleep = async(ms: number) => {
      return new Promise(resolve => setTimeout(resolve, ms));
    };
    const headers: { ['Async-Id']?: string } = {};
    while (!done) {
      const output = await api.getVehicleVersionsOperation(params, { headers }) as AxiosResponse<GetVehicleVersionsResponse | null>;
      callback(output);
      if (output.status === 202) {
        try {
          const retry = parseInt(output.headers['retry']);
          headers['Async-Id'] = output.headers['request-id'];
          if (isNaN(retry) || retry <= 0 || retry > 1000 * 3600) {
            throw new Error(`Retry time not acceptable (in milliseconds): ${retry}`);
          }
          await sleep(retry);
        } catch (err) {
          throw new Error(`Error in Async Request ${err}`);
        }
      } else {
        done = true;
      }
    }
  }

  /**
   * 
   * @param {GetVehicleVersionsRequest} getVehicleVersionsRequest
   
   * @throws {AsyncRequestError | RequiredError}
   * @returns {Promise<GetVehicleVersionsResponse | null>}
   */
  public async getVehicleVersionsOperationPromise(params: getVehicleVersionsOperationParams): Promise<GetVehicleVersionsResponse | null> {
    return new Promise((resolve, reject) => {
      this.getVehicleVersionsOperationSteps(params, (response: AxiosResponse<GetVehicleVersionsResponse | null>) => {
        if (response.status !== 202) {
          resolve(response.data);
        }
      })
      .catch((err) => {
        reject(err);
      });
    });
  } 
}


