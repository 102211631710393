<!-- eslint-disable max-lines -->
<template>
  <div>
    <ea-card
      shadow="hidden"
    >
      <div slot="cardbody" class="policyholder-card p-a-16">
        <h2>{{ $t(`common.roles.tomador`) }}</h2>
        <ea-alert
          v-if="isAlertVisible"
          type="warning"
          :closable="false"
          :title= "riskAppetiteMessage"
          class="m-b-16"
        />
        <ea-form
          v-show="!showSearchResult && !readOnlyOperation"
          ref="refSearchPolicyHolderForm"
          :model="formValues"
          :validationOptions="formValidationOptions"
          :validateOnRuleChange="false">
          <div>
            <ea-row extraClass="d-display-flex d-wrap d-align-items-flex-end">
              <ea-col :span="6">
                <ea-form-item
                  :label="$t('fleets.fleetsFlow.search-person.documentType')"
                  prop="documentType"
                  required>
                  <ea-select
                    :placeholder="$t('common.label.select')"
                    v-model="formValues.documentType"
                    @change="onDocumentTypeChange">
                    <ea-option
                      v-for="item in documentTypeList"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"/>
                  </ea-select>
                </ea-form-item>
              </ea-col>
              <ea-col :span="6">
                <ea-form-item
                  :label="$t('fleets.fleetsFlow.search-person.documentNumber')"
                  prop="documentNumber"
                  required>
                  <div slot="tooltipIconInfo">
                    <div slot="content">
                      {{ $t('fleets.fleetsFlow.generalDataView.searchPolicyholderTooltip') }}
                    </div>
                  </div>
                  <ea-input-text
                    v-model="formValues.documentNumber"
                    @change = "onDocumentNumberChange"/>
                </ea-form-item>
              </ea-col>
              <ea-col :span="6">
                <ea-button
                  type="secondary"
                  class="m-b-32"
                  @click="searchPerson()">
                  {{ $t('common.label.search') }}
                </ea-button>
              </ea-col>
            </ea-row>
          </div>
        </ea-form>
        <!-- SHOW POLICY HOLDER INFO -->
        <show-policyholder-info
          v-if="readOnlyOperation || showSearchResult"
          id="show-policyholder-info"
          :addressTypeList="addressTypeList"
          :selectedJuridicalPerson="model.selectedJuridicalPerson"
          :documentTypeList="documentTypeList"
          :readOnlyOperation="readOnlyOperation"
          :interveningFlow="interveningFlow"
          @resetSelection="onResetSelection"
          @editPolicyholder="editPolicyholder"
          />
        <ea-row v-if="model.indicadorCompromisoContrato">
          <ea-col>
            <fleet-commit-contract
              v-model="model.fleetCommitContract"
              id="fleet-commit-contract"
              ref="refCommitContractBC"
              :elementList="commitContractPolicyTypes"
              :readOnlyOperation="readOnlyOperation"
              @selectedCommitContractValuesChange="onSelectedCommitContractValuesChange">
            </fleet-commit-contract>
          </ea-col>
        </ea-row>
      </div>
    </ea-card>
    <search-person-results
      :resultsTableVisible="isResultsTableVisible"
      :personList="searchPersonResultList"
      @closeResultsDialog="closeResultsDialog"
      @selectPersonAndClose="selectPersonAndClose"
    />
    
    
    <ea-dialog
      width="75%"
      v-if="editingPolicyholder"
      :visible="editingPolicyholder"
      :closeOnClickModal="false"
      @close="editingPolicyholder = false"
      :closeOnPressEscape="!forceEdit"
      :showClose="!forceEdit"
      :title="$t('fleets.fleetsFlow.edit-policyholder.editPolicyholder')">
      <ea-row>
        <ea-col
          v-if="forceEdit">
          <ea-alert
            type="warning"
            :title="$t('fleets.fleetsFlow.edit-policyholder.forceEditMessageTitle')"
            :description="$t('fleets.fleetsFlow.edit-policyholder.forceEditMessage')"
            class="m-b-16"/>
        </ea-col>
        <ea-col>
          <edit-policyholder
            id="edit-policyholder"
            :selectedJuridicalPerson="model.selectedJuridicalPerson"
            :documentTypeList="documentTypeList"
            :addressTypeList="addressTypeList"
            :forceEdit="forceEdit"
            @cancel="editingPolicyholder = false"
            @save="onSavePolicyholder"
          >
          </edit-policyholder>
        </ea-col>
      </ea-row>
      <span slot="footer"></span>
    </ea-dialog>
  </div>
</template>

<script lang="ts">
/* eslint-disable max-lines */
import {
  Component, Prop, Watch
} from 'vue-property-decorator';
import {
  mixins
} from 'vue-class-component';
import {
  EABusinessComponent, EAErrorManager, EAMethod, ResponseWithErrors, throwIfResponseHasErrors,
} from '@zurich-es-npm/ea-front-web-core';
import {
  EAFormValidationOptions, eaRequiredValidation, EAValidation, Form
} from '@zurich-es-npm/ea-front-web-ui';
import SearchPersonUtils from '@/business-components/qb-search-person/utils/qb-search-person-utils';
import {
  EASearchPolicyHolderApi, SearchPolicyHolderResponse, SearchPolicyHolderResponseJuridicalPeople
} from '@/services/V1/fleets/searchPolicyHolderOperation/post/api';
import FleetSearchPolicyHolderModel, {
  ErrorSearchPolicyHolder
} from './fleet-search-policyholder-model';
import PostCodeLocationInputBusiness from '../post-code-location-input/post-code-location-input-business.vue';
import ShowPolicyHolderInfoComponent from './components/show-policyholder-info-component.vue';
import FleetCommitContractBusiness from '../fleet-commit-contract/fleet-commit-contract-business.vue';
import {
  LabelValueType
} from '@/flows/fleets/model/label-value-type-model';
import {
  NotificationsTypeEnum, NotificationsUtils, ZZNotification,
} from '@/utils/notifications/notifications-utils';
import SearchPersonResultsTableComponent from '../qb-search-person/components/search-person-results-table.vue';
import {
  GetPersonsResponseListaPersonas
} from '@/services/V1/quoteAndBuy/getPersonsOperation/post';
import EditPolicyHolderComponent from './components/edit-policyholder-component.vue';
import {
  ParsedTableData
} from '@/utils/corporate-tables';

import {
  EAUpdatePolicyHolderApi, UpdatePolicyHolderRequest
} from '@/services/V1/fleets/updatePolicyHolderOperation/post/api';

import {
  isValidCif,
} from '@/utils/document-validations';
import PermissionUtils from '@/utils/permissions-utils';
import {
  GetDefaultUserValuesResponseCodigoTipoUsuarioEnum as UserTypeEnum
} from '@/services/V1/home/getDefaultUserValuesOperation/post';


@Component({
  name: 'fleet-search-person',
  components: {
    'fleet-commit-contract': FleetCommitContractBusiness,
    'postal-code': PostCodeLocationInputBusiness,
    'show-policyholder-info': ShowPolicyHolderInfoComponent,
    'edit-policyholder': EditPolicyHolderComponent,
    'search-person-results': SearchPersonResultsTableComponent
  },
})

/**
 * Business Component qb-search-person
 */
export default class FleetSearchPolicyHolderBusiness extends mixins<EABusinessComponent<FleetSearchPolicyHolderModel>>(
  EABusinessComponent
) {
  @Prop({
    required: true
  })
    intermediaryCode!: string;

  @Prop({
    required: true
  })
    commitContractPolicyTypes!: LabelValueType[];
  
  @Prop({
    required: true
  })
    documentTypeList!: LabelValueType[];
  
  @Prop({
    required: true
  })
    intermediaryName!: string;

  @Prop()
    addressTypeList?: ParsedTableData[];

  @Prop()
    readOnlyOperation?: boolean;

  @Prop()
    interveningFlow?: boolean;

  @Prop({
    required: true
  })
    employeeMaxRiskAppetite!: number;

  @Prop({
    required: true
  })
    intermediaryMaxRiskAppetite!: number;

  formValues = {
    documentType: '',
    documentNumber: ''
  };

  formValidationOptions: EAFormValidationOptions = {
    rules: {
      documentType: [eaRequiredValidation('common.label.validation.fieldRequired')],
      documentNumber: [eaRequiredValidation('common.label.validation.fieldRequired')]
    },
  };

  showSearchResult: boolean = false;

  isResultsTableVisible: boolean = false;

  searchPersonResultList: GetPersonsResponseListaPersonas[] = [];
  
  editingPolicyholder: boolean = false;
  
  forceEdit: boolean = false;

  employeeUser = false;
  
  riskAppetiteMessage?: string;

  isAlertVisible: boolean = false;

  /**
   * Hook created
   *
   */
  @EAMethod()
  created() {
    this.showSearchResultIntervening();
    const userType = PermissionUtils.getUserType();
    this.employeeUser = PermissionUtils.isUserOfType(userType, UserTypeEnum.EMP);
  }

  /**
   * Emit selectedValues
   */
  showSearchResultIntervening() {
    if (this.interveningFlow) {
      this.showSearchResult = true;
    }
  }
    
  /**
   * Emit selectedValues
   * @param {string[]} selectedValues
   */
  onSelectedCommitContractValuesChange(selectedValues: string[]) {
    this.$emit('userWithCommitContrat', selectedValues.length > 0);
  }

  /**
   * Sets documentType default value on CIF
   */
  @Watch('this.formValues.documentType', {
    immediate: true,
  })
  setDocumentTypeDefaultValue() {
    const selectDocumentTypeValue = this.formValues.documentType;

    if (selectDocumentTypeValue === '') {
      this.formValues.documentType = 'C'; // CIF
    }
  }

  /**
   * Update validation rules when user changes document type as it is mandatory for NIF value
   */
  onDocumentTypeChange() {
    // Wait first for model to be updated
    this.$nextTick(() => {
      // Clear errors and notifications
      NotificationsUtils.clearNotifications();
      EAErrorManager.clearError();
      // Reset documentNumber value 
      this.formValues.documentNumber = '';
      // Clear validations
      const searchPolicyHolderForm = this.$refs.refSearchPolicyHolderForm as Form;
      searchPolicyHolderForm.clearValidate();
    });
  }

  /**
   * Changes documentNumber value to uppercase and unsets its error.
   * @returns { void }
   */
  onDocumentNumberChange(): void {
    // Add validations    
    if (this.formValues.documentNumber) {
      this.formValues.documentNumber =
      this.formValues.documentNumber.toUpperCase();
      // Add validations
      if (this.formValues.documentType === 'C') {
        this.formValidationOptions.rules.documentNumber = SearchPersonUtils.getDocumentNumberValidationRules(
          this.formValues.documentType, this.documentNumberCorporateTableValidation
        );
      } else if (this.formValues.documentType === 'N') {
        this.formValidationOptions.rules.documentNumber = [];
      }
      this.formValidationOptions.rules.documentNumber.unshift(
        eaRequiredValidation('common.label.validation.fieldRequired')
      );
    }
  }
  
  /**
   * It not necesary this function.
   *
   * @param {any} _rule
   * @param {any} _value
   * @param {Function} callback
   * @return {void} callback() if document number is valid; callback(new Error()) otherwise
   */
  documentNumberCorporateTableValidation(_rule: any, _value: any, callback: Function): void {
    return callback();
  }

  /**
   *  Set variable editing policyholder true
   */
  editPolicyholder() {
    this.editingPolicyholder = true;
  }


  /**
   *  Set variable editing policyholder true
   * @param { UpdatePolicyHolderRequest } selectedJuridicalPerson
   */
  @EAMethod({
    loading: true,
  })
  async onSavePolicyholder(selectedJuridicalPerson: UpdatePolicyHolderRequest) {
    // Limpiar posible errores mostrados anteriormente
    NotificationsUtils.clearNotifications();
    EAErrorManager.clearError();

    this.editingPolicyholder = false;
    const api: EAUpdatePolicyHolderApi = new EAUpdatePolicyHolderApi();
    const response = await api.updatePolicyHolderOperation({
      updatePolicyHolderRequest: selectedJuridicalPerson
    });
    if (response?.okRazonSocial) {
      this.model.selectedJuridicalPerson.razonSocialMatriz = selectedJuridicalPerson.razonSocialMatriz;
    } else {
      this.throwWarning(this.$t(`fleets.fleetsFlow.edit-policyholder.errors.businessNameNotEdited`).toString());
    }
    if (response?.okDireccion) {
      this.model.selectedJuridicalPerson.tipoViaDireccionLarga = selectedJuridicalPerson.tipoViaDireccionLarga;
      this.model.selectedJuridicalPerson.nombreDireccionTomador = selectedJuridicalPerson.nombreDireccionTomador;
      this.model.selectedJuridicalPerson.numeroDireccionTomador = selectedJuridicalPerson.numeroDireccionTomador;
      this.model.selectedJuridicalPerson.informacionAdicional = selectedJuridicalPerson.informacionAdicional;
      this.model.selectedJuridicalPerson.codigoPostal = selectedJuridicalPerson.codigoPostal;
      this.model.selectedJuridicalPerson.nombrePoblacionTomador = selectedJuridicalPerson.nombrePoblacionTomador;
      this.model.selectedJuridicalPerson.nombreProvinciaTomador = selectedJuridicalPerson.nombreProvinciaTomador;

      // Needed to update nombrePoblacionTomador
      this.update();
    } else {
      this.throwWarning(this.$t(`fleets.fleetsFlow.edit-policyholder.errors.addressNotEdited`).toString());
    }
    this.$forceUpdate();

    try {
      throwIfResponseHasErrors(response as ResponseWithErrors);
      this.forceEdit = false;
      this.throwConfirmation(this.$t('fleets.fleetsFlow.edit-policyholder.editPolicyholderSuccess').toString());
    } catch (errors) {
      if (this.forceEdit) {
        this.onResetSelection();
        this.forceEdit = false;
      }
      throw errors;
    }
  }

  /**
   * Checks if document type refers to a juridical person (persona física)
   * @param { string } documentType
   * @return { boolean } true if document type refers to a juridical person; false otherwise
   */
  isJuridicalPerson(documentType: string): boolean {
    const juridicalPersonDocumentType = ['E', 'X', 'C'];
    return juridicalPersonDocumentType.includes(documentType);
  }

  /**
   * @description when press button "newSearch" clear view component
   */
  onResetSelection() {
    // Limpiar posible errores mostrados anteriormente
    NotificationsUtils.clearNotifications();
    EAErrorManager.clearError();
    this.isAlertVisible = false;
    // Clear all fields form
    this.formValues.documentType = '';
    this.formValues.documentNumber = '';
    // Set default values
    this.setDocumentTypeDefaultValue();
    // Reset values rules
    this.formValidationOptions = {
      rules: {
        documentType: [eaRequiredValidation('common.label.validation.fieldRequired')],
        documentNumber: [eaRequiredValidation('common.label.validation.fieldRequired')]
      },
    };
    // Show form
    this.showSearchResult = false;
    
    // Clean model
    this.model.selectedJuridicalPerson = {
      tipoDocumento: undefined,
      codigoIdentificacionFiscal: '',
      razonSocialMatriz: '',
      tipoViaDireccionLarga: '',
      nombreDireccionTomador: '',
      numeroDireccionTomador: '',
      informacionAdicional: '',
      codigoPostal: '',
      nombrePoblacionTomador: '',
      nombreProvinciaTomador: ''
    };
    this.model.indicadorCompromisoContrato = false;
    this.model.fleetCommitContract.formValues.selectedValues = [];
    this.update();
    this.$emit('userWithCommitContrat', false);
    this.$emit('disableNextBtnOnNewSearch');
  }

  /**
   * Validates search form before performing search
   */
  @EAMethod()
  async searchPerson() {
    // Limpiar posible errores mostrados anteriormente
    NotificationsUtils.clearNotifications();
    EAErrorManager.clearError();
    const searchPolicyHolderForm = this.$refs.refSearchPolicyHolderForm as Form;
    await searchPolicyHolderForm.validation().validate();
      
    if (this.isJuridicalPerson(this.formValues.documentType)) {
      await this.performSearchPerson(this.formValues.documentNumber);
    } else if (isValidCif(this.formValues.documentNumber)) {
      this.throwWarning(this.$t('fleets.fleetsFlow.generalDataView.documentInvalid').toString());
    } else {
      // Show error
      this.throwWarning(this.$t(`fleets.fleetsFlow.search-person.contactWithMailbox`).toString());
    }
  }

  /**
   * Main function for search person
   * @param {string} documentNumber
   * @param {string} codigoFiliacion
   */
  @EAMethod({
    loading: true,
  })
  async performSearchPerson(documentNumber: string, codigoFiliacion?: string) {
    try {
      const api = new EASearchPolicyHolderApi();
      const response: SearchPolicyHolderResponse = await api.searchPolicyHolderOperation({
        searchPolicyHolderRequest:{
          codigoIdentificacionFiscal: documentNumber.toUpperCase(),
          identificadorMediador: this.intermediaryCode,
          nombreMediador: this.intermediaryName,
          codigoFiliacion
        }
      }) as SearchPolicyHolderResponse;

      const notFound = response.errors?.find(err => err.code === ErrorSearchPolicyHolder.NotFound);
      
      // In case not found use isValidRiskApettite
      if (!notFound) {
        // Throw errors
        throwIfResponseHasErrors(response as unknown as ResponseWithErrors);
      }

      if (this.isValidRiskAppetite(response.indicadorApetitoRiesgo)) {
        this.handleSearchPersonResponse(response);
        
        if (response.indicadorExistePersona) {
          this.throwConfirmation(this.$t(`fleets.fleetsFlow.generalDataView.foundPersonAtDatabase`).toString());
        } else {
          this.throwConfirmation(this.$t(`fleets.fleetsFlow.search-person.savedNewPersonInInfo`).toString());
        }
      }
    } catch (err) {
      this.$emit('validRiskApetite', false);
      throw err;
    }
  }

  /**
   * Handle search person response
   * @param {SearchPolicyHolderResponse} response
   */
  @EAMethod()
  handleSearchPersonResponse(response: SearchPolicyHolderResponse) {
    if (response.juridicalPeople) {
      this.model.juridicalPeople = response.juridicalPeople;
      if (response.juridicalPeople.length === 1) {
        if (response.indicadorDatosIncorrectos) {
          this.editingPolicyholder = true;
          this.forceEdit = true;
        }
        this.model.selectedJuridicalPerson = response.juridicalPeople[0];
        this.model.indicadorCompromisoContrato = response.indicadorCompromisoContratacion;
        this.model.indicadorExistePersona = response.indicadorExistePersona;
        this.showSearchResult = true;
        this.update();
        if (this.model.indicadorCompromisoContrato === false) {
          this.$emit('userWithCommitContrat', true);
        }
      } else {
        this.mapJuridicalPeopleToSelectionModal(response.juridicalPeople);
        this.isResultsTableVisible = true;
      }
    }
  }

  /**
   * Handle search person response
   * @param {SearchPolicyHolderResponse} juridicalPeople
   */
  mapJuridicalPeopleToSelectionModal(juridicalPeople: SearchPolicyHolderResponseJuridicalPeople[]) {
    this.searchPersonResultList = juridicalPeople.map(juridicalPerson => {
      return {
        codigoFiliacion: juridicalPerson.codigoFiliacion,
        codigoIdentificacionFiscal: juridicalPerson.codigoIdentificacionFiscal,
        tipoDocumento: juridicalPerson.tipoDocumento,
        descripcionDireccionCliente: juridicalPerson.direccionTomador,
        primerApellido: '',
        segundoApellido: '',
        nombrePropio: juridicalPerson.razonSocialMatriz,
      };
    }) as GetPersonsResponseListaPersonas[];
  }


  /**
   * @description: method to validate risk appetite
   * @param {number} riskAppetite
   * @returns {boolean}
   */
  isValidRiskAppetite(riskAppetite?: number) {
    let isValidRiskAppetite = true;

    if (!riskAppetite) {
      isValidRiskAppetite = false;
      const contactWithMailbox = this.$t(`fleets.fleetsFlow.search-person.contactWithMailbox`).toString();
      this.throwWarning(contactWithMailbox);
    } else if ((!this.employeeUser && riskAppetite > this.intermediaryMaxRiskAppetite) ||
    (this.employeeUser && riskAppetite > this.employeeMaxRiskAppetite)) {
      isValidRiskAppetite = false;
      const uninsurableRiskMessage = this.$t(`fleets.fleetsFlow.search-person.uninsurableRisk`).toString();
      this.throwWarning(uninsurableRiskMessage);
    } else if (this.employeeUser && riskAppetite === this.employeeMaxRiskAppetite-1) {
      this.isAlertVisible = true;
      this.riskAppetiteMessage = this.$t(`fleets.fleetsFlow.search-person.riskAppetite2`).toString();
    } else if (this.employeeUser && riskAppetite === this.employeeMaxRiskAppetite) {
      this.isAlertVisible = true;
      this.riskAppetiteMessage = this.$t(`fleets.fleetsFlow.search-person.riskAppetite3`).toString();
    }
    this.$emit('validRiskApetite', isValidRiskAppetite);
    return isValidRiskAppetite;
  }

  /**
   * @description: method to validate form in parent component
   * @returns {EAValidation}
   */
  public async validate(): Promise<EAValidation> {
    if (this.model.indicadorCompromisoContrato) {
    // Validación bloque "compromiso de contratación"
      const fleetCommitContractBC = this.$refs.refCommitContractBC as FleetCommitContractBusiness;
      await fleetCommitContractBC.validation().validate();
      fleetCommitContractBC.update();
    }
    
    const searchPolicyHolderForm: Form = this.$refs.refSearchPolicyHolderForm as Form;
    return searchPolicyHolderForm.validation();
  }


  /**
   * Throw warning by event buss
   * @param {string} warningMessage
   */
  throwWarning(warningMessage: string) {
    this.throwNotification(warningMessage, NotificationsTypeEnum.Warning);
  }


  /**
   * Throw confirmation by event buss
   * @param {string} confirmetionMessage
   */
  throwConfirmation(confirmetionMessage: string) {
    this.throwNotification(confirmetionMessage, NotificationsTypeEnum.Success);
  }

  /**
   * Throw any kind of notification by event buss
   * @param {string} notificationMessage 
   * @param {NotificationsTypeEnum} notificationType 
   */
  throwNotification(notificationMessage: string, notificationType: NotificationsTypeEnum) {
    const notifications: ZZNotification[] = [
      {
        title: notificationType,
        message: notificationMessage,
        type: notificationType,
      },
    ];
    NotificationsUtils.launchNotifications(notifications);
  }


  /**
   * Closes results table dialog without selecting person
   */
  closeResultsDialog() {
    this.isResultsTableVisible = false;
  }


  /**
   * @Param { GetPersonsResponseListaPersonas | undefined } tableSelectedPerson - Selected person in results table
   * Closes the dialog selecting a person
   * Calls method which provides person full data
   */
  @EAMethod()
  async selectPersonAndClose(tableSelectedPerson: GetPersonsResponseListaPersonas) {
    if (tableSelectedPerson) {
      this.isResultsTableVisible = false;
      await this.performSearchPerson(tableSelectedPerson.codigoIdentificacionFiscal as string,
        tableSelectedPerson.codigoFiliacion);
    }
  }
}

</script>

<style scoped lang="scss">
  .policyholder-card{
    position: relative;
  }
</style>
