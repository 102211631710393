import Vue from 'vue';
import {
  GetGeneralDataResponseDataDatosCabeceraElementosPoliza,
  GetGeneralDataResponseDataDatosObjeto,
  GetGeneralDataResponseDataDatosPersonas
} from '@/services/V1/quoteAndBuy/getGeneralDataOperation/post';
import {
  GetPersonsResponseDatosPersona,
  GetPersonsResponseDatosPersonaDatosGeneralesPersonaSexoEnum,
  GetPersonsResponseListaPersonasTipoDocumentoEnum,
  GetPersonsResponseListaPersonasTipoPersonaEnum,
} from '@/services/V1/quoteAndBuy/getPersonsOperation/post';
import {
  Roles
} from '@/types/roles/roles-enum.types';
import {
  EAValidationError
} from '@zurich-es-npm/ea-front-web-core';
import {
  PersonRole
} from './quote-buy-product-factory/types/product-role-types';
import {
  DocumentationClientData
} from '@/presentational-components/clientBasicInfo/clientBasicInfo.type';
import {
  isBoolean
} from 'lodash';

/**
 * Utils object to handle offer and policy persons
 */
export class PersonUtils {

  /**
   * Returns the parsed data of the selected person
   * @param {GetGeneralDataResponseDataDatosPersonas} selectedPerson
   * @returns {GetPersonsResponseDatosPersona}
   */
  public static getSelectedPerson(
    selectedPerson: GetGeneralDataResponseDataDatosPersonas
  ): GetPersonsResponseDatosPersona {
    const tipoDocumento = selectedPerson.tipoDocumento
      ? `${selectedPerson.tipoDocumento}` as GetPersonsResponseListaPersonasTipoDocumentoEnum
      : undefined;
    const tipoPersona = selectedPerson.tipoPersona
      ? `${selectedPerson.tipoPersona}` as GetPersonsResponseListaPersonasTipoPersonaEnum
      : undefined;

    return {
      datosBasicosPersona: {
        codigoFiliacion: selectedPerson.codigoFiliacion,
        codigoIdentificacionFiscal: selectedPerson.codigoIdentificacionFiscal,
        nombrePropio: selectedPerson.nombrePropio,
        primerApellido: selectedPerson.primerApellido,
        segundoApellido: selectedPerson.segundoApellido,
        tipoDocumento,
        tipoPersona,
      },
      datosGeneralesPersona: {
        fechaNacimiento: selectedPerson.fechaNacimiento,
        nacionalidadPersona: selectedPerson.nacionalidadPersona,
        codigoResidencia: selectedPerson.codigoResidencia,
        sexo: selectedPerson.sexo as
        unknown as
        GetPersonsResponseDatosPersonaDatosGeneralesPersonaSexoEnum,
      },
    };
  }

  /**
   * Gets person data from tabItems by role
   * If no person found => Show generic error
   * @param {PersonRole[]} personRoles
   * @param {any} role
   * @returns {GetPersonsResponseDatosPersona}
   */
  public static getPersonDataByRole(personRoles: PersonRole[], role: Roles): GetPersonsResponseDatosPersona {
    const personFound = PersonUtils.getPersonByRole(personRoles, role);

    if (personFound?.searchModel.selectedPerson && Object.keys(personFound?.searchModel.selectedPerson).length > 0) {
      return personFound.searchModel.selectedPerson;
    }

    throw new EAValidationError(
      Vue.prototype.$t('quoteBuyGenericFlow.error.personRoleNotFound', {
        rol: Vue.prototype.$t(`common.roles.${role}`).toString()
          .toLowerCase()
      }).toString()
    );
  }

  /**
   * Gets person from tabItems by role
   * IT WILL TAKE INTO ACCOUNT ROLE STATEMENTS SELECTIONS
   * @param {PersonRole[]} personRoles
   * @param {any} role
   * @returns {PersonRole | undefined}
   */
  public static getPersonByRole(
    personRoles: PersonRole[], role: Roles,
  ): PersonRole | undefined {
    const personFound = personRoles.find(
      person => person.role.toLowerCase() === role.toString().toLowerCase()
    );
    if (personFound) {
      for (const roleEqualStatement of personFound.roleEqualStatements || []) {
        if (roleEqualStatement.value) {
          // Person is equal to person with another role => Return that person
          const personCopyFrom = personRoles.find(person => person.role === roleEqualStatement.roleCopyFrom);
          if (personCopyFrom) {
            return personCopyFrom;
          }
        }
      }
    }
  
    return personFound;
  }

  /**
   * Gets user selected nationality based on received role
   * @param {PersonRole[]} personRoles
   * @param {any} role
   * @returns {string}
   */
  public static getSelectedNationalityByRole(
    personRoles: PersonRole[],
    role: any
  ): string {
    const personFound = personRoles.find(
      person => person.role.toLowerCase() === role.toString().toLowerCase()
    );
    if (personFound) {
      return personFound.searchModel.selectedPerson?.datosGeneralesPersona?.nacionalidadPersona || '';
    }
    return '';
  }

  /**
   * Retrieves received role person tax treatment area
   *  Will only return result for 'tomador' person and those persons who are equal to 'tomador' person
   * @param {PersonRole[]} personRoles 
   * @param {any} role
   * @returns {string}
   */
  public static getPersonTaxTreatmentArea(
    personRoles: PersonRole[], role: any
  ): string {
    const person = this.getPersonByRole(personRoles, role);
    if (person?.role.toLowerCase() === Roles.Tomador.toString().toLowerCase()) {
      return person.taxTreatmentArea || '';
    }
    return '';
  }
  

  /**
   * Retrieves client data for received role
   * This information is used in the header of address and iban modals
   * @param {PersonRole[]} personRoles
   * @param {Roles} role
   * @returns {DocumentationClientData}
   */
  public static getDocumentationClientData(
    personRoles: PersonRole[], role: any
  ): DocumentationClientData {
    const person = this.getPersonByRole(personRoles, role);

    return {
      clientData: person?.searchModel.selectedPerson || null,
      clientRole: role
    };
  }

  /**
   * Updates received person's selectable iban list
   * IMPORTANT: Received person may not be updated in personRoles array
   *  so it's better to use personRoleToUpdate when accessing its variables
   * @param {PersonRole[]} personRoles
   * @param {PersonRole} personRoleToUpdate
   * @returns {void}
   */
  public static updateSelectableIban(
    personRoles: PersonRole[], personRoleToUpdate: PersonRole
  ): void {
    // ### Set necessary variables ### //
    
    const tomador = personRoles.find(person => person.role === Roles.Tomador);
    const asegurado = personRoles.find(person => person.role === Roles.Asegurado);

    switch (personRoleToUpdate.role) {
      case Roles.Tomador:
        personRoleToUpdate.ibanCodeModel.selectableIbanList = personRoleToUpdate?.ibanList || [];
        break;
      case Roles.Asegurado:
        // eslint-disable-next-line no-case-declarations
        const aseguradoEqualToTomadorStatement = personRoleToUpdate?.roleEqualStatements?.find(
          roleEqualStatement => roleEqualStatement.roleCopyFrom === Roles.Tomador
        )?.value;
        if (aseguradoEqualToTomadorStatement) {
          personRoleToUpdate.ibanCodeModel.selectableIbanList = tomador?.ibanList || [];
        } else {
          personRoleToUpdate.ibanCodeModel.selectableIbanList = personRoleToUpdate?.ibanList || [];
        }
        break;
      case Roles.Pagador:
        // eslint-disable-next-line no-case-declarations
        const pagadorEqualToTomador = personRoleToUpdate?.roleEqualStatements?.find(
          roleEqualStatement => roleEqualStatement.roleCopyFrom === Roles.Tomador
        )?.value;
        // eslint-disable-next-line no-case-declarations
        const pagadorEqualToAsegurado = personRoleToUpdate?.roleEqualStatements?.find(
          roleEqualStatement => roleEqualStatement.roleCopyFrom === Roles.Asegurado
        )?.value;
        if (pagadorEqualToTomador) {
          personRoleToUpdate.ibanCodeModel.selectableIbanList = tomador?.ibanList || [];
        } else if (pagadorEqualToAsegurado) {
          personRoleToUpdate.ibanCodeModel.selectableIbanList = asegurado?.ibanList || [];
        } else {
          personRoleToUpdate.ibanCodeModel.selectableIbanList = personRoleToUpdate?.ibanList || [];
        }
        break;
      default:
        break;
    }
  }

  /**
   * Updates "canBeChanged" flag from every person with received objectElements data
   * @param {PersonRole[]} personRoles
   * @param {GetGeneralDataResponseDataDatosCabeceraElementosPoliza[] | undefined} elementosPoliza
   * @param {GetGeneralDataResponseDataDatosObjeto[] | undefined} datosObjeto
   * 
   * @returns {void}
   */
  public static updatePersonsCanBeChangedFlag(
    personRoles: PersonRole[],
    elementosPoliza?: GetGeneralDataResponseDataDatosCabeceraElementosPoliza[],
    datosObjeto?: GetGeneralDataResponseDataDatosObjeto[]
  ): void {
    const tomador = personRoles.find(person => person.role === Roles.Tomador);
    if (tomador) {
      const tomadorElement = elementosPoliza?.find(
        element => element.codigoElemento === 'CLFILTOM'
      );
      tomador.canBeChanged =
        tomadorElement && isBoolean(tomadorElement.elementoModificable) ? tomadorElement.elementoModificable : true;

    }

    const asegurado = personRoles.find(person => person.role === Roles.Asegurado);
    if (asegurado) {
      const aseguradoElement = datosObjeto?.find(
        element => element.codigoElemento === 'CDFILAS1'
      );
      asegurado.canBeChanged = aseguradoElement &&
        isBoolean(aseguradoElement.elementoModificable) ? aseguradoElement.elementoModificable : true;
    }
  }
  
}

export default PersonUtils;
