import {
  UpdateRiskResponse, UpdateRiskRequest, EAUpdateRiskApi
} from '@/services/V1/fleets/updateRiskOperation/post';
import Utils from '@/utils/utils';
import {
  VehicleFormModel
} from '../fleet-manual-upload-vehicle/fleet-manual-upload-vehicle-model';
import {
  CreateRiskWithPlateNumberResponse,
  CreateRiskWithPlateNumberRequest,
  CreateRiskWithPlateNumberRequestTipoMatriculaEnum,
  EACreateRiskWithPlateNumberApi
} from '@/services/V1/fleets/createRiskWithPlateNumberOperation/post';
import {
  EAUpdateVehicleEffectiveDateApi, UpdateVehicleEffectiveDateRequest
} from '@/services/V1/fleets/updateVehicleEffectiveDateOperation/post';

/**
 * 
 */
export class FleetInputVehiclesDataApi {

  /**
   *
   * @param {VehicleFormModel} editedVehicle 
   * @param {string} fleetCode 
   * @param {number} fleetVersion 
   * @return {Promise<UpdateRiskResponse | null>}
   */
  public static async updateRisk(editedVehicle: VehicleFormModel, fleetCode: string, fleetVersion: number):
  Promise<UpdateRiskResponse | null> {
    const updateRiskApi: EAUpdateRiskApi = new EAUpdateRiskApi();
    const updateRiskRequest: UpdateRiskRequest = {
      codigoFlota: fleetCode,
      versionFlota: fleetVersion,
      codigoPoliza: editedVehicle.offerNumber as string,
      versionPoliza: editedVehicle.offerVersion as number,
      fechaEfectoAnual: Utils.convertDateToBffString(editedVehicle.effectiveDate),
      valorAccesoriosTotal: editedVehicle.accessoriesValue as number,
      codigoUsoVehiculo: editedVehicle.vehicleUseId as string,
      codigoUsoPorMatricula: editedVehicle.plateUseId as string,
      numeroPolizaActual: editedVehicle.policyNumber,
      anoMatriculacion:
        editedVehicle.vehicleYear ? editedVehicle.vehicleYear.getFullYear().toString() : undefined,
      mesMatriculacion:
        editedVehicle.vehicleYear ? `0${editedVehicle.vehicleYear.getMonth() + 1}`.slice(-2): undefined,
      valorVehiculo: editedVehicle.vehicleValue as number,
      valorPesoMaximo: editedVehicle.vehicleMaxWeight as number
    };
    const updatedRiskResponse = await updateRiskApi.updateRiskOperation({
      updateRiskRequest
    });

    return updatedRiskResponse;
  }

  /**
   *
   * @param {VehicleFormModel} editedVehicle 
   * @param {string} fleetCode 
   * @param {number} fleetVersion 
   * @return {Promise<UpdateRiskResponse | null>}
   */
  public static async updateVehicleDate(editedVehicle: VehicleFormModel, fleetCode: string, fleetVersion: number):
  Promise<UpdateRiskResponse | null> {
    const updateVehicleDateApi: EAUpdateVehicleEffectiveDateApi = new EAUpdateVehicleEffectiveDateApi();
    const updateVehicleEffectiveDateRequest: UpdateVehicleEffectiveDateRequest = {
      versionFlota: fleetVersion,
      codigoFlota: fleetCode,
      codigoPoliza: editedVehicle.offerNumber as string,
      versionPoliza: editedVehicle.offerVersion as number,
      fechaEfectoAnual: Utils.convertDateToBffString(editedVehicle.effectiveDate)
    };
    const updateVehicleDateResponse = await updateVehicleDateApi.updateVehicleEffectiveDateOperation({
      updateVehicleEffectiveDateRequest
    });
    return updateVehicleDateResponse;
  }

  /**
   *
   * @param {VehicleFormModel} editedVehicle 
   * @param {string} fleetCode 
   * @param {number} fleetVersion 
   * @return {Promise<CreateRiskWithPlateNumberResponse | null>}
   */
  public static async createRiskWithManualData(
    editedVehicle: VehicleFormModel, fleetCode: string, fleetVersion: number):
    Promise<CreateRiskWithPlateNumberResponse | null> {
    const createRiskWithPlateNumberApi: EACreateRiskWithPlateNumberApi = new EACreateRiskWithPlateNumberApi();
    const createRiskWithPlateNumberRequest: CreateRiskWithPlateNumberRequest = {
      codigoFlota: fleetCode,
      versionFlota: fleetVersion,
      codigoPoliza: editedVehicle.offerNumber as string,
      versionPoliza: editedVehicle.offerVersion as number,
      codigoVehiculo: editedVehicle.codigoVehiculo as string,
      matriculaVehiculo: editedVehicle.plateNumber as string,
      tipoMatricula: editedVehicle.plateNumberType as CreateRiskWithPlateNumberRequestTipoMatriculaEnum,
      codigoUsoVehiculo: editedVehicle.vehicleUseId as string,
      codigoUsoPorMatricula: editedVehicle.plateUseId as string,
      fechaEfectoMovimiento: Utils.convertDateToBffString(editedVehicle.effectiveDate as Date),
      valorAccesoriosTotal: editedVehicle.accessoriesValue as number,
      numeroPolizaActual: editedVehicle.policyNumber,
      valorVehiculo: editedVehicle.vehicleValue as number,
      valorPesoMaximo: editedVehicle.vehicleMaxWeight as number
    };
    const createRiskWithPlateResponse = await createRiskWithPlateNumberApi.createRiskWithPlateNumberOperation({
      createRiskWithPlateNumberRequest
    });
    return createRiskWithPlateResponse;
  }
}
