

export interface ColumnItem {
  propKey: string;
  labelKey?: string;
  colType?: ColumnItemType;
}

export enum ColumnItemType {
  TextButton = 'text-button',
  Button = 'button',
  Text = 'text',
  Date = 'date',
  IconButton = 'IconButton'
}

/** 
 * Model competences-data
 */
class CompetencesDataModel {
  public variable = '';
}

export default CompetencesDataModel;
