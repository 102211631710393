<template>
  <div>
    <ea-flow-container>
      <div>
        <notification-handler/>
      </div>
      <div>
        <ea-row>
          <ea-col>
            <qb-header
              id="policy-qb-header"
              v-model="model"
              :title="title"
              :subtitle="subTitle"
              :parentFlowId="flowId"
              :headerSpan="12"
              ></qb-header>
          </ea-col>
          <ea-col>
            <competences
              v-model="model"
              @back="handleBack"
            />
          </ea-col>
        </ea-row>
      </div>
    </ea-flow-container>
  </div>
</template>

<script lang="ts">
import {
  Component
} from 'vue-property-decorator';

import {
  mixins
} from 'vue-class-component';

import {
  EAFlow,
  EAFlowNavigation,
  EAApplicationLogger,
  EAMethod,
  EAContextManager,
  EAEventBus,
  EALocaleManager
} from '@zurich-es-npm/ea-front-web-core';

import Competences from './views/management.vue';
import {
  CompetencesModel, InputModel
} from './competences-model';
import {
  isDocumentationProcessFinished
} from '@/utils/documents/documents-utils';
import QbHeaderBusiness from '@/business-components/qb-header/qb-header-business.vue';


const logger = new EAApplicationLogger();

@Component({
  components: {
    QbHeader: QbHeaderBusiness,
    Competences: Competences,
  }
})

/** 
 * Competences flow
 * 
 */
export default class CompetencesFlow extends mixins<EAFlow<CompetencesModel>>(EAFlow) {
  
  public flowId: string = '';

  public notifyKey: number = 0;

  /** 
   * Initialize flow model
   */
  constructor() {
    super();
    logger.debug('CompetencesFlow::constructor');
    this.model = new CompetencesModel();
    
    this.navigation = new EAFlowNavigation();
    this.navigation.maxWindows = 1;
  }

  /** 
   * Get data from params
   */
  created() {
    this.getParamsValue();
    this.flowId = EAContextManager.getInstance().getFlowContext()?.activeFlow?.flowId || '';
    EAEventBus.$on('zzCloseFlow', this.onCloseCoexitenceFlow);
  }

  /**
   * Remove the listeners created for analytics tracking
   *
   * @memberof EAAnalyticsTracking
   */
  beforeDestroy(): void {
    EAEventBus.$off('zzCloseFlow', this.onCloseCoexitenceFlow);
  }

  /**
   * On close Coexistence flow
   *
   * @param {string} targetFlowId 
   */
  @EAMethod({
    loading: true
  })
  async onCloseCoexitenceFlow(targetFlowId: string): Promise<void> {
    if (targetFlowId === this.flowId && await isDocumentationProcessFinished(
      this.model.offerNumber,
      this.model.offerVersion
    )) {
      this.closeFlow();
    }
  }

  /**
   * Returns tu parent flow - se encarga de devolver el control
   * al flujo padre.
   */
  @EAMethod()
  handleBack() {
    const outputModel = {
      codigoPoliza: this.model.policyNumber,
    };
  
    this.returnToParentFlow(outputModel);
  }
 

  /**
   * Get params from the InputModel
   */
  getParamsValue() {
    const {
      codigoPoliza, versionPoliza, tipoPoliza
    }: InputModel = this.inputModel;
    this.model.policyNumber = codigoPoliza;
    this.model.policyType = tipoPoliza;
    this.model.policyVersion = versionPoliza;
  }

  /** 
   * Get title
   */
  get title(): string {
    return EALocaleManager.i18n.t('competencesFlow.competencesView.title') as string;
  }


  /** 
   * Get subtitle
   */
  get subTitle(): string {
    if (this.model.policyVersion > 0) {
      const policy = EALocaleManager.i18n.t('competencesFlow.competencesView.subTitle', {
        number: this.model.policyNumber
      }) as string;
      const version = EALocaleManager.i18n.t('competencesFlow.competencesView.version', {
        version: this.model.policyVersion
      }) as string;
      
      return `${policy} ${version}`;
    }
    return EALocaleManager.i18n.t('competencesFlow.competencesView.subTitle', {
      number: this.model.policyNumber
    }) as string;
  }

}
</script>
