<template>
  <div class="slide" >
          <h2 class="t-align-center m-b-32">{{ $t('onboarding.comunidades.secondSlide.title') }}</h2>

          <h4 class="t-align-center t-weight-normal m-r-64 m-l-64 m-b-32">
            {{ $t('onboarding.comunidades.secondSlide.descriptionFirst') }}
          </h4>
          <h4 class="t-align-center t-weight-normal m-r-64 m-l-64 m-b-32">
            {{ $t('onboarding.comunidades.secondSlide.descriptionSecond') }}
          </h4>
          <h4 class="t-align-center t-weight-normal m-r-64 m-l-64 m-b-32">
            {{ $t('onboarding.comunidades.secondSlide.descrtiptionThird') }}
          </h4>
          
          <div class="d-display-flex d-align-items-center d-justify-center">
            <img
              :src="require(`@/assets/images/onboarding/comunidades/onboarding5.png`)"
              :alt="$t('layout.header.logoAlt')"
            >
          </div>
        </div>
</template>
<script lang="ts">

import Vue from 'vue';
import {
  Component
} from 'vue-property-decorator';

@Component({
  name: 'comunidades-onboarding-2'
})

/**
 * Comunidades onboarding component
 */
export default class ComunidadesOnboarding2 extends Vue {

}
</script>
