<template>
  <div>
    <ea-form ref="form" :model="model" :validateOnRuleChange="false" class="m-b-32">
      <ea-form-item
        prop="selectedEmail"
        :label="$t('common.field.email')"
        :required="isEmailRequired && !consultaOperation"
        :class="[model.emailList.length === 0 ? 'm-b-64' : '']"
      >
        <ea-select
          v-show="model.emailList.length > 0 ? true : false"
          :placeholder="$t('common.placeholder.selectEmail')"
          :readonly="consultaOperation"
          v-model="model.selectedEmailCode"
          @change="onSelectedAddressChange"
          id="email-sel"
        >
          <ea-option
            v-for="email in model.emailList"
            :key="email.codigoSecuencialDomicilio"
            :label="getEmailLabel(email)"
            :value="email.codigoSecuencialDomicilio"
          />
        </ea-select>
      </ea-form-item>
    </ea-form>

    <div v-if="!consultaOperation"  :class="[model.emailList.length === 0 ? 'm-t-8' : '']">
      <ea-link  @click="openAddEditModal">
        {{ $t('common.link.addEditEmail') }}
      </ea-link>
    </div>

    <add-email-tomador
      id="add-email-tomador-modal"
      ref="editionModal"
      v-model="model.addEmailTomadorModel"
      :selectedAddressTitle="$t('addEmailModal.addedEmails')"
      :addColumnTitle="$t('addEmailModal.addEmail')"
      :editColumnTitle="$t('addEmailModal.editEmail')"
      :addLinkText="$t('addEmailModal.addNewEmail')"
      :emptyResultText="$t('addEmailModal.noAddedEmails')"
      :labelMapMethod="getEmailLabel"
      :documentationClientData="documentationClientData"
      @saveEmailAddress="saveEmailAddress"
      @selectEmailAddress="selectEmailAddress"
      @cancel="updateModel"
    ></add-email-tomador>
  </div>
</template>

<script lang="ts">
import {
  Component, Prop
} from 'vue-property-decorator';

import {
  mixins
} from 'vue-class-component';

import {
  GetPersonAddressesResponseDomicilios
} from '@/services/V1/persons/getPersonAddressesOperation/post';
import {
  EABusinessComponent, EAMethod
} from '@zurich-es-npm/ea-front-web-core';
import AddEmailTomadorBusiness from '../../modals/add-email-tomador/add-email-tomador-business.vue';
import AddEditEmailModel from './add-edit-email-model';
import {
  SetPersonAddressesRequestDomicilios
} from '@/services/V1/persons/setPersonAddressesOperation/post';
import {
  DocumentationClientData
} from '@/presentational-components/clientBasicInfo/clientBasicInfo.type';

@Component({
  name: 'add-edit-email',
  components: {
    AddEmailTomador: AddEmailTomadorBusiness,
  },
})

/**
 * Business Component add-edit-email
 */
export default class AddEditEmailBusiness extends mixins<EABusinessComponent<AddEditEmailModel>>(EABusinessComponent) {

  @Prop()
    documentationClientData!: DocumentationClientData;

  @Prop()
    consultaOperation?: boolean;

  @Prop({
    required: false,
    'default': () => false,
  })
    isEmailRequired?: boolean;

  /**
   * Parses postal label to display format
   * @param {GetPersonAddressesResponseDomicilios} address
   * @returns {string}
   */
  public getEmailLabel(address: GetPersonAddressesResponseDomicilios): string {
    return address.emailContacto || '';
  }

  /**
   * Handles add edit address button click
   */
  openAddEditModal() {
    this.openEditionModal();
    this.model.addEmailTomadorModel = {
      ...this.model.addEmailTomadorModel,
      emails: this.model.emailList,
      selectedEmailCode: this.model.selectedEmailCode
    };
  }

  /**
   * Handles address selection
   */
  onSelectedAddressChange() {
    this.$nextTick(() => {
      if (this.model.selectedEmailCode) {
        this.model.selectedEmail = this.model.emailList.find(
          address => address.codigoSecuencialDomicilio === this.model.selectedEmailCode
        );
        this.updateModel();
      }
    });
  }
  
  /**
   * Emits save event
   * @param {SetPersonAddressesRequestDomicilios} saveEmail 
   */
  saveEmailAddress(saveEmail: SetPersonAddressesRequestDomicilios): void {
    this.$emit('saveEmailAddress', saveEmail);
  }

  /**
   * Selected received postal address
   * @param {string} selectedEmailCode
   */
  @EAMethod()
  selectEmailAddress(selectedEmailCode: string) {
    this.closeEditionModal();
    this.model.selectedEmailCode = selectedEmailCode;
    this.updateModel();
  }

  /**
   * Updates model and emits event to parent component
   */
  updateModel() {
    this.update();
    this.$nextTick(() => {
      this.$emit('updateViewModel');
    });
  }

  /**
   * Opens edition modal
   */
  openEditionModal() {
    const editionModalComp = this.$refs.editionModal as AddEmailTomadorBusiness;
    if (editionModalComp) {
      editionModalComp.open();
    }
  }

  /**
   * Closes edition modal
   */
  closeEditionModal() {
    const editionModalComp = this.$refs.editionModal as AddEmailTomadorBusiness;
    if (editionModalComp) {
      editionModalComp.close();
    }
  }
}
</script>
