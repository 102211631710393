<template>
  <div>
    <ea-form ref="form" :model="model" :validateOnRuleChange="false" class="m-b-32">
      <ea-form-item
        prop="selectedAddressCode"
        :label="$t('common.field.address')"
        :required="isAddressRequired && !consultaOperation"
        :class="[model.addressList.length === 0 ? 'm-b-64' : '']"
      >
        <ea-select
          v-if="model.addressList.length !== 0"
          :placeholder="$t('common.placeholder.selectAddress')"
          :readonly="consultaOperation"
          v-model="model.selectedAddressCode"
          @change="onSelectedAddressChange"
          id="address-sel"
        >
          <ea-option
            v-for="address in model.addressList"
            :key="address.codigoSecuencialDomicilio"
            :label="getPostalLabel(address)"
            :value="address.codigoSecuencialDomicilio"
          />
        </ea-select>
      </ea-form-item>
    </ea-form>

    <div v-if="!consultaOperation">
      <ea-link @click="openAddEditModal">
        {{ $t('common.link.addEditAddress') }}
      </ea-link>
    </div>

    <add-address-tomador
      id="add-addres-tomador-modal"
      ref="editionModal"
      v-model="model.addAddressTomadorModel"
      :selectedAddressTitle="$t('addAddressModal.addedAddresses')"
      :addColumnTitle="$t('addAddressModal.addAddress')"
      :editColumnTitle="$t('addAddressModal.editAddress')"
      :addLinkText="$t('addAddressModal.addNewAddress')"
      :emptyResultText="$t('addAddressModal.noAddedAddresses')"
      :labelMapMethod="getPostalLabel"
      :documentationClientData="documentationClientData"
      :corpTableDocumentsAddressType="corpTableDocumentsAddressType"
      :corpTableDocumentsCountries="corpTableDocumentsCountries"
      @savePostalAddress="savePostalAddress"
      @selectPostalAddress="selectPostalAddress"
      @cancel="updateModel"
    ></add-address-tomador>
  </div>
</template>

<script lang="ts">
import {
  Component, Prop
} from 'vue-property-decorator';

import {
  mixins
} from 'vue-class-component';

import {
  EAApplicationLogger, EABusinessComponent, EAError, EAMethod
} from '@zurich-es-npm/ea-front-web-core';

import {
  GetPersonAddressesResponseDomicilios
} from '@/services/V1/persons/getPersonAddressesOperation/post';
import {
  AddressUtils
} from '@/utils/address-utils';
import AddAddressTomadorBusiness from '../../modals/add-address-tomador/add-address-tomador-business.vue';
import AddEditAddressModel from './add-edit-address-model';
import {
  SetPersonAddressesRequestDomicilios
} from '@/services/V1/persons/setPersonAddressesOperation/post';
import {
  CorpTableNames,
  fetchCorporateTable,
  parseCorpTableDocuments,
  ParsedTableData,
} from '@/utils/corporate-tables';
import {
  DocumentationClientData
} from '@/presentational-components/clientBasicInfo/clientBasicInfo.type';

@Component({
  name: 'add-edit-address',
  components: {
    AddAddressTomador: AddAddressTomadorBusiness,
  },
})

/**
 * Business Component add-edit-address
 */
export default class AddEditAddressBusiness extends mixins<EABusinessComponent<AddEditAddressModel>>(
  EABusinessComponent
) {
  @Prop()
    documentationClientData!: DocumentationClientData;

  @Prop()
    consultaOperation?: boolean;

  @Prop({
    required: false,
    'default': () => true,
  })
    isAddressRequired?: boolean;

  public corpTableDocumentsAddressType: ParsedTableData[] = [];

  public corpTableDocumentsCountries: ParsedTableData[] = [];

  /**
   * Hook on created.
   *
   * @returns {void}
   */
  public created(): void {
    this.getCorpTablesData();
  }

  /**
   * Fetch corporate tables to retrieve products names and parse data.
   */
  @EAMethod({
    loading:true
  })
  async getCorpTablesData(): Promise<void> {
    try {
      const promises = [fetchCorporateTable(CorpTableNames.AddressType), fetchCorporateTable(CorpTableNames.Countries)];
      const results = await Promise.all(promises);

      this.corpTableDocumentsAddressType = parseCorpTableDocuments(results[0]);
      this.corpTableDocumentsCountries = parseCorpTableDocuments(results[1]);
    } catch (error) {
      const eaError = error as EAError;
      new EAApplicationLogger().error(
        `NewOfferBusiness::getCorpTablesData:: fetch corporate table :: ${eaError.message}`
      );
      throw error;
    }
  }

  /**
   * Parses postal label to display format
   * @param {GetPersonAddressesResponseDomicilios} address
   * @returns {string}
   */
  public getPostalLabel(address: GetPersonAddressesResponseDomicilios): string {
    return AddressUtils.getPostalLabel(address, this.corpTableDocumentsAddressType);
  }

  /**
   * Handles add edit address button click
   */
  openAddEditModal() {
    this.openEditionModal();
    this.model.addAddressTomadorModel = {
      ...this.model.addAddressTomadorModel,
      addresses: this.model.addressList,
      selectedAddressCode: this.model.selectedAddressCode,
    };
  }

  /**
   * Handles address selection
   */
  onSelectedAddressChange() {
    this.$nextTick(() => {
      if (this.model.selectedAddressCode) {
        this.model.selectedAddress = this.model.addressList.find(
          address => address.codigoSecuencialDomicilio === this.model.selectedAddressCode
        );
        this.updateModel();
      }
    });
  }

  /**
   * Emits save event
   * @param {SetPersonAddressesRequestDomicilios} savePostalAddress
   */
  savePostalAddress(savePostalAddress: SetPersonAddressesRequestDomicilios): void {
    this.$emit('savePostalAddress', savePostalAddress);
  }

  /**
   * Selects received postal address + closes edition modal
   * @param {string} selectedAddressCode
   */
  @EAMethod()
  selectPostalAddress(selectedAddressCode: string) {
    this.closeEditionModal();
    this.model.selectedAddressCode = selectedAddressCode;
    this.updateModel();
  }

  /**
   * Updates model and emits event to parent component
   */
  updateModel() {
    this.update();
    this.$nextTick(() => {
      this.$emit('updateViewModel');
    });
  }

  /**
   * Opens edition modal
   */
  openEditionModal() {
    const editionModalComp = this.$refs.editionModal as AddAddressTomadorBusiness;
    if (editionModalComp) {
      editionModalComp.open();
    }
  }

  /**
   * Closes edition modal
   */
  closeEditionModal() {
    const editionModalComp = this.$refs.editionModal as AddAddressTomadorBusiness;
    if (editionModalComp) {
      editionModalComp.close();
    }
  }
}
</script>
