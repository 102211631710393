import ProductBase from './products/product-base';
import ProductComunidades from './products/comunidades/product-comunidades';
import {
  EAApplicationError, EAApplicationLogger
} from '@zurich-es-npm/ea-front-web-core';
import {
  ProductTodoRiesgoConstruccionMontaje
} from './products/todo-riesgo-construccion-montaje/product-todo-riesgo-construccion-montaje';
import ProductHogar from './products/hogar/product-hogar';
import {
  CodigoRamo
} from '@/types/codigo-ramo/codigo-ramo-enum.types';

/**
 * Product Factory for Quote & Buy
 * 
 * @export
 * @class ProductFactory
 */
export class ProductFactory {

  private static factories: { [key: string]: new (codigoRamo: string) => ProductBase } = {
    THCO: ProductComunidades,
    COMU: ProductComunidades,
    ZTRC: ProductTodoRiesgoConstruccionMontaje,
    LPHG: ProductHogar,
    HOME: ProductHogar,
    THHG: ProductHogar,
    THHD: ProductHogar,
  };

  /**
   * Return correspondant factory for the productCode received
   * 
   * @static
   * @param {CodigoRamo} codigoRamo product code
   * @throws in case no factory could be created
   * @returns {ProductBase} correspondant product factory
   * 
   * @memberOf ProductFactory
   */
  public static createFactory(codigoRamo: CodigoRamo): ProductBase {
    if (Object.prototype.hasOwnProperty.call(this.factories, codigoRamo)) {
      return new this.factories[codigoRamo](codigoRamo);
    } else {
      const error = new EAApplicationError('ZON00039', [codigoRamo]);
      new EAApplicationLogger().error(error);
      throw error;
    }
  }
}

export default ProductFactory;
