<template>
  <ea-dialog
    :visible=true
    @close="onCancel"
  >
    <template slot='title'>
      <h3>{{getTitle()}}</h3>
    </template>
    <div>
      <ea-form
        ref="form"
        v-if="addEditPersonForm"
        :model="addEditPersonForm"
        :validationOptions="addEditPersonValidation"
        :validateOnRuleChange="false"
      >
        <ea-row>
          <ea-col :span="6">
            <ea-form-item
              :label="$t('quoteBuyGenericFlow.searchPerson.field.documentType')"
              prop="documentType"
              :required="!isEditting"
            >
              <ea-select
                v-model="addEditPersonForm.documentType"
                :placeholder="$t('common.label.select')"
                :readonly="isAdding || isEditting"
                @change="onDocumentTypeChange"
              >
                <ea-option
                  v-for="documentType in documentTypeList"
                  :key="documentType.value"
                  :label="documentType.label"
                  :value="documentType.value"
                />
              </ea-select>
            </ea-form-item>
          </ea-col>

          <ea-col :span="6">
            <ea-form-item
              :label="$t('quoteBuyGenericFlow.searchPerson.field.documentNumber')"
              prop="documentNumber"
              :required="!isEditting"
            >
              <ea-input-text
                v-model="addEditPersonForm.documentNumber"
                :readonly="isDocumentNumberReadonly"
                @change="onDocumentNumberChange"
              />
            </ea-form-item>
          </ea-col>
        </ea-row>

        <ea-row v-if="!isAddEditPersonJuridicalPerson">
          <ea-col :span="6">
            <ea-form-item :label="$t('quoteBuyGenericFlow.searchPerson.field.firstName')" prop="firstName" required>
              <ea-input-text v-model="addEditPersonForm.firstName" />
            </ea-form-item>
          </ea-col>

          <ea-col :span="6">
            <ea-form-item :label="$t('quoteBuyGenericFlow.searchPerson.field.lastName')" prop="lastName" required>
              <ea-input-text v-model="addEditPersonForm.lastName" />
            </ea-form-item>
          </ea-col>

          <ea-col :span="6">
            <ea-form-item
              :label="$t('quoteBuyGenericFlow.searchPerson.field.lastName2')"
              prop="lastName2"
              :required="isLastName2Required() ? true : false"
            >
            <ea-input-text v-model="addEditPersonForm.lastName2" />
            </ea-form-item>
          </ea-col>
        </ea-row>

        <ea-row v-if="isAddEditPersonJuridicalPerson">
          <ea-col :span="6">
            <ea-form-item
              :label="$t('quoteBuyGenericFlow.searchPerson.field.businessName')"
              prop="businessName"
              required
            >
              <ea-input-text v-model="addEditPersonForm.businessName" />
            </ea-form-item>
          </ea-col>
        </ea-row>

        <ea-row>
          <ea-col :span="6" v-if="!isAddEditPersonJuridicalPerson">
            <ea-form-item
              prop="gender"
              :label="$t('quoteBuyGenericFlow.searchPerson.field.gender.gender')"
              required
            >
              <ea-radio-group v-model="addEditPersonForm.gender" class="m-t-16"
              >
                <ea-radio label="V">
                  {{$t('quoteBuyGenericFlow.searchPerson.field.gender.male')}}
                </ea-radio>
                <ea-radio label="M">
                  {{$t('quoteBuyGenericFlow.searchPerson.field.gender.female')}}
                </ea-radio>
              </ea-radio-group>
            </ea-form-item>
          </ea-col>

          <ea-col :span="6" v-if="!isAddEditPersonJuridicalPerson">
            <ea-form-item :label="$t('quoteBuyGenericFlow.searchPerson.field.birthDate')" prop="birthDate" required>
              <ea-date-picker
                v-model="addEditPersonForm.birthDate"
                :placeholder="$t('common.label.dateSelect')"
              ></ea-date-picker>
            </ea-form-item>
          </ea-col>

          <ea-col :span="6">
            <ea-form-item :label="$t('quoteBuyGenericFlow.searchPerson.field.country')" prop="country" required>
              <ea-select
                v-model="addEditPersonForm.country"
                :placeholder="$t('common.label.select')"
                @change="onCountryChange"
              >
                <ea-option
                  v-for="country in countryList"
                  :key="country.value"
                  :label="country.label"
                  :value="country.value"
                />
              </ea-select>
            </ea-form-item>
          </ea-col>

          <ea-col :span="6" v-if="shouldShowTaxTreatmentAreaField">
            <ea-form-item
              :label="$t('quoteBuyGenericFlow.searchPerson.field.taxTreatmentArea')"
              prop="taxTreatmentArea"
            >
              <ea-select
                v-model="addEditPersonForm.taxTreatmentArea"
                :placeholder="$t('common.label.select')"
                @change="onTaxTreatmentAreaChange"
              >
                <ea-option
                  v-for="constraint in taxTreatmentAreaList"
                  :key="constraint.valorRestriccion"
                  :label="constraint.nombreRestriccion"
                  :value="constraint.valorRestriccion"
                />
              </ea-select>
            </ea-form-item>
          </ea-col>
        </ea-row>
      </ea-form>

      <div class="d-display-flex d-justify-flex-end">
        <ea-button type="secondary" @click="onCancel()" class="m-r-16">
          {{ $t('common.label.cancel') }}
        </ea-button>
        <ea-button type="primary" @click="onSave()">
          {{ $t('common.label.save') }}
        </ea-button>
      </div>
    </div>
  </ea-dialog>
</template>
<script lang="ts">
import {
  EACorporateTableDirective
} from '@zurich-es-npm/ea-front-web-core';
import {
  EAFormValidationOptions,
  eaRequiredValidation,
  EAValidation,
  EAValidationTriggers,
  EAValidationTypes,
  Form,
} from '@zurich-es-npm/ea-front-web-ui';
import Vue from 'vue';
import {
  Component, Prop, Watch
} from 'vue-property-decorator';
import {
  AddEditPersonForm
} from '../qb-search-person-model';
import {
  GetGeneralDataResponseDataDatosCabeceraTablaRestricciones
} from '@/services/V1/quoteAndBuy/getGeneralDataOperation/post';
import {
  SearchPersonUtils
} from '../utils/qb-search-person-utils';
import {
  ParsedTableData
} from '@/utils/corporate-tables';
import {
  Roles
} from '@/types/roles/roles-enum.types';

@Component({
  name: 'add-edit-person',
  directives: {
    'ea-corporate-table': EACorporateTableDirective,
  }
})

/**
 * Add Edit Person Component
 */
export default class AddEditPersonComponent extends Vue {
  @Prop()
    role?: Roles;

  @Prop()
    addEditPersonForm?: AddEditPersonForm;

  @Prop()
    documentTypeList?: ParsedTableData[];

  @Prop()
    countryList?: ParsedTableData[];

  @Prop()
    isAdding?: boolean;

  @Prop()
    isEditting?: boolean;

  @Prop()
    dateHandleFormat?: string;

  @Prop()
    taxTreatmentAreaList?: GetGeneralDataResponseDataDatosCabeceraTablaRestricciones[];

  @Prop()
    showDocumentInvalidError?: boolean;

  @Prop()
    showTaxTreatmentAreaField?: boolean;

  addEditPersonValidation: EAFormValidationOptions = {
    rules: {
      documentType: [eaRequiredValidation('common.label.validation.fieldRequired')],
      documentNumber: [eaRequiredValidation('common.label.validation.fieldRequired')],
      firstName: [eaRequiredValidation('common.label.validation.fieldRequired')],
      lastName: [eaRequiredValidation('common.label.validation.fieldRequired')],
      businessName: [eaRequiredValidation('common.label.validation.fieldRequired')],
      gender: [eaRequiredValidation('common.label.validation.fieldRequired')],
      birthDate: [eaRequiredValidation(
        'common.label.validation.fieldRequired', EAValidationTriggers.CHANGE, EAValidationTypes.DATE
      )],
      city: [eaRequiredValidation('common.label.validation.fieldRequired')],
      country: [eaRequiredValidation('common.label.validation.fieldRequired')]
    },
  };

  public isDocumentNumberReadonly = true;

  /**
   * Hook on created.
   *
   * @returns {void}
   */
  public created(): void {
    const docNumberEmpty = !!this.addEditPersonForm && this.addEditPersonForm.documentNumber === '';
    this.isDocumentNumberReadonly = (!!this.isAdding || !!this.isEditting) && !docNumberEmpty;

    // Add document number validation if it is editable (after searching with name/lastName)
    if (!this.isDocumentNumberReadonly && this.addEditPersonForm) {
      this.addEditPersonValidation.rules.documentNumber = SearchPersonUtils.getDocumentNumberValidationRules(
        this.addEditPersonForm.documentType, this.documentNumberCorporateTableValidation
      );
    }

    // Add lastName2 validation if required
    if (this.isLastName2Required()) {
      this.addEditPersonValidation.rules.lastName2 = [eaRequiredValidation('common.label.validation.fieldRequired')];
    } else {
      this.addEditPersonValidation.rules.lastName2 = [];
    }
  }

  /**
   * Handle cancel button and dispatch the event.
   */
  onCancel() {
    this.$emit('cancel');
  }

  /**
   * Validates search form before dispatching the save event
   */
  async onSave() {
    this.$emit('clearFlowErrors');

    try {
      await this.validation()?.validate();
      this.$emit('save');
    } catch (err) {
      // Design System will show form errors
    }
  }

  /**
   * Returns validation object
   * @returns {EAValidation}
   */
  public validation(): EAValidation {
    const form = this.$refs.form as Form;
    return form?.validation();
  }

  /**
   * Checks if selected person's document type refers to a juridical person (persona jurídica)
   * @return { boolean } true if document type refers to a juridical person; false otherwise
   */
  get isAddEditPersonJuridicalPerson(): boolean {
    if (!this.addEditPersonForm?.documentType) {
      return false;
    }
    const juridicalPersonDocumentType = ['E', 'X', 'C'];
    return juridicalPersonDocumentType.includes(this.addEditPersonForm.documentType);
  }

  /**
   * Update validation rules when user changes document type as it is mandatory for NIF value
   */
  onDocumentTypeChange() {
    // Wait first for model to be updated
    this.$nextTick(() => {
      if (!this.addEditPersonForm || !this.addEditPersonValidation) {
        return;
      }

      this.addEditPersonValidation.rules.documentNumber = SearchPersonUtils.getDocumentNumberValidationRules(
        this.addEditPersonForm.documentType, this.documentNumberCorporateTableValidation
      );

      if (this.isLastName2Required()) {
        this.addEditPersonValidation.rules.lastName2 = [eaRequiredValidation('common.label.validation.fieldRequired')];
      } else {
        this.addEditPersonValidation.rules.lastName2 = [];
      }

      // Clear all fields
      this.addEditPersonForm.documentNumber = '';
      this.addEditPersonForm.firstName = '';
      this.addEditPersonForm.lastName = '';
      this.addEditPersonForm.lastName2 = '';
      this.addEditPersonForm.businessName = '';

      const form: Form = this.$refs.form as Form;
      form.clearValidate();
    });
  }

  /**
   * Changes documentNumber value to uppercase and unsets its error.
   *
   * @returns { void }
   */
  onDocumentNumberChange(): void {
    this.showDocumentInvalidError = false;
    if (this.addEditPersonForm?.documentNumber) {
      this.addEditPersonForm.documentNumber = this.addEditPersonForm.documentNumber.toUpperCase();
    }
  }

  /**
   * Checks if lastName2 is required in search form
   * @return { boolean } true if it is required (ie: documentType==='N'); false otherwise
   */
  isLastName2Required(): boolean {
    return this.addEditPersonForm?.documentType === 'N';
  }

  /**
   * Validates document number within the corporate table.
   *
   * @param {any} _rule
   * @param {any} _value
   * @param {Function} callback
   * @return {void} callback() if document number is valid; callback(new Error()) otherwise
   */
  documentNumberCorporateTableValidation(_rule: any, _value: any, callback: Function): void {
    const result = this.showDocumentInvalidError ? callback(new Error()) : callback();
    this.showDocumentInvalidError = false;
    return result;
  }

  /**
   * Handles country change and modifies tax treatment area accordingly
   */
  onCountryChange() {
    // Wait first for model to be updated
    this.$nextTick(() => {
      if (!this.addEditPersonForm) {
        return;
      }

      const oldValue = this.addEditPersonForm.taxTreatmentArea;
      this.addEditPersonForm.taxTreatmentArea = this.addEditPersonForm.country === 'AND' ? 'A' : 'E';
      this.onTaxTreatmentAreaChange(this.addEditPersonForm.taxTreatmentArea, oldValue);
    });
  }

  /**
   * Handles taxTreatment change
   * If changed to Andorra sets updateDocLanguageValue to 'CA' (Catalan)
   * @param {string} newValue
   * @param {string | undefined} oldValue
   */
  onTaxTreatmentAreaChange(newValue: string, oldValue?: string) {
    if (!this.addEditPersonForm) {
      return;
    }

    oldValue = oldValue ? oldValue : this.addEditPersonForm.taxTreatmentArea;

    if (oldValue !== 'A') {
      if (newValue === 'A') {
        this.addEditPersonForm.updateDocLanguageValue = 'CA';
        return;
      }
    }

    this.addEditPersonForm.updateDocLanguageValue = undefined;
  }

  /**
   * Getter for dialog title
   * @returns {string}
   */
  getTitle(): string {
    if (this.isAdding) {
      return this.$t('quoteBuyGenericFlow.searchPerson.addClient').toString();
    } else {
      return this.$t('quoteBuyGenericFlow.searchPerson.editClient').toString();
    }
  }

  /**
   * Watcher for showDocumentInvalidError prop
   * @param {boolean} showDocumentInvalidError
   *
   */
  @Watch('showDocumentInvalidError')
  onShowDocumentInvalidError() {
    if (this.showDocumentInvalidError) {
      this.validation()
        ?.validate()
        .catch(() => {
          // Form errors will be shown automatically
        });
    }
  }

  /**
   * Watcher for addEditPersonForm prop
   * @param {AddEditPersonForm} addEditPersonForm
   *
   */
  @Watch('addEditPersonForm')
  onAddEditPersonFormChange() {
    if (!this.addEditPersonValidation) {
      return;
    }

    if (this.isLastName2Required()) {
      this.addEditPersonValidation.rules.lastName2 = [eaRequiredValidation('common.label.validation.fieldRequired')];
    } else {
      this.addEditPersonValidation.rules.lastName2 = [];
    }
  }

  /**
   * Getter that retrieves if person has 'tomador' role
   */
  get isTomador(): boolean {
    return this.role === Roles.Tomador;
  }

  /**
   * Getter that retrieves if tax treatment area field is visible in form
   */
  get shouldShowTaxTreatmentAreaField(): boolean {
    return this.isTomador && !!this.showTaxTreatmentAreaField && !!this.isEditting;
  }
}
</script>
