export enum PolicyBatchOnline {
  Error = 'TER',
  NotRate = 'T01',
  PendingRate = 'T02',
  Rated = 'T03',
  PolicyError = 'EER',
  PolicyPendingEmit = 'E01',
  PolicyEmitted = 'E02',
  GDC = 'GDC'
}
