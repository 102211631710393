import {
  GetDocumentUploadLinkResponseEnlacesCarga
} from '@/services/V1/common/getDocumentUploadLinkOperation/post';
import {
  GenerateOfferDocumentationResponseDocumentos
} from '@/services/V1/quoteAndBuy/generateOfferDocumentationOperation/post';
import {
  GetPersonsResponseDatosPersona,
  GetPersonsResponseListaPersonasTipoDocumentoEnum
} from '@/services/V1/quoteAndBuy/getPersonsOperation/post';
import {
  SaveOfferDataRequestDatosPersonasTipoPersonaEnum
} from '@/services/V1/quoteAndBuy/saveOfferDataOperation/post';
import {
  EAContextManager, EAFilesManager, EAUploadFile
} from '@zurich-es-npm/ea-front-web-core';
import moment from 'moment';
import 'moment/locale/es';

export interface FileUploadModel {
  name: string;
  id: string;
  extension: string;
  raw: File;
}

/**
 * Utils object with auxiliar functions
 */
export class Utils {

  public static _userAgent: string = '';

  /**
   * Returns userAgent
   * @return {userAgent}
   */
  public static get userAgent(): string {
    if (!Utils._userAgent) {
      const context = EAContextManager.getInstance();
      const platformContext = context ? context.getPlatformContext() : null;
      if (platformContext) {
        Utils._userAgent = platformContext.platformInfo?.name || '';
      }
    }
    return Utils._userAgent;
  }

  /**
   * Format date to "DD/MM/YYYY" format.
   *
   * @param {Date} date
   * @returns {string}
   */
  public static formatDateToString(date: Date | undefined): string {
    const momentDate = moment(date);
    return momentDate.isValid() ? momentDate.format('DD/MM/YYYY') : '';
  }

  /**
   * Convert date from bff format (YYYY-MM-DD) to "DD/MM/YYYY" format.
   *
   * @param {string} date
   * @returns {string}
   * @memberof OportunidadesNegocioDetailsModel
   */
  public static convertDateToString(date: string | undefined): string {
    const momentDate = moment(date, 'YYYY-MM-DD');
    return momentDate.isValid() ? momentDate.format('DD/MM/YYYY') : '';
  }

  /**
   * Convert date to bff format (YYYY-MM-DD).
   *
   * @param {Date} date
   * @returns {string}
   */
  public static convertDateToBffString(date: Date | undefined): string {
    const momentDate = moment(date);
    return momentDate.isValid() ? momentDate.format('YYYY-MM-DD') : '';
  }

  /**
   * 
   * @param {Date} inputDate 
   * @returns {Date}
   */
  public static returnDateWithHoursMinsReset(inputDate: Date): Date {
    inputDate.setHours(0, 0, 0, 0);
    return inputDate;
  }

  /**
   * Set scroll event listener on a table
   * @param {string} tableId Id of the requested table
   * @param {Function} callbackFunction loadMore function to call
   */
  public static setScrollEventOnTable(tableId: string, callbackFunction: Function): void {
    // FIXME: in a mobile device the table scroll listener should be different
    const tableEl = document.querySelector(`#${tableId} .ea-table-component`);
    if (tableEl) {
      tableEl.addEventListener('scroll', evt => {
        if (tableEl.scrollTop + tableEl.clientHeight >= tableEl.scrollHeight - 100) {
          callbackFunction();
        } else {
          evt.stopPropagation();
        }
      });
    }
  }


  /**
   * Convert first charater of string to upperCase.
   *
   * @param {string | undefined} str
   * @returns {string}
   */
  public static capitalizeFirstCharacter(str?: string): string {
    if (!str) {
      return '';
    }
    return str.charAt(0).toUpperCase() + str.slice(1).toLocaleLowerCase();
  }

  /**
   * Sorts received object array by given property
   * @param { any[] } array 
   * @param { string } property
   * @returns { any[] } sorted array
   */
  public static sortObjectArrayByProperty(array: any[], property: string): any[] {
    return array.sort(
      (elemA, elemB) => elemA[property].localeCompare(elemB[property], undefined, {
        numeric: true,
        sensitivity: 'base'
      })
    );
  }

  /**
   * Capitalizes object array property
   * @param { any[] } array 
   * @param { string } property
   * @returns { any[] } capitalized array
   */
  public static capitalizeObjectArrayProperty(array: any[], property: string): any[] {
    for (const elem of array) {
      if (elem[property]) {
        elem[property] =
          elem[property].charAt(0).toUpperCase() + elem[property].substr(1).toLowerCase();
      }
    }

    return array;
  }

  /**
   * Transforms received boolean input to string accepted by bff output
   * @param {boolean | undefined} booleanInput - input to be transformed 
   * @returns {string} 'S' if input is true 'N' otherwise
   */
  public static convertBooleanToString(booleanInput: boolean | undefined): string {
    return booleanInput ? 'S' : 'N';
  }

  /**
   * Returns received numeric value if received; otherwise defaultValue or 0
   * @param {number | undefined} numericValue - Number to parse
   * @param {number | undefined} defaultValue - Default value if there is no number to parse
   * @returns {number} Received numeric value if received; otherwise defaultValue or 0
   */
  public static parseNumericValueIfReceived(
    numericValue: number | undefined, defaultValue: number | undefined
  ): number {
    if (numericValue) {
      return numericValue;
    }

    if (defaultValue) {
      return defaultValue;
    }

    return 0;
  }

  /**
   * Sets the last element of an array given a property and the last element name
   * @param {any[]} array
   * @param {string} property
   * @param {string} lastElement
   */
  public static setObjectArrayLastElement(array: any[], property: string, lastElement: string): void {
    const foundIndex =
      array.findIndex((obj: any) => obj[property].toUpperCase().includes(lastElement.toUpperCase()));
    if (foundIndex > -1) {
      const itemToMove = array.splice(foundIndex, 1)[0];
      array.push(itemToMove);
    }
  }

  /**
   * Gets the person type based on its type person value or document type.
   * @param {GetPersonsResponseDatosPersona} person
   * @returns {SaveOfferDataRequestDatosPersonasTipoPersonaEnum}
   */
  public static getPersonType(
    person: GetPersonsResponseDatosPersona
  ): SaveOfferDataRequestDatosPersonasTipoPersonaEnum {
    if (person?.datosBasicosPersona?.tipoPersona) {
      return person.datosBasicosPersona.tipoPersona as unknown as SaveOfferDataRequestDatosPersonasTipoPersonaEnum;
    } else if (person.datosBasicosPersona?.tipoDocumento) {
      return [
        GetPersonsResponseListaPersonasTipoDocumentoEnum.X,
        GetPersonsResponseListaPersonasTipoDocumentoEnum.E,
        GetPersonsResponseListaPersonasTipoDocumentoEnum.C
      ].includes(person.datosBasicosPersona.tipoDocumento)
        ? SaveOfferDataRequestDatosPersonasTipoPersonaEnum.J
        : SaveOfferDataRequestDatosPersonasTipoPersonaEnum.F;
    } else {
      return SaveOfferDataRequestDatosPersonasTipoPersonaEnum.F;
    }
  }

  /**
   * Flattens array of arrays. [[1,2],[3,4]] => [1,2,3,4]
   * @param { Array<Array<any>> } arrayToFlatten - array to be flattened
   * @returns { Array<any> }
   */
  public static flatten(arrayToFlatten: any[][]): any[] {
    return arrayToFlatten.reduce(
      (
        flat: any[], toFlatten: any[][]
      ) => flat.concat(Array.isArray(toFlatten) ? this.flatten(toFlatten) : toFlatten), []);
  }

  /**
   * Remove non alphanumeric characters from string
   * 
   * @param {string} value
   * @return {string}
   */
  public static removeNonAlphaNumericCharacters(value: string): string {
    const isNotAlphanumeric = /(?!\w).|_/g;
    return value.replace(isNotAlphanumeric, '');
  }

  /**
   * Remove non numeric characters from string
   * 
   * @param {string} value
   * @return {string}
   */
  public static removeNonNumericCharacters(value: string): string {
    const isNotNumeric = /(?!\d)./g;
    return value.replace(isNotNumeric, '');
  }

  /**
   * Add thousands dot to a number
   * @param {string} value 
   * @return {string}
   */
  public static formatFourDigitNumber(value: string): string {
    const indexOfComma = value.toString().indexOf(',');
    const beforeCommaValue = indexOfComma === -1
      ? value
      : value.slice(0, indexOfComma);
    const afterCommaValue = indexOfComma === -1
      ? ''
      : value.slice(indexOfComma);

    if (beforeCommaValue.length === 4) {
      return `${beforeCommaValue.slice(0, 1)}.${beforeCommaValue.slice(1, 4)}${
        afterCommaValue.length === 2 || afterCommaValue.length === 0
          ? afterCommaValue + this.addZerosAfterComa(afterCommaValue.length)
          : afterCommaValue
      }`;
    }

    return `${
      afterCommaValue.length === 2 || afterCommaValue.length === 0
        ? value + this.addZerosAfterComa(afterCommaValue.length)
        : value
    }`;
  }

  /**
   * AddZerosAfterComa
   * 
   * @param {number} value 
   * @returns {string}
   */
  private static addZerosAfterComa(value: number): string {
    return value === 0 ? ',00' : '0';
  }

  /**
   * Formatea el precio ejemplo : 500 -> 500.00€
   * @param {string} price valor sin formato 500
   * @returns {string} valor con formato 500.00 €
   */
  public static digitsFormatter(price: string): string {
    return price ? `${Utils.formatFourDigitNumber(price)} €` : '-';
  }
  
  /**
   * Add thousands dot to a string number: 30000 -> 30.000
   * It doesn't work on decimals
   * @param {string} numberString without dot 30000
   * @returns {string} valor with milers dot 30.000
   */
  public static thousandsDotFormatter(numberString: string): string {
    const numLength = numberString.length;
    let formattedNumber = '';
    for (let index = 0; index < numLength; index++) {
      if (index > 0 && (numLength - index) % 3 === 0) {
        formattedNumber += '.';
      }
      formattedNumber += numberString[index];
    }
    return formattedNumber;
  }

  /**
   * Downloads given files
   * @param {ny} files
   */
  public static downloadFiles(files?: GenerateOfferDocumentationResponseDocumentos[] | undefined): void {
    const filesManager = EAFilesManager.getInstance();
    files?.forEach(file => {
      if (file.nombreDocumento && file.contenidoArchivo) {
        filesManager.downloadBase64File({
          fileName: file.nombreDocumento,
          base64Data: file.contenidoArchivo,
          mimeType: file.tipoMimeArchivo,
        });
      }
    });
  }

  /**
   * Uploads given files
   * @param {FileUploadModel[]} files
   * @param {GetDocumentUploadLinkResponseEnlacesCarga[]} signedUrls
   */
  public static async uploadFiles(
    files: FileUploadModel[],
    signedUrls: GetDocumentUploadLinkResponseEnlacesCarga[]
  ): Promise<void> {
    const filesToUpload: EAUploadFile[] = [];
    const attachments = files.map((file: FileUploadModel) => file.raw);

    attachments.forEach((attachment, index) => {
      filesToUpload.push({
        file: attachment,
        signedUrl: signedUrls[index].link
      });
    });

    const uploadApi = EAFilesManager.getInstance();
    await uploadApi.uploadFiles(filesToUpload);
  }

  /**
   * If value doesn't exists return hyphen
   * @param {string} valor 
   * @returns {string}
   */
  public static valueOrHyphen(valor: string): string {
    return (valor || '').trim() || '-';
  }

  /**
   * Sorts array by received property with translation label
   * @param {any[]} array 
   * @param {string} property 
   * @param {any} $t - translation function
   * @returns {any[]}
   */
  public static sortArrayByTranslatedProperty(array: any[], property: string, $t: any): any[] {
    if (!$t) {
      return array;
    }
    
    return array.sort(
      (elemA, elemB) => $t(elemA[property]).localeCompare($t(elemB[property]))
    );
  }

  /**
   * A simple sleep function to delay the execution of the code below.
   * @param {number} ms amount of time in milliseconds
   * @returns {Promise}
   */
  public static async sleep(ms: number): Promise<void> {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  /**
   * Scrolls to the center of the first element matching provided `selector`.
   * By default, uses the following options (can be overrided):
   * `{ block: 'center', behavior: 'smooth' }`
   * @param {string} selector 
   * @param {ScrollIntoViewOptions | undefined} options
   * @returns {Promise<boolean>} `true` if element could be found, `false` otherwise
   */
  public static async scrollToCenterOfElement(
    selector: string,
    options?: ScrollIntoViewOptions
  ): Promise<boolean> {
    await this.sleep(150); // To ensure element is already rendered into the DOM
    const elemToScroll = document.querySelector(selector);
    elemToScroll?.scrollIntoView(options || {
      block: 'center',
      behavior: 'smooth'
    });
    
    return !!elemToScroll;
  }
}

export default Utils;
