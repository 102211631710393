<!-- eslint-disable max-len -->
<template>
  <div>
    <ea-form
      ref="form"
      :model="model"
      :validationOptions="validationOptions"
      :validateOnRuleChange="false">
      <ea-row :gutter="40">
        <ea-col :span="8">
          <!-- SECCIÓN PAQUETE Y VALOR -->
          <ea-row>
            <ea-col :span="24">
              <ea-form-item
                prop="packageSelected"
                :label="$t('fleets.fleetsFlow.fleetPackagesWarrantiesModal.package')"
                :required="!isReadonly">
                <ea-select
                  v-model="model.packageSelected"
                  :placeholder="$t('common.label.select')"
                  :readonly="isReadonly"
                  @change="onChangeSelectedPackage($event)"
                >
                  <ea-option
                    v-for="code in packagesList"
                    :key="code.codigoPaqueteGarantia"
                    :label="$t(`fleets.fleetsFlow.fleetPackagesWarrantiesModal.codes.${code.codigoPaqueteGarantia}`)"
                    :value="code.codigoPaqueteGarantia"
                  />
                </ea-select>
              </ea-form-item>
            </ea-col>
            <ea-col
              :span="24"
              v-if="model.warranties.franquiciasValor && model.warranties.franquiciasValor.length">
              <ea-form-item
                prop="packageModalitySelected"
                :label="$t('fleets.fleetsFlow.fleetPackagesWarrantiesModal.packageModalityLabel')"
                :required="!isReadonly">
                <ea-select
                  v-model="model.packageModalitySelected"
                  :placeholder="$t('common.label.select')"
                  :readonly="isReadonly"
                >
                  <ea-option
                    v-for="franchise in model.warranties.franquiciasValor"
                    :key="franchise.indicadorModalidadGarantia"
                    :label="$t(`fleets.fleetsFlow.fleetPackagesWarrantiesModal.franchises.${franchise.indicadorModalidadGarantia}`)"
                    :value="franchise.indicadorModalidadGarantia"
                  />
                </ea-select>
              </ea-form-item>
            </ea-col>
          </ea-row>
        </ea-col>
        <!-- SECCIÓN GARANTÍAS INCLUIDAS -->
        <ea-col :span="16" v-if="model.packageSelected">
          <ea-row>
            <ea-col :span="24">
              <div class="t-weight-bold">
                {{$t(`fleets.fleetsFlow.fleetPackagesWarrantiesModal.warrantiesTitle`)}}
              </div>
            </ea-col>
          </ea-row>
          <ea-row>
            <ea-col :span="12"
              :key="warranty.codigoGarantia"
              v-for="warranty of model.warranties.garantiasIncluidas">
            <div class="m-r-12"
                 :class="[warranty.elementos && warranty.elementos.length
                  && model.warranties.garantiasOpcionales.find(
                        optionalWarranty => optionalWarranty.codigoGarantia === warranty.codigoGarantia && optionalWarranty.garantiaSeleccionada) ? 'm-b-64' : 'm-b-4']">
                <span class="m-b-4">
                  - {{$t(`fleets.fleetsFlow.fleetPackagesWarrantiesModal.warrantiesCodes.${warranty.codigoGarantia}`)}}
                </span>
                <template v-if="warranty.elementos && warranty.elementos.length
                  && !model.warranties.garantiasOpcionales.find(
                        optionalWarranty => optionalWarranty.codigoGarantia === warranty.codigoGarantia && optionalWarranty.garantiaSeleccionada)">
                  <ea-form
                    v-for="element of warranty.elementos"
                    :key="element.codigoElemento"
                    :ref="`form_${warranty.codigoGarantia}_${element.codigoElemento}`"
                    :model="element"
                    :validationOptions="formsValidationsRules[`form_${warranty.codigoGarantia}_${element.codigoElemento}`]"
                    :validateOnRuleChange="false">
                      <ea-form-item
                        prop="valorElemento"
                        :showLabel="false"
                        :required="!isReadonly">
                        <ea-select
                          :placeholder="$t('common.label.select')"
                          v-model="element.valorElemento"
                          :readonly="isReadonly"
                        >
                          <ea-option
                            v-for="restriction in element.tablaRestricciones"
                            :key="restriction.valorRestriccion"
                            :label="restriction.nombreRestriccion"
                            :value="restriction.valorRestriccion"
                          />
                        </ea-select>
                      </ea-form-item>
                  </ea-form>
                </template>
             </div>
            </ea-col>
          </ea-row>
        </ea-col>
      </ea-row>
      <!-- SECCIÓN GARANTÍAS OPCIONALES -->
      <ea-row
        v-if="model.packageSelected">
        <ea-col :span="24">
          <div class="t-weight-bold m-b-16">
          {{$t(`fleets.fleetsFlow.fleetPackagesWarrantiesModal.optionalWarranties.title`)}}
          </div>
        </ea-col>
        <ea-col :span="8" :gutter="40"
          v-for="(warranty, index) of model.warranties.garantiasOpcionales"
          :key="warranty.codigoGarantia+'-'+index">
          <div class="m-r-12">
            <ea-checkbox
              :label="$t(`fleets.fleetsFlow.fleetPackagesWarrantiesModal.optionalWarranties.${warranty.codigoGarantia}.${warranty.claveModificadorLiteralGarantia || 'default'}`)"
              v-model="warranty.garantiaSeleccionada"
              :checked="warranty.garantiaSeleccionada"
              :readonly="isReadonly"
              @change="onChangeSetOptionalWarranty(warranty, index, $event)"
              v-bind:class="{ 'm-b-64': !warranty.elementos || !warranty.elementos.length }"
              class="m-b-4"/>
              <template v-if="warranty.elementos && warranty.elementos.length">
                <ea-form
                  v-for="element of warranty.elementos"
                  :key="element.codigoElemento"
                  :ref="`form_${warranty.codigoGarantia}_${element.codigoElemento}_optional`"
                  :model="element"
                  :validationOptions="formsValidationsRules[`form_${warranty.codigoGarantia}_${element.codigoElemento}_optional`]"
                  :validateOnRuleChange="false">
                    <ea-form-item
                      prop="valorElemento"
                      :showLabel="false"
                      :required="isReadonly"
                      >
                      <ea-select
                        v-if="!!warranty.garantiaSeleccionada && getWarrantyFieldType(element) === 'select'"
                        :placeholder="$t('common.label.select')"
                        v-model="element.valorElemento"
                        :readonly="isReadonly"
                      >
                        <ea-option
                          v-for="restriction in element.tablaRestricciones"
                          :key="restriction.valorRestriccion"
                          :label="getModalityLabel(`fleets.fleetsFlow.fleetPackagesWarrantiesModal.optionalWarranties.${warranty.codigoGarantia}.modalities`, restriction.nombreRestriccion)"
                          :value="restriction.valorRestriccion"
                        />
                      </ea-select>
                      <!-- Solo se muestra cuando la garantía está deseleccionada -->
                      <ea-select
                        v-else-if="!!!warranty.garantiaSeleccionada && getWarrantyFieldType(element) === 'select'"
                        :placeholder="$t('common.label.select')"
                        :disabled="true"
                      >
                      </ea-select>
                      <ea-input-currency
                        v-else-if="!!warranty.garantiaSeleccionada && getWarrantyFieldType(element) === 'input'"
                        :allowNegative="false"
                        :max="element.valorMaximoElemento"
                        :min="element.valorMinimoElemento"
                        :placeholder="`Máx. ${element.valorMaximoElemento} €`"
                        v-model="element.valorElemento"
                        :readonly="isReadonly"
                      />
                      <!-- Solo se muestra cuando la garantía está deseleccionada -->
                      <ea-input-currency
                        v-else-if="!!!warranty.garantiaSeleccionada && getWarrantyFieldType(element) === 'input'"
                        :placeholder="`Máx. ${element.valorMaximoElemento} €`"
                        :disabled="true"
                      />
                    </ea-form-item>
                </ea-form>
              </template>
          </div>
        </ea-col>
      </ea-row>
    </ea-form>
  </div>
</template>

<script lang="ts">
import {
  Component, Prop
} from 'vue-property-decorator';
import {
  mixins
} from 'vue-class-component';
import {
  EABusinessComponent, EAMethod, ResponseWithErrors, throwIfResponseHasErrors
} from '@zurich-es-npm/ea-front-web-core';
import {
  EAFormValidationOptions, EAIValidationRule, eaRangeValidation,
  eaRequiredValidation, EAValidationTriggers, EAValidationTypes, Form
} from '@zurich-es-npm/ea-front-web-ui';
import FleetPackageSelectModel from './fleet-package-select-model';
import {
  GetWarrantyPackagesResponsePaquetes
} from '@/services/V1/fleets/getWarrantyPackagesOperation/post/api';
import {
  EAGetWarrantiesByPackageApi,
  GetWarrantiesByPackageRequest,
  GetWarrantiesByPackageRequestEstadoPolizaFlotaEnum,
  GetWarrantiesByPackageResponse,
  GetWarrantiesByPackageResponseElementos,
  GetWarrantiesByPackageResponseFranquiciasValor,
  GetWarrantiesByPackageResponseGarantiasIncluidas
} from '@/services/V1/fleets/getWarrantiesByPackageOperation/post/api';
import {
  GetGroupedVehicleListResponseListaVehiculos
} from '@/services/V1/fleets/getGroupedVehicleListOperation/post';
import {
  cloneDeep
} from 'lodash';

@Component({
  name: 'fleet-package-select'
})

/**
 * Business Component fleet-package-select
 */
export default class FleetPackageSelectBusiness extends
  mixins<EABusinessComponent<FleetPackageSelectModel>>(EABusinessComponent) {

  @Prop({
    'default': () => []
  })
    packagesList?: GetWarrantyPackagesResponsePaquetes[];
  
  @Prop({
    required: false,
    'default': () => [],
  })
    selectedVehicles!: GetGroupedVehicleListResponseListaVehiculos[];

  @Prop({
    required: false,
    'default': () => '',
  })
    vehicleType!: string;

  @Prop()
    isReadonly?: boolean;

  validationOptions: EAFormValidationOptions = {
    rules: {
      packageSelected: [eaRequiredValidation()],
      packageModalitySelected: []
    }
  };

  formsValidationsRules: Record<string, EAFormValidationOptions> = {};

  getWarratyByPackageApi: EAGetWarrantiesByPackageApi = new EAGetWarrantiesByPackageApi();

  loadedPackageWarrantiesCache: Record<string, GetWarrantiesByPackageResponse> = {};

  /**
   * Created life ci
   */
  async created() {
    if (this.model.packageSelected) {
      await this.onChangeSelectedPackage(this.model.packageSelected, true);
    }
  }

  /**
   * @param {string} packageCode
   * @param {boolean} isEditing
   */
  @EAMethod({
    loading: true
  })
  async onChangeSelectedPackage(packageCode: string, isEditing?: boolean) {
    const vehicle = this.selectedVehicles[0];
    if (vehicle) {
      let response: GetWarrantiesByPackageResponse;

      // If warranties are in cache memory wont load again
      if (!this.loadedPackageWarrantiesCache[`${this.vehicleType}-${packageCode}`]) {
        this.model.warranties.garantiasIncluidas = [];
        this.model.warranties.garantiasOpcionales = [];
        this.model.warranties.franquiciasValor = [];
        const getWarrantiesByPackageRequest: GetWarrantiesByPackageRequest = {
          codigoPaqueteGarantia: packageCode,
          codigoPoliza: vehicle.codigoPoliza as string,
          versionPoliza: vehicle.versionPoliza as number,
          indicadorEdicion: !!isEditing,
          codigoAgrupacionFlota: this.selectedVehicles[0].codigoAgrupacionFlota as string,
          estadoPolizaFlota: GetWarrantiesByPackageRequestEstadoPolizaFlotaEnum.Oferta
        };
        response =
        await this.getWarratyByPackageApi.getWarrantiesByPackageOperation({
          getWarrantiesByPackageRequest
        }) as GetWarrantiesByPackageResponse;
        throwIfResponseHasErrors(response as ResponseWithErrors);
        response.garantiasIncluidas = this.sortIncludedWarranties(response.garantiasIncluidas || []);
        // Relationship of vehicletype and package to cache
        this.loadedPackageWarrantiesCache[`${this.vehicleType}-${packageCode}`] = response;
      }
      this.loadPackageModalityRestrictions(
        this.loadedPackageWarrantiesCache[`${this.vehicleType}-${packageCode}`].franquiciasValor);
      this.loadWarrantiesRestrictions(this.loadedPackageWarrantiesCache[`${this.vehicleType}-${packageCode}`]);
      this.model.warranties = cloneDeep(this.loadedPackageWarrantiesCache[`${this.vehicleType}-${packageCode}`]);
      this.model.packageModalitySelected =
        // eslint-disable-next-line max-len
        this.loadedPackageWarrantiesCache[`${this.vehicleType}-${packageCode}`].franquiciasValor?.find(franchise => franchise.opcionSeleccionada)?.indicadorModalidadGarantia as string;
      
    }
  }


  /**
   * Set rules to validation options
   * @param {GetWarrantiesByPackageResponseFranquiciasValor[]} franchises 
   */
  loadPackageModalityRestrictions(franchises: GetWarrantiesByPackageResponseFranquiciasValor[] = []) {
    this.validationOptions.rules.packageModalitySelected = [];
    if (franchises.length) {
      this.validationOptions.rules.packageModalitySelected = [eaRequiredValidation()];
    }
    this.$forceUpdate();
  }

  /**
   * Set rules to validation options
   * @param {GetWarrantiesByPackageResponse} response 
   */
  loadWarrantiesRestrictions(response: GetWarrantiesByPackageResponse) {
    this.formsValidationsRules = {};
    response.garantiasIncluidas?.forEach(warranty => {
      warranty.elementos?.forEach(elemento => {
        const validations: EAIValidationRule[] = [eaRequiredValidation()];
        this.formsValidationsRules[`form_${warranty.codigoGarantia}_${elemento.codigoElemento}`] = {
          rules: {
            valorElemento: validations
          }
        };
      });
    });
    this.sortIncludedWarranties(response.garantiasOpcionales || []);
    response.garantiasOpcionales?.forEach((warranty, index) => {
      this.onChangeSetOptionalWarranty(warranty, index, !!warranty.garantiaSeleccionada);
    });
  }

  /**
   * On change optional warranty handler
   * @param {GetWarrantiesByPackageResponseGarantiasIncluidas} warranty 
   * @param {number} index 
   * @param {boolean} checked 
   */
  onChangeSetOptionalWarranty(warranty: GetWarrantiesByPackageResponseGarantiasIncluidas, index: number,
    checked: boolean) {
    
    this.onChangeSetOptionalWarrantyOnConflict(warranty, index, checked);
    this.onChangeSetOptionalWarrantyValidation(warranty, checked);
    
  }

  /**
   * Set rules to validation options
   * @param {GetWarrantiesByPackageResponseGarantiasIncluidas} warranty 
   * @param {boolean} checked 
   */
  onChangeSetOptionalWarrantyValidation(warranty: GetWarrantiesByPackageResponseGarantiasIncluidas, checked: boolean) {
    let validations: EAIValidationRule[] = [];
    

    warranty.elementos?.forEach(elemento => {
      if (checked) {
        if (elemento.tablaRestricciones?.length) {
          validations = [eaRequiredValidation()];
        } else {
          elemento.valorElemento = elemento.valorElemento || undefined;
          validations =
            [eaRangeValidation(elemento.valorMinimoElemento as number, elemento.valorMaximoElemento as number,
              undefined, EAValidationTriggers.BLUR, EAValidationTypes.NUMBER),
            eaRequiredValidation(undefined, undefined, EAValidationTypes.NUMBER)];
        }
      } else if (!elemento.tablaRestricciones?.length) {
        // Si se considera tipo input dejamos el valor asignado o en caso contrario asignamos por defecto 0
        elemento.valorElemento = elemento.valorElemento || undefined;
      }

      delete this.formsValidationsRules[`form_${warranty.codigoGarantia}_${elemento.codigoElemento}_optional`];
      if (validations.length) {
        this.formsValidationsRules[`form_${warranty.codigoGarantia}_${elemento.codigoElemento}_optional`] = {
          rules: {
            valorElemento: validations
          }
        };
      }
      const childForm: Form[] =
        this.$refs[`form_${warranty.codigoGarantia}_${elemento.codigoElemento}_optional`] as Form[];
      if (childForm?.length) {
        childForm[0].clearValidate();
      }
    });

  }

  /**
   * Sorts included warrenty list with selectors in the last position
   * @param {GetWarrantiesByPackageResponseGarantiasIncluidas[]} warranties 
   * @returns {GetWarrantiesByPackageResponseGarantiasIncluidas[]}
   */
  sortIncludedWarranties(warranties: GetWarrantiesByPackageResponseGarantiasIncluidas[]):
  GetWarrantiesByPackageResponseGarantiasIncluidas[] {
    // eslint-disable-next-line max-len
    return warranties.sort((warrantyA, warrantyB) => Number(warrantyA.elementos?.length || 0) - Number(warrantyB.elementos?.length || 0));
  }

  /**
   * Assign warrenty field type to select or input
   * @param {GetWarrantiesByPackageResponseElementos} element 
   * @returns {string}
   */
  getWarrantyFieldType(element: GetWarrantiesByPackageResponseElementos): string {
    return element.tablaRestricciones?.length ? 'select' : 'input';
  }

  /**
   * BeforeDestroy Lifecycle
   */
  beforeDestroy() {
    this.loadedPackageWarrantiesCache = {};
  }

  /**
   * If translate is found return it, if not exist return literal
   * @param {string} translatePath 
   * @param {string} defaultKey 
   * @returns {string}
   */
  getModalityLabel(translatePath: string, defaultKey: string) {
    if (this.$te(`${translatePath}.${defaultKey}`)) {
      return this.$t(`${translatePath}.${defaultKey}`).toString();
    }
    return defaultKey;
  }

  /**
   * Desmarca las garantías que estén en conflicto con la seleccionada
   * @param {GetWarrantiesByPackageResponseGarantiasIncluidas} warranty 
   * @param {number} warrantyIndex 
   * @param {boolean} checked 
   */
  onChangeSetOptionalWarrantyOnConflict(warranty: GetWarrantiesByPackageResponseGarantiasIncluidas,
    warrantyIndex: number,
    checked: boolean): void {
    if (checked) {
      this.model.warranties.garantiasOpcionales?.forEach((optionalWarranty, index) => {
        if (optionalWarranty.codigoGarantia === warranty.codigoGarantia &&
        index !== warrantyIndex) {
          optionalWarranty.garantiaSeleccionada = false;
        }
      });
    }
  }

  /**
   * Validate
   */
  async validateForms() {
    const form = this.$refs.form as Form;
    await form.validation().validate();
    for (const key in this.formsValidationsRules) {
      const childForm: Form[] = this.$refs[key] as Form[];
      if (childForm?.length) {
        await childForm[0].validation().validate();
      }
    }
    this.update();
  }

}
</script>
