import DocumentationFlow from '@/flows/documentation/documentation-flow.vue';
import CompetencesFlow from '@/flows/competences/competences-flow.vue';
import NotesFlow from '@/flows/notes/notes-flow.vue';
import QuoteBuyFlow from '@/flows/quote-buy/quote-buy-flow.vue';
import PolicyFlow from '@/flows/policy/policy-flow.vue';
import FleetsQueryPolicyFlow from '@/flows/fleets-query-policy/fleets-query-policy-flow.vue';
import FleetsMaintenancePolicyFlow from '@/flows/fleets-maintenance-policy/fleets-maintenance-policy-flow.vue';
import FleetsFlow from '@/flows/fleets/fleets-flow.vue';

/**
 * Install business component flows as Vue component
 * 
 * @param {any} Vue - Vue instance
 */
const install = function(Vue: any): void {
  // Install necessary flows here
  Vue.component('DocumentationFlow', DocumentationFlow);
  Vue.component('CompetencesFlow', CompetencesFlow);
  Vue.component('NotesFlow', NotesFlow);
  Vue.component('QuoteAndBuyMaintenance', QuoteBuyFlow);
  Vue.component('QuoteAndBuyInquiry', QuoteBuyFlow);
  Vue.component('PolicyInquiry', PolicyFlow);
  Vue.component('PrepolicyMaintenance', PolicyFlow);
  Vue.component('PolicySupplement', PolicyFlow);
  Vue.component('PolicyReplacement', PolicyFlow);
  Vue.component('FleetQueryPolicy', FleetsQueryPolicyFlow);
  Vue.component('FleetMaintenancePolicy', FleetsMaintenancePolicyFlow);
  Vue.component('FleetNew', FleetsFlow);
  Vue.component('FleetMaintenance', FleetsFlow);
};

export default install;
