export enum VehicleEditionTypeEnum {
  none = 'none',
  invalid = 'invalid',
  basesiete = 'basesiete',
  use = 'use',
  common = 'common',
  date = 'date',
  values = 'values',

}
