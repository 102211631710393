import {
  GetContractDataResponseFormasPago
} from '@/services/V1/quoteAndBuy/getContractDataOperation/post';
import {
  GetFractionalPaymentsRequestTipoFormaPagoTarificacionGarantiasEnum as FormasPagoEnum
} from '@/services/V1/quoteAndBuy/getFractionalPaymentsOperation/post';

/** 
 * Model qb-offer-issuance-bonus-data-form
 */
class QbOfferIssuanceBonusDataFormModel {

  public paymentForm: FormasPagoEnum = FormasPagoEnum.Anual;

  public noMinimumInsurance: boolean = false;

  public price?: number = 0;

  public firstReceipt?: number = 0;

  public successiveReceipts?: number = 0;

  public paymentForms: GetContractDataResponseFormasPago[] = [];

}

export default QbOfferIssuanceBonusDataFormModel;
