<template>
  <div>
    <ea-form ref="form" :model="model" :validationOptions="formValidationOptions">
      <ea-row>
        <ea-col :span="6">
          <ea-form-item
            :label="$t('quoteBuyGenericFlow.searchPerson.field.billing')"
            prop="billingValue"
          >
            <ea-input-currency
              :precision="2"
              :disabled="isReadonly"
              v-model="model.billingValue"
              @input="onChangeBilling"
            />
          </ea-form-item>
        </ea-col>
      </ea-row>
    </ea-form>
  </div>
</template>

<script lang="ts">
import {
  Component, Prop
} from 'vue-property-decorator';

import {
  mixins
} from 'vue-class-component';

import {
  EABusinessComponent
} from '@zurich-es-npm/ea-front-web-core';

import QbBillingDataModel from './qb-billing-data-model';
import {
  EAFormValidationOptions, EAValidation, Form, eaCustomValidation
} from '@zurich-es-npm/ea-front-web-ui';
import {
  minMaxValidation
} from '@/utils/object-utils';

@Component({
  name: 'qb-billing-data'
})

/**
 * Business Component qb-billing-data
 */
export default class QbBillingDataBusiness extends
  mixins<EABusinessComponent<QbBillingDataModel>>(EABusinessComponent) {

  public minValue: number = 0;

  public maxValue: number = 999999999999.99;

  @Prop({
    'default': () => false
  })
    isReadonly!: boolean;

  /**
   * Gets form validation options
   */
  get formValidationOptions(): EAFormValidationOptions {
    return {
      rules:{
        billingValue: [
          eaCustomValidation(
            minMaxValidation(this.minValue, this.maxValue),
            'common.label.validation.invalidRange'
          )
        ]
      }
    };
  }

  /**
   * Handles billingValue change
   */
  onChangeBilling() {
    this.updateModel();
  }

  /**
   * Updates model and emits event to parent component
   */
  updateModel() {
    this.update();
    this.$nextTick(() => {
      this.$emit('updateBillingData');
    });
  }

  /**
   * Returns validation object
   * @returns { EAValidation }
   */
  public validation(): EAValidation {
    const form = this.$refs.form as Form;
    return form.validation();
  }
}
</script>
