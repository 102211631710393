import QbIbanCodeModalModel from '@/business-components/qb-iban-code-modal/qb-iban-code-modal-model';
import {
  EAGetIbanApi, GetIbanResponse, GetIbanResponseDataIbanList
} from '@/services/V1/quoteAndBuy/getIbanOperation/post';
import {
  ResponseWithErrors, EAMethod
} from '@zurich-es-npm/ea-front-web-core';

export interface GetIbanListDataResponse {
  ibanList? : GetIbanResponseDataIbanList[];
  serviceOutput: ResponseWithErrors;
}

/**
 * Iban utils object with auxiliar functions
 */
export class IbanUtils {

  /**
   * Retrieves iban list for given user
   * @param {string | undefined} filiationCode
   * @returns {Promise<GetIbanResponse | null>}
   */
  @EAMethod()
  public static async getIbanList(filiationCode?: string): Promise<GetIbanResponse | null> {
    if (!filiationCode) {
      return null;
    }

    const api = new EAGetIbanApi();

    const getIbanApiResponse = await api.getIbanOperation({
      getIbanRequest: {
        codigoFiliacion: filiationCode,
      },
    });

    return getIbanApiResponse;
  }


  /**
   * Sets selected ibanCode if the indicadorCuentaPrincipal is true and no other ibanCode is selected
   *
   * @param {GetIbanResponseDataIbanList[]} ibanList
   * @param {QbIbanCodeModalModel} qbIbanCodeModalModel
   */
  public static selectMarkedAsPrincipalIban(
    ibanList: GetIbanResponseDataIbanList[],
    qbIbanCodeModalModel: QbIbanCodeModalModel
  ): void {
    const ibanCode = ibanList.find(iban => iban.indicadorCuentaPrincipal)?.codigoIBAN;
    qbIbanCodeModalModel.selectedIbanCode = ibanCode ? ibanCode : '';
  }

  /**
   * Add asterisks to received iban code and returns it
   * @param {string} ibanCode
   * @returns {string}
   */
  public static getIbanCodeWithAddedAsterisks(ibanCode: string): string {
    return `${ibanCode?.slice(0, 16)}******${ibanCode?.slice(22, 24)}`;
  }
}
