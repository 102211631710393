<template>
  <ea-row>
    <ea-col :span="24">
      <ea-card shadow="hidden" class="p-a-16 t-bg-color-primary-20">
        <div slot="cardbody">
          <!-- Titles -->
          <ea-row class="m-b-12">
            <ea-col :span="12">
              <ea-heading level="4">
                {{ $t('fleets.fleetsFlow.offerIssuanceDataView.offerIssuanceDataComponent.fleet.title') }}
              </ea-heading>
            </ea-col>
            <ea-col :span="12">
              <ea-heading level="4">
                {{ $t('fleets.fleetsFlow.offerIssuanceDataView.offerIssuanceDataComponent.fleet.holder.title') }}
              </ea-heading>
            </ea-col>
          </ea-row>

          <!-- Form -->
          <ea-form :model="componentData">
            <!-- Intermediary Name / Code -->
            <ea-row>
              <!-- Number of vehicles -->
              <ea-col :span="6">
                <ea-form-item :label="$t(
                  'fleets.fleetsFlow.offerIssuanceDataView.offerIssuanceDataComponent.fleet.summary.vehiclesNumber'
                )">
                  <ea-input-number readonly v-model="componentData.numberOfVehicles" />
                </ea-form-item>
              </ea-col>
              <!-- Dates -->
              <ea-col :span="6">
                <ea-form-item
                  :label="$t('fleets.fleetsFlow.offerIssuanceDataView.offerIssuanceDataComponent.fleet.summary.date')"
                >
                  <ea-input-text readonly v-model="componentData.dates" />
                </ea-form-item>
              </ea-col>
              <!-- Document number -->
              <ea-col :span="6">
                <ea-form-item :label="$t(
                  'fleets.fleetsFlow.offerIssuanceDataView.offerIssuanceDataComponent.fleet.holder.fiscalId'
                )">
                  <ea-input-text readonly :value="componentData.documentId" />
                </ea-form-item>
              </ea-col>
              <!-- Business name -->
              <ea-col :span="6" >
                <ea-form-item :label="$t(
                  'fleets.fleetsFlow.offerIssuanceDataView.offerIssuanceDataComponent.fleet.holder.socialReason'
                )">
                  <ea-input-text readonly v-model="componentData.businessName" />
                </ea-form-item>
              </ea-col>
            </ea-row>
          </ea-form>
        </div>
      </ea-card>
    </ea-col>
  </ea-row>
</template>

<script lang="ts">
import Vue from 'vue';
import {
  Component, Prop
} from 'vue-property-decorator';
import Utils from '../../../utils/utils';

interface IssueInfoFleetPolicyHolderData {
  numberOfVehicles: number;
  dates: string;
  documentId: string;
  businessName: string;
}

@Component({
  name: 'issue-info-fleet-policyholder',
})

/**
 * Presentational Component IssueInfoFleetPolicyHolder
 */
export default class IssueInfoFleetPolicyHolder extends Vue {

  @Prop({
    required: true
  })
    numberOfVehicles!: number;

  @Prop({
    required: true
  })
    startDate!: Date;

  @Prop({
    required: true
  })
    endDate!: Date;

  @Prop({
    required: true
  })
    documentId!: string;

  @Prop({
    required: true
  })
    businessName!: string;

  /**
   * Get data for the component form
   */
  get componentData(): IssueInfoFleetPolicyHolderData {
    return {
      numberOfVehicles: this.numberOfVehicles,
      dates: `${Utils.formatDateToString(this.startDate)} - ${Utils.formatDateToString(this.endDate)}`,
      documentId: this.documentId,
      businessName: this.businessName
    };
  }

}
</script>
