<template>
  <div>
    <ea-dialog
      :title="$t('fleets.fleetsFlow.fleetPackagesWarrantiesModal.title', {type: vehicleType})"
      id="packagesDialog"
      :visible="visible"
      :size= "modalSize"
      :closeOnClickModal="false"
      @close="onCloseModal"
    >
      <ea-alert
        v-if="!isReadonly && isAlertVisible"
        type="info"
        :title="$t('fleets.fleetsFlow.fleetPackagesWarrantiesModal.infoAlert')"
      />

      <p class="t-size-small m-b-16">{{ $t('fleets.fleetsFlow.fleetPackagesWarrantiesModal.subtitle') }}</p>

      <fleet-package-select
        ref="warrantiesPackageSelect"
        v-if="visible"
        id="packageSelect"
        v-model="model.fleetPackageSelectModel"
        :packagesList="packagesList"
        :selectedVehicles="selectedVehicles"
        :vehicleType="vehicleType"
        :isReadonly="isReadonly"
      ></fleet-package-select>
      
      <div v-if="isReadonly" slot="footer" class="d-display-flex d-justify-flex-end">
        <ea-button type="primary" @click="onCloseModal" class="m-r-16">
          {{ $t('common.label.accept') }}
        </ea-button>
      </div>
      <div v-else slot="footer" class="d-display-flex d-justify-flex-end">
        <ea-button type="secondary" @click="onCloseModal" class="m-r-16">
          {{ $t('fleets.fleetsFlow.fleetPackagesWarrantiesModal.cancelButton') }}
        </ea-button>
        <ea-button type="primary" @click="saveWarranties">
          {{ $t('fleets.fleetsFlow.fleetPackagesWarrantiesModal.saveButton') }}
        </ea-button>
      </div>
    </ea-dialog>
  </div>
</template>

<script lang="ts">
import {
  Component, Prop, Watch
} from 'vue-property-decorator';

import {
  mixins
} from 'vue-class-component';

import {
  EABusinessComponent
} from '@zurich-es-npm/ea-front-web-core';

import FleetPackagesWarrantiesModalModel from './fleet-packages-warranties-modal-model';
import FleetPackageSelectBusiness from '../fleet-package-select/fleet-package-select-business.vue';
import {
  GetWarrantyPackagesResponsePaquetes,
} from '@/services/V1/fleets/getWarrantyPackagesOperation/post/api';
import {
  GetGroupedVehicleListResponseListaVehiculos
} from '@/services/V1/fleets/getGroupedVehicleListOperation/post';

@Component({
  name: 'fleet-packages-warranties-modal',
  components: {
    'fleet-package-select': FleetPackageSelectBusiness
  }
})

/**
 * Business Component fleet-packages-warranties-modal
 */
export default class FleetPackagesWarrantiesModalBusiness extends
  mixins<EABusinessComponent<FleetPackagesWarrantiesModalModel>>(EABusinessComponent) {

  @Prop({
    required: false,
    'default': () => false,
  })
    visible?: boolean;

  @Prop({
    required: false,
    'default': () => '',
  })
    vehicleType!: string;

  @Prop({
    required: false,
    'default': () => [],
  })
    selectedVehicles!: GetGroupedVehicleListResponseListaVehiculos[];

  @Prop()
    packagesList?: GetWarrantyPackagesResponsePaquetes[];

  @Prop()
    isReadonly?: boolean;

  public modalSize: string = 'medium';

  /** 
   * Constructor
   */
  constructor() {
    super();
    this.modalSize = this.calcModalSize();
  }

  /**
   * Alert is visible for all vehicles types
   * Except for AUTOBUS, VEH. AGRICULAS, INDUSTRIALES
   */
  get isAlertVisible() {
    const excludedVehiclesTypeCodes = ['06', '07', '08'];
    return !this.selectedVehicles.find(vehicle => excludedVehiclesTypeCodes.includes(
      vehicle.codigoAgrupacionFlota as string));
  }


  /**
   * Adapt modal size to window width
   * @returns {string}
   */
  calcModalSize() {
    const windowWidth = window.innerWidth;
    if (windowWidth > 1500) {
      return 'large';
    } else {
      return 'extraLarge';
    }
  }

  /**
   * Should close the modal and reset the value of packageSelected
   */
  onCloseModal() {
    this.$emit('onCloseModal');
  }

  /**
   * Should close the modal and reset the value of packageSelected
   */
  async saveWarranties() {
    const warrantiesPackageSelect = this.$refs.warrantiesPackageSelect as FleetPackageSelectBusiness;
    try {
      await warrantiesPackageSelect.validateForms();
      this.update();
      this.$emit('onSaveWarranties');
    } catch (exception) {
      //To be defined by design when error validation form
    }
  }


  /**
   * Watcher on visiblity dialog prop
   */
  @Watch('visible', {
    immediate: true,
  })
  onVisibleChange() {
    this.modalSize = this.calcModalSize();
  }

}
</script>
