<template>
  <!-- Loader to multioperation tab when quoting (batch online) -->
  <div
    :id="loadingContainerId"
    v-loading="loading"
    :element-loading-text="$t('fleets.fleetsFlow.requoteFleet.loadingText')"
  >
  <ea-row>
    <ea-col>
      <fleet-competences-management-modal
        :fleetNumber="model.offerNumber"
        :hasCompetencesManagement="hasCompetencesManagement"
        :fleetHasPrice="fleetHasPrice"
        id="fleet-competences-management-modal"
        v-model="model.fleetCompetencesManagementModalModel"
      />
    </ea-col>
  </ea-row>

    <fleet-quote-payment-form
      id="fleet-quote-payment-form"
      :parentPaymentForm="model.fleetQuotePaymentFormModel.paymentForm"
      v-model="model.fleetQuotePaymentFormModel"
      :paymentFormOptions="model.flowConfiguration.quoteDataView.paymentFormOptions"
      :defaultPaymentFormOption="model.flowConfiguration.quoteDataView.defaultPaymentFormOption"
      :isReadonly="consultFlow"
      @updatePaymentForm="onUpdatePaymentForm"
    />

    <fleet-vehicle-groups-business
      id="fleet-vehicle-groups"
      ref="fleetVehicleGroups"
      v-model="model.fleetVehicleGroupsModel"
      :offerNumber="model.offerNumber"
      :offerVersion="model.offerVersion"
      :batchOnlineStatusList="model.batchOnlineStatusList"
      :paymentForm="model.fleetQuotePaymentFormModel.paymentForm"
      :isRequoted="isRequoted"
      :isReadonly="consultFlow"
      :limitPrizeTarification="model.flowConfiguration.quoteDataView.limitPrizeTarification"
      @hasRequote="onHasRequote"
      @limitPrizeExceed="onLimitPrizeExceed"
      @checkFleetComposition="onCheckFleetComposition"
      @fetchCompetencesManagement="getCompetencesManagement"
    />

    <ea-row extraClass="m-t-24">
      <ea-col class="d-display-flex d-justify-space-between d-align-items-center">
        <ea-button type="secondary" @click="onGoBack()">
          {{ $t('common.label.back') }}
        </ea-button>
        <ea-row>
          <ea-col class="d-display-flex d-justify-space-between d-align-items-center">
            <fleet-competences-management-modal
              :hasCompetencesManagement="hasCompetencesManagement"
              :fleetHasPrice="fleetHasPrice"
              id="fleet-competences-management-modal"
              v-model="model.fleetCompetencesManagementModalModel"
            />
            <ea-button
            type="primary"
            v-if="isNextVisible && !isRequoteVisible && !consultFlow"
            :disabled="!fleetHasPrice"
            @click="onGoNext()"
            class="m-l-16"
            >
            {{ $t('common.label.next') }}
          </ea-button>
          <ea-button v-if="isRequoteVisible && !consultFlow" type="warning" @click="requoteFleet()" class="m-l-16">
            {{ $t('fleets.fleetsFlow.requoteButton') }}
          </ea-button>
          </ea-col>
        </ea-row>
      </ea-col>
    </ea-row>
  </div>
</template>

<script lang="ts">
import {
  Component, Watch
} from 'vue-property-decorator';
import FleetQuotePaymentFormBusiness
  from '../../../business-components/fleet-quote-payment-form/fleet-quote-payment-form-business.vue';
import {
  mixins
} from 'vue-class-component';
import {
  EAApplicationError,
  EAApplicationLogger,
  EAError,
  EAErrorManager,
  EAMethod,
  EAView,
  ResponseWithErrors,
  throwIfResponseHasErrors,
} from '@zurich-es-npm/ea-front-web-core';
import {
  FleetsModel, FlowViewsStepsModel, FlowHeaderStepsModel, OperationTypeEnum
} from '../fleets-model';
import FleetVehicleGroupsBusiness from '@/business-components/fleet-vehicle-groups/fleet-vehicle-groups-business.vue';
import {
  NotificationsTypeEnum,
  NotificationsUtils
} from '@/utils/notifications/notifications-utils';
import {
  EAVehiclesRateBatchOnlineApi,
  VehiclesRateBatchOnlineRequest,
  VehiclesRateBatchOnlineResponseEstadoProcesoBatchOnlineEnum as statusTypes,
} from '@/services/V1/fleets/vehiclesRateBatchOnlineOperation/post';
import {
  GetGroupedVehicleListResponseListaAgrupaciones,
  GetGroupedVehicleListResponseListaVehiculos,
} from '@/services/V1/fleets/getGroupedVehicleListOperation/post';
import {
  PolicyBatchOnline
} from '@/types/batch-online/batch-online.types';
import {
  EASavePaymentFormApi
} from '@/services/V1/fleets/savePaymentFormOperation/post';
import {
  EAGetVehicleListFleetApi
} from '@/services/V1/fleets/getVehicleListFleetOperation/post';
import {
  EAGetFleetCompetencesManagementApi,
} from '@/services/V1/fleets/getFleetCompetencesManagementOperation/post';
import FleetCompetencesManagementModalBusiness
  from '@/business-components/fleet-competences-management-modal/fleet-competences-management-modal-business.vue';
import {
  CorpTableNames, fetchCorporateTable, parseCorpTableDocuments
} from '@/utils/corporate-tables';
import Utils from '@/utils/utils';


@Component({
  components: {
    'fleet-quote-payment-form': FleetQuotePaymentFormBusiness,
    'fleet-vehicle-groups-business': FleetVehicleGroupsBusiness,
    'fleet-competences-management-modal': FleetCompetencesManagementModalBusiness
  },
})

/**
 * Quote view
 *
 */
export default class QuoteView extends mixins<EAView<FleetsModel>>(EAView) {

  public PolicyBatchOnline = PolicyBatchOnline;

  vehicleRateApi: EAVehiclesRateBatchOnlineApi = new EAVehiclesRateBatchOnlineApi();
  
  hasCompetencesManagement: boolean = false;

  idBatchOnline: string = '';

  hasToRequote: boolean = false;

  exceededMaxPrize: boolean = false;

  timePerVehicle: number = 2000; // 2 Seconds per vehicle to initial comprobation

  timeToRetry: number = 5000; // 2 Seconds per vehicle to initial comprobation

  hasVehicleErros: boolean = false;

  loading = false; // Loader on multioperation tab

  loadingContainerId = 'loading-container-fleets-quote-view';

  flowId = ''; // Used to know if we are in the same flow when requtoe has finished

  isRequoted: boolean = false;

  /**
   * Hook created
   */
  @EAMethod({
    loading: true,
  })
  public async created() {
    this.flowId = NotificationsUtils.getCurrentFlowId();
    await this.getCorpTablesData();
  }


  /**
   * Hook mounted
   * 
   * It is necessary when come back for the second time
   */
  @EAMethod({
    loading: true,
  })
  public async mounted() {
    await this.getCompetencesManagement();
  }

  /**
   * Getter to map all agrupations vehicles in a single array
   */
  get allAgrupationsVehicles() {
    const vehicles: GetGroupedVehicleListResponseListaVehiculos[] = [];
    const groupList: GetGroupedVehicleListResponseListaAgrupaciones[] = this.model.fleetVehicleGroupsModel
      .fleetGroupedVehicleList;
    for (const group of groupList) {
      if (group.listaVehiculos) {
        vehicles.push(...group.listaVehiculos);
      }
    }
    return vehicles;
  }

  /**
   * Getter on rated fleet to disable next btn
   */
  get fleetHasPrice(): boolean {
    let hasPrice = false;
    if (this.model.fleetVehicleGroupsModel.fleetGroupedVehicleList.length) {
      // eslint-disable-next-line max-len
      hasPrice = !this.allAgrupationsVehicles
        ?.some(vehicle => vehicle.estadoPolizaBatch === PolicyBatchOnline.NotRate
                 || (vehicle.estadoPolizaBatch === PolicyBatchOnline.GDC && !vehicle.importeTotal));
    }
    return hasPrice;
  }

  /**
   * Getter to check pending vehicles
   */
  get hasPendingVehicles(): boolean {
    if (this.model.fleetVehicleGroupsModel?.fleetGroupedVehicleList) {
      for (const vehicle of this.allAgrupationsVehicles) {
        if (vehicle.estadoPolizaBatch === PolicyBatchOnline.NotRate ||
        vehicle.estadoPolizaBatch === PolicyBatchOnline.PendingRate) {
          return true;
        }
      }
    }
    return false;
  }

  /**
   * Getter on vehicles without package
   */
  get hasVehiclesWithoutPackage(): boolean {
    if (this.model.fleetVehicleGroupsModel?.fleetGroupedVehicleList) {
      for (const vehicle of this.allAgrupationsVehicles) {
        if (!vehicle.codigoPaquete) {
          return true;
        }
      }
    }
    return false;
  }

  /**
   * Getter for consult flow
   */
  get consultFlow() {
    return this.model.operationType === OperationTypeEnum.CONSULTA;
  }


  /**
   * In the first moment the default value for fleetHasPrice is false
   * when fleetGroupedVehicleList have elements change the value for fleetHasPrice
   * updateCompetencesCase is necessary to catch it
   *
   */
  @Watch('fleetHasPrice')
  async updateCompetencesCase() {
    await this.getCompetencesManagement();
  }

  /**
   * Watches `loading` changes and scrolls to the center of the page if loader is active.
   * @param {boolean} newValue 
   */
  @Watch('loading')
  async onLoadingStatusChange(newValue: boolean) {
    if (newValue) {
      await Utils.scrollToCenterOfElement(`#${this.loadingContainerId}`);
    }
  }

  /**
   * Get competences
   *
   */
  async getCompetencesManagement() {
    if (this.fleetHasPrice) {
      await this.fetchCompetencesManagement();
    }
  }


  /**
   * Check Fleet composition and if the difference between composition and
   * current number of vehicles is more than 3, force a requote.
   */
  @EAMethod({
    loading: true,
  })
  async onCheckFleetComposition(): Promise<void> {
    const MAX_ALLOWED_COMPOSITION_DIFF = 3;

    // Call to Fleet Info and vehicle List
    const fleetVehiclesAndInfoApi = new EAGetVehicleListFleetApi();
    const response = await fleetVehiclesAndInfoApi.getVehicleListFleetOperation({
      getVehicleListFleetRequest: {
        codigoFlota: this.model.offerNumber,
        versionFlota: this.model.offerVersion
      }
    });

    if (response) {
      throwIfResponseHasErrors(response as ResponseWithErrors);

      // Check composition and current num of vehicles
      const fleetComposition = response.flota?.numeroComposicionFlota || 0;
      const numberOfVehicles = response.lista?.length || 0;

      if (fleetComposition && Math.abs(fleetComposition - numberOfVehicles) > MAX_ALLOWED_COMPOSITION_DIFF) {
        // Warn user and force to requote
        this.hasToRequote = true;
        this.showFleetCompositionWarning();
      }
    }
  }

  /**
   * Show fleet composition change warning
   */
  showFleetCompositionWarning() {
    NotificationsUtils.throwWarning(
      `${this.$t('fleets.fleetsFlow.requoteFleet.compositionChangeWarning')}`,
      this.flowId
    );
  }

  /**
   * Goes to previous step
   */
  onGoBack() {
    EAErrorManager.clearError();
    NotificationsUtils.clearNotifications();

    this.model.operationType = this.model.operationType === OperationTypeEnum.CONSULTA ?
      this.model.operationType :
      OperationTypeEnum.MANTENIMIENTO;
    this.update();
    this.$emit('changeStep', FlowViewsStepsModel.VehiclesDataStep, FlowHeaderStepsModel.VehiclesDataStep);
  }

  /**
   * Update payment form on BFF and refresh model data
   * @param {string} paymentForm
   */
  @EAMethod({
    loading: true,
  })
  async onUpdatePaymentForm(paymentForm: string) {
    EAErrorManager.clearError();
    NotificationsUtils.clearNotifications();

    await this.updatePaymentFormOnBff(paymentForm);
    this.model.fleetQuotePaymentFormModel.paymentForm = paymentForm;

    const hasToRequote = true;
    const groupedVehiclesComp = this.$refs.fleetVehicleGroups as FleetVehicleGroupsBusiness;
    await groupedVehiclesComp.getVehicleList();
    this.onHasRequote(hasToRequote);

    // TODO: Should requote

    NotificationsUtils.throwSuccess(this.$t('fleets.fleetsFlow.fleetQuotePaymentForm.success').toString());
  }

  /**
   * Updates payment form on BFF
   * @param {string} paymentForm
   */
  async updatePaymentFormOnBff(paymentForm: string) {
    const paymentFormApi = new EASavePaymentFormApi();

    const savePaymentFormResponse = await paymentFormApi.savePaymentFormOperation({
      savePaymentFormRequest: {
        codigoFlota: this.model.offerNumber,
        versionFlota: this.model.offerVersion,
        valorCobertura: paymentForm,
      },
    });

    if (!savePaymentFormResponse) {
      throw new EAApplicationError('ZON00163');
    }

    throwIfResponseHasErrors(savePaymentFormResponse as ResponseWithErrors);
  }

  /**
   * Requote fleet
   */
  @EAMethod()
  async requoteFleet(): Promise<void> {
    NotificationsUtils.clearNotifications(this.flowId);
    EAErrorManager.clearError();

    this.loading = true; // To put loader into full tab
    
    const groupedVehiclesComp = this.$refs.fleetVehicleGroups as FleetVehicleGroupsBusiness;
    let timer = this.timeToRetry;
    if (!this.idBatchOnline) {
      let totalVehicles: GetGroupedVehicleListResponseListaVehiculos[] = [];
      this.model.fleetVehicleGroupsModel.fleetGroupedVehicleList.forEach(vehiclesGroup => {
        totalVehicles = totalVehicles.concat(totalVehicles, vehiclesGroup.listaVehiculos || []);
      });
      timer = this.timePerVehicle * totalVehicles.length;
    }
    const vehiclesRateBatchOnlineRequest: VehiclesRateBatchOnlineRequest = {
      codigoFlota: this.model.offerNumber,
      versionFlota: this.model.offerVersion,
      idProcesoBatchOnline: this.idBatchOnline,
    };

    try {
      const response = await this.vehicleRateApi.vehiclesRateBatchOnlineOperation({
        vehiclesRateBatchOnlineRequest
      });

      throwIfResponseHasErrors(response as unknown as ResponseWithErrors);
      
      this.idBatchOnline = response?.idProcesoBatchOnline as string;

      if (response?.estadoProcesoBatchOnline === statusTypes.Finalizado) {
        this.idBatchOnline = '';
        this.hasToRequote = false;
        this.isRequoted = true;
        this.onLimitPrizeExceed(false);
        await Promise.all([
          groupedVehiclesComp.getVehicleList()
        
        ]);
        this.getMessageRequote();
        this.loading = false;
      } else if (response?.estadoProcesoBatchOnline === statusTypes.EnCurso) {
        await this.sleep(timer);
        await this.requoteFleet();
      } else if (response?.estadoProcesoBatchOnline === statusTypes.Error) {
        this.hasToRequote = true;
        this.loading = false;

        // Top-right notification error
        this.showNotifyErrorMessageIfDifferentFlow();
      }
    } catch (err) {
      // In case of throw error, we must catch the error to close loader and then, throw again
      this.hasToRequote = true;
      this.loading = false;

      // Top-right notification error
      this.showNotifyErrorMessageIfDifferentFlow();

      throw err;
    }
  }

  /**
   * Fetches competences management list
   */
  @EAMethod({
    loading: true,
  })
  async fetchCompetencesManagement(): Promise<void> {
    const api = new EAGetFleetCompetencesManagementApi();
    const output = await api.getFleetCompetencesManagementOperation({
      getFleetCompetencesManagementRequest: {
        codigoFlota: this.model.offerNumber,
        versionFlota: this.model.offerVersion
      },
    });
    throwIfResponseHasErrors(output as ResponseWithErrors);
    this.hasCompetencesManagement = output?.gestionDeCompetencias as boolean;
    this.model.fleetCompetencesManagementModalModel.vehiculos = output?.vehiculos || [];
    this.showCompetencesManagementWarning();
  }

  /**
   * Throw competences management warning when fleet has price
   */
  showCompetencesManagementWarning() {
    if (this.hasCompetencesManagement) {
      NotificationsUtils.launchNotifications([{
        title: 'Proficiency',
        message: this.$t('fleets.fleetsFlow.requoteFleet.warning.message').toString(),
        type: NotificationsTypeEnum.Warning
      }], this.flowId);
    }
  }


  /**
   * Show top-right notification error if different current flow than initial flow
   */
  showNotifyErrorMessageIfDifferentFlow() {
    const isSame = NotificationsUtils.isCurrentFlowSameThan(this.flowId);
    if (!isSame) {
      this.showNotifyMessage('error');
    }
  }

  /**
   * Show overlayed notify message in the top right corner of the screen.
   * @param {'success' | 'warning' | 'error'} type 
   */
  showNotifyMessage(type: 'success' | 'warning' | 'error') {
    const title = `fleets.fleetsFlow.requoteFleet.requoteResultsTopRightNotifications.${type}.title`;
    const message = `fleets.fleetsFlow.requoteFleet.requoteResultsTopRightNotifications.${type}.message`;
    this.$eaNotify({
      title: `${this.$t(title)}`,
      message: `${this.$t(message)}`,
      duration: 0, // Infinite duration
      type
    });
  }

  /**
   * Handle next button availability
   * @returns {boolean}
   */
  hasVehiclesWithError(): boolean {
    if (!this.model.fleetVehicleGroupsModel?.fleetGroupedVehicleList) {
      return false;
    }
    const groupList: GetGroupedVehicleListResponseListaAgrupaciones[] = this.model.fleetVehicleGroupsModel
      .fleetGroupedVehicleList;
    for (const group of groupList) {
      if (group.listaVehiculos) {
        for (const vehicle of group.listaVehiculos) {
          if (vehicle.estadoPolizaBatch === PolicyBatchOnline.Error) {
            return true;
          }
        }
      }
    }
    return false;
  }

  /**
   * Handle next button availability
   * @returns {boolean}
   */
  get isNextVisible(): boolean {
    if (this.hasVehiclesWithError() || this.hasToRequote || this.exceededMaxPrize) {
      return false;
    } else {
      return true;
    }
  }

  /**
   * Handle requote button availability
   * @returns {boolean}
   */
  get isRequoteVisible(): boolean {
    if (this.allAgrupationsVehicles.length) {
      if (this.hasPendingVehicles && !this.fleetHasPrice && !this.hasVehiclesWithoutPackage) {
        return true;
      }
      if (this.hasToRequote) {
        return true;
      }
    }
    return false;
  }


  /**
   * Show pcakges/warranties modal with needed information.
   * @param {boolean} hasToRequote
   */
  onHasRequote(hasToRequote: boolean) {
    let hasVehiclesWithoutPackage = false;
    for (const group of this.model.fleetVehicleGroupsModel.fleetGroupedVehicleList) {
      if (group.listaVehiculos) {
        for (const vehiculo of group.listaVehiculos) {
          if (!vehiculo.codigoPaquete) {
            hasVehiclesWithoutPackage = true;
          }
        }
      }
    }

    if (hasVehiclesWithoutPackage) {
      this.hasToRequote = false;
    } else {
      this.hasToRequote = hasToRequote;
    }
  }

  /**
   * Stores result from check if prize limit is sourpased
   * @param {boolean} exceededMaxPrize
   */
  onLimitPrizeExceed(exceededMaxPrize: boolean) {
    this.exceededMaxPrize = exceededMaxPrize;
  }

  /**
   * Hold app sleep
   * @param {number} ms
   */
  async sleep(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  /**
   * Goes to previous step
   */
  onGoNext() {
    NotificationsUtils.clearNotifications();
    EAErrorManager.clearError();
    this.model.fleetSearchPolicyHolderModel.indicadorCompromisoContrato = false;
    this.update();
    this.$emit('changeStep', FlowViewsStepsModel.GenerateDocumentationStep, FlowHeaderStepsModel.QuoteDataStep);
  }
  

  /**
   * Get message after requote.
   */
  getMessageRequote() {
    const errorCases: GetGroupedVehicleListResponseListaVehiculos[] = [];

    this.model.fleetVehicleGroupsModel.fleetGroupedVehicleList.forEach(group => {
      group.listaVehiculos?.forEach(item => {
        if (item.estadoPolizaBatch === PolicyBatchOnline.Error) {
          errorCases.push(item);
        }
      });
    });
  
    this.throwWarningOrSuccessMessages(errorCases);
 
    this.displayFloatingEANotifyIfNecessary(errorCases);
  }

  /**
   * Throw warning or success messages.
   * @param {GetGroupedVehicleListResponseListaVehiculos[]} errorCases 
   */
  throwWarningOrSuccessMessages(
    errorCases: GetGroupedVehicleListResponseListaVehiculos[]) {
    if (errorCases.length > 0) {
      NotificationsUtils.throwWarning(`${this.$t('fleets.fleetsFlow.requoteFleet.error.message')}`, this.flowId);
    }

    if (errorCases.length === 0) {
      NotificationsUtils.throwSuccess(`${this.$t('fleets.fleetsFlow.requoteFleet.success.message')}`, this.flowId);
    }
  }

  /**
   * Display eaNotify messages if user current flow is not this fleet flow.
   * @param {GetGroupedVehicleListResponseListaVehiculos[]} errorCases 
   * @param {string | undefined} initialFlowId
   */
  displayFloatingEANotifyIfNecessary(
    errorCases: GetGroupedVehicleListResponseListaVehiculos[],
  ) {
    if (!NotificationsUtils.isCurrentFlowSameThan(this.flowId)) {
      if (errorCases.length === 0) {
        // Top-right notification success
        this.showNotifyMessage('success');
      } else {
        // Top-right notification warning
        this.showNotifyMessage('warning');
      }
    }
  }

  /**
   * Fetch corporate tables to retrieve products names and parse data.
   */
  @EAMethod({
    loading: true,
  })
  async getCorpTablesData(): Promise<void> {
    try {
      const result = await fetchCorporateTable(CorpTableNames.BatchOnlineStatus);
      this.model.batchOnlineStatusList = parseCorpTableDocuments(result);
    } catch (error) {
      const eaError = error as EAError;
      new EAApplicationLogger().error(
        `QuoteView::getCorpTablesData:: fetch corporate table :: ${eaError.message}`
      );
      throw error;
    }
  }

}
</script>
