import QbRiskSituationModel from '../qb-risk-situation/qb-risk-situation-model';
import QbTechnicalConsiderationsModel from '../qb-technical-considerations/qb-technical-considerations-model';

export interface LabelValue {
  label?: string;
  value?: string;
}

export interface MinMaxValue {
  valorMinimoElemento?: number;
  valorMaximoElemento?: number;
}

/**
 * Model qb-producto-asegurado-comunidades
 */
class QbProductoAseguradoComunidadesModel {
  
  public warranty?: string = '';
  
  public FIELD_TYPES = {
    NUMBER: 'number',
    STRING: 'string',
    DEFAULT: 'default'
  };
  
  // Property Data Form
  public technicalConsiderations: QbTechnicalConsiderationsModel = new QbTechnicalConsiderationsModel();

  public riskSituation: QbRiskSituationModel = new QbRiskSituationModel();

  public riskExtraInfo?: string = '';
  
  // Remodeling Years Form
  public constructionYear?: number = 0;

  public remodelingYear?: number = 0;

  public deckYear?: number = 0;

  public electricityYear?: number = 0;
  
  public waterYear?: number = 0;
  
  public comunitaryWater?: string = '';

  // General Features
  public ownerType?: string = '';

  public realEstateDestination?: string = '';

  public REAL_STATE_PARKING_DESTINATION_VALUE = 'GR';

  public parkingSpacesNumber?: number = 0;

  public buildingType?: string = '';

  public buildingNumber?: number = 0;

  public blockStairNumber?: number = 0;

  public livingPlaceNumber?: number = 0;

  public elevatorNumber?: number = 0;

  public buildingPlant?: number = 0;

  public groundFloor?: number = 0;

  public communityWaterFacilities?: string = '';

  public businessPremisesActivity?: string = '';

  public ecologicalCertification?: string = '';

  public ecologicalPolicy?: string = '';

  public fico?: string = '';

  // Construction Materials

  public edification?: string = '';

  public glazedFacades?: string = '';

  public downspoutPipes?: string = '';

  public quality?: string = '';

  public sandwichPanels?: boolean = false;

  // Surface Form
  public propertySize?: number = 0;

  public backyardSize?: number = 0;

  public garageSize?: number = 0;

  public propertyTotalSize?: number = 0;

  public moreThan25InCommerce?: string = '';

  // Chemical products storage
  public productType?: string = '';

  public liters?: number = 0;

  public NO_CHEMICAL_PRODUCTS_STORAGE_VALUE = '0';

  // Complementary facilities

  public sportsRecreational?: boolean = false;

  public swimmingPool?: boolean = false;

  public gym?: boolean = false;

  public socialClub?: boolean = false;

  public solarPanels?: boolean = false;

  public electricVehicleChargingPoints?: boolean = false;

  // Complementary facilities

  public fireExtinguishers?: boolean = false;

  public fireHydrants?: boolean = false;

  public sprinklers?: boolean = false;

  public smokeDetectors?: boolean = false;

  public moisturizingColumns?: boolean = false;

  public fireStairs?: boolean = false;

  // Complementary facilities

  public securityCamera?: boolean = false;

  public permanentSurveillance?: boolean = false;

  public warrantyGroupList?: LabelValue[] = [];

  public bffCodesFrontRelation = [
    {
      bffCode: 'DUCONTRA',
      frontCode: 'quoteBuyGenericFlow.newOffer.duration'
    },
    {
      bffCode: 'TCINFISC',
      frontCode: 'quoteBuyGenericFlow.searchPerson.field.taxTreatmentArea'
    },
    {
      bffCode: 'TCCANCO1',
      frontCode: 'quoteBuyGenericFlow.newOffer.paymentChannel'
    },
    {
      bffCode: 'FEEFTOMO',
      frontCode: 'quoteBuyGenericFlow.newOffer.efectMovementDate'
    },
    {
      bffCode: 'FEEFTONA',
      frontCode: 'quoteBuyGenericFlow.newOffer.efectMovementDate'
    },
    {
      bffCode: 'FEINIPOL',
      frontCode: 'quoteBuyGenericFlow.newOffer.efectMovementDate'
    },
    {
      bffCode: 'FEFINEFE',
      frontCode: 'quoteBuyGenericFlow.newOffer.movementDue'
    },
    {
      bffCode: 'FEVENCIM',
      frontCode: 'quoteBuyGenericFlow.newOffer.movementDue'
    },
    {
      bffCode: 'INIDIOMA',
      frontCode: 'quoteBuyGenericFlow.newOffer.documentationLanguage'
    },
    {
      bffCode: 'CDPAIS',
      frontCode: 'quoteBuyGenericFlow.propertyData.field.country'
    },
    {
      bffCode: 'TCCOPOST',
      frontCode: 'quoteBuyGenericFlow.searchPerson.field.zipCode'
    },
    {
      bffCode: 'NOPOBLSR',
      frontCode: 'quoteBuyGenericFlow.propertyData.field.city'
    },
    {
      bffCode: 'CDPOBLA',
      frontCode: 'quoteBuyGenericFlow.propertyData.field.city'
    },
    {
      bffCode: 'DSPOBTOT',
      frontCode: 'quoteBuyGenericFlow.propertyData.field.city'
    },
    {
      bffCode: 'TCPROVIN',
      frontCode: 'quoteBuyGenericFlow.propertyData.field.provinceName'
    },
    {
      bffCode: 'DSPROVIN',
      frontCode: 'quoteBuyGenericFlow.propertyData.field.provinceName'
    },
    {
      bffCode: 'CDDIRESR',
      frontCode: 'quoteBuyGenericFlow.propertyData.field.roadType'
    },
    {
      bffCode: 'NODIRESR',
      frontCode: 'quoteBuyGenericFlow.propertyData.field.roadName'
    },
    {
      bffCode: 'NUDIRESR',
      frontCode: 'quoteBuyGenericFlow.propertyData.field.roadNumber'
    },
    {
      bffCode: 'VAACCDSR',
      frontCode: 'quoteBuyGenericFlow.propertyData.field.extraInfo'
    },
    {
      bffCode: 'TXDESOB1',
      frontCode: 'quoteBuyGenericFlow.propertyData.field.riskExtraInfo'
    },
    {
      bffCode: 'TXDESOB2',
      frontCode: 'quoteBuyGenericFlow.propertyData.field.riskExtraInfo'
    },
    {
      bffCode: 'AACONSTR',
      frontCode: 'quoteBuyGenericFlow.generalCharacteristics.field.constructionYear'
    },
    {
      bffCode: 'AAREFOFA',
      frontCode: 'quoteBuyGenericFlow.generalCharacteristics.field.remodelingYear'
    },
    {
      bffCode: 'AAREFOCU',
      frontCode: 'quoteBuyGenericFlow.generalCharacteristics.field.deckYear'
    },
    {
      bffCode: 'AAREFOEL',
      frontCode: 'quoteBuyGenericFlow.generalCharacteristics.field.electricityYear'
    },
    {
      bffCode: 'AAREFOAG',
      frontCode: 'quoteBuyGenericFlow.generalCharacteristics.field.waterYear'
    },
    {
      bffCode: 'CDNIREAG',
      frontCode: 'quoteBuyGenericFlow.generalCharacteristics.field.comunitaryWater'
    },
    {
      bffCode: 'TCAGUACO',
      frontCode: 'quoteBuyGenericFlow.generalCharacteristics.field.communityWaterFacilities'
    },
    {
      bffCode: 'TCTIPROC',
      frontCode: 'quoteBuyGenericFlow.generalCharacteristics.field.ownerType'
    },
    {
      bffCode: 'TCUSOINM',
      frontCode: 'quoteBuyGenericFlow.generalCharacteristics.field.realEstateDestination'
    },
    {
      bffCode: 'CDACLOCO',
      frontCode: 'quoteBuyGenericFlow.generalCharacteristics.field.businessPremisesActivity'
    },
    {
      bffCode: 'NUEDIFIC',
      frontCode: 'quoteBuyGenericFlow.generalCharacteristics.field.buildingNumber'
    },
    {
      bffCode: 'NUESCBLO',
      frontCode: 'quoteBuyGenericFlow.generalCharacteristics.field.blockStairNumber'
    },
    {
      bffCode: 'NUVIVIEN',
      frontCode: 'quoteBuyGenericFlow.generalCharacteristics.field.livingPlaceNumber'
    },
    {
      bffCode: 'NUASCTOT',
      frontCode: 'quoteBuyGenericFlow.generalCharacteristics.field.elevatorNumber'
    },
    {
      bffCode: 'NUALTEDI',
      frontCode: 'quoteBuyGenericFlow.generalCharacteristics.field.buildingPlant'
    },
    {
      bffCode: 'NUALTBRA',
      frontCode: 'quoteBuyGenericFlow.generalCharacteristics.field.groundFloor'
    },
    {
      bffCode: 'INCOFICO',
      frontCode: 'quoteBuyGenericFlow.generalCharacteristics.field.fico'
    },
    {
      bffCode: 'CDNIVCEC',
      frontCode: 'quoteBuyGenericFlow.generalCharacteristics.field.ecologicalCertification'
    },
    {
      bffCode: 'CDPOLECO',
      frontCode: 'quoteBuyGenericFlow.generalCharacteristics.field.ecologicalPolicy'
    },
    {
      bffCode: 'NUPLAGAR',
      frontCode: 'quoteBuyGenericFlow.generalCharacteristics.field.parkingSpacesNumber'
    },
    {
      bffCode: 'NUSUPINM',
      frontCode: 'quoteBuyGenericFlow.surface.field.propertySize'
    },
    {
      bffCode: 'NUSUPJAR',
      frontCode: 'quoteBuyGenericFlow.surface.field.backyardSize'
    },
    {
      bffCode: 'NUSUPGAR',
      frontCode: 'quoteBuyGenericFlow.surface.field.garageSize'
    },
    {
      bffCode: 'NUSUPTOT',
      frontCode: 'quoteBuyGenericFlow.surface.field.propertyTotalSize'
    },
    {
      bffCode: 'INCOMS25',
      frontCode: 'quoteBuyGenericFlow.surface.field.moreThan25InCommerce'
    },
    {
      bffCode: 'CDMATCON',
      frontCode: 'quoteBuyGenericFlow.constructionMaterials.field.edification'
    },
    {
      bffCode: 'CDFACRIS',
      frontCode: 'quoteBuyGenericFlow.constructionMaterials.field.glazedFacades'
    },
    {
      bffCode: 'CDCALINM',
      frontCode: 'quoteBuyGenericFlow.constructionMaterials.field.quality'
    },
    {
      bffCode: 'CDMATTUB',
      frontCode: 'quoteBuyGenericFlow.constructionMaterials.field.downspoutPipes'
    },
    {
      bffCode: 'INPANSAN',
      frontCode: 'quoteBuyGenericFlow.constructionMaterials.field.sandwichPanels'
    },
    {
      bffCode: 'TCPROQUI',
      frontCode: 'quoteBuyGenericFlow.chemicalProductsStorage.field.productType'
    },
    {
      bffCode: 'NULITROS',
      frontCode: 'quoteBuyGenericFlow.chemicalProductsStorage.field.liters'
    },
    {
      bffCode: 'INDEPREC',
      frontCode: 'quoteBuyGenericFlow.complementaryFacilities.field.linkedSportsRecreational'
    },
    {
      bffCode: 'INPISCIN',
      frontCode: 'quoteBuyGenericFlow.complementaryFacilities.field.swimmingPool'
    },
    {
      bffCode: 'INGIMNAS',
      frontCode: 'quoteBuyGenericFlow.complementaryFacilities.field.gym'
    },
    {
      bffCode: 'INLOCSOC',
      frontCode: 'quoteBuyGenericFlow.complementaryFacilities.field.socialClub'
    },
    {
      bffCode: 'INDPLACS',
      frontCode: 'quoteBuyGenericFlow.complementaryFacilities.field.solarPanels'
    },
    {
      bffCode: 'INPUNCAR',
      frontCode: 'quoteBuyGenericFlow.complementaryFacilities.field.electricVehicleChargingPoints'
    },
    {
      bffCode: 'INEXTINT',
      frontCode: 'quoteBuyGenericFlow.fireFightingMeasures.field.fireExtinguishers'
    },
    {
      bffCode: 'INBOCINC',
      frontCode: 'quoteBuyGenericFlow.fireFightingMeasures.field.fireHydrants'
    },
    {
      bffCode: 'INROCIAD',
      frontCode: 'quoteBuyGenericFlow.fireFightingMeasures.field.sprinklers'
    },
    {
      bffCode: 'INDETECT',
      frontCode: 'quoteBuyGenericFlow.fireFightingMeasures.field.smokeDetectors'
    },
    {
      bffCode: 'INCOLHID',
      frontCode: 'quoteBuyGenericFlow.fireFightingMeasures.field.moisturizingColumns'
    },
    {
      bffCode: 'INESCINC',
      frontCode: 'quoteBuyGenericFlow.fireFightingMeasures.field.fireStairs'
    },
    {
      bffCode: 'INCAMSEG',
      frontCode: 'quoteBuyGenericFlow.securityMeasures.field.securityCamera'
    },
    {
      bffCode: 'INVIGPER',
      frontCode: 'quoteBuyGenericFlow.securityMeasures.field.permanentSurveillance'
    }
  ];
}

export default QbProductoAseguradoComunidadesModel;
