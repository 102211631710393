<template>
  <div>
    <div>
      <ea-card>
        <div slot="cardbody" class="p-a-24" v-if="isInitialLoadDone">
          <h2 class="m-b-16">{{$t('fleets.fleetsFlow.generateDocumentationView.cardTitle')}}</h2>
          <ea-row>
            <ea-col :span="12">
              <fleets-documentation-group-vehicle-totals
              id="fleetsDocumentationGroupVehicleTotals"
              :groupedVehicleList="model.fleetVehicleGroupsModel.fleetGroupedVehicleList"
              >
              </fleets-documentation-group-vehicle-totals>
            </ea-col>
            <ea-col :span="12">
              <qb-documentation-select-documentation
                id="selectDocumentation"
                v-model="model.documentationSelectDocumentationModel"
                :hideMultipleEmailInput="true"
                ref="selectDocumentationComp"
              ></qb-documentation-select-documentation>
            </ea-col>
          </ea-row>
          <qb-addresses-person
            id="addresses-person"
            v-model="tomadorPerson.addressesModel"
            :showClientData="true"
            :documentationClientData="tomadorDocumentationClientData"
            @refreshData="fetchParseAddressesData"
            @handleGenericError="handleGenericError"
            @showError="showError"
          >
          </qb-addresses-person>
        </div>
      </ea-card>

      <ea-row class="m-t-16">
        <ea-col :span="12" class="d-display-flex d-justify-flex-start">
          <ea-button type="secondary" @click="onGoBack" size="medium">
            {{ $t('common.label.back') }}
          </ea-button>
        </ea-col>
        <ea-col :span="12" class="d-display-flex d-justify-flex-end">
          <ea-button-group>
            <ea-button type="primary" @click="onDownloadAndSend" size="medium">
              {{ $t('fleets.fleetsFlow.generateDocumentationView.downloadAndSend') }}
            </ea-button>
          </ea-button-group>
        </ea-col>
      </ea-row>
    </div>
  </div>
</template>

<script lang="ts">
import {
  Component, Prop
} from 'vue-property-decorator';

import {
  mixins
} from 'vue-class-component';

import {
  EAView,
  EAMethod,
  ResponseWithErrors,
  throwIfResponseHasErrors,
  EAErrorManager
} from '@zurich-es-npm/ea-front-web-core';

import {
  FleetDocumentCodes,
  FleetsModel,
  FlowHeaderStepsModel,
  FlowViewsStepsModel,
} from '../fleets-model';
import {
  NotificationsUtils
} from '@/utils/notifications/notifications-utils';
import QbDocumentationSelectDocumentationBusiness
  from '@/business-components/qb-documentation-select-documentation/qb-documentation-select-documentation-business.vue';
import QbAddressesPersonBusiness from '@/business-components/addresses/qb-addresses-person.vue';
import {
  SetPersonAddressesRequestDomicilios
} from '@/services/V1/persons/setPersonAddressesOperation/post';
import {
  AddressUtils
} from '@/utils/address-utils';
import {
  Roles
} from '@/types/roles/roles-enum.types';
import PersonUtils from '@/utils/person-utils';
import {
  PersonRole
} from '@/utils/quote-buy-product-factory/types/product-role-types';
import {
  GenericErrorData
} from '@/business-components/qb-generic-error/qb-generic-error-business.vue';
import PermissionUtils from '@/utils/permissions-utils';
import {
  GetDefaultUserValuesResponseCodigoTipoUsuarioEnum as UserTypeEnum
} from '@/services/V1/home/getDefaultUserValuesOperation/post';
import FlowModelFillUtils from '../utils/flow-model-fill.utils';
import FleetsDocumentationGroupVehicleTotals from '../components/fleets-documentation-group-vehicle-totals.vue';
import {
  EAGenerateFleetOfferDocumentationApi
} from '../../../services/V1/fleets/generateFleetOfferDocumentationOperation/post';
import {
  GetDocumentationDataResponseListaDocumentos
} from '@/services/V1/quoteAndBuy/getDocumentationDataOperation/post';
import Utils from '@/utils/utils';
import {
  GetPersonAddressesResponse
} from '@/services/V1/persons/getPersonAddressesOperation/post';
import {
  DocumentationClientData
} from '@/presentational-components/clientBasicInfo/clientBasicInfo.type';

@Component({
  components: {
    QbDocumentationSelectDocumentation: QbDocumentationSelectDocumentationBusiness,
    QbAddressesPerson: QbAddressesPersonBusiness,
    FleetsDocumentationGroupVehicleTotals: FleetsDocumentationGroupVehicleTotals
  }
})

/**
 * GenerateDocumentationView view
 *
 */
export default class GenerateDocumentationView extends mixins<EAView<FleetsModel>>(EAView) {
  @Prop({
    required: true,
  })
    currentHeaderStep!: number;
  
  tomadorPerson!: PersonRole;

  isInitialLoadDone: boolean = false;

  /**
   * Hook on create
   */
  @EAMethod({
    loading: true,
  })
  async created() {
    this.tomadorPerson = PersonUtils.getPersonByRole(this.model.personsModel.personRoles, Roles.Tomador) as PersonRole;
    await Promise.all([
      FlowModelFillUtils.loadFleetData(this.model, true),
      FlowModelFillUtils.loadVehicleList(this.model)
    ]);

    await FlowModelFillUtils.loadPolicyHolderData(this.model);
    await this.fetchParseAddressesData();
    this.handleDocumentationAvailability();
    this.isInitialLoadDone = true;
  }


  /**
   * Go back button handler
   */
  onGoBack() {
    EAErrorManager.clearError();
    NotificationsUtils.clearNotifications();
  
    if (this.currentHeaderStep === FlowHeaderStepsModel.QuoteDataStep) {
      this.$emit('changeStep', FlowViewsStepsModel.QuoteDataStep, FlowHeaderStepsModel.QuoteDataStep);
    } else {
      this.$emit('changeStep', FlowViewsStepsModel.InterveningDataStep, FlowHeaderStepsModel.InterveningDataStep);
    }
  }

  /**
   * Calls auxiliar method to retrieve addresses data + parse it to model
   * @param {SetPersonAddressesRequestDomicilios | undefined} lastAddedAddress
   */
  @EAMethod({
    loading: true
  })
  public async fetchParseAddressesData(
    lastAddedAddress?: SetPersonAddressesRequestDomicilios
  ) {
    if (this.model.fleetSearchPolicyHolderModel.selectedJuridicalPerson.codigoFiliacion) {
      const fetchAddressesDataOutput = await this._fetchAddressesData(
        this.model.fleetSearchPolicyHolderModel.selectedJuridicalPerson.codigoFiliacion
      );
    
      if (fetchAddressesDataOutput) {
        this._parseFetchedAddressesToModel(fetchAddressesDataOutput);
        AddressUtils.selectMainAddresses(fetchAddressesDataOutput, this.tomadorPerson.addressesModel, lastAddedAddress);
      }
    }
  }

  /**
   * Calls bff and retrieves addresses
   * Throws error if any
   * @param {string} filiationCode
   * @returns {Promise<GetPersonAddressesResponse | null>}
   */
  public async _fetchAddressesData(filiationCode: string): Promise<GetPersonAddressesResponse | null> {
    const fetchAddressesDataOutput = await AddressUtils.fetchAddressesData(
      filiationCode
    );
    if (fetchAddressesDataOutput) {
      throwIfResponseHasErrors(fetchAddressesDataOutput as ResponseWithErrors);
    }
    return fetchAddressesDataOutput;
  }

  /**
   * Parses fetchAddressesDataOutput to 'tomador' person model
   * @param {GetPersonAddressesResponse} fetchAddressesDataOutput
   */
  public _parseFetchedAddressesToModel(
    fetchAddressesDataOutput: GetPersonAddressesResponse
  ): void {
    this.tomadorPerson.addressesModel = AddressUtils.addressesResponseToModel(
      fetchAddressesDataOutput, this.tomadorPerson.addressesModel
    );
  }

  /**
   * Method to set de documentation availability based on user type
   */
  handleDocumentationAvailability() {
    const userType = PermissionUtils.getUserType();
    const ipidDoc = this.findIpidDocument();
    const pptoDoc = this.findBudgetDocument();

    if (!ipidDoc || !pptoDoc) {
      return;
    }
    
    if (UserTypeEnum.AGE === userType) {
      ipidDoc.indicadorVisualizacion = true;
      ipidDoc.indicadorDocumentoBloqueado = true;
      pptoDoc.indicadorVisualizacion = true;
      pptoDoc.indicadorDocumentoBloqueado = false;
    } else {
      ipidDoc.indicadorVisualizacion = true;
      ipidDoc.indicadorDocumentoBloqueado = false;
      pptoDoc.indicadorVisualizacion = true;
      pptoDoc.indicadorDocumentoBloqueado = false;
    }
  
  }

  /**
   * Finds IPID document into `model.documentationSelectDocumentationModel`.
   *
   * @returns {GetDocumentationDataResponseListaDocumentos | undefined}
   */
  findIpidDocument(): GetDocumentationDataResponseListaDocumentos | undefined {
    return this.model.documentationSelectDocumentationModel.documentList
      .find(doc => doc.codigoDocumento === FleetDocumentCodes.IPID);
  }

  /**
   * Finds budget (presupuesto) document into `model.documentationSelectDocumentationModel`.
   *
   * @returns {GetDocumentationDataResponseListaDocumentos | undefined}
   */
  findBudgetDocument(): GetDocumentationDataResponseListaDocumentos | undefined {
    return this.model.documentationSelectDocumentationModel.documentList
      .find(doc => doc.codigoDocumento === FleetDocumentCodes.BUDGET);
  }

  /**
   * Download and send button handler
   */
  @EAMethod({
    loading: true,
  })
  async onDownloadAndSend() {
    EAErrorManager.clearError();
    NotificationsUtils.clearNotifications();

    await this.generateAndDownloadDocumentation();

    // Go to next step
    this.goToNextStep();
  }

  /**
   * Go to next step (Intervening) or back to QUOTE step (depending on user permissions)
   */
  goToNextStep() {
    if (this.userHasEmissionRights()) {
      this.$emit('changeStep', FlowViewsStepsModel.InterveningDataStep, FlowHeaderStepsModel.InterveningDataStep);
    } else {
      this.$emit('changeStep', FlowViewsStepsModel.QuoteDataStep, FlowHeaderStepsModel.QuoteDataStep);
    }
  }

  /**
   * Returns if user has permissions to emit fleets.
   * @returns {boolean}
   */
  userHasEmissionRights(): boolean {
    return PermissionUtils.hasAbility(PermissionUtils.getUserAbilities(), 'flotas-emision');
  }

  /**
   * Updates QbDocumentationSelectDocumentationBusiness model data to know the changes from View.
   */
  updateDocumentationSelectionData() {
    const selectDocumentationComp = this.$refs.selectDocumentationComp as QbDocumentationSelectDocumentationBusiness;
    selectDocumentationComp.update();
    this.update();
  }

  /**
   * Call to generate offer documentation service.
   */
  async generateAndDownloadDocumentation(): Promise<void> {
    // Updates model
    this.updateDocumentationSelectionData();

    // Call to generate and download
    const ipidDoc = this.findIpidDocument();
    const budgetDoc = this.findBudgetDocument();
    const api = new EAGenerateFleetOfferDocumentationApi();
    const generateDocumentationResult = await api.generateFleetOfferDocumentationOperation({
      generateFleetOfferDocumentationRequest: {
        codigoFlota: this.model.offerNumber,
        versionFlota: this.model.offerVersion,
        indicadorDescargaIpid: ipidDoc?.indicadorDocumentoMarcado || false,
        indicadorDescargaPresupuesto: budgetDoc?.indicadorDocumentoMarcado || false
      }
    });

    // If errors, show them in screen and stop the flow
    throwIfResponseHasErrors(generateDocumentationResult as ResponseWithErrors);
    
    // Download obtained documents
    Utils.downloadFiles(generateDocumentationResult?.documentos);
  }

  /**
   * Emits showError event
   * @param {GenericErrorData} genericErrorData
   */
  showError(genericErrorData: GenericErrorData) {
    this.$emit('showError', genericErrorData);
  }

  /**
   * Emits handleGenericError event
   * @param {any} args
   */
  handleGenericError(args: any) {
    const {
      error, errorCode
    } = args;
    this.$emit('handleGenericError', {
      error,
      errorCode,
    });
  }

  /**
   * Retrieves documentation client data model for person with 'tomador' role
   * @returns {DocumentationClientData}
   */
  public get tomadorDocumentationClientData(): DocumentationClientData {
    return PersonUtils.getDocumentationClientData(this.model.personsModel.personRoles, Roles.Tomador);
  }

}
</script>
