<!-- eslint-disable max-lines -->
<template>
  <div>
    <ea-row
      extraClass="m-t-16"
      v-for="(person, index) in personsToShow"
      :key="index"
      :label="$t('common.roles.' + person.role)"
      :disabled="true"
      :name="'common.roles.' + person.role"
    >
      <ea-col>
         <!-- The index is because in fleet flow we use another component for Tomador -->
        <qb-search-person
          v-model="model.personRoles[interveningFlow  ? index + 1: index]"
          :id="'search-person-qb-common.roles.' + person.role"
          :codigoRamo="codigoRamo"
          :taxTreatmentAreaTable="taxTreatmentAreaTable"
          :documentTypeList="documentTypeList"
          :countryList="countryList"
          :disabled="consultaOperation || person.disabled"
          :canBeChanged="person.canBeChanged"
          :ref="'ref-'+person.role"
          :consultaOperation="consultaOperation"
          :indicadorDataQuality="indicadorDataQuality"
          :addressesVisibility="getRoleAddressVisibility(person.role)"
          :documentationClientData="getDocumentationClientData(person.role)"
          :isPersonEqualToAnotherRole="isPersonEqualToAnotherRole(person.role)"
          :isIbanBlockVisible="isIbanBlockVisible(person.role)"
          :isIbanRequired="isIbanRequired(person.role)"
          :showNationalitySelector="showNationalitySelector"
          :interveningFlow="interveningFlow"
          :vehiclesList="vehiclesList"
          :showTaxTreatmentAreaField="showTaxTreatmentAreaField"
          :shouldShowAddresses="shouldShowAddresses"
          :showBillingField="showBillingField"
          :shouldShowNewSearchInShowPersonInfo="shouldShowNewSearchInShowPersonInfo"
          :updateGeneralDataParty="updateGeneralDataParty"
          :additionalElements="additionalElements"
          :policyElementsScore="policyElementsScore"
          :scoringFieldsInvalidated="model.scoringFieldsInvalidated"
          :axesorSearchDocumentTypes="axesorSearchDocumentTypes"
          @updateBilling="onUpdateBilling"
          @selectDriver="onSelectDriver"
          @personRoleEqualStatementRadioBtnChange="onPersonRoleEqualStatementRadioBtnChange"
          @selectPerson="onSelectPerson"
          @showError="onShowError"
          @handleGenericError="onHandleGenericError"
          @refreshAddressesData="onRefreshAddressesData"
          @refreshIbanData="onRefreshIbanData"
          @resetPerson="onResetPerson"
          @driversToDelete="onDriversToDelete"
          @isDriverIsNominated="onIsDriverIsNominated"
          @getDriverList="onGetDriverList"
        ></qb-search-person>
      </ea-col>
    </ea-row>
  </div>
</template>

<script lang="ts">
import {
  Component, Prop
} from 'vue-property-decorator';

import {
  mixins
} from 'vue-class-component';

import {
  EABusinessComponent, EAMethod, EAValidationError, ResponseWithErrors, throwIfResponseHasErrors
} from '@zurich-es-npm/ea-front-web-core';

import QbPersonsModel from './qb-persons-model';
import {
  GenericErrorData
} from '../qb-generic-error/qb-generic-error-business.vue';
import {
  GetPersonsResponseDatosPersona,
  GetPersonsResponseListaPersonasTipoDocumentoEnum as TipoDocumentoEnum
} from '@/services/V1/quoteAndBuy/getPersonsOperation/post';
import {
  PersonRole, SelectionInFormTabRole
} from '@/utils/quote-buy-product-factory/types/product-role-types';
import QbSearchPersonBusiness, {
  RefreshAddressesEventData,
  RefreshIbanEventData,
  SelectPersonEventData
} from '../qb-search-person/qb-search-person-business.vue';
import {
  GetGeneralDataResponseDataDatosCabeceraElementosPoliza,
  GetGeneralDataResponseDataDatosCabeceraTablaRestricciones,
  GetGeneralDataResponseDataDatosObjeto,
  GetGeneralDataResponseDataDatosPersonas
} from '@/services/V1/quoteAndBuy/getGeneralDataOperation/post';
import {
  ParsedTableData
} from '@/utils/corporate-tables';
import PersonUtils from '@/utils/person-utils';
import {
  AddressUtils
} from '@/utils/address-utils';
import QbAddressesPersonModel from '../addresses/qb-addresses-person-model';
import {
  Roles
} from '@/types/roles/roles-enum.types';
import {
  IbanUtils
} from '@/utils/iban-utils';
import QbIbanCodeModel from '../qb-iban-code/qb-iban-code-model';
import QbSearchPersonModel, {
  SelectDriverInterface
} from '../qb-search-person/qb-search-person-model';
import {
  EACompositeValidation, EAValidation
} from '@zurich-es-npm/ea-front-web-ui';
import {
  SetPersonAddressesRequestDomicilios
} from '@/services/V1/persons/setPersonAddressesOperation/post';
import {
  VehicleFormModel
} from '../fleet-manual-upload-vehicle/fleet-manual-upload-vehicle-model';
import {
  GetPersonAddressesResponse
} from '@/services/V1/persons/getPersonAddressesOperation/post/api';
import {
  DocumentationClientData
} from '@/presentational-components/clientBasicInfo/clientBasicInfo.type';
import {
  NotificationsUtils
} from '@/utils/notifications/notifications-utils';

export interface CheckPersonEqualityToOtherRolesResult {
  personCanBeSelected: boolean;
  rolePersonCopyFrom?: Roles;
  resetPagadorIfEqualAsegurado?: boolean;
}

@Component({
  name: 'qb-persons',
  components: {
    QbSearchPerson: QbSearchPersonBusiness,
  }
})

/**
 * Business Component qb-persons
 */
export default class QbPersonsBusiness extends mixins<EABusinessComponent<QbPersonsModel>>(EABusinessComponent) {
  
  @Prop()
    codigoRamo?: string;

  @Prop()
    taxTreatmentAreaTable?: GetGeneralDataResponseDataDatosCabeceraTablaRestricciones[];

  @Prop()
    documentTypeList?: ParsedTableData[];

  @Prop()
    countryList?: ParsedTableData[];

  @Prop()
    indicadorDataQuality?: boolean;

  @Prop({
    required: false,
    'default': () => false,
  })
    isIBANVisible?: boolean;

  @Prop({
    required: false,
    'default': () => false,
  })
    consultaOperation?: boolean;

  @Prop({
    required: false,
    'default': () => true,
  })
    shouldShowAddresses?: boolean;
  
  @Prop({
    'default': false
  })
    showNationalitySelector?: boolean;

  @Prop({
    'default': false
  })
    showBillingField?: boolean;

  @Prop({
    required: true
  })
    allowedRoles?: Roles[];

  @Prop({
    required: false,
    'default': () => true,
  })
    paymentChannelIsBank?: boolean;

  @Prop({
    required: false,
    'default': () => true,
  })
    shouldFetchPersonIbanAddresses?: boolean;

  @Prop()
    interveningFlow?: boolean;

  @Prop()
    vehiclesList?: VehicleFormModel[];

  @Prop({
    required: false,
    'default': () => true,
  })
    showTaxTreatmentAreaField?: boolean;
  
  @Prop({
    'default': () => true
  })
    shouldShowNewSearchInShowPersonInfo?: boolean;

  /**
   * Indicates if we want to update "birth date, sex and country" when editting a person
   *  This is because we don't want to update these fields until "offer issuance" step when policyType === 'Offer'
   */
  @Prop({
    'default': () => true
  })
    updateGeneralDataParty?: boolean;
    
  @Prop()
    additionalElements?: GetGeneralDataResponseDataDatosObjeto[];

  @Prop()
    policyElementsScore?: GetGeneralDataResponseDataDatosCabeceraElementosPoliza[];

  @Prop({
    'default': () => []
  })
    axesorSearchDocumentTypes?: DocumentType[];

  /**
   * Sets every person received in generalData response
   * @param {GetGeneralDataResponseDataDatosPersonas[]} personsData
   */
  public async setPersonsFromGeneralData(
    personsData: GetGeneralDataResponseDataDatosPersonas[]
  ) {
    await this.setPersonWithRoleFromGeneralData(personsData, Roles.Tomador);
    await this.setPersonWithRoleFromGeneralData(personsData, Roles.Asegurado);
    await this.setPersonWithRoleFromGeneralData(personsData, Roles.Pagador);

    this.update();
  }

  /**
   * Sets tax treatment area for person with "tomador" role
   * @param {string} taxTreatmentArea
   */
  public async setTomadorPersonTaxTreatmentArea(
    taxTreatmentArea: string
  ) {
    const tomador = this.model.personRoles.find(person => person.role === Roles.Tomador);

    if (!tomador) {
      return;
    }
    tomador.taxTreatmentArea = taxTreatmentArea;
    this.update();
  }

  /**
   * Sets iban default code for person with "pagador" role
   * @param {string} ibanCode
   */
  public async setPagadorPersonIbanCode(
    ibanCode: string
  ) {
    const pagador = this.model.personRoles.find(person => person.role === Roles.Pagador);

    if (!pagador) {
      return;
    }
    pagador.ibanCodeModel.qbIbanCodeModalModel.selectedIbanCode = ibanCode;
    this.update();
  }

  /**
   * Sets a person received in generalData response with role 
   * @param {GetGeneralDataResponseDataDatosPersonas[]} personsData
   * @param {Roles} role
   */
  public async setPersonWithRoleFromGeneralData(
    personsData: GetGeneralDataResponseDataDatosPersonas[],
    role: Roles
  ) {
    const personToSet = personsData.find(person => person.rolCliente?.toLowerCase() === role);
    if (personToSet) {
      await this.onSelectPerson({
        selectedPerson: PersonUtils.getSelectedPerson(personToSet),
        role: role,
        showEqualityErrorMessage: false
      }, false);
    }
  }

  /**
   * Handles person selection.
   * Performs corresponding validations on received person
   * @param {SelectPersonEventData} selectedPersonEventData
   * @param {boolean} isFromUserAction
   */
  @EAMethod({
    loading: true
  })
  async onSelectPerson(selectedPersonEventData: SelectPersonEventData, isFromUserAction = true) {
    const {
      selectedPerson, role, showEqualityErrorMessage, taxTreatmentArea, updateDocLanguageValue, billingValue
    } = selectedPersonEventData;

    if (isFromUserAction && role === Roles.Tomador) {
      this.model.scoringFieldsInvalidated = true;
    }

    const checkPersonEqualityResult = this.checkPersonEqualityToOtherRoles(selectedPerson, role);

    this.onResetPagadorIfEqualAsegurado(checkPersonEqualityResult.resetPagadorIfEqualAsegurado);

    if (checkPersonEqualityResult.personCanBeSelected) {
      await this.selectPersonWithRole(selectedPerson, role, taxTreatmentArea, updateDocLanguageValue, billingValue);

      /**
       * Update each person selectable iban list checking 'role equal' statements value
       */
      for (const person of this.model.personRoles) {
        this.updateSelectableIban(person);
      }

      this.update();
      this.$emit('personChanged');
      return;
    }
    
    if (showEqualityErrorMessage) {
      this.update(); //Needed to update the data for the button state
      const roleTranslatedLabel = this.$t(`common.roles.${ checkPersonEqualityResult.rolePersonCopyFrom}`).toString();
      const message = this.$t('common.label.validation.roleEqualsAnotherRole', {
        role: roleTranslatedLabel
      }).toString();

      this.$nextTick(() => {
        NotificationsUtils.throwWarning(message);
      });
    }
  }


  /**
   * Reset selectedPerson from Pagador in offer-issuance
   * when set first the pagador that the asegurado
   * and is the same person
   * @param {boolean} resetPagadorIfEqualAsegurado
   */
  onResetPagadorIfEqualAsegurado(resetPagadorIfEqualAsegurado?: boolean): void {
   
    if (resetPagadorIfEqualAsegurado) {
      const updatePersons = this.model.personRoles.map(person => {
        if (person.role === Roles.Pagador) {
          person.searchModel.selectedPerson = null;
        }
        return person;
      });

      this.model.personRoles = updatePersons;
      this.update();
    }
  }

  /**
   * Update the model if any driver change
   */
  onSelectDriver() {
    this.update();
    this.$emit('personChanged');
  }

  /**
   * Update the model for the billingValue
   */
  onUpdateBilling() {
    this.update();
  }

  /**
   * Checks if person changed is already selected with another role and triggers corresponding actions
   * @param {GetPersonsResponseDatosPersona} personToCheck
   * @param {string} personToCheckRole
   * @returns {CheckPersonEqualityToOtherRolesResult} - error message if any
   */
  checkPersonEqualityToOtherRoles(
    personToCheck: GetPersonsResponseDatosPersona, personToCheckRole: string
  ): CheckPersonEqualityToOtherRolesResult {
    this.clearFlowErrors();

    const result: CheckPersonEqualityToOtherRolesResult = {
      personCanBeSelected: true
    };
    
    const tomador = this.model.personRoles.find(person => person.role === Roles.Tomador);
    const asegurado = this.model.personRoles.find(person => person.role === Roles.Asegurado);
    const pagador = this.model.personRoles.find(person => person.role === Roles.Pagador);

    switch (personToCheckRole) {
      case Roles.Tomador:
        if (this.isSamePerson(personToCheck, asegurado)) {
          result.rolePersonCopyFrom = Roles.Asegurado;
          this.triggerTomadorEqualAseguradoChanges(asegurado, pagador);
        } else if (this.isSamePerson(personToCheck, pagador)) {
          result.rolePersonCopyFrom = Roles.Pagador;
          this.triggerTomadorEqualPagadorChanges(pagador);
        }
        break;
      case Roles.Asegurado:
        if (this.isSamePerson(personToCheck, tomador)) {
          result.rolePersonCopyFrom = Roles.Tomador;
          result.personCanBeSelected = false;
          this.triggerTomadorEqualAseguradoChanges(asegurado, pagador);
        } else if (this.isSamePerson(personToCheck, pagador)) {
          result.rolePersonCopyFrom = Roles.Pagador;
          result.resetPagadorIfEqualAsegurado = true;
          this.triggerAseguradoEqualPagadorChanges(pagador);
        } else {
          this.triggerAseguradoNotEqualAnyPersonChanges(asegurado);
        }
        break;
      case Roles.Pagador:
        if (this.isSamePerson(personToCheck, tomador)) {
          result.rolePersonCopyFrom = Roles.Tomador;
          result.personCanBeSelected = false;
          this.triggerTomadorEqualPagadorChanges(pagador);
        } else if (this.isSamePerson(personToCheck, asegurado)) {
          result.rolePersonCopyFrom = Roles.Asegurado;
          result.personCanBeSelected = false;
          this.triggerAseguradoEqualPagadorChanges(pagador);
        } else {
          this.triggerPagadorNotEqualAnyPersonChanges(asegurado, pagador);
        }
        break;
      default:
        break;
    }

    return result;
  }

  /**
   * Triggers when tomador equal asegurado AFTER SEARCHING A PERSON
   *  (function that triggers after touching a radiobutton ==> recalculateRoleEqualsStatements)
   * Makes corresponding changes to "role equal" statement
   * @param {PersonRole | undefined} asegurado
   * @param {PersonRole | undefined} pagador
   */
  triggerTomadorEqualAseguradoChanges(
    asegurado?: PersonRole, pagador?: PersonRole,
  ) {

    /**
     * Change "asegurado equal to tomador" statement value to true
     */
    const aseguradoEqualToTomadorStatement = asegurado?.roleEqualStatements?.find(
      roleEqualStatement => roleEqualStatement.roleCopyFrom === Roles.Tomador
    );

    if (aseguradoEqualToTomadorStatement) {
      aseguradoEqualToTomadorStatement.hidden = false;
      aseguradoEqualToTomadorStatement.value = true;
    }

    /**
     * If "pagador equal to asegurado":
     *  - Change "pagador equal to asegurado" statement value to false
     *  - Change "pagador equal to tomador" statement value to true
     */
    const pagadorEqualToAseguradoStatement = pagador?.roleEqualStatements?.find(
      roleEqualStatement => roleEqualStatement.roleCopyFrom === Roles.Asegurado
    );

    if (pagadorEqualToAseguradoStatement?.value) {
      pagadorEqualToAseguradoStatement.hidden = true;
      pagadorEqualToAseguradoStatement.value = false;

      const pagadorEqualToTomadorStatement = pagador?.roleEqualStatements?.find(
        roleEqualStatement => roleEqualStatement.roleCopyFrom === Roles.Tomador
      );

      if (pagadorEqualToTomadorStatement) {
        pagadorEqualToTomadorStatement.hidden = false;
        pagadorEqualToTomadorStatement.value = true;
      }
    }
  }

  /**
   * Triggers when tomador NOT equal asegurado AFTER SEARCHING A PERSON
   *  (function that triggers after touching a radiobutton ==> recalculateRoleEqualsStatements)
   * Makes corresponding changes to "role equal" statement
   * @param {PersonRole} asegurado 
   */
  triggerAseguradoNotEqualAnyPersonChanges(
    asegurado?: PersonRole
  ) {
    const aseguradoEqualToTomadorStatement = asegurado?.roleEqualStatements?.find(
      roleEqualStatement => roleEqualStatement.roleCopyFrom === Roles.Tomador
    );

    if (aseguradoEqualToTomadorStatement) {
      aseguradoEqualToTomadorStatement.hidden = false;
      aseguradoEqualToTomadorStatement.value = false;
    }
  }

  /**
   * Triggers when tomador equal pagador AFTER SEARCHING A PERSON
   *  (function that triggers after touching a radiobutton ==> recalculateRoleEqualsStatements)
   * Makes corresponding changes to "role equal" statements
   * @param {PersonRole | undefined} pagador
   * @param {PersonRole | undefined} asegurado
   */
  triggerTomadorEqualPagadorChanges(
    pagador?: PersonRole,
    asegurado?: PersonRole
  ) {

    /**
     * Change "pagador equal to tomador" statement value to true
     */
    const pagadorEqualToTomadorStatement = pagador?.roleEqualStatements?.find(
      roleEqualStatement => roleEqualStatement.roleCopyFrom === Roles.Tomador
    );
    const aseguradoEqualToTomadorStatement = asegurado?.roleEqualStatements?.find(
      roleEqualStatement => roleEqualStatement.roleCopyFrom === Roles.Tomador
    );

    if (pagadorEqualToTomadorStatement && !aseguradoEqualToTomadorStatement?.value) {
      pagadorEqualToTomadorStatement.hidden = false;
      pagadorEqualToTomadorStatement.value = true;
    }

    /**
     * Change "pagador equal to asegurado" statement value to false
     */
    const pagadorEqualToAseguradoStatement = pagador?.roleEqualStatements?.find(
      roleEqualStatement => roleEqualStatement.roleCopyFrom === Roles.Asegurado
    );

    if (pagadorEqualToAseguradoStatement) {
      pagadorEqualToAseguradoStatement.hidden = true;
      pagadorEqualToAseguradoStatement.value = false;
    }
  }
  

  /**
   * Triggers when asegurado equal pagador AFTER SEARCHING A PERSON
   *  (function that triggers after touching a radiobutton ==> recalculateRoleEqualsStatements)
   * Makes corresponding changes to "role equal" statements
   * @param {PersonRole | undefined} pagador
   */
  triggerAseguradoEqualPagadorChanges(
    pagador?: PersonRole
  ) {

    /**
     * Change "pagador equal to asegurado" statement value to true
     */
    const pagadorEqualToAseguradoStatement = pagador?.roleEqualStatements?.find(
      roleEqualStatement => roleEqualStatement.roleCopyFrom === Roles.Asegurado
    );

    if (pagadorEqualToAseguradoStatement) {
      pagadorEqualToAseguradoStatement.hidden = false;
      pagadorEqualToAseguradoStatement.value = true;
    }
    
    /**
     * Change "pagador equal to tomador" statement value to false
     */
    const pagadorEqualToTomadorStatement = pagador?.roleEqualStatements?.find(
      roleEqualStatement => roleEqualStatement.roleCopyFrom === Roles.Tomador
    );

    if (pagadorEqualToTomadorStatement) {
      pagadorEqualToTomadorStatement.hidden = false;
      pagadorEqualToTomadorStatement.value = false;
    }
  }

  /**
   * Triggers when pagador NOT equal any person AFTER SEARCHING A PERSON
   *  (function that triggers after touching a radiobutton ==> recalculateRoleEqualsStatements)
   * Makes corresponding changes to "role equal" statement
   * @param {PersonRole} asegurado 
   * @param {PersonRole} pagador 
   */
  triggerPagadorNotEqualAnyPersonChanges(
    asegurado?: PersonRole,
    pagador?: PersonRole
  ) {
    const aseguradoEqualToTomadorStatement = asegurado?.roleEqualStatements?.find(
      roleEqualStatement => roleEqualStatement.roleCopyFrom === Roles.Tomador
    );
    const pagadorEqualToTomadorStatement = pagador?.roleEqualStatements?.find(
      roleEqualStatement => roleEqualStatement.roleCopyFrom === Roles.Tomador
    );
    const pagadorEqualToAseguradoStatement = pagador?.roleEqualStatements?.find(
      roleEqualStatement => roleEqualStatement.roleCopyFrom === Roles.Asegurado
    );

    if (!pagadorEqualToTomadorStatement || !pagadorEqualToAseguradoStatement) {
      return;
    }

    pagadorEqualToTomadorStatement.value = false;
    pagadorEqualToTomadorStatement.hidden = false;
    pagadorEqualToAseguradoStatement.value = false;

    /**
     * Hide 'pagador equal to asegurado' statement if asegurado equals to tomador
     */
    if (aseguradoEqualToTomadorStatement?.value) {
      pagadorEqualToAseguradoStatement.hidden = true;
    } else {
      pagadorEqualToAseguradoStatement.hidden = false;
    }
  }

  /**
   * Checks if received persons are same person
   * @param {GetPersonsResponseDatosPersona} personA
   * @param {PersonRole} personB
   * @returns {boolean}
   */
  isSamePerson(
    personA: GetPersonsResponseDatosPersona,
    personB?: PersonRole,
  ): boolean {
    if (!personA || !personB) {
      return false;
    }

    return personA.datosBasicosPersona?.codigoFiliacion ===
      personB.searchModel.selectedPerson?.datosBasicosPersona?.codigoFiliacion;
  }

  /**
   * Sets received person for received role
   * @param {GetPersonsResponseDatosPersona} personToSelect
   * @param {Roles} role
   * @param {string | undefined} taxTreatmentArea
   * @param {string | undefined} updateDocLanguageValue
   * @param {number | undefined} billingValue
   */
  async selectPersonWithRole(
    personToSelect: GetPersonsResponseDatosPersona,
    role: Roles,
    taxTreatmentArea?: string,
    updateDocLanguageValue?: string,
    billingValue?: number
  ) {
    
    const updatedPersonRoles = await Promise.all(
      this.model.personRoles.map(async person => {
        if (person.role !== role) {
          return person;
        }
        
        const isNewPerson = person?.searchModel.selectedPerson?.datosBasicosPersona?.codigoFiliacion !==
          personToSelect?.datosBasicosPersona?.codigoFiliacion;

        // ### Save person data in model ###
        person.searchModel.selectedPerson = personToSelect;

        // ### Update tax treatment area ###
        if (taxTreatmentArea) {
          // Person recently added/edited
          person.taxTreatmentArea = taxTreatmentArea;
        }
        
        // ### Update policy language if tax treatment area changed to 'Andorra' ###
        if (updateDocLanguageValue) {
          this.$emit('updateDocLanguage', updateDocLanguageValue);
        }

        if (billingValue) {
          person.billing.billingValue = billingValue;
        }

        if (isNewPerson && this.shouldFetchPersonIbanAddresses) {
          // ### Fetch person addresses ###
          await this.refreshPersonAddresses(person);
            
          // ### Fetch person iban list ###
          await this.refreshPersonIbanList(person);
        }

        // ### Set nationality field ###
        person.addressesModel.selectNationality.selectedNationality =
          personToSelect.datosGeneralesPersona?.nacionalidadPersona || '';

        return person;
      })
    );
  
    this.model.personRoles = updatedPersonRoles;
    // Update the modal to continue the flow
    this.update();
  }


  /**
   * Refreshes given person addresses list
   * @param {PersonRole} personToRefresh
   * @param {SetPersonAddressesRequestDomicilios | undefined} lastAddedAddress
   */
  public async refreshPersonAddresses(
    personToRefresh: PersonRole,
    lastAddedAddress?: SetPersonAddressesRequestDomicilios
  ) {
    const fetchAddressesDataOutput = await this._fetchAddressesData(personToRefresh);
    
    if (fetchAddressesDataOutput) {
      this._parseFetchedAddressesToModel(personToRefresh, fetchAddressesDataOutput);
      AddressUtils.selectMainAddresses(fetchAddressesDataOutput, personToRefresh.addressesModel, lastAddedAddress);
    }
  }

  /**
   * Calls bff and retrieves addresses
   * Throws error if any
   * @param {PersonRole} personToRefresh
   * @returns {Promise<GetPersonAddressesResponse | null>}
   */
  public async _fetchAddressesData(personToRefresh: PersonRole): Promise<GetPersonAddressesResponse | null> {
    const fetchAddressesDataOutput = await AddressUtils.fetchAddressesData(
      personToRefresh.searchModel.selectedPerson?.datosBasicosPersona?.codigoFiliacion
    );
    if (fetchAddressesDataOutput) {
      throwIfResponseHasErrors(fetchAddressesDataOutput as ResponseWithErrors);
    }
    return fetchAddressesDataOutput;
  }

  /**
   * Parses fetchAddressesDataOutput to 'tomador' person model
   * @param {PersonRole} personToRefresh
   * @param {GetPersonAddressesResponse} fetchAddressesDataOutput
   */
  public _parseFetchedAddressesToModel(
    personToRefresh: PersonRole,
    fetchAddressesDataOutput: GetPersonAddressesResponse
  ): void {
    personToRefresh.addressesModel = AddressUtils.addressesResponseToModel(
      fetchAddressesDataOutput, personToRefresh.addressesModel
    );
  }

  /**
   * Refreshes given person iban list
   * @param {PersonRole} personToRefresh
   */
  public async refreshPersonIbanList(personToRefresh: PersonRole) {
    const ibanListOutput = await IbanUtils.getIbanList(
      personToRefresh.searchModel.selectedPerson?.datosBasicosPersona?.codigoFiliacion
    );

    if (ibanListOutput) {
      throwIfResponseHasErrors(ibanListOutput as ResponseWithErrors);
      if (ibanListOutput.data?.ibanList) {
        personToRefresh.ibanList = ibanListOutput.data.ibanList;
      }
      this.updateSelectableIban(personToRefresh);
    }
  }

  /**
   * Updates received person's selectable iban list based on 'role equal' statements values
   * @param {PersonRole} personToRefresh 
   */
  updateSelectableIban(personToRefresh: PersonRole) {
    PersonUtils.updateSelectableIban(
      this.model.personRoles, personToRefresh
    );
    if (
      !personToRefresh.ibanCodeModel.qbIbanCodeModalModel.selectedIbanCode ||
      !personToRefresh.ibanCodeModel.selectableIbanList.find(
        ibanElem => ibanElem.codigoIBAN === personToRefresh.ibanCodeModel.qbIbanCodeModalModel.selectedIbanCode
      )
    ) {

      /**
       * Selected iban code undefined or not listed in person's selectable iban list => Select main iban
       */
      IbanUtils.selectMarkedAsPrincipalIban(
        personToRefresh.ibanCodeModel.selectableIbanList,
        personToRefresh.ibanCodeModel.qbIbanCodeModalModel
      );
    }
  }

  /**
   * Gets address visibility for received role
   * @param {string} receivedRole
   * @returns {boolean}
   */
  public getRoleAddressVisibility(receivedRole: string): boolean {
    if (!this.shouldShowAddresses) {
      // Addresses are hidden by parent component => return false
      return false;
    }

    switch (receivedRole) {
      case Roles.Tomador:
        return this._getTomadorRoleAddressVisibility();
      case Roles.Asegurado:
        return this._getAseguradoRoleAddressVisibility();
      case Roles.Pagador:
        return this._getPagadorRoleAddressVisibility();
      default:
        return false;
    }
  }

  /**
   * Checks if nationality selector is visible for received person (documentType === NIE or Passport)
   * @param {PersonRole} person
   * @returns {boolean}
   */
  public isNationalitySelectorShown(person: PersonRole): boolean {
    const documentType = this.getDocumentationClientData(person.role).
      clientData?.datosBasicosPersona?.tipoDocumento;
    return documentType === TipoDocumentoEnum.R || documentType === TipoDocumentoEnum.P;
  }

  /**
   * Gets address visibility for tomador role
   * True if person is selected
   * @returns {boolean}
   */
  public _getTomadorRoleAddressVisibility(): boolean {
    const tomador = this.model.personRoles.find(person => person.role === Roles.Tomador);
    return !!tomador?.searchModel.selectedPerson;
  }

  /**
   * Gets address visibility for asegurado role
   * True if person is selected AND
   *  asegurado !== tomador && pagador !== tomador && asegurado === pagador 
   * @returns {boolean}
   */
  public _getAseguradoRoleAddressVisibility(): boolean {
    const asegurado = this.model.personRoles.find(person => person.role === Roles.Asegurado);

    if (!asegurado?.searchModel.selectedPerson) {
      // Person is not selected => Don't show address
      return false;
    }

    const aseguradoEqualToTomador = asegurado?.roleEqualStatements?.find(
      roleEqualStatement => roleEqualStatement.roleCopyFrom === Roles.Tomador
    )?.value;
    const pagador = this.model.personRoles.find(person => person.role === Roles.Pagador);
    const pagadorEqualToTomador = pagador?.roleEqualStatements?.find(
      roleEqualStatement => roleEqualStatement.roleCopyFrom === Roles.Tomador
    )?.value;
    const pagadorEqualToAsegurado = pagador?.roleEqualStatements?.find(
      roleEqualStatement => roleEqualStatement.roleCopyFrom === Roles.Asegurado
    )?.value;

    return !aseguradoEqualToTomador && !pagadorEqualToTomador && !!pagadorEqualToAsegurado;
  }

  /**
   * Gets address visibility for pagador role
   * True if person is selected AND
   *  pagador !== tomador && pagador !== asegurado
   * @returns {boolean}
   */
  public _getPagadorRoleAddressVisibility(): boolean {
    const pagador = this.model.personRoles.find(person => person.role === Roles.Pagador);

    if (!pagador?.searchModel.selectedPerson) {
      // Person is not selected => Don't show address
      return false;
    }

    const pagadorEqualToTomador = pagador?.roleEqualStatements?.find(
      roleEqualStatement => roleEqualStatement.roleCopyFrom === Roles.Tomador
    )?.value;
    const pagadorEqualToAsegurado = pagador?.roleEqualStatements?.find(
      roleEqualStatement => roleEqualStatement.roleCopyFrom === Roles.Asegurado
    )?.value;
    
    return !pagadorEqualToTomador && !pagadorEqualToAsegurado;
  }

  /**
   * Handles a change in a role equals statement (eg: tomador es igual a pagador)
   * @param {SelectionInFormTabRole} changedEqualStatement
   */
  public recalculateRoleEqualsStatements(changedEqualStatement: SelectionInFormTabRole): void {
    // ### Set necessary variables ### //
    const asegurado = this.model.personRoles.find(person => person.role === Roles.Asegurado);
    const pagador = this.model.personRoles.find(person => person.role === Roles.Pagador);
    
    const pagadorEqualToAsegurado = pagador?.roleEqualStatements?.find(
      roleEqualStatement => roleEqualStatement.roleCopyFrom === Roles.Asegurado
    );
    const pagadorEqualToTomador = pagador?.roleEqualStatements?.find(
      roleEqualStatement => roleEqualStatement.roleCopyFrom === Roles.Tomador
    );
    const aseguradoEqualToTomador = asegurado?.roleEqualStatements?.find(
      roleEqualStatement => roleEqualStatement.roleCopyFrom === Roles.Tomador
    );

    // ### Call corresponding auxiliar method ### //
    switch (changedEqualStatement.roleCopyTo) {
      case Roles.Asegurado:
        // Tomador equal asegurado statement changed
        this._recalculateTomadorEqualAseguradoStatementChanged(
          changedEqualStatement.value,
          pagadorEqualToTomador,
          pagadorEqualToAsegurado
        );
        break;
      case Roles.Pagador:
        if (changedEqualStatement.roleCopyFrom === Roles.Tomador) {
          // Tomador equal pagador statement changed
          this._recalculateTomadorEqualPagadorStatementChanged(
            changedEqualStatement.value,
            pagadorEqualToAsegurado,
            aseguradoEqualToTomador
          );
        }
        break;
      default:
        break;
    }

    /**
     * If "Yes" option selected => Reset person model
     */
    if (changedEqualStatement.value && changedEqualStatement.roleCopyTo) {
      this.resetPerson(changedEqualStatement.roleCopyTo);
    }
  }

  /**
   * Triggers when "tomador igual a asegurado" statement value changes
   * @param {boolean} newValue 
   * @param {SelectionInFormTabRole | undefined} pagadorEqualToTomador 
   * @param {SelectionInFormTabRole | undefined} pagadorEqualToAsegurado 
   */
  public _recalculateTomadorEqualAseguradoStatementChanged(
    newValue: boolean,
    pagadorEqualToTomador?: SelectionInFormTabRole,
    pagadorEqualToAsegurado?: SelectionInFormTabRole
  ) {

    if (!pagadorEqualToAsegurado || !pagadorEqualToTomador) {
      return;
    }

    /**
     * If asegurado equals to tomador:
     */
    if (newValue) {
      if (pagadorEqualToAsegurado.value) {

        /**
         * Pagador equals to asegurado => pagador must be equal to tomador
         */
        pagadorEqualToTomador.hidden = false;
        pagadorEqualToTomador.value = true;
      }

      // "pagador equal to asegurado" must be hidden and false
      pagadorEqualToAsegurado.hidden = true;
      pagadorEqualToAsegurado.value = false;
    } else if (!pagadorEqualToTomador.value) {

      /**
       * Pagador IS NOT equal to asegurado => "pagador equal to asegurado" must NOT be hidden
       */
      pagadorEqualToAsegurado.hidden = false;
    }
  }

  /**
   * Triggers when "tomador igual a pagador" statement value changes
   * @param {boolean} newValue 
   * @param {SelectionInFormTabRole | undefined} pagadorEqualToAsegurado 
   * @param {SelectionInFormTabRole | undefined} aseguradoEqualToTomador 
   */
  public _recalculateTomadorEqualPagadorStatementChanged(
    newValue: boolean,
    pagadorEqualToAsegurado?: SelectionInFormTabRole,
    aseguradoEqualToTomador?: SelectionInFormTabRole
  ) {
    if (!pagadorEqualToAsegurado) {
      return;
    }

    if (newValue) {
      // Tomador IS equal to pagador => hide "pagador equal asegurado" statement & change its value to false
      this.hidePagadorEqualToAseguradoStatement(pagadorEqualToAsegurado);
    } else if (aseguradoEqualToTomador?.value) {

      /**
       * Tomador IS NOT equal to pagador && Asegurado IS equal to tomador =>
       *  hide "pagador equal asegurado" statement & change its value to false
       */
      this.hidePagadorEqualToAseguradoStatement(pagadorEqualToAsegurado);

    } else {

      /**
       * Tomador IS NOT equal to pagador && Asegurado IS NOT equal to tomador =>
       *  show "pagador equal asegurado" statement & change its value to true
       */
      
      pagadorEqualToAsegurado.hidden = false;
      pagadorEqualToAsegurado.value = true;
    }
  }

  /**
   * Hides "pagador equal asegurado" statement & change its value to false
   * @param {SelectionInFormTabRole} pagadorEqualToAsegurado
   */
  hidePagadorEqualToAseguradoStatement(pagadorEqualToAsegurado: SelectionInFormTabRole) {
    pagadorEqualToAsegurado.hidden = true;
    pagadorEqualToAsegurado.value = false;
  }

  /**
   * Retrieves client data for received role
   * This information is used in the header of address and iban modals
   * @param {Roles} role
   * @returns {DocumentationClientData}
   */
  getDocumentationClientData(role: Roles): DocumentationClientData {
    return PersonUtils.getDocumentationClientData(this.model.personRoles, role);
  }


  // ### EVENT RECEIVERS ### //

  /**
   * Handles equalStatementRadioBtnChange event
   * Recalculates "role equals" statements + updates selectable iban list
   * @param {SelectionInFormTabRole | undefined} changedEqualStatement
   */
  onPersonRoleEqualStatementRadioBtnChange(changedEqualStatement?: SelectionInFormTabRole) {
    if (changedEqualStatement) {
      this.recalculateRoleEqualsStatements(changedEqualStatement);
      
      /**
       * Update each person selectable iban list checking 'role equal' statements value
       */
      for (const person of this.model.personRoles) {
        this.updateSelectableIban(person);
      }
    }
    
    this.update();
    this.$emit('personChanged');
  }

  /**
   * Handles refreshAddressData event
   * Refreshes addresses information
   * @param {RefreshAddressesEventData} refreshAddressesEventData
   */
  async onRefreshAddressesData(refreshAddressesEventData: RefreshAddressesEventData) {

    /**
     * Find which person do we have to update considering 'role equal' statements
     */
    const personRoleToRefresh = PersonUtils.getPersonByRole(
      this.model.personRoles, refreshAddressesEventData.role
    )?.role || refreshAddressesEventData.role;

    /**
     * Using Promise.all + Array.map ensures personWithRole will be
     * updated inside personRoles array
     */
    const updatedPersonRoles = await Promise.all(
      this.model.personRoles.map(async personWithRole => {
        if (personWithRole.role === personRoleToRefresh) {
          await this.refreshPersonAddresses(personWithRole, refreshAddressesEventData.lastAddedAddress);
        }
        return personWithRole;
      })
    );
    this.model.personRoles = updatedPersonRoles;
  }

  /**
   * Handles refreshIbanData event
   * Refreshes iban list for person with received role
   * @param {RefreshIbanEventData} refreshIbanEventData
   */
  @EAMethod({
    loading: true
  })
  async onRefreshIbanData(refreshIbanEventData: RefreshIbanEventData) {

    /**
     * Find which person do we have to update considering 'role equal' statements
     */
    const personRoleToRefresh = PersonUtils.getPersonByRole(this.model.personRoles, refreshIbanEventData.role);

    /**
     * Using Promise.all + Array.map ensures personWithRole will be
     * updated inside personRoles array
     */
    const updatedPersonRoles = await Promise.all(
      this.model.personRoles.map(async personWithRole => {
        if (personWithRole.role === refreshIbanEventData.role && personRoleToRefresh) {

          /**
           * Update iban list for person to copy from. eg:
           *  if 'pagador' equals 'tomador' => updates tomador
           *  if 'pagador' not equal to any role => updates pagador
           */
          await this.refreshPersonIbanList(personRoleToRefresh);

          /**
           * Update selectable iban list for received person. eg:
           *  if 'pagador' equals 'tomador' => updates pagador selectable iban list with tomador iban list
           *  if 'pagador' not equal to any role => updates pagador selectable iban list with its own iban list
           */
          this.updateSelectableIban(personWithRole);
        }
        
        personWithRole.ibanCodeModel.qbIbanCodeModalModel.selectedIbanCode =
          IbanUtils.getIbanCodeWithAddedAsterisks(refreshIbanEventData.lastAddedIban);
        return personWithRole;
      })
    );
    this.model.personRoles = updatedPersonRoles;
  }

  /**
   * Fetches every person addresses + iban list
   */
  async fetchPersonIbanAddresses() {
    const updatedPersonRoles = [];
    
    for (const personWithRole of this.model.personRoles) {
      await this.refreshPersonAddresses(personWithRole);
      await this.refreshPersonIbanList(personWithRole);
      this.updateSelectableIban(personWithRole);
      updatedPersonRoles.push(personWithRole);
    }
    
    this.model.personRoles = updatedPersonRoles;
    this.update();
  }

  /**
   * Returns if person with received role is equal to another person
   * @param {Roles} role
   * @return {boolean}
   */
  isPersonEqualToAnotherRole(role: Roles): boolean {
    return !!this.searchPersonEqualToAnotherRole(role);
  }

  /**
   * Search if person with received role is equal to another person - RETURNS ROLE FROM EQUAL PERSON IF FOUND
   * @param {Roles} role
   * @return {Roles | undefined}
   */
  searchPersonEqualToAnotherRole(role: Roles): Roles | undefined {
    const personRoleEqualStatements = this.model.personRoles.find(person => person.role === role)?.roleEqualStatements;

    if (!personRoleEqualStatements) {
      return;
    }

    let rdo;
    personRoleEqualStatements.forEach(roleEqStatement => {
      if (roleEqStatement.value && !roleEqStatement.hidden) {
        rdo = roleEqStatement.roleCopyFrom;
      }
    });
    return rdo;
  }

  /**
   * Retrieves if iban block is visible for person with retrieved role
   * @param {Roles} role
   * @return {boolean}
   */
  isIbanBlockVisible(role: Roles): boolean {
    if (role !== Roles.Pagador || !this.isIBANVisible) {
      return false;
    }
    
    const pagador = this.model.personRoles.find(person => person.role === Roles.Pagador);
    const pagadorEqualToTomador = pagador?.roleEqualStatements?.find(
      roleEqualStatement => roleEqualStatement.roleCopyFrom === Roles.Tomador
    )?.value;

    /**
     * If pagador equals tomador => Visible if we have tomador person data
     */
    if (pagadorEqualToTomador) {
      const tomador = this.model.personRoles.find(person => person.role === Roles.Tomador);
      return !!tomador?.searchModel.selectedPerson;
    }

    const pagadorEqualToAsegurado = pagador?.roleEqualStatements?.find(
      roleEqualStatement => roleEqualStatement.roleCopyFrom === Roles.Asegurado
    )?.value;

    /**
     * If pagador equals asegurado => Visible if we have asegurado person data
     */
    if (pagadorEqualToAsegurado) {
      const asegurado = this.model.personRoles.find(person => person.role === Roles.Asegurado);
      return !!asegurado?.searchModel.selectedPerson;
    }

    // Pagador IS NOT equal to tomador or asegurado => Visible if we have pagador person data
    return !!pagador?.searchModel.selectedPerson;
  }

  /**
   * Resets person with received role
   * @param {Roles} role
   */
  resetPerson(role: Roles) {
    const personWithRole = this.model.personRoles.find(person => person.role === role);

    if (!personWithRole) {
      return;
    }

    personWithRole.searchModel = new QbSearchPersonModel();
    personWithRole.addressesModel = new QbAddressesPersonModel();
    personWithRole.ibanList = [];
    personWithRole.ibanCodeModel = new QbIbanCodeModel();
  }

  /**
   * Validates and updates person component
   */
  async validateUpdatePersonsComponent() {
    const personComponentValidations = [];

    for (const person of this.model.personRoles) {
      const personComponent: QbSearchPersonBusiness = this.getSearchPersonComp(person.role);
      if (personComponent) {
        await personComponent.updateViewModel();
        this.update();
        personComponentValidations.push(personComponent.validation());
      }
    }

    try {
      const compositeVal = new EACompositeValidation(personComponentValidations, this.$tc.bind(this));
      await compositeVal.validate();
    } catch (error) {
      throw new EAValidationError(this.$t('common.label.validation.formWithErrors').toString());
    }
  }

  /**
   * Validates persons selected addresses:
   * For each person => 
   *  Check if person has selected addresses when:
   *    Person is showing addresses selector
   *    Person is not equal to other roles person
   * @returns {any[]}
   */
  validateSelectedAddresses(): any[] {
    const errors = [];

    const tomador = this.model.personRoles.find(person => person.role === Roles.Tomador);
    const tipoDocumentoTomador = tomador?.searchModel.selectedPerson?.datosBasicosPersona?.tipoDocumento;
    const tomadorPersonaFisica =
      tipoDocumentoTomador === TipoDocumentoEnum.N ||
      tipoDocumentoTomador === TipoDocumentoEnum.P ||
      tipoDocumentoTomador === TipoDocumentoEnum.R;

    for (const person of this.model.personRoles) {
      const equalPersonRole = this.searchPersonEqualToAnotherRole(person.role);

      if (
        !equalPersonRole && this.getRoleAddressVisibility(person.role) &&
        !person?.addressesModel?.addEditAddress.selectedAddressCode
      ) {
        errors.push({
          message: this.$t('quoteBuyGenericFlow.error.addressRequired', {
            rol: person.role,
          }).toString(),
        });
      }
      if (
        person.role === 'tomador' &&
        tomadorPersonaFisica &&
        this.indicadorDataQuality === true &&
        !person?.addressesModel?.addEditPhone.selectedPhoneNumber
      ) {
        errors.push({
          message: this.$t('quoteBuyGenericFlow.error.phoneRequired', {
            rol: person.role,
          }).toString(),
        });
      }
      if (
        person.role === 'tomador' &&
        tomadorPersonaFisica &&
        this.indicadorDataQuality === true &&
        !person?.addressesModel?.addEditEmail.selectedEmailCode
      ) {
        errors.push({
          message: this.$t('quoteBuyGenericFlow.error.emailRequired', {
            rol: person.role,
          }).toString(),
        });
      }
    }
    
    return errors;
  }

  /**
   * Validates persons selected nationality
   * @returns {Promise<any>}
   */
  async validateSelectedNationality(): Promise<any[]> {
    const errors = [];

    for (const person of this.model.personRoles) {
      const equalPersonRole = this.searchPersonEqualToAnotherRole(person.role);

      if (
        !equalPersonRole && this.getRoleAddressVisibility(person.role) &&
        this.isNationalitySelectorShown(person)
      ) {
        const personComponent: QbSearchPersonBusiness = this.getSearchPersonComp(person.role);

        try {
          await personComponent.nationalityValidation().validate();
        } catch (error) {
          errors.push({
            message: this.$t('quoteBuyGenericFlow.error.nationalityRequired', {
              rol: person.role,
            }).toString(),
          });
        }
      }
    }
    
    return errors;
  }

  /**
   * Validates billing data for TRC
   * @returns {EAValidation}
   * 
   */
  validateSelectedBilling(): EAValidation {

    const personComponent: QbSearchPersonBusiness = this.getSearchPersonComp(Roles.Tomador);
    return personComponent.billingValidation();
  }

  /**
   * Gets search person component given person's role
   * @param {string} role
   * @returns {QbSearchPersonBusiness}
   */
  getSearchPersonComp(role: string): QbSearchPersonBusiness {
    const ref = `ref-${role}`;
    let personComponentRef = this.$refs[ref] as unknown;

    if (Array.isArray(personComponentRef)) {
      personComponentRef = personComponentRef[0];
    }

    return personComponentRef as QbSearchPersonBusiness;
  }

  /**
   * Checks if received role is in allowed roles array
   * @param {Roles} role
   * @returns {boolean}
   */
  isRoleAllowed(role: Roles): boolean {
    return !!this.allowedRoles?.includes(role);
  }

  /**
   * Retrieves wether iban is required or not for received role
   * @param {Roles} role
   * @returns {boolean}
   */
  isIbanRequired(role: Roles): boolean {
    return !!this.paymentChannelIsBank && role === Roles.Pagador;
  }

  /**
   * Retrieves persons to show using received param "allowedRoles"
   */
  get personsToShow() {
    return this.model.personRoles.filter(person => this.isRoleAllowed(person.role));
  }

  // ### EVENT EMITTERS ### //

  /**
   * Emit received handle generic error event to parent component
   * @param {any} args
   */
  onHandleGenericError(args: any) {
    this.$emit('handleGenericError', args);
  }

  /**
   * Emit received show error event to parent component
   * @param {GenericErrorData} genericErrorData
   */
  onShowError(genericErrorData: GenericErrorData) {
    this.$emit('showError', genericErrorData);
  }

  /**
   * Calls auxiliar method for resetting person
   * @param {Roles} role
   */
  onResetPerson(role: Roles) {
    this.resetPerson(role);
    this.update();
    this.$emit('personChanged');

    if (role === Roles.Tomador) {
      this.$emit('changeTomador');
    }
  }

  /**
   * Emit delete driver data
   * @param {SelectDriverInterface[]} data
   */
  onDriversToDelete(data: SelectDriverInterface[]) {
    this.$emit('driversToDelete', data);
  }

  /**
   * Emit the value of nominated radio-button
   * @param {boolean} value
   */
  onIsDriverIsNominated(value: boolean) {
    this.$emit('isDriverIsNominated', value);
  }

  /**
   * Update drivers in model
   */
  onGetDriverList() {
    this.update();
  }
  
}
</script>
