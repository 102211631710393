/* eslint-disable */

/**
 * zonazurich
 * Portal de Mediación Digital
 *
 * The version of the OpenAPI document: 1
 * Contact: digitalarchitecture@zurich.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import {
  EASDKConfig,
  EAUrlUtils
} from '@zurich-es-npm/ea-shared-utils-js';

import * as globalImportUrl from 'url';
import {
  Configuration
} from './configuration';
import globalAxios, {
  AxiosPromise,
  AxiosInstance,
  AxiosResponse
} from 'axios';

const BASE_PATH = EAUrlUtils.removeLastForwardSlash('http://localhost:3000');

/**
 *
 * @export
 */
export const COLLECTION_FORMATS = {
  csv: ',',
  ssv: ' ',
  tsv: '\t',
  pipes: '|',
};

/**
 *  
 * @export
 * @interface RequestArgs
 */
export interface RequestArgs {
  url: string;
  options: any;
}

/**
 * 
 * @export
 * @class BaseAPI
 */
export class BaseAPI {
  protected configuration: Configuration | undefined;

  /**
   *Creates an instance of BaseAPI.
   * @param {Configuration} [configuration]
   * @param {string} [basePath=BASE_PATH]
   * @param {AxiosInstance} [axios=globalAxios]
   * @memberof BaseAPI
   */
  public constructor(
    configuration?: Configuration,
    protected basePath: string = BASE_PATH,
    protected axios: AxiosInstance = globalAxios) {
    if (configuration) {
      this.configuration = configuration;
      this.basePath = configuration.basePath || this.basePath;
    }
  }
}

/**
 * 
 * @export
 * @class RequiredError
 * @extends {Error}
 */
export class RequiredError extends Error {
  public name: 'RequiredError' = 'RequiredError';

  public field: string;

  /**
   *Creates an instance of RequiredError.
   * @param {string} field
   * @param {string} [msg]
   * @memberof RequiredError
   */
  public constructor(field: string, msg?: string) {
    super(msg);
    this.field=field;
  }
}

/**
 * 
 * @export
 * @class AsyncRequestError
 * @extends {Error}
 */
export class AsyncRequestError extends Error {
  public name: 'AsyncRequestError' = 'AsyncRequestError';

  /**
   *Creates an instance of AsyncRequestError.
   * @param {string} field
   * @param {string} [msg]
   * @memberof AsyncRequestError
   */
  public constructor(msg?: string) {
    super(msg);
  }
}

/**
 * 
 * @export
 * @interface EditPersonRequest
 */
export interface EditPersonRequest {
    /**
     * 
     * @type {boolean}
     * @memberof EditPersonRequest
     */
    actualizarDatosGenerales?: boolean;
    /**
     * 
     * @type {EditPersonRequestDatosBasicosPersona}
     * @memberof EditPersonRequest
     */
    datosBasicosPersona?: EditPersonRequestDatosBasicosPersona;
    /**
     * 
     * @type {EditPersonRequestDatosGeneralesPersona}
     * @memberof EditPersonRequest
     */
    datosGeneralesPersona?: EditPersonRequestDatosGeneralesPersona;
    /**
     * 
     * @type {EditPersonRequestTitulacionPersona}
     * @memberof EditPersonRequest
     */
    titulacionPersona?: EditPersonRequestTitulacionPersona;
    /**
     * 
     * @type {EditPersonRequestEmpresa}
     * @memberof EditPersonRequest
     */
    empresa?: EditPersonRequestEmpresa;
    /**
     * 
     * @type {EditPersonRequestRegistroEmpresa}
     * @memberof EditPersonRequest
     */
    registroEmpresa?: EditPersonRequestRegistroEmpresa;
    /**
     * 
     * @type {EditPersonRequestRegistroEmpresaDetalles}
     * @memberof EditPersonRequest
     */
    registroEmpresaDetalles?: EditPersonRequestRegistroEmpresaDetalles;
    /**
     * 
     * @type {Array<EditPersonRequestListaCarnetsPersona>}
     * @memberof EditPersonRequest
     */
    listaCarnetsPersona?: Array<EditPersonRequestListaCarnetsPersona>;
    /**
     * 
     * @type {EditPersonRequestMarketing}
     * @memberof EditPersonRequest
     */
    marketing?: EditPersonRequestMarketing;
}/**
 * 
 * @export
 * @interface EditPersonRequestDatosBasicosPersona
 */
export interface EditPersonRequestDatosBasicosPersona {
    /**
     * 
     * @type {string}
     * @memberof EditPersonRequestDatosBasicosPersona
     */
    codigoFiliacion?: string;
    /**
     * 
     * @type {string}
     * @memberof EditPersonRequestDatosBasicosPersona
     */
    tipoPersona?: EditPersonRequestDatosBasicosPersonaTipoPersonaEnum;
    /**
     * 
     * @type {string}
     * @memberof EditPersonRequestDatosBasicosPersona
     */
    codigoIdentificacionFiscal?: string;
    /**
     * 
     * @type {string}
     * @memberof EditPersonRequestDatosBasicosPersona
     */
    primerApellido?: string;
    /**
     * 
     * @type {string}
     * @memberof EditPersonRequestDatosBasicosPersona
     */
    segundoApellido?: string;
    /**
     * 
     * @type {string}
     * @memberof EditPersonRequestDatosBasicosPersona
     */
    nombrePropio?: string;
    /**
     * 
     * @type {string}
     * @memberof EditPersonRequestDatosBasicosPersona
     */
    tipoDocumento?: EditPersonRequestDatosBasicosPersonaTipoDocumentoEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum EditPersonRequestDatosBasicosPersonaTipoPersonaEnum {
    F = 'F',
    J = 'J'
}
/**
    * @export
    * @enum {string}
    */
export enum EditPersonRequestDatosBasicosPersonaTipoDocumentoEnum {
    C = 'C',
    E = 'E',
    N = 'N',
    P = 'P',
    R = 'R',
    S = 'S',
    X = 'X'
}
/**
 * 
 * @export
 * @interface EditPersonRequestDatosGeneralesPersona
 */
export interface EditPersonRequestDatosGeneralesPersona {
    /**
     * 
     * @type {string}
     * @memberof EditPersonRequestDatosGeneralesPersona
     */
    tratamiento?: string;
    /**
     * 
     * @type {string}
     * @memberof EditPersonRequestDatosGeneralesPersona
     */
    sexo?: EditPersonRequestDatosGeneralesPersonaSexoEnum;
    /**
     * 
     * @type {string}
     * @memberof EditPersonRequestDatosGeneralesPersona
     */
    fechaNacimiento?: string;
    /**
     * 
     * @type {string}
     * @memberof EditPersonRequestDatosGeneralesPersona
     */
    nombrePoblacion?: string;
    /**
     * 
     * @type {string}
     * @memberof EditPersonRequestDatosGeneralesPersona
     */
    poblacionIneNacimiento?: string;
    /**
     * 
     * @type {string}
     * @memberof EditPersonRequestDatosGeneralesPersona
     */
    nacionalidadPersona?: string;
    /**
     * 
     * @type {string}
     * @memberof EditPersonRequestDatosGeneralesPersona
     */
    codigoResidencia?: string;
    /**
     * 
     * @type {string}
     * @memberof EditPersonRequestDatosGeneralesPersona
     */
    estadoCivil?: string;
    /**
     * 
     * @type {string}
     * @memberof EditPersonRequestDatosGeneralesPersona
     */
    tipoContactoZurich?: string;
    /**
     * 
     * @type {string}
     * @memberof EditPersonRequestDatosGeneralesPersona
     */
    idiomaCorrespondencia?: string;
    /**
     * 
     * @type {string}
     * @memberof EditPersonRequestDatosGeneralesPersona
     */
    especialidadPrincipal?: string;
    /**
     * 
     * @type {string}
     * @memberof EditPersonRequestDatosGeneralesPersona
     */
    indicadorListaRobinson?: string;
    /**
     * 
     * @type {string}
     * @memberof EditPersonRequestDatosGeneralesPersona
     */
    codigoCnae?: string;
    /**
     * 
     * @type {string}
     * @memberof EditPersonRequestDatosGeneralesPersona
     */
    indicadorPersoonaRestringida?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum EditPersonRequestDatosGeneralesPersonaSexoEnum {
    V = 'V',
    M = 'M'
}
/**
 * 
 * @export
 * @interface EditPersonRequestEmpresa
 */
export interface EditPersonRequestEmpresa {
    /**
     * 
     * @type {string}
     * @memberof EditPersonRequestEmpresa
     */
    nombreEmpresaTrabajo?: string;
    /**
     * 
     * @type {string}
     * @memberof EditPersonRequestEmpresa
     */
    codigoCategoriaEmpresa?: string;
    /**
     * 
     * @type {string}
     * @memberof EditPersonRequestEmpresa
     */
    tipoContrato?: string;
    /**
     * 
     * @type {string}
     * @memberof EditPersonRequestEmpresa
     */
    fechaAltaEmpresa?: string;
    /**
     * 
     * @type {string}
     * @memberof EditPersonRequestEmpresa
     */
    fechaBajaEmpresa?: string;
    /**
     * 
     * @type {string}
     * @memberof EditPersonRequestEmpresa
     */
    codigoBajaEmpresa?: string;
}/**
 * 
 * @export
 * @interface EditPersonRequestListaCarnetsPersona
 */
export interface EditPersonRequestListaCarnetsPersona {
    /**
     * 
     * @type {string}
     * @memberof EditPersonRequestListaCarnetsPersona
     */
    tipoPermiso?: string;
    /**
     * 
     * @type {string}
     * @memberof EditPersonRequestListaCarnetsPersona
     */
    numeroPermiso?: string;
    /**
     * 
     * @type {string}
     * @memberof EditPersonRequestListaCarnetsPersona
     */
    fechaObtencionPermiso?: string;
    /**
     * 
     * @type {string}
     * @memberof EditPersonRequestListaCarnetsPersona
     */
    fechaUltimaExpedicion?: string;
    /**
     * 
     * @type {string}
     * @memberof EditPersonRequestListaCarnetsPersona
     */
    fechaCaducidadPermiso?: string;
    /**
     * 
     * @type {string}
     * @memberof EditPersonRequestListaCarnetsPersona
     */
    estadoPermiso?: string;
    /**
     * 
     * @type {string}
     * @memberof EditPersonRequestListaCarnetsPersona
     */
    fechaEstadoPermiso?: string;
}/**
 * 
 * @export
 * @interface EditPersonRequestMarketing
 */
export interface EditPersonRequestMarketing {
    /**
     * 
     * @type {string}
     * @memberof EditPersonRequestMarketing
     */
    timestampPersonaFisica?: string;
    /**
     * 
     * @type {string}
     * @memberof EditPersonRequestMarketing
     */
    timestampPersonaJuridica?: string;
    /**
     * 
     * @type {string}
     * @memberof EditPersonRequestMarketing
     */
    codigoOperacionPersona?: EditPersonRequestMarketingCodigoOperacionPersonaEnum;
    /**
     * 
     * @type {string}
     * @memberof EditPersonRequestMarketing
     */
    codigoFicheroExterno?: string;
    /**
     * 
     * @type {string}
     * @memberof EditPersonRequestMarketing
     */
    indicadorFicheroExterno?: string;
    /**
     * 
     * @type {string}
     * @memberof EditPersonRequestMarketing
     */
    indicadorDatosNormalizados?: string;
    /**
     * 
     * @type {string}
     * @memberof EditPersonRequestMarketing
     */
    codigoOrigenPersona?: string;
    /**
     * 
     * @type {string}
     * @memberof EditPersonRequestMarketing
     */
    indicadorFraude?: string;
    /**
     * 
     * @type {string}
     * @memberof EditPersonRequestMarketing
     */
    codigoUnidad?: string;
    /**
     * 
     * @type {string}
     * @memberof EditPersonRequestMarketing
     */
    claveSBU?: string;
    /**
     * 
     * @type {string}
     * @memberof EditPersonRequestMarketing
     */
    codigoPasaporte?: string;
    /**
     * 
     * @type {string}
     * @memberof EditPersonRequestMarketing
     */
    tipoRegimenFiscal?: string;
    /**
     * 
     * @type {string}
     * @memberof EditPersonRequestMarketing
     */
    fechaEmisionCertificadoResidencia?: string;
    /**
     * 
     * @type {string}
     * @memberof EditPersonRequestMarketing
     */
    indicadorNifCifModificado?: string;
    /**
     * 
     * @type {string}
     * @memberof EditPersonRequestMarketing
     */
    codigoRiskName?: string;
    /**
     * 
     * @type {string}
     * @memberof EditPersonRequestMarketing
     */
    fechaAltaRiskName?: string;
    /**
     * 
     * @type {string}
     * @memberof EditPersonRequestMarketing
     */
    fechaBajaRiskName?: string;
    /**
     * 
     * @type {string}
     * @memberof EditPersonRequestMarketing
     */
    codigoPaisDocumentoCee?: string;
    /**
     * 
     * @type {string}
     * @memberof EditPersonRequestMarketing
     */
    indicadorMorosidadZfs?: string;
    /**
     * 
     * @type {string}
     * @memberof EditPersonRequestMarketing
     */
    indicadorTipoPersona?: string;
    /**
     * 
     * @type {string}
     * @memberof EditPersonRequestMarketing
     */
    tipoCliente?: string;
    /**
     * 
     * @type {string}
     * @memberof EditPersonRequestMarketing
     */
    estadoPersona?: EditPersonRequestMarketingEstadoPersonaEnum;
    /**
     * 
     * @type {string}
     * @memberof EditPersonRequestMarketing
     */
    campoPaginacion?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum EditPersonRequestMarketingCodigoOperacionPersonaEnum {
    MO = 'MO',
    AL = 'AL'
}
/**
    * @export
    * @enum {string}
    */
export enum EditPersonRequestMarketingEstadoPersonaEnum {
    T = 'T',
    A = 'A'
}
/**
 * 
 * @export
 * @interface EditPersonRequestRegistroEmpresa
 */
export interface EditPersonRequestRegistroEmpresa {
    /**
     * 
     * @type {string}
     * @memberof EditPersonRequestRegistroEmpresa
     */
    codigoEpigrafe?: string;
    /**
     * 
     * @type {string}
     * @memberof EditPersonRequestRegistroEmpresa
     */
    codigoDelegacionHacienda?: string;
    /**
     * 
     * @type {string}
     * @memberof EditPersonRequestRegistroEmpresa
     */
    codigoIcea?: string;
    /**
     * 
     * @type {string}
     * @memberof EditPersonRequestRegistroEmpresa
     */
    codigoLetraIcea?: string;
}/**
 * 
 * @export
 * @interface EditPersonRequestRegistroEmpresaDetalles
 */
export interface EditPersonRequestRegistroEmpresaDetalles {
    /**
     * 
     * @type {string}
     * @memberof EditPersonRequestRegistroEmpresaDetalles
     */
    codigoProvinciaInscripcionRm?: string;
    /**
     * 
     * @type {string}
     * @memberof EditPersonRequestRegistroEmpresaDetalles
     */
    fechaInscripcionRm?: string;
    /**
     * 
     * @type {string}
     * @memberof EditPersonRequestRegistroEmpresaDetalles
     */
    codigoHojaRm?: string;
}/**
 * 
 * @export
 * @interface EditPersonRequestTitulacionPersona
 */
export interface EditPersonRequestTitulacionPersona {
    /**
     * 
     * @type {string}
     * @memberof EditPersonRequestTitulacionPersona
     */
    titulacionAcademica?: string;
    /**
     * 
     * @type {string}
     * @memberof EditPersonRequestTitulacionPersona
     */
    profesion?: string;
}/**
 * 
 * @export
 * @interface EditPersonResponse
 */
export interface EditPersonResponse {
    /**
     * 
     * @type {string}
     * @memberof EditPersonResponse
     */
    codigoFiliacion?: string;
    /**
     * 
     * @type {Array<Error>}
     * @memberof EditPersonResponse
     */
    errors?: Array<Error>;
}/**
 * 
 * @export
 * @interface Error
 */
export interface Error {
    /**
     * 
     * @type {string}
     * @memberof Error
     */
    code: string;
    /**
     * 
     * @type {string}
     * @memberof Error
     */
    message: string;
    /**
     * 
     * @type {number}
     * @memberof Error
     */
    severity: number;
    /**
     * 
     * @type {Array<object>}
     * @memberof Error
     */
    childErrors?: Array<object>;
}

type editPersonOperationParams = {
  editPersonRequest: EditPersonRequest,
};

/**
 * EditPersonApi - axios parameter creator
 * @param {Configuration} [configuration]
 * @returns {any}
 */
const EditPersonApiAxiosParamCreator = function(configuration?: Configuration): any {
  return {
    
    /**
     * Alta / Modficicación de los datos de la persona
     * @param {EditPersonRequest} editPersonRequest 
     * @param {string} [acceptLanguage] El Accept-Language request HTTP header indica en que lenguaje debe responder el backend.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @returns {RequestArgs}
     */
    editPersonOperation(params: editPersonOperationParams, options: any = {}): RequestArgs {
      // Verify required parameter 'editPersonRequest' is not null or undefined
      if (params.editPersonRequest === null || params.editPersonRequest === undefined) {
        throw new RequiredError('editPersonRequest', 'Required parameter editPersonRequest was null or undefined when calling editPersonOperation.');
      }
      const localVarPath = `/v1/quoteAndBuy/editPersonOperation`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true) as Partial<globalImportUrl.UrlWithParsedQuery>;
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;


  
      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
      // Fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
      const needsSerialization = ('EditPersonRequest' as any !== 'string') ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization ? JSON.stringify(params.editPersonRequest || {}) : params.editPersonRequest || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * EditPersonApi - functional programming interface
 * @param {Configuration} [configuration]
 * @returns {any}
 */
const EditPersonApiFp = function(configuration?: Configuration): any {
  return {
    
    /**
     * Alta / Modficicación de los datos de la persona
     * @param {EditPersonRequest} editPersonRequest 
     * @param {string} [acceptLanguage] El Accept-Language request HTTP header indica en que lenguaje debe responder el backend.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @returns {any}
     */
    editPersonOperation(params: editPersonOperationParams, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<EditPersonResponse> {
      const localVarAxiosArgs = EditPersonApiAxiosParamCreator(configuration).editPersonOperation(params, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH): any => {
        const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * EditPersonApi - factory interface
 * @param {Configuration} configuration
 * @param {string} basePath
 * @param {AxiosInstance} axios
 * @export
 * @returns {any}
 */
const EditPersonApiFactory = function(configuration?: Configuration, basePath?: string, axios?: AxiosInstance): any {
  return {
    
    /**
     * Alta / Modficicación de los datos de la persona
     * @param {EditPersonRequest} editPersonRequest 
     * @param {string} [acceptLanguage] El Accept-Language request HTTP header indica en que lenguaje debe responder el backend.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @returns {any}
     */
    editPersonOperation(params: editPersonOperationParams, options?: any): any {
      return EditPersonApiFp(configuration).editPersonOperation(params, options)(axios, basePath);
    },
  };
};

/** 
 * EditPersonApi - object-oriented interface
 * @export
 * @class EditPersonApi
 * @extends {BaseAPI}
 */
export class EditPersonApi extends BaseAPI {
  
  /**
   * Alta / Modficicación de los datos de la persona
   * @param {EditPersonRequest} editPersonRequest 
   * @param {string} [acceptLanguage] El Accept-Language request HTTP header indica en que lenguaje debe responder el backend.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @returns {any}
   * @memberof EditPersonApi
   */
  public editPersonOperation(params: editPersonOperationParams, options?: any): any {
    return EditPersonApiFp(this.configuration).editPersonOperation(params, options)(this.axios, this.basePath);
  }

}

/**
 * 
 * @export
 * @class EAeditPersonOperationInputMessage
 */
export class EAeditPersonOperationInputMessage {
  
  /**
   * 
   * @type {EditPersonRequest}
   * @memberof EAeditPersonOperationInputMessage
   */
  public editPersonRequest: EditPersonRequest;

  public constructor(editPersonRequest: EditPersonRequest, ){
  
  this.editPersonRequest=editPersonRequest;
  } 
}



/** 
 * EAEditPersonApi - Architecture client for editPersonOperation
 * @export
 * @class EAEditPersonApi
 */
export class EAEditPersonApi {

  /**
   * 
   * @param {EditPersonRequest} editPersonRequest
   
   * @throws {AsyncRequestError | RequiredError}
   * @returns {Promise<EditPersonResponse | null>}
  */ 
  public async editPersonOperation(params: editPersonOperationParams): Promise<EditPersonResponse | null> {
    return this.editPersonOperationPromise(params);
  }

  /**
   * 
   * @param {EditPersonRequest} editPersonRequest
   
   * @param {Function} callback
   * @throws {AsyncRequestError | RequiredError}
   */
  public async editPersonOperationSteps(params: editPersonOperationParams, callback: (res: AxiosResponse<EditPersonResponse | null>) => void) {
    let done: boolean = false;
    const httpClient: AxiosInstance = EASDKConfig.getHttpClient('/v1/quoteAndBuy/editPersonOperation', 'editPerson', 'editPersonOperation');
    const config: Configuration = {};
    const api = new EditPersonApi(config, '', httpClient);
    const sleep = async(ms: number) => {
      return new Promise(resolve => setTimeout(resolve, ms));
    };
    const headers: { ['Async-Id']?: string } = {};
    while (!done) {
      const output = await api.editPersonOperation(params, { headers }) as AxiosResponse<EditPersonResponse | null>;
      callback(output);
      if (output.status === 202) {
        try {
          const retry = parseInt(output.headers['retry']);
          headers['Async-Id'] = output.headers['request-id'];
          if (isNaN(retry) || retry <= 0 || retry > 1000 * 3600) {
            throw new Error(`Retry time not acceptable (in milliseconds): ${retry}`);
          }
          await sleep(retry);
        } catch (err) {
          throw new Error(`Error in Async Request ${err}`);
        }
      } else {
        done = true;
      }
    }
  }

  /**
   * 
   * @param {EditPersonRequest} editPersonRequest
   
   * @throws {AsyncRequestError | RequiredError}
   * @returns {Promise<EditPersonResponse | null>}
   */
  public async editPersonOperationPromise(params: editPersonOperationParams): Promise<EditPersonResponse | null> {
    return new Promise((resolve, reject) => {
      this.editPersonOperationSteps(params, (response: AxiosResponse<EditPersonResponse | null>) => {
        if (response.status !== 202) {
          resolve(response.data);
        }
      })
      .catch((err) => {
        reject(err);
      });
    });
  } 
}


