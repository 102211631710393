<template>
  <div>
    <ea-heading level="4" class="m-b-8">
        {{ $t('quoteBuyGenericFlow.offerIssuanceData.billingData') }}
      </ea-heading>

    <ea-form ref="form" :model="model">
      <ea-row>
        <ea-col :span="6">
          <ea-form-item
            prop="paymentChannel"
            :label="$t('quoteBuyGenericFlow.offerIssuanceData.field.firstReceiptPaymentChannel')"
          >
            <ea-select
              v-model="model.paymentChannelFirstReceipt"
              :placeholder="$t('common.label.select')"
              :readonly="paymentChannelsFirstReceiptList.length === 1"
              @change="onPaymentChannelFirstReceiptChange"
            >
              <ea-option
                v-for="paymentChannel in paymentChannelsFirstReceiptList"
                :key="paymentChannel.value"
                :label="paymentChannel.label"
                :value="paymentChannel.value"
              />
            </ea-select>
          </ea-form-item>
        </ea-col>

        <ea-col :span="6">
          <ea-form-item
            v-if="model.debtCollectorIntermediaryFirstReceiptList.length !== 1"
            prop="paymentChannel"
            :label="$t('quoteBuyGenericFlow.offerIssuanceData.field.firstReceiptDebtCollectorIntermediary')"
          >
            <ea-select
              v-model="model.debtCollectorIntermediaryFirstReceipt"
              :placeholder="$t('common.label.select')"
              :readonly="model.debtCollectorIntermediaryFirstReceiptList.length === 1"
            >
              <ea-option
                v-for="debtCollectorIntermediary in model.debtCollectorIntermediaryFirstReceiptList"
                :key="debtCollectorIntermediary.codigoIntermediario"
                :label="debtCollectorIntermediary.nombreIntermediario"
                :value="debtCollectorIntermediary.codigoIntermediario"
              />
            </ea-select>
          </ea-form-item>
        </ea-col>
        <ea-col :span="6" v-if="showSuccessiveData">
          <ea-form-item
            prop="paymentChannel"
            :label="$t('quoteBuyGenericFlow.offerIssuanceData.field.successiveReceiptsPaymentChannel')"
          >
            <ea-select
              v-model="model.paymentChannelSuccessiveReceipts"
              :placeholder="$t('common.label.select')"
              @change="onPaymentChannelSuccessiveReceiptsChange"
            >
              <ea-option
                v-for="paymentChannel in paymentChannelsSuccessiveReceiptsList"
                :key="paymentChannel.value"
                :label="paymentChannel.label"
                :value="paymentChannel.value"
              />
            </ea-select>
          </ea-form-item>
        </ea-col>

        <ea-col :span="6" v-if="showSuccessiveData">
          <ea-form-item
            v-if="model.debtCollectorIntermediarySuccessiveReceiptsList.length !== 1"
            prop="paymentChannel"
            :label="$t('quoteBuyGenericFlow.offerIssuanceData.field.successiveReceiptsDebtCollectorItermediary')"
          >
            <ea-select
              v-model="model.debtCollectorIntermediarySuccessiveReceipts"
              :placeholder="$t('common.label.select')"
              :readonly="model.debtCollectorIntermediarySuccessiveReceiptsList.length === 1"
            >
              <ea-option
                v-for="debtCollectorIntermediary in model.debtCollectorIntermediarySuccessiveReceiptsList"
                :key="debtCollectorIntermediary.codigoIntermediario"
                :label="debtCollectorIntermediary.nombreIntermediario"
                :value="debtCollectorIntermediary.codigoIntermediario"
              />
            </ea-select>
          </ea-form-item>
        </ea-col>
      </ea-row>
    </ea-form>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import {
  Component, Prop
} from 'vue-property-decorator';

import {
  mixins
} from 'vue-class-component';

import {
  EAApplicationLogger, EABusinessComponent, EAError, EAMethod
} from '@zurich-es-npm/ea-front-web-core';

import {
  GetContractDataResponseListaCanalesCobro1Recibo,
  GetContractDataResponseListaIntermediarios,
} from '@/services/V1/quoteAndBuy/getContractDataOperation/post';
import {
  CorpTableNames,
  fetchCorporateTable,
  parseCorpTableDocuments,
} from '@/utils/corporate-tables';
import QbOfferIssuanceBillingDataFormModel from './qb-offer-issuance-billing-data-form-model';

export enum TipoCanalCobroEnum {
  Bank = 'BC',
  Intermediary = 'IN',
  Window = 'CD',
}

@Component({
  name: 'qb-offer-issuance-billing-data-form',
})

/**
 * Business Component qb-offer-issuance-billing-data-form
 */
export default class QbOfferIssuanceBillingDataFormBusiness extends mixins<
EABusinessComponent<QbOfferIssuanceBillingDataFormModel>
>(EABusinessComponent) {

  @Prop({
    'default': () => true
  })
    showSuccessiveData?: boolean;

  /**
   * Hook on created
   */
  public async created() {
    await this.fetchDropdownData();
  }

  /**
   * Fetch payment channel dropdown data
   * @returns {Promise<void>}
   */
  @EAMethod()
  public async fetchDropdownData(): Promise<void> {
    try {
      this.model.paymentChannels = [];
      const corpTable = await fetchCorporateTable(CorpTableNames.PaymentMethodChannels);
      parseCorpTableDocuments(corpTable).forEach(doc => this.model.paymentChannels.push(doc));
      this.update();
    } catch (error) {
      const eaError = error as EAError;
      new EAApplicationLogger().error(
        `QbOfferIssuanceBillingDataFormBusiness::fetchDropdownData:: fetch corporate table :: ${eaError.message}`
      );
      throw error;
    }
  }

  /**
   * Handles payment channel first receipt change
   * Updates intermediary list
   */
  onPaymentChannelFirstReceiptChange() {
    Vue.nextTick(() => {
      this.model.debtCollectorIntermediaryFirstReceiptList = this.getUpdatedIntermediaryList(
        this.model.paymentChannelFirstReceipt,
        this.model.selectablePaymentChannelsFirstReceiptList
      );
      this.model.debtCollectorIntermediaryFirstReceipt = this.getDebtCollectorIntermediaryByPaymentChannel(
        this.model.paymentChannelFirstReceipt
      );
      this.update();
      this.$emit('resetOfferIssuance');
    });
  }

  /**
   * Handles payment channel successive receipts change
   * Updates intermediary list
   */
  onPaymentChannelSuccessiveReceiptsChange() {
    Vue.nextTick(() => {
      this.model.debtCollectorIntermediarySuccessiveReceiptsList = this.getUpdatedIntermediaryList(
        this.model.paymentChannelSuccessiveReceipts,
        this.model.selectablePaymentChannelsSuccessiveReceiptsList
      );
      this.model.debtCollectorIntermediarySuccessiveReceipts = this.getDebtCollectorIntermediaryByPaymentChannel(
        this.model.paymentChannelSuccessiveReceipts
      );
      this.update();
      this.$emit('resetOfferIssuance');
    });
  }

  /**
   * Gets debt collector based on selected payment channel
   * @param {string} paymentChannel
   * @param {GetContractDataResponseListaIntermediarios[]} intermediaryList
   * @returns {string}
   */
  getDebtCollectorIntermediaryByPaymentChannel(paymentChannel: string): string {
    switch (paymentChannel) {
      case TipoCanalCobroEnum.Intermediary:
        return this.model.producerIntermediaryCode;
      case TipoCanalCobroEnum.Bank:
        // Default code for bank
        return '0000092779';
      case TipoCanalCobroEnum.Window:
        return this.model.producerIntermediaryCode;
      default:
        return '';
    }
  }

  /**
   * Updates intermediary list
   * @param {string} selectedPaymentMethod
   * @param {GetContractDataResponseListaCanalesCobro1Recibo[]} selectablePaymentChannels
   * @returns {GetContractDataResponseListaIntermediarios[]}
   */
  getUpdatedIntermediaryList(
    selectedPaymentMethod: string,
    selectablePaymentChannels: GetContractDataResponseListaCanalesCobro1Recibo[]
  ): GetContractDataResponseListaIntermediarios[] {
    return (
      selectablePaymentChannels.find(canalCobro => canalCobro.tipoCanalCobro === selectedPaymentMethod)
        ?.listaIntermediarios || []
    );
  }

  /**
   * Getter for filtered payment channels first receipt list
   */
  get paymentChannelsFirstReceiptList() {
    // eslint-disable-next-line max-len
    return this.model.paymentChannels.filter(paymentChannel => this.model.selectablePaymentChannelsFirstReceiptList.find(
      selectablePaymentChannel => selectablePaymentChannel.tipoCanalCobro?.toString() === paymentChannel.value
    )
    );
  }

  /**
   * Getter for filtered payment channels succesice receipt list
   */
  get paymentChannelsSuccessiveReceiptsList() {
    // eslint-disable-next-line max-len
    return this.model.paymentChannels.filter(paymentChannel => this.model.selectablePaymentChannelsSuccessiveReceiptsList.find(
      selectablePaymentChannel => selectablePaymentChannel.tipoCanalCobro?.toString() === paymentChannel.value
    )
    );
  }
}
</script>
