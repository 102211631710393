<template>
  <ea-dialog
    :visible="model.isVisible"
    :beforeClose="onClose"
    size="large"
  >
    <!-- Principal title -->
    <ea-heading level="4" v-if="model.title && model.title.trim()">{{ model.title }}</ea-heading>

    <ea-table
      class="m-t-32"
      v-if="model.fractionalPaymentData.length"
      :data="model.fractionalPaymentData">
      <ea-table-column
        :label="title"
        show="paymentForm"
        headerClass="no-sortBy t-weight-bold t-size-medium"
      >
        <template slot-scope="row">
          <p :class="`ea-table-td-content ${row.showInBold ? ' t-weight-bold' : ''}`">
            {{row.paymentForm}}
          </p>
        </template>
      </ea-table-column>
      <ea-table-column
        v-for="(proposalCode, index) in model.proposalNumbers"
        :key="index"
        :label="mustShowProposalHeader ? getProposalTitle(proposalCode, index) : ''"
        cellClass="t-align-left"
        headerClass="no-sortBy t-weight-bold t-size-medium t-align-center"
      >
        <template slot-scope="row">
          <p :class="`ea-table-td-content t-align-center ${row.showInBold ? 't-weight-bold' : ''}`">
            <span v-if="isNumeric(row.paymentFormValues[proposalCode])">
              {{ `${getPaymentFormValues(row.paymentFormValues[proposalCode])} €` }}
            </span>
            <span v-else>{{row.paymentFormValues[proposalCode]}}</span>
          </p>
        </template>
      </ea-table-column>
    </ea-table>
  </ea-dialog>
</template>

<script lang="ts">
import {
  Component, Prop
} from 'vue-property-decorator';

import {
  mixins
} from 'vue-class-component';

import {
  EABusinessComponent
} from '@zurich-es-npm/ea-front-web-core';
import QbFractionalPaymentModalModel from './qb-fractional-payment-modal-model';
import {
  FieldUtils
} from '../../utils/fields/field-utils';
import Utils from '@/utils/utils';
import {
  PolicyMovementType
} from '@/flows/policy/policy-model';
import ProductBase from '@/utils/quote-buy-product-factory/products/product-base';

@Component({
  name: 'qb-fractional-payment-modal'
})

/**
 * Business Component qb-fractional-payment-modal
 */
export default class QbFractionalPaymentModalBusiness extends mixins<
EABusinessComponent<QbFractionalPaymentModalModel>>(EABusinessComponent) {

  @Prop({
    required: true,
  })
    productFactory!: ProductBase;

  /**
   * Returns the modal title. By default, returns the non-singular title.
   */
  get title(): string {
    let literalModifier: string;
    if (this.model.movementType === PolicyMovementType.Suplemento) {
      // If movement is Supplement ('2')
      literalModifier = 'supplement';
    } else {
      // If not a Supplement, take into account 'isSingularTitle' property
      literalModifier = this.model.isSingularTitle ? 'singular' : 'default';
    }
    return this.$t(`warranties.fractionalPaymentsModalTitle.${literalModifier}`).toString();
    // TODO: FUTURE: Consider use only property 'model.movementType' and delete 'model.isSingularTitle' property
  }

  /**
   * Getter to know if proposal column header must be shown
   */
  get mustShowProposalHeader(): boolean {
    return this.model.movementType !== PolicyMovementType.Suplemento;
  }

  /**
   * Closes the dialog
   */
  onClose() {
    this.model.isVisible = false;
    this.model.proposalNumbers = [];
    this.model.fractionalPaymentData = [];
    this.update();
  }

  /**
   * Returns if received value is numeric
   * @param {string} value
   * @returns {boolean}
   */
  isNumeric(value: string): boolean {
    return !FieldUtils.isFieldNaN(value);
  }

  /**
   * Gets and formats the payment form values
   * 
   * @param {string | number} value
   * @return {string}
   */
  getPaymentFormValues(value: string | number): string {
    return Utils.formatFourDigitNumber(this.$n(+value, 'decimal'));
  }

  /**
   * Get proposal title
   * @param {string} proposalCode
   * @param {number} index
   * @returns {stirng}
   */
  getProposalTitle(proposalCode: string, index: number): string {
    if (this.productFactory.shouldShowCustomPackageName) {
      return this.$t(`warranties.packageName.${proposalCode}`).toString();
    } else {
      return this.$t('quoteBuyGenericFlow.proficiencyManagement.field.proposalNumber',
        {
          number: index + 1
        }).toString();
    }
  }
}
</script>

