import QbEmployeeModalIntermediariesModel
  from '../qb-employee-modal-intermediaries/qb-employee-modal-intermediaries-model';

/** 
 * Model intermediary-selection
 */
class IntermediarySelectionModel {

  public employeeUser?: boolean = false;

  public intermediaryName: string = '';

  public intermediaryCode: string = '';

  public intermediaryTaxIdentity: string = '';

  public intermediariesEmployeeModal = new QbEmployeeModalIntermediariesModel();

  public businessCode: string = '';

  public businessName: string = '';

  public productCode: string = '';

  public productComercialCode: string = '';

  public productTechnicalCode: string = '';

  public productName: string = '';

}

export default IntermediarySelectionModel;
