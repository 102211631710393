/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  GenerateDocumentationViewLabelsModel
} from '@/business-components/qb-documentation-select-proposal/qb-documentation-select-proposal-model';
import {
  OfferIssuanceDataModel
} from '@/business-components/qb-offer-issuance-data/qb-offer-issuance-data-model';
import {
  GetWarrantiesResponseDataDatosGarantias
} from '@/services/V1/quoteAndBuy/getWarrantiesOperation/post';
import {
  SaveOfferDataRequestElementosPoliza
} from '@/services/V1/quoteAndBuy/saveOfferDataOperation/post';
import {
  Roles
} from '@/types/roles/roles-enum.types';
import {
  ParsedTableData
} from '@/utils/corporate-tables';
import PersonUtils from '@/utils/person-utils';
import {
  EALocaleManager,
  EAValidationError
} from '@zurich-es-npm/ea-front-web-core';
import Vue, {
  VueConstructor
} from 'vue';
import {
  VisibleElementsWarranties,
  PersonRole
} from '../../types/product-role-types';
import {
  ProductBase
} from '../product-base';
import TodoRiesgoConstruccionMontajeModel from './todo-riesgo-construccion-montaje-model';
import QbProductoAseguradoTrConstruccionMontajeBusiness
  // eslint-disable-next-line max-len
  from '@/business-components/qb-producto-asegurado-tr-construccion-montaje/qb-producto-asegurado-tr-construccion-montaje-business.vue';
import QbSearchPersonModel from '@/business-components/qb-search-person/qb-search-person-model';
import QbAddressesPersonModel from '@/business-components/addresses/qb-addresses-person-model';
import QbIbanCodeModel from '@/business-components/qb-iban-code/qb-iban-code-model';
import Utils from '@/utils/utils';
import TextUtils from '@/utils/text-utils';
import QbGeneralDataInformationModel
  from '@/business-components/qb-general-data-information/qb-general-data-information-model';
import {
  PaymentChannelEnum
} from '@/types/payment-channel/payment-channel-enum.types';
import moment from 'moment';
import QbBillingDataModel from '@/business-components/qb-billing-data/qb-billing-data-model';
import TrcDocumentationProposalCardComponent
  // eslint-disable-next-line max-len
  from '@/presentational-components/documentationProposalCard/trc-documentationProposalCard/trc-documentationProposalCard.vue';
import {
  AddressUtils
} from '@/utils/address-utils';
import TodoRiesgoConstruccionMontajePolicyModel from './todo-riesgo-construccion-montaje-policy-model';
import QbShowPersonInfoModel from '@/business-components/qb-show-person-info/qb-show-person-info-business-model';
import {
  GetGeneralDataResponseDataDatosObjeto
} from '@/services/V1/quoteAndBuy/getGeneralDataOperation/post';
import {
  CodigoRamo
} from '@/types/codigo-ramo/codigo-ramo-enum.types';
import {
  DocumentType
} from '@/types/document-types/document-types-enum.types';

/**
 * Product Todo Riesgo Construcción y Montaje
 *
 * @export
 * @class ProductTodoRiesgoConstruccionMontaje
 * @extends {ProductBase}
 */
export class ProductTodoRiesgoConstruccionMontaje extends ProductBase {
  public codigoRamo?: CodigoRamo;

  public readonly allowedRolesAtGeneralDataView = [Roles.Tomador, Roles.Asegurado];

  public readonly personRoles: PersonRole[] = [
    // ### TOMADOR ROLE ###
    {
      disabled: false,
      role: Roles.Tomador,
      searchModel: new QbSearchPersonModel(),
      addressesModel: new QbAddressesPersonModel(),
      ibanList: [],
      ibanCodeModel: new QbIbanCodeModel(),
      billing: new QbBillingDataModel(),
      taxTreatmentArea: '',
      canBeChanged: true,
      showPersonInfoModel: new QbShowPersonInfoModel()
    },
    // ### ASEGURADO ROLE ###
    {
      disabled: false,
      role: Roles.Asegurado,
      searchModel: new QbSearchPersonModel(),
      addressesModel: new QbAddressesPersonModel(),
      ibanList: [],
      ibanCodeModel: new QbIbanCodeModel(),
      billing: new QbBillingDataModel(),
      roleEqualStatements: [
        {
          // ### TOMADOR EQUAL ASEGURADO STATEMENT ###
          roleCopyTo: Roles.Asegurado,
          roleCopyFrom: Roles.Tomador,
          value: true,
          hidden: false,
        },
      ],
      canBeChanged: true,
      showPersonInfoModel: new QbShowPersonInfoModel()
    },
    // ### PAGADOR ROLE ###
    {
      disabled: false,
      role: Roles.Pagador,
      searchModel: new QbSearchPersonModel(),
      addressesModel: new QbAddressesPersonModel(),
      ibanList: [],
      ibanCodeModel: new QbIbanCodeModel(),
      billing: new QbBillingDataModel(),
      roleEqualStatements: [
        {
          // ### TOMADOR EQUAL PAGADOR STATEMENT ###
          roleCopyTo: Roles.Pagador,
          roleCopyFrom: Roles.Tomador,
          value: true,
          hidden: false,
        },
        {
          // ### ASEGURADO EQUAL PAGADOR STATEMENT ###
          roleCopyTo: Roles.Pagador,
          roleCopyFrom: Roles.Asegurado,
          value: false,
          hidden: true,
        },
      ],
      canBeChanged: true,
      showPersonInfoModel: new QbShowPersonInfoModel()
    },
  ];

  public readonly objetoAseguradoComponent: VueConstructor<Vue> = QbProductoAseguradoTrConstruccionMontajeBusiness;

  public readonly documentationSelectProposalComponent: VueConstructor = TrcDocumentationProposalCardComponent;

  public readonly shouldAskForTomadorInNewOfferView: boolean = true;

  public readonly shouldShowNewSearchInShowPersonInfo: boolean = false;

  public readonly axesorSearchDocumentTypes: DocumentType[] = [
    DocumentType.CIF
  ];

  public readonly shouldReadonlyDatesInGeneralDataInfo: boolean = true;

  public readonly shouldShowProposalTitle: boolean = false;

  public readonly shouldShowCustomPackageName: boolean = false;

  public readonly containerWarrantyCode: string = '';

  public readonly contentWarrantyCode: string = '';

  public readonly shouldShowTypeProposal: boolean = false;

  public readonly shouldValidate1YearMoreDate: boolean = false;

  public readonly alwaysVisibleElementsWarranties: VisibleElementsWarranties[] = [];

  /**
   * Warranties header variables
   */
  public readonly warrantiesHeaderConfig = {
    showWarrantiesGroups: false,
    canSelectMultipleWarrantiesGroups: false,
    warrantiesGroupsDisplayMode: undefined,
    showPackagesGroups: false,
    canSelectMultiplePackagesGroups: false,
    packagesGroupsDisplayMode: undefined,
  };
  
  public readonly warrantiesHeaderPolicyConfig = {
    showWarrantiesGroups: false,
    canSelectMultipleWarrantiesGroups: false,
    warrantiesGroupsDisplayMode: undefined,
    showPackagesGroups: false,
    canSelectMultiplePackagesGroups: false,
    packagesGroupsDisplayMode: undefined,
  };
  
  /**
   * Factory constructor
   * @param {string} codigoRamo 
   */
  constructor(codigoRamo: string) {
    super();
    this.codigoRamo = codigoRamo as CodigoRamo;
  }

  /**
   * Retrieves product title
   */
  get productTitle(): string {
    return `quoteBuyGenericFlow.titleDefault.${this.codigoRamo}`;
  }

  /**
   * Retrieves product translation label
   */
  get productTranslationLabel(): string {
    return `ofertaEmisionBusiness.productsByRamo.${this.codigoRamo}`;
  }

  /**
   * Retrieves product name
   */
  get productName(): string {
    return EALocaleManager.i18n.t(this.productTranslationLabel) as string;
  }

  /**
   * Create Todo Riesgo Construccion y Montaje Model Instance
   *
   * @return {TodoRiesgoConstruccionMontajeModel}
   */
  public createModelInstance(): TodoRiesgoConstruccionMontajeModel {
    return new TodoRiesgoConstruccionMontajeModel();
  }

  /**
   * Create Todo Riesgo Construccion y Montaje Model Instance for policies flow
   *
   * @return {ComunidadesModel}
   */
  public createPolicyModelInstance(): TodoRiesgoConstruccionMontajePolicyModel {
    return new TodoRiesgoConstruccionMontajePolicyModel();
  }

  /**
   * Generates offer data for saving service
   *
   * @param {ProductModel} productModel - product model
   * @returns { SaveOfferDataRequestElementosPoliza[] } generated offer
   */
  public generateOfferData(productModel: TodoRiesgoConstruccionMontajeModel): SaveOfferDataRequestElementosPoliza[] {
    const riskSituation = productModel.productModel.riskSituation;
    const technicalConsiderations = productModel.productModel.technicalConsiderations;
    
    const data: SaveOfferDataRequestElementosPoliza[] = [
      {
        codigoElemento: 'CDPAIS',
        valorElemento: riskSituation.formValues.country,
      },
      {
        codigoElemento: 'CDPOST',
        valorElemento: riskSituation.postCodeLocationInputModel.zipCode,
      },
      {
        codigoElemento: 'NOPOBLSR',
        valorElemento: riskSituation.postCodeLocationInputModel.city.substring(0, 30),
      },
      {
        codigoElemento: 'CDPOBLA',
        valorElemento: riskSituation.postCodeLocationInputModel.cityCode || '88000000001',
      },
      {
        codigoElemento: 'TCPROVIN',
        valorElemento: riskSituation.postCodeLocationInputModel.zipCode?.substring(0, 2),
      },
      {
        codigoElemento: 'DSPROVIN',
        valorElemento: riskSituation.postCodeLocationInputModel.province,
      },
      {
        codigoElemento: 'CDDIRESR',
        valorElemento: riskSituation.formValues.roadType,
      },
      {
        codigoElemento: 'NODIRESR',
        valorElemento: riskSituation.formValues.roadName,
      },
      {
        codigoElemento: 'NUDIRESR',
        valorElemento: Utils.parseNumericValueIfReceived(riskSituation.formValues.roadNumber, 0).toString(),
      },
      {
        codigoElemento: 'CDLONGI2',
        valorElemento: riskSituation.formValues.longitude,
      },
      {
        codigoElemento: 'CDLATITU',
        valorElemento: riskSituation.formValues.latitude,
      },
      {
        codigoElemento: 'TXDSRIE1',
        valorElemento: technicalConsiderations.formValues.workDetailedDescription?.substring(0, 80)
      },
      {
        codigoElemento: 'TXDSRIE2',
        valorElemento: technicalConsiderations.formValues.workDetailedDescription?.substring(80, 160)
      },
      {
        codigoElemento: 'TXDSRIE3',
        valorElemento: technicalConsiderations.formValues.workDetailedDescription?.substring(160)
      },
      {
        codigoElemento: 'CDACTVEL',
        valorElemento: technicalConsiderations.formValues.constructionType,
      },
      {
        codigoElemento: 'DUOBRAMO',
        valorElemento: Utils.parseNumericValueIfReceived(
          technicalConsiderations.formValues.constructionTime,
          0
        ).toString(),
      },
      {
        codigoElemento: 'CDCONTEC',
        valorElemento: technicalConsiderations.formValues.technicalConsiderations,
      },
      {
        codigoElemento: 'NUPLASOB',
        valorElemento: Utils.parseNumericValueIfReceived(
          technicalConsiderations.formValues.numberBuildingPlant,
          0
        ).toString(),
      },
      {
        codigoElemento: 'NUSOTANO',
        valorElemento: Utils.parseNumericValueIfReceived(
          technicalConsiderations.formValues.numberBasements,
          0
        ).toString(),
      },
      {
        codigoElemento: 'CDPENTER',
        valorElemento: technicalConsiderations.formValues.landSlope,
      },
      {
        codigoElemento: 'IMSADAOB',
        valorElemento: Utils.parseNumericValueIfReceived(
          technicalConsiderations.formValues.totalConstructionValue,
          0
        ).toString(),
      },
      {
        codigoElemento: 'CDFQDAOB',
        valorElemento: technicalConsiderations.formValues.franchise,
      },
      {
        codigoElemento: 'FEINIOBM',
        valorElemento: technicalConsiderations.formValues.startDate ?
          moment(technicalConsiderations.formValues.startDate).format('YYYYMMDD') : ''
      },
      {
        codigoElemento: 'FEFINOBM',
        valorElemento: technicalConsiderations.formValues.endDate ?
          moment(technicalConsiderations.formValues.endDate).format('YYYYMMDD') : ''
      },
      {
        codigoElemento: 'CDDSTRAB',
        valorElemento: technicalConsiderations.formValues.workDescription
      },
      {
        codigoElemento: 'CDOBREMP',
        valorElemento: technicalConsiderations.formValues.advancedWork,
      },
      {
        codigoElemento: 'INPANSAN',
        valorElemento: Utils.convertBooleanToString(technicalConsiderations.formValues.sandwichPanels),
      },
      {
        codigoElemento: 'INELEPRE',
        valorElemento: Utils.convertBooleanToString(
          technicalConsiderations.formValues.premadeOrWoodenElementsConstruction
        ),
      },
      {
        codigoElemento: 'INOBRPAR',
        valorElemento: Utils.convertBooleanToString(technicalConsiderations.formValues.paralisedOrIncludedConstruction),
      },
    ];
    return data;
  }

  /**
   * Gets labels for generate documentation view
   * @param {ProductModel} productModel - product model
   * @param {ParsedTableData[]} roadTypeCorpTable - corp table for address type
   * @returns {GenerateDocumentationViewLabelsModel}
   */
  public getGenerateDocumentationViewLabels(
    productModel: TodoRiesgoConstruccionMontajeModel | TodoRiesgoConstruccionMontajePolicyModel,
    roadTypeCorpTable: ParsedTableData[]
  ): GenerateDocumentationViewLabelsModel {
    const model = productModel.productModel;
    const parsedRoadType = roadTypeCorpTable.find(
      roadType => roadType.value === model.riskSituation.formValues.roadType
    )?.label;
    const proposalSelectionSubtitleData = AddressUtils.normalizeAddress({
      addressType: parsedRoadType,
      addressName: model.riskSituation.formValues.roadName,
      addressNumber: model.riskSituation.formValues.roadNumber,
      zipCode: model.riskSituation.postCodeLocationInputModel.zipCode,
      cityName: model.riskSituation.postCodeLocationInputModel.city
    });
    return {
      proposalSelectionTitleLabel: this.productTranslationLabel,
      proposalSelectionSubitleLabel: 'quoteBuyGenericFlow.generateDocumentation.field.riskDirection',
      proposalSelectionSubtitleData: proposalSelectionSubtitleData,
    };
  }

  /**
   * Gets labels for offer issuance data view
   * @param {ProductModel} productModel
   * @param {ParsedTableData[]} roadTypeCorpTable - corp table for address type
   * @returns {OfferIssuanceDataModel[]}
   */
  public getOfferIssuanceViewLabels(
    productModel: TodoRiesgoConstruccionMontajeModel,
    roadTypeCorpTable: ParsedTableData[]
  ): OfferIssuanceDataModel[] {
    const model = productModel.productModel;
    const roadNumber = model.riskSituation.formValues.roadNumber ? ` ${model.riskSituation.formValues.roadNumber}` : '';
    const parsedRoadType = roadTypeCorpTable.find(
      roadType => roadType.value === model.riskSituation.formValues.roadType
    )?.label;
    const roadType = parsedRoadType ? `${parsedRoadType} `: '';
    
    const data = [
      {
        label: 'quoteBuyGenericFlow.offerIssuanceData.field.riskDirection',
        value:
          // eslint-disable-next-line max-len
          `${roadType}${model.riskSituation.formValues.roadName}${roadNumber} - ${
            model.riskSituation.postCodeLocationInputModel.zipCode
          } ${TextUtils.capitalizeFirstLetterOneWord(
            model.riskSituation.postCodeLocationInputModel.city.toLowerCase()
          )}`,
      },
      {
        label: 'quoteBuyGenericFlow.offerIssuanceData.field.movementEffectDate',
        value: moment(productModel.generalDataInformationModel.movementEfect).isValid() ?
          moment(productModel.generalDataInformationModel.movementEfect).format('DD/MM/YYYY') : '',
      },
      {
        label: 'quoteBuyGenericFlow.offerIssuanceData.field.movementEndDate',
        value: moment(productModel.generalDataInformationModel.movementDue).isValid() ?
          moment(productModel.generalDataInformationModel.movementDue).format('DD/MM/YYYY') : '',
      },
    ];

    if (productModel.datosCabecera.codigoNegocio && productModel.datosCabecera.nombreNegocio) {
      data.push({
        label: productModel.datosCabecera.nombreNegocio,
        value: productModel.datosCabecera.codigoNegocio,
      });
    }

    return data;
  }

  /**
   * Validates warranties configuration. Only for 'Comunidades'.
   *
   * @param {GetWarrantiesResponseDataDatosGarantias[]} warranties
   * @returns {EAValidationError[] | undefined}
   * @memberof ProductComunidades
   */
  public validateWarranties(warranties: GetWarrantiesResponseDataDatosGarantias[]): EAValidationError[] | undefined {
    // TODO: Implement
    return [];
  }

  /**
   * Returns the expected secuential address code for a filiation code.
   * @param {Roles} role
   * @returns {string | undefined}
   */
  public getSecuentialAddressCode(role: Roles): string | undefined {
    return PersonUtils.getPersonByRole(this.personRoles, role)?.addressesModel.addEditAddress.selectedAddressCode;
  }

  /**
   * Generates offer data for saving service
   *
   * @param {QuoteBuyModel} model - product model
   * @param {string | undefined} filiationCodePagador
   * @returns { any } } generated offer
   */
  public generatePolicyData(
    model: TodoRiesgoConstruccionMontajeModel,
    filiationCodePagador?: string
  ): { codigoElemento: string; valorElemento: string | undefined }[] {
    const pagador = this.personRoles.find(person => person.role === Roles.Pagador);
    const ibanCode = pagador?.ibanCodeModel.qbIbanCodeModalModel.selectedIbanCode;
    const paymentForm = model.offerIssuanceBonusDataFormModel.paymentForms.find(
      payment => payment.nombreRestriccion === model.offerIssuanceBonusDataFormModel.paymentForm ||
      payment.valorRestriccion === model.offerIssuanceBonusDataFormModel.paymentForm
    )?.valorRestriccion;

    const policyElements = [
      {
        codigoElemento: 'TCCANCO1',
        valorElemento: model.offerIssuanceBillingDataFormModel.paymentChannelFirstReceipt,
      },
      {
        codigoElemento: 'TCCANCOS',
        valorElemento: model.offerIssuanceBillingDataFormModel.paymentChannelSuccessiveReceipts,
      },
      {
        codigoElemento: 'CLINTEC1',
        valorElemento: model.offerIssuanceBillingDataFormModel.debtCollectorIntermediaryFirstReceipt,
      },
      {
        codigoElemento: 'CLINTECS',
        valorElemento: model.offerIssuanceBillingDataFormModel.debtCollectorIntermediarySuccessiveReceipts,
      },
      {
        codigoElemento: 'TCFORPAG',
        valorElemento: paymentForm,
      },
      {
        codigoElemento: 'TCFOPASU',
        valorElemento: paymentForm,
      },
      {
        codigoElemento: 'CDIBANUM',
        valorElemento: ibanCode,
      },
      {
        codigoElemento: 'CLFILPAG',
        valorElemento: filiationCodePagador,
      },
      {
        codigoElemento: 'CDSECPAG',
        valorElemento: this.getSecuentialAddressCode(Roles.Pagador),
      },
    ];

    return policyElements;
  }

  /**
   * Sets default payment channel for this product
   * @param {QbGeneralDataInformationModel} generalDataInformationModel
   */
  public setDefaultPaymentChannel(generalDataInformationModel: QbGeneralDataInformationModel): void {
    generalDataInformationModel.paymentChannel = PaymentChannelEnum.Banco;
  }
  
  /**
   * Sets default payment channel for this product
   * @param {PersonRole} personModel
   * @param {GetGeneralDataResponseDataDatosObjeto[]} aditionalData
   * @param {TodoRiesgoConstruccionMontajeModel} productModel
   * @param {boolean} doSaveObjetoasegurado
   * @returns { GetGeneralDataResponseDataDatosObjeto[] } generated offer
   */
  public generateAditionalOfferData(
    personModel: PersonRole,
    aditionalData: GetGeneralDataResponseDataDatosObjeto[],
    productModel: TodoRiesgoConstruccionMontajeModel,
    doSaveObjetoasegurado: boolean): SaveOfferDataRequestElementosPoliza[] {
    // No implementation necessary for this product
    return [];
  }
}
