<template>
  <div class="slide d-display-flex d-direction-column d-justify-space-between h-100">
        <div>
          <h2 v-if="!hideFirstTitle"  class="t-color-primary">{{ $t('onboarding.cardPayment.secondSlide.title') }}</h2>

          <p class="m-b-0">
            {{ $t('onboarding.cardPayment.secondSlide.descriptionFirst') }}
          </p>
          <p>
            {{ $t('onboarding.cardPayment.secondSlide.descriptionSecond') }}
          </p>
        </div>
        
        <div class="d-display-flex d-align-items-center d-justify-center p-a-32 w-100 t-bg-color-gray-20">
          <img
            :src="require(`@/assets/images/onboarding/cardpayment/onboarding2.png`)"
            :alt="$t('onboarding.cardPayment.secondSlide.title').toString()"
          >
        </div>
      </div>
</template>
<script lang="ts">

import Vue from 'vue';
import {
  Component, Prop
} from 'vue-property-decorator';

@Component({
  name: 'card-payment-onboarding-2'
})

/**
 * Comunidades onboarding component
 */
export default class CardPaymentOnboarding2Component extends Vue {

  @Prop({
    required: false,
    'default': () => false,
  })
    hideFirstTitle?: boolean;
}
</script>
