<template>
  <div>
    <ea-form :model="model" ref="form">
      <ea-form-item
        prop="selectedNationality"
        :label="$t('selectNationality.fields.nationality')"
        :required="isRequired &&!consultaOperation"
      >
        <ea-select
          v-model="model.selectedNationality"
          :placeholder="$t('common.label.select')"
          @change="onNationalityChange"
          :readonly="consultaOperation"
        >
          <ea-option
            v-for="country in corpTableDocumentsCountry"
            :key="country.value"
            :label="country.label"
            :value="country.value"
          />
        </ea-select>
      </ea-form-item>
    </ea-form>
  </div>
</template>

<script lang="ts">
import {
  EABusinessComponent,
  EAMethod,
  EAError,
  EAApplicationLogger
} from '@zurich-es-npm/ea-front-web-core';
import Component, {
  mixins
} from 'vue-class-component';
import SelectNationalityModel from './select-nationality-model';
import {
  fetchCorporateTable, CorpTableNames, parseCorpTableDocuments, ParsedTableData
} from '@/utils/corporate-tables';
import {
  Prop
} from 'vue-property-decorator';
import {
  EAValidation, Form
} from '@zurich-es-npm/ea-front-web-ui';

@Component({
  name: 'select-nationality'
})

/**
 * Business Component select-nationality
 */
export default class SelectNationalityBusiness extends mixins<EABusinessComponent<SelectNationalityModel>>(
  EABusinessComponent
) {
  
  @Prop({
    required: false,
    'default': () => true
  })
    isRequired?: boolean;

  public corpTableDocumentsCountry: ParsedTableData[] = [];

  @Prop()
    consultaOperation?: boolean;

  /**
   * Hook on create
   */
  created() {
    this.getCorpTablesData();
  }

  /**
   * Fetch corporate tables to retrieve products names and parse data.
   */
  @EAMethod({
    loading:true
  })
  async getCorpTablesData(): Promise<void> {
    try {
      const result = await fetchCorporateTable(CorpTableNames.Countries);
      this.corpTableDocumentsCountry =
        parseCorpTableDocuments(result)
          .filter(corpTableDocumentCountry => corpTableDocumentCountry.value !== 'ESP');
    } catch (error) {
      const eaError = error as EAError;
      new EAApplicationLogger().error(
        `SelectNationalityBusiness::getCorpTablesData:: fetch corporate table :: ${eaError.message}`
      );
      throw error;
    }
  }

  /**
   * Handles nationality selector change => Updates parent
   */
  @EAMethod()
  onNationalityChange() {
    this.$nextTick(() => {
      this.update();
      this.$emit('updateViewModel');
    });
  }

  /**
   * Returns validation object
   * @returns {EAValidation }
   */
  public validation(): EAValidation {
    const form = this.$refs.form as Form;
    return form?.validation();
  }
}
</script>
