<template>
  <div>
    <ea-card shadow="hidden">
      <div slot="cardbody" class="p-a-16">
        <h2 class="m-b-8">{{ $t(`fleets.fleetsFlow.fleetQuotePaymentForm.title`) }}</h2>

        <ea-form
          ref="refFleetQuotePaymentForm"
          :model="model"
          :validationOptions="formValidation"
          :validateOnRuleChange="false"
        >
          <ea-row>
            <ea-col :span="6">
              <ea-form-item
                :label="$t('fleets.fleetsFlow.fleetQuotePaymentForm.dropdownLabel')"
                prop="paymentForm"
                :required="!isReadonly"
              >
                <ea-select v-model="model.paymentForm" :readonly="isReadonly">
                  <ea-option
                    v-for="option of paymentFormOptions"
                    :key="option"
                    :label="$t(`fleets.fleetsFlow.fleetQuotePaymentForm.options.${option}`)"
                    :value="option"
                  />
                </ea-select>
              </ea-form-item>
            </ea-col>

            <ea-col :span="18" v-if="showUpdateButton" class="d-display-flex d-align-items-center">
              <ea-form-item>
                <ea-button type="secondary" @click="onUpdateButtonClick">
                  {{ $t('fleets.fleetsFlow.fleetQuotePaymentForm.update') }}
                </ea-button>
              </ea-form-item>
              <span class="m-l-24">
                  {{ $t('fleets.fleetsFlow.fleetQuotePaymentForm.updateMessageAdvisement') }}
              </span>
            </ea-col>
          </ea-row>
        </ea-form>
      </div>
    </ea-card>
  </div>
</template>

<script lang="ts">
import {
  Component, Prop
} from 'vue-property-decorator';

import {
  mixins
} from 'vue-class-component';

import {
  EABusinessComponent
} from '@zurich-es-npm/ea-front-web-core';

import FleetQuotePaymentFormModel from './fleet-quote-payment-form-model';
import {
  EAFormValidationOptions, eaRequiredValidation
} from '@zurich-es-npm/ea-front-web-ui';

@Component({
  name: 'fleet-quote-payment-form',
})

/**
 * Business Component fleet-quote-payment-form
 */
export default class FleetQuotePaymentFormBusiness extends mixins<EABusinessComponent<FleetQuotePaymentFormModel>>(
  EABusinessComponent
) {

  @Prop({
    required: true,
  })
    parentPaymentForm!: string;

  @Prop({
    required: true,
  })
    paymentFormOptions!: string[];

  @Prop({
    required: true,
  })
    defaultPaymentFormOption!: string;

  @Prop()
    isReadonly?: boolean;

  public formValidation: EAFormValidationOptions = {
    rules: {
      paymentForm: [eaRequiredValidation('common.label.validation.fieldRequired')],
    },
  };

  /**
   * Indicates if the update button should be shown
   */
  get showUpdateButton(): boolean {

    // Do not show if paymentForm is default
    if (this.parentPaymentForm === '' && this.model.paymentForm === this.defaultPaymentFormOption) {
      return false;
    }

    return this.parentPaymentForm !== this.model.paymentForm;
  }

  /**
   * Lifecycle hook
   */
  public created(): void {
    this.model.paymentForm = this.model.paymentForm || this.defaultPaymentFormOption;
  }

  /**
   * Updates payment
   */
  onUpdateButtonClick() {
    this.$emit('updatePaymentForm', this.model.paymentForm);
  }
}
</script>
