export interface DriverListInterface {
  plateNumber?: string;
  brand?: string;
  model?: string;
  version?: string;
  documentNumber?: string;
  firstName?: string;
  lastName?: string;
  offerNumber?: string;
  offerVersion?: number;
}

/** 
 * Model fleets-intervening-driver-table
 */
class FleetsInterveningDriverTableModel {

  public driverList: DriverListInterface[] = [];
  
}

export default FleetsInterveningDriverTableModel;
