import {
  GetMovementListByPolicyNumberResponseListaPolizas
} from '@/services/V1/policy/getMovementListByPolicyNumberOperation/post';

export interface PaginationData<T> {
  items: T[];
  currentPage: number;
  isLastPage: boolean | undefined;
  status: HistoryTableStatus;
}

export enum HistoryTableStatus {
  NOT_LOADED = 'NOT_LOADED',
  LOADING = 'LOADING',
  LOADED = 'LOADED',
  ERROR_LOADING = 'ERROR_LOADING'
}

export interface ColumnItem {
  propKey: string;
  labelKey?: string;
  colType?: ColumnItemType;
}

export enum ColumnItemType {
  TextButton = 'text-button',
  Button = 'button',
  Text = 'text',
  Date = 'date',
  IconButton = 'IconButton'
}

/** 
 * Model qb-policy-history-modal
 */
class QbPolicyHistoryModalModel {
  public showHistoryPolicyModal?: boolean = false;

  public policySelected: string = '';

  public versionPolicySelected: number = 0;

  public policy?: GetMovementListByPolicyNumberResponseListaPolizas = undefined;
}

export default QbPolicyHistoryModalModel;
