<template>
  <ea-card class="t-bg-color-primary-10 p-a-16 m-b-32">
    <div slot="cardbody" class="d-display-flex">
      <slot /> <!-- Searcher + proficiency mgmt-->

      <div class="d-display-flex d-direction-column w-75 d-justify-space-between"
      >
        <div class="d-display-flex d-justify-center m-t-24">
          <!-- INLINE -->
          <warranties-header-inline-selection
            id="warrantiesGroupInlineSelection"
            v-if="showWarrantiesGroups &&
            warrantiesGroupsDisplayMode === EXPOSE_WARRANTIES_HEADER_DISPLAY_MODE.INLINE &&
            !consultaOperation"
            v-model="model.warrantiesGroupsInlineSelectionModel"
            :optionsToSelect="warrantiesGroups"
            :canSelectMultipleOptions="canSelectMultipleWarrantiesGroups"
            @selectedOptionChange="onSelectedWarrantiesGroupsChange"
          ></warranties-header-inline-selection>

          <warranties-header-inline-selection
            v-if="showPackagesGroups &&
            warrantiesGroupsDisplayMode === EXPOSE_WARRANTIES_HEADER_DISPLAY_MODE.INLINE &&
            !consultaOperation"
            id="packagesGroupsInlineSelection"
            v-model="model.packagesGroupsInlineSelectionModel"
            :optionsToSelect="packagesGroups"
            :canSelectMultipleOptions="canSelectMultiplePackagesGroups"
            @selectedOptionChange="onSelectedPackagesGroupsChange"
          ></warranties-header-inline-selection>

          <!-- GRID -->
          <warranties-header-grid-selection
            id="warrantiesGroupGridSelection"
            v-if="showWarrantiesGroups &&
            warrantiesGroupsDisplayMode === EXPOSE_WARRANTIES_HEADER_DISPLAY_MODE.GRID &&
            !consultaOperation"
            v-model="model.warrantiesGroupsGridSelectionModel"
            :optionsToSelect="warrantiesGroups"
            :canSelectMultipleOptions="canSelectMultipleWarrantiesGroups"
            @selectedOptionChange="onSelectedWarrantiesGroupsChange"
          ></warranties-header-grid-selection>

          <warranties-header-grid-selection
            v-if="showPackagesGroups &&
            warrantiesGroupsDisplayMode === EXPOSE_WARRANTIES_HEADER_DISPLAY_MODE.GRID &&
            !consultaOperation"
            id="packagesGroupsGridSelection"
            v-model="model.packagesGroupsGridSelectionModel"
            :optionsToSelect="packagesGroups"
            :canSelectMultipleOptions="canSelectMultiplePackagesGroups"
            @selectedOptionChange="onSelectedPackagesGroupsChange"
          ></warranties-header-grid-selection>
        </div>
        <div v-if="showTarificationButton"
          class="d-display-flex d-justify-flex-end d-align-items-center"
        >
          <ea-tooltip placement="top" class="d-display-inline">
            <ea-icon
              icon="z-info"
              status="warning"
              size="large" />
            <div slot="content">
                {{ $t('warranties.header.tooltips.tarification.first') }}<br/>
                {{ $t('warranties.header.tooltips.tarification.second') }}<br/>
                {{ $t('warranties.header.tooltips.tarification.third') }}
            </div>
          </ea-tooltip>
          <ea-button
            class="m-l-8"
            type="warning"
            size="medium"
            @click="callTarification">
            {{ $t('warranties.pricing') }}
          </ea-button>
        </div>
      </div>
    </div>
    
  </ea-card>
</template>

<script lang="ts">
import {
  Component, Prop
} from 'vue-property-decorator';

import {
  mixins
} from 'vue-class-component';

import {
  EABusinessComponent, EAMethod
} from '@zurich-es-npm/ea-front-web-core';

import WarrantiesHeaderModel from './warranties-header-model';
import {
  LabelValue
} from '../../qb-producto-asegurado-comunidades/qb-producto-asegurado-comunidades-model';
import WarrantiesHeaderGridSelectionBusiness
  from '../warranties-header-grid-selection/warranties-header-grid-selection-business.vue';
import WarrantiesHeaderInlineSelectionBusiness
  from '../warranties-header-inline-selection/warranties-header-inline-selection-business.vue';

export enum WARRANTIES_HEADER_DISPLAY_MODE {
  INLINE = 'INLINE',
  GRID = 'GRID'
}

export enum WARRANTIES_HEADER_SELECTIONS {
  WARRANTIES_GROUPS = 'WARRANTIES_GROUPS',
  PACKAGES_GROUPS = 'PACKAGES_GROUPS'
}

export interface WarrantiesHeaderConfig {
  showWarrantiesGroups: boolean,
  canSelectMultipleWarrantiesGroups: boolean,
  warrantiesGroupsDisplayMode: WARRANTIES_HEADER_DISPLAY_MODE | undefined,
  showPackagesGroups: boolean,
  canSelectMultiplePackagesGroups: boolean,
  packagesGroupsDisplayMode: WARRANTIES_HEADER_DISPLAY_MODE | undefined,
}

@Component({
  name: 'warranties-header',
  components: {
    WarrantiesHeaderInlineSelection: WarrantiesHeaderInlineSelectionBusiness,
    WarrantiesHeaderGridSelection: WarrantiesHeaderGridSelectionBusiness
  }
})

/**
 * Business Component warranties-header
 */
export default class WarrantiesHeaderBusiness extends mixins<
EABusinessComponent<WarrantiesHeaderModel>
>(EABusinessComponent) {

  @Prop()
    warrantiesGroups!: LabelValue[];

  @Prop({
    required: true,
  })
    showWarrantiesGroups!: boolean;

  @Prop({
    required: true,
  })
    canSelectMultipleWarrantiesGroups!: boolean;

  @Prop({
    required: true,
  })
    warrantiesGroupsDisplayMode!: WARRANTIES_HEADER_DISPLAY_MODE;

  @Prop()
    packagesGroups!: string[];

  @Prop({
    required: true,
  })
    showPackagesGroups!: boolean;

  @Prop({
    required: true,
  })
    canSelectMultiplePackagesGroups!: boolean;

  @Prop({
    required: true,
  })
    packagesGroupsDisplayMode!: WARRANTIES_HEADER_DISPLAY_MODE;

  @Prop({
    required: true,
  })
    canLoadMoreOptions!: boolean;

  @Prop()
    consultaOperation?: boolean;

  public showTarificationButton: boolean = false;

  public whichSelectionsChanged: WARRANTIES_HEADER_SELECTIONS[] = [];

  public EXPOSE_WARRANTIES_HEADER_DISPLAY_MODE = WARRANTIES_HEADER_DISPLAY_MODE;

  /**
   * Handles tarification button
   * Emits event to parent view
   */
  @EAMethod()
  public callTarification() {
    this.$emit('callTarification', this.whichSelectionsChanged);
  }

  /**
   * Shows tarification button
   */
  showRequoteButton() {
    this.showTarificationButton = true;
  }

  /**
   * Hides tarification button
   */
  hideRequoteButton() {
    this.showTarificationButton = false;
  }

  /**
   * Handles selected warranties groups changes
   */
  onSelectedWarrantiesGroupsChange(): void {
    this.showTarificationButton = true;
    this.$emit('showEmptyWarrantiesMessage', this.$t('warranties.refreshCard.title.warrantyGroups').toString());

    if (!this.whichSelectionsChanged.includes(WARRANTIES_HEADER_SELECTIONS.WARRANTIES_GROUPS)) {
      this.whichSelectionsChanged.push(WARRANTIES_HEADER_SELECTIONS.WARRANTIES_GROUPS);
    }

    this.update();
  }

  /**
   * Handles selected packages groups changes
   */
  onSelectedPackagesGroupsChange(): void {
    this.showTarificationButton = true;
    this.$emit('showEmptyWarrantiesMessage', this.$t('warranties.refreshCard.title.packageGroups').toString());

    if (!this.whichSelectionsChanged.includes(WARRANTIES_HEADER_SELECTIONS.PACKAGES_GROUPS)) {
      this.whichSelectionsChanged.push(WARRANTIES_HEADER_SELECTIONS.PACKAGES_GROUPS);
    }

    this.update();
  }
}
</script>
