import {
  GetDocumentationDataResponseListaDocumentos
} from '@/services/V1/quoteAndBuy/getDocumentationDataOperation/post';
import MultipleEmailInputModel from '../multiple-email-input/multiple-email-input-model';

/** 
 * Model qb-documentation-select-documentation
 */
class QbDocumentationSelectDocumentationModel {

  public documentList: GetDocumentationDataResponseListaDocumentos[] = [];

  public multipleEmailInputModel: MultipleEmailInputModel = new MultipleEmailInputModel();

}

export default QbDocumentationSelectDocumentationModel;
