/** 
 * Model fleet-group-vehicle-table
 */
class FleetGroupVehicleTableModel {
  public hiddenTotal: boolean = false;
}

export interface RefreshVehiclesDataEmitParams {
  checkFleetComposition: boolean;
}

export default FleetGroupVehicleTableModel;
