<!-- eslint-disable max-lines -->
<template>
  <ea-form ref="form" :model="model" :validationOptions="validationOptions" :validateOnRuleChange="false">
    <ea-card>
      <div slot="cardbody" class="p-a-24">
        <!-- Datos del inmueble -->
        <qb-risk-situation
          id="qb-risk-situation"
          ref="riskSituationBC"
          v-model="model.riskSituation"
          :datosObjeto="datosObjeto"
          :consultaOperation="consultaOperation"
          :isElementPrintable="isElementPrintable"
        ></qb-risk-situation>
        
        <ea-row v-if="isElementPrintable(['TXDESOB1'])">
          <ea-col :span="12">
            <ea-form-item
              v-if="!consultaOperation || !elementValueIsAnEmptyString('TXDESOB1', datosObjeto)"
              prop="riskExtraInfo"
              :label="$t('quoteBuyGenericFlow.propertyData.field.riskExtraInfo')"
              :required="!consultaOperation && isElementRequired('TXDESOB1')">
              <div slot="tooltipIconInfo">
                <div slot="content" class="t-align-center">
                  <span>
                    {{ $t('quoteBuyGenericFlow.generalCharacteristics.field.riskExtraInfoToolTipFirstLine') }}<br/>
                    {{ $t('quoteBuyGenericFlow.generalCharacteristics.field.riskExtraInfoToolTipSecondLine') }}
                  </span>
                </div>
              </div>
              <ea-input-text v-model="model.riskExtraInfo" :readonly="!isElementModificable('TXDESOB1')"/>
            </ea-form-item>
          </ea-col>
        </ea-row>
        <!-- Año de construcción -->
        <ea-row v-if="isElementPrintable(['AACONSTR'])">
          <ea-col :span="24">
            <ea-heading level="5" class="m-b-16">
              {{ $t('quoteBuyGenericFlow.generalCharacteristics.field.constructionYearTitle') }}
            </ea-heading>
          </ea-col>
        </ea-row>
        <ea-row v-if="isElementPrintable(['AACONSTR'])">
          <ea-col :span="6">
            <ea-form-item
              prop="constructionYear"
              :label="$t('quoteBuyGenericFlow.generalCharacteristics.field.constructionYear')"
              :required="!consultaOperation && isElementRequired('AACONSTR')"
            >
              <ea-input-number
                :use-grouping="false"
                v-model="model.constructionYear"
                :readonly="!isElementModificable('AACONSTR')"
                :precision="returnElementMaxDecimalsByCode('AACONSTR')"
                :placeholder="consultaOperation ? '' : $t('common.label.year')"
                @change="onConstructionYearChange()"
              />
            </ea-form-item>
          </ea-col>
        </ea-row>
        <!-- Año de reformas -->
        <ea-row v-if="isElementPrintable(['AAREFOFA','AAREFOCU','AAREFOEL','AAREFOAG'])">
          <ea-col :span="24">
            <ea-heading level="5" class="m-b-16">
              {{ $t('quoteBuyGenericFlow.generalCharacteristics.field.remodelingYearTitle') }}
            </ea-heading>
            <!-- TODO AM -->
            <p class="t-weight-normal font-size-14 m-b-16">
              {{ $t('quoteBuyGenericFlow.generalCharacteristics.field.remodelingYearSubtitle') }}
            </p>
          </ea-col>
        </ea-row>
        <ea-row v-if="isElementPrintable(['AAREFOFA','AAREFOCU','AAREFOEL','AAREFOAG'])">
          <ea-col v-if="isElementPrintable(['AAREFOFA'])" :span="6">
            <ea-form-item
              prop="remodelingYear"
              :label="$t('quoteBuyGenericFlow.generalCharacteristics.field.remodelingYear')"
              :required="!consultaOperation && isElementRequired('AAREFOFA')"
            >
              <div slot="tooltipIconInfo">
                <div slot="content" class="t-align-center">
                  <span>
                    {{ $t('quoteBuyGenericFlow.generalCharacteristics.field.remodelingYearTooltipFirstline') }}<br/>
                    {{ $t('quoteBuyGenericFlow.generalCharacteristics.field.remodelingYearTooltipSecondLine') }}
                  </span>
                </div>
              </div>
              <ea-input-text
                v-if="elementValueIsAnEmptyString('AAREFOFA', datosObjeto) && consultaOperation"
                readonly
                :value="$t('quoteBuyGenericFlow.generalCharacteristics.field.noRemodeling')"
              />
              <ea-input-number
                v-else
                :use-grouping="false"
                v-model="model.remodelingYear"
                :readonly="!isElementModificable('AAREFOFA')"
                :precision="returnElementMaxDecimalsByCode('AAREFOFA')"
                :placeholder="$t('common.label.year')"
              />

            </ea-form-item>
          </ea-col>
          <ea-col v-if="isElementPrintable(['AAREFOCU'])" :span="6">
            <ea-form-item
              prop="deckYear"
              :label="$t('quoteBuyGenericFlow.generalCharacteristics.field.deckYear')"
              :required="!consultaOperation && isElementRequired('AAREFOCU')"
            >
              <div slot="tooltipIconInfo" class="t-align-center">
                <div slot="content">
                  <span>
                    {{ $t('quoteBuyGenericFlow.generalCharacteristics.field.deckYearTooltipFirstline') }}<br/>
                    {{ $t('quoteBuyGenericFlow.generalCharacteristics.field.deckYearTooltipSecondLine') }}
                  </span>
                </div>
              </div>
              <ea-input-text
                v-if="elementValueIsAnEmptyString('AAREFOCU', datosObjeto) && consultaOperation"
                readonly
                :value="$t('quoteBuyGenericFlow.generalCharacteristics.field.noRemodeling')"
              />
              <ea-input-number
                v-else
                :use-grouping="false"
                v-model="model.deckYear"
                :readonly="!isElementModificable('AAREFOCU')"
                :precision="returnElementMaxDecimalsByCode('AAREFOCU')"
                :placeholder="$t('common.label.year')"
              />
            </ea-form-item>
          </ea-col>
          <ea-col v-if="isElementPrintable(['AAREFOEL'])" :span="6">
            <ea-form-item
              prop="electricityYear"
              :label="$t('quoteBuyGenericFlow.generalCharacteristics.field.electricityYear')"
              :required="!consultaOperation && isElementRequired('AAREFOEL')"
            >
              <div slot="tooltipIconInfo" class="t-align-center">
                <div slot="content">
                  <span>{{ $t('quoteBuyGenericFlow.generalCharacteristics.field.electricityYearTooltip') }}</span>
                </div>
              </div>
              <ea-input-text
                v-if="elementValueIsAnEmptyString('AAREFOEL', datosObjeto) && consultaOperation"
                readonly
                :value="$t('quoteBuyGenericFlow.generalCharacteristics.field.noRemodeling')"
              />
              <ea-input-number
                v-else
                :use-grouping="false"
                v-model="model.electricityYear"
                :readonly="!isElementModificable('AAREFOEL')"
                :precision="returnElementMaxDecimalsByCode('AAREFOEL')"
                :placeholder="$t('common.label.year')"
              />
            </ea-form-item>
          </ea-col>
          <ea-col v-if="isElementPrintable(['AAREFOAG'])" :span="6">
            <ea-form-item
              prop="waterYear"
              id="waterYear"
              :label="$t('quoteBuyGenericFlow.generalCharacteristics.field.waterYear')"
              :required="!consultaOperation && isElementRequired('AAREFOAG')"
            >
              <div slot="tooltipIconInfo" class="t-align-center">
                <div slot="content">
                  <span>
                    {{ $t('quoteBuyGenericFlow.generalCharacteristics.field.waterYearTooltipFirstline') }}<br/>
                    {{ $t('quoteBuyGenericFlow.generalCharacteristics.field.waterYearTooltipSecondLine') }}
                  </span>
                </div>
              </div>
              <ea-input-text
                v-if="elementValueIsAnEmptyString('AAREFOAG', datosObjeto) && consultaOperation"
                readonly
                :value="$t('quoteBuyGenericFlow.generalCharacteristics.field.noRemodeling')"
              />
              <ea-input-number
                v-else
                :use-grouping="false"
                v-model="model.waterYear"
                :readonly="!isElementModificable('AAREFOAG')"
                :precision="returnElementMaxDecimalsByCode('AAREFOAG')"
                :placeholder="$t('common.label.year')"
                @blur="setCommunitaryWaterVisibility()"
                @keydown.native.tab="handleCommunitaryWaterVisibility"
              />
            </ea-form-item>
          </ea-col>
        </ea-row>
        <ea-row v-if="isElementPrintable(['CDNIREAG'])">
          <ea-col  :span="12" v-if="showCommunitaryWater  && isElementPrintable(['CDNIREAG'])" >
            <ea-form-item
              prop="comunitaryWater"
              id="comunitaryWater"
              :label="$t('quoteBuyGenericFlow.generalCharacteristics.field.comunitaryWater')"
              :required="!consultaOperation && isElementRequired('CDNIREAG')"
            >
              <ea-select
                v-model="model.comunitaryWater"
                :placeholder="$t('common.label.select')"
                :readonly="!isElementModificable('CDNIREAG')"
              >
                <ea-option
                  v-for="comunitaryWater in comunitaryWaterList"
                  :key="comunitaryWater.value"
                  :label="comunitaryWater.label"
                  :value="comunitaryWater.value"
                />
              </ea-select>
            </ea-form-item>
          </ea-col>
        </ea-row>
        <!-- Características generales -->
        <ea-row v-if="isElementPrintable(
            ['TCTIPROC',
            'TCUSOINM',
            'NUEDIFIC',
            'NUESCBLO',
            'NUVIVIEN',
            'NUASCTOT',
            'NUALTEDI',
            'NUALTBRA',
            'TCAGUACO',
            'CDACLOCO',
            'CDNIVCEC',
            'CDPOLECO'
            ])">
          <ea-col :span="24">
            <ea-heading level="5" class="m-b-16">
              {{ $t('quoteBuyGenericFlow.generalCharacteristics.title') }}
            </ea-heading>
          </ea-col>
          <ea-col v-if="isElementPrintable(['TCTIPROC'])"  :span="6">
            <ea-form-item
              prop="ownerType"
              :label="$t('quoteBuyGenericFlow.generalCharacteristics.field.ownerType')"
              :required="!consultaOperation && isElementRequired('TCTIPROC')"
            >
              <ea-select
                v-model="model.ownerType"
                :placeholder="consultaOperation ? '' : $t('common.label.select')"
                :readonly="!isElementModificable('TCTIPROC')"
              >
                <ea-option
                  v-for="ownerType in ownerTypeList"
                  :key="ownerType.value"
                  :label="ownerType.label"
                  :value="ownerType.value"
                />
              </ea-select>
            </ea-form-item>
          </ea-col>
          <ea-col v-if="isElementPrintable(['TCUSOINM'])" :span="6">
            <ea-form-item
              prop="realEstateDestination"
              :label="$t('quoteBuyGenericFlow.generalCharacteristics.field.realEstateDestination')"
              :required="!consultaOperation && isElementRequired('TCUSOINM')"
            >
              <ea-select
                v-model="model.realEstateDestination"
                :placeholder="$t('common.label.select')"
                :readonly="!isElementModificable('TCUSOINM')"
                @change="realEstateDestinationHandler()"
              >
                <ea-option
                  v-for="realEstateDestination in realEstateDestinationList"
                  :key="realEstateDestination.value"
                  :label="realEstateDestination.label"
                  :value="realEstateDestination.value"
                />
              </ea-select>
            </ea-form-item>
          </ea-col>
          <ea-col v-if="isElementPrintable(['NUEDIFIC'])" :span="6">
            <ea-form-item
              prop="buildingNumber"
              :label="$t('quoteBuyGenericFlow.generalCharacteristics.field.buildingNumber')"
              :required="!consultaOperation && isElementRequired('NUEDIFIC')"
              v-if="model.realEstateDestination !== 'UF'"
            >
              <ea-input-number v-model="model.buildingNumber" :readonly="!isElementModificable('NUEDIFIC')"
                :precision="returnElementMaxDecimalsByCode('NUEDIFIC')"
              />
            </ea-form-item>
          </ea-col>
          <ea-col v-if="isElementPrintable(['NUESCBLO'])" :span="6">
            <ea-form-item
              prop="blockStairNumber"
              :label="$t('quoteBuyGenericFlow.generalCharacteristics.field.blockStairNumber')"
              :required="!consultaOperation && isElementRequired('NUESCBLO')"
              v-if="model.realEstateDestination !== 'UF'"
            >
              <ea-input-number v-model="model.blockStairNumber" :readonly="!isElementModificable('NUESCBLO')"
                :precision="returnElementMaxDecimalsByCode('NUESCBLO')"
              />
            </ea-form-item>
          </ea-col>
        </ea-row>
        <ea-row v-if="isElementPrintable(['NUVIVIEN','NUASCTOT','NUPLAGAR','NUALTEDI','NUALTBRA'])">
          <ea-col v-if="isElementPrintable(['NUPLAGAR'])" :span="6">
            <ea-form-item
              prop="parkingSpacesNumber"
              :label="$t('quoteBuyGenericFlow.generalCharacteristics.field.parkingSpacesNumber')"
              :required="!consultaOperation && isElementRequired('NUPLAGAR')"
            >
              <ea-input-number v-model="model.parkingSpacesNumber" :readonly="!isElementModificable('NUPLAGAR')"
                :precision="returnElementMaxDecimalsByCode('NUPLAGAR')"
              />
            </ea-form-item>
          </ea-col>
          <ea-col v-if="isElementPrintable(['NUVIVIEN'])" :span="6">
            <ea-form-item
              prop="livingPlaceNumber"
              :label="$t('quoteBuyGenericFlow.generalCharacteristics.field.livingPlaceNumber')"
              :required="!consultaOperation && isElementRequired('NUVIVIEN')"
            >
              <ea-input-number v-model="model.livingPlaceNumber" :readonly="!isElementModificable('NUVIVIEN')"
                :precision="returnElementMaxDecimalsByCode('NUVIVIEN')"
              />
            </ea-form-item>
          </ea-col>
          <ea-col v-if="isElementPrintable(['NUASCTOT'])" :span="6">
            <ea-form-item
              prop="elevatorNumber"
              :label="$t('quoteBuyGenericFlow.generalCharacteristics.field.elevatorNumber')"
              :required="!consultaOperation && isElementRequired('NUASCTOT')"
            >
              <ea-input-number v-model="model.elevatorNumber" :readonly="!isElementModificable('NUASCTOT')"
                :precision="returnElementMaxDecimalsByCode('NUASCTOT')"
              />
            </ea-form-item>
          </ea-col>
          <ea-col v-if="isElementPrintable(['NUALTEDI'])" :span="6">
            <ea-form-item
              prop="buildingPlant"
              :label="$t('quoteBuyGenericFlow.generalCharacteristics.field.buildingPlant')"
              :required="!consultaOperation && isElementRequired('NUALTEDI')"
            >
              <ea-input-number v-model="model.buildingPlant" :readonly="!isElementModificable('NUALTEDI')"
                :precision="returnElementMaxDecimalsByCode('NUALTEDI')"
              />
            </ea-form-item>
          </ea-col>
          <ea-col v-if="isElementPrintable(['NUALTBRA'])" :span="6">
            <ea-form-item
              prop="groundFloor"
              :label="$t('quoteBuyGenericFlow.generalCharacteristics.field.groundFloor')"
              :required="!consultaOperation && isElementRequired('NUALTBRA')"
            >
              <ea-input-number v-model="model.groundFloor" :readonly="!isElementModificable('NUALTBRA')"
                :precision="returnElementMaxDecimalsByCode('NUALTBRA')"
              />
            </ea-form-item>
          </ea-col>
        </ea-row>
        <ea-row v-if="isElementPrintable(['TCAGUACO','CDACLOCO','CDNIVCEC','CDPOLECO'])">
          <ea-col v-if="isElementPrintable(['TCAGUACO'])" :span="6">
            <ea-form-item
              prop="communityWaterFacilities"
              :label="$t('quoteBuyGenericFlow.generalCharacteristics.field.communityWaterFacilities')"
              :required="!consultaOperation && isElementRequired('TCAGUACO')"
            >
              <ea-select
                v-model="model.communityWaterFacilities"
                :placeholder="$t('common.label.select')"
                :readonly="!isElementModificable('TCAGUACO')"
              >
                <ea-option
                  v-for="communityWaterFacilities in communityWaterFacilitiesList"
                  :key="communityWaterFacilities.value"
                  :label="communityWaterFacilities.label"
                  :value="communityWaterFacilities.value"
                />
              </ea-select>
            </ea-form-item>
          </ea-col>
          <ea-col v-if="isElementPrintable(['CDACLOCO'])" :span="6">
            <ea-form-item
              prop="businessPremisesActivity"
              :label="$t('quoteBuyGenericFlow.generalCharacteristics.field.businessPremisesActivity')"
            >
              <ea-select
                v-model="model.businessPremisesActivity"
                :placeholder="$t('common.label.select')"
                :readonly="!isElementModificable('CDACLOCO')"
              >
                <ea-option
                  v-for="businessPremisesActivity in businessPremisesActivityList"
                  :key="businessPremisesActivity.value"
                  :label="businessPremisesActivity.label"
                  :value="businessPremisesActivity.value"
                />
              </ea-select>
            </ea-form-item>
          </ea-col>
          <ea-col v-if="isElementPrintable(['CDNIVCEC'])" :span="6">
            <ea-form-item
              v-if="!consultaOperation || model.ecologicalCertification"
              prop="ecologicalCertification"
              :label="$t('quoteBuyGenericFlow.generalCharacteristics.field.ecologicalCertification')"
            >
              <ea-select
                v-model="model.ecologicalCertification"
                :placeholder="$t('common.label.select')"
                :readonly="!isElementModificable('CDNIVCEC')"
              >
                <ea-option
                  v-for="ecologicalCertification in ecologicalCertificationList"
                  :key="ecologicalCertification.value"
                  :label="ecologicalCertification.label"
                  :value="ecologicalCertification.value"
                />
              </ea-select>
            </ea-form-item>
          </ea-col>
          <ea-col v-if="isElementPrintable(['CDPOLECO'])" :span="6">
            <ea-form-item
              prop="ecologicalPolicy"
              v-if="model.ecologicalPolicy"
              :label="$t('quoteBuyGenericFlow.generalCharacteristics.field.ecologicalPolicy')"
            >
              <ea-select
                v-model="model.ecologicalPolicy"
                :placeholder="$t('common.label.select')"
                readonly
              >
                <ea-option
                  v-for="ecologicalPolicy in ecologicalPolicyList"
                  :key="ecologicalPolicy.value"
                  :label="ecologicalPolicy.label"
                  :value="ecologicalPolicy.value"
                />
              </ea-select>
            </ea-form-item>
          </ea-col>
        </ea-row>
        <ea-row v-if="!elementValueIsAnEmptyString('INCOFICO', datosObjeto)">
          <ea-col :span="6">
            <div class="w-100">
              <ea-form-item
                prop="fico"
                :label="$t('quoteBuyGenericFlow.generalCharacteristics.field.fico')"
              >
                <ea-input-text :value="$t(`common.label.${model.fico === 'S' ? 'yes' : 'no'}`)" :readonly="true" />
              </ea-form-item>
            </div>
          </ea-col>
        </ea-row>
        <!-- Superficie -->
        <ea-row v-if="isElementPrintable(['NUSUPINM','NUSUPJAR','NUSUPGAR','NUSUPTOT','INCOMS25'])">
          <ea-col :span="24">
            <ea-heading level="5" class="m-b-16">
              {{ $t('quoteBuyGenericFlow.surface.title') }}
            </ea-heading>
          </ea-col>
        </ea-row>
        <ea-row v-if="isElementPrintable(['NUSUPINM','NUSUPJAR','NUSUPGAR','NUSUPTOT'])">
          <ea-col v-if="isElementPrintable(['NUSUPINM'])" :span="6">
            <ea-form-item
              prop="propertySize"
              :label="$t('quoteBuyGenericFlow.surface.field.propertySize')"
              :required="!consultaOperation && isElementRequired('NUSUPINM')"
            >
              <ea-input-number
                v-model="model.propertySize"
                :readonly="!isElementModificable('NUSUPINM')"
                :precision="returnElementMaxDecimalsByCode('NUSUPINM')"
              />
            </ea-form-item>
          </ea-col>
          <ea-col v-if="isElementPrintable(['NUSUPJAR'])" :span="6">
            <ea-form-item
              prop="backyardSize"
              :label="$t('quoteBuyGenericFlow.surface.field.backyardSize')"
              :required="!consultaOperation && isElementRequired('NUSUPJAR')"
              v-if="!isRealEstateDestinationEqualGR"
            >
              <ea-input-number
                v-model="model.backyardSize"
                :readonly="!isElementModificable('NUSUPJAR')"
                :precision="returnElementMaxDecimalsByCode('NUSUPJAR')"
              />
            </ea-form-item>
          </ea-col>
          <ea-col v-if="isElementPrintable(['NUSUPGAR'])" :span="6">
            <ea-form-item
              prop="garageSize"
              :label="$t('quoteBuyGenericFlow.surface.field.garageSize')"
              :required="!consultaOperation && isElementRequired('NUSUPGAR')"
              v-if="!isRealEstateDestinationEqualGR"
            >
              <ea-input-number
                v-model="model.garageSize"
                :readonly="!isElementModificable('NUSUPGAR')"
                :precision="returnElementMaxDecimalsByCode('NUSUPGAR')"
              />
            </ea-form-item>
          </ea-col>
          <ea-col v-if="isElementPrintable(['NUSUPTOT'])" :span="6">
            <ea-form-item
              prop="propertyTotalSize"
              :label="$t('quoteBuyGenericFlow.surface.field.propertyTotalSize')"
              :required="!consultaOperation && isElementRequired('NUSUPTOT')"
            >
              <ea-input-number
                v-model="model.propertyTotalSize"
                :readonly="true"
                :precision="returnElementMaxDecimalsByCode('NUSUPTOT')"
              />
            </ea-form-item>
          </ea-col>
        </ea-row>
        <ea-row v-if="isElementPrintable(['INCOMS25'])">
          <ea-col v-if="isElementPrintable(['INCOMS25'])" :span="24">
            <ea-form-item
              prop="moreThan25InCommerce"
              :label="$t('quoteBuyGenericFlow.surface.field.moreThan25InCommerce')"
              :required="!consultaOperation && isElementRequired('INCOMS25')"
            >
              <ea-input-text
                v-if="consultaOperation"
                :value="$t(`common.label.${model.moreThan25InCommerce !== 'N' ? 'yes' : 'no'}`)"
                readonly
              />
              <ea-radio-group v-else v-model="model.moreThan25InCommerce" direction="horizontal">
                <ea-radio
                  label="S"
                  class="m-x-8"
                  :readonly="!isElementModificable('INCOMS25')"
                >
                  {{$t('common.label.yes')}}
                </ea-radio>
                <ea-radio
                  label="N"
                  class="m-x-8"
                  :readonly="!isElementModificable('INCOMS25')"
                >
                  {{$t('common.label.no')}}
                </ea-radio>
              </ea-radio-group>
            </ea-form-item>
          </ea-col>
        </ea-row>
        <!-- Materiales de construcción -->
        <ea-row v-if="isElementPrintable(['CDCALINM','CDMATCON','CDFACRIS','CDMATTUB','INPANSAN'])">
          <ea-col :span="24">
            <ea-heading level="5" class="m-b-16">
              {{ $t('quoteBuyGenericFlow.constructionMaterials.title') }}
            </ea-heading>
          </ea-col>
        </ea-row>
        <ea-row v-if="isElementPrintable(['CDCALINM','CDMATCON','CDFACRIS','INPANSAN'])">
          <ea-col v-if="isElementPrintable(['CDCALINM'])" :span="6">
            <ea-form-item
              prop="quality"
              :label="$t('quoteBuyGenericFlow.constructionMaterials.field.quality')"
              :required="!consultaOperation && isElementRequired('CDCALINM')"
            >
              <ea-select
                v-model="model.quality"
                :placeholder="consultaOperation ? '' : $t('common.label.select')"
                :readonly="!isElementModificable('CDCALINM')"
              >
                <ea-option
                  v-for="quality in qualityList"
                  :key="quality.value"
                  :label="quality.label"
                  :value="quality.value"
                />
              </ea-select>
            </ea-form-item>
          </ea-col>
          <ea-col v-if="isElementPrintable(['CDMATCON'])" :span="6">
            <ea-form-item
              prop="edification"
              :label="$t('quoteBuyGenericFlow.constructionMaterials.field.edification')"
              :required="!consultaOperation && isElementRequired('CDMATCON')"
            >
              <ea-select
                v-model="model.edification"
                :placeholder="consultaOperation ? '' : $t('common.label.select')"
                :readonly="!isElementModificable('CDMATCON')"
              >
                <ea-option
                  v-for="edification in edificationList"
                  :key="edification.value"
                  :label="edification.label"
                  :value="edification.value"
                />
              </ea-select>
            </ea-form-item>
          </ea-col>
          <ea-col v-if="isElementPrintable(['CDFACRIS'])" :span="6">
            <ea-form-item
              prop="glazedFacades"
              :label="$t('quoteBuyGenericFlow.constructionMaterials.field.glazedFacades')"
              :required="!consultaOperation && isElementRequired('CDFACRIS')"
            >
              <ea-select
                v-model="model.glazedFacades"
                :placeholder="consultaOperation ? '' : $t('common.label.select')"
                :readonly="!isElementModificable('CDFACRIS')"
              >
                <ea-option
                  v-for="glazedFacades in glazedFacadesList"
                  :key="glazedFacades.value"
                  :label="glazedFacades.label"
                  :value="glazedFacades.value"
                />
              </ea-select>
            </ea-form-item>
          </ea-col>
          <ea-col v-if="isElementPrintable(['INPANSAN'])" :span="6" class="p-t-8">
              <ea-form-item
                prop="sandwichPanels"
                :required="!consultaOperation && isElementRequired('INPANSAN')"
              >
                <ea-checkbox
                  v-model="model.sandwichPanels"
                  :checked="model.sandwichPanels"
                  :label="$t('quoteBuyGenericFlow.constructionMaterials.field.sandwichPanels')"
                  :readonly="!isElementModificable('INPANSAN')"
                ></ea-checkbox>
              </ea-form-item>
          </ea-col>
        </ea-row>
        <ea-row v-if="isElementPrintable(['CDMATTUB'])">
          <ea-col v-if="isElementPrintable(['CDMATTUB'])" :span="6">
            <ea-form-item
              prop="downspoutPipes"
              :label="$t('quoteBuyGenericFlow.constructionMaterials.field.downspoutPipes')"
              :required="!consultaOperation && isElementRequired('CDMATTUB')"
            >
              <ea-select
                v-model="model.downspoutPipes"
                :placeholder="consultaOperation ? '' : $t('common.label.select')"
                :readonly="!isElementModificable('CDMATTUB')"
              >
                <ea-option
                  v-for="downspoutPipes in downspoutPipesList"
                  :key="downspoutPipes.value"
                  :label="downspoutPipes.label"
                  :value="downspoutPipes.value"
                />
              </ea-select>
            </ea-form-item>
          </ea-col>
        </ea-row>
        <!-- Almacenaje productos químicos -->
        <ea-row v-if="isElementPrintable(['TCPROQUI','NULITROS'])">
          <ea-col :span="24">
            <ea-heading level="5" class="m-b-16">
              {{ $t('quoteBuyGenericFlow.chemicalProductsStorage.title') }}
            </ea-heading>
          </ea-col>
        </ea-row>
        <ea-row v-if="isElementPrintable(['TCPROQUI','NULITROS'])">
          <ea-col v-if="isElementPrintable(['TCPROQUI'])" :span="6">
            <ea-form-item
              prop="productType"
              :label="$t('quoteBuyGenericFlow.chemicalProductsStorage.field.productType')"
              :required="!consultaOperation && isElementRequired('TCPROQUI')"
            >
              <ea-select
                v-model="model.productType"
                :placeholder="$t('common.label.select')"
                :readonly="!isElementModificable('TCPROQUI')"
              >
                <ea-option
                  v-for="productType in productTypeList"
                  :key="productType.value"
                  :label="productType.label"
                  :value="productType.value"
                />
              </ea-select>
            </ea-form-item>
          </ea-col>
          <ea-col  :span="6" v-if="showChemicalProductsStorageLitersField && isElementPrintable(['NULITROS'])">
            <ea-form-item
              prop="liters"
              :label="$t('quoteBuyGenericFlow.chemicalProductsStorage.field.liters')"
              :required="!consultaOperation && isElementRequired('NULITROS')"
            >
              <ea-input-number v-model="model.liters" :readonly="!isElementModificable('NULITROS')"
                :precision="returnElementMaxDecimalsByCode('NULITROS')"
              />
            </ea-form-item>
          </ea-col>
        </ea-row>
      </div>
    </ea-card>
    <ea-row
      v-if="isElementPrintable(['INDEPREC','INPISCIN','INGIMNAS','INLOCSOC','INDPLACS','INPUNCAR',
        'INEXTINT','INBOCINC','INROCIAD','INDETECT','INCOLHID','INESCINC',
        'INCAMSEG','INVIGPER'])"
      extraClass="checks-section m-t-16 d-display-flex">
      <!-- Instalaciones complementarias -->
      <ea-col
        extraClass="d-align-self-stretch"
        v-if="isElementPrintable(['INDEPREC','INPISCIN','INGIMNAS','INLOCSOC','INDPLACS','INPUNCAR'])"
        >
        <ea-card>
          <div slot="cardbody" class="p-a-16 h-100">
            <ea-heading level="5" class="m-b-16">
              {{ $t('quoteBuyGenericFlow.complementaryFacilities.title') }}
            </ea-heading>
            <ea-form-item :showLabel="false" prop="sportsRecreational">
              <ea-checkbox
                v-if="isElementPrintable(['INDEPREC'])"
                v-model="model.sportsRecreational"
                :checked="model.sportsRecreational"
                class="m-t-4 m-b-0"
                :label="$t('quoteBuyGenericFlow.complementaryFacilities.field.linkedSportsRecreational')"
                :readonly="!isElementModificable('INDEPREC')"
              ></ea-checkbox>
            </ea-form-item>
            <ea-form-item :showLabel="false" prop="swimmingPool">
              <ea-checkbox
                v-if="isElementPrintable(['INPISCIN'])"
                v-model="model.swimmingPool"
                :checked="model.swimmingPool"
                class="m-t-4 m-b-0"
                :label="$t('quoteBuyGenericFlow.complementaryFacilities.field.swimmingPool')"
                :readonly="!isElementModificable('INPISCIN')"
              ></ea-checkbox>
            </ea-form-item>
            <ea-form-item :showLabel="false" prop="gym">
              <ea-checkbox
                v-if="isElementPrintable(['INGIMNAS'])"
                v-model="model.gym"
                :checked="model.gym"
                class="m-t-4 m-b-0"
                :label="$t('quoteBuyGenericFlow.complementaryFacilities.field.gym')"
                :readonly="!isElementModificable('INGIMNAS')"
              ></ea-checkbox>
            </ea-form-item>
            <ea-form-item :showLabel="false" prop="socialClub">
              <ea-checkbox
                v-if="isElementPrintable(['INLOCSOC'])"
                v-model="model.socialClub"
                :checked="model.socialClub"
                class="m-t-4 m-b-0"
                :label="$t('quoteBuyGenericFlow.complementaryFacilities.field.socialClub')"
                :readonly="!isElementModificable('INLOCSOC')"
              ></ea-checkbox>
            </ea-form-item>
            <ea-form-item :showLabel="false" prop="solarPanels">
              <ea-checkbox
                v-if="isElementPrintable(['INDPLACS'])"
                v-model="model.solarPanels"
                :checked="model.solarPanels"
                class="m-t-4 m-b-0"
                :label="$t('quoteBuyGenericFlow.complementaryFacilities.field.solarPanels')"
                :readonly="!isElementModificable('INDPLACS')"
              ></ea-checkbox>
            </ea-form-item>
            <ea-form-item :showLabel="false" prop="electricVehicleChargingPoints">
              <ea-checkbox
                v-if="isElementPrintable(['INPUNCAR'])"
                v-model="model.electricVehicleChargingPoints"
                :checked="model.electricVehicleChargingPoints"
                class="m-t-4 m-b-0"
                :label="$t('quoteBuyGenericFlow.complementaryFacilities.field.electricVehicleChargingPoints')"
                :readonly="!isElementModificable('INPUNCAR')"
              ></ea-checkbox>
            </ea-form-item>
          </div>
        </ea-card>
      </ea-col>
      <!-- Medidas contra incendios -->
      <ea-col extraClass="d-align-self-stretch"
        v-if="isElementPrintable(['INEXTINT','INBOCINC','INROCIAD','INDETECT','INCOLHID','INESCINC'])">
        <ea-card class="h-100">
          <div slot="cardbody" class="p-a-16 h-100">
            <ea-heading level="5" class="m-b-16"
            >
              {{ $t('quoteBuyGenericFlow.fireFightingMeasures.title') }}
            </ea-heading>
            <ea-form-item :showLabel="false" prop="fireExtinguishers">
              <ea-checkbox
              v-if="isElementPrintable(['INEXTINT'])"
                v-model="model.fireExtinguishers"
                :checked="model.fireExtinguishers"
                class="m-t-4 m-b-0"
                :label="$t('quoteBuyGenericFlow.fireFightingMeasures.field.fireExtinguishers')"
                :readonly="!isElementModificable('INEXTINT')"
              ></ea-checkbox>
            </ea-form-item>
            <ea-form-item :showLabel="false" prop="fireHydrants">
              <ea-checkbox
              v-if="isElementPrintable(['INBOCINC'])"
                v-model="model.fireHydrants"
                :checked="model.fireHydrants"
                class="m-t-4 m-b-0"
                :label="$t('quoteBuyGenericFlow.fireFightingMeasures.field.fireHydrants')"
                :readonly="!isElementModificable('INBOCINC')"
              ></ea-checkbox>
            </ea-form-item>
            <ea-form-item :showLabel="false" prop="sprinklers">
              <ea-checkbox
              v-if="isElementPrintable(['INROCIAD'])"
                v-model="model.sprinklers"
                :checked="model.sprinklers"
                class="m-t-4 m-b-0"
                :label="$t('quoteBuyGenericFlow.fireFightingMeasures.field.sprinklers')"
                :readonly="!isElementModificable('INROCIAD')"
              ></ea-checkbox>
            </ea-form-item>
            <ea-form-item :showLabel="false" prop="smokeDetectors">
              <ea-checkbox
              v-if="isElementPrintable(['INDETECT'])"
                v-model="model.smokeDetectors"
                :checked="model.smokeDetectors"
                class="m-t-4 m-b-0"
                :label="$t('quoteBuyGenericFlow.fireFightingMeasures.field.smokeDetectors')"
                :readonly="!isElementModificable('INDETECT')"
              ></ea-checkbox>
            </ea-form-item>
            <ea-form-item :showLabel="false" prop="moisturizingColumns">
              <ea-checkbox
              v-if="isElementPrintable(['INCOLHID'])"
                v-model="model.moisturizingColumns"
                :checked="model.moisturizingColumns"
                class="m-t-4 m-b-0"
                :label="$t('quoteBuyGenericFlow.fireFightingMeasures.field.moisturizingColumns')"
                :readonly="!isElementModificable('INCOLHID')"
              ></ea-checkbox>
            </ea-form-item>
            <ea-form-item :showLabel="false" prop="fireStairs">
              <ea-checkbox
              v-if="isElementPrintable(['INESCINC'])"
                v-model="model.fireStairs"
                :checked="model.fireStairs"
                class="m-t-4 m-b-0"
                :label="$t('quoteBuyGenericFlow.fireFightingMeasures.field.fireStairs')"
                :readonly="!isElementModificable('INESCINC')"
              ></ea-checkbox>
            </ea-form-item>
          </div>
        </ea-card>
      </ea-col>
      <!-- Medidas de seguridad -->
      <ea-col v-if="isElementPrintable(['INCAMSEG','INVIGPER'])" extraClass="d-align-self-stretch">
        <ea-card class="h-100">
          <div slot="cardbody" class="p-a-16">
            <ea-heading level="5" class="m-b-16">
              {{ $t('quoteBuyGenericFlow.securityMeasures.title') }}
            </ea-heading>
            <ea-form-item :showLabel="false" prop="securityCamera">
              <ea-checkbox
                v-model="model.securityCamera"
                :checked="model.securityCamera"
                class="m-t-4 m-b-0"
                :label="$t('quoteBuyGenericFlow.securityMeasures.field.securityCamera')"
                :readonly="!isElementModificable('INCAMSEG')"
              ></ea-checkbox>
            </ea-form-item>
            <ea-form-item :showLabel="false" prop="permanentSurveillance">
              <ea-checkbox
                v-model="model.permanentSurveillance"
                :checked="model.permanentSurveillance"
                class="m-t-4 m-b-0"
                :label="$t('quoteBuyGenericFlow.securityMeasures.field.permanentSurveillance')"
                :readonly="!isElementModificable('INVIGPER')"
              ></ea-checkbox>
            </ea-form-item>
          </div>
        </ea-card>
      </ea-col>
    </ea-row>
  </ea-form>
</template>

<script lang="ts">
import {
  Component, Prop, Watch
} from 'vue-property-decorator';

import {
  mixins
} from 'vue-class-component';

import {
  EABusinessComponent, EACorporateTableDirective
} from '@zurich-es-npm/ea-front-web-core';

import {
  GetGeneralDataResponseDataDatosObjeto
} from '@/services/V1/quoteAndBuy/getGeneralDataOperation/post';

import QbProductoAseguradoComunidadesModel, {
  LabelValue, MinMaxValue
} from './qb-producto-asegurado-comunidades-model';
import PostCodeLocationInputBusiness from '../post-code-location-input/post-code-location-input-business.vue';
import {
  EAFormValidationOptions,
  EAIValidationRule,
  eaCustomValidation,
  eaRequiredValidation,
  eaRequiredNumberValidation,
  eaRangeValidation,
  EAValidation,
  Form,
  EACompositeValidation,
} from '@zurich-es-npm/ea-front-web-ui';
import {
  ElForm
} from 'element-ui/types/form';
import {
  EAValidatorFunction
} from '@zurich-es-npm/ea-front-web-core/lib/validation-rules';
import {
  Utils
} from '../../utils/utils';
import TextUtils from '@/utils/text-utils';
import {
  elementValueIsAnEmptyString,
  returnElementListByCode
} from '@/utils/object-utils';
import QbRiskSituationBusiness from '../qb-risk-situation/qb-risk-situation-business.vue';

interface PrintableElementConditionToCheckModel {
  code: string; // Element code
  checkFunction: Function; // Function that will check if element is printable
  expectedValueToBePrintable: boolean; // Expected returned value by checkFunction for element to be printable
}

@Component({
  name: 'qb-producto-asegurado-comunidades',
  components: {
    PostCodeLocationInput: PostCodeLocationInputBusiness,
    QbRiskSituation: QbRiskSituationBusiness
  },
  directives: {
    'ea-corporate-table': EACorporateTableDirective,
  }
})

/**
 * Business Component qb-producto-asegurado-comunidades
 */
export default class QbProductoAseguradoComunidadesBusiness extends mixins<
EABusinessComponent<QbProductoAseguradoComunidadesModel>
>(EABusinessComponent) {

  @Prop()
    datosObjeto?: GetGeneralDataResponseDataDatosObjeto[];

  @Prop()
    consultaOperation?: boolean;

  comunitaryWaterList?: LabelValue[] = [];

  ownerTypeList?: LabelValue[] = [];

  realEstateDestinationList?: LabelValue[] = [];

  buildingTypeList?: LabelValue[] = [];

  communityWaterFacilitiesList?: LabelValue[] = [];

  businessPremisesActivityList?: LabelValue[] = [];

  ecologicalCertificationList?: LabelValue[] = [];

  ecologicalPolicyList?: LabelValue[] = [];

  edificationList?: LabelValue[] = [];

  glazedFacadesList?: LabelValue[] = [];

  downspoutPipesList?: LabelValue[] = [];

  qualityList?: LabelValue[] = [];

  productTypeList?: LabelValue[] = [];

  requiredExceptionList = ['CDMATTUB', 'NULITROS'];

  notRequiredExceptionList = ['INPANSAN', 'INCOFICO'];

  showCommunitaryWater: boolean = false;

  zipCodeCompCityListRetrievalCode: string = '';

  public elementValueIsAnEmptyString = elementValueIsAnEmptyString;

  /**
   * Hook on created.
   */
  created() {
    this.model.riskExtraInfo =
      this.returnElementValueByCode('TXDESOB1') +
      this.returnElementValueByCode('TXDESOB2');

    // Remodeling years
    this.model.constructionYear = this.returnNumericElementValueByCode('AACONSTR');
    this.model.remodelingYear = this.returnNumericElementValueByCode('AAREFOFA');
    this.model.deckYear = this.returnNumericElementValueByCode('AAREFOCU');
    this.model.electricityYear = this.returnNumericElementValueByCode('AAREFOEL');
    this.model.waterYear = this.returnNumericElementValueByCode('AAREFOAG');
    this.model.comunitaryWater = this.returnElementValueByCode('CDNIREAG') === 'N'
      ? ''
      : this.returnElementValueByCode('CDNIREAG');
    this.comunitaryWaterList = returnElementListByCode(
      'CDNIREAG', this.datosObjeto)?.filter(item => item.value !== 'N'
    );

    this.setCommunitaryWaterVisibility();
    if (this.model.constructionYear) {
      this.onConstructionYearChange();
    }

    // General Features
    this.model.ownerType = this.returnElementValueByCode('TCTIPROC');
    this.model.realEstateDestination = this.returnElementValueByCode('TCUSOINM');
    this.model.parkingSpacesNumber = this.returnNumericElementValueByCode('NUPLAGAR');
    this.model.buildingNumber = this.returnNumericElementValueByCode('NUEDIFIC');
    this.model.blockStairNumber = this.returnNumericElementValueByCode('NUESCBLO');
    this.model.livingPlaceNumber = this.returnNumericElementValueByCode('NUVIVIEN');
    this.model.elevatorNumber = this.returnNumericElementValueByCode('NUASCTOT');
    this.model.buildingPlant = this.returnNumericElementValueByCode('NUALTEDI');
    this.model.groundFloor = this.returnNumericElementValueByCode('NUALTBRA');
    this.model.communityWaterFacilities = this.returnElementValueByCode('TCAGUACO');
    this.model.businessPremisesActivity = this.returnElementValueByCode('CDACLOCO');
    this.model.ecologicalCertification = this.returnElementValueByCode('CDNIVCEC');
    this.model.ecologicalPolicy = this.returnElementValueByCode('CDPOLECO');
    this.model.fico = this.returnElementValueByCode('INCOFICO');

    // Construction materials

    this.model.quality = this.returnElementValueByCode('CDCALINM');
    this.model.edification = this.returnElementValueByCode('CDMATCON');
    this.model.glazedFacades = this.returnElementValueByCode('CDFACRIS');
    this.model.downspoutPipes = this.returnElementValueByCode('CDMATTUB');
    this.model.sandwichPanels = this.returnElementValueByCode('INPANSAN') === 'S';

    // Surface
    this.model.propertySize = this.returnNumericElementValueByCode('NUSUPINM');
    this.model.backyardSize = this.returnNumericElementValueByCode('NUSUPJAR');
    this.model.garageSize = this.returnNumericElementValueByCode('NUSUPGAR');
    this.model.propertyTotalSize = this.returnNumericElementValueByCode('NUSUPTOT') || 0;
    this.model.moreThan25InCommerce = this.returnElementValueByCode('INCOMS25');

    // Chemical products storage
    this.model.productType = this.returnElementValueByCode('TCPROQUI');
    this.model.liters = this.returnNumericElementValueByCode('NULITROS');

    // Complementary facilities
    this.model.sportsRecreational = this.returnElementValueByCode('INDEPREC') === 'S';
    this.model.swimmingPool = this.returnElementValueByCode('INPISCIN') === 'S';
    this.model.gym = this.returnElementValueByCode('INGIMNAS') === 'S';
    this.model.socialClub = this.returnElementValueByCode('INLOCSOC') === 'S';
    this.model.solarPanels = this.returnElementValueByCode('INDPLACS') === 'S';
    this.model.electricVehicleChargingPoints = this.returnElementValueByCode('INPUNCAR') === 'S';

    // Fire fighting measures
    this.model.fireExtinguishers = this.returnElementValueByCode('INEXTINT') === 'S';
    this.model.fireHydrants = this.returnElementValueByCode('INBOCINC') === 'S';
    this.model.sprinklers = this.returnElementValueByCode('INROCIAD') === 'S';
    this.model.smokeDetectors = this.returnElementValueByCode('INDETECT') === 'S';
    this.model.moisturizingColumns = this.returnElementValueByCode('INCOLHID') === 'S';
    this.model.fireStairs = this.returnElementValueByCode('INESCINC') === 'S';

    // Security measures
    this.model.securityCamera = this.returnElementValueByCode('INCAMSEG') === 'S';
    this.model.permanentSurveillance = this.returnElementValueByCode('INVIGPER') === 'S';

    this.ownerTypeList = returnElementListByCode('TCTIPROC', this.datosObjeto);
    this.realEstateDestinationList = returnElementListByCode('TCUSOINM', this.datosObjeto);
    this.communityWaterFacilitiesList = returnElementListByCode('TCAGUACO', this.datosObjeto);
    this.businessPremisesActivityList = returnElementListByCode('CDACLOCO', this.datosObjeto);
    this.businessPremisesActivityList &&
      Utils.setObjectArrayLastElement(this.businessPremisesActivityList, 'value', '99');
    this.ecologicalCertificationList = returnElementListByCode('CDNIVCEC', this.datosObjeto);
    this.ecologicalPolicyList = returnElementListByCode('CDPOLECO', this.datosObjeto);
    this.edificationList = returnElementListByCode('CDMATCON', this.datosObjeto);
    this.glazedFacadesList = returnElementListByCode('CDFACRIS', this.datosObjeto);
    this.downspoutPipesList = returnElementListByCode('CDMATTUB', this.datosObjeto);
    this.qualityList = returnElementListByCode('CDCALINM', this.datosObjeto);
    this.productTypeList = returnElementListByCode('TCPROQUI', this.datosObjeto);

    this.$nextTick(() => {
      const comp = this.$refs.riskSituationBC as QbRiskSituationBusiness;
      comp.setFormValues();
      this.update();
    });
  }

  /**
   * Gets if realEstateDestination is equal Garaje
   * @returns {boolean} true if realEstateDestination is equal GR; false otherwise
   */
  get isRealEstateDestinationEqualGR(): boolean {
    if (this.model.realEstateDestination === 'GR') {
      this.model.backyardSize = undefined;
      this.model.garageSize = undefined;
      return true;
    }
    return false;
  }

  /**
   * Returns the concatented total size sum variables for the @Watch('computedTotalSize') method
   */
  get computedTotalSize() {
    return `${this.model.propertySize}|${this.model.backyardSize}|${this.model.garageSize}`;
  }

  /**
   * Sets "showCommunitaryWater" to true if all of these conditions apply, false otherwise:
   * - Comunitary water must have a value (CDIREAG must exist).
   * - Water year must have a value (this.model.waterYear must be defined as a number).
   * - Water year value must be between the defined min max values (1800 to 2999).
   */
  setCommunitaryWaterVisibility(): void {
    const waterYearMinMaxValues = this.returnMinMaxValues('AAREFOAG');

    const comunitaryWaterHasValue = this.returnElementByCode('CDNIREAG');
    const waterYearHasValue =
      this.model.waterYear !== undefined &&
      this.model.waterYear !== null;
    const waterYearIsBetweenMinMax =
      this.model.waterYear !== undefined &&
      waterYearMinMaxValues?.valorMinimoElemento &&
      waterYearMinMaxValues?.valorMaximoElemento &&
      this.model.waterYear >= waterYearMinMaxValues.valorMinimoElemento &&
      this.model.waterYear <= waterYearMinMaxValues.valorMaximoElemento;

    if (
      !this.showCommunitaryWater &&
      !!comunitaryWaterHasValue &&
      !!waterYearHasValue &&
      !!waterYearIsBetweenMinMax
    ) {
      this.showCommunitaryWater = true;
    }

    if (!waterYearHasValue) {
      this.model.comunitaryWater = '';
      this.showCommunitaryWater = false;
    }
  }

  /**
   * Focuses on communitary water element if it's shown.
   *
   * When you press tab, the page doesn't wait for the element to appear and focus it, instead it jumps
   * to the next visible element.
   *
   * This method doesn't interfere with tab presses or prevents focus on next element, it just refocuses
   * on the previous element and fixes that behaviour.
   */
  async handleCommunitaryWaterVisibility() {
    // Toggle visibility
    this.setCommunitaryWaterVisibility();

    // Wait for DOM to show or hide the expected element
    await this.$nextTick();

    // If the expected element is visible, then change the focus from its next element to this element
    if (this.showCommunitaryWater) {
      const element: HTMLElement | null = document.getElementById('comunitaryWater');
      element?.focus();
    }
  }

  /**
   * Gets form validation options based on retrieved data
   */
  get validationOptions(): EAFormValidationOptions {
    return {
      rules: {
        riskExtraInfo: this.getFieldRules(this.model.FIELD_TYPES.STRING, 'TXDESOB1'),

        constructionYear: this.getFieldRules(this.model.FIELD_TYPES.NUMBER, 'AACONSTR'),
        remodelingYear: this.getFieldRules(this.model.FIELD_TYPES.NUMBER, 'AAREFOFA'),
        deckYear: this.getFieldRules(this.model.FIELD_TYPES.NUMBER, 'AAREFOCU'),
        electricityYear: this.getFieldRules(this.model.FIELD_TYPES.NUMBER, 'AAREFOEL'),
        waterYear: this.getFieldRules(this.model.FIELD_TYPES.NUMBER, 'AAREFOAG'),
        comunitaryWater: this.getFieldRules(this.model.FIELD_TYPES.DEFAULT, 'CDNIREAG'),

        ownerType: this.getFieldRules(this.model.FIELD_TYPES.DEFAULT, 'TCTIPROC'),
        realEstateDestination: this.getFieldRules(this.model.FIELD_TYPES.DEFAULT, 'TCUSOINM'),
        buildingNumber: this.getFieldRules(this.model.FIELD_TYPES.NUMBER, 'NUEDIFIC'),
        blockStairNumber: this.getFieldRules(this.model.FIELD_TYPES.NUMBER, 'NUESCBLO'),
        livingPlaceNumber: this.getFieldRules(this.model.FIELD_TYPES.NUMBER, 'NUVIVIEN'),
        elevatorNumber: this.getFieldRules(this.model.FIELD_TYPES.NUMBER, 'NUASCTOT'),
        parkingSpacesNumber: this.getFieldRules(this.model.FIELD_TYPES.NUMBER, 'NUPLAGAR'),
        buildingPlant: this.getFieldRules(this.model.FIELD_TYPES.NUMBER, 'NUALTEDI'),
        groundFloor: this.getFieldRules(this.model.FIELD_TYPES.NUMBER, 'NUALTBRA'),
        communityWaterFacilities: this.getFieldRules(this.model.FIELD_TYPES.DEFAULT, 'TCAGUACO'),
        businessPremisesActivity: this.getFieldRules(this.model.FIELD_TYPES.DEFAULT, 'CDACLOCO'),
        ecologicalCertification: this.getFieldRules(this.model.FIELD_TYPES.DEFAULT, 'CDNIVCEC'),
        ecologicalPolicy: this.getFieldRules(this.model.FIELD_TYPES.DEFAULT, 'CDPOLECO'),
        fico: this.getFieldRules(this.model.FIELD_TYPES.DEFAULT, 'INCOFICO'),

        quality: this.getFieldRules(this.model.FIELD_TYPES.DEFAULT, 'CDCALINM'),
        edification: this.getFieldRules(this.model.FIELD_TYPES.DEFAULT, 'CDMATCON'),
        glazedFacades: this.getFieldRules(this.model.FIELD_TYPES.DEFAULT, 'CDFACRIS'),
        downspoutPipes: this.getFieldRules(this.model.FIELD_TYPES.DEFAULT, 'CDMATTUB'),
        sandwichPanels: this.getFieldRules(this.model.FIELD_TYPES.DEFAULT, 'INPANSAN'),

        propertySize: this.getFieldRules(this.model.FIELD_TYPES.NUMBER, 'NUSUPINM'),
        backyardSize: this.getFieldRules(this.model.FIELD_TYPES.NUMBER, 'NUSUPJAR'),
        garageSize: this.getFieldRules(this.model.FIELD_TYPES.NUMBER, 'NUSUPGAR'),
        propertyTotalSize: this.getFieldRules(this.model.FIELD_TYPES.NUMBER, 'NUSUPTOT'),
        moreThan25InCommerce: this.getFieldRules(this.model.FIELD_TYPES.DEFAULT, 'INCOMS25'),

        productType: this.getFieldRules(this.model.FIELD_TYPES.DEFAULT, 'TCPROQUI'),
        liters: this.getFieldRules(this.model.FIELD_TYPES.NUMBER, 'NULITROS'),
      }
    };
  }

  /**
   * Return element by code
   * @param {string} code
   * @returns {GetGeneralDataResponseDataDatosObjeto  | undefined}
   */
  returnElementByCode(code: string): GetGeneralDataResponseDataDatosObjeto | undefined {
    return this.datosObjeto ?
      this.datosObjeto.find(element => element.codigoElemento === code) : undefined;
  }

  /**
   * Return element value by code
   * @param {string} code
   * @returns {string}
   */
  returnElementValueByCode(code: string): string {
    const valorElemento = this.returnElementByCode(code)?.valorElemento;
    return valorElemento ? valorElemento : '';
  }

  /**
   * Return numeric element value by code
   * @param {string} code
   * @returns {number | undefined}
   */
  returnNumericElementValueByCode(code: string): number | undefined {
    if (this.isElementNotNaN(code)) {
      return Number.parseInt(this.returnElementValueByCode(code));
    } else {
      return undefined;
    }
  }

  /**
   * Checks if the element is not NaN
   * @param {string} code
   * @returns {boolean}
   */
  isElementNotNaN(code: string): boolean {
    return !isNaN(Number.parseInt(this.returnElementValueByCode(code)));
  }

  /**
   * Returns if element is required
   * @param {string} code
   * @returns { boolean }
   */
  isElementRequired(code: string): boolean {
    const element = this.returnElementByCode(code);

    const requiredException = this.requiredExceptionList.find(exception => exception === code);
    const notRequiredException = this.notRequiredExceptionList.find(exception => exception === code);

    if (requiredException && !this.consultaOperation) {
      return true;
    } else if (notRequiredException) {
      return false;
    }

    return element && element.elementoRequerido !== undefined ? element.elementoRequerido : true;
  }
  

  /**
   * Returns if element is printable
   * @param {array} codes
   * @returns { boolean }
   */
  isElementPrintable(codes: string[]): boolean {
    if (this.consultaOperation) {
      return this.isElementPrintableQuery(codes);
    }
    return this.isElementPrintablePN(codes);
      
  }


  /**
   * Returns if element is printable query
   * @param {array} codes
   * @returns { boolean }
   */
  isElementPrintableQuery(codes: string[]): boolean {
    let visible = true;
    for (const code of codes) {
      const element = this.returnElementByCode(code);
      if (this.isHiddenByRealStateDestinationSelection(code) || (element && element.valorElemento === undefined)) {
        visible = false;
      } else {
        visible = true;
      }
    }
    return visible;
  }

  /**
   * Checks if element is hidden due to selected real state destination
   * @param {string} code
   * @returns { boolean }
   */
  isHiddenByRealStateDestinationSelection(code: string): boolean {
    if (this.model.realEstateDestination !== this.model.REAL_STATE_PARKING_DESTINATION_VALUE) {
      return TextUtils.equalIgnoreCase(code, 'NUPLAGAR');
    }

    return TextUtils.equalIgnoreCase(code, 'NUEDIFIC') || TextUtils.equalIgnoreCase(code, 'NUESCBLO') ||
      TextUtils.equalIgnoreCase(code, 'NUVIVIEN');
  }


  /**
   * Returns if element is printable Nueva Producción
   * @param {array} codes
   * @returns { boolean }
   */
  isElementPrintablePN(codes: string[]): boolean {
    for (const code of codes) {
      const element = this.returnElementByCode(code);
      if (
        element &&
        (element.elementoModificable === true || element.elementoModificable === false) &&
        this.checkIfPrintableElementByRealStateDestination(code)
      ) {
        return true;
      }
    }
    return false;
  }

  /**
   * Gets if parking spaces number input has to be shown
   * @returns {boolean}
   */
  public showParkingSpacesNumber(): boolean {
    return this.model.realEstateDestination === this.model.REAL_STATE_PARKING_DESTINATION_VALUE;
  }

  /**
   * Checks if elements is printable according to real state destination value
   * @param {string} code
   * @returns {boolean}
   */
  checkIfPrintableElementByRealStateDestination(code: string) {
    const conditionsToCheck: PrintableElementConditionToCheckModel[] = [
      {
        code: 'NUPLAGAR',
        checkFunction: this.showParkingSpacesNumber,
        expectedValueToBePrintable: true
      },
      {
        code: 'NUVIVIEN',
        checkFunction: this.showParkingSpacesNumber,
        expectedValueToBePrintable: false
      },
      {
        code: 'NUEDIFIC',
        checkFunction: this.showParkingSpacesNumber,
        expectedValueToBePrintable: false
      },
      {
        code: 'NUESCBLO',
        checkFunction: this.showParkingSpacesNumber,
        expectedValueToBePrintable: false
      }
    ];

    const elementToCheck = conditionsToCheck.find(element => element.code === code);
    if (elementToCheck) {
      return elementToCheck.checkFunction() === elementToCheck.expectedValueToBePrintable;
    }

    return true;
  }

  /**
   * Returns if element is modificable
   * @param {string} code
   * @returns { boolean }
   */
  isElementModificable(code: string): boolean {
    const element = this.returnElementByCode(code);
    return element && element.elementoModificable !== undefined ? element.elementoModificable : true;
  }

  /**
   * Returns if element is in range
   * @param {string} code
   * @returns { MinMaxValue | undefined }
   */
  returnMinMaxValues(code: string): MinMaxValue | undefined {
    const element = this.returnElementByCode(code);
    if (element) {
      return {
        valorMinimoElemento: element.valorMinimoElemento,
        valorMaximoElemento: element.valorMaximoElemento
      };
    }
  }

  /**
   * Returns element max length
   * @param {string} code
   * @returns { number | undefined }
   */
  returnElementLengthByCode(code: string): number | null {
    if (code === 'TXDESOB1') {
      return this.returnRiskExtraInfoElementLength();
    }
    const element = this.returnElementByCode(code);
    if (element) {
      return element.longitudElemento || null;
    }
    return null;
  }

  /**
   * Returns risk extra info element max length
   * @returns { number | undefined }
   */
  returnRiskExtraInfoElementLength() {
    const riskExtraInfoEl1 = this.returnElementByCode('TXDESOB1');
    const riskExtraInfoEl2 = this.returnElementByCode('TXDESOB2');

    if (riskExtraInfoEl1?.longitudElemento && riskExtraInfoEl2?.longitudElemento) {
      return riskExtraInfoEl1.longitudElemento + riskExtraInfoEl2.longitudElemento;
    }
    return null;
  }

  /**
   * Returns element max length
   * @param {string} code
   * @returns { number | undefined }
   */
  returnElementMaxDecimalsByCode(code: string): number | undefined {
    const element = this.returnElementByCode(code);
    if (element) {
      return element.numeroDecimalesElemento;
    }
  }

  /**
   * Calcuates the propertyTotalSize when the computedTotalSize upadtes
   */
  @Watch('computedTotalSize')
  calculateTotalSize() {
    this.model.propertyTotalSize =
      (this.model.propertySize && this.model.propertySize > 0 ? this.model.propertySize : 0) +
      (this.model.backyardSize && this.model.backyardSize > 0 ? this.model.backyardSize : 0) +
      (this.model.garageSize && this.model.garageSize > 0 ? this.model.garageSize : 0);
  }

  /**
   * Gets field rules
   * @param { string } type - field type (numeric, string, etc.)
   * @param { string } label - field object label
   * @return { EAIValidationRule[] } rules for object
   */
  getFieldRules(type: string, label: string): EAIValidationRule[] {
    const rules: EAIValidationRule[] = [];

    switch (type) {
      case this.model.FIELD_TYPES.NUMBER: {
        if (this.isElementRequired(label)) {
          rules.push(eaRequiredNumberValidation('common.label.validation.fieldRequired'));
        }

        const minMaxValues = this.returnMinMaxValues(label);
        if (minMaxValues) {
          rules.push(
            eaCustomValidation(
              this.minMaxValidation(
                minMaxValues.valorMinimoElemento,
                minMaxValues.valorMaximoElemento
              ),
              'common.label.validation.invalidRange'
            )
          );
        }
        break;
      }
      case this.model.FIELD_TYPES.STRING: {
        if (this.isElementRequired(label)) {
          rules.push(eaRequiredValidation('common.label.validation.fieldRequired'));
        }

        const ruleMaxLength = this.returnElementLengthByCode(label);
        if (ruleMaxLength) {
          rules.push(
            eaRangeValidation(0, ruleMaxLength, 'common.label.validation.invalidLength')
          );
        }
        break;
      }
      default:
        if (this.isElementRequired(label)) {
          rules.push(eaRequiredValidation('common.label.validation.fieldRequired'));
        }
        return rules;
    }

    return rules;
  }

  /**
   * Validates if the construction year is between the inputed min and max value
   *
   * @returns {EAValidatorFunction}
   * @param {number} min
   * @param {number} max
   */
  minMaxValidation(min?: number, max?: number): EAValidatorFunction {
    return (_rule: any, value: any, callback: Function) => {

      if (value !== null && (min || max)) {
        if (min !== undefined && value < min) {
          return callback(new Error());
        }
        if (max !== undefined && value > max) {
          return callback(new Error());
        }
      }

      return callback();
    };
  }

  /**
   * Gets if liters field is visible
   * @returns { boolean } true if visible; false otherwise
   */
  get showChemicalProductsStorageLitersField() {
    return this.model.productType !== this.model.NO_CHEMICAL_PRODUCTS_STORAGE_VALUE;
  }

  /**
   * Returns validation object
   * @returns {EAValidation }
   */
  public validation(): EAValidation {
    const form = this.$refs.form as Form;

    const riskSituationBC: QbRiskSituationBusiness =
      this.$refs.riskSituationBC as QbRiskSituationBusiness;
    riskSituationBC.update();

    return new EACompositeValidation([
      form.validation(), riskSituationBC.validation()
    ], this.$tc.bind(this));
  }

  /**
   * Validates a single field
   * @param {string} fieldName
   */
  validateField(fieldName: string) {
    this.clearFlowErrors();

    const form = this.$refs.form as Form;
    const elForm = form?.$refs.form as ElForm;

    elForm.validateField(fieldName);
  }

  /**
   * Handles construction year input change
   * Changes minimum value for reform years inputs
   */
  async onConstructionYearChange() {
    const remodelingYearElement = this.returnElementByCode('AAREFOFA');
    if (remodelingYearElement) {
      remodelingYearElement.valorMinimoElemento = this.model.constructionYear;
    }

    const deckYearElement = this.returnElementByCode('AAREFOCU');
    if (deckYearElement) {
      deckYearElement.valorMinimoElemento = this.model.constructionYear;
    }

    const electricityYearElement = this.returnElementByCode('AAREFOEL');
    if (electricityYearElement) {
      electricityYearElement.valorMinimoElemento = this.model.constructionYear;
    }

    const waterYearElement = this.returnElementByCode('AAREFOAG');
    if (waterYearElement) {
      waterYearElement.valorMinimoElemento = this.model.constructionYear;
    }

    await this.$nextTick();

    const propsOfFieldsToValidate = ['remodelingYear', 'deckYear', 'electricityYear', 'waterYear'];
    propsOfFieldsToValidate.forEach(prop => this.validateField(prop));
  }

  /**
   * Handles realEstateDestination input change
   * Changes visibility on buildings number and stairs
   */
  async realEstateDestinationHandler() {
    await this.$nextTick();
    const buildingNumberDefaultValue = 1;
    const blockStairNumberDefaultValue = 1;
    const unifamiliarDestination = 'UF';
    if (this.model.realEstateDestination === unifamiliarDestination) {
      this.model.buildingNumber = buildingNumberDefaultValue;
      this.model.blockStairNumber = blockStairNumberDefaultValue;
    }


    const garageDestination = 'GR';
    if (this.model.realEstateDestination === garageDestination) {
      this.model.buildingNumber = 1;
      this.model.blockStairNumber = 1;
      this.model.livingPlaceNumber = undefined;
      this.model.backyardSize = undefined;
      this.model.garageSize = undefined;
    } else {
      this.model.parkingSpacesNumber = undefined;
    }
  }

  /**
   * Updates zip and current model
   */
  public updateAllData() {
    const comp = this.$refs.riskSituationBC as QbRiskSituationBusiness;
    comp.updateAllData();
    this.update();
  }
}
</script>


