<!-- eslint-disable max-lines -->
<template>
  <div>
      <ea-card>
        <div slot="cardbody" class="p-a-24 cardbody">
          <qb-risk-situation
            id="qb-risk-situation"
            ref="riskSituationBC"
            v-model="model.riskSituation"
            :title="hogarTitle"
            :datosObjeto="datosObjeto"
            :consultaOperation="consultaOperation"
          ></qb-risk-situation>
          
          <ea-form ref="form" :model="model" :validationOptions="validationOptions" :validateOnRuleChange="false">
            <!-- Año de construcción -->
            <ea-row v-if="isElementPrintable(['AACONSTR','AAREFORM'], datosObjeto, consultaOperation)">
              <ea-col :span="24">
                <ea-heading level="5" class="m-b-16">
                  {{ $t('quoteBuyGenericFlow.generalCharacteristics.field.constructionYearTitle') }}
                </ea-heading>
              </ea-col>
              <ea-col :span="6" v-if="isElementPrintable(['AACONSTR'], datosObjeto, consultaOperation)">
                <ea-form-item
                  prop="constructionYear"
                  :label="$t('quoteBuyGenericFlow.generalCharacteristics.field.constructionYear')"
                  :required="!consultaOperation && isElementRequired('AACONSTR', datosObjeto)"
                >
                  <ea-input-number
                    :use-grouping="false"
                    v-model="model.constructionYear"
                    valueAsInteger
                    :readonly="!isElementModificable('AAREFORM', datosObjeto)"
                    :precision="returnElementMaxDecimalsByCode('AACONSTR')"
                    :placeholder="consultaOperation ? '' : $t('common.label.year')"
                    @change="onConstructionYearChange()"
                  />
                </ea-form-item>
              </ea-col>
              <ea-col :span="6" v-if="isElementPrintable(['AAREFORM'], datosObjeto, consultaOperation)">
                <ea-form-item
                  prop="remodelingYear"
                  :label="$t('quoteBuyGenericFlow.generalCharacteristics.field.remodelingYearHogarTitle')"
                  :required="!consultaOperation && isElementRequired('AAREFORM', datosObjeto)"
                >
                  <div slot="tooltipIconInfo">
                    <div slot="content">
                      <span>{{$t('quoteBuyGenericFlow.generalCharacteristics.field.remodelingYearHogarTooltip')}}</span>
                    </div>
                  </div>
                  <ea-input-number
                    :use-grouping="false"
                    v-model="model.remodelingYear"
                    valueAsInteger
                    :readonly="!isElementModificable('AAREFORM', datosObjeto)"
                    :precision="returnElementMaxDecimalsByCode('AAREFORM')"
                    :placeholder="consultaOperation ? '' : $t('common.label.year')"
                    @change="onConstructionYearChange()"
                  />
                </ea-form-item>
              </ea-col>
            </ea-row>
            <!-- Características generales -->
            <ea-row v-if="isElementPrintable(
              [
              'TCCLAVIV',
              'TCUBIVIV',
              'CDCALIVV',
              'TCCARASE',
              'INUSOVIV',
              'INUSOSAT'
              ],
              datosObjeto,
              consultaOperation)">
              <ea-col :span="24">
                <ea-heading level="5" class="m-b-16">
                  {{ $t('quoteBuyGenericFlow.generalCharacteristics.title') }}
                </ea-heading>
              </ea-col>
              <ea-row
                class="p-x-12"
                v-if="isElementPrintable(['TCCLAVIV','TCUBIVIV', 'CDCALIVV'], datosObjeto, consultaOperation)">
                <ea-col v-if="isElementPrintable(['TCCLAVIV'], datosObjeto, consultaOperation)"
                  :span="6">
                  <ea-form-item
                    :required="!consultaOperation && isElementRequired('TCCLAVIV')"
                    prop="buildingType"
                    :label="$t('quoteBuyGenericFlow.generalCharacteristics.field.buildingType')"
                  >
                    <ea-select
                      v-model="model.buildingType"
                      :placeholder="consultaOperation ? '' : $t('common.label.select')"
                      :readonly="(buildingTypeList && buildingTypeList.length === 1) ||
                        !isElementModificable('TCCLAVIV', datosObjeto)"
                    >
                      <ea-option
                        v-for="buildType in buildingTypeList"
                        :key="buildType.value"
                        :label="capitalizeFirstLetter(buildType.label)"
                        :value="buildType.value"
                      />
                    </ea-select>
                  </ea-form-item>
                </ea-col>
                <ea-col v-if="isElementPrintable(['TCUBIVIV'], datosObjeto, consultaOperation)"
                  :span="6">
                  <ea-form-item
                    :required="!consultaOperation && isElementRequired('TCUBIVIV')"
                    prop="buildingLocation"
                    :label="$t('quoteBuyGenericFlow.generalCharacteristics.field.buildingLocation')"
                  >
                    <ea-select
                      v-model="model.buildingLocation"
                      :placeholder="consultaOperation ? '' : $t('common.label.select')"
                      :readonly="(buildingLocationList && buildingLocationList.length === 1) ||
                        !isElementModificable('TCUBIVIV', datosObjeto)"
                    >
                      <ea-option
                        v-for="buildLocationType in buildingLocationList"
                        :key="buildLocationType.value"
                        :label="capitalizeFirstLetter(buildLocationType.label)"
                        :value="buildLocationType.value"
                      />
                    </ea-select>
                  </ea-form-item>
                </ea-col>
                <ea-col v-if="isElementPrintable(['CDCALIVV'], datosObjeto, consultaOperation)"
                    :span="6">
                    <ea-form-item
                      :required="!consultaOperation && isElementRequired('CDCALIVV')"
                      prop="quality"
                      :label="$t('quoteBuyGenericFlow.constructionMaterials.field.quality')"
                    >
                      <div slot="tooltipIconInfo" class="t-align-center">
                        <div slot="content">
                          <span>
                            {{ $t('quoteBuyGenericFlow.constructionMaterials.field.qualityTooltipFirstLine') }}<br/>
                            {{ $t('quoteBuyGenericFlow.constructionMaterials.field.qualityTooltipSecondLine') }}
                          </span>
                        </div>
                      </div>
                      <ea-select
                        v-model="model.quality"
                        :placeholder="consultaOperation ? '' : $t('common.label.select')"
                        :readonly="(qualityList && qualityList.length === 1) ||
                        !isElementModificable('CDCALIVV', datosObjeto)"
                      >
                        <ea-option
                          v-for="qualityType in qualityList"
                          :key="qualityType.value"
                          :label="capitalizeFirstLetter(qualityType.label)"
                          :value="qualityType.value"
                        />
                      </ea-select>
                    </ea-form-item>
                  </ea-col>
              </ea-row>
              <ea-row
                class="p-x-12"
                v-if="isElementPrintable(['TCCARASE','INUSOVIV', 'INUSOSAT'], datosObjeto, consultaOperation)">
                <div class="d-display-flex d-align-items-center">
                  <ea-col v-if="isElementPrintable(['TCCARASE'], datosObjeto, consultaOperation)"
                  :span="6">
                  <ea-form-item
                    :required="!consultaOperation && isElementRequired('TCCARASE')"
                    prop="ownerOrRenter"
                    :label="$t('quoteBuyGenericFlow.generalCharacteristics.field.ownerOrRenter')"
                  >
                    <ea-select
                      v-model="model.ownerOrRenter"
                      :placeholder="consultaOperation ? '' : $t('common.label.select')"
                      :readonly="(ownerOrRenterList && ownerOrRenterList.length === 1) ||
                        !isElementModificable('TCCARASE', datosObjeto)"
                    >
                      <ea-option
                        v-for="ownerType in ownerOrRenterList"
                        :key="ownerType.value"
                        :label="capitalizeFirstLetter(ownerType.label)"
                        :value="ownerType.value"
                      />
                    </ea-select>
                  </ea-form-item>
                  </ea-col>
                  <ea-col v-if="isElementPrintable(['INUSOVIV'], datosObjeto, consultaOperation)"
                    :span="6">
                    <ea-form-item
                      :required="!consultaOperation && isElementRequired('INUSOVIV')"
                      prop="buildingUse"
                      :label="$t('quoteBuyGenericFlow.generalCharacteristics.field.buildingUse')"
                    >
                      <ea-select
                        v-model="model.buildingUse"
                        :placeholder="consultaOperation ? '' : $t('common.label.select')"
                        :readonly="(buildingUseList && buildingUseList.length === 1) ||
                        !isElementModificable('INUSOVIV', datosObjeto)"
                      >
                        <ea-option
                          v-for="buildingUseType in buildingUseList"
                          :key="buildingUseType.value"
                          :label="capitalizeFirstLetter(buildingUseType.label)"
                          :value="buildingUseType.value"
                        />
                      </ea-select>
                    </ea-form-item>
                  </ea-col>
                  <ea-col v-if="isElementPrintable(['INUSOSAT'], datosObjeto, consultaOperation)"
                    :span="6">
                    <ea-form-item
                      prop="hasTuristicUse"
                    >
                    <div
                      class="d-display-flex d-align-items-center">
                      <ea-checkbox
                        v-model="model.hasTuristicUse"
                        :checked="model.hasTuristicUse"
                        :label="$t('quoteBuyGenericFlow.generalCharacteristics.field.hasTuristicUse')"
                        :readonly="!isElementModificable('INUSOSAT', datosObjeto)"
                      />
                        <ea-tooltip
                          class="m-l-8"
                          placement="top"
                          :content="$t('quoteBuyGenericFlow.generalCharacteristics.field.hasTuristicUseTooltip')"
                        >
                      <ea-icon class="m-r-8" status="info" icon="z-info" />
                    </ea-tooltip>
                    </div>
                    </ea-form-item>
                  </ea-col>
                </div>
              </ea-row>
            </ea-row>
            <!-- Superficie -->
            <ea-row v-if="isElementPrintable(['NUSUPVIV','NUSUPJAR','NUSUPTOT'], datosObjeto, consultaOperation)">
              <ea-col :span="24">
                <ea-heading level="5" class="m-b-16">
                  {{ $t('quoteBuyGenericFlow.surface.title') }}
                </ea-heading>
              </ea-col>
              <ea-col :span="6" v-if="isElementPrintable(['NUSUPVIV'], datosObjeto, consultaOperation)">
                <ea-form-item
                  prop="propertySize"
                  :label="$t('quoteBuyGenericFlow.surface.field.placeSize')"
                  :required="!consultaOperation && isElementRequired('NUSUPVIV', datosObjeto)"
                >
                  <ea-input-number
                    v-model="model.propertySize"
                    :readonly="!isElementModificable('NUSUPVIV', datosObjeto)"
                    :precision="returnElementMaxDecimalsByCode('NUSUPVIV')"
                  />
                </ea-form-item>
              </ea-col>
              <ea-col :span="6" v-if="isElementPrintable(['NUSUPJAR'], datosObjeto, consultaOperation)">
                <ea-form-item
                  prop="backyardSize"
                  :label="$t('quoteBuyGenericFlow.surface.field.exteriorSize')"
                  :required="!consultaOperation && isElementRequired('NUSUPJAR', datosObjeto)"
                >
                  <div slot="tooltipIconInfo">
                    <div slot="content">
                      <span>{{$t('quoteBuyGenericFlow.surface.tooltip')}}</span>
                    </div>
                  </div>
                  <ea-input-number
                    v-model="model.exteriorSize"
                    :readonly="!isElementModificable('NUSUPJAR', datosObjeto)"
                    :precision="returnElementMaxDecimalsByCode('NUSUPJAR')"
                  />
                </ea-form-item>
              </ea-col>
              <ea-col :span="6" v-if="isElementPrintable(['NUSUPTOT'], datosObjeto, consultaOperation)">
                <ea-form-item
                  prop="propertyTotalSize"
                  :label="$t('quoteBuyGenericFlow.surface.field.propertyTotalSize')"
                  :required="!consultaOperation
                  && isElementRequiredException(
                    'NUSUPTOT', requiredExceptionList, notRequiredExceptionList, datosObjeto
                    )"
                >
                  <ea-input-number
                    v-model="model.propertyTotalSize"
                    :readonly="isElementPrintable(['NUSUPJAR', 'NUSUPVIV'], datosObjeto)"
                    :precision="returnElementMaxDecimalsByCode('NUSUPTOT')"
                  />
                </ea-form-item>
              </ea-col>
            </ea-row>
            <!-- Medidas de protección de riesgo -->
            <ea-row v-if="isElementPrintable(
              ['INPUESEG','CDPUSEGU','INALACON','INREJASH','CDREJASH','INCAJAFU'],
              datosObjeto,
              consultaOperation)">
              <ea-row class="p-x-12">
                <ea-col :span="24">
                  <ea-heading level="5">
                    {{ $t('quoteBuyGenericFlow.securityMeasures.hogarTitle') }}
                  </ea-heading>
                </ea-col>
              </ea-row>
              <ea-row class="p-x-12">
                <div class="d-display-flex d-align-items-center">
                  <ea-col v-if="isElementPrintable(['INPUESEG'], datosObjeto, consultaOperation)"
                    :span="6">
                    <ea-form-item
                      prop="hasProtectionDoor"
                    >
                      <ea-checkbox
                        v-model="model.hasProtectionDoor"
                        :checked="model.hasProtectionDoor"
                        :label="$t('quoteBuyGenericFlow.securityMeasures.field.extraProtectionDoor')"
                        :readonly="!isElementModificable('INPUESEG', datosObjeto)"
                      />
                    </ea-form-item>
                  </ea-col>
                  <ea-col v-if="isElementPrintable(['CDPUSEGU'], datosObjeto, consultaOperation)"
                    :span="6">
                    <ea-form-item
                      :required="!consultaOperation && isElementRequired('CDPUSEGU')"
                      prop="protectionDoor"
                      :label="$t('quoteBuyGenericFlow.securityMeasures.field.extraProtectionDoor')"
                    >
                      <ea-select
                        v-model="model.protectionDoor"
                        :placeholder="consultaOperation ? '' : $t('common.label.select')"
                        :readonly="protectionDoorList && protectionDoorList.length === 1 ||
                        !isElementModificable('CDPUSEGU', datosObjeto)"
                      >
                        <ea-option
                          v-for="door in protectionDoorList"
                          :key="door.value"
                          :label="capitalizeFirstLetter(door.label)"
                          :value="door.value"
                        />
                      </ea-select>
                    </ea-form-item>
                  </ea-col>
                  <ea-col v-if="isElementPrintable(['INALACON'], datosObjeto, consultaOperation)"
                    :span="6">
                    <ea-form-item
                      prop="hasProtectionAlarm"
                    >
                      <ea-checkbox
                        v-model="model.hasProtectionAlarm"
                        :checked="model.hasProtectionAlarm"
                        :label="$t('quoteBuyGenericFlow.securityMeasures.field.extraProtectionAlarm')"
                        :readonly="!isElementModificable('INALACON', datosObjeto)"
                      />
                    </ea-form-item>
                  </ea-col>
                  <ea-col v-if="isElementPrintable(['INREJASH'], datosObjeto, consultaOperation)"
                    :span="6">
                    <ea-form-item
                      prop="hasProtectionGrate"
                    >
                      <ea-checkbox
                        v-model="model.hasProtectionGrate"
                        :checked="model.hasProtectionGrate"
                        :label="$t('quoteBuyGenericFlow.securityMeasures.field.extraProtectionGrate')"
                        :readonly="!isElementModificable('INREJASH', datosObjeto)"
                      />
                    </ea-form-item>
                  </ea-col>
                  <ea-col v-if="isElementPrintable(['CDREJASH'], datosObjeto, consultaOperation)"
                    :span="6">
                    <ea-form-item
                      :required="!consultaOperation && isElementRequired('CDREJASH')"
                      prop="protectionGrate"
                      :label="$t('quoteBuyGenericFlow.securityMeasures.field.extraProtectionGrate')"
                    >
                      <ea-select
                        v-model="model.protectionGrate"
                        :placeholder="consultaOperation ? '' : $t('common.label.select')"
                        :readonly="protectionGrateList && protectionGrateList.length === 1 ||
                        !isElementModificable('CDREJASH', datosObjeto)"
                      >
                        <ea-option
                          v-for="protectionGrate in protectionGrateList"
                          :key="protectionGrate.value"
                          :label="capitalizeFirstLetter(protectionGrate.label)"
                          :value="protectionGrate.value"
                        />
                      </ea-select>
                    </ea-form-item>
                  </ea-col>
                  <ea-col v-if="isElementPrintable(['INCAJAFU'], datosObjeto, consultaOperation)"
                    :span="6">
                    <ea-form-item
                      :required="!consultaOperation
                        && isElementRequiredException(
                          'INCAJAFU', requiredExceptionList, notRequiredExceptionList, datosObjeto
                          )"
                      prop="protectionSafe"
                      :label="$t('quoteBuyGenericFlow.securityMeasures.field.extraProtectionSafe')"
                    >
                      <ea-select
                        v-model="model.protectionSafe"
                        :placeholder="consultaOperation ? '' : $t('common.label.select')"
                        :readonly="safeList && safeList.length === 1 ||
                        !isElementModificable('INCAJAFU', datosObjeto)"
                      >
                        <ea-option
                          v-for="safeType in safeList"
                          :key="safeType.value"
                          :label="capitalizeFirstLetter(safeType.label)"
                          :value="safeType.value"
                        />
                      </ea-select>
                    </ea-form-item>
                  </ea-col>
                </div>
              </ea-row>
            </ea-row>
            <!-- Garantías y Franquicia -->
            <ea-row v-if="isElementPrintable(['INDGGHOG', 'CDFRANQA'], datosObjeto, consultaOperation)">
              <ea-col :span="24">
                <ea-heading level="5" class="m-b-16">
                  {{ $t('warranties.shared.warrentiesSelection') }}
                </ea-heading>
              </ea-col>
              <ea-col :span="6" v-if="isElementPrintable(['INDGGHOG'], datosObjeto, consultaOperation)">
                <ea-form-item
                  :required="!consultaOperation && isElementRequired('INDGGHOG', datosObjeto)"
                  prop="warranty"
                  :label="$t('warranties.shared.warrantiesGroup')"
                  >
                  <ea-select
                    v-model="model.warranty"
                    :placeholder="consultaOperation ? '' : $t('common.label.select')"
                    :readonly="(warrantyGroupList && warrantyGroupList.length === 1) ||
                        !isElementModificable('INDGGHOG', datosObjeto)"
                    >
                    <ea-option
                      v-for="warrantyType in warrantyGroupList"
                      :key="warrantyType.value"
                      :label="capitalizeFirstLetter(warrantyType.label)"
                      :value="warrantyType.value"
                    />
                  </ea-select>
                </ea-form-item>
              </ea-col>
              <ea-col :span="6" v-if="isElementPrintable(['CDFRANQA'],datosObjeto, consultaOperation)">
                <ea-form-item
                  :required="!consultaOperation && isElementRequired('CDFRANQA', datosObjeto)"
                  prop="franchise"
                  :label="$t('quoteBuyGenericFlow.technicalConsiderationsBusiness.field.franchise')"
                >
                  <ea-select
                    v-model="model.franchise"
                    :placeholder="consultaOperation ? '' : $t('common.label.select')"
                    :readonly="(franchiseList && franchiseList.length === 1) ||
                      !isElementModificable('CDFRANQA', datosObjeto)"
                  >
                    <ea-option
                      v-for="franchiseType in franchiseList"
                      :key="franchiseType.value"
                      :label="capitalizeFirstLetter(franchiseType.label)"
                      :value="franchiseType.value"
                    />
                  </ea-select>
                </ea-form-item>
              </ea-col>
            </ea-row>
          </ea-form>

          <qb-bank-details
            id="qb-bank-details"
            ref="bankdetailsBC"
            v-if="isElementPrintable(
              ['DSNUCODB','DSNUSODB','CDOFICDB','DSOFICDB','NODIRADB','TCCOPADB'],
              datosAdicionales)"
            v-model="model.bankDetails"
            :datosAdicionales="datosAdicionales"
            :consultaOperation="consultaOperation">
          </qb-bank-details>
        </div>
      </ea-card>
  </div>
</template>

<script lang="ts">
import {
  Component, Prop, Watch
} from 'vue-property-decorator';

import {
  mixins
} from 'vue-class-component';

import {
  EABusinessComponent, EACorporateTableDirective
} from '@zurich-es-npm/ea-front-web-core';

import {
  GetGeneralDataResponseDataDatosObjeto
} from '@/services/V1/quoteAndBuy/getGeneralDataOperation/post';

import QbProductoAseguradoHogarModel, {
  LabelValue
} from './qb-producto-asegurado-hogar-model';
import PostCodeLocationInputBusiness from '../post-code-location-input/post-code-location-input-business.vue';
import TextUtils from '@/utils/text-utils';
import {
  isElementModificable,
  isAnyElementModificable,
  isElementRequired,
  isElementRequiredException,
  returnElementByCode,
  returnNumericElementValueByCode,
  getFieldRules,
  returnElementListByCode,
  returnElementValueByCode,
  returnMinMaxValues,
  isElementPrintable
} from '@/utils/object-utils';
import {
  EAFormValidationOptions,
  EAValidation,
  Form,
  EACompositeValidation,
} from '@zurich-es-npm/ea-front-web-ui';
import QbRiskSituationBusiness from '../qb-risk-situation/qb-risk-situation-business.vue';
import {
  ElForm
} from 'element-ui/types/form';
import QbBankDetailsBusiness from '../qb-bank-details/qb-bank-details-business.vue';

@Component({
  name: 'qb-producto-asegurado-hogar',
  components: {
    PostCodeLocationInput: PostCodeLocationInputBusiness,
    QbRiskSituation: QbRiskSituationBusiness,
    QbBankDetails: QbBankDetailsBusiness
  },
  directives: {
    'ea-corporate-table': EACorporateTableDirective,
  }
})

/**
 * Business Component qb-producto-asegurado-hogar
 */
export default class QbProductoAseguradoHogarBusiness extends mixins<
EABusinessComponent<QbProductoAseguradoHogarModel>
>(EABusinessComponent) {

  @Prop({
    required: true
  })
    datosObjeto!: GetGeneralDataResponseDataDatosObjeto[];

  @Prop({
    required: true
  })
    datosAdicionales!: GetGeneralDataResponseDataDatosObjeto[];

  @Prop({
    'default': () => false
  })
    consultaOperation?: boolean;
    
  buildingTypeList?: LabelValue[] = [];

  buildingLocationList?: LabelValue[] = [];

  ownerOrRenterList?: LabelValue[] = [];

  buildingUseList?: LabelValue[] = [];
  
  qualityList?: LabelValue[] = [];

  franchiseList?: LabelValue[] = [];

  safeList?: LabelValue[] = [];

  protectionDoorList?: LabelValue[] = [];

  protectionGrateList?: LabelValue[] = [];
  
  requiredExceptionList = [];

  notRequiredExceptionList = ['NUSUPTOT', 'INCAJAFU'];

  warrantyGroupList?: LabelValue[] = [];

  isElementRequired = isElementRequired;

  isElementRequiredException = isElementRequiredException;

  isElementModificable = isElementModificable;

  isAnyElementModificable = isAnyElementModificable;

  returnElementByCode = returnElementByCode;

  returnNumericElementValueByCode = returnNumericElementValueByCode;

  getFieldRules = getFieldRules;

  returnElementValueByCode = returnElementValueByCode;

  returnElementListByCode = returnElementListByCode;

  returnMinMaxValues = returnMinMaxValues;

  isElementPrintable = isElementPrintable;

  /**
   * Getter on Hogar Title
   */
  get hogarTitle() {
    return this.$t('quoteBuyGenericFlow.propertyData.houseDataTitle').toString();
  }

  /**
   * Gets form validation options based on retrieved data
   */
  get validationOptions(): EAFormValidationOptions {
    return {
      rules: {
        constructionYear: getFieldRules(this.model.FIELD_TYPES.NUMBER, 'AACONSTR', this.datosObjeto),
        remodelingYear: getFieldRules(this.model.FIELD_TYPES.NUMBER, 'AAREFORM', this.datosObjeto),

        buildingType: getFieldRules(this.model.FIELD_TYPES.STRING, 'TCCLAVIV', this.datosObjeto),
        buildingLocation: getFieldRules(this.model.FIELD_TYPES.STRING, 'TCUBIVIV', this.datosObjeto),
        ownerOrRenter: getFieldRules(this.model.FIELD_TYPES.STRING, 'TCCARASE', this.datosObjeto),
        buildingUse: getFieldRules(this.model.FIELD_TYPES.STRING, 'INUSOVIV', this.datosObjeto),
        
        quality: getFieldRules(this.model.FIELD_TYPES.STRING, 'CDCALIVV', this.datosObjeto),

        propertySize: getFieldRules(this.model.FIELD_TYPES.NUMBER, 'NUSUPVIV', this.datosObjeto),
        backyardSize: getFieldRules(this.model.FIELD_TYPES.NUMBER, 'NUSUPJAR', this.datosObjeto),
        franchise: getFieldRules(this.model.FIELD_TYPES.DEFAULT, 'CDFRANQA', this.datosObjeto),
        hasProtectionSafe: getFieldRules(this.model.FIELD_TYPES.DEFAULT, 'INCAJAFU', this.datosObjeto),
               
        warranty: getFieldRules(this.model.FIELD_TYPES.DEFAULT, 'INDGGHOG', this.datosObjeto),

        protectionDoor: getFieldRules(this.model.FIELD_TYPES.STRING, 'CDPUSEGU', this.datosObjeto),
        protectionGrate: getFieldRules(this.model.FIELD_TYPES.STRING, 'CDREJASH', this.datosObjeto)
      }
    };
  }

  /**
   * Returns the concatented total size sum variables for the @Watch('computedTotalSize') method
   */
  get computedTotalSize() {
    return `${this.model.propertySize}|${this.model.exteriorSize}`;
  }

  /**
   * Calcuates the propertyTotalSize when the computedTotalSize upadtes
   */
  @Watch('computedTotalSize')
  calculateTotalSize() {
    this.model.propertyTotalSize =
      (this.model.propertySize && this.model.propertySize > 0 ? this.model.propertySize : 0) +
      (this.model.exteriorSize && this.model.exteriorSize > 0 ? this.model.exteriorSize : 0);
  }

  /**
   * Hook on created.
   */
  created() {
    // Remodeling years
    this.model.constructionYear = returnNumericElementValueByCode('AACONSTR', this.datosObjeto);
    this.model.remodelingYear = returnNumericElementValueByCode('AAREFORM', this.datosObjeto);

    this.buildingTypeList = returnElementListByCode('TCCLAVIV', this.datosObjeto);
    this.model.buildingType = returnElementValueByCode('TCCLAVIV', this.datosObjeto);

    this.buildingLocationList = returnElementListByCode('TCUBIVIV', this.datosObjeto);
    this.model.buildingLocation = returnElementValueByCode('TCUBIVIV', this.datosObjeto);

    this.ownerOrRenterList = returnElementListByCode('TCCARASE', this.datosObjeto);
    this.model.ownerOrRenter = returnElementValueByCode('TCCARASE', this.datosObjeto);

    this.buildingUseList = returnElementListByCode('INUSOVIV', this.datosObjeto);
    this.model.buildingUse = returnElementValueByCode('INUSOVIV', this.datosObjeto);
    
    this.qualityList = returnElementListByCode('CDCALIVV', this.datosObjeto);
    this.model.quality = returnElementValueByCode('CDCALIVV', this.datosObjeto);

    this.model.hasTuristicUse = returnElementValueByCode('INUSOSAT', this.datosObjeto) === 'S';
    
    // Surface
    this.model.propertySize = returnNumericElementValueByCode('NUSUPVIV', this.datosObjeto);
    this.model.exteriorSize = returnNumericElementValueByCode('NUSUPJAR', this.datosObjeto);
    this.model.propertyTotalSize = returnNumericElementValueByCode('NUSUPTOT', this.datosObjeto);

    this.model.franchise = returnElementValueByCode('CDFRANQA', this.datosObjeto);
    this.franchiseList = returnElementListByCode('CDFRANQA', this.datosObjeto);
    
    // Security measures
    this.model.hasProtectionDoor = returnElementValueByCode('INPUESEG', this.datosObjeto) === 'S';
    this.model.hasProtectionGrate = returnElementValueByCode('INREJASH', this.datosObjeto) === 'S';
    this.model.hasProtectionAlarm = returnElementValueByCode('INALACON', this.datosObjeto) === 'S';
    this.model.protectionSafe = returnElementValueByCode('INCAJAFU', this.datosObjeto);
    this.safeList = returnElementListByCode('INCAJAFU', this.datosObjeto);
    this.protectionDoorList = returnElementListByCode('CDPUSEGU', this.datosObjeto);
    this.model.protectionDoor = returnElementValueByCode('CDPUSEGU', this.datosObjeto);
    this.protectionGrateList = returnElementListByCode('CDREJASH', this.datosObjeto);
    this.model.protectionGrate = returnElementValueByCode('CDREJASH', this.datosObjeto);
    
    // Warrenties Group
    this.model.warranty = returnElementValueByCode('INDGGHOG', this.datosObjeto);
    this.model.warrantyGroupListIsModificable = isElementModificable('INDGGHOG', this.datosObjeto);
    // Warrenties Group
    this.warrantyGroupList = returnElementListByCode('INDGGHOG', this.datosObjeto);
    this.model.warrantyGroupList = this.warrantyGroupList;

    this.$nextTick(() => {
      const comp = this.$refs.riskSituationBC as QbRiskSituationBusiness;
      comp.setFormValues();
      const aditionalDataComp = this.$refs.bankdetailsBC as QbBankDetailsBusiness;
      if (aditionalDataComp) {
        aditionalDataComp.setFormValues();
      }
      this.update();
    });
  }

  /**
   * Returns element max length
   * @param {string} code
   * @returns { number | undefined }
   */
  returnElementMaxDecimalsByCode(code: string): number | undefined {
    const element = returnElementByCode(code, this.datosObjeto);
    if (element) {
      return element.numeroDecimalesElemento;
    }
  }

  /**
   * Handles construction year input change
   * Changes minimum value for reform years inputs
   */
  async onConstructionYearChange() {
    const currentYear: number = new Date().getFullYear();
    const constructionYearElement = returnElementByCode('AACONSTR', this.datosObjeto);
    const remodelingYearElement = returnElementByCode('AAREFORM', this.datosObjeto);
    
    if (constructionYearElement) {
      constructionYearElement.valorMaximoElemento = currentYear;
    }
    if (remodelingYearElement) {
      remodelingYearElement.valorMinimoElemento = this.model.constructionYear;
    }
    await this.$nextTick();

    const propsOfFieldsToValidate = ['constructionYear', 'remodelingYear'];
    propsOfFieldsToValidate.forEach(prop => this.validateField(prop));
  }

  /**
   * Validates a single field
   * @param {string} fieldName
   */
  validateField(fieldName: string) {
    this.clearFlowErrors();

    const form = this.$refs.form as Form;
    const elForm = form?.$refs.form as ElForm;

    elForm.validateField(fieldName);
  }


  /**
   * Returns validation object
   * @returns {EAValidation }
   */
  public validation(): EAValidation {
    const form = this.$refs.form as Form;

    const riskSituationBC: QbRiskSituationBusiness =
      this.$refs.riskSituationBC as QbRiskSituationBusiness;
    riskSituationBC.updateAllData();
    const bankdetailsBC: QbBankDetailsBusiness =
      this.$refs.bankdetailsBC as QbBankDetailsBusiness;
    bankdetailsBC?.update();

    const validations = [form.validation(), riskSituationBC.validation()];
    if (bankdetailsBC) {
      validations.push(bankdetailsBC.validation());
    }
    return new EACompositeValidation(validations, this.$tc.bind(this));
  }

  /**
   * Updates zip and current model
   */
  public updateAllData() {
    this.updateChildBusinessComponents();
    this.update();
  }

  /**
   * Updates child business components
   */
  public updateChildBusinessComponents() {
    const riskSituationBC: QbRiskSituationBusiness = this.$refs.riskSituationBC as QbRiskSituationBusiness;
    const bankdetailsBC: QbBankDetailsBusiness = this.$refs.bankdetailsBC as QbBankDetailsBusiness;
    riskSituationBC.updateAllData();
    bankdetailsBC?.update();
  }

  /**
   * Capitalizes first letter of received string
   * @param {string} string
   * @returns {string}
   */
  capitalizeFirstLetter(string: string | undefined): string {
    return string ? TextUtils.capitalizeFirstLetterOneWord(string.toLowerCase()) : '';
  }

}
</script>
