import {
  EASecurityAbilityState
} from '@zurich-es-npm/ea-front-web-core/lib/store/contexts/security/ea-security-context-types';
import {
  EAContextManager,
  EAApplicationLogger,
  EAApplicationError,
  EASecurityConfigManager
} from '@zurich-es-npm/ea-front-web-core';
import {
  GetDefaultUserValuesResponse,
  GetDefaultUserValuesResponseCodigoTipoUsuarioEnum as UserTypesEnum
} from '@/services/V1/home/getDefaultUserValuesOperation/post';
import VueRouter from 'vue-router';

export type AbilitiyActions = 'read' | 'create';

export const DefaultUserValuesSessionName = 'defaultUserValues';

/**
 * Permissions utils object
 */
export class PermissionUtils {

  /**
   * Get user type value (`userValuesSession`) from session storage.
   * @returns {string} userValuesSession value representing user type. Empty if value not found.
   */
  public static getUserType(): UserTypesEnum | '' {
    const userValuesSession =
      EAContextManager.getInstance().getSessionValue(DefaultUserValuesSessionName) as GetDefaultUserValuesResponse;
    if (!userValuesSession || !userValuesSession.codigoTipoUsuario) {
      const errorUserType = new EAApplicationError('ZON00062');
      const logger = new EAApplicationLogger();
      logger.error(errorUserType);
      return '';
    } else {
      return userValuesSession.codigoTipoUsuario;
    }
  }

  /**
   * Get security user abilities from session storage.
   * @returns {EASecurityAbilityState[]} security user abilities
   */
  public static getUserAbilities(): EASecurityAbilityState[] {
    return EAContextManager.getInstance().getUserAbilities();
  }

  /**
   * Returns if the user is one of the given types.
   * @param {string} currentUserType current user type given by CDTIPUSE
   * @param {UserTypesEnum | UserTypesEnum[]} possibleTypes
   * @returns {boolean} if the user is one of the possible user types to match
   */
  public static isUserOfType(
    currentUserType: string,
    possibleTypes: UserTypesEnum | UserTypesEnum[]
  ): boolean {
    if (typeof possibleTypes === 'string') {
      possibleTypes = [possibleTypes];
    }
    return !!possibleTypes.find(type => type === currentUserType);
  }

  /**
   * Returns if the user has a given ability.
   * @param {EASecurityAbilityState[]} userAbilities list of user abilities
   * @param {string} subject ability name
   * @param {string} action 'read' or 'create'
   * @returns {boolean} if the user has this ability
   */
  public static hasAbility(
    userAbilities: EASecurityAbilityState[],
    subject: string,
    action?: AbilitiyActions
  ): boolean {
    const ability = userAbilities.find(ab => ab.subject === subject);
    if (ability && !action) {
      return true;
    } else if (ability && action) {
      return !!ability.action.find(act => act === action);
    }
    return false;
  }

  /**
   * Returns if the user has all given abilities.
   * @param {EASecurityAbilityState[]} userAbilities list of user abilities
   * @param {string[]} subjects abilities names array
   * @returns {boolean} if the user has this ability
   */
  public static hasAllAbilities(
    userAbilities: EASecurityAbilityState[],
    subjects: string[],
  ): boolean {
    const ability = subjects.map(elem => !!userAbilities.find(ab => ab.subject === elem));
    return ability.filter(value => value === false).length === 0;
  }

  /**
   * Returns if the user has any of the given abilities.
   * @param {EASecurityAbilityState[]} userAbilities list of user abilities
   * @param {string[]} subjects abilities names array
   * @returns {boolean} if the user has this ability
   */
  public static hasAnyOfAbilitiesList(
    userAbilities: EASecurityAbilityState[],
    subjects: string[],
  ): boolean {
    const ability = subjects.map(elem => !!userAbilities.find(ab => ab.subject === elem));
    return ability.filter(value => value === true).length > 0;
  }

  /**
   * Returns if the user has a given ability.
   * @param {VueRouter} router name
   */
  public static redirectToUnauthorizedFlow(
    router: VueRouter
  ): void {
    const store = EAContextManager.getInstance().getStore();
    store.commit('flow/deleteFlowContext');
    const securityConfig = EASecurityConfigManager.getInstance().getConfig();
    router.push({
      name: securityConfig.unauthorizedFlowName
    });
  }

}
export default PermissionUtils;
