export enum CoexistenceId {
  ConsultaOferta = '0236',
  ConsultaPoliza = '0240',
  Suplementos = '0260',
  Reemplazos = '0270',
  MantenimientoIncompletas = '0250',
  MantenimientoOfertas = '0235',
  AltaNotas = '0682',
  ConsultaNotas = '0684',
  GestionDocumentos = '0291',
  GestionCompetencias = '0273',
  VersionesPoliza = '0276',
  Recibos = '0510', // Consulta de recibos???
  MantenimientoRecibos = '0515',
  RecibosUsuarioProfesional = '9510',
  Siniestros = '0430',
  ConsultaCliente = '0802',
  GestionCartera = '1140'
}

export interface ActionsFlowInterface {
  actionId: string;
  name: string;
  operationId: CoexistenceId;
}
