/* eslint-disable */

/**
 * Mediación Digital - Get coexistence menu
 * Devuelve el menu de la web antigua de ZonaZurich
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import {
  EASDKConfig,
  EAUrlUtils
} from '@zurich-es-npm/ea-shared-utils-js';

import * as globalImportUrl from 'url';
import {
  Configuration
} from './configuration';
import globalAxios, {
  AxiosPromise,
  AxiosInstance,
  AxiosResponse
} from 'axios';

const BASE_PATH = EAUrlUtils.removeLastForwardSlash('http://localhost:3000');

/**
 *
 * @export
 */
export const COLLECTION_FORMATS = {
  csv: ',',
  ssv: ' ',
  tsv: '\t',
  pipes: '|',
};

/**
 *  
 * @export
 * @interface RequestArgs
 */
export interface RequestArgs {
  url: string;
  options: any;
}

/**
 * 
 * @export
 * @class BaseAPI
 */
export class BaseAPI {
  protected configuration: Configuration | undefined;

  /**
   *Creates an instance of BaseAPI.
   * @param {Configuration} [configuration]
   * @param {string} [basePath=BASE_PATH]
   * @param {AxiosInstance} [axios=globalAxios]
   * @memberof BaseAPI
   */
  public constructor(
    configuration?: Configuration,
    protected basePath: string = BASE_PATH,
    protected axios: AxiosInstance = globalAxios) {
    if (configuration) {
      this.configuration = configuration;
      this.basePath = configuration.basePath || this.basePath;
    }
  }
}

/**
 * 
 * @export
 * @class RequiredError
 * @extends {Error}
 */
export class RequiredError extends Error {
  public name: 'RequiredError' = 'RequiredError';

  public field: string;

  /**
   *Creates an instance of RequiredError.
   * @param {string} field
   * @param {string} [msg]
   * @memberof RequiredError
   */
  public constructor(field: string, msg?: string) {
    super(msg);
    this.field=field;
  }
}

/**
 * 
 * @export
 * @class AsyncRequestError
 * @extends {Error}
 */
export class AsyncRequestError extends Error {
  public name: 'AsyncRequestError' = 'AsyncRequestError';

  /**
   *Creates an instance of AsyncRequestError.
   * @param {string} field
   * @param {string} [msg]
   * @memberof AsyncRequestError
   */
  public constructor(msg?: string) {
    super(msg);
  }
}

/**
 * 
 * @export
 * @interface CoexistenceMenuResponse
 */
export interface CoexistenceMenuResponse {
    /**
     * 
     * @type {Array<MenuItem>}
     * @memberof CoexistenceMenuResponse
     */
    menu?: Array<MenuItem>;
    /**
     * 
     * @type {Array<Error>}
     * @memberof CoexistenceMenuResponse
     */
    errors?: Array<Error>;
}/**
 * 
 * @export
 * @interface Error
 */
export interface Error {
    /**
     * 
     * @type {string}
     * @memberof Error
     */
    code: string;
    /**
     * 
     * @type {string}
     * @memberof Error
     */
    message: string;
    /**
     * 
     * @type {number}
     * @memberof Error
     */
    severity: number;
    /**
     * 
     * @type {Array<object>}
     * @memberof Error
     */
    childErrors?: Array<object>;
}/**
 * 
 * @export
 * @interface MenuItem
 */
export interface MenuItem {
    /**
     * 
     * @type {string}
     * @memberof MenuItem
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof MenuItem
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof MenuItem
     */
    url?: string;
    /**
     * 
     * @type {string}
     * @memberof MenuItem
     */
    scenario?: string;
    /**
     * 
     * @type {string}
     * @memberof MenuItem
     */
    type?: string;
}

type coexistenceMenuParams = {
  };

/**
 * CorpTablesApi - axios parameter creator
 * @param {Configuration} [configuration]
 * @returns {any}
 */
const CorpTablesApiAxiosParamCreator = function(configuration?: Configuration): any {
  return {
    
    /**
     * Devuelve la estructura del menu de la antigua zona zurich
     * @param {string} [acceptLanguage] The Accept-Language request HTTP header advertises which languages the client is able to understand, and which locale variant is preferred.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @returns {RequestArgs}
     */
    coexistenceMenu(params: coexistenceMenuParams, options: any = {}): RequestArgs {
      const localVarPath = `/V1/coexistence/menu`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true) as Partial<globalImportUrl.UrlWithParsedQuery>;
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;


  
      localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
      // Fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * CorpTablesApi - functional programming interface
 * @param {Configuration} [configuration]
 * @returns {any}
 */
const CorpTablesApiFp = function(configuration?: Configuration): any {
  return {
    
    /**
     * Devuelve la estructura del menu de la antigua zona zurich
     * @param {string} [acceptLanguage] The Accept-Language request HTTP header advertises which languages the client is able to understand, and which locale variant is preferred.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @returns {any}
     */
    coexistenceMenu(params: coexistenceMenuParams, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<CoexistenceMenuResponse> {
      const localVarAxiosArgs = CorpTablesApiAxiosParamCreator(configuration).coexistenceMenu(params, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH): any => {
        const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * CorpTablesApi - factory interface
 * @param {Configuration} configuration
 * @param {string} basePath
 * @param {AxiosInstance} axios
 * @export
 * @returns {any}
 */
const CorpTablesApiFactory = function(configuration?: Configuration, basePath?: string, axios?: AxiosInstance): any {
  return {
    
    /**
     * Devuelve la estructura del menu de la antigua zona zurich
     * @param {string} [acceptLanguage] The Accept-Language request HTTP header advertises which languages the client is able to understand, and which locale variant is preferred.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @returns {any}
     */
    coexistenceMenu(params: coexistenceMenuParams, options?: any): any {
      return CorpTablesApiFp(configuration).coexistenceMenu(params, options)(axios, basePath);
    },
  };
};

/** 
 * CorpTablesApi - object-oriented interface
 * @export
 * @class CorpTablesApi
 * @extends {BaseAPI}
 */
export class CorpTablesApi extends BaseAPI {
  
  /**
   * Devuelve la estructura del menu de la antigua zona zurich
   * @param {string} [acceptLanguage] The Accept-Language request HTTP header advertises which languages the client is able to understand, and which locale variant is preferred.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @returns {any}
   * @memberof CorpTablesApi
   */
  public coexistenceMenu(params: coexistenceMenuParams, options?: any): any {
    return CorpTablesApiFp(this.configuration).coexistenceMenu(params, options)(this.axios, this.basePath);
  }

}

/**
 * 
 * @export
 * @class EAcoexistenceMenuInputMessage
 */
export class EAcoexistenceMenuInputMessage {

  public constructor(){
  
  } 
}



/** 
 * EACorpTablesApi - Architecture client for coexistenceMenu
 * @export
 * @class EACorpTablesApi
 */
export class EACorpTablesApi {

  /**
   * 
   
   * @throws {AsyncRequestError | RequiredError}
   * @returns {Promise<CoexistenceMenuResponse | null>}
  */ 
  public async coexistenceMenu(params: coexistenceMenuParams): Promise<CoexistenceMenuResponse | null> {
    return this.coexistenceMenuPromise(params);
  }

  /**
   * 
   
   * @param {Function} callback
   * @throws {AsyncRequestError | RequiredError}
   */
  public async coexistenceMenuSteps(params: coexistenceMenuParams, callback: (res: AxiosResponse<CoexistenceMenuResponse | null>) => void) {
    let done: boolean = false;
    const httpClient: AxiosInstance = EASDKConfig.getHttpClient('/V1/coexistence/menu', 'corpTables', 'coexistenceMenu');
    const config: Configuration = {};
    const api = new CorpTablesApi(config, '', httpClient);
    const sleep = async(ms: number) => {
      return new Promise(resolve => setTimeout(resolve, ms));
    };
    const headers: { ['Async-Id']?: string } = {};
    while (!done) {
      const output = await api.coexistenceMenu(params, { headers }) as AxiosResponse<CoexistenceMenuResponse | null>;
      callback(output);
      if (output.status === 202) {
        try {
          const retry = parseInt(output.headers['retry']);
          headers['Async-Id'] = output.headers['request-id'];
          if (isNaN(retry) || retry <= 0 || retry > 1000 * 3600) {
            throw new Error(`Retry time not acceptable (in milliseconds): ${retry}`);
          }
          await sleep(retry);
        } catch (err) {
          throw new Error(`Error in Async Request ${err}`);
        }
      } else {
        done = true;
      }
    }
  }

  /**
   * 
   
   * @throws {AsyncRequestError | RequiredError}
   * @returns {Promise<CoexistenceMenuResponse | null>}
   */
  public async coexistenceMenuPromise(params: coexistenceMenuParams): Promise<CoexistenceMenuResponse | null> {
    return new Promise((resolve, reject) => {
      this.coexistenceMenuSteps(params, (response: AxiosResponse<CoexistenceMenuResponse | null>) => {
        if (response.status !== 202) {
          resolve(response.data);
        }
      })
      .catch((err) => {
        reject(err);
      });
    });
  } 
}


