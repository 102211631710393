<template>
  <div>
    <zz-collapse
      ref="zzCollapse"
      :title="$t(`warranties.${model.codigoGarantia}.description`)"
      :tooltipLabel="`warranties.${model.codigoGarantia}.tooltip`"
      :columnsSpan="columnsSpan"
      :existBeforeVersion="existBeforeVersion"
      :columnsChecked="getColumnsCheckedList()"
      :columnsDisabled="getColumnsDisabledList(model.elementosGarantias)"
      :columnsCodes="getColumnsCodeList(model.elementosGarantias)"
      :allProposalCodes="allProposalCodes"
      :warrantyHasVisibleElements="warrantyHasVisibleElements"
      @checkChange="onCheckChange"
    >
      <!-- Slot for zz-collapse-->
      <ea-form :model="ruleForm"
        :ref="`form-${model.codigoGarantia}`"
        :validationOptions="warrantyFormValidations"
        :validateOnRuleChange="false">
        <!-- Warranty Row-->
        <ea-row
          class="warranty__element-row p-a-16 m-0"
          v-for="elementoGarantia in visibleElementosGarantias"
          :key="`${model.codigoGarantia}-${elementoGarantia.codigoElemento}`"
        >
          <!-- Warranty Columns-->
          <ea-col :span="columnsSpan" class="warranty__title m-y-4">
            {{ $t(`warranties.${model.codigoGarantia}.elements.${elementoGarantia.codigoElemento}`) }}

            <!-- Tooltip -->
            <ea-tooltip placement="top" class="d-display-inline"
              v-if="$te(`warranties.${model.codigoGarantia}.tooltips.${elementoGarantia.codigoElemento}`)"
            >
              <ea-icon
                class="m-l-8"
                icon="z-info" />
              <div slot="content">
                {{ $t(`warranties.${model.codigoGarantia}.tooltips.${elementoGarantia.codigoElemento}`) }}
              </div>
            </ea-tooltip>
          </ea-col>

          <div :class="`warranty__elements`">
            <ea-col
              :span="columnsSpan"
              v-for="proposalCode in allProposalCodes"
              :key="`${proposalCode}`">
                <div
                  v-for="(datoPropuesta, index) in elementoGarantia.datosPropuestas"
                  :key="`${model.codigoGarantia}-${index}`"
                >
                <template v-if="proposalCode===datoPropuesta.codigoPropuesta">
                  <ea-form-item
                    :prop="`${elementoGarantia.codigoElemento}_${datoPropuesta.codigoPropuesta}`"
                    v-show="datoPropuesta.elementoVisible
                      && datoPropuesta.codigoPropuesta
                      && checksCurrentStatusRecord[datoPropuesta.codigoPropuesta]"
                  >
                  <!-- Aquí debería ir los elementos vacíos si tocan -->
                    <component
                      class="warranty__package-field"
                      :is="getComponentType(datoPropuesta)"
                      v-bind="getComponentProps(datoPropuesta, elementoGarantia)"
                      v-model="ruleForm[`${elementoGarantia.codigoElemento}_${datoPropuesta.codigoPropuesta}`]"
                      @change="onValueChange($event, elementoGarantia.codigoElemento, datoPropuesta)"
                    >
                      <ea-option
                        v-for="(option, index) in datoPropuesta.tablaRestricciones"
                        :key="index"
                        :label="option.nombreRestriccion"
                        :value="option.valorRestriccion"
                      />
                    </component>

                    <!-- Tooltip -->
                    <ea-tooltip placement="top" class="d-display-inline d-align-items-center"
                      v-if="
                        $te(`warranties.${model.codigoGarantia}.formItemTooltips.${elementoGarantia.codigoElemento}`)
                      "
                    >
                      <ea-icon
                        class="m-l-8"
                        icon="z-info" />
                      <div slot="content">
                        {{ $t(
                          `warranties.${model.codigoGarantia}.formItemTooltips.${elementoGarantia.codigoElemento}`
                        ) }}
                      </div>
                    </ea-tooltip>
                  </ea-form-item>
                </template>
                </div>
            </ea-col>
          </div>

          <!-- Warranty Columns-->
        </ea-row>
        <!-- Warranty Row-->
      </ea-form>
      <!-- Slot for zz-collapse-->
    </zz-collapse>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import {
  Component, Prop, Watch
} from 'vue-property-decorator';

import {
  mixins
} from 'vue-class-component';

import {
  EABusinessComponent, EASessionManager
} from '@zurich-es-npm/ea-front-web-core';

import {
  EAIValidationRule, EAValidation, Form
} from '@zurich-es-npm/ea-front-web-ui';

import WarrantyModel from './warranty-model';
import WarrantyFieldUtils from './warranty-field-utils';
import {
  WarrantyProperties, EventChange, ActionType,
  WarrantyElement, WarrantyElementComponentType
} from './warranty.types';
import {
  GetWarrantiesResponseDataDatosGarantias,
  GetWarrantiesResponseDataDatosPropuestas,
  GetWarrantiesResponseDataDatosPropuestasTipoPropuestaEnum,
  GetWarrantiesResponseDataDatosPropuestasTipoVariableEnum,
  GetWarrantiesResponseDataElementosGarantias,
} from '@/services/V1/quoteAndBuy/getWarrantiesOperation/post/api';
import {
  FIELD_TYPES
} from '@/utils/fields/fields.types';
import Collapse from '@/presentational-components/collapse/collapse.vue';

import {
  CheckData
} from '@/presentational-components/collapse/collapse.type';

import {
  getActionsByWarrantyChange
} from './warranty-actions';
import {
  ElForm
} from 'element-ui/types/form';
import ProductBase from '@/utils/quote-buy-product-factory/products/product-base';

@Component({
  name: 'warranty',
  components: {
    'zz-collapse': Collapse,
  },
})

/**
 * Business Component warranty
 */
export default class WarrantyBusiness extends mixins<EABusinessComponent<WarrantyModel>>(EABusinessComponent) {

  /**
   * Used for render the warranty field components based on their definition
   */
  public ruleForm: Record<string, string | number | boolean> = {};

  public notRequiredExceptionList: string[] = [];

  /**
   * Used for storing which proposals have been checked
   */
  public checksCurrentStatusRecord: Record<string, boolean> = {};

  @Prop()
    columnsSpan!: number;

  @Prop()
    warrantiesData!: GetWarrantiesResponseDataDatosGarantias[];

  @Prop()
    existBeforeVersion?: boolean;

  /*
   * Reset event will be disabled until user starts to make changes
   * It prevents prices to be resetted when creating form for first time
   * or when requote is triggered
   */
  @Prop()
    eventsEnabled!: boolean;

  @Prop({
    required: true
  })
    allProposalCodes!: string[];

  @Prop({
    required: true,
  })
    productFactory!: ProductBase;

  /**
   * Created hook
   */
  created() {
    this.initializeChecksCurrentStatusRecord();
  }

  /**
   * Initializes object with check status for each warranty proposal
   */
  initializeChecksCurrentStatusRecord() {
    const columnsCheckedList = this.getColumnsCheckedList();

    if (this.model.elementosGarantias[0].datosPropuestas) {
      // Mock data in case of exist beforeVersion for the second column
      if (this.model.elementosGarantias[0].datosPropuestas.length === 1 && this.existBeforeVersion) {
        this.model.elementosGarantias.map(item => item.datosPropuestas?.push({}));
      }
      for (let idx = 0; idx < this.model.elementosGarantias[0].datosPropuestas.length; idx++) {
        const codigoPropuesta = this.model.elementosGarantias[0].datosPropuestas[idx].codigoPropuesta || '';
        Vue.set(this.checksCurrentStatusRecord, codigoPropuesta, columnsCheckedList[idx]);
      }
    }
  }

  /**
   * Returns an object which contains dynamic component properties.
   *
   * @param { GetWarrantiesResponseDataDatosPropuestas } datoPropuesta
   * @param { GetWarrantiesResponseDataElementosGarantias } elementoGarantia
   * @returns { WarrantyProperties } Component properties such as `readonly`, `placeholder`, etc.
   */
  getComponentProps(
    datoPropuesta: GetWarrantiesResponseDataDatosPropuestas,
    elementoGarantia: GetWarrantiesResponseDataElementosGarantias
  ): WarrantyProperties {
    // Set common props
    const props: WarrantyProperties = {
      readonly: this.isReadOnly(datoPropuesta),
      placeholder: this.getPlaceholder(datoPropuesta),
    };

    // Add specific props based on its type
    const componentType = this.getComponentType(datoPropuesta);
    if (componentType === 'ea-checkbox') {
      props.label = '';
      props.checked = this.ruleForm[`${elementoGarantia.codigoElemento}_${datoPropuesta.codigoPropuesta}`] as boolean;
      props.class = 'checkbox-item-field';
    } else if (componentType === 'ea-input-currency' || componentType === 'ea-input-number') {
      props.precision = this.getPrecision(datoPropuesta);
    }

    return props;
  }

  /**
   * Get componentType
   *
   * @param {GetWarrantiesResponseDataDatosPropuestas} field
   * @return {string}
   */
  getComponentType(field: GetWarrantiesResponseDataDatosPropuestas): WarrantyElementComponentType {
    const fieldStructure = WarrantyFieldUtils.convertToFieldStructure(field);
    return WarrantyFieldUtils.getComponentType(fieldStructure);
  }

  /**
   * Is read only
   *
   * @param {GetWarrantiesResponseDataDatosPropuestas} field
   * @return {boolean}
   */
  isReadOnly(field: GetWarrantiesResponseDataDatosPropuestas): boolean {
    const fieldStructure = WarrantyFieldUtils.convertToFieldStructure(field);
    const isFieldReadOnly = WarrantyFieldUtils.isFieldReadOnly(fieldStructure);
    const isDropdownOneOption = this.getComponentType(field) === 'ea-select' && field.tablaRestricciones?.length === 1;

    /**
     * Component must be readonly if:
     *   elementoModificable === false OR
     *   It's a dropdown with only 1 possible option
     */
    return isFieldReadOnly || isDropdownOneOption;
  }

  /**
   * Get Precision
   *
   * @param {GetWarrantiesResponseDataDatosPropuestas} field
   * @return {number}
   */
  getPrecision(field: GetWarrantiesResponseDataDatosPropuestas): number {
    const fieldStructure = WarrantyFieldUtils.convertToFieldStructure(field);
    return WarrantyFieldUtils.getDecimalPrecision(fieldStructure);
  }

  /**
   * Get Placeholder
   *
   * @param {GetWarrantiesResponseDataDatosPropuestas} field
   * @return {number}
   */
  getPlaceholder(field: GetWarrantiesResponseDataDatosPropuestas): string {
    const fieldStructure = WarrantyFieldUtils.convertToFieldStructure(field);
    const readOnly = WarrantyFieldUtils.isFieldReadOnly(fieldStructure);
    const type = WarrantyFieldUtils.getFieldType(fieldStructure);
    if (!field.valorElemento && type === FIELD_TYPES.NUMBER) {
      if (readOnly) {
        return '0 €';
      }
      return '0';
    }
    return '';
  }

  /**
   * Returns validation object
   * @returns {EAValidation}
   */
  public validation(): EAValidation {
    const form = this.$refs.form as Form;
    return form?.validation();
  }

  /**
   * Watcher for elementosGarantias
   * Launches events for all elements that have been modified
   * Rebuilds ruleForm with updated values
   */
  @Watch('model.elementosGarantias', {
    deep: true,
    immediate: true
  })
  elementoGarantiasWatcher(): void {
    let modelForm = {};
    this.model.elementosGarantias.forEach(elementoGarantia => {
      const codigoElemento = elementoGarantia.codigoElemento;
      elementoGarantia.datosPropuestas?.forEach(datoPropuesta => {
        const updatedField = this.getUpdatedField(datoPropuesta, codigoElemento);

        modelForm = {
          ...modelForm,
          ...updatedField,
        };

        this.launchElementEvents(
          updatedField[`${codigoElemento}_${datoPropuesta.codigoPropuesta}`], datoPropuesta, codigoElemento
        );
      });
    });
    // Mock data in case of exist beforeVersion for the second column
    if (this.model.elementosGarantias?.[0].datosPropuestas?.length === 1 && this.existBeforeVersion) {
      this.model.elementosGarantias.map(item => item.datosPropuestas?.push({}));
    }

    this.ruleForm = modelForm;
  }

  /**
   * Gets element value for given proposal
   * @param {GetWarrantiesResponseDataDatosPropuestas} datoPropuesta 
   * @param {string | undefined} codigoElemento
   * @returns {Record <string, string | number | null>}
   */
  getUpdatedField(
    datoPropuesta: GetWarrantiesResponseDataDatosPropuestas, codigoElemento?: string
  ): Record <string, string | number | boolean | null> {
    if (datoPropuesta.valorElemento) {

      const isCheckbox = this.getComponentType(datoPropuesta) === 'ea-checkbox';
      if (isCheckbox) {
        return {
          [`${codigoElemento}_${datoPropuesta.codigoPropuesta}`]: datoPropuesta.valorElemento === 'S'
        };
      }
      return {
        [`${codigoElemento}_${datoPropuesta.codigoPropuesta}`]:
        datoPropuesta.tipoVariable === GetWarrantiesResponseDataDatosPropuestasTipoVariableEnum.N
          ? +datoPropuesta.valorElemento
          : datoPropuesta.valorElemento,
      };
    } else {
      return {
        [`${codigoElemento}_${datoPropuesta.codigoPropuesta}`]: null,
      };
    }
  }

  /**
   * Launches element's events if value change is detected
   * Compares received value with old value in ruleForm property
   * @param {string | number | null} updatedValue 
   * @param {GetWarrantiesResponseDataDatosPropuestas} datoPropuesta 
   * @param {string} codigoElemento 
   */
  launchElementEvents(
    updatedValue: string | number | boolean | null,
    datoPropuesta: GetWarrantiesResponseDataDatosPropuestas,
    codigoElemento?: string
  ): void {
    if (
      updatedValue !== null && updatedValue !==
        this.ruleForm[`${codigoElemento}_${datoPropuesta.codigoPropuesta}`]
    ) {
      // Element value has changed => Launch element events
      this.onValueChange(
        updatedValue.toString(), codigoElemento, datoPropuesta, true
      );
    }
  }

  /**
   * Warranty Form Validations
   *
   */
  get warrantyFormValidations(): { rules: Record<string, EAIValidationRule[]> } {
    let formValidations = {};
    this.model.elementosGarantias.forEach(elementoGarantia => {
      const codigoElemento = elementoGarantia.codigoElemento;
      elementoGarantia.datosPropuestas?.forEach(datoPropuesta => {
        if (
          codigoElemento && this.isProposalSelected(datoPropuesta.codigoPropuesta) &&
          datoPropuesta.elementoVisible && datoPropuesta.elementoModificable
        ) {
          const fieldStructure = WarrantyFieldUtils.convertToFieldStructure(datoPropuesta, codigoElemento);
          const rulesForField = {
            // eslint-disable-next-line max-len
            [`${codigoElemento}_${datoPropuesta.codigoPropuesta}`]: WarrantyFieldUtils.getFieldRules(
              fieldStructure, [], this.notRequiredExceptionList
            ),
          };
          formValidations = {
            ...formValidations,
            ...rulesForField,
          };
        }
      });
    });
    return {
      rules: formValidations,
    };
  }

  /**
   * Checks if given proposal is selected
   * @param {string | undefined} codigoPropuesta
   * @returns {boolean}
   */
  isProposalSelected(codigoPropuesta?: string): boolean {
    if (!codigoPropuesta || !this.model.garantiaSeleccionada?.length) {
      return false;
    }

    return this.model.garantiaSeleccionada.find(
      garantiaSeleccionadaElem => garantiaSeleccionadaElem.codigoPropuesta === codigoPropuesta
    )?.garantiaSeleccionada ?? false;
  }

  /**
   * Reads every column data and returns a list that defines checked columns by default.
   *
   * @returns {boolean[]} List of checked columns (example: `[true, false]` means 1st is checked, 2nd is not checked)
   */
  getColumnsCheckedList(): boolean[] {
    return this.model.garantiaSeleccionada?.map(
      garantiaSeleccionadaEl => !!garantiaSeleccionadaEl.garantiaSeleccionada
    ) || [];
  }

  /**
   * Reads every column data and returns a list that defines disabled columns by default.
   *
   * @param {GetWarrantiesResponseDataElementosGarantias[] | undefined} warrantyElements
   * @returns {boolean[]} List of checked columns (example: `[true, false]` means 1st is disabled, 2nd is enabled)
   */
  getColumnsDisabledList(warrantyElements: GetWarrantiesResponseDataElementosGarantias[] | undefined): boolean[] {
    const values: boolean[] = [];

    // Read first warranty element (all elements have the same proposals, we don't need to read others)
    const [firstWarrantyElement] = warrantyElements || [];

    // Read its proposal types and get the corresponding values
    firstWarrantyElement?.datosPropuestas?.forEach(datoPropuesta => {
      const isProposalDisabled = this.isProposalDisabled(datoPropuesta);
      values.push(isProposalDisabled);
    });

    return values;
  }

  /**
   * Returns a boolean which indicates if a proposal must be checked or not.
   *
   * Permutations based on `tipoPropuesta`:
   * - `O` returns `false`
   * - `S` returns `true`
   * - `M` returns `true`
   *
   * @param {GetWarrantiesResponseDataDatosPropuestas} datoPropuesta
   * @returns {boolean} `true` if proposal in that warranty must be checked; `false` otherwise
   */
  isProposalChecked(datoPropuesta: GetWarrantiesResponseDataDatosPropuestas): boolean {
    return datoPropuesta.tipoPropuesta !== GetWarrantiesResponseDataDatosPropuestasTipoPropuestaEnum.O;
  }

  /**
   * Returns a boolean which indicates if a proposal must be disabled or enabled.
   *
   * Permutations based on `tipoPropuesta`:
   * - `O` opcional returns `false`
   * - `S` sugerido returns `false`
   * - `N` no contratable returns `false`
   * - `M` mandatoy returns `true`
   *
   * @param {GetWarrantiesResponseDataDatosPropuestas} datoPropuesta
   * @returns {boolean} `true` if proposal in that warranty must be disabled; `false` otherwise
   */
  isProposalDisabled(datoPropuesta: GetWarrantiesResponseDataDatosPropuestas): boolean {
    return datoPropuesta.tipoPropuesta === GetWarrantiesResponseDataDatosPropuestasTipoPropuestaEnum.M
                || datoPropuesta.tipoPropuesta === GetWarrantiesResponseDataDatosPropuestasTipoPropuestaEnum.N;
  }

  /**
   * Reads the column data and returns a list with the column code.
   *
   * @param {GetWarrantiesResponseDataElementosGarantias[] | undefined} warrantyElements
   * @returns {string[]} List of column codes (example: `['0000000235', '0000000236']`)
   */
  getColumnsCodeList(warrantyElements: GetWarrantiesResponseDataElementosGarantias[] | undefined): string[] {
    const values: string[] = [];

    // Read first warranty element (all elements have the same proposals, we don't need to read others)
    const [firstWarrantyElement] = warrantyElements || [];

    // Read its proposal types and get the corresponding values
    firstWarrantyElement?.datosPropuestas?.forEach(datoPropuesta => {
      datoPropuesta.codigoPropuesta && values.push(datoPropuesta.codigoPropuesta);
    });

    return values;
  }

  /**
   * Check if the introduced value in form item is valid.
   * @param {string} elementCode
   * @param {string} proposalCode
   *
   * @return{boolean}
   *
   */
  async isFormValueValid(elementCode?: string, proposalCode?: string): Promise<boolean> {
    if (!elementCode || !proposalCode || !this.$refs) {
      return Promise.resolve(false);
    }

    const warrantyForm = this.$refs[`form-${this.model.codigoGarantia}`] as Form;
    const elWarrantyForm = warrantyForm?.$refs.form as ElForm;

    if (!elWarrantyForm) {
      return Promise.resolve(false);
    }

    return new Promise(resolve => {
      elWarrantyForm.validateField(`${elementCode}_${proposalCode}`, (errorMessage: string) => {
        if (errorMessage) {
          resolve(false);
        } else {
          resolve(true);
        }
      });
    });
  }

  /**
   * On check change method
   *
   * @param {CheckData} checkData
   */
  onCheckChange(checkData: CheckData): void {
    this.$emit('qbWarrantyReset', checkData.columnCode);

    this.updateChecksCurrentStatusRecord(checkData);

    if (!this.model.lanzadorPorCheck) {
      return;
    }

    const warrantyCode = this.model.codigoGarantia;
    const proposalCode = checkData.columnCode;
    const triggerByCheck = this.model.lanzadorPorCheck;

    getActionsByWarrantyChange(
      {
        warrantyCode,
        proposalCode,
      },
      checkData.value,
      {
        triggerByCheck,
      },
      this.warrantiesData
    ).forEach((eventAction: EventChange) => {
      this.$emit('qbWarrantyChange', eventAction);
    });
  }

  /**
   * On Value Change method. Triggers only for user made changes
   *
   * @param {string | boolean} value
   * @param {string} elementCode
   * @param {GetWarrantiesResponseDataDatosPropuestas} datoPropuesta
   * @param {boolean} skipValidation
   */
  async onValueChange(
    value: string | boolean,
    elementCode: string | undefined,
    datoPropuesta: GetWarrantiesResponseDataDatosPropuestas,
    skipValidation = false
  ): Promise<void> {
    EASessionManager.getInstance().touchAndSendTabMessage();
    if (!this.eventsEnabled) {
      return;
    }

    this.$emit('qbWarrantyReset', datoPropuesta.codigoPropuesta);

    const emitterElement: WarrantyElement = {
      warrantyCode: this.model.codigoGarantia,
      proposalCode: datoPropuesta.codigoPropuesta || '',
      elementCode
    };
    const previousValue = datoPropuesta.valorElemento;

    // Always emit CHANGE_VALUE event in order to have view model updated
    this.$emit('qbWarrantyChange', {
      emitter: emitterElement,
      receiver: emitterElement,
      action: {
        type: ActionType.CHANGE_VALUE,
        value: value
      }
    });

    let shouldEmitActions = true;

    if (!skipValidation) {
      shouldEmitActions = await this.isFormValueValid(elementCode, datoPropuesta.codigoPropuesta);
    }
    
    if (shouldEmitActions) {
      if (datoPropuesta.codigoPropuesta && datoPropuesta.lanzadorPorValor) {
        getActionsByWarrantyChange(
          emitterElement,
          value,
          {
            triggerByValue: datoPropuesta.lanzadorPorValor
          },
          this.warrantiesData,
          previousValue
        ).forEach((eventAction: EventChange) => {
          this.$emit('qbWarrantyChange', eventAction);
        });
      }
    }
  }


  /**
   * Check if should copy value to rest of proposals
   *  (Horizontal copy propagation)
   *
   * @param {EventChange} eventAction
   * @return {Boolean}
   */
  shouldChangeRestOfProposals(eventAction: EventChange): boolean {
    return eventAction.receiver.proposalCode === 'All';
  }

  /**
   * Get all proposal codes different than emitter proposal Code
   *
   * @param {EventChange} eventAction
   * @return {string[]}
   */
  getRestOfProposalCodes(eventAction: EventChange): string[] {
    const elementWarranty = this.model.elementosGarantias
      .find(elementoGarantia => elementoGarantia.codigoElemento === eventAction.receiver.elementCode);
    
    if (elementWarranty && elementWarranty.datosPropuestas) {
      return elementWarranty.datosPropuestas
        .filter(datoPropuesta => datoPropuesta.codigoPropuesta !== eventAction.emitter.proposalCode)
        .map(datoPropuesta => datoPropuesta.codigoPropuesta)
        .filter(datoPropuesta => datoPropuesta) as string[]; //Remove undefined items if exists

    } else {
      return [];
    }
  }

  /**
   * Updates checks current status record with provided change
   * @param {CheckData} checkData - Last checkbox change
   */
  updateChecksCurrentStatusRecord(checkData: CheckData) {
    Vue.set(this.checksCurrentStatusRecord, checkData.columnCode, checkData.value);
    
    const garantiaSeleccionadaSavedRecord = this.model.garantiaSeleccionada?.find(
      garantiaSeleccionadaEl => garantiaSeleccionadaEl.codigoPropuesta === checkData.columnCode
    );

    if (garantiaSeleccionadaSavedRecord) {
      garantiaSeleccionadaSavedRecord.garantiaSeleccionada = checkData.value;
    }

    this.update();
  }

  
  /**
   * Gets filtered elementosGarantias filtering those elements that are invisible for all proposals 
   * 
   * @param {GetWarrantiesResponseDataElementosGarantias} elementoGarantias - Last checkbox change
   * @returns {boolean}
   */
  filterVisibleElements(
    elementoGarantias: GetWarrantiesResponseDataElementosGarantias
  ): boolean {

    const findElement = this.productFactory.alwaysVisibleElementsWarranties.find(
      elem => elem.codigoElemento === elementoGarantias.codigoElemento
    );

    const elementMustBeVisible = elementoGarantias.datosPropuestas?.some(
      datoPropuestas => datoPropuestas.valorElemento !== '0' && findElement
    );

    if (elementoGarantias.datosPropuestas &&
        elementoGarantias.datosPropuestas.some(datoPropuestas => datoPropuestas.elementoVisible) &&
       !findElement
    ) {
      return true;
    }

    if (elementMustBeVisible) {
      return true;
    }

    return false;
  }

  /**
   * Gets filtered elementosGarantias filtering those elements that are invisible for all proposals 
   */
  get visibleElementosGarantias() {
    return this.model.elementosGarantias.filter(
      elementoGarantias => this.filterVisibleElements(elementoGarantias)
    );
  }

  /**
   * Gets if warranty has any element
   */
  get warrantyHasVisibleElements() {
    return this.model.elementosGarantias.some(
      elemen => elemen.datosPropuestas?.some(
        prop => prop.elementoVisible
      )
    );
  }
}
</script>

<style scoped lang="scss">
/*TODO: LO LIMPARA ALGUN INTEGRANTE DEL EQUIPO 1 */
$border-color: #eee;
$font-size: 14px;
$font-weight: 400;
$color: #131313;
$letter-spacing: 0.5px;
$placeholder-color: #606266;

.ea-row.warranty__element-row::v-deep {
  &:not(&:last-child) {
    border-bottom: 1px solid $border-color;
    font-size: $font-size;
    font-weight: $font-weight;
    color: $color;
    letter-spacing: $letter-spacing;
  }

  .el-row {
    // Align titles and inputs vertically
    display: flex;
    flex-direction: row;
    align-items: center;

    .warranty {
      &__title {
        font-size: $font-size;
        font-weight: $font-weight;
        color: $color;
        letter-spacing: $letter-spacing;
      }

      &__elements {
        // Extend until empty space is filled to horizontally align option titles and inputs
        display: flex;
        flex: 1;

        > .el-col {
          flex: 1;
        }

        .el-form-item {
          margin: 0;

          .input--readonly input {
            // Fix for placeholder font color when an input is readonly (same as disabled)
            color: #606266;
            -webkit-text-fill-color: #606266;

            &::placeholder {
              color: #606266;
              -webkit-text-fill-color: #606266;
            }
          }

          .el-form-item__content {
            display: flex;
            margin: auto;
            max-width: 250px;
            .warranty__package-field {
              width: 100%;
              margin: 0;
            }
            .ea-form-item__label {
              // "Remove" unused/empty labels (they take some height and misalign inputs)
              display: none;
            }

            & > label {
              // Checkboxes input fix
              display: none;

              &.checkbox-item-field {
                // Labels are checkboxes and their height should be adjusted to that checkbox
                display: inherit;
                height: auto;
              }
            }
          }
        }
      }

      &__package-field {
        width: 50%;
      }
    }
  }
}
</style>
