<template>
  <div extraClass="m-t-64 d-display-flex">
    <ea-row extraClass="d-display-flex" v-if="showClientData">
      <ea-col>
        <zz-client-basic-info
          id="showClientData"
          :documentationClientData="documentationClientData"
        ></zz-client-basic-info>
      </ea-col>
    </ea-row>
    
    <ea-row extraClass="m-b-32" v-if="showNationalitySelector && shouldShowNationalitySelector">
      <ea-col :span="6">
        <select-nationality
          id="selectNationality"
          ref="selectNationalityComp"
          v-model="model.selectNationality"
          :consultaOperation="consultaOperation"
          @updateViewModel="updateViewModel"
        ></select-nationality>
      </ea-col>
    </ea-row>

    <ea-row>
      <ea-col :span="6">
        <add-edit-email
          id="addEditEmail"
          ref="addEditEmail"
          v-model="model.addEditEmail"
          :documentationClientData="documentationClientData"
          :consultaOperation="consultaOperation"
          :isEmailRequired="isEmailRequired"
          @saveEmailAddress="saveAddress"
          @updateViewModel="updateViewModel"
        ></add-edit-email>
      </ea-col>
      <ea-col :span="6">
        <add-edit-phone
          id="addEditPhone"
          ref="addEditPhone"
          v-model="model.addEditPhone"
          :documentationClientData="documentationClientData"
          :consultaOperation="consultaOperation"
          :isPhoneRequired="isPhoneRequired"
          @savePhoneAddress="saveAddress"
          @updateViewModel="updateViewModel"
        ></add-edit-phone>
      </ea-col>
      <ea-col :span="12">
        <add-edit-address
          id="addEditAddress"
          ref="addEditAddress"
          v-model="model.addEditAddress"
          :documentationClientData="documentationClientData"
          :consultaOperation="consultaOperation"
          @savePostalAddress="saveAddress"
          @updateViewModel="updateViewModel"
        ></add-edit-address>
      </ea-col>
    </ea-row>
  </div>
</template>

<script lang="ts">
import {
  EABusinessComponent, EAMethod
} from '@zurich-es-npm/ea-front-web-core';
import Component, {
  mixins
} from 'vue-class-component';
// eslint-disable-next-line max-len
import {
  EASetPersonAddressesApi,
  SetPersonAddressesRequestDomicilios,
} from '@/services/V1/persons/setPersonAddressesOperation/post';
import {
  Prop, Watch
} from 'vue-property-decorator';
import AddAddressTomadorModel from './modals/add-address-tomador/add-address-tomador-model';
import AddEmailTomadorModel from './modals/add-email-tomador/add-email-tomador-model';
import QbAddressesPersonModel from './qb-addresses-person-model';
import AddEditAddressBusiness from './selectables/add-edit-address/add-edit-address-business.vue';
import AddEditEmailBusiness from './selectables/add-edit-email/add-edit-email-business.vue';
import AddEditPhoneBusiness from './selectables/add-edit-phone/add-edit-phone-business.vue';
import SelectNationalityBusiness from './selectables/select-nationality/select-nationality-business.vue';
import {
  GetPersonsResponseListaPersonasTipoDocumentoEnum as TipoDocumentoEnum
} from '@/services/V1/quoteAndBuy/getPersonsOperation/post';
import {
  EAValidation
} from '@zurich-es-npm/ea-front-web-ui';
import {
  GenericErrorData
} from '../qb-generic-error/qb-generic-error-business.vue';
import ZzClientBasicInfo from '@/presentational-components/clientBasicInfo/clientBasicInfo.vue';
import {
  DocumentationClientData
} from '@/presentational-components/clientBasicInfo/clientBasicInfo.type';

@Component({
  name: 'qb-addresses-person',
  components: {
    AddEditPhone: AddEditPhoneBusiness,
    AddEditEmail: AddEditEmailBusiness,
    AddEditAddress: AddEditAddressBusiness,
    SelectNationality: SelectNationalityBusiness,
    'zz-client-basic-info': ZzClientBasicInfo
  },
})

/**
 * Business Component post-code-location-input
 */
export default class QbAddressesPersonBusiness extends mixins<EABusinessComponent<QbAddressesPersonModel>>(
  EABusinessComponent
) {
  @Prop({
    required: false,
    'default': () => false,
  })
    showClientData?: boolean;

  @Prop({
    required: false,
    'default': () => false,
  })
    showNationalitySelector?: boolean;

  @Prop()
    consultaOperation?: boolean;

  @Prop()
    documentationClientData!: DocumentationClientData;

  @Prop()
    isEmailRequired?: boolean;

  @Prop()
    isPhoneRequired?: boolean;

  /**
   * Saves user inputted addresses
   * @param {SetPersonAddressesRequestDomicilios} address
   * @param {string} role
   */
  @EAMethod({
    loading: true,
  })
  async saveAddress(address: SetPersonAddressesRequestDomicilios): Promise<void> {
    try {
      this.clearFlowErrors();
      const api = new EASetPersonAddressesApi();
      const output = await api.setPersonAddressesOperation({
        setPersonAddressesRequest: {
          domicilios: [address],
        },
      });

      if (output) {
        if (output.errors?.length) {
          const genericErrorData: GenericErrorData = {
            title: 'Error',
            errors: output.errors,
          };
          this.$emit('showError', genericErrorData);
        } else {
          this.closeAllModals();
          this.$emit('refreshData', address);
        }
      }
    } catch (error) {
      this.$emit('handleGenericError', {
        error,
        errorCode: 'ZON00072',
      });
    }
  }

  /**
   * Closes all edition modals
   */
  closeAllModals() {
    const mailEditionComp = this.$refs.addEditEmail as AddEditEmailBusiness;
    if (mailEditionComp) {
      mailEditionComp.closeEditionModal();
    }

    const phoneEditionComp = this.$refs.addEditPhone as AddEditPhoneBusiness;
    if (phoneEditionComp) {
      phoneEditionComp.closeEditionModal();
    }

    const postalEditionComp = this.$refs.addEditAddress as AddEditAddressBusiness;
    if (postalEditionComp) {
      postalEditionComp.closeEditionModal();
    }
  }

  /**
   * Updates model
   */
  @Watch('model.addEditEmail.emailList', {
    deep: true,
    immediate: true,
  })
  onEmailListChange() {
    if (!this.model.addEditEmail.selectedEmailCode || this.model.addEditEmail.selectedEmailCode === '') {
      this.model.addEditEmail.selectedEmail = this.model.addEditEmail.emailList.find(
        emailAddress => emailAddress.indicadorDomicilioPrincipal === 'S'
      );
      if (!this.model.addEditEmail.selectedEmail) {
        this.model.addEditEmail.selectedEmail = this.model.addEditEmail.emailList[
          this.model.addEditEmail.emailList.length - 1
        ];
      }
      this.model.addEditEmail.selectedEmailCode = this.model.addEditEmail.selectedEmail?.codigoSecuencialDomicilio;
      this.model.addEditEmail.addEmailTomadorModel = new AddEmailTomadorModel();
    }
  }

  /**
   * Watcher for addressList prop
   * Updates selected address
   */
  @Watch('model.addEditAddress.addressList', {
    deep: true,
    immediate: true,
  })
  onPostalAddressListChange() {
    if (!this.model.addEditAddress.selectedAddressCode || this.model.addEditAddress.selectedAddressCode === '') {
      this.model.addEditAddress.selectedAddress = this.model.addEditAddress.addressList.find(
        postalAddress => postalAddress.indicadorDomicilioPrincipal === 'S'
      );

      if (!this.model.addEditAddress.selectedAddress) {
        this.model.addEditAddress.selectedAddress = this.model.addEditAddress.addressList[
          this.model.addEditAddress.addressList.length - 1
        ];
      }

      // eslint-disable-next-line max-len
      this.model.addEditAddress.selectedAddressCode = this.model.addEditAddress.selectedAddress?.codigoSecuencialDomicilio;
      this.model.addEditAddress.addAddressTomadorModel = new AddAddressTomadorModel();
    }
  }

  /**
   * Updates model
   */
  @EAMethod()
  public async updateViewModel() {
    this.update();
    await this.$nextTick(() => {
      this.$emit('updateViewModel');
    });
  }

  /**
   * Nationality validation method
   * @returns {EAValidation}
   */
  public nationalityValidation(): EAValidation {
    this.clearFlowErrors();
    
    const selectNationalityComp: SelectNationalityBusiness = this.$refs
      .selectNationalityComp as SelectNationalityBusiness;
    if (selectNationalityComp) {
      selectNationalityComp.update();
    }

    return selectNationalityComp?.validation();
  }
  

  /**
   * Returns flag for nationality selector visibility
   * True if person's document type is Permiso Residencia or Pasaporte
   */
  get shouldShowNationalitySelector() {
    const documentType = this.documentationClientData.clientData?.datosBasicosPersona?.tipoDocumento;
    return documentType === TipoDocumentoEnum.R || documentType === TipoDocumentoEnum.P;
  }
}
</script>
