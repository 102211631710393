import {
  ParsedTableData
} from '@/utils/corporate-tables';
import PostCodeLocationInputModel from '../post-code-location-input/post-code-location-input-model';

export interface FormValues {
  country?: string;
  roadType?: string;
  roadName?: string;
  roadNumber?: number;
  extraInfo?: string;
  blockStair?: string;
  floor?: string;
  stairwell?: string;
  doorNumber?: string;
  latitude?: string;
  longitude?: string;
}

/**
 * Model qb-risk-situation
 */
class QbRiskSituationModel {
  public FIELD_TYPES = {
    NUMBER: 'number',
    STRING: 'string',
    DEFAULT: 'default',
  };

  public formValues: FormValues = {
    country: '',
    roadType: '',
    roadName: '',
    roadNumber: undefined,
    extraInfo: '',
    blockStair: '',
    floor: '',
    stairwell: '',
    doorNumber: '',
    latitude: '',
    longitude: ''
  };

  public countryList: ParsedTableData[] = [];

  public postCodeLocationInputModel: PostCodeLocationInputModel = new PostCodeLocationInputModel();
}

export default QbRiskSituationModel;
