import {
  VehicleFormModel
} from '@/business-components/fleet-manual-upload-vehicle/fleet-manual-upload-vehicle-model';

/**
 * 
 */
export class FleetInputVehiclesDataUtils {

  
  /**
   * Sorts vehicle list with invalid vehicles first.
   * @param {VehicleFormModel[]} vehicleList 
   * @returns {VehicleFormModel[]}
   */
  public static sortVehicleList(vehicleList: VehicleFormModel[]): VehicleFormModel[] {
    // eslint-disable-next-line max-len
    return vehicleList.sort((vehicleA, vehicleB) => {
      const vehicleValueA = Number(vehicleA.invalid || false) ||
        Number(vehicleA.invalidDate || false) || Number(vehicleA.invalidValues || false);
      const vehicleValueB = Number(vehicleB.invalid || false) ||
        Number(vehicleB.invalidDate || false) || Number(vehicleB.invalidValues || false);
      return vehicleValueB - vehicleValueA;
    });
  }

}
