import {
  MassiveSearchRequestTipoBusquedaMasivaEnum as TipoBusquedaMasivaEnum
} from '@/services/V1/home/massiveSearchOperation/post/api';

export interface MassiveSearchSelectPolicy {
  name: TipoBusquedaMasivaNameEnum.Policy;
  value: TipoBusquedaMasivaEnum.Poliza;
}

export interface MassiveSearchSelectOffer {
  name: TipoBusquedaMasivaNameEnum.Offer;
  value: TipoBusquedaMasivaEnum.Oferta;
}

export interface MassiveSearchSelectReceipt {
  name: TipoBusquedaMasivaNameEnum.Receipt;
  value: TipoBusquedaMasivaEnum.Recibo;
}

export interface MassiveSearchSelectSinister {
  name: TipoBusquedaMasivaNameEnum.Sinister;
  value: TipoBusquedaMasivaEnum.Siniestro;
}

export interface MassiveSearchSelectClient {
  name: TipoBusquedaMasivaNameEnum.Client;
  value: TipoBusquedaMasivaEnum.Cliente;
}

export enum TipoBusquedaMasivaNameEnum {
  Policy = 'policy',
  Offer = 'offer',
  Receipt = 'receipt',
  Sinister = 'sinister',
  Client = 'client'
}

export type MassiveSearchSelect =
  MassiveSearchSelectPolicy |
  MassiveSearchSelectOffer |
  MassiveSearchSelectReceipt |
  MassiveSearchSelectSinister |
  MassiveSearchSelectClient;


export const NO_RESULT_FOUND_CODES = ['1069', '8201', '2071', '8005'];
