<template>
  <div>
    
    <ea-card
      shadow="hidden"
      class="m-t-16">
      <div slot="cardbody" class="policyholder-card p-a-16">
        <h2 class="m-b-16">{{$t('fleets.fleetsFlow.fleet-risk-management.title')}}</h2>
        <ea-paragraph
          size="medium"
          class="m-b-20">
          {{$t('fleets.fleetsFlow.fleet-risk-management.subTitle')}}
        </ea-paragraph>

        <ea-form
          ref="riskManagementForm"
          :model="model.formValues"
          :validationOptions="formValidations"
          :validateOnRuleChange="false">
          <ea-row>
            <ea-col :span="24" >
              <ea-form-item
                :label="$t('fleets.fleetsFlow.fleet-risk-management.majorVehiclesHasAdvancedDriverTechnology')"
                prop="majorVehiclesHasAdvancedDriverTechnology"
                :required="!readOnlyOperation">
                <ea-radio-group
                  v-model="model.formValues.majorVehiclesHasAdvancedDriverTechnology"
                  direction="horizontal">
                  <ea-radio
                    :readonly="readOnlyOperation"
                    :label="true"
                    class="m-l-0">
                    {{ $t('common.label.yes') }}
                  </ea-radio>
                  <ea-radio
                    :readonly="readOnlyOperation"
                    :label="false"
                    class="m-8">
                    {{ $t('common.label.no') }}
                  </ea-radio>
                </ea-radio-group>
                <div slot="tooltipIconInfo">
                  <div slot="content">
                    <span> {{$t('fleets.fleetsFlow.fleet-risk-management.vehiclesAdvancesSystemDescription')}}</span>
                  </div>
                </div>
              </ea-form-item>
            </ea-col>
          </ea-row>
          <ea-row>
            <ea-col :span="24" >
              <ea-form-item
                :label="$t('fleets.fleetsFlow.fleet-risk-management.anyDriversCurrentlyDoingEfficiencyCourses')"
                prop="anyDriversCurrentlyDoingEfficiencyCourses"
                :required="!readOnlyOperation">
                <ea-radio-group
                  v-model="model.formValues.anyDriversCurrentlyDoingEfficiencyCourses"
                  direction="horizontal">
                  <ea-radio
                    :readonly="readOnlyOperation"
                    :label="true"
                    class="m-l-0">
                    {{ $t('common.label.yes') }}
                  </ea-radio>
                  <ea-radio
                    :readonly="readOnlyOperation"
                    :label="false"
                    class="m-8">
                    {{ $t('common.label.no') }}
                  </ea-radio>
                </ea-radio-group>
              </ea-form-item>
            </ea-col>
          </ea-row>
          <ea-row>
            <ea-col :span="24" >
              <ea-form-item
                :label="$t('fleets.fleetsFlow.fleet-risk-management.securityControlsRiskManagement')"
                prop="securityControlsRiskManagement"
                :required="!readOnlyOperation">
                <ea-radio-group
                  v-model="model.formValues.securityControlsRiskManagement"
                  direction="horizontal">
                  <ea-radio
                      :readonly="readOnlyOperation"
                      :label="true"
                      class="m-l-0">
                      {{ $t('common.label.yes') }}
                    </ea-radio>
                    <ea-radio
                      :readonly="readOnlyOperation"
                      :label="false"
                      class="m-8">
                      {{ $t('common.label.no') }}
                    </ea-radio>
                </ea-radio-group>
              </ea-form-item>
            </ea-col>
          </ea-row>
        </ea-form>
      </div>
    </ea-card>
  </div>
</template>

<script lang="ts">
import {
  Component, Prop
} from 'vue-property-decorator';

import {
  mixins
} from 'vue-class-component';

import {
  EABusinessComponent
} from '@zurich-es-npm/ea-front-web-core';

import FleetsRiskManagementModel from './fleets-risk-management-model';
import {
  EAFormValidationOptions,
  eaRequiredValidation,
  EAValidation, EAValidationTriggers, EAValidationTypes, Form
} from '@zurich-es-npm/ea-front-web-ui';

@Component({
  name: 'fleets-risk-management'
})

/**
 * Business Component fleets-risk-management
 */
export default class FleetsRiskManagementBusiness
  extends mixins<EABusinessComponent<FleetsRiskManagementModel>>(EABusinessComponent) {

  @Prop()
    readOnlyOperation?: boolean;

  /**
   * Getter for validation options
   * @returns { EAFormValidationOptions }
   */
  get formValidations(): EAFormValidationOptions {
    return {
      rules: {
        majorVehiclesHasAdvancedDriverTechnology: [
          eaRequiredValidation(
            'common.label.validation.fieldRequired',
            EAValidationTriggers.BLUR,
            EAValidationTypes.BOOLEAN
          )
        ],
        anyDriversCurrentlyDoingEfficiencyCourses: [
          eaRequiredValidation(
            'common.label.validation.fieldRequired',
            EAValidationTriggers.BLUR,
            EAValidationTypes.BOOLEAN
          )
        ],
        securityControlsRiskManagement: [
          eaRequiredValidation(
            'common.label.validation.fieldRequired',
            EAValidationTriggers.BLUR,
            EAValidationTypes.BOOLEAN
          )
        ]
      }
    };
  }

  /**
   * @description: method to validate form in parent component
   * @returns {EAValidation}
   */
  validation(): EAValidation {
    const riskManagementForm: Form = this.$refs.riskManagementForm as Form;
    return riskManagementForm.validation();
  }
}
</script>
