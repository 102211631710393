export interface WorksCenters {
  codigoCentroTrabajo?: string;
  codigoCentroTramitador?: string;
  descripcionCentroTrabajo?: string;
  codigosCentro?: string;
}

/** 
 * Model competences-extra-data-employee
 */
class CompetencesExtraDataEmployeeModel {

  public selectedRol: string = '';

  public codes: string = '';

}

export default CompetencesExtraDataEmployeeModel;
