import {
  DocumentTypeValidation
} from './document-type-validation-model';

/**
 * 
 */
export class FleetSearchPolicyholderRules {
  public documentType: DocumentTypeValidation = new DocumentTypeValidation();
}
