<template>
  <div class="slide d-display-flex d-direction-column d-justify-space-between h-100">
    <div>
      <h2
        v-if="!hideFirstTitle"
        class="t-color-primary m-b-16"
        >
        {{ $t('onboarding.intermediaryCertificates.firstSlide.title1') }}
      </h2>

      <i18n path="onboarding.intermediaryCertificates.firstSlide.descriptionFirst.text" tag="p" class="m-b-32">
        <span class="t-weight-semibold">{{
          $t('onboarding.intermediaryCertificates.firstSlide.descriptionFirst.bold1')
        }}</span>
        <span class="t-weight-semibold">{{
          $t('onboarding.intermediaryCertificates.firstSlide.descriptionFirst.bold2')
        }}</span>
      </i18n>

      <i18n path="onboarding.intermediaryCertificates.firstSlide.descriptionSecond.text" tag="p">
        <span class="t-weight-semibold">{{
          $t('onboarding.intermediaryCertificates.firstSlide.descriptionSecond.bold1')
        }}</span>
        <span class="t-weight-semibold">{{
          $t('onboarding.intermediaryCertificates.firstSlide.descriptionSecond.bold2')
        }}</span>
      </i18n>
    </div>
    
    <div class="d-display-flex d-align-items-center d-justify-center p-a-32 w-100 t-bg-color-gray-20">
      <img
        :src="require(`@/assets/images/onboarding/intermediaryCertificates/onboarding1.png`)"
        :alt="$t('onboarding.intermediaryCertificates.firstSlide.title1').toString()"
      >
    </div>
  </div>
</template>
<script lang="ts">

import Vue from 'vue';
import {
  Component, Prop
} from 'vue-property-decorator';

@Component({
  name: 'intermediary-certificates-onboarding-1'
})

/**
 * Intermediary certificates component
 */
export default class IntermediaryCertificatesOnboarding1Component extends Vue {

  @Prop({
    required: false,
    'default': () => false,
  })
    hideFirstTitle?: boolean;
}
</script>
