import {
  GetIbanResponseDataIbanList
} from '@/services/V1/quoteAndBuy/getIbanOperation/post';
import QbIbanCodeModalModel from '../qb-iban-code-modal/qb-iban-code-modal-model';

/**
 * Model qb-iban-code
 */
class QbIbanCodeModel {
  public selectableIbanList: GetIbanResponseDataIbanList[] = [];

  public qbIbanCodeModalModel: QbIbanCodeModalModel = new QbIbanCodeModalModel();
}

export default QbIbanCodeModel;
