import {
  OnboardingData,
  TOUR_CHECK_SHOWING_FUNCTIONS,
  TOUR_NAMES_ENUM,
  TOUR_PERMISSIONS
} from './onboarding-types';
import {
  PermissionUtils
} from '@/utils/permissions-utils';
import {
  EAContextManager
} from '@zurich-es-npm/ea-front-web-core';

import CardPaymentOnboarding1Component from
  '@/presentational-components/onboardings/card-payment-onboarding/card-payment-onboarding-1.vue';
import CardPaymentOnboarding2Component from
  '@/presentational-components/onboardings/card-payment-onboarding/card-payment-onboarding-2.vue';
import CardPaymentOnboarding3Component from
  '@/presentational-components/onboardings/card-payment-onboarding/card-payment-onboarding-3.vue';
import CardPaymentOnboarding4Component from
  '@/presentational-components/onboardings/card-payment-onboarding/card-payment-onboarding-4.vue';
import CardPaymentOnboarding5Component from
  '@/presentational-components/onboardings/card-payment-onboarding/card-payment-onboarding-5.vue';
import ComunidadesOnboarding1Component from
  '@/presentational-components/onboardings/comunidades-onboarding/comunidades-onboarding-1.vue';
import ComunidadesOnboarding2Component from
  '@/presentational-components/onboardings/comunidades-onboarding/comunidades-onboarding-2.vue';
import FleetsOnboarding1Component from
  '@/presentational-components/onboardings/fleets-onboarding/fleets-onboarding-1.vue';
import IntermediaryCertificatesOnboarding1Component from
  // eslint-disable-next-line max-len
  '@/presentational-components/onboardings/intermediary-certificates-onboarding/intermediary-certificates-onboarding-1.vue';
import PaperlessOnboarding1Component from
  '@/presentational-components/onboardings/paperless-onboarding/paperless-onboarding-1.vue';
import {
  TourUtils
} from '@/business-components/ea-tour/utils/tour-utils';
import CarNbLc1Component from
  '@/presentational-components/onboardings/car-nb-lc-onboarding/car-nb-lc-onboarding-1.vue';


/**
 * Utils object with auxiliar functions
 */
export class OnboardingUtils {

  public static readonly onboardingsConfig: OnboardingData = {
    currentOnboardings: [
      {
        onboardingName: TOUR_NAMES_ENUM.CARD_PAYMENT_TOUR,
        onboardingHiddenSlides: [],
        onboardingModalTitleLabel: 'onboarding.cardPayment.onDemandModalTitle',
        onboardingComponents: [
          CardPaymentOnboarding1Component as unknown as Vue,
          CardPaymentOnboarding2Component as unknown as Vue,
          CardPaymentOnboarding3Component as unknown as Vue,
          CardPaymentOnboarding4Component as unknown as Vue,
          CardPaymentOnboarding5Component as unknown as Vue,
        ]
      },
      {
        onboardingName: TOUR_NAMES_ENUM.COMUNIDADES_TOUR,
        onboardingHiddenSlides: [],
        onboardingComponents: [
          ComunidadesOnboarding1Component as unknown as Vue,
          ComunidadesOnboarding2Component as unknown as Vue
        ]
      },
      {
        onboardingName: TOUR_NAMES_ENUM.FLEETS_TOUR,
        onboardingHiddenSlides: [],
        onboardingComponents: [
          FleetsOnboarding1Component as unknown as Vue
        ]
      },
      {
        onboardingName: TOUR_NAMES_ENUM.HOME_CERT_TOUR,
        onboardingHiddenSlides: [],
        onboardingComponents: [
          IntermediaryCertificatesOnboarding1Component as unknown as Vue
        ]
      },
      {
        onboardingName: TOUR_NAMES_ENUM.HOME_PAPERLESS_TOUR,
        onboardingHiddenSlides: [],
        onboardingComponents: [
          PaperlessOnboarding1Component as unknown as Vue
        ]
      },
      {
        onboardingName: TOUR_NAMES_ENUM.CARD_PAYMENT_TOUR_ONDEMAND,
        onboardingHiddenSlides: [0, 4],
        onboardingModalTitleLabel: 'onboarding.cardPayment.onDemandModalTitle',
        onboardingComponents: [
          CardPaymentOnboarding1Component as unknown as Vue,
          CardPaymentOnboarding2Component as unknown as Vue,
          CardPaymentOnboarding3Component as unknown as Vue,
          CardPaymentOnboarding4Component as unknown as Vue,
          CardPaymentOnboarding5Component as unknown as Vue,
        ]
      },
      {
        onboardingName: TOUR_NAMES_ENUM.HOME_CAR_NB_LC_TOUR,
        onboardingHiddenSlides: [],
        onboardingComponents: [
          CarNbLc1Component as unknown as Vue
        ]
      },
    ]
  };


  /**
   * Returns true if
   *  actual date is between kcitons table dates
   *  onboarding modal has not been shown yet
   *  user meets onboarding permissions
   * @param {string} tourName
   * @returns {Promise<boolean>}
   */
  public static async checkIfShowOnboarding(tourName: string): Promise<boolean> {
    return await this.checkCorporateTableDates(tourName) &&
      this.checkIfAlreadyShown(tourName) &&
      this.checkOnboardingPermissions(tourName) &&
      this.checkExtraCheckingPermissionsFunctions(tourName);
  }

  /**
   * Returns true if
   *  actual date is between kcitons table dates
   *  onboarding modal has not been shown yet
   *  user meets onboarding permissions
   * @returns {Promise<string[]>}
   */
  public static async checkIfShowAnyOnboarding(): Promise<string[]> {
    const toursToShow: string[] = [];
    const tourNames = Object.values(TOUR_NAMES_ENUM);

    for (const tourName of tourNames) {
      if (await this.checkIfShowOnboarding(tourName)) {
        toursToShow.push(tourName);
      }
    }
    return toursToShow;
  }

  /**
   * Checks if onboarding has been already shown
   * @param {string} tourName
   * @returns {Promise<boolean>}
   */
  public static checkIfAlreadyShown(tourName: string): boolean {
    let tourNameSave = tourName;
  
    const contextManager = EAContextManager.getInstance();
    const userName = contextManager.getSecurityContext().userInfo?.userName ?? '';
    tourNameSave = `${tourName}-${userName}`;
    const localStorageItem = localStorage.getItem(`onboarding-${tourNameSave}`);

    // True if item NOT found
    return !localStorageItem;
  }

  /**
   * Updates local storage onboarding item so logged user won't see onboarding anymore
   * @param {string []} tourNames
   */
  public static updateMultipleOnboardingLocalItem(tourNames: string[]): void {

    tourNames.forEach(tourName => {
      OnboardingUtils.updateOnboardingLocalItem(tourName);
    });
  
  }

  /**
   * Updates local storage onboarding item so logged user won't see onboarding anymore
   * @param {string} tourName
   */
  public static updateOnboardingLocalItem(tourName: string): void {
    let tourNameSave = tourName;
    const contextManager = EAContextManager.getInstance();
    const userName = contextManager.getSecurityContext().userInfo?.userName ?? '';
    tourNameSave = `${tourName}-${userName}`;
    localStorage.setItem(`onboarding-${tourNameSave}`, 'true');
  }

  /**
   * Checks if actual date is between kcitons corp table
   * @param {string} tourName
   * @returns {Promise<boolean>}
   */
  public static async checkCorporateTableDates(tourName: string): Promise<boolean> {
    const tourDates = await TourUtils.getTourDates(tourName);

    const todayInMillis = new Date().getTime();
    const tourSinceDateInMillis = tourDates.startDate?.getTime() || null;
    const tourUntilDateInMillis = tourDates.endDate?.getTime() || null;
    return (
      !!tourSinceDateInMillis && !!tourUntilDateInMillis &&
     todayInMillis > tourSinceDateInMillis &&
     todayInMillis < tourUntilDateInMillis
    );
  }

  /**
   * Checks if user has permissions for onboarding to show
   * @param {string} tourName
   * @returns {boolean}
   */
  public static checkOnboardingPermissions(tourName: string): boolean {
    const permissions = TOUR_PERMISSIONS[tourName];

    if (!permissions?.length) {
      return true;
    }

    const userAbilities = PermissionUtils.getUserAbilities();
    const res = PermissionUtils.hasAnyOfAbilitiesList(userAbilities, permissions);
    return res;
  }

  /**
   * 
   * @param {string} tourName 
   * @returns {boolean}
   */
  public static checkExtraCheckingPermissionsFunctions(tourName: string): boolean {
    const checkPermissionsFunctions = TOUR_CHECK_SHOWING_FUNCTIONS[tourName];

    if (!checkPermissionsFunctions.length) {
      return true;
    }

    for (const func of checkPermissionsFunctions) {
      if (!func()) {
        return false;
      }
    }

    return true;
  }
}
