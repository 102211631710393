<template>
  <div>
    <iframe
      id='coexSecIframe'
      title='coexSecIframe'
      style="width:0px;height:0px;display:none"
    />
  </div>
</template>

<script lang="ts">
import {
  EAApplicationLogger
} from '@zurich-es-npm/ea-front-web-core';
import {
  Component,
  Vue
} from 'vue-property-decorator';
import {
  bindMessageEventHandler
} from '../ea-pivot-message-handlers';
import {
  EADataMessageEvent, PivotEventType
} from '../ea-pivot-model';
import {
  EAPivotSecurityHooks
} from './ea-pivot-security-hooks';

const logger = new EAApplicationLogger();

@Component

/** 
 * Pivot security iframe is in charge of rendering a
 * hidden frame used for logout and session refresh.
 * 
 */
export default class EAPivotSecurityIFrame extends Vue {

  /** 
   * Mounted hook
   */
  public mounted(): void {
    logger.debug('EAPivotSecurityIFrame::mounted');
    bindMessageEventHandler(window, this.processPivotAuthHandler);
    EAPivotSecurityHooks.forcePivotAuthenticatedTimeout(30);
  }

  /** 
   * Process Pivot Authentication Handler
   * @param {Event} event
   * 
   */
  public processPivotAuthHandler(event: Event): void {
    const messageEvent = event as MessageEvent;
    const baseUrl = Vue.prototype.pivotBaseUrl;

    if (messageEvent.origin === baseUrl) {
      if (messageEvent.data) {
        const messageEventData = messageEvent.data as EADataMessageEvent;
        if (messageEventData.type === PivotEventType.PAGE && messageEventData.view === 'home') {
          EAPivotSecurityHooks.setPivotAuthenticated(true);
        }
      }
    } else {
      logger.error('Invalid origin processing Pivot Authentication');
    }
  }
}
</script>
