<template>
  <div>
    <ea-flow-container>

      <!-- Notifications container -->
      <div>
        <notification-handler />
      </div>

      <!-- Flow content: Header + Views -->
      <ea-row v-if="model.flowConfiguration.branchCode">
        <ea-col>
          <qb-header
            id="fleets-qb-header"
            :key="qbHeaderKey"
            v-model="undefined"
            :title="getTitle"
            :subtitle="subTitle"
            :showSteps="showSteps"
            :steps="steps"
            :activeStep="currentHeaderStep"
            :offerNumber="model.offerNumber"
            :offerVersion="model.offerVersion"
            :parentFlowId="flowId"
            :showCreateNoteButton="false"
            :showQueryNoteButton="false"
            :showDocumentationButton="false"
          />
        </ea-col>
        <ea-col>
          <component
            v-if="stepComponent"
            :is="stepComponent"
            v-model="model"
            :currentHeaderStep="currentHeaderStep"
            @changeStep="changeToStep"
            @goToPreviousFlow="goToPreviousFlow"
          />
          <!-- @showError="showGenericError"
            @handleGenericError="handleGenericError" -->
        </ea-col>
      </ea-row>

    </ea-flow-container>
  </div>
</template>

<script lang="ts">
import {
  Component
} from 'vue-property-decorator';
import {
  mixins
} from 'vue-class-component';
import {
  EAFlow,
  EAFlowNavigation,
  EALocaleManager,
  EAMethod,
  EAView,
  ResponseWithErrors,
  throwIfResponseHasErrors
} from '@zurich-es-npm/ea-front-web-core';
import QbHeaderBusiness from '@/business-components/qb-header/qb-header-business.vue';
import NotificationHandler from '@/presentational-components/notification-handler/notificationHandler.vue';
import FleetSummaryView from './views/fleet-summary-view.vue';
import VehiclesDataView from './views/vehicles-data-view.vue';
import {
  FleetsMaintenancePolicyModel,
  FlowViewsStepsModel,
  InputModel,
  OperationTypeEnum
} from './fleets-maintenance-policy-model';
import {
  ConfigFlowUtils
} from '@/utils/config-flow-utils';
import {
  NewFleetFlowConfig
} from '../fleets/model/new-fleet-flow-config-model';
import QuoteView from './views/quote-view.vue';
import InterveningIssuanceDataView from './views/intervening-issuance-data-view.vue';
import {
  FlowThunderNameEnum
} from '@/types/flow/flow-enum.types';

/**
 * Step / component relationship
 */
const StepComponentRelationship: {
  step: FlowViewsStepsModel;
  title?: string;
  component: typeof EAView;
}[] = [
  {
    step: FlowViewsStepsModel.FleetSummary,
    component: FleetSummaryView
  }, {
    step: FlowViewsStepsModel.VehiclesDataStep,
    title: 'fleets.fleetsMaintenancePolicyFlow.header.steps.vehicleData',
    component: VehiclesDataView
  },
  {
    step: FlowViewsStepsModel.QuoteDataStep,
    title: 'fleets.fleetsMaintenancePolicyFlow.header.steps.quote',
    component: QuoteView
  },
  {
    step: FlowViewsStepsModel.InterveningIssuanceStep,
    title: 'fleets.fleetsMaintenancePolicyFlow.header.steps.issuance',
    component: InterveningIssuanceDataView
  }
];

@Component({
  components: {
    QbHeader: QbHeaderBusiness,
    NotificationHandler
  }
})

/** 
 * Fleets-maintenance-policy flow
 * 
 */
export default class FleetsMaintenancePolicyFlow extends mixins<EAFlow<FleetsMaintenancePolicyModel>>(EAFlow) {

  public currentHeaderStep: number = -1;

  public showSteps: boolean = false;

  public qbHeaderKey: number = 0;

  public flowId: string = '';

  /** 
   * Initialize flow model
   */
  constructor() {
    super();
    this.model = new FleetsMaintenancePolicyModel();

    this.navigation = new EAFlowNavigation();
    this.navigation.maxWindows = 1;

    StepComponentRelationship.forEach(step => {
      if (step.title) {
        step.title = EALocaleManager.i18n.t(step.title) as string;
      }
    });
  }

  /**
   * Hooks on created
   */
  @EAMethod({
    loading: true
  })
  async created() {
    try {
      // Using same flowConfig than NewFleet flow seems reasonable
      this.model.flowConfiguration =
        await ConfigFlowUtils.getConfigFlowByName(FlowThunderNameEnum.FleetMaintenancePolicy) as NewFleetFlowConfig;
      
    } catch (err) {
      throwIfResponseHasErrors(err as ResponseWithErrors);
    }
    this.setInitialStep();
  }

  /** 
   * Get steps array
   */
  get steps(): {
    step: FlowViewsStepsModel;
    title?: string;
    component: typeof EAView;
  }[] {
    return StepComponentRelationship;
  }

  /**
   * Get correspondant step view component
   */
  get stepComponent(): typeof EAView | null {
    const relationship = StepComponentRelationship.find(
      rel => rel.step === this.navigation.step
    );
    if (relationship) {
      return relationship.component;
    }
    return null;
  }

  /**
   * Get flow title
   */
  get getTitle(): string {
    if (this.navigation.step === FlowViewsStepsModel.FleetSummary) {
      return EALocaleManager.i18n.t('fleets.fleetsMaintenancePolicyFlow.titleDefault') as string;
    } else {
      return EALocaleManager.i18n.t('fleets.fleetsMaintenancePolicyFlow.newOfferTitle') as string;
    }
  }

  /**
   * Get flow subtitle
   */
  get subTitle(): string {
    if (this.navigation.step === FlowViewsStepsModel.FleetSummary || !this.model.offerNumber) {
      return '';
    } else {
      return EALocaleManager.i18n.t('fleets.fleetsMaintenancePolicyFlow.header.offerNumber', {
        offerNumber: this.model.offerNumber
      }) as string;
    }
  }

  /**
   * Method to change to another step into the flow
   * @param {number} step
   * @param {number} headerStep
   */
  changeToStep(step: number, headerStep: number) {
    if (step === FlowViewsStepsModel.FleetSummary) {
      this.showSteps = false;
    } else {
      this.showSteps = true;
    }
   
    this.navigation.step = step;
    this.currentHeaderStep = headerStep;
    scrollTo(0, 0);
  }

  /**
   * Handles the navigatino to the next step
   * - If this.inputModel is informed, fill input config
   */
  setInitialStep() {
    const {
      codigoPoliza, versionPoliza, hasParentFlow
    }: InputModel = this.inputModel;

    this.model.hasParentFlow = hasParentFlow;
  
    if (codigoPoliza !== undefined && versionPoliza !== undefined) {
      this.model.offerNumber = codigoPoliza;
      this.model.offerVersion = versionPoliza;
      this.model.operationType = OperationTypeEnum.MANTENIMIENTO;

      // Go to Summary step (1st step)
      this.changeToStep(FlowViewsStepsModel.FleetSummary, -1);
    }
  }

  /**
   * Opens previous flow (ie: FleetsSearchOfferFlow...)
   */
  @EAMethod({
    loading: true
  })
  goToPreviousFlow() {
    this.returnToParentFlow(undefined);
  }

}
</script>
