import Vue from 'vue';
import {
  EAGetVehicleBrandsApi,
  GetVehicleBrandsResponseData
} from '@/services/V1/fleets/getVehicleBrandsOperation/post';
import {
  EAValidationError,
  ResponseWithErrors,
  throwIfResponseHasErrors
} from '@zurich-es-npm/ea-front-web-core';
import {
  cloneDeep
} from 'lodash';

/**
 * Vehicle brands utilities.
 */
export class VehicleBrandsUtils {

  private static _vehicleBrands: GetVehicleBrandsResponseData[] | undefined = undefined;

  /**
   * Get vehicle brands.
   * Returns cached vehicle brands or fetch from Cobol if no cache data.
   */
  public static async getVehicleBrands(): Promise<GetVehicleBrandsResponseData[]> {
    if (this._vehicleBrands?.length) {
      // Return cached data
      return cloneDeep(this._vehicleBrands);
    }

    // Fetch data and save in cache
    const api = new EAGetVehicleBrandsApi();
    const vehicleBrandsResponse = await api.getVehicleBrandsOperation({});
    if (vehicleBrandsResponse) {
      throwIfResponseHasErrors(vehicleBrandsResponse as ResponseWithErrors);
      if (vehicleBrandsResponse.data?.length) {
        // Save in cache obtained data
        this._vehicleBrands = vehicleBrandsResponse.data;
        return cloneDeep(this._vehicleBrands);
      }
    }
    
    // If no data could be obtained, throw error
    throw new EAValidationError(
      Vue.prototype.$t('fleets.fleetsFlow.inputVehiclesData.manualInput.errorChargingBrands')
    );
  }

  /**
   * Clear vehcicle brands cache.
   */
  public static clearVehicleBrandsCache(): void {
    this._vehicleBrands = undefined;
  }

}
