
import {
  NotUnifiedDateRestriction,
} from './not-unified-date-restriction';
import {
  SelectItemType
} from './select-Item-type-model';
import {
  UnifiedDateRestriction,
} from './unified-date-restriction';

/**
 * 
 */
export class VehiclesDataView {
  public allowedPlateTypes: string[] = [];

  public noPlateOption: SelectItemType = new SelectItemType();

  public unifiedDateRestriction: UnifiedDateRestriction = new UnifiedDateRestriction();

  public notUnifiedDateRestriction: NotUnifiedDateRestriction = new NotUnifiedDateRestriction();
}
