// eslint-disable-next-line max-len
import QbAddressesPersonModel from '@/business-components/addresses/qb-addresses-person-model';
import {
  EAGetPersonAddressesApi,
  GetPersonAddressesResponse,
  GetPersonAddressesResponseDomicilios
} from '@/services/V1/persons/getPersonAddressesOperation/post';
import {
  SetPersonAddressesRequestDomicilios
} from '@/services/V1/persons/setPersonAddressesOperation/post';
import {
  EAMethod, ResponseWithErrors
} from '@zurich-es-npm/ea-front-web-core';
import {
  cloneDeep
} from 'lodash';
import {
  ParsedTableData
} from './corporate-tables';
import TextUtils from './text-utils';

export enum AddressType {
  Email = 'E',
  Phone = 'T',
  Postal = 'P'
}

export interface FetchAddressesDataResponse {
  addressesModel? : QbAddressesPersonModel;
  serviceOutput: ResponseWithErrors;
}

export interface NormalizeAddressParams {
  addressType?: string;
  addressName?: string;
  addressNumber?: string | number;
  blockStair?: string | number;
  stairwell?: string | number;
  floorOrInformacionAdicional?: string;
  doorNumber?: string | number;
  zipCode?: string;
  cityName?: string;
}

/**
 * Address utils object with auxiliar functions
 */
export class AddressUtils {
  public static roadTypeCorpTable: ParsedTableData[] = [];

  /**
   * Fetches person addresses data
   * @param {string | undefined} filiationCode
   * @returns {Promise<GetPersonAddressesResponse | null>}
   */
  @EAMethod()
  public static async fetchAddressesData(
    filiationCode?: string,
  ): Promise<GetPersonAddressesResponse | null> {
    if (!filiationCode) {
      return null;
    }

    const api = new EAGetPersonAddressesApi();
    return api.getPersonAddressesOperation({
      getPersonAddressesRequest: {
        codigoFiliacion: filiationCode,
      },
    });
  }

  /**
   * Normalizes address
   * @param {NormalizeAddressParams} normalizeAddressParams
   * @returns {string}
   */
  public static normalizeAddress(
    normalizeAddressParams: NormalizeAddressParams
  ): string {
    normalizeAddressParams.cityName =
      normalizeAddressParams.cityName ? normalizeAddressParams.cityName.toLowerCase() : '';
    normalizeAddressParams.addressName =
    normalizeAddressParams.addressName ? normalizeAddressParams.addressName.toLowerCase() : '';
    // eslint-disable-next-line max-len
    return (
      `${normalizeAddressParams.addressType || ''}${
        normalizeAddressParams.addressType ? ' ' : ''}${normalizeAddressParams.addressName}${
        normalizeAddressParams.addressNumber ? ' ' : ''
      }${normalizeAddressParams.addressNumber || ''}${
        this.getBlockstair(normalizeAddressParams.blockStair)
      }${
        this.getStairwell(normalizeAddressParams.stairwell)
      }${
        this.getFloorOrInformacionAdicional(normalizeAddressParams.floorOrInformacionAdicional)
      }${
        this.getDoorNumber(normalizeAddressParams.doorNumber)
      }, ${normalizeAddressParams.zipCode} ${
        TextUtils.capitalizeFirstLetterOneWord(normalizeAddressParams.cityName.toLowerCase())
      }`
    );
  }

  /**
   * Gets block if has
   * @param {string | undefined} block
   * @returns {string}
   */
  public static getBlockstair(block?: string | number): string {
    return block !== undefined && block !== '' ? `, Bl.${block}` : '';
  }

  /**
   * Gets stairwell if has
   * @param {string | undefined} stairwell
   * @returns {string}
   */
  public static getStairwell(stairwell?: string | number): string {
    return stairwell !== undefined && stairwell !== '' ? `, Esc.${stairwell}` : '';
  }

  /**
   * Gets getFloorOrInformacionAdicional if has
   * @param {string | undefined} floorOrInformacionAdicional
   * @returns {string}
   */
  public static getFloorOrInformacionAdicional(floorOrInformacionAdicional?: string): string {
    // eslint-disable-next-line max-len
    return floorOrInformacionAdicional !== undefined && floorOrInformacionAdicional !== '' ? `, ${floorOrInformacionAdicional}` : '';
  }

  /**
   * Gets doorNumber if has
   * @param {string | number | undefined} doorNumber
   * @returns {string}
   */
  public static getDoorNumber(doorNumber?: string | number): string {
    // eslint-disable-next-line max-len
    return doorNumber !== undefined && doorNumber !== '' ? `-${doorNumber}` : '';
  }


  /**
   * Get contact address or main POSTAL address
   * @param {GetPersonAddressesResponse} addressesData
   * @param {QbAddressesPersonModel} model
   * @returns {GetPersonAddressesResponseDomicilios | undefined}
   */
  public static getContactOrMainPostalAddress(
    addressesData: GetPersonAddressesResponse,
    model: QbAddressesPersonModel
  ): GetPersonAddressesResponseDomicilios | undefined {
    let mainAddress;
    if (addressesData.direccionContacto) {
      mainAddress = model.addEditAddress.addressList.find(
        postalAddress => postalAddress.codigoSecuencialDomicilio === addressesData.direccionContacto
      );
    }
    if (!mainAddress) {
      mainAddress = AddressUtils.getMainPostalAddress(model);
    }
    return mainAddress;
  }

  /**
   * Get main POSTAL address
   * @param {QbAddressesPersonModel} model
   * @returns {GetPersonAddressesResponseDomicilios | undefined}
   */
  public static getMainPostalAddress(
    model: QbAddressesPersonModel): GetPersonAddressesResponseDomicilios | undefined {
    return model.addEditAddress.addressList.find(
      postalAddress => postalAddress.indicadorDomicilioPrincipal === 'S'
    );
  }

  /**
   * Get  main EMAIL address
   * @param {QbAddressesPersonModel} model
   * @returns {GetPersonAddressesResponseDomicilios | undefined}
   */
  public static getMainEmailAddress(
    model: QbAddressesPersonModel): GetPersonAddressesResponseDomicilios | undefined {
    return model.addEditEmail.emailList.find(
      emailAddress => emailAddress.indicadorDomicilioPrincipal === 'S'
    );
  }

  /**
   * Get  main PHONE address
   * @param {QbAddressesPersonModel} model
   * @returns {GetPersonAddressesResponseDomicilios | undefined}
   */
  public static getMainPhoneAddress(
    model: QbAddressesPersonModel): GetPersonAddressesResponseDomicilios | undefined {
    return model.addEditPhone.phoneList.find(
      phoneAddress => phoneAddress.indicadorDomicilioPrincipal === 'S'
    );
  }

  /**
   * Gets address with higher 'codigoSecuencialDomicilio'
   * @param {GetPersonAddressesResponse} addressesData 
   * @returns {GetPersonAddressesResponseDomicilios | undefined}
   */
  public static getAddressWithHigherAddressCode(
    addressesData: GetPersonAddressesResponse,
  ): GetPersonAddressesResponseDomicilios | undefined {
    if (!addressesData.domicilios) {
      return;
    }

    const codeSortedAddresses = this._sortAddressesByCode(addressesData.domicilios);
    return codeSortedAddresses[codeSortedAddresses.length -1];
  }

  /**
   * Updates addresses models with received data
   * @param {GetPersonAddressesResponse} addressesData
   * @param {QbAddressesPersonModel | undefined} oldModel - received if we want to maintain selected addresses
   * @returns {QbAddressesPersonModel}
   */
  public static addressesResponseToModel(
    addressesData: GetPersonAddressesResponse,
    oldModel?: QbAddressesPersonModel,
  ): QbAddressesPersonModel {
    const model = oldModel ? oldModel : new QbAddressesPersonModel();

    if (!addressesData.domicilios) {
      return model;
    }

    // ### Set email addresses ###
    AddressUtils.emailToModel(addressesData, model);

    // ### Set postal addresses ###
    AddressUtils.postalsToModel(addressesData, model);

    // ### Set phone addresses ###
    AddressUtils.phoneToModel(addressesData, model);

    return model;
  }

  /**
   * Updates phone model with received data
   * @param {GetPersonAddressesResponse} addressesData
   * @param {QbAddressesPersonModel} model
   * @returns {void}
   */
  public static phoneToModel(
    addressesData: GetPersonAddressesResponse,
    model: QbAddressesPersonModel,
  ): void {
    if (!addressesData.domicilios) {
      return;
    }

    const phoneAddresses = addressesData.domicilios.filter(
      address => address.tipoDomicilio === AddressType.Phone ||
        (address.tipoDomicilio === AddressType.Postal && !!address.telefonos?.length)
    );
    model.addEditPhone.phoneList = phoneAddresses;
  }

  /**
   * Updates emails model with received data
   * @param {GetPersonAddressesResponse} addressesData
   * @param {QbAddressesPersonModel} model
   * @returns {void}
   */
  public static emailToModel(
    addressesData: GetPersonAddressesResponse,
    model: QbAddressesPersonModel,
  ): void {
    if (!addressesData.domicilios) {
      return;
    }

    const emailAddresses = addressesData.domicilios.filter(
      address => address.tipoDomicilio === AddressType.Email);
    model.addEditEmail.emailList = emailAddresses;
  }

  /**
   * Updates postals model with received data
   * @param {GetPersonAddressesResponse} addressesData
   * @param {QbAddressesPersonModel} model
   * @param {SetPersonAddressesRequestDomicilios | undefined} lastAddedAddress
   * @returns {void}
   */
  public static postalsToModel(
    addressesData: GetPersonAddressesResponse,
    model: QbAddressesPersonModel
  ): void {
    if (!addressesData.domicilios) {
      return;
    }

    const postalAddresses = addressesData.domicilios.
      filter(address => address.tipoDomicilio === AddressType.Postal);
    model.addEditAddress.addressList = postalAddresses;
  }

  /**
   * Parses postal label to display format
   * @param {GetPersonAddressesResponseDomicilios} address
   * @param {ParsedTableData[]} corpTableDocumentsAddressType
   * @returns {string}
   */
  public static getPostalLabel(
    address: GetPersonAddressesResponseDomicilios,
    corpTableDocumentsAddressType: ParsedTableData[]
  ): string {
    const parsedAddressType = corpTableDocumentsAddressType.find(
      addressType => addressType.value === address.tipoViaDireccion
    )?.label;
    return AddressUtils.normalizeAddress(
      {
        addressType: parsedAddressType,
        addressName: address.nombreDireccion,
        addressNumber: address.numeroDireccion,
        floorOrInformacionAdicional: address.informacionAdicional,
        zipCode: address.codigoPostalConLetras,
        cityName: address.nombrePoblacion
      }
    );
  }

  /**
   * Gets last secuential code in address list given address type & model with address lists
   * @param {QbAddressesPersonModel} model
   * @param {string | undefined} addressType
   * @returns {string}
   */
  public static getLastSecuentialCode(
    model: QbAddressesPersonModel,
    addressType?: string
  ): string {
    switch (addressType) {
      case 'E':
        return model.addEditEmail.emailList[
          model.addEditEmail.emailList.length - 1
        ].codigoSecuencialDomicilio || '';
      case 'T':
        return model.addEditPhone.phoneList[
          model.addEditPhone.phoneList.length - 1
        ].codigoSecuencialDomicilio || '';
      case 'P':
        return model.addEditAddress.addressList[
          model.addEditAddress.addressList.length - 1
        ].codigoSecuencialDomicilio || '';
      default:
        return '';
    }
  }

  /**
   * Selects received address
   * @param {QbAddressesPersonModel} model
   * @param {SetPersonAddressesRequestDomicilios | undefined} addressToSelect
   */
  public static selectAddress(
    model: QbAddressesPersonModel,
    addressToSelect?: SetPersonAddressesRequestDomicilios
  ): void {
    if (!addressToSelect?.tipoDomicilio) {
      return;
    }

    switch (addressToSelect.tipoDomicilio) {
      case 'E':
        model.addEditEmail.selectedEmailCode = addressToSelect.codigoSecuencialDomicilio;
        break;
      case 'T':
        model.addEditPhone.selectedPhoneNumber =
        addressToSelect.telefonos?.[addressToSelect.telefonos.length - 1].numeroTelefono;
        break;
      case 'P':
        model.addEditAddress.selectedAddressCode = addressToSelect.codigoSecuencialDomicilio;
        break;
      default:
        break;
    }
  }

  /**
   * Sort received addresses by codigoSecuencialDomicilio property
   * @param {GetPersonAddressesResponseDomicilios[]} addresses
   * @returns {GetPersonAddressesResponseDomicilios[]}
   */
  public static _sortAddressesByCode(
    addresses: GetPersonAddressesResponseDomicilios[]
  ): GetPersonAddressesResponseDomicilios[] {
    const codeSortedAddresses = cloneDeep(addresses);
    codeSortedAddresses.sort((addressA, addressB) => parseInt(addressA.codigoSecuencialDomicilio as string) -
        parseInt(addressB.codigoSecuencialDomicilio as string));
    return codeSortedAddresses;
  }

  /**
   * Sets selectedAddress property in received addressesModel for each type of address (email, phone, postal)
   * @param {GetPersonAddressesResponse} fetchAddressesDataOutput
   * @param {QbAddressesPersonModel | undefined} addressesModel
   * @param {SetPersonAddressesRequestDomicilios | undefined} lastAddedAddress
   */
  public static selectMainAddresses(
    fetchAddressesDataOutput: GetPersonAddressesResponse,
    addressesModel?: QbAddressesPersonModel,
    lastAddedAddress?: SetPersonAddressesRequestDomicilios
  ): void {
    if (!addressesModel) {
      addressesModel = new QbAddressesPersonModel();
    }
    this._selectPostalMainAddress(addressesModel, fetchAddressesDataOutput, lastAddedAddress);
    this._selectEmailMainAddress(addressesModel, fetchAddressesDataOutput, lastAddedAddress);
    this._selectPhoneMainAddress(addressesModel, fetchAddressesDataOutput, lastAddedAddress);
  }

  /**
   *
   * Sets selectedAddress for 'Postal' type
   *  If lastAddedAddress received && lastAddress is 'Postal' type =>
   *    assign last received address in response as received lastAddedAddress does not have codigoSecuencialDomicilio
   * @param {QbAddressesPersonModel} addressesModel
   * @param {GetPersonAddressesResponse} fetchAddressesDataOutput
   * @param {SetPersonAddressesRequestDomicilios | undefined} lastAddedAddress
   */
  public static _selectPostalMainAddress(
    addressesModel: QbAddressesPersonModel,
    fetchAddressesDataOutput: GetPersonAddressesResponse,
    lastAddedAddress?: SetPersonAddressesRequestDomicilios
  ): void {
    if (lastAddedAddress?.tipoDomicilio === AddressType.Postal) {

      /**
       * If lastAddedAddress was new (doesn't have code) => assign address with higher code
       *  Otherwise (editted address) => we can assign it as it will have code
       */
      addressesModel.addEditAddress.selectedAddress = lastAddedAddress.codigoSecuencialDomicilio ?
        lastAddedAddress : AddressUtils.getAddressWithHigherAddressCode(fetchAddressesDataOutput);
    }

    if (!addressesModel.addEditAddress.selectedAddress) {
      addressesModel.addEditAddress.selectedAddress =
        AddressUtils.getContactOrMainPostalAddress(fetchAddressesDataOutput, addressesModel);
    }

    addressesModel.addEditAddress.selectedAddressCode =
      addressesModel.addEditAddress.selectedAddress?.codigoSecuencialDomicilio;
  }

  /**
   *
   * Sets selectedAddress for 'Email' type
   *  If lastAddedAddress received && lastAddress is 'Email' type =>
   *    assign last received address in response as received lastAddedAddress does not have codigoSecuencialDomicilio
   * @param {QbAddressesPersonModel} addressesModel
   * @param {GetPersonAddressesResponse} fetchAddressesDataOutput
   * @param {SetPersonAddressesRequestDomicilios | undefined} lastAddedAddress
   */
  public static _selectEmailMainAddress(
    addressesModel: QbAddressesPersonModel,
    fetchAddressesDataOutput: GetPersonAddressesResponse,
    lastAddedAddress?: SetPersonAddressesRequestDomicilios
  ): void {
    if (lastAddedAddress?.tipoDomicilio === AddressType.Email) {

      /**
       * If lastAddedAddress was new (doesn't have code) => assign address with higher code
       *  Otherwise (editted address) => we can assign it as it will have code
       */
      addressesModel.addEditEmail.selectedEmail = lastAddedAddress.codigoSecuencialDomicilio ?
        lastAddedAddress : AddressUtils.getAddressWithHigherAddressCode(fetchAddressesDataOutput);
    }

    if (!addressesModel.addEditEmail.selectedEmail) {
      addressesModel.addEditEmail.selectedEmail = AddressUtils.getMainEmailAddress(addressesModel);
    }

    addressesModel.addEditEmail.selectedEmailCode =
      addressesModel.addEditEmail.selectedEmail?.codigoSecuencialDomicilio;
  }

  /**
   *
   * Sets selectedAddress for 'Phone' type
   *  If lastAddedAddress received && lastAddress is 'Phone' type =>
   *    assign last received address in response as received lastAddedAddress does not have codigoSecuencialDomicilio
   * @param {QbAddressesPersonModel} addressesModel
   * @param {GetPersonAddressesResponse} fetchAddressesDataOutput
   * @param {SetPersonAddressesRequestDomicilios | undefined} lastAddedAddress
   */
  public static _selectPhoneMainAddress(
    addressesModel: QbAddressesPersonModel,
    fetchAddressesDataOutput: GetPersonAddressesResponse,
    lastAddedAddress?: SetPersonAddressesRequestDomicilios
  ): void {
    if (lastAddedAddress?.tipoDomicilio === AddressType.Phone) {
      const addedAddressWithHigherAddressCode =
        AddressUtils.getAddressWithHigherAddressCode(fetchAddressesDataOutput);

      /**
       * If lastAddedAddress was new (doesn't have code) => assign address with higher code
       *  Otherwise (editted address) => we can assign it as it will have code
       */
      addressesModel.addEditPhone.selectedPhoneNumber = lastAddedAddress.codigoSecuencialDomicilio ?
        this._getLastPhoneInList(lastAddedAddress) : this._getLastPhoneInList(addedAddressWithHigherAddressCode);
    }

    if (!addressesModel.addEditPhone.selectedPhoneNumber) {
      const contactOrMainPhoneAddress = AddressUtils.getMainPhoneAddress(addressesModel);
      addressesModel.addEditPhone.selectedPhoneNumber = this._getLastPhoneInList(contactOrMainPhoneAddress);
    }
  }

  /**
   * Returns last phone number from given list
   * @param {GetPersonAddressesResponseDomicilios} phoneAddressesList
   * @returns {string}
   */
  public static _getLastPhoneInList(
    phoneAddressesList?: GetPersonAddressesResponseDomicilios
  ): string {
    if (!phoneAddressesList?.telefonos) {
      return '';
    }
    const lastPhoneIdx = phoneAddressesList.telefonos.length - 1;
    return phoneAddressesList.telefonos[lastPhoneIdx].numeroTelefono || '';
  }
}
