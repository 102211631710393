import {
  GetPersonAddressesResponseDomicilios
} from '@/services/V1/persons/getPersonAddressesOperation/post';

export enum SelectedAddressEnum {
  POLICY_TAKER_ADDRESS = 'POLICY_TAKER_ADDRESS',
  INTERMEDIARY_ADDRESS = 'INTERMEDIARY_ADDRESS'
}

/** 
 * Model qb-select-documentation-sending-address
 */
class QbSelectDocumentationSendingAddressModel {

  public intermediaryAddress: string = '';

  public radioBtnSelectedAddress: SelectedAddressEnum = SelectedAddressEnum.POLICY_TAKER_ADDRESS;

  public policyTakerAddressList: GetPersonAddressesResponseDomicilios[] = [];

  public policyTakerSelectedAddressCode?: string;

}

export default QbSelectDocumentationSendingAddressModel;
