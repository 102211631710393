/* eslint-disable */

/**
 * zonazurich
 * Portal de Mediación Digital
 *
 * The version of the OpenAPI document: 1
 * Contact: digitalarchitecture@zurich.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import {
  EASDKConfig,
  EAUrlUtils
} from '@zurich-es-npm/ea-shared-utils-js';

import * as globalImportUrl from 'url';
import {
  Configuration
} from './configuration';
import globalAxios, {
  AxiosPromise,
  AxiosInstance,
  AxiosResponse
} from 'axios';

const BASE_PATH = EAUrlUtils.removeLastForwardSlash('http://localhost:3000');

/**
 *
 * @export
 */
export const COLLECTION_FORMATS = {
  csv: ',',
  ssv: ' ',
  tsv: '\t',
  pipes: '|',
};

/**
 *  
 * @export
 * @interface RequestArgs
 */
export interface RequestArgs {
  url: string;
  options: any;
}

/**
 * 
 * @export
 * @class BaseAPI
 */
export class BaseAPI {
  protected configuration: Configuration | undefined;

  /**
   *Creates an instance of BaseAPI.
   * @param {Configuration} [configuration]
   * @param {string} [basePath=BASE_PATH]
   * @param {AxiosInstance} [axios=globalAxios]
   * @memberof BaseAPI
   */
  public constructor(
    configuration?: Configuration,
    protected basePath: string = BASE_PATH,
    protected axios: AxiosInstance = globalAxios) {
    if (configuration) {
      this.configuration = configuration;
      this.basePath = configuration.basePath || this.basePath;
    }
  }
}

/**
 * 
 * @export
 * @class RequiredError
 * @extends {Error}
 */
export class RequiredError extends Error {
  public name: 'RequiredError' = 'RequiredError';

  public field: string;

  /**
   *Creates an instance of RequiredError.
   * @param {string} field
   * @param {string} [msg]
   * @memberof RequiredError
   */
  public constructor(field: string, msg?: string) {
    super(msg);
    this.field=field;
  }
}

/**
 * 
 * @export
 * @class AsyncRequestError
 * @extends {Error}
 */
export class AsyncRequestError extends Error {
  public name: 'AsyncRequestError' = 'AsyncRequestError';

  /**
   *Creates an instance of AsyncRequestError.
   * @param {string} field
   * @param {string} [msg]
   * @memberof AsyncRequestError
   */
  public constructor(msg?: string) {
    super(msg);
  }
}

/**
 * 
 * @export
 * @interface Error
 */
export interface Error {
    /**
     * 
     * @type {string}
     * @memberof Error
     */
    code: string;
    /**
     * 
     * @type {string}
     * @memberof Error
     */
    message: string;
    /**
     * 
     * @type {number}
     * @memberof Error
     */
    severity: number;
    /**
     * 
     * @type {Array<object>}
     * @memberof Error
     */
    childErrors?: Array<object>;
}/**
 * 
 * @export
 * @interface SendNoteRequest
 */
export interface SendNoteRequest {
    /**
     * 
     * @type {string}
     * @memberof SendNoteRequest
     */
    codigoPoliza: string;
    /**
     * 
     * @type {number}
     * @memberof SendNoteRequest
     */
    versionPoliza: number;
    /**
     * 
     * @type {string}
     * @memberof SendNoteRequest
     */
    tipoPoliza: SendNoteRequestTipoPolizaEnum;
    /**
     * 
     * @type {string}
     * @memberof SendNoteRequest
     */
    titularNota: string;
    /**
     * 
     * @type {string}
     * @memberof SendNoteRequest
     */
    motivoNota?: string;
    /**
     * 
     * @type {string}
     * @memberof SendNoteRequest
     */
    tipoDestinoNota: SendNoteRequestTipoDestinoNotaEnum;
    /**
     * 
     * @type {string}
     * @memberof SendNoteRequest
     */
    codigoTareaAvisoNota: string;
}

/**
    * @export
    * @enum {string}
    */
export enum SendNoteRequestTipoPolizaEnum {
    Oferta = 'Oferta',
    Pliza = 'Póliza',
    Incompleta = 'Incompleta'
}
/**
    * @export
    * @enum {string}
    */
export enum SendNoteRequestTipoDestinoNotaEnum {
    SinDestino = 'SinDestino',
    Grupo = 'Grupo'
}
/**
 * 
 * @export
 * @interface SendNoteResponse
 */
export interface SendNoteResponse {
    /**
     * 
     * @type {boolean}
     * @memberof SendNoteResponse
     */
    ok?: boolean;
    /**
     * 
     * @type {Array<Error>}
     * @memberof SendNoteResponse
     */
    errors?: Array<Error>;
}

type sendNoteOperationParams = {
  sendNoteRequest: SendNoteRequest,
};

/**
 * SendNoteApi - axios parameter creator
 * @param {Configuration} [configuration]
 * @returns {any}
 */
const SendNoteApiAxiosParamCreator = function(configuration?: Configuration): any {
  return {
    
    /**
     * Crea una nota
     * @param {SendNoteRequest} sendNoteRequest 
     * @param {string} [acceptLanguage] El Accept-Language request HTTP header indica en que lenguaje debe responder el backend.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @returns {RequestArgs}
     */
    sendNoteOperation(params: sendNoteOperationParams, options: any = {}): RequestArgs {
      // Verify required parameter 'sendNoteRequest' is not null or undefined
      if (params.sendNoteRequest === null || params.sendNoteRequest === undefined) {
        throw new RequiredError('sendNoteRequest', 'Required parameter sendNoteRequest was null or undefined when calling sendNoteOperation.');
      }
      const localVarPath = `/v1/notes/sendNoteOperation`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true) as Partial<globalImportUrl.UrlWithParsedQuery>;
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;


  
      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
      // Fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
      const needsSerialization = ('SendNoteRequest' as any !== 'string') ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization ? JSON.stringify(params.sendNoteRequest || {}) : params.sendNoteRequest || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * SendNoteApi - functional programming interface
 * @param {Configuration} [configuration]
 * @returns {any}
 */
const SendNoteApiFp = function(configuration?: Configuration): any {
  return {
    
    /**
     * Crea una nota
     * @param {SendNoteRequest} sendNoteRequest 
     * @param {string} [acceptLanguage] El Accept-Language request HTTP header indica en que lenguaje debe responder el backend.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @returns {any}
     */
    sendNoteOperation(params: sendNoteOperationParams, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<SendNoteResponse> {
      const localVarAxiosArgs = SendNoteApiAxiosParamCreator(configuration).sendNoteOperation(params, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH): any => {
        const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * SendNoteApi - factory interface
 * @param {Configuration} configuration
 * @param {string} basePath
 * @param {AxiosInstance} axios
 * @export
 * @returns {any}
 */
const SendNoteApiFactory = function(configuration?: Configuration, basePath?: string, axios?: AxiosInstance): any {
  return {
    
    /**
     * Crea una nota
     * @param {SendNoteRequest} sendNoteRequest 
     * @param {string} [acceptLanguage] El Accept-Language request HTTP header indica en que lenguaje debe responder el backend.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @returns {any}
     */
    sendNoteOperation(params: sendNoteOperationParams, options?: any): any {
      return SendNoteApiFp(configuration).sendNoteOperation(params, options)(axios, basePath);
    },
  };
};

/** 
 * SendNoteApi - object-oriented interface
 * @export
 * @class SendNoteApi
 * @extends {BaseAPI}
 */
export class SendNoteApi extends BaseAPI {
  
  /**
   * Crea una nota
   * @param {SendNoteRequest} sendNoteRequest 
   * @param {string} [acceptLanguage] El Accept-Language request HTTP header indica en que lenguaje debe responder el backend.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @returns {any}
   * @memberof SendNoteApi
   */
  public sendNoteOperation(params: sendNoteOperationParams, options?: any): any {
    return SendNoteApiFp(this.configuration).sendNoteOperation(params, options)(this.axios, this.basePath);
  }

}

/**
 * 
 * @export
 * @class EAsendNoteOperationInputMessage
 */
export class EAsendNoteOperationInputMessage {
  
  /**
   * 
   * @type {SendNoteRequest}
   * @memberof EAsendNoteOperationInputMessage
   */
  public sendNoteRequest: SendNoteRequest;

  public constructor(sendNoteRequest: SendNoteRequest, ){
  
  this.sendNoteRequest=sendNoteRequest;
  } 
}



/** 
 * EASendNoteApi - Architecture client for sendNoteOperation
 * @export
 * @class EASendNoteApi
 */
export class EASendNoteApi {

  /**
   * 
   * @param {SendNoteRequest} sendNoteRequest
   
   * @throws {AsyncRequestError | RequiredError}
   * @returns {Promise<SendNoteResponse | null>}
  */ 
  public async sendNoteOperation(params: sendNoteOperationParams): Promise<SendNoteResponse | null> {
    return this.sendNoteOperationPromise(params);
  }

  /**
   * 
   * @param {SendNoteRequest} sendNoteRequest
   
   * @param {Function} callback
   * @throws {AsyncRequestError | RequiredError}
   */
  public async sendNoteOperationSteps(params: sendNoteOperationParams, callback: (res: AxiosResponse<SendNoteResponse | null>) => void) {
    let done: boolean = false;
    const httpClient: AxiosInstance = EASDKConfig.getHttpClient('/v1/notes/sendNoteOperation', 'sendNote', 'sendNoteOperation');
    const config: Configuration = {};
    const api = new SendNoteApi(config, '', httpClient);
    const sleep = async(ms: number) => {
      return new Promise(resolve => setTimeout(resolve, ms));
    };
    const headers: { ['Async-Id']?: string } = {};
    while (!done) {
      const output = await api.sendNoteOperation(params, { headers }) as AxiosResponse<SendNoteResponse | null>;
      callback(output);
      if (output.status === 202) {
        try {
          const retry = parseInt(output.headers['retry']);
          headers['Async-Id'] = output.headers['request-id'];
          if (isNaN(retry) || retry <= 0 || retry > 1000 * 3600) {
            throw new Error(`Retry time not acceptable (in milliseconds): ${retry}`);
          }
          await sleep(retry);
        } catch (err) {
          throw new Error(`Error in Async Request ${err}`);
        }
      } else {
        done = true;
      }
    }
  }

  /**
   * 
   * @param {SendNoteRequest} sendNoteRequest
   
   * @throws {AsyncRequestError | RequiredError}
   * @returns {Promise<SendNoteResponse | null>}
   */
  public async sendNoteOperationPromise(params: sendNoteOperationParams): Promise<SendNoteResponse | null> {
    return new Promise((resolve, reject) => {
      this.sendNoteOperationSteps(params, (response: AxiosResponse<SendNoteResponse | null>) => {
        if (response.status !== 202) {
          resolve(response.data);
        }
      })
      .catch((err) => {
        reject(err);
      });
    });
  } 
}


