<template>
  <div>
    <qb-new-offer
      id="new-offer-communities-qb"
      v-model="model.newOfferModel"
      :productFactory="productFactory"
      :codigoRamo="productFactory.codigoRamo"
      @offerCreated="newOfferCreated"
      @showError="showError"
      @handleGenericError="onHandleGenericError"
      @closeGenericErrorDialog="closeGenericErrorDialog"
    ></qb-new-offer>
  </div>
</template>

<script lang="ts">
import {
  Component, Prop
} from 'vue-property-decorator';

import {
  mixins
} from 'vue-class-component';

import {
  EAView, EAMethod
} from '@zurich-es-npm/ea-front-web-core';

import {
  FlowHeaderStepsModel,
  FlowViewsStepsModel, QuoteBuyModel
} from '../quote-buy-model';

import QBNewOfferBusiness from '@/business-components/qb-new-offer/qb-new-offer-business.vue';

import ProductBase from '@/utils/quote-buy-product-factory/products/product-base';
import {
  GenericErrorData
} from '@/business-components/qb-generic-error/qb-generic-error-business.vue';
import {
  NotificationsUtils
} from '@/utils/notifications/notifications-utils';

@Component({
  components: {
    QbNewOffer: QBNewOfferBusiness
  },
})

/**
 * Quote-buy NewOffer view
 *
 */
export default class NewOfferView extends mixins<EAView<QuoteBuyModel>>(EAView) {

  @Prop({
    required: true
  })
    productFactory!: ProductBase;

  /**
   * Go to next view.
   * @param {string} offerNumber offer number
   * @param {number} offerVersion offer version
   */
  @EAMethod()
  newOfferCreated(offerNumber: string, offerVersion: number) {
    this.model.offerNumber = offerNumber;
    this.model.offerVersion = offerVersion;
    this.update();
    NotificationsUtils.clearNotifications();
    this.$emit('changeStep', FlowViewsStepsModel.GeneralDataStep, FlowHeaderStepsModel.GeneralDataStep);
  }

  /**
   * Emits showError event
   * @param {GenericErrorData} genericErrorData
   */
  showError(genericErrorData: GenericErrorData) {
    this.$emit('showError', genericErrorData);
  }

  /**
   * Emit the refresh data event from address to parent component
   *
   * @param {any} args
   */
  onHandleGenericError(args: any) {
    this.$emit('handleGenericError', args);
  }

  /**
   * Emits closeGenericErrorDialog event
   *
   * @param {any} args
   */
  closeGenericErrorDialog() {
    this.$emit('closeGenericErrorDialog');
  }
}
</script>
