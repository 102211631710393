import Vue from 'vue';
import {
  EAValidationError
} from '@zurich-es-npm/ea-front-web-core';

/**
 * Utils for supplements
 */
export class SupplementsUtils {

  /**
   * Perform supplement validations
   * @param {boolean} shouldValidate1YearMore
   * @param {Date} fechaInicioPoliza
   * @param {Date} fechaEfectoNatural
   * @param {Date} dueDate
   * @param {Date | undefined} effectDate
   * @param {boolean} isExpiration
   * @returns {EAValidationError[]}
   */
  public static checkValidations(
    shouldValidate1YearMore: boolean,
    fechaInicioPoliza: Date,
    fechaEfectoNatural: Date,
    dueDate: Date,
    effectDate?: Date,
    isExpiration?: boolean,
  ): EAValidationError[] {
    const validationsErrors: EAValidationError[] = [];
    if (effectDate) {
      effectDate.setHours(0, 0, 0, 0);
    }
    fechaInicioPoliza.setHours(0, 0, 0, 0);
    fechaEfectoNatural.setHours(0, 0, 0, 0);
    dueDate.setHours(0, 0, 0, 0);

    if (effectDate && effectDate.getTime() < fechaEfectoNatural.getTime()) {
      // INFO error: 8401
      validationsErrors.push(
        new EAValidationError(`${Vue.prototype.$t('policyMaintenanceFlow.supplements.validation.8401')}`)
      );
    }

    if (shouldValidate1YearMore) {
      const fechaEfectoNatural1YearMore = new Date(fechaEfectoNatural);
      // Add 1 year
      fechaEfectoNatural1YearMore.setFullYear(fechaEfectoNatural1YearMore.getFullYear() + 1);

      if (dueDate.getTime() > fechaEfectoNatural1YearMore.getTime()) {
        // INFO error: 8417
        validationsErrors.push(
          new EAValidationError(`${Vue.prototype.$t('policyMaintenanceFlow.supplements.validation.8417')}`)
        );
      }
    }

    if (dueDate.getTime() < fechaEfectoNatural.getTime()) {
      // INFO error: 8428
      validationsErrors.push(
        new EAValidationError(`${Vue.prototype.$t('policyMaintenanceFlow.supplements.validation.8428')}`)
      );
    }

    if (effectDate && effectDate.getTime() > dueDate.getTime()) {
      // INFO error: 7115
      validationsErrors.push(
        new EAValidationError(`${Vue.prototype.$t('policyMaintenanceFlow.supplements.validation.7115')}`)
      );
    }

    if (effectDate && isExpiration === true &&
      effectDate.toLocaleDateString() !== dueDate.toLocaleDateString()) {
      // INFO error expirationSameDate
      validationsErrors.push(
        new EAValidationError(`${Vue.prototype.$t('policyMaintenanceFlow.supplements.validation.expirationSameDate')}`)
      );
    }

    return validationsErrors;

  }
}
