<!-- eslint-disable max-lines -->
<template>
  <div>
    <qb-offer-issuance-data id="offerIssuanceData" v-model="model.offerIssuanceDataModel"></qb-offer-issuance-data>

    <ea-card>
      <div slot="cardbody" class="p-a-24">
        <qb-offer-issuance-bonus-data-form
          id="offerIssuanceBonusDataForm"
          v-model="model.offerIssuanceBonusDataFormModel"
          @resetOfferIssuance="resetOfferIssuance()"
        ></qb-offer-issuance-bonus-data-form>

        <ea-row class="m-b-24" v-if="requoteButtonVisible">
          <ea-col :span="6">
            <ea-button type="warning" size="medium" @click="onRequote()">
              {{ $t('warranties.pricing') }}
            </ea-button>
          </ea-col>
        </ea-row>

        <qb-offer-issuance-billing-data-form v-if="showPaymentChannel"
          id="offerIssuanceBillingDataForm"
          :showSuccessiveData="showSuccessiveData"
          v-model="model.offerIssuanceBillingDataFormModel"
          @resetOfferIssuance="resetOfferIssuance()"
        ></qb-offer-issuance-billing-data-form>
      </div>
    </ea-card>

    <qb-persons
      v-model="personsModel"
      id="qb-persons"
      ref="personsComp"
      :codigoRamo="productFactory.codigoRamo"
      :taxTreatmentAreaTable="model.taxTreatmentAreaTable"
      :indicadorDataQuality="model.datosCabecera.indicadorDataQuality"
      :documentTypeList="model.documentTypeList"
      :countryList="model.countryList"
      :consultaOperation="consultaOperation"
      :isIBANVisible="isIBANVisible"
      :showNationalitySelector="true"
      :allowedRoles="allowedRoles"
      :paymentChannelIsBank="paymentChannelIsBank"
      :axesorSearchDocumentTypes="productFactory.axesorSearchDocumentTypes"
      @showError="showError"
      @handleGenericError="handleGenericError"
    ></qb-persons>

    <qb-offer-issuance-additional-data
      v-if="model.offerIssuanceAdditionalDataFormModel.additionalData.length"
      id="offerIssuanceAdditionalDataForm"
      v-model="model.offerIssuanceAdditionalDataFormModel"
      :cessionRights="model.cessionRights"
      :loanNumber="model.loanNumber"
    ></qb-offer-issuance-additional-data>

    <div class="d-display-flex d-justify-space-between m-t-16">

      <div class="d-display-flex d-align-items-center">
        <ea-button type="secondary" @click="onGoBack" size="medium">
          {{ $t('common.label.back') }}
        </ea-button>
      </div>

      <div class="d-display-flex d-align-items-center">
        <qb-proficiency-management
          class="m-r-24"
          id="qb-proficiency-management"
          v-model="model.proficiencyManagementModel"
          ref="proficiencyManagementComp"
          :checkGestionCompetencias="model.pendingCompetenceManagement"
          :proficiencyManagementList="proficiencyManagementList"
          :productFactory="productFactory"
          @retrieveProficiencyManagementData="refreshProficiencyManagementData"
          @close="closeProficiencyManagementDialog"
        ></qb-proficiency-management>
        <ea-button @click="onGenerateDocumentation" type="secondary" size="medium" class="m-r-24">
          {{ $t('quoteBuyGenericFlow.offerIssuanceData.generateDocumentation') }}
        </ea-button>
        <ea-button @click="onSaveEmission" type="primary" size="medium" v-if="model.pendingCompetenceManagement">
          {{ $t('quoteBuyGenericFlow.offerIssuanceData.requestAuthorizationBtn') }}
        </ea-button>
        <ea-button @click="onSaveEmission" type="primary" size="medium" v-if="!model.pendingCompetenceManagement">
          {{ $t('quoteBuyGenericFlow.offerIssuanceData.emitBtn') }}
        </ea-button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
/* eslint-disable max-len */
import Vue from 'vue';
import QbAddressesPersonBusiness from '@/business-components/addresses/qb-addresses-person.vue';
import QbIbanCodeModel from '@/business-components/qb-iban-code/qb-iban-code-model';
import QbOfferIssuanceAdditionalDataBusiness from '@/business-components/qb-offer-issuance-additional-data/qb-offer-issuance-additional-data-business.vue';
import QbOfferIssuanceBillingDataFormBusiness from '@/business-components/qb-offer-issuance-billing-data-form/qb-offer-issuance-billing-data-form-business.vue';
import QbOfferIssuanceBonusDataFormBusiness from '@/business-components/qb-offer-issuance-bonus-data-form/qb-offer-issuance-bonus-data-form-business.vue';
import QbOfferIssuanceDataBusiness from '@/business-components/qb-offer-issuance-data/qb-offer-issuance-data-business.vue';
import QbProficiencyManagementBusiness from '@/business-components/qb-proficiency-management/qb-proficiency-management-business.vue';
import {
  GetCompetencesManagementByOfferResponseCompetencesManagementByOfferData, EAGetCompetencesManagementByOfferApi
} from '@/services/V1/quoteAndBuy/getCompetencesManagementByOfferOperation/post';
import {
  EAGetContractDataApi,
  GetContractDataResponse,
  GetContractDataResponseFormasPago,
} from '@/services/V1/quoteAndBuy/getContractDataOperation/post';
import {
  EAGetFractionalPaymentsApi,
  GetFractionalPaymentsRequestTipoFormaPagoTarificacionGarantiasEnum as FormasPagoEnum,
  GetFractionalPaymentsRequestTipoPolizaEnum,
  GetFractionalPaymentsResponse,
  GetFractionalPaymentsResponseDatosTarificacion,
} from '@/services/V1/quoteAndBuy/getFractionalPaymentsOperation/post/api';
import {
  GetPersonsResponseDatosPersona
} from '@/services/V1/quoteAndBuy/getPersonsOperation/post';
import {
  EASaveEmissionDataApi,
  SaveEmissionDataRequest,
  SaveEmissionDataRequestDatosPersonas,
  SaveEmissionDataRequestDatosPersonasRolClienteEnum,
  SaveEmissionDataRequestDatosPersonasTipoDocumentoEnum,
  SaveEmissionDataRequestDatosPersonasTipoPersonaEnum,
  SaveEmissionDataRequestTipoPolizaEnum,
  SaveEmissionDataResponse,
  Error
} from '@/services/V1/quoteAndBuy/saveEmissionDataOperation/post/api';
import {
  EASaveOfferGenerateDocApi,
  SaveOfferGenerateDocRequest,
  SaveOfferGenerateDocRequestDatosPersonas,
  SaveOfferGenerateDocRequestDatosPersonasRolClienteEnum,
  SaveOfferGenerateDocRequestTipoPolizaEnum,
  SaveOfferGenerateDocResponse,
} from '@/services/V1/quoteAndBuy/saveOfferGenerateDocOperation/post';
import {
  PolicyType
} from '@/types/policy-types/policy-types-enum.types';
import ProductBase from '@/utils/quote-buy-product-factory/products/product-base';
import Utils from '@/utils/utils';
import {
  EAApplicationLogger,
  EAMethod,
  EAMultiError,
  EAValidationError,
  EAView,
  ResponseWithErrors,
  throwIfResponseHasErrors,
  EAError
} from '@zurich-es-npm/ea-front-web-core';
import {
  mixins
} from 'vue-class-component';
import {
  Component, Prop, Watch
} from 'vue-property-decorator';
import {
  codeMessageFicoFic,
  FlowHeaderStepsModel,
  FlowViewsStepsModel,
  QuoteBuyModel,
} from '../quote-buy-model';
import {
  OfferPolicyUtils
} from '@/utils/offer-policy-utils';
import {
  GenericErrorData
} from '@/business-components/qb-generic-error/qb-generic-error-business.vue';
import {
  GetGeneralDataResponseDataDatosObjeto,
  GetGeneralDataRequestTipoOperacionOfertaEnum as OperationType
} from '@/services/V1/quoteAndBuy/getGeneralDataOperation/post';
import {
  SubflowThunderNameEnum
} from '@/types/flow/flow-enum.types';
import {
  NotificationsTypeEnum, NotificationsUtils
} from '@/utils/notifications/notifications-utils';
import {
  Roles
} from '@/types/roles/roles-enum.types';
import PersonUtils from '@/utils/person-utils';
import QbPersonsBusiness from '@/business-components/qb-persons/qb-persons-business.vue';
import QbPersonsModel from '@/business-components/qb-persons/qb-persons-model';
import {
  SaveOfferDataRequestDataQuality
} from '@/services/V1/quoteAndBuy/saveOfferDataOperation/post';
import {
  GetPersonAddressesResponseDomicilios, GetPersonAddressesResponseTelefonos
} from '@/services/V1/persons/getPersonAddressesOperation/post';
import {
  PersonRole
} from '@/utils/quote-buy-product-factory/types/product-role-types';
import {
  CorpTableNames, ParsedTableData, fetchCorporateTable, parseCorpTableDocuments
} from '@/utils/corporate-tables';

@Component({
  components: {
    QbOfferIssuanceData: QbOfferIssuanceDataBusiness,
    QbOfferIssuanceBillingDataForm: QbOfferIssuanceBillingDataFormBusiness,
    QbOfferIssuanceBonusDataForm: QbOfferIssuanceBonusDataFormBusiness,
    QbOfferIssuanceAdditionalData: QbOfferIssuanceAdditionalDataBusiness,
    QbPersons: QbPersonsBusiness,
    QbAddressesPerson: QbAddressesPersonBusiness,
    QbProficiencyManagement: QbProficiencyManagementBusiness
  },
})

/**
 * OfferIssuanceData view
 *
 */
export default class OfferIssuanceDataView extends mixins<EAView<QuoteBuyModel>>(EAView) {
  @Prop({
    required: true,
  })
    productFactory!: ProductBase;

  @Prop({
    required: true,
  })
    hasTestAbility!: boolean;

  isIBANVisible: boolean = false;

  requoteButtonVisible: boolean = false;

  emisionData = true;

  isInitialLoadingFinish = false;
  
  proficiencyManagementList?: GetCompetencesManagementByOfferResponseCompetencesManagementByOfferData[] = [];

  existAddressData: boolean = false;

  addressesVisibility: boolean = false;

  personsModel: QbPersonsModel = new QbPersonsModel();

  allowedRoles: Roles[] = [Roles.Tomador, Roles.Asegurado, Roles.Pagador];

  corpTableDocumentsAddressType: ParsedTableData[] = [];

  /**
   * Checks if the operation type is of type maintenence
   */
  get consultaOperation() {
    return this.model.operationType === OperationType.CONSULTA;
  }

  /**
   * Checks if the payment channel is bank to determine if the iban must be shown o not
   */
  get paymentChannelIsBank(): boolean {
    const paymentChannelFirstReceiptBank =
      this.model.offerIssuanceBillingDataFormModel.paymentChannelFirstReceipt.toUpperCase() === 'BC';
    const paymentChannelSuccessiveReceiptsBank =
      this.model.offerIssuanceBillingDataFormModel.paymentChannelSuccessiveReceipts.toUpperCase() === 'BC';
    return paymentChannelFirstReceiptBank || paymentChannelSuccessiveReceiptsBank;
  }

  /**
   * Returns if payment channel must be shown.
   * This is, user does not have ONLY 'bank charge' in payment channel.
   */
  get showPaymentChannel(): boolean {
    return !OfferPolicyUtils.userHasOnlyBankCharge();
  }
  
  /**
   * Not show Success data in case of Unica paymentForm
   */
  get showSuccessiveData(): boolean {
    // eslint-disable-next-line require-jsdoc
    return this.model.offerIssuanceBonusDataFormModel.paymentForm !== FormasPagoEnum.Unica &&
    this.model.offerIssuanceBonusDataFormModel.paymentForms.length > 1;
  }

  /**
   * Hook on created
   */
  @EAMethod({
    loading: true,
  })
  async created() {
    this.resetInfoMessage();
    await this.setOfferInsuranceData();
    this.changeTomadorAseguradoDisabledStatus(true);
    await this.getCorpTablesData();

    this.personsModel.personRoles = this.productFactory.personRoles;

    await this.$nextTick(); // Await for persons component to update personRoles
    const personsComp: QbPersonsBusiness = this.$refs.personsComp as QbPersonsBusiness;
    await personsComp.fetchPersonIbanAddresses();
    const pagador = this.personsModel.personRoles.find(person => person.role === Roles.Pagador);
    const pagadorPreviouslySelectedIbanCode = pagador?.ibanCodeModel.qbIbanCodeModalModel.selectedIbanCode;

    this.model.offerIssuanceDataModel.offerIssuanceData =
      this.productFactory.getOfferIssuanceViewLabels(this.model, this.corpTableDocumentsAddressType);
    this.model.offerIssuanceDataModel.productTranslationLabel = this.productFactory.productTranslationLabel;

    const ibanCodeModel = new QbIbanCodeModel();

    if (pagadorPreviouslySelectedIbanCode && pagadorPreviouslySelectedIbanCode !== '') {
    // Restore previous selected iban code if we had one
      ibanCodeModel.qbIbanCodeModalModel.selectedIbanCode = pagadorPreviouslySelectedIbanCode;
    } else {
      const ibanCodeElement = this.getPolicyElementByCode('CDIBANUM');
      if (ibanCodeElement) {
        ibanCodeModel.qbIbanCodeModalModel.selectedIbanCode = ibanCodeElement?.valorElemento || '';
      }
    }
    
    this.showFicoFicWarning();
    this.showCompetenceWarning();

    this.isInitialLoadingFinish = true;
    this.paymentChannelChange();

    // We added this because pagador's  iban list was empty at the end of page loading
    if (pagador) {
      personsComp.updateSelectableIban(pagador);
    }
  }

  /**
   * Fetch corporate tables to retrieve addreess type data.
   */
  @EAMethod({
    loading: true,
  })
  async getCorpTablesData(): Promise<void> {
    try {
      const result = await fetchCorporateTable(CorpTableNames.AddressType);
      this.corpTableDocumentsAddressType = parseCorpTableDocuments(result);
    } catch (error) {
      const eaError = error as EAError;
      new EAApplicationLogger().error(
        `GenerateDocumentationView::getCorpTablesData:: fetch corporate table :: ${eaError.message}`
      );
      throw error;
    }
  }

  /**
   * Get payment form descriptive name from payment form code
   * if it exists in valid options array
   *
   * @param {string} paymentFormCode
   * @param {validPaymentForms} validPaymentForms
   *
   * @returns {FormasPagoEnum | undefined}
   */
  getPaymentFormNameFromCode(
    paymentFormCode?: string,
    validPaymentForms?: GetContractDataResponseFormasPago[]
  ): FormasPagoEnum {
    let paymentFormValue: FormasPagoEnum = FormasPagoEnum.Anual; //Default Value

    if (paymentFormCode && validPaymentForms && validPaymentForms.length > 0) {
      const foundPaymentForm = validPaymentForms.find(
        validPaymentForm => validPaymentForm.valorRestriccion === paymentFormCode
      );

      if (foundPaymentForm?.nombreRestriccion) {
        paymentFormValue = foundPaymentForm.nombreRestriccion as FormasPagoEnum;
      }
    }
    return paymentFormValue;
  }

  /**
   *
   */
  async setOfferInsuranceData(): Promise<void> {
    const data = await this.fetchOfferIssuanceData();

    // OfferIssuanceDataModel
    this.model.generalDataInformationModel.movementDue = data?.datosCabecera?.fechaVencimiento ?
      new Date(data?.datosCabecera?.fechaVencimiento) :
      this.model.generalDataInformationModel.movementDue;
    this.model.offerIssuanceDataModel.offerIssuanceData =
      this.productFactory.getOfferIssuanceViewLabels(this.model, this.corpTableDocumentsAddressType);

    // OfferIssuanceBonusDataFormModel
    this.model.offerIssuanceBonusDataFormModel.price = data?.importeTotalRecibo || 0;
    this.model.offerIssuanceBonusDataFormModel.firstReceipt = data?.importePrimerRecibo || 0;
    this.model.offerIssuanceBonusDataFormModel.successiveReceipts = data?.importeRecibosSucesivos || 0;
    this.model.offerIssuanceBonusDataFormModel.paymentForm = this.getPaymentFormNameFromCode(
      data?.formaPago,
      data?.formasPago
    );
    this.model.offerIssuanceBonusDataFormModel.paymentForms = data?.formasPago || [];

    // OfferIssuanceBillingDataFormMode

    // Payment Channel
    this.model.offerIssuanceBillingDataFormModel.paymentChannelFirstReceipt = data?.tipoCanalCobro1Recibo || '';
    this.model.offerIssuanceBillingDataFormModel.paymentChannelSuccessiveReceipts = data?.tipoCanalCobroSucesivos || '';

    this.model.offerIssuanceBillingDataFormModel.selectablePaymentChannelsFirstReceiptList =
      data?.listaCanalesCobro1Recibo || [];
    this.model.offerIssuanceBillingDataFormModel.selectablePaymentChannelsSuccessiveReceiptsList =
      data?.listaCanalesCobroSucesivos || [];

    // Debt collect intermediaries
    const debtCollectorIntermediaryFirstReceiptList =
      data?.listaCanalesCobro1Recibo?.find(canalCobro => canalCobro.tipoCanalCobro === data?.tipoCanalCobro1Recibo)
        ?.listaIntermediarios || [];
    const debtCollectorIntermediarySuccessiveReceiptsList =
      data?.listaCanalesCobroSucesivos?.find(
        canalCobro => canalCobro.tipoCanalCobro === data?.tipoCanalCobroSucesivos
      )?.listaIntermediarios || [];

    this.model.offerIssuanceBillingDataFormModel.debtCollectorIntermediaryFirstReceiptList = debtCollectorIntermediaryFirstReceiptList;
    this.model.offerIssuanceBillingDataFormModel.debtCollectorIntermediarySuccessiveReceiptsList = debtCollectorIntermediarySuccessiveReceiptsList;

    this.model.offerIssuanceBillingDataFormModel.debtCollectorIntermediaryFirstReceipt =
      data?.intermediarioCobrador1Recibo || '';
    this.model.offerIssuanceBillingDataFormModel.debtCollectorIntermediarySuccessiveReceipts =
      data?.intermediarioCobradorSucesivos || '';

    this.model.offerIssuanceBillingDataFormModel.producerIntermediaryCode =
      this.model.datosCabecera.datosPoliza?.codigoIntermediario || '';
  }

  /**
   * Method run in created() hook
   *
   * @returns {Promise<GetContractDataResponse | undefined>}
   */
  @EAMethod({
    loading: true,
  })
  async fetchOfferIssuanceData(): Promise<GetContractDataResponse | undefined> {
    const api = new EAGetContractDataApi();
    const output = await api.getContractDataOperation({
      getContractDataRequest: {
        codigoPoliza: this.model.offerNumber,
        versionPoliza: this.model.offerVersion
      },
    });
    if (output) {
      throwIfResponseHasErrors(output as ResponseWithErrors);
      return output;
    }
  }

  /**
   * Requote proposal. Then refresh prices.
   */
  public async onRequote() {
    const data = await this.requoteProposal();
    throwIfResponseHasErrors(data as ResponseWithErrors);
    if (data?.datosTarificacion) {
      const paymentForm = this.model.offerIssuanceBonusDataFormModel.paymentForm;
      const pricingData = this.getPricingDataByFormPayment(data, paymentForm);
      if (pricingData && pricingData.noPrimaMinima) {
        this.model.offerIssuanceBonusDataFormModel.noMinimumInsurance = true;
      } else {
        this.model.offerIssuanceBonusDataFormModel.noMinimumInsurance = false;
        this.model.offerIssuanceBonusDataFormModel.price = pricingData?.importeTotalRecibo;
        this.model.offerIssuanceBonusDataFormModel.firstReceipt = pricingData?.importePrimerRecibo;
        this.model.offerIssuanceBonusDataFormModel.successiveReceipts = pricingData?.importeRecibosSucesivos;
        this.requoteButtonVisible = false;
      }
    }
  }
  
  /**
   * Emits showError event
   * @param {GenericErrorData} genericErrorData
   */
  showError(genericErrorData: GenericErrorData) {
    this.$emit('showError', genericErrorData);
  }

  /**
   * Get Price Data from response by FormPayment
   *
   * @param {GetFractionalPaymentsResponse} data
   * @param {FormasPagoEnum} formPayment
   *
   * @returns {number}
   */
  getPricingDataByFormPayment(
    data: GetFractionalPaymentsResponse,
    formPayment: FormasPagoEnum
  ): GetFractionalPaymentsResponseDatosTarificacion | undefined {
    return data.datosTarificacion?.find(
      pricing => pricing.formaPagoTarificacion && pricing.formaPagoTarificacion.toString() === formPayment.toString()
    );
  }

  /**
   * Save and requote proposals call.
   */
  @EAMethod({
    loading: true,
  })
  async requoteProposal(): Promise<GetFractionalPaymentsResponse | null> {
    this.clearFlowErrors();
    
    const api = new EAGetFractionalPaymentsApi();
    const response = await api.getFractionalPaymentsOperation({
      getFractionalPaymentsRequest: {
        codigoPoliza: this.model.offerNumber,
        versionPoliza: this.model.offerVersion,
        tipoPoliza: GetFractionalPaymentsRequestTipoPolizaEnum.Incompleta,
        tipoFormaPagoTarificacionGarantias: this.model.offerIssuanceBonusDataFormModel.paymentForm,
        codigosPropuesta: [
          {
            codigoPropuesta: this.model.selectedProposal,
          },
        ],
        tipoCanalCobro1Recibo: this.model.offerIssuanceBillingDataFormModel.paymentChannelFirstReceipt,
        tipoCanalCobroSucesivos: this.model.offerIssuanceBillingDataFormModel.paymentChannelSuccessiveReceipts,
        intermediarioCobrador1Recibo: this.model.offerIssuanceBillingDataFormModel
          .debtCollectorIntermediaryFirstReceipt,
        intermediarioCobradorSucesivos: this.model.offerIssuanceBillingDataFormModel
          .debtCollectorIntermediarySuccessiveReceipts,
      },
    });
    return response;
  }

  /**
   * Show requote button and clean prices
   *
   */
  resetOfferIssuance(): void {
    this.requoteButtonVisible = true;
    this.model.offerIssuanceBonusDataFormModel.noMinimumInsurance = false;
    this.model.offerIssuanceBonusDataFormModel.price = undefined;
    this.model.offerIssuanceBonusDataFormModel.firstReceipt = undefined;
    this.model.offerIssuanceBonusDataFormModel.successiveReceipts = undefined;
  }

  /**
   * Go back button handler
   */
  onGoBack() {
    this.clearFlowErrors();
    
    const asegurado = this.productFactory.personRoles.find(
      tabItem => tabItem.role === Roles.Asegurado.toLocaleLowerCase()
    );
    if (asegurado) {
      asegurado.roleEqualStatements?.forEach(equal => {
        equal.readonly = undefined;
      });
    }

    // ### UPDATE FLOW MODEL AS PROFICIENCY MGMT MODAL MAY REMAIN OPEN
    this.update();

    this.$emit('changeStep', FlowViewsStepsModel.WarrantiesStep, FlowHeaderStepsModel.WarrantiesStep);
  }

  /**
   * Go back button handler
   */
  async onSaveEmission() {
    this.clearFlowErrors();
    NotificationsUtils.clearNotifications();
    await this.areDataBlocksValid();

    await this.saveEmissionData(true, () => {
      this.model.offerType = PolicyType.Incompleta;
      if (!this.model.pendingIPIDDocumentation) {
        if (this.model.pendingCompetenceManagement) {
          this.$emit('openSubflow', SubflowThunderNameEnum.CompetencesFlow);
        } else {
          this.onGoToNextStep(FlowViewsStepsModel.IssueOfferStep, FlowHeaderStepsModel.IssueOfferStep);
        }
      }
    });
  }

  /**
   * Generate documentation button handler
   * Saves data and goes no next step
   */
  public async onGenerateDocumentation() {
    this.clearFlowErrors();

    await this.validateUpdatePersonsComponent();

    // ### Await for nationality field to be updated ###
    await this.$nextTick();

    this.productFactory.personRoles = this.personsModel.personRoles;

    const selectedPersonTomador = PersonUtils.getPersonDataByRole(this.personsModel.personRoles, Roles.Tomador);
    const selectedPersonAsegurado = PersonUtils.getPersonDataByRole(this.personsModel.personRoles, Roles.Asegurado);
    const selectedPersonPagador = PersonUtils.getPersonDataByRole(this.personsModel.personRoles, Roles.Pagador);

    this.update();

    let addressesTomador;
    for (const person of this.personsModel.personRoles) {
      if (person.role === Roles.Tomador) {
        addressesTomador = person.addressesModel;
      }
    }

    const elementosPoliza = this.productFactory.generatePolicyData(
      this.model,
      selectedPersonPagador.datosBasicosPersona?.codigoFiliacion
    );

    const dataQuality = this.getDataQualityToSave();

    //Modificar data-dictionary para añadir el codigoSecuencialDomicilio a SaveOfferGenerateDocRequest
    const saveOfferGenerateDocRequest: SaveOfferGenerateDocRequest = {
      codigoPoliza: this.model.offerNumber,
      versionPoliza: this.model.offerVersion,
      tipoPoliza: SaveOfferGenerateDocRequestTipoPolizaEnum.Oferta,
      codigoSecuencialDomicilio: addressesTomador?.addEditAddress.selectedAddressCode,
      datosPersonas: [
        this.getPersonFieldsToSave(
          selectedPersonTomador,
          SaveOfferGenerateDocRequestDatosPersonasRolClienteEnum.Tomador
        ) as SaveOfferGenerateDocRequestDatosPersonas,
        this.getPersonFieldsToSave(
          selectedPersonAsegurado,
          SaveOfferGenerateDocRequestDatosPersonasRolClienteEnum.Asegurado
        ) as SaveOfferGenerateDocRequestDatosPersonas,
        this.getPersonFieldsToSave(
          selectedPersonPagador,
          SaveOfferGenerateDocRequestDatosPersonasRolClienteEnum.Pagador
        ) as SaveOfferGenerateDocRequestDatosPersonas,
      ],
      elementosPoliza,
      dataQuality
    };

    saveOfferGenerateDocRequest.guardarDatosObjeto = true;

    this.addObjectElements(saveOfferGenerateDocRequest);
  
    const resp = await this.callSaveOfferGenerateDocBff(saveOfferGenerateDocRequest);

    if (resp) {
      if (resp.errors) {
        // Clear the FICO - FIC warnigns
        const errorsWithoutFICO = resp.errors.filter(err => err.severity >= 4);
        if (errorsWithoutFICO.length > 0) {
          const multiError = new EAMultiError();
          errorsWithoutFICO.forEach(
            (error: { message: string }) => multiError.push(new EAValidationError(error.message)));
          throw multiError;
        }
        this.onGoToNextStep(FlowViewsStepsModel.GenerateDocumentationStep, FlowHeaderStepsModel.OfferIssuanceDataStep);
      } else {
        this.onGoToNextStep(FlowViewsStepsModel.GenerateDocumentationStep, FlowHeaderStepsModel.OfferIssuanceDataStep);
      }
    }
  }

  /**
   * Validates persons existance
   * Throws error if any person is missing
   */
  validatePersonsExistance() {
    PersonUtils.getPersonDataByRole(this.personsModel.personRoles, Roles.Tomador);
    PersonUtils.getPersonDataByRole(this.personsModel.personRoles, Roles.Asegurado);
    PersonUtils.getPersonDataByRole(this.personsModel.personRoles, Roles.Pagador);
  }

  /**
   * Saves prices and emits change step event
   */
  async areDataBlocksValid() {
    const policyElements = this.model.datosCabecera?.elementosPoliza;
    // ### Validate Canal Cobro ###
    const canalCobro = policyElements?.find(elemento => elemento.codigoElemento==='TCCANCO1');
    if (canalCobro) {
      canalCobro.valorElemento = this.model.offerIssuanceBillingDataFormModel.paymentChannelSuccessiveReceipts;
    }

    const canalCobroS = policyElements?.find(elemento => elemento.codigoElemento==='TCCANCOS');
    if (canalCobroS) {
      canalCobroS.valorElemento = this.model.offerIssuanceBillingDataFormModel.paymentChannelSuccessiveReceipts;
    }

    let errors: EAValidationError[] = [];
    if (this.requoteButtonVisible) {
      errors.push(
        new EAValidationError(this.$t('quoteBuyGenericFlow.error.requoteRequired').toString())
      );
    }

    // ### Validate persons ###
    await this.validateUpdatePersonsComponent();
    this.validatePersonsExistance();

    // ### Validate addresses ###
    const personsComp: QbPersonsBusiness = this.$refs.personsComp as QbPersonsBusiness;
    errors = errors.concat(
      personsComp.validateSelectedAddresses()
    );

    // ### Validate nationality ###
    errors = errors.concat(
      await personsComp.validateSelectedNationality()
    );

    // ### Validate selected IBAN code ###
    const ibanCodeModel = PersonUtils.getPersonByRole(this.personsModel.personRoles, Roles.Pagador)?.ibanCodeModel;

    if (this.paymentChannelIsBank && this.isIBANVisible && !ibanCodeModel?.qbIbanCodeModalModel.selectedIbanCode) {
      errors.push(
        new EAValidationError(this.$t('quoteBuyGenericFlow.error.ibanRequired').toString())
      );
    }

    if (errors.length) {
      const multiError = new EAMultiError();
      errors.forEach(error => multiError.push(new EAValidationError(error.message)));
      throw multiError;
    }
  }

  /**
   * Saves prices and emits change step event
   * @param {FlowViewsStepsModel} step
   * @param {FlowHeaderStepsModel} headerStep
   * @param {boolean | undefined} clearError
   */
  onGoToNextStep(step: FlowViewsStepsModel, headerStep: FlowHeaderStepsModel) {
    NotificationsUtils.clearNotifications();
    this.changeTomadorAseguradoDisabledStatus(false); // Restore persons tabs
    this.$emit('changeStep', step, headerStep);
  }

  /**
   * Handles continue button
   * @param {boolean} doSaveObjetoAsegurado - flag to indicate if we want to save objeto asegurado data
   * @param {Function} callbackIfOk - Function to be called if service call returns OK result
   */
  @EAMethod({
    loading: true,
  })
  async saveEmissionData(doSaveObjetoAsegurado: boolean, callbackIfOk: Function) {
    this.clearFlowErrors();

    await this.validateUpdatePersonsComponent();

    // ### Await for nationality field to be updated ###
    await this.$nextTick();

    this.productFactory.personRoles = this.personsModel.personRoles;

    const selectedPersonTomador = PersonUtils.getPersonDataByRole(this.personsModel.personRoles, Roles.Tomador);
    const selectedPersonAsegurado = PersonUtils.getPersonDataByRole(this.personsModel.personRoles, Roles.Asegurado);
    const selectedPersonPagador = PersonUtils.getPersonDataByRole(this.personsModel.personRoles, Roles.Pagador);

    let addressesTomador;
    for (const person of this.personsModel.personRoles) {
      if (person.role === Roles.Tomador) {
        addressesTomador = person.addressesModel;
      }
    }

    const elementosPoliza = this.productFactory.generatePolicyData(
      this.model,
      selectedPersonPagador.datosBasicosPersona?.codigoFiliacion
    );

    this.update();

    const dataQuality = this.getDataQualityToSave();

    const saveEmissionDataRequest: SaveEmissionDataRequest = {
      codigoPoliza: this.model.offerNumber,
      versionPoliza: this.model.offerVersion,
      tipoPoliza: this.model.offerType as unknown as SaveEmissionDataRequestTipoPolizaEnum,
      codigoSecuencialDomicilio: addressesTomador?.addEditAddress.selectedAddressCode || '',
      datosPersonas: [
        this.getPersonFieldsToSave(
          selectedPersonTomador,
          SaveEmissionDataRequestDatosPersonasRolClienteEnum.Tomador,
          this.productFactory.getSecuentialAddressCode(
            Roles.Tomador
          )
        ),
        this.getPersonFieldsToSave(
          selectedPersonAsegurado,
          SaveEmissionDataRequestDatosPersonasRolClienteEnum.Asegurado,
          this.productFactory.getSecuentialAddressCode(
            Roles.Asegurado
          )
        ),
        this.getPersonFieldsToSave(
          selectedPersonPagador,
          SaveEmissionDataRequestDatosPersonasRolClienteEnum.Pagador,
          this.productFactory.getSecuentialAddressCode(
            Roles.Pagador
          )
        ),
      ],
      elementosPoliza,
      dataQuality
    };
    if (doSaveObjetoAsegurado) {
      saveEmissionDataRequest.guardarDatosObjeto = true;
      this.addObjectElements(saveEmissionDataRequest);
   
    }
    const resp = await this.callSaveEmissionDataBff(saveEmissionDataRequest);

    if (resp) {
      this.model.pendingIPIDDocumentation = false;
      this.manageSaveEmissionDataResponse(resp, callbackIfOk);
    }
    
  }

  /**
   * Gets the elements for the object to save
   * @param {SaveOfferGenerateDocRequest | SaveEmissionDataRequest} request
   * 
   */
  addObjectElements(request: SaveOfferGenerateDocRequest | SaveEmissionDataRequest) {
    request.elementosObjeto = [];
    const cessionRights = this.model.offerIssuanceAdditionalDataFormModel.additionalData.find(
      elem => elem.codigoElemento === 'NOTITCES'
    );
    if (cessionRights) {
      request.elementosObjeto.push(
        {
          codigoElemento: 'NOTITCES',
          valorElemento: cessionRights.valorElemento,
        }
      );
    }

    const loanNumber = this.model.offerIssuanceAdditionalDataFormModel.additionalData.find(
      elem => elem.codigoElemento === 'NOCUECES'
    );

    if (loanNumber) {
      request.elementosObjeto.push(
        {
          codigoElemento: 'NOCUECES',
          valorElemento: Utils.parseNumericValueIfReceived(
            Number(loanNumber.valorElemento), 0
          ).toString()
        }
      );
    }

  }

  /**
   * Gets person fields in Bff format
   * @returns {SaveOfferDataRequestDatosPersonas}
   */
  getDataQualityToSave(
  ): SaveOfferDataRequestDataQuality {
    const tomador = this.personsModel.personRoles.find(person => person.role === Roles.Tomador);
    const selectedPhoneAddress = this.findTomadorSelectedPhoneAddress(tomador);
    return {
      codigoFiliacion: tomador?.searchModel.selectedPerson?.datosBasicosPersona?.codigoFiliacion,
      emailTomador: tomador?.addressesModel.addEditEmail.selectedEmail?.emailContacto,
      telefonoTomador: selectedPhoneAddress?.selectedPhone?.numeroTelefono,
      prefijoPais: selectedPhoneAddress?.selectedPhone?.prefijoPais,
      codigoSecuenciaDomicilioEmail: tomador?.addressesModel.addEditEmail.selectedEmail?.codigoSecuencialDomicilio,
      codigoSecuenciaDomicilioTelefono: selectedPhoneAddress?.phoneAddress.codigoSecuencialDomicilio,
      claseDomicilioEmail: tomador?.addressesModel.addEditEmail.selectedEmail?.claseDomicilio,
      claseDomicilioTelefono: selectedPhoneAddress?.phoneAddress.claseDomicilio
    };
  }

  /**
   * Finds Phone address for selected phone in dropdown
   * @param {PersonRole | undefined} tomador
   * @returns {Object} 
   */
  findTomadorSelectedPhoneAddress(tomador: PersonRole | undefined): {
    phoneAddress: GetPersonAddressesResponseDomicilios;
    selectedPhone: GetPersonAddressesResponseTelefonos | undefined;
  } | undefined {
    const selectedPhoneNumber = tomador?.addressesModel.addEditPhone.selectedPhoneNumber;
    const phoneList = tomador?.addressesModel.addEditPhone.phoneList;
    if (phoneList) {
      for (const phoneAddress of phoneList) {
        const selectedPhone =
          phoneAddress.telefonos?.find(phone => phone.numeroTelefono === selectedPhoneNumber);
        if (selectedPhone) {
          return {
            phoneAddress,
            selectedPhone
          };
        }
      }
    }
  }

  /**
   * Manage SaveEmissionData Response
   * @param {SaveEmissionDataResponse | null} resp
   * @param {Function} callbackIfOk - Function to be called if service call returns OK result
   */
  manageSaveEmissionDataResponse(resp: SaveEmissionDataResponse, callbackIfOk: Function) {
    if (resp.errors?.length) {
      const errors = this.getIpidError(resp.errors);
      
      const errorsWithoutFICO = errors.filter(err => err.severity >= 4);
      if (errorsWithoutFICO.length > 0) {
        const multiError = new EAMultiError();
        errorsWithoutFICO.forEach((error: { message: string }) => multiError.push(new EAValidationError(error.message)));
        throw multiError;
      }
      if (this.model.pendingIPIDDocumentation) {
        callbackIfOk();
      }
    } else if (resp.pendingCompetenceManagement) {
      this.model.pendingCompetenceManagement = true;
      callbackIfOk();
    } else {
      this.model.pendingCompetenceManagement = false;
      callbackIfOk();
    }
  }
  
  /**
   * Validates persons component's form
   * If validation OK => Updates model
   * If validation NOK => Throws EAValidationError
   */
  async validateUpdatePersonsComponent() {
    const personsComp: QbPersonsBusiness = this.$refs.personsComp as QbPersonsBusiness;
    await personsComp.validateUpdatePersonsComponent();
    this.update();
  }

  /**
   * Get ipid erros
   * @param {Error[]} errors
   * @return {Error[]}
   */
  getIpidError(errors: Error[]): Error[] {
    this.model.pendingIPIDDocumentation = false;
    const ipidError = errors.find(el => el.code === '0950' || el.code === '0951');
    if (ipidError) {
      const ipidErrorIndex = errors.indexOf(ipidError);
      this.model.pendingIPIDDocumentation = true;
      NotificationsUtils.launchNotifications([{
        title: NotificationsTypeEnum.Info,
        message: ipidError.message,
        type: NotificationsTypeEnum.Warning
      }]);
      errors.splice(ipidErrorIndex, 1);
    }
    return errors;
  }

  /**
   * @param {SaveEmissionDataRequest} saveEmissionDataRequest
   * @returns {Promise<SaveEmissionDataResponse | null>}
   */
  @EAMethod({
    loading: true,
  })
  async callSaveEmissionDataBff(
    saveEmissionDataRequest: SaveEmissionDataRequest
  ): Promise<SaveEmissionDataResponse | null> {
    const api = new EASaveEmissionDataApi();
    return api.saveEmissionDataOperation({
      saveEmissionDataRequest,
    });
  }

  /**
   * @param {SaveOfferGenerateDocRequest} saveOfferGenerateDocRequest
   * @returns {Promise<SaveOfferGenerateDocResponse | null>}
   */
  @EAMethod({
    loading: true,
  })
  async callSaveOfferGenerateDocBff(
    saveOfferGenerateDocRequest: SaveOfferGenerateDocRequest
  ): Promise<SaveOfferGenerateDocResponse | null> {
    const api = new EASaveOfferGenerateDocApi();
    const saveOfferGenDocResponse = await api.saveOfferGenerateDocOperation({
      saveOfferGenerateDocRequest
    });
    if (saveOfferGenDocResponse?.errors?.length) {
      const ipidError = saveOfferGenDocResponse.errors.find(el => el.code === '0950' || el.code === '0951');
      if (ipidError) {
        const ipidErrorIndex = saveOfferGenDocResponse.errors.indexOf(ipidError);
        this.model.pendingIPIDDocumentation = true;
        NotificationsUtils.launchNotifications([{
          title: NotificationsTypeEnum.Info,
          message: ipidError.message,
          type: NotificationsTypeEnum.Warning
        }]);
        saveOfferGenDocResponse.errors.splice(ipidErrorIndex, 1);
      }
    }
    return saveOfferGenDocResponse;
  }

  /**
   * Gets person fields in Bff format
   * @param {GetPersonsResponseDatosPersona} person
   * @param {SaveEmissionDataRequestDatosPersonasRolClienteEnum | SaveOfferGenerateDocRequestDatosPersonasRolClienteEnum} personRole
   * @param {string | undefined} codSecuenciaDomicilio
   * @returns {SaveEmissionDataRequestDatosPersonas}
   */
  getPersonFieldsToSave(
    person: GetPersonsResponseDatosPersona,
    personRole:
    | SaveEmissionDataRequestDatosPersonasRolClienteEnum
    | SaveOfferGenerateDocRequestDatosPersonasRolClienteEnum,
    codSecuenciaDomicilio?: string | undefined
  ): SaveEmissionDataRequestDatosPersonas {
    const tipoDocumento = (person?.datosBasicosPersona
      ?.tipoDocumento as unknown) as SaveEmissionDataRequestDatosPersonasTipoDocumentoEnum;
    const result = {
      rolCliente: personRole as SaveEmissionDataRequestDatosPersonasRolClienteEnum,
      codigoFiliacion: person?.datosBasicosPersona?.codigoFiliacion,
      tipoPersona: (Utils.getPersonType(person) as unknown) as SaveEmissionDataRequestDatosPersonasTipoPersonaEnum,
      codigoIdentificacionFiscal: person?.datosBasicosPersona?.codigoIdentificacionFiscal,
      primerApellido: person?.datosBasicosPersona?.primerApellido,
      segundoApellido: person?.datosBasicosPersona?.segundoApellido,
      nombrePropio: person?.datosBasicosPersona?.nombrePropio,
      tipoDocumento,
      indicadorFiscal: this.model.taxTreatmentArea,
      codigoSecuencialDomicilio: codSecuenciaDomicilio,
      nacionalidadPersona: tipoDocumento === SaveEmissionDataRequestDatosPersonasTipoDocumentoEnum.P || tipoDocumento === SaveEmissionDataRequestDatosPersonasTipoDocumentoEnum.R ?
        PersonUtils.getSelectedNationalityByRole(this.personsModel.personRoles, personRole) : 'ESP'
    };

    if (personRole === SaveEmissionDataRequestDatosPersonasRolClienteEnum.Tomador) {
      result.indicadorFiscal = this.model.taxTreatmentArea;
    }

    return result;
  }


  /**
   * Emits handleGenericError event
   * @param {any} args
   */
  handleGenericError(args: any) {
    const {
      error, errorCode
    } = args;
    this.$emit('handleGenericError', {
      error,
      errorCode,
    });
  }

  /**
   * Checks if the payment channel is bank to determine if the iban must be shown o not
   */
  @Watch('model.offerIssuanceBillingDataFormModel')
  paymentChannelChange() {
    if (!this.isInitialLoadingFinish) {
      return;
    }
  
    const pagador = PersonUtils.getPersonByRole(this.personsModel.personRoles, Roles.Pagador);

    if (pagador?.searchModel.selectedPerson?.datosBasicosPersona?.codigoFiliacion &&
    (this.model.offerIssuanceBillingDataFormModel.paymentChannelFirstReceipt === 'BC' ||
    this.model.offerIssuanceBillingDataFormModel.paymentChannelSuccessiveReceipts === 'BC')) {
      this.isIBANVisible = true;
    } else {
      this.isIBANVisible = false;
    }
  }

  /**
   * Closes proficiency management dialog
   */
  closeProficiencyManagementDialog() {
    const proficiencyManagementComp: QbProficiencyManagementBusiness =
      this.$refs.proficiencyManagementComp as QbProficiencyManagementBusiness;
    proficiencyManagementComp.hideModal();
    this.proficiencyManagementList = [];
  }

  /**
   * Refreshes Proficiency Management Data
   */
  async refreshProficiencyManagementData() {
    const data = await this.fetchProficiencyManagement();
    
    if (data) {
      this.proficiencyManagementList = data.filter(
        dataEl => dataEl.codigoPropuesta === this.model.selectedProposal || dataEl.codigoPropuesta === ''
      );
      Vue.nextTick(() => {
        this.model.pendingCompetenceManagement = true;
        this.model.proficiencyManagementModel.proposalNumbersOrder = [this.model.selectedProposal];
        const proficiencyManagementComp: QbProficiencyManagementBusiness =
        this.$refs.proficiencyManagementComp as QbProficiencyManagementBusiness;
        proficiencyManagementComp.showModal();
        this.showCompetenceWarning();
        this.showFicoFicWarning();
      });
    }
  }

  /**
   * Reset generalData info message
   */
  resetInfoMessage() {
    NotificationsUtils.clearNotifications();
  }

  /**
   * Show competences warning message
   */
  showCompetenceWarning() {
    if (this.model.pendingCompetenceManagement) {
      NotificationsUtils.launchNotifications([{
        title: 'Proficiency',
        message: this.$t('warranties.proficiencyWarningDescription').toString(),
        type: NotificationsTypeEnum.Warning
      }]);
    }
  }

  /**
   * Show fic - fico warning message
   */
  showFicoFicWarning() {
    if (this.model.ficoFicError.length > 0) {
      this.model.ficoFicError.forEach(err => {
        NotificationsUtils.launchNotifications([{
          title: NotificationsTypeEnum.Warning,
          message: this.$t(`warranties.${codeMessageFicoFic[err]}`).toString(),
          type: NotificationsTypeEnum.Warning
        }]);
      });
    }
  }

  /**
   * Fetches proficiency management list
   *
   * @returns {Promise<void>}
   */
  @EAMethod({
    loading: true,
  })
  async fetchProficiencyManagement():
  Promise<GetCompetencesManagementByOfferResponseCompetencesManagementByOfferData[]| undefined> {
    const api = new EAGetCompetencesManagementByOfferApi();
    const output = await api.getCompetencesManagementByOfferOperation({
      getCompetencesManagementByOfferRequest: {
        codigoPoliza: this.model.offerNumber,
        versionPoliza: this.model.offerVersion
      },
    });
    throwIfResponseHasErrors(output as ResponseWithErrors);
    if (output?.competencesManagementByOfferData) {
      return output.competencesManagementByOfferData;
    }
  }

  
  /**
   * Return policy element by code (Datos cabecera)
   * @param {string} code
   * @returns {GetGeneralDataResponseDataDatosCabeceraElementosPoliza  | undefined}
   */
  getPolicyElementByCode(code: string): GetGeneralDataResponseDataDatosObjeto | undefined {
    return this.model.datosCabecera.elementosPoliza?.find(element => element.codigoElemento === code);
  }

  /**
   * Getter that retrieves if person is disabled
   * @param {Roles} role
   * @returns {boolean}
   */
  isPersonDisabled(role: Roles): boolean {
    return role === Roles.Pagador ? false : this.consultaOperation;
  }

  /**
   * Changes "tomador" and "asegurado" persons disabled status + "role equal" statements
   * @param {boolean} disabledStatusValue
   */
  changeTomadorAseguradoDisabledStatus(disabledStatusValue: boolean) {

    /*
     * If Asegurado is not allowed in general-data must be set in offer-issuance
     * then readonly and disabled value must be false to can set any person
     * this case is for HOGAR
     */
    const isAllowedAtGeneralDataAsegurado = this.productFactory.allowedRolesAtGeneralDataView.find(person => person === Roles.Asegurado);

    const tomador = this.productFactory.personRoles.find(person => person.role === Roles.Tomador);
    if (tomador) {
      tomador.disabled = disabledStatusValue;
    }
    
    const asegurado = this.productFactory.personRoles.find(person => person.role === Roles.Asegurado);
    if (asegurado) {
      asegurado.disabled = disabledStatusValue;
      asegurado?.roleEqualStatements?.forEach(roleEqStatement => {
        roleEqStatement.disabled = disabledStatusValue;

        // To set the asegurado data in offer-issuance
        if (!isAllowedAtGeneralDataAsegurado) {
          roleEqStatement.readonly = false;
          roleEqStatement.disabled = false;
          asegurado.disabled = false;
        }
      });
    }
  }
}
</script>
