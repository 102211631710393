<template>
  <div>
    <div>
      <h4 class="m-b-16">{{ $t('quoteBuyGenericFlow.generateDocumentation.generateDocumentation') }}</h4>
      <div v-for="document of model.documentList" :key="document.nombreDocumento">
        <ea-checkbox
          class="m-b-16"
          :label="document.nombreDocumento"
          v-model="document.indicadorDocumentoMarcado"
          :checked="document.indicadorDocumentoMarcado"
          :disabled="document.indicadorDocumentoBloqueado"
          @change="updateEmailRequiredProp"
        >
        </ea-checkbox>
    </div>
    </div>

    <ea-row v-if="!hideMultipleEmailInput">
      <ea-col>
        <h4 class="m-b-16">
          {{$t('quoteBuyGenericFlow.generateDocumentation.sendDocumentation')}}
        </h4>

      </ea-col>
    </ea-row>

    <multiple-email-input
      id="multipleEmailInput"
      v-if="!hideMultipleEmailInput"
      v-model="model.multipleEmailInputModel"
      :isEmailRequired="emailRequired"
      ref="emailInputComp"
    ></multiple-email-input>

  </div>
</template>

<script lang="ts">
import {
  EABusinessComponent
} from '@zurich-es-npm/ea-front-web-core';
import {
  EAValidation
} from '@zurich-es-npm/ea-front-web-ui';
import {
  mixins
} from 'vue-class-component';
import {
  Component, Prop, Watch
} from 'vue-property-decorator';
import MultipleEmailInputBusiness from '../multiple-email-input/multiple-email-input-business.vue';
import QbDocumentationSelectDocumentationModel from './qb-documentation-select-documentation-model';

@Component({
  name: 'qb-documentation-select-documentation',
  components: {
    MultipleEmailInput: MultipleEmailInputBusiness
  }
})

/**
 * Business Component qb-documentation-select-documentation
 */
export default class QbDocumentationSelectDocumentationBusiness extends mixins<
EABusinessComponent<QbDocumentationSelectDocumentationModel>
>(EABusinessComponent) {

  @Prop({
    'default': () => false
  })
    hideMultipleEmailInput!: boolean;

  public emailRequired: boolean = false;

  /**
   * Returns form validation object.
   *
   * @returns {EAValidation} Form validation object
   */
  public validation(): EAValidation {
    const emailInputComp: MultipleEmailInputBusiness = this.$refs.emailInputComp as MultipleEmailInputBusiness;
    emailInputComp.update();
    return emailInputComp.validation();
  }

  /**
   * Watcher for documentList property
   * Updates required properties and validation options
   */
  @Watch('model.documentList', {
    immediate: true,
    deep: true,
  })
  onDocumentListChange() {
    this.updateEmailRequiredProp();
  }

  /**
   * Updates email required prperty
   * True if any document with indicadorEnvioMail equal to true is checked
   */
  updateEmailRequiredProp() {
    this.emailRequired = this.model.documentList.some(
      document => document.indicadorDocumentoMarcado && document.indicadorEnvioMail
    );
  }
}
</script>
