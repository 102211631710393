<template>
  <div>
    <h4 clas="m-b-24">{{$t('quoteBuyGenericFlow.selectDocumentationSendingAdress.title')}}</h4>

    <ea-form :model="model">
      <ea-form-item
        prop="radioBtnSelectedAddress"
        class="radiogroup-horizontal m-b-24"
      >
        <ea-radio-group direction="horizontal" v-model="model.radioBtnSelectedAddress">
          <ea-radio
            label="POLICY_TAKER_ADDRESS"
          >
            {{$t('quoteBuyGenericFlow.selectDocumentationSendingAdress.policyTakerAddress')}}
          </ea-radio>
          <ea-radio
            label="INTERMEDIARY_ADDRESS"
          >
            {{$t('quoteBuyGenericFlow.selectDocumentationSendingAdress.intermediaryAddress')}}
          </ea-radio>
        </ea-radio-group>
      </ea-form-item>
    </ea-form>
    

    <!-- Policy taker address selection -->
    <div v-if="model.radioBtnSelectedAddress === 'POLICY_TAKER_ADDRESS'">
      <ea-select
        :placeholder="$t('common.placeholder.selectAddress')"
        v-model="model.policyTakerSelectedAddressCode"
        id="address-sel"
        class="m-b-32"
      >
        <ea-option
          v-for="address in model.policyTakerAddressList"
          :key="address.codigoSecuencialDomicilio"
          :label="getPostalLabel(address)"
          :value="address.codigoSecuencialDomicilio"
        />
      </ea-select>
    </div>
    
    <!-- Show intermediary address -->
    <div v-if="model.radioBtnSelectedAddress === 'INTERMEDIARY_ADDRESS'">
      <p>
        <span class="t-weight-bold">
          {{ $t('quoteBuyGenericFlow.selectDocumentationSendingAdress.fields.address') }}:
        </span>{{model.intermediaryAddress}}
      </p>
    </div>
  </div>
</template>

<script lang="ts">
import {
  Component, Prop
} from 'vue-property-decorator';

import {
  mixins
} from 'vue-class-component';

import {
  EABusinessComponent
} from '@zurich-es-npm/ea-front-web-core';
import QbSelectDocumentationSendingAddressModel from './qb-select-documentation-sending-address-model';
import {
  GetPersonAddressesResponseDomicilios
} from '@/services/V1/persons/getPersonAddressesOperation/post';
import {
  AddressUtils
} from '@/utils/address-utils';
import {
  ParsedTableData
} from '@/utils/corporate-tables';
import AddAddressTomadorBusiness from '../addresses/modals/add-address-tomador/add-address-tomador-business.vue';

@Component({
  name: 'qb-select-documentation-sending-address',
  components: {
    AddAddressTomador: AddAddressTomadorBusiness,
  },
})

/**
 * Business Component qb-select-documentation-sending-address
 */
export default class QbSelectDocumentationSendingAddressBusiness extends mixins<
EABusinessComponent<QbSelectDocumentationSendingAddressModel>
>(EABusinessComponent) {
  
  @Prop({
    'default': () => [],
  })
  public corpTableDocumentsAddressType?: ParsedTableData[];

  /**
   * Parses postal label to display format
   * @param {GetPersonAddressesResponseDomicilios} address
   * @returns {string}
   */
  public getPostalLabel(address: GetPersonAddressesResponseDomicilios): string {
    return AddressUtils.getPostalLabel(address, this.corpTableDocumentsAddressType || []);
  }

}
</script>


<style lang="scss" scoped>
/*NO SE HA PODIDO QUITAR LA CLASE POR NO SABER LLEGAR A LA PANTALLA */
::v-deep .radiogroup-horizontal .el-form-item__content {
  display: flex;
  flex-direction: row;
  align-items: center;

  label {
    margin-bottom: 0;
  }

  .el-radio-group label {
    display: inline-block !important;
  }
}
</style>
