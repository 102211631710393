<template>
  <div>
    <ea-form ref="form" :model="model" :validationOptions="validationOptions">
      <h4>{{ $t('quoteBuyGenericFlow.premiumAdjustments.title') }}</h4>

      <ea-row class="m-b-16" v-if="model.multiProductDiscount">
        <ea-col :span="16">
          <span class="t-weight-normal t-size-small">
            {{ $t('quoteBuyGenericFlow.premiumAdjustments.dtoMultiProPo') }}
          </span>
        </ea-col>
        <ea-col :span="8">
          <span class="t-weight-normal t-size-small">
            {{ model.multiProductDiscount + '%' }}
          </span>
        </ea-col>
      </ea-row>
      
      <ea-row v-if="model.campaignName">
        <ea-col :span="16">
          <span class="t-weight-normal t-size-small">
            {{ $t('quoteBuyGenericFlow.premiumAdjustments.campañaCrossSelling') }}
          </span>
        </ea-col>
        <ea-col :span="8">
          <span class="t-weight-normal t-size-small">
            {{ model.campaignName }}
          </span>
        </ea-col>
      </ea-row>

      <ea-row  class="m-b-16" v-if="model.campaignName && model.campaignDiscount !== '0'" >
        <ea-col :span="16">
          <span class="t-weight-normal t-size-small">
            {{ $t('quoteBuyGenericFlow.premiumAdjustments.dtoCampaña') }}
          </span>
        </ea-col>
        <ea-col :span="8">
          <span class="t-weight-normal t-size-small">
            {{ model.campaignDiscount + '%' }}
          </span>
        </ea-col>
      </ea-row>

      <ea-row v-if="!premiumAdjustmentsDataIsEmpty">
        <ea-col :span="16">
          <ea-form-item prop="comissionType" :label="$t('quoteBuyGenericFlow.premiumAdjustments.tipoComision')">
            <ea-select
              v-model="model.comissionType"
              :placeholder="$t('common.label.select')"
              :readonly="!model.comissionTypeModificable"
              @input="onValueChange"
            >
              <ea-option
                v-for="comissionType in comissionTypeList"
                :key="comissionType.value"
                :label="comissionType.label"
                :value="comissionType.value"
              />
            </ea-select>
          </ea-form-item>
        </ea-col>
      </ea-row>

      <ea-row class="m-b-16" v-if="!consultaOperation && model.discountMax">
        <ea-col :span="16">
          <span class="t-weight-normal t-size-small">
            {{ $t('quoteBuyGenericFlow.premiumAdjustments.dtoComercialMaxTotal') }}
          </span>
        </ea-col>
        <ea-col :span="8">
          <span class="t-weight-normal t-size-small">
            {{ model.discountMax + '%' }}
          </span>
        </ea-col>
      </ea-row>

      <ea-row v-if="!premiumAdjustmentsDataIsEmpty">
        <ea-col :span="16">
          <ea-form-item prop="discountType" :label="$t('quoteBuyGenericFlow.premiumAdjustments.recargoOrDto')">
            <ea-select
              v-model="model.discountType"
              :placeholder="$t('common.label.select')"
              :readonly="!model.discountType || readOnly || !returnElementIsModificableByCode('POREDWEB')"
              @input="onDiscountTypeSelectChange($event)">
                <ea-option
                  v-for="discountType in discountTypeList"
                  :key="discountType.value"
                  :label="discountType.label"
                  :value="discountType.value"
                />
            </ea-select>
          </ea-form-item>
        </ea-col>
        <ea-col :span="8" class="align-bottom-container">
          <ea-form-item prop="discountValue" :label="$t('')" class="">
            <ea-input-currency
              v-model="model.discountValue"
              placeholder="0%"
              :currency="{
                suffix: '%'
              }"
              :precision="2"
              :readonly="readOnly || !returnElementIsModificableByCode('POREDWEB')"
              @input="onValueChange"
            ></ea-input-currency>
          </ea-form-item>
        </ea-col>
      </ea-row>
    </ea-form>
  </div>
</template>

<script lang="ts">
import {
  Component, Prop
} from 'vue-property-decorator';
import {
  mixins
} from 'vue-class-component';

import {
  EABusinessComponent
} from '@zurich-es-npm/ea-front-web-core';

import {
  GetWarrantiesResponseDataDatosAjustesPrimas as DatosAjustesPrimas
} from '@/services/V1/quoteAndBuy/getWarrantiesOperation/post';
import QbPremiumAdjustmentsModel from './qb-premium-adjustments-model';
import {
  EAFormValidationOptions,
  eaRangeValidation,
  EAValidation,
  EAValidationTriggers,
  EAValidationTypes,
  Form,
} from '@zurich-es-npm/ea-front-web-ui';
import {
  LabelValue
} from '../qb-producto-asegurado-comunidades/qb-producto-asegurado-comunidades-model';
import Utils from '@/utils/utils';
import {
  DatosAjustesPrimasEnum,
  AjustesPrimasRecargoDescuentoEnum
} from '../qb-warranty/warranty.types';

@Component({
  name: 'qb-premium-adjustments',
})

/**
 * Business Component qb-premium-adjustments
 */
export default class QbPremiumAdjustmentsBusiness extends mixins<EABusinessComponent<QbPremiumAdjustmentsModel>>(
  EABusinessComponent
) {
  @Prop()
    premiumAdjustmentsData?: DatosAjustesPrimas[];

  @Prop()
    readOnly?: boolean;

  @Prop()
    consultaOperation?: boolean;

  comissionTypeList?: LabelValue[] = [];

  discountTypeList?: LabelValue[] = [];

  discountValue?: number;

  maxValue: number = 0;

  dataIsReady = false;

  validationOptions: EAFormValidationOptions = {
    rules: {
      discountValue: [],
    },
  };

  /**
   * Checks if premiumAdjustmentsData is an empty list
   */
  get premiumAdjustmentsDataIsEmpty() {
    return this.premiumAdjustmentsData && this.premiumAdjustmentsData.length === 0;
  }

  /**
   * Hook when page is created.
   */
  created() {
    this.discountTypeList = [
      {
        label: this.$t('quoteBuyGenericFlow.premiumAdjustments.recargo').toString(),
        value: AjustesPrimasRecargoDescuentoEnum.Recargo
      },
      {
        label: this.$t('quoteBuyGenericFlow.premiumAdjustments.descuento').toString(),
        value: AjustesPrimasRecargoDescuentoEnum.Descuento
      }
    ];
    this.setPremiumAdjustmentsData();
  }

  /**
   * On Value Change method. Triggers when user made changes
   *
   */
  onValueChange(): void {
    if (this.dataIsReady) {
      this.update();
      this.$emit('qbWarrantyReset');
    }
  }

  /**
   * On Select Change method. Triggers when user made changes
   *
   * @param {AjustesPrimasRecargoDescuentoEnum} valueSelected
   */
  onDiscountTypeSelectChange(valueSelected: AjustesPrimasRecargoDescuentoEnum): void {
    this.setMaxDiscountValue(valueSelected);
    this.update();
    this.$emit('qbWarrantyReset');
  }

  /**
   * Watcher for premiumAdjustmentsData prop
   * @param {string} premiumAdjustmentsData
   */
  setPremiumAdjustmentsData() {
    if (!this.premiumAdjustmentsDataIsEmpty) {
      this.comissionTypeList = this.returnElementListByCode(DatosAjustesPrimasEnum.TipoComision);
      if (this.comissionTypeList) {
        Utils.sortObjectArrayByProperty(this.comissionTypeList, 'label');
        Utils.setObjectArrayLastElement(this.comissionTypeList, 'value', 'N');
        Utils.capitalizeObjectArrayProperty(this.comissionTypeList, 'label');
      }
      this.model.comissionType = this.returnElementValueByCode(DatosAjustesPrimasEnum.TipoComision);
      this.model.comissionTypeModificable = this.returnElementIsModificableByCode(DatosAjustesPrimasEnum.TipoComision);

      const poredwebValue = parseFloat(
        this.returnElementValueByCode(DatosAjustesPrimasEnum.Descuento).replace(',', '.')
      );
      this.model.discountValue = Math.abs(poredwebValue);
      this.model.discountType = poredwebValue > 0
        ? AjustesPrimasRecargoDescuentoEnum.Recargo
        : AjustesPrimasRecargoDescuentoEnum.Descuento;
      this.setMaxDiscountValue(this.model.discountType as AjustesPrimasRecargoDescuentoEnum);

      this.model.campaignName = this.returnElementValueByCode(DatosAjustesPrimasEnum.Campana);
      this.model.campaignDiscount = this.returnElementValueByCode(DatosAjustesPrimasEnum.DescuentoCampana);
      this.model.discountMax = this.returnElementValueByCode(DatosAjustesPrimasEnum.DescuentoMax);
      this.model.multiProductDiscount = this.returnElementValueByCode(DatosAjustesPrimasEnum.DescuentoMultiProduct);

      this.validationOptions = {
        rules: {
          discountValue: [eaRangeValidation(
            0,
            this.maxValue,
            'common.label.validation.invalidRange',
            EAValidationTriggers.BLUR,
            EAValidationTypes.NUMBER
          )]
        },
      };
      this.update();

      this.$nextTick(() => {
        this.dataIsReady = true;
      });
    }
  }

  /**
   * @param {string} code
   * @returns {LabelValue[] | undefined}
   *
   */
  returnElementListByCode(code: string): LabelValue[] | undefined {
    const elementList = this.returnElementByCode(code)?.tablaRestricciones?.map(restriccion => {
      return {
        label: restriccion.nombreRestriccion,
        value: restriccion.valorRestriccion,
      };
    });

    if (elementList) {
      Utils.sortObjectArrayByProperty(elementList, 'label');
      return elementList;
    } else {
      return undefined;
    }
  }

  /**
   * Return element value by code
   * @param {string} code
   * @returns {string}
   */
  returnElementValueByCode(code: string): string {
    const valorElemento = this.returnElementByCode(code)?.valorElemento;
    return valorElemento ? valorElemento : '';
  }

  /**
   * Return element value by code
   * @param {string} code
   * @returns {boolean}
   */
  returnElementIsModificableByCode(code: string): boolean {
    const elementoModificable = this.returnElementByCode(code)?.elementoModificable;
    return elementoModificable ? elementoModificable : false;
  }

  /**
   * Return element by code
   * @param {string} code
   * @returns {DatosAjustesPrimas  | undefined}
   */
  returnElementByCode(code: string): DatosAjustesPrimas | undefined {
    return this.premiumAdjustmentsData
      ? this.premiumAdjustmentsData.find(premiumAdjustmentData => premiumAdjustmentData.codigoElemento === code)
      : undefined;
  }
  
  /**
   * Set discount value by discountType
   * @param {AjustesPrimasRecargoDescuentoEnum} discountType
   */
  setMaxDiscountValue(discountType: AjustesPrimasRecargoDescuentoEnum) {
    const discountElement = this.returnElementByCode(DatosAjustesPrimasEnum.Descuento);
    if (discountElement && discountElement.valorMaximoElemento) {
      this.maxValue = discountElement.valorMaximoElemento;
    }
    if (discountType === AjustesPrimasRecargoDescuentoEnum.Descuento) {
      if (discountElement && discountElement.valorMinimoElemento) {
        this.maxValue = discountElement.valorMinimoElemento * parseInt(discountType);
      }
    }
    this.validationOptions = {
      rules: {
        discountValue: [eaRangeValidation(
          0,
          this.maxValue,
          'common.label.validation.invalidRange',
          EAValidationTriggers.BLUR,
          EAValidationTypes.NUMBER
        )]
      },
    };
  }

  /**
   * Returns validation object
   * @returns {EAValidation}
   */
  public validation(): EAValidation {
    const form = this.$refs.form as Form;
    return form?.validation();
  }
}
</script>
