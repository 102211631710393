/** 
 * Model notes-description-modal
 */
class NotesDescriptionModalModel {

  public showModalNotes?: boolean = false;

  public description: string | undefined;

  public noteTitle: string | undefined;

  public canMark = false;

  public codigoTareaAviso: string = '';
}

export default NotesDescriptionModalModel;
