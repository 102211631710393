<template>
  <div>
    <ea-form ref="form" :model="model.formValues"
      :validateOnRuleChange="false"
      :validationOptions="formValidationOptions">
      <ea-row>
        <ea-col :span="24">
          <ea-heading level="5" class="m-b-16">
            {{ $t('quoteBuyGenericFlow.bankDetails.title') }}
          </ea-heading>
        </ea-col>
      </ea-row>
      <ea-row>
        <ea-col :span="6" v-if="isElementPrintable('DSNUCODB') && existsElement('DSNUCODB', datosAdicionales)">
          <ea-form-item
            v-if="!consultaOperation || !elementValueIsAnEmptyString('DSNUCODB', datosAdicionales)"
            prop="contractNumber"
            :label="$t('quoteBuyGenericFlow.bankDetails.field.contractNumber')"
            :required="isElementRequired('DSNUCODB', datosAdicionales)"
          >
            <ea-input-text
              v-model="model.formValues.contractNumber"
              :readonly="!isElementModificable('DSNUCODB', datosAdicionales)"
            />
          </ea-form-item>
        </ea-col>
        <ea-col :span="6" v-if="isElementPrintable(['DSNUSODB']) && existsElement('DSNUSODB', datosAdicionales)">
          <ea-form-item
            v-if="!consultaOperation || !elementValueIsAnEmptyString('DSNUSODB', datosAdicionales)"
            prop="applicationNumber"
            :label="$t('quoteBuyGenericFlow.bankDetails.field.applicationNumber')"
            :required="isElementRequired('DSNUSODB', datosAdicionales)"
          >
            <ea-input-text
              v-model="model.formValues.applicationNumber"
              :readonly="!isElementModificable('DSNUSODB', datosAdicionales)"
            />
          </ea-form-item>
        </ea-col>
      </ea-row>
      <ea-row>
        <ea-col :span="6" v-if="isElementPrintable(['CDOFICDB']) && existsElement('CDOFICDB', datosAdicionales)">
          <ea-form-item
            v-if="!consultaOperation || !elementValueIsAnEmptyString('CDOFICDB', datosAdicionales)"
            prop="officeCode"
            :label="$t('quoteBuyGenericFlow.bankDetails.field.officeCode')"
            :required="isElementRequired('CDOFICDB', datosAdicionales)"
          >
            <ea-input-text
              v-model="model.formValues.officeCode"
              :readonly="!isElementModificable('CDOFICDB', datosAdicionales)"
            />
          </ea-form-item>
        </ea-col>
        <ea-col :span="6" v-if="isElementPrintable(['DSOFICDB']) && existsElement('DSOFICDB', datosAdicionales)">
          <ea-form-item
            v-if="!consultaOperation || !elementValueIsAnEmptyString('DSOFICDB', datosAdicionales)"
            prop="officeDescription"
            :label="$t('quoteBuyGenericFlow.bankDetails.field.officeDescription')"
            :required="isElementRequired('DSOFICDB', datosAdicionales)"
          >
            <ea-input-text
              v-model="model.formValues.officeDescription"
              :readonly="!isElementModificable('DSOFICDB', datosAdicionales)"
            />
          </ea-form-item>
        </ea-col>
        <ea-col :span="6" v-if="isElementPrintable(['NODIRADB']) && existsElement('NODIRADB', datosAdicionales)">
          <ea-form-item
            v-if="!consultaOperation || !elementValueIsAnEmptyString('NODIRADB', datosAdicionales)"
            prop="agencyAddress"
            :label="$t('quoteBuyGenericFlow.bankDetails.field.agencyAddress')"
            :required="isElementRequired('NODIRADB', datosAdicionales)"
          >
            <ea-input-text
              v-model="model.formValues.agencyAddress"
              :readonly="!isElementModificable('NODIRADB', datosAdicionales)"
            />
          </ea-form-item>
        </ea-col>
      </ea-row>
      <ea-row>
        <ea-col v-if="isElementPrintable(['TCCOPADB']) && existAnyElement(['TCCOPADB'], datosAdicionales)">
          <post-code-location-input
            id="post-code-location-input"
            ref="postCodeLocationInputBC"
            v-model="model.postCodeLocationInputModel"
            :datosObjeto="datosAdicionales"
            :isSelectedCountryAndorra="false"
            :cityListRetrievalCode="zipCodeCompCityListRetrievalCode"
            :isReadonly="consultaOperation"
            :isElementModificable="isElementModificable"
            :isAnyElementModificable="isAnyElementModificable"
          ></post-code-location-input>
        </ea-col>
      </ea-row>
    </ea-form>
  </div>
</template>

<script lang="ts">
import {
  Component, Prop
} from 'vue-property-decorator';

import {
  mixins
} from 'vue-class-component';

import {
  EABusinessComponent
} from '@zurich-es-npm/ea-front-web-core';
import {
  existAnyElement,
  existsElement,
  isElementModificable,
  isAnyElementModificable,
  isElementRequired,
  returnElementValueByCode,
  elementValueIsAnEmptyString,
  returnElementValueByPossibleCodes,
  getFieldRules
} from '@/utils/object-utils';
import QbBankDetailsModel from './qb-bank-details-model';
import {
  GetGeneralDataResponseDataDatosObjeto
} from '@/services/V1/quoteAndBuy/getGeneralDataOperation/post';
import {
  EACompositeValidation,
  EAFormValidationOptions, EAValidation, Form
} from '@zurich-es-npm/ea-front-web-ui';
import PostCodeLocationInputBusiness from '../post-code-location-input/post-code-location-input-business.vue';
import {
  LabelValue
} from '../qb-producto-asegurado-hogar/qb-producto-asegurado-hogar-model';

@Component({
  name: 'qb-bank-details',
  components: {
    PostCodeLocationInput: PostCodeLocationInputBusiness,
  }
})

/**
 * Business Component qb-bank-details
 */
export default class QbBankDetailsBusiness extends
  mixins<EABusinessComponent<QbBankDetailsModel>>(EABusinessComponent) {

  @Prop({
    required: true,
  })
    datosAdicionales!: GetGeneralDataResponseDataDatosObjeto[];

  @Prop({
    'default': () => false
  })
    consultaOperation?: boolean;

  @Prop({
    'default': () => () => true
  })
    isElementPrintable!: Function;

  countryList?: LabelValue[] = [];

  public zipCodeCompCityListRetrievalCode: string = '';

  public isElementRequired = isElementRequired;

  public isElementModificable = isElementModificable;

  public isAnyElementModificable = isAnyElementModificable;

  public existsElement = existsElement;

  public existAnyElement = existAnyElement;

  public elementValueIsAnEmptyString = elementValueIsAnEmptyString;

  /**
   * Gets form validation options
   */
  get formValidationOptions(): EAFormValidationOptions {
    return {
      rules: {
        contractNumber: getFieldRules(this.model.FIELD_TYPES.STRING, 'DSNUCODB', this.datosAdicionales),
        applicationNumber: getFieldRules(this.model.FIELD_TYPES.STRING, 'DSNUSODB', this.datosAdicionales),
        officeCode: getFieldRules(this.model.FIELD_TYPES.STRING, 'CDOFICDB', this.datosAdicionales),
        officeDescription: getFieldRules(this.model.FIELD_TYPES.STRING, 'DSOFICDB', this.datosAdicionales),
        agencyAddress: getFieldRules(this.model.FIELD_TYPES.STRING, 'NODIRADB', this.datosAdicionales)
      }
    };
  }

  /**
   * Sets form initial values
   */
  setFormValues() {
    this.model.formValues.contractNumber = returnElementValueByCode('DSNUCODB', this.datosAdicionales);
    this.model.formValues.applicationNumber = returnElementValueByCode('DSNUSODB', this.datosAdicionales);
    this.model.formValues.officeCode = returnElementValueByCode('CDOFICDB', this.datosAdicionales);
    this.model.formValues.officeDescription = returnElementValueByCode('DSOFICDB', this.datosAdicionales);
    this.model.formValues.agencyAddress = returnElementValueByCode('NODIRADB', this.datosAdicionales);

    this.model.postCodeLocationInputModel.zipCode =
      returnElementValueByPossibleCodes(['TCCOPADB'], this.datosAdicionales);
    this.zipCodeCompCityListRetrievalCode = this.model.postCodeLocationInputModel.zipCode;
    this.model.postCodeLocationInputModel.city = returnElementValueByCode('NOPOBADB', this.datosAdicionales);
    this.model.postCodeLocationInputModel.province = returnElementValueByCode('DSPROADB', this.datosAdicionales);

    this.update();
  }

  /**
   * Returns validation object
   * @returns {EAValidation }
   */
  public validation(): EAValidation {
    const form = this.$refs.form as Form;
    const postCodeLocationInputBC: PostCodeLocationInputBusiness =
      this.$refs.postCodeLocationInputBC as PostCodeLocationInputBusiness;
    postCodeLocationInputBC.update();
    return new EACompositeValidation([form.validation(), postCodeLocationInputBC.validation()], this.$tc.bind(this));
  }
}
</script>
