import {
  GetPersonsRequestTipoDocumentoEnum
} from '@/services/V1/quoteAndBuy/getPersonsOperation/post';

/** 
 * Model qb-search-policyholder
 */
class QbSearchPolicyholderModel {

  public policyholderType?: string = undefined;

  public documentType?: GetPersonsRequestTipoDocumentoEnum = undefined;

  public showDocumentInvalidError: boolean = false;
  
  public documentNumber: string = '';

  public fiscalId: string = '';

  public firstName: string = '';

  public lastName: string = '';

  public lastName2: string = '';

  public businessName: string = '';

  public affiliationCode: string = '';

}

export default QbSearchPolicyholderModel;
