/* eslint-disable */

/**
 * zonazurich
 * Portal de Mediación Digital
 *
 * The version of the OpenAPI document: 1
 * Contact: digitalarchitecture@zurich.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import {
  EASDKConfig,
  EAUrlUtils
} from '@zurich-es-npm/ea-shared-utils-js';

import * as globalImportUrl from 'url';
import {
  Configuration
} from './configuration';
import globalAxios, {
  AxiosPromise,
  AxiosInstance,
  AxiosResponse
} from 'axios';

const BASE_PATH = EAUrlUtils.removeLastForwardSlash('http://localhost:3000');

/**
 *
 * @export
 */
export const COLLECTION_FORMATS = {
  csv: ',',
  ssv: ' ',
  tsv: '\t',
  pipes: '|',
};

/**
 *  
 * @export
 * @interface RequestArgs
 */
export interface RequestArgs {
  url: string;
  options: any;
}

/**
 * 
 * @export
 * @class BaseAPI
 */
export class BaseAPI {
  protected configuration: Configuration | undefined;

  /**
   *Creates an instance of BaseAPI.
   * @param {Configuration} [configuration]
   * @param {string} [basePath=BASE_PATH]
   * @param {AxiosInstance} [axios=globalAxios]
   * @memberof BaseAPI
   */
  public constructor(
    configuration?: Configuration,
    protected basePath: string = BASE_PATH,
    protected axios: AxiosInstance = globalAxios) {
    if (configuration) {
      this.configuration = configuration;
      this.basePath = configuration.basePath || this.basePath;
    }
  }
}

/**
 * 
 * @export
 * @class RequiredError
 * @extends {Error}
 */
export class RequiredError extends Error {
  public name: 'RequiredError' = 'RequiredError';

  public field: string;

  /**
   *Creates an instance of RequiredError.
   * @param {string} field
   * @param {string} [msg]
   * @memberof RequiredError
   */
  public constructor(field: string, msg?: string) {
    super(msg);
    this.field=field;
  }
}

/**
 * 
 * @export
 * @class AsyncRequestError
 * @extends {Error}
 */
export class AsyncRequestError extends Error {
  public name: 'AsyncRequestError' = 'AsyncRequestError';

  /**
   *Creates an instance of AsyncRequestError.
   * @param {string} field
   * @param {string} [msg]
   * @memberof AsyncRequestError
   */
  public constructor(msg?: string) {
    super(msg);
  }
}

/**
 * 
 * @export
 * @interface Error
 */
export interface Error {
    /**
     * 
     * @type {string}
     * @memberof Error
     */
    code: string;
    /**
     * 
     * @type {string}
     * @memberof Error
     */
    message: string;
    /**
     * 
     * @type {number}
     * @memberof Error
     */
    severity: number;
    /**
     * 
     * @type {Array<object>}
     * @memberof Error
     */
    childErrors?: Array<object>;
}/**
 * 
 * @export
 * @interface GetWarrantiesPolicyConsultationModeRequest
 */
export interface GetWarrantiesPolicyConsultationModeRequest {
    /**
     * 
     * @type {string}
     * @memberof GetWarrantiesPolicyConsultationModeRequest
     */
    codigoPoliza: string;
    /**
     * 
     * @type {number}
     * @memberof GetWarrantiesPolicyConsultationModeRequest
     */
    versionPoliza: number;
    /**
     * 
     * @type {string}
     * @memberof GetWarrantiesPolicyConsultationModeRequest
     */
    tipoPoliza: GetWarrantiesPolicyConsultationModeRequestTipoPolizaEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum GetWarrantiesPolicyConsultationModeRequestTipoPolizaEnum {
    Oferta = 'Oferta',
    Pliza = 'Póliza',
    Incompleta = 'Incompleta'
}
/**
 * 
 * @export
 * @interface GetWarrantiesPolicyConsultationModeResponse
 */
export interface GetWarrantiesPolicyConsultationModeResponse {
    /**
     * 
     * @type {GetWarrantiesPolicyConsultationModeResponseData}
     * @memberof GetWarrantiesPolicyConsultationModeResponse
     */
    data?: GetWarrantiesPolicyConsultationModeResponseData;
    /**
     * 
     * @type {Array<Error>}
     * @memberof GetWarrantiesPolicyConsultationModeResponse
     */
    errors?: Array<Error>;
}/**
 * 
 * @export
 * @interface GetWarrantiesPolicyConsultationModeResponseData
 */
export interface GetWarrantiesPolicyConsultationModeResponseData {
    /**
     * 
     * @type {Array<GetWarrantiesPolicyConsultationModeResponseDataDatosTarificacion>}
     * @memberof GetWarrantiesPolicyConsultationModeResponseData
     */
    datosTarificacion?: Array<GetWarrantiesPolicyConsultationModeResponseDataDatosTarificacion>;
    /**
     * 
     * @type {Array<GetWarrantiesPolicyConsultationModeResponseDataDatosGarantias>}
     * @memberof GetWarrantiesPolicyConsultationModeResponseData
     */
    datosGarantias?: Array<GetWarrantiesPolicyConsultationModeResponseDataDatosGarantias>;
    /**
     * 
     * @type {GetWarrantiesPolicyConsultationModeResponseDataDatosGestionCompetencias}
     * @memberof GetWarrantiesPolicyConsultationModeResponseData
     */
    datosGestionCompetencias?: GetWarrantiesPolicyConsultationModeResponseDataDatosGestionCompetencias;
    /**
     * 
     * @type {Array<GetWarrantiesPolicyConsultationModeResponseDataDatosAjustesPrimas>}
     * @memberof GetWarrantiesPolicyConsultationModeResponseData
     */
    datosAjustesPrimas?: Array<GetWarrantiesPolicyConsultationModeResponseDataDatosAjustesPrimas>;
    /**
     * 
     * @type {Array<GetWarrantiesPolicyConsultationModeResponseDataTablaRestricciones>}
     * @memberof GetWarrantiesPolicyConsultationModeResponseData
     */
    fraccionamientoFormasPago?: Array<GetWarrantiesPolicyConsultationModeResponseDataTablaRestricciones>;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetWarrantiesPolicyConsultationModeResponseData
     */
    codigosPropuestas?: Array<string>;
}/**
 * 
 * @export
 * @interface GetWarrantiesPolicyConsultationModeResponseDataCadenaLanzamiento
 */
export interface GetWarrantiesPolicyConsultationModeResponseDataCadenaLanzamiento {
    /**
     * 
     * @type {string}
     * @memberof GetWarrantiesPolicyConsultationModeResponseDataCadenaLanzamiento
     */
    codigoGarantia?: string;
    /**
     * 
     * @type {string}
     * @memberof GetWarrantiesPolicyConsultationModeResponseDataCadenaLanzamiento
     */
    codigoElemento?: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetWarrantiesPolicyConsultationModeResponseDataCadenaLanzamiento
     */
    elementoModificable?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetWarrantiesPolicyConsultationModeResponseDataCadenaLanzamiento
     */
    elementoRequerido?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetWarrantiesPolicyConsultationModeResponseDataCadenaLanzamiento
     */
    elementoVisible?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetWarrantiesPolicyConsultationModeResponseDataCadenaLanzamiento
     */
    valorElemento?: string;
    /**
     * 
     * @type {GetWarrantiesPolicyConsultationModeResponseDataCopiaValor}
     * @memberof GetWarrantiesPolicyConsultationModeResponseDataCadenaLanzamiento
     */
    copiaValor?: GetWarrantiesPolicyConsultationModeResponseDataCopiaValor;
}/**
 * 
 * @export
 * @interface GetWarrantiesPolicyConsultationModeResponseDataCondicionLanzamientoValorAnteriorElemento
 */
export interface GetWarrantiesPolicyConsultationModeResponseDataCondicionLanzamientoValorAnteriorElemento {
    /**
     * 
     * @type {string}
     * @memberof GetWarrantiesPolicyConsultationModeResponseDataCondicionLanzamientoValorAnteriorElemento
     */
    condicionLanzamientoValorElemento?: string;
    /**
     * 
     * @type {string}
     * @memberof GetWarrantiesPolicyConsultationModeResponseDataCondicionLanzamientoValorAnteriorElemento
     */
    condicionLanzamientoRestriccionGarantia?: GetWarrantiesPolicyConsultationModeResponseDataCondicionLanzamientoValorAnteriorElementoCondicionLanzamientoRestriccionGarantiaEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum GetWarrantiesPolicyConsultationModeResponseDataCondicionLanzamientoValorAnteriorElementoCondicionLanzamientoRestriccionGarantiaEnum {
    Equal = 'Equal',
    NotEqual = 'NotEqual',
    Any = 'Any'
}
/**
 * 
 * @export
 * @interface GetWarrantiesPolicyConsultationModeResponseDataCopiaValor
 */
export interface GetWarrantiesPolicyConsultationModeResponseDataCopiaValor {
    /**
     * 
     * @type {string}
     * @memberof GetWarrantiesPolicyConsultationModeResponseDataCopiaValor
     */
    codigoGarantia: string;
    /**
     * 
     * @type {string}
     * @memberof GetWarrantiesPolicyConsultationModeResponseDataCopiaValor
     */
    codigoPropuesta: string;
    /**
     * 
     * @type {string}
     * @memberof GetWarrantiesPolicyConsultationModeResponseDataCopiaValor
     */
    codigoElemento: string;
    /**
     * 
     * @type {string}
     * @memberof GetWarrantiesPolicyConsultationModeResponseDataCopiaValor
     */
    condicionLanzamientoRestriccionGarantia?: GetWarrantiesPolicyConsultationModeResponseDataCopiaValorCondicionLanzamientoRestriccionGarantiaEnum;
    /**
     * 
     * @type {string}
     * @memberof GetWarrantiesPolicyConsultationModeResponseDataCopiaValor
     */
    condicionLanzamientoValorElemento?: string;
    /**
     * 
     * @type {GetWarrantiesPolicyConsultationModeResponseDataCopiaValorCondicionLanzamientoElemento}
     * @memberof GetWarrantiesPolicyConsultationModeResponseDataCopiaValor
     */
    condicionLanzamientoElemento?: GetWarrantiesPolicyConsultationModeResponseDataCopiaValorCondicionLanzamientoElemento;
}

/**
    * @export
    * @enum {string}
    */
export enum GetWarrantiesPolicyConsultationModeResponseDataCopiaValorCondicionLanzamientoRestriccionGarantiaEnum {
    Equal = 'Equal',
    NotEqual = 'NotEqual',
    Any = 'Any'
}
/**
 * 
 * @export
 * @interface GetWarrantiesPolicyConsultationModeResponseDataCopiaValorCondicionLanzamientoElemento
 */
export interface GetWarrantiesPolicyConsultationModeResponseDataCopiaValorCondicionLanzamientoElemento {
    /**
     * 
     * @type {string}
     * @memberof GetWarrantiesPolicyConsultationModeResponseDataCopiaValorCondicionLanzamientoElemento
     */
    codigoGarantia: string;
    /**
     * 
     * @type {string}
     * @memberof GetWarrantiesPolicyConsultationModeResponseDataCopiaValorCondicionLanzamientoElemento
     */
    codigoPropuesta: string;
    /**
     * 
     * @type {string}
     * @memberof GetWarrantiesPolicyConsultationModeResponseDataCopiaValorCondicionLanzamientoElemento
     */
    codigoElemento: string;
}/**
 * 
 * @export
 * @interface GetWarrantiesPolicyConsultationModeResponseDataDatosAjustesPrimas
 */
export interface GetWarrantiesPolicyConsultationModeResponseDataDatosAjustesPrimas {
    /**
     * 
     * @type {string}
     * @memberof GetWarrantiesPolicyConsultationModeResponseDataDatosAjustesPrimas
     */
    codigoElemento?: string;
    /**
     * 
     * @type {string}
     * @memberof GetWarrantiesPolicyConsultationModeResponseDataDatosAjustesPrimas
     */
    tablaElemento?: string;
    /**
     * 
     * @type {string}
     * @memberof GetWarrantiesPolicyConsultationModeResponseDataDatosAjustesPrimas
     */
    valorElemento?: string;
    /**
     * 
     * @type {number}
     * @memberof GetWarrantiesPolicyConsultationModeResponseDataDatosAjustesPrimas
     */
    valorMaximoElemento?: number;
    /**
     * 
     * @type {number}
     * @memberof GetWarrantiesPolicyConsultationModeResponseDataDatosAjustesPrimas
     */
    valorMinimoElemento?: number;
    /**
     * 
     * @type {boolean}
     * @memberof GetWarrantiesPolicyConsultationModeResponseDataDatosAjustesPrimas
     */
    elementoRequerido?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetWarrantiesPolicyConsultationModeResponseDataDatosAjustesPrimas
     */
    elementoModificable?: boolean;
    /**
     * 
     * @type {number}
     * @memberof GetWarrantiesPolicyConsultationModeResponseDataDatosAjustesPrimas
     */
    longitudElemento?: number;
    /**
     * 
     * @type {number}
     * @memberof GetWarrantiesPolicyConsultationModeResponseDataDatosAjustesPrimas
     */
    numeroDecimalesElemento?: number;
    /**
     * 
     * @type {Array<GetWarrantiesPolicyConsultationModeResponseDataTablaRestricciones>}
     * @memberof GetWarrantiesPolicyConsultationModeResponseDataDatosAjustesPrimas
     */
    tablaRestricciones?: Array<GetWarrantiesPolicyConsultationModeResponseDataTablaRestricciones>;
}/**
 * 
 * @export
 * @interface GetWarrantiesPolicyConsultationModeResponseDataDatosGarantias
 */
export interface GetWarrantiesPolicyConsultationModeResponseDataDatosGarantias {
    /**
     * 
     * @type {string}
     * @memberof GetWarrantiesPolicyConsultationModeResponseDataDatosGarantias
     */
    codigoGarantia?: string;
    /**
     * 
     * @type {Array<GetWarrantiesPolicyConsultationModeResponseDataGarantiaSeleccionada>}
     * @memberof GetWarrantiesPolicyConsultationModeResponseDataDatosGarantias
     */
    garantiaSeleccionada?: Array<GetWarrantiesPolicyConsultationModeResponseDataGarantiaSeleccionada>;
    /**
     * 
     * @type {Array<GetWarrantiesPolicyConsultationModeResponseDataElementosGarantias>}
     * @memberof GetWarrantiesPolicyConsultationModeResponseDataDatosGarantias
     */
    elementosGarantias?: Array<GetWarrantiesPolicyConsultationModeResponseDataElementosGarantias>;
    /**
     * 
     * @type {Array<GetWarrantiesPolicyConsultationModeResponseDataLanzadorPorCheck>}
     * @memberof GetWarrantiesPolicyConsultationModeResponseDataDatosGarantias
     */
    lanzadorPorCheck?: Array<GetWarrantiesPolicyConsultationModeResponseDataLanzadorPorCheck>;
}/**
 * 
 * @export
 * @interface GetWarrantiesPolicyConsultationModeResponseDataDatosGestionCompetencias
 */
export interface GetWarrantiesPolicyConsultationModeResponseDataDatosGestionCompetencias {
    /**
     * 
     * @type {boolean}
     * @memberof GetWarrantiesPolicyConsultationModeResponseDataDatosGestionCompetencias
     */
    gestionDeCompetencias?: boolean;
    /**
     * 
     * @type {Array<GetWarrantiesPolicyConsultationModeResponseDataDatosGestionCompetenciasCompetencesManagementByOfferData>}
     * @memberof GetWarrantiesPolicyConsultationModeResponseDataDatosGestionCompetencias
     */
    competencesManagementByOfferData?: Array<GetWarrantiesPolicyConsultationModeResponseDataDatosGestionCompetenciasCompetencesManagementByOfferData>;
}/**
 * 
 * @export
 * @interface GetWarrantiesPolicyConsultationModeResponseDataDatosGestionCompetenciasCompetencesManagementByOfferData
 */
export interface GetWarrantiesPolicyConsultationModeResponseDataDatosGestionCompetenciasCompetencesManagementByOfferData {
    /**
     * 
     * @type {string}
     * @memberof GetWarrantiesPolicyConsultationModeResponseDataDatosGestionCompetenciasCompetencesManagementByOfferData
     */
    codigoObjeto?: string;
    /**
     * 
     * @type {string}
     * @memberof GetWarrantiesPolicyConsultationModeResponseDataDatosGestionCompetenciasCompetencesManagementByOfferData
     */
    codigoOpcion?: string;
    /**
     * 
     * @type {string}
     * @memberof GetWarrantiesPolicyConsultationModeResponseDataDatosGestionCompetenciasCompetencesManagementByOfferData
     */
    codigoPropuesta?: string;
    /**
     * 
     * @type {string}
     * @memberof GetWarrantiesPolicyConsultationModeResponseDataDatosGestionCompetenciasCompetencesManagementByOfferData
     */
    codigoMCT?: string;
    /**
     * 
     * @type {string}
     * @memberof GetWarrantiesPolicyConsultationModeResponseDataDatosGestionCompetenciasCompetencesManagementByOfferData
     */
    descripcionElemento?: string;
    /**
     * 
     * @type {number}
     * @memberof GetWarrantiesPolicyConsultationModeResponseDataDatosGestionCompetenciasCompetencesManagementByOfferData
     */
    codigoErrorAutorizacion?: number;
    /**
     * 
     * @type {string}
     * @memberof GetWarrantiesPolicyConsultationModeResponseDataDatosGestionCompetenciasCompetencesManagementByOfferData
     */
    estadoControlCompetencias?: GetWarrantiesPolicyConsultationModeResponseDataDatosGestionCompetenciasCompetencesManagementByOfferDataEstadoControlCompetenciasEnum;
    /**
     * 
     * @type {string}
     * @memberof GetWarrantiesPolicyConsultationModeResponseDataDatosGestionCompetenciasCompetencesManagementByOfferData
     */
    descripcionErrorAutorizacion?: string;
    /**
     * 
     * @type {string}
     * @memberof GetWarrantiesPolicyConsultationModeResponseDataDatosGestionCompetenciasCompetencesManagementByOfferData
     */
    descripcionEstadoControlCompetencias?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum GetWarrantiesPolicyConsultationModeResponseDataDatosGestionCompetenciasCompetencesManagementByOfferDataEstadoControlCompetenciasEnum {
    A = 'A',
    P = 'P',
    R = 'R'
}
/**
 * 
 * @export
 * @interface GetWarrantiesPolicyConsultationModeResponseDataDatosPropuestas
 */
export interface GetWarrantiesPolicyConsultationModeResponseDataDatosPropuestas {
    /**
     * 
     * @type {string}
     * @memberof GetWarrantiesPolicyConsultationModeResponseDataDatosPropuestas
     */
    codigoPropuesta?: string;
    /**
     * 
     * @type {string}
     * @memberof GetWarrantiesPolicyConsultationModeResponseDataDatosPropuestas
     */
    tablaElemento?: string;
    /**
     * 
     * @type {string}
     * @memberof GetWarrantiesPolicyConsultationModeResponseDataDatosPropuestas
     */
    valorElemento?: string;
    /**
     * 
     * @type {number}
     * @memberof GetWarrantiesPolicyConsultationModeResponseDataDatosPropuestas
     */
    valorMinimoElemento?: number;
    /**
     * 
     * @type {number}
     * @memberof GetWarrantiesPolicyConsultationModeResponseDataDatosPropuestas
     */
    valorMaximoElemento?: number;
    /**
     * 
     * @type {boolean}
     * @memberof GetWarrantiesPolicyConsultationModeResponseDataDatosPropuestas
     */
    elementoModificable?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetWarrantiesPolicyConsultationModeResponseDataDatosPropuestas
     */
    elementoRequerido?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetWarrantiesPolicyConsultationModeResponseDataDatosPropuestas
     */
    elementoVisible?: boolean;
    /**
     * 
     * @type {number}
     * @memberof GetWarrantiesPolicyConsultationModeResponseDataDatosPropuestas
     */
    longitudElemento?: number;
    /**
     * 
     * @type {number}
     * @memberof GetWarrantiesPolicyConsultationModeResponseDataDatosPropuestas
     */
    numeroDecimalesElemento?: number;
    /**
     * 
     * @type {string}
     * @memberof GetWarrantiesPolicyConsultationModeResponseDataDatosPropuestas
     */
    tipoVariable?: GetWarrantiesPolicyConsultationModeResponseDataDatosPropuestasTipoVariableEnum;
    /**
     * 
     * @type {string}
     * @memberof GetWarrantiesPolicyConsultationModeResponseDataDatosPropuestas
     */
    tipoMascara?: GetWarrantiesPolicyConsultationModeResponseDataDatosPropuestasTipoMascaraEnum;
    /**
     * 
     * @type {string}
     * @memberof GetWarrantiesPolicyConsultationModeResponseDataDatosPropuestas
     */
    tipoPropuesta?: GetWarrantiesPolicyConsultationModeResponseDataDatosPropuestasTipoPropuestaEnum;
    /**
     * 
     * @type {Array<GetWarrantiesPolicyConsultationModeResponseDataTablaRestricciones>}
     * @memberof GetWarrantiesPolicyConsultationModeResponseDataDatosPropuestas
     */
    tablaRestricciones?: Array<GetWarrantiesPolicyConsultationModeResponseDataTablaRestricciones>;
    /**
     * 
     * @type {Array<GetWarrantiesPolicyConsultationModeResponseDataLanzadorPorValor>}
     * @memberof GetWarrantiesPolicyConsultationModeResponseDataDatosPropuestas
     */
    lanzadorPorValor?: Array<GetWarrantiesPolicyConsultationModeResponseDataLanzadorPorValor>;
}

/**
    * @export
    * @enum {string}
    */
export enum GetWarrantiesPolicyConsultationModeResponseDataDatosPropuestasTipoVariableEnum {
    A = 'A',
    N = 'N'
}
/**
    * @export
    * @enum {string}
    */
export enum GetWarrantiesPolicyConsultationModeResponseDataDatosPropuestasTipoMascaraEnum {
    A = 'A',
    C = 'C',
    D = 'D',
    F = 'F',
    I = 'I',
    M = 'M',
    P = 'P',
    R = 'R',
    T = 'T'
}
/**
    * @export
    * @enum {string}
    */
export enum GetWarrantiesPolicyConsultationModeResponseDataDatosPropuestasTipoPropuestaEnum {
    M = 'M',
    S = 'S',
    O = 'O',
    N = 'N'
}
/**
 * 
 * @export
 * @interface GetWarrantiesPolicyConsultationModeResponseDataDatosTarificacion
 */
export interface GetWarrantiesPolicyConsultationModeResponseDataDatosTarificacion {
    /**
     * 
     * @type {string}
     * @memberof GetWarrantiesPolicyConsultationModeResponseDataDatosTarificacion
     */
    codigoPropuesta?: string;
    /**
     * 
     * @type {number}
     * @memberof GetWarrantiesPolicyConsultationModeResponseDataDatosTarificacion
     */
    importeAcumuladoRecibo?: number;
    /**
     * 
     * @type {number}
     * @memberof GetWarrantiesPolicyConsultationModeResponseDataDatosTarificacion
     */
    importePrimerRecibo?: number;
    /**
     * 
     * @type {number}
     * @memberof GetWarrantiesPolicyConsultationModeResponseDataDatosTarificacion
     */
    importeRecibosSucesivos?: number;
    /**
     * 
     * @type {number}
     * @memberof GetWarrantiesPolicyConsultationModeResponseDataDatosTarificacion
     */
    importeTotalRecibo?: number;
    /**
     * 
     * @type {string}
     * @memberof GetWarrantiesPolicyConsultationModeResponseDataDatosTarificacion
     */
    formaPagoTarificacion?: GetWarrantiesPolicyConsultationModeResponseDataDatosTarificacionFormaPagoTarificacionEnum;
    /**
     * 
     * @type {boolean}
     * @memberof GetWarrantiesPolicyConsultationModeResponseDataDatosTarificacion
     */
    noPrimaMinima?: boolean;
}

/**
    * @export
    * @enum {string}
    */
export enum GetWarrantiesPolicyConsultationModeResponseDataDatosTarificacionFormaPagoTarificacionEnum {
    Anual = 'Anual',
    Unica = 'Unica',
    Semestral = 'Semestral',
    Trimestral = 'Trimestral',
    Mensual = 'Mensual'
}
/**
 * 
 * @export
 * @interface GetWarrantiesPolicyConsultationModeResponseDataElementosGarantias
 */
export interface GetWarrantiesPolicyConsultationModeResponseDataElementosGarantias {
    /**
     * 
     * @type {string}
     * @memberof GetWarrantiesPolicyConsultationModeResponseDataElementosGarantias
     */
    codigoElemento?: string;
    /**
     * 
     * @type {Array<GetWarrantiesPolicyConsultationModeResponseDataDatosPropuestas>}
     * @memberof GetWarrantiesPolicyConsultationModeResponseDataElementosGarantias
     */
    datosPropuestas?: Array<GetWarrantiesPolicyConsultationModeResponseDataDatosPropuestas>;
}/**
 * 
 * @export
 * @interface GetWarrantiesPolicyConsultationModeResponseDataGarantiaSeleccionada
 */
export interface GetWarrantiesPolicyConsultationModeResponseDataGarantiaSeleccionada {
    /**
     * 
     * @type {string}
     * @memberof GetWarrantiesPolicyConsultationModeResponseDataGarantiaSeleccionada
     */
    codigoPropuesta?: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetWarrantiesPolicyConsultationModeResponseDataGarantiaSeleccionada
     */
    garantiaSeleccionada?: boolean;
}/**
 * 
 * @export
 * @interface GetWarrantiesPolicyConsultationModeResponseDataLanzadorPorCheck
 */
export interface GetWarrantiesPolicyConsultationModeResponseDataLanzadorPorCheck {
    /**
     * 
     * @type {string}
     * @memberof GetWarrantiesPolicyConsultationModeResponseDataLanzadorPorCheck
     */
    condicionLanzamientoValorElemento?: string;
    /**
     * 
     * @type {string}
     * @memberof GetWarrantiesPolicyConsultationModeResponseDataLanzadorPorCheck
     */
    condicionLanzamientoRestriccionGarantia?: GetWarrantiesPolicyConsultationModeResponseDataLanzadorPorCheckCondicionLanzamientoRestriccionGarantiaEnum;
    /**
     * 
     * @type {Array<GetWarrantiesPolicyConsultationModeResponseDataCadenaLanzamiento>}
     * @memberof GetWarrantiesPolicyConsultationModeResponseDataLanzadorPorCheck
     */
    cadenaLanzamiento?: Array<GetWarrantiesPolicyConsultationModeResponseDataCadenaLanzamiento>;
}

/**
    * @export
    * @enum {string}
    */
export enum GetWarrantiesPolicyConsultationModeResponseDataLanzadorPorCheckCondicionLanzamientoRestriccionGarantiaEnum {
    Equal = 'Equal',
    NotEqual = 'NotEqual',
    Any = 'Any'
}
/**
 * 
 * @export
 * @interface GetWarrantiesPolicyConsultationModeResponseDataLanzadorPorValor
 */
export interface GetWarrantiesPolicyConsultationModeResponseDataLanzadorPorValor {
    /**
     * 
     * @type {string}
     * @memberof GetWarrantiesPolicyConsultationModeResponseDataLanzadorPorValor
     */
    condicionLanzamientoValorElemento?: string;
    /**
     * 
     * @type {string}
     * @memberof GetWarrantiesPolicyConsultationModeResponseDataLanzadorPorValor
     */
    condicionLanzamientoRestriccionGarantia?: GetWarrantiesPolicyConsultationModeResponseDataLanzadorPorValorCondicionLanzamientoRestriccionGarantiaEnum;
    /**
     * 
     * @type {GetWarrantiesPolicyConsultationModeResponseDataCondicionLanzamientoValorAnteriorElemento}
     * @memberof GetWarrantiesPolicyConsultationModeResponseDataLanzadorPorValor
     */
    condicionLanzamientoValorAnteriorElemento?: GetWarrantiesPolicyConsultationModeResponseDataCondicionLanzamientoValorAnteriorElemento;
    /**
     * 
     * @type {Array<GetWarrantiesPolicyConsultationModeResponseDataCadenaLanzamiento>}
     * @memberof GetWarrantiesPolicyConsultationModeResponseDataLanzadorPorValor
     */
    cadenaLanzamiento?: Array<GetWarrantiesPolicyConsultationModeResponseDataCadenaLanzamiento>;
}

/**
    * @export
    * @enum {string}
    */
export enum GetWarrantiesPolicyConsultationModeResponseDataLanzadorPorValorCondicionLanzamientoRestriccionGarantiaEnum {
    Equal = 'Equal',
    NotEqual = 'NotEqual',
    Any = 'Any'
}
/**
 * 
 * @export
 * @interface GetWarrantiesPolicyConsultationModeResponseDataTablaRestricciones
 */
export interface GetWarrantiesPolicyConsultationModeResponseDataTablaRestricciones {
    /**
     * 
     * @type {string}
     * @memberof GetWarrantiesPolicyConsultationModeResponseDataTablaRestricciones
     */
    nombreRestriccion?: string;
    /**
     * 
     * @type {string}
     * @memberof GetWarrantiesPolicyConsultationModeResponseDataTablaRestricciones
     */
    valorRestriccion?: string;
}

type getWarrantiesPolicyConsultationModeOperationParams = {
  getWarrantiesPolicyConsultationModeRequest: GetWarrantiesPolicyConsultationModeRequest,
};

/**
 * GetWarrantiesPolicyConsultationModeApi - axios parameter creator
 * @param {Configuration} [configuration]
 * @returns {any}
 */
const GetWarrantiesPolicyConsultationModeApiAxiosParamCreator = function(configuration?: Configuration): any {
  return {
    
    /**
     * Recupera los datos de garantías, en modo lectura, para pólizas completas e incompletas
     * @param {GetWarrantiesPolicyConsultationModeRequest} getWarrantiesPolicyConsultationModeRequest 
     * @param {string} [acceptLanguage] El Accept-Language request HTTP header indica en que lenguaje debe responder el backend.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @returns {RequestArgs}
     */
    getWarrantiesPolicyConsultationModeOperation(params: getWarrantiesPolicyConsultationModeOperationParams, options: any = {}): RequestArgs {
      // Verify required parameter 'getWarrantiesPolicyConsultationModeRequest' is not null or undefined
      if (params.getWarrantiesPolicyConsultationModeRequest === null || params.getWarrantiesPolicyConsultationModeRequest === undefined) {
        throw new RequiredError('getWarrantiesPolicyConsultationModeRequest', 'Required parameter getWarrantiesPolicyConsultationModeRequest was null or undefined when calling getWarrantiesPolicyConsultationModeOperation.');
      }
      const localVarPath = `/v1/quoteAndBuy/getWarrantiesPolicyConsultationModeOperation`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true) as Partial<globalImportUrl.UrlWithParsedQuery>;
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;


  
      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
      // Fix override query string Detail: https://stackoverflow.com/a/7517673/1077943

      delete localVarUrlObj.search;
      localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
      const needsSerialization = ('GetWarrantiesPolicyConsultationModeRequest' as any !== 'string') ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization ? JSON.stringify(params.getWarrantiesPolicyConsultationModeRequest || {}) : params.getWarrantiesPolicyConsultationModeRequest || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * GetWarrantiesPolicyConsultationModeApi - functional programming interface
 * @param {Configuration} [configuration]
 * @returns {any}
 */
const GetWarrantiesPolicyConsultationModeApiFp = function(configuration?: Configuration): any {
  return {
    
    /**
     * Recupera los datos de garantías, en modo lectura, para pólizas completas e incompletas
     * @param {GetWarrantiesPolicyConsultationModeRequest} getWarrantiesPolicyConsultationModeRequest 
     * @param {string} [acceptLanguage] El Accept-Language request HTTP header indica en que lenguaje debe responder el backend.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @returns {any}
     */
    getWarrantiesPolicyConsultationModeOperation(params: getWarrantiesPolicyConsultationModeOperationParams, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetWarrantiesPolicyConsultationModeResponse> {
      const localVarAxiosArgs = GetWarrantiesPolicyConsultationModeApiAxiosParamCreator(configuration).getWarrantiesPolicyConsultationModeOperation(params, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH): any => {
        const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * GetWarrantiesPolicyConsultationModeApi - factory interface
 * @param {Configuration} configuration
 * @param {string} basePath
 * @param {AxiosInstance} axios
 * @export
 * @returns {any}
 */
const GetWarrantiesPolicyConsultationModeApiFactory = function(configuration?: Configuration, basePath?: string, axios?: AxiosInstance): any {
  return {
    
    /**
     * Recupera los datos de garantías, en modo lectura, para pólizas completas e incompletas
     * @param {GetWarrantiesPolicyConsultationModeRequest} getWarrantiesPolicyConsultationModeRequest 
     * @param {string} [acceptLanguage] El Accept-Language request HTTP header indica en que lenguaje debe responder el backend.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @returns {any}
     */
    getWarrantiesPolicyConsultationModeOperation(params: getWarrantiesPolicyConsultationModeOperationParams, options?: any): any {
      return GetWarrantiesPolicyConsultationModeApiFp(configuration).getWarrantiesPolicyConsultationModeOperation(params, options)(axios, basePath);
    },
  };
};

/** 
 * GetWarrantiesPolicyConsultationModeApi - object-oriented interface
 * @export
 * @class GetWarrantiesPolicyConsultationModeApi
 * @extends {BaseAPI}
 */
export class GetWarrantiesPolicyConsultationModeApi extends BaseAPI {
  
  /**
   * Recupera los datos de garantías, en modo lectura, para pólizas completas e incompletas
   * @param {GetWarrantiesPolicyConsultationModeRequest} getWarrantiesPolicyConsultationModeRequest 
   * @param {string} [acceptLanguage] El Accept-Language request HTTP header indica en que lenguaje debe responder el backend.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @returns {any}
   * @memberof GetWarrantiesPolicyConsultationModeApi
   */
  public getWarrantiesPolicyConsultationModeOperation(params: getWarrantiesPolicyConsultationModeOperationParams, options?: any): any {
    return GetWarrantiesPolicyConsultationModeApiFp(this.configuration).getWarrantiesPolicyConsultationModeOperation(params, options)(this.axios, this.basePath);
  }

}

/**
 * 
 * @export
 * @class EAgetWarrantiesPolicyConsultationModeOperationInputMessage
 */
export class EAgetWarrantiesPolicyConsultationModeOperationInputMessage {
  
  /**
   * 
   * @type {GetWarrantiesPolicyConsultationModeRequest}
   * @memberof EAgetWarrantiesPolicyConsultationModeOperationInputMessage
   */
  public getWarrantiesPolicyConsultationModeRequest: GetWarrantiesPolicyConsultationModeRequest;

  public constructor(getWarrantiesPolicyConsultationModeRequest: GetWarrantiesPolicyConsultationModeRequest, ){
  
  this.getWarrantiesPolicyConsultationModeRequest=getWarrantiesPolicyConsultationModeRequest;
  } 
}



/** 
 * EAGetWarrantiesPolicyConsultationModeApi - Architecture client for getWarrantiesPolicyConsultationModeOperation
 * @export
 * @class EAGetWarrantiesPolicyConsultationModeApi
 */
export class EAGetWarrantiesPolicyConsultationModeApi {

  /**
   * 
   * @param {GetWarrantiesPolicyConsultationModeRequest} getWarrantiesPolicyConsultationModeRequest
   
   * @param {Configuration} configuration
   * @throws {AsyncRequestError | RequiredError}
   * @returns {Promise<GetWarrantiesPolicyConsultationModeResponse | null>}
  */ 
  public async getWarrantiesPolicyConsultationModeOperation(params: getWarrantiesPolicyConsultationModeOperationParams, configuration: Configuration = {}): Promise<GetWarrantiesPolicyConsultationModeResponse | null> {
    return this.getWarrantiesPolicyConsultationModeOperationPromise(params, configuration);
  }

  /**
   * 
   * @param {GetWarrantiesPolicyConsultationModeRequest} getWarrantiesPolicyConsultationModeRequest
   
   * @param {Configuration} configuration
   * @param {Function} callback
   * @throws {AsyncRequestError | RequiredError}
   */
  public async getWarrantiesPolicyConsultationModeOperationSteps(params: getWarrantiesPolicyConsultationModeOperationParams, configuration: Configuration, callback: (res: AxiosResponse<GetWarrantiesPolicyConsultationModeResponse | null>) => void) {
    let done: boolean = false;
    const httpClient: AxiosInstance = EASDKConfig.getHttpClient('/v1/quoteAndBuy/getWarrantiesPolicyConsultationModeOperation', 'getWarrantiesPolicyConsultationMode', 'getWarrantiesPolicyConsultationModeOperation');
    const api = new GetWarrantiesPolicyConsultationModeApi(configuration, '', httpClient);
    const sleep = async(ms: number) => {
      return new Promise(resolve => setTimeout(resolve, ms));
    };
    const headers: { ['Async-Id']?: string } = {};
    while (!done) {
      const output = await api.getWarrantiesPolicyConsultationModeOperation(params, { headers }) as AxiosResponse<GetWarrantiesPolicyConsultationModeResponse | null>;
      callback(output);
      if (output.status === 202) {
        try {
          const retry = parseInt(output.headers['retry']);
          headers['Async-Id'] = output.headers['request-id'];
          if (isNaN(retry) || retry <= 0 || retry > 1000 * 3600) {
            throw new Error(`Retry time not acceptable (in milliseconds): ${retry}`);
          }
          await sleep(retry);
        } catch (err) {
          throw new Error(`Error in Async Request ${err}`);
        }
      } else {
        done = true;
      }
    }
  }

  /**
   * 
   * @param {GetWarrantiesPolicyConsultationModeRequest} getWarrantiesPolicyConsultationModeRequest
   
   * @param {Configuration} configuration
   * @throws {AsyncRequestError | RequiredError}
   * @returns {Promise<GetWarrantiesPolicyConsultationModeResponse | null>}
   */
  public async getWarrantiesPolicyConsultationModeOperationPromise(params: getWarrantiesPolicyConsultationModeOperationParams, configuration: Configuration): Promise<GetWarrantiesPolicyConsultationModeResponse | null> {
    return new Promise((resolve, reject) => {
      this.getWarrantiesPolicyConsultationModeOperationSteps(params, configuration, (response: AxiosResponse<GetWarrantiesPolicyConsultationModeResponse | null>) => {
        if (response.status !== 202) {
          resolve(response.data);
        }
      })
      .catch((err) => {
        reject(err);
      });
    });
  } 
}


