<template>
  <div>
    
    <qb-supplement-reason
      v-if="isSupplementData"
      id="supplement-reason-form"
      ref="supplementReasonForm"
      v-model="model.supplementReasonModel"
      :codigoPoliza="model.offerNumber"
      :versionPoliza="model.offerVersion"
      :productFactory="productFactory"
      :supplementData="supplementData"
      @createSupplement="generateSupplementMovement"
      @goToPreviousFlow="goToPreviousFlow"
    />
    
  </div>
</template>

<script lang="ts">
import {
  Component, Prop
} from 'vue-property-decorator';
import {
  mixins
} from 'vue-class-component';
import moment from 'moment';
import {
  EAMethod,
  EAView,
  ResponseWithErrors,
  throwIfResponseHasErrors
} from '@zurich-es-npm/ea-front-web-core';
import {
  FlowHeaderStepsModel,
  FlowViewsStepsModel,
  PolicyModel,
} from '../policy-model';
import {
  EAGetSupplementCreationDataApi,
  GetSupplementCreationDataRequestCodigoRamoEnum,
  GetSupplementCreationDataResponse
} from '@/services/V1/policy/getSupplementCreationDataOperation/post';
import QbSupplementMovementReasonBusiness
  from '@/business-components/qb-supplement-movement-reason/qb-supplement-movement-reason-business.vue';
import {
  EAGetNewSupplementMovementApi,
  GetNewSupplementMovementRequestTipoOperacionSuplementoEnum
} from '@/services/V1/policy/getNewSupplementMovementOperation/post';
import ProductBase from '@/utils/quote-buy-product-factory/products/product-base';
import {
  NotificationsTypeEnum, NotificationsUtils
} from '@/utils/notifications/notifications-utils';

@Component({
  components: {
    QbSupplementReason: QbSupplementMovementReasonBusiness
  }
})

/**
 * MovementReason view
 *
 */
export default class MovementReasonView extends mixins<EAView<PolicyModel>>(EAView) {
  
  @Prop({
    required: true,
  })
    productFactory!: ProductBase;

  supplementData: GetSupplementCreationDataResponse = {};

  /**
   * Hook on created
   */
  @EAMethod({
    loading: true,
  })
  async created() {
    await this.fetchSupplementData();
    this.showRememberToUpdateWarning();
  }

  /**
   * Return if exist data in supplementData
   */
  get isSupplementData(): boolean {
    return !!this.supplementData && Object.keys(this.supplementData).length > 0;
  }

  /**
   * Fetch data for supplement creation.
   */
  @EAMethod({
    loading: true
  })
  async fetchSupplementData() {
    const api = new EAGetSupplementCreationDataApi();
    const supplementData = await api.getSupplementCreationDataOperation({
      getSupplementCreationDataRequest: {
        codigoPoliza: this.model.offerNumber,
        versionPoliza: this.model.offerVersion,
        codigoRamo: this.productFactory.codigoRamo as unknown as GetSupplementCreationDataRequestCodigoRamoEnum
      }
    });

    // Display errors if any
    throwIfResponseHasErrors(supplementData as ResponseWithErrors);

    if (supplementData) {
      this.supplementData = supplementData;
    }
  }

  /**
   * Call to generate new supplement movement.
   * Then, if response is OK, navigate to next step.
   */
  @EAMethod({
    loading: true
  })
  async generateSupplementMovement(): Promise<void> {
    const api = new EAGetNewSupplementMovementApi();
    const result = await api.getNewSupplementMovementOperation({
      getNewSupplementMovementRequest: {
        codigoPoliza: this.model.offerNumber,
        versionPoliza: this.model.offerVersion,
        tipoOperacionSuplemento: this.model.supplementReasonModel.isExpiration
          ? GetNewSupplementMovementRequestTipoOperacionSuplementoEnum.SuplementoVencimiento
          : GetNewSupplementMovementRequestTipoOperacionSuplementoEnum.Suplemento,
        motivoMovimientoPoliza: this.model.supplementReasonModel.movementReason,
        codigoComercialProducto: this.supplementData.codigoComercialProducto || '',
        codigoTecnicoProducto: this.supplementData.codigoTecnicoProducto || '',
        codigoIntermediario: this.model.supplementReasonModel.intermediaryCode,
        fechaEfectoMovimiento: this.formatDateToBff(this.model.supplementReasonModel.effectDate as Date),
        fechaVencimiento: this.formatDateToBff(this.model.supplementReasonModel.dueDate),
        codigoNegocio: this.model.supplementReasonModel.businessCode || undefined,
      }
    });

    if (result) {
      throwIfResponseHasErrors(result as ResponseWithErrors);

      if (result.versionPoliza) { // We assume version cannot be 0. Always >= 1
        // Set new version and dates into model
        this.model.offerVersion = result.versionPoliza;
        this.update();

        // Clear Notifications before going next step
        NotificationsUtils.clearNotifications();

        // Go to next step (general data step)
        this.$emit('changeStep', FlowViewsStepsModel.GeneralDataStep, FlowHeaderStepsModel.GeneralDataStep);
      }
    }
  }

  /**
   * Format date to 'YYYY-MM-DD' format.
   * @param {Date} date 
   * @returns {string} date formatted
   */
  formatDateToBff(date: Date): string {
    if (date === undefined || !moment(date).isValid()) {
      return '';
    }
    return moment(date).format('YYYY-MM-DD');
  }

  /**
   * Show not last version warning message, 
   * Reminder for the user that warns about the need of updating later versions
   */
  showRememberToUpdateWarning() {
    const ultimaLastSituation = 'ULTIMA';
    if (this.model.supplementReasonModel.lastSituation !== undefined &&
      this.model.supplementReasonModel.lastSituation !== ultimaLastSituation) {
      NotificationsUtils.launchNotifications([{
        title: NotificationsTypeEnum.Warning,
        message:
          this.$t('policyMaintenanceFlow.supplements.alerts.warning.rememberToUpdateWarningDescription').toString(),
        type: NotificationsTypeEnum.Warning
      }]);
      this.$forceUpdate();
      this.$nextTick(() => {
        const supplementReasonForm = this.$refs.supplementReasonForm as QbSupplementMovementReasonBusiness;
        if (supplementReasonForm) {
          supplementReasonForm.update();
        }
        this.update();
      });
    }
  }

  /**
   * Emits goToPreviousFlow event
   */
  goToPreviousFlow() {
    this.$emit('goToPreviousFlow');
  }

}
</script>
