/** 
 * Model fleet-issuance-data
 */
class FleetIssuanceDataModel {
  public fechaEfectoMovimiento: Date | undefined;

  public fechaVencimientoFlota: Date | undefined;
}

export default FleetIssuanceDataModel;
