import {
  FleetSummaryView
} from './fleet-summary-view-model';

/**
 * 
 */
export class FleetsMaintenancePolicy {

  public fleetSummaryView: FleetSummaryView = new FleetSummaryView();
}
