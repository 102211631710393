/* eslint-disable */

/**
 * zonazurich
 * Portal de Mediación Digital
 *
 * The version of the OpenAPI document: 1
 * Contact: digitalarchitecture@zurich.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import {
  EASDKConfig,
  EAUrlUtils
} from '@zurich-es-npm/ea-shared-utils-js';

import * as globalImportUrl from 'url';
import {
  Configuration
} from './configuration';
import globalAxios, {
  AxiosPromise,
  AxiosInstance,
  AxiosResponse
} from 'axios';

const BASE_PATH = EAUrlUtils.removeLastForwardSlash('http://localhost:3000');

/**
 *
 * @export
 */
export const COLLECTION_FORMATS = {
  csv: ',',
  ssv: ' ',
  tsv: '\t',
  pipes: '|',
};

/**
 *  
 * @export
 * @interface RequestArgs
 */
export interface RequestArgs {
  url: string;
  options: any;
}

/**
 * 
 * @export
 * @class BaseAPI
 */
export class BaseAPI {
  protected configuration: Configuration | undefined;

  /**
   *Creates an instance of BaseAPI.
   * @param {Configuration} [configuration]
   * @param {string} [basePath=BASE_PATH]
   * @param {AxiosInstance} [axios=globalAxios]
   * @memberof BaseAPI
   */
  public constructor(
    configuration?: Configuration,
    protected basePath: string = BASE_PATH,
    protected axios: AxiosInstance = globalAxios) {
    if (configuration) {
      this.configuration = configuration;
      this.basePath = configuration.basePath || this.basePath;
    }
  }
}

/**
 * 
 * @export
 * @class RequiredError
 * @extends {Error}
 */
export class RequiredError extends Error {
  public name: 'RequiredError' = 'RequiredError';

  public field: string;

  /**
   *Creates an instance of RequiredError.
   * @param {string} field
   * @param {string} [msg]
   * @memberof RequiredError
   */
  public constructor(field: string, msg?: string) {
    super(msg);
    this.field=field;
  }
}

/**
 * 
 * @export
 * @class AsyncRequestError
 * @extends {Error}
 */
export class AsyncRequestError extends Error {
  public name: 'AsyncRequestError' = 'AsyncRequestError';

  /**
   *Creates an instance of AsyncRequestError.
   * @param {string} field
   * @param {string} [msg]
   * @memberof AsyncRequestError
   */
  public constructor(msg?: string) {
    super(msg);
  }
}

/**
 * 
 * @export
 * @interface Error
 */
export interface Error {
    /**
     * 
     * @type {string}
     * @memberof Error
     */
    code: string;
    /**
     * 
     * @type {string}
     * @memberof Error
     */
    message: string;
    /**
     * 
     * @type {number}
     * @memberof Error
     */
    severity: number;
    /**
     * 
     * @type {Array<object>}
     * @memberof Error
     */
    childErrors?: Array<object>;
}/**
 * 
 * @export
 * @interface RevokeCancelPolicyRequest
 */
export interface RevokeCancelPolicyRequest {
    /**
     * 
     * @type {string}
     * @memberof RevokeCancelPolicyRequest
     */
    codigoPoliza: string;
    /**
     * 
     * @type {number}
     * @memberof RevokeCancelPolicyRequest
     */
    versionPoliza: number;
}/**
 * 
 * @export
 * @interface RevokeCancelPolicyResponse
 */
export interface RevokeCancelPolicyResponse {
    /**
     * 
     * @type {number}
     * @memberof RevokeCancelPolicyResponse
     */
    codigoRetorno?: number;
    /**
     * 
     * @type {Array<Error>}
     * @memberof RevokeCancelPolicyResponse
     */
    errors?: Array<Error>;
}

type revokeCancelPolicyOperationParams = {
  revokeCancelPolicyRequest: RevokeCancelPolicyRequest,
};

/**
 * RevokeCancelPolicyApi - axios parameter creator
 * @param {Configuration} [configuration]
 * @returns {any}
 */
const RevokeCancelPolicyApiAxiosParamCreator = function(configuration?: Configuration): any {
  return {
    
    /**
     * Cancela el movimiento pendiente anulación de póliza
     * @param {RevokeCancelPolicyRequest} revokeCancelPolicyRequest 
     * @param {string} [acceptLanguage] El Accept-Language request HTTP header indica en que lenguaje debe responder el backend.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @returns {RequestArgs}
     */
    revokeCancelPolicyOperation(params: revokeCancelPolicyOperationParams, options: any = {}): RequestArgs {
      // Verify required parameter 'revokeCancelPolicyRequest' is not null or undefined
      if (params.revokeCancelPolicyRequest === null || params.revokeCancelPolicyRequest === undefined) {
        throw new RequiredError('revokeCancelPolicyRequest', 'Required parameter revokeCancelPolicyRequest was null or undefined when calling revokeCancelPolicyOperation.');
      }
      const localVarPath = `/v1/policy/revokeCancelPolicyOperation`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true) as Partial<globalImportUrl.UrlWithParsedQuery>;
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;


  
      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
      // Fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
      const needsSerialization = ('RevokeCancelPolicyRequest' as any !== 'string') ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization ? JSON.stringify(params.revokeCancelPolicyRequest || {}) : params.revokeCancelPolicyRequest || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * RevokeCancelPolicyApi - functional programming interface
 * @param {Configuration} [configuration]
 * @returns {any}
 */
const RevokeCancelPolicyApiFp = function(configuration?: Configuration): any {
  return {
    
    /**
     * Cancela el movimiento pendiente anulación de póliza
     * @param {RevokeCancelPolicyRequest} revokeCancelPolicyRequest 
     * @param {string} [acceptLanguage] El Accept-Language request HTTP header indica en que lenguaje debe responder el backend.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @returns {any}
     */
    revokeCancelPolicyOperation(params: revokeCancelPolicyOperationParams, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<RevokeCancelPolicyResponse> {
      const localVarAxiosArgs = RevokeCancelPolicyApiAxiosParamCreator(configuration).revokeCancelPolicyOperation(params, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH): any => {
        const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * RevokeCancelPolicyApi - factory interface
 * @param {Configuration} configuration
 * @param {string} basePath
 * @param {AxiosInstance} axios
 * @export
 * @returns {any}
 */
const RevokeCancelPolicyApiFactory = function(configuration?: Configuration, basePath?: string, axios?: AxiosInstance): any {
  return {
    
    /**
     * Cancela el movimiento pendiente anulación de póliza
     * @param {RevokeCancelPolicyRequest} revokeCancelPolicyRequest 
     * @param {string} [acceptLanguage] El Accept-Language request HTTP header indica en que lenguaje debe responder el backend.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @returns {any}
     */
    revokeCancelPolicyOperation(params: revokeCancelPolicyOperationParams, options?: any): any {
      return RevokeCancelPolicyApiFp(configuration).revokeCancelPolicyOperation(params, options)(axios, basePath);
    },
  };
};

/** 
 * RevokeCancelPolicyApi - object-oriented interface
 * @export
 * @class RevokeCancelPolicyApi
 * @extends {BaseAPI}
 */
export class RevokeCancelPolicyApi extends BaseAPI {
  
  /**
   * Cancela el movimiento pendiente anulación de póliza
   * @param {RevokeCancelPolicyRequest} revokeCancelPolicyRequest 
   * @param {string} [acceptLanguage] El Accept-Language request HTTP header indica en que lenguaje debe responder el backend.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @returns {any}
   * @memberof RevokeCancelPolicyApi
   */
  public revokeCancelPolicyOperation(params: revokeCancelPolicyOperationParams, options?: any): any {
    return RevokeCancelPolicyApiFp(this.configuration).revokeCancelPolicyOperation(params, options)(this.axios, this.basePath);
  }

}

/**
 * 
 * @export
 * @class EArevokeCancelPolicyOperationInputMessage
 */
export class EArevokeCancelPolicyOperationInputMessage {
  
  /**
   * 
   * @type {RevokeCancelPolicyRequest}
   * @memberof EArevokeCancelPolicyOperationInputMessage
   */
  public revokeCancelPolicyRequest: RevokeCancelPolicyRequest;

  public constructor(revokeCancelPolicyRequest: RevokeCancelPolicyRequest, ){
  
  this.revokeCancelPolicyRequest=revokeCancelPolicyRequest;
  } 
}



/** 
 * EARevokeCancelPolicyApi - Architecture client for revokeCancelPolicyOperation
 * @export
 * @class EARevokeCancelPolicyApi
 */
export class EARevokeCancelPolicyApi {

  /**
   * 
   * @param {RevokeCancelPolicyRequest} revokeCancelPolicyRequest
   
   * @throws {AsyncRequestError | RequiredError}
   * @returns {Promise<RevokeCancelPolicyResponse | null>}
  */ 
  public async revokeCancelPolicyOperation(params: revokeCancelPolicyOperationParams): Promise<RevokeCancelPolicyResponse | null> {
    return this.revokeCancelPolicyOperationPromise(params);
  }

  /**
   * 
   * @param {RevokeCancelPolicyRequest} revokeCancelPolicyRequest
   
   * @param {Function} callback
   * @throws {AsyncRequestError | RequiredError}
   */
  public async revokeCancelPolicyOperationSteps(params: revokeCancelPolicyOperationParams, callback: (res: AxiosResponse<RevokeCancelPolicyResponse | null>) => void) {
    let done: boolean = false;
    const httpClient: AxiosInstance = EASDKConfig.getHttpClient('/v1/policy/revokeCancelPolicyOperation', 'revokeCancelPolicy', 'revokeCancelPolicyOperation');
    const config: Configuration = {};
    const api = new RevokeCancelPolicyApi(config, '', httpClient);
    const sleep = async(ms: number) => {
      return new Promise(resolve => setTimeout(resolve, ms));
    };
    const headers: { ['Async-Id']?: string } = {};
    while (!done) {
      const output = await api.revokeCancelPolicyOperation(params, { headers }) as AxiosResponse<RevokeCancelPolicyResponse | null>;
      callback(output);
      if (output.status === 202) {
        try {
          const retry = parseInt(output.headers['retry']);
          headers['Async-Id'] = output.headers['request-id'];
          if (isNaN(retry) || retry <= 0 || retry > 1000 * 3600) {
            throw new Error(`Retry time not acceptable (in milliseconds): ${retry}`);
          }
          await sleep(retry);
        } catch (err) {
          throw new Error(`Error in Async Request ${err}`);
        }
      } else {
        done = true;
      }
    }
  }

  /**
   * 
   * @param {RevokeCancelPolicyRequest} revokeCancelPolicyRequest
   
   * @throws {AsyncRequestError | RequiredError}
   * @returns {Promise<RevokeCancelPolicyResponse | null>}
   */
  public async revokeCancelPolicyOperationPromise(params: revokeCancelPolicyOperationParams): Promise<RevokeCancelPolicyResponse | null> {
    return new Promise((resolve, reject) => {
      this.revokeCancelPolicyOperationSteps(params, (response: AxiosResponse<RevokeCancelPolicyResponse | null>) => {
        if (response.status !== 202) {
          resolve(response.data);
        }
      })
      .catch((err) => {
        reject(err);
      });
    });
  } 
}


