
import {
  EAContextManager
} from '@zurich-es-npm/ea-front-web-core';

/**
 * Check pivot browser compatibility
 *
 * @class EAPivotCompatibility
 */
class EAPivotCompatibility {

  // IE platform name
  private static BROWSER_NAME_IE = 'IE';

  // Safari platform name
  private static BROSER_NAME_SAFARI = 'Safari';

  /**
   * Verify if the browser can use the iframe
   *
   * @static
   * @returns {boolean}
   * @memberof EAPivotCompatibility
   */
  public static canUseIframe(): boolean {

    const context = EAContextManager.getInstance();
    const platformContext = context ? context.getPlatformContext() : null;
    
    const platformInfo = platformContext ? platformContext.platformInfo : null;

    const name = platformInfo ? platformInfo.name: null;
    if (
      name === EAPivotCompatibility.BROWSER_NAME_IE
    ) {
      return false;
    } else if (
      name === EAPivotCompatibility.BROSER_NAME_SAFARI
    ) {
      return EAPivotCompatibility.firstTimeOpenTab(context);
    }

    return true;
  }

  /**
   * Fisrt time open a tab, next times use iframe
   *
   * @static
   * @param {EAContextManager} context
   * @returns {boolean}
   * @memberof EAPivotCompatibility
   */
  private static firstTimeOpenTab(context: EAContextManager): boolean {

    let hasOpenedZZFlow = false;
    try {
      hasOpenedZZFlow = context.getSessionValueAsBoolean('coexistence.openedFlow');
    } catch {
      hasOpenedZZFlow = false;
    }
    if (hasOpenedZZFlow) {
      return true;
    } else {
      context.setSessionValue('coexistence.openedFlow', true);
      return false;
    }
  }

}

export {
  EAPivotCompatibility
};
