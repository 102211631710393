<!-- eslint-disable max-lines -->
<template>
  <div>
    <!-- Warranty search & Gestión de garantías -->
    
    <warranties-header
      v-if="isComponentLoaded"
      id="warrantiesHeader"
      ref="warrantiesHeader"
      v-model="warrantiesHeaderModel"
      :warrantiesGroups="model.productModel.warrantyGroupList"
      :showWarrantiesGroups="productFactory.warrantiesHeaderConfig.showWarrantiesGroups"
      :canSelectMultipleWarrantiesGroups="productFactory.warrantiesHeaderConfig.canSelectMultipleWarrantiesGroups"
      :warrantiesGroupsDisplayMode="productFactory.warrantiesHeaderConfig.warrantiesGroupsDisplayMode"
      :packagesGroups="packagesGroups"
      :showPackagesGroups="productFactory.warrantiesHeaderConfig.showPackagesGroups"
      :canSelectMultiplePackagesGroups="productFactory.warrantiesHeaderConfig.canSelectMultiplePackagesGroups"
      :packagesGroupsDisplayMode="productFactory.warrantiesHeaderConfig.packagesGroupsDisplayMode"
      :canLoadMoreOptions="false"
      :consultaOperation="consultaOperation"
      @callTarification="onHeaderRetarification"
      @showEmptyWarrantiesMessage="showEmptyWarrantiesMessage"
    >
      <div class="w-25 d-flex d-direction-column">
        <!-- Slot for searcher + proficiency mgmt -->
        <ea-form
          ref="form"
          :model="searchTermForm"
          :validationOptions="searchFormValidationOptions"
        >
          <ea-form-item :label="$t('common.label.search')" prop="searchTerm">
            <div class="d-display-flex d-align-items-center">
              <ea-input-text
                :disabled="!!emptyWarrantiesMessage"
                v-model="searchTermForm.searchTerm"
                :placeholder="$t('warranties.shared.searchPlaceholder')"
                @focus="onSearchTermFocusGain"
                @blur="onSearchTermFocusLost"
                @input="onSearchTermInput"
              ></ea-input-text>
            </div>
          </ea-form-item>
        </ea-form>
      
        <qb-proficiency-management
          id="qb-proficiency-management"
          v-model="model.proficiencyManagementModel"
          ref="proficiencyMgmtTop"
          :checkGestionCompetencias="model.pendingCompetenceManagement"
          :proficiencyManagementList="proficiencyManagementList"
          :originFlow="'quoteAndBuy'"
          :productFactory="productFactory"
          @retrieveProficiencyManagementData="refreshProficiencyManagementData('proficiencyMgmtTop')"
          @close="closeProficiencyManagementDialog('proficiencyMgmtTop')"
        ></qb-proficiency-management>
      </div>
    </warranties-header>
    
    
    <ea-row v-if="emptyWarrantiesMessage">
      <ea-col :sm="24" :md="24" :lg="24" :xl="24" class="m-b-24">
        <ea-card>
          <ea-result
          :title="emptyWarrantiesMessage"
          :description="$t('warranties.refreshCard.description')"
          :type="'empty'"
          icon="z-boat"/>
        </ea-card>
      </ea-col>
    </ea-row>
    <template v-else>
    <!-- Warranties header -->
    <ea-row class="t-bg-color-primary-20 m-b-16 p-x-24 p-y-16 t-weight-bold">
      <ea-col :span="calculateSpans" :class="getHeaderPadding()">
        <ea-heading level="5">
          {{ $t('warranties.shared.warrantyTitle') }}
        </ea-heading>
      </ea-col>
      <ea-col v-for="(proposal, index) of proposalsData"
      :key="index" :span="calculateSpans" class="font-size-bold t-align-center">
        <ea-heading level="5">
          {{ getTitleProposal(index, proposal) }}
        </ea-heading>
      
        <div :class="getHeaderPadding(true)"
          v-if="proposal.codigoPropuesta">
          <span class="m-b-16 t-weight-bold"
            :class="{
              't-color-primary': showingProposalPrice[proposal.codigoPropuesta],
              't-color-warning': !showingProposalPrice[proposal.codigoPropuesta]
            }"
          >
            {{ showingProposalPrice[proposal.codigoPropuesta] && model.warrantyRates[proposal.codigoPropuesta] ?
              `${getProposalPrice(proposal)} €` : '-'
            }}
          </span>
        </div>
      </ea-col>
    </ea-row>
    <!--  -->

    <!-- Warranty business components -->
    <ea-row v-for="(warrantyData, index) in warrantiesData" :key="warrantyData.codigoGarantia" class="m-b-16">
      <ea-col>
        <!-- Title -->
        <h4 v-if="warrantiesData && $te(`warranties.${warrantyData.codigoGarantia}.title`)">
          {{ $t(`warranties.${warrantyData.codigoGarantia}.title`) }}
        </h4>
        <ea-card shadow="hidden" class="warranty-card">
          <div slot="cardbody">
            <warranty-business v-if="warrantiesData"
              :ref="warrantyData.codigoGarantia"
              :id="index"
              :columnsSpan="calculateSpans"
              v-model="warrantiesData[index]"
              :warrantiesData="warrantiesData"
              :eventsEnabled="eventsEnabled"
              :allProposalCodes="model.warrantiesProposals"
              :productFactory="productFactory"
              @qbWarrantyChange="onQbWarrantyChange"
              @qbWarrantyReset="onQbWarrantyReset"
            />
          </div>
        </ea-card>
      </ea-col>
    </ea-row>
    <!--  -->

    <!-- Premium adjustments -->
    <ea-row v-if="premiumAdjustmentsData && premiumAdjustmentsData.length" class="m-b-0">
      <ea-col>
        <ea-card>
          <div slot="cardbody" class="premium-adjustments-container">
            <ea-row>
              <ea-col :span="calculateSpans" class="border-right-lightGrey p-a-16 p-l-28">
                <qb-premium-adjustments
                  id="qb-general-data-information"
                  ref="premiumAdjustmentsComp"
                  v-model="model.premiumAdjustmentsModel"
                  :premiumAdjustmentsData="premiumAdjustmentsData"
                  :readOnly="consultaOperation"
                  @qbWarrantyReset="onQbWarrantyReset"
                ></qb-premium-adjustments>
              </ea-col>
              <ea-col v-for="(proposal, index) of proposalsData"
                :key="index"
                :span="calculateSpans"
                class="proposals"
              >
                <div
                  class="d-display-flex d-direction-column d-align-items-center m-t-64 p-t-32 price-container"
                  v-if="proposal.codigoPropuesta"
                >
                  <span class="option-rate m-b-16 t-weight-bold"
                    :class="{
                      't-color-primary': showingProposalPrice[proposal.codigoPropuesta],
                      't-color-warning': !showingProposalPrice[proposal.codigoPropuesta]
                    }"
                  >
                    {{ showingProposalPrice[proposal.codigoPropuesta] && model.warrantyRates[proposal.codigoPropuesta] ?
                      `${getProposalPrice(proposal)} €` : '-'
                    }}
                  </span>
                  <ea-button
                    v-if="viewContractButton(proposal)"
                    type="primary"
                    size="medium"
                    @click="onRateSelect(proposal.codigoPropuesta)"
                  >
                    {{ $t('warranties.toContract') }}
                  </ea-button>
                  <ea-button
                    v-if="viewRequoteButton(proposal)"
                    type="warning"
                    size="medium"
                    @click="onRequote()"
                  >
                    {{ $t('warranties.pricing') }}
                  </ea-button>
                </div>
              </ea-col>
            </ea-row>
            <ea-row class="fractional-payment">
              <ea-col
                :offset="calculateSpans"
                :span="calculateSpans * (numberOfColumns - 1)"
                class="t-align-center fractional-payment-button border-left-lightGrey">
                <ea-button
                  class="d-display-flex d-justify-center"
                  type="text" @click="openFractionalPaymentDialog"
                  v-if="fractionalPaymentLinkShown"
                >
                  {{ $t('warranties.fractionalPayment') }}
                </ea-button>
              </ea-col>
            </ea-row>
          </div>
        </ea-card>
      </ea-col>
    </ea-row>
    <!--  -->
    </template>
    <!-- View Buttons-->
    
    <div class="d-display-flex d-justify-space-between m-t-24">
      <div class="d-display-flex d-align-items-center">
        <ea-button type="secondary" @click="onGoBack()">
          {{ $t('common.label.back') }}
        </ea-button>
      </div>
      <div class="d-display-flex d-align-items-center" v-if="!consultaOperation">
        <qb-proficiency-management
          id="qb-proficiency-management"
          class="m-r-24"
          v-model="proficiencyManagementModelCopy"
          :checkGestionCompetencias="model.pendingCompetenceManagement"
          :proficiencyManagementList="proficiencyManagementList"
          ref="proficiencyMgmtBottom"
          :originFlow="'quoteAndBuy'"
          :productFactory="productFactory"
          @retrieveProficiencyManagementData="refreshProficiencyManagementData('proficiencyMgmtBottom')"
          @close="closeProficiencyManagementDialog('proficiencyMgmtBottom')"
        ></qb-proficiency-management>

        <ea-button
          type="secondary"
          :disabled="generateDocDisabled"
          @click="onGoToGenerateDocumentation"
        >
          {{ $t('warranties.generateDocumentation') }}
        </ea-button>
      </div>
    </div>

    <!-- Fractional Payment Business Component -->
    <qb-fractional-payment-modal
      id="fractionalPayment"
      v-model="model.fractionalPaymentModel"
      :productFactory="productFactory"
    ></qb-fractional-payment-modal>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import {
  Component, Prop
} from 'vue-property-decorator';
import {
  mixins
} from 'vue-class-component';
import {
  GetWarrantiesResponseDataDatosGarantias,
  GetWarrantiesResponseDataDatosPropuestas,
  GetWarrantiesResponseData,
  EAGetWarrantiesApi,
  GetWarrantiesRequestTipoPolizaEnum,
  GetWarrantiesResponseDataDatosAjustesPrimas,
  GetWarrantiesResponseDataDatosTarificacion,
  GetWarrantiesResponseDataTablaRestricciones,
  GetWarrantiesResponseDataElementosGarantias
} from '@/services/V1/quoteAndBuy/getWarrantiesOperation/post';
import {
  EAError,
  EAMethod, EAMultiError, EAValidationError, EAView, ResponseWithErrors, throwIfResponseHasErrors
} from '@zurich-es-npm/ea-front-web-core';
import {
  codeMessageFicoFic,
  ErrorCodesGoBack,
  FlowHeaderStepsModel,
  FlowViewsStepsModel,
  QuoteBuyModel
} from '../quote-buy-model';
import WarrantyBusiness from '@/business-components/qb-warranty/warranty-business.vue';
import QbProficiencyManagementBusiness
  from '@/business-components/qb-proficiency-management/qb-proficiency-management-business.vue';
import QbPremiumAdjustmentsBusiness
  from '@/business-components/qb-premium-adjustments/qb-premium-adjustments-business.vue';
import {
  EventChange, ActionType, WarrantyElement
} from '@/business-components/qb-warranty/warranty.types';
import {
  EAUpdateWarrantiesAndRateApi,
  UpdateWarrantiesAndRateRequestDatosGarantias,
  UpdateWarrantiesAndRateRequestTipoPolizaEnum,
  UpdateWarrantiesAndRateRequestDatosPropuestas,
  UpdateWarrantiesAndRateRequestDatosPropuestasTipoMascaraEnum as TipoMascaraEnum,
  UpdateWarrantiesAndRateResponse,
  UpdateWarrantiesAndRateResponseDatosGarantias,
} from '@/services/V1/quoteAndBuy/updateWarrantiesAndRateOperation/post';
import QbFractionalPaymentModalBusiness from
  '@/business-components/qb-fractional-payment-modal/qb-fractional-payment-business-modal.vue';
import {
  FractionalPaymentDataModel
} from '@/business-components/qb-fractional-payment-modal/qb-fractional-payment-modal-model';
import WarrantyFieldUtils from '@/business-components/qb-warranty/warranty-field-utils';
import {
  cloneDeep
} from 'lodash';
import {
  EAContractOfferApi
} from '@/services/V1/quoteAndBuy/contractOfferOperation/post';
import {
  EAUpdateWarrantyGroupHogarApi,
  UpdateWarrantyGroupHogarRequest,
  UpdateWarrantyGroupHogarRequestTipoPolizaEnum
} from '@/services/V1/quoteAndBuy/updateWarrantyGroupHogarOperation/post';
import {
  EACompositeValidation,
  eaCustomValidation,
  EAFormValidationOptions,
  EAValidation,
  Form
} from '@zurich-es-npm/ea-front-web-ui';
import {
  EAGetCompetencesManagementByOfferApi,
  GetCompetencesManagementByOfferResponseCompetencesManagementByOfferData
} from '@/services/V1/quoteAndBuy/getCompetencesManagementByOfferOperation/post';
import {
  EAGetFractionalPaymentsApi,
  GetFractionalPaymentsRequestCodigosPropuesta,
  GetFractionalPaymentsRequestTipoFormaPagoTarificacionGarantiasEnum as GetFractionalTipoFormaPagoEnum,
  GetFractionalPaymentsRequestTipoPolizaEnum,
  GetFractionalPaymentsResponseDatosTarificacion,
  GetFractionalPaymentsResponseDatosTarificacionFormaPagoTarificacionEnum as FractionalFormaPagoTarificacionEnum
} from '@/services/V1/quoteAndBuy/getFractionalPaymentsOperation/post';
import {
  GetGeneralDataRequestTipoOperacionOfertaEnum as OperationType,
} from '@/services/V1/quoteAndBuy/getGeneralDataOperation/post';
import {
  EAGetWarrantiesConsultationModeApi,
  GetWarrantiesConsultationModeRequestTipoPolizaEnum
} from '@/services/V1/quoteAndBuy/getWarrantiesConsultationModeOperation/post';
import ProductBase from '@/utils/quote-buy-product-factory/products/product-base';
import Utils from '@/utils/utils';
import PermissionUtils from '@/utils/permissions-utils';
import {
  PolicyMovementType
} from '@/flows/policy/policy-model';
import {
  EAValidatorFunction
} from '@zurich-es-npm/ea-front-web-core/lib/validation-rules';
import ZzCollapse from '@/presentational-components/collapse/collapse.vue';
import {
  NotificationsTypeEnum, NotificationsUtils
} from '@/utils/notifications/notifications-utils';
import TextUtils from '@/utils/text-utils';
import {
  Roles
} from '@/types/roles/roles-enum.types';
import {
  PolicyType
} from '@/types/policy-types/policy-types-enum.types';
import WarrantiesHeaderBusiness, {
  WARRANTIES_HEADER_DISPLAY_MODE,
  WARRANTIES_HEADER_SELECTIONS
} from '@/business-components/qb-warranty/warranties-header/warranties-header-business.vue';
import WarrantiesHeaderModel from '@/business-components/qb-warranty/warranties-header/warranties-header-model';
import {
  LabelValue
} from '@/business-components/qb-producto-asegurado-comunidades/qb-producto-asegurado-comunidades-model';

@Component({
  components: {
    QbProficiencyManagement: QbProficiencyManagementBusiness,
    WarrantyBusiness: WarrantyBusiness,
    QbPremiumAdjustments: QbPremiumAdjustmentsBusiness,
    QbFractionalPaymentModal: QbFractionalPaymentModalBusiness,
    WarrantiesHeader: WarrantiesHeaderBusiness
  },
})

/**
 * Quote-buy GeneralData view
 *
 */
export default class WarrantiesView extends mixins<EAView<QuoteBuyModel>>(EAView) {

  @Prop({
    required: true,
  })
    productFactory!: ProductBase;

  proficiencyManagementList?: GetCompetencesManagementByOfferResponseCompetencesManagementByOfferData[] = [];
  
  warrantiesData: GetWarrantiesResponseDataDatosGarantias[] = [];

  fractionalPaymentForms: GetWarrantiesResponseDataTablaRestricciones[] = [];

  premiumAdjustmentsData?: GetWarrantiesResponseDataDatosAjustesPrimas[] = [];

  showingProposalPrice: Record<string, boolean> = {}; // Relationship proposal/ready to buyout (for all proposals)

  pricingData: GetWarrantiesResponseDataDatosTarificacion[] = [];

  bannedPaymentForms = ['U'];

  isInitialTarification = true; // Flag to know if is the first time getting price

  showWarrantiesSearch = false;

  /*
   * Events will be disabled until user starts to make changes
   * It prevents prices to be resetted when creating form for first time
   * or when requote is triggered
   */
  eventsEnabled = false;

  // Search warranties variables
  searchTermForm = {
    searchTerm: ''
  };

  lastSearchTerm = '';

  searchInputHasFocus = false;

  scrollElementIndex = 0;

  proposalsData: GetWarrantiesResponseDataDatosPropuestas[] = [];

  warrantiesHeaderModel: WarrantiesHeaderModel = new WarrantiesHeaderModel();

  emptyWarrantiesMessage: string = '';

  retrievedProposalNumbers: string[] = [];

  isComponentLoaded: boolean = false;

  /**
   * Hook on created
   * @returns {Promise<void>}
   */
  @EAMethod({
    loading: true,
  })
  public async created(): Promise<void> {
    try {
      await this.loadComponentData();
    } catch (err) {
      this.onPostLoadData();
      throw err;
    }
    this.onPostLoadData();
  }

  /**
   * Set header + make it visible
   */
  public onPostLoadData(): void {
    this.setHeaderSelectedOptions();
    this.isComponentLoaded = true;
  }

  /**
   * Fetch warranties
   * @returns {Promise<void>}
   */
  @EAMethod()
  public async loadComponentData(): Promise<void> {
    this.resetInfoMessage();
    this.showFicoFicWarning();
    window.addEventListener('keydown', event => {
      (async() => {
        await this.onKeyPressed(event);
      })();
    });
    this.warrantiesData = [];
    if (this.consultaOperation) {
      await this.fetchWarrantiesConsultionMode();
    } else {
      await this._fetchWarranties();
    }
    this.emptyWarrantiesMessage = '';
    const warrantiesHeader = this.$refs.warrantiesHeader as WarrantiesHeaderBusiness;
    if (warrantiesHeader) {
      warrantiesHeader.hideRequoteButton();
    }
  }


  /**
   * Handles key pressed
   * Calls validateAndSearch if pressed key is Tabulator or Intro and focus is in search field
   * @param {KeyboardEvent} event
   */
  async onKeyPressed(event: KeyboardEvent) {
    if ((event.key === 'Tab' || event.key === 'Enter') && this.searchInputHasFocus) {
      event.preventDefault();
      await this.validateAndSearch();
    }
  }

  /**
   * Reset styles
   * @param {string} value
   */
  onSearchTermInput(value: string) {
    if (value.length === 0) {
      this.removeClassFromElements('highlighted');
      this.removeClassFromElements('scroll-highlighted');
    }
  }

  /**
   * Reset generalData info message
   */
  resetInfoMessage() {
    NotificationsUtils.clearNotifications();
  }

  /**
   *
   * @param {GetWarrantiesResponseDataDatosPropuestas} proposal
   * @return {string | undefined}
   */
  getProposalPrice(proposal: GetWarrantiesResponseDataDatosPropuestas): string | undefined {
    if (!proposal.codigoPropuesta) {
      return;
    }

    const warrantyRate = this.$n(+this.model.warrantyRates[proposal.codigoPropuesta], 'decimal');
  
    return Utils.formatFourDigitNumber(warrantyRate);
  }

  /**
   * Get proposal title
   * @param {number} index
   * @param {GetWarrantiesResponseDataDatosPropuestas} proposal
   * @returns {string}
   */
  getTitleProposal(index: number, proposal: GetWarrantiesResponseDataDatosPropuestas): string {
    if (this.productFactory.shouldShowProposalTitle) {
      if (this.productFactory.shouldShowCustomPackageName) {
        return this.$t(`warranties.packageName.${proposal.codigoPropuesta}`).toString();
      } else {
        return this.$t('warranties.shared.proposalTitleWithNumber', {
          index: index + 1
        }).toString();
      }
    }
    return '';
  }

  /**
   * Get header padding
   * @param {boolean} price
   * @returns {string | undefined}
   */
  getHeaderPadding(price?: boolean): string | undefined {
    if (this.proposalsData.length === 2) {
      return 'p-t-16';
    }

    // Center price when there is only one propouse
    if (price) {
      return 'p-t-8';
    }
  }

  /**
   * @description asiginación de los datos para su mostrado
   * @argument {GetWarrantiesResponseData | undefined} data
   */
  public async _assignData(data: GetWarrantiesResponseData | undefined) {
    if (data) {
      this.model.pendingCompetenceManagement = data.datosGestionCompetencias?.gestionDeCompetencias || false;
      this.showCompetenceWarning();
      this.model.productModel.warranty = data.agrupacion?.[0] ?? this.model.productModel.warranty;
      this.warrantiesData = data.datosGarantias || [];
      this.loadAllproposalCodes();
      this.pricingData = data.datosTarificacion || [];
      this.fillNonPricedProposalsIntoPricingData();
      this.fractionalPaymentForms = this.getSortedFractionalPaymentForms(
        data.fraccionamientoFormasPago
      );

      this.premiumAdjustmentsData = data.datosAjustesPrimas;
      this.recalculateShowingProposalPrices();
      this.recalculateWarrantyRates();

      this.isInitialTarification = false;
      this.retrievedProposalNumbers = data.codigosPropuestas ?? [];
      
    }
    this.enableEvents();
  }

  
  /**
   * Add an empty price into `pricingData` for the proposals we didn't received price data.
   */
  loadAllproposalCodes() {
    this.model.warrantiesProposals = [];
    this.proposalsData = [];
    this.warrantiesData.forEach(warrantyData => {
      warrantyData?.elementosGarantias?.forEach(warrantyElement => {
        warrantyElement.datosPropuestas?.forEach(proposalData => {
          if (proposalData.codigoPropuesta &&
              this.model.warrantiesProposals.indexOf(proposalData.codigoPropuesta) === -1) {
            this.proposalsData.push(proposalData);
            this.model.warrantiesProposals.push(proposalData.codigoPropuesta);
          }
        });
      });
    });
  }


  /**
   * Add an empty price into `pricingData` for the proposals we didn't received price data.
   */
  fillNonPricedProposalsIntoPricingData() {
    const allProposalCodes = this.model.warrantiesProposals;
  
    for (const proposal of allProposalCodes) {
      const foundData = this.pricingData.find(price => price.codigoPropuesta === proposal);
      if (!foundData) {
        this.pricingData.push({
          codigoPropuesta: proposal
        });
      }
    }
  }

  /**
   * Sorts payment forms based on custom order
   * @param {GetWarrantiesResponseDataTablaRestricciones[] | undefined} fraccionamientoFormasPago
   * @returns {GetWarrantiesResponseDataTablaRestricciones[]}
   */
  public getSortedFractionalPaymentForms(
    fraccionamientoFormasPago?: GetWarrantiesResponseDataTablaRestricciones[]
  ): GetWarrantiesResponseDataTablaRestricciones[] {
    if (!fraccionamientoFormasPago || !fraccionamientoFormasPago.length) {
      return [];
    }

    const order: Record<string, number> = {
      Unica: 1,
      Anual: 2,
      Semestral: 3,
      Trimestral: 4,
      Mensual: 5
    };

    fraccionamientoFormasPago.sort((elemA, elemB) => order[elemA.nombreRestriccion as keyof Record<string, number>] -
    order[elemB.nombreRestriccion as keyof Record<string, number>]);

    return fraccionamientoFormasPago.filter(
      elem => elem.valorRestriccion && !this.bannedPaymentForms.includes(elem.valorRestriccion)
    );
  }

  /**
   * Initializes status of select proposal buttons.
   *
   * Match incoming proposals in `datosGarantias` and `datosTarificacion`.
   *
   * For the ones matched and existing `importeTotalRecibo`, we set a `true` value and `false` for the other ones. 
   */
  recalculateShowingProposalPrices() {
    this.showingProposalPrice = {};
    for (const price of this.pricingData) {
      if (price.codigoPropuesta) {
        Vue.set(this.showingProposalPrice, price.codigoPropuesta, !!price.importeTotalRecibo);
      }
    }
  }

  /**
   * Initializes status of proposal prices.
   *
   * Set prize for the incoming proposals in `pricingData` (datosTarificacion). If a price is not received, set 0.
   */
  recalculateWarrantyRates() {
    this.model.warrantyRates = {};
    for (const price of this.pricingData) {
      if (price.codigoPropuesta) {
        Vue.set(this.model.warrantyRates, price.codigoPropuesta, price.importeTotalRecibo || 0);
      }
    }
  }

  /**
   * Checks if contract button is show
   * @param {GetWarrantiesResponseDataDatosPropuestas} proposal
   * @returns {boolean}
   */
  viewContractButton(proposal: GetWarrantiesResponseDataDatosPropuestas): boolean {
    if (this.consultaOperation) {
      return false;
    }
    if (!this.consultaOperation
      && this.showingProposalPrice[proposal.codigoPropuesta as string]
      && this.userHasAbilityToEmit()
    ) {
      return true;
    }
    return false;
  }

  /**
   * Checks if requote button is show
   * @param {GetWarrantiesResponseDataDatosPropuestas} proposal
   * @returns {boolean}
   */
  viewRequoteButton(proposal: GetWarrantiesResponseDataDatosPropuestas): boolean {
    if (this.consultaOperation) {
      return false;
    }
    if (!this.consultaOperation && !this.showingProposalPrice[proposal.codigoPropuesta as string]) {
      this.showHeaderRequoteButton();
      return true;
    }
    return false;
  }

  /**
   * Returns if user has ability to emit offers.
   * @returns {boolean} the ability to emit or not
   */
  userHasAbilityToEmit(): boolean {
    const EMIT_ABILITIES = ['quote-buy-emit', 'quote-buy-emit-partner'];
    const userAbilities = PermissionUtils.getUserAbilities();
    // Check if user has an emit ability
    return PermissionUtils.hasAnyOfAbilitiesList(userAbilities, EMIT_ABILITIES);
  }

  /**
   * Checks if the operation type is of type maintenence
   */
  get consultaOperation() {
    return this.model.operationType === OperationType.CONSULTA;
  }

  /**
   * Get number of columns
   *
   * The number of columns is calculated by adding 1 to warranty elements
   */
  get numberOfColumns() {
    return this.proposalsData.length + 1;
  }

  /**
   * Calculate spans
   *
   * Divide the max number of spans (24) by the number of warranty columns
   */
  get calculateSpans() {
    return 24 / this.numberOfColumns;
  }

  /**
   * Get packages codes
   *
   * @returns {string[]}
   */
  get keyPackagesLabels(): string[] {
    return this.proposalsData.map(datoPropuesta => `warranties.package.${datoPropuesta.codigoPropuesta}`);
  }

  /**
   * Get disabled state for generate documentation step
   *
   * @returns {boolean}
   */
  get generateDocDisabled(): boolean {
    if (this.isAnyPriceShown() && this.emptyWarrantiesMessage === '') {
      return false;
    } else {
      return true;
    }
  }

  /**
   * 
   */
  @EAMethod()
  async fetchWarrantiesConsultionMode(): Promise<void> {
    const api = new EAGetWarrantiesConsultationModeApi();
    const output = await api.getWarrantiesConsultationModeOperation({
      getWarrantiesConsultationModeRequest: {
        codigoPoliza: this.model.offerNumber,
        versionPoliza: this.model.offerVersion,
        tipoPoliza: GetWarrantiesConsultationModeRequestTipoPolizaEnum.Oferta
      }
    });
    if (output?.data) {
      this._assignData(output.data as GetWarrantiesResponseData);
    }
    throwIfResponseHasErrors(output as ResponseWithErrors);
  }

  /**
   * Method run in created() hook
   *
   * @returns {Promise<void>}
   */
  @EAMethod()
  public async _fetchWarranties(): Promise<void> {
    const api = new EAGetWarrantiesApi();
    const output = await api.getWarrantiesOperation({
      getWarrantiesRequest: {
        codigoPoliza: this.model.offerNumber,
        versionPoliza: this.model.offerVersion,
        tipoPoliza: GetWarrantiesRequestTipoPolizaEnum.Oferta,
      },
    });

    // Go back showing error if error forces going back
    const goBackError = output?.errors?.find(
      err => ErrorCodesGoBack.includes(err.code)
    );
    if (goBackError) {
      this.onGoBack();
      NotificationsUtils.launchNotifications([{
        title: NotificationsTypeEnum.Error,
        message: goBackError.message,
        type: NotificationsTypeEnum.Error
      }]);

      return;
    }

    if (output?.data) {
      this._assignData(output.data);
    }
    throwIfResponseHasErrors(output as ResponseWithErrors);
  }

  /**
   * Gets proposal codes for proposal with prices
   * @returns {GetFractionalPaymentsRequestCodigosPropuesta[] }
   */
  getProposalsWithPricesCodes(): GetFractionalPaymentsRequestCodigosPropuesta[] {
    const proposalCodes = [];

    for (const [key] of Object.entries(this.showingProposalPrice)) {
      if (this.showingProposalPrice[key]) {
        proposalCodes.push({
          codigoPropuesta: key
        });
      }
    }

    return proposalCodes;
  }

  /**
   * Return an array of proposal codes that we currently do not have price.
   * 
   * @returns {string[]}
   */
  getPlainArrayProposalsToPrice(): string[] {
    return Object.entries(this.showingProposalPrice)
      .filter(entry => !entry[1])
      .map(entry => entry[0]);
  }

  /**
   * Fetches fractional payments
   *
   * @returns {Promise<GetFractionalPaymentsResponseDatosTarificacion[] | undefined>}
   */
  @EAMethod({
    loading: true,
  })
  async fetchFractionalPayments(): Promise<GetFractionalPaymentsResponseDatosTarificacion[] | undefined> {
    const api = new EAGetFractionalPaymentsApi();
    const output = await api.getFractionalPaymentsOperation({
      getFractionalPaymentsRequest: {
        codigoPoliza: this.model.offerNumber,
        versionPoliza: this.model.offerVersion,
        tipoPoliza: GetFractionalPaymentsRequestTipoPolizaEnum.Oferta,
        tipoFormaPagoTarificacionGarantias: GetFractionalTipoFormaPagoEnum.Todas,
        codigosPropuesta: this.getProposalsWithPricesCodes()
      }
    });
      
    if (output) {
      throwIfResponseHasErrors(output as ResponseWithErrors);
      return output.datosTarificacion;
    }
  }

  
  /**
   * Fetches proficiency management list
   *
   * @returns {Promise<void>}
   */
  @EAMethod({
    loading: true,
  })
  async fetchProficiencyManagement():
  Promise<GetCompetencesManagementByOfferResponseCompetencesManagementByOfferData[]| undefined> {
    const api = new EAGetCompetencesManagementByOfferApi();
    const output = await api.getCompetencesManagementByOfferOperation({
      getCompetencesManagementByOfferRequest: {
        codigoPoliza: this.model.offerNumber,
        versionPoliza: this.model.offerVersion
      },
    });
    if (output?.errors) {
      throwIfResponseHasErrors(output as ResponseWithErrors);
    }
    if (output?.competencesManagementByOfferData) {
      return output.competencesManagementByOfferData;
    }
  }

  /**
   * Listener: select a rate.
   *
   * @param {string} codigoPropuesta
   */
  public async onRateSelect(codigoPropuesta: string | undefined): Promise<void> {
    if (codigoPropuesta) {
      const asegurado = this.productFactory.personRoles.filter(person => person.role === Roles.Asegurado)[0];
      asegurado.roleEqualStatements?.forEach(roleEqual => {
        roleEqual.readonly = true;
      });
      this.model.selectedProposal = codigoPropuesta;
      this.clearFlowErrors();
      const data = await this.fetchProficiencyManagement();
      this.model.pendingCompetenceManagement = false;
      if (data?.find(elem => elem.codigoPropuesta === codigoPropuesta) ||
        data?.find(elem => elem.codigoMCT === '0000000000')) { // 0000000000 is the code for all proposals
        this.model.pendingCompetenceManagement = true;
      }
      await this.callContractOffer();
    }
  }

  /**
   * Listener: unselect a rate.
   */
  public onRateUnselect(): void {
    this.model.selectedProposal = '';
  }

  /** 
   * Does a validation of warranties based on the Product Factory (PF).
   */
  public productWarrantiesValidation(): void {
    const errors = this.productFactory.validateWarranties(this.warrantiesData);
    if (errors && errors.length) {
      throw errors.length === 1 ? errors[0] : new EAMultiError(errors);
    }
  }

  /**
   * Disable Events
   * 
   */
  public disableEvents(): void {
    this.eventsEnabled = false;
  }

  /**
   * Enable Events
   * 
   */
  public enableEvents(): void {
    this.$nextTick(() => {
      this.eventsEnabled = true;
    });
  }

  /**
   * Listener for "retarificar" buttons.
   * Save and requote proposals. Then refresh warranties data.
   */
  public async onRequote() {
    this.disableEvents();
    this.onRateUnselect();
    this.clearFlowErrors(); // Clear errors section

    // Do previous validations
    try {
      this.productWarrantiesValidation();
      await this.validateWarrantyBusinessComp();
      await this.validatePremiumAdjustmentsComp();
    } catch (error) {
      // Enable warranty events again and throw validation errors
      this.enableEvents();
      throw error;
    }
    try {
      NotificationsUtils.clearNotifications();
      const data = await this.requoteProposals();
      if (data?.datosGarantias) {
        this.model.pendingCompetenceManagement =
          !!data.datosGestionCompetencias && data.datosGestionCompetencias.length > 0;
        this.showCompetenceWarning();
        this.showFicoFicWarning(true);
  
        scrollTo(0, 0);

        // Merge current warranties data with the incoming data
        this.warrantiesData = this.mergeWarrantyData(data.datosGarantias || []);

        // Update pricingData array 1 by 1
        const proposalsToPrice = this.getPlainArrayProposalsToPrice();
        const ensureDatosTarificacion = data.datosTarificacion || [];
        const matchedDatosTarif = proposalsToPrice.map(proposal => {
          let incomingPrice = ensureDatosTarificacion.find(tarif => tarif.codigoPropuesta === proposal);
          if (!incomingPrice) {
            incomingPrice = {
              codigoPropuesta: proposal
            };
          }
          return incomingPrice;
        });

        this.pricingData = this.pricingData.map(price => {
          const foundInMatched = matchedDatosTarif.find(tarif => tarif.codigoPropuesta === price.codigoPropuesta);
          return foundInMatched as GetWarrantiesResponseDataDatosTarificacion || price;
        });

        // Recalculate some data
        this.recalculateShowingProposalPrices();
        this.recalculateWarrantyRates();
      }
      throwIfResponseHasErrors(data as ResponseWithErrors);
      this.hideHeaderRequoteButton();
    } catch (error) {
      this.enableEvents();
      throw error;
    }
    this.enableEvents();
  }

  /**
   * Show competences warning message
   */
  showCompetenceWarning() {
    if (this.model.pendingCompetenceManagement) {
      NotificationsUtils.launchNotifications([{
        title: 'Proficiency',
        message: this.$t('warranties.proficiencyWarningDescription').toString(),
        type: NotificationsTypeEnum.Warning
      }]);
    }
  }

  /**
   * Show competences warning message
   * @param {boolean} requote
   */
  showFicoFicWarning(requote?: boolean): void {
    if (this.model.ficoFicError.length > 0) {
      this.model.ficoFicError.forEach(err => {
        NotificationsUtils.launchNotifications([{
          title: NotificationsTypeEnum.Warning,
          message: this.$t(`warranties.${codeMessageFicoFic[err]}`).toString(),
          type: NotificationsTypeEnum.Warning
        }]);
      });

      if (requote) {
        // Necessary wait when the user requote
        this.$nextTick(() => {
          this.update();
        });
      } else {
        // Show the message in created()
        this.update();
      }
    }
  }

  /**
   * Merge `warrantiesData` with incoming warranty data (from BFF).
   * The most important data is the incoming one.
   * 
   * @param {UpdateWarrantiesAndRateResponseDatosGarantias[]} incomingData
   * 
   * @returns {GetWarrantiesResponseDataDatosGarantias[]} merged data array
   */
  mergeWarrantyData(
    incomingData: UpdateWarrantiesAndRateResponseDatosGarantias[]
  ): GetWarrantiesResponseDataDatosGarantias[] {
    const currentWarranties = cloneDeep(this.warrantiesData);
    currentWarranties.forEach(currWarranty => {
      // Selected
      (currWarranty.garantiaSeleccionada || []).forEach((currSelected, indexSelected) => {
        const foundSelected = incomingData
          .find(prop => prop.codigoGarantia === currWarranty.codigoGarantia)?.garantiaSeleccionada?.
          find(sel => sel.codigoPropuesta === currSelected.codigoPropuesta);
        if (foundSelected) {
          (currWarranty.garantiaSeleccionada || []).splice(indexSelected, 1, {
            ...foundSelected
          });
        }
      });

      // Elements
      (currWarranty.elementosGarantias || []).forEach((currElement, indexElement) => {
        (currElement.datosPropuestas || []).forEach((currProposalData, indexProposal) => {
          const foundProposalData = incomingData
            .find(prop => prop.codigoGarantia === currWarranty.codigoGarantia)?.elementosGarantias?.
            find(elem => elem.codigoElemento === currElement.codigoElemento)?.datosPropuestas?.
            find(proposalData => proposalData.codigoPropuesta === currProposalData.codigoPropuesta);
          if (foundProposalData) {
            (currWarranty.elementosGarantias || [])[indexElement].datosPropuestas?.splice(indexProposal, 1, {
              ...foundProposalData
            });
          }
        });
      });
    });

    return currentWarranties;
  }

  /**
   * Validates premium adjustments component's form
   * If validation OK => The flow continues
   * If validation NOK => Throws EAValidationError
   */
  async validatePremiumAdjustmentsComp() {
    const premiumAdjustmentsComp: QbPremiumAdjustmentsBusiness =
      this.$refs.premiumAdjustmentsComp as QbPremiumAdjustmentsBusiness;

    try {
      await premiumAdjustmentsComp.validation().validate();
    } catch (error) {
      throw new EAValidationError(this.$t('common.label.validation.formWithErrors').toString());
    }
  }

  /**
   * Retrieves warranty-bussiness components list
   * @returns {WarrantyBusiness[]}
   */
  retrieveWarrantiesComponents(): WarrantyBusiness[] {
    const warrantiesComponentList: WarrantyBusiness[] = [];
    this.warrantiesData.forEach((warrantyData: GetWarrantiesResponseDataDatosGarantias) => {
      if (warrantyData.codigoGarantia) {
        const warrantyComponent = this.$refs[warrantyData.codigoGarantia] as WarrantyBusiness[];
        warrantiesComponentList.push(warrantyComponent[0]);
      }
    });
    return warrantiesComponentList;
  }

  /**
   * Validates WarrantyBusiness component's forms
   * If validation OK => Updates model
   * If validation NOK => Throws EAValidationError
   */
  async validateWarrantyBusinessComp() {
    const warrantiesComponentList = this.retrieveWarrantiesComponents();
    let isError = false;
    const compositeValidation: EACompositeValidation = new EACompositeValidation(
      [], this.$tc.bind(this)
    );

    for (const warrantyComponent of warrantiesComponentList) {
      const form = warrantyComponent.$refs[`form-${warrantyComponent.model.codigoGarantia}`] as Form;
      if (form) {
        compositeValidation.add(form.validation());
      }
    }
    
    try {
      await compositeValidation.validate();
    } catch (error) {
      isError = true;
    }
    if (isError) {
      throw new EAValidationError(this.$t('common.label.validation.formWithErrors').toString());
    }
  }

  /**
   * Save and requote proposals call.
   */
  @EAMethod({
    loading: true
  })
  async requoteProposals(): Promise<UpdateWarrantiesAndRateResponse | null> {
    const datosGarantias: UpdateWarrantiesAndRateRequestDatosGarantias[] = cloneDeep(
      this.warrantiesData
    ) as UpdateWarrantiesAndRateRequestDatosGarantias[];

    this.filterWarrantiesDataOnlyNonPriceProposals(datosGarantias);

    const api = new EAUpdateWarrantiesAndRateApi();
    const response = await api.updateWarrantiesAndRateOperation({
      updateWarrantiesAndRateRequest: {
        codigoPoliza: this.model.offerNumber,
        versionPoliza: this.model.offerVersion,
        tipoPoliza: UpdateWarrantiesAndRateRequestTipoPolizaEnum.Oferta,
        datosGarantias: datosGarantias,
        ajusteComercialCotizacion: this.ajusteComercialCotizacion,
        tipoComision: this.model.premiumAdjustmentsModel.comissionType || ''
      }
    });
    return response;
  }

  /**
   * Get the calculated percentage (discount) user informed in premium adjustments (ajusteComercialCotizacion)
   */
  get ajusteComercialCotizacion(): number {
    const discountValue = this.model.premiumAdjustmentsModel.discountValue || 0;
    const discountType: 1 | -1 = this.model.premiumAdjustmentsModel.discountType === '-1' ? -1 : 1;
    return discountValue * discountType;
  }

  /**
   * Filter only the warranties for the proposals that prize is not in screen.
   * 
   * Note: Additionally, parse each warranty element and set a default value for empty timascar properties.
   * 
   * @param {UpdateWarrantiesAndRateRequestDatosGarantias[]} warranties
   */
  filterWarrantiesDataOnlyNonPriceProposals(
    warranties: UpdateWarrantiesAndRateRequestDatosGarantias[]
  ): void {
    const proposalsToPrice = this.getPlainArrayProposalsToPrice();

    for (const warranty of warranties) {
      // eslint-disable-next-line no-extra-parens
      warranty.garantiaSeleccionada =
        warranty.garantiaSeleccionada?.filter(select => proposalsToPrice.indexOf(select.codigoPropuesta || '') !== -1)
        || [];
      for (const element of warranty.elementosGarantias || []) {
        element.datosPropuestas =
          element.datosPropuestas?.filter(
            proposalElement => proposalsToPrice.indexOf(proposalElement.codigoPropuesta || '') !== -1
          );
        this.setDefaultTimascarToElements(element.datosPropuestas);
      }
    }
  }

  /**
   * Set default timascar to elements.
   * 
   * @param {UpdateWarrantiesAndRateRequestDatosPropuestas[] | undefined} elements
   */
  setDefaultTimascarToElements(elements: UpdateWarrantiesAndRateRequestDatosPropuestas[] | undefined) {
    if (!elements) {
      return;
    }
    for (const elem of elements) {
      elem.tipoMascara = elem.tipoMascara || TipoMascaraEnum.T; // Set default tipo mascara to 'T' if empty
    }
  }

  /**
   * Goes to previous step
   */
  onGoBack() {
    // ### HIDE COMPETENCY MGMT ALERT ###
    NotificationsUtils.clearNotifications();

    this.$emit('changeStep', FlowViewsStepsModel.GeneralDataStep, FlowHeaderStepsModel.GeneralDataStep);
  }
  
  /**
   * Handle for Warranty Change event
   *
   * @param {EventChange} eventChange
   * @return {void}
   */
  onQbWarrantyChange(eventChange: EventChange): void {
    let datosPropuestasReceiver = [];

    if (eventChange.receiver.proposalCode === 'All') {
      datosPropuestasReceiver = this.findAllWarrantyProposalData(eventChange.receiver) || [];
    } else {
      const foundDatosPropuestaReceiver = this.findWarrantyProposalData(eventChange.receiver);
      if (foundDatosPropuestaReceiver) {
        datosPropuestasReceiver.push(foundDatosPropuestaReceiver);
      }
    }

    if (!datosPropuestasReceiver || !datosPropuestasReceiver.length) {
      return;
    }
    
    for (const datosPropuestaReceiver of datosPropuestasReceiver) {
      this.performEvent(eventChange, datosPropuestaReceiver);
    }
  }

  /**
   * Performs event action on receiver element
   * @param {EventChange} eventChange
   * @param {GetWarrantiesResponseDataDatosPropuestas} datosPropuestaReceiver
   */
  performEvent(
    eventChange: EventChange,
    datosPropuestaReceiver: GetWarrantiesResponseDataDatosPropuestas,
  ): void {

    const fieldStructure = WarrantyFieldUtils.convertToFieldStructure(datosPropuestaReceiver);
    const cmpType = WarrantyFieldUtils.getComponentType(fieldStructure);
    if (cmpType === 'ea-checkbox') {
      const eventValue = eventChange.action.value;
      const isTruthy = eventValue === true || eventValue === 'true' || eventValue === 'S';
      eventChange.action.value = isTruthy ? 'S' : 'N';
    }

    if (eventChange.action.type === ActionType.CHANGE_HIDDEN) {
      datosPropuestaReceiver.elementoVisible = !!eventChange.action.value;
    } else if (eventChange.action.type === ActionType.CHANGE_READONLY) {
      datosPropuestaReceiver.elementoModificable = !!eventChange.action.value;
    } else if (eventChange.action.type === ActionType.CHANGE_VALUE) {
      datosPropuestaReceiver.valorElemento = eventChange.action.value ?
        eventChange.action.value.toString() : undefined;
    } else if (eventChange.action.type === ActionType.COPY_VALUE) {
      const elementToCopyValueFrom = eventChange.action.value;
      datosPropuestaReceiver.valorElemento = typeof elementToCopyValueFrom === 'object' ?
        this.getElementValue(elementToCopyValueFrom) : eventChange.action.value?.toString();
    }
  }

  /**
   * Get Value from passed warranty element
   * 
   * @param {WarrantyElement} element
   * @return {string | undefined}
   */
  getElementValue(element: WarrantyElement): string | undefined {
    const datosPropuestaReceiver = this.findWarrantyProposalData(element);
    return datosPropuestaReceiver?.valorElemento;
  }

  /**
   * Find the proposal code
   *
   * @param {WarrantyElement} warrantyElement
   * @return {GetWarrantiesResponseDataDatosPropuestas | undefined}
   */
  findWarrantyProposalData(warrantyElement: WarrantyElement): GetWarrantiesResponseDataDatosPropuestas | undefined {
    return this.warrantiesData
      ?.find(element => element.codigoGarantia === warrantyElement.warrantyCode)
      ?.elementosGarantias?.find(element => element.codigoElemento === warrantyElement.elementCode)
      ?.datosPropuestas?.find(element => element.codigoPropuesta === warrantyElement.proposalCode);
  }

  /**
   * Find proposal data for given warranty element
   * @param {WarrantyElement} warrantyElement 
   * @return {GetWarrantiesResponseDataDatosPropuestas[] | undefined}
   */
  findAllWarrantyProposalData(
    warrantyElement: WarrantyElement
  ): GetWarrantiesResponseDataDatosPropuestas[] | undefined {
    return this.warrantiesData
      ?.find(element => element.codigoGarantia === warrantyElement.warrantyCode)
      ?.elementosGarantias?.find(element => element.codigoElemento === warrantyElement.elementCode)
      ?.datosPropuestas;
  }

  /**
   * Handle for Warranty Reset event
   * @param {string | undefined} codigoPropuesta - Resetted proposal
   * @return {void}
   */
  onQbWarrantyReset(codigoPropuesta?: string): void {
    if (codigoPropuesta) {
      Vue.set(this.showingProposalPrice, codigoPropuesta, false);
      Vue.set(this.model.warrantyRates, codigoPropuesta, undefined);
    } else {
      this.model.warrantiesProposals.forEach(proposalCode => {
        Vue.set(this.showingProposalPrice, proposalCode, false);
        Vue.set(this.model.warrantyRates, proposalCode, undefined);
      });
    }
    this.model.selectedProposal = '';
  }

  /**
   * Open fractional payment dialog
   */
  async openFractionalPaymentDialog() {
    const fractionalPayments = await this.fetchFractionalPayments();

    if (fractionalPayments) {
      const proposalNumbers = this.model.warrantiesProposals;

      this.model.fractionalPaymentModel = {
        movementType: this.model.movementTypeCode as PolicyMovementType,
        isVisible: true,
        fractionalPaymentData: this.getFractionalPaymentData(fractionalPayments, proposalNumbers),
        proposalNumbers
      };
    }
    
  }

  /**
   * Fills fractional payment data for fractional payment modal
   * @param {GetFractionalPaymentsResponseDatosTarificacion[]} fractionalPayments
   * @param {string[]} proposalNumbers
   * @return {FractionalPaymentDataModel}
   */
  getFractionalPaymentData(
    fractionalPayments: GetFractionalPaymentsResponseDatosTarificacion[], proposalNumbers: string[]
  ): FractionalPaymentDataModel[] {
    const fractionalPaymentData = [];
    
    for (const fractionalPaymentForm of this.fractionalPaymentForms) {
      if (fractionalPaymentForm.nombreRestriccion) {
        fractionalPaymentData.push({
          paymentForm: fractionalPaymentForm.nombreRestriccion,
          paymentFormValues: this.getPaymentFormValues(
            fractionalPayments,
            fractionalPaymentForm.nombreRestriccion as FractionalFormaPagoTarificacionEnum,
            proposalNumbers
          ),
          showInBold: true
        });

        if (fractionalPaymentForm.valorRestriccion !== 'U' && fractionalPaymentForm.valorRestriccion !== 'A') {
          fractionalPaymentData.push({
            paymentForm: this.$t('warranties.firstReceipt').toString(),
            paymentFormValues: this.getPaymentFormValues(
              fractionalPayments,
              fractionalPaymentForm.nombreRestriccion as FractionalFormaPagoTarificacionEnum,
              proposalNumbers,
              true
            )
          });

          fractionalPaymentData.push({
            paymentForm: this.$t('warranties.successiveReceipts').toString(),
            paymentFormValues: this.getPaymentFormValues(
              fractionalPayments,
              fractionalPaymentForm.nombreRestriccion as FractionalFormaPagoTarificacionEnum,
              proposalNumbers,
              false,
              true
            )
          });
        }
      }
    }

    return fractionalPaymentData;
  }

  /**
   * Gets payment form values for given payment form
   * @param {GetFractionalPaymentsResponseDatosTarificacion[]} fractionalPayments
   * @param {GetWarrantiesResponseDataDatosTarificacionFormaPagoTarificacionEnum} paymentForm
   * @param {string[]} proposalNumbers
   * @param {boolean | undefined} lookingFirstReceipt
   * @param {boolean | undefined} lookingSuccessiveReceipts
   * @returns {Record<string, string | number>}
   */
  getPaymentFormValues(
    fractionalPayments: GetFractionalPaymentsResponseDatosTarificacion[],
    paymentForm: FractionalFormaPagoTarificacionEnum,
    proposalNumbers: string[],
    lookingFirstReceipt?: boolean,
    lookingSuccessiveReceipts?: boolean
  ): Record<string, string | number> {
    const paymentFormValues: Record<string, string | number> = {};

    for (const proposalNumber of proposalNumbers) {
      if (this.showingProposalPrice[proposalNumber]) {
        const paymentFormElement = fractionalPayments.find(
          fractionalPaymentEl => fractionalPaymentEl.codigoPropuesta === proposalNumber &&
            fractionalPaymentEl.formaPagoTarificacion === paymentForm
        );

        if (!paymentFormElement) {
          paymentFormValues[proposalNumber] = '-';
        } else if (paymentFormElement.noPrimaMinima) {
          paymentFormValues[proposalNumber] = this.$t('warranties.notAllowed').toString();
        } else if (lookingSuccessiveReceipts) {
          paymentFormValues[proposalNumber] = paymentFormElement.importeRecibosSucesivos || '-';
        } else if (lookingFirstReceipt) {
          paymentFormValues[proposalNumber] = paymentFormElement.importePrimerRecibo || '-';
        } else {
          paymentFormValues[proposalNumber] = paymentFormElement.importeTotalRecibo || '-';
        }
      } else {
        paymentFormValues[proposalNumber] = '-';
      }
    }

    return paymentFormValues;
  }

  /**
   * Getter that states if fractional payment link is shown
   */
  get fractionalPaymentLinkShown() {
    return this.fractionalPaymentForms && this.fractionalPaymentForms.length &&
      this.model.generalDataInformationModel.durationValue === 'R' &&
      this.showingProposalPrice && this.isAnyPriceShown();
  }

  /**
   * Returns if any proposal price is shown
   * @returns {boolean}
   */
  isAnyPriceShown() {
    return this.getProposalsWithPricesCodes().length;
  }
  
  /**
   * Calls IPID validation service
   * If it returns an error => Show it to the user
   */
  @EAMethod({
    loading: true
  })
  async callContractOffer() {
    try {
      const api = new EAContractOfferApi();
      const contractOfferResponse = await api.contractOfferOperation({
        contractOfferRequest: {
          codigoPoliza: this.model.offerNumber,
          versionPoliza: this.model.offerVersion,
          codigoPropuesta: this.model.selectedProposal,
          codigoOperacionPoliza: 'CO'
        }
      });
      if (contractOfferResponse?.errors?.length) {
        if (contractOfferResponse.errors[0].code === '0950' || contractOfferResponse.errors[0].code === '0951') {
          this.model.pendingIPIDDocumentation = true;
          NotificationsUtils.launchNotifications([{
            title: NotificationsTypeEnum.Info,
            message: contractOfferResponse.errors[0].message,
            type: NotificationsTypeEnum.Warning
          }]);
          this.onGoToNextStep(
            FlowViewsStepsModel.GenerateDocumentationStep, FlowHeaderStepsModel.WarrantiesStep, false);
        } else {
          throwIfResponseHasErrors(contractOfferResponse as ResponseWithErrors);
        }
      } else {
        this.onGoToNextStep(
          FlowViewsStepsModel.OfferIssuanceDataStep, FlowHeaderStepsModel.OfferIssuanceDataStep, true);
      }
    } catch (error) {
      const eaError = error as EAError;
      throw new EAValidationError(eaError.message);
    }
  }

  /**
   * Handle generate documentation button click
   * Emits change step event
   */
  onGoToGenerateDocumentation() {
    this.onGoToNextStep(FlowViewsStepsModel.GenerateDocumentationStep, FlowHeaderStepsModel.WarrantiesStep, true);
  }

  /**
   * Closes proficiency management dialog
   * @param {string} componentReference
   */
  closeProficiencyManagementDialog(componentReference: string) {
    const proficiencyMgmtComp: QbProficiencyManagementBusiness =
        this.$refs[componentReference] as QbProficiencyManagementBusiness;

    if (proficiencyMgmtComp) {
      proficiencyMgmtComp.hideModal();
    }
    this.proficiencyManagementList = [];
  }

  /**
   * Refreshes Proficiency Management Data
   * @param {string} componentReference
   *
   */
  @EAMethod({})
  async refreshProficiencyManagementData(componentReference: string) {
    const data = await this.fetchProficiencyManagement();
    
    const proposalNumbersOrder = this.model.warrantiesProposals;
    
    if (data) {
      this.proficiencyManagementList = data;
      this.model.pendingCompetenceManagement = true;
      this.model.proficiencyManagementModel.proposalNumbersOrder = proposalNumbersOrder;
      const proficiencyMgmtComp: QbProficiencyManagementBusiness =
        this.$refs[componentReference] as QbProficiencyManagementBusiness;
      if (proficiencyMgmtComp) {
        proficiencyMgmtComp.showModal();
      }
    }
  }

  /**
   * Saves prices and emits change step event
   * @param {FlowViewsStepsModel} step
   * @param {FlowHeaderStepsModel} headerStep
   * @param {boolean} clearNotifications
   */
  onGoToNextStep(step: FlowViewsStepsModel, headerStep: FlowHeaderStepsModel, clearNotifications?: boolean) {
    // ### HIDE COMPETENCY MGMT ALERT ###
    if (clearNotifications) {
      NotificationsUtils.clearNotifications();
    }
    
    this.saveContinentContentPrices();
    this.update();
    this.$emit('changeStep', step, headerStep);
  }

  /**
   * Saves "Continente" and "Contenido" prices in model
   */
  saveContinentContentPrices() {
    const containerWarrantyCode = this.productFactory.containerWarrantyCode;
    const contentWarrantyCode = this.productFactory.contentWarrantyCode;
    const priceElementCode = 'CPASEGUR';

    const continentWarranty = this.findWarrantyByCode(containerWarrantyCode);
    const contentWarranty = this.findWarrantyByCode(contentWarrantyCode);

    if (continentWarranty) {
      this.model.continentPrices = this.getWarrantyElementProposalPrices(continentWarranty, priceElementCode);
    }

    if (contentWarranty) {
      this.model.contentPrices = this.getWarrantyElementProposalPrices(contentWarranty, priceElementCode);
    }
  }

  /**
   * Gets prices for each proposal given a warranty element
   * @param {GetWarrantiesResponseDataDatosGarantias} warranty 
   * @param {string} elementCode
   * @returns {Record <string, string | number>}
   */
  getWarrantyElementProposalPrices(
    warranty: GetWarrantiesResponseDataDatosGarantias,
    elementCode: string
  ): Record <string, string | number> {
    const warrantyElement = this.findWarrantyElementByCode(warranty, elementCode);
    const result = {};

    if (warrantyElement && warrantyElement.datosPropuestas) {
      for (const datoPropuesta of warrantyElement.datosPropuestas) {
        if (datoPropuesta && datoPropuesta.codigoPropuesta) {
          Vue.set(result, datoPropuesta.codigoPropuesta, datoPropuesta.valorElemento);
        }
      }
    }

    return result;
  }

  /**
   * Finds warranty by given code
   * @param {string} warrantyCode
   * @returns {GetWarrantiesResponseDataDatosGarantias | undefined}
   */
  findWarrantyByCode(warrantyCode: string): GetWarrantiesResponseDataDatosGarantias | undefined {
    return this.warrantiesData.find(warranty => warranty.codigoGarantia === warrantyCode);
  }

  /**
   * Returns warranty element given element code and warranty to look into
   * @param {GetWarrantiesResponseDataDatosGarantias} warranty 
   * @param {string} elementCode
   * @returns {GetWarrantiesResponseDataElementosGarantias | undefined} 
   */
  findWarrantyElementByCode(
    warranty: GetWarrantiesResponseDataDatosGarantias,
    elementCode: string
  ): GetWarrantiesResponseDataElementosGarantias | undefined {
    return warranty.elementosGarantias?.find(
      element => element.codigoElemento === elementCode
    );
  }
  
  /**
   * @returns {EAFormValidationOptions}
   */
  get searchFormValidationOptions(): EAFormValidationOptions {
    return {
      rules: {
        searchTerm: [
          eaCustomValidation(
            this.minLengthValidator(4),
            'common.label.validation.invalidLength'
          ),
          eaCustomValidation(
            this.resultsFoundValidator(),
            'common.label.validation.warrantiesSearchNoResult'
          )
        ]
      }
    };
  }
  
  /**
   * Min length custom validator function
   * @param {number} minLength
   * @returns {EAValidatorFunction}
   */
  minLengthValidator(minLength: number): EAValidatorFunction {
    return (_rule: any, value: any, callback: Function) => {
      if (
        this.searchTermForm.searchTerm?.length &&
        this.searchTermForm.searchTerm.length < minLength
      ) {
        return callback(new Error());
      }

      return callback();
    };
  }
  

  /**
   * Validates if search term has any result
   *
   * @returns {EAValidatorFunction}
   */
  resultsFoundValidator(): EAValidatorFunction {
    return (_rule: any, _value: any, callback: Function) => {
      if (this.retrieveMatchingItems().length) {
        return callback();
      }
      
      return callback(new Error());
    };
  }

  /**
   * Handles search term input focus
   */
  onSearchTermFocusGain() {
    this.searchInputHasFocus = true;
  }

  /**
   * Handles search term input blur
   */
  onSearchTermFocusLost() {
    this.searchInputHasFocus = false;
  }

  /**
   * Return form validation object
   * @returns {EAValidation}
   */
  public searchFormValidation(): EAValidation {
    const form: Form = this.$refs.form as Form;
    return form.validation();
  }

  /**
   * Validates seach form and calls auxiliar search function
   */
  @EAMethod()
  public async validateAndSearch() {
    try {
      await this.searchFormValidation().validate();
      this.search();
    } catch (err) {
      scrollTo(0, 0);
    }
  }

  /**
   * Search
   */
  public search() {
    if (this.searchTermForm.searchTerm === '' || this.searchTermForm.searchTerm.length < 4) {
      this.removeClassFromElements('highlighted');
      this.removeClassFromElements('scroll-highlighted');
      return;
    }

    this.calculateSearchParams();

    const matchingWarrantyElements = this.retrieveMatchingItems();
    this.expandCollapseElementsBasedOnMatch();

    matchingWarrantyElements.forEach(matchingWarrantyEl => {
      this.highlightMatchingText(matchingWarrantyEl, 'highlighted');
    });

    this.scrollToNextElement(matchingWarrantyElements);
  }


  /**
   * Calculates search necessary params like scrollElementIndex
   * And resets highlighted elements if search is new search
   */
  calculateSearchParams() {
    if (this.searchTermForm.searchTerm === this.lastSearchTerm) {
      this.scrollElementIndex++;
    } else {
      // ### New search ###
      this.scrollElementIndex = 0;
      this.lastSearchTerm = this.searchTermForm.searchTerm;
      this.removeClassFromElements('highlighted');
      this.removeClassFromElements('scroll-highlighted');
    }
  }

  /**
   * Retrieves warranty elements that match introduced search term
   * @returns {any[]}
   */
  public retrieveMatchingItems() {
    const warrantiesCard: any[] = Array.from(document.getElementsByClassName('warranty-card'));
    let matchingWarrantyElements: any[] = [];
    const parsedSearchTerm = TextUtils.removeAccents(this.searchTermForm.searchTerm).toLowerCase();

    warrantiesCard.forEach(warrantyCard => {
      const warrantyTitleElements: {innerText: string}[] = Array.from(
        warrantyCard.querySelectorAll('.warranty__title, .collapse__title')
      );
      matchingWarrantyElements = matchingWarrantyElements.concat(
        warrantyTitleElements.filter(
          wTElem => TextUtils.removeAccents(wTElem.innerText).toLowerCase()
            .includes(parsedSearchTerm)
        )
      );
    });

    return matchingWarrantyElements;
  }

  /**
   * Removes every 'highlighted' class in DOM
   * @param {string} className
   */
  public removeClassFromElements(className: string) {
    const elementsWithHighlightedClass = document.querySelectorAll(`.${ className}`);

    elementsWithHighlightedClass.forEach(element => {
      element.classList.remove(className);
    });
  }

  /**
   * Expands warranties with elements that match search term
   * Collapses not selected warranties that are expanded and whose elements don't match search term
   * @param {any} matchingWarrantyEl 
   */
  public expandCollapseElementsBasedOnMatch() {
    const warrantiesComponentList = this.retrieveWarrantiesComponents();
    
    warrantiesComponentList.forEach(warrantyComponent => {
      const parsedSearchTerm = TextUtils.removeAccents(this.searchTermForm.searchTerm).toLowerCase();
      const matchFound = warrantyComponent.model.elementosGarantias.some(
        elementoGarantia => TextUtils.removeAccents(this.$t(
          `warranties.${warrantyComponent.model.codigoGarantia}.elements.${elementoGarantia.codigoElemento}`
        ).toString())
          .toLowerCase()
          .includes(parsedSearchTerm)
      );

      if (matchFound) {
        this.expandWarrantyComponent(warrantyComponent);
      } else {
        this.collapseWarrantyComponentIfNotChecked(warrantyComponent);
      }
    });
  }

  /**
   * Expands warranty component
   * @param {WarrantyBusiness} warrantyComponent
   */
  expandWarrantyComponent(warrantyComponent: WarrantyBusiness) {
    const collapseRef = warrantyComponent.$refs.zzCollapse as ZzCollapse;
    if (collapseRef) {
      collapseRef.collapseHidden = false;
    }
  }

  /**
   * Collapses warranty component if no proposal is checked
   * @param {WarrantyBusiness} warrantyComponent
   */
  collapseWarrantyComponentIfNotChecked(warrantyComponent: WarrantyBusiness) {
    if (warrantyComponent.model.garantiaSeleccionada?.some(elem => !!elem.garantiaSeleccionada)) {
      // ### Return if any proposal is checked ###
      return;
    }

    const collapseRef = warrantyComponent.$refs.zzCollapse as ZzCollapse;
    if (collapseRef) {
      collapseRef.collapseHidden = true;
    }
  }

  /**
   * Highlights user introduced term in matching warranty element
   * @param {any} matchingWarrantyEl
   * @param {string} className
   */
  public highlightMatchingText(matchingWarrantyEl: any, className: string) {
    const parsedSearchTerm = TextUtils.removeAccents(this.searchTermForm.searchTerm).toLowerCase();
    const idx = TextUtils.removeAccents(matchingWarrantyEl.innerText).toLowerCase()
      .indexOf(parsedSearchTerm);

    if (idx === -1) {
      return;
    }

    matchingWarrantyEl.innerHTML =
      `${matchingWarrantyEl.innerText.substring(0, idx)
      }<span class="${className}">${
        matchingWarrantyEl.innerText.substring(idx, idx + this.searchTermForm.searchTerm.length)
      }</span>${
        matchingWarrantyEl.innerText.substring(idx + this.searchTermForm.searchTerm.length)
      }`;
  }

  /**
   * Scrolls to next element + adds scroll-highlighted class
   * @param {any[]} matchingWarrantyElements
   */
  public scrollToNextElement(matchingWarrantyElements: any[]) {
    this.removeClassFromElements('scroll-highlighted');

    if (!matchingWarrantyElements[this.scrollElementIndex]) {
      // ### Restart index as no element has been found ###
      this.scrollElementIndex = 0;
    }

    if (matchingWarrantyElements[this.scrollElementIndex]) {
      this.highlightMatchingText(matchingWarrantyElements[this.scrollElementIndex], 'scroll-highlighted');
      const posY = matchingWarrantyElements[this.scrollElementIndex].getBoundingClientRect().top + window.scrollY;
      const HEADERS_HEIGHT_SUM = 150;
      const SPACE_BELOW = window.innerHeight / 4;
      scrollTo(0, posY - HEADERS_HEIGHT_SUM - SPACE_BELOW);
    }
  }

  /**
   * Gets a copy of proficiencyManagementModel
   */
  get proficiencyManagementModelCopy() {
    return cloneDeep(this.model.proficiencyManagementModel);
  }

  /**
   * Gets selected warranty group for tarification call
   * @returns {string[] | undefined}
   */
  getSelectedWarrantyGroup(): string[] | undefined {
    const model =
      this.productFactory.warrantiesHeaderConfig.warrantiesGroupsDisplayMode === WARRANTIES_HEADER_DISPLAY_MODE.GRID ?
        this.warrantiesHeaderModel.warrantiesGroupsGridSelectionModel :
        this.warrantiesHeaderModel.warrantiesGroupsInlineSelectionModel;

    const uniqueSelectedOption = model.selectedOption ? [model.selectedOption] : undefined;
    return this.productFactory.warrantiesHeaderConfig.canSelectMultipleWarrantiesGroups
      ? model.multipleSelectedOptions : uniqueSelectedOption;
  }

  /**
   * Gets selected warranty group for tarification call
   * @returns {string[] | undefined}
   */
  getSelectedWarrantyPackage(): string[] | undefined {
    const model =
      this.productFactory.warrantiesHeaderConfig.packagesGroupsDisplayMode ===
        WARRANTIES_HEADER_DISPLAY_MODE.GRID ?
        this.warrantiesHeaderModel.packagesGroupsGridSelectionModel :
        this.warrantiesHeaderModel.packagesGroupsInlineSelectionModel;

    const uniqueSelectedOption = model.selectedOption ? [model.selectedOption] : undefined;
    return this.productFactory.warrantiesHeaderConfig.canSelectMultiplePackagesGroups ?
      model.multipleSelectedOptions : uniqueSelectedOption;
  }

  /**
   * Update warranty config based on selected option
   * @returns {Promise<void>}
   */
  @EAMethod({
    loading: true,
  })
  public async updateWarrantyConfig(): Promise<void> {
    this.disableEvents();
    const api = new EAUpdateWarrantyGroupHogarApi();
    const request: UpdateWarrantyGroupHogarRequest = {
      codigoPoliza: this.model.offerNumber,
      tipoPoliza: PolicyType.Incompleta as unknown as UpdateWarrantyGroupHogarRequestTipoPolizaEnum,
      versionPoliza: this.model.offerVersion,
      valorElemento: this.getSelectedWarrantyGroup()?.[0] ?? '' // We will only have one selected option
    };
    const response = await api.updateWarrantyGroupHogarOperation({
      updateWarrantyGroupHogarRequest: request
    });
    const showLowSeverityErrors = false;
    NotificationsUtils.comprobeErrors(response as ResponseWithErrors, showLowSeverityErrors, this.getFlowId());
    await this.loadComponentData();
    this.enableEvents();
  }

  /**
   * Sets empty warranties message
   * @param {string} emptyWarrantiesMessage
   */
  showEmptyWarrantiesMessage(emptyWarrantiesMessage: string): void {
    this.emptyWarrantiesMessage = emptyWarrantiesMessage;
  }

  /**
   * Sets header selected options
   */
  setHeaderSelectedOptions(): void {
    if (this.model.productModel.warranty) {
      this.warrantiesHeaderModel.warrantiesGroupsInlineSelectionModel.selectedOption = this.model.productModel.warranty;
      this.warrantiesHeaderModel.warrantiesGroupsInlineSelectionModel.multipleSelectedOptions =
        [this.model.productModel.warranty];
    }
  }

  /**
   * Get selectable package groups
   * @returns {LabelValue[]}
   */
  get packagesGroups(): LabelValue[] {
    return this.retrievedProposalNumbers.map(elem => {
      return {
        value: elem,
        label: this.$t(`warranties.packageName.${elem}`).toString()
      };
    });
  }

  /**
   * Handles header tarification button
   * @param {WARRANTIES_HEADER_SELECTIONS[]} headerSelections
   */
  async onHeaderRetarification(headerSelections: WARRANTIES_HEADER_SELECTIONS[]) {
    if (headerSelections.length === 2) {
      // TODO: Will not happen yet
    } else if (headerSelections.includes(WARRANTIES_HEADER_SELECTIONS.WARRANTIES_GROUPS)) {
      this.model.productModel.warranty = this.warrantiesHeaderModel.warrantiesGroupsInlineSelectionModel.selectedOption;
      await this.updateWarrantyConfig();
    } else if (headerSelections.includes(WARRANTIES_HEADER_SELECTIONS.PACKAGES_GROUPS)) {
      // TODO: Will not happen yet
    } else {
      // Requote current option as some warranty element has changed
      await this.onRequote();
    }
  }

  /**
   * Shows requote button in warranties header
   */
  showHeaderRequoteButton() {
    const warrantiesHeaderComp: WarrantiesHeaderBusiness =
      this.$refs.warrantiesHeader as WarrantiesHeaderBusiness;
    if (warrantiesHeaderComp) {
      warrantiesHeaderComp.showRequoteButton();
    }
  }

  /**
   * Hides requote button in warranties header
   */
  hideHeaderRequoteButton() {
    const warrantiesHeaderComp: WarrantiesHeaderBusiness =
      this.$refs.warrantiesHeader as WarrantiesHeaderBusiness;
    if (warrantiesHeaderComp) {
      warrantiesHeaderComp.hideRequoteButton();
    }
  }
}
</script>

<style scoped lang="scss">

$backgroud-color: #e7eaf7;
$font-size: 20px;
$color: #000;
$letter-spacing: 0.71px;
$price-font-size: 20px;

.warranties-header {
  background: $backgroud-color;
  box-shadow: 0 2px 5px 1px rgba(0, 0, 0, 0.2);
  color: $color;
  font-size: $font-size;
  letter-spacing: $letter-spacing;
  position: sticky;
  top: 48px;
  z-index: 10;
}

.border-right-lightGrey {
  border-right: 1px solid #EEEEEE;
}

.border-left-lightGrey {
  border-left: 1px solid #EEEEEE;
}

.premium-adjustments-container {
  ::v-deep .el-row {
    align-items: stretch;
    display: flex;
  }
  .proposals {
    .price-container {
      .option-rate {
        font-size: $price-font-size;
        font-weight: 700;
        line-height: $price-font-size;
      }
    }
  }
  .fractional-payment {
    .fractional-payment-button {
      display: flex;
      justify-content: center;
      margin-top: -8px;
      padding: 8px 0;
    }
  }
}

.m-t-28 {
  margin-top: 28px;
}

::v-deep {
  .highlighted {
    background-color: yellow;
  }

  .scroll-highlighted {
    background-color: orange;
  }
}
</style>
