/* eslint-disable */

/**
 * zonazurich
 * Portal de Mediación Digital
 *
 * The version of the OpenAPI document: 1
 * Contact: digitalarchitecture@zurich.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import {
  EASDKConfig,
  EAUrlUtils
} from '@zurich-es-npm/ea-shared-utils-js';

import * as globalImportUrl from 'url';
import {
  Configuration
} from './configuration';
import globalAxios, {
  AxiosPromise,
  AxiosInstance,
  AxiosResponse
} from 'axios';

const BASE_PATH = EAUrlUtils.removeLastForwardSlash('http://localhost:3000');

/**
 *
 * @export
 */
export const COLLECTION_FORMATS = {
  csv: ',',
  ssv: ' ',
  tsv: '\t',
  pipes: '|',
};

/**
 *  
 * @export
 * @interface RequestArgs
 */
export interface RequestArgs {
  url: string;
  options: any;
}

/**
 * 
 * @export
 * @class BaseAPI
 */
export class BaseAPI {
  protected configuration: Configuration | undefined;

  /**
   *Creates an instance of BaseAPI.
   * @param {Configuration} [configuration]
   * @param {string} [basePath=BASE_PATH]
   * @param {AxiosInstance} [axios=globalAxios]
   * @memberof BaseAPI
   */
  public constructor(
    configuration?: Configuration,
    protected basePath: string = BASE_PATH,
    protected axios: AxiosInstance = globalAxios) {
    if (configuration) {
      this.configuration = configuration;
      this.basePath = configuration.basePath || this.basePath;
    }
  }
}

/**
 * 
 * @export
 * @class RequiredError
 * @extends {Error}
 */
export class RequiredError extends Error {
  public name: 'RequiredError' = 'RequiredError';

  public field: string;

  /**
   *Creates an instance of RequiredError.
   * @param {string} field
   * @param {string} [msg]
   * @memberof RequiredError
   */
  public constructor(field: string, msg?: string) {
    super(msg);
    this.field=field;
  }
}

/**
 * 
 * @export
 * @class AsyncRequestError
 * @extends {Error}
 */
export class AsyncRequestError extends Error {
  public name: 'AsyncRequestError' = 'AsyncRequestError';

  /**
   *Creates an instance of AsyncRequestError.
   * @param {string} field
   * @param {string} [msg]
   * @memberof AsyncRequestError
   */
  public constructor(msg?: string) {
    super(msg);
  }
}

/**
 * 
 * @export
 * @interface Error
 */
export interface Error {
    /**
     * 
     * @type {string}
     * @memberof Error
     */
    code: string;
    /**
     * 
     * @type {string}
     * @memberof Error
     */
    message: string;
    /**
     * 
     * @type {number}
     * @memberof Error
     */
    severity: number;
    /**
     * 
     * @type {Array<object>}
     * @memberof Error
     */
    childErrors?: Array<object>;
}/**
 * 
 * @export
 * @interface GetOfferListRequest
 */
export interface GetOfferListRequest {
    /**
     * 
     * @type {string}
     * @memberof GetOfferListRequest
     */
    codigoPoliza?: string;
    /**
     * 
     * @type {number}
     * @memberof GetOfferListRequest
     */
    versionPoliza?: number;
    /**
     * 
     * @type {string}
     * @memberof GetOfferListRequest
     */
    tipoPolizaOferta?: GetOfferListRequestTipoPolizaOfertaEnum;
    /**
     * 
     * @type {string}
     * @memberof GetOfferListRequest
     */
    tipoBusqueda?: GetOfferListRequestTipoBusquedaEnum;
    /**
     * 
     * @type {string}
     * @memberof GetOfferListRequest
     */
    indicadorUltimaSituacion?: string;
    /**
     * 
     * @type {string}
     * @memberof GetOfferListRequest
     */
    indicadorRecuperacionNegocio?: string;
    /**
     * 
     * @type {string}
     * @memberof GetOfferListRequest
     */
    codigoReferenciaVendedor?: string;
    /**
     * 
     * @type {string}
     * @memberof GetOfferListRequest
     */
    tiempoUltimaModificacion?: string;
    /**
     * 
     * @type {string}
     * @memberof GetOfferListRequest
     */
    identificadorUsuario?: string;
    /**
     * 
     * @type {number}
     * @memberof GetOfferListRequest
     */
    numeroElementosPagina?: number;
    /**
     * 
     * @type {number}
     * @memberof GetOfferListRequest
     */
    numeroPagina?: number;
    /**
     * 
     * @type {string}
     * @memberof GetOfferListRequest
     */
    codigoTecnicoProducto?: string;
    /**
     * 
     * @type {string}
     * @memberof GetOfferListRequest
     */
    codigoComercialProducto?: string;
    /**
     * 
     * @type {string}
     * @memberof GetOfferListRequest
     */
    codigoContrato?: string;
    /**
     * 
     * @type {string}
     * @memberof GetOfferListRequest
     */
    codigoEstadoPoliza?: string;
    /**
     * 
     * @type {string}
     * @memberof GetOfferListRequest
     */
    codigoFiliacion?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum GetOfferListRequestTipoPolizaOfertaEnum {
    O = 'O',
    I = 'I',
    C = 'C'
}
/**
    * @export
    * @enum {string}
    */
export enum GetOfferListRequestTipoBusquedaEnum {
    NumeroOferta = 'numeroOferta',
    NumeroPoliza = 'numeroPoliza',
    TipoPoliza = 'tipoPoliza',
    Tomador = 'tomador',
    FiliacionCliente = 'filiacionCliente',
    Producto = 'producto',
    Usuario = 'usuario',
    SituacionRiesgo = 'situacionRiesgo',
    Matricula = 'matricula',
    NombreApellido = 'nombreApellido',
    RazonSocial = 'razonSocial'
}
/**
 * 
 * @export
 * @interface GetOfferListResponse
 */
export interface GetOfferListResponse {
    /**
     * 
     * @type {Array<GetOfferListResponseListaOfertas>}
     * @memberof GetOfferListResponse
     */
    listaOfertas?: Array<GetOfferListResponseListaOfertas>;
    /**
     * 
     * @type {number}
     * @memberof GetOfferListResponse
     */
    numeroDeRegistros?: number;
    /**
     * 
     * @type {boolean}
     * @memberof GetOfferListResponse
     */
    esUltimaPagina?: boolean;
    /**
     * 
     * @type {Array<Error>}
     * @memberof GetOfferListResponse
     */
    errors?: Array<Error>;
}/**
 * 
 * @export
 * @interface GetOfferListResponseListaOfertas
 */
export interface GetOfferListResponseListaOfertas {
    /**
     * 
     * @type {string}
     * @memberof GetOfferListResponseListaOfertas
     */
    codigoPoliza?: string;
    /**
     * 
     * @type {number}
     * @memberof GetOfferListResponseListaOfertas
     */
    versionPoliza?: number;
    /**
     * 
     * @type {number}
     * @memberof GetOfferListResponseListaOfertas
     */
    numeroSuplemento?: number;
    /**
     * 
     * @type {string}
     * @memberof GetOfferListResponseListaOfertas
     */
    codigoFiliacion?: string;
    /**
     * 
     * @type {string}
     * @memberof GetOfferListResponseListaOfertas
     */
    nombreCompactadoPersonas?: string;
    /**
     * 
     * @type {string}
     * @memberof GetOfferListResponseListaOfertas
     */
    codigoTecnicoProducto?: string;
    /**
     * 
     * @type {string}
     * @memberof GetOfferListResponseListaOfertas
     */
    codigoComercialProducto?: string;
    /**
     * 
     * @type {string}
     * @memberof GetOfferListResponseListaOfertas
     */
    descripcionProducto?: string;
    /**
     * 
     * @type {string}
     * @memberof GetOfferListResponseListaOfertas
     */
    tipoMovimientoPoliza?: string;
    /**
     * 
     * @type {string}
     * @memberof GetOfferListResponseListaOfertas
     */
    motivoMovimientoPoliza?: string;
    /**
     * 
     * @type {string}
     * @memberof GetOfferListResponseListaOfertas
     */
    codigoEstadoPoliza?: string;
    /**
     * 
     * @type {string}
     * @memberof GetOfferListResponseListaOfertas
     */
    formaPago?: string;
    /**
     * 
     * @type {string}
     * @memberof GetOfferListResponseListaOfertas
     */
    codigoMoneda?: string;
    /**
     * 
     * @type {string}
     * @memberof GetOfferListResponseListaOfertas
     */
    codigoContrato?: string;
    /**
     * 
     * @type {string}
     * @memberof GetOfferListResponseListaOfertas
     */
    fechaInicioPoliza?: string;
    /**
     * 
     * @type {string}
     * @memberof GetOfferListResponseListaOfertas
     */
    fechaEfectoNatural?: string;
    /**
     * 
     * @type {string}
     * @memberof GetOfferListResponseListaOfertas
     */
    fechaEfectoMovimiento?: string;
    /**
     * 
     * @type {string}
     * @memberof GetOfferListResponseListaOfertas
     */
    fechaFinPoliza?: string;
    /**
     * 
     * @type {string}
     * @memberof GetOfferListResponseListaOfertas
     */
    fechaVencimiento?: string;
    /**
     * 
     * @type {string}
     * @memberof GetOfferListResponseListaOfertas
     */
    fechaEmision?: string;
    /**
     * 
     * @type {string}
     * @memberof GetOfferListResponseListaOfertas
     */
    codigoUsuario?: string;
    /**
     * 
     * @type {string}
     * @memberof GetOfferListResponseListaOfertas
     */
    codigoEstructura?: string;
    /**
     * 
     * @type {string}
     * @memberof GetOfferListResponseListaOfertas
     */
    codigoEstructuraCentroTrabajo?: string;
    /**
     * 
     * @type {string}
     * @memberof GetOfferListResponseListaOfertas
     */
    claveTipoOficinaProductora?: string;
    /**
     * 
     * @type {string}
     * @memberof GetOfferListResponseListaOfertas
     */
    claveUnidadOficinaProductora?: string;
    /**
     * 
     * @type {string}
     * @memberof GetOfferListResponseListaOfertas
     */
    estructuraRol?: string;
    /**
     * 
     * @type {string}
     * @memberof GetOfferListResponseListaOfertas
     */
    tipoUnidadRol?: string;
    /**
     * 
     * @type {string}
     * @memberof GetOfferListResponseListaOfertas
     */
    codigoUnidadRol?: string;
    /**
     * 
     * @type {string}
     * @memberof GetOfferListResponseListaOfertas
     */
    indicadorModificable?: string;
    /**
     * 
     * @type {string}
     * @memberof GetOfferListResponseListaOfertas
     */
    indicadorConsultable?: string;
    /**
     * 
     * @type {string}
     * @memberof GetOfferListResponseListaOfertas
     */
    duracionPoliza?: string;
    /**
     * 
     * @type {string}
     * @memberof GetOfferListResponseListaOfertas
     */
    indicadorCoaseguro?: string;
    /**
     * 
     * @type {string}
     * @memberof GetOfferListResponseListaOfertas
     */
    indicadorReciboUnico?: GetOfferListResponseListaOfertasIndicadorReciboUnicoEnum;
    /**
     * 
     * @type {string}
     * @memberof GetOfferListResponseListaOfertas
     */
    indicadorReaseguroAceptado?: GetOfferListResponseListaOfertasIndicadorReaseguroAceptadoEnum;
    /**
     * 
     * @type {string}
     * @memberof GetOfferListResponseListaOfertas
     */
    indicadorGeneracionExtorno?: string;
    /**
     * 
     * @type {string}
     * @memberof GetOfferListResponseListaOfertas
     */
    indicadorFiscal?: string;
    /**
     * 
     * @type {string}
     * @memberof GetOfferListResponseListaOfertas
     */
    indicadorBloqueoPoliza?: string;
    /**
     * 
     * @type {string}
     * @memberof GetOfferListResponseListaOfertas
     */
    indicadorUltimaSituacion?: string;
    /**
     * 
     * @type {string}
     * @memberof GetOfferListResponseListaOfertas
     */
    codigoInternoCEC?: string;
    /**
     * 
     * @type {string}
     * @memberof GetOfferListResponseListaOfertas
     */
    claveSBU?: string;
    /**
     * 
     * @type {string}
     * @memberof GetOfferListResponseListaOfertas
     */
    tiempoUltimaModificacion?: string;
    /**
     * 
     * @type {string}
     * @memberof GetOfferListResponseListaOfertas
     */
    codigoNegocio?: string;
    /**
     * 
     * @type {string}
     * @memberof GetOfferListResponseListaOfertas
     */
    descripcionNegocio?: string;
    /**
     * 
     * @type {string}
     * @memberof GetOfferListResponseListaOfertas
     */
    claveIntermediarioProductor1?: string;
    /**
     * 
     * @type {number}
     * @memberof GetOfferListResponseListaOfertas
     */
    numeroSiniestroFactorCulpaAsegurado?: number;
    /**
     * 
     * @type {string}
     * @memberof GetOfferListResponseListaOfertas
     */
    codigoCanalDistribucion?: string;
    /**
     * 
     * @type {string}
     * @memberof GetOfferListResponseListaOfertas
     */
    codigoCompaniaGrupo?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum GetOfferListResponseListaOfertasIndicadorReciboUnicoEnum {
    ReciboUnico = 'ReciboUnico',
    PorCompania = 'PorCompania'
}
/**
    * @export
    * @enum {string}
    */
export enum GetOfferListResponseListaOfertasIndicadorReaseguroAceptadoEnum {
    IndicadorCedido = 'IndicadorCedido',
    SinReaseguroAceptado = 'SinReaseguroAceptado',
    AceptadoObligatorio = 'AceptadoObligatorio',
    AceptadoFacultativo = 'AceptadoFacultativo'
}


type getOfferListOperationParams = {
  getOfferListRequest: GetOfferListRequest,
};

/**
 * GetOfferListApi - axios parameter creator
 * @param {Configuration} [configuration]
 * @returns {any}
 */
const GetOfferListApiAxiosParamCreator = function(configuration?: Configuration): any {
  return {
    
    /**
     * Devuelve la lista de la oferta a partir de los criterios de busqueda
     * @param {GetOfferListRequest} getOfferListRequest 
     * @param {string} [acceptLanguage] El Accept-Language request HTTP header indica en que lenguaje debe responder el backend.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @returns {RequestArgs}
     */
    getOfferListOperation(params: getOfferListOperationParams, options: any = {}): RequestArgs {
      // Verify required parameter 'getOfferListRequest' is not null or undefined
      if (params.getOfferListRequest === null || params.getOfferListRequest === undefined) {
        throw new RequiredError('getOfferListRequest', 'Required parameter getOfferListRequest was null or undefined when calling getOfferListOperation.');
      }
      const localVarPath = `/v1/search-offer/getOfferListOperation`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true) as Partial<globalImportUrl.UrlWithParsedQuery>;
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;


  
      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
      // Fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
      const needsSerialization = ('GetOfferListRequest' as any !== 'string') ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization ? JSON.stringify(params.getOfferListRequest || {}) : params.getOfferListRequest || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * GetOfferListApi - functional programming interface
 * @param {Configuration} [configuration]
 * @returns {any}
 */
const GetOfferListApiFp = function(configuration?: Configuration): any {
  return {
    
    /**
     * Devuelve la lista de la oferta a partir de los criterios de busqueda
     * @param {GetOfferListRequest} getOfferListRequest 
     * @param {string} [acceptLanguage] El Accept-Language request HTTP header indica en que lenguaje debe responder el backend.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @returns {any}
     */
    getOfferListOperation(params: getOfferListOperationParams, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetOfferListResponse> {
      const localVarAxiosArgs = GetOfferListApiAxiosParamCreator(configuration).getOfferListOperation(params, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH): any => {
        const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * GetOfferListApi - factory interface
 * @param {Configuration} configuration
 * @param {string} basePath
 * @param {AxiosInstance} axios
 * @export
 * @returns {any}
 */
const GetOfferListApiFactory = function(configuration?: Configuration, basePath?: string, axios?: AxiosInstance): any {
  return {
    
    /**
     * Devuelve la lista de la oferta a partir de los criterios de busqueda
     * @param {GetOfferListRequest} getOfferListRequest 
     * @param {string} [acceptLanguage] El Accept-Language request HTTP header indica en que lenguaje debe responder el backend.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @returns {any}
     */
    getOfferListOperation(params: getOfferListOperationParams, options?: any): any {
      return GetOfferListApiFp(configuration).getOfferListOperation(params, options)(axios, basePath);
    },
  };
};

/** 
 * GetOfferListApi - object-oriented interface
 * @export
 * @class GetOfferListApi
 * @extends {BaseAPI}
 */
export class GetOfferListApi extends BaseAPI {
  
  /**
   * Devuelve la lista de la oferta a partir de los criterios de busqueda
   * @param {GetOfferListRequest} getOfferListRequest 
   * @param {string} [acceptLanguage] El Accept-Language request HTTP header indica en que lenguaje debe responder el backend.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @returns {any}
   * @memberof GetOfferListApi
   */
  public getOfferListOperation(params: getOfferListOperationParams, options?: any): any {
    return GetOfferListApiFp(this.configuration).getOfferListOperation(params, options)(this.axios, this.basePath);
  }

}

/**
 * 
 * @export
 * @class EAgetOfferListOperationInputMessage
 */
export class EAgetOfferListOperationInputMessage {
  
  /**
   * 
   * @type {GetOfferListRequest}
   * @memberof EAgetOfferListOperationInputMessage
   */
  public getOfferListRequest: GetOfferListRequest;

  public constructor(getOfferListRequest: GetOfferListRequest, ){
  
  this.getOfferListRequest=getOfferListRequest;
  } 
}



/** 
 * EAGetOfferListApi - Architecture client for getOfferListOperation
 * @export
 * @class EAGetOfferListApi
 */
export class EAGetOfferListApi {

  /**
   * 
   * @param {GetOfferListRequest} getOfferListRequest
   
   * @throws {AsyncRequestError | RequiredError}
   * @returns {Promise<GetOfferListResponse | null>}
  */ 
  public async getOfferListOperation(params: getOfferListOperationParams): Promise<GetOfferListResponse | null> {
    return this.getOfferListOperationPromise(params);
  }

  /**
   * 
   * @param {GetOfferListRequest} getOfferListRequest
   
   * @param {Function} callback
   * @throws {AsyncRequestError | RequiredError}
   */
  public async getOfferListOperationSteps(params: getOfferListOperationParams, callback: (res: AxiosResponse<GetOfferListResponse | null>) => void) {
    let done: boolean = false;
    const httpClient: AxiosInstance = EASDKConfig.getHttpClient('/v1/search-offer/getOfferListOperation', 'getOfferList', 'getOfferListOperation');
    const config: Configuration = {};
    const api = new GetOfferListApi(config, '', httpClient);
    const sleep = async(ms: number) => {
      return new Promise(resolve => setTimeout(resolve, ms));
    };
    const headers: { ['Async-Id']?: string } = {};
    while (!done) {
      const output = await api.getOfferListOperation(params, { headers }) as AxiosResponse<GetOfferListResponse | null>;
      callback(output);
      if (output.status === 202) {
        try {
          const retry = parseInt(output.headers['retry']);
          headers['Async-Id'] = output.headers['request-id'];
          if (isNaN(retry) || retry <= 0 || retry > 1000 * 3600) {
            throw new Error(`Retry time not acceptable (in milliseconds): ${retry}`);
          }
          await sleep(retry);
        } catch (err) {
          throw new Error(`Error in Async Request ${err}`);
        }
      } else {
        done = true;
      }
    }
  }

  /**
   * 
   * @param {GetOfferListRequest} getOfferListRequest
   
   * @throws {AsyncRequestError | RequiredError}
   * @returns {Promise<GetOfferListResponse | null>}
   */
  public async getOfferListOperationPromise(params: getOfferListOperationParams): Promise<GetOfferListResponse | null> {
    return new Promise((resolve, reject) => {
      this.getOfferListOperationSteps(params, (response: AxiosResponse<GetOfferListResponse | null>) => {
        if (response.status !== 202) {
          resolve(response.data);
        }
      })
      .catch((err) => {
        reject(err);
      });
    });
  } 
}


