<template>
  <div>
    <ea-card v-for="group in model.fleetGroupedVehicleList" :key="group.codigoAgrupacionFlota" class="m-t-16">
      <div slot="cardbody" class="p-a-16">
        <fleet-group-vehicle-table-business
          v-model="model.fleetGroupVehicleTableModel"
          :group="group"
          :id="group.codigoAgrupacionFlota"
          :batchOnlineStatusList="batchOnlineStatusList"
          :isReadonly="isReadonly"
          @showWarrantiesModal="onShowWarrantiesModal"
          @refreshVehiclesData="onRefreshVehiclesData"
          @showPackageModalConsult="onShowPackageModalConsult"
        ></fleet-group-vehicle-table-business>
      </div>
    </ea-card>

    <fleet-footer-total-annual-premium
      v-if="model.fleetShowTotal"
      :totalAnnualPremium="allGroupsTotal"
      :showEmittedVehicles = "false"
    />

    <fleet-packages-warranties-modal
      id="fleetPackagesWarrantiesModal"
      v-model="model.fleetPackagesWarrantiesModal"
      :visible="showModal"
      :vehicleType="vehicleType"
      :packagesList="warrantiesPackagesList"
      :selectedVehicles="vehiclesWarrantiesBeingEdited"
      :isReadonly="isReadonly"
      @onCloseModal="onCloseModal"
      @onSaveWarranties="onSaveWarranties"
    />
  </div>
</template>

<script lang="ts">
import {
  Component, Prop
} from 'vue-property-decorator';
import {
  mixins
} from 'vue-class-component';
import {
  EAApplicationLogger,
  EABusinessComponent,
  EAError,
  EAMethod,
  ResponseWithErrors,
  throwIfResponseHasErrors
} from '@zurich-es-npm/ea-front-web-core';
import {
  EAGetGroupedVehicleListApi,
  GetGroupedVehicleListRequest,
  GetGroupedVehicleListResponseListaAgrupaciones as Agrupacion,
  GetGroupedVehicleListResponseListaVehiculos,
} from '@/services/V1/fleets/getGroupedVehicleListOperation/post/api';
import {
  EAGetWarrantyPackagesApi,
  GetWarrantyPackagesRequestVehiculos,
  GetWarrantyPackagesResponsePaquetes
} from '@/services/V1/fleets/getWarrantyPackagesOperation/post/api';
import FleetVehicleGroupsModel from './fleet-vehicle-groups-model';
import FleetGroupVehicleTableBusiness from '../fleet-group-vehicle-table/fleet-group-vehicle-table-business.vue';
import {
  RefreshVehiclesDataEmitParams
} from '../fleet-group-vehicle-table/fleet-group-vehicle-table-model';
import FleetPackagesWarrantiesModalBusiness
  from '../fleet-packages-warranties-modal/fleet-packages-warranties-modal-business.vue';
import {
  EASaveWarrantiesApi, SaveWarrantiesRequest,
  SaveWarrantiesRequestFormaPagoTarificacionEnum, SaveWarrantiesRequestGarantiasIncluidas,
  SaveWarrantiesRequestGarantiasOpcionales,
  SaveWarrantiesRequestVehiculos
} from '@/services/V1/fleets/saveWarrantiesOperation/post';
import {
  PolicyBatchOnline
} from '@/types/batch-online/batch-online.types';
import Utils from '@/utils/utils';
import {
  NotificationsTypeEnum,
  NotificationsUtils,
  ZZNotification
} from '@/utils/notifications/notifications-utils';
import {
  ParsedTableData
} from '@/utils/corporate-tables';
import FleetFooterTotalAnnualPremium from '@/flows/fleets/components/fleet-footer-total-annual-premium.vue';
import {
  EAGetVehicleDataApi, GetVehicleDataRequest
} from '@/services/V1/fleets/getVehicleDataOperation/post';

@Component({
  name: 'fleet-vehicle-groups',
  components: {
    'fleet-group-vehicle-table-business': FleetGroupVehicleTableBusiness,
    'fleet-packages-warranties-modal': FleetPackagesWarrantiesModalBusiness,
    'fleet-footer-total-annual-premium': FleetFooterTotalAnnualPremium
  }
})

/**
 * Business Component fleet-vehicle-groups
 */
export default class FleetVehicleGroupsBusiness extends
  mixins<EABusinessComponent<FleetVehicleGroupsModel>>(EABusinessComponent) {
    
  packagesList: Record<string, GetWarrantyPackagesResponsePaquetes[]> = {};

  showModal: boolean = false;

  vehicleType: string = '';

  warrantiesPackagesList: GetWarrantyPackagesResponsePaquetes[] = [];

  vehiclesWarrantiesBeingEdited: GetGroupedVehicleListResponseListaVehiculos[] = [];

  saveWarrantiesApi: EASaveWarrantiesApi = new EASaveWarrantiesApi();

  @Prop({
    required: true
  })
    offerNumber!: string;

  @Prop({
    required: true
  })
    offerVersion!: number;

  @Prop({
    required: true
  })
    paymentForm!: string;

  @Prop({
    required: true
  })
    limitPrizeTarification!: number;

  @Prop()
    messageLimitPrizeTarification!: number;
    
  @Prop({
    required: true,
    'default': () => []
  })
    batchOnlineStatusList!: ParsedTableData[];

  @Prop()
    isReadonly?: boolean;

  @Prop()
    isEmittedFleet?: boolean;

  flowId = ''; // Used to show notifications into correct flow when async processes

  /**
   * Hook created
   * 
   */
  async created() {
    this.flowId = NotificationsUtils.getCurrentFlowId();
    await this.initialGetVehicleList();
  }

  /**
   * Computed to know if component has predefined set of brands/models/versions
   */
  public get allGroupsTotal(): number {
    let total = 0;
    for (const group of this.model.fleetGroupedVehicleList) {
      if (group.listaVehiculos) {
        for (const vehiculo of group.listaVehiculos) {
          if (vehiculo.importeTotal) {
            total += vehiculo.importeTotal;
          }
        }
      }
    }
    return total;
  }

  /**
   * Computed to know if there is at least one vehicle with status NotRated.
   */
  get anyVehicleNotRated(): boolean {
    for (const agrupation of this.model.fleetGroupedVehicleList) {
      if (agrupation.listaVehiculos?.some(vehicle => vehicle.estadoPolizaBatch === PolicyBatchOnline.NotRate)) {
        return true;
      }
    }
    return false;
  }

  /**
   * Initial load vehicles 
   */
  @EAMethod({
    loading: true
  })
  async initialGetVehicleList() {
    await this.getVehicleList();

    // Check fleet composition
    this.checkFleetComposition();
  }

  /**
   * Get vehicles data again to refresh data
   * @param {RefreshVehiclesDataEmitParams} params
   */
  @EAMethod({
    loading: true
  })
  async onRefreshVehiclesData(params: RefreshVehiclesDataEmitParams) {
    this.$emit('fetchCompetencesManagement');
    await this.getVehicleList();
    
    // If fleet composition must be checked -> check it
    if (params.checkFleetComposition) {
      this.checkFleetComposition();
    }
  }

  /**
   * Emits correspondant event to check fleet composition if apply.
   * If there are vehicles in status "Not rated" we do not check fleet composition,
   * because re-quote button is already enabled anyways.
   */
  checkFleetComposition() {
    if (!this.anyVehicleNotRated) {
      // Emit event to parent View to check fleet composition,
      this.$emit('checkFleetComposition');
    }
  }

  /**
   * Get raw vehicle list from fleet
   */
  @EAMethod()
  async getVehicleList() {
    this.model.fleetGroupedVehicleList = [];
  
    // Not show the total after requoteAll validations
    this.model.fleetShowTotal = false;
    this.update();

    const api = new EAGetGroupedVehicleListApi();

    const request: GetGroupedVehicleListRequest = {
      codigoFlota: this.offerNumber,
      versionFlota: this.offerVersion
    };

    try {
      const response = await api.getGroupedVehicleListOperation({
        getGroupedVehicleListRequest: request
      });
      const severityErrors = response?.errors?.filter(error => error.severity > 3);
      throwIfResponseHasErrors({
        errors: severityErrors
      });
      if (response?.listaAgrupaciones) {
        this.model.fleetGroupedVehicleList = response.listaAgrupaciones;
        let notPending;
        
        const requoteAll =
          this.model.fleetGroupedVehicleList.map(
            item => {
              const notRate =
            item.listaVehiculos?.every(ele => ele.estadoPolizaBatch !== PolicyBatchOnline.NotRate);
              notPending =
            item.listaVehiculos?.every(ele => ele.estadoPolizaBatch !== PolicyBatchOnline.PendingRate);
              if (notRate && notPending) {
                return true;
              }
              return false;
            }
          ).every(elemet => elemet === true);

        if (requoteAll) {
          this.model.fleetShowTotal = true;
          
          this.update(); // Needed to show the total in quote-view
        } else if (!notPending) {
          //Emit to quote view if has to requote fleets vehicles
          this.$emit('hasRequote', true);
        }

        if (this.allGroupsTotal > this.limitPrizeTarification) {
          NotificationsUtils.throwWarning(
            `${this.$t('fleets.fleetsFlow.requoteFleet.maxPrizeLimit.message', {
              maxPrize: this.digitsFormatter(this.messageLimitPrizeTarification || this.limitPrizeTarification)
            })}`,
            this.flowId
          );
          this.$emit('limitPrizeExceed', true);
        }

        await Promise.all([
          this.getPackagesData(),
          this.checkIfSincoHasError()
        ]);

      }
      this.update();
    } catch (error) {
      const eaError = error as EAError;
      new EAApplicationLogger().error(
        `FleetVehicleGroupsBusiness::getVehicleList:: loading grouping vehicle list :: ${eaError.message}`
      );
      throw error;
    }
  }


  /**
   * Calls get data vehicle operation to check if sinco has error in requote fleet
   * its based on first vehicle of fleet
   * if sinco has error its propagated to all vehicles in fleet
   */
  @EAMethod()
  async checkIfSincoHasError() {
    if (this.isReadonly || this.isEmittedFleet) {
      return;
    }
    //Find first vehicle rated
    let ratedVehicle: GetGroupedVehicleListResponseListaVehiculos | undefined;
    this.model.fleetGroupedVehicleList.forEach(vehicleGroup => {
      ratedVehicle = vehicleGroup.listaVehiculos
        ?.find(vehicle => vehicle.estadoPolizaBatch === PolicyBatchOnline.Rated ||
          vehicle.estadoPolizaBatch === PolicyBatchOnline.GDC);
    });
    if (ratedVehicle) {
      const codigoPoliza = ratedVehicle?.codigoPoliza as string;
      const versionPoliza = ratedVehicle?.versionPoliza as number;

      const api = new EAGetVehicleDataApi();
      const request: GetVehicleDataRequest = {
        codigoVehiculo: '',
        codigoPoliza,
        versionPoliza
      };
    
      const response = await api.getVehicleDataOperation({
        getVehicleDataRequest: request
      });
      if (response?.sincoError && response?.sincoError !== '00') {
        const notification: ZZNotification = {
          title: this.$t('fleets.fleetsFlow.requoteFleet.sincoErrors.default').toString(),
          message: '',
          type: NotificationsTypeEnum.Warning
        };
        //If there is a specific error
        if (this.$te(`fleets.fleetsFlow.requoteFleet.sincoErrors.${response.sincoError}`)) {
          notification.message =
            this.$t(`fleets.fleetsFlow.requoteFleet.sincoErrors.${response.sincoError}`).toString();
        }
        //TODO: throw message
        NotificationsUtils.launchNotifications([notification], this.flowId);
      }
    }
  }

  /**
   * Show overlayed notify message in the top right corner of the screen.
   * @param {'success' | 'warning' | 'error'} type 
   */
  showNotifyMessage(type: 'success' | 'warning' | 'error') {
    const title = `fleets.fleetsFlow.requoteFleet.requoteResultsTopRightNotifications.${type}.title`;
    const message = `fleets.fleetsFlow.requoteFleet.requoteResultsTopRightNotifications.${type}.message`;
    this.$eaNotify({
      title: `${this.$t(title)}`,
      message: `${this.$t(message)}`,
      duration: 0, // Infinite duration
      type
    });
  }

  /**
   * Get packages list
   */
  @EAMethod()
  async getPackagesData() {
    const api = new EAGetWarrantyPackagesApi();

    const requestBody = this.model.fleetGroupedVehicleList.map(item => {
      const vehicle = item.listaVehiculos?.[0];
      return {
        codigoPoliza: vehicle?.codigoPoliza,
        versionPoliza: vehicle?.versionPoliza
      };
    });
    const response = await api.getWarrantyPackagesOperation({
      getWarrantyPackagesRequest: {
        vehiculos: requestBody as unknown as GetWarrantyPackagesRequestVehiculos[]
      }
    });
    if (response) {
      throwIfResponseHasErrors(response as ResponseWithErrors);

      if (response.paquetes && response.paquetes.length > 0) {
        // Save each agroupation with its packages
        this.model.fleetGroupedVehicleList.forEach((group, index) => {
          this.$set(
            this.packagesList,
            group.codigoAgrupacionFlota as string, // Group code
            response.paquetes?.[index].paquetes || [] // Group packages
          );
        });
      }
    }
  }

  /**
   * Show pcakges/warranties modal with needed information.
   * @param {Agrupacion} group 
   * @param {GetGroupedVehicleListResponseListaVehiculos[]} vehicles 
   */
  onShowWarrantiesModal(
    group: Agrupacion,
    vehicles: GetGroupedVehicleListResponseListaVehiculos[]
  ) {
    this.vehicleType = group.descripcionAgrupacionFlota || '';
    this.warrantiesPackagesList = this.packagesList[group.codigoAgrupacionFlota || ''];
    this.vehiclesWarrantiesBeingEdited = vehicles;
    //If only once vehicle and codigoPaquete is defined is editing
    if (this.vehiclesWarrantiesBeingEdited.length === 1 && this.vehiclesWarrantiesBeingEdited[0].codigoPaquete) {
      this.model.fleetPackagesWarrantiesModal.fleetPackageSelectModel.packageSelected =
        this.vehiclesWarrantiesBeingEdited[0].codigoPaquete;
    }
    this.showModal = true;
  }

  /**
   * Close the modal
   */
  onCloseModal() {
    this.resetPackageSelectModel();
    this.showModal = false;
  }

  /**
   * Open modal for consult
   * @param {GetGroupedVehicleListResponseListaVehiculos} vehicle
   */
  onShowPackageModalConsult(vehicle: GetGroupedVehicleListResponseListaVehiculos) {
    this.onShowWarrantiesModal(vehicle, [vehicle]);
  }

  /**
   * Set save warranties and set package to vehicles
   */
  @EAMethod({
    loading: true
  })
  async onSaveWarranties() {
    // Clear notifications previous to packages warranties dialog
    NotificationsUtils.clearNotifications(this.flowId);
    const packageCode = this.model.fleetPackagesWarrantiesModal.fleetPackageSelectModel.packageSelected;
    const packageModalitySelected =
      this.model.fleetPackagesWarrantiesModal.fleetPackageSelectModel.packageModalitySelected;
    const includedWarranties =
      this.model.fleetPackagesWarrantiesModal.fleetPackageSelectModel.warranties.garantiasIncluidas;
    const optionalWarranties =
      this.model.fleetPackagesWarrantiesModal.fleetPackageSelectModel.warranties.garantiasOpcionales;
    optionalWarranties?.forEach(warranty => {
      warranty.elementos?.forEach(element => {
        element.valorElemento = element.valorElemento?.toString();
      });
    });
    const saveWarrantiesRequest: SaveWarrantiesRequest = {
      vehiculos: this.vehiclesWarrantiesBeingEdited as SaveWarrantiesRequestVehiculos[],
      codigoPaqueteGarantia: packageCode,
      codigoFranquiciaModalidad: packageModalitySelected,
      garantiasIncluidas: includedWarranties as SaveWarrantiesRequestGarantiasIncluidas[],
      garantiasOpcionales: optionalWarranties as SaveWarrantiesRequestGarantiasOpcionales[],
      codigoFlota: this.offerNumber,
      versionFlota: this.offerVersion,
      formaPagoTarificacion: this.parsePaymenForm(this.paymentForm)
    };
    const response = await this.saveWarrantiesApi.saveWarrantiesOperation({
      saveWarrantiesRequest
    });

    let anyVehicleOk = false; // Flag indicating if there is at least 1 vehicle with result OK
    this.vehiclesWarrantiesBeingEdited.forEach(vehicle => {
      const findResult = response?.resultados.find(result => result.codigoPoliza === vehicle.codigoPoliza);
      if (findResult?.ok) {
        anyVehicleOk = true;
        vehicle.codigoPaquete = packageCode;
        vehicle.importeTotal = 0;
        vehicle.primaNeta = 0;
        // Estado de la poliza pendiente de tarificar T01
        vehicle.estadoPolizaBatch = PolicyBatchOnline.NotRate;
      }
    });

    if (anyVehicleOk) {
      // Show selected package success notification
      NotificationsUtils.throwSuccess(
        this.$t(`fleets.fleetsFlow.fleet-group-vehicle-table.packageSelectedConfirmation`).toString(),
        this.flowId
      );
    }
    
    this.update(); // Is it necessary?
    
    //Emit to quote view if has to requote fleets vehicles
    this.$emit('hasRequote', this.vehiclesWarrantiesBeingEdited.some(vehicle => vehicle.codigoPaquete === packageCode));
    this.showModal = false;
    this.resetPackageSelectModel();
    
    await Utils.sleep(100); // Workaround to make errores thrown by throwIfResponseHasErrors appear in screen
    throwIfResponseHasErrors(response as unknown as ResponseWithErrors);
  }

  /**
   * Should close the modal and reset the value of packageSelected
   */
  resetPackageSelectModel() {
    this.model.fleetPackagesWarrantiesModal.fleetPackageSelectModel.packageSelected = '';
    this.model.fleetPackagesWarrantiesModal.fleetPackageSelectModel.packageModalitySelected = '';
    this.model.fleetPackagesWarrantiesModal.fleetPackageSelectModel.warranties.garantiasIncluidas = [];
    this.model.fleetPackagesWarrantiesModal.fleetPackageSelectModel.warranties.franquiciasValor = [];
  }

  /**
   * Parse string to payment form enum
   * @param {string} paymentForm
   * @return {SaveWarrantiesRequestFormaPagoTarificacionEnum} warranties
   */
  parsePaymenForm(paymentForm: string): SaveWarrantiesRequestFormaPagoTarificacionEnum {
    switch (paymentForm) {
      case 'M':
        return SaveWarrantiesRequestFormaPagoTarificacionEnum.Mensual;
      case 'S':
        return SaveWarrantiesRequestFormaPagoTarificacionEnum.Semestral;
      case 'T':
        return SaveWarrantiesRequestFormaPagoTarificacionEnum.Trimestral;
      case 'U':
        return SaveWarrantiesRequestFormaPagoTarificacionEnum.Unica;
      default:
        return SaveWarrantiesRequestFormaPagoTarificacionEnum.Anual;
    }
  }

  /**
   * Formatea el precio ejemplo : 500 -> 500.00€
   * @param {String} price valor sin formato 500
   * @returns {String} valor con formato 500.00 €
   */
  digitsFormatter(price: number): string {
    return price ? `${Utils.formatFourDigitNumber(this.$n(price))} €` : '-';
  }
}
</script>
