<template>
  <div>
    <qb-offer-issuance-data id="offerIssuanceData" v-model="model.offerIssuanceDataModel"></qb-offer-issuance-data>
    <ea-card shadow="hidden" class="m-t-16">
      <div slot="cardbody" class="p-a-16">
        <ea-row>
          <ea-col :span="12" class="documents">
            <zz-client-basic-info
              id="showClientData"
              v-model="tomadorDocumentationClientData"
              :documentationClientData="tomadorDocumentationClientData"
              class="m-b-32"
            ></zz-client-basic-info>

            <qb-document-check-emit
              id="documentList"
              :documentList="model.policyDocumentEmit"
              v-model="checkEmitDocumentsModel"
              title="quoteBuyGenericFlow.titleDownloadDocuments"
              :download="true"
              @showDocumentPreview="showDocumentPreview"
            ></qb-document-check-emit>
          </ea-col>
        </ea-row>
        <ea-row class="m-t-16 d-display-flex d-justify-flex-end">
          <ea-col :span="12">
            <ea-button
              @click="downloadDocuments(model.policyDocumentEmit)"
              type="primary" size="medium" :disabled="isDownloadBtnDisabled"
            >
              {{ $t('quoteBuyGenericFlow.issueOffer.summaryButtons.download') }}
            </ea-button>
          </ea-col>
       </ea-row>
      </div>
    </ea-card>

    <ea-row class="m-t-16">
      <ea-col :span="12">
        <ea-button @click="onClose" type="secondary" size="medium" >
          {{ $t('quoteBuyGenericFlow.issueOffer.summaryButtons.close') }}
        </ea-button>
      </ea-col>
    </ea-row>
  </div>
</template>

<script lang="ts">
import {
  Component, Prop
} from 'vue-property-decorator';

import {
  mixins
} from 'vue-class-component';

import {
  EAContextManager,
  EAFlowItem,
  EAFlowNavigationUtils,
  EAMethod,
  EAView,
  throwIfResponseHasErrors
} from '@zurich-es-npm/ea-front-web-core';

import {
  QuoteBuyModel
} from '../quote-buy-model';
import QbOfferIssuanceDataBusiness
  from '@/business-components/qb-offer-issuance-data/qb-offer-issuance-data-business.vue';
import ProductBase from '@/utils/quote-buy-product-factory/products/product-base';
import {
  EALocaleManager
} from '@zurich-es-npm/ea-front-web-ui';
import QbDocumentCheckEmitBusiness
  from '@/business-components/qb-document-check-emit/qb-document-check-emit-business.vue';
import MultipleEmailInputModel from '@/business-components/multiple-email-input/multiple-email-input-model';
import Utils from '@/utils/utils';
import QbDocumentCheckEmitModel from '@/business-components/qb-document-check-emit/qb-document-check-emit-model';
import {
  DownloadFilesRequestDocumentos,
  EADownloadFilesApi
} from '@/services/V1/common/downloadFilesOperation/post';
import {
  GetIssueOfferDataResponseListaDocumentos
} from '@/services/V1/quoteAndBuy/getIssueOfferDataOperation/post';
import {
  NotificationsTypeEnum, NotificationsUtils
} from '@/utils/notifications/notifications-utils';
import {
  Roles
} from '@/types/roles/roles-enum.types';
import PersonUtils from '@/utils/person-utils';
import ZzClientBasicInfo from '@/presentational-components/clientBasicInfo/clientBasicInfo.vue';
import {
  DocumentationClientData
} from '@/presentational-components/clientBasicInfo/clientBasicInfo.type';

@Component({
  components: {
    QbOfferIssuanceData: QbOfferIssuanceDataBusiness,
    QbDocumentCheckEmit: QbDocumentCheckEmitBusiness,
    'zz-client-basic-info': ZzClientBasicInfo
  }
})

/**
 * SummaryDownloadPolicy view
 *
 */
export default class SummaryDownloadPolicyView extends mixins<EAView<QuoteBuyModel>>(EAView) {

  @Prop({
    required: true,
  })
    productFactory!: ProductBase;

  public multipleEmailInputModel: MultipleEmailInputModel = new MultipleEmailInputModel();

  public checkEmitDocumentsModel: QbDocumentCheckEmitModel = new QbDocumentCheckEmitModel();

  public activeFlow?: EAFlowItem;

  /**
   * Hook on created.
   *
   * @returns {void}
   */
  @EAMethod({
    loading: true,
  })
  public async created() {
    NotificationsUtils.launchNotifications([
      {
        title: 'issueConfirmation',
        message: this.description,
        type: NotificationsTypeEnum.Success
      },
      {
        title: NotificationsTypeEnum.Info,
        message: this.$t('quoteBuyGenericFlow.issueOffer.showDocGenerationWarning.description').toString(),
        type: NotificationsTypeEnum.Warning
      },
    ]);
  }

  /**
   * Get description data
   */
  get description(): string {
    return EALocaleManager.i18n.t('quoteBuyGenericFlow.issueOffer.issueConfirmation.descriptionTwo', {
      policyNumber: this.model.offerNumber
    }) as string;
  }

  /**
   * Close the view and go to home
   */
  onClose() {
    this.activeFlow = EAContextManager.getInstance().getFlowContext()?.activeFlow;
    if (this.activeFlow) {
      EAFlowNavigationUtils.closeFlow(this, this.activeFlow);
    }
  }

  /**
   * Downloads single document
   * @param {GetIssueOfferDataResponseListaDocumentos} document
   */
  showDocumentPreview(document: GetIssueOfferDataResponseListaDocumentos) {
    this.downloadDocuments([document]);
  }

  /**
   * Downloads selected document
   * @param {GetIssueOfferDataResponseListaDocumentos[] | undefined} documents 
   */
  @EAMethod({
    loading: true
  })
  public async downloadDocuments(documents?: GetIssueOfferDataResponseListaDocumentos[]) {
    if (!documents) {
      return;
    }
    const documentos: DownloadFilesRequestDocumentos[] = [];

    documents.forEach(document => {
      if (document && document.codigoDocumento && document.codigoDocumentoTexto && document.indicadorDocumentoMarcado) {
        documentos.push({
          codigoDocumento: document.codigoDocumento,
          nombreDocumento: document.nombreDocumento
        });
      }
    });
   
    const downloadFilesApi = new EADownloadFilesApi();
    const output = await downloadFilesApi.downloadFilesOperation({
      downloadFilesRequest: {
        documentos
      }
    });

    if (output) {
      const highSeverityErrors = output.errors?.filter(error => error.severity >= 4);
      throwIfResponseHasErrors({
        errors: highSeverityErrors
      });
      Utils.downloadFiles(output.documentos);
    }
  }


  /**
   * Gets if download button is disabled => true if no document is marked
   */
  get isDownloadBtnDisabled() {
    return !this.model.policyDocumentEmit?.some(doc => !!doc?.indicadorDocumentoMarcado);
  }

  /**
   * Retrieves documentation client data model for person with 'tomador' role
   * @returns {DocumentationClientData}
   */
  public get tomadorDocumentationClientData(): DocumentationClientData {
    return PersonUtils.getDocumentationClientData(this.productFactory.personRoles, Roles.Tomador);
  }
}
</script>
