import {
  EAGetFleetApi
} from '@/services/V1/fleets/getFleetOperation/post';
import {
  EAGetGroupedVehicleListApi,
  GetGroupedVehicleListRequest,
  GetGroupedVehicleListResponse,
} from '@/services/V1/fleets/getGroupedVehicleListOperation/post';
import {
  SearchPolicyHolderResponseJuridicalPeopleTipoDocumentoEnum as documentType
} from '@/services/V1/fleets/searchPolicyHolderOperation/post';
import {
  EAGetPersonsApi
} from '@/services/V1/quoteAndBuy/getPersonsOperation/post';
import {
  Roles
} from '@/types/roles/roles-enum.types';
import {
  NotificationsUtils
} from '@/utils/notifications/notifications-utils';
import {
  PersonRole
} from '@/utils/quote-buy-product-factory/types/product-role-types';
import {
  EAApplicationLogger,
  EAError,
  EAMethod,
  ResponseWithErrors
} from '@zurich-es-npm/ea-front-web-core';
import {
  FleetsModel
} from '../fleets-model';

export enum VehicleListOperationEnum {
  Todos = '1',
  Pendientes = '5',
  Emitidos = '7',
}


/**
 * Class to get flow configuration and handle it
 */
export class FlowModelFillUtils {

  /**
   * Get raw vehicle list from fleet
   * @param {FleetsModel} model
   * @param {VehicleListOperationEnum} codigoOperacion
   */
  @EAMethod({
    loading: true,
  })
  public static async loadVehicleList(model: FleetsModel, codigoOperacion?: VehicleListOperationEnum):
  Promise<GetGroupedVehicleListResponse | null> {

    const api = new EAGetGroupedVehicleListApi();
    const request: GetGroupedVehicleListRequest = {
      codigoFlota: model.offerNumber,
      versionFlota: model.offerVersion,
    };
    if (codigoOperacion) {
      request.codigoOperacionFlota = codigoOperacion;
    }
    try {
      const response = await api.getGroupedVehicleListOperation({
        getGroupedVehicleListRequest: request,
      });

      if (response) {
        NotificationsUtils.comprobeErrors(response as ResponseWithErrors);
        if (response.listaAgrupaciones) {
          model.fleetVehicleGroupsModel.fleetGroupedVehicleList = response.listaAgrupaciones;
          // Get the number of vehicles and the total annual premium of EMITTED vehicles from COBOL
          model.fleetVehicleGroupsModel.totalEmittedVehicles = response.numeroVehiculosVigor as number;
          model.fleetVehicleGroupsModel.annualPremiumEmittedVehicles = response.primaTotalFlota as number;
        }
      }
      return response;
    } catch (error) {
      const eaError = error as EAError;
      new EAApplicationLogger().error(
        `FlowModelFillUtils::loadVehicleList:: loading grouping vehicle list :: ${eaError.message}`
      );
      throw error;
    }
  }

  /**
   * Get fleet data for maintenance
   * @param {FleetsModel} model
   * @param {boolean} indicadorCompromisoContrato
   */
  @EAMethod({
    loading: true,
  })

  // eslint-disable-next-line max-len
  public static async loadFleetData(model: FleetsModel, indicadorCompromisoContrato: boolean): Promise<void> { // NOSONAR
    const selectedValuesCommit = [];
    const api = new EAGetFleetApi();
    const output = await api.getFleetOperation({
      getFleetRequest: {
        codigoFlota: model.offerNumber,
        versionFlota: model.offerVersion,
      },
    });
  
    if (output) {
      NotificationsUtils.comprobeErrors(output as ResponseWithErrors);
      
      if (indicadorCompromisoContrato) {
        // Variable to manage the component visibility
        indicadorCompromisoContrato =
          !!output.indicadorCompromisoComercio ||
          !!output.indicadorCompromisoPymes ||
          !!output.indicadorCompromisoRespCivil;
      }

      if (output.indicadorCompromisoComercio) {
        selectedValuesCommit.push(model.flowConfiguration.generalDataView.fleetCommitContractPolicyTypes[1].label);
      }

      if (output.indicadorCompromisoPymes) {
        selectedValuesCommit.push(model.flowConfiguration.generalDataView.fleetCommitContractPolicyTypes[0].label);
      }

      if (output.indicadorCompromisoRespCivil) {
        selectedValuesCommit.push(model.flowConfiguration.generalDataView.fleetCommitContractPolicyTypes[2].label);
      }
      model.fleetName = output.nombreFlota;

      model.qbNewOfferBusinessModel.productComercialCode = output.codigoComercialProducto;
      model.qbNewOfferBusinessModel.intermediaryCode = output.codigoIntermediario;
      model.qbNewOfferBusinessModel.productTechnicalCode = output.codigoTecnicoProducto;
      model.qbNewOfferBusinessModel.intermediaryName = output.nombreIntermediario;
      model.qbNewOfferBusinessModel.productName = output.nombreProducto;
      model.qbNewOfferBusinessModel.businessCode = output.codigoNegocio;
      model.qbNewOfferBusinessModel.businessName = output.nombreNegocio;
      model.qbNewOfferBusinessModel.productCode = `${output.codigoTecnicoProducto}-${output.codigoComercialProducto}`;

      model.fleetSearchPolicyHolderModel.indicadorCompromisoContrato = indicadorCompromisoContrato;
      
      const selectedJuridicalPerson = model.fleetSearchPolicyHolderModel.selectedJuridicalPerson;
      selectedJuridicalPerson.tipoDocumento = (output.tomador?.tipoDocumento as unknown) as documentType;
      selectedJuridicalPerson.codigoIdentificacionFiscal = output.tomador?.codigoIdentificacionFiscal;
      selectedJuridicalPerson.razonSocialMatriz = output.tomador?.razonSocialMatriz;
      selectedJuridicalPerson.tipoViaDireccionLarga = output.tomador?.tipoViaDireccionLarga;
      selectedJuridicalPerson.nombreDireccionTomador = output.tomador?.nombreDireccionTomador;
      selectedJuridicalPerson.numeroDireccionTomador = output.tomador?.numeroDireccionTomador;
      selectedJuridicalPerson.codigoPostal = output.tomador?.codigoPostal;
      selectedJuridicalPerson.nombrePoblacionTomador = output.tomador?.nombrePoblacionTomador;
      selectedJuridicalPerson.nombreProvinciaTomador = output.tomador?.nombreProvinciaTomador;
      selectedJuridicalPerson.informacionAdicional = output.tomador?.informacionAdicional;
      selectedJuridicalPerson.codigoFiliacion = output.tomador?.codigoFiliacion;

      model.fleetSearchPolicyHolderModel.fleetCommitContract.formValues.selectedValues = selectedValuesCommit;

      model.fleetGeneralInfoModel.formValues.vehiclesNumber = output.numeroDeVehiculos;
      model.fleetGeneralInfoModel.formValues.rentingVehicle = output.vehiculosRenting;
      model.fleetGeneralInfoModel.formValues.secondCategoryVehicle = !!output.ambitoTransporteSegundaCategoria;
      model.fleetGeneralInfoModel.formValues.secondCategoryVehicleScope = output.ambitoTransporteSegundaCategoria;
      model.fleetGeneralInfoModel.formValues.annualInsurancePremium = output.primaNetaEstimada;
      model.fleetGeneralInfoModel.formValues.underageConductor = output.conductorInexperto;

      const riskManagement = model.fleetsRiskManagementModel.formValues;
      riskManagement.majorVehiclesHasAdvancedDriverTechnology = output.sistemaConduccionAvanzado;
      riskManagement.anyDriversCurrentlyDoingEfficiencyCourses = output.conductorReeducacion;
      riskManagement.securityControlsRiskManagement = output.controlGerenciaRiesgo;

      const unifiedExperitionModel = model.fleetUnifiedExpirationModel.formValues;
      unifiedExperitionModel.hasUnifiedExpiration = output.vencimientoUnificado;
      unifiedExperitionModel.unifiedExpirationDate =
        output.vencimientoUnificado && output.fechaEfectoMovimiento ? new Date(output.fechaEfectoMovimiento) : null;
      unifiedExperitionModel.notUnifiedExpirationDate =
        !output.vencimientoUnificado && output.fechaEfectoMovimiento ? new Date(output.fechaEfectoMovimiento) : null;

      model.fleetIssuanceDataModel.
        fechaEfectoMovimiento = output.fechaEfectoMovimiento ? new Date(output.fechaEfectoMovimiento) : undefined;
      model.fleetIssuanceDataModel.fechaVencimientoFlota =
        output.fechaVencimientoFlota ? new Date(output.fechaVencimientoFlota) : undefined;

      model.fleetQuotePaymentFormModel.paymentForm = output.formaPago || '';
      model.fleetPaymentDataModel.paymentChannel = output.tipoCanalCobro1Recibo as string;
      model.fleetPaymentDataModel.ibanCodeModel.qbIbanCodeModalModel.selectedIbanCode = output.codigoIBAN as string;
    }
  }

  /**
   * Main function for search person
   * @param {FleetsModel} model
   */
  @EAMethod({
    loading: true,
  })
  public static async loadPolicyHolderData(model: FleetsModel): Promise<void> {
    const api = new EAGetPersonsApi();
    const personResponse = await api.getPersonsOperation({
      getPersonsRequest: {
        codigoFiliacion: model.fleetSearchPolicyHolderModel.selectedJuridicalPerson.codigoFiliacion,
      },
    });

    if (!personResponse) {
      return;
    }

    NotificationsUtils.comprobeErrors(personResponse as ResponseWithErrors);

    const person = model.personsModel.personRoles.find(
      personRole => personRole.role.toLowerCase() === Roles.Tomador.toString().toLowerCase()
    ) as PersonRole;

    // ### Save person data in model ###
    if (personResponse.datosPersona) {
      person.searchModel.selectedPerson = personResponse.datosPersona;
    }

    // ### Set nationality field ###
    person.addressesModel.selectNationality.selectedNationality =
      personResponse.datosPersona?.datosGeneralesPersona?.nacionalidadPersona || '';
  }
}

export default FlowModelFillUtils;
