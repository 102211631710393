/** 
 * Model qb-observations-authorization
 */
class QbObservationsAuthorizationModel {

  public title: string = '';

  public reasons: string = '';
}

export default QbObservationsAuthorizationModel;
