import {
  FleetRestriction
} from './fleet-restriction-model';

/**
 * 
 */
export class FleetGeneralInfoRestrictions {
  public vehiclesNumber: FleetRestriction = new FleetRestriction();

  public annualInsurancePremium: FleetRestriction = new FleetRestriction();

  public allawedEmployeeRiskAppetite: FleetRestriction = new FleetRestriction();

  public allawedIntermediaryRiskAppetite: FleetRestriction = new FleetRestriction();

}
