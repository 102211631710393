import AddEditAddressModel from './selectables/add-edit-address/add-edit-address-model';
import AddEditEmailModel from './selectables/add-edit-email/add-edit-email-model';
import AddEditPhoneModel from './selectables/add-edit-phone/add-edit-phone-model';
import SelectNationalityModel from './selectables/select-nationality/select-nationality-model';

/**
 *
 */
class QbAddressesPersonModel {
  public addEditPhone: AddEditPhoneModel = new AddEditPhoneModel();

  public addEditAddress: AddEditAddressModel = new AddEditAddressModel();

  public addEditEmail: AddEditEmailModel = new AddEditEmailModel();

  public selectNationality: SelectNationalityModel = new SelectNationalityModel();
}

export default QbAddressesPersonModel;
