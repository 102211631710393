
export interface LabelValuePair {
  value: string;
  label: string;
}

export enum MovementReasonEnum {
  CambioVencimiento = '180',
  CambioCEC = '700'
}
