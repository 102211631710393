<template>
  <div>
    <ea-row>
      <ea-col>
        <div class="m-b-16">
          <ea-text size="medium">
            {{ $t( 'quoteBuyGenericFlow.person.conductor.text1') }}
          </ea-text>
          <ea-text size="medium">
            {{ $t( 'quoteBuyGenericFlow.person.conductor.text2') }}
          </ea-text>
        </div>
        <ea-form ref="form"
          :model="formValues"
          :validationOptions="formValidationOptions"
          :validateOnRuleChange="false">

          <ea-row class="m-l-12">
            <ea-form-item prop="conductor" class="radiogroup-horizontal m-b-8">
              <!-- LABEL -->
              <span class="radiogroup-label">
                {{ $t( 'quoteBuyGenericFlow.person.conductor.label') }}
              </span>

              <!-- RADIO BUTTONS -->
              <span>
                <ea-radio-group
                  v-model="formValues.nominated"
                  direction="horizontal"
                  @change="onChangeRadio"
                >
                  <ea-radio
                    :label="true"
                    class="m-8">{{ $t('common.label.yes') }}
                  </ea-radio>
                  <ea-radio
                    :label="false"
                    class="m-8">{{ $t('common.label.no') }}
                  </ea-radio>
                </ea-radio-group>
              </span>
            </ea-form-item>
          </ea-row>

          <ea-row v-if="formValues.nominated" extraClass="d-display-flex d-wrap d-align-items-flex-end">
            <ea-col :span="6">
              <ea-form-item prop="plateNumber" :label="$t('searchOfferFlow.searcher.plateNumber')" required>
                <ea-select
                  v-model="formValues.plateNumber"
                  :placeholder="$t('common.label.select')"
                  @change="onChange"
                  >
                  <ea-option
                    v-for="vehicle in vehiclesList"
                    :key="vehicle.plateNumber"
                    :label="vehicle.plateNumber"
                    :value="vehicle.plateNumber"
                  />
                </ea-select>
              </ea-form-item>
            </ea-col>
          </ea-row>

        </ea-form>
      </ea-col>
    </ea-row>

  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import {
  Component, Prop
} from 'vue-property-decorator';
import {
  PersonRole
} from '@/utils/quote-buy-product-factory/types/product-role-types';
import {
  VehicleFormModel
} from '@/business-components/fleet-manual-upload-vehicle/fleet-manual-upload-vehicle-model';
import {
  EAFormValidationOptions, eaRequiredValidation
} from '@zurich-es-npm/ea-front-web-ui';

@Component({
  name: 'policy-holder-vs-driver-person'
})

/**
 * Policy holder vs insured person component
 */
export default class PolicyHolderVsDriverPersonComponent extends Vue {

  @Prop({
    required: true,
  })
    person!: PersonRole;

  @Prop({
    required: true,
  })
    vehiclesList!: VehicleFormModel[];

  formValues = {
    plateNumber: '',
    nominated: false,
  };

  formValidationOptions: EAFormValidationOptions = {
    rules: {
      plateNumber: [eaRequiredValidation('common.label.validation.fieldRequired')]
    },
  };

  /**
   * Set platenumber value
   * @param {string} value
   */
  onChange(value: string) {
    this.formValues.plateNumber = value;
    const findPlateNumber =
      this.vehiclesList.find(vehicle => vehicle.plateNumber === this.formValues.plateNumber);
   
    if (findPlateNumber) {
      this.$emit('onGetVehicleSearched', findPlateNumber, this.formValues.plateNumber);
    }
  }

  /**
   * Change radio-button value for nominated driver
   * @param {boolean} value
   */
  onChangeRadio(value: boolean) {
    this.$emit('onDriverRadioChange', value);
  }
}

</script>
