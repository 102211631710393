<template>
  <div v-if="warrantiesProposal"
    class="t-bg-color-primary-10 p-t-16 p-l-16 p-b-24
          p-r-24 d-display-flex d-justify-space-between d-align-items-center"
  >
  
    <div>
      <div v-if="shouldShowTitle" class="d-display-inline">
        <ea-checkbox
          label=""
          :checked="warrantiesProposal.checked"
          :disabled="warrantiesProposal.disabled"
          @change="onWarrantiesProposalCheckedStatusChange"
          class="m-t-8">
        </ea-checkbox>
        <h3 v-if="warrantiesProposal.proposalIndex !== undefined">
          {{ getTitleProposal(warrantiesProposal) }}
        </h3>
      </div>

      <component
        :id="`docProposalCard-${warrantiesProposal.proposalNumber}`"
        :is="productFactory.documentationSelectProposalComponent"
        :productModel="productModel"
        :warrantiesProposal="warrantiesProposal"
        :proposalsToShowLength="proposalsToShowLength"
        :getRate="getRate"
        class="m-b-8"
      />
    </div>

    <div>
      <p class="t-weight-bold m-b-0">Prima anual</p>
      <h2 class="t-align-center">
        {{ warrantiesProposal.rate ?
          `${getRate(warrantiesProposal.rate)} €` : '-'
        }}
      </h2>
    </div>
  </div>
</template>
<script lang="ts">

import Vue from 'vue';
import {
  Component, Prop
} from 'vue-property-decorator';
import {
  WarrantiesProposal
} from '../qb-documentation-select-proposal-model';
import Utils from '@/utils/utils';
import {
  ProductModel
} from '@/flows/policy/policy-model';
import ProductBase from '@/utils/quote-buy-product-factory/products/product-base';

@Component({
  name: 'proposal-card'
})

/**
 * Proposal card component
 */
export default class ProposalCardComponent extends Vue {

  @Prop()
    warrantiesProposal?: WarrantiesProposal;

  @Prop()
    proposalsToShowLength?: number;
    
  @Prop({
    required: true,
  })
    productFactory!: ProductBase;
    
  @Prop({
    required: true,
  })
    productModel!: ProductModel;

  /**
   * OnCreated hook
   * Checks proposal if we only have one
   */
  created() {
    if (this.warrantiesProposal && this.proposalsToShowLength === 1) {
      this.warrantiesProposal.checked = true;
      this.$emit('warrantiesProposalCheckedStatusChange', this.warrantiesProposal);
    }
  }

  /**
   * Should show the title 
   * if shouldShowTypeProposal is true or proposalsToShowLength > 1
   * 
   * @param {string | number} value
   * @return {string}
   */
  get shouldShowTitle(): boolean {
    if (this.productFactory.shouldShowTypeProposal ||
    (this.proposalsToShowLength && this.proposalsToShowLength > 1)) {
      return true;
    }

    return false;
  }

  /**
   * Get proposal title
   * @param {WarrantiesProposal} proposal
   * @returns {string}
   */
  getTitleProposal(proposal: WarrantiesProposal): string {
    if (this.productFactory.shouldShowProposalTitle) {
      if (this.productFactory.shouldShowCustomPackageName) {
        return this.$t(`warranties.packageName.${proposal.proposalNumber}`).toString();
      } else {
        return this.$t('warranties.shared.proposalTitleWithNumber', {
          index: proposal.proposalIndex + 1
        }).toString();
      }
    }
    return '';
  }

  /**
   * Handles checkbox change event
   * Emits event
   */
  onWarrantiesProposalCheckedStatusChange() {
    if (this.warrantiesProposal) {
      this.warrantiesProposal.checked = !this.warrantiesProposal.checked;
      this.$emit('warrantiesProposalCheckedStatusChange', this.warrantiesProposal);
    }
  }

  /**
   * Gets and formats the rate of the checkboxStatus
   * 
   * @param {string | number} value
   * @return {string}
   */
  getRate(value: string | number): string {
    return Utils.formatFourDigitNumber(this.$n(+value, 'decimal'));
  }
}

</script>
