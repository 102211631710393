<template>
  <div>
    <ea-form ref="form" :model="model">
      <ea-row>
        <ea-col :span="ibanSelectSpan">
          <ea-form-item
            :label="$t('quoteBuyGenericFlow.ibanCode.title')"
            prop="qbIbanCodeModalModel.selectedIbanCode"
            :required="isIbanRequired && !consultaOperation && !isReadonly"
            v-if="model.selectableIbanList && model.selectableIbanList.length > 0"
          >
            <ea-select
              placeholder="Select" :readonly="consultaOperation || isReadonly"
              v-model="model.qbIbanCodeModalModel.selectedIbanCode"
              @change="onSelectedIbanCodeChange"
            >
              <ea-option
                v-for="iban in model.selectableIbanList"
                :key="iban.codigoIBAN"
                :label="iban.codigoIBAN"
                :value="iban.codigoIBAN"
              />
            </ea-select>
          </ea-form-item>

          <div v-if="!consultaOperation && !isReadonly">
            <ea-link @click="openAddEditModal">
              {{ $t('common.link.addEditIban') }}
            </ea-link>
          </div>
        </ea-col>
      </ea-row>
    </ea-form>
    <qb-iban-code-modal
      id="qbIbanCodeModalBusiness"
      v-model="model.qbIbanCodeModalModel"
      :documentationClientData="documentationClientData"
      :ibanList="model.selectableIbanList"
      :hidden="modalHidden"
      @recallGetIbanList="onRecallGetIbanList"
      @showError="showError"
      @updateParentModel="updateModel"
      @handleGenericError="handleGenericError"
      @closeModal="closeModal"
    />
  </div>
</template>

<script lang="ts">
import {
  Component, Prop
} from 'vue-property-decorator';

import {
  mixins
} from 'vue-class-component';

import {
  EABusinessComponent
} from '@zurich-es-npm/ea-front-web-core';

import QbIbanCodeModel from './qb-iban-code-model';
import QbIbanCodeModalBusiness from '../qb-iban-code-modal/qb-iban-code-modal-business.vue';
import {
  GenericErrorData
} from '../qb-generic-error/qb-generic-error-business.vue';
import {
  DocumentationClientData
} from '@/presentational-components/clientBasicInfo/clientBasicInfo.type';

@Component({
  name: 'qb-iban-code',
  components: {
    QbIbanCodeModal: QbIbanCodeModalBusiness,
  },
})

/**
 * Business Component qb-iban-code
 */
export default class QbIbanCodeBusiness extends mixins<EABusinessComponent<QbIbanCodeModel>>(EABusinessComponent) {
  @Prop()
    consultaOperation?: boolean;

  @Prop()
    documentationClientData!: DocumentationClientData;

  @Prop({
    required: false,
    'default': () => true,
  })
    isIbanRequired?: boolean;

  @Prop({
    required: false,
    'default': () => 6,
  })
    ibanSelectSpan?: number;

  @Prop({
    required: false,
    'default': () => false,
  })
    isReadonly?: boolean;

  /**
   * We use a reactive variable because we need to keep selectedIban updated
   */
  modalHidden = true;

  /**
   * Handles add edit address button click
   */
  openAddEditModal() {
    this.model.qbIbanCodeModalModel = {
      ...this.model.qbIbanCodeModalModel,
      selectedIbanCode: this.model.qbIbanCodeModalModel.selectedIbanCode as string,
    };
    this.modalHidden = false;
  }

  /**
   * Calls the get iban list when an iban was added / updated
   *
   * @param {string} ibanCodeToSelect
   */
  onRecallGetIbanList(ibanCodeToSelect: string) {
    this.$emit('refreshData', ibanCodeToSelect);
  }

  /**
   * Emits showError event
   * @param {GenericErrorData} genericErrorData
   */
  showError(genericErrorData: GenericErrorData) {
    this.$emit('showError', genericErrorData);
  }

  /**
   * Emits handleGenericError event
   * @param {any} args
   */
  handleGenericError(args: any) {
    this.$emit('handleGenericError', args);
  }

  /**
   * Handles iban code change
   */
  onSelectedIbanCodeChange() {
    this.$nextTick(() => {
      this.updateModel();
    });
  }

  /**
   * Updates model and emits event to parent component
   */
  updateModel() {
    this.update();
    this.$nextTick(() => {
      this.$emit('updateParentModel');
    });
  }

  /**
   * Handles iban code modal close
   */
  closeModal(): void {
    this.modalHidden = true;
  }
}
</script>
