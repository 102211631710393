<!-- eslint-disable max-lines -->
<template>
  <div>
    <ea-row>
      <ea-col
        v-if="isUseEdition">
        <ea-alert
          type="info"
          :title="$t('fleets.fleetsFlow.inputVehiclesData.editVehicle.useErrorInfoMessage')"
          class="m-b-16"/>
      </ea-col>
      <ea-col
        v-if="isUseIncompatible">
        <ea-alert
          type="warning"
          :title="$t('fleets.fleetsFlow.inputVehiclesData.editVehicle.useIncompatibleInfoMessage')"
          class="m-b-16"/>
      </ea-col>
      <ea-col
        v-if="isInvalidEffectiveDate">
        <ea-alert
          type="warning"
          :title="$t('common.label.warning')"
          :description="$t('fleets.fleetsFlow.fleet-vehicle-data.maintenance.effective-date-validation-warning')"
          class="m-b-16"/>
      </ea-col>
      <ea-col
        v-if="!vehicleWithPlateNumber && !editionMode">
        <ea-alert
          type="info"
          :title="$t('fleets.fleetsFlow.inputVehiclesData.editVehicle.noMatriculaHelperTitle')"
          :description="$t('fleets.fleetsFlow.inputVehiclesData.editVehicle.alertHelperText')"
          :closable="false"
          class="m-b-16"/>
      </ea-col>
      <ea-col
        v-if="model.errorMsg">
        <ea-alert
          type="error"
          :title="model.errorMsg"
          @close="onCloseErrorAlert"
          class="m-b-16"/>
      </ea-col>
    </ea-row>
    <ea-form
      ref="formVehicle"
      v-if="isInitialLoadComplete"
      :model="model.formValues"
      :validationOptions="formValidation"
      :validateOnRuleChange="false"
      @submit.native.prevent
      >
      <div extraClass="d-display-flex d-wrap d-align-items-flex-end">
        <ea-row>
          <ea-col :span="6">
            <ea-form-item
              :label="$t('fleets.fleetsFlow.inputVehiclesData.manualInput.licensePlateType')"
              prop="plateNumberType"
              :required="!editionMode && !isUseEdition">
              <div slot="tooltipIconInfo" v-if="tooltipPlateNumber && !editionMode">
                <div slot="content" class="wp-88 t-align-center">
                  <span>{{ tooltipPlateNumber }}</span>
                </div>
              </div>
              <ea-select
                v-model="model.formValues.plateNumberType"
                :placeholder="$t('common.label.select')"
                @change="onChangePlateNumberType"
                :readonly="editionMode || isUseEdition"
                >
                <ea-option
                  v-for="item in model.plateTypeSearchList"
                  :key="item.code"
                  :label="item.name"
                  :value="item.code"
                />
              </ea-select>
            </ea-form-item>
          </ea-col>
          <ea-col :span="6">
            <ea-form-item
              :label="$t('fleets.fleetsFlow.inputVehiclesData.manualInput.licensePlateNumber')"
              prop="plateNumber"
              :required="!isBaseSieteEdition && !isCommonEdition && !isUseEdition && !isValuesEdition">
              <ea-input-text
                v-model="model.formValues.plateNumber"
                :readonly="isBaseSieteEdition || isCommonEdition || isUseEdition || isValuesEdition"
                @change="onChangePlateNumberValue"
                @input="onChangePLateNumberInput"
              />
            </ea-form-item>
          </ea-col>

          <ea-col :span="6" v-show="vehicleWithPlateNumber && !editionMode || isInvalidEdition">
            <ea-form-item
              :label="$t('fleets.fleetsFlow.inputVehiclesData.manualInput.vehicleUse')"
              prop="plateUseId"
              :required="!editionMode || isCommonEdition || isUseEdition">
              <ea-select
                :placeholder="$t('common.label.select')"
                v-model="model.formValues.plateUseId"
                :readonly="editionMode && !isCommonEdition && !isUseEdition && !isInvalidEdition"
                @change="onChangeUse">
                <ea-option
                  v-for="item in vehicleUses"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value"/>
              </ea-select>
            </ea-form-item>
          </ea-col>
          
          <ea-col :span="6" v-if="!vehicleWithPlateNumber">
            <ea-form-item
              :label="$t('fleets.fleetsFlow.inputVehiclesData.manualInput.vehicleYear')"
              prop="vehicleYear"
              required>
              <div>
                <ea-date-picker
                  :pickerOptions="vehicleYearDatepickerOptions"
                  :placeholder="$t('common.label.dateSelect')"
                  v-model="model.formValues.vehicleYear"
                ></ea-date-picker>
              </div>
            </ea-form-item>
          </ea-col>
          
          <ea-col :span="6">
            <ea-form-item
              :label="$t('fleets.fleetsFlow.inputVehiclesData.manualInput.accessoriesValue')"
              prop="accessoriesValue"
              :required="!editionMode || isCommonEdition || isUseEdition">
              <ea-input-currency
                v-model="model.formValues.accessoriesValue"
                :readonly="editionMode && !isCommonEdition && !isUseEdition"
              />
            </ea-form-item>
          </ea-col>

        </ea-row>
        <ea-row v-if="
        !vehicleWithPlateNumber || isBaseSieteEdition || isCommonEdition || isUseEdition || isValuesEdition">
          <ea-col
            v-if="isAlertHelperVisible && alertHelperTitle">
            <ea-alert
              type="info"
              :title="alertHelperTitle"
              :description="$t('fleets.fleetsFlow.inputVehiclesData.editVehicle.alertHelperText')"
              :closable="false"
              class="m-b-16"
            />
          </ea-col>
          <ea-col :span="6">
            <ea-form-item
              :label="$t('fleets.fleetsFlow.inputVehiclesData.tableDataHeaders.brand')"
              prop="vehicleBrand"
              :required="vehicleBrands.length !== 1 && !isCommonEdition && !isUseEdition">
              <ea-select
                :placeholder="$t('common.label.select')"
                @change="onChangeVehicleBrand"
                v-model="model.formValues.vehicleBrand"
                :readonly="vehicleBrands.length === 1 || isCommonEdition || isUseEdition|| isValuesEdition">
                <ea-option
                  v-for="item in vehicleBrands"
                  :key="item.codigoMarcaVehiculo"
                  :label="item.descripcionMarcaVehiculo"
                  :value="item.codigoMarcaVehiculo"/>
              </ea-select>
            </ea-form-item>
          </ea-col>
          <ea-col :span="6">
            <ea-form-item
              :label="$t('fleets.fleetsFlow.inputVehiclesData.tableDataHeaders.model')"
              prop="vehicleModel"
              :required="vehicleBrandModels.length !== 1 && !isCommonEdition && !isUseEdition && !isValuesEdition">
              <ea-select
              :placeholder="$t('common.label.select')"
              @change="onChangeVehicleBrandModels"
              v-model="model.formValues.vehicleModel"
              :disabled="vehicleBrandModels.length === 0 && !isCommonEdition"
              :readonly="vehicleBrandModels.length === 1 || isCommonEdition || isUseEdition || isValuesEdition">
              <ea-option
                v-for="item in vehicleBrandModels"
                :key="item.codigoModeloVehiculo"
                :label="item.descripcionModeloVehiculo"
                :value="item.codigoModeloVehiculo"/>
            </ea-select>
            </ea-form-item>
          </ea-col>
          <ea-col :span="6">
            <ea-form-item
              :label="$t('searchOfferFlow.searcher.policyVersionLabel')"
              prop="vehicleVersion"
              :required="vehicleVersions.length !== 1 && !isCommonEdition && !isUseEdition && !isValuesEdition">
              <ea-select
              :placeholder="$t('common.label.select')"
              v-model="model.formValues.vehicleVersion"
              :disabled="vehicleVersions.length === 0 && !isCommonEdition"
              :readonly="vehicleVersions.length === 1 || isCommonEdition || isUseEdition || isValuesEdition">
              <ea-option
                v-for="item in vehicleVersions"
                :key="item.codigoVehiculo"
                :label="item.descripcionVersionVehiculo"
                :value="item.codigoVehiculo"/>
            </ea-select>
            </ea-form-item>
          </ea-col>
          <ea-col :span="6">
            <ea-form-item
              :label="$t('fleets.fleetsFlow.inputVehiclesData.manualInput.vehicleUse')"
              prop="plateUseId"
              :required="true">
              <ea-select
                :placeholder="$t('common.label.select')"
                v-model="model.formValues.plateUseId"
                :disabled="!isDisabledUseSelector"
                :readonly="!editionMode && editionType !== 'none'">
                <ea-option
                  v-for="item in vehicleUses"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value"/>
              </ea-select>
            </ea-form-item>
          </ea-col>
        </ea-row>
        <ea-row>
          <ea-col
            :span="6"
            v-if="showEffectiveDate">
  
            <ea-form-item
              :label="$t('fleets.fleetsFlow.inputVehiclesData.manualInput.effectDate')"
              prop="effectiveDate"
              required>
              <ea-date-picker
                :pickerOptions="vehicleEffectDatepickerOptions"
                :placeholder="$t('common.label.dateSelect')"
                v-model="model.formValues.effectiveDate"
              ></ea-date-picker>
            </ea-form-item>
            
          </ea-col>
  
          <ea-col
            :span="6">
            <ea-form-item
              :label="$t('massiveSearchComponent.placerholders.poliza')"
              prop="policyNumber">
              <div slot="tooltipIconInfo">
                <div slot="content">
                  <span>{{$t('fleets.fleetsFlow.inputVehiclesData.manualInput.policyNumberTooltip')}}</span>
                </div>
              </div>
              <ea-input-text
                v-model="model.formValues.policyNumber"
              />
            </ea-form-item>
          </ea-col>
          
          
          <ea-col :span="6" >
            <ea-form-item
              v-if="vehicleValueSet"
              :label="$t('fleets.fleetsFlow.inputVehiclesData.manualInput.vehicleValue')"
              prop="vehicleValue"
              :required="!isReadonlyVehicleValue"
              >
              <ea-input-currency
                v-model="model.formValues.vehicleValue"
                :readonly="isReadonlyVehicleValue"
              />
            </ea-form-item>
          </ea-col>
          <ea-col :span="6" >
            <ea-form-item
              v-if="isDisabledUseSelector && isMaxWieghtControledVehicle"
              :label="$t('fleets.fleetsFlow.inputVehiclesData.manualInput.vechileMaxWeigh')"
              prop="vehicleMaxWeight"
              :required="!isReadonlyVehicleMaxWeight"
              >
              <div slot="tooltipIconInfo">
                <div slot="content">
                  <span>{{$t('fleets.fleetsFlow.inputVehiclesData.manualInput.vechileMaxWeighTooltip')}}</span>
                </div>
              </div>
              <ea-input-currency
                v-model="model.formValues.vehicleMaxWeight"
                :readonly="isReadonlyVehicleMaxWeight"
                :currency="{ suffix: ' Kg' }"
              />
            </ea-form-item>
          </ea-col>
        </ea-row>
        
      </div>
    </ea-form>
    <div class="d-display-flex d-justify-flex-end">
      <ea-button
        v-if="!editionMode"
        type="primary"
        :disabled="disabledBase7Mode"
        @click="addVehicle()"
      >
        {{ $t('common.label.add') }}
      </ea-button>
      <div v-else>
        <ea-button type="secondary" class="m-r-16" @click="onCancel()">
          {{ $t('common.label.cancel') }}
        </ea-button>
        <ea-button v-if="isInvalidEdition || isValuesEdition" type="primary" @click="saveEditedVehicle()">
          {{ $t('common.label.save') }}
        </ea-button>
        <ea-button
          v-else-if="isBaseSieteEdition" type="primary" @click="saveEditedVehicleAfterBaseSiete()">
          {{ $t('common.label.save') }}
        </ea-button>
        <ea-button
          v-else-if="isUseEdition" type="primary" @click="saveEditedVehicleFixingUse()">
          {{ $t('common.label.save') }}
        </ea-button>
        <ea-button v-else-if="isCommonEdition" type="primary" @click="saveEditedVehicleCommonData()">
          {{ $t('common.label.save') }}
        </ea-button>
        <ea-button v-else-if="isDateEdition" type="primary" @click="saveEditedVehicleCommonData()">
          {{ $t('common.label.save') }}
        </ea-button>
        <ea-button v-else type="primary" @click="saveEditedVehicle()">
          {{ $t('common.label.save') }}
        </ea-button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {
  Component, Prop, Watch,
} from 'vue-property-decorator';
import {
  mixins,
} from 'vue-class-component';
import {
  EABusinessComponent, EAErrorManager, EAMethod, ResponseWithErrors, throwIfResponseHasErrors
} from '@zurich-es-npm/ea-front-web-core';
import FleetManualUploadVehicleModel, {
  BrandModelVersionLevel,
  PossibleBrand,
  PossibleModel,
  PossibleVersion,
  VehicleFormModel,
  VehicleUseData,
} from './fleet-manual-upload-vehicle-model';
import {
  eaCustomValidation,
  EAFormValidationOptions,
  eaRangeValidation,
  eaRequiredNumberValidation,
  eaRequiredValidation,
  EAValidation,
  EAValidationTriggers,
  EAValidationTypes,
  Form,
} from '@zurich-es-npm/ea-front-web-ui';
import {
  plateNumberValidations
} from '@/utils/plate-number-validations/plate-number-validations';
import {
  GetOfferListByRiskRequestSearchByPlateNumberTipoMatriculaEnum as TipoMatriculaEnum
} from '@/services/V1/search-offer/getOfferListByRiskOperation/post';
import FleetUnifiedExpirationModel from '../fleet-unified-expiration/fleet-unified-expiration-model';
import {
  dateBetweenValidation
} from '@/utils/document-validations';
import {
  DatePickerOptions
} from 'element-ui/types/date-picker';
import {
  cloneDeep
} from 'lodash';
import {
  GetVehicleBrandsResponseData
} from '@/services/V1/fleets/getVehicleBrandsOperation/post';
import {
  EAGetVehicleModelsApi, GetVehicleModelsRequest, GetVehicleModelsResponseData
} from '@/services/V1/fleets/getVehicleModelsOperation/post';
import {
  EAGetVehicleVersionsApi, GetVehicleVersionsRequest, GetVehicleVersionsResponseData
} from '@/services/V1/fleets/getVehicleVersionsOperation/post';
import Utils from '@/utils/utils';
import {
  NotificationsUtils
} from '@/utils/notifications/notifications-utils';
import {
  VehicleBrandsUtils
} from '../../utils/vehicle-brands-utils';
import {
  VehiclesRestrictions
} from '@/flows/fleets/model/vehicles-restrictions-model';
import {
  VehicleEditionTypeEnum
} from '@/types/edition/edition-enum.types';
import {
  VehicleTypeEnum
} from '@/types/vehicle-types/vehicle-types-enum.types';
import {
  EAUpdateRiskApi
} from '@/services/V1/fleets/updateRiskOperation/post/api';
import {
  QueryBaseSieteResponsePosiblesVehiculos
} from '@/services/V1/fleets/queryBaseSieteOperation/post';
import {
  EAGetVehicleUsesApi
} from '@/services/V1/fleets/getVehicleUsesOperation/post';
import {
  EAGetVehicleDataApi, GetVehicleDataRequest
} from '@/services/V1/fleets/getVehicleDataOperation/post';
import {
  OperationTypeEnum
} from '@/flows/fleets/fleets-model';
import moment from 'moment';
import {
  ExtraPlateValidations
} from '../../utils/plate-number-validations/extra-plate-validations.utils';

@Component({
  name: 'fleet-manual-upload-vehicle',
})

/**
 * Business Component fleet-manual-upload-vehicle
 */
export default class FleetManualUploadVehicleBusiness extends mixins<
EABusinessComponent<FleetManualUploadVehicleModel>
>(EABusinessComponent) {

  @Prop({
    'default': () => 0
  })
    vehicleRestrictions!: VehiclesRestrictions;

  @Prop({})
    fleetUnifiedExpirationModel!: FleetUnifiedExpirationModel;

  @Prop({
    required: true,
    'default': () => []
  })
    allVehicleUses!: VehicleUseData[];

  @Prop({
    'default': () => []
  })
    predefinedVehicleModels!: GetVehicleModelsResponseData[];

  @Prop({
    'default': () => []
  })
    predefinedVehicleVersions!: GetVehicleVersionsResponseData[];

  @Prop({
    required: false,
    'default': () => false
  })
    editionMode!: boolean;

  @Prop({
    required: false,
    'default': () => VehicleEditionTypeEnum.none
  })
    editionType!: VehicleEditionTypeEnum;

  @Prop()
    disabledBase7Mode!: boolean;

  @Prop({})
    fleetCode!: string;

  @Prop({})
    fleetVersion!: number;

  @Prop({
    required:false,
    'default': () => OperationTypeEnum.NUEVA
  })
    operationType!: OperationTypeEnum;

  @Prop({
    required: true,
    'default': () => []
  })
    pamVehicleTypes!: string[];

  public vehicleUses: VehicleUseData[] = [];

  public vehicleBrands: GetVehicleBrandsResponseData [] = [];

  public vehicleBrandModels: GetVehicleModelsResponseData [] = [];

  public possibleVehicleBrandModels: PossibleModel [] = [];

  public vehicleVersions: GetVehicleVersionsResponseData [] = [];

  public possibleVehicleVersions: PossibleVersion [] = [];

  public tooltipPlateNumber: string = '';

  public vehicleEffectDatepickerOptions: DatePickerOptions = {
    firstDayOfWeek: 1
  };

  public vehicleYearDatepickerOptions: DatePickerOptions = {
    firstDayOfWeek: 1
  };

  public vehicleWithPlateNumber = true;

  updateRiskApi: EAUpdateRiskApi = new EAUpdateRiskApi();

  useErrorNotification: boolean = false;

  isUseIncompatible: boolean = false;

  isInvalidEffectiveDate: boolean = false;

  isReadonlyVehicleValue: boolean = false;

  isReadonlyVehicleMaxWeight: boolean = false;

  isInitialLoadComplete: boolean = false;

  vehicleValueSet: boolean = false;

  isAlertHelperVisible: boolean = false;


  /**
   * 
   */
  get isInvalidEdition(): boolean {
    return this.editionType === VehicleEditionTypeEnum.invalid;
  }


  /**
   * 
   */
  get isValuesEdition(): boolean {
    return this.editionType === VehicleEditionTypeEnum.values;
  }


  /**
   * 
   */
  get isBaseSieteEdition(): boolean {
    return this.editionType === VehicleEditionTypeEnum.basesiete;
  }
  
  /**
   * 
   */
  get isCommonEdition(): boolean {
    return this.editionType === VehicleEditionTypeEnum.common;
  }

  /**
   * 
   */
  get isUseEdition(): boolean {
    return this.editionType === VehicleEditionTypeEnum.use;
  }

  /**
   * 
   */
  get isDateEdition(): boolean {
    return this.editionType === VehicleEditionTypeEnum.date;
  }

  /**
   * 
   */
  get isMaintenanceFlow(): boolean {
    return this.operationType === OperationTypeEnum.MANTENIMIENTO;
  }

  /**
   *
   */
  get isDisabledUseSelector(): boolean {
    return !!this.model.formValues.vehicleVersion;
  }
  
  /**
   *
   */
  get showEffectiveDate(): boolean {
    return this.isMaintenanceFlow || !this.fleetUnifiedExpirationModel.formValues.hasUnifiedExpiration;
  }

  /**
   * 
   */
  get isMaxWieghtControledVehicle(): boolean {
    return this.pamVehicleTypes.includes(this.model.formValues.vehicleType as string);
  }

  /**
   * Getter on alert helper title
   */
  get alertHelperTitle(): string {
    if (this.model.formValues.plateNumberType === TipoMatriculaEnum.R ||
    this.model.formValues.plateNumberType === TipoMatriculaEnum.R1) {
      return this.$t('fleets.fleetsFlow.inputVehiclesData.editVehicle.remolqueVehicleHelperTitle').toString();
    } else if (this.model.formValues.plateNumberType === TipoMatriculaEnum.E ||
      this.model.formValues.plateNumberType === TipoMatriculaEnum.E1) {
      return this.$t('fleets.fleetsFlow.inputVehiclesData.editVehicle.specialVehicleHelperTitle').toString();
    }
    return '';
  }

  // Form rules definition
  public formValidation: EAFormValidationOptions = {
    rules: {
      plateNumberType: [eaRequiredValidation('common.label.validation.fieldRequired')],
      plateNumber: [eaRequiredValidation('common.label.validation.fieldRequired')],
      plateUseId: [eaRequiredValidation('common.label.validation.fieldRequired')],
      accessoriesValue: [
        eaRequiredNumberValidation('common.label.validation.fieldRequired', EAValidationTriggers.BLUR)
      ],
      //TODO: Definición provisional
      vehicleValue: [
        eaRequiredNumberValidation('common.label.validation.fieldRequired', EAValidationTriggers.BLUR),
        eaRangeValidation(
          1,
          601012.100,
          'common.label.validation.invalidRange',
          EAValidationTriggers.BLUR,
          EAValidationTypes.NUMBER
        )
      ],
      vehicleMaxWeight: [
        eaRequiredNumberValidation('common.label.validation.fieldRequired', EAValidationTriggers.BLUR),
        eaRangeValidation(
          1,
          99999,
          'common.label.validation.invalidRange',
          EAValidationTriggers.BLUR,
          EAValidationTypes.NUMBER
        )
      ],
    },
  };

  /** 
   * Constructor
   */
  constructor() {
    super();
    this.vehicleEffectDatepickerOptions.disabledDate = this.disabledDates;
    this.vehicleYearDatepickerOptions.disabledDate = this.disableFutureDates;
  }

  /**
   * Hook on created.
   *
   * @returns {Promise<void>}
   */
  public async created(): Promise<void> {
    this.vehicleValueSet = false;
    await this.$nextTick();
    await this.initialLogic();
    this.isInitialLoadComplete = true;
    this.isAlertHelperVisible = this.editionMode && !this.model.formValues.vehicleBrand;
  }

  /**
   * Initial logic
   * 
   * @returns {Promise}
   */
  @EAMethod({
    loading: true
  })
  async initialLogic(): Promise<void> {
    if (!this.model.formValues.plateNumberType) {
      this.model.formValues.plateNumberType = TipoMatriculaEnum.NOMATRICULA;
    }

    if (this.isMaintenanceFlow) {
      this.addMaintenanceEffectiveDateFieldValidation();
    } else if (!this.fleetUnifiedExpirationModel.formValues.hasUnifiedExpiration) {
      this.addEffectiveDateFieldValidation();
    }
    // Add validations on edition mode
    if (this.editionMode) {
      this.setVehicleUses(this.model.formValues.plateNumberType as unknown as string);
      
      this.formValidation.rules.vehicleBrand = [eaRequiredValidation('common.label.validation.fieldRequired')];
      this.formValidation.rules.vehicleModel = [eaRequiredValidation('common.label.validation.fieldRequired')];
      this.formValidation.rules.vehicleVersion = [eaRequiredValidation('common.label.validation.fieldRequired')];
    } else {
      
      this.setPlateNumberValidation(this.model.formValues.plateNumberType as TipoMatriculaEnum);
      this.model.formValues.accessoriesValue = this.vehicleRestrictions.accesoryDefaultValue;
    }
    // Fetch vehicle brands if no predefined ones
    if (this.isBaseSieteEdition || this.isUseEdition) {
      
      if (this.model.formValues.possibleVehicles?.length) {
        await this.processPossibleVehiclesData(this.model.formValues.possibleVehicles);
      } else {
        this.loadVehicleBrands();
      }
    } else if (this.isCommonEdition || this.isValuesEdition) {
      await this.setUsesByVehicle();
    }
    this.update();
  }

  /**
   * Load the possible vehicle brands
   */
  @EAMethod({
    loading: true
  })
  public async loadVehicleBrands() {
    if (!this.vehicleBrands.length) {
      const vehicleBrands = await VehicleBrandsUtils.getVehicleBrands();
      Utils.sortObjectArrayByProperty(vehicleBrands, 'descripcionMarcaVehiculo');
      this.vehicleBrands = vehicleBrands;
    }
  }

  /**
   * @param {QueryBaseSieteResponsePosiblesVehiculos} possibleVehicles
   */
  public async processPossibleVehiclesData(possibleVehicles: QueryBaseSieteResponsePosiblesVehiculos []) {
    const possibleBrands: PossibleBrand []= [];
    const possibleModels: PossibleModel []= [];
    const possibleVersions: PossibleVersion []= [];
    // Possible vehicle cannot have undefined values
    possibleVehicles = possibleVehicles.filter(
      vehicle => vehicle.codigoVehiculo !== ''
      && vehicle.descripcionMarcaVehiculo!== ''
      && vehicle.descripcionModeloVehiculo !== ''
      && vehicle.descripcionVersionVehiculo !== ''
    );
    possibleVehicles.forEach(possibleVehicle => {
      const newPossibleBrand =
      {
        brandCode: possibleVehicle.codigoVehiculo.slice(0, 4),
        brandDescription: possibleVehicle.descripcionMarcaVehiculo
      };
      if (!possibleBrands.find(prossibleBrand => prossibleBrand.brandCode === newPossibleBrand.brandCode)) {
        possibleBrands.push(newPossibleBrand);
      }
      const newPossibleModel =
      {
        brandCode: possibleVehicle.codigoVehiculo.slice(0, 4),
        modelCode: possibleVehicle.codigoVehiculo.slice(4, 7),
        modelDescription: possibleVehicle.descripcionModeloVehiculo
      };
      if (!possibleModels.find(possibleModel => possibleModel.modelCode === newPossibleModel.modelCode)) {
        possibleModels.push(newPossibleModel);
      }
      const newPossibleVersion =
      {
        modelCode: possibleVehicle.codigoVehiculo.slice(4, 7),
        versionCode: possibleVehicle.codigoVehiculo,
        versionDescription: Utils.valueOrHyphen(possibleVehicle.descripcionVersionVehiculo)
      };
      if (!possibleVersions.find(possibleVersion => possibleVersion.versionCode === newPossibleVersion.versionCode)) {
        possibleVersions.push(newPossibleVersion);
      }
    });
    this.vehicleBrands = possibleBrands.map(possibleBrand => {
      return {
        codigoMarcaVehiculo: possibleBrand.brandCode,
        descripcionMarcaVehiculo: possibleBrand.brandDescription
      };
    });
    this.possibleVehicleBrandModels = possibleModels;
    this.possibleVehicleVersions = possibleVersions;
    if (this.vehicleBrands.length === 1) {
      this.model.formValues.vehicleBrand = this.vehicleBrands[0].codigoMarcaVehiculo;
      await this.onChangeVehicleBrand(this.model.formValues.vehicleBrand as string);
    }
  }

  /**
   * Gets the plate number (matrículas) types for a <select> element.
   */
  @EAMethod({
    loading: true
  })
  public async addVehicle() {
    // Limpiar posible errores mostrados anteriormente
    this.clearAllKindNotifications();
    const plateTypeName = this.model.plateTypeSearchList
      .find(plateType => plateType.code === this.model.formValues.plateNumberType)?.name;
    const vehicleUseName = this.vehicleUses
      .find(vehicleUse => vehicleUse.value === this.model.formValues.plateUseId)?.name;
    const vehicleWithDescriptions =
      this.mapBrandModelAndVersionDescriptions(this.model.formValues);
    const vehicle: VehicleFormModel = {
      ...vehicleWithDescriptions,
      plateTypeName,
      vehicleUseName,
      codigoVehiculo: this.model.formValues.vehicleVersion,
      isBaseSieteData: !!vehicleWithDescriptions.vehicleBrand
      // eslint-disable-next-line max-len
      // If vehicle has brand, we assume vehicle has no plate but bastidor number, so must not be consulted to BaseSIETE
    };

    // If NO-MATRICULA parse serialNumber
    if (vehicle.plateNumberType === TipoMatriculaEnum.NOMATRICULA) {
      vehicle.plateNumber = this._parseSerialNumber(vehicle.plateNumber || '');
    }

    try {
      await this.validation().validate();
      const bussinesInvalidEffectiveDate = this.isMaintenanceFlow && this.validateEffectiveDateMaintenance();
      if (bussinesInvalidEffectiveDate) {
        this.notifyInvalidEffectiveDate();
      } else {
        
        this.$emit('onInsertVehicle', [vehicle]);
        this.$nextTick(() => {
          this.setVehicleUses(this.model.formValues.plateNumberType || '');
          this.resetDefaultVehicleValues();
          this.update();
          const form: Form = this.$refs.formVehicle as Form;
          form.clearValidate();
        });
       
      }
    } catch (error) {
      NotificationsUtils.throwWarning(this.$t('common.label.validation.formWithErrors').toString());
    }
     
  }

  
  /**
   * Truncates serial number following these rules:
   *  - Delete spaces, hyphens and slashes
   *  - If serial number is up to 12 characters, the whole serial number is kept.
   *  - If taller than 12 characters, take the lattest 12 characters.
   * @param {string} serialNumber 
   * @returns {string}
   */
  public _parseSerialNumber(serialNumber: string): string {
    return serialNumber.replace(/-|\s|\//g, '').slice(-12);
  }

  /**
   *
   * Add rule validation for vehicle effective date
   *
   */
  public addEffectiveDateFieldValidation() {
    const endDate = cloneDeep(this.fleetUnifiedExpirationModel.formValues.notUnifiedExpirationDate as Date);
    endDate.setFullYear(endDate.getFullYear() + 1);
    endDate.setDate(endDate.getDate()-1);
    this.formValidation.rules.effectiveDate = [
      eaRequiredValidation('common.label.validation.fieldRequired',
        EAValidationTriggers.BLUR, EAValidationTypes.DATE),
      eaCustomValidation(
        // eslint-disable-next-line max-len
        dateBetweenValidation(this.fleetUnifiedExpirationModel.formValues.notUnifiedExpirationDate as unknown as Date, endDate),
        'common.label.validation.invalidRange',
        EAValidationTriggers.BLUR
      ),
      eaCustomValidation(
        // eslint-disable-next-line max-len
        dateBetweenValidation(this.fleetUnifiedExpirationModel.formValues.notUnifiedExpirationDate as unknown as Date, endDate),
        'common.label.validation.invalidRange',
        EAValidationTriggers.CHANGE
      )
    ];
    this.$forceUpdate();
  }

  /**
   *
   * Add rule validation for vehicle effective date
   *
   */
  public addMaintenanceEffectiveDateFieldValidation() {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const expirationDate = this.fleetUnifiedExpirationModel.formValues.expirationDate as Date;
    this.formValidation.rules.effectiveDate = [
      eaRequiredValidation('common.label.validation.fieldRequired',
        EAValidationTriggers.BLUR, EAValidationTypes.DATE),
      eaCustomValidation(
        // eslint-disable-next-line max-len
        dateBetweenValidation(today, expirationDate),
        'common.label.validation.invalidRange',
        EAValidationTriggers.BLUR
      ),
      eaCustomValidation(
        // eslint-disable-next-line max-len
        dateBetweenValidation(today, expirationDate),
        'common.label.validation.invalidRange',
        EAValidationTriggers.CHANGE
      )
    ];
    this.$forceUpdate();
  }

  /**
   * Dates in the past
   * Only used in not unified expiration date
   * @param {Date} date
   * @returns {boolean}
   */
  public disabledDates(date: Date): boolean {
    let initDate;
    let endDate;
    if (this.isMaintenanceFlow) {
      initDate = new Date();
      initDate.setDate(initDate.getDate() - 1);
      endDate = new Date(this.fleetUnifiedExpirationModel.formValues.expirationDate as Date);
    } else {
      initDate = new Date(this.fleetUnifiedExpirationModel.formValues.notUnifiedExpirationDate as Date);
      endDate = new Date(this.fleetUnifiedExpirationModel.formValues.notUnifiedExpirationDate as Date);
      endDate.setFullYear(endDate.getFullYear() + 1);
      endDate.setDate(endDate.getDate()-1);
    }
    return date.getTime() < initDate.getTime() || date.getTime() > endDate.getTime();
  }

  /**
   * Dates after effective date are desabled
   * @param {Date} date
   * @returns {boolean}
   */
  public disableFutureDates(date: Date): boolean {
    const initDate = new Date();
    return date.getTime() > initDate.getTime();
  }

  /**
   * Set plate number validaiton
   * @param {TipoMatriculaEnum} plateNumberType
   */
  public setPlateNumberValidation(plateNumberType: TipoMatriculaEnum) {
    const validationsRules = plateNumberValidations[plateNumberType]?.validationsRules();

    // Change tootip
    this.tooltipPlateNumber = plateNumberValidations[plateNumberType]?.tooltip;
    // Change the rules validations
    this.formValidation.rules.plateNumber = validationsRules;
    // Handle vehicle data inputs with/without plate number
    if (plateNumberType === TipoMatriculaEnum.NOMATRICULA) {
      this.vehicleWithPlateNumber = false;
      this.formValidation.rules.vehicleYear = [
        eaRequiredValidation('common.label.validation.fieldRequired',
          EAValidationTriggers.CHANGE, EAValidationTypes.DATE),
        eaRequiredValidation('common.label.validation.fieldRequired',
          EAValidationTriggers.BLUR, EAValidationTypes.DATE)];

      this.vehicleYearDatepickerOptions.disabledDate = this.disableFutureDates;
    } else {
      // Add trigger on change validation in edition mode
      if (this.editionMode) {
        validationsRules.forEach(validation => {
          const validationCloned = cloneDeep(validation);
          validationCloned.trigger = EAValidationTriggers.CHANGE;
          this.formValidation.rules.plateNumber.push(validationCloned);
        });
      }
    
      this.formValidation.rules.vehicleYear = [];
      this.vehicleWithPlateNumber = true;
    }
  }

  /**
   * Handler for plate type chages.
   * @param {TipoMatriculaEnum} plateNumberType
   */
  @Watch('model.formValues.plateNumberType', {
    immediate: true,
  })
  public async onChangePlateNumberType(plateNumberType: TipoMatriculaEnum) {
    // In editionMode or isUseEdition plateNumberType sould not enter in this method
    if (this.editionMode || this.isUseEdition) {
      return;
    }
    if (this.model.formValues.plateNumberType) {
      const editFormVehicle = this.$refs.formVehicle as Form;
      editFormVehicle?.clearValidate();
      this.model.formValues.plateTypeName = '';
      this.setPlateNumberValidation(plateNumberType);
      this.model.formValues.plateUseId = '';
      this.setVehicleUses(plateNumberType);
      // Set the correct default accessories value 
      this.setAccessoriesValue(plateNumberType);

      // Set Required Validations for no matrícula
      this.setRequiredValidation(plateNumberType);
        
      // Reset previously selected brand/model/version
      this.resetBrandModelVersionData(BrandModelVersionLevel.Brand);
    
      // Reset vehicle "use"
      this.model.formValues.plateUseId = '';
    
      if (!this.editionMode && plateNumberType === TipoMatriculaEnum.NOMATRICULA) {
        this.loadVehicleBrands();
      }

      this.model.formValues.plateNumber = '';
  
    } else {
      // Sets plateNumberType default value
      this.model.formValues.plateNumberType = TipoMatriculaEnum._1; // Ordinaria
    }
  }

  /**
   * Set the correct default accessories value
   * @param {TipoMatriculaEnum} plateNumberType
   */
  setAccessoriesValue(plateNumberType: TipoMatriculaEnum) {
    if (plateNumberType === TipoMatriculaEnum.C) {
      this.model.formValues.accessoriesValue = this.vehicleRestrictions.mopedAccesoryDefaultValue;
    } else {
      this.model.formValues.accessoriesValue = this.vehicleRestrictions.accesoryDefaultValue;
    }
  }

  /**
   * Set required validations in case of NO MATRICULA
   * @param {TipoMatriculaEnum} plateNumberType
   */
  public setRequiredValidation(plateNumberType: TipoMatriculaEnum) {
    if (plateNumberType === TipoMatriculaEnum.NOMATRICULA) {
      this.formValidation.rules.vehicleBrand = [eaRequiredValidation('common.label.validation.fieldRequired')];
      this.formValidation.rules.vehicleModel = [eaRequiredValidation('common.label.validation.fieldRequired')];
      this.formValidation.rules.vehicleVersion = [eaRequiredValidation('common.label.validation.fieldRequired')];
    } else {
      this.formValidation.rules.vehicleBrand = [];
      this.formValidation.rules.vehicleModel = [];
      this.formValidation.rules.vehicleVersion = [];
    }
  }

  /**
   * Clear the table and set the value
   * @param {string} value
   * @param {string} updateModel
   */
  async onChangePlateNumberValue(value: string) {
    let format = value;
 
    if (value.length > 1 && this.model.formValues.plateNumberType) {
      const withoutSpaces = value.split(' ').join('');
      format = await ExtraPlateValidations.formatPlate(withoutSpaces, this.model.formValues.plateNumberType);
    }
    this.model.formValues = {
      ...this.model.formValues,
      plateNumber: format.toUpperCase()
    };
 
    this.update();
  }

  /**
   * @param {string} value
   */
  async onChangePLateNumberInput(value: string) {
    await this.$nextTick();
 
    const reg: RegExp | undefined =
      plateNumberValidations[this.model.formValues.plateNumberType as TipoMatriculaEnum]?.mask;
   
    if (reg) {
      this.model.formValues.plateNumber = value.replace(reg, '');
    } else {
      this.model.formValues.plateNumber = value;
    }
  }

  /**
   * Set vehicle uses based on plate type
   * @param {string} plateType 
   */
  public setVehicleUses(plateType: string): void {
    if (!plateType) {
      this.vehicleUses = [];
      return;
    }
    
    this.vehicleUses = cloneDeep(this.allVehicleUses.filter(use => use.plateType === plateType));
  }

  /**
   * Resets brand/model/version data depending the desired level.
   * @param {BrandModelVersionLevel} level 
   */
  resetBrandModelVersionData(level: BrandModelVersionLevel): void {
    this.model.formValues.vehicleVersion = ''; // Reset version
    this.vehicleVersions = []; // Reset list of versions
    if (level <= BrandModelVersionLevel.Model) {
      this.model.formValues.vehicleModel = ''; // Reset model
      this.vehicleBrandModels = []; // Reset list of models
    }
    if (level === BrandModelVersionLevel.Brand) {
      this.model.formValues.vehicleBrand = ''; // Reset brand
    }
  }

  /**
   * @param {string} vehicleBrandCode 
   */
  @EAMethod({
    loading: true
  })
  async onChangeVehicleBrand(vehicleBrandCode: string) {
    // Reset selected vehicle model and version
    this.resetBrandModelVersionData(BrandModelVersionLevel.Model);
    if (this.possibleVehicleBrandModels.length) {
      this.vehicleBrandModels =
      this.possibleVehicleBrandModels
        .filter(possibleModel => possibleModel.brandCode === vehicleBrandCode)
        .map(filteredPossibleModel => {
          return {
            codigoModeloVehiculo: filteredPossibleModel.modelCode,
            descripcionModeloVehiculo: filteredPossibleModel.modelDescription
          };
        }
        );
    } else {
      
      const api = new EAGetVehicleModelsApi();
      const request: GetVehicleModelsRequest = {
        codigoMarcaVehiculo: vehicleBrandCode
      };
      const vehicleBrandModelsResponse = await api.getVehicleModelsOperation({
        getVehicleModelsRequest: request
      });
      throwIfResponseHasErrors(vehicleBrandModelsResponse as ResponseWithErrors);
      const vehicleBrandModelsData = vehicleBrandModelsResponse?.data as GetVehicleModelsResponseData [];
      this.vehicleBrandModels = vehicleBrandModelsData;
    }
    if (this.vehicleBrandModels.length === 1) {
      this.model.formValues.vehicleBrand = vehicleBrandCode;
      this.model.formValues.vehicleModel = this.vehicleBrandModels[0].codigoModeloVehiculo;
      this.onChangeVehicleBrandModels(this.model.formValues.vehicleModel as string);
    }
    Utils.sortObjectArrayByProperty(this.vehicleBrandModels, 'descripcionModeloVehiculo');
  }

  /**
   * @param {string} vehicleBrandModelsCode 
   */
  @EAMethod({
    loading: true
  })
  async onChangeVehicleBrandModels(vehicleBrandModelsCode: string) {
    // Reset selected vehicle version
    this.resetBrandModelVersionData(BrandModelVersionLevel.Version);

    const api = new EAGetVehicleVersionsApi();
    const request: GetVehicleVersionsRequest = {
      codigoMarcaVehiculo: this.model.formValues.vehicleBrand as string,
      codigoModeloVehiculo: vehicleBrandModelsCode
    };
    
    const vehicleVersionsResponse = await api.getVehicleVersionsOperation({
      getVehicleVersionsRequest: request
    });
    throwIfResponseHasErrors(vehicleVersionsResponse as ResponseWithErrors);

    if (this.possibleVehicleBrandModels.length) {
      this.vehicleVersions =
      this.possibleVehicleVersions
        .filter(possibleVersion => possibleVersion.modelCode === vehicleBrandModelsCode)
        .map(filteredPossibleVersion => {
          const vehicleVersion = vehicleVersionsResponse?.data?.find(
            version => version.codigoVehiculo === filteredPossibleVersion.versionCode
          );
          return {
            codigoVehiculo: filteredPossibleVersion.versionCode,
            descripcionVersionVehiculo: filteredPossibleVersion.versionDescription,
            tipoVehiculo: vehicleVersion?.tipoVehiculo,
            valorVehiculo: vehicleVersion?.valorVehiculo,
            valorPesoMaximo: vehicleVersion?.valorPesoMaximo
          };
        });
    } else {
      this.vehicleVersions = vehicleVersionsResponse?.data as GetVehicleVersionsResponseData [];
    }

    if (this.vehicleVersions.length === 1) {
      this.model.formValues.vehicleVersion = this.vehicleVersions[0].codigoVehiculo;
      this.model.formValues.vehicleValue = this.vehicleVersions[0].valorVehiculo;
      this.isReadonlyVehicleValue = this.vehicleVersions[0].valorVehiculo as number > 0;
      this.vehicleValueSet = true;
      this.model.formValues.vehicleMaxWeight = this.vehicleVersions[0].valorPesoMaximo;
      this.isReadonlyVehicleMaxWeight = this.vehicleVersions[0].valorPesoMaximo as number > 0 &&
      this.pamVehicleTypes.includes(this.vehicleVersions[0].tipoVehiculo as string);
      this.model.formValues.vehicleType = this.vehicleVersions[0].tipoVehiculo;
      this.update();
    }
    Utils.sortObjectArrayByProperty(this.vehicleVersions, 'descripcionVersionVehiculo');
  }

  /**
   * Fetch all the vehicle uses based on the selected vehicle version
   * @param {string} vehicleVersionCode 
   */
  @EAMethod({
    loading: true
  })
  @Watch('model.formValues.vehicleVersion')
  public async onChangeVehicleVersion() {

    if (!this.model.formValues.vehicleVersion) {
      this.vehicleValueSet = false;
      return;
    }
  
    const parsedUses = await this.getAllVehicleUses();
    
    // eslint-disable-next-line max-len
    const selectedVersion = this.vehicleVersions.find(version => version.codigoVehiculo === this.model.formValues.vehicleVersion);
    const vehicleType = selectedVersion?.tipoVehiculo as string;
    const plateType = this.model.formValues.plateNumberType as string;
    
    this.model.formValues.vehicleType = vehicleType;
    // Filter by plate type and vehicle type
    const filteredUses =
      parsedUses?.filter(use => use.vehicleType === vehicleType && use.plateType === plateType);
    this.model.formValues.vehicleValue = selectedVersion?.valorVehiculo;
    this.isReadonlyVehicleValue = selectedVersion?.valorVehiculo as number > 0;
    this.vehicleValueSet = true;
    this.model.formValues.vehicleMaxWeight = selectedVersion?.valorPesoMaximo;
    this.isReadonlyVehicleMaxWeight = selectedVersion?.valorPesoMaximo as number > 0 &&
    this.pamVehicleTypes.includes(vehicleType);

    if (!filteredUses?.length) {
      // Limpiar posible errores mostrados anteriormente
      this.clearAllKindNotifications();
      if (this.vehicleWithPlateNumber) {
        NotificationsUtils.throwWarning(
          this.$t('fleets.fleetsFlow.fleetManualUploadVehicleBusiness.useNotFound').toString()
        );
      } else {
        NotificationsUtils.throwWarning(
          this.$t('fleets.fleetsFlow.fleetManualUploadVehicleBusiness.invalidVehicleTypeWithoutPlate').toString()
        );
      }
      this.vehicleUses = [];
      this.onCancel();
      return;
    }

    if (vehicleType === VehicleTypeEnum.Motocicletas &&
    this.model.formValues.accessoriesValue === this.vehicleRestrictions.accesoryDefaultValue) {
      this.model.formValues.accessoriesValue = this.vehicleRestrictions.mopedAccesoryDefaultValue;
      this.update();
    }

    this.vehicleUses = filteredUses;
    if (!this.vehicleUses.some(use => use.value === this.model.formValues.plateUseId) &&
    this.model.formValues.plateNumberType !== TipoMatriculaEnum.NOMATRICULA) {
      this.model.formValues.plateUseId = '';
      this.isUseIncompatible = true;
    }
    
    this.update();
  }

  /**
   * Sets plage use id when vehicle use id changes
   * @param {string} plateUseId 
   */
  @EAMethod({
    loading: true
  })
  @Watch('model.formValues.plateUseId')
  public async onChangeUse() {
    if (!this.model.formValues.plateUseId) {
      return;
    }

    const currentUse = this.vehicleUses.find(use => use.value === this.model.formValues.plateUseId);
    this.model.formValues.vehicleUseId = currentUse?.vehicleUseCode;
  }

  /**
   * Set all the availabe uses for the current vehicle code
   */
  @EAMethod({
    loading: true
  })
  async setUsesByVehicle() {
    const api = new EAGetVehicleDataApi();
    const request: GetVehicleDataRequest = {
      codigoVehiculo: this.model.formValues.codigoVehiculo || '',
      codigoPoliza: this.model.formValues.offerNumber || '',
      versionPoliza: this.model.formValues.offerVersion || 0
    };
    
    const results = await Promise.all([
      api.getVehicleDataOperation({
        getVehicleDataRequest: request
      }),
      this.getAllVehicleUses()
    ]);

    const vehicleDataResponse = results[0];
    throwIfResponseHasErrors(vehicleDataResponse as ResponseWithErrors);
    if (vehicleDataResponse?.data?.fechaAntiguedadSinMatricula) {
      this.model.formValues.vehicleYear = new Date(vehicleDataResponse?.data?.fechaAntiguedadSinMatricula);
    }

    const parsedUses = results[1];
    // eslint-disable-next-line max-len
    const vehicleType = vehicleDataResponse?.data?.tipoVehiculo as string;
    const plateType = this.model.formValues.plateNumberType as string;

    // Filter by plate type and vehicle type
    const filteredUses =
      parsedUses?.filter(use => use.vehicleType === vehicleType && use.plateType === plateType);

    this.model.formValues.vehicleUseId =
      filteredUses?.find(vehicleUse => vehicleUse.value === this.model.formValues.plateUseId)?.vehicleUseCode;
    this.model.formValues.vehicleType = vehicleType;
    this.vehicleValueSet = true;
    this.isReadonlyVehicleValue = this.model.formValues.vehicleValue as number > 0;
    this.vehicleValueSet = true;
    this.isReadonlyVehicleMaxWeight = this.model.formValues.vehicleMaxWeight as number > 0 &&
      this.pamVehicleTypes.includes(vehicleType);
      
    if (!filteredUses?.length) {
      NotificationsUtils.throwWarning(
        this.$t('fleets.fleetsFlow.fleetManualUploadVehicleBusiness.useNotFound').toString()
      );
      this.vehicleUses = [];
      this.onCancel();
      return;
    }

    this.vehicleUses = filteredUses;
    this.update(); // Needed to update vehicleUseId and vehicleYear in the model 
  }

  /**
   * 
   */
  async getAllVehicleUses(): Promise<VehicleUseData[]> {
    const getVehicleUsesApi = new EAGetVehicleUsesApi();
    const vehicleUsesResponse = await getVehicleUsesApi.getVehicleUsesOperation({});

    throwIfResponseHasErrors(vehicleUsesResponse as ResponseWithErrors);

    // Map documents with only relevant information
    if (vehicleUsesResponse?.resultadosUsos) {
      return vehicleUsesResponse.resultadosUsos.map(use => {
        return {
          name: use.descripcionElemento,
          value: use.codigoUsoPorMatricula,
          plateType: use.tipoMatricula,
  
          vehicleUseCode: use.codigoUsoVehiculo,
          plateUseCode: use.codigoUsoPorMatricula,
          vehicleType: use.tipoVehiculo
        };
      });
    } else {
      return [];
    }
  }

  /**
   * Reset to the default values of the vehicle form
   */
  public resetDefaultVehicleValues(): void {
    this.model.formValues = {
      plateNumberType: '',
      plateTypeName: '',
      plateNumber: '',
      plateUseId: '',
      vehicleUseId: '',
      vehicleUseName: '',
      accessoriesValue: this.vehicleRestrictions.accesoryDefaultValue,
      policyNumber: '',
      vehicleBrand: '',
      vehicleModel: '',
      vehicleVersion: '',
      effectiveDate: '' as unknown as Date,
      vehicleValue: undefined,
      vehicleMaxWeight: undefined
    };
    this.vehicleWithPlateNumber = true;
    this.vehicleBrandModels = [];
    this.vehicleVersions = [];
  }

  /**
   * Return form validation object
   * @returns {EAValidation}
   */
  public validation(): EAValidation {
    const form: Form = this.$refs.formVehicle as Form;
    return form.validation();
  }

  /**
   *
   */
  @Watch('fleetUnifiedExpirationModel.formValues.notUnifiedExpirationDate', {
    deep: true,
    immediate: true
  })
  onFleetUnifiedExpirationModelChange() {
    if (!this.isMaintenanceFlow) {
      if (this.fleetUnifiedExpirationModel.formValues.hasUnifiedExpiration) {
        this.formValidation.rules.effectiveDate = [];
      } else {
        this.addEffectiveDateFieldValidation();
      }
    }
  }

  /**
   * Save edit form
   */
  @EAMethod({})
  public async saveEditedVehicle() {
    this.clearAllKindNotifications();
    try {
      await this.validation().validate();
      const bussinesInvalidEffectiveDate = this.isMaintenanceFlow && this.validateEffectiveDateMaintenance();
      if (bussinesInvalidEffectiveDate) {
        this.notifyInvalidEffectiveDate();
      } else {
        const vehicleWithUseName = this.vehicleWithUseName();
        this.$emit('updateCommonData', vehicleWithUseName);
      }
    } catch (exception) {
      // TODO: verificar que hacer cuando dá error el modal
    }
  }

  /**
   * Save edit form
   */
  @EAMethod({
    loading: true
  })
  public async saveEditedVehicleAfterBaseSiete() {
    // Limpiar posible errores mostrados anteriormente
    this.clearAllKindNotifications();
    await this.validation().validate();
    const bussinesInvalidEffectiveDate = this.isMaintenanceFlow && this.validateEffectiveDateMaintenance();
    if (bussinesInvalidEffectiveDate) {
      this.notifyInvalidEffectiveDate();
    } else {
      const vehicle: VehicleFormModel =
          this.mapBrandModelAndVersionDescriptions(this.model.formValues);
      this.$emit('fillBaseSIETEData', vehicle);
    }
  }

  /**
   * Save edit form
   */
  @EAMethod({
    loading: true
  })
  public async saveEditedVehicleFixingUse() {
    // Limpiar posible errores mostrados anteriormente
    this.clearAllKindNotifications();
    await this.validation().validate();
    const bussinesInvalidEffectiveDate = this.isMaintenanceFlow && this.validateEffectiveDateMaintenance();
    if (bussinesInvalidEffectiveDate) {
      this.notifyInvalidEffectiveDate();
    } else {
      const vehicleWithUseName = this.vehicleWithUseName();
      const vehicle: VehicleFormModel =
          this.mapBrandModelAndVersionDescriptions(vehicleWithUseName);
      this.$emit('fixIncorrectUse', vehicle);
    }
  }

  /**
   * Save edit form
   */
  @EAMethod({
    loading: true
  })
  public async saveEditedVehicleCommonData() {
    
    this.clearAllKindNotifications();
    await this.validation().validate();
    const bussinesInvalidEffectiveDate = this.isMaintenanceFlow && this.validateEffectiveDateMaintenance();
    if (bussinesInvalidEffectiveDate) {
      this.notifyInvalidEffectiveDate();
    } else {
      const vehicleWithUseName = this.vehicleWithUseName();
      this.$emit('updateCommonData', vehicleWithUseName);
     
    }
  }
  
  /**
   * @returns {boolean}
   */
  public validateEffectiveDateMaintenance(): boolean {
    const today = new Date();
    const effectiveDate = this.fleetUnifiedExpirationModel.formValues.effectiveDate as Date;
    const vehicleEffectiveDate = this.model.formValues.effectiveDate as Date;
     
    if (moment(effectiveDate).
      isAfter(moment(today))) {
      return moment(Utils.returnDateWithHoursMinsReset(vehicleEffectiveDate)).
        isSameOrAfter(Utils.returnDateWithHoursMinsReset(today)) &&
       moment(Utils.returnDateWithHoursMinsReset(vehicleEffectiveDate)).
         isBefore(moment(Utils.returnDateWithHoursMinsReset(effectiveDate)));
          
    }
    return false;
  }

  /**
   * 
   * @param {VehicleFormModel} vehicle 
   * @param {string} codigoVehiculo
   * @returns {VehicleFormModel}
   */
  public mapBrandModelAndVersionDescriptions(vehicle: VehicleFormModel): VehicleFormModel {

    /**
     * Si no tenemos código de vehiculo informado, es porque hemos seleccionado
     *  al menos la versión a mano, la cual corresponde con el codigoVehiculo
     */
    const codigoVehiculo = vehicle.codigoVehiculo as string || vehicle.vehicleVersion as string;
    let vehicleBrand: string | undefined =
      this.model.formValues.vehicleBrand ? this.model.formValues.vehicleBrand : '';
    if (this.vehicleBrands.length) {
      vehicleBrand = this.vehicleBrands.find(
        vehicleBrandSearch => vehicleBrandSearch.codigoMarcaVehiculo === codigoVehiculo.slice(0, 4))?.
        descripcionMarcaVehiculo;
    }

    let vehicleModel: string | undefined =
      this.model.formValues.vehicleModel ? this.model.formValues.vehicleModel : '';
    if (this.vehicleBrandModels.length) {
      vehicleModel = this.vehicleBrandModels.find(
        vehicleModelSearch => vehicleModelSearch.codigoModeloVehiculo === codigoVehiculo.slice(4, 7))?.
        descripcionModeloVehiculo;
    }

    let vehicleVersion: string | undefined =
      this.model.formValues.vehicleVersion ? this.model.formValues.vehicleVersion : '';
    if (this.vehicleVersions.length) {
      vehicleVersion = this.vehicleVersions.find(
        vehicleVersionSearch => vehicleVersionSearch.codigoVehiculo === codigoVehiculo)?.
        descripcionVersionVehiculo;
    }
     
    const foundVehicleUse = this.vehicleUses
      .find(vehicleUse => vehicleUse.value === this.model.formValues.plateUseId);
    const vehicleUseName = foundVehicleUse?.name;
    const vehicleUseId = foundVehicleUse?.vehicleUseCode;
    const plateUseId = foundVehicleUse?.value || vehicle.plateUseId;

    const vehicleWithDescriptions: VehicleFormModel = {
      ...vehicle,
      vehicleBrand,
      vehicleModel,
      vehicleVersion,
      vehicleUseName,
      vehicleUseId,
      codigoVehiculo,
      plateUseId
    };
      
    return vehicleWithDescriptions;
  }

  /**
   * Includes vehicle use name to vehicle
   * @return {VehicleFormModel}
   */
  public vehicleWithUseName(): VehicleFormModel {
    const vehicleUseName = this.allVehicleUses
      .find(vehicleUse => vehicleUse.value === this.model.formValues.plateUseId)?.name || '';
    const vehicleWithUseName = {
      ...this.model.formValues,
      vehicleUseName
    };
    return vehicleWithUseName;
  }

  /**
   * 
   */
  public clearAllKindNotifications() {
    NotificationsUtils.clearNotifications();
    EAErrorManager.clearError();
    this.isInvalidEffectiveDate = false;
  }

  /**
   * 
   */
  public notifyInvalidEffectiveDate() {
    if (this.editionType === VehicleEditionTypeEnum.none) {
      NotificationsUtils.throwWarning(
        this.$t('fleets.fleetsFlow.fleet-vehicle-data.maintenance.effective-date-validation-warning')
          .toString());
    } else {
      this.isInvalidEffectiveDate = true;
    }
  }


  /**
   * Close error alert
   */
  public onCloseErrorAlert() {
    this.model.errorMsg = null;
    this.update();
  }

  /**
   * Close edit form
   */
  public onCancel() {
    this.$emit('closeEditVehicle');
  }
}
</script>
