/* eslint-disable */

/**
 * zonazurich
 * Portal de Mediación Digital
 *
 * The version of the OpenAPI document: 1
 * Contact: digitalarchitecture@zurich.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import {
  EASDKConfig,
  EAUrlUtils
} from '@zurich-es-npm/ea-shared-utils-js';

import * as globalImportUrl from 'url';
import {
  Configuration
} from './configuration';
import globalAxios, {
  AxiosPromise,
  AxiosInstance,
  AxiosResponse
} from 'axios';

const BASE_PATH = EAUrlUtils.removeLastForwardSlash('http://localhost:3000');

/**
 *
 * @export
 */
export const COLLECTION_FORMATS = {
  csv: ',',
  ssv: ' ',
  tsv: '\t',
  pipes: '|',
};

/**
 *  
 * @export
 * @interface RequestArgs
 */
export interface RequestArgs {
  url: string;
  options: any;
}

/**
 * 
 * @export
 * @class BaseAPI
 */
export class BaseAPI {
  protected configuration: Configuration | undefined;

  /**
   *Creates an instance of BaseAPI.
   * @param {Configuration} [configuration]
   * @param {string} [basePath=BASE_PATH]
   * @param {AxiosInstance} [axios=globalAxios]
   * @memberof BaseAPI
   */
  public constructor(
    configuration?: Configuration,
    protected basePath: string = BASE_PATH,
    protected axios: AxiosInstance = globalAxios) {
    if (configuration) {
      this.configuration = configuration;
      this.basePath = configuration.basePath || this.basePath;
    }
  }
}

/**
 * 
 * @export
 * @class RequiredError
 * @extends {Error}
 */
export class RequiredError extends Error {
  public name: 'RequiredError' = 'RequiredError';

  public field: string;

  /**
   *Creates an instance of RequiredError.
   * @param {string} field
   * @param {string} [msg]
   * @memberof RequiredError
   */
  public constructor(field: string, msg?: string) {
    super(msg);
    this.field=field;
  }
}

/**
 * 
 * @export
 * @class AsyncRequestError
 * @extends {Error}
 */
export class AsyncRequestError extends Error {
  public name: 'AsyncRequestError' = 'AsyncRequestError';

  /**
   *Creates an instance of AsyncRequestError.
   * @param {string} field
   * @param {string} [msg]
   * @memberof AsyncRequestError
   */
  public constructor(msg?: string) {
    super(msg);
  }
}

/**
 * 
 * @export
 * @interface Error
 */
export interface Error {
    /**
     * 
     * @type {string}
     * @memberof Error
     */
    code: string;
    /**
     * 
     * @type {string}
     * @memberof Error
     */
    message: string;
    /**
     * 
     * @type {number}
     * @memberof Error
     */
    severity: number;
    /**
     * 
     * @type {Array<object>}
     * @memberof Error
     */
    childErrors?: Array<object>;
}/**
 * 
 * @export
 * @interface SaveFleetDriverPersonRequest
 */
export interface SaveFleetDriverPersonRequest {
    /**
     * 
     * @type {string}
     * @memberof SaveFleetDriverPersonRequest
     */
    codigoPoliza: string;
    /**
     * 
     * @type {number}
     * @memberof SaveFleetDriverPersonRequest
     */
    versionPoliza: number;
    /**
     * 
     * @type {SaveFleetDriverPersonRequestConductor}
     * @memberof SaveFleetDriverPersonRequest
     */
    conductor: SaveFleetDriverPersonRequestConductor;
}/**
 * 
 * @export
 * @interface SaveFleetDriverPersonRequestConductor
 */
export interface SaveFleetDriverPersonRequestConductor {
    /**
     * 
     * @type {boolean}
     * @memberof SaveFleetDriverPersonRequestConductor
     */
    conductorNominado: boolean;
    /**
     * 
     * @type {string}
     * @memberof SaveFleetDriverPersonRequestConductor
     */
    codigoFiliacionConductor: string;
    /**
     * 
     * @type {string}
     * @memberof SaveFleetDriverPersonRequestConductor
     */
    tipoDocumentoConductor: string;
    /**
     * 
     * @type {string}
     * @memberof SaveFleetDriverPersonRequestConductor
     */
    codigoIdentificacionConductor: string;
    /**
     * 
     * @type {string}
     * @memberof SaveFleetDriverPersonRequestConductor
     */
    nombreConductor: string;
    /**
     * 
     * @type {string}
     * @memberof SaveFleetDriverPersonRequestConductor
     */
    primerApellidoConductor: string;
    /**
     * 
     * @type {string}
     * @memberof SaveFleetDriverPersonRequestConductor
     */
    segundoApellidoConductor: string;
    /**
     * 
     * @type {string}
     * @memberof SaveFleetDriverPersonRequestConductor
     */
    fechaNacimientoConductor?: string;
    /**
     * 
     * @type {string}
     * @memberof SaveFleetDriverPersonRequestConductor
     */
    fechaCarneConductor?: string;
}/**
 * 
 * @export
 * @interface SaveFleetDriverPersonResponse
 */
export interface SaveFleetDriverPersonResponse {
    /**
     * 
     * @type {boolean}
     * @memberof SaveFleetDriverPersonResponse
     */
    ok?: boolean;
    /**
     * 
     * @type {Array<Error>}
     * @memberof SaveFleetDriverPersonResponse
     */
    errors?: Array<Error>;
}

type saveFleetDriverPersonOperationParams = {
  saveFleetDriverPersonRequest: SaveFleetDriverPersonRequest,
};

/**
 * SaveFleetDriverPersonApi - axios parameter creator
 * @param {Configuration} [configuration]
 * @returns {any}
 */
const SaveFleetDriverPersonApiAxiosParamCreator = function(configuration?: Configuration): any {
  return {
    
    /**
     * Crea la oferta de flotas
     * @param {SaveFleetDriverPersonRequest} saveFleetDriverPersonRequest 
     * @param {string} [acceptLanguage] El Accept-Language request HTTP header indica en que lenguaje debe responder el backend.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @returns {RequestArgs}
     */
    saveFleetDriverPersonOperation(params: saveFleetDriverPersonOperationParams, options: any = {}): RequestArgs {
      // Verify required parameter 'saveFleetDriverPersonRequest' is not null or undefined
      if (params.saveFleetDriverPersonRequest === null || params.saveFleetDriverPersonRequest === undefined) {
        throw new RequiredError('saveFleetDriverPersonRequest', 'Required parameter saveFleetDriverPersonRequest was null or undefined when calling saveFleetDriverPersonOperation.');
      }
      const localVarPath = `/v1/fleets/saveFleetDriverPersonOperation`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true) as Partial<globalImportUrl.UrlWithParsedQuery>;
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;


  
      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
      // Fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
      const needsSerialization = ('SaveFleetDriverPersonRequest' as any !== 'string') ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization ? JSON.stringify(params.saveFleetDriverPersonRequest || {}) : params.saveFleetDriverPersonRequest || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * SaveFleetDriverPersonApi - functional programming interface
 * @param {Configuration} [configuration]
 * @returns {any}
 */
const SaveFleetDriverPersonApiFp = function(configuration?: Configuration): any {
  return {
    
    /**
     * Crea la oferta de flotas
     * @param {SaveFleetDriverPersonRequest} saveFleetDriverPersonRequest 
     * @param {string} [acceptLanguage] El Accept-Language request HTTP header indica en que lenguaje debe responder el backend.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @returns {any}
     */
    saveFleetDriverPersonOperation(params: saveFleetDriverPersonOperationParams, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<SaveFleetDriverPersonResponse> {
      const localVarAxiosArgs = SaveFleetDriverPersonApiAxiosParamCreator(configuration).saveFleetDriverPersonOperation(params, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH): any => {
        const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * SaveFleetDriverPersonApi - factory interface
 * @param {Configuration} configuration
 * @param {string} basePath
 * @param {AxiosInstance} axios
 * @export
 * @returns {any}
 */
const SaveFleetDriverPersonApiFactory = function(configuration?: Configuration, basePath?: string, axios?: AxiosInstance): any {
  return {
    
    /**
     * Crea la oferta de flotas
     * @param {SaveFleetDriverPersonRequest} saveFleetDriverPersonRequest 
     * @param {string} [acceptLanguage] El Accept-Language request HTTP header indica en que lenguaje debe responder el backend.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @returns {any}
     */
    saveFleetDriverPersonOperation(params: saveFleetDriverPersonOperationParams, options?: any): any {
      return SaveFleetDriverPersonApiFp(configuration).saveFleetDriverPersonOperation(params, options)(axios, basePath);
    },
  };
};

/** 
 * SaveFleetDriverPersonApi - object-oriented interface
 * @export
 * @class SaveFleetDriverPersonApi
 * @extends {BaseAPI}
 */
export class SaveFleetDriverPersonApi extends BaseAPI {
  
  /**
   * Crea la oferta de flotas
   * @param {SaveFleetDriverPersonRequest} saveFleetDriverPersonRequest 
   * @param {string} [acceptLanguage] El Accept-Language request HTTP header indica en que lenguaje debe responder el backend.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @returns {any}
   * @memberof SaveFleetDriverPersonApi
   */
  public saveFleetDriverPersonOperation(params: saveFleetDriverPersonOperationParams, options?: any): any {
    return SaveFleetDriverPersonApiFp(this.configuration).saveFleetDriverPersonOperation(params, options)(this.axios, this.basePath);
  }

}

/**
 * 
 * @export
 * @class EAsaveFleetDriverPersonOperationInputMessage
 */
export class EAsaveFleetDriverPersonOperationInputMessage {
  
  /**
   * 
   * @type {SaveFleetDriverPersonRequest}
   * @memberof EAsaveFleetDriverPersonOperationInputMessage
   */
  public saveFleetDriverPersonRequest: SaveFleetDriverPersonRequest;

  public constructor(saveFleetDriverPersonRequest: SaveFleetDriverPersonRequest, ){
  
  this.saveFleetDriverPersonRequest=saveFleetDriverPersonRequest;
  } 
}



/** 
 * EASaveFleetDriverPersonApi - Architecture client for saveFleetDriverPersonOperation
 * @export
 * @class EASaveFleetDriverPersonApi
 */
export class EASaveFleetDriverPersonApi {

  /**
   * 
   * @param {SaveFleetDriverPersonRequest} saveFleetDriverPersonRequest
   
   * @throws {AsyncRequestError | RequiredError}
   * @returns {Promise<SaveFleetDriverPersonResponse | null>}
  */ 
  public async saveFleetDriverPersonOperation(params: saveFleetDriverPersonOperationParams): Promise<SaveFleetDriverPersonResponse | null> {
    return this.saveFleetDriverPersonOperationPromise(params);
  }

  /**
   * 
   * @param {SaveFleetDriverPersonRequest} saveFleetDriverPersonRequest
   
   * @param {Function} callback
   * @throws {AsyncRequestError | RequiredError}
   */
  public async saveFleetDriverPersonOperationSteps(params: saveFleetDriverPersonOperationParams, callback: (res: AxiosResponse<SaveFleetDriverPersonResponse | null>) => void) {
    let done: boolean = false;
    const httpClient: AxiosInstance = EASDKConfig.getHttpClient('/v1/fleets/saveFleetDriverPersonOperation', 'saveFleetDriverPerson', 'saveFleetDriverPersonOperation');
    const config: Configuration = {};
    const api = new SaveFleetDriverPersonApi(config, '', httpClient);
    const sleep = async(ms: number) => {
      return new Promise(resolve => setTimeout(resolve, ms));
    };
    const headers: { ['Async-Id']?: string } = {};
    while (!done) {
      const output = await api.saveFleetDriverPersonOperation(params, { headers }) as AxiosResponse<SaveFleetDriverPersonResponse | null>;
      callback(output);
      if (output.status === 202) {
        try {
          const retry = parseInt(output.headers['retry']);
          headers['Async-Id'] = output.headers['request-id'];
          if (isNaN(retry) || retry <= 0 || retry > 1000 * 3600) {
            throw new Error(`Retry time not acceptable (in milliseconds): ${retry}`);
          }
          await sleep(retry);
        } catch (err) {
          throw new Error(`Error in Async Request ${err}`);
        }
      } else {
        done = true;
      }
    }
  }

  /**
   * 
   * @param {SaveFleetDriverPersonRequest} saveFleetDriverPersonRequest
   
   * @throws {AsyncRequestError | RequiredError}
   * @returns {Promise<SaveFleetDriverPersonResponse | null>}
   */
  public async saveFleetDriverPersonOperationPromise(params: saveFleetDriverPersonOperationParams): Promise<SaveFleetDriverPersonResponse | null> {
    return new Promise((resolve, reject) => {
      this.saveFleetDriverPersonOperationSteps(params, (response: AxiosResponse<SaveFleetDriverPersonResponse | null>) => {
        if (response.status !== 202) {
          resolve(response.data);
        }
      })
      .catch((err) => {
        reject(err);
      });
    });
  } 
}


