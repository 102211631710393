/* eslint-disable */

/**
 * zonazurich
 * Portal de Mediación Digital
 *
 * The version of the OpenAPI document: 1
 * Contact: digitalarchitecture@zurich.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import {
  EASDKConfig,
  EAUrlUtils
} from '@zurich-es-npm/ea-shared-utils-js';

import * as globalImportUrl from 'url';
import {
  Configuration
} from './configuration';
import globalAxios, {
  AxiosPromise,
  AxiosInstance,
  AxiosResponse
} from 'axios';

const BASE_PATH = EAUrlUtils.removeLastForwardSlash('http://localhost:3000');

/**
 *
 * @export
 */
export const COLLECTION_FORMATS = {
  csv: ',',
  ssv: ' ',
  tsv: '\t',
  pipes: '|',
};

/**
 *  
 * @export
 * @interface RequestArgs
 */
export interface RequestArgs {
  url: string;
  options: any;
}

/**
 * 
 * @export
 * @class BaseAPI
 */
export class BaseAPI {
  protected configuration: Configuration | undefined;

  /**
   *Creates an instance of BaseAPI.
   * @param {Configuration} [configuration]
   * @param {string} [basePath=BASE_PATH]
   * @param {AxiosInstance} [axios=globalAxios]
   * @memberof BaseAPI
   */
  public constructor(
    configuration?: Configuration,
    protected basePath: string = BASE_PATH,
    protected axios: AxiosInstance = globalAxios) {
    if (configuration) {
      this.configuration = configuration;
      this.basePath = configuration.basePath || this.basePath;
    }
  }
}

/**
 * 
 * @export
 * @class RequiredError
 * @extends {Error}
 */
export class RequiredError extends Error {
  public name: 'RequiredError' = 'RequiredError';

  public field: string;

  /**
   *Creates an instance of RequiredError.
   * @param {string} field
   * @param {string} [msg]
   * @memberof RequiredError
   */
  public constructor(field: string, msg?: string) {
    super(msg);
    this.field=field;
  }
}

/**
 * 
 * @export
 * @class AsyncRequestError
 * @extends {Error}
 */
export class AsyncRequestError extends Error {
  public name: 'AsyncRequestError' = 'AsyncRequestError';

  /**
   *Creates an instance of AsyncRequestError.
   * @param {string} field
   * @param {string} [msg]
   * @memberof AsyncRequestError
   */
  public constructor(msg?: string) {
    super(msg);
  }
}

/**
 * 
 * @export
 * @interface Error
 */
export interface Error {
    /**
     * 
     * @type {string}
     * @memberof Error
     */
    code: string;
    /**
     * 
     * @type {string}
     * @memberof Error
     */
    message: string;
    /**
     * 
     * @type {number}
     * @memberof Error
     */
    severity: number;
    /**
     * 
     * @type {Array<object>}
     * @memberof Error
     */
    childErrors?: Array<object>;
}/**
 * 
 * @export
 * @interface GetWarrantiesConsultationModeRequest
 */
export interface GetWarrantiesConsultationModeRequest {
    /**
     * 
     * @type {string}
     * @memberof GetWarrantiesConsultationModeRequest
     */
    codigoPoliza: string;
    /**
     * 
     * @type {number}
     * @memberof GetWarrantiesConsultationModeRequest
     */
    versionPoliza: number;
    /**
     * 
     * @type {string}
     * @memberof GetWarrantiesConsultationModeRequest
     */
    tipoPoliza: GetWarrantiesConsultationModeRequestTipoPolizaEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum GetWarrantiesConsultationModeRequestTipoPolizaEnum {
    Oferta = 'Oferta',
    Pliza = 'Póliza',
    Incompleta = 'Incompleta'
}
/**
 * 
 * @export
 * @interface GetWarrantiesConsultationModeResponse
 */
export interface GetWarrantiesConsultationModeResponse {
    /**
     * 
     * @type {GetWarrantiesConsultationModeResponseData}
     * @memberof GetWarrantiesConsultationModeResponse
     */
    data?: GetWarrantiesConsultationModeResponseData;
    /**
     * 
     * @type {Array<Error>}
     * @memberof GetWarrantiesConsultationModeResponse
     */
    errors?: Array<Error>;
}/**
 * 
 * @export
 * @interface GetWarrantiesConsultationModeResponseData
 */
export interface GetWarrantiesConsultationModeResponseData {
    /**
     * 
     * @type {Array<GetWarrantiesConsultationModeResponseDataDatosTarificacion>}
     * @memberof GetWarrantiesConsultationModeResponseData
     */
    datosTarificacion?: Array<GetWarrantiesConsultationModeResponseDataDatosTarificacion>;
    /**
     * 
     * @type {Array<GetWarrantiesConsultationModeResponseDataDatosGarantias>}
     * @memberof GetWarrantiesConsultationModeResponseData
     */
    datosGarantias?: Array<GetWarrantiesConsultationModeResponseDataDatosGarantias>;
    /**
     * 
     * @type {GetWarrantiesConsultationModeResponseDataDatosGestionCompetencias}
     * @memberof GetWarrantiesConsultationModeResponseData
     */
    datosGestionCompetencias?: GetWarrantiesConsultationModeResponseDataDatosGestionCompetencias;
    /**
     * 
     * @type {Array<GetWarrantiesConsultationModeResponseDataDatosAjustesPrimas>}
     * @memberof GetWarrantiesConsultationModeResponseData
     */
    datosAjustesPrimas?: Array<GetWarrantiesConsultationModeResponseDataDatosAjustesPrimas>;
    /**
     * 
     * @type {Array<GetWarrantiesConsultationModeResponseDataTablaRestricciones>}
     * @memberof GetWarrantiesConsultationModeResponseData
     */
    fraccionamientoFormasPago?: Array<GetWarrantiesConsultationModeResponseDataTablaRestricciones>;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetWarrantiesConsultationModeResponseData
     */
    codigosPropuestas?: Array<string>;
}/**
 * 
 * @export
 * @interface GetWarrantiesConsultationModeResponseDataCadenaLanzamiento
 */
export interface GetWarrantiesConsultationModeResponseDataCadenaLanzamiento {
    /**
     * 
     * @type {string}
     * @memberof GetWarrantiesConsultationModeResponseDataCadenaLanzamiento
     */
    codigoGarantia?: string;
    /**
     * 
     * @type {string}
     * @memberof GetWarrantiesConsultationModeResponseDataCadenaLanzamiento
     */
    codigoElemento?: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetWarrantiesConsultationModeResponseDataCadenaLanzamiento
     */
    elementoModificable?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetWarrantiesConsultationModeResponseDataCadenaLanzamiento
     */
    elementoRequerido?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetWarrantiesConsultationModeResponseDataCadenaLanzamiento
     */
    elementoVisible?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetWarrantiesConsultationModeResponseDataCadenaLanzamiento
     */
    valorElemento?: string;
    /**
     * 
     * @type {GetWarrantiesConsultationModeResponseDataCopiaValor}
     * @memberof GetWarrantiesConsultationModeResponseDataCadenaLanzamiento
     */
    copiaValor?: GetWarrantiesConsultationModeResponseDataCopiaValor;
}/**
 * 
 * @export
 * @interface GetWarrantiesConsultationModeResponseDataCondicionLanzamientoValorAnteriorElemento
 */
export interface GetWarrantiesConsultationModeResponseDataCondicionLanzamientoValorAnteriorElemento {
    /**
     * 
     * @type {string}
     * @memberof GetWarrantiesConsultationModeResponseDataCondicionLanzamientoValorAnteriorElemento
     */
    condicionLanzamientoValorElemento?: string;
    /**
     * 
     * @type {string}
     * @memberof GetWarrantiesConsultationModeResponseDataCondicionLanzamientoValorAnteriorElemento
     */
    condicionLanzamientoRestriccionGarantia?: GetWarrantiesConsultationModeResponseDataCondicionLanzamientoValorAnteriorElementoCondicionLanzamientoRestriccionGarantiaEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum GetWarrantiesConsultationModeResponseDataCondicionLanzamientoValorAnteriorElementoCondicionLanzamientoRestriccionGarantiaEnum {
    Equal = 'Equal',
    NotEqual = 'NotEqual',
    Any = 'Any'
}
/**
 * 
 * @export
 * @interface GetWarrantiesConsultationModeResponseDataCopiaValor
 */
export interface GetWarrantiesConsultationModeResponseDataCopiaValor {
    /**
     * 
     * @type {string}
     * @memberof GetWarrantiesConsultationModeResponseDataCopiaValor
     */
    codigoGarantia: string;
    /**
     * 
     * @type {string}
     * @memberof GetWarrantiesConsultationModeResponseDataCopiaValor
     */
    codigoPropuesta: string;
    /**
     * 
     * @type {string}
     * @memberof GetWarrantiesConsultationModeResponseDataCopiaValor
     */
    codigoElemento: string;
    /**
     * 
     * @type {string}
     * @memberof GetWarrantiesConsultationModeResponseDataCopiaValor
     */
    condicionLanzamientoRestriccionGarantia?: GetWarrantiesConsultationModeResponseDataCopiaValorCondicionLanzamientoRestriccionGarantiaEnum;
    /**
     * 
     * @type {string}
     * @memberof GetWarrantiesConsultationModeResponseDataCopiaValor
     */
    condicionLanzamientoValorElemento?: string;
    /**
     * 
     * @type {GetWarrantiesConsultationModeResponseDataCopiaValorCondicionLanzamientoElemento}
     * @memberof GetWarrantiesConsultationModeResponseDataCopiaValor
     */
    condicionLanzamientoElemento?: GetWarrantiesConsultationModeResponseDataCopiaValorCondicionLanzamientoElemento;
}

/**
    * @export
    * @enum {string}
    */
export enum GetWarrantiesConsultationModeResponseDataCopiaValorCondicionLanzamientoRestriccionGarantiaEnum {
    Equal = 'Equal',
    NotEqual = 'NotEqual',
    Any = 'Any'
}
/**
 * 
 * @export
 * @interface GetWarrantiesConsultationModeResponseDataCopiaValorCondicionLanzamientoElemento
 */
export interface GetWarrantiesConsultationModeResponseDataCopiaValorCondicionLanzamientoElemento {
    /**
     * 
     * @type {string}
     * @memberof GetWarrantiesConsultationModeResponseDataCopiaValorCondicionLanzamientoElemento
     */
    codigoGarantia: string;
    /**
     * 
     * @type {string}
     * @memberof GetWarrantiesConsultationModeResponseDataCopiaValorCondicionLanzamientoElemento
     */
    codigoPropuesta: string;
    /**
     * 
     * @type {string}
     * @memberof GetWarrantiesConsultationModeResponseDataCopiaValorCondicionLanzamientoElemento
     */
    codigoElemento: string;
}/**
 * 
 * @export
 * @interface GetWarrantiesConsultationModeResponseDataDatosAjustesPrimas
 */
export interface GetWarrantiesConsultationModeResponseDataDatosAjustesPrimas {
    /**
     * 
     * @type {string}
     * @memberof GetWarrantiesConsultationModeResponseDataDatosAjustesPrimas
     */
    codigoElemento?: string;
    /**
     * 
     * @type {string}
     * @memberof GetWarrantiesConsultationModeResponseDataDatosAjustesPrimas
     */
    tablaElemento?: string;
    /**
     * 
     * @type {string}
     * @memberof GetWarrantiesConsultationModeResponseDataDatosAjustesPrimas
     */
    valorElemento?: string;
    /**
     * 
     * @type {number}
     * @memberof GetWarrantiesConsultationModeResponseDataDatosAjustesPrimas
     */
    valorMaximoElemento?: number;
    /**
     * 
     * @type {number}
     * @memberof GetWarrantiesConsultationModeResponseDataDatosAjustesPrimas
     */
    valorMinimoElemento?: number;
    /**
     * 
     * @type {boolean}
     * @memberof GetWarrantiesConsultationModeResponseDataDatosAjustesPrimas
     */
    elementoRequerido?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetWarrantiesConsultationModeResponseDataDatosAjustesPrimas
     */
    elementoModificable?: boolean;
    /**
     * 
     * @type {number}
     * @memberof GetWarrantiesConsultationModeResponseDataDatosAjustesPrimas
     */
    longitudElemento?: number;
    /**
     * 
     * @type {number}
     * @memberof GetWarrantiesConsultationModeResponseDataDatosAjustesPrimas
     */
    numeroDecimalesElemento?: number;
    /**
     * 
     * @type {Array<GetWarrantiesConsultationModeResponseDataTablaRestricciones>}
     * @memberof GetWarrantiesConsultationModeResponseDataDatosAjustesPrimas
     */
    tablaRestricciones?: Array<GetWarrantiesConsultationModeResponseDataTablaRestricciones>;
}/**
 * 
 * @export
 * @interface GetWarrantiesConsultationModeResponseDataDatosGarantias
 */
export interface GetWarrantiesConsultationModeResponseDataDatosGarantias {
    /**
     * 
     * @type {string}
     * @memberof GetWarrantiesConsultationModeResponseDataDatosGarantias
     */
    codigoGarantia?: string;
    /**
     * 
     * @type {Array<GetWarrantiesConsultationModeResponseDataGarantiaSeleccionada>}
     * @memberof GetWarrantiesConsultationModeResponseDataDatosGarantias
     */
    garantiaSeleccionada?: Array<GetWarrantiesConsultationModeResponseDataGarantiaSeleccionada>;
    /**
     * 
     * @type {Array<GetWarrantiesConsultationModeResponseDataElementosGarantias>}
     * @memberof GetWarrantiesConsultationModeResponseDataDatosGarantias
     */
    elementosGarantias?: Array<GetWarrantiesConsultationModeResponseDataElementosGarantias>;
    /**
     * 
     * @type {Array<GetWarrantiesConsultationModeResponseDataLanzadorPorCheck>}
     * @memberof GetWarrantiesConsultationModeResponseDataDatosGarantias
     */
    lanzadorPorCheck?: Array<GetWarrantiesConsultationModeResponseDataLanzadorPorCheck>;
}/**
 * 
 * @export
 * @interface GetWarrantiesConsultationModeResponseDataDatosGestionCompetencias
 */
export interface GetWarrantiesConsultationModeResponseDataDatosGestionCompetencias {
    /**
     * 
     * @type {boolean}
     * @memberof GetWarrantiesConsultationModeResponseDataDatosGestionCompetencias
     */
    gestionDeCompetencias?: boolean;
    /**
     * 
     * @type {Array<GetWarrantiesConsultationModeResponseDataDatosGestionCompetenciasCompetencesManagementByOfferData>}
     * @memberof GetWarrantiesConsultationModeResponseDataDatosGestionCompetencias
     */
    competencesManagementByOfferData?: Array<GetWarrantiesConsultationModeResponseDataDatosGestionCompetenciasCompetencesManagementByOfferData>;
}/**
 * 
 * @export
 * @interface GetWarrantiesConsultationModeResponseDataDatosGestionCompetenciasCompetencesManagementByOfferData
 */
export interface GetWarrantiesConsultationModeResponseDataDatosGestionCompetenciasCompetencesManagementByOfferData {
    /**
     * 
     * @type {string}
     * @memberof GetWarrantiesConsultationModeResponseDataDatosGestionCompetenciasCompetencesManagementByOfferData
     */
    codigoObjeto?: string;
    /**
     * 
     * @type {string}
     * @memberof GetWarrantiesConsultationModeResponseDataDatosGestionCompetenciasCompetencesManagementByOfferData
     */
    codigoOpcion?: string;
    /**
     * 
     * @type {string}
     * @memberof GetWarrantiesConsultationModeResponseDataDatosGestionCompetenciasCompetencesManagementByOfferData
     */
    codigoPropuesta?: string;
    /**
     * 
     * @type {string}
     * @memberof GetWarrantiesConsultationModeResponseDataDatosGestionCompetenciasCompetencesManagementByOfferData
     */
    codigoMCT?: string;
    /**
     * 
     * @type {string}
     * @memberof GetWarrantiesConsultationModeResponseDataDatosGestionCompetenciasCompetencesManagementByOfferData
     */
    descripcionElemento?: string;
    /**
     * 
     * @type {number}
     * @memberof GetWarrantiesConsultationModeResponseDataDatosGestionCompetenciasCompetencesManagementByOfferData
     */
    codigoErrorAutorizacion?: number;
    /**
     * 
     * @type {string}
     * @memberof GetWarrantiesConsultationModeResponseDataDatosGestionCompetenciasCompetencesManagementByOfferData
     */
    estadoControlCompetencias?: GetWarrantiesConsultationModeResponseDataDatosGestionCompetenciasCompetencesManagementByOfferDataEstadoControlCompetenciasEnum;
    /**
     * 
     * @type {string}
     * @memberof GetWarrantiesConsultationModeResponseDataDatosGestionCompetenciasCompetencesManagementByOfferData
     */
    descripcionErrorAutorizacion?: string;
    /**
     * 
     * @type {string}
     * @memberof GetWarrantiesConsultationModeResponseDataDatosGestionCompetenciasCompetencesManagementByOfferData
     */
    descripcionEstadoControlCompetencias?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum GetWarrantiesConsultationModeResponseDataDatosGestionCompetenciasCompetencesManagementByOfferDataEstadoControlCompetenciasEnum {
    A = 'A',
    P = 'P',
    R = 'R'
}
/**
 * 
 * @export
 * @interface GetWarrantiesConsultationModeResponseDataDatosPropuestas
 */
export interface GetWarrantiesConsultationModeResponseDataDatosPropuestas {
    /**
     * 
     * @type {string}
     * @memberof GetWarrantiesConsultationModeResponseDataDatosPropuestas
     */
    codigoPropuesta?: string;
    /**
     * 
     * @type {string}
     * @memberof GetWarrantiesConsultationModeResponseDataDatosPropuestas
     */
    tablaElemento?: string;
    /**
     * 
     * @type {string}
     * @memberof GetWarrantiesConsultationModeResponseDataDatosPropuestas
     */
    valorElemento?: string;
    /**
     * 
     * @type {number}
     * @memberof GetWarrantiesConsultationModeResponseDataDatosPropuestas
     */
    valorMinimoElemento?: number;
    /**
     * 
     * @type {number}
     * @memberof GetWarrantiesConsultationModeResponseDataDatosPropuestas
     */
    valorMaximoElemento?: number;
    /**
     * 
     * @type {boolean}
     * @memberof GetWarrantiesConsultationModeResponseDataDatosPropuestas
     */
    elementoModificable?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetWarrantiesConsultationModeResponseDataDatosPropuestas
     */
    elementoRequerido?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetWarrantiesConsultationModeResponseDataDatosPropuestas
     */
    elementoVisible?: boolean;
    /**
     * 
     * @type {number}
     * @memberof GetWarrantiesConsultationModeResponseDataDatosPropuestas
     */
    longitudElemento?: number;
    /**
     * 
     * @type {number}
     * @memberof GetWarrantiesConsultationModeResponseDataDatosPropuestas
     */
    numeroDecimalesElemento?: number;
    /**
     * 
     * @type {string}
     * @memberof GetWarrantiesConsultationModeResponseDataDatosPropuestas
     */
    tipoVariable?: GetWarrantiesConsultationModeResponseDataDatosPropuestasTipoVariableEnum;
    /**
     * 
     * @type {string}
     * @memberof GetWarrantiesConsultationModeResponseDataDatosPropuestas
     */
    tipoMascara?: GetWarrantiesConsultationModeResponseDataDatosPropuestasTipoMascaraEnum;
    /**
     * 
     * @type {string}
     * @memberof GetWarrantiesConsultationModeResponseDataDatosPropuestas
     */
    tipoPropuesta?: GetWarrantiesConsultationModeResponseDataDatosPropuestasTipoPropuestaEnum;
    /**
     * 
     * @type {Array<GetWarrantiesConsultationModeResponseDataTablaRestricciones>}
     * @memberof GetWarrantiesConsultationModeResponseDataDatosPropuestas
     */
    tablaRestricciones?: Array<GetWarrantiesConsultationModeResponseDataTablaRestricciones>;
    /**
     * 
     * @type {Array<GetWarrantiesConsultationModeResponseDataLanzadorPorValor>}
     * @memberof GetWarrantiesConsultationModeResponseDataDatosPropuestas
     */
    lanzadorPorValor?: Array<GetWarrantiesConsultationModeResponseDataLanzadorPorValor>;
}

/**
    * @export
    * @enum {string}
    */
export enum GetWarrantiesConsultationModeResponseDataDatosPropuestasTipoVariableEnum {
    A = 'A',
    N = 'N'
}
/**
    * @export
    * @enum {string}
    */
export enum GetWarrantiesConsultationModeResponseDataDatosPropuestasTipoMascaraEnum {
    A = 'A',
    C = 'C',
    D = 'D',
    F = 'F',
    I = 'I',
    M = 'M',
    P = 'P',
    R = 'R',
    T = 'T'
}
/**
    * @export
    * @enum {string}
    */
export enum GetWarrantiesConsultationModeResponseDataDatosPropuestasTipoPropuestaEnum {
    M = 'M',
    S = 'S',
    O = 'O',
    N = 'N'
}
/**
 * 
 * @export
 * @interface GetWarrantiesConsultationModeResponseDataDatosTarificacion
 */
export interface GetWarrantiesConsultationModeResponseDataDatosTarificacion {
    /**
     * 
     * @type {string}
     * @memberof GetWarrantiesConsultationModeResponseDataDatosTarificacion
     */
    codigoPropuesta?: string;
    /**
     * 
     * @type {number}
     * @memberof GetWarrantiesConsultationModeResponseDataDatosTarificacion
     */
    importeAcumuladoRecibo?: number;
    /**
     * 
     * @type {number}
     * @memberof GetWarrantiesConsultationModeResponseDataDatosTarificacion
     */
    importePrimerRecibo?: number;
    /**
     * 
     * @type {number}
     * @memberof GetWarrantiesConsultationModeResponseDataDatosTarificacion
     */
    importeRecibosSucesivos?: number;
    /**
     * 
     * @type {number}
     * @memberof GetWarrantiesConsultationModeResponseDataDatosTarificacion
     */
    importeTotalRecibo?: number;
    /**
     * 
     * @type {string}
     * @memberof GetWarrantiesConsultationModeResponseDataDatosTarificacion
     */
    formaPagoTarificacion?: GetWarrantiesConsultationModeResponseDataDatosTarificacionFormaPagoTarificacionEnum;
    /**
     * 
     * @type {boolean}
     * @memberof GetWarrantiesConsultationModeResponseDataDatosTarificacion
     */
    noPrimaMinima?: boolean;
}

/**
    * @export
    * @enum {string}
    */
export enum GetWarrantiesConsultationModeResponseDataDatosTarificacionFormaPagoTarificacionEnum {
    Anual = 'Anual',
    Unica = 'Unica',
    Semestral = 'Semestral',
    Trimestral = 'Trimestral',
    Mensual = 'Mensual'
}
/**
 * 
 * @export
 * @interface GetWarrantiesConsultationModeResponseDataElementosGarantias
 */
export interface GetWarrantiesConsultationModeResponseDataElementosGarantias {
    /**
     * 
     * @type {string}
     * @memberof GetWarrantiesConsultationModeResponseDataElementosGarantias
     */
    codigoElemento?: string;
    /**
     * 
     * @type {Array<GetWarrantiesConsultationModeResponseDataDatosPropuestas>}
     * @memberof GetWarrantiesConsultationModeResponseDataElementosGarantias
     */
    datosPropuestas?: Array<GetWarrantiesConsultationModeResponseDataDatosPropuestas>;
}/**
 * 
 * @export
 * @interface GetWarrantiesConsultationModeResponseDataGarantiaSeleccionada
 */
export interface GetWarrantiesConsultationModeResponseDataGarantiaSeleccionada {
    /**
     * 
     * @type {string}
     * @memberof GetWarrantiesConsultationModeResponseDataGarantiaSeleccionada
     */
    codigoPropuesta?: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetWarrantiesConsultationModeResponseDataGarantiaSeleccionada
     */
    garantiaSeleccionada?: boolean;
}/**
 * 
 * @export
 * @interface GetWarrantiesConsultationModeResponseDataLanzadorPorCheck
 */
export interface GetWarrantiesConsultationModeResponseDataLanzadorPorCheck {
    /**
     * 
     * @type {string}
     * @memberof GetWarrantiesConsultationModeResponseDataLanzadorPorCheck
     */
    condicionLanzamientoValorElemento?: string;
    /**
     * 
     * @type {string}
     * @memberof GetWarrantiesConsultationModeResponseDataLanzadorPorCheck
     */
    condicionLanzamientoRestriccionGarantia?: GetWarrantiesConsultationModeResponseDataLanzadorPorCheckCondicionLanzamientoRestriccionGarantiaEnum;
    /**
     * 
     * @type {Array<GetWarrantiesConsultationModeResponseDataCadenaLanzamiento>}
     * @memberof GetWarrantiesConsultationModeResponseDataLanzadorPorCheck
     */
    cadenaLanzamiento?: Array<GetWarrantiesConsultationModeResponseDataCadenaLanzamiento>;
}

/**
    * @export
    * @enum {string}
    */
export enum GetWarrantiesConsultationModeResponseDataLanzadorPorCheckCondicionLanzamientoRestriccionGarantiaEnum {
    Equal = 'Equal',
    NotEqual = 'NotEqual',
    Any = 'Any'
}
/**
 * 
 * @export
 * @interface GetWarrantiesConsultationModeResponseDataLanzadorPorValor
 */
export interface GetWarrantiesConsultationModeResponseDataLanzadorPorValor {
    /**
     * 
     * @type {string}
     * @memberof GetWarrantiesConsultationModeResponseDataLanzadorPorValor
     */
    condicionLanzamientoValorElemento?: string;
    /**
     * 
     * @type {string}
     * @memberof GetWarrantiesConsultationModeResponseDataLanzadorPorValor
     */
    condicionLanzamientoRestriccionGarantia?: GetWarrantiesConsultationModeResponseDataLanzadorPorValorCondicionLanzamientoRestriccionGarantiaEnum;
    /**
     * 
     * @type {GetWarrantiesConsultationModeResponseDataCondicionLanzamientoValorAnteriorElemento}
     * @memberof GetWarrantiesConsultationModeResponseDataLanzadorPorValor
     */
    condicionLanzamientoValorAnteriorElemento?: GetWarrantiesConsultationModeResponseDataCondicionLanzamientoValorAnteriorElemento;
    /**
     * 
     * @type {Array<GetWarrantiesConsultationModeResponseDataCadenaLanzamiento>}
     * @memberof GetWarrantiesConsultationModeResponseDataLanzadorPorValor
     */
    cadenaLanzamiento?: Array<GetWarrantiesConsultationModeResponseDataCadenaLanzamiento>;
}

/**
    * @export
    * @enum {string}
    */
export enum GetWarrantiesConsultationModeResponseDataLanzadorPorValorCondicionLanzamientoRestriccionGarantiaEnum {
    Equal = 'Equal',
    NotEqual = 'NotEqual',
    Any = 'Any'
}
/**
 * 
 * @export
 * @interface GetWarrantiesConsultationModeResponseDataTablaRestricciones
 */
export interface GetWarrantiesConsultationModeResponseDataTablaRestricciones {
    /**
     * 
     * @type {string}
     * @memberof GetWarrantiesConsultationModeResponseDataTablaRestricciones
     */
    nombreRestriccion?: string;
    /**
     * 
     * @type {string}
     * @memberof GetWarrantiesConsultationModeResponseDataTablaRestricciones
     */
    valorRestriccion?: string;
}

type getWarrantiesConsultationModeOperationParams = {
  getWarrantiesConsultationModeRequest: GetWarrantiesConsultationModeRequest,
};

/**
 * GetWarrantiesConsultationModeApi - axios parameter creator
 * @param {Configuration} [configuration]
 * @returns {any}
 */
const GetWarrantiesConsultationModeApiAxiosParamCreator = function(configuration?: Configuration): any {
  return {
    
    /**
     * Recupera los datos de garantías, en modo lectura, en base a una número de oferta
     * @param {GetWarrantiesConsultationModeRequest} getWarrantiesConsultationModeRequest 
     * @param {string} [acceptLanguage] El Accept-Language request HTTP header indica en que lenguaje debe responder el backend.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @returns {RequestArgs}
     */
    getWarrantiesConsultationModeOperation(params: getWarrantiesConsultationModeOperationParams, options: any = {}): RequestArgs {
      // Verify required parameter 'getWarrantiesConsultationModeRequest' is not null or undefined
      if (params.getWarrantiesConsultationModeRequest === null || params.getWarrantiesConsultationModeRequest === undefined) {
        throw new RequiredError('getWarrantiesConsultationModeRequest', 'Required parameter getWarrantiesConsultationModeRequest was null or undefined when calling getWarrantiesConsultationModeOperation.');
      }
      const localVarPath = `/v1/quoteAndBuy/getWarrantiesConsultationModeOperation`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true) as Partial<globalImportUrl.UrlWithParsedQuery>;
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;


  
      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
      // Fix override query string Detail: https://stackoverflow.com/a/7517673/1077943

      delete localVarUrlObj.search;
      localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
      const needsSerialization = ('GetWarrantiesConsultationModeRequest' as any !== 'string') ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization ? JSON.stringify(params.getWarrantiesConsultationModeRequest || {}) : params.getWarrantiesConsultationModeRequest || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * GetWarrantiesConsultationModeApi - functional programming interface
 * @param {Configuration} [configuration]
 * @returns {any}
 */
const GetWarrantiesConsultationModeApiFp = function(configuration?: Configuration): any {
  return {
    
    /**
     * Recupera los datos de garantías, en modo lectura, en base a una número de oferta
     * @param {GetWarrantiesConsultationModeRequest} getWarrantiesConsultationModeRequest 
     * @param {string} [acceptLanguage] El Accept-Language request HTTP header indica en que lenguaje debe responder el backend.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @returns {any}
     */
    getWarrantiesConsultationModeOperation(params: getWarrantiesConsultationModeOperationParams, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetWarrantiesConsultationModeResponse> {
      const localVarAxiosArgs = GetWarrantiesConsultationModeApiAxiosParamCreator(configuration).getWarrantiesConsultationModeOperation(params, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH): any => {
        const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * GetWarrantiesConsultationModeApi - factory interface
 * @param {Configuration} configuration
 * @param {string} basePath
 * @param {AxiosInstance} axios
 * @export
 * @returns {any}
 */
const GetWarrantiesConsultationModeApiFactory = function(configuration?: Configuration, basePath?: string, axios?: AxiosInstance): any {
  return {
    
    /**
     * Recupera los datos de garantías, en modo lectura, en base a una número de oferta
     * @param {GetWarrantiesConsultationModeRequest} getWarrantiesConsultationModeRequest 
     * @param {string} [acceptLanguage] El Accept-Language request HTTP header indica en que lenguaje debe responder el backend.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @returns {any}
     */
    getWarrantiesConsultationModeOperation(params: getWarrantiesConsultationModeOperationParams, options?: any): any {
      return GetWarrantiesConsultationModeApiFp(configuration).getWarrantiesConsultationModeOperation(params, options)(axios, basePath);
    },
  };
};

/** 
 * GetWarrantiesConsultationModeApi - object-oriented interface
 * @export
 * @class GetWarrantiesConsultationModeApi
 * @extends {BaseAPI}
 */
export class GetWarrantiesConsultationModeApi extends BaseAPI {
  
  /**
   * Recupera los datos de garantías, en modo lectura, en base a una número de oferta
   * @param {GetWarrantiesConsultationModeRequest} getWarrantiesConsultationModeRequest 
   * @param {string} [acceptLanguage] El Accept-Language request HTTP header indica en que lenguaje debe responder el backend.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @returns {any}
   * @memberof GetWarrantiesConsultationModeApi
   */
  public getWarrantiesConsultationModeOperation(params: getWarrantiesConsultationModeOperationParams, options?: any): any {
    return GetWarrantiesConsultationModeApiFp(this.configuration).getWarrantiesConsultationModeOperation(params, options)(this.axios, this.basePath);
  }

}

/**
 * 
 * @export
 * @class EAgetWarrantiesConsultationModeOperationInputMessage
 */
export class EAgetWarrantiesConsultationModeOperationInputMessage {
  
  /**
   * 
   * @type {GetWarrantiesConsultationModeRequest}
   * @memberof EAgetWarrantiesConsultationModeOperationInputMessage
   */
  public getWarrantiesConsultationModeRequest: GetWarrantiesConsultationModeRequest;

  public constructor(getWarrantiesConsultationModeRequest: GetWarrantiesConsultationModeRequest, ){
  
  this.getWarrantiesConsultationModeRequest=getWarrantiesConsultationModeRequest;
  } 
}



/** 
 * EAGetWarrantiesConsultationModeApi - Architecture client for getWarrantiesConsultationModeOperation
 * @export
 * @class EAGetWarrantiesConsultationModeApi
 */
export class EAGetWarrantiesConsultationModeApi {

  /**
   * 
   * @param {GetWarrantiesConsultationModeRequest} getWarrantiesConsultationModeRequest
   
   * @param {Configuration} configuration
   * @throws {AsyncRequestError | RequiredError}
   * @returns {Promise<GetWarrantiesConsultationModeResponse | null>}
  */ 
  public async getWarrantiesConsultationModeOperation(params: getWarrantiesConsultationModeOperationParams, configuration: Configuration = {}): Promise<GetWarrantiesConsultationModeResponse | null> {
    return this.getWarrantiesConsultationModeOperationPromise(params, configuration);
  }

  /**
   * 
   * @param {GetWarrantiesConsultationModeRequest} getWarrantiesConsultationModeRequest
   
   * @param {Configuration} configuration
   * @param {Function} callback
   * @throws {AsyncRequestError | RequiredError}
   */
  public async getWarrantiesConsultationModeOperationSteps(params: getWarrantiesConsultationModeOperationParams, configuration: Configuration, callback: (res: AxiosResponse<GetWarrantiesConsultationModeResponse | null>) => void) {
    let done: boolean = false;
    const httpClient: AxiosInstance = EASDKConfig.getHttpClient('/v1/quoteAndBuy/getWarrantiesConsultationModeOperation', 'getWarrantiesConsultationMode', 'getWarrantiesConsultationModeOperation');
    const api = new GetWarrantiesConsultationModeApi(configuration, '', httpClient);
    const sleep = async(ms: number) => {
      return new Promise(resolve => setTimeout(resolve, ms));
    };
    const headers: { ['Async-Id']?: string } = {};
    while (!done) {
      const output = await api.getWarrantiesConsultationModeOperation(params, { headers }) as AxiosResponse<GetWarrantiesConsultationModeResponse | null>;
      callback(output);
      if (output.status === 202) {
        try {
          const retry = parseInt(output.headers['retry']);
          headers['Async-Id'] = output.headers['request-id'];
          if (isNaN(retry) || retry <= 0 || retry > 1000 * 3600) {
            throw new Error(`Retry time not acceptable (in milliseconds): ${retry}`);
          }
          await sleep(retry);
        } catch (err) {
          throw new Error(`Error in Async Request ${err}`);
        }
      } else {
        done = true;
      }
    }
  }

  /**
   * 
   * @param {GetWarrantiesConsultationModeRequest} getWarrantiesConsultationModeRequest
   
   * @param {Configuration} configuration
   * @throws {AsyncRequestError | RequiredError}
   * @returns {Promise<GetWarrantiesConsultationModeResponse | null>}
   */
  public async getWarrantiesConsultationModeOperationPromise(params: getWarrantiesConsultationModeOperationParams, configuration: Configuration): Promise<GetWarrantiesConsultationModeResponse | null> {
    return new Promise((resolve, reject) => {
      this.getWarrantiesConsultationModeOperationSteps(params, configuration, (response: AxiosResponse<GetWarrantiesConsultationModeResponse | null>) => {
        if (response.status !== 202) {
          resolve(response.data);
        }
      })
      .catch((err) => {
        reject(err);
      });
    });
  } 
}


