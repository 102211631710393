import {
  GetPersonAddressesResponseDomicilios
} from '@/services/V1/persons/getPersonAddressesOperation/post';
import PostCodeLocationInputModel from '../../../post-code-location-input/post-code-location-input-model';
import MainAddressChangeModel from '../main-address-change/main-address-change-model';

export interface AddressEditionFormModel{
  addressType?: string;
  addressName?: string;
  addressNumber?: string;
  additionalInfo?: string;
  addressCountry?: string;
  province?: string;
  city?: string;
  zipCode?: string;
  isMainAddress?: boolean;
}

/**
 * Model add-address-tomador
 */
class AddAddressTomadorModel {
  public addresses: GetPersonAddressesResponseDomicilios[] = [];

  public selectedAddressCode?: string = '';

  public addressInEditionIdx: number | undefined = undefined;

  public isAddingEditting: boolean = false;

  public addressEditionForm: AddressEditionFormModel = {
    isMainAddress: false,
    addressType: '',
    addressName: '',
    addressNumber: '',
    addressCountry: '',
    additionalInfo: '',
  };

  public postCodeLocationInputModel: PostCodeLocationInputModel = new PostCodeLocationInputModel();

  public mainAddressChangeModel: MainAddressChangeModel = new MainAddressChangeModel();
}

export default AddAddressTomadorModel;
