import {
  PolicyMovementType
} from '@/flows/quote-buy/quote-buy-model';
import PermissionUtils from './permissions-utils';

/**
 * Offer Policy utilities
 */
export class OfferPolicyUtils {

  /**
   * Utility to know if user has only allowed 'cobro banco' payment channel.
   * @returns {boolean}
   */
  public static userHasOnlyBankCharge(): boolean {
    const userAbilities = PermissionUtils.getUserAbilities();
    return PermissionUtils.hasAbility(userAbilities, 'only-bank-charge');
  }

  /**
   * Gets policy type given movement type code
   * @param {string | undefined} codigoTipoMovimiento
   * @returns {string}
   */
  public static getIntippol(codigoTipoMovimiento?: string): string {
    // TODO falta implementar más tipos de movimientos que haremos más adelante
    if (
      codigoTipoMovimiento &&
        (codigoTipoMovimiento === PolicyMovementType.Incompleta ||
          codigoTipoMovimiento === PolicyMovementType.Oferta)
    ) {
      return 'I';
    }
    return 'C';
  }

}
