/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  EAApplicationError
} from '@zurich-es-npm/ea-front-web-core';
import {
  EAIValidationRule,
  eaRangeValidation,
  eaRequiredNumberValidation,
  eaRequiredValidation,
  EAValidationTriggers,
  EAValidationTypes
} from '@zurich-es-npm/ea-front-web-ui';
import Utils from '../utils';
import {
  TableValues,
  FieldStructure,
  MinMaxValue,
  FIELD_TYPES
} from './fields.types';

/**
 *
 */
export class FieldUtils {

  
  /**
   * Abstract method to implement field type logic
   * @param {FieldStructure} _field
   */
  public static getFieldType(_field: FieldStructure): string {
    throw new EAApplicationError('getFieldType method should be implemented');
  }

  /**
   * Get field value from fields array by code
   * 
   * @param {FieldStructure} field
   * @returns {string}
   */
  public static getFieldValue(field: FieldStructure): string {
    return field.valorElemento;
  }

  /**
   * Get field Decimal Precision
   * 
   * @param {FieldStructure} field
   * @returns {number}
   */
  public static getDecimalPrecision(field: FieldStructure): number {
    if (field.tipoMascara === 'M') {
      // Elements with amount type have 2 decimal numbers
      return 2;
    }
    return field.numeroDecimalesElemento ?? 0;
  }


  /**
   * Get numeric field value
   * 
   * @param {FieldStructure} field
   * @returns {number | undefined}
   */
  public static getNumericFieldValue(field: FieldStructure): number | undefined {
    if (this.isFieldNaN(this.getFieldValue(field))) {
      return;
    }
    return Number.parseInt(this.getFieldValue(field));
  }

  /**
   * Checks if the element value is NaN
   * 
   * @param {string} value
   * @returns {boolean}
   */
  public static isFieldNaN(value: string): boolean {
    return isNaN(Number.parseInt(value));
  }

  /**
   * Return element list by code
   * @param {FieldStructure} field
   * @returns {TableValues[] | undefined}
   */
  public static getElementTableValues(
    field: FieldStructure
  ): TableValues[] {
    return Utils.sortObjectArrayByProperty(field.tablaRestricciones, 'nombreRestriccion');
  }

  /**
   * Returns if element is required based on its own definition and expections lists
   * Important: Exceptions lists take precedence over elementoRequerido field value
   * 
   * @param {FieldStructure} field
   * @param {string[] | undefined} requiredExceptionList
   * @param {string[] | undefined} notRequiredExceptionList
   * @returns { boolean }
   */
  public static isFieldRequired(
    field: FieldStructure,
    requiredExceptionList?: string[],
    notRequiredExceptionList?: string[]
  ): boolean {
    const requiredException = requiredExceptionList?.find(exception => exception === field.codigoElemento);
    const notRequiredException = notRequiredExceptionList?.find(exception => exception === field.codigoElemento);

    if (requiredException) {
      return true;
    } else if (notRequiredException) {
      return false;
    }

    return field.elementoRequerido;
  }

  /**
   * Returns if element is read only
   * 
   * @param {FieldStructure} field
   * @returns { boolean }
   */
  public static isFieldReadOnly(field: FieldStructure): boolean {
    return !field.elementoModificable;
  }

  /**
   * Returns element range values
   * 
   * @param {FieldStructure[]} field
   * @param {string} code
   * @returns { MinMaxValue }
   */
  public static getMinMaxValues(field: FieldStructure): MinMaxValue {
    return {
      valorMinimoElemento: field.valorMinimoElemento,
      valorMaximoElemento: field.valorMaximoElemento,
    };
  }

  /**
   * Returns element max length
   * 
   * @param {FieldStructure} field
   * @returns { number }
   */
  public static getFieldLength(field: FieldStructure): number | undefined {
    return field.longitudElemento;
  }

  /**
   * Get field rules
   * 
   * @param { FieldStructure } field
   * @param { string[] } requiredExceptionList
   * @param { string[] } notRequiredExceptionList
   * @return { EAIValidationRule[] } rules for object
   */
  public static getFieldRules(
    field: FieldStructure,
    requiredExceptionList?: string[],
    notRequiredExceptionList?: string[]): EAIValidationRule[] {
    
    const rules: EAIValidationRule[] = [];

    switch (this.getFieldType(field)) {

      /**
       * Rules to apply in number fields:
       * - Required
       * - Range
       */
      case FIELD_TYPES.NUMBER: {
        if (FieldUtils.isFieldRequired(field, requiredExceptionList, notRequiredExceptionList)) {
          rules.push(eaRequiredNumberValidation('common.label.validation.fieldRequired', EAValidationTriggers.BLUR));
        }
  
        const minMaxValues = FieldUtils.getMinMaxValues(field);
        if ((minMaxValues.valorMinimoElemento !== undefined) || (minMaxValues.valorMaximoElemento !== undefined)) {
          rules.push(
            eaRangeValidation(
              minMaxValues.valorMinimoElemento ?? null,
              minMaxValues.valorMaximoElemento ?? null,
              'common.label.validation.invalidRange',
              EAValidationTriggers.BLUR,
              EAValidationTypes.NUMBER
            )
          );
        }
        break;
      }

      /**
       * Rules to apply in string fields:
       * - Required
       * - Length
       */
      case FIELD_TYPES.STRING: {
        if (this.isFieldRequired(field, requiredExceptionList, notRequiredExceptionList)) {
          rules.push(eaRequiredValidation('common.label.validation.fieldRequired', EAValidationTriggers.BLUR));
        }
  
        const ruleMaxLength = this.getFieldLength(field);
        if (ruleMaxLength) {
          rules.push(
            eaRangeValidation(0, ruleMaxLength, 'common.label.validation.invalidLength', EAValidationTriggers.BLUR)
          );
        }
        break;
      }

      /**
       * Rules to apply in other field types:
       * - Required
       */
      default:
        if (this.isFieldRequired(field, requiredExceptionList, notRequiredExceptionList)) {
          rules.push(eaRequiredValidation('common.label.validation.fieldRequired', EAValidationTriggers.BLUR));
        }
    }

    return rules;
  }

}
