import {
  OfferPolicyUtils
} from '@/utils/offer-policy-utils';

export enum TOUR_NAMES_ENUM {
  COMUNIDADES_TOUR = 'HOME_COMU_TOUR',
  CARD_PAYMENT_TOUR = 'HOME_CARD_TOUR',
  CARD_PAYMENT_TOUR_ONDEMAND = 'HOME_CARD_TOUR_ONDEMAND',
  FLEETS_TOUR = 'HOME_FLEETS_TOUR',
  HOME_CERT_TOUR = 'HOME_CERT_TOUR',
  HOME_PAPERLESS_TOUR = 'HOME_PAPERLESS_TOUR',
  HOME_CAR_NB_LC_TOUR = 'HOME_CAR_NB_LC_TOUR'
}

export const TOUR_PERMISSIONS: Record<string, string[]> = {
  HOME_COMU_TOUR: [],
  HOME_CARD_TOUR: ['quote-buy'],
  HOME_FLEETS_TOUR: ['flotas-oferta'],
  HOME_CERT_TOUR: ['intermediary-retention-certificates'],
  HOME_PAPERLESS_TOUR: ['paperless-contract'],
  HOME_CAR_NB_LC_TOUR: ['car-nb-lc-onboarding']
};

export const TOUR_CHECK_SHOWING_FUNCTIONS: Record<string, Function[]> = {
  HOME_COMU_TOUR: [],
  HOME_CARD_TOUR: [OfferPolicyUtils.userHasOnlyBankCharge],
  HOME_FLEETS_TOUR: [],
  HOME_CERT_TOUR: [],
  HOME_PAPERLESS_TOUR: [],
  HOME_CAR_NB_LC_TOUR: []
};

/** 
 * Model onboarding
 */
export class Onboarding {

  public onboardingName: string = '';

  public onboardingComponents?: Vue [];

  public onboardingHiddenSlides: number[] = [];

  public onboardingModalTitleLabel?: string = '';

  /**
   * Flag for hiding first title
   */
  public onboardingHideFirstTitle?: boolean = false;
}

/**
 * 
 */
export class OnboardingSlide {

  public onboardingComponent?: Vue ;

  public onboardingHideFirstTitle?: boolean = false;
}

/**
 * 
 */
export class OnboardingData {
  currentOnboardings: Onboarding [] = [];
}

export interface OnboardingConfig {
  hideModalTitle?: boolean;
}
