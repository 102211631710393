/* eslint-disable */

/**
 * zonazurich
 * Portal de Mediación Digital
 *
 * The version of the OpenAPI document: 1
 * Contact: digitalarchitecture@zurich.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import {
  EASDKConfig,
  EAUrlUtils
} from '@zurich-es-npm/ea-shared-utils-js';

import * as globalImportUrl from 'url';
import {
  Configuration
} from './configuration';
import globalAxios, {
  AxiosPromise,
  AxiosInstance,
  AxiosResponse
} from 'axios';

const BASE_PATH = EAUrlUtils.removeLastForwardSlash('http://localhost:3000');

/**
 *
 * @export
 */
export const COLLECTION_FORMATS = {
  csv: ',',
  ssv: ' ',
  tsv: '\t',
  pipes: '|',
};

/**
 *  
 * @export
 * @interface RequestArgs
 */
export interface RequestArgs {
  url: string;
  options: any;
}

/**
 * 
 * @export
 * @class BaseAPI
 */
export class BaseAPI {
  protected configuration: Configuration | undefined;

  /**
   *Creates an instance of BaseAPI.
   * @param {Configuration} [configuration]
   * @param {string} [basePath=BASE_PATH]
   * @param {AxiosInstance} [axios=globalAxios]
   * @memberof BaseAPI
   */
  public constructor(
    configuration?: Configuration,
    protected basePath: string = BASE_PATH,
    protected axios: AxiosInstance = globalAxios) {
    if (configuration) {
      this.configuration = configuration;
      this.basePath = configuration.basePath || this.basePath;
    }
  }
}

/**
 * 
 * @export
 * @class RequiredError
 * @extends {Error}
 */
export class RequiredError extends Error {
  public name: 'RequiredError' = 'RequiredError';

  public field: string;

  /**
   *Creates an instance of RequiredError.
   * @param {string} field
   * @param {string} [msg]
   * @memberof RequiredError
   */
  public constructor(field: string, msg?: string) {
    super(msg);
    this.field=field;
  }
}

/**
 * 
 * @export
 * @class AsyncRequestError
 * @extends {Error}
 */
export class AsyncRequestError extends Error {
  public name: 'AsyncRequestError' = 'AsyncRequestError';

  /**
   *Creates an instance of AsyncRequestError.
   * @param {string} field
   * @param {string} [msg]
   * @memberof AsyncRequestError
   */
  public constructor(msg?: string) {
    super(msg);
  }
}

/**
 * 
 * @export
 * @interface Error
 */
export interface Error {
    /**
     * 
     * @type {string}
     * @memberof Error
     */
    code: string;
    /**
     * 
     * @type {string}
     * @memberof Error
     */
    message: string;
    /**
     * 
     * @type {number}
     * @memberof Error
     */
    severity: number;
    /**
     * 
     * @type {Array<object>}
     * @memberof Error
     */
    childErrors?: Array<object>;
}/**
 * 
 * @export
 * @interface UpdateWarrantiesAndRateRequest
 */
export interface UpdateWarrantiesAndRateRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateWarrantiesAndRateRequest
     */
    codigoPoliza: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateWarrantiesAndRateRequest
     */
    versionPoliza: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateWarrantiesAndRateRequest
     */
    tipoPoliza: UpdateWarrantiesAndRateRequestTipoPolizaEnum;
    /**
     * 
     * @type {string}
     * @memberof UpdateWarrantiesAndRateRequest
     */
    tipoComision: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateWarrantiesAndRateRequest
     */
    ajusteComercialCotizacion: number;
    /**
     * 
     * @type {Array<UpdateWarrantiesAndRateRequestDatosGarantias>}
     * @memberof UpdateWarrantiesAndRateRequest
     */
    datosGarantias: Array<UpdateWarrantiesAndRateRequestDatosGarantias>;
}

/**
    * @export
    * @enum {string}
    */
export enum UpdateWarrantiesAndRateRequestTipoPolizaEnum {
    Oferta = 'Oferta',
    Pliza = 'Póliza',
    Incompleta = 'Incompleta'
}
/**
 * 
 * @export
 * @interface UpdateWarrantiesAndRateRequestDatosGarantias
 */
export interface UpdateWarrantiesAndRateRequestDatosGarantias {
    /**
     * 
     * @type {string}
     * @memberof UpdateWarrantiesAndRateRequestDatosGarantias
     */
    codigoGarantia?: string;
    /**
     * 
     * @type {Array<UpdateWarrantiesAndRateRequestGarantiaSeleccionada>}
     * @memberof UpdateWarrantiesAndRateRequestDatosGarantias
     */
    garantiaSeleccionada?: Array<UpdateWarrantiesAndRateRequestGarantiaSeleccionada>;
    /**
     * 
     * @type {Array<UpdateWarrantiesAndRateRequestElementosGarantias>}
     * @memberof UpdateWarrantiesAndRateRequestDatosGarantias
     */
    elementosGarantias?: Array<UpdateWarrantiesAndRateRequestElementosGarantias>;
}/**
 * 
 * @export
 * @interface UpdateWarrantiesAndRateRequestDatosPropuestas
 */
export interface UpdateWarrantiesAndRateRequestDatosPropuestas {
    /**
     * 
     * @type {string}
     * @memberof UpdateWarrantiesAndRateRequestDatosPropuestas
     */
    codigoPropuesta?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateWarrantiesAndRateRequestDatosPropuestas
     */
    tablaElemento?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateWarrantiesAndRateRequestDatosPropuestas
     */
    valorElemento?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateWarrantiesAndRateRequestDatosPropuestas
     */
    valorMinimoElemento?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateWarrantiesAndRateRequestDatosPropuestas
     */
    valorMaximoElemento?: number;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateWarrantiesAndRateRequestDatosPropuestas
     */
    elementoModificable?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateWarrantiesAndRateRequestDatosPropuestas
     */
    elementoRequerido?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateWarrantiesAndRateRequestDatosPropuestas
     */
    elementoVisible?: boolean;
    /**
     * 
     * @type {number}
     * @memberof UpdateWarrantiesAndRateRequestDatosPropuestas
     */
    longitudElemento?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateWarrantiesAndRateRequestDatosPropuestas
     */
    numeroDecimalesElemento?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateWarrantiesAndRateRequestDatosPropuestas
     */
    tipoVariable?: UpdateWarrantiesAndRateRequestDatosPropuestasTipoVariableEnum;
    /**
     * 
     * @type {string}
     * @memberof UpdateWarrantiesAndRateRequestDatosPropuestas
     */
    tipoMascara?: UpdateWarrantiesAndRateRequestDatosPropuestasTipoMascaraEnum;
    /**
     * 
     * @type {string}
     * @memberof UpdateWarrantiesAndRateRequestDatosPropuestas
     */
    tipoPropuesta?: UpdateWarrantiesAndRateRequestDatosPropuestasTipoPropuestaEnum;
    /**
     * 
     * @type {Array<UpdateWarrantiesAndRateRequestTablaRestricciones>}
     * @memberof UpdateWarrantiesAndRateRequestDatosPropuestas
     */
    tablaRestricciones?: Array<UpdateWarrantiesAndRateRequestTablaRestricciones>;
}

/**
    * @export
    * @enum {string}
    */
export enum UpdateWarrantiesAndRateRequestDatosPropuestasTipoVariableEnum {
    A = 'A',
    N = 'N'
}
/**
    * @export
    * @enum {string}
    */
export enum UpdateWarrantiesAndRateRequestDatosPropuestasTipoMascaraEnum {
    A = 'A',
    C = 'C',
    D = 'D',
    F = 'F',
    I = 'I',
    M = 'M',
    P = 'P',
    R = 'R',
    T = 'T'
}
/**
    * @export
    * @enum {string}
    */
export enum UpdateWarrantiesAndRateRequestDatosPropuestasTipoPropuestaEnum {
    M = 'M',
    S = 'S',
    O = 'O',
    N = 'N'
}
/**
 * 
 * @export
 * @interface UpdateWarrantiesAndRateRequestElementosGarantias
 */
export interface UpdateWarrantiesAndRateRequestElementosGarantias {
    /**
     * 
     * @type {string}
     * @memberof UpdateWarrantiesAndRateRequestElementosGarantias
     */
    codigoElemento?: string;
    /**
     * 
     * @type {Array<UpdateWarrantiesAndRateRequestDatosPropuestas>}
     * @memberof UpdateWarrantiesAndRateRequestElementosGarantias
     */
    datosPropuestas?: Array<UpdateWarrantiesAndRateRequestDatosPropuestas>;
}/**
 * 
 * @export
 * @interface UpdateWarrantiesAndRateRequestGarantiaSeleccionada
 */
export interface UpdateWarrantiesAndRateRequestGarantiaSeleccionada {
    /**
     * 
     * @type {string}
     * @memberof UpdateWarrantiesAndRateRequestGarantiaSeleccionada
     */
    codigoPropuesta?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateWarrantiesAndRateRequestGarantiaSeleccionada
     */
    garantiaSeleccionada?: boolean;
}/**
 * 
 * @export
 * @interface UpdateWarrantiesAndRateRequestTablaRestricciones
 */
export interface UpdateWarrantiesAndRateRequestTablaRestricciones {
    /**
     * 
     * @type {string}
     * @memberof UpdateWarrantiesAndRateRequestTablaRestricciones
     */
    nombreRestriccion?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateWarrantiesAndRateRequestTablaRestricciones
     */
    valorRestriccion?: string;
}/**
 * 
 * @export
 * @interface UpdateWarrantiesAndRateResponse
 */
export interface UpdateWarrantiesAndRateResponse {
    /**
     * 
     * @type {Array<UpdateWarrantiesAndRateResponseDatosTarificacion>}
     * @memberof UpdateWarrantiesAndRateResponse
     */
    datosTarificacion?: Array<UpdateWarrantiesAndRateResponseDatosTarificacion>;
    /**
     * 
     * @type {Array<UpdateWarrantiesAndRateResponseDatosGarantias>}
     * @memberof UpdateWarrantiesAndRateResponse
     */
    datosGarantias?: Array<UpdateWarrantiesAndRateResponseDatosGarantias>;
    /**
     * 
     * @type {Array<UpdateWarrantiesAndRateResponseDatosGestionCompetencias>}
     * @memberof UpdateWarrantiesAndRateResponse
     */
    datosGestionCompetencias?: Array<UpdateWarrantiesAndRateResponseDatosGestionCompetencias>;
    /**
     * 
     * @type {Array<Error>}
     * @memberof UpdateWarrantiesAndRateResponse
     */
    errors?: Array<Error>;
}/**
 * 
 * @export
 * @interface UpdateWarrantiesAndRateResponseDatosGarantias
 */
export interface UpdateWarrantiesAndRateResponseDatosGarantias {
    /**
     * 
     * @type {string}
     * @memberof UpdateWarrantiesAndRateResponseDatosGarantias
     */
    codigoGarantia?: string;
    /**
     * 
     * @type {Array<UpdateWarrantiesAndRateRequestGarantiaSeleccionada>}
     * @memberof UpdateWarrantiesAndRateResponseDatosGarantias
     */
    garantiaSeleccionada?: Array<UpdateWarrantiesAndRateRequestGarantiaSeleccionada>;
    /**
     * 
     * @type {Array<UpdateWarrantiesAndRateResponseElementosGarantias>}
     * @memberof UpdateWarrantiesAndRateResponseDatosGarantias
     */
    elementosGarantias?: Array<UpdateWarrantiesAndRateResponseElementosGarantias>;
}/**
 * 
 * @export
 * @interface UpdateWarrantiesAndRateResponseDatosGestionCompetencias
 */
export interface UpdateWarrantiesAndRateResponseDatosGestionCompetencias {
    /**
     * 
     * @type {string}
     * @memberof UpdateWarrantiesAndRateResponseDatosGestionCompetencias
     */
    codigoObjeto?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateWarrantiesAndRateResponseDatosGestionCompetencias
     */
    codigoOpcion?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateWarrantiesAndRateResponseDatosGestionCompetencias
     */
    codigoMCT?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateWarrantiesAndRateResponseDatosGestionCompetencias
     */
    descripcionElemento?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateWarrantiesAndRateResponseDatosGestionCompetencias
     */
    codigoErrorAutorizacion?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateWarrantiesAndRateResponseDatosGestionCompetencias
     */
    estadoControlCompetencias?: UpdateWarrantiesAndRateResponseDatosGestionCompetenciasEstadoControlCompetenciasEnum;
    /**
     * 
     * @type {string}
     * @memberof UpdateWarrantiesAndRateResponseDatosGestionCompetencias
     */
    descripcionErrorAutorizacion?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateWarrantiesAndRateResponseDatosGestionCompetencias
     */
    descripcionEstadoControlCompetencias?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum UpdateWarrantiesAndRateResponseDatosGestionCompetenciasEstadoControlCompetenciasEnum {
    A = 'A',
    P = 'P',
    R = 'R'
}
/**
 * 
 * @export
 * @interface UpdateWarrantiesAndRateResponseDatosPropuestas
 */
export interface UpdateWarrantiesAndRateResponseDatosPropuestas {
    /**
     * 
     * @type {string}
     * @memberof UpdateWarrantiesAndRateResponseDatosPropuestas
     */
    codigoPropuesta?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateWarrantiesAndRateResponseDatosPropuestas
     */
    valorElemento?: string;
}/**
 * 
 * @export
 * @interface UpdateWarrantiesAndRateResponseDatosTarificacion
 */
export interface UpdateWarrantiesAndRateResponseDatosTarificacion {
    /**
     * 
     * @type {string}
     * @memberof UpdateWarrantiesAndRateResponseDatosTarificacion
     */
    codigoPropuesta?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateWarrantiesAndRateResponseDatosTarificacion
     */
    importeAcumuladoRecibo?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateWarrantiesAndRateResponseDatosTarificacion
     */
    importePrimerRecibo?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateWarrantiesAndRateResponseDatosTarificacion
     */
    importeRecibosSucesivos?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateWarrantiesAndRateResponseDatosTarificacion
     */
    importeTotalRecibo?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateWarrantiesAndRateResponseDatosTarificacion
     */
    formaPagoTarificacion?: UpdateWarrantiesAndRateResponseDatosTarificacionFormaPagoTarificacionEnum;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateWarrantiesAndRateResponseDatosTarificacion
     */
    noPrimaMinima?: boolean;
}

/**
    * @export
    * @enum {string}
    */
export enum UpdateWarrantiesAndRateResponseDatosTarificacionFormaPagoTarificacionEnum {
    Anual = 'Anual',
    Unica = 'Unica',
    Semestral = 'Semestral',
    Trimestral = 'Trimestral',
    Mensual = 'Mensual'
}
/**
 * 
 * @export
 * @interface UpdateWarrantiesAndRateResponseElementosGarantias
 */
export interface UpdateWarrantiesAndRateResponseElementosGarantias {
    /**
     * 
     * @type {string}
     * @memberof UpdateWarrantiesAndRateResponseElementosGarantias
     */
    codigoElemento?: string;
    /**
     * 
     * @type {Array<UpdateWarrantiesAndRateResponseDatosPropuestas>}
     * @memberof UpdateWarrantiesAndRateResponseElementosGarantias
     */
    datosPropuestas?: Array<UpdateWarrantiesAndRateResponseDatosPropuestas>;
}

type updateWarrantiesAndRateOperationParams = {
  updateWarrantiesAndRateRequest: UpdateWarrantiesAndRateRequest,
};

/**
 * UpdateWarrantiesAndRateApi - axios parameter creator
 * @param {Configuration} [configuration]
 * @returns {any}
 */
const UpdateWarrantiesAndRateApiAxiosParamCreator = function(configuration?: Configuration): any {
  return {
    
    /**
     * Guarda y tarifica una oferta dadas las garantías seleccionadas
     * @param {UpdateWarrantiesAndRateRequest} updateWarrantiesAndRateRequest 
     * @param {string} [acceptLanguage] El Accept-Language request HTTP header indica en que lenguaje debe responder el backend.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @returns {RequestArgs}
     */
    updateWarrantiesAndRateOperation(params: updateWarrantiesAndRateOperationParams, options: any = {}): RequestArgs {
      // Verify required parameter 'updateWarrantiesAndRateRequest' is not null or undefined
      if (params.updateWarrantiesAndRateRequest === null || params.updateWarrantiesAndRateRequest === undefined) {
        throw new RequiredError('updateWarrantiesAndRateRequest', 'Required parameter updateWarrantiesAndRateRequest was null or undefined when calling updateWarrantiesAndRateOperation.');
      }
      const localVarPath = `/v1/quoteAndBuy/updateWarrantiesAndRateOperation`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true) as Partial<globalImportUrl.UrlWithParsedQuery>;
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;


  
      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
      // Fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
      const needsSerialization = ('UpdateWarrantiesAndRateRequest' as any !== 'string') ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization ? JSON.stringify(params.updateWarrantiesAndRateRequest || {}) : params.updateWarrantiesAndRateRequest || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * UpdateWarrantiesAndRateApi - functional programming interface
 * @param {Configuration} [configuration]
 * @returns {any}
 */
const UpdateWarrantiesAndRateApiFp = function(configuration?: Configuration): any {
  return {
    
    /**
     * Guarda y tarifica una oferta dadas las garantías seleccionadas
     * @param {UpdateWarrantiesAndRateRequest} updateWarrantiesAndRateRequest 
     * @param {string} [acceptLanguage] El Accept-Language request HTTP header indica en que lenguaje debe responder el backend.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @returns {any}
     */
    updateWarrantiesAndRateOperation(params: updateWarrantiesAndRateOperationParams, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateWarrantiesAndRateResponse> {
      const localVarAxiosArgs = UpdateWarrantiesAndRateApiAxiosParamCreator(configuration).updateWarrantiesAndRateOperation(params, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH): any => {
        const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * UpdateWarrantiesAndRateApi - factory interface
 * @param {Configuration} configuration
 * @param {string} basePath
 * @param {AxiosInstance} axios
 * @export
 * @returns {any}
 */
const UpdateWarrantiesAndRateApiFactory = function(configuration?: Configuration, basePath?: string, axios?: AxiosInstance): any {
  return {
    
    /**
     * Guarda y tarifica una oferta dadas las garantías seleccionadas
     * @param {UpdateWarrantiesAndRateRequest} updateWarrantiesAndRateRequest 
     * @param {string} [acceptLanguage] El Accept-Language request HTTP header indica en que lenguaje debe responder el backend.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @returns {any}
     */
    updateWarrantiesAndRateOperation(params: updateWarrantiesAndRateOperationParams, options?: any): any {
      return UpdateWarrantiesAndRateApiFp(configuration).updateWarrantiesAndRateOperation(params, options)(axios, basePath);
    },
  };
};

/** 
 * UpdateWarrantiesAndRateApi - object-oriented interface
 * @export
 * @class UpdateWarrantiesAndRateApi
 * @extends {BaseAPI}
 */
export class UpdateWarrantiesAndRateApi extends BaseAPI {
  
  /**
   * Guarda y tarifica una oferta dadas las garantías seleccionadas
   * @param {UpdateWarrantiesAndRateRequest} updateWarrantiesAndRateRequest 
   * @param {string} [acceptLanguage] El Accept-Language request HTTP header indica en que lenguaje debe responder el backend.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @returns {any}
   * @memberof UpdateWarrantiesAndRateApi
   */
  public updateWarrantiesAndRateOperation(params: updateWarrantiesAndRateOperationParams, options?: any): any {
    return UpdateWarrantiesAndRateApiFp(this.configuration).updateWarrantiesAndRateOperation(params, options)(this.axios, this.basePath);
  }

}

/**
 * 
 * @export
 * @class EAupdateWarrantiesAndRateOperationInputMessage
 */
export class EAupdateWarrantiesAndRateOperationInputMessage {
  
  /**
   * 
   * @type {UpdateWarrantiesAndRateRequest}
   * @memberof EAupdateWarrantiesAndRateOperationInputMessage
   */
  public updateWarrantiesAndRateRequest: UpdateWarrantiesAndRateRequest;

  public constructor(updateWarrantiesAndRateRequest: UpdateWarrantiesAndRateRequest, ){
  
  this.updateWarrantiesAndRateRequest=updateWarrantiesAndRateRequest;
  } 
}



/** 
 * EAUpdateWarrantiesAndRateApi - Architecture client for updateWarrantiesAndRateOperation
 * @export
 * @class EAUpdateWarrantiesAndRateApi
 */
export class EAUpdateWarrantiesAndRateApi {

  /**
   * 
   * @param {UpdateWarrantiesAndRateRequest} updateWarrantiesAndRateRequest
   
   * @throws {AsyncRequestError | RequiredError}
   * @returns {Promise<UpdateWarrantiesAndRateResponse | null>}
  */ 
  public async updateWarrantiesAndRateOperation(params: updateWarrantiesAndRateOperationParams): Promise<UpdateWarrantiesAndRateResponse | null> {
    return this.updateWarrantiesAndRateOperationPromise(params);
  }

  /**
   * 
   * @param {UpdateWarrantiesAndRateRequest} updateWarrantiesAndRateRequest
   
   * @param {Function} callback
   * @throws {AsyncRequestError | RequiredError}
   */
  public async updateWarrantiesAndRateOperationSteps(params: updateWarrantiesAndRateOperationParams, callback: (res: AxiosResponse<UpdateWarrantiesAndRateResponse | null>) => void) {
    let done: boolean = false;
    const httpClient: AxiosInstance = EASDKConfig.getHttpClient('/v1/quoteAndBuy/updateWarrantiesAndRateOperation', 'updateWarrantiesAndRate', 'updateWarrantiesAndRateOperation');
    const config: Configuration = {};
    const api = new UpdateWarrantiesAndRateApi(config, '', httpClient);
    const sleep = async(ms: number) => {
      return new Promise(resolve => setTimeout(resolve, ms));
    };
    const headers: { ['Async-Id']?: string } = {};
    while (!done) {
      const output = await api.updateWarrantiesAndRateOperation(params, { headers }) as AxiosResponse<UpdateWarrantiesAndRateResponse | null>;
      callback(output);
      if (output.status === 202) {
        try {
          const retry = parseInt(output.headers['retry']);
          headers['Async-Id'] = output.headers['request-id'];
          if (isNaN(retry) || retry <= 0 || retry > 1000 * 3600) {
            throw new Error(`Retry time not acceptable (in milliseconds): ${retry}`);
          }
          await sleep(retry);
        } catch (err) {
          throw new Error(`Error in Async Request ${err}`);
        }
      } else {
        done = true;
      }
    }
  }

  /**
   * 
   * @param {UpdateWarrantiesAndRateRequest} updateWarrantiesAndRateRequest
   
   * @throws {AsyncRequestError | RequiredError}
   * @returns {Promise<UpdateWarrantiesAndRateResponse | null>}
   */
  public async updateWarrantiesAndRateOperationPromise(params: updateWarrantiesAndRateOperationParams): Promise<UpdateWarrantiesAndRateResponse | null> {
    return new Promise((resolve, reject) => {
      this.updateWarrantiesAndRateOperationSteps(params, (response: AxiosResponse<UpdateWarrantiesAndRateResponse | null>) => {
        if (response.status !== 202) {
          resolve(response.data);
        }
      })
      .catch((err) => {
        reject(err);
      });
    });
  } 
}


