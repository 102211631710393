import {
  EAGetDocumentationStatusApi
} from '../../services/V1/quoteAndBuy/getDocumentationStatusOperation/post/';

/**
 * Method to verify is documentation Process have just finished.
 * Use EAGetDocumentationStatusApi to retrieve this information
 * 
 * @param {string} offerPolicyCode
 * @param {number} offerPolicyVersion
 * @returns {boolean}
 */
export const isDocumentationProcessFinished = async function(
  offerPolicyCode: string,
  offerPolicyVersion: number
): Promise<boolean> {
  const documentStatusApi = new EAGetDocumentationStatusApi();
  const documentationStatusResponse = await documentStatusApi.getDocumentationStatusOperation({
    getDocumentationStatusRequest: {
      codigoPoliza: offerPolicyCode,
      versionPoliza: offerPolicyVersion,
    }
  });
  
  return documentationStatusResponse?.indicadorDocumentacionFinalizada ? true : false;
};


