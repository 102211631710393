/* eslint-disable */

/**
 * zonazurich
 * Portal de Mediación Digital
 *
 * The version of the OpenAPI document: 1
 * Contact: digitalarchitecture@zurich.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import {
  EASDKConfig,
  EAUrlUtils
} from '@zurich-es-npm/ea-shared-utils-js';

import * as globalImportUrl from 'url';
import {
  Configuration
} from './configuration';
import globalAxios, {
  AxiosPromise,
  AxiosInstance,
  AxiosResponse
} from 'axios';

const BASE_PATH = EAUrlUtils.removeLastForwardSlash('http://localhost:3000');

/**
 *
 * @export
 */
export const COLLECTION_FORMATS = {
  csv: ',',
  ssv: ' ',
  tsv: '\t',
  pipes: '|',
};

/**
 *  
 * @export
 * @interface RequestArgs
 */
export interface RequestArgs {
  url: string;
  options: any;
}

/**
 * 
 * @export
 * @class BaseAPI
 */
export class BaseAPI {
  protected configuration: Configuration | undefined;

  /**
   *Creates an instance of BaseAPI.
   * @param {Configuration} [configuration]
   * @param {string} [basePath=BASE_PATH]
   * @param {AxiosInstance} [axios=globalAxios]
   * @memberof BaseAPI
   */
  public constructor(
    configuration?: Configuration,
    protected basePath: string = BASE_PATH,
    protected axios: AxiosInstance = globalAxios) {
    if (configuration) {
      this.configuration = configuration;
      this.basePath = configuration.basePath || this.basePath;
    }
  }
}

/**
 * 
 * @export
 * @class RequiredError
 * @extends {Error}
 */
export class RequiredError extends Error {
  public name: 'RequiredError' = 'RequiredError';

  public field: string;

  /**
   *Creates an instance of RequiredError.
   * @param {string} field
   * @param {string} [msg]
   * @memberof RequiredError
   */
  public constructor(field: string, msg?: string) {
    super(msg);
    this.field=field;
  }
}

/**
 * 
 * @export
 * @class AsyncRequestError
 * @extends {Error}
 */
export class AsyncRequestError extends Error {
  public name: 'AsyncRequestError' = 'AsyncRequestError';

  /**
   *Creates an instance of AsyncRequestError.
   * @param {string} field
   * @param {string} [msg]
   * @memberof AsyncRequestError
   */
  public constructor(msg?: string) {
    super(msg);
  }
}

/**
 * 
 * @export
 * @interface Error
 */
export interface Error {
    /**
     * 
     * @type {string}
     * @memberof Error
     */
    code: string;
    /**
     * 
     * @type {string}
     * @memberof Error
     */
    message: string;
    /**
     * 
     * @type {number}
     * @memberof Error
     */
    severity: number;
    /**
     * 
     * @type {Array<object>}
     * @memberof Error
     */
    childErrors?: Array<object>;
}/**
 * 
 * @export
 * @interface GetPersonsAxesorRequest
 */
export interface GetPersonsAxesorRequest {
    /**
     * 
     * @type {string}
     * @memberof GetPersonsAxesorRequest
     */
    codigoFiliacion?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonsAxesorRequest
     */
    codigoIdentificacionFiscal: string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonsAxesorRequest
     */
    tipoPersona?: GetPersonsAxesorRequestTipoPersonaEnum;
    /**
     * 
     * @type {string}
     * @memberof GetPersonsAxesorRequest
     */
    tipoDocumento?: GetPersonsAxesorRequestTipoDocumentoEnum;
    /**
     * 
     * @type {string}
     * @memberof GetPersonsAxesorRequest
     */
    primerApellido?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonsAxesorRequest
     */
    segundoApellido?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonsAxesorRequest
     */
    nombrePropio?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonsAxesorRequest
     */
    campoPaginacion?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum GetPersonsAxesorRequestTipoPersonaEnum {
    F = 'F',
    J = 'J'
}
/**
    * @export
    * @enum {string}
    */
export enum GetPersonsAxesorRequestTipoDocumentoEnum {
    C = 'C',
    E = 'E',
    N = 'N',
    P = 'P',
    R = 'R',
    S = 'S',
    X = 'X'
}
/**
 * 
 * @export
 * @interface GetPersonsAxesorResponse
 */
export interface GetPersonsAxesorResponse {
    /**
     * 
     * @type {boolean}
     * @memberof GetPersonsAxesorResponse
     */
    indicadorDatosEncontradosAxesor?: boolean;
    /**
     * 
     * @type {number}
     * @memberof GetPersonsAxesorResponse
     */
    facturacionEmpresa?: number;
    /**
     * 
     * @type {Array<GetPersonsAxesorResponseListaPersonas>}
     * @memberof GetPersonsAxesorResponse
     */
    listaPersonas?: Array<GetPersonsAxesorResponseListaPersonas>;
    /**
     * 
     * @type {GetPersonsAxesorResponseDatosPersona}
     * @memberof GetPersonsAxesorResponse
     */
    datosPersona?: GetPersonsAxesorResponseDatosPersona;
    /**
     * 
     * @type {Array<Error>}
     * @memberof GetPersonsAxesorResponse
     */
    errors?: Array<Error>;
}/**
 * 
 * @export
 * @interface GetPersonsAxesorResponseDatosPersona
 */
export interface GetPersonsAxesorResponseDatosPersona {
    /**
     * 
     * @type {GetPersonsAxesorResponseListaPersonas}
     * @memberof GetPersonsAxesorResponseDatosPersona
     */
    datosBasicosPersona?: GetPersonsAxesorResponseListaPersonas;
    /**
     * 
     * @type {GetPersonsAxesorResponseDatosPersonaDatosGeneralesPersona}
     * @memberof GetPersonsAxesorResponseDatosPersona
     */
    datosGeneralesPersona?: GetPersonsAxesorResponseDatosPersonaDatosGeneralesPersona;
    /**
     * 
     * @type {GetPersonsAxesorResponseDatosPersonaTitulacionPersona}
     * @memberof GetPersonsAxesorResponseDatosPersona
     */
    titulacionPersona?: GetPersonsAxesorResponseDatosPersonaTitulacionPersona;
    /**
     * 
     * @type {GetPersonsAxesorResponseDatosPersonaEmpresa}
     * @memberof GetPersonsAxesorResponseDatosPersona
     */
    empresa?: GetPersonsAxesorResponseDatosPersonaEmpresa;
    /**
     * 
     * @type {GetPersonsAxesorResponseDatosPersonaRegistroEmpresa}
     * @memberof GetPersonsAxesorResponseDatosPersona
     */
    registroEmpresa?: GetPersonsAxesorResponseDatosPersonaRegistroEmpresa;
    /**
     * 
     * @type {GetPersonsAxesorResponseDatosPersonaRegistroEmpresaDetalles}
     * @memberof GetPersonsAxesorResponseDatosPersona
     */
    registroEmpresaDetalles?: GetPersonsAxesorResponseDatosPersonaRegistroEmpresaDetalles;
    /**
     * 
     * @type {Array<GetPersonsAxesorResponseDatosPersonaListaCarnetsPersona>}
     * @memberof GetPersonsAxesorResponseDatosPersona
     */
    listaCarnetsPersona?: Array<GetPersonsAxesorResponseDatosPersonaListaCarnetsPersona>;
    /**
     * 
     * @type {GetPersonsAxesorResponseDatosPersonaMarketing}
     * @memberof GetPersonsAxesorResponseDatosPersona
     */
    marketing?: GetPersonsAxesorResponseDatosPersonaMarketing;
    /**
     * 
     * @type {GetPersonsAxesorResponseDatosPersonaDomicilioPrincipal}
     * @memberof GetPersonsAxesorResponseDatosPersona
     */
    domicilioPrincipal?: GetPersonsAxesorResponseDatosPersonaDomicilioPrincipal;
}/**
 * 
 * @export
 * @interface GetPersonsAxesorResponseDatosPersonaDatosGeneralesPersona
 */
export interface GetPersonsAxesorResponseDatosPersonaDatosGeneralesPersona {
    /**
     * 
     * @type {string}
     * @memberof GetPersonsAxesorResponseDatosPersonaDatosGeneralesPersona
     */
    tratamiento?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonsAxesorResponseDatosPersonaDatosGeneralesPersona
     */
    sexo?: GetPersonsAxesorResponseDatosPersonaDatosGeneralesPersonaSexoEnum;
    /**
     * 
     * @type {string}
     * @memberof GetPersonsAxesorResponseDatosPersonaDatosGeneralesPersona
     */
    fechaNacimiento?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonsAxesorResponseDatosPersonaDatosGeneralesPersona
     */
    nombrePoblacion?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonsAxesorResponseDatosPersonaDatosGeneralesPersona
     */
    poblacionIneNacimiento?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonsAxesorResponseDatosPersonaDatosGeneralesPersona
     */
    nacionalidadPersona?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonsAxesorResponseDatosPersonaDatosGeneralesPersona
     */
    codigoResidencia?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonsAxesorResponseDatosPersonaDatosGeneralesPersona
     */
    estadoCivil?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonsAxesorResponseDatosPersonaDatosGeneralesPersona
     */
    tipoContactoZurich?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonsAxesorResponseDatosPersonaDatosGeneralesPersona
     */
    idiomaCorrespondencia?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonsAxesorResponseDatosPersonaDatosGeneralesPersona
     */
    especialidadPrincipal?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonsAxesorResponseDatosPersonaDatosGeneralesPersona
     */
    indicadorListaRobinson?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonsAxesorResponseDatosPersonaDatosGeneralesPersona
     */
    codigoCnae?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonsAxesorResponseDatosPersonaDatosGeneralesPersona
     */
    indicadorPersoonaRestringida?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum GetPersonsAxesorResponseDatosPersonaDatosGeneralesPersonaSexoEnum {
    V = 'V',
    M = 'M'
}
/**
 * 
 * @export
 * @interface GetPersonsAxesorResponseDatosPersonaDomicilioPrincipal
 */
export interface GetPersonsAxesorResponseDatosPersonaDomicilioPrincipal {
    /**
     * 
     * @type {string}
     * @memberof GetPersonsAxesorResponseDatosPersonaDomicilioPrincipal
     */
    tipoViaDireccion?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonsAxesorResponseDatosPersonaDomicilioPrincipal
     */
    nombreDireccion?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonsAxesorResponseDatosPersonaDomicilioPrincipal
     */
    numeroDireccion?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonsAxesorResponseDatosPersonaDomicilioPrincipal
     */
    nombrePoblacion?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonsAxesorResponseDatosPersonaDomicilioPrincipal
     */
    codigoPoblacionINE?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonsAxesorResponseDatosPersonaDomicilioPrincipal
     */
    nombreProvincia?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonsAxesorResponseDatosPersonaDomicilioPrincipal
     */
    codigoPostalConLetras?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonsAxesorResponseDatosPersonaDomicilioPrincipal
     */
    codigoPais?: string;
}/**
 * 
 * @export
 * @interface GetPersonsAxesorResponseDatosPersonaEmpresa
 */
export interface GetPersonsAxesorResponseDatosPersonaEmpresa {
    /**
     * 
     * @type {string}
     * @memberof GetPersonsAxesorResponseDatosPersonaEmpresa
     */
    nombreEmpresaTrabajo?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonsAxesorResponseDatosPersonaEmpresa
     */
    codigoCategoriaEmpresa?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonsAxesorResponseDatosPersonaEmpresa
     */
    tipoContrato?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonsAxesorResponseDatosPersonaEmpresa
     */
    fechaAltaEmpresa?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonsAxesorResponseDatosPersonaEmpresa
     */
    fechaBajaEmpresa?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonsAxesorResponseDatosPersonaEmpresa
     */
    codigoBajaEmpresa?: string;
}/**
 * 
 * @export
 * @interface GetPersonsAxesorResponseDatosPersonaListaCarnetsPersona
 */
export interface GetPersonsAxesorResponseDatosPersonaListaCarnetsPersona {
    /**
     * 
     * @type {string}
     * @memberof GetPersonsAxesorResponseDatosPersonaListaCarnetsPersona
     */
    tipoPermiso?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonsAxesorResponseDatosPersonaListaCarnetsPersona
     */
    numeroPermiso?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonsAxesorResponseDatosPersonaListaCarnetsPersona
     */
    fechaObtencionPermiso?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonsAxesorResponseDatosPersonaListaCarnetsPersona
     */
    fechaUltimaExpedicion?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonsAxesorResponseDatosPersonaListaCarnetsPersona
     */
    fechaCaducidadPermiso?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonsAxesorResponseDatosPersonaListaCarnetsPersona
     */
    estadoPermiso?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonsAxesorResponseDatosPersonaListaCarnetsPersona
     */
    fechaEstadoPermiso?: string;
}/**
 * 
 * @export
 * @interface GetPersonsAxesorResponseDatosPersonaMarketing
 */
export interface GetPersonsAxesorResponseDatosPersonaMarketing {
    /**
     * 
     * @type {string}
     * @memberof GetPersonsAxesorResponseDatosPersonaMarketing
     */
    timestampPersonaFisica?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonsAxesorResponseDatosPersonaMarketing
     */
    timestampPersonaJuridica?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonsAxesorResponseDatosPersonaMarketing
     */
    codigoFicheroExterno?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonsAxesorResponseDatosPersonaMarketing
     */
    indicadorFicheroExterno?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonsAxesorResponseDatosPersonaMarketing
     */
    indicadorDatosNormalizados?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonsAxesorResponseDatosPersonaMarketing
     */
    codigoOrigenPersona?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonsAxesorResponseDatosPersonaMarketing
     */
    indicadorFraude?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonsAxesorResponseDatosPersonaMarketing
     */
    codigoUnidad?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonsAxesorResponseDatosPersonaMarketing
     */
    claveSBU?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonsAxesorResponseDatosPersonaMarketing
     */
    codigoPasaporte?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonsAxesorResponseDatosPersonaMarketing
     */
    tipoRegimenFiscal?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonsAxesorResponseDatosPersonaMarketing
     */
    fechaEmisionCertificadoResidencia?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonsAxesorResponseDatosPersonaMarketing
     */
    indicadorNifCifModificado?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonsAxesorResponseDatosPersonaMarketing
     */
    codigoRiskName?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonsAxesorResponseDatosPersonaMarketing
     */
    fechaAltaRiskName?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonsAxesorResponseDatosPersonaMarketing
     */
    fechaBajaRiskName?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonsAxesorResponseDatosPersonaMarketing
     */
    codigoPaisDocumentoCee?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonsAxesorResponseDatosPersonaMarketing
     */
    indicadorMorosidadZfs?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonsAxesorResponseDatosPersonaMarketing
     */
    indicadorTipoPersona?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonsAxesorResponseDatosPersonaMarketing
     */
    tipoCliente?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonsAxesorResponseDatosPersonaMarketing
     */
    estadoPersona?: GetPersonsAxesorResponseDatosPersonaMarketingEstadoPersonaEnum;
    /**
     * 
     * @type {string}
     * @memberof GetPersonsAxesorResponseDatosPersonaMarketing
     */
    campoPaginacion?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum GetPersonsAxesorResponseDatosPersonaMarketingEstadoPersonaEnum {
    T = 'T',
    A = 'A'
}
/**
 * 
 * @export
 * @interface GetPersonsAxesorResponseDatosPersonaRegistroEmpresa
 */
export interface GetPersonsAxesorResponseDatosPersonaRegistroEmpresa {
    /**
     * 
     * @type {string}
     * @memberof GetPersonsAxesorResponseDatosPersonaRegistroEmpresa
     */
    codigoEpigrafe?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonsAxesorResponseDatosPersonaRegistroEmpresa
     */
    codigoDelegacionHacienda?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonsAxesorResponseDatosPersonaRegistroEmpresa
     */
    codigoIcea?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonsAxesorResponseDatosPersonaRegistroEmpresa
     */
    codigoLetraIcea?: string;
}/**
 * 
 * @export
 * @interface GetPersonsAxesorResponseDatosPersonaRegistroEmpresaDetalles
 */
export interface GetPersonsAxesorResponseDatosPersonaRegistroEmpresaDetalles {
    /**
     * 
     * @type {string}
     * @memberof GetPersonsAxesorResponseDatosPersonaRegistroEmpresaDetalles
     */
    codigoProvinciaInscripcionRm?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonsAxesorResponseDatosPersonaRegistroEmpresaDetalles
     */
    fechaInscripcionRm?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonsAxesorResponseDatosPersonaRegistroEmpresaDetalles
     */
    codigoHojaRm?: string;
}/**
 * 
 * @export
 * @interface GetPersonsAxesorResponseDatosPersonaTitulacionPersona
 */
export interface GetPersonsAxesorResponseDatosPersonaTitulacionPersona {
    /**
     * 
     * @type {string}
     * @memberof GetPersonsAxesorResponseDatosPersonaTitulacionPersona
     */
    titulacionAcademica?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonsAxesorResponseDatosPersonaTitulacionPersona
     */
    profesion?: string;
}/**
 * 
 * @export
 * @interface GetPersonsAxesorResponseListaPersonas
 */
export interface GetPersonsAxesorResponseListaPersonas {
    /**
     * 
     * @type {string}
     * @memberof GetPersonsAxesorResponseListaPersonas
     */
    codigoFiliacion?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonsAxesorResponseListaPersonas
     */
    codigoIdentificacionFiscal?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonsAxesorResponseListaPersonas
     */
    tipoDocumento?: GetPersonsAxesorResponseListaPersonasTipoDocumentoEnum;
    /**
     * 
     * @type {string}
     * @memberof GetPersonsAxesorResponseListaPersonas
     */
    descripcionDireccionCliente?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonsAxesorResponseListaPersonas
     */
    nombreCompactadoPersonas?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonsAxesorResponseListaPersonas
     */
    estadoPersona?: GetPersonsAxesorResponseListaPersonasEstadoPersonaEnum;
    /**
     * 
     * @type {string}
     * @memberof GetPersonsAxesorResponseListaPersonas
     */
    primerApellido?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonsAxesorResponseListaPersonas
     */
    segundoApellido?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonsAxesorResponseListaPersonas
     */
    nombrePropio?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonsAxesorResponseListaPersonas
     */
    tipoPersona?: GetPersonsAxesorResponseListaPersonasTipoPersonaEnum;
    /**
     * 
     * @type {string}
     * @memberof GetPersonsAxesorResponseListaPersonas
     */
    codigoExterno?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum GetPersonsAxesorResponseListaPersonasTipoDocumentoEnum {
    C = 'C',
    E = 'E',
    N = 'N',
    P = 'P',
    R = 'R',
    S = 'S',
    X = 'X'
}
/**
    * @export
    * @enum {string}
    */
export enum GetPersonsAxesorResponseListaPersonasEstadoPersonaEnum {
    T = 'T',
    A = 'A'
}
/**
    * @export
    * @enum {string}
    */
export enum GetPersonsAxesorResponseListaPersonasTipoPersonaEnum {
    F = 'F',
    J = 'J'
}


type getPersonsAxesorOperationParams = {
  getPersonsAxesorRequest: GetPersonsAxesorRequest,
};

/**
 * GetPersonsAxesorApi - axios parameter creator
 * @param {Configuration} [configuration]
 * @returns {any}
 */
const GetPersonsAxesorApiAxiosParamCreator = function(configuration?: Configuration): any {
  return {
    
    /**
     * Devuelve los datos de la persona tras consultar a Axesor
     * @param {GetPersonsAxesorRequest} getPersonsAxesorRequest 
     * @param {string} [acceptLanguage] El Accept-Language request HTTP header indica en que lenguaje debe responder el backend.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @returns {RequestArgs}
     */
    getPersonsAxesorOperation(params: getPersonsAxesorOperationParams, options: any = {}): RequestArgs {
      // Verify required parameter 'getPersonsAxesorRequest' is not null or undefined
      if (params.getPersonsAxesorRequest === null || params.getPersonsAxesorRequest === undefined) {
        throw new RequiredError('getPersonsAxesorRequest', 'Required parameter getPersonsAxesorRequest was null or undefined when calling getPersonsAxesorOperation.');
      }
      const localVarPath = `/v1/quoteAndBuy/getPersonsAxesorOperation`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true) as Partial<globalImportUrl.UrlWithParsedQuery>;
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;


  
      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
      // Fix override query string Detail: https://stackoverflow.com/a/7517673/1077943

      delete localVarUrlObj.search;
      localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
      const needsSerialization = ('GetPersonsAxesorRequest' as any !== 'string') ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization ? JSON.stringify(params.getPersonsAxesorRequest || {}) : params.getPersonsAxesorRequest || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * GetPersonsAxesorApi - functional programming interface
 * @param {Configuration} [configuration]
 * @returns {any}
 */
const GetPersonsAxesorApiFp = function(configuration?: Configuration): any {
  return {
    
    /**
     * Devuelve los datos de la persona tras consultar a Axesor
     * @param {GetPersonsAxesorRequest} getPersonsAxesorRequest 
     * @param {string} [acceptLanguage] El Accept-Language request HTTP header indica en que lenguaje debe responder el backend.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @returns {any}
     */
    getPersonsAxesorOperation(params: getPersonsAxesorOperationParams, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetPersonsAxesorResponse> {
      const localVarAxiosArgs = GetPersonsAxesorApiAxiosParamCreator(configuration).getPersonsAxesorOperation(params, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH): any => {
        const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * GetPersonsAxesorApi - factory interface
 * @param {Configuration} configuration
 * @param {string} basePath
 * @param {AxiosInstance} axios
 * @export
 * @returns {any}
 */
const GetPersonsAxesorApiFactory = function(configuration?: Configuration, basePath?: string, axios?: AxiosInstance): any {
  return {
    
    /**
     * Devuelve los datos de la persona tras consultar a Axesor
     * @param {GetPersonsAxesorRequest} getPersonsAxesorRequest 
     * @param {string} [acceptLanguage] El Accept-Language request HTTP header indica en que lenguaje debe responder el backend.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @returns {any}
     */
    getPersonsAxesorOperation(params: getPersonsAxesorOperationParams, options?: any): any {
      return GetPersonsAxesorApiFp(configuration).getPersonsAxesorOperation(params, options)(axios, basePath);
    },
  };
};

/** 
 * GetPersonsAxesorApi - object-oriented interface
 * @export
 * @class GetPersonsAxesorApi
 * @extends {BaseAPI}
 */
export class GetPersonsAxesorApi extends BaseAPI {
  
  /**
   * Devuelve los datos de la persona tras consultar a Axesor
   * @param {GetPersonsAxesorRequest} getPersonsAxesorRequest 
   * @param {string} [acceptLanguage] El Accept-Language request HTTP header indica en que lenguaje debe responder el backend.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @returns {any}
   * @memberof GetPersonsAxesorApi
   */
  public getPersonsAxesorOperation(params: getPersonsAxesorOperationParams, options?: any): any {
    return GetPersonsAxesorApiFp(this.configuration).getPersonsAxesorOperation(params, options)(this.axios, this.basePath);
  }

}

/**
 * 
 * @export
 * @class EAgetPersonsAxesorOperationInputMessage
 */
export class EAgetPersonsAxesorOperationInputMessage {
  
  /**
   * 
   * @type {GetPersonsAxesorRequest}
   * @memberof EAgetPersonsAxesorOperationInputMessage
   */
  public getPersonsAxesorRequest: GetPersonsAxesorRequest;

  public constructor(getPersonsAxesorRequest: GetPersonsAxesorRequest, ){
  
  this.getPersonsAxesorRequest=getPersonsAxesorRequest;
  } 
}



/** 
 * EAGetPersonsAxesorApi - Architecture client for getPersonsAxesorOperation
 * @export
 * @class EAGetPersonsAxesorApi
 */
export class EAGetPersonsAxesorApi {

  /**
   * 
   * @param {GetPersonsAxesorRequest} getPersonsAxesorRequest
   
   * @param {Configuration} configuration
   * @throws {AsyncRequestError | RequiredError}
   * @returns {Promise<GetPersonsAxesorResponse | null>}
  */ 
  public async getPersonsAxesorOperation(params: getPersonsAxesorOperationParams, configuration: Configuration = {}): Promise<GetPersonsAxesorResponse | null> {
    return this.getPersonsAxesorOperationPromise(params, configuration);
  }

  /**
   * 
   * @param {GetPersonsAxesorRequest} getPersonsAxesorRequest
   
   * @param {Configuration} configuration
   * @param {Function} callback
   * @throws {AsyncRequestError | RequiredError}
   */
  public async getPersonsAxesorOperationSteps(params: getPersonsAxesorOperationParams, configuration: Configuration, callback: (res: AxiosResponse<GetPersonsAxesorResponse | null>) => void) {
    let done: boolean = false;
    const httpClient: AxiosInstance = EASDKConfig.getHttpClient('/v1/quoteAndBuy/getPersonsAxesorOperation', 'getPersonsAxesor', 'getPersonsAxesorOperation');
    const api = new GetPersonsAxesorApi(configuration, '', httpClient);
    const sleep = async(ms: number) => {
      return new Promise(resolve => setTimeout(resolve, ms));
    };
    const headers: { ['Async-Id']?: string } = {};
    while (!done) {
      const output = await api.getPersonsAxesorOperation(params, { headers }) as AxiosResponse<GetPersonsAxesorResponse | null>;
      callback(output);
      if (output.status === 202) {
        try {
          const retry = parseInt(output.headers['retry']);
          headers['Async-Id'] = output.headers['request-id'];
          if (isNaN(retry) || retry <= 0 || retry > 1000 * 3600) {
            throw new Error(`Retry time not acceptable (in milliseconds): ${retry}`);
          }
          await sleep(retry);
        } catch (err) {
          throw new Error(`Error in Async Request ${err}`);
        }
      } else {
        done = true;
      }
    }
  }

  /**
   * 
   * @param {GetPersonsAxesorRequest} getPersonsAxesorRequest
   
   * @param {Configuration} configuration
   * @throws {AsyncRequestError | RequiredError}
   * @returns {Promise<GetPersonsAxesorResponse | null>}
   */
  public async getPersonsAxesorOperationPromise(params: getPersonsAxesorOperationParams, configuration: Configuration): Promise<GetPersonsAxesorResponse | null> {
    return new Promise((resolve, reject) => {
      this.getPersonsAxesorOperationSteps(params, configuration, (response: AxiosResponse<GetPersonsAxesorResponse | null>) => {
        if (response.status !== 202) {
          resolve(response.data);
        }
      })
      .catch((err) => {
        reject(err);
      });
    });
  } 
}


