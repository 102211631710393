import {
  IndexedGetPersonAddressesResponseDomicilios
} from '../../selectables/add-edit-phone/add-edit-phone-model';
import MainAddressChangeModel from '../main-address-change/main-address-change-model';

export interface PhoneEditionFormModel{
  phoneNumber?: string;
  phoneType?: string;
  phoneCountry?: string;
}

/**
 * Model add-address-tomador
 */
class AddPhoneTomadorModel {
  public phoneAddresses: IndexedGetPersonAddressesResponseDomicilios[] = [];

  public selectedPhoneNumber?: string = '';

  public phoneInEditionIdx: number | undefined = undefined;

  public phoneInEditionSecCode: string | undefined = undefined;

  public phoneEditionForm: PhoneEditionFormModel = {
    phoneNumber: '',
    phoneType: '',
    phoneCountry: ''
  };

  public isAddingEditting: boolean = false;

  public mainAddressChangeModel: MainAddressChangeModel = new MainAddressChangeModel();
}

export default AddPhoneTomadorModel;
