<template>
  <div>
    <ea-flow-container>
      <div>
        <notification-handler/>
      </div>
      <ea-row>
        <ea-col>
          <qb-header
            id="policy-qb-header"
            v-model="model"
            :title="title"
            :subtitle="subTitle"
            :showSteps="showSteps"
            :steps="steps"
            :activeStep="currentHeaderStep"
            :offerNumber="model.offerNumber"
            :offerVersion="model.offerVersion"
            :codigoTipoMovimiento="getCodigoMovimiento()"
            :parentFlowId="flowId"
            :hasTestAbility="hasTestAbility"
            @openSubflow="openSubflow"
            ></qb-header>
        </ea-col>
        <ea-col>
          <component
            v-if="stepComponent"
            :is="stepComponent"
            v-model="model"
            :productFactory="productFactory"
            :currentHeaderStep="currentHeaderStep"
            :parentFlowId="flowId"
            :hasTestAbility="hasTestAbility"
            @changeStep="changeToStep"
            @showError="showGenericError"
            @handleGenericError="handleGenericError"
            @openSubflow="openSubflow"
            @goToPreviousFlow="goToPreviousFlow"
          />
          <!-- TODO: temporal template -->
          <template v-else>
            <div>
              <pre>{{
                `¡Vista asociada al navigation.step = '${navigation.step}' no implementada!`
              }}</pre>
            </div>
          </template>
        </ea-col>
      </ea-row>

      <!-- Generic Error Business Component -->
      <qb-generic-error
        id="genericError"
        v-model="model.genericErrorModel"
        :isVisible="isGenericErrorVisible"
        :modalData="genericErrorData"
        @close="closeGenericErrorDialog"
      ></qb-generic-error>
    </ea-flow-container>
  </div>
</template>

<script lang="ts">
import {
  Component, Prop
} from 'vue-property-decorator';

import {
  mixins
} from 'vue-class-component';

import {
  EAApplicationError,
  EAError,
  EAFlow,
  EAFlowNavigation,
  EAView,
  EALocaleManager,
  EAMultiError,
  EAMethod,
  EAContextManager,
  EAEventBus
} from '@zurich-es-npm/ea-front-web-core';

import MovementReasonView from './views/movement-reason-view.vue';
import GeneralDataView from './views/general-data-view.vue';
import WarrantiesView from './views/warranties-view.vue';

import {
  FlowHeaderStepsModel,
  FlowViewsStepsModel,
  InputModel,
  PolicyModel
} from './policy-model';
import QbHeaderBusiness from '@/business-components/qb-header/qb-header-business.vue';

import {
  GetGeneralDataRequestTipoOperacionOfertaEnum as OperationType
} from '@/services/V1/quoteAndBuy/getGeneralDataOperation/post/api';
import QbGenericErrorBusiness, {
  GenericErrorData
} from '@/business-components/qb-generic-error/qb-generic-error-business.vue';
import ProductFactory from '@/utils/quote-buy-product-factory/product-factory';
import ProductBase from '@/utils/quote-buy-product-factory/products/product-base';
import IssueOfferView from '../policy/views/issue-offer-view.vue';
import SummaryDownloadPolicyView from '../policy/views/summary-download-policy-view.vue';
import {
  isDocumentationProcessFinished
} from '@/utils/documents/documents-utils';
import GenerateDocumentationView from './views/generate-documentation-view.vue';

import PermissionUtils from '@/utils/permissions-utils';
import {
  SubflowThunderNameEnum
} from '@/types/flow/flow-enum.types';
import NotificationHandler from '@/presentational-components/notification-handler/notificationHandler.vue';
import ReplacementDataView from './views/replacement-data-view.vue';
import {
  CodigoRamo
} from '@/types/codigo-ramo/codigo-ramo-enum.types';

const PolicyFlowStepComponents = {
  movementReasonView: MovementReasonView,
  replacementDataView: ReplacementDataView,
  generalDataView: GeneralDataView,
  warrantiesView: WarrantiesView,
  generateDocumentationView: GenerateDocumentationView,
  issueOfferView: IssueOfferView,
  summaryDownloadPolicyView: SummaryDownloadPolicyView
};

@Component({
  components: {
    QbHeader: QbHeaderBusiness,
    QbGenericError: QbGenericErrorBusiness,
    'notification-handler': NotificationHandler
  }
})

/** 
 * Policy flow
 * 
 */
export default class PolicyFlow extends mixins<EAFlow<PolicyModel>>(EAFlow) {

  @Prop({
    required: false
  })
  public codigoRamo!: CodigoRamo;
  
  public productFactory!: ProductBase;

  public showSteps: boolean = false;

  public currentHeaderStep: number = 1;

  public flowId: string = '';

  public genericErrorData: GenericErrorData = {};

  public isGenericErrorVisible = false;

  hasTestAbility: boolean = false;
  
  /**
   * Step / component relationship
   */
  public stepComponentRelationship: {
    step: FlowViewsStepsModel;
    title?: string;
    component: typeof EAView;
  }[] = [];
  
  /**
   * Sets flow steps
   */
  public setStepComponentRelationship(): void {
    this.stepComponentRelationship = [];
    this.addPreviousStepIfNecessary();
    this.concatDefaultSteps();
    this.setStepTitles();
  }

  /**
   * Adds previous step if replacement or supplement
   */
  public addPreviousStepIfNecessary(): void {
    if (this.model.replacementMode) {
      this.stepComponentRelationship.push({
        step: FlowViewsStepsModel.ReplacementDataStep,
        component: PolicyFlowStepComponents.replacementDataView
      });
    }

    if (this.model.supplementMode) {
      this.stepComponentRelationship.push({
        step: FlowViewsStepsModel.MovementReasonStep,
        component: PolicyFlowStepComponents.movementReasonView
      });
    }
  }

  /**
   * Concats default steps to stepComponentRelationship
   */
  public concatDefaultSteps(): void {
    this.stepComponentRelationship = this.stepComponentRelationship.concat([
      {
        step: FlowViewsStepsModel.GeneralDataStep,
        component: PolicyFlowStepComponents.generalDataView
      },
      {
        step: FlowViewsStepsModel.WarrantiesStep,
        component: PolicyFlowStepComponents.warrantiesView
      },
      {
        step: FlowViewsStepsModel.GenerateDocumentationStep,
        component: PolicyFlowStepComponents.generateDocumentationView
      },
      {
        step: FlowViewsStepsModel.IssueOfferStep,
        component: PolicyFlowStepComponents.issueOfferView
      },
      {
        step: FlowViewsStepsModel.SummaryAndDownloadPolicyStep,
        component: PolicyFlowStepComponents.summaryDownloadPolicyView
      }
    ]);
  }

  /**
   * Sets step titles
   */
  setStepTitles(): void {
    const generalDataStep = this.stepComponentRelationship.find(
      stepItem => stepItem.step === FlowViewsStepsModel.GeneralDataStep);
    if (generalDataStep) {
      generalDataStep.title = EALocaleManager.i18n.t('quoteBuyGenericFlow.header.step.generalData') as string;
    }

    const warrantiesStep = this.stepComponentRelationship.find(
      stepItem => stepItem.step === FlowViewsStepsModel.WarrantiesStep);
    if (warrantiesStep) {
      warrantiesStep.title = EALocaleManager.i18n.t('quoteBuyGenericFlow.header.step.warranties') as string;
    }

    const issueOfferStep = this.stepComponentRelationship.find(
      stepItem => stepItem.step === FlowViewsStepsModel.IssueOfferStep);
    if (issueOfferStep) {
      issueOfferStep.title = EALocaleManager.i18n.t('quoteBuyGenericFlow.header.step.emission') as string;
    }
  }

  /** 
   * Initialize flow model
   */
  constructor() {
    super();
    
    this.navigation = new EAFlowNavigation();
    this.navigation.maxWindows = 1;
    this.navigation.step = 1;
  }

  /**
   * Hook created
   * - Create product factory for current product
   * - Set model for current product
   */
  created() {
    this.createProductFactory();
    this.setProductModel();
    this.setPermissions();
    this.setInitialStep();
    this.flowId = EAContextManager.getInstance().getFlowContext()?.activeFlow?.flowId || '';
    EAEventBus.$on('zzCloseFlow', this.onCloseCoexitenceFlow);
  }

  /**
   * On close Coexistence flow
   * 
   * @param {string} targetFlowId 
   */
  @EAMethod({
    loading: true
  })
  async onCloseCoexitenceFlow(targetFlowId: string): Promise<void> {
    if (targetFlowId === this.flowId && await isDocumentationProcessFinished(
      this.model.offerNumber,
      this.model.offerVersion
    )) {
      this.closeFlow();
    }
  }

  /**
   * Remove the listeners created for analytics tracking
   *
   * @memberof EAAnalyticsTracking
   */
  beforeDestroy(): void {
    EAEventBus.$off('zzCloseFlow', this.onCloseCoexitenceFlow);
  }

  /**
   * Create productFactory needed in the flow
   */
  @EAMethod()
  createProductFactory() {
    const product = ProductFactory.createFactory(this.codigoRamo || this.inputModel.codigoRamo);
    this.productFactory = product || null;
  }

  /** 
   * Set Product Model based on product factory
   */
  setProductModel() {
    this.model = this.productFactory.createPolicyModelInstance() as PolicyModel;
  }

  /**
   * Handles the navigatino to the next step
   * - If this.inputModel is informed, the next step is NewOfferStep
   * - If this.inputModel has no data, the next step is GeneralDataStep
   */
  setInitialStep() {
    const {
      codigoPoliza,
      versionPoliza,
      tipoPoliza,
      lastSituation,
      maintenanceMode,
      supplementMode,
      replacementMode,
      intermediarioProductor,
      hasParentFlow
    }: InputModel = this.inputModel;

    if (codigoPoliza !== undefined
      && versionPoliza !== undefined
      && tipoPoliza !== undefined) {
      this.model.offerNumber = codigoPoliza;
      this.model.offerVersion = versionPoliza;
      this.model.offerType = tipoPoliza;
      this.model.hasParentFlow = hasParentFlow;
      this.model.supplementMode = supplementMode;
      this.model.replacementMode = replacementMode;
      this.setStepComponentRelationship();
      if (supplementMode) {
        this.model.operationType = OperationType.MANTENIMIENTO; // Same behaviour than maintenance mode
        this.model.supplementReasonModel.producerIntermediary = intermediarioProductor;
        this.model.supplementReasonModel.lastSituation = lastSituation;
        this.changeToStep(FlowViewsStepsModel.MovementReasonStep, -1);
        return; // End method
      } else if (replacementMode) {
        this.model.operationType = OperationType.MANTENIMIENTO; // Same behaviour than maintenance mode
        this.model.replacementDataModel.producerIntermediary = intermediarioProductor;
        this.changeToStep(FlowViewsStepsModel.ReplacementDataStep, -1);
        return; // End method
      } else if (maintenanceMode) {
        this.model.operationType = OperationType.MANTENIMIENTO;
      } else {
        this.model.operationType = OperationType.CONSULTA;

        const issueOfferStep = this.stepComponentRelationship.find(
          stepItem => stepItem.step === FlowViewsStepsModel.IssueOfferStep);
        if (issueOfferStep) {
          issueOfferStep.title = undefined;
        }
      }
      this.changeToStep(FlowViewsStepsModel.GeneralDataStep, FlowHeaderStepsModel.GeneralDataStep);
    }
  }

  /**
   * Get correspondant step view component
   */
  get stepComponent(): typeof EAView | null {
    const relationship = this.stepComponentRelationship.find(
      rel => rel.step === this.navigation.step
    );
    if (relationship) {
      return relationship.component;
    }
    return null;
  }

  /**
   * Change manually to a specific step.
   * @param {number} step step to change
   * @param {number} headerStep step to change
   */
  changeToStep(step: number, headerStep: number): void {
    this.model.genericErrorModel = {
      title: '',
      isVisible: false,
      changeStepOnClose: undefined,
      showStepOnClose: undefined
    };
    this.$forceUpdate();

    // Steps visibility
    if (step === FlowViewsStepsModel.MovementReasonStep || step === FlowViewsStepsModel.ReplacementDataStep) {
      this.showSteps = false;
    } else {
      this.showSteps = true;
    }

    this.currentHeaderStep = headerStep;
    this.navigation.step = step;
    scrollTo(0, 0);
  }

  /**
   * Get codigo movimiento from model
   * 
   * @returns {string | undefined}
   */
  getCodigoMovimiento(): string | undefined {
    return this.model.datosCabecera?.datosPoliza?.codigoTipoMovimiento;
  }

  /**
   * Get flow title
   */
  get title(): string {
    const {
      maintenanceMode, supplementMode, replacementMode
    }: InputModel = this.inputModel;
    if (this.navigation.step === FlowViewsStepsModel.IssueOfferStep ||
    this.navigation.step === FlowViewsStepsModel.SummaryAndDownloadPolicyStep) {
      return EALocaleManager.i18n.t('quoteBuyGenericFlow.titleEmitPolicy') as string;
    } else if (supplementMode) {
      return EALocaleManager.i18n.t('policyMaintenanceFlow.supplementsFlowTitle') as string;
    } else if (replacementMode) {
      return EALocaleManager.i18n.t('policyMaintenanceFlow.replacementsFlowTitle') as string;
    } else if (maintenanceMode) {
      return EALocaleManager.i18n.t('policyMaintenanceFlow.flowTitle') as string;
    } else {
      return EALocaleManager.i18n.t('policyInquiryFlow.flowTitle') as string;
    }
  }

  /**
   * Get flow subtitle
   */
  get subTitle(): string {
    let subtitle = '';
    if (this.navigation.step === FlowViewsStepsModel.SummaryAndDownloadPolicyStep) {
      subtitle = EALocaleManager.i18n.t('quoteBuyGenericFlow.header.policyNumber', {
        policyNumber: this.model.emitOfferCode
      }) as string;
    } else {
      subtitle = EALocaleManager.i18n.t('quoteBuyGenericFlow.header.policyNumber', {
        policyNumber: this.model.offerNumber
      }) as string;
    }

    if (this.model.offerVersion > 0) {
      subtitle = `${subtitle} V.${ this.model.offerVersion}`;
    }

    return subtitle;
  }


  /** 
   * Get current step
   */
  get currentStep(): number {
    return this.navigation.step;
  }

  /** 
   * Get steps array
   */
  get steps(): {
    step: FlowViewsStepsModel;
    title?: string;
    component: typeof EAView;
  }[] {
    return this.stepComponentRelationship;
  }

  /**
   * Handles general data services errors
   * @param {any} args
   */
  public handleGenericError(args: any) {
    const {
      error, errorCode, changeStepOnClose
    } = args;
    // eslint-disable-next-line no-extra-parens
    if ((error as EAMultiError).getErrorList) {
      const multiError = error as EAMultiError;
      this.genericErrorData = {
        title: 'Error',
        errors: multiError.getErrorList().map(_err => {
          return {
            message: _err.message
          };
        }),
        changeStepOnClose
      };
      this.isGenericErrorVisible = true;
    } else {
      const _error = error as EAError;
      this.genericErrorData = {
        title: 'Error',
        message: _error.message,
        changeStepOnClose
      };
      this.isGenericErrorVisible = true;
      throw new EAApplicationError(errorCode, [_error.message]);
    }
  }

  /**
   * Closes generic dialog
   */
  closeGenericErrorDialog() {
    const changeStep = this.genericErrorData.changeStepOnClose;
    const showStep = this.genericErrorData.showStepOnClose;
    this.genericErrorData = {
      title: '',
      changeStepOnClose: undefined,
      showStepOnClose: undefined
    };
    this.isGenericErrorVisible = false;
    if (changeStep !== undefined && showStep !== undefined) {
      this.changeToStep(changeStep, showStep);
    }
  }

  /**
   * Shows error modal
   * @param {GenericErrorData} args - params for error modal
   */
  showGenericError(args: GenericErrorData) {
    const {
      title,
      message,
      messages,
      errors,
      changeStepOnClose,
      showStepOnClose
    } = args;
  
    this.genericErrorData = {
      title,
      message,
      messages,
      errors,
      changeStepOnClose,
      showStepOnClose
    };
    this.isGenericErrorVisible = true;
  }

  /**
   * Set permissions for the different sections
   */
  @EAMethod()
  public setPermissions(): void {
    const userAbilities = PermissionUtils.getUserAbilities();
    if (userAbilities.length > 0) {
      this.hasTestAbility = PermissionUtils.hasAbility(userAbilities, 'test');
    }
  }

  /**
   * Método que abre un subflujo
   * @param {SubflowThunderNameEnum} flow 
   */
  @EAMethod()
  openSubflow(flow: SubflowThunderNameEnum) {
    const inputChildModel = {
      codigoPoliza: this.model.offerNumber,
      versionPoliza: this.model.offerVersion,
      tipoPoliza: this.model.offerType,
    };

    if (flow === SubflowThunderNameEnum.DocumentationFlow) {
      this.goToFlow(SubflowThunderNameEnum.DocumentationFlow, inputChildModel);
    }
    if (flow === SubflowThunderNameEnum.CompetencesFlow) {
      this.goToFlow(SubflowThunderNameEnum.CompetencesFlow, inputChildModel);
    }
    if (flow === SubflowThunderNameEnum.NotesFlow) {
      this.goToFlow(SubflowThunderNameEnum.NotesFlow, inputChildModel);
    }
  }

  /**
   * Opens previous flow (ie: SearchOfferFlow, OfferMaintenanceFlow...)
   */
  @EAMethod()
  goToPreviousFlow() {
    this.returnToParentFlow(undefined);
  }

}
</script>
