<template>
  <div>
    <ea-card shadow="hidden">
      <div slot="cardbody" class="p-a-16">
        <ea-form
          ref="form"
          :model="model"
          :validationOptions="formValidation"
          :validateOnRuleChange="false"
        >
          <ea-row>

            <!-- LEFT SECTION: product, movement type & reason, intermediary, product, business -->
            <ea-col :span="12">
              <!-- PRODUCT -->
              <ea-row>
                <!-- Nombre producto -->
                <ea-col :span="12" v-if="model.productName">
                  <ea-form-item :label="$t('policyMaintenanceFlow.replacements.field.productName')">
                    <ea-input-text v-model="model.productName" :readonly="true" />
                  </ea-form-item>
                </ea-col>

                <!-- Código producto -->
                <ea-col :span="12" v-if="model.productCode">
                  <ea-form-item :label="$t('policyMaintenanceFlow.replacements.field.productCode')">
                    <ea-input-text v-model="model.productCode" :readonly="true" />
                  </ea-form-item>
                </ea-col>
              </ea-row>


              <!-- MOVIMIENTO -->
              <ea-row>
                <!-- Tipo movimiento -->
                <ea-col :span="12">
                  <ea-form-item
                    :label="$t('policyMaintenanceFlow.replacements.field.movementType')"
                    prop="movementType"
                    :required="!isReplacementDataView"
                  >
                    <ea-select
                      v-model="model.movementType"
                      :placeholder="$t('policyMaintenanceFlow.replacements.placeholder.movementType')"
                      :readonly="true"
                    >
                      <ea-option
                        v-for="reason in movementTypeList"
                        :key="reason.value"
                        :label="reason.label"
                        :value="reason.value"
                      />
                    </ea-select>
                  </ea-form-item>
                </ea-col>

                <!-- Motivo movimiento -->
                <ea-col :span="12">
                  <ea-form-item
                    :label="$t('policyMaintenanceFlow.replacements.field.movementReason')"
                    prop="movementReason"
                    :required="!isReplacementDataView"
                  >
                    <ea-select
                      v-model="model.movementReason"
                      :placeholder="$t('policyMaintenanceFlow.replacements.placeholder.movementReason')"
                      :readonly="true"
                    >
                      <ea-option
                        v-for="reason in movementReasonList"
                        :key="reason.value"
                        :label="reason.label"
                        :value="reason.value"
                      />
                    </ea-select>
                  </ea-form-item>
                </ea-col>
              </ea-row>
            </ea-col>

            <!-- RIGHT SECTION: date -->
            <ea-col :span="12">
              <ea-row>
                <!-- Fecha efecto natural -->
                <ea-col :span="12">
                   <ea-form-item
                    :label="$t('policyMaintenanceFlow.replacements.field.naturalEffectDate')"
                    prop="naturalEffectDate"
                  >
                    <ea-input-text
                      :value="model.naturalEffectDate"
                      readonly
                    />
                  </ea-form-item>
                </ea-col>
              </ea-row>

              <ea-row>
                <!-- Fecha efecto -->
                <ea-col :span="12">
                   <ea-form-item
                    :label="$t('policyMaintenanceFlow.replacements.field.effectDate')"
                    prop="effectDate"
                    required
                  >
                    <ea-date-picker
                      v-model="model.effectDate"
                      @change="onMovementEfectInputChange"
                      size="small"
                    />
                  </ea-form-item>
                </ea-col>
                
                <!-- Fecha vencimiento -->
                <ea-col :span="12">
                   <ea-form-item
                    :label="$t('policyMaintenanceFlow.replacements.field.dueDate')"
                    prop="dueDate"
                    required
                  >
                    <ea-date-picker
                      v-model="model.dueDate"
                      size="small"
                    />
                  </ea-form-item>
                </ea-col>
              </ea-row>
            </ea-col>

            <!-- INTERMEDIARIO -->
            <ea-col>
              <ea-row>
                <ea-col :span="18">
                  <intermediary-selection
                    id="intermediary-selection"
                    v-model="model.intermediarySelectionModel"
                    ref="intermediarySelection"
                    :productFactory="productFactory"
                    :showProductSelection="true"
                    :showBusinessSelection="true"
                    :emitProductListUpdateEvent="true"
                    :emitBusinessListUpdateEvent="true"
                    :fieldLabels="fieldLabels"
                    :isReplacementDataView="isReplacementDataView"
                    :businessIsRequired="businessIsRequired"
                    @businessListUpdate="businessListUpdate"
                    @productListUpdate="productListUpdate"
                    @showError="showError"
                    @handleGenericError="onHandleGenericError"
                    @closeGenericErrorDialog="closeGenericErrorDialog"
                    @setDefaultBusiness="setDefaultBusiness"
                  ></intermediary-selection>
                </ea-col>
              </ea-row>
            </ea-col>

          </ea-row>
        </ea-form>
      </div>
    </ea-card>

    <!-- Back & Next buttons -->
    <ea-row class="m-t-24">
      <ea-col :span="12" class="d-display-flex d-justify-flex-start">
        <ea-button
          type="secondary"
          @click="onGoBack()">
          {{ $t('common.label.back') }}
        </ea-button>
      </ea-col>
      <ea-col :span="12" class="d-display-flex d-justify-flex-end">
        <ea-button  @click="createReplacement">
          {{ $t('common.label.next') }}
        </ea-button>
      </ea-col>
    </ea-row>
  </div>
</template>

<script lang="ts">
import {
  Component, Prop
} from 'vue-property-decorator';

import {
  mixins
} from 'vue-class-component';

import {
  EAApplicationLogger,
  EABusinessComponent,
  EAError,
  EAMethod,
  EAValidationError,
  ResponseWithErrors,
  throwIfResponseHasErrors
} from '@zurich-es-npm/ea-front-web-core';

import QbReplacementDataModel from './qb-replacement-data-model';
import PermissionUtils from '@/utils/permissions-utils';
import {
  GetDefaultUserValuesResponseCodigoTipoUsuarioEnum as UserTypesEnum
} from '@/services/V1/home/getDefaultUserValuesOperation/post';
import {
  LabelValuePair
} from './qb-replacement-data.types';
import {
  EAFormValidationOptions, eaRequiredValidation, EAValidation, EAValidationTriggers, EAValidationTypes, Form
} from '@zurich-es-npm/ea-front-web-ui';
import IntermediarySelectionBusiness from '../intermediary-selection/intermediary-selection-business.vue';
import {
  GenericErrorData
} from '../qb-generic-error/qb-generic-error-business.vue';
import ProductBase from '@/utils/quote-buy-product-factory/products/product-base';
import {
  GetReplacementCreationDataResponse
} from '@/services/V1/policy/getReplacementCreationDataOperation/post';
import Utils from '@/utils/utils';
import {
  fetchCorporateTable, CorpTableNames, parseCorpTableDocuments, ParsedTableData
} from '@/utils/corporate-tables';
import {
  EAGetProductListApi, GetProductListRequestCodigoOperacionEnum, GetProductListRequestCodigoRamoEnum
} from '@/services/V1/common/getProductListOperation/post';
import {
  EAGetBusinessListApi, GetBusinessListRequestCodigoRamoEnum as CodigoRamoEnum
} from '@/services/V1/common/getBusinessListOperation/post';
import {
  BusinessList, ProductList
} from '../qb-searcher/qb-searcher.interface';
import moment from 'moment';

@Component({
  name: 'qb-replacement-data',
  components: {
    IntermediarySelection: IntermediarySelectionBusiness,
  },
})

/**
 * Business Component qb-replacement-data
 */
export default class QbReplacementDataBusiness extends mixins<
EABusinessComponent<QbReplacementDataModel>
>(EABusinessComponent) {
  
  @Prop({
    required: true,
  })
    productFactory!: ProductBase;

  @Prop({
    required: true
  })
    codigoPoliza?: string;

  @Prop({
    required: true
  })
    versionPoliza?: number;

  @Prop({
    required: true,
  })
    codigoRamo?: string;

  @Prop({
    required: true,
  })
    replacementData?: GetReplacementCreationDataResponse;

  @Prop({
    required: false,
    'default': () => false,
  })
    isReplacementDataView!: boolean;

  movementReasonList: LabelValuePair[] = [];

  movementTypeList: LabelValuePair[] = [];

  businessList: LabelValuePair[] = [];

  employeeUser: boolean = false;

  businessIsRequired: boolean = false;

  // Form rules definition
  formValidation: EAFormValidationOptions = {
    rules: {}
  };

  // Labels for intermediary-search comp
  fieldLabels: Record<string, string> = {
    productName: 'selectIntermediary.fields.replacingProductName',
    productCode: 'selectIntermediary.fields.replacingProductCode'
  };

  /**
   * On created hook.
   */
  @EAMethod({
    loading: true
  })
  async created() {
    this.setUserType();
    await this.setFormData();
    this.setValidationRules();
  }

  /**
   * Checks if user is employee and saves result
   */
  setUserType() {
    const userType = PermissionUtils.getUserType();
    this.employeeUser = PermissionUtils.isUserOfType(userType, UserTypesEnum.EMP);
    this.model.intermediarySelectionModel.employeeUser = this.employeeUser;
  }

  /**
   * Sets replacement form data
   */
  @EAMethod({
    loading: true
  })
  async setFormData() {
    // Business
    this.model.businessCode = this.replacementData?.codigoNegocio || '';
    this.model.businessName = this.replacementData?.nombreNegocio || '';
    this.setBusinessMandatoriness();

    // Product
    this.model.productCode = this.getDisplayedProductCode();
    this.model.productName = this.replacementData?.nombreProducto || '';

    // Intermediary
    this.model.intermediaryName = this.replacementData?.nombreIntermediario || '';
    this.model.intermediarySelectionModel.intermediaryName = this.model.intermediaryName;
    this.model.intermediarySelectionModel.intermediaryCode = this.model.producerIntermediary;
    const intermediarySelectionComp: IntermediarySelectionBusiness =
      this.$refs.intermediarySelection as IntermediarySelectionBusiness;
    if (intermediarySelectionComp) {
      await intermediarySelectionComp.onSelectedIntermediaryChange();
    }

    // Movement type & reason
    this.model.movementType = this.replacementData?.tipoMovimientoPoliza || '';
    this.model.movementReason = this.replacementData?.motivoMovimientoPoliza || '';

    // Movement type restriction table
    this.movementTypeList = await this.fetchMovementTypeCorporateTable();

    // Movement reason restriction table
    if (this.replacementData?.tablaRestricciones) {
      this.movementReasonList = this.replacementData.tablaRestricciones.
        filter(restric => restric.nombreRestriccion && restric.valorRestriccion)
      // eslint-disable-next-line arrow-body-style
        .map(restric => ({
          label: restric.nombreRestriccion as string,
          value: restric.valorRestriccion as string
        }));
    }
    Utils.sortObjectArrayByProperty(this.movementReasonList, 'label');
    
    // Dates
    this.model.naturalEffectDate = new Date(
      this.replacementData?.fechaEfectoNatural || ''
    ).toLocaleDateString('es-ES', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
    });
    
    this.update();
  }

  /**
   * Checks if origin businessCode is in businessList, and if true sets default business on dropdawn
   */
  setDefaultBusiness() {
    const intermediarySelectionComp: IntermediarySelectionBusiness =
      this.$refs.intermediarySelection as IntermediarySelectionBusiness;
    const businessFound =
      intermediarySelectionComp.businessList.find(bussiness => bussiness.businessCode === this.model.businessCode);
    if (intermediarySelectionComp && businessFound) {
      this.model.intermediarySelectionModel.businessName = this.model.businessName;
      this.model.intermediarySelectionModel.businessCode = this.model.businessCode;
    } else if (!businessFound) {
      this.model.intermediarySelectionModel.businessName =
        intermediarySelectionComp.businessList[0].businessName || '';
      this.model.intermediarySelectionModel.businessCode =
        intermediarySelectionComp.businessList[0].businessCode || '';
    }
  }
  
  /**
   * Sets mandatoriness for Bussiness field in depence of cdstruc
   */
  setBusinessMandatoriness() {
    this.businessIsRequired =
      this.replacementData?.codigoEstructura === '1006' ||
      this.replacementData?.codigoEstructura === '1008' ||
      this.replacementData?.codigoEstructura === '1009';
  }

  /**
   * Sets the validation rules for current form
   */
  setValidationRules() {
    this.formValidation = {
      rules: {
        effectDate: [
          eaRequiredValidation(
            'common.label.validation.fieldRequired',
            EAValidationTriggers.BLUR,
            EAValidationTypes.DATE
          ),
          // Needed for trigger validation when date is cleared clicking icon
          eaRequiredValidation(
            'common.label.validation.fieldRequired',
            EAValidationTriggers.CHANGE,
            EAValidationTypes.DATE
          )
        ],
        dueDate: [
          eaRequiredValidation(
            'common.label.validation.fieldRequired',
            EAValidationTriggers.BLUR,
            EAValidationTypes.DATE
          ),
          // Needed for trigger validation when date is cleared clicking icon
          eaRequiredValidation(
            'common.label.validation.fieldRequired',
            EAValidationTriggers.CHANGE,
            EAValidationTypes.DATE
          )
        ],
      },
    };
  }
  

  /**
   * Fetches movement type corporate table
   * @returns {Promise<ParsedTableData[]>}
   */
  async fetchMovementTypeCorporateTable(): Promise<ParsedTableData[]> {
    try {
      const result = await fetchCorporateTable(CorpTableNames.PolicyMovementType);
      const parsedResult = parseCorpTableDocuments(result, 'DSTIMOVP', 'TCTIMOVP');
      return parsedResult.map(entry => {
        return {
          ...entry,
          label: Utils.capitalizeFirstCharacter(entry.label)
        };
      });
    } catch (error) {
      const eaError = error as EAError;
      new EAApplicationLogger().error(
        `QbReplacementDataBusiness::fetchMovementTypeCorporateTable:: fetch corporate table :: ${eaError.message}`
      );
      throw error;
    }
  }

  /**
   * Fetch selected intermediary business list
   */
  @EAMethod({
    loading: true
  })
  async businessListUpdate() {
    const api = new EAGetBusinessListApi();
    const output = await api.getBusinessListOperation({
      getBusinessListRequest: {
        codigoRamo: this.codigoRamo as CodigoRamoEnum,
        codigoIntermediario: this.model.intermediarySelectionModel.intermediaryCode
      }
    });

    if (output) {
      throwIfResponseHasErrors(output as ResponseWithErrors);
      const intermediarySelectionComp: IntermediarySelectionBusiness =
      this.$refs.intermediarySelection as IntermediarySelectionBusiness;
      if (intermediarySelectionComp) {
        const businessList: BusinessList[] = (output.negocios || []).map(business => {
          return {
            businessName: business.nombreNegocio as string,
            businessCode: business.codigoNegocio as string
          };
        });
        intermediarySelectionComp.setBusinessList(businessList);
      }
    }
  }

  /**
   * Fetch selected intermediary product list
   */
  @EAMethod({
    loading: true
  })
  async productListUpdate() {
    const api = new EAGetProductListApi();
    const output = await api.getProductListOperation({
      getProductListRequest: {
        codigoComercialProducto: this.replacementData?.codigoComercialProducto,
        codigoTecnicoProducto: this.replacementData?.codigoTecnicoProducto,
        codigoIntermediario: this.model.intermediarySelectionModel.intermediaryCode,
        codigoNegocio: this.model.intermediarySelectionModel.businessCode,
        codigoOperacion: GetProductListRequestCodigoOperacionEnum.RE,
        codigoRamo: this.productFactory.codigoRamo as unknown as GetProductListRequestCodigoRamoEnum
      }
    });

    if (output) {
      throwIfResponseHasErrors(output as ResponseWithErrors);
      const intermediarySelectionComp: IntermediarySelectionBusiness =
      this.$refs.intermediarySelection as IntermediarySelectionBusiness;
      if (intermediarySelectionComp) {
        const productList: ProductList[] = (output?.listaProductos || []).map(product => {
          return {
            productName: product.descripcionProducto,
            productCode: `${product.codigoTecnicoProducto}-${product.codigoComercialProducto}`,
            productComercialCode: product.codigoComercialProducto,
            productTechnicalCode: product.codigoTecnicoProducto
          };
        });
        intermediarySelectionComp.setProductList(productList);
      }
    }
  }

  /**
   * Calculates movement due date when duration value is equal to 'Renewable'
   * @return { Date } movement effect date + 1 year - 1 day (+ 1 year if leap year (bisiesto))
   */
  calculateMovementDueMax(): Date {
    const rdo = moment(this.model.effectDate)
      .add(1, 'years')
      .subtract(1, 'days');

    if (
      moment(this.model.effectDate).isLeapYear() &&
      moment(this.model.effectDate).month() === 1 &&
      moment(this.model.effectDate).date() === 29
    ) {
      rdo.add(1, 'days');
    }

    return moment(rdo).toDate();
  }

  /**
   * Handles effectDate input change
   * Converts received date to string one in order to avoid validation errors
   * @param {Date} value - inputted date
   */
  onMovementEfectInputChange() {
    //Triggers only when theres info in the value
    if (this.model.effectDate) {
      this.model.dueDate = this.calculateMovementDueMax();
    }
    this.update();
  }

  /**
   * Get product code concatenation.
   * 
   * @returns {string}
   */
  getDisplayedProductCode(): string {
    if (!this.replacementData) {
      return '';
    }
    return `${this.replacementData.codigoTecnicoProducto}-${this.replacementData.codigoComercialProducto}`;
  }

  /**
   * Handles next button
   */
  @EAMethod()
  public async createReplacement() {
    this.update();
    await this.validation().validate();
    this.$emit('createReplacement');
  }

  /**
   * Return form validation object
   * @returns {EAValidation}
   */
  public validation(): EAValidation {
    this.clearFlowErrors();
    
    const intermediarySelectionComp: IntermediarySelectionBusiness = this.$refs
      .intermediarySelection as IntermediarySelectionBusiness;
    if (this.model.businessCode !== '' && intermediarySelectionComp.model.businessCode === '') {
      throw new EAValidationError(
        this.$t('policyMaintenanceFlow.replacements.validation.businessCodeRequired').toString()
      );
    }

    const form: Form = this.$refs.form as Form;
    return form.validation();
  }

  /**
   * Handles back button
   */
  @EAMethod()
  onGoBack() {
    this.$emit('goToPreviousFlow');
  }

  /**
   * Emits showError event
   * @param {GenericErrorData} genericErrorData
   */
  @EAMethod()
  showError(genericErrorData: GenericErrorData) {
    this.$emit('showError', genericErrorData);
  }

  /**
   * Emit the refresh data event from address to parent component
   *
   * @param {any} args
   */
  @EAMethod()
  onHandleGenericError(args: any) {
    this.$emit('handleGenericError', args);
  }

  /**
   * Emits closeGenericErrorDialog event
   *
   * @param {any} args
   */
  @EAMethod()
  closeGenericErrorDialog() {
    this.$emit('closeGenericErrorDialog');
  }

}
</script>
