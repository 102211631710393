import {
  EAValidatorFunction
} from '@zurich-es-npm/ea-front-web-core/lib/validation-rules';
import moment from 'moment';

/**
 * Regexes for validating values.
 *
 * WARNING: nifPattern can be used for all NIF subtypes (DNI, NIE and CIF), but since CIF has different logic with
 * its own pattern and NIE has its own pattern, nifPattern is only used for DNI. This is conceptually wrong,
 * because DNI is formatted as "8 digits and 1 letter", and NIF pattern says that the first character can be a letter.
 *
 * It's suspected that no DNI pattern exists because NIFs in old ZonaZurich may be checked as follows (approximation):
 *  if (nifPattern.test(value)) {
 *    if (niePattern.test(value)) { // NIE }
 *    else if (cifPattern.test(value)) { // CIF }
 *    else { // DNI }
 *  }
 */
export const nifPattern = /^(([a-zA-Z]+\d{7})|\d{8})[a-zA-Z]$/;
export const niePattern = /^[x-zX-Z]\d{7}[a-zA-Z]$/;
export const cifPattern = /^[a-zA-Z]\d{7}[0-9a-zA-Z]$/;
export const passportOrExternalPattern = /^[0-9a-zA-Z]{9,12}$/;
export const knownPattern = /^\d{9}$/;
export const otherPattern = /^[0-9a-zA-Z]{3,12}$/;
export const nifLetters = 'TRWAGMYFPDXBNJZSQVHLCKE';
export const cifLetters = 'JABCDEFGHI';

/**
 * Returns the digits from a given NIF (except CIF).
 *
 * @param {string} nif - NIF string as N digits + 1 character (CIF excluded)
 * @returns {number | undefined} - A number if NIF is formatted as expected; undefined otherwise
 */
export function extractNifDigits(nif: string): number | undefined {
  const digitsAsString: string = nif.slice(0, nif.length - 1);
  const digitsAsNumber: number = parseInt(digitsAsString, 10);

  if (!(/^[0-9]+$/).test(digitsAsString) || isNaN(digitsAsNumber)) {
    return undefined;
  }

  return digitsAsNumber;
}

/**
 * Returns the control letter for a given NIF (except CIF).
 *
 * @param {number} nifDigits - NIF digits (CIF excluded)
 * @returns {string} - Control letter
 */
export function calculateNifControlLetter(nifDigits: number): string {
  // "nifLetters.length" is 23, but it's better to not hardcode that value
  return nifLetters[nifDigits % nifLetters.length];
}

/**
 * Checks if the last character from a NIF matches the expected control letter.
 *
 * @param {string} nif - NIF string as 8 digits + 1 letter (CIF excluded)
 * @returns {boolean} - true if control letter is correct and NIF is formatted as expected; false otherwise
 */
export function isControlLetterOK(nif: string): boolean {
  const isNifFormattedAsExpected: boolean = (/^[0-9]{8}[a-zA-Z]$/).test(nif);
  if (!isNifFormattedAsExpected) {
    return false;
  }

  // Get NIF digits (impossible to get undefined, since we are already handling that case with the pattern above)
  const nifDigits: number = extractNifDigits(nif) as number;

  // Get control and last letters
  const calculatedControlLetterFromNif: string = calculateNifControlLetter(nifDigits);
  const lastLetterFromNif: string = nif.charAt(nif.length -1).toUpperCase();

  // Check that both letters match
  return calculatedControlLetterFromNif === lastLetterFromNif;
}

/**
 * Checks if a NIF is valid or not.
 *
 * @param {string} value - NIF
 * @returns {boolean} - true if valid, false if not valid
 */
export function isValidNif(value: string): boolean {
  if (!value) {
    return true;
  }

  const nif: string = value.toUpperCase();

  // Check NIF pattern
  if (!nifPattern.test(nif)) {
    return false;
  }

  // Check NIF control character (letter)
  return isControlLetterOK(nif);
}

/**
 * Checks if a NIE is valid or not.
 *
 * @param {string} value - NIE
 * @returns {boolean} - true if valid, false if not valid
 */
export function isValidNie(value: string): boolean {
  if (!value) {
    return true;
  }

  const nie: string = value.toUpperCase();

  // Check NIE pattern
  if (!niePattern.test(nie)) {
    return false;
  }

  // Check NIE control character (letter)
  const firstDigit: number = ['X', 'Y', 'Z'].indexOf(nie.charAt(0));
  const calculatedNie = `${firstDigit}${nie.slice(1)}`;

  return firstDigit !== -1 && isControlLetterOK(calculatedNie);
}

/**
 * Checks if a passport is valid or not.
 *
 * @param {string} value - Passport
 * @returns {boolean} - true if valid, false if not valid
 */
export function isValidPassportOrExternal(value: string): boolean {
  if (!value) {
    return true;
  }

  const passportOrExternal: string = value.toUpperCase();

  // Check passport pattern
  return passportOrExternalPattern.test(passportOrExternal);
}

/**
 * Checks if a CIF is valid or not.
 *
 * @param {string} value - CIF
 * @returns {boolean} - true if valid, false if not valid
 */
export function isValidCif(value: string): boolean {
  if (!value) {
    return true;
  }

  const cif: string = value.toUpperCase();

  // Check first 8 characters
  if (!cifPattern.test(cif)) {
    return false;
  }
  
  const digits: number = parseInt(cif.slice(1, 8), 10);
  if (isNaN(digits) || digits === 0) {
    return false;
  }
  
  return true;

  /**
   * // Check CIF control character (letter or digit)
   *
   * // J = 0, A = 1, B = 2, C= 3, D = 4, E = 5, F = 6, G = 7, H = 8, I = 9
   * const letters: string[] = cifLetters.split('');
   *
   * // Split value in differents variables
   * const letter: string = cif.slice(0, 1);
   * const digits: number[] = cif
   * .slice(1, 8).split('')
   * .map(Number);
   * const control: string = cif.slice(1, 8);
   *
   * /*
   * Calculate sums from elements in even and odd positions
   *
   * WARNING: https://es.wikipedia.org/wiki/C%C3%B3digo_de_identificaci%C3%B3n_fiscal#Formato_del_c%C3%B3digo
   * Indexes are defined relative to the VALUE length and not DIGITS length,
   * so for these filter() functions, conditions are reversed since the first character in VALUE is discarded
   * and even indexes in VALUE are odd indexes in DIGITS
   *
   * const sumEven: number = digits
   * .filter((__, index) => index % 2 !== 0)
   * .reduce((first, second) => first + second, 0);
   * const sumOdd: number = digits
   * .filter((__, index) => index % 2 === 0)
   * .map((oddN: number) => {
   * let result: number = oddN * 2;
   *
   * /*
   * Result can't be higher than 18, since the highest single digit is 9,
   * and 9*2 = 18
   *
   * if (result > 9) {
   * const firstDigit: number = parseInt(`${result}`.charAt(0), 10);
   * const secondDigit: number = parseInt(`${result}`.charAt(1), 10);
   * result = firstDigit + secondDigit;
   * }
   *
   * return result;
   * })
   * .reduce((first, second) => first + second, 0);
   *
   * // Calculate digit
   * const lastDigitFromSum: number = (sumOdd + sumEven) % 10;
   * const digit: number = lastDigitFromSum === 0 ? 0 : 10 - lastDigitFromSum;
   *
   * // Compare result and control digit/character
   * if (letter.match(/[PQRSW]/) || digits.join('').slice(0, 2) === '00') {
   * // 1. Digit refers to a LETTER: valid if control matches the result
   * console.log('1');
   * return letters[digit] === control;
   * } else if (letter.match(/[ABEH]/)) {
   * // 2. Digit refers to a NUMBER: valid if control matches the digit
   * console.log('2');
   * return `${digit}` === control;
   * } else {
   * // 3. Digit can either refer to a NUMBER or a LETTER: valid if there's a match in one of both cases
   * console.log('3');
   * return `${digit}` === control || letters[digit] === control;
   * }
   */
}

/**
 * Checks if a document of known type is valid or not.
 *
 * @param {string} value Document code
 * @returns {boolean} true if valid, false if not valid
 */
export function isValidKnownType(value: string): boolean {
  const documentCode: string = value.toUpperCase();

  // Check pattern
  return knownPattern.test(documentCode);
}

/**
 * Checks if a document of any other type is valid or not.
 *
 * @param {string} value Document code
 * @returns {boolean} true if valid, false if not valid
 * @memberof DocumentValidations
 */
export function isValidOtherType(value: string): boolean {
  const documentCode: string = value.toUpperCase();

  // Check pattern
  return otherPattern.test(documentCode);
}

/**
 * Validates a NIF in a eaCustomValidation() function.
 *
 * @param {any} _rule
 * @param {string} value - NIF
 * @param {Function} callback - callback() is called if the NIF is valid, callback(new Error()) otherwise
 * @returns {void}
 */
export function nifValidation(_rule: any, value: string, callback: Function): void {
  return isValidNif(value) ? callback() : callback(new Error());
}

/**
 * Validates a passport in a eaCustomValidation() function.
 *
 * @param {any} _rule
 * @param {string} value - passport
 * @param {Function} callback - callback() is called if the passport is valid, callback(new Error()) otherwise
 * @returns {void}
 */
export function passportOrExternalValidation(_rule: any, value: string, callback: Function): void {
  return isValidPassportOrExternal(value) ? callback() : callback(new Error());
}

/**
 * Validates a NIE in a eaCustomValidation() function.
 *
 * @param {any} _rule
 * @param {string} value - NIE
 * @param {Function} callback - callback() is called if the NIE is valid, callback(new Error()) otherwise
 * @returns {void}
 */
export function nieValidation(_rule: any, value: string, callback: Function): void {
  return isValidNie(value) ? callback() : callback(new Error());
}

/**
 * Validates a CIF in a eaCustomValidation() function.
 *
 * @param {any} _rule
 * @param {string} value - CIF
 * @param {Function} callback - callback() is called if the CIF is valid, callback(new Error()) otherwise
 * @returns {void}
 */
export function cifValidation(_rule: any, value: string, callback: Function): void {
  return isValidCif(value) ? callback() : callback(new Error());
}

/**
 * Validates a value of Known Type in a eaCustomValidation() function.
 *
 * @param {any} _rule
 * @param {string} value - CIF
 * @param {Function} callback - callback() is called if the Known Type is valid, callback(new Error()) otherwise
 * @returns {void}
 */
export function knownTypeValidation(_rule: any, value: string, callback: Function): void {
  return isValidKnownType(value) ? callback() : callback(new Error());
}

/**
 * Validates a value of Other Type in a eaCustomValidation() function.
 *
 * @param {any} _rule
 * @param {string} value - CIF
 * @param {Function} callback - callback() is called if the Other Type is valid, callback(new Error()) otherwise
 * @returns {void}
 */
export function otherTypeValidation(_rule: any, value: string, callback: Function): void {
  return isValidOtherType(value) ? callback() : callback(new Error());
}

/**
 * Validates a date vale is between two other dates.
 *
 * @param {Date} min - Minimum date
 * @param {Date} max - Maximum date
 * @param {Function} callback - callback() is called if the Other Type is valid, callback(new Error()) otherwise
 * @returns {EAValidatorFunction}
 */
export function dateBetweenValidation(min?: Date, max?: Date): EAValidatorFunction {
  return (_rule: any, value: any, callback: Function) => {
    if (value !== null && (min || max)) {
      const momentValue = moment(value);
      const momentMin = moment(min);
      const momentMax = moment(max);
      if (momentMin !== undefined && momentValue.isBefore(momentMin)) {
        return callback(new Error());
      }
      if (max !== undefined && momentValue.isAfter(momentMax)) {
        return callback(new Error());
      }
    }

    return callback();
  };
}
