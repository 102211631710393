<template>
  <div>
    <ea-card v-for="group in model.fleetGroupedVehicleList" :key="group.codigoAgrupacionFlota" class="m-t-16">
      <div slot="cardbody" class="p-a-16">
        <fleet-maintenance-group-vehicle-table
          v-model="model.fleetGroupVehicleTableModel"
          :group="group"
          :id="group.codigoAgrupacionFlota"
          :queryPolicy="queryPolicy"
          :policyEmitedStatusList="policyEmitedStatusList"
          @queryVehicle="queryVehicle"
          @showDocumentation="onShowDocumentation"
          @anulateVehicle="onAnulateVehicle"
        />
      </div>
    </ea-card>
  </div>
</template>

<script lang="ts">
import {
  Component, Prop
} from 'vue-property-decorator';

import {
  mixins
} from 'vue-class-component';

import {
  EABusinessComponent
} from '@zurich-es-npm/ea-front-web-core';

import FleetMaintenanceVehicleGroupsModel from './fleet-maintenance-vehicle-groups-model';
import FleetMaintenanceGroupVehicleTableBusiness
  from '../fleet-maintenance-group-vehicle-table/fleet-maintenance-group-vehicle-table-business.vue';
import {
  GetGroupedVehicleListResponseListaVehiculos
} from '@/services/V1/fleets/getGroupedVehicleListOperation/post';
import {
  ParsedTableData
} from '@/utils/corporate-tables';

@Component({
  name: 'fleet-maintenance-vehicle-groups',
  components:{
    'fleet-maintenance-group-vehicle-table': FleetMaintenanceGroupVehicleTableBusiness
  }
})

/**
 * Business Component fleet-maintenance-vehicle-groups
 */
export default class FleetMaintenanceVehicleGroupsBusiness
  extends mixins<EABusinessComponent<FleetMaintenanceVehicleGroupsModel>>(EABusinessComponent) {

  @Prop()
    queryPolicy!: boolean;

  @Prop({
    required: true
  })
    policyEmitedStatusList!: ParsedTableData[];

  /**
   * Emite el vehiculo a consultar
   * @param {GetGroupedVehicleListResponseListaVehiculos} vehicle con los datos del vehiculo
   * @param {string} groupDescription descripción agrupación
   */
  queryVehicle(vehicle: GetGroupedVehicleListResponseListaVehiculos, groupDescription: string): void {
    this.$emit('queryVehicle', vehicle, groupDescription);
  }

  /**
   * Emite el vehiculo del que mostrar su documentación
   * @param {GetGroupedVehicleListResponseListaVehiculos} vehicle con los datos del vehiculo
   */
  onShowDocumentation(vehicle: GetGroupedVehicleListResponseListaVehiculos): void {
    this.$emit('showDocumentation', vehicle);
  }

  /**
   * Emite el vehiculo del que mostrar su documentación
   * @param {GetGroupedVehicleListResponseListaVehiculos} vehicle con los datos del vehiculo
   */
  onAnulateVehicle(vehicle: GetGroupedVehicleListResponseListaVehiculos): void {
    this.$emit('anulateVehicle', vehicle);
  }
}
</script>
