/* eslint-disable */

/**
 * zonazurich
 * Portal de Mediación Digital
 *
 * The version of the OpenAPI document: 1
 * Contact: digitalarchitecture@zurich.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import {
  EASDKConfig,
  EAUrlUtils
} from '@zurich-es-npm/ea-shared-utils-js';

import * as globalImportUrl from 'url';
import {
  Configuration
} from './configuration';
import globalAxios, {
  AxiosPromise,
  AxiosInstance,
  AxiosResponse
} from 'axios';

const BASE_PATH = EAUrlUtils.removeLastForwardSlash('http://localhost:3000');

/**
 *
 * @export
 */
export const COLLECTION_FORMATS = {
  csv: ',',
  ssv: ' ',
  tsv: '\t',
  pipes: '|',
};

/**
 *  
 * @export
 * @interface RequestArgs
 */
export interface RequestArgs {
  url: string;
  options: any;
}

/**
 * 
 * @export
 * @class BaseAPI
 */
export class BaseAPI {
  protected configuration: Configuration | undefined;

  /**
   *Creates an instance of BaseAPI.
   * @param {Configuration} [configuration]
   * @param {string} [basePath=BASE_PATH]
   * @param {AxiosInstance} [axios=globalAxios]
   * @memberof BaseAPI
   */
  public constructor(
    configuration?: Configuration,
    protected basePath: string = BASE_PATH,
    protected axios: AxiosInstance = globalAxios) {
    if (configuration) {
      this.configuration = configuration;
      this.basePath = configuration.basePath || this.basePath;
    }
  }
}

/**
 * 
 * @export
 * @class RequiredError
 * @extends {Error}
 */
export class RequiredError extends Error {
  public name: 'RequiredError' = 'RequiredError';

  public field: string;

  /**
   *Creates an instance of RequiredError.
   * @param {string} field
   * @param {string} [msg]
   * @memberof RequiredError
   */
  public constructor(field: string, msg?: string) {
    super(msg);
    this.field=field;
  }
}

/**
 * 
 * @export
 * @class AsyncRequestError
 * @extends {Error}
 */
export class AsyncRequestError extends Error {
  public name: 'AsyncRequestError' = 'AsyncRequestError';

  /**
   *Creates an instance of AsyncRequestError.
   * @param {string} field
   * @param {string} [msg]
   * @memberof AsyncRequestError
   */
  public constructor(msg?: string) {
    super(msg);
  }
}

/**
 * 
 * @export
 * @interface Error
 */
export interface Error {
    /**
     * 
     * @type {string}
     * @memberof Error
     */
    code: string;
    /**
     * 
     * @type {string}
     * @memberof Error
     */
    message: string;
    /**
     * 
     * @type {number}
     * @memberof Error
     */
    severity: number;
    /**
     * 
     * @type {Array<object>}
     * @memberof Error
     */
    childErrors?: Array<object>;
}/**
 * 
 * @export
 * @interface SaveOfferGenerateDocRequest
 */
export interface SaveOfferGenerateDocRequest {
    /**
     * 
     * @type {string}
     * @memberof SaveOfferGenerateDocRequest
     */
    codigoPoliza: string;
    /**
     * 
     * @type {number}
     * @memberof SaveOfferGenerateDocRequest
     */
    versionPoliza: number;
    /**
     * 
     * @type {string}
     * @memberof SaveOfferGenerateDocRequest
     */
    tipoPoliza: SaveOfferGenerateDocRequestTipoPolizaEnum;
    /**
     * 
     * @type {string}
     * @memberof SaveOfferGenerateDocRequest
     */
    codigoSecuencialDomicilio?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SaveOfferGenerateDocRequest
     */
    guardarDatosObjeto?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SaveOfferGenerateDocRequest
     */
    cesionDerechos?: string;
    /**
     * 
     * @type {Array<SaveOfferGenerateDocRequestElementosPoliza>}
     * @memberof SaveOfferGenerateDocRequest
     */
    elementosPoliza?: Array<SaveOfferGenerateDocRequestElementosPoliza>;
    /**
     * 
     * @type {Array<SaveOfferGenerateDocRequestElementosPoliza>}
     * @memberof SaveOfferGenerateDocRequest
     */
    elementosObjeto?: Array<SaveOfferGenerateDocRequestElementosPoliza>;
    /**
     * 
     * @type {Array<SaveOfferGenerateDocRequestElementosPoliza>}
     * @memberof SaveOfferGenerateDocRequest
     */
    elementosAdicionales?: Array<SaveOfferGenerateDocRequestElementosPoliza>;
    /**
     * 
     * @type {Array<SaveOfferGenerateDocRequestDatosPersonas>}
     * @memberof SaveOfferGenerateDocRequest
     */
    datosPersonas?: Array<SaveOfferGenerateDocRequestDatosPersonas>;
    /**
     * 
     * @type {SaveOfferGenerateDocRequestDataQuality}
     * @memberof SaveOfferGenerateDocRequest
     */
    dataQuality?: SaveOfferGenerateDocRequestDataQuality;
}

/**
    * @export
    * @enum {string}
    */
export enum SaveOfferGenerateDocRequestTipoPolizaEnum {
    Oferta = 'Oferta',
    Pliza = 'Póliza',
    Incompleta = 'Incompleta'
}
/**
 * 
 * @export
 * @interface SaveOfferGenerateDocRequestDataQuality
 */
export interface SaveOfferGenerateDocRequestDataQuality {
    /**
     * 
     * @type {string}
     * @memberof SaveOfferGenerateDocRequestDataQuality
     */
    codigoFiliacion?: string;
    /**
     * 
     * @type {string}
     * @memberof SaveOfferGenerateDocRequestDataQuality
     */
    codigoSecuenciaDomicilioTelefono?: string;
    /**
     * 
     * @type {string}
     * @memberof SaveOfferGenerateDocRequestDataQuality
     */
    codigoSecuenciaDomicilioEmail?: string;
    /**
     * 
     * @type {string}
     * @memberof SaveOfferGenerateDocRequestDataQuality
     */
    telefonoTomador?: string;
    /**
     * 
     * @type {string}
     * @memberof SaveOfferGenerateDocRequestDataQuality
     */
    prefijoPais?: string;
    /**
     * 
     * @type {string}
     * @memberof SaveOfferGenerateDocRequestDataQuality
     */
    emailTomador?: string;
    /**
     * 
     * @type {string}
     * @memberof SaveOfferGenerateDocRequestDataQuality
     */
    claseDomicilioTelefono?: string;
    /**
     * 
     * @type {string}
     * @memberof SaveOfferGenerateDocRequestDataQuality
     */
    claseDomicilioEmail?: string;
}/**
 * 
 * @export
 * @interface SaveOfferGenerateDocRequestDatosPersonas
 */
export interface SaveOfferGenerateDocRequestDatosPersonas {
    /**
     * 
     * @type {string}
     * @memberof SaveOfferGenerateDocRequestDatosPersonas
     */
    rolCliente?: SaveOfferGenerateDocRequestDatosPersonasRolClienteEnum;
    /**
     * 
     * @type {string}
     * @memberof SaveOfferGenerateDocRequestDatosPersonas
     */
    codigoFiliacion?: string;
    /**
     * 
     * @type {string}
     * @memberof SaveOfferGenerateDocRequestDatosPersonas
     */
    nombrePropio?: string;
    /**
     * 
     * @type {string}
     * @memberof SaveOfferGenerateDocRequestDatosPersonas
     */
    primerApellido?: string;
    /**
     * 
     * @type {string}
     * @memberof SaveOfferGenerateDocRequestDatosPersonas
     */
    segundoApellido?: string;
    /**
     * 
     * @type {string}
     * @memberof SaveOfferGenerateDocRequestDatosPersonas
     */
    codigoIdentificacionFiscal?: string;
    /**
     * 
     * @type {string}
     * @memberof SaveOfferGenerateDocRequestDatosPersonas
     */
    tipoDocumento?: SaveOfferGenerateDocRequestDatosPersonasTipoDocumentoEnum;
    /**
     * 
     * @type {string}
     * @memberof SaveOfferGenerateDocRequestDatosPersonas
     */
    tipoPersona?: SaveOfferGenerateDocRequestDatosPersonasTipoPersonaEnum;
    /**
     * 
     * @type {string}
     * @memberof SaveOfferGenerateDocRequestDatosPersonas
     */
    sexo?: SaveOfferGenerateDocRequestDatosPersonasSexoEnum;
    /**
     * 
     * @type {string}
     * @memberof SaveOfferGenerateDocRequestDatosPersonas
     */
    fechaNacimiento?: string;
    /**
     * 
     * @type {string}
     * @memberof SaveOfferGenerateDocRequestDatosPersonas
     */
    nacionalidadPersona?: string;
    /**
     * 
     * @type {string}
     * @memberof SaveOfferGenerateDocRequestDatosPersonas
     */
    codigoSecuencialDomicilio?: string;
    /**
     * 
     * @type {string}
     * @memberof SaveOfferGenerateDocRequestDatosPersonas
     */
    indicadorFiscal?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SaveOfferGenerateDocRequestDatosPersonas
     */
    indicadorTomadorIgualAsegurado?: boolean;
}

/**
    * @export
    * @enum {string}
    */
export enum SaveOfferGenerateDocRequestDatosPersonasRolClienteEnum {
    Tomador = 'Tomador',
    Asegurado = 'Asegurado',
    Pagador = 'Pagador'
}
/**
    * @export
    * @enum {string}
    */
export enum SaveOfferGenerateDocRequestDatosPersonasTipoDocumentoEnum {
    C = 'C',
    E = 'E',
    N = 'N',
    P = 'P',
    R = 'R',
    S = 'S',
    X = 'X'
}
/**
    * @export
    * @enum {string}
    */
export enum SaveOfferGenerateDocRequestDatosPersonasTipoPersonaEnum {
    F = 'F',
    J = 'J'
}
/**
    * @export
    * @enum {string}
    */
export enum SaveOfferGenerateDocRequestDatosPersonasSexoEnum {
    V = 'V',
    M = 'M'
}
/**
 * 
 * @export
 * @interface SaveOfferGenerateDocRequestElementosPoliza
 */
export interface SaveOfferGenerateDocRequestElementosPoliza {
    /**
     * 
     * @type {string}
     * @memberof SaveOfferGenerateDocRequestElementosPoliza
     */
    codigoElemento?: string;
    /**
     * 
     * @type {string}
     * @memberof SaveOfferGenerateDocRequestElementosPoliza
     */
    valorElemento?: string;
}/**
 * 
 * @export
 * @interface SaveOfferGenerateDocResponse
 */
export interface SaveOfferGenerateDocResponse {
    /**
     * 
     * @type {boolean}
     * @memberof SaveOfferGenerateDocResponse
     */
    ok?: boolean;
    /**
     * 
     * @type {Array<Error>}
     * @memberof SaveOfferGenerateDocResponse
     */
    errors?: Array<Error>;
}

type saveOfferGenerateDocOperationParams = {
  saveOfferGenerateDocRequest: SaveOfferGenerateDocRequest,
};

/**
 * SaveOfferGenerateDocApi - axios parameter creator
 * @param {Configuration} [configuration]
 * @returns {any}
 */
const SaveOfferGenerateDocApiAxiosParamCreator = function(configuration?: Configuration): any {
  return {
    
    /**
     * Guarda los datos de oferta (cabecera+tomador, objeto+adicionales+otros roles)
     * @param {SaveOfferGenerateDocRequest} saveOfferGenerateDocRequest 
     * @param {string} [acceptLanguage] El Accept-Language request HTTP header indica en que lenguaje debe responder el backend.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @returns {RequestArgs}
     */
    saveOfferGenerateDocOperation(params: saveOfferGenerateDocOperationParams, options: any = {}): RequestArgs {
      // Verify required parameter 'saveOfferGenerateDocRequest' is not null or undefined
      if (params.saveOfferGenerateDocRequest === null || params.saveOfferGenerateDocRequest === undefined) {
        throw new RequiredError('saveOfferGenerateDocRequest', 'Required parameter saveOfferGenerateDocRequest was null or undefined when calling saveOfferGenerateDocOperation.');
      }
      const localVarPath = `/v1/quoteAndBuy/saveOfferGenerateDocOperation`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true) as Partial<globalImportUrl.UrlWithParsedQuery>;
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;


  
      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
      // Fix override query string Detail: https://stackoverflow.com/a/7517673/1077943

      delete localVarUrlObj.search;
      localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
      const needsSerialization = ('SaveOfferGenerateDocRequest' as any !== 'string') ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization ? JSON.stringify(params.saveOfferGenerateDocRequest || {}) : params.saveOfferGenerateDocRequest || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * SaveOfferGenerateDocApi - functional programming interface
 * @param {Configuration} [configuration]
 * @returns {any}
 */
const SaveOfferGenerateDocApiFp = function(configuration?: Configuration): any {
  return {
    
    /**
     * Guarda los datos de oferta (cabecera+tomador, objeto+adicionales+otros roles)
     * @param {SaveOfferGenerateDocRequest} saveOfferGenerateDocRequest 
     * @param {string} [acceptLanguage] El Accept-Language request HTTP header indica en que lenguaje debe responder el backend.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @returns {any}
     */
    saveOfferGenerateDocOperation(params: saveOfferGenerateDocOperationParams, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<SaveOfferGenerateDocResponse> {
      const localVarAxiosArgs = SaveOfferGenerateDocApiAxiosParamCreator(configuration).saveOfferGenerateDocOperation(params, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH): any => {
        const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * SaveOfferGenerateDocApi - factory interface
 * @param {Configuration} configuration
 * @param {string} basePath
 * @param {AxiosInstance} axios
 * @export
 * @returns {any}
 */
const SaveOfferGenerateDocApiFactory = function(configuration?: Configuration, basePath?: string, axios?: AxiosInstance): any {
  return {
    
    /**
     * Guarda los datos de oferta (cabecera+tomador, objeto+adicionales+otros roles)
     * @param {SaveOfferGenerateDocRequest} saveOfferGenerateDocRequest 
     * @param {string} [acceptLanguage] El Accept-Language request HTTP header indica en que lenguaje debe responder el backend.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @returns {any}
     */
    saveOfferGenerateDocOperation(params: saveOfferGenerateDocOperationParams, options?: any): any {
      return SaveOfferGenerateDocApiFp(configuration).saveOfferGenerateDocOperation(params, options)(axios, basePath);
    },
  };
};

/** 
 * SaveOfferGenerateDocApi - object-oriented interface
 * @export
 * @class SaveOfferGenerateDocApi
 * @extends {BaseAPI}
 */
export class SaveOfferGenerateDocApi extends BaseAPI {
  
  /**
   * Guarda los datos de oferta (cabecera+tomador, objeto+adicionales+otros roles)
   * @param {SaveOfferGenerateDocRequest} saveOfferGenerateDocRequest 
   * @param {string} [acceptLanguage] El Accept-Language request HTTP header indica en que lenguaje debe responder el backend.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @returns {any}
   * @memberof SaveOfferGenerateDocApi
   */
  public saveOfferGenerateDocOperation(params: saveOfferGenerateDocOperationParams, options?: any): any {
    return SaveOfferGenerateDocApiFp(this.configuration).saveOfferGenerateDocOperation(params, options)(this.axios, this.basePath);
  }

}

/**
 * 
 * @export
 * @class EAsaveOfferGenerateDocOperationInputMessage
 */
export class EAsaveOfferGenerateDocOperationInputMessage {
  
  /**
   * 
   * @type {SaveOfferGenerateDocRequest}
   * @memberof EAsaveOfferGenerateDocOperationInputMessage
   */
  public saveOfferGenerateDocRequest: SaveOfferGenerateDocRequest;

  public constructor(saveOfferGenerateDocRequest: SaveOfferGenerateDocRequest, ){
  
  this.saveOfferGenerateDocRequest=saveOfferGenerateDocRequest;
  } 
}



/** 
 * EASaveOfferGenerateDocApi - Architecture client for saveOfferGenerateDocOperation
 * @export
 * @class EASaveOfferGenerateDocApi
 */
export class EASaveOfferGenerateDocApi {

  /**
   * 
   * @param {SaveOfferGenerateDocRequest} saveOfferGenerateDocRequest
   
   * @throws {AsyncRequestError | RequiredError}
   * @returns {Promise<SaveOfferGenerateDocResponse | null>}
  */ 
  public async saveOfferGenerateDocOperation(params: saveOfferGenerateDocOperationParams): Promise<SaveOfferGenerateDocResponse | null> {
    return this.saveOfferGenerateDocOperationPromise(params);
  }

  /**
   * 
   * @param {SaveOfferGenerateDocRequest} saveOfferGenerateDocRequest
   
   * @param {Function} callback
   * @throws {AsyncRequestError | RequiredError}
   */
  public async saveOfferGenerateDocOperationSteps(params: saveOfferGenerateDocOperationParams, callback: (res: AxiosResponse<SaveOfferGenerateDocResponse | null>) => void) {
    let done: boolean = false;
    const httpClient: AxiosInstance = EASDKConfig.getHttpClient('/v1/quoteAndBuy/saveOfferGenerateDocOperation', 'saveOfferGenerateDoc', 'saveOfferGenerateDocOperation');
    const config: Configuration = {};
    const api = new SaveOfferGenerateDocApi(config, '', httpClient);
    const sleep = async(ms: number) => {
      return new Promise(resolve => setTimeout(resolve, ms));
    };
    const headers: { ['Async-Id']?: string } = {};
    while (!done) {
      const output = await api.saveOfferGenerateDocOperation(params, { headers }) as AxiosResponse<SaveOfferGenerateDocResponse | null>;
      callback(output);
      if (output.status === 202) {
        try {
          const retry = parseInt(output.headers['retry']);
          headers['Async-Id'] = output.headers['request-id'];
          if (isNaN(retry) || retry <= 0 || retry > 1000 * 3600) {
            throw new Error(`Retry time not acceptable (in milliseconds): ${retry}`);
          }
          await sleep(retry);
        } catch (err) {
          throw new Error(`Error in Async Request ${err}`);
        }
      } else {
        done = true;
      }
    }
  }

  /**
   * 
   * @param {SaveOfferGenerateDocRequest} saveOfferGenerateDocRequest
   
   * @throws {AsyncRequestError | RequiredError}
   * @returns {Promise<SaveOfferGenerateDocResponse | null>}
   */
  public async saveOfferGenerateDocOperationPromise(params: saveOfferGenerateDocOperationParams): Promise<SaveOfferGenerateDocResponse | null> {
    return new Promise((resolve, reject) => {
      this.saveOfferGenerateDocOperationSteps(params, (response: AxiosResponse<SaveOfferGenerateDocResponse | null>) => {
        if (response.status !== 202) {
          resolve(response.data);
        }
      })
      .catch((err) => {
        reject(err);
      });
    });
  } 
}


