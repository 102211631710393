import {
  GetDefaultNoteListResponseNotasTipoDestinoNotaEnum
} from '@/services/V1/notes/getDefaultNoteListOperation/post';

export interface CreateNoteFormModel {
  noteType?: GetDefaultNoteListResponseNotasTipoDestinoNotaEnum;
  noteTitle: string;
  noteBody: string;
}

/** 
 * Model create-note-modal
 */
class CreateNoteModalModel {

  public hidden: boolean = true;

  public createNoteForm: CreateNoteFormModel = {
    noteTitle: '',
    noteBody: ''
  };

}

export default CreateNoteModalModel;
