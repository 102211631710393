/** 
 * Model warranties-header-grid-selection
 */
class WarrantiesHeaderGridSelectionModel {
 
  public selectedOption?: string = undefined;

  public multipleSelectedOptions: string[] = [];

}

export default WarrantiesHeaderGridSelectionModel;
