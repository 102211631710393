<template>
  <ea-form ref="form" :model="model" :validationOptions="validationOptions">
    <ea-row>
      <!-- Código postal / Zip Code -->
      <ea-col :span="model.columnSpan">
        <ea-form-item
          prop="zipCode"
          :label="$t('quoteBuyGenericFlow.searchPerson.field.zipCode')"
          :required="!isReadonly"
        >
          <ea-input-text
            @keydown.enter.native="stopKeyEvent($event)"
            v-model="model.zipCode"
            :maxlength="5"
            :readonly="isReadonly || !isAnyElementModificable(['TCCOPOST', 'CDPOST', 'TCCOPADB'], datosObjeto)"
            @input="onZipCodeInput"
            @change="onZipCodeChange"
          />
        </ea-form-item>
      </ea-col>
      <!-- Población / City -->
      <ea-col :span="model.columnSpan">
        <ea-form-item
          v-if="isSelectedCountryAndorra || (citiesList && citiesList.length >= 2) || model.city"
          prop="city"
          :label="$t('quoteBuyGenericFlow.searchPerson.field.city')"
          :required="!isReadonly && (isSelectedCountryAndorra || (citiesList && citiesList.length >= 2))"
        >
          <ea-input-text
            v-if="isSelectedCountryAndorra"
            v-model="model.city"
            :readonly="isReadonly || !isAnyElementModificable(['NOPOBLSR', 'NOPOBADB'], datosObjeto)"
          />
          <ea-select
            v-else
            v-model="model.city"
            @change="onCityChange"
            :placeholder="$t('common.label.select')"
            :readonly="isReadonly || (citiesList && citiesList.length <= 1) ||
              !isAnyElementModificable(['NOPOBLSR', 'NOPOBADB'], datosObjeto)">
            <ea-option
              v-for="retrievedCity in citiesList"
              :key="retrievedCity.nombrePoblacion"
              :label="retrievedCity.nombrePoblacion"
              :value="retrievedCity.nombrePoblacion"
            />
          </ea-select>
        </ea-form-item>
      </ea-col>
      <!-- Provincia / Province -->
      <ea-col :span="model.columnSpan" v-if="model.isProvinceShown">
        <ea-form-item
          v-if="model.province"
          prop="province"
          :label="$t('quoteBuyGenericFlow.searchPerson.field.province')"
        >
          <ea-input-text v-model="model.province" readonly/>
        </ea-form-item>
      </ea-col>
    </ea-row>
  </ea-form>
</template>

<script lang="ts">
import {
  Component, Prop, Watch
} from 'vue-property-decorator';
import {
  mixins
} from 'vue-class-component';
import PostCodeLocationInputModel from './post-code-location-input-model';
import {
  GetLocationsByZipCodeRequest,
  EAGetLocationsByZipCodeApi,
  GetLocationsByZipCodeResponseData,
} from '../../services/V1/persons/getLocationsByZipCodeOperation/post';
import {
  Form,
  EAFormValidationOptions,
  eaRequiredValidation,
  eaCustomValidation,
  eaLengthValidation,
  EAValidation,
} from '@zurich-es-npm/ea-front-web-ui';
import {
  EABusinessComponent,
  EACorporateTableDirective,
  EAMethod,
  EAMultiError,
  EAValidationError,
} from '@zurich-es-npm/ea-front-web-core';
import {
  EAValidatorFunction
} from '@zurich-es-npm/ea-front-web-core/lib/validation-rules';
import {
  TextUtils
} from '../../utils/text-utils';
import Utils from '@/utils/utils';
import {
  ElForm,
  ValidateFieldCallback
} from 'element-ui/types/form';
import {
  GetGeneralDataResponseDataDatosObjeto
} from '@/services/V1/quoteAndBuy/getGeneralDataOperation/post';

@Component({
  name: 'post-code-location-input',
  directives: {
    'ea-corporate-table': EACorporateTableDirective,
  },
})

/**
 * Business Component post-code-location-input
 */
export default class PostCodeLocationInputBusiness extends mixins<EABusinessComponent<PostCodeLocationInputModel>>(
  EABusinessComponent
) {
  @Prop()
    isSelectedCountryAndorra?: boolean;

  @Prop({
    required: false,
    'default': () => false
  })
    isReadonly?: boolean;

  @Prop()
    cityListRetrievalCode?: string;
    
  @Prop({
    required: true,
  })
    datosObjeto!: GetGeneralDataResponseDataDatosObjeto[];

  citiesList?: GetLocationsByZipCodeResponseData[] = [];
  

  @Prop({
    'default': () => () => true
  })
    isElementModificable!: Function;

  @Prop({
    'default': () => () => true
  })
    isAnyElementModificable!: Function;

  isValidZipCode = true;

  citiesCache: Record<string, GetLocationsByZipCodeResponseData[] | undefined> = {};

  validationOptions: EAFormValidationOptions = {
    rules: {
      zipCode: [
        eaRequiredValidation('common.label.validation.fieldRequired'),
        eaLengthValidation(5),
        // eslint-disable-next-line no-invalid-this
        eaCustomValidation(this.zipCodeValidation(), 'common.label.validation.zipCodeServiceError'),
      ],
      city: [eaRequiredValidation('common.label.validation.fieldRequired')],
      province: [eaRequiredValidation('common.label.validation.fieldRequired')],
    }
  };

  /**
   * Will change to true once parent's created hook has finished
   * Prevents clearFlowErrors to be called before flowId is setted
   */
  parentCreatedHookFinished = false;

  /**
   * Hook on mounted
   * Checks if parent component's created hook has finished
   */
  mounted() {
    // TODO NODEJS20 Escucha el evento created del componente padre
    if (this.$parent) {
      this.$parent.$on('created', () => {
        this.parentCreatedHookFinished = true;
      });
    }
  }

  /**
   * Hook on created
   */
  created() {
    if (this.cityListRetrievalCode) {
      this.onCityListRetrievalCodeReceive();
    }
  }

  /**
   * Watcher for isSelectedCountryAndorra prop
   * - Triggered every time Country value is changes
   * - Resets the zipCode and all the zipCode fields on change
   */
  @Watch('isSelectedCountryAndorra')
  onIsSelectedCountryAndorraChange() {
    this.model.zipCode = '';
    this.resetFields();
  }

  /**
   * Handles zip code validation
   * - Throws error if the zip code lenght is less than 5
   * - Throws error if the selected country is Andorra and the province is empty
   * - Throws error if the selected country is not Andorra and the cityList is empty
   * @returns {EAValidatorFunction}
   */
  zipCodeValidation(): EAValidatorFunction {
    return (_rule: any, _value: any, callback: Function) => {
      if (!this.isValidZipCode) {
        return callback(new Error());
      }
      return callback();
    };
  }

  /**
   * Handles zip code input key press
   * - The input handles only alphanumeric characters if selected country is Andorra
   * - The input handles only numeric characters if selected country is Spain
   * @param {string} zipCode
   */
  onZipCodeInput(zipCode: string) {
    this.$nextTick(() => {
      this.model.zipCode = this.isSelectedCountryAndorra
        ? Utils.removeNonAlphaNumericCharacters(zipCode).toUpperCase()
        : Utils.removeNonNumericCharacters(zipCode);
    });
  }

  /**
   * Handles zip code change event
   * - Resets the fields when the inputed zip code has less than 5 chars
   * - If the selected country is Andorra, it handles the Andorra zip code input
   * - If the selected country is Spain, it handles the Spain zip code input
   * @param {string} zipCode - user entered zipCode
   */
  onZipCodeChange(zipCode: string) {
    this.isValidZipCode = true;
    this.validateField('zipCode', (errorMessage?: string) => {
      this.resetFields();
      if (!errorMessage) {
        this.isSelectedCountryAndorra
          ? this.andorraZipCodeHandler(zipCode)
          : this.spainZipCodeHandler(zipCode);
      }
    });
  }

  /**
   * Updates model with location list for given zipCode
   */
  resetFields() {
    this.citiesList = [];
    this.model.city = '';
    this.model.cityCode = '';
    this.model.province = '';
    this.model.populationNumber = 0;
  }

  /**
   * Handles ZipCode change when country is Andorra.
   * @param {string} zipCode
   */
  async andorraZipCodeHandler(zipCode: string) {
    const foundProvince = this.model.andorraProvinces.find(province => {
      const andorraProvinceLettersFound = TextUtils.equalIgnoreCase(zipCode.substring(0, 2), province.provinceCode);
      const andorraProvinceNumberFound = province.zipCode[2] === zipCode[2];
      return andorraProvinceLettersFound && andorraProvinceNumberFound;
    });

    if (foundProvince) {
      this.model.province = foundProvince?.province;
      this.isValidZipCode = true;
      this.update();
    } else {
      this.isValidZipCode = false;
      await this.$nextTick();
      this.validateField('zipCode');
    }
  }

  /**
   * Handles ZipCode change when country is Spain.
   * @param {string} zipCode
   */
  async spainZipCodeHandler(zipCode: string) {
    this.citiesList = await this.fetchLocationsByZipCode(zipCode);

    if (this.citiesList?.length) {
      Utils.sortObjectArrayByProperty(this.citiesList, 'nombrePoblacion');
      this.onCityChange();
      this.isValidZipCode = true;
      this.update();
    } else {
      this.isValidZipCode = false;
      this.validateField('zipCode');
    }
  }

  /**
   * Validates a single field
   * @param {string} fieldName
   * @param {ValidateFieldCallback} callback
   */
  validateField(fieldName: string, callback?: ValidateFieldCallback) {
    const form = this.$refs.form as Form;
    const elForm = form?.$refs.form as ElForm;
    elForm.validateField(fieldName, callback);
  }

  /**
   * Fetch location list with given zipCode
   * @param {string} zipCode - zipCode to search for
   */
  @EAMethod({
    loading: true,
  })
  async fetchLocationsByZipCode(zipCode: string) {
    if (this.parentCreatedHookFinished) {
      this.clearFlowErrors();
    }

    if (!this.citiesCache[zipCode]) {
      const getLocationsByZipCodeRequest: GetLocationsByZipCodeRequest = {
        codigoPostal: zipCode,
      };

      const api = new EAGetLocationsByZipCodeApi();
      const resp = await api.getLocationsByZipCodeOperation({
        getLocationsByZipCodeRequest,
      });

      if (resp && resp.errors?.length) {
        const multiError = new EAMultiError();
        resp.errors.forEach(error => multiError.push(new EAValidationError(error.message)));
        throw multiError;
      }
      this.citiesCache[zipCode] = resp?.data;
    }
    

    return this.citiesCache[zipCode] as GetLocationsByZipCodeResponseData[];
  }

  /**
   * Update parent when user changes selected city
   */
  onCityChange() {
    this.$nextTick(() => {
      if (this.citiesList?.length === 1) {
        this.model.city = this.citiesList[0]?.nombrePoblacion || '';
        this.model.cityCode = this.citiesList[0]?.codigoPoblacion || '';
        this.model.province = this.citiesList[0]?.nombreProvincia || '';
        this.model.populationNumber = this.citiesList[0]?.poblacionTotal || 0;
        this.model.poblationArea = this.citiesList[0]?.superficiePoblacion || 0;
        this.update();
        return;
      }
      const retrievedCity =
          this.citiesList && this.citiesList.find(
            cityListElement => cityListElement.nombrePoblacion?.substring(0, 30) === this.model.city.substring(0, 30)
          );
      this.model.cityCode = retrievedCity?.codigoPoblacion || '';
      this.model.province = retrievedCity?.nombreProvincia || '';
      this.model.populationNumber = retrievedCity?.poblacionTotal || 0;
      this.model.poblationArea = retrievedCity?.superficiePoblacion || 0;
      this.update();
    });
  }

  /**
   * Returns validation object
   * @returns {EAValidation }
   */
  public validation(): EAValidation {
    const form = this.$refs.form as Form;
    return form?.validation();
  }

  /**
   * Watcher for cityListRetrievalCode
   * If code received and country is not Andorra => Reload cityList
   */
  @Watch('cityListRetrievalCode', {
    immediate: true
  })
  async onCityListRetrievalCodeReceive() {
    if (this.cityListRetrievalCode && this.cityListRetrievalCode !== '' && !this.isSelectedCountryAndorra) {
      this.citiesList = await this.fetchLocationsByZipCode(this.cityListRetrievalCode);
      this.onCityChange();
    }
  }


  /**
   * Returns validation object
   * @param {Event} event
   */
  stopKeyEvent(event:Event) {
    event.preventDefault();
    event.stopImmediatePropagation();
  }
}
</script>
