import {
  GetIbanResponseDataIbanList
} from '@/services/V1/quoteAndBuy/getIbanOperation/post';
import QbIbanCodeModel from '../qb-iban-code/qb-iban-code-model';

/** 
 * Model fleet-payment-data
 */
class FleetPaymentDataModel {
  public paymentChannel: string = '';

  public ibanCodeModel: QbIbanCodeModel = new QbIbanCodeModel();

  public ibanList: GetIbanResponseDataIbanList[] = [];
}

export default FleetPaymentDataModel;
