export enum VehicleTypeEnum {
  Turismo = '100',
  Monovolumen = '120',
  TodoTerreno = '150',
  ComercialDerivadoTurismo = '200',
  ComercialDerivadoTT = '250',
  FurgonesYCamionesLigeros = '300',
  FurgonesHabPasajeros = '310',
  FurgonesPesados = '320',
  Camiones = '350',
  TractoCamiones = '400',
  AutocaresYAutobuses = '430',
  Motocicletas = '240',
  Ciclomotores = '280',
  VehículosAgrícolas = '470',
  VehículosIndustriales = '500',
  Remolques = '900',
  Semirremolques = '910'
}

