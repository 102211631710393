<template>
  <div>
    <ea-collapse v-if="group" header-background-color="white">
      <ea-collapse-item
        :title="
          $t('fleets.fleetsFlow.fleet-group-vehicle-table.titlePrefix') +
            (group.descripcionAgrupacionFlota ? group.descripcionAgrupacionFlota : '')
        "
      >
        <ea-row v-if="!isReadonly">
          <ea-col :span="12">
            {{ $t('fleets.fleetsFlow.fleet-group-vehicle-table.contentInfo') }}
          </ea-col>
        </ea-row>
        <ea-table
          :data="sortedListaVehiculos"
          :ref="'ref' + group.codigoAgrupacionFlota"
          :id="group.codigoAgrupacionFlota"
          :multiSelect="!isReadonly"
          :infinite-scroll="true"
          @checkBoxChange="onTableCheckChange('ref' + group.codigoAgrupacionFlota)"
          @checkBoxAllChange="onTableCheckChange('ref' + group.codigoAgrupacionFlota)"
          class="thead-fixed"
        >
          <template slot="top-table">
            <div class="ea-toolbar-action-wrapper d-display-flex d-direction-reverse">
              <ea-button type="primary" @click="onShowPackages()" class="m-l-16" :disabled="!hasActiveChecks">
                {{ $t('fleets.fleetsFlow.fleet-group-vehicle-table.tableDataHeaders.selectPackage') }}
              </ea-button>
              <ea-button
                type="secondary"
                @click="onRemoveVehiclesBtn('ref' + group.codigoAgrupacionFlota)"
                :disabled="!hasActiveChecks"
              >
                {{ $t('common.label.remove') }}
              </ea-button>
            </div>
          </template>
          <ea-table-column
            headerClass="no-sortBy"
            :label="$t('fleets.fleetsFlow.fleet-group-vehicle-table.tableDataHeaders.licensePlateNumber')"
            show="numeroMatriculaSinValidaciones"
          >
            <template slot-scope="row">
              <span>
                {{ row.numeroMatriculaSinValidaciones }}
              </span>
            </template>
          </ea-table-column>
          <ea-table-column
            headerClass="no-sortBy"
            :label="$t('fleets.fleetsFlow.fleet-group-vehicle-table.tableDataHeaders.brand')"
            show="descripcionMarcaVehiculo"
          >
            <template slot-scope="row">
              <span>
                {{ row.descripcionMarcaVehiculo }}
              </span>
            </template>
          </ea-table-column>
          <ea-table-column
            headerClass="no-sortBy"
            :label="$t('fleets.fleetsFlow.fleet-group-vehicle-table.tableDataHeaders.model')"
            show="descripcionModeloVehiculo"
          >
            <template slot-scope="row">
              <span>
                {{ row.descripcionModeloVehiculo }}
              </span>
            </template>
          </ea-table-column>
          <ea-table-column
            headerClass="no-sortBy"
            :label="$t('fleets.fleetsFlow.fleet-group-vehicle-table.tableDataHeaders.version')"
            show="descripcionVersionVehiculo"
          >
            <template slot-scope="row">
              <span>
                {{ row.descripcionVersionVehiculo }}
              </span>
            </template>
          </ea-table-column>
          <ea-table-column
            headerClass="no-sortBy"
            :label="$t('fleets.fleetsFlow.fleet-group-vehicle-table.tableDataHeaders.policyEfectDate')"
            show="fechaEfectoMovimiento"
            data-type="date"
          >
            <template slot-scope="row">
              <span>
                {{ dateFormatter(row.fechaEfectoMovimiento) }}
              </span>
            </template>
          </ea-table-column>
          <!-- TODO: cambiar por descripcion de paquete cuando exista -->
          <ea-table-column
            headerClass="no-sortBy"
            :label="$t('fleets.fleetsFlow.fleet-group-vehicle-table.tableDataHeaders.package')"
            :sortable="false"
          >
            <template slot-scope="col">
              <span>
                {{
                  col.codigoPaquete
                    ? $t(`fleets.fleetsFlow.fleetPackagesWarrantiesModal.codes.${col.codigoPaquete}`)
                    : '-'
                }}
              </span>
            </template>
          </ea-table-column>

          <!-- TODO: cambiar por la prima anual cuando exista -->
          <ea-table-column
            headerClass="no-sortBy"
            :label="$t('fleets.fleetsFlow.fleet-group-vehicle-table.tableDataHeaders.annualPremium')"
            show="importeTotal"
          >
            <template slot-scope="row">
              <div class="wp-80 d-display-flex d-justify-flex-end">
                <ea-text>
                  {{ digitsFormatter(row.importeTotal) }}
                </ea-text>
              </div>
            </template>
          </ea-table-column>

          <!-- TODO: cambiar por el comentario cuando devuelva datos -->
          <ea-table-column
            :label="$t('fleets.fleetsFlow.fleet-group-vehicle-table.tableDataHeaders.status')"
            :sortable="false"
            headerClass="no-sortBy"
          >
            <template slot-scope="col">
              <span class="d-display-flex d-align-items-center">
                <ea-icon
                  class="m-r-8"
                  v-if="getInfoIcon(col)"
                  status="info"
                  icon="el-icon-warning-outline"
                />
                <ea-icon
                  class="m-r-8"
                  v-else-if="getSuccessIcon(col)"
                  status="success"
                  icon="el-icon-circle-check"
                />
                <ea-icon
                  class="m-r-8"
                  v-else-if="col.estadoPolizaBatch === PolicyBatchOnline.GDC"
                  status="warning"
                  icon="el-icon-warning-outline"
                />
                <ea-tooltip
                  v-else-if="getErrorIcon(col)"
                  placement="top"
                  class="kpi-title-tooltip d-display-flex d-align-items-center"
                  :content="col.descripcionErrorTarificacion"
                >
                  <ea-icon class="m-r-8" status="danger" icon="el-icon-remove-outline" />
                </ea-tooltip>
                {{ getStatusText(col) }}
              </span>
            </template>
          </ea-table-column>
          <ea-table-column
            headerClass="no-sortBy"
            label=""
          >
            <template slot-scope="col">
              <ea-button-icon
                v-if="isReadonly && col.codigoPaquete"
                @click="onOpenPackageData(col)"
                type="text"
                class="t-size-14"
                icon="z-edit">
              </ea-button-icon>
            </template>
          </ea-table-column>
          <template slot="tfoot">
            <div class="m-a-16 d-display-flex d-justify-flex-end t-weight-bold">
              <span class="t-size-medium">
                {{ digitsFormatter(groupTotal) }}
              </span>
            </div>
          </template>
        </ea-table>
      </ea-collapse-item>
    </ea-collapse>

    <!-- Confirm vehicles deletion dialog -->
    <ea-dialog
      :visible="isConfirmVehicleDeletionVisible"
      size="small"
      :title="$t('fleets.fleetsFlow.inputVehiclesData.deleteDialog.title')"
      @close="onCancelDeletion"
    >
      <ea-paragraph size="medium" class="m-b-40 m-t-16">
        {{ $t(confirmVehicleDeletionDescription) }}
      </ea-paragraph>

      <!-- Cancel and save button -->
      <div class="d-display-flex d-justify-flex-end">
        <ea-button type="secondary" class="m-r-16" @click="onCancelDeletion">
          {{ $t('common.label.cancel') }}
        </ea-button>
        <ea-button type="primary" @click="deleteVehicles">
          {{ $t('common.label.accept') }}
        </ea-button>
      </div>
    </ea-dialog>
  </div>
</template>

<script lang="ts">
import {
  Component, Prop
} from 'vue-property-decorator';
import {
  mixins
} from 'vue-class-component';
import {
  EAApplicationLogger,
  EABusinessComponent,
  EAErrorManager,
  EAMethod,
  ResponseWithErrors,
  throwIfResponseHasErrors,
} from '@zurich-es-npm/ea-front-web-core';
import FleetGroupVehicleTableModel, {
  RefreshVehiclesDataEmitParams
} from './fleet-group-vehicle-table-model';
import {
  GetGroupedVehicleListResponseListaAgrupaciones as Agrupacion,
  GetGroupedVehicleListResponseListaVehiculos,
  GetGroupedVehicleListResponseListaVehiculos as Vehiculo,
} from '@/services/V1/fleets/getGroupedVehicleListOperation/post/api';
import {
  NotificationsTypeEnum, NotificationsUtils, ZZNotification
} from '@/utils/notifications/notifications-utils';
import {
  Table
} from '@zurich-es-npm/ea-front-web-ui';
import {
  cloneDeep
} from 'lodash';
import {
  DeleteVehicleResponseResultados, EADeleteVehicleApi
} from '@/services/V1/fleets/deleteVehicleOperation/post';
import Utils from '@/utils/utils';
import {
  PolicyBatchOnline
} from '@/types/batch-online/batch-online.types';
import {
  ParsedTableData
} from '@/utils/corporate-tables';

@Component({
  name: 'fleet-group-vehicle-table',
})

/**
 * Business Component fleet-group-vehicle-table
 */
export default class FleetGroupVehicleTableBusiness extends mixins<EABusinessComponent<FleetGroupVehicleTableModel>>(
  EABusinessComponent
) {
  @Prop()
    group!: Agrupacion;

  @Prop({
    required: true,
    'default': () => []
  })
    batchOnlineStatusList!: ParsedTableData[];

  @Prop()
    isReadonly?: boolean;

  vehiclesToDelete: Vehiculo[] = [];

  isConfirmVehicleDeletionVisible: boolean = false;

  confirmVehicleDeletionDescription: string = '';

  hasActiveChecks = false;

  PolicyBatchOnline = PolicyBatchOnline;

  /**
   * Life cycle hook called after a data change causes the virtual DOM to be re-rendered
   */
  updated() {
    this.onTableCheckChange(`ref${this.group.codigoAgrupacionFlota}`);
  }
  
  /**
   * Sorts vehicle list with invalid vehicles first.
   * @param {VehicleFormModel[]} vehicleList 
   * @returns {VehicleFormModel[]}
   */
  public get sortedListaVehiculos(): GetGroupedVehicleListResponseListaVehiculos[] | undefined {
    // eslint-disable-next-line max-len
    return this.group.listaVehiculos?.sort((vehicleA, vehicleB) => Number(vehicleA.valorVehiculo || false) - Number(vehicleB.valorVehiculo || false))
      .sort(
        // eslint-disable-next-line max-len
        (vehicleA, vehicleB) => Number(vehicleA.estadoPolizaBatch !== PolicyBatchOnline.NotRate) - Number(vehicleB.estadoPolizaBatch !== PolicyBatchOnline.NotRate)
      );
  }

  /**
   * Computed to know if component has predefined set of brands/models/versions
   */
  public get groupTotal(): number {
    let total = 0;
    if (this.group.listaVehiculos) {
      for (const vehiculo of this.group.listaVehiculos) {
        if (vehiculo.importeTotal) {
          total += vehiculo.importeTotal;
        }
      }
    }
    return total;
  }

  /**
   *  Show the icon in NotRate / PendingRate / PolicyPendingEmit case 
   * @param {GetGroupedVehicleListResponseListaVehiculos} col
   * @returns {boolean}
   */
  getInfoIcon(col: GetGroupedVehicleListResponseListaVehiculos): boolean {
    if (col.estadoPolizaBatch === PolicyBatchOnline.NotRate ||
        col.estadoPolizaBatch === PolicyBatchOnline.PendingRate ||
        col.estadoPolizaBatch === PolicyBatchOnline.PolicyPendingEmit) {
      return true;
    }
    return false;
  }

  /**
   * Show the icon in Rated or  PolicyEmitted case 
   * @param {GetGroupedVehicleListResponseListaVehiculos} col
   * @returns {boolean}
   */
  getSuccessIcon(col: GetGroupedVehicleListResponseListaVehiculos): boolean {
    if (col.estadoPolizaBatch === PolicyBatchOnline.Rated ||
        col.estadoPolizaBatch === PolicyBatchOnline.PolicyEmitted) {
      return true;
    }
    return false;
  }

  /**
   * Show the icon in error case 
   * @param {GetGroupedVehicleListResponseListaVehiculos} col
   * @returns {boolean}
   */
  getErrorIcon(col: GetGroupedVehicleListResponseListaVehiculos): boolean {
    if (col.estadoPolizaBatch === PolicyBatchOnline.Error ||
        col.estadoPolizaBatch === PolicyBatchOnline.PolicyError) {
      return true;
    }
    return false;
  }

  /**
   * Return the text of the table from policyEmitedStatusList
   * @param {GetGroupedVehicleListResponseListaVehiculos} col con los datos del vehiculo
   * @returns {string}
   */
  getStatusText(col: GetGroupedVehicleListResponseListaVehiculos) {
    // El estado en la tabla flexible corresponde al estadoPolizaBatch añadiendo una “P” delante
    const statusText = this.batchOnlineStatusList.find(item => item.value === `P${col.estadoPolizaBatch}`);
    return statusText?.label;
  }

  /**
   * Emits 'showWarrantiesModal' with needed data to show packages dialog.
   */
  onShowPackages() {
    const tableRef = `ref${this.group.codigoAgrupacionFlota}`;
    const eaTable = this.getTableComponent(tableRef);
    if (eaTable) {
      const selectedVehicles: Vehiculo[] = eaTable.getSelectedRows().map(row => row.getData() as Vehiculo);

      // Show warranties modal
      this.$emit('showWarrantiesModal', this.group, selectedVehicles);
    } else {
      new EAApplicationLogger().error(
        `FleetGroupVehicleTableBusiness::onShowPackages:: table component '${tableRef}' not found`
      );
    }
  }

  /**
   * Show delete vehicles confirmation dialog.
   *
   * @param {string} tableRef Reference name to the table component
   */
  onRemoveVehiclesBtn(tableRef: string) {
    const eaTable = this.getTableComponent(tableRef);

    if (!eaTable) {
      new EAApplicationLogger().error(
        `FleetGroupVehicleTableBusiness::onRemoveVehiclesBtn:: table component '${tableRef}' not found`
      );
      return;
    }

    const rows = eaTable.getSelectedRows();

    if (!rows.length) {
      return;
    }

    const vehicles = rows.map(row => row.getData() as Vehiculo);

    const modifier: 'single' | 'multi' = vehicles.length === 1 ? 'single' : 'multi';

    this.confirmVehicleDeletionDescription = `fleets.fleetsFlow.inputVehiclesData.deleteDialog.description.${modifier}`;

    this.vehiclesToDelete = vehicles;
    this.isConfirmVehicleDeletionVisible = true;
  }

  /**
   * Close delete vehicle modal and reset vehicles to delet array.
   */
  onCancelDeletion(): void {
    this.vehiclesToDelete = [];
    this.isConfirmVehicleDeletionVisible = false;
    this.hasActiveChecks = false;
  }

  /**
   * Open modal for consult package
   * @param {GetGroupedVehicleListResponseListaVehiculos} col
   */
  onOpenPackageData(col: GetGroupedVehicleListResponseListaVehiculos): void {
    this.$emit('showPackageModalConsult', col);
  }

  /**
   * Delete selected vehicles form fleet.
   */
  @EAMethod({
    loading: true,
  })
  async deleteVehicles(): Promise<void> {
    if (!this.group.listaVehiculos?.length) {
      return;
    }

    // Clean previous errors
    NotificationsUtils.clearNotifications();
    EAErrorManager.clearError();

    const vehiclesToDelete = cloneDeep(this.vehiclesToDelete);

    this.onCancelDeletion();

    const deleteApi = new EADeleteVehicleApi();

    const deleteResult = await deleteApi.deleteVehicleOperation({
      deleteVehicleRequest: {
        codigoFlota: this.group.listaVehiculos[0].codigoFlota as string,
        versionFlota: this.group.listaVehiculos[0].versionFlota as number,
        vehiculos: vehiclesToDelete.map(vehicle => {
          return {
            codigoPoliza: vehicle.codigoPoliza as string,
            versionPoliza: vehicle.versionPoliza as number,
          };
        }),
      },
    });

    if (deleteResult) {
      // Take correct deletions
      const deletedOk = deleteResult.resultados?.filter(res => res.ok) || [];

      this.deleteVehiclesFromTable(deletedOk);

      this.vehiclesToDelete = []; // Clear vehicles to delete

      throwIfResponseHasErrors(deleteResult as ResponseWithErrors); // At the end of logic, show errors in screen
    }

    // Clear vehicles to delete
    this.vehiclesToDelete = [];
  }

  /**
   * Delete given vehicles from table.
   * @param {DeleteVehicleResponseResultados[]} vehicles
   */
  deleteVehiclesFromTable(vehicles: DeleteVehicleResponseResultados[]) {
    if (!vehicles.length) {
      return;
    }

    const offerNumbersToDelete = vehicles.map(vehicle => vehicle.codigoPoliza);

    const nonDeletedVehicles = this.group.listaVehiculos?.filter(
      vehicle => offerNumbersToDelete.indexOf(vehicle.codigoPoliza as string) === -1
    );

    this.group.listaVehiculos = nonDeletedVehicles;

    // Refresh tables data
    this.getTableComponent(`ref${this.group.codigoAgrupacionFlota}`)?.mapDataToRows();

    // Show success notification
    const modifier = vehicles.length > 1 ? 'multi' : 'single';
    const label = `fleets.fleetsFlow.fleet-group-vehicle-table.deleteConfirmationMessage.${modifier}`;
    this.throwSuccessAlert(`${this.$t(label)}`);

    // Refresh the entire vehicles agroupations and tell to check fleet compisition as well
    this.$emit('refreshVehiclesData', {
      checkFleetComposition: true
    } as RefreshVehiclesDataEmitParams);
  }

  /**
   * Throw success alert by event buss
   * @param {string} message
   */
  throwSuccessAlert(message: string) {
    const notifications: ZZNotification[] = [
      {
        title: NotificationsTypeEnum.Success,
        message,
        type: NotificationsTypeEnum.Success,
      },
    ];
    NotificationsUtils.launchNotifications(notifications);
  }

  /**
   * Get table component by its reference (`this.$refs`).
   * @param {string} reference
   *
   * @returns {Table | undefined}
   */
  getTableComponent(reference: string): Table | undefined {
    return this.$refs[reference] as Table | undefined;
  }

  /**
   * Look for any active checks in the given table ref.
   * @param {string} tableRef
   */
  onTableCheckChange(tableRef: string): void {
    EAErrorManager.clearError();
    const eaTable = this.$refs[tableRef] as Table | undefined;
    if (eaTable) {
      const rows = eaTable.getSelectedRows();
      if (rows.length === 0) {
        this.hasActiveChecks = false;
      } else {
        this.hasActiveChecks = true;
      }
    } else {
      this.hasActiveChecks = false;
    }
  }

  /**
   * Formatea el precio ejemplo : 500 -> 500.00€
   * @param {String} price valor sin formato 500
   * @returns {String} valor con formato 500.00 €
   */
  digitsFormatter(price: number): string {
    return price ? `${Utils.formatFourDigitNumber(this.$n(price))} €` : '-';
  }

  /**
   * Formatea el valor de la fecha en formato "DD/MM/YYYY"
   * @param {String} value fecha sin en formato YYYY-MM-DD
   * @returns {String} fecha en formato "DD/MM/YYYY"
   */
  dateFormatter(value: string): string {
    return value ? Utils.convertDateToString(value) : '';
  }
}
</script>
