/* eslint-disable */

/**
 * zonazurich
 * Portal de Mediación Digital
 *
 * The version of the OpenAPI document: 1
 * Contact: digitalarchitecture@zurich.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import {
  EASDKConfig,
  EAUrlUtils
} from '@zurich-es-npm/ea-shared-utils-js';

import * as globalImportUrl from 'url';
import {
  Configuration
} from './configuration';
import globalAxios, {
  AxiosPromise,
  AxiosInstance,
  AxiosResponse
} from 'axios';

const BASE_PATH = EAUrlUtils.removeLastForwardSlash('http://localhost:3000');

/**
 *
 * @export
 */
export const COLLECTION_FORMATS = {
  csv: ',',
  ssv: ' ',
  tsv: '\t',
  pipes: '|',
};

/**
 *  
 * @export
 * @interface RequestArgs
 */
export interface RequestArgs {
  url: string;
  options: any;
}

/**
 * 
 * @export
 * @class BaseAPI
 */
export class BaseAPI {
  protected configuration: Configuration | undefined;

  /**
   *Creates an instance of BaseAPI.
   * @param {Configuration} [configuration]
   * @param {string} [basePath=BASE_PATH]
   * @param {AxiosInstance} [axios=globalAxios]
   * @memberof BaseAPI
   */
  public constructor(
    configuration?: Configuration,
    protected basePath: string = BASE_PATH,
    protected axios: AxiosInstance = globalAxios) {
    if (configuration) {
      this.configuration = configuration;
      this.basePath = configuration.basePath || this.basePath;
    }
  }
}

/**
 * 
 * @export
 * @class RequiredError
 * @extends {Error}
 */
export class RequiredError extends Error {
  public name: 'RequiredError' = 'RequiredError';

  public field: string;

  /**
   *Creates an instance of RequiredError.
   * @param {string} field
   * @param {string} [msg]
   * @memberof RequiredError
   */
  public constructor(field: string, msg?: string) {
    super(msg);
    this.field=field;
  }
}

/**
 * 
 * @export
 * @class AsyncRequestError
 * @extends {Error}
 */
export class AsyncRequestError extends Error {
  public name: 'AsyncRequestError' = 'AsyncRequestError';

  /**
   *Creates an instance of AsyncRequestError.
   * @param {string} field
   * @param {string} [msg]
   * @memberof AsyncRequestError
   */
  public constructor(msg?: string) {
    super(msg);
  }
}

/**
 * 
 * @export
 * @interface Error
 */
export interface Error {
    /**
     * 
     * @type {string}
     * @memberof Error
     */
    code: string;
    /**
     * 
     * @type {string}
     * @memberof Error
     */
    message: string;
    /**
     * 
     * @type {number}
     * @memberof Error
     */
    severity: number;
    /**
     * 
     * @type {Array<object>}
     * @memberof Error
     */
    childErrors?: Array<object>;
}/**
 * 
 * @export
 * @interface GetFleetRequest
 */
export interface GetFleetRequest {
    /**
     * 
     * @type {string}
     * @memberof GetFleetRequest
     */
    codigoFlota: string;
    /**
     * 
     * @type {number}
     * @memberof GetFleetRequest
     */
    versionFlota?: number;
}/**
 * 
 * @export
 * @interface GetFleetResponse
 */
export interface GetFleetResponse {
    /**
     * 
     * @type {string}
     * @memberof GetFleetResponse
     */
    codigoPoliza?: string;
    /**
     * 
     * @type {number}
     * @memberof GetFleetResponse
     */
    versionPoliza?: number;
    /**
     * 
     * @type {string}
     * @memberof GetFleetResponse
     */
    nombreFlota?: string;
    /**
     * 
     * @type {GetFleetResponseTomador}
     * @memberof GetFleetResponse
     */
    tomador?: GetFleetResponseTomador;
    /**
     * 
     * @type {string}
     * @memberof GetFleetResponse
     */
    codigoTecnicoProducto?: string;
    /**
     * 
     * @type {string}
     * @memberof GetFleetResponse
     */
    codigoComercialProducto?: string;
    /**
     * 
     * @type {string}
     * @memberof GetFleetResponse
     */
    formaPago?: string;
    /**
     * 
     * @type {string}
     * @memberof GetFleetResponse
     */
    formaPagoSucesivos?: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetFleetResponse
     */
    vencimientoUnificado?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetFleetResponse
     */
    fechaEfectoMovimiento?: string;
    /**
     * 
     * @type {string}
     * @memberof GetFleetResponse
     */
    fechaInicioPoliza?: string;
    /**
     * 
     * @type {string}
     * @memberof GetFleetResponse
     */
    fechaVencimientoFlota?: string;
    /**
     * 
     * @type {string}
     * @memberof GetFleetResponse
     */
    tipoCanalCobro1Recibo?: string;
    /**
     * 
     * @type {string}
     * @memberof GetFleetResponse
     */
    tipoCanalCobroSucesivos?: string;
    /**
     * 
     * @type {string}
     * @memberof GetFleetResponse
     */
    codigoIntermediario?: string;
    /**
     * 
     * @type {string}
     * @memberof GetFleetResponse
     */
    nombreIntermediario?: string;
    /**
     * 
     * @type {string}
     * @memberof GetFleetResponse
     */
    indicadorComposicionFlota?: string;
    /**
     * 
     * @type {number}
     * @memberof GetFleetResponse
     */
    numeroComposicionFlota?: number;
    /**
     * 
     * @type {number}
     * @memberof GetFleetResponse
     */
    numeroDeVehiculos?: number;
    /**
     * 
     * @type {boolean}
     * @memberof GetFleetResponse
     */
    indicadorCompromisoComercio?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetFleetResponse
     */
    indicadorCompromisoPymes?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetFleetResponse
     */
    indicadorCompromisoRespCivil?: boolean;
    /**
     * 
     * @type {number}
     * @memberof GetFleetResponse
     */
    primaNetaEstimada?: number;
    /**
     * 
     * @type {boolean}
     * @memberof GetFleetResponse
     */
    vehiculosRenting?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetFleetResponse
     */
    vehiculoSegundaCategoria?: string;
    /**
     * 
     * @type {string}
     * @memberof GetFleetResponse
     */
    ambitoTransporteSegundaCategoria?: string;
    /**
     * 
     * @type {number}
     * @memberof GetFleetResponse
     */
    numeroPolizasSme?: number;
    /**
     * 
     * @type {boolean}
     * @memberof GetFleetResponse
     */
    conductorReeducacion?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetFleetResponse
     */
    controlGerenciaRiesgo?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetFleetResponse
     */
    sistemaConduccionAvanzado?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetFleetResponse
     */
    conductorInexperto?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetFleetResponse
     */
    nombreProducto?: string;
    /**
     * 
     * @type {string}
     * @memberof GetFleetResponse
     */
    codigoEstructura?: string;
    /**
     * 
     * @type {string}
     * @memberof GetFleetResponse
     */
    codigoNegocio?: string;
    /**
     * 
     * @type {string}
     * @memberof GetFleetResponse
     */
    nombreNegocio?: string;
    /**
     * 
     * @type {string}
     * @memberof GetFleetResponse
     */
    codigoIBAN?: string;
    /**
     * 
     * @type {Array<Error>}
     * @memberof GetFleetResponse
     */
    errors?: Array<Error>;
}/**
 * 
 * @export
 * @interface GetFleetResponseTomador
 */
export interface GetFleetResponseTomador {
    /**
     * 
     * @type {string}
     * @memberof GetFleetResponseTomador
     */
    tipoDocumento?: GetFleetResponseTomadorTipoDocumentoEnum;
    /**
     * 
     * @type {string}
     * @memberof GetFleetResponseTomador
     */
    codigoIdentificacionFiscal?: string;
    /**
     * 
     * @type {string}
     * @memberof GetFleetResponseTomador
     */
    razonSocialMatriz?: string;
    /**
     * 
     * @type {string}
     * @memberof GetFleetResponseTomador
     */
    tipoViaDireccionLarga?: string;
    /**
     * 
     * @type {string}
     * @memberof GetFleetResponseTomador
     */
    nombreDireccionTomador?: string;
    /**
     * 
     * @type {string}
     * @memberof GetFleetResponseTomador
     */
    numeroDireccionTomador?: string;
    /**
     * 
     * @type {string}
     * @memberof GetFleetResponseTomador
     */
    informacionAdicional?: string;
    /**
     * 
     * @type {string}
     * @memberof GetFleetResponseTomador
     */
    codigoPostal?: string;
    /**
     * 
     * @type {string}
     * @memberof GetFleetResponseTomador
     */
    nombrePoblacionTomador?: string;
    /**
     * 
     * @type {string}
     * @memberof GetFleetResponseTomador
     */
    nombreProvinciaTomador?: string;
    /**
     * 
     * @type {string}
     * @memberof GetFleetResponseTomador
     */
    codigoFiliacion?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum GetFleetResponseTomadorTipoDocumentoEnum {
    C = 'C',
    E = 'E',
    N = 'N',
    P = 'P',
    R = 'R',
    S = 'S',
    X = 'X'
}


type getFleetOperationParams = {
  getFleetRequest: GetFleetRequest,
};

/**
 * GetFleetApi - axios parameter creator
 * @param {Configuration} [configuration]
 * @returns {any}
 */
const GetFleetApiAxiosParamCreator = function(configuration?: Configuration): any {
  return {
    
    /**
     * Devuelve la lista de marcas vehiculo disponibles
     * @param {GetFleetRequest} getFleetRequest 
     * @param {string} [acceptLanguage] El Accept-Language request HTTP header indica en que lenguaje debe responder el backend.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @returns {RequestArgs}
     */
    getFleetOperation(params: getFleetOperationParams, options: any = {}): RequestArgs {
      // Verify required parameter 'getFleetRequest' is not null or undefined
      if (params.getFleetRequest === null || params.getFleetRequest === undefined) {
        throw new RequiredError('getFleetRequest', 'Required parameter getFleetRequest was null or undefined when calling getFleetOperation.');
      }
      const localVarPath = `/v1/fleets/getFleetOperation`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true) as Partial<globalImportUrl.UrlWithParsedQuery>;
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;


  
      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
      // Fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
      const needsSerialization = ('GetFleetRequest' as any !== 'string') ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization ? JSON.stringify(params.getFleetRequest || {}) : params.getFleetRequest || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * GetFleetApi - functional programming interface
 * @param {Configuration} [configuration]
 * @returns {any}
 */
const GetFleetApiFp = function(configuration?: Configuration): any {
  return {
    
    /**
     * Devuelve la lista de marcas vehiculo disponibles
     * @param {GetFleetRequest} getFleetRequest 
     * @param {string} [acceptLanguage] El Accept-Language request HTTP header indica en que lenguaje debe responder el backend.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @returns {any}
     */
    getFleetOperation(params: getFleetOperationParams, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetFleetResponse> {
      const localVarAxiosArgs = GetFleetApiAxiosParamCreator(configuration).getFleetOperation(params, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH): any => {
        const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * GetFleetApi - factory interface
 * @param {Configuration} configuration
 * @param {string} basePath
 * @param {AxiosInstance} axios
 * @export
 * @returns {any}
 */
const GetFleetApiFactory = function(configuration?: Configuration, basePath?: string, axios?: AxiosInstance): any {
  return {
    
    /**
     * Devuelve la lista de marcas vehiculo disponibles
     * @param {GetFleetRequest} getFleetRequest 
     * @param {string} [acceptLanguage] El Accept-Language request HTTP header indica en que lenguaje debe responder el backend.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @returns {any}
     */
    getFleetOperation(params: getFleetOperationParams, options?: any): any {
      return GetFleetApiFp(configuration).getFleetOperation(params, options)(axios, basePath);
    },
  };
};

/** 
 * GetFleetApi - object-oriented interface
 * @export
 * @class GetFleetApi
 * @extends {BaseAPI}
 */
export class GetFleetApi extends BaseAPI {
  
  /**
   * Devuelve la lista de marcas vehiculo disponibles
   * @param {GetFleetRequest} getFleetRequest 
   * @param {string} [acceptLanguage] El Accept-Language request HTTP header indica en que lenguaje debe responder el backend.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @returns {any}
   * @memberof GetFleetApi
   */
  public getFleetOperation(params: getFleetOperationParams, options?: any): any {
    return GetFleetApiFp(this.configuration).getFleetOperation(params, options)(this.axios, this.basePath);
  }

}

/**
 * 
 * @export
 * @class EAgetFleetOperationInputMessage
 */
export class EAgetFleetOperationInputMessage {
  
  /**
   * 
   * @type {GetFleetRequest}
   * @memberof EAgetFleetOperationInputMessage
   */
  public getFleetRequest: GetFleetRequest;

  public constructor(getFleetRequest: GetFleetRequest, ){
  
  this.getFleetRequest=getFleetRequest;
  } 
}



/** 
 * EAGetFleetApi - Architecture client for getFleetOperation
 * @export
 * @class EAGetFleetApi
 */
export class EAGetFleetApi {

  /**
   * 
   * @param {GetFleetRequest} getFleetRequest
   
   * @throws {AsyncRequestError | RequiredError}
   * @returns {Promise<GetFleetResponse | null>}
  */ 
  public async getFleetOperation(params: getFleetOperationParams): Promise<GetFleetResponse | null> {
    return this.getFleetOperationPromise(params);
  }

  /**
   * 
   * @param {GetFleetRequest} getFleetRequest
   
   * @param {Function} callback
   * @throws {AsyncRequestError | RequiredError}
   */
  public async getFleetOperationSteps(params: getFleetOperationParams, callback: (res: AxiosResponse<GetFleetResponse | null>) => void) {
    let done: boolean = false;
    const httpClient: AxiosInstance = EASDKConfig.getHttpClient('/v1/fleets/getFleetOperation', 'getFleet', 'getFleetOperation');
    const config: Configuration = {};
    const api = new GetFleetApi(config, '', httpClient);
    const sleep = async(ms: number) => {
      return new Promise(resolve => setTimeout(resolve, ms));
    };
    const headers: { ['Async-Id']?: string } = {};
    while (!done) {
      const output = await api.getFleetOperation(params, { headers }) as AxiosResponse<GetFleetResponse | null>;
      callback(output);
      if (output.status === 202) {
        try {
          const retry = parseInt(output.headers['retry']);
          headers['Async-Id'] = output.headers['request-id'];
          if (isNaN(retry) || retry <= 0 || retry > 1000 * 3600) {
            throw new Error(`Retry time not acceptable (in milliseconds): ${retry}`);
          }
          await sleep(retry);
        } catch (err) {
          throw new Error(`Error in Async Request ${err}`);
        }
      } else {
        done = true;
      }
    }
  }

  /**
   * 
   * @param {GetFleetRequest} getFleetRequest
   
   * @throws {AsyncRequestError | RequiredError}
   * @returns {Promise<GetFleetResponse | null>}
   */
  public async getFleetOperationPromise(params: getFleetOperationParams): Promise<GetFleetResponse | null> {
    return new Promise((resolve, reject) => {
      this.getFleetOperationSteps(params, (response: AxiosResponse<GetFleetResponse | null>) => {
        if (response.status !== 202) {
          resolve(response.data);
        }
      })
      .catch((err) => {
        reject(err);
      });
    });
  } 
}


