import {
  EAGetConfigFlowApi
} from '@/services/V1/common/getConfigFlowOperation/post';
import {
  ResponseWithErrors, throwIfResponseHasErrors
} from '@zurich-es-npm/ea-front-web-core';
import {
  Store
} from 'vuex';

/**
 * Class to get flow configuration and handle it
 */
export class ConfigFlowUtils {

  /**
   * Get config flow from bff
   * @param {Store} store 
   */
  public static async getConfigFlow(store: Store<any>): Promise<object> {
    const activeFlow = store.getters['flow/getActiveFlow'];
    
    const api = new EAGetConfigFlowApi();
    const response = await api.getConfigFlowOperation({
      getConfigFlowRequest: {
        nombreFlujo: activeFlow.flow as string
      }
    });
  
    if (!response) {
      return {};
    }

    throwIfResponseHasErrors(response as unknown as ResponseWithErrors);
    return JSON.parse(response.configFlow as string);
  }

  /**
   * Get config flow from bff
   * @param {string} flowName 
   */
  public static async getConfigFlowByName(flowName: string): Promise<object> {
    const api = new EAGetConfigFlowApi();
    const response = await api.getConfigFlowOperation({
      getConfigFlowRequest: {
        nombreFlujo: flowName
      }
    });
  
    if (!response) {
      return {};
    }

    throwIfResponseHasErrors(response as unknown as ResponseWithErrors);
    return JSON.parse(response.configFlow as string);
  }
}
