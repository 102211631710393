<!-- eslint-disable max-lines -->
<template>
  <div>
    <ea-row extraClass="m-t-8">
      <ea-col>
        <qb-general-data-information
          v-if="isLoadingFinish"
          v-model="model.generalDataInformationModel"
          :datosCabecera="model.datosCabecera"
          id="qb-general-data-information"
          ref="generalDataComp"
          :consultaOperation="consultaOperation"
          :areDatesReadonly="areDatesReadonly"
          @updateParentView="update"
        ></qb-general-data-information>
      </ea-col>
    </ea-row>

    <qb-persons
      v-model="personsModel"
      id="qb-persons"
      ref="personsComp"
      :codigoRamo="productFactory.codigoRamo"
      :taxTreatmentAreaTable="model.taxTreatmentAreaTable"
      :documentTypeList="model.documentTypeList"
      :countryList="model.countryList"
      :consultaOperation="consultaOperation"
      :shouldShowAddresses="false"
      :allowedRoles="allowedRoles"
      :shouldFetchPersonIbanAddresses="false"
      :shouldShowNewSearchInShowPersonInfo="productFactory.shouldShowNewSearchInShowPersonInfo"
      :updateGeneralDataParty="false"
      :additionalElements="model.datosAdicionales"
      :policyElementsScore="getPolicyElementsScore()"
      :axesorSearchDocumentTypes="productFactory.axesorSearchDocumentTypes"
      @showError="showError"
      @handleGenericError="handleGenericError"
      @updateDocLanguage="updateDocLanguage"
      @changeTomador="onChangeTomador"
    ></qb-persons>

    <ea-row class="d-align-items-flex-end" extraClass="m-t-16" v-if="!isProductDataShown">
      <ea-col :span="12" class="d-display-flex d-justify-flex-start">
        <ea-button type="secondary" @click="onGoBack()" v-if="model.hasParentFlow">
          {{ $t('common.label.back') }}
        </ea-button>
      </ea-col>
      <ea-col :span="model.hasParentFlow ? 12 : 24" class="d-display-flex d-justify-flex-end">
        <ea-button
          type="primary"
          :disabled="consultaOperation || continueToProductDataBtnDisabled"
          @click="continueToProductData()"
        >
          {{ $t('common.label.next') }}
        </ea-button>
      </ea-col>
    </ea-row>

    <ea-row extraClass="m-t-16" v-if="isProductDataShown">
      <ea-col>
        <component
          v-model="model.productModel"
          id="objeto-aseguradoComponent-qb"
          ref="objetoAseguradoComp"
          :is="productFactory.objetoAseguradoComponent"
          :datosObjeto="datosObjeto"
          :datosAdicionales="model.datosAdicionales"
          :taxTreatmentArea="model.taxTreatmentArea"
          :consultaOperation="consultaOperation"
          @updateDates="onUpdateDates"
        />
      </ea-col>
    </ea-row>

    <ea-row class="d-align-items-flex-end" extraClass="m-t-8" v-if="isProductDataShown">
      <ea-col :span="12" class="d-display-flex d-justify-flex-start">
        <ea-button type="secondary" @click="onGoBack()" v-if="model.hasParentFlow">
          {{ $t('common.label.back') }}
        </ea-button>
      </ea-col>
      <ea-col :span="model.hasParentFlow ? 12 : 24" class="d-display-flex d-justify-flex-end">
        <ea-button
          type="primary"
          @click="continuetoNextStep()"
        >
          {{ $t('common.label.next') }}
        </ea-button>
      </ea-col>
    </ea-row>

  </div>
</template>

<script lang="ts">
import {
  Component, Prop
} from 'vue-property-decorator';

import {
  mixins
} from 'vue-class-component';

import {
  EAView,
  EAMethod,
  EAApplicationError,
  EAApplicationLogger,
  EAError,
  EAMultiError,
  EAValidationError,
  throwIfResponseHasErrors
} from '@zurich-es-npm/ea-front-web-core';

import {
  BffCodesFrontRelationModel,
  ErrorCodeFicoFic,
  FlowHeaderStepsModel,
  FlowViewsStepsModel,
  QuoteBuyModel,
} from '../quote-buy-model';

import {
  EAGetGeneralDataApi,
  GetGeneralDataRequestTipoOperacionOfertaEnum as OperationType,
  GetGeneralDataRequestCodigoRamoEnum,
  GetGeneralDataRequestTipoPolizaEnum,
  GetGeneralDataResponseDataDatosObjeto,
  GetGeneralDataResponseDataDatosPersonas,
  GetGeneralDataResponse,
  GetGeneralDataResponseDataDatosCabeceraElementosPoliza,
} from '@/services/V1/quoteAndBuy/getGeneralDataOperation/post';

import Utils from '@/utils/utils';
import ProductBase from '@/utils/quote-buy-product-factory/products/product-base';

import {
  EASaveOfferDataApi,
  SaveOfferDataRequest,
  SaveOfferDataRequestDatosPersonas,
  SaveOfferDataRequestDatosPersonasRolClienteEnum,
  SaveOfferDataRequestDatosPersonasTipoDocumentoEnum,
  SaveOfferDataRequestElementosPoliza,
  SaveOfferDataRequestTipoPolizaEnum,
  SaveOfferDataResponse
} from '@/services/V1/quoteAndBuy/saveOfferDataOperation/post';
import moment from 'moment';
import {
  Error
} from '@/services/V1/quoteAndBuy/saveOfferDataOperation/post/';

// eslint-disable-next-line max-len
import QbProductoAseguradoComunidadesBusiness from '@/business-components/qb-producto-asegurado-comunidades/qb-producto-asegurado-comunidades-business.vue';

// eslint-disable-next-line max-len
import QbGeneralDataInformationBusiness from '@/business-components/qb-general-data-information/qb-general-data-information-business.vue';
// eslint-disable-next-line max-len
import QbProductoAseguradoTrConstruccionMontajeBusiness from '@/business-components/qb-producto-asegurado-tr-construccion-montaje/qb-producto-asegurado-tr-construccion-montaje-business.vue';

import {
  fetchCorporateTable, CorpTableNames, parseCorpTableDocuments
} from '@/utils/corporate-tables';
import {
  GetPersonsResponseDatosPersona,
  GetPersonsResponseDatosPersonaDatosGeneralesPersonaSexoEnum,
  GetPersonsResponseListaPersonasTipoDocumentoEnum,
  GetPersonsResponseListaPersonasTipoPersonaEnum
} from '@/services/V1/quoteAndBuy/getPersonsOperation/post';
import {
  GenericErrorData
} from '@/business-components/qb-generic-error/qb-generic-error-business.vue';
import {
  NotificationsUtils
} from '@/utils/notifications/notifications-utils';
import {
  Roles
} from '@/types/roles/roles-enum.types';
import QbPersonsModel from '@/business-components/qb-persons/qb-persons-model';
import QbPersonsBusiness from '@/business-components/qb-persons/qb-persons-business.vue';
import PersonUtils from '@/utils/person-utils';
import QbProductoAseguradoHogarBusiness
  from '@/business-components/qb-producto-asegurado-hogar/qb-producto-asegurado-hogar-business.vue';
import {
  FormValues
} from '@/business-components/qb-technical-considerations/qb-technical-considerations-model';
import {
  returnElementByCode,
} from '@/utils/object-utils';
import {
  PersonRole
} from '@/utils/quote-buy-product-factory/types/product-role-types';

@Component({
  components: {
    QbPersons: QbPersonsBusiness,
    QbGeneralDataInformation: QbGeneralDataInformationBusiness,
    QbProductoAseguradoComunidades: QbProductoAseguradoComunidadesBusiness,
    QbProductoAseguradoTrConstruccionMontaje: QbProductoAseguradoTrConstruccionMontajeBusiness,
    QbProductoAseguradoHogar: QbProductoAseguradoHogarBusiness
  },
})

/**
 * Quote-buy GeneralData view
 *
 */
export default class GeneralDataView extends mixins<EAView<QuoteBuyModel>>(EAView) {
  @Prop({
    required: true,
  })
    productFactory!: ProductBase;
    
  datosObjeto?: GetGeneralDataResponseDataDatosObjeto[] = [];
    
  datosAdicionales?: GetGeneralDataResponseDataDatosObjeto[] = [];

  elementosPoliza?: GetGeneralDataResponseDataDatosCabeceraElementosPoliza[] = [];

  isLoadingFinish: boolean = false;

  personsModel: QbPersonsModel = new QbPersonsModel();

  allowedRoles: Roles[] = [];

  /**
   * Checks if the operation type is of type maintenence
   */
  get consultaOperation() {
    return this.model.operationType === OperationType.CONSULTA;
  }

  /**
   * Checks if the dates from general-data-info must be readonly
   */
  get areDatesReadonly() {
    return this.productFactory.shouldReadonlyDatesInGeneralDataInfo && this.model.isProductDataShown;
  }

  /**
   * Hook on created
   *
   * @returns {Promise<void>}
   */
  @EAMethod({
    loading: true
  })
  public async created(): Promise<void> {
    this.allowedRoles = this.productFactory.allowedRolesAtGeneralDataView;
    this.getPersonRoles();
    await this.fetchGeneralData();

    if (!this.model.documentTypeList.length || !this.model.countryList.length) {
      await this.fetchDropdownData();
    }

    if (!this.consultaOperation) {
      this.personsModel.personRoles.forEach((person: { disabled: boolean }) => {
        person.disabled = false;
      });
    }

    PersonUtils.updatePersonsCanBeChangedFlag(
      this.personsModel.personRoles, this.elementosPoliza, this.datosObjeto
    );
    
    this.productFactory.setDefaultPaymentChannel(this.model.generalDataInformationModel);
  
    this.update();
    await this.$nextTick(); // Wait for model to be setted
    this.isLoadingFinish = true;
  }

  /**
   * Get tabItems data
   */
  getPersonRoles() {
    this.personsModel.personRoles = this.productFactory.personRoles;
    const asegurado = this.productFactory.personRoles.find(person => person.role === Roles.Asegurado);
 
    if (asegurado && this.$router.currentRoute.path.includes('quote-buy-inquiry')) {
      asegurado.roleEqualStatements?.forEach(equal => {
        equal.readonly = true;
      });
    }
  }

  /**
   * Method run in created() hook
   *
   * @returns {Promise<void>}
   */
  async fetchGeneralData(): Promise<void> {
    if (!this.model.offerNumber || this.model.offerVersion === undefined) {
      return;
    }

    const api = new EAGetGeneralDataApi();
    const generalData = await api.getGeneralDataOperation({
      getGeneralDataRequest: {
        codigoPoliza: this.model.offerNumber,
        versionPoliza: this.model.offerVersion,
        tipoPoliza: GetGeneralDataRequestTipoPolizaEnum.Incompleta,
        codigoRamo: this.productFactory.codigoRamo as unknown as GetGeneralDataRequestCodigoRamoEnum,
        tipoOperacionOferta: this.model.operationType,
      },
    });
      
    if (!generalData) {
      return;
    }

    if (generalData?.errors?.length) {
      this.showReceivedErrors(generalData.errors);
    }
    this.elementosPoliza = generalData.data?.datosCabecera?.elementosPoliza;
    this.datosObjeto = generalData?.data?.datosObjeto || [];
    
    const asegurado = this.getRiskElementByCode('SAVEDRISK');
    this.model.isProductDataShown = asegurado?.valorElemento === 'S';

    this.loadHeaderData(generalData);
    await this.setPersonsData(generalData.data?.datosPersonas);
    this.loadTaxTreatmentData();

    this.saveAdditionalValues();
    this.saveOfferIssuanceAdditionalData();
    this.datosAdicionales = generalData?.data?.datosAdicionales || [];

    this.model.datosCabecera.indicadorDataQuality = generalData.data?.datosCabecera?.indicadorDataQuality;
    this.update();
  }

  /**
   * Saves received cession rights | loan number values in model
   */
  saveAdditionalValues() {
    const cessionRights = this.getRiskElementByCode('NOTITCES');
   
    if (cessionRights) {
      const findCessionRights = this.model.offerIssuanceAdditionalDataFormModel.additionalData.find(
        elem => elem.codigoElemento === cessionRights.codigoElemento
      );

      if (!findCessionRights) {
        this.model.offerIssuanceAdditionalDataFormModel.additionalData.push(cessionRights);
      }
    }

    const loanNumber = this.getRiskElementByCode('NOCUECES');
    if (loanNumber) {
      const findLoanNumber = this.model.offerIssuanceAdditionalDataFormModel.additionalData.find(
        elem => elem.codigoElemento === loanNumber.codigoElemento
      );
      if (!findLoanNumber) {
        this.model.offerIssuanceAdditionalDataFormModel.additionalData.push(loanNumber);
      }
    }
  }

  /**
   * Shows received errors
   * Error if severity > 4
   * Warning if severity <= 4
   * @param {Error[]} errors
   */
  showReceivedErrors(errors: Error[]): void {
    const highSeverityErrors = errors?.filter(error => error.severity > 4);
    throwIfResponseHasErrors({
      errors: highSeverityErrors
    });
    const lowSeverityErrors = errors?.filter(error => error.severity <= 4);
    lowSeverityErrors?.forEach(error => {
      NotificationsUtils.throwWarning(error.message);
    });
  }

  /**
   * Sets persons data
   * It makes persons component to call addresses and iban services under same spinner
   * @param {GetGeneralDataResponseDataDatosPersonas[] | undefined} personsData
   */
  public async setPersonsData(personsData?: GetGeneralDataResponseDataDatosPersonas[]) {
    if (!personsData) {
      return;
    }
    
    const personsComp: QbPersonsBusiness = this.$refs.personsComp as QbPersonsBusiness;
    await personsComp.setPersonsFromGeneralData(personsData);
  }

  /**
   * Load header data mapping the correspondient fields
   * @param {GetGeneralDataResponse} generalData
   */
  loadHeaderData(
    generalData: GetGeneralDataResponse
  ): void {
    if (generalData?.data?.datosCabecera) {
      this.model.datosCabecera = generalData?.data?.datosCabecera;
      this.model.datosAdicionales = generalData?.data?.datosAdicionales || [];
      this.model.movementTypeCode = this.model.datosCabecera?.datosPoliza?.codigoTipoMovimiento || '';
      const durationMovement = this.getPolicyElementByCode('DUCONTRA');
      if (durationMovement) {
        this.model.generalDataInformationModel.durationValue = durationMovement.valorElemento as string;
      }
      // TODO esto hay que quitarlo después de la salida a producción
      this.$emit('codigoTipoMovimiento');
    }
  }

  /**
   * Saves in model received tax treatment information
   * It will be used in search-person component
   */
  loadTaxTreatmentData() {
    const taxTreatmentElement = this.getPolicyElementByCode('TCINFISC');

    if (taxTreatmentElement) {
      const personsComp: QbPersonsBusiness = this.$refs.personsComp as QbPersonsBusiness;
      personsComp.setTomadorPersonTaxTreatmentArea(taxTreatmentElement?.valorElemento || '');
      
      this.model.taxTreatmentAreaTable = Utils.sortObjectArrayByProperty(
        taxTreatmentElement.tablaRestricciones || [], 'nombreRestriccion'
      );
    }
  }

  /**
   * Returns the parsed data of the selected person
   * @param {GetGeneralDataResponseDataDatosPersonas} selectedPerson
   * @returns {GetPersonsResponseDatosPersona}
   */
  getSelectedPerson(selectedPerson: GetGeneralDataResponseDataDatosPersonas): GetPersonsResponseDatosPersona {
    const tipoDocumento =
      selectedPerson.tipoDocumento ? `${selectedPerson.tipoDocumento}` as
      GetPersonsResponseListaPersonasTipoDocumentoEnum : undefined;
    const tipoPersona =
      selectedPerson.tipoPersona ? `${selectedPerson.tipoPersona}` as
      GetPersonsResponseListaPersonasTipoPersonaEnum : undefined;

    return {
      datosBasicosPersona: {
        codigoFiliacion: selectedPerson.codigoFiliacion,
        codigoIdentificacionFiscal: selectedPerson.codigoIdentificacionFiscal,
        nombrePropio: selectedPerson.nombrePropio,
        primerApellido: selectedPerson.primerApellido,
        segundoApellido: selectedPerson.segundoApellido,
        tipoDocumento,
        tipoPersona,
      },
      datosGeneralesPersona: {
        fechaNacimiento: selectedPerson.fechaNacimiento,
        codigoResidencia: selectedPerson.nacionalidadPersona,
        sexo: selectedPerson.sexo as
        unknown as
        GetPersonsResponseDatosPersonaDatosGeneralesPersonaSexoEnum
      }
    };
  }

  /**
   * Return risk element by code (Datos objeto)
   * @param {string} code
   * @returns {GetGeneralDataResponseDataDatosObjeto  | undefined}
   */
  getRiskElementByCode(code: string): GetGeneralDataResponseDataDatosObjeto | undefined {
    return this.datosObjeto?.find(element => element.codigoElemento === code);
  }

  /**
   * Return policy element by code (Datos cabecera)
   * @param {string} code
   * @returns {GetGeneralDataResponseDataDatosCabeceraElementosPoliza  | undefined}
   */
  getPolicyElementByCode(code: string): GetGeneralDataResponseDataDatosObjeto | undefined {
    return this.model.datosCabecera.elementosPoliza?.find(element => element.codigoElemento === code);
  }

  /**
   * Handles tax treatment area change
   * @param {string} value - Updated tax treatment area
   */
  updateTaxTreatmentArea(value: string) {
    this.model.taxTreatmentArea = value;
    this.update();
  }

  /**
   * Validates general data component's form (HEADER DATA)
   * If validation OK => Updates model
   * If validation NOK => Throws EAValidationError
   */
  async validateUpdateGeneralDataComp() {
    const generalDataComp: QbGeneralDataInformationBusiness =
    this.$refs.generalDataComp as QbGeneralDataInformationBusiness;
    try {
      await generalDataComp.validation().validate();
    } catch (error) {
      throw new EAValidationError(this.$t('common.label.validation.formWithErrors').toString());
    }
    generalDataComp.update();
  }
  
  /**
   * Validates persons component's form
   * If validation OK => Updates model
   * If validation NOK => Throws EAValidationError
   */
  async validateUpdatePersonsComponent() {
    const personsComp: QbPersonsBusiness = this.$refs.personsComp as QbPersonsBusiness;
    try {
      await personsComp.validateUpdatePersonsComponent();
      this.update();
    } catch (error) {
      throw new EAValidationError(this.$t('common.label.validation.formWithErrors').toString());
    }
  }

  /**
   * Validates objeto asegurado data component's form
   * If validation OK => Updates model
   * If validation NOK => Throws EAValidationError
   */
  async validateUpdateObjetoAseguradoComp() {
    const aseguradoComp = this.$refs.objetoAseguradoComp as QbProductoAseguradoComunidadesBusiness;
    aseguradoComp.updateAllData();
    try {
      await aseguradoComp.validation().validate();
    } catch (error) {
      if (error instanceof EAApplicationError) {
        throw error;
      }
      throw new EAValidationError(this.$t('common.label.validation.formWithErrors').toString());
    }
  }

  /**
   * Clear notifications type errors or warning
   */
  clearErrorsNotifications() {
    NotificationsUtils.clearNotifications();
  }

  /**
   * Gets person fields in Bff format
   * @param {GetPersonsResponseDatosPersona} person
   * @param {SaveOfferDataRequestDatosPersonasRolClienteEnum} personRole
   * @returns {SaveOfferDataRequestDatosPersonas}
   */
  getPersonFieldsToSave(
    person: GetPersonsResponseDatosPersona, personRole: SaveOfferDataRequestDatosPersonasRolClienteEnum
  ): SaveOfferDataRequestDatosPersonas {
    const result = {
      rolCliente: personRole,
      codigoFiliacion: person?.datosBasicosPersona?.codigoFiliacion,
      tipoPersona: Utils.getPersonType(person),
      codigoIdentificacionFiscal: person?.
        datosBasicosPersona?.codigoIdentificacionFiscal,
      primerApellido: person?.datosBasicosPersona?.primerApellido,
      segundoApellido: person?.datosBasicosPersona?.segundoApellido,
      nombrePropio: person?.datosBasicosPersona?.nombrePropio,
      tipoDocumento: person?.
        datosBasicosPersona?.tipoDocumento as unknown as SaveOfferDataRequestDatosPersonasTipoDocumentoEnum,
      indicadorFiscal: PersonUtils.getPersonTaxTreatmentArea(this.personsModel.personRoles, personRole)
    };

    return result;
  }

  /**
   * Gets Asegurado fields in Bff format
   * @returns {SaveOfferDataRequestElementosPoliza[] }
   */
  getCabeceraFieldsToSave(): SaveOfferDataRequestElementosPoliza[] {
    const iniDate = this.model.generalDataInformationModel.movementEfect ?
      moment(this.model.generalDataInformationModel.movementEfect).format('YYYYMMDD') : '';
    const endData = this.model.generalDataInformationModel.movementDue ?
      moment(this.model.generalDataInformationModel.movementDue).format('YYYYMMDD') : '';

    const tomador = PersonUtils.getPersonByRole(this.personsModel.personRoles, Roles.Tomador);

    const cabeceraFields = [
      {
        codigoElemento: 'FEEFTOMO',
        valorElemento: iniDate
      }, {
        codigoElemento: 'FEEFTONA',
        valorElemento: iniDate
      }, {
        codigoElemento: 'FEINIPOL',
        valorElemento: iniDate
      }, {
        codigoElemento: 'FEFINEFE',
        valorElemento: endData
      }, {
        codigoElemento: 'FEVENCIM',
        valorElemento: endData
      }, {
        codigoElemento: 'TCCANCO1',
        valorElemento: this.model.generalDataInformationModel.paymentChannel
      }, {
        codigoElemento: 'DUCONTRA',
        valorElemento: this.model.generalDataInformationModel.durationValue
      }, {
        codigoElemento: 'TCINFISC',
        valorElemento: tomador?.taxTreatmentArea
      }, {
        codigoElemento: 'INIDIOMA',
        valorElemento: this.model.generalDataInformationModel.documentationLanguage,
      },
      // Header score elements
      {
        codigoElemento: 'NOPOBTOM',
        valorElemento: tomador?.showPersonInfoModel.postalCodeModel.city
      },
      {
        codigoElemento: 'TCPOBTOM',
        valorElemento: tomador?.showPersonInfoModel.postalCodeModel.cityCode
      },
      {
        codigoElemento: 'TCCOPTOM',
        valorElemento: tomador?.showPersonInfoModel.postalCodeModel.zipCode
      }
    ];

    return this.filterExcludeNonModificableElements(cabeceraFields);
  }

  /**
   * Filters those fields that are modificable from receivedFields array
   * @param {SaveOfferDataRequestElementosPoliza[] | undefined} receivedFields
   * @param {boolean | undefined} riskElement
   * @returns {SaveOfferDataRequestElementosPoliza[]}
   */
  filterExcludeNonModificableElements(
    receivedFields?: SaveOfferDataRequestElementosPoliza[],
    riskElement?: boolean
  ): SaveOfferDataRequestElementosPoliza[] {
    if (!receivedFields) {
      return [];
    }
    
    return receivedFields.filter(field => {
      if (!field.codigoElemento) {
        return false;
      }

      const policyElement = riskElement ?
        this.getRiskElementByCode(field.codigoElemento) : this.getPolicyElementByCode(field.codigoElemento);

      if (!policyElement) {
        return false;
      }

      const modificable = policyElement.elementoModificable;

      return modificable === undefined || modificable === true;
    });
  }
 
  /**
   * Handles continue button
   * @param {boolean} doSaveObjetoAsegurado - flag to indicate if we want to save objeto asegurado data
   * @param {Function} callbackIfOk - Function to be called if service call returns OK result
   */
  async saveOfferData(doSaveObjetoAsegurado: boolean, callbackIfOk: Function) {
    this.clearFlowErrors();

    await this.validateUpdateGeneralDataComp();
    await this.validateUpdatePersonsComponent();

    if (doSaveObjetoAsegurado) {
      await this.validateUpdateObjetoAseguradoComp();
      this.update();
    }

    this.productFactory.personRoles = this.personsModel.personRoles;

    const selectedPersonTomadorData = PersonUtils.getPersonDataByRole(this.personsModel.personRoles, Roles.Tomador);
    const selectedPersonAseguradoData = PersonUtils.getPersonDataByRole(
      this.personsModel.personRoles, Roles.Asegurado
    );
    
    this.clearErrorsNotifications();

    const saveOfferDataRequest: SaveOfferDataRequest = {
      codigoPoliza: this.model.offerNumber,
      versionPoliza: this.model.offerVersion,
      tipoPoliza: this.model.offerType as unknown as SaveOfferDataRequestTipoPolizaEnum,
      guardarDatosObjeto: doSaveObjetoAsegurado,
      datosPersonas: [
        this.getPersonFieldsToSave(selectedPersonTomadorData, SaveOfferDataRequestDatosPersonasRolClienteEnum.Tomador),
        this.getPersonFieldsToSave(
          selectedPersonAseguradoData, SaveOfferDataRequestDatosPersonasRolClienteEnum.Asegurado
        )
      ],
      elementosPoliza: this.getCabeceraFieldsToSave()
    };

    const tomador = PersonUtils.getPersonByRole(this.personsModel.personRoles, Roles.Tomador) as PersonRole;

    saveOfferDataRequest.elementosAdicionales =
      this.productFactory.generateAditionalOfferData(
        tomador,
        this.model.datosAdicionales,
        this.model,
        doSaveObjetoAsegurado
      );

    if (doSaveObjetoAsegurado) {
      saveOfferDataRequest.elementosObjeto = this.filterExcludeNonModificableElements(
        this.productFactory.generateOfferData(this.model), true
      );
    }

    this.update();

    const resp = await this.callSaveOfferDataBff(saveOfferDataRequest);
    if (resp) {
      this.model.ficoFicError = [];
      if (resp.errors) {
        const errrorFIC =
          resp.errors.filter(err => err.code === ErrorCodeFicoFic.Fico || err.code === ErrorCodeFicoFic.Fic);

        if (errrorFIC.length > 0) {
          // Update the model to show the fic - fico error in offer-issuance
          errrorFIC.forEach(err => this.model.ficoFicError.push(err.code));
          this.update();
        }
        // Clear the FICO - FIC warnigns
        const errorsWithoutFICO = resp.errors.filter(err => err.severity >= 4);
        if (errorsWithoutFICO.length > 0) {
          const multiError = new EAMultiError();
          errorsWithoutFICO.forEach(
            (error: Error) => multiError.push(new EAValidationError(this.translateError(error))));
          throw multiError;
        }
        callbackIfOk();
      } else {
        callbackIfOk();
      }
    }
  }

  /**
   * Clear ficoFicError when change tomador
   */
  onChangeTomador() {
    this.model.ficoFicError = [];
    this.update();
  }

  /**
   * @param {SaveOfferDataOperationParams} saveOfferDataRequest
   * @returns {Promise<SaveOfferDataResponse | null>}
   */
  @EAMethod({
    loading: true
  })
  async callSaveOfferDataBff(
    saveOfferDataRequest: SaveOfferDataRequest
  ): Promise<SaveOfferDataResponse | null> {
    const api = new EASaveOfferDataApi();
    return api.saveOfferDataOperation({
      saveOfferDataRequest
    });
  }

  /**
   * Translates error codes into legible ones
   * @param {Error} error - Bff received error
   * @returns {string} translated errors
   */
  translateError(error: Error): string {
    const splitted = error.message.split(':');

    if (splitted.length < 2) {
      return error.message;
    }
    
    const codeRelation = this.model.productModel.bffCodesFrontRelation.find(
      (rel: BffCodesFrontRelationModel) => rel.bffCode === splitted[1].trim()
    );

    if (codeRelation) {
      const frontCodeTranslated = this.$t(codeRelation.frontCode);
      const errorMessageTranslated = this.$t(`common.label.validation.${splitted[2]}`);
      error.message = `${splitted[0]} => ${frontCodeTranslated}: ${errorMessageTranslated}`;
    }

    return error.message;
  }

  /**
   * Saves offer data (header, tomador and asegurado) and continue to product data section
   */
  async continueToProductData() {
    const callbackFn = () => {
      this.model.isProductDataShown = true;
      this.update();
    };
    await this.saveOfferData(false, callbackFn);
  }

  /**
   * Save and continue to warranties step
   */
  async continuetoNextStep() {
    const callbackFn = () => this.$emit(
      'changeStep', FlowViewsStepsModel.WarrantiesStep, FlowHeaderStepsModel.WarrantiesStep);
    if (this.consultaOperation) {
      callbackFn();
    } else {
      await this.saveOfferData(true, callbackFn);
    }
  }

  /**
   * Handles back button
   */
  onGoBack() {
    this.$emit('goToPreviousFlow');
  }

  /**
   * Fetch documentType and country dropdown data
   * @returns {Promise<void>}
   */
  @EAMethod({
    loading: true,
  })
  public async fetchDropdownData(): Promise<void> {

    try {
      const promises = [
        fetchCorporateTable(CorpTableNames.DocumentTypes),
        fetchCorporateTable(CorpTableNames.Countries),
      ];
      const results = await Promise.all(promises);

      this.model.documentTypeList = parseCorpTableDocuments(results[0]);
      this.model.countryList = parseCorpTableDocuments(results[1]);

      this.update();
    } catch (err) {
      const loggerErrorMessages: string[] = [];
      let errors: EAError[] = [];

      // eslint-disable-next-line no-extra-parens
      if (typeof err.getErrorList === 'function') {
        // eslint-disable-next-line no-extra-parens
        errors = (err as EAMultiError).getErrorList() as EAError[];
      } else {
        errors = [err as EAError];
      }

      errors.forEach(error => {
        loggerErrorMessages.push(`QbSearchPersonBusiness::performSearchPerson ${error.message}`);
      });

      const logger = new EAApplicationLogger();
      loggerErrorMessages.forEach(message => {
        logger.error(message);
      });

      throw new EAApplicationError('ZON00031', loggerErrorMessages);
    }
  }

  /**
   * Updates received doc language
   * @param {string} receivedDocLanguage
   */
  updateDocLanguage(receivedDocLanguage: string) {
    this.model.generalDataInformationModel.documentationLanguage = receivedDocLanguage;
  }

  /**
   * Updates date from general data info with the dates from qb-technical-considerations for trc
   * @param {FormValues} formValues
   */
  public onUpdateDates(formValues: FormValues) {
    this.model.generalDataInformationModel.movementEfect = formValues.startDate;
    this.model.generalDataInformationModel.movementDue = formValues.endDate;
  }

  /**
   * Saves in model data that will be used in offer issuance step
   */
  saveOfferIssuanceAdditionalData(): void {
    this.model.cessionRights = returnElementByCode('NOTITCES', this.datosObjeto);
    this.model.loanNumber = returnElementByCode('NOCUECES', this.datosObjeto);
  }


  // ### GETTERS ### //

  /**
   * Gets if product data section is shown
   * @returns {boolean} true if it is shown; false otherwise
   */
  get isProductDataShown() {
    return this.model.isProductDataShown && this.datosObjeto?.length;
  }

  /**
   * Gets is continue to product data button is disabled
   * @returns {boolean}
   */
  get continueToProductDataBtnDisabled() {
    const tomador = PersonUtils.getPersonByRole(this.personsModel.personRoles, Roles.Tomador);
    const asegurado = PersonUtils.getPersonByRole(this.personsModel.personRoles, Roles.Asegurado);
    return !tomador?.searchModel.selectedPerson?.datosBasicosPersona?.codigoFiliacion ||
      !asegurado?.searchModel.selectedPerson?.datosBasicosPersona?.codigoFiliacion;
  }

  /**
   * Gets policy elements for score
   * @returns {GetGeneralDataResponseDataDatosCabeceraElementosPoliza[]}
   */
  getPolicyElementsScore(): GetGeneralDataResponseDataDatosCabeceraElementosPoliza[] {
    const elements = ['NOPOBTOM', 'TCPOBTOM', 'TCCOPTOM'];
    return this.model.datosCabecera.elementosPoliza?.filter(
      elem => elem.codigoElemento && elements.includes(elem.codigoElemento)
    ) || [];
  }
  
  // ### EVENT EMITTERS ### //

  /**
   * Emits showError event
   * @param {GenericErrorData} genericErrorData
   */
  showError(genericErrorData: GenericErrorData) {
    this.$emit('showError', genericErrorData);
  }

  /**
   * Emits handleGenericError event
   * @param {any} args
   */
  handleGenericError(args: any) {
    const {
      error, errorCode
    } = args;
    this.$emit('handleGenericError', {
      error,
      errorCode,
    });
  }
}
</script>

<style scoped lang="scss">
  .hidden {
    display: none;
  }
</style>
