import {
  EAContextManager,
  EAEventBus,
  ResponseWithErrors,
  throwIfResponseHasErrors
} from '@zurich-es-npm/ea-front-web-core';

export enum NotificationsTypeEnum {
  Success = 'Success',
  Warning = 'Warning',
  Info = 'Info',
  Error = 'Error',
}

/**
 * 
 * @export
 * @interface ZZNotification
 */
export interface ZZNotification {
  title: string;
  message: string;
  type: NotificationsTypeEnum;
}

/**
 * 
 * @export
 * @interface Warning
 */
export interface Warning {
  code?: string;
  title?: string;
  message: string;
  severity?: number;
}

export const SEND_NOTIFICATIONS_EVENT = 'sendNotifications_';
export const SEND_NOTIFICATIONS_EVENT_IN_MODAL = '_modal';

/**
 * NotificationsUtils helps develop to show users notifications
 */
export class NotificationsUtils {

  /**
   * Launch notification
   * @param {ZZNotification[]} notifications
   * @param {string | undefined} targetFlowId
   * @param {boolean} isInModal
   */
  public static launchNotifications(notifications: ZZNotification[], targetFlowId?: string,
    isInModal: boolean = false): void {
    if (notifications.length) {
      scrollTo(0, 0);
      const flowId = targetFlowId || this.getCurrentFlowId();
      // eslint-disable-next-line max-len
      EAEventBus.$emit(`${SEND_NOTIFICATIONS_EVENT}${flowId}${isInModal? SEND_NOTIFICATIONS_EVENT_IN_MODAL: ''}`, notifications);
    }
  }

  /**
   * Clear all notifications
   * @param {string | undefined} targetFlowId
   * @param {boolean} isInModal
   */
  public static clearNotifications(targetFlowId?: string, isInModal: boolean = false): void {
    const flowId = targetFlowId || this.getCurrentFlowId();
    EAEventBus.$emit(`${SEND_NOTIFICATIONS_EVENT}${flowId}${isInModal? SEND_NOTIFICATIONS_EVENT_IN_MODAL: ''}`, []);
  }

  /**
   * Filters errors / warning of responseWithErrors
   * @param {ResponseWithErrors} response
   * @param {boolean | undefined} showLowSeverityError
   * @param {string | undefined} targetFlowId
   * @param {boolean} isInModal
   * @returns {number} # of notifications launched
   */
  public static comprobeErrors(
    response: ResponseWithErrors,
    showLowSeverityError?: boolean,
    targetFlowId?: string,
    isInModal: boolean = false
  ): number {
    let notificationsLaunched = 0;
    if (showLowSeverityError) {
      const warnings = response.errors?.filter(error => error.severity < 4 && error.severity > 1);
      const warningAsNotification: ZZNotification[] = [];
      warnings?.forEach(warning => {
        warningAsNotification.push({
          type: NotificationsTypeEnum.Warning,
          message: warning.message,
          title: NotificationsTypeEnum.Warning
        });
        notificationsLaunched++;
      });
      NotificationsUtils.launchNotifications(warningAsNotification, targetFlowId, isInModal);
    }
    const errors = response.errors?.filter(error => error.severity > 3);
    if (!isInModal) {
      throwIfResponseHasErrors({
        errors
      });
    }
    const errorAsNotification: ZZNotification[] = [];
    errors?.forEach(error => {
      errorAsNotification.push({
        type: NotificationsTypeEnum.Error,
        message: error.message,
        title: NotificationsTypeEnum.Error
      });
      notificationsLaunched++;
    });
    NotificationsUtils.launchNotifications(errorAsNotification, targetFlowId, isInModal);
    return notificationsLaunched;
  }

  /**
   * Calls notifications utils to launch a warning notification with a message
   * @param {string} message
   * @param {string | undefined} targetFlowId
   * @param {boolean} isInModal
   */
  public static throwWarning(message: string, targetFlowId?: string, isInModal: boolean = false): void {
    const notifications: ZZNotification[] = [
      {
        title: NotificationsTypeEnum.Warning,
        message,
        type: NotificationsTypeEnum.Warning,
      }];
    this.launchNotifications(notifications, targetFlowId, isInModal);
  }

  /**
   * Calls notifications utils to launch a success notification with a message
   * @param {string} message
   * @param {string | undefined} targetFlowId
   * @param {boolean} isInModal
   */
  public static throwSuccess(message: string, targetFlowId?: string, isInModal: boolean = false): void {
    const notifications: ZZNotification[] = [
      {
        title: NotificationsTypeEnum.Success,
        message,
        type: NotificationsTypeEnum.Success,
      }];
    this.launchNotifications(notifications, targetFlowId, isInModal);
  }

  /**
   * Calls notifications utils to launch a information notification with a message
   * @param {string} message
   * @param {string | undefined} targetFlowId
   * @param {boolean} isInModal
   */
  public static throwInfo(message: string, targetFlowId?: string, isInModal: boolean = false): void {
    const notifications: ZZNotification[] = [
      {
        title: NotificationsTypeEnum.Info,
        message,
        type: NotificationsTypeEnum.Info,
      }];
    this.launchNotifications(notifications, targetFlowId, isInModal);
  }

  /**
   * Returns the curront flow id.
   * @returns {string}
   */
  public static getCurrentFlowId(): string {
    return EAContextManager.getInstance().getFlowContext()?.activeFlow?.flowId || '';
  }

  /**
   * Returns if given `flowId` is the same than current flow id.
   * 
   * @param {string} flowId 
   * @returns {boolean} if current flow is the same than given by `flowId` param
   */
  public static isCurrentFlowSameThan(flowId: string): boolean {
    const currentFlowId = this.getCurrentFlowId();
    return currentFlowId === flowId;
  }

}
