/* eslint-disable @typescript-eslint/naming-convention */
import {
  AnalyticsApi, EAApplicationLogger
} from '@zurich-es-npm/ea-front-web-core';
import {
  EventPayload
} from '@zurich-es-npm/ea-front-web-core/lib/infrastructure/analytics/api/event';
import Vue from 'vue';
import {
  snakeCase
} from 'lodash';

enum EventName {
  VirtualPage = 'page_view',          // Click in multipoeration tab
  MenuClick = 'menu_click',           // Click menu item
  CountersDetails = 'details_click',  // Counters "Ver detalles" click
  OutboundLink = 'outbound_link',     // Contacts 'tel' and 'mailto' links click
  AllContacts = 'contact_click',      // Contacts "Ver todos" click
  LinkClick = 'link_click',           // 'Outstanding Operative' and 'Links Interest' element click
  LinksInterestAll = 'link_click',    // Links of interest "Ver mas" click
  NewOfferButton = 'new_offer_click', // "Nueva oferta" home button click
  NewOfferLink = 'offer_click',       // Click in a product inside "Oferta y Emision" page
  Logout = 'logout_submit',           // User logout
  SessionExpired = 'session_expired'  // User session expired
}

interface EventParameters {
  event_name: EventName;  // Nombre del evento
  element?: string;       // Ruta de los elementos seleccionados en el menu
  link_text?: string;     // Texto del enlace clicado
  link_type?: string;     // Tipo de enlace clicado
  link_url?: string;      // Ruta del enlace clicado
  location?: string;      // Ubicacion del elemento sobre el que se clica
  page_title?: string;    // Titulo de la pagina (texto de la tab multiperacion seleccionada)
}

const UserTypes: {[key: string]: string} = {
  COR: 'corredor',
  AGE: 'agente',
  EMP: 'empleado',
  PAU: 'partner automotive',
  PAF: 'partner affinities',
  PBC: 'partner bancasurance',
};

type InsuranceType = 'GI' | 'Vida';

export type NewOfferCardName = 'Particulares' | 'Empresas' | 'CommercialInsurance';

const EVENT_NAME = 'ga4_event';

/**
 * Utilities to send analytics events (GTM)
 */
export class AnalyticsEvents {

  /**
   * Send virtual page opened event to GTM
   * @param {string} flowTitle virtual page (flow) title
   */
  public static virtualPage(flowTitle: string): void {
    if (flowTitle && flowTitle.trim().length) {
      AnalyticsEvents.sendEvent({
        event_name: EventName.VirtualPage,
        page_title: snakeCase(flowTitle)
      });
    }
  }

  /**
   * Send counters 'See details' event to GTM
   * @param {string} cardTitle card title
   */
  public static seeDetails(cardTitle: string): void {
    if (cardTitle && cardTitle.trim().length) {
      AnalyticsEvents.sendEvent({
        event_name: EventName.CountersDetails,
        element: undefined,
        link_text: undefined,
        link_type: undefined,
        link_url: undefined,
        location: snakeCase(cardTitle)
      });
    }
  }

  /**
   * Send contact telephone link event to GTM
   * @param {string} hrefContent href content (tel: 666123456)
   */
  public static contactPhone(hrefContent: string): void {
    if (hrefContent && hrefContent.trim().length) {
      AnalyticsEvents.sendEvent({
        event_name: EventName.OutboundLink,
        element: undefined,
        link_text: undefined,
        link_type: 'phone number',
        link_url: hrefContent,
        location: undefined
      });
    }
  }

  /**
   * Send contact email link event to GTM
   * @param {string} hrefContent href content (mailto: mail@zurich.es)
   */
  public static contactEmail(hrefContent: string): void {
    if (hrefContent && hrefContent.trim().length) {
      AnalyticsEvents.sendEvent({
        event_name: EventName.OutboundLink,
        element: undefined,
        link_text: undefined,
        link_type: 'email address',
        link_url: hrefContent,
        location: undefined
      });
    }
  }

  /**
   * Send contacts 'See All' event to GTM
   */
  public static contactsSeeAll(): void {
    AnalyticsEvents.sendEvent({
      event_name: EventName.AllContacts,
      element: undefined,
      link_text: 'Ver todos',
      link_type: undefined,
      link_url: undefined,
      location: 'Contactos Zurich'
    });
  }

  /**
   * Send link click from Outstanding operative event to GTM
   * @param {string} linkText text of the link
   * @param {InsuranceType} insuranceType GI or Vida
   */
  public static outstandingOperativeLink(linkText: string, insuranceType: InsuranceType): void {
    if (linkText && linkText.trim().length && insuranceType) {
      AnalyticsEvents.sendEvent({
        event_name: EventName.LinkClick,
        element: undefined,
        link_text: snakeCase(linkText),
        link_type: undefined,
        link_url: undefined,
        location: `Operativa destacada/${insuranceType === 'GI' ? 'Seguros Generales' : 'Seguros de Vida y Pensiones'}`
      });
    }
  }

  /**
   * Send link click from links of Interest event to GTM
   * @param {string} linkText text of the link
   */
  public static linkOfInterest(linkText: string): void {
    if (linkText && linkText.trim().length) {
      AnalyticsEvents.sendEvent({
        event_name: EventName.LinkClick,
        element: undefined,
        link_text: linkText,
        link_type: undefined,
        link_url: undefined,
        location: 'Enlaces de interés'
      });
    }
  }

  /**
   * Send links of interest (See All) event to GTM
   */
  public static linksOfInterestSeeAll(): void {
    AnalyticsEvents.sendEvent({
      event_name: EventName.LinkClick,
      element: undefined,
      link_text: 'Ver más',
      link_type: undefined,
      link_url: undefined,
      location: 'Enlaces de interés'
    });
  }

  /**
   * Send New offer button click event to GTM
   */
  public static newOffer(): void {
    AnalyticsEvents.sendEvent({
      event_name: EventName.NewOfferButton,
      element: undefined,
      link_text: undefined,
      link_type: undefined,
      link_url: undefined,
      location: undefined
    });
  }

  /**
   * Send user began New offer (Oferta/Emision product click) event to GTM
   * @param {string} linkText text of the link
   * @param {NewOfferCardName} cardTitle card title ('Particulares', 'Empresas' or 'Commercial Insurance')
   * @param {string} insuranceType text of the link
   */
  public static beginNewOffer(linkText: string, cardTitle: NewOfferCardName, insuranceType: string): void {
    if (linkText && linkText.trim().length && cardTitle && insuranceType && insuranceType.trim().length) {
      AnalyticsEvents.sendEvent({
        event_name: EventName.NewOfferLink,
        element: undefined,
        link_text: linkText,
        link_type: undefined,
        link_url: undefined,
        location: `${cardTitle}/${insuranceType}`
      });
    }
  }

  /**
   * Send user session expired event to GTM
   */
  public static sessionExpired(): void {
    AnalyticsEvents.sendEvent({
      event_name: EventName.SessionExpired,
      element: undefined,
      link_text: undefined,
      link_type: undefined,
      link_url: undefined,
      location: undefined
    });
  }

  /**
   * Send user logout event to GTM
   */
  public static logout(): void {
    AnalyticsEvents.sendEvent({
      event_name: EventName.Logout,
      element: undefined,
      link_text: undefined,
      link_type: undefined,
      link_url: undefined,
      location: undefined
    });
  }

  /**
   * Send user login event to GTM
   * @param {string} userId user identification
   * @param {string} userRol user rol
   */
  public static login(userId: string, userRol: string): void {
    const analyticsApi = Vue.prototype.$analytics as AnalyticsApi;
    if (analyticsApi) {
      new EAApplicationLogger().info(`AnalyticsEvents::login ${JSON.stringify({
        user_id: userId, user_rol: UserTypes[userRol]
      })}`);
      analyticsApi.trackUser({
        user_id: userId, user_rol: UserTypes[userRol]
      });
    }
  }

  /**
   * Send new event via analytics api
   * 
   * @param {EventParameters} payload event parameters
   */
  private static sendEvent(payload: EventParameters): void {
    const analyticsApi = Vue.prototype.$analytics as AnalyticsApi;
    if (analyticsApi) {
      new EAApplicationLogger().info(`AnalyticsEvents::sendEvent ${JSON.stringify({
        event: EVENT_NAME,
        payload
      })}`);
      analyticsApi.event(EVENT_NAME, payload as unknown as EventPayload);
    }
  }

}

export default AnalyticsEvents;
