import {
  eaCustomValidation,
  EAIValidationRule,
  eaLengthValidation,
  EAValidationTriggers,
} from '@zurich-es-npm/ea-front-web-ui';
import {
  cifValidation, nieValidation, nifValidation, passportOrExternalValidation, knownTypeValidation, otherTypeValidation
} from '@/utils/document-validations';
import {
  EAValidatorFunction
} from '@zurich-es-npm/ea-front-web-core/lib/validation-rules';

/**
 * Utils object with auxiliar functions
 */
export class SearchPersonUtils {

  /**
   * Gets array with validations for document number field based on received document type
   * @param {string} documentType
   * @param {Function} documentNumberCorporateTableValidation
   * @returns {EAIValidationRule[]}
   */
  public static getDocumentNumberValidationRules(
    documentType: string,
    documentNumberCorporateTableValidation: EAValidatorFunction
  ): EAIValidationRule[] {
    let documentNumberValidationRules;
    
    if (documentType === 'N') {
      // NIF
      documentNumberValidationRules = [
        eaLengthValidation(9, 'common.label.validation.invalidLength'),
        eaCustomValidation(nifValidation, 'common.label.validation.invalidValue'),
        eaCustomValidation(
          documentNumberCorporateTableValidation,
          'common.label.validation.invalidValue',
          EAValidationTriggers.CHANGE
        ),
      ];
    } else if (
      documentType === 'P' || documentType === 'X'
    ) {
      // Passport or External Company
      documentNumberValidationRules = [
        eaCustomValidation(passportOrExternalValidation, 'common.label.validation.invalidValue'),
        eaCustomValidation(
          documentNumberCorporateTableValidation,
          'common.label.validation.invalidValue',
          EAValidationTriggers.CHANGE
        ),
      ];
    } else if (documentType === 'R') {
      // NIE
      documentNumberValidationRules = [
        eaLengthValidation(9, 'common.label.validation.invalidLength'),
        eaCustomValidation(nieValidation, 'common.label.validation.invalidValue'),
        eaCustomValidation(
          documentNumberCorporateTableValidation,
          'common.label.validation.invalidValue',
          EAValidationTriggers.CHANGE
        ),
      ];
    } else if (documentType === 'C') {
      // CIF
      documentNumberValidationRules = [
        eaLengthValidation(9, 'common.label.validation.invalidLength'),
        eaCustomValidation(cifValidation, 'common.label.validation.invalidValue'),
        eaCustomValidation(
          documentNumberCorporateTableValidation,
          'common.label.validation.invalidValue',
          EAValidationTriggers.CHANGE
        ),
      ];
    } else if (
      documentType === 'S' || documentType === 'F'
    ) {
      // Known Type
      documentNumberValidationRules = [
        eaCustomValidation(knownTypeValidation, 'common.label.validation.invalidValue'),
        eaCustomValidation(
          documentNumberCorporateTableValidation,
          'common.label.validation.invalidValue',
          EAValidationTriggers.CHANGE
        ),
      ];
    } else {
      documentNumberValidationRules = [
        eaCustomValidation(otherTypeValidation, 'common.label.validation.invalidValue'),
        eaCustomValidation(
          documentNumberCorporateTableValidation,
          'common.label.validation.invalidValue',
          EAValidationTriggers.CHANGE
        ),
      ];
    }

    return documentNumberValidationRules;
  }
}

export default SearchPersonUtils;
