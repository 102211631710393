import {
  EALocaleManager,
  EABrandingManager
} from '@zurich-es-npm/ea-front-web-core';

import loadLocaleMessages from './i18n';

interface StyleType {
  use: Function;
  unuse: Function;
}

interface BrandingStyleType {
  [key: string]: StyleType;
}

const brandingStyles: BrandingStyleType = {};

export const BASE_BRANDING = 'zurich';

/**
 * Change branding styles
 * @param {string} currentBranding
 * @param {string[]} languages 
 */
export default function changeBrandingStyles(currentBranding: string): void {
  const availableLocales = EALocaleManager.availableLocales;
  const messages = loadLocaleMessages(currentBranding, availableLocales);

  EABrandingManager.currentBranding = currentBranding;

  EALocaleManager.messages = messages;

  // Disable all branding styles
  Object.values(brandingStyles).forEach((style: StyleType) => {
    style.unuse();
  });

  if (currentBranding === BASE_BRANDING) {
    return;
  }

  if (brandingStyles[currentBranding]) {
    const styles = brandingStyles[currentBranding];
    styles.use();
  }
}
