<template>
  <div>
    <ea-heading level="5" class="m-b-8">
      {{$t(titleLabel)}}
    </ea-heading>
    <p class="t-weight-regular m-b-4 t-capitalize">{{
      `${$t(nameTranslationLabel)}: ${clientDataName}`
    }}</p>
    <p class="t-weight-regular m-b-4 t-capitalize">{{
      `${$t('quoteBuyGenericFlow.generateDocumentation.field.taxIdentity')}: ${clientDataDocumentNumber}`
    }}</p>
  </div>
</template>

<script lang="ts">
import {
  GetPersonsResponseDatosPersona
} from '@/services/V1/quoteAndBuy/getPersonsOperation/post';
import {
  Roles
} from '@/types/roles/roles-enum.types';
import TextUtils from '@/utils/text-utils';
import Vue from 'vue';
import {
  Component, Prop
} from 'vue-property-decorator';
import {
  DocumentationClientData
} from './clientBasicInfo.type';

@Component({
  name: 'zz-client-basic-info',
})

/**
 * Presentational Component zz-client-basic-info
 */
export default class ZzClientBasicInfo extends Vue {

  @Prop({
    required: true,
  })
    documentationClientData!: DocumentationClientData;

  @Prop()
    titleLabelOverride?: string;

  titleLabel: string = '';

  nameTranslationLabel: string = '';

  clientDataDocumentNumber: string = '';

  clientDataName: string = '';

  /**
   * Created hook
   */
  created() {
    this.calculateLabels();
  }

  /**
   * Watcher for documentationClientData property
   * Calculates info to show
   */
  public calculateLabels() {
    this.titleLabel = this.calcTitleLabel();
    this.nameTranslationLabel = this.calcNameTranslationLabel();
    this.clientDataDocumentNumber = this.calcClientDataDocumentNumber();
    this.clientDataName = this.calcClientDataName();
  }

  /**
   * Gets title label based on person role
   * @returns {string}
   */
  calcTitleLabel(): string {
    if (this.titleLabelOverride) {
      return this.titleLabelOverride;
    }

    switch (this.documentationClientData.clientRole) {
      case Roles.Tomador:
        return 'quoteBuyGenericFlow.generateDocumentation.policyTakerData';
      case Roles.Asegurado:
        return 'quoteBuyGenericFlow.generateDocumentation.ensuredPersonData';
      case Roles.Pagador:
        return 'quoteBuyGenericFlow.generateDocumentation.payerData';
      default:
        return 'quoteBuyGenericFlow.generateDocumentation.policyTakerData';
    }
  }

  /**
   * Gets name label translation based on person type
   * @returns {string}
   */
  calcNameTranslationLabel(): string {
    switch (this.getClientDataPersonType()) {
      case 'F':
        return 'quoteBuyGenericFlow.generateDocumentation.field.nameLastname';
      case 'J':
        return 'quoteBuyGenericFlow.generateDocumentation.field.businessName';
      default:
        return '';
    }
  }

  /**
   * Returns policy taker document number
   * @returns {string}
   */
  calcClientDataDocumentNumber(): string {
    return this.documentationClientData.clientData?.datosBasicosPersona?.codigoIdentificacionFiscal || '';
  }

  /**
   * Getter for policy taker name
   * @returns {string}
   */
  calcClientDataName() {

    if (this.documentationClientData.clientData) {
      const personType = this.getClientDataPersonType();
      
      if (personType === 'F') {
        return this.getNaturalPersonFullName(this.documentationClientData.clientData);
      } else if (personType === 'J') {
        return TextUtils.capitalizeFirstLetterOneWord(
          this.documentationClientData.clientData.datosBasicosPersona?.primerApellido?.toLowerCase() || ''
        );
      }
    }

    return '';
  }

  /**
   * Gets person type
   * @returns {string}
   */
  getClientDataPersonType(): string {
    return this.documentationClientData.clientData?.datosBasicosPersona?.tipoPersona?.toString() || '';
  }
  

  /**
   * Gets natural person full name in proper format
   * @param {GetPersonsResponseDatosPersona} person
   * @returns {string}
   */
  getNaturalPersonFullName(person: GetPersonsResponseDatosPersona): string {
    const name = TextUtils.capitalizeFirstLetterOneWord(
      person.datosBasicosPersona?.nombrePropio?.toLowerCase() || ''
    );
    const lastName = TextUtils.capitalizeFirstLetterOneWord(
      person.datosBasicosPersona?.primerApellido?.toLowerCase() || ''
    );
    const lastName2 = TextUtils.capitalizeFirstLetterOneWord(
      person.datosBasicosPersona?.segundoApellido?.toLowerCase() || ''
    );

    return `${name } ${ lastName } ${ lastName2}`;
  }

}
</script>
