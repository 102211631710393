import {
  RangeRestriction,
} from './range-restriction-model';
import {
  VehiclesRestrictions,
} from './vehicles-restrictions-model';

/**
 * 
 */
export class UnifiedDateRestriction {
  public datesRange: RangeRestriction = new RangeRestriction();

  public vehiclesRestrictions: VehiclesRestrictions = new VehiclesRestrictions();
}
