import PostCodeLocationInputModel from '../post-code-location-input/post-code-location-input-model';
import {
  SearchPolicyHolderResponseJuridicalPeople as JuridicalPeople
} from '@/services/V1/fleets/searchPolicyHolderOperation/post/api';
import FleetCommitContractModel from '../fleet-commit-contract/fleet-commit-contract-model';

export enum ErrorSearchPolicyHolder {
  NotFound = '1004'
}

/**
 * Model qb-search-client
 */
class FleetSearchPolicyHolderModel {

  public indicadorCompromisoContrato: boolean | undefined = undefined;
  
  public indicadorExistePersona: boolean | undefined = undefined;
  
  public showDocumentInvalidError = false;
  
  public postCodeLocationInputModel = new PostCodeLocationInputModel();

  public selectedJuridicalPerson: JuridicalPeople = {
    tipoDocumento: undefined,
    codigoIdentificacionFiscal: '',
    razonSocialMatriz: '',
    tipoViaDireccionLarga: '',
    nombreDireccionTomador: '',
    numeroDireccionTomador: '',
    informacionAdicional: '',
    codigoPostal: '',
    nombrePoblacionTomador: '',
    nombreProvinciaTomador: '',
    codigoFiliacion: ''
  };
  
  public juridicalPeople: JuridicalPeople[] | null = null;

  public fleetCommitContract: FleetCommitContractModel = new FleetCommitContractModel();

}

export default FleetSearchPolicyHolderModel;
