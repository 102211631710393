import {
  FleetsMaintenancePolicy
} from './fleets-maintenance-policy-model';
import {
  GeneralDataView
} from './general-data-view-model';
import {
  NewOfferView
} from './new-offer-view-model';
import {
  OfferIssuanceDataView
} from './offer-issuance-data-view-model';
import {
  QuoteDataView
} from './quote-data-view-model';
import {
  VehiclesDataView
} from './vehicles-data-view-model';
import {
  FleetCancelPolicy
} from './fleet-cancel-policy-model';


/**
 * 
 */
export class NewFleetFlowConfig {
  public branchCode: string = '';

  public newOfferView: NewOfferView = new NewOfferView();

  public generalDataView: GeneralDataView = new GeneralDataView();

  public vehiclesDataView: VehiclesDataView = new VehiclesDataView();

  public quoteDataView: QuoteDataView = new QuoteDataView();

  public offerIssuanceDataView: OfferIssuanceDataView = new OfferIssuanceDataView();

  public fleetsMaintenancePolicy: FleetsMaintenancePolicy = new FleetsMaintenancePolicy();

  public cancelFleet: FleetCancelPolicy = new FleetCancelPolicy();
}
