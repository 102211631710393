import {
  EAMenuStructure
} from '@/layout/menu-structure-types.d';

/**
 * Sort menu structure by id
 *
 * @export
 * @param {EAMenuStructure} menuStructure
 * @returns {EAMenuStructure}
 */
export function sortMenuStructureById(menuStructure: EAMenuStructure): EAMenuStructure {
  const result = menuStructure.sort((item1, item2) => {
    const menuId1 = item1.itemId.split('-');
    const menuId2 = item2.itemId.split('-');
    return parseInt(menuId1[menuId1.length - 1]) - parseInt(menuId2[menuId2.length - 1]);
  });

  for (let index = 0; index < result.length; index++) {
    const children = result[index].children;
    result[index].children = children ? sortMenuStructureById(children) : undefined;
  }

  return result;
}
