import {
  GetOfferListByRiskRequestTipoPolizaOfertaEnum
} from '@/services/V1/search-offer/getOfferListByRiskOperation/post';
import {
  GetOfferListRequestTipoPolizaOfertaEnum,
  GetOfferListResponseListaOfertas,
} from '@/services/V1/search-offer/getOfferListOperation/post';
import {
  GetPolicyListRequestTipoPolizaOfertaEnum,
  GetPolicyListResponseListaPolizas
} from '@/services/V1/search-policy/getPolicyListOperation/post';
import {
  SearcherModeEnum,
  SearchTypeEnum
} from '@/utils/fields/fields.enum';
import QbPolicyHistoryModalModel from '../qb-policy-history-modal/qb-policy-history-modal-model';
import {
  FlowEnum
} from '@/types/flow/flow-enum.types';
import {
  GetCardPaymentPoliciesManagementListRequestEstadoPolizaPagoConTarjetaEnum as EstadoPolizaPagoConTarjetaEnum,
} from '@/services/V1/gestion-pago-tarjeta/getCardPaymentPoliciesManagementListOperation/post';
import QbEmployeeModalIntermediariesModel
  from '../qb-employee-modal-intermediaries/qb-employee-modal-intermediaries-model';
import QbSearchPlateNumberModel from '../qb-search-plate-number/qb-search-plate-number-model';
import QbSearchPolicyholderModel from '../qb-search-policyholder/qb-search-policyholder-model';
import {
  GetPersonsRequestTipoPersonaEnum
} from '@/services/V1/quoteAndBuy/getPersonsOperation/post';

/**
 * Model qb-searcher
 */
class QbSearcherModel {
  public searchBy?: SearchTypeEnum = undefined;

  public offerNumber: string = '';

  public personType?: GetPersonsRequestTipoPersonaEnum = undefined;


  public numberProduct: string = '';
  
  public searchPlateNumberModel = new QbSearchPlateNumberModel();

  public searchPolicyholderModel = new QbSearchPolicyholderModel();

  public filiationCode: string = '';

  public statusCode: string | EstadoPolizaPagoConTarjetaEnum = '';

  public offer?: GetOfferListResponseListaOfertas = undefined;

  public policy?: GetPolicyListResponseListaPolizas = undefined;

  public user: string = '';

  public policyType: GetOfferListRequestTipoPolizaOfertaEnum |GetOfferListByRiskRequestTipoPolizaOfertaEnum |
  GetPolicyListRequestTipoPolizaOfertaEnum = GetOfferListRequestTipoPolizaOfertaEnum.O;

  public policyHistoryModal = new QbPolicyHistoryModalModel();
  
  public fleetCode: string = '';

  public fleetName: string = '';

  public intermediaryCode: string | undefined = '';

  public intermediaryName: string | undefined = '';

  public flow?: FlowEnum = undefined;

  /**
   * Flag used to force fetching results after goBack action from general data
   *  It also avoids entering the same offer again when fetch data returns 1 results
   */
  public goBackFlag: boolean = false;

  public intermediariesEmployeeModal = new QbEmployeeModalIntermediariesModel();

  public searcherMode: SearcherModeEnum = '' as SearcherModeEnum;

}

export default QbSearcherModel;
