<template>
  <ea-dialog
    v-if="onboardingsToShow"
    :visible.sync="showTour"
    :title="getTitle()"
    :beforeClose="onCancel"
    width="936px"
    height="736px"
  >
  <ea-carousel
    :loop="false"
    arrow="always"
    height="700px"
    indicator-position="outside">
    <ea-carousel-item
     v-for="(slide, index) in activeSlides" :key="index">
      <component :is="slide.onboardingComponent"
      :hideFirstTitle="slide.onboardingHideFirstTitle"></component>
    </ea-carousel-item>
  </ea-carousel>
  </ea-dialog>
</template>

<script lang="ts">
import {
  Component, Vue
} from 'vue-property-decorator';

import {
  EAEventBus
} from '@zurich-es-npm/ea-front-web-core';

import {
  OnboardingUtils
} from './utils/onboarding-utils';
import {
  OnboardingSlide, Onboarding, OnboardingConfig
} from './utils/onboarding-types';
import {
  clone
} from 'lodash';

@Component({
  name: 'onboarding'
})

/**
 * Business Component onboarding
 */
export default class OnboardingBusiness extends Vue {

  onboardingsToShow: string[] | null = null;
  
  showTour: boolean = false;

  activeSlides: OnboardingSlide[] = [];

  onboardingModalTitleLabel?: string = '';
  
  /**
   * 
   */
  created(): void {
    EAEventBus.$on('openTour', (tours: string[], config?: OnboardingConfig) => {

      // Hide or show modal title ('Novedades'). By default, show modal title
      if (config?.hideModalTitle) {
        this.onboardingModalTitleLabel = ' ';
      } else {
        this.onboardingModalTitleLabel = '';
      }

      this.onboardingsToShow = tours;
      this.prepareOnboardings();
      this.showTour = true;
    });
  }

  /**
   * Remove the listeners created for analytics tracking
   * @memberof EAAnalyticsTracking
   */
  beforeDestroy(): void {
    EAEventBus.$off('openTour');
  }

  /**
   * 
   */
  prepareOnboardings(): void {
    this.activeSlides = [];
    const availableOnboardings = OnboardingUtils.onboardingsConfig;
    const activeOnboardings: Onboarding[] = availableOnboardings.currentOnboardings
      .filter(onboarding => this.onboardingsToShow?.includes(onboarding.onboardingName));
    activeOnboardings.forEach(onb => {
      if (onb.onboardingHiddenSlides && onb.onboardingComponents) {
        const visibleSlides = clone(onb.onboardingComponents);
        for (let hiddenSlide = onb.onboardingHiddenSlides.length - 1; hiddenSlide >= 0; hiddenSlide--) {
          visibleSlides.splice(onb.onboardingHiddenSlides[hiddenSlide], 1);
        }
        visibleSlides.forEach(slide => {
          this.activeSlides.push({
            onboardingComponent: slide,
            onboardingHideFirstTitle: onb.onboardingHideFirstTitle
          });
        });
      }
    });
  }

  /**
   * Handles dialog cancel
   * Emits close event
   */
  onCancel() {
    this.showTour = false;
    OnboardingUtils.updateMultipleOnboardingLocalItem(this.onboardingsToShow as string[]);
    this.onboardingsToShow = null;
  }

  /**
   * Gets title for dialog
   * @returns {string}
   */
  getTitle(): string {
    return this.onboardingModalTitleLabel ?
      this.$t(this.onboardingModalTitleLabel).toString() : this.$t('onboarding.title').toString();
  }

}
</script>
