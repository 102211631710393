<template>
  <div>
    <ea-flow-container>
      <div>
        <notification-handler/>
      </div>

      <ea-row>
        <ea-col>
          <qb-header
            id="policy-qb-header"
            v-model="model"
            :title="title"
            :subtitle="subTitle"
            :parentFlowId="flowId"
            ></qb-header>
        </ea-col>
        <ea-col>
          <div>
            <documentation
              v-model="model"
              @back="handleBack"
            />
          </div>
        </ea-col>
      </ea-row>
 
    </ea-flow-container>
  </div>
</template>

<script lang="ts">
import {
  Component
} from 'vue-property-decorator';

import {
  mixins
} from 'vue-class-component';

import {
  EAFlow,
  EAFlowNavigation,
  EAApplicationLogger,
  EAMethod,
  EAContextManager
} from '@zurich-es-npm/ea-front-web-core';

import Documentation from './views/check-and-maintain.vue';
import {
  DocumentationModel, InputModel
} from './documentation-model';
import QbHeaderBusiness from '@/business-components/qb-header/qb-header-business.vue';
import {
  EALocaleManager
} from '@zurich-es-npm/ea-front-web-ui';
import NotificationHandler from '@/presentational-components/notification-handler/notificationHandler.vue';

const logger = new EAApplicationLogger();

@Component({
  components: {
    QbHeader: QbHeaderBusiness,
    Documentation: Documentation,
    'notification-handler': NotificationHandler
  }
})

/** 
 * Documentation flow
 * 
 */
export default class DocumentationFlow extends mixins<EAFlow<DocumentationModel>>(EAFlow) {

  public flowId: string = '';

  /** 
   * Initialize flow model
   */
  constructor() {
    super();
    logger.debug('DocumentationFlow::constructor');
    this.model = new DocumentationModel();
    this.navigation = new EAFlowNavigation();
    this.navigation.maxWindows = 1;
  }

  
  /** 
   * Get data from params
   */
  created() {
    this.getParamsValue();
    this.flowId = EAContextManager.getInstance().getFlowContext()?.activeFlow?.flowId || '';
  }


  /** 
   * Get title
   */
  get title(): string {
    return EALocaleManager.i18n.t('documentationFlow.documentationView.title') as string;
  }


  /** 
   * Get subtitle
   */
  get subTitle(): string {
    const subTitle = `documentationFlow.documentationView.${this.model.isFleet ? 'fleetSubTitle' : 'subTitle'}`;
    if (this.model.policyVersion > 0) {
      const policy = EALocaleManager.i18n.t(subTitle, {
        number: this.model.policyNumber
      }) as string;
      const version = EALocaleManager.i18n.t('documentationFlow.documentationView.version', {
        version: this.model.policyVersion
      }) as string;
      
      return `${policy} ${version}`;
    }
    return EALocaleManager.i18n.t(subTitle, {
      number: this.model.policyNumber
    }) as string;
  }

  /**
   * Get params from the InputModel
   */
  getParamsValue() {
    const {
      codigoPoliza, versionPoliza, tipoPoliza, allowAttachments, isFleet
    }: InputModel = this.inputModel;
    this.model.policyNumber = codigoPoliza;
    this.model.policyType = tipoPoliza;
    this.model.policyVersion = versionPoliza;
    this.model.allowAttachments = allowAttachments === undefined ? true : allowAttachments;
    this.model.isFleet = isFleet === undefined ? false : isFleet;
  }

  /**
   * Returns tu parent flow - se encarga de devolver el control
   * al flujo padre.
   */
  @EAMethod()
  handleBack() {
    const outputModel = {
      codigoPoliza: this.model.policyNumber,
    };
    
    this.returnToParentFlow(outputModel);
  }

}
</script>
