<template>
  <span />
</template>

<script lang="ts">

import Vue from 'vue';
import {
  Component, Watch
} from 'vue-property-decorator';

import {
  EAContextManager,
  EAFlowItem,
} from '@zurich-es-npm/ea-front-web-core';
import AnalyticsEvents from './analytics-events';

@Component({
  name: 'analytics-virtualpage-tracker'
})

/**
 * Analytics virtual page tracker Component
 */
export default class AnalyticsVirtualPageTracker extends Vue {

  /**
   * Getter for the current active flow in the application
   */
  get activeFlow(): EAFlowItem | undefined {
    return EAContextManager.getInstance().getFlowContext().activeFlow;
  }

  /**
   * Listen changes in the current active flow and track them in analytics
   * @param {EAFlowItem | undefined} activeFlow current active flow
   * @param {EAFlowItem | undefined} oldFlow old active flow
   */
  @Watch('activeFlow', {
    deep: true,
    immediate: true
  })
  trackNewActiveFlow(activeFlow: EAFlowItem | undefined, oldFlow: EAFlowItem | undefined): void {
    if (activeFlow) {
      let tabName = '';
      if (activeFlow.flow === 'ZonaZurich') {
        // Coexistence flow
        if (!this.mustTrackCoexistenceNewFlow(activeFlow, oldFlow)) {
          return;
        }
        tabName = activeFlow.meta?.custom?.name || 'ZonaZurich';
      } else if (activeFlow.flow === 'Dashboard') {
        // Home flow
        tabName = activeFlow.meta?.title || 'Dashboard';
      } else {
        // Standard flow
        tabName = activeFlow.meta?.title ? `${this.$t(activeFlow.meta?.title)}` : activeFlow.flow;
      }
      AnalyticsEvents.virtualPage(tabName);
    }
  }

  /**
   * @param {EAFlowItem | undefined} activeFlow current active flow
   * @param {EAFlowItem | undefined} oldFlow old active flow
   * 
   * @return {boolean} if new active flow must be tracked or not
   */
  mustTrackCoexistenceNewFlow(activeFlow: EAFlowItem, oldFlow: EAFlowItem | undefined): boolean {

    /* 
     * If no 'id' informed -> coexistence tab is not already binded with its correspondant operation id
     * If coexistence operation id is the sme than the old one, do not track event to avoid repeated events
     */
    if (!activeFlow.meta?.custom?.id || activeFlow.meta?.custom?.id === oldFlow?.meta?.custom?.id) {
      return false;
    }
    return true;
  }
}
</script>
