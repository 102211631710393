export enum plateNumberTypeEnum {
  PLATE_CICLOMOTOR = 'C',
  PLATE_ESPECIAL = 'E',
  PLATE_DIPLOMATICO = 'D',
  PLATE_ESPECIAL_RD = 'E1',
  PLATE_HISTORICO = 'H',
  PLATE_ITV = 'I',
  PLATE_OTRAS = 'K',
  PLATE_ESTADO = 'M',
  PLATE_ESTADO_RD = 'M1',
  PLATE_PRUEBAS = 'P',
  PLATE_REMOLQUE = 'R',
  PLATE_REMOLQUE_RD = 'R1',
  PLATE_TURISTICA = 'T',
  PLATE_TURISTICA_RD = 'T1',
  PLATE_TEMPORAL = 'TE',
  PLATE_AUTORIZACION_TEMPORAL = 'TT',
  PLATE_ORDINARIA = '1',
  PLATE_BLANK = ' '
}
