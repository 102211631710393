import {
  QueryBaseSieteResponsePosiblesVehiculos
} from '@/services/V1/fleets/queryBaseSieteOperation/post';
import {
  SelectList,
} from '../qb-searcher/qb-searcher.interface';

export interface VehicleFormModel {
  plateTypeName?: string;
  plateNumberType?: string;
  plateNumber?: string;
  vehicleUseId?: string;
  plateUseId?: string;
  vehicleUseName?: string;
  accessoriesValue?: number;
  policyNumber?: string;
  vehicleBrand?: string;
  vehicleModel?: string;
  vehicleVersion?: string;
  vehicleYear?: Date;
  codigoVehiculo?: string;
  effectiveDate?: Date;
  offerNumber?: string;
  offerVersion?: number;
  invalid?: boolean;
  invalidDate?: boolean;
  invalidValues?: boolean;
  isBaseSieteData?: boolean;
  possibleVehicles?: QueryBaseSieteResponsePosiblesVehiculos[];
  isExistErrorVehicle?: boolean;
  productComercialCode?: string;
  productTechnicalCode?: string;
  driverDocumentNumber?: string;
  driverFisrtName?: string;
  driverLastName?: string;
  driverFiliationCode?: string;
  nominatedDriver?: boolean;
  vehicleValue?: number;
  vehicleMaxWeight?: number;
  vehicleType?: string;
}

export interface VehicleUseData {
  name: string;
  value: string;
  plateType: string;

  plateUseCode?: string;
  vehicleUseCode?: string;
  vehicleType?: string;
}

export enum BrandModelVersionLevel {
  Brand = 0,
  Model = 1,
  Version = 2
}

/**
 * 
 */
export interface PossibleBrand {
  brandCode: string;
  brandDescription: string;

}

/**
 * 
 */
export interface PossibleModel {
  brandCode: string;
  modelCode: string;
  modelDescription: string;
}

/**
 * 
 */
export interface PossibleVersion {

  modelCode: string;
  versionCode: string;
  versionDescription: string;
}


/** 
 * Model fleet-manual-upload-vehicle
 */
class FleetManualUploadVehicleModel {
  public formValues: VehicleFormModel = { // IMPORTANT: Properties must be initialized in order to be reactive!
    plateNumberType: '',
    plateTypeName: '',
    plateNumber: '',
    plateUseId: '',
    vehicleUseId: '',
    vehicleUseName: '',
    accessoriesValue: 0,
    policyNumber: '',
    vehicleBrand: '',
    vehicleModel: '',
    vehicleVersion: '',
    effectiveDate: '' as unknown as Date,
    vehicleYear: '' as unknown as Date,
    offerNumber: '', // TODO: may be this info does not fit well here
    offerVersion: 0,
    isBaseSieteData: false,
    possibleVehicles: [],
    vehicleValue: 0,
    vehicleMaxWeight: 0,
    vehicleType: ''
  };

  //TODO: fetch data from backend?
  public tableValues = {
    vehicleBrand: '',
    vehicleModel: '',
    vehicleVersion: '',
    vehicleYear: '',
    effectiveDate: '',
  };

  public plateTypeSearchList: SelectList[] = [];

  public errorMsg: string | null = null;
}

export default FleetManualUploadVehicleModel;
