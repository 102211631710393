<template>
  <div class="slide d-display-flex d-direction-column d-justify-space-between h-100" >
      <div>
        <h2 class="t-color-primary m-b-16">{{ $t('onboarding.paperless.firstSlide.title') }}</h2>

      <i18n path="onboarding.paperless.firstSlide.descriptionFirst.text" tag="p" class="m-b-16">
        <span class="t-weight-semibold">{{ $t('onboarding.paperless.firstSlide.descriptionFirst.bold1') }}</span>
      </i18n>

      <i18n path="onboarding.paperless.firstSlide.descriptionSecond.text" tag="p">
        <span class="t-weight-semibold">{{ $t('onboarding.paperless.firstSlide.descriptionSecond.bold1') }}</span>
      </i18n>
    </div>

    <div class="d-display-flex d-align-items-center d-justify-center p-t-16 p-b-16 w-100 t-bg-color-gray-20">
        <img
        :src="require(`@/assets/images/onboarding/paperless/onboarding1.png`)"
        :alt="$t('onboarding.paperless.firstSlide.title1').toString()"
        class="w-75"
      >
    </div>
  
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import {
  Component
} from 'vue-property-decorator';

@Component({
  name: 'paperless-onboarding-1'
})

/**
 * Paperless onboarding component
 */
export default class PaperlessOnboarding1Component extends Vue {

}
</script>
