import {
  ZZNotification
} from '@/utils/notifications/notifications-utils';


/** 
 * Model qb-download-documentation
 */
class QbDownloadDocumentationModel {
  public notifications: ZZNotification[] = [];
}

export default QbDownloadDocumentationModel;
