<template>
  <ea-dialog
    :visible="resultsTableVisible"
    :beforeClose="onCloseResultsDialog"
    type="passive"
    :title="$t('quoteBuyGenericFlow.searchPerson.resultsTable')"
  >
    <div class="m-t-24">

      <ea-table
        :data="personList"
        :simpleSelect="true"
        :infinite-scroll="true"
        class="thead-fixed table-height-limited"
        @rowClick="handleRowSelection"
      >
      
        <ea-table-column
          :label="$t('quoteBuyGenericFlow.searchPerson.field.linkedNameBusinessName')"
          headerClass="no-sortBy"
          dataType="string"
        >
          <template slot-scope="row">
            <p class="ea-table-td-content">
              {{
                `${row.nombrePropio}
                ${row.primerApellido}
                ${row.segundoApellido}`
              }}
            </p>
          </template>
        </ea-table-column>

        <ea-table-column
          :label="$t('quoteBuyGenericFlow.searchPerson.field.documentNumberShort')"
          show="documentNumber"
          dataType="string"
          headerClass="no-sortBy"
        >
          <template slot-scope="row">
            <p class="ea-table-td-content">{{ `${row.codigoIdentificacionFiscal}` }}</p>
          </template>
        </ea-table-column>

        <ea-table-column
          :label="$t('quoteBuyGenericFlow.searchPerson.field.address')"
          headerClass="no-sortBy"
          dataType="string"
        >
          <template slot-scope="row">
            <p class="ea-table-td-content t-align-left">
              {{ `${row.descripcionDireccionCliente}` }}
            </p>
          </template>
        </ea-table-column>
      </ea-table>
    </div>

    <div slot="footer" class="d-display-flex d-justify-flex-end">
      <ea-button type="secondary" @click="onCloseResultsDialog()" class="m-r-16" size="medium">
        {{$t('common.label.cancel')}}
      </ea-button>
      <ea-button
        type="primary" size="medium"
        :disabled="!isAnyPersonSelectedInTable"
        @click="onSelectPersonAndCloseHandle()"
      >
        {{ $t('common.label.select') }}
      </ea-button>
    </div>
  </ea-dialog>
</template>

<script lang="ts">

import {
  Component, Prop
} from 'vue-property-decorator';
import Vue from 'vue';
import Row from '@zurich-es-npm/ea-front-web-ui/lib/ea/data/table/src/class/Row';
import {
  GetPersonsResponseListaPersonas
} from '@/services/V1/quoteAndBuy/getPersonsOperation/post';

@Component({
  name: 'search-person-results-table'
})

/**
 * SearchPersonResultsTableComponent component
 */
export default class SearchPersonResultsTableComponent extends Vue {

  public tableSelectedPerson: GetPersonsResponseListaPersonas | null = null;

  @Prop()
    resultsTableVisible?: boolean;

  @Prop()
    personList?: GetPersonsResponseListaPersonas[];
  
  /**
   * Handle close dialog button and dispatch the event.
   */
  onCloseResultsDialog() {
    this.tableSelectedPerson = null;
    this.$emit('closeResultsDialog');
  }
  
  /**
   * Handle select person button and dispatch the event.
   */
  onSelectPersonAndCloseHandle() {
    if (this.tableSelectedPerson) {
      this.$emit('selectPersonAndClose', this.tableSelectedPerson);
    }
  }

  /**
   * Handles click on person table row
   * @param { Row } event selected row data
   */
  handleRowSelection(event: Row) {
    const tableSelectedPerson = event.getData() as GetPersonsResponseListaPersonas;
    this.tableSelectedPerson = tableSelectedPerson;
  }

  /**
   * Checks if user has selected a person on table
   * Enables/Disables "Continue" button in table modal
   * @return { boolean } true if user has selected any person on table; false otherwise
   */
  get isAnyPersonSelectedInTable(): boolean {
    return !!this.tableSelectedPerson;
  }

}
</script>
