import QbProductoAseguradoHogarPolicyModel
  from '@/business-components/qb-producto-asegurado-hogar/qb-producto-asegurado-hogar-model';
import {
  PolicyModel
} from '../../../../flows/policy/policy-model';

/**
 * Comunidades model
 *
 * @export
 * @class ComunidadesPolicyModel
 * @extends {PolicyModel}
 */
export default class HogarPolicyModel extends PolicyModel {
  public productModel = new QbProductoAseguradoHogarPolicyModel();
}
