<template>
  <div>
    <ea-form ref="form" :model="formData" :validationOptions="validationOptions" :validateOnRuleChange="false">
      <ea-row>
        <ea-col>
          <div class="d-display-flex d-align-items-center" v-for="(email, id) in model.emailList" :key="id">
            <ea-form-item :prop="`${id}`" :label="$t('common.field.email')" :required="isEmailRequired">
              <ea-input-email
                v-model="formFields[`${id}`]"
                @blur="updateValue(id)"
                size="medium"
                :placeholder="$t('quoteBuyGenericFlow.generateDocumentation.placeholder.email')"
              />
            </ea-form-item>
            <ea-tooltip placement="top" class="m-b-8" v-if="model.emailList.length < 3 && id === 0">
              <ea-button-icon class="m-l-16" icon="z-plus" size="medium" @click.native="addEmail" />
              <div slot="content">
                {{ $t('quoteBuyGenericFlow.generateDocumentation.tooltip.addEmail') }}
              </div>
            </ea-tooltip>
            <ea-tooltip placement="top" class="m-b-8" v-if="id !== 0">
              <ea-button-icon class="m-l-16" icon="z-trash" size="medium" @click.native="removeEmail(id)" />
              <div slot="content">
                {{ $t('quoteBuyGenericFlow.generateDocumentation.tooltip.removeEmail') }}
              </div>
            </ea-tooltip>
          </div>
        </ea-col>
      </ea-row>
    </ea-form>
  </div>
</template>

<script lang="ts">
import {
  Component, Prop, Watch
} from 'vue-property-decorator';

import {
  mixins
} from 'vue-class-component';
import {
  EAValidatorFunction
} from '@zurich-es-npm/ea-front-web-core/lib/validation-rules';
import {
  eaCustomValidation,
  EAFormValidationOptions,
  EAIValidationRule,
  eaRequiredValidation,
  eaTypeValidation,
  EAValidation,
  EAValidationTypes,
  Form,
} from '@zurich-es-npm/ea-front-web-ui';
import {
  EABusinessComponent
} from '@zurich-es-npm/ea-front-web-core';

import MultipleEmailInputModel from './multiple-email-input-model';

@Component({
  name: 'multiple-email-input'
})

/**
 * Business Component multiple-email-input
 */
export default class MultipleEmailInputBusiness extends mixins<
EABusinessComponent<MultipleEmailInputModel>
>(EABusinessComponent) {

  @Prop({
    required: false,
    'default': () => false,
  })
    isEmailRequired?: boolean;

  /**
   * Watcher for isEmailRequired prop
   */
  @Watch('isEmailRequired')
  onIsEmailRequiredChange() {
    if (!this.isEmailRequired) {
      const form: Form = this.$refs.form as Form;
      form?.clearValidate();
    }
  }

  public formFields: Record<number, string> = {};

  /**
   * Getter for formFields
   * @returns { EAFormValidationOptions }
   */
  get formData(): Record<string, string> {
    return this.formFields;
  }

  /**
   * Hook on create
   */
  created() {
    this.formFields = Object.assign(
      {},
      this.model.emailList
    );
  }

  /**
   * Getter for validation options
   * @returns { EAFormValidationOptions }
   */
  get validationOptions(): EAFormValidationOptions {
    let emailRules: EAIValidationRule[] = [];

    const isEmailRequired = this.isEmailRequired && this.model.emailList.some(email => email === '' || !email);

    if (isEmailRequired) {
      emailRules.push(eaRequiredValidation('common.label.validation.fieldRequired'));
    }

    emailRules = emailRules.concat([
      eaCustomValidation(this.duplicatedEmailValidator(), 'common.label.validation.duplicatedEmail'),
      eaTypeValidation(EAValidationTypes.EMAIL, 'common.label.validation.invalidEmail')
    ]);

    const validationOptions = {
      rules: {},
    };
    this.model.emailList.forEach((emailField: string, index: number) => {
    // eslint-disable-next-line
      (validationOptions.rules as any)[`${index}`] = emailRules;
    });
    return validationOptions;
  }

  /**
   * Validates if introduced email is already in email list
   *
   * @returns {EAValidatorFunction}
   */
  duplicatedEmailValidator(): EAValidatorFunction {
    return (_rule: any, _value: any, callback: Function) => {
      if (this.formFields[_rule.field] === '') {
        return callback();
      }
      const findDuplicated = Object.keys(this.formData).some(
        (key: string) => _rule.field !== key && this.formFields[Number.parseInt(key)] === this.formFields[_rule.field]
      );
      if (findDuplicated) {
        return callback(new Error());
      }

      return callback();
    };
  }

  /**
   * Returns form validation object.
   *
   * @returns {EAValidation} Form validation object
   */
  public validation(): EAValidation {
    const form: Form = this.$refs.form as Form;
    return form.validation();
  }

  /**
   * Handles add email button click
   */
  async addEmail() {
    try {
      await this.validation()?.validate();
      if (this.model.emailList.length < 3 && this.model.emailList.every(email => email !== '')) {
        this.model.emailList.push('');
      }
      this.update();
    } catch (err) {
    // Design System will show form errors
    }
  }

  /**
   * @param {number} index
   */
  removeEmail(index: number) {
    this.model.emailList.splice(index, 1);
    this.formFields = Object.assign(
      {},
      this.model.emailList
    );
  }

  /**
   * Updates inputted value
   * @param {number} id
   */
  updateValue(id: number) {
    this.model.emailList[id] = this.formFields[id];
    this.update();
  }
}
</script>
