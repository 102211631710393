import {
  PolicyType
} from '@/types/policy-types/policy-types-enum.types';
import {
  EAFlowModel
} from '@zurich-es-npm/ea-front-web-core';

export interface InputModel {
  codigoPoliza: string;
  versionPoliza: number;
  tipoPoliza: PolicyType;
  allowAttachments: boolean;
  isFleet: boolean;
}

/**
 * Flow model
 *
 * @export
 * @class DocumentationModel
 * @extends {EAFlowModel}
 */
export class DocumentationModel extends EAFlowModel {
  public policyNumber: string = '';

  public policyVersion: number = 0;

  public policyType: PolicyType = PolicyType.Incompleta;

  public allowAttachments: boolean = true;

  public isFleet: boolean = false;
}
