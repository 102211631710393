<template>
  <div class="slide" >
        <h2 class="t-align-center m-b-32">{{ $t('onboarding.comunidades.firstSlide.title') }}</h2>

        <h4 class="t-align-center t-weight-normal m-r-64 m-l-64 m-b-32">
          {{ $t('onboarding.comunidades.firstSlide.description') }}
        </h4>

        <div class="d-display-flex d-align-items-top d-justify-center">
          <div class="image-item d-display-flex d-align-items-center d-direction-column">
            <img
              :src="require(`@/assets/images/onboarding/comunidades/onboarding1.png`)"
              :alt="$t('layout.header.logoAlt')"
              class="m-b-24"
            >
            <p class="t-align-center t-weight-semibold">
              {{ $t('onboarding.comunidades.firstSlide.imagesDescriptions.newCoverage') }}
            </p>
          </div>
          <div class="image-item d-display-flex d-align-items-center d-direction-column">
            <img
              :src="require(`@/assets/images/onboarding/comunidades/onboarding2.png`)"
              :alt="$t('layout.header.logoAlt')"
              class="m-b-24"
            >
            <p class="t-align-center t-weight-semibold">
              {{ $t('onboarding.comunidades.firstSlide.imagesDescriptions.moreAgile') }}
            </p>
          </div>
          <div class="image-item d-display-flex d-align-items-center d-direction-column">
            <img
              :src="require(`@/assets/images/onboarding/comunidades/onboarding3.png`)"
              :alt="$t('layout.header.logoAlt')"
              class="m-b-24"
            >
            <p class="t-align-center t-weight-semibold">
              {{ $t('onboarding.comunidades.firstSlide.imagesDescriptions.flexibleProduct') }}
            </p>
          </div>
          <div class="image-item d-display-flex d-align-items-center d-direction-column">
            <img
              :src="require(`@/assets/images/onboarding/comunidades/onboarding4.png`)"
              :alt="$t('layout.header.logoAlt')"
              class="m-b-24"
            >
            <p class="t-align-center t-weight-semibold">
              {{ $t('onboarding.comunidades.firstSlide.imagesDescriptions.alignedRate') }}
            </p>
          </div>
        </div>
      </div>
</template>

<script lang="ts">
import Vue from 'vue';
import {
  Component
} from 'vue-property-decorator';

@Component({
  name: 'comunidades-onboarding-1'
})

/**
 * Comunidades onboarding component
 */
export default class ComunidadesOnboarding1Component extends Vue {

}
</script>
