import Vue from 'vue';
import {
  MenuItem
} from '../services/V1/coexistence/menu/get';
import {
  EAPivotService
} from '@/coexistence/pivot/ea-pivot-service';
import {
  EAContextManager,
  EAApplicationError
} from '@zurich-es-npm/ea-front-web-core';
import {
  EASecurityMenuState
} from '@zurich-es-npm/ea-front-web-core/lib/store/contexts/security/ea-security-context-types';

export const SESSION_COEXISTENCE_MENU_KEY = 'coexistenceMenu';

/* eslint-disable max-len */
const URL_REGEXP = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/;

let coexistenceMenuPromise: Promise<MenuItem[]> | null = null;

/**
 * Guard to check whether value is menu item array
 *
 * @export
 * @param {unknown} value
 * @returns {boolean}
 */
export function isMenuItemArray(value: unknown): value is MenuItem[] {
  return Array.isArray(value) && value.reduce((result, item) => result && typeof item === 'object', true);
}

/**
 * Retrieve coexistence menu
 *
 * @export
 * @returns {Promise<MenuItem[]>}
 */
export async function getCoexistenceMenu(): Promise<MenuItem[]> {
  const ctxManager = EAContextManager.getInstance();
  // Check whether coexistence menu is set in session context
  try {
    const menu = ctxManager.getSessionValue(SESSION_COEXISTENCE_MENU_KEY);
    if (isMenuItemArray(menu)) {
      return menu;
    }
  } catch (err) {
    // If is not set, retrieve from pivot service
    if (!coexistenceMenuPromise) {
      // If two calls are executed simultaneously call will be cached to do an unique call
      coexistenceMenuPromise = EAPivotService.getPivotOperations();
    }
    const coexistenceMenu = await coexistenceMenuPromise;
    ctxManager.setSessionValue(SESSION_COEXISTENCE_MENU_KEY, coexistenceMenu);
    return coexistenceMenu;
  }
  
  throw new EAApplicationError('ZON00001');
}

/**
 * Flatten menu structure
 *
 * @export
 * @param {EASecurityMenuState[]} menu
 * @returns {EASecurityMenuState[]}
 */
export function flattenMenu(menu: EASecurityMenuState[]): EASecurityMenuState[] {
  const result = [];
  for (let menuIdx = 0; menuIdx < menu.length; menuIdx++) {
    let childrenItems: EASecurityMenuState[] = [];
    if (menu[menuIdx].children !== undefined) {
      childrenItems = flattenMenu(menu[menuIdx].children as EASecurityMenuState[]);
    }
    const item = JSON.parse(JSON.stringify(menu[menuIdx]));
    delete item.children;
    result.push(item);
    if (childrenItems.length > 0) {
      result.push(...childrenItems);
    }
  }
  return result;
}

/**
 * Filter hidden scenario menu items
 *
 * @export
 * @param {MenuItem[]} menuItems
 * @returns {MenuItem[]}
 */
export function filterHiddenScenario(menuItems: MenuItem[]): MenuItem[] {
  return menuItems.filter(menuItem => menuItem.scenario && menuItem.scenario !== '0900');
}

/**
 * Mount menu item external url
 * 
 * @param {MenuItem} menuItem
 * @returns {string} external url
 */
export function mountExternalLink(menuItem: MenuItem): string {
  const pivotBaseUrl = Vue.prototype.pivotBaseUrl;
  return URL_REGEXP.test(menuItem.url as string)
    ? menuItem.url as string
    : `${pivotBaseUrl}/${menuItem.url}`;
}
