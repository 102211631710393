/* eslint-disable max-lines */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  GenerateDocumentationViewLabelsModel
} from '@/business-components/qb-documentation-select-proposal/qb-documentation-select-proposal-model';
import {
  OfferIssuanceDataModel
} from '@/business-components/qb-offer-issuance-data/qb-offer-issuance-data-model';
import {
  ProductBase
} from '../product-base';
import QbAddressesPersonModel
  from '@/business-components/addresses/qb-addresses-person-model';
import QbProductoAseguradoHogarBusiness
  from '@/business-components/qb-producto-asegurado-hogar/qb-producto-asegurado-hogar-business.vue';
import QbSearchPersonModel from '@/business-components/qb-search-person/qb-search-person-model';
import {
  QuoteBuyModel
} from '@/flows/quote-buy/quote-buy-model';
import {
  GetWarrantiesResponseDataDatosGarantias
} from '@/services/V1/quoteAndBuy/getWarrantiesOperation/post';
import {
  SaveOfferDataRequestElementosPoliza
} from '@/services/V1/quoteAndBuy/saveOfferDataOperation/post';
import {
  AddressUtils
} from '@/utils/address-utils';
import {
  ParsedTableData
} from '@/utils/corporate-tables';
import HogarModel
  from '@/utils/quote-buy-product-factory/products/hogar/hogar-model';
import {
  EALocaleManager,
  EAValidationError
} from '@zurich-es-npm/ea-front-web-core';
import {
  VueConstructor
} from 'vue';
import {
  VisibleElementsWarranties,
  PersonRole,
} from '../../types/product-role-types';
import HogarPolicyModel from './hogar-policy-model';
import {
  PolicyModel
} from '@/flows/policy/policy-model';
import {
  Roles
} from '@/types/roles/roles-enum.types';
import QbIbanCodeModel from '@/business-components/qb-iban-code/qb-iban-code-model';
import PersonUtils from '@/utils/person-utils';
import QbGeneralDataInformationModel
  from '@/business-components/qb-general-data-information/qb-general-data-information-model';
import QbBillingDataModel from '@/business-components/qb-billing-data/qb-billing-data-model';
import Utils from '@/utils/utils';
import ComunidadesDocumentationProposalCardComponent
  // eslint-disable-next-line max-len
  from '@/presentational-components/documentationProposalCard/comunidades-documentationProposalCard/comunidades-documentationProposalCard.vue';
import QbProductoAseguradoHogarModel
  from '@/business-components/qb-producto-asegurado-hogar/qb-producto-asegurado-hogar-model';
import TextUtils from '@/utils/text-utils';
import moment from 'moment';
import QbShowPersonInfoModel from '@/business-components/qb-show-person-info/qb-show-person-info-business-model';
import {
  GetGeneralDataResponseDataDatosCabeceraTablaRestricciones,
  GetGeneralDataResponseDataDatosObjeto
} from '@/services/V1/quoteAndBuy/getGeneralDataOperation/post';
import {
  WARRANTIES_HEADER_DISPLAY_MODE
} from '@/business-components/qb-warranty/warranties-header/warranties-header-business.vue';
import {
  CodigoRamo
} from '@/types/codigo-ramo/codigo-ramo-enum.types';
import {
  DocumentType
} from '@/types/document-types/document-types-enum.types';
import {
  GetPersonsResponseListaPersonasTipoPersonaEnum
} from '@/services/V1/quoteAndBuy/getPersonsOperation/post';

/**
 * Product Hogar
 *
 * @export
 * @class ProductHogar
 * @extends {ProductBase}
 */
export class ProductHogar extends ProductBase {
  public codigoRamo?: CodigoRamo;

  public readonly objetoAseguradoComponent: VueConstructor = QbProductoAseguradoHogarBusiness;

  public readonly documentationSelectProposalComponent: VueConstructor = ComunidadesDocumentationProposalCardComponent;

  public readonly allowedRolesAtGeneralDataView = [Roles.Tomador];

  public personRoles: PersonRole[] = [
    // ### TOMADOR ROLE ###
    {
      disabled: false,
      role: Roles.Tomador,
      searchModel: new QbSearchPersonModel(),
      addressesModel: new QbAddressesPersonModel(),
      ibanList: [],
      ibanCodeModel: new QbIbanCodeModel(),
      billing: new QbBillingDataModel(),
      taxTreatmentArea: '',
      canBeChanged: true,
      showPersonInfoModel: new QbShowPersonInfoModel()
    },
    // ### ASEGURADO ROLE ###
    {
      disabled: false,
      role: Roles.Asegurado,
      searchModel: new QbSearchPersonModel(),
      addressesModel: new QbAddressesPersonModel(),
      ibanList: [],
      ibanCodeModel: new QbIbanCodeModel(),
      billing: new QbBillingDataModel(),
      roleEqualStatements: [{
        // ### TOMADOR EQUAL ASEGURADO STATEMENT ###
        roleCopyTo: Roles.Asegurado,
        roleCopyFrom: Roles.Tomador,
        value: true,
        hidden: false,
      }],
      canBeChanged: true,
      showPersonInfoModel: new QbShowPersonInfoModel()
    },
    // ### PAGADOR ROLE ###
    {
      disabled: false,
      role: Roles.Pagador,
      searchModel: new QbSearchPersonModel(),
      addressesModel: new QbAddressesPersonModel(),
      ibanList: [],
      ibanCodeModel: new QbIbanCodeModel(),
      billing: new QbBillingDataModel(),
      roleEqualStatements: [
        {
          // ### TOMADOR EQUAL PAGADOR STATEMENT ###
          roleCopyTo: Roles.Pagador,
          roleCopyFrom: Roles.Tomador,
          value: true,
          hidden: false,
        },
        {
          // ### ASEGURADO EQUAL PAGADOR STATEMENT ###
          roleCopyTo: Roles.Pagador,
          roleCopyFrom: Roles.Asegurado,
          value: false,
          hidden: true,
        },
      ],
      canBeChanged: true,
      showPersonInfoModel: new QbShowPersonInfoModel()
    }
  ];

  public readonly shouldAskForTomadorInNewOfferView: boolean = false;

  public readonly shouldShowNewSearchInShowPersonInfo: boolean = true;

  public readonly axesorSearchDocumentTypes: DocumentType[] = [];

  public readonly shouldReadonlyDatesInGeneralDataInfo: boolean = false;

  public readonly shouldShowProposalTitle: boolean = true;

  public readonly shouldShowCustomPackageName: boolean = true;

  public readonly containerWarrantyCode: string = '0000000629';

  public readonly contentWarrantyCode: string = '0000000630';

  public readonly shouldShowTypeProposal: boolean = true;

  public readonly shouldValidate1YearMoreDate: boolean = true;

  public readonly alwaysVisibleElementsWarranties: VisibleElementsWarranties[] = [{
    codigoElemento: 'IMROBTRA', // Importe robos trasteros y anexos
    codigoGarantia: '0000000635'
  }];

  /**
   * Warranties header variables
   */
  public readonly warrantiesHeaderConfig = {
    showWarrantiesGroups: true,
    canSelectMultipleWarrantiesGroups: false,
    warrantiesGroupsDisplayMode: WARRANTIES_HEADER_DISPLAY_MODE.INLINE,
    showPackagesGroups: false,
    canSelectMultiplePackagesGroups: false,
    packagesGroupsDisplayMode: WARRANTIES_HEADER_DISPLAY_MODE.INLINE,
  };
  
  public readonly warrantiesHeaderPolicyConfig = {
    showWarrantiesGroups: false,
    canSelectMultipleWarrantiesGroups: false,
    warrantiesGroupsDisplayMode: WARRANTIES_HEADER_DISPLAY_MODE.INLINE,
    showPackagesGroups: true,
    canSelectMultiplePackagesGroups: false,
    packagesGroupsDisplayMode: WARRANTIES_HEADER_DISPLAY_MODE.INLINE,
  };

  /**
   * Factory constructor
   * @param {string} codigoRamo 
   */
  constructor(codigoRamo: string) {
    super();
    this.codigoRamo = codigoRamo as CodigoRamo;
  }

  /**
   * Retrieves product title
   */
  get productTitle(): string {
    return `quoteBuyGenericFlow.titleDefault.${this.codigoRamo}`;
  }

  /**
   * Retrieves product translation label
   */
  get productTranslationLabel(): string {
    return `ofertaEmisionBusiness.productsByRamo.${this.codigoRamo}`;
  }

  /**
   * Retrieves product name
   */
  get productName(): string {
    return EALocaleManager.i18n.t(this.productTranslationLabel) as string;
  }

  /**
   * Create Comunidades Model Instance
   *
   * @return {HogarModel}
   */
  public createModelInstance(): HogarModel {
    return new HogarModel();
  }

  /**
   * Create Comunidades Model Instance
   *
   * @return {HogarModel}
   */
  public createPolicyModelInstance(): HogarPolicyModel {
    return new HogarPolicyModel();
  }

  /**
   * Generates offer data for saving service
   *
   * @param {HogarModel} hogarModel - product model
   * @returns { SaveOfferDataRequestElementosPoliza[] } generated offer
   */
  public generateOfferData(hogarModel: HogarModel): SaveOfferDataRequestElementosPoliza[] {
    const productModel: QbProductoAseguradoHogarModel = hogarModel.productModel;
    const data: SaveOfferDataRequestElementosPoliza[] = [
      {
        codigoElemento: 'CDPAIS',
        valorElemento: productModel.riskSituation.formValues.country,
      },
      {
        codigoElemento: 'TCCOPOST',
        valorElemento: productModel.riskSituation.postCodeLocationInputModel.zipCode,
      },
      {
        codigoElemento: 'CDPOBLA',
        valorElemento: productModel.riskSituation.postCodeLocationInputModel.cityCode || '88000000001',
      },
      {
        codigoElemento: 'NOPOBLSR',
        valorElemento: productModel.riskSituation.
          postCodeLocationInputModel.city.substring(0, 30) || '88000000001',
      },
      {
        codigoElemento: 'DSPROVIN',
        valorElemento: productModel.riskSituation.postCodeLocationInputModel.province,
      },
      {
        codigoElemento: 'TCPROVIN',
        valorElemento: productModel.riskSituation.postCodeLocationInputModel.zipCode?.substring(0, 2),
      },
      {
        codigoElemento: 'CDDIRESR',
        valorElemento: productModel.riskSituation.formValues.roadType,
      },
      {
        codigoElemento: 'NODIRESR',
        valorElemento: productModel.riskSituation.formValues.roadName,
      },
      {
        codigoElemento: 'NUDIRESR',
        valorElemento: Utils.parseNumericValueIfReceived(
          productModel.riskSituation.formValues.roadNumber, 0
        ).toString(),
      },
      {
        codigoElemento: 'CDBLOQSR',
        valorElemento: productModel.riskSituation.formValues.blockStair
      },
      {
        codigoElemento: 'CDESCASR',
        valorElemento: productModel.riskSituation.formValues.stairwell
      },
      {
        codigoElemento: 'CDPLANSR',
        valorElemento: productModel.riskSituation.formValues.floor
      },
      {
        codigoElemento: 'CDPUERSR',
        valorElemento: productModel.riskSituation.formValues.doorNumber
      },
      {
        codigoElemento: 'VAACCDSR',
        valorElemento: productModel.riskSituation.formValues.extraInfo
      },
      {
        codigoElemento: 'TCCLAVIV',
        valorElemento: productModel.buildingType
      },
      {
        codigoElemento: 'TCUBIVIV',
        valorElemento: productModel.buildingLocation
      },
      {
        codigoElemento: 'AACONSTR',
        valorElemento: Utils.parseNumericValueIfReceived(productModel.constructionYear, 0).toString(),
      },
      {
        codigoElemento: 'AAREFORM',
        valorElemento: Utils.parseNumericValueIfReceived(productModel.remodelingYear, 0).toString(),
      },
      {
        codigoElemento: 'TCCARASE',
        valorElemento: productModel.ownerOrRenter
      },
      {
        codigoElemento: 'INUSOVIV',
        valorElemento: productModel.buildingUse
      },
      {
        codigoElemento: 'INUSOSAT',
        valorElemento: productModel.hasTuristicUse ? 'S' : 'N'
      },
      {
        codigoElemento: 'CDCALIVV',
        valorElemento: productModel.quality
      },
      {
        codigoElemento: 'NUSUPVIV',
        valorElemento: Utils.parseNumericValueIfReceived(productModel.propertySize, 0).toString()
      },
      {
        codigoElemento: 'NUSUPJAR',
        valorElemento: Utils.parseNumericValueIfReceived(productModel.exteriorSize, 0).toString()
      },
      {
        codigoElemento: 'NUSUPTOT',
        valorElemento: Utils.parseNumericValueIfReceived(productModel.propertyTotalSize, 0).toString()
      },
      {
        codigoElemento: 'CDFRANQA',
        valorElemento: productModel.franchise
      },
      {
        codigoElemento: 'INPUESEG',
        valorElemento: productModel.hasProtectionDoor ? 'S' : 'N'
      },
      {
        codigoElemento: 'INREJASH',
        valorElemento: productModel.hasProtectionGrate ? 'S' : 'N'
      },
      {
        codigoElemento: 'INALACON',
        valorElemento: productModel.hasProtectionAlarm ? 'S' : 'N'
      },
      {
        codigoElemento: 'INCAJAFU',
        valorElemento: productModel.protectionSafe
      },
      {
        codigoElemento: 'CDPUSEGU',
        valorElemento: productModel.protectionDoor
      },
      {
        codigoElemento: 'CDREJASH',
        valorElemento: productModel.protectionGrate
      },
      {
        codigoElemento: 'INDGGHOG',
        valorElemento: productModel.warranty
      },
      {
        codigoElemento: 'DSSUPERF',
        valorElemento: productModel.riskSituation.postCodeLocationInputModel.poblationArea?.toFixed(3)
      },
    ];

    if (productModel.riskSituation.postCodeLocationInputModel.cityCode) {
      if (productModel.riskSituation.postCodeLocationInputModel.cityCode === '88000000001') {
        data.push({
          codigoElemento: 'DSPOBTOT',
          valorElemento: '0.00'
        });
      } else {
        data.push({
          codigoElemento: 'DSPOBTOT',
          valorElemento:
            productModel.riskSituation.postCodeLocationInputModel.populationNumber?.toFixed(2),
        });
      }
    }

    return data;
  }

  /**
   * Generates offer data for saving service
   *
   * @param {QuoteBuyModel} model - product model
   * @param {string | undefined} filiationCodePagador
   * @returns { any } } generated offer
   */
  public generatePolicyData(
    model: QuoteBuyModel | PolicyModel,
    filiationCodePagador: string | undefined
  ): { codigoElemento: string; valorElemento: string | undefined }[] {
    const pagador = this.personRoles.find(person => person.role === Roles.Pagador);
    const ibanCode = pagador?.ibanCodeModel.qbIbanCodeModalModel.selectedIbanCode;
    const paymentForm = model.offerIssuanceBonusDataFormModel.paymentForms.find(
      payment => payment.nombreRestriccion === model.offerIssuanceBonusDataFormModel.paymentForm ||
      payment.valorRestriccion === model.offerIssuanceBonusDataFormModel.paymentForm
    )?.valorRestriccion;
    const policyElements = [
      {
        codigoElemento: 'TCCANCO1',
        valorElemento: model.offerIssuanceBillingDataFormModel.paymentChannelFirstReceipt,
      },
      {
        codigoElemento: 'TCCANCOS',
        valorElemento: model.offerIssuanceBillingDataFormModel.paymentChannelSuccessiveReceipts,
      },
      {
        codigoElemento: 'CLINTEC1',
        valorElemento: model.offerIssuanceBillingDataFormModel.debtCollectorIntermediaryFirstReceipt,
      },
      {
        codigoElemento: 'CLINTECS',
        valorElemento: model.offerIssuanceBillingDataFormModel.debtCollectorIntermediarySuccessiveReceipts,
      },
      {
        codigoElemento: 'TCFORPAG',
        valorElemento: paymentForm,
      },
      {
        codigoElemento: 'TCFOPASU',
        valorElemento: paymentForm,
      },
      {
        codigoElemento: 'CDIBANUM',
        valorElemento: ibanCode,
      },
      {
        codigoElemento: 'CLFILPAG',
        valorElemento: filiationCodePagador,
      },
      {
        codigoElemento: 'CDSECPAG',
        valorElemento: this.getSecuentialAddressCode(Roles.Pagador),
      },
    ];
    return policyElements;
  }

  /**
   * Gets labels for generate documentation view
   * @param {ProductModel} productModel - product model
   * @param {ParsedTableData[]} roadTypeCorpTable - corp table for address type
   * @returns {GenerateDocumentationViewLabelsModel}
   */
  public getGenerateDocumentationViewLabels(
    productModel: HogarModel | HogarPolicyModel,
    roadTypeCorpTable: ParsedTableData[]
  ): GenerateDocumentationViewLabelsModel {
    const model = productModel.productModel;
    const parsedRoadType = roadTypeCorpTable.find(
      roadType => roadType.value === model.riskSituation.formValues.roadType
    )?.label;
    const proposalSelectionSubtitleData = AddressUtils.normalizeAddress({
      addressType: parsedRoadType,
      addressName: model.riskSituation.formValues.roadName,
      addressNumber: model.riskSituation.formValues.roadNumber,
      blockStair: model.riskSituation.formValues.blockStair,
      stairwell: model.riskSituation.formValues.stairwell,
      floorOrInformacionAdicional: model.riskSituation.formValues.floor,
      doorNumber: model.riskSituation.formValues.doorNumber,
      zipCode: model.riskSituation.postCodeLocationInputModel.zipCode,
      cityName: model.riskSituation.postCodeLocationInputModel.city
    });
    return {
      proposalSelectionTitleLabel: this.productTranslationLabel,
      proposalSelectionSubitleLabel: 'quoteBuyGenericFlow.generateDocumentation.field.riskDirection',
      proposalSelectionSubtitleData: proposalSelectionSubtitleData,
    };
  }

  /**
   * Gets labels for offer issuance data view
   * @param {HogarModel | HogarPolicyModel} productModel
   * @param {ParsedTableData[]} roadTypeCorpTable - corp table for address type
   * @returns {OfferIssuanceDataModel[]}
   */
  public getOfferIssuanceViewLabels(
    productModel: HogarModel | HogarPolicyModel,
    roadTypeCorpTable: ParsedTableData[]
  ): OfferIssuanceDataModel[] {
    const model = productModel.productModel;
    const roadNumber = model.riskSituation.formValues.roadNumber ? ` ${model.riskSituation.formValues.roadNumber}` : '';
    const parsedRoadType = roadTypeCorpTable.find(
      roadType => roadType.value === model.riskSituation.formValues.roadType
    )?.label;
    const roadType = parsedRoadType ? `${parsedRoadType} `: '';

    const data = [
      {
        label: 'quoteBuyGenericFlow.offerIssuanceData.field.riskDirection',
        value:
          // eslint-disable-next-line max-len
          `${roadType}${model.riskSituation.formValues.roadName}${roadNumber}${
            this.getBlockstair(model.riskSituation.formValues.blockStair)
          }${
            this.getStairwell(model.riskSituation.formValues.stairwell)
          }${
            this.getFloorOrInformacionAdicional(model.riskSituation.formValues.floor)
          }${
            this.getDoorNumber(model.riskSituation.formValues.doorNumber)
          } - ${
            model.riskSituation.postCodeLocationInputModel.zipCode
          } ${TextUtils.capitalizeFirstLetterOneWord(
            model.riskSituation.postCodeLocationInputModel.city.toLowerCase()
          )}`,
      },
      {
        label: 'quoteBuyGenericFlow.offerIssuanceData.field.movementEffectDate',
        value: moment(productModel.generalDataInformationModel.movementEfect).isValid() ?
          moment(productModel.generalDataInformationModel.movementEfect).format('DD/MM/YYYY') : '',
      },
      {
        label: 'quoteBuyGenericFlow.offerIssuanceData.field.movementEndDate',
        value: moment(productModel.generalDataInformationModel.movementDue).isValid() ?
          moment(productModel.generalDataInformationModel.movementDue).format('DD/MM/YYYY') : '',
      },
    ];

    if (productModel.datosCabecera.codigoNegocio && productModel.datosCabecera.nombreNegocio) {
      data.push({
        label: productModel.datosCabecera.nombreNegocio,
        value: productModel.datosCabecera.codigoNegocio,
      });
    }

    return data;
  }

  /**
   * Gets block if has
   * @param {string | undefined} block
   * @returns {string}
   */
  public getBlockstair(block?: string | number): string {
    return block !== undefined && block !== '' ? `, Bl.${block}` : '';
  }

  /**
   * Gets stairwell if has
   * @param {string | undefined} stairwell
   * @returns {string}
   */
  public getStairwell(stairwell?: string | number): string {
    return stairwell !== undefined && stairwell !== '' ? `, Esc.${stairwell}` : '';
  }

  /**
   * Gets getFloorOrInformacionAdicional if has
   * @param {string | undefined} floorOrInformacionAdicional
   * @returns {string}
   */
  public getFloorOrInformacionAdicional(floorOrInformacionAdicional?: string): string {
    // eslint-disable-next-line max-len
    return floorOrInformacionAdicional !== undefined && floorOrInformacionAdicional !== '' ? `, ${floorOrInformacionAdicional}` : '';
  }

  /**
   * Gets doorNumber if has
   * @param {string | number | undefined} doorNumber
   * @returns {string}
   */
  public getDoorNumber(doorNumber?: string | number): string {
    // eslint-disable-next-line max-len
    return doorNumber !== undefined && doorNumber !== '' ? `-${doorNumber}` : '';
  }

  /**
   * Validates warranties configuration. Only for 'Comunidades'.
   *
   * @param {GetWarrantiesResponseDataDatosGarantias[]} warranties
   * @returns {EAValidationError[] | undefined}
   * @memberof ProductHogar
   */
  public validateWarranties(warranties: GetWarrantiesResponseDataDatosGarantias[]): EAValidationError[] | undefined {
    const returnedErrors: EAValidationError[] = [];
    return returnedErrors.length === 0 ? undefined : returnedErrors;
  }

  /**
   * Returns the expected secuential address code for a filiation code.
   * @param {Roles} role
   * @returns {string | undefined}
   */
  public getSecuentialAddressCode(
    role: Roles
  ): string | undefined {
    return PersonUtils.getPersonByRole(this.personRoles, role)?.addressesModel.addEditAddress.selectedAddressCode;
  }

  /**
   * Sets default payment channel for this product
   * @param {QbGeneralDataInformationModel} generalDataInformationModel
   */
  public setDefaultPaymentChannel(
    generalDataInformationModel: QbGeneralDataInformationModel
  ): void {
    //
  }
  
  /**
   * Sets default payment channel for this product
   * @param {PersonRole} personModel
   * @param {GetGeneralDataResponseDataDatosObjeto} aditionalData
   * @param {HogarModel} hogarModel
   * @param {boolean} doSaveObjetoAsegurado
   * @returns { SaveOfferDataRequestElementosPoliza[] } generated offer
   */
  public generateAditionalOfferData(
    personModel: PersonRole,
    aditionalData: GetGeneralDataResponseDataDatosObjeto[],
    hogarModel: HogarModel,
    doSaveObjetoAsegurado: boolean
  ): SaveOfferDataRequestElementosPoliza[] {
    const additionalElements: SaveOfferDataRequestElementosPoliza[] = [];
    
    // Score elements
    const scoreElements = this._generateAdditionalScoreElements(personModel, aditionalData);
    additionalElements.push(...scoreElements);
    if (doSaveObjetoAsegurado) {
      // Bank Details aditional elements
      const bankElements = this._generateAdditionalBankElements(aditionalData, hogarModel);
      additionalElements.push(...bankElements);
    }
    
    return additionalElements;
  }

  /**
   * Sets default payment channel for this product
   * @param {PersonRole} personModel
   * @param {GetGeneralDataResponseDataDatosObjeto} aditionalData
   * @returns { SaveOfferDataRequestElementosPoliza[] } generated offer
   */
  public _generateAdditionalScoreElements(
    personModel: PersonRole,
    aditionalData: GetGeneralDataResponseDataDatosObjeto[]
  ): SaveOfferDataRequestElementosPoliza[] {
    const additionalElements: SaveOfferDataRequestElementosPoliza[] = [];
    
    const tipoPersona = personModel.searchModel.selectedPerson?.datosBasicosPersona?.tipoPersona;
    if (aditionalData.find(el => el.codigoElemento === 'TCPFJSCR')) {
      additionalElements.push({
        codigoElemento: 'TCPFJSCR',
        valorElemento: tipoPersona
      });
    }
    if (aditionalData.find(el => el.codigoElemento === 'FENACSCR')
      && tipoPersona === GetPersonsResponseListaPersonasTipoPersonaEnum.F
    ) {
      // Only add FENACSCR if additional elements exists and it's a physical person
      const birthDate: Date = new Date(personModel.showPersonInfoModel.birthDate);
      additionalElements.push({
        codigoElemento: 'FENACSCR',
        valorElemento: moment(birthDate).format('YYYYMMDD') // Example date format: 19991231
      });
    }
    if (aditionalData.find(el => el.codigoElemento === 'CDPAISCR')) {
      additionalElements.push({
        codigoElemento: 'CDPAISCR',
        valorElemento: personModel.showPersonInfoModel.country
      });
    }
    const foundTipodocumento = aditionalData.find(el => el.codigoElemento === 'TCDOCSCR');
    if (foundTipodocumento) {
      additionalElements.push({
        codigoElemento: 'TCDOCSCR',
        valorElemento: this.mapRestrictionFromNameToValue(
          personModel.showPersonInfoModel.documentType,
          foundTipodocumento.tablaRestricciones || []
        )
      });
    }
    if (aditionalData.find(el => el.codigoElemento === 'CDNIFSCR')) {
      additionalElements.push({
        codigoElemento: 'CDNIFSCR',
        valorElemento: personModel.showPersonInfoModel.documentNumber
      });
    }
    if (aditionalData.find(el => el.codigoElemento === 'TCPOSSCR')) {
      additionalElements.push({
        codigoElemento: 'TCPOSSCR',
        valorElemento: personModel.showPersonInfoModel.postalCodeModel.zipCode
      });
    }

    return additionalElements;
  }

  /**
   * Sets default payment channel for this product
   * @param {GetGeneralDataResponseDataDatosObjeto} aditionalData
   * @param {HogarModel} hogarModel
   * @returns { SaveOfferDataRequestElementosPoliza[] } generated offer
   */
  public _generateAdditionalBankElements(
    aditionalData: GetGeneralDataResponseDataDatosObjeto[],
    hogarModel: HogarModel
  ): SaveOfferDataRequestElementosPoliza[] {
    const productModel: QbProductoAseguradoHogarModel = hogarModel.productModel;
    const additionalElements: SaveOfferDataRequestElementosPoliza[] = [];

    if (aditionalData.find(el => el.codigoElemento === 'DSNUCODB')) {
      additionalElements.push({
        codigoElemento: 'DSNUCODB',
        valorElemento: productModel.bankDetails.formValues.contractNumber
      });
    }
    if (aditionalData.find(el => el.codigoElemento === 'DSNUSODB')) {
      additionalElements.push({
        codigoElemento: 'DSNUSODB',
        valorElemento: productModel.bankDetails.formValues.applicationNumber
      });
    }
    if (aditionalData.find(el => el.codigoElemento === 'CDOFICDB')) {
      additionalElements.push({
        codigoElemento: 'CDOFICDB',
        valorElemento: productModel.bankDetails.formValues.officeCode
      });
    }
    if (aditionalData.find(el => el.codigoElemento === 'DSOFICDB')) {
      additionalElements.push({
        codigoElemento: 'DSOFICDB',
        valorElemento: productModel.bankDetails.formValues.officeDescription
      });
    }
    if (aditionalData.find(el => el.codigoElemento === 'NODIRADB')) {
      additionalElements.push({
        codigoElemento: 'NODIRADB',
        valorElemento: productModel.bankDetails.formValues.agencyAddress
      });
    }
    if (aditionalData.find(el => el.codigoElemento === 'TCCOPADB')) {
      additionalElements.push({
        codigoElemento: 'TCCOPADB',
        valorElemento: productModel.bankDetails.postCodeLocationInputModel.zipCode
      });
    }
    if (aditionalData.find(el => el.codigoElemento === 'NOPOBADB')) {
      additionalElements.push({
        codigoElemento: 'NOPOBADB',
        valorElemento: productModel.bankDetails.postCodeLocationInputModel.city
      });
    }
    if (aditionalData.find(el => el.codigoElemento === 'DSPROADB')) {
      additionalElements.push({
        codigoElemento: 'DSPROADB',
        valorElemento: productModel.bankDetails.postCodeLocationInputModel.province
      });
    }

    return additionalElements;
  }

  /**
   * Maps from restriction name to restriction value if found relationship.
   * @param {string} name
   * @param {GetGeneralDataResponseDataDatosCabeceraTablaRestricciones[]} tablaRestricciones 
   * @returns {string}
   */
  mapRestrictionFromNameToValue(
    name: string,
    tablaRestricciones: GetGeneralDataResponseDataDatosCabeceraTablaRestricciones[]
  ): string {
    const foundRestriccion = tablaRestricciones.find(restriccion => restriccion.nombreRestriccion === name);
    // eslint-disable-next-line @typescript-eslint/no-extra-parens
    return foundRestriccion ? (foundRestriccion.valorRestriccion || '') : name;
  }
}

export default ProductHogar;
