<template>
  <div>
    <ea-heading level="5" class="m-b-16">
      {{ $t('quoteBuyGenericFlow.technicalConsiderationsBusiness.title') }}
    </ea-heading>

    <ea-form
      ref="form"
      :model="model.formValues"
      :validationOptions="formValidationOptions"
      :validateOnRuleChange="false"
    >
      <ea-row>
        <ea-col :span="12" v-if="existsElement('CDACTVEL', datosObjeto)">
          <!-- Tipo de la obra -->
          <ea-form-item
            :label="$t('quoteBuyGenericFlow.technicalConsiderationsBusiness.field.constructionType')"
            prop="constructionType"
            :required="isElementRequired('CDACTVEL', datosObjeto)"
          >
            <ea-select
              v-model="model.formValues.constructionType"
              :readonly="!isElementModificable('CDACTVEL', datosObjeto)"
              @change="onConstructionTypeChange"
            >
              <ea-option
                v-for="constructionType in constructionTypeList"
                :key="constructionType.value"
                :label="constructionType.label"
                :value="constructionType.value"
              />
            </ea-select>
          </ea-form-item>
        </ea-col>

        <ea-col :span="12" v-if="existsElement('CDCONTEC', datosObjeto) && model.formValues.technicalConsiderations">
          <!-- Consideraciones técnicas -->
          <ea-form-item
            :label="$t('quoteBuyGenericFlow.technicalConsiderationsBusiness.field.technicalConsiderations')"
            prop="technicalConsiderations"
            :required="isElementRequired('CDCONTEC', datosObjeto)"
          >
            <ea-select
              v-model="model.formValues.technicalConsiderations"
              readonly
            >
              <ea-option
                v-for="technicalConsideration in technicalConsiderationsList"
                :key="technicalConsideration.value"
                :label="technicalConsideration.label"
                :value="technicalConsideration.value"
              />
            </ea-select>
          </ea-form-item>
        </ea-col>
      </ea-row>

      <ea-row v-if="existsElement('CDDSTRAB', datosObjeto) && isWorkDescriptionShown">
        <ea-col :span="18">
          <!-- Descripción de la obra -->
          <ea-form-item
            prop="workDescription"
            :label="$t('quoteBuyGenericFlow.technicalConsiderationsBusiness.field.workDescription')"
            :required="isElementRequired('CDDSTRAB', datosObjeto)">
            <ea-select
              v-model="model.formValues.workDescription"
              :readonly="!isElementModificable('CDDSTRAB', datosObjeto) || areWorkDescriptionFieldsDisabled"
              @change="onWorkDescriptionChange"
            >
              <ea-option
                v-for="workDescription in workDescriptionList"
                :key="workDescription.value"
                :label="workDescription.label"
                :value="workDescription.value"
              />
            </ea-select>
          </ea-form-item>
        </ea-col>
      </ea-row>

      <ea-row v-if="isWorkDetailedDescriptionFieldShown">
        <ea-col :span="18">
          <!-- Descripción detallada de la obra -->
          <ea-form-item
            prop="workDetailedDescription"
            :label="$t('quoteBuyGenericFlow.technicalConsiderationsBusiness.field.workDetailedDescription')"
            :required="isElementRequired('TXDSRIE1', datosObjeto) && employeeUser">
            <ea-input-textarea
              :maxlength="returnElementLengthByCode('TXDSRIE1', datosObjeto)"
              :readonly="!isElementModificable('TXDSRIE1', datosObjeto) || areWorkDescriptionFieldsDisabled"
              v-model="model.formValues.workDetailedDescription" />
          </ea-form-item>
        </ea-col>

      </ea-row>

      <ea-row>
        <ea-col :span="6" v-if="existsElement('NUPLASOB', datosObjeto)">
          <ea-form-item
            :label="$t('quoteBuyGenericFlow.technicalConsiderationsBusiness.field.numberBuildingPlant')"
            prop="numberBuildingPlant"
            :required="isElementRequired('NUPLASOB', datosObjeto)"
          >
            <ea-input-number
              v-model="model.formValues.numberBuildingPlant"
              :readonly="!isElementModificable('NUPLASOB', datosObjeto)"
            />
          </ea-form-item>
        </ea-col>

        <ea-col :span="6" v-if="existsElement('NUSOTANO', datosObjeto)">
          <ea-form-item
            :label="$t('quoteBuyGenericFlow.technicalConsiderationsBusiness.field.numberBasements')"
            prop="numberBasements"
            :required="isElementRequired('NUSOTANO', datosObjeto)"
          >
            <ea-input-number
              v-model="model.formValues.numberBasements"
              :readonly="!isElementModificable('NUSOTANO', datosObjeto)"
            />
          </ea-form-item>
        </ea-col>

        <ea-col :span="6" v-if="existsElement('CDPENTER', datosObjeto)">
          <ea-form-item
            :label="$t('quoteBuyGenericFlow.technicalConsiderationsBusiness.field.landSlope')"
            prop="landSlope"
            :required="isElementRequired('CDPENTER', datosObjeto)"
          >
            <ea-select
              v-model="model.formValues.landSlope"
              :readonly="!isElementModificable('CDPENTER', datosObjeto)">
              <ea-option
                v-for="landSlope in landSlopeList"
                :key="landSlope.value"
                :label="landSlope.label"
                :value="landSlope.value"
              />
            </ea-select>
          </ea-form-item>
        </ea-col>
      </ea-row>

      <ea-row>
        <ea-col :span="6" v-if="existsElement('IMSADAOB', datosObjeto)">
          <ea-form-item
            :label="$t('quoteBuyGenericFlow.technicalConsiderationsBusiness.field.totalConstructionValue')"
            prop="totalConstructionValue"
            :required="isElementRequired('IMSADAOB', datosObjeto)"
          >
            <ea-input-currency
              v-model="model.formValues.totalConstructionValue"
              :readonly="!isElementModificable('IMSADAOB', datosObjeto)"
              :precision="2"
            />
          </ea-form-item>
        </ea-col>

        <ea-col :span="6" v-if="existsElement('CDFQDAOB', datosObjeto)">
          <ea-form-item
            :label="$t('quoteBuyGenericFlow.technicalConsiderationsBusiness.field.franchise')"
            prop="franchise"
            :required="isElementRequired('CDFQDAOB', datosObjeto)"
          >
            <ea-select
              v-model="model.formValues.franchise"
              :readonly="!isElementModificable('CDFQDAOB', datosObjeto)">
              <ea-option
                v-for="franchise in franchiseList"
                :key="franchise.value"
                :label="franchise.label"
                :value="franchise.value"
              />
            </ea-select>
          </ea-form-item>
        </ea-col>

      </ea-row>

      <ea-row>
        <ea-col :span="6" v-if="existsElement('DUOBRAMO', datosObjeto)">
          <ea-form-item
            :label="$t('quoteBuyGenericFlow.technicalConsiderationsBusiness.field.constructionTime')"
            prop="constructionTime"
            :required="isElementRequired('DUOBRAMO', datosObjeto)"
          >
            <ea-input-number
              v-model="model.formValues.constructionTime"
              :readonly="!isElementModificable('DUOBRAMO', datosObjeto)"
              @change="onUpdateEndDateValue"
            />
          </ea-form-item>
        </ea-col>

        <ea-col :span="6" v-if="existsElement('FEINIOBM', datosObjeto)">
          <ea-form-item
            :label="$t('quoteBuyGenericFlow.technicalConsiderationsBusiness.field.startDate')"
            prop="startDate"
            :required="isElementRequired('FEINIOBM', datosObjeto)"
          >
            <ea-date-picker
              v-model="model.formValues.startDate"
              size="small"
              @change="onUpdateEndDateValue"
              :readonly="!isElementModificable('FEINIOBM', datosObjeto)"
            />
          </ea-form-item>
        </ea-col>

        <ea-col :span="6" v-if="existsElement('FEFINOBM', datosObjeto) && isShowEndDate">
          <ea-form-item
            :label="$t('quoteBuyGenericFlow.technicalConsiderationsBusiness.field.endDate')"
            prop="endDate"
          >
            <ea-date-picker
              v-model="model.formValues.endDate"
              size="small"
              readonly
            />
          </ea-form-item>
        </ea-col>
      </ea-row>

      <ea-heading level="5" class="m-b-16">
      {{ $t('quoteBuyGenericFlow.technicalConsiderationsBusiness.constructionMaterials') }}
      </ea-heading>

      <ea-row>
        <ea-col :span="6" class="p-t-8" v-if="existsElement('CDOBREMP', datosObjeto)">
          <ea-form-item
            :label="$t('quoteBuyGenericFlow.technicalConsiderationsBusiness.field.advancedWork')"
            prop="advancedWork"
            :required="isElementRequired('CDOBREMP', datosObjeto)"
          >
            <ea-select
              v-model="model.formValues.advancedWork"
              :readonly="!isElementModificable('CDOBREMP', datosObjeto)">
              <ea-option
                v-for="advancedWork in advancedWorkList"
                :key="advancedWork.value"
                :label="advancedWork.label"
                :value="advancedWork.value"
              />
            </ea-select>
          </ea-form-item>
        </ea-col>
      </ea-row>

      <ea-row>
        <ea-col :span="6" v-if="existsElement('INOBRPAR', datosObjeto)">
          <ea-form-item
            prop="paralisedOrIncludedConstruction"
          >
            <div class="d-display-flex d-direction-horizontal d-align-items-center m-b-8">
              <span class="m-r-8">
                {{ $t('quoteBuyGenericFlow.technicalConsiderationsBusiness.field.paralisedOrIncludedConstruction') }}
              </span>
              <ea-radio-group
                v-model="model.formValues.paralisedOrIncludedConstruction"
                direction="horizontal"
              >
                <ea-radio
                  :label="true"
                  :readonly="!isElementModificable('INOBRPAR', datosObjeto)"
                  class="m-8">{{ $t('common.label.yes') }}
                </ea-radio>
                <ea-radio
                  :label="false"
                  :readonly="!isElementModificable('INOBRPAR', datosObjeto)"
                  class="m-8">{{ $t('common.label.no') }}
                </ea-radio>
              </ea-radio-group>
            </div>
          </ea-form-item>
        </ea-col>

        <ea-col :span="6" v-if="existsElement('INPANSAN', datosObjeto)">
            <ea-form-item
              prop="sandwichPanels"
            >
              <div class="d-display-flex d-direction-horizontal d-align-items-center m-b-8">
                <span class="m-r-8">
                  {{ $t('quoteBuyGenericFlow.technicalConsiderationsBusiness.field.sandwichPanels') }}
                </span>
                <ea-radio-group
                  v-model="model.formValues.sandwichPanels"
                  direction="horizontal"
                >
                  <ea-radio
                    :label="true"
                    :readonly="!isElementModificable('INPANSAN', datosObjeto)"
                    class="m-8">{{ $t('common.label.yes') }}
                  </ea-radio>
                  <ea-radio
                    :label="false"
                    :readonly="!isElementModificable('INPANSAN', datosObjeto)"
                    class="m-8">{{ $t('common.label.no') }}
                  </ea-radio>
                </ea-radio-group>
              </div>
            </ea-form-item>
        </ea-col>

        <ea-col :span="12" v-if="existsElement('INELEPRE', datosObjeto)">
            <ea-form-item
              prop="premadeOrWoodenElementsConstruction"
            >
              <div class="d-display-flex d-direction-horizontal d-align-items-center m-b-8">
                <span class="m-r-8">
                  {{$t('quoteBuyGenericFlow.technicalConsiderationsBusiness.field.premadeOrWoodenElementsConstruction'
                  ) }}
                </span>
                <ea-radio-group
                  v-model="model.formValues.premadeOrWoodenElementsConstruction"
                  direction="horizontal"
                >
                  <ea-radio
                    :label="true"
                    :readonly="!isElementModificable('INELEPRE', datosObjeto)"
                    class="m-8">{{ $t('common.label.yes') }}
                  </ea-radio>
                  <ea-radio
                    :label="false"
                    :readonly="!isElementModificable('INELEPRE', datosObjeto)"
                    class="m-8">{{ $t('common.label.no') }}
                  </ea-radio>
                </ea-radio-group>
              </div>
            </ea-form-item>
        </ea-col>
      </ea-row>
    </ea-form>
  </div>
</template>

<script lang="ts">
import {
  Component, Prop
} from 'vue-property-decorator';

import {
  mixins
} from 'vue-class-component';

import {
  EABusinessComponent, EAInfoDateConverter, EAMethod, ResponseWithErrors, throwIfResponseHasErrors,
  EAApplicationError,
} from '@zurich-es-npm/ea-front-web-core';

import QbTechnicalConsiderationsModel, {
  FormValues
} from './qb-technical-considerations-model';
import {
  EAValidation,
  EAFormValidationOptions,
  Form
} from '@zurich-es-npm/ea-front-web-ui';
import {
  GetGeneralDataResponseDataDatosObjeto
} from '@/services/V1/quoteAndBuy/getGeneralDataOperation/post';
import {
  EAGetObjectElementsDefaultValuesApi, GetObjectElementsDefaultValuesRequestElementosObjeto
} from '@/services/V1/quoteAndBuy/getObjectElementsDefaultValuesOperation/post';
import {
  LabelValue
} from '../qb-producto-asegurado-comunidades/qb-producto-asegurado-comunidades-model';
import {
  GetDefaultUserValuesResponseCodigoTipoUsuarioEnum as UserTypesEnum
} from '@/services/V1/home/getDefaultUserValuesOperation/post';
import {
  getFieldRules,
  isElementModificable,
  isElementRequired,
  returnElementListByCode,
  returnElementValueByCode,
  returnNumericElementValueByCode,
  existsElement,
  returnElementLengthByCode
} from '@/utils/object-utils';
import moment from 'moment';
import PermissionUtils from '@/utils/permissions-utils';
import {
  CorpTableNames,
  fetchCorporateTable,
  parseCorpTableDocuments
} from '@/utils/corporate-tables';
import Utils from '@/utils/utils';

@Component({
  name: 'qb-technical-considerations',
})

/**
 * Business Component qb-technical-considerations
 */
export default class QbTechnicalConsiderationsBusiness extends
  mixins<EABusinessComponent<QbTechnicalConsiderationsModel>>(EABusinessComponent) {
  
  @Prop({
    required: true,
  })
    datosObjeto!: GetGeneralDataResponseDataDatosObjeto[];

  @Prop({
    'default': () => false
  })
    consultaOperation?: boolean;

  public constructionTypeList: LabelValue[] = [];

  public landSlopeList: LabelValue[] = [];

  public franchiseList: LabelValue[] = [];

  public workDescriptionList: LabelValue[] = [];

  public technicalConsiderationsList: LabelValue[] = [];

  public advancedWorkList: LabelValue[] = [];

  public isElementRequired = isElementRequired;

  public isElementModificable = isElementModificable;

  public existsElement = existsElement;

  public returnElementLengthByCode = returnElementLengthByCode;

  public employeeUser: boolean = false;

  public readonly WORK_DESCRIPTION_FREE_TEXT_VALUE = '99';

  public componentCreationFinished = false;

  /**
   * Gets form validation options
   */
  get formValidationOptions(): EAFormValidationOptions {
    return {
      rules: {
        constructionType: getFieldRules(this.model.FIELD_TYPES.STRING, 'CDACTVEL', this.datosObjeto),
        constructionTime: getFieldRules(this.model.FIELD_TYPES.NUMBER, 'DUOBRAMO', this.datosObjeto),
        numberBuildingPlant: getFieldRules(this.model.FIELD_TYPES.NUMBER, 'NUPLASOB', this.datosObjeto),
        numberBasements: getFieldRules(this.model.FIELD_TYPES.NUMBER, 'NUSOTANO', this.datosObjeto),
        landSlope: getFieldRules(this.model.FIELD_TYPES.STRING, 'CDPENTER', this.datosObjeto),
        totalConstructionValue: getFieldRules(this.model.FIELD_TYPES.NUMBER, 'IMSADAOB', this.datosObjeto),
        franchise: getFieldRules(this.model.FIELD_TYPES.STRING, 'CDFQDAOB', this.datosObjeto),
        startDate: getFieldRules(this.model.FIELD_TYPES.DATE, 'FEINIOBM', this.datosObjeto),
        endDate: getFieldRules(this.model.FIELD_TYPES.DATE, 'FEFINOBM', this.datosObjeto),
        advancedWork: getFieldRules(this.model.FIELD_TYPES.STRING, 'CDOBREMP', this.datosObjeto),
        workDescription: this.workDescriptionList.length ?
          getFieldRules(this.model.FIELD_TYPES.STRING, 'CDDSTRAB', this.datosObjeto) : [],
        workDetailedDescription: this.employeeUser ?
          getFieldRules(this.model.FIELD_TYPES.STRING, 'TXDSRIE1', this.datosObjeto) :
          [],
      },
    };
  }

  /**
   * If exist constructionTime and startDate show endDate
   */
  get isShowEndDate(): boolean {
    return !!this.model.formValues.constructionTime && !!this.model.formValues.startDate;
  }

  /**
   * Hook on created.
   */
  async created() {
    const userType = PermissionUtils.getUserType();
    this.employeeUser = PermissionUtils.isUserOfType(userType, UserTypesEnum.EMP);

    this.constructionTypeList = returnElementListByCode('CDACTVEL', this.datosObjeto) ?? [];
    this.landSlopeList = Utils.sortObjectArrayByProperty(
      returnElementListByCode('CDPENTER', this.datosObjeto) as LabelValue[], 'value') ?? [];
    this.franchiseList = returnElementListByCode('CDFQDAOB', this.datosObjeto) ?? [];
    this.technicalConsiderationsList = returnElementListByCode('CDCONTEC', this.datosObjeto) ?? [];
 
    this.advancedWorkList = Utils.sortObjectArrayByProperty(
      returnElementListByCode('CDOBREMP', this.datosObjeto) as LabelValue[], 'value') ?? [];
    this.assignValue('constructionType', returnElementValueByCode('CDACTVEL', this.datosObjeto));
    this.assignValue('workDescription', returnElementValueByCode('CDDSTRAB', this.datosObjeto));
    await this.retrieveWorkDescriptionTable();
    this.assignFormValues(this.datosObjeto);

    await this.onConstructionTypeChange();
    this.componentCreationFinished = true;
  }

  /**
   * Assigns form values
   * @param {
   *  GetGeneralDataResponseDataDatosObjeto[] |
   *  GetObjectElementsDefaultValuesRequestElementosObjeto[] 
   * } datosObjeto 
   */
  assignFormValues(
    datosObjeto: GetGeneralDataResponseDataDatosObjeto[] | GetObjectElementsDefaultValuesRequestElementosObjeto[]
  ): void {
    this.assignValue('constructionType', returnElementValueByCode('CDACTVEL', datosObjeto));
   
    if (this.model.formValues.constructionType) {
      this.assignValue('technicalConsiderations', returnElementValueByCode('CDCONTEC', datosObjeto), true);
    }

    this.assignValue('landSlope', returnElementValueByCode('CDPENTER', datosObjeto));
    this.assignValue('numberBuildingPlant', returnNumericElementValueByCode('NUPLASOB', datosObjeto));
    this.assignValue('numberBasements', returnNumericElementValueByCode('NUSOTANO', datosObjeto));
    this.assignValue('constructionTime', returnNumericElementValueByCode('DUOBRAMO', datosObjeto));
    this.assignValue('totalConstructionValue', returnNumericElementValueByCode('IMSADAOB', datosObjeto));
    this.assignValue('franchise', returnElementValueByCode('CDFQDAOB', datosObjeto));

    this.assignValue('startDate', returnElementValueByCode('FEINIOBM', datosObjeto) ?
      new Date(EAInfoDateConverter.convertInfoDateToRFC3339(
        parseInt(returnElementValueByCode('FEINIOBM', datosObjeto) || '')
      )) : undefined
    );
    this.assignValue('endDate', returnElementValueByCode('FEFINOBM', datosObjeto) ?
      new Date(EAInfoDateConverter.convertInfoDateToRFC3339(
        parseInt(returnElementValueByCode('FEFINOBM', datosObjeto) || '')
      )) : undefined
    );

    this.assignWorkDescriptionValues(datosObjeto);

    this.assignValue('advancedWork', returnElementValueByCode('CDOBREMP', datosObjeto));
    this.assignValue('sandwichPanels', returnElementValueByCode('INPANSAN', datosObjeto) === 'S');
    this.assignValue('premadeOrWoodenElementsConstruction', returnElementValueByCode('INELEPRE', datosObjeto) === 'S');
    this.assignValue('paralisedOrIncludedConstruction', returnElementValueByCode('INOBRPAR', datosObjeto) === 'S');
  }

  /**
   * Assign work description and work type description values
   * @param {
   *  GetGeneralDataResponseDataDatosObjeto[] | GetObjectElementsDefaultValuesRequestElementosObjeto[]
   * } datosObjeto
   * @returns {void}
   */
  assignWorkDescriptionValues(
    datosObjeto: GetGeneralDataResponseDataDatosObjeto[] | GetObjectElementsDefaultValuesRequestElementosObjeto[]
  ): void {
    const workDescription = returnElementValueByCode('CDDSTRAB', datosObjeto);

    if (!workDescription) {
      return;
    }

    const workDescriptionListSelectedElement = this.workDescriptionList.find(
      element => element.value === workDescription
    );
    this.assignValue('workDescription', workDescriptionListSelectedElement?.value ?? '');

    if (
      this.model.formValues.workDescription !== this.WORK_DESCRIPTION_FREE_TEXT_VALUE &&
      this.model.formValues.workDescription !== ''
    ) {
      this.model.formValues.workDetailedDescription = workDescriptionListSelectedElement?.label;
      this.assignValue('workDetailedDescription', workDescriptionListSelectedElement?.label);
    } else {
      this.assignValue('workDetailedDescription',
        // eslint-disable-next-line max-len
        `${returnElementValueByCode('TXDSRIE1', datosObjeto)}${returnElementValueByCode('TXDSRIE2', datosObjeto)}${returnElementValueByCode('TXDSRIE3', datosObjeto)}`
      );
    }
  }

  /**
   * Assigns value to form element if it has no value
   * @param { string } modelKey 
   * @param { string | number | Date | boolean | undefined } newValue 
   * @param { boolean } overrideOldValue 
   */
  public assignValue(
    modelKey: keyof FormValues,
    newValue?: string | number | Date | boolean,
    overrideOldValue = false
  ): void {
    if (newValue === undefined) {
      return;
    }
    if (!this.model.formValues[modelKey] || (
      overrideOldValue || this.model.formValues[modelKey] === '')
    ) {
      this.model.formValues = {
        ...this.model.formValues,
        [modelKey]: newValue
      };
      this.update();
    }
  }

  /**
   * Returns validation object
   * @returns { EAValidation }
   */
  public validation(): EAValidation {
    this.clearFlowErrors();
    if (!this.workDescriptionList.length && this.model.formValues.constructionType) {
      // Throw error upwards to customize and avoid validation of the form
      throw new EAApplicationError('ZON00209');
    }
    const form = this.$refs.form as Form;
    return form.validation();
  }

  /**
   * Set endDate value
   * Updates date from general data info with the dates from qb-technical-considerations for trc
   */
  onUpdateEndDateValue() {
    if (this.isShowEndDate) {
      this.model.formValues.endDate = this.calculateEndDate();
      this.$emit('updateDates', this.model.formValues);
    }
    this.update();
  }

  /**
   * Calculates the date for endDate with constructionTime (months) and startDate
   * @return { Date }
   */
  calculateEndDate(): Date {
    const rdo = moment(this.model.formValues.startDate).add(this.model.formValues.constructionTime, 'months');
     
    return moment(rdo).toDate();
  }
  

  /**
   * Update view when "constructionType" is changed.
   * @param {string | undefined} newValue
   */
  @EAMethod({
    loading: true
  })
  public async onConstructionTypeChange() {
    this.clearFlowErrors();
    await this.$nextTick(); // Await for model to be updated
    if (!this.model.formValues.constructionType || this.model.formValues.constructionType === '') {
      return;
    }
    await Promise.all([
      this.updateObjectElementsDefaultValues(),
      this.retrieveWorkDescriptionTable()
    ]);
    this.filterFreeTextValueIfNecessary();
  }

  /**
   * Filters "Free text" work description value if user is not employee
   */
  filterFreeTextValueIfNecessary() {
    if (!this.employeeUser) {
      this.workDescriptionList = this.workDescriptionList.filter(
        workDescriptionEl => workDescriptionEl.value !== this.WORK_DESCRIPTION_FREE_TEXT_VALUE
      );
    }
  }

  /**
   * Updates default value for form elements
   */
  public async updateObjectElementsDefaultValues(): Promise<void> {
    const objectElementResponse = await this.fetchObjectElementsDefaultValues();
    if (objectElementResponse) {
      this.assignFormValues(objectElementResponse);
    }
  }

  /**
   * Retrieves and filters work description element's table
   */
  public async retrieveWorkDescriptionTable(): Promise<void> {
    const table = await fetchCorporateTable(CorpTableNames.WorkDescriptions);
    table.data.tableDocuments = this.getFilteredWorkDescriptionList(table.data.tableDocuments);
    this.workDescriptionList = parseCorpTableDocuments(table, 'DSDESCR3', 'CDAGRTTR');
    this.clearWorkDescriptionValueIfNecessary();
  }

  /**
   * Retrieves filtered work description list
   * @param {any[]} tableDocuments
   * @returns {any}
   */
  getFilteredWorkDescriptionList(tableDocuments: {
    [field: string]: string;
    companyCode: string;
  }[]) {
    let filteredDocs = tableDocuments.filter(
      tableRow => tableRow.CDACTVEL === this.model.formValues.constructionType
    );

    if (
      !this.employeeUser && !this.consultaOperation &&
      this.model.formValues.workDescription !== this.WORK_DESCRIPTION_FREE_TEXT_VALUE
    ) {
      // Remove "Free text" options for not employee user when maintaining offer/policy + Free Text option not selected
      filteredDocs = filteredDocs.filter(tableRow => tableRow.CDAGRTTR !== this.WORK_DESCRIPTION_FREE_TEXT_VALUE);
    }

    if (!this.consultaOperation) {
      // Remove "N/A" options for all user when maintaining offer/policy
      filteredDocs = filteredDocs.filter(tableRow => tableRow.CDAGRTTR !== '88');
      

      if (!filteredDocs.length && this.model.formValues.constructionType) {
        // We empty this.workDescriptionList to hide the field
        this.workDescriptionList = [];
        this.assignFormValues(this.datosObjeto);
        // Throw error upwards to customize and avoid validation of the form
        throw new EAApplicationError('ZON00209');
      }
    }

    return filteredDocs;
  }

  /**
   * Calls bff for fetching object element defaut values
   * @return {Promise<GetObjectElementsDefaultValuesRequestElementosObjeto[] | undefined>}
   */
  public async fetchObjectElementsDefaultValues():
  Promise<GetObjectElementsDefaultValuesRequestElementosObjeto[] | undefined> {
    const api = new EAGetObjectElementsDefaultValuesApi();
    const response = await api.getObjectElementsDefaultValuesOperation({
      getObjectElementsDefaultValuesRequest: {
        elementosObjeto: [
          {
            codigoElemento: 'CDACTVEL',
            valorElemento: this.model.formValues.constructionType
          }
        ]
      }
    });
    if (response) {
      throwIfResponseHasErrors(response as ResponseWithErrors);
      return response.elementosObjeto;
    }
  }

  /**
   * Triggers when workDescription field changes
   * Copies its value to workDetailedDescription field when field is not disabled
   */
  public async onWorkDescriptionChange() {
    await this.$nextTick();
    this.model.formValues.workDetailedDescription = '';

    if (this.model.formValues.workDescription === this.WORK_DESCRIPTION_FREE_TEXT_VALUE) {
      return;
    }

    this.model.formValues.workDetailedDescription = this.workDescriptionList.find(
      element => element.value === this.model.formValues.workDescription
    )?.label;
  }

  /**
   * Getter for modificable property in work detailed description element
   * True if selected work type description equals free text value
   */
  get areWorkDescriptionFieldsDisabled() {
    return this.model.formValues.workDescription === this.WORK_DESCRIPTION_FREE_TEXT_VALUE &&
      !this.employeeUser;
  }

  /**
   * Clears work description fields when component creation has finished
   *  as we don't want to reset the fields when form is assigning value for 1st time on creation phase
   */
  clearWorkDescriptionValueIfNecessary() {
    if (this.componentCreationFinished) {
      this.model.formValues.workDescription = '';
      this.model.formValues.workDetailedDescription = '';
    }
  }
  
  /**
   * Getter for visible property in work type description element
   * False if readonly; True if otherwise and (list has options to select)
   */
  get isWorkDescriptionShown() {
    if (this.consultaOperation) {
      return false;
    }

    return this.workDescriptionList.length;
  }
  
  /**
   * Getter for visible property in work description element
   * True if (readonly mode and has value) or
   *  (user is employee and selected work type description equals free text value)
   */
  get isWorkDetailedDescriptionFieldShown() {
    return this.consultaOperation || this.model.formValues.workDescription === this.WORK_DESCRIPTION_FREE_TEXT_VALUE;
  }
}
</script>
