<template>
  <div>
    <div>
      <competences-data
        id="competencesData"
        :competencesData="competencesData"
        @sendBtnDisabled="sendBtnDisabled"
        v-model="model.competences"
      ></competences-data>

      <qb-observations-authorization
        id="observations"
        ref="observations"
        v-model="model.observations"
      ></qb-observations-authorization>

      <competences-extra-data-employee
        v-if="employeeUser"
        id="competencesExtraDataEmployee"
        ref="competencesExtraDataEmployee"
        v-model="model.extraDataEmployee"
        :competencesData="competencesData"
      ></competences-extra-data-employee>

      <ea-row class="m-t-16">
        <ea-col :span="12" class="d-display-flex d-justify-flex-start">
          <ea-button type="secondary" @click="onClose(false)" size="medium">
            {{ $t('common.label.close') }}
          </ea-button>
        </ea-col>
        <ea-col :span="12" class="d-display-flex d-justify-flex-end">
          <ea-button @click="onSendNotes" type="primary" size="medium" :disabled="sendDisabled">
            {{ $t('common.label.send') }}
          </ea-button>
        </ea-col>
      </ea-row>
    </div>
  </div>
</template>

<script lang="ts">
import {
  Component
} from 'vue-property-decorator';

import {
  mixins
} from 'vue-class-component';

import {
  EAView,
  EAMethod,
  EAValidationError,
  throwIfResponseHasErrors,
  ResponseWithErrors,
  EAContextManager,
  EAFlowNavigationUtils,
  EAFlowItem
} from '@zurich-es-npm/ea-front-web-core';

import {
  CompetencesModel
} from '../competences-model';
import CompetencesDataBusiness from '@/business-components/competences-data/competences-data-business.vue';
import QbObservationsAuthorizationBusiness
  from '@/business-components/qb-observations-authorization/qb-observations-authorization-business.vue';
import {
  EACompositeValidation,
  EAValidation, Form
} from '@zurich-es-npm/ea-front-web-ui';
import {
  EASendCompetencyNoteApi
} from '@/services/V1/gestion-competencias/sendCompetencyNoteOperation/post';
import {
  PolicyType
} from '@/types/policy-types/policy-types-enum.types';
import CompetencesExtraDataEmployeeBusiness
  from '@/business-components/competences-extra-data-employee/competences-extra-data-employee-business.vue';
import {
  EAGetCompetencesManagementDataApi,
  GetCompetencesManagementDataRequestTipoPolizaEnum,
  GetCompetencesManagementDataResponse
} from '@/services/V1/quoteAndBuy/getCompetencesManagementDataOperation/post';
import PermissionUtils from '@/utils/permissions-utils';
import {
  GetDefaultUserValuesResponseCodigoTipoUsuarioEnum as UserTypesEnum
} from '@/services/V1/home/getDefaultUserValuesOperation/post';
import {
  EaMessageOptions
} from '@zurich-es-npm/ea-front-web-ui/lib/ea/rich/message/src/message-model';
import {
  NotificationsUtils
} from '@/utils/notifications/notifications-utils';

@Component({
  components: {
    CompetencesData: CompetencesDataBusiness,
    QbObservationsAuthorization: QbObservationsAuthorizationBusiness,
    CompetencesExtraDataEmployee: CompetencesExtraDataEmployeeBusiness
  }
})

/**
 * Competences view
 *
 */
export default class Competences extends mixins<EAView<CompetencesModel>>(EAView) {
  
  public activeFlow?: EAFlowItem;

  public sendDisabled: boolean = false;

  public competencesData: GetCompetencesManagementDataResponse = {};

  public employeeUser: boolean = false;

  /**
   * Get the list of documents
   */
  @EAMethod({
    loading: true,
  })
  async created() {
    await this.getCompetencesDataBff();

    const userType = PermissionUtils.getUserType();
    this.employeeUser = PermissionUtils.isUserOfType(userType, UserTypesEnum.EMP);
  }

  /**
   * Get the documents list
   */
  @EAMethod({
    loading: true,
  })
  async getCompetencesDataBff() {
    const api = new EAGetCompetencesManagementDataApi();
    const response = await api.getCompetencesManagementDataOperation({
      getCompetencesManagementDataRequest: {
        codigoPoliza: this.model.policyNumber,
        versionPoliza: this.model.policyVersion,
        tipoPoliza: this.model.typePolicy as unknown as GetCompetencesManagementDataRequestTipoPolizaEnum
      }
    });

    if (response) {
      throwIfResponseHasErrors(response as ResponseWithErrors);
      this.competencesData = response;
    }
  }

  /**
   * Close the view
   * @param {boolean} notification
   */
  onClose(notification?: boolean): void {
    this.clearFlowErrors();
    NotificationsUtils.clearNotifications();

    this.activeFlow = EAContextManager.getInstance().getFlowContext()?.activeFlow;
 
    let messageOptions: EaMessageOptions = {
      message: this.$t('competencesFlow.competencesView.confirmation').toString(),
      type: 'success',
      duration: 7000,
      showClose: true,
      offset: 20
    };

    if (this.activeFlow && this.model.policyType === PolicyType.Oferta) {
      EAFlowNavigationUtils.closeFlow(this, this.activeFlow);
      if (notification) {
        this.$eaMessage(messageOptions);
      }
   
    } else {
      this.$emit('back');
      if (notification) {
        messageOptions = {
          ...messageOptions,
          duration: 40000
        };
        this.$eaMessage(messageOptions);
      }
    }
  }

  /**
   * Return form validation object
   * @returns {EAValidation}
   */
  public validation(): EAValidation {
    this.clearFlowErrors();
    
    const observationsComponent: QbObservationsAuthorizationBusiness = this.$refs
      .observations as QbObservationsAuthorizationBusiness;
    if (observationsComponent) {
      observationsComponent.update();
    }
    const observationsForm = observationsComponent.$refs.form as Form;

    if (this.employeeUser) {
       
      const extraCompetences: CompetencesExtraDataEmployeeBusiness = this.$refs
        .competencesExtraDataEmployee as CompetencesExtraDataEmployeeBusiness;
      if (extraCompetences) {
        extraCompetences.update();
      }
      const extraCompetencesForm = extraCompetences.$refs.form as Form;

      return new EACompositeValidation(
        [observationsForm.validation(), extraCompetencesForm.validation()], this.$tc.bind(this)
      );
    }
    return observationsForm.validation();
   
  }

  /**
   * Validate form and call bff
   */
  @EAMethod()
  async onSendNotes() {
    try {
      await this.validation().validate();
    } catch (error) {
      throw new EAValidationError(this.$t('common.label.validation.formWithErrors').toString());
    }
    
    await this.sendNotesBff();
  }

  /**
   * Send notes
   */
  @EAMethod({
    loading: true
  })
  async sendNotesBff() {
    const api = new EASendCompetencyNoteApi();
    let request;
    const basicData = {
      codigoPoliza: this.model.policyNumber,
      versionPoliza: this.model.policyVersion,
      titularNota: this.model.observations.title,
      motivoNota: this.model.observations.reasons,
      destinatarioNota: ''
    };

    request = {
      ...basicData
    };

    if (this.employeeUser) {
      const codesCenter = this.model.extraDataEmployee.codes.split(' ');

      request = {
        ...basicData,
        codigoEstructuraCompetencial: this.competencesData.codigoEstructuraCompetencial,
        codigoCentroTrabajo: codesCenter[0],
        codigoCentroTramitador: codesCenter[1],
        tipoUnidadRol: this.model.extraDataEmployee.selectedRol
      };
    }
    const output = await api.sendCompetencyNoteOperation({
      sendCompetencyNoteRequest: request
    });
   
    if (output) {
      throwIfResponseHasErrors(output as ResponseWithErrors);
      this.onClose(true);
    }
  }

  /**
   * Set permissions for the different sections
   */
  @EAMethod()
  public sendBtnDisabled(): void {
    this.sendDisabled = true;
  }

  
}
</script>
