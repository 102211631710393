/** 
 * Model select-nationality
 */
class SelectNationalityModel {

  public selectedNationality: string = '';

}

export default SelectNationalityModel;
