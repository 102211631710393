<template>
  <ea-web-app-layout class="default-layout"
    ref="eaWebAppLayout"
    :menu="menuStructure"
    :header-title="$t('layout.header.title')"
    :footer-title="$t('layout.footer.copyright')"
    :userNameHeader="userName"
    :userMenuLinks="[
      { link: $t('layout.userProfile.checkMailLink') },
      { link: $t('layout.userProfile.editMailLink') }
    ]"
    @userLinkClick="handlerEventLinkClick"
    @logoutClick="logoutDialogVisible = true"
    @hook:mounted="onChildComponentMount()"
  >
    <template slot="aside">
      <ea-logout-component :dialogVisible.sync="logoutDialogVisible"></ea-logout-component>
      <search-option-menu-modal
        :menuStructure="menuStructureForSearcher"
        :showModal="showSearchModal"
        @closeModal="onCloseModal"/>
    </template>
    <template slot="headerMiddleLayout">
      <massive-search class="search-bar"/>
    </template>
    <template slot="footerLeftLayout">
      {{ $t('layout.footer.copyright') }}
      <regularization-tour />
      <onboarding />
      <ea-pivot-security-iframe></ea-pivot-security-iframe>
      <ea-analytics-tracking />
      <analytics-virtualpage-tracker />
    </template>
  </ea-web-app-layout>
</template>

<script lang='ts'>
import {
  Vue,
  Component
} from 'vue-property-decorator';

import {
  // EAVueWebApp,
  EaWebAppLayout,
  EAAuthService,
  EALogoutComponent,
  EAMethod,
  EAFlowNavigationUtils,
  EAEventBus,
  EAContextManager,
  binarySearchRepeated,
  EAAnalyticsTracking,
  EASecurityUserInfo
} from '@zurich-es-npm/ea-front-web-core';

import EAPivotSecurityIFrame from '../coexistence/pivot/security/ea-pivot-security-iframe.vue';

import {
  EAPivotSecurityHooks
} from '../coexistence/pivot/security/ea-pivot-security-hooks';

import {
  MenuItem
} from '@/services/V1/coexistence/menu/get';

import {
  EAMenuStructure,
  EAMenuStructureItem
} from '@/layout/menu-structure-types.d';
import {
  getCoexistenceMenu,
  filterHiddenScenario,
  mountExternalLink
} from '../utils/coexistence-utils';

import {
  sortMenuStructureById
} from '../utils/menu-utils';

import cloneDeep from 'lodash/cloneDeep';
import EaMenu from '@zurich-es-npm/ea-front-web-ui/lib/ea/navigation/menu/src/Menu.vue';

import AnalyticsVirtualPageTracker from '@/utils/analytics/analytics-virtualpage-tracker.vue';
import MassiveSearch from '@/presentational-components/massive-search/massiveSearch.vue';
import SearchOptionMenuModal from '@/presentational-components/layout/searchOptionMenuModal.vue';
import EATour from '@/business-components/ea-tour/ea-tour.vue';
import OnboardingBusiness from '@/business-components/onboarding/onboarding-business.vue';

const COEXISTENCE_FLOWNAME = 'ZonaZurich';

@Component({
  components: {
    'ea-web-app-layout': EaWebAppLayout,
    'ea-logout-component': EALogoutComponent,
    'ea-pivot-security-iframe': EAPivotSecurityIFrame,
    'ea-analytics-tracking': EAAnalyticsTracking,
    'analytics-virtualpage-tracker': AnalyticsVirtualPageTracker,
    'massive-search': MassiveSearch,
    'search-option-menu-modal': SearchOptionMenuModal,
    RegularizationTour: EATour,
    Onboarding: OnboardingBusiness
  }
})

/**
 * Application default layout
 */
export default class DefaultLayout extends Vue {

  private logoutDialogVisible = false;

  showSearchModal = false;

  private menuStructure: EAMenuStructure = [];

  private menuStructureForSearcher: EAMenuStructure = [];

  private coexistenceMenu: MenuItem[] | null = null;

  eaWebLayoutObserver!: MutationObserver;

  /** 
   * Get menus structure
   */
  @EAMethod()
  async created() {
    this.eaWebLayoutObserver = new MutationObserver(this.findSearchButton);
    this.coexistenceMenu = await this.loadCoexistenceMenu();
    this.menuStructure = cloneDeep(this.$store.getters['security/userMenus']) as EAMenuStructure;
    this.menuStructure = sortMenuStructureById(this.menuStructure);
    this.mapMenuToCoexistence(this.coexistenceMenu);
    this.menuStructureForSearcher = cloneDeep(this.menuStructure);
    this.menuStructure.unshift(
      {
        itemId: 'MenuItemSearch',
        icon: 'el-icon-search',
        flowName: undefined,
        name: 'Buscar',
        path: ''
      });
    EAPivotSecurityHooks.setPivotAuthenticated(false);
    EAPivotSecurityHooks.home();
  }

  /**
   * Vue mounted lifecycle hook
   */
  @EAMethod()
  mounted(): void {
    const mediaQuery = window.matchMedia('screen and (min-width: 1025px)');
    if (mediaQuery.matches) {
      this.toggleMainMenu();
    }
  }

  /**
   * @param {number} index 
   * @param {string} item 
   */
  handlerEventLinkClick(index: number) {
    if (index === 0) {
      this.coexistenceNavigation('0752');
    } else {
      this.coexistenceNavigation('0751');
    }
  }

  /**
   * Load coexistence menu from pivot endpoint
   * @returns {Promise<MenuItem[]>}
   */
  public loadCoexistenceMenu(): Promise<MenuItem[]> {
    return getCoexistenceMenu();
  }

  /**
   * Map configured menu to coexistence menu
   * @param {MenuItem[]} coexistenceMenu
   */
  public mapMenuToCoexistence(coexistenceMenu: MenuItem[]): void {
    const handleItem = (item: EAMenuStructureItem): boolean => {
      // Si es flow de coexistencia
      if (item.flowName === COEXISTENCE_FLOWNAME) {
        // Mapear por operationId
        if (item.params && item.params.operationId) {
          const coexistenceFlowId = item.params.operationId;
          const filteredMenu = filterHiddenScenario(
            binarySearchRepeated(coexistenceMenu, parseInt(coexistenceFlowId), coexItem => parseInt(coexItem.id || ''))
          );
          // Si existe en el menu de la coexistencia
          if (filteredMenu.length && filteredMenu[0].url) {
            // Si es de tipo Salto (S) es un link externo
            if (filteredMenu[0].type === 'S') {
              item.params.externalUrl = mountExternalLink(filteredMenu[0]);
              item.flowName = 'External';
            } else if (item.params.flowNameThunder) {
              item.flowName = item.params.flowNameThunder;
            } else { // Sino al flujo de coexistencia
              item.params.operationName = filteredMenu[0].url as string;
            }
            return true;
          }
        // O mapear por operationName
        } else if (item.params && item.params.operationName) {
          const coexistenceUrl = item.params.operationName;
          const filteredMenu = coexistenceMenu.filter(coexItem => coexItem.url === coexistenceUrl);
          if (filteredMenu.length && filteredMenu[0].id !== undefined) {
            item.params.operationId = filteredMenu[0].id as string;
            return true;
          }
        }
        return false;
      }
      if (item.children) {
        for (let childKey = 0; childKey < item.children.length;) {
          const child = item.children[childKey];
          const submenuChildren = child.children ? child.children.length : 0;
          if (handleItem(child) &&
              (submenuChildren === 0 ||
              (submenuChildren > 0 && child.children && child.children.length > 0
              ))) {
            childKey++;
          } else {
            item.children.splice(childKey, 1);
          }
        }
      }
      return true;
    };
    for (let menuKey = 0; menuKey < this.menuStructure.length;) {
      const child = this.menuStructure[menuKey];
      const submenuChildren = child.children ? child.children.length : 0;
      if (handleItem(child) &&
          (submenuChildren === 0 ||
          (submenuChildren > 0 && child.children && child.children.length > 0
          ))) {
        menuKey++;
      } else {
        this.menuStructure.splice(menuKey, 1);
      }
    }
  }

  /** 
   * Get user name formated
   * @returns {string} - User name
   */
  get userName() {
    const fullName = this.$store.getters['security/userName'];
    const securityContextStore = EAContextManager.getInstance().getSecurityContext();
    const userInfo = securityContextStore.userInfo as EASecurityUserInfo;
    return userInfo?.infoUsername?.trim()
      ? `${userInfo.infoUsername.trim().toLocaleLowerCase()} - ${fullName}`: fullName;
  }

  /** 
   * Get user initials
   * @returns {string} - User initials
   */
  get userNameInitials() {
    return this.$store.getters['security/userNameInitials'];
  }

  /**
   * Returns the user main role
   * @returns {string} - User role
   */
  get userMainRole() {
    return this.$store.getters['security/userMainRole'];
  }

  /**
   * @param {string} operationId
   * @returns {boolean} 
   */
  public getCoexistenceByActionId(operationId: string) {
    if (this.coexistenceMenu !== null
         && this.coexistenceMenu.findIndex(item => item.id === operationId) !== -1) {
      return true;
    }
    return false;
  }

  /**
   * Logout user
   */
  doLogout() {
    EAAuthService.getInstance().doLogout();
  }

  /**
   * Single Sign On logout
   */
  doSsoLogout() {
    EAAuthService.getInstance().doSsoLogout();
  }

  /**
   * @param {string} operationId
   */
  coexistenceNavigation(operationId: string) {
    EAFlowNavigationUtils.navigate(this, 'ZonaZurich', {
      operationId
    });
  }

  /**
   * Toggle main menu
   */
  toggleMainMenu(): void {
    const eaWebAppLayout = this.$refs.eaWebAppLayout as EaWebAppLayout;
    const eaMenu = eaWebAppLayout.$refs.eaMenu as EaMenu;
    eaMenu.expand();
  }

  /**
   * @description Cierra el user menu después del click en sus botones
   */
  closeUserMenu(): void {
    EAEventBus.$emit('closeUsermenu');
  }

  /**
   * @description Observa el eaWebLayout para setear el buscador del menú
   * @param {MutationRecord[]} mutationList
   */
  findSearchButton(mutationList: MutationRecord[]) {
    mutationList.forEach((mutation: MutationRecord) => {
      if (mutation.type === 'childList') {
        const target: Element = mutation.target as Element;
        const menuSearchButton: Element | null = target.querySelector('#MenuItemSearch_');
        if (menuSearchButton) {
          menuSearchButton.addEventListener('click', this.openModal);
          this.eaWebLayoutObserver.disconnect();
        }
      }
    });
  }

  /**
   * Shows search modal component
   */
  openModal() {
    this.showSearchModal = true;
  }

  /**
   * Shows search modal component
   */
  onCloseModal() {
    this.showSearchModal = false;
  }

  /**
   * Vue ea-web-layout mounted
   */
  onChildComponentMount() {
    const eaWebApp = this.$refs.eaWebAppLayout as EaWebAppLayout;
    this.eaWebLayoutObserver.observe(eaWebApp.$el, {
      childList: true,
      subtree: true
    });
  }

}
</script>

<style>
/*TODO: REVISAR SCSS VER SI SE PUEDE AJUSTAR A LA NUEVA MARCA */
/* Enter and leave animations can use different */
/* durations and timing functions.              */

/* Slide-fade*/
.slide-fade-enter-active {
  transition: all 0.3s ease;
  display: block;
}
.slide-fade-leave-active {
  transition: all 0.3s ease;
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateY(-10px);
  opacity: 0;
}
/* */

/* Fade */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
/* temporal fix */
header .user__body .el-button.el-button--text{
  padding-left: 0;
}
.massiveSearch{
  display: flex;
  flex: 1;
  justify-content: center;
}
header .header--content {
  flex: 1;
  justify-content: flex-end;
}
@media only screen and (min-width: 768px) {
  .massiveSearch .massiveSearchInput {
    width: 30% !important;
  }
  .massiveSearch .massiveSearchSelect {
    width: 25% !important;
    margin-right: 4px;
  }
  .massiveSearch{
    padding-right: 4px;
  }
}
@media only screen and (max-width: 980px) {
  header .header--title {
    position: absolute !important;
    left: 5% !important;
    -webkit-transform: none !important;
    transform: none !important;
  }
}
@media only screen and (min-width: 1024px) {
  .massiveSearch .massiveSearchInput {
    width: 50% !important;
  }
  .massiveSearch .massiveSearchSelect {
    width: 35% !important;
    margin-right: 4px;
  }
}
@media only screen and (min-width: 1100px) {
  .massiveSearch .massiveSearchInput {
    width: 30% !important;
  }
  .massiveSearch .massiveSearchSelect {
    width: 25% !important;
  }
}
</style>
