var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[(_vm.group)?_c('ea-collapse',{attrs:{"header-background-color":"white"}},[_c('ea-collapse-item',{attrs:{"title":_vm.$t('fleets.fleetsMaintenancePolicyFlow.fleetMaintenanceGroupVehicleTable.titlePrefix') +
          (_vm.group.descripcionAgrupacionFlota ? _vm.group.descripcionAgrupacionFlota : '')}},[_c('ea-table',{ref:'ref' + _vm.group.codigoAgrupacionFlota,class:!_vm.queryPolicy ? 'thead-fixed' : 'thead-fixed col-fixed-right',attrs:{"data":_vm.sortedListaVehiculos,"id":_vm.group.codigoAgrupacionFlota,"infinite-scroll":true,"simpleSelect":!_vm.queryPolicy,"disabledRows":_vm.disabledRows},on:{"rowClick":_vm.onSelectVehicle}},[_c('template',{slot:"top-table"},[_c('div',{staticClass:"ea-toolbar-action-wrapper d-display-flex d-direction-reverse"},[_c('ea-button',{attrs:{"type":"secondary","disabled":!_vm.selectedVehicle},on:{"click":_vm.anulateVehicle}},[_vm._v(" "+_vm._s(_vm.$t('fleets.fleetsMaintenancePolicyFlow.fleetMaintenanceGroupVehicleTable.anulate'))+" ")])],1)]),_c('ea-table-column',{attrs:{"headerClass":"no-sortBy","label":_vm.$t('fleets.fleetsMaintenancePolicyFlow.fleetMaintenanceGroupVehicleTable.'+
          'tableDataHeaders.policyNumber'),"show":"codigoPoliza"},scopedSlots:_vm._u([{key:"default",fn:function(row){return [(!_vm.queryPolicy)?_c('span',[_vm._v(" "+_vm._s(row.codigoPoliza)+" ")]):_c('ea-link',{on:{"click":function($event){return _vm.queryVehicle(row)}}},[_vm._v(" "+_vm._s(row.codigoPoliza)+" ")])]}}],null,false,1977292719)}),_c('ea-table-column',{attrs:{"headerClass":"no-sortBy","label":_vm.$t('fleets.fleetsMaintenancePolicyFlow.fleetMaintenanceGroupVehicleTable.'+
            'tableDataHeaders.licensePlateNumber'),"show":"numeroMatriculaSinValidaciones"},scopedSlots:_vm._u([{key:"default",fn:function(row){return [_c('span',[_vm._v(" "+_vm._s(row.numeroMatriculaSinValidaciones)+" ")])]}}],null,false,548569258)}),_c('ea-table-column',{attrs:{"headerClass":"no-sortBy","label":_vm.$t('fleets.fleetsMaintenancePolicyFlow.fleetMaintenanceGroupVehicleTable.tableDataHeaders.version'),"show":"versionPoliza"},scopedSlots:_vm._u([{key:"default",fn:function(row){return [_c('span',[_vm._v(" "+_vm._s(row.versionPoliza)+" ")])]}}],null,false,4294530393)}),_c('ea-table-column',{attrs:{"headerClass":"no-sortBy","label":_vm.$t('fleets.fleetsMaintenancePolicyFlow.fleetMaintenanceGroupVehicleTable.tableDataHeaders.brand'),"show":"descripcionMarcaVehiculo"},scopedSlots:_vm._u([{key:"default",fn:function(row){return [_c('span',[_vm._v(" "+_vm._s(row.descripcionMarcaVehiculo)+" ")])]}}],null,false,2388161032)}),_c('ea-table-column',{attrs:{"headerClass":"no-sortBy","label":_vm.$t('fleets.fleetsMaintenancePolicyFlow.fleetMaintenanceGroupVehicleTable.tableDataHeaders.model'),"show":"descripcionModeloVehiculo"},scopedSlots:_vm._u([{key:"default",fn:function(row){return [_c('span',[_vm._v(" "+_vm._s(row.descripcionModeloVehiculo)+" ")])]}}],null,false,2454001044)}),_c('ea-table-column',{attrs:{"headerClass":"no-sortBy","label":_vm.$t('fleets.fleetsMaintenancePolicyFlow.fleetMaintenanceGroupVehicleTable.'+
            'tableDataHeaders.policyEfectDate'),"show":"fechaEfectoMovimiento","data-type":"date"},scopedSlots:_vm._u([{key:"default",fn:function(row){return [_c('span',[_vm._v(" "+_vm._s(_vm.dateFormatter(row.fechaEfectoMovimiento))+" ")])]}}],null,false,2510461225)}),_c('ea-table-column',{attrs:{"headerClass":"no-sortBy","label":_vm.$t('fleets.fleetsMaintenancePolicyFlow.fleetMaintenanceGroupVehicleTable.'+
            'tableDataHeaders.policyEndDate'),"show":"fechaVencimiento","data-type":"date"},scopedSlots:_vm._u([{key:"default",fn:function(row){return [_c('span',[_vm._v(" "+_vm._s(_vm.dateFormatter(row.fechaVencimiento))+" ")])]}}],null,false,3475147005)}),_c('ea-table-column',{attrs:{"headerClass":"no-sortBy","label":_vm.$t('fleets.fleetsMaintenancePolicyFlow.fleetMaintenanceGroupVehicleTable.tableDataHeaders.package'),"sortable":false},scopedSlots:_vm._u([{key:"default",fn:function(col){return [_c('span',[_vm._v(" "+_vm._s(col.codigoPaquete ? _vm.$t(`fleets.fleetsFlow.fleetPackagesWarrantiesModal.codes.${col.codigoPaquete}`) : '-')+" ")])]}}],null,false,863008149)}),_c('ea-table-column',{attrs:{"headerClass":"no-sortBy","label":_vm.$t('fleets.fleetsMaintenancePolicyFlow.fleetMaintenanceGroupVehicleTable.'+
            'tableDataHeaders.annualPremium'),"show":"importeTotal"},scopedSlots:_vm._u([{key:"default",fn:function(row){return [_c('div',{staticClass:"wp-80 d-display-flex d-justify-flex-end"},[_c('ea-text',[_vm._v(" "+_vm._s(_vm.digitsFormatter(row.importeTotal))+" ")])],1)]}}],null,false,1055128902)}),_c('ea-table-column',{attrs:{"label":_vm.$t('fleets.fleetsMaintenancePolicyFlow.fleetMaintenanceGroupVehicleTable.tableDataHeaders.status'),"sortable":false},scopedSlots:_vm._u([{key:"default",fn:function(col){return [_c('span',{staticClass:"d-display-flex d-align-items-center"},[(col.codigoEstadoPoliza === _vm.PolicyEmitedStatus.VIGOR)?_c('ea-icon',{staticClass:"m-r-8",attrs:{"status":"success","icon":"el-icon-circle-check"}}):(col.codigoEstadoPoliza === _vm.PolicyEmitedStatus.ANULADA)?_c('ea-icon',{staticClass:"m-r-8",attrs:{"status":"danger","icon":"el-icon-remove-outline"}}):_vm._e(),_vm._v(" "+_vm._s(_vm.getStatusText(col))+" ")],1)]}}],null,false,4211347651)}),(_vm.queryPolicy)?_c('ea-table-column',{attrs:{"headerClass":"no-sortBy","label":''},scopedSlots:_vm._u([{key:"default",fn:function(row){return [_c('ea-tooltip',{attrs:{"placement":"top","content":_vm.$t('searchOfferFlow.searcher.documentation')}},[_c('ea-button-icon',{staticClass:"m-b-2",attrs:{"size":"medium","icon":"z-document"},on:{"click":function($event){return _vm.onShowDocumentation(row)}}})],1)]}}],null,false,3333184176)}):_vm._e()],2)],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }