<!-- eslint-disable max-lines -->
<template>
  <div>
    <ea-dialog
      v-if="isEditVehicleOpen"
      :title="$t('fleets.fleetsFlow.inputVehiclesData.editVehicle.vechicleDataTitle')"
      type="transactional"
      :visible.sync="isEditVehicleOpen"
      @button-click="isEditVehicleOpen = false"
    >
      <fleet-manual-upload-vehicle
        id="fleet-edit-vehicle"
        name="fleet-edit-vehicle"
        v-model="model.fleetsEditVehicleModel"
        :fleetUnifiedExpirationModel="fleetUnifiedExpirationModel"
        :vehicleRestrictions="vehicleRestrictions"
        :allVehicleUses="allVehicleUses"
        :editionMode="true"
        :editionType="editionType"
        :fleetCode="fleetCode"
        :fleetVersion="fleetVersion"
        :operationType="operationType"
        :pamVehicleTypes="pamVehicleTypes"
        @updateCommonData="onUpdateCommonData"
        @fillBaseSIETEData="onSaveEditedVehicleAfterBaseSiete"
        @fixIncorrectUse="onSaveEditedVehicleAfterEditUse"
        @saveEditedVehicle="updateVehicle"
        @closeEditVehicle="isEditVehicleOpen = false"
      >
      </fleet-manual-upload-vehicle>
      <span slot="footer"></span>
    </ea-dialog>

    <ea-card v-if="!showDescriptionText">
      <ea-paragraph
          size="medium"
          class="m-y-8">
              {{ $t('fleets.fleetsFlow.inputVehiclesData.descriptionWithoutVehicle') }}
        </ea-paragraph>
    </ea-card>

    <ea-card v-else-if="showVehicleDataConsult" shadow="hidden">
      <div slot="cardbody" class="p-a-16">
        <h2>{{ $t(`fleets.fleetsFlow.inputVehiclesData.title`) }}</h2>
        <ea-row v-if="isMixedInputEnabled && !consultFlow">
          <ea-col :span="24">
            <ea-paragraph
              size="medium"
              class="m-y-8">
                  {{ $t('fleets.fleetsFlow.inputVehiclesData.description') }}
            </ea-paragraph>
          </ea-col>
        </ea-row>

        <ea-tabs v-if="!consultFlow && isMixedInputEnabled" :active-name="activeTabName" class="ft-sz-14">
          <!-- Manual input tab -->
          <ea-tab-pane
            :label="$t('fleets.fleetsFlow.inputVehiclesData.manualInput.tabTitle')"
            name="manual"
            class="p-t-16"
          >
            <fleet-manual-upload-vehicle
              id="fleet-manual-upload-vehicle"
              name="fleet-manual-upload-vehicle"
              v-model="model.manualUploadVehicleModel"
              :fleetUnifiedExpirationModel="fleetUnifiedExpirationModel"
              :vehicleRestrictions="vehicleRestrictions"
              :allVehicleUses="allVehicleUses"
              :disabledBase7Mode="callingToBaseSiete"
              :operationType="operationType"
              :pamVehicleTypes="pamVehicleTypes"
              @onInsertVehicle="onInsertVehicle"
            >
            </fleet-manual-upload-vehicle>
          </ea-tab-pane>

          <!-- Grouped input tab -->
          <ea-tab-pane
            :label="$t('fleets.fleetsFlow.inputVehiclesData.groupInput.tabTitle')"
            name="grouped"
            class="p-t-16"
          >
            <fleet-group-upload-vehicles
              id="fleet-group-upload-vehicles-id"
              v-model="model.groupUploadVehiclesModel"
              :fleetUnifiedExpirationModel="fleetUnifiedExpirationModel"
              :allVehicleUses="allVehicleUses"
              :vehiclesNumberRange="vehiclesNumberRange"
              :disabledBase7Mode="callingToBaseSiete"
              @onInsertVehicle="onInsertVehicle"
            >
            </fleet-group-upload-vehicles>
          </ea-tab-pane>
        </ea-tabs>

        <fleet-manual-upload-vehicle v-else-if="isManualInputEnabled"
              id="fleet-manual-upload-vehicle"
              name="fleet-manual-upload-vehicle"
              v-model="model.manualUploadVehicleModel"
              :fleetUnifiedExpirationModel="fleetUnifiedExpirationModel"
              :vehicleRestrictions="vehicleRestrictions"
              :allVehicleUses="allVehicleUses"
              :disabledBase7Mode="callingToBaseSiete"
              :operationType="operationType"
              :pamVehicleTypes="pamVehicleTypes"
              @onInsertVehicle="onInsertVehicle"
            >
            </fleet-manual-upload-vehicle>

        <!-- Table for vehicles NON-checked to BaseSiete -->
        <template v-if="vehiclesNOBaseSiete.length">
          <div class="d-display-flex d-align-items-center d-justify-space-between">
            <div>
              <h4>{{ $t('fleets.fleetsFlow.inputVehiclesData.tableDataHeaders.vehicleTableTitle') }}</h4>
              <ea-paragraph
                v-if="!consultFlow"
                size="medium"
                class="m-t-8 m-b-8">
                  {{ $t('fleets.fleetsFlow.inputVehiclesData.tableDataHeaders.baseInfo') }}
              </ea-paragraph>
            </div>
          </div>
          <!-- Same table for both tabs -->
          <vehicles-data-table
            :isAfterBaseSieteTable="false"
            :vehicleList="model.vehicles"
            :errorsVehicles="model.errorsVehicles"
            :consultFlow="consultFlow"
            :isEditVehicleOpen="isEditVehicleOpen"
            @removeVehiclesBtn="onRemoveVehiclesBtn"
            @openEditVehicle="onOpenEditVehicle"
          >
          </vehicles-data-table>
          
          <div v-if="!consultFlow" class="p-y-16 d-display-flex d-direction-reverse">
            <ea-button
              type="primary"
              :disabled="anyNoBaseSieteVehiclesInvalid || callingToBaseSiete"
              @click="queryBaseSiete"
            >
              {{ $t('fleets.fleetsFlow.inputVehiclesData.tableDataHeaders.queryBaseButton') }}
            </ea-button>
          </div>
        </template>
      </div>
    </ea-card>

    <ea-card shadow="hidden" v-if="vehiclesBaseSieteOk.length" class="m-t-24">
      <div slot="cardbody" class="p-a-16">
        <!-- Table for vehicles checked OK to BaseSiete -->
        <div class="d-display-flex d-align-items-center d-justify-space-betwee m-b-8">
          <h4>{{ $t('fleets.fleetsFlow.inputVehiclesData.tableDataHeaders.vehicleTableTitleBaseSiete') }}</h4>
        </div>

        <!-- Warning if any BaseSiete-OK some invalid vehicles -->
        <div v-if="anyBaseSieteOkVehiclesInvalid">
          <ea-alert
            key="baseSieteOk-invalids-warning"
            type="warning"
            :title="$t('fleets.fleetsFlow.inputVehiclesData.tableDataHeaders.warningSomeInvalidToContinue')"
            :closable="false"
            class="m-b-16"
          />
        </div>

        <!-- 2nd table -->
        <vehicles-data-table
          :isAfterBaseSieteTable="true"
          :vehicleList="model.vehicles"
          :errorsVehicles="model.errorsVehicles"
          :consultFlow="consultFlow"
          :isEditVehicleOpen="isEditVehicleOpen"
          @removeVehiclesBtn="onRemoveVehiclesBtn"
          @openEditVehicle="onOpenEditVehicle"
        >
        </vehicles-data-table>

        <div class="m-y-16">
          <ea-text :in-line="false">
            {{ $t('fleets.fleetsFlow.inputVehiclesData.tableDataHeaders.tableExplanation1') }}
          </ea-text>
          <ea-text :in-line="false">
            {{ $t('fleets.fleetsFlow.inputVehiclesData.tableDataHeaders.tableExplanation2') }}
          </ea-text>
          <ea-text :in-line="false">
            {{ $t('fleets.fleetsFlow.inputVehiclesData.tableDataHeaders.tableExplanation3') }}
          </ea-text>
        </div>
      </div>
    </ea-card>

    <!-- Confirm vehicles deletion dialog -->
    <ea-dialog
      :visible="isConfirmVehicleDeletionVisible"
      size="small"
      :title="$t('fleets.fleetsFlow.inputVehiclesData.deleteDialog.title')"
      @close="cancelDeletion"
    >
      <ea-paragraph
        size="medium"
        class="m-b-32 m-t-16">
        {{ confirmVehicleDeletionDescription }}
      </ea-paragraph>

      <!-- Cancel and save button -->
      <div class="d-display-flex d-justify-flex-end">
        <ea-button type="secondary" class="m-r-16" @click="cancelDeletion">
          {{ $t('common.label.cancel') }}
        </ea-button>
        <ea-button type="primary" @click="deleteVehicles">
          {{ $t('common.label.accept') }}
        </ea-button>
      </div>
    </ea-dialog>
  </div>
</template>

<script lang="ts">
import {
  Component, Prop, Watch
} from 'vue-property-decorator';
import {
  mixins
} from 'vue-class-component';
import {
  EABusinessComponent,
  EAError,
  EAErrorManager,
  EAMethod,
  ResponseWithErrors,
  throwIfResponseHasErrors,
  EAValidationError,
} from '@zurich-es-npm/ea-front-web-core';
import {
  cloneDeep
} from 'lodash';
import FleetInputVehiclesDataModel from './fleet-input-vehicles-data-model';
import FleetManualUploadVehicleBusiness from '../fleet-manual-upload-vehicle/fleet-manual-upload-vehicle-business.vue';
import {
  VehicleFormModel, VehicleUseData
} from '../fleet-manual-upload-vehicle/fleet-manual-upload-vehicle-model';
import FleetUnifiedExpirationModel from '../fleet-unified-expiration/fleet-unified-expiration-model';
import {
  SelectItemType
} from '@/flows/fleets/model/select-Item-type-model';
import FleetGroupUploadVehiclesBusiness from '../fleet-group-upload-vehicles/fleet-group-upload-vehicles-business.vue';
import Utils from '@/utils/utils';
import {
  AddVehicleRequest,
  AddVehicleRequestListaMatriculasVehiculo,
  AddVehicleRequestListaMatriculasVehiculoTipoMatriculaEnum as TipoMatriculaEnum,
  AddVehicleResponseResultados,
  EAAddVehicleApi,
} from '@/services/V1/fleets/addVehicleOperation/post/api';
import {
  CreateVehicleAndRiskWithoutPlateNumberRequest,
  CreateVehicleAndRiskWithoutPlateNumberResponse,
  EACreateVehicleAndRiskWithoutPlateNumberApi
} from '@/services/V1/fleets/createVehicleAndRiskWithoutPlateNumberOperation/post/api';
import {
  RangeRestriction
} from '@/flows/fleets/model/range-restriction-model';
import {
  NotificationsTypeEnum,
  NotificationsUtils,
  ZZNotification
} from '@/utils/notifications/notifications-utils';
import {
  DeleteVehicleResponseResultados,
  EADeleteVehicleApi
} from '@/services/V1/fleets/deleteVehicleOperation/post';
import {
  Table
} from '@zurich-es-npm/ea-front-web-ui';
import {
  VehiclesRestrictions
} from '@/flows/fleets/model/vehicles-restrictions-model';
import {
  EAQueryBaseSieteApi,
  QueryBaseSieteRequestListaMatriculasVehiculo,
  QueryBaseSieteResponse,
  QueryBaseSieteResponsePosiblesVehiculos,
  QueryBaseSieteResponseResultadosVehiculos,
  Error
} from '@/services/V1/fleets/queryBaseSieteOperation/post';
import {
  VehicleEditionTypeEnum
} from '@/types/edition/edition-enum.types';
import {
  InputVehiclesMode
} from '@/types/input-vehicles-mode/input-vehicles-mode-enum.types';
import {
  FleetInputVehiclesDataApi
} from './fleet-input-vehicles-data-api';
import {
  FleetInputVehiclesDataUtils
} from './utils/fleet-input-vehicles-data-utils';
import {
  FleetGDCError,
  OperationTypeEnum
} from '@/flows/fleets/fleets-model';
import {
  MaxVehiclesValidations
} from '@/flows/fleets-maintenance-policy/fleets-maintenance-policy-model';
import {
  EaMessageType
} from '@zurich-es-npm/ea-front-web-ui/lib/ea/rich/notify/src/notify-model';
import Row from '@zurich-es-npm/ea-front-web-ui/lib/ea/data/table/src/class/Row';
import VehiclesDataTableComponent from './components/vehicles-data-table.vue';

const ZON_ERROR_BASESIETE = 'ZON00136';
const ALREADY_VEHICLE_INSURED_ERROR_CODE = '6887';

export interface OpenEditVehicleEventData {
  editionType: VehicleEditionTypeEnum;
  row: VehicleFormModel;
}

@Component({
  name: 'fleet-input-vehicles-data',
  components: {
    'fleet-manual-upload-vehicle': FleetManualUploadVehicleBusiness,
    'fleet-group-upload-vehicles': FleetGroupUploadVehiclesBusiness,
    'vehicles-data-table': VehiclesDataTableComponent
  },
})

/**
 * Business Component fleet-input-vehicles-data
 */
export default class FleetInputVehiclesDataBusiness extends mixins<EABusinessComponent<FleetInputVehiclesDataModel>>(
  EABusinessComponent
) {
  @Prop({})
    vehicleRestrictions!: VehiclesRestrictions;

  @Prop({})
    vehiclesNumberRange!: RangeRestriction;

  @Prop({})
    allowedPlateTypes!: string[];

  @Prop({})
    fleetUnifiedExpirationModel!: FleetUnifiedExpirationModel;

  @Prop({})
    noPlateOption!: SelectItemType;

  @Prop({})
    fleetCode!: string;

  @Prop({})
    fleetVersion!: number;

  @Prop()
    allVehicleUses?: VehicleUseData[];

  @Prop({
    'default': InputVehiclesMode.Mixed
  })
    inputMode?: InputVehiclesMode;

  @Prop({
    required:false,
    'default': () => OperationTypeEnum.NUEVA
  })
    operationType!: OperationTypeEnum;

  @Prop({
    required:false,
    'default': () => 0
  })
    alreadyEmmitedVehicles!: number;

  @Prop()
    consultFlow?: boolean;

  @Prop({
    required: true,
    'default': () => []
  })
    pamVehicleTypes!: [string];
  
  tableReferences = {
    NonBaseSieteVehicles: 'nonBaseSieteVehicles',
    OkBaseSieteVehicles: 'okBaseSieteVehicles'
  };

  hasActiveChecks: Record<string, boolean> = {
    nonBaseSieteVehicles: false,
    okBaseSieteVehicles: false
  };

  public activeTabName = 'manual';


  flowId = ''; // Used to know if we are in the same flow when requtoe has finished

  /**
   * Returns vehicles
   */
  get vehicles(): VehicleFormModel[] {
    return this.model.vehicles;
  }

  /**
   * Set vehicles to model
   * @param {VehicleFormModel[]} vehicles
   */
  set vehicles(vehicles: VehicleFormModel[]) {
    this.model.vehicles = vehicles;
  }

  /**
   *
   */
  get isMixedInputEnabled(): boolean {
    return this.inputMode === InputVehiclesMode.Mixed;
  }

  /**
   *
   */
  get isManualInputEnabled(): boolean {
    return this.inputMode === InputVehiclesMode.Manual;
  }


  vehicleApi: EAAddVehicleApi = new EAAddVehicleApi();

  createVehicleWithoutPlateNumberApi: EACreateVehicleAndRiskWithoutPlateNumberApi =
    new EACreateVehicleAndRiskWithoutPlateNumberApi();

  isConfirmVehicleDeletionVisible = false;

  confirmVehicleDeletionDescription = '';

  vehiclesToDelete: VehicleFormModel[] = [];

  vehiclesToBaseSiete: VehicleFormModel[] = [];
  
  callingToBaseSiete = false;

  public editingVehicleIndex?: number;

  public isEditVehicleOpen = false;

  public editionType: VehicleEditionTypeEnum = VehicleEditionTypeEnum.none;

  hasChecksIOkBaseSieteTable = false;

  hasChecksNoBaseSieteTable = false;

  errorsVehicleMessage: EAValidationError[] = [];


  /**
   * Return vehicles not called to BaseSIETE
   */
  get vehiclesNOBaseSiete(): VehicleFormModel[] {
    return FleetInputVehiclesDataUtils.sortVehicleList(this.vehicles)
      .filter(vehicle => !vehicle.isBaseSieteData);
  }

  /**
   * Return vehicles have been called correctly to BaseSIETE.
   * Invalid vehicles go first.
   */
  get vehiclesBaseSieteOk(): VehicleFormModel[] {
    const filtered = this.vehicles.filter(vehicle => vehicle.isBaseSieteData);
    return FleetInputVehiclesDataUtils.sortVehicleList(filtered);
  }

  /**
   * Returns if there is some "invalid" vehicles in the NO-BaseSiete table
   */
  get anyNoBaseSieteVehiclesInvalid(): boolean {
    return !!this.vehiclesNOBaseSiete.find(vehicle => vehicle.invalid);
  }

  /**
   * Returns if there is some "invalid" vehicles in the BaseSiete-OK table
   */
  get anyBaseSieteOkVehiclesInvalid(): boolean {
    return !!this.vehiclesBaseSieteOk.find(vehicle => vehicle.invalid);
  }

  /**
   * Returns if there is some invalid-dates vehicles in the BaseSiete-OK table
   */
  get anyBaseSieteOkVehiclesInvalidDate(): boolean {
    return !!this.vehiclesBaseSieteOk.find(vehicle => vehicle.invalidDate);
  }

  /**
   * Returns if there is some invalid-values vehicles in the BaseSiete-OK table
   */
  get anyBaseSieteOkVehiclesInvalidValues(): boolean {
    return !!this.vehiclesBaseSieteOk.find(vehicle => vehicle.invalidValues);
  }

  /**
   * Returns false when the flow is consult and without vehicles  
   */
  get showDescriptionText(): boolean {
    if (this.consultFlow && this.vehicles.length === 0) {
      return false;
    }
    return true;
  }

  /**
   * Returns false when the flow is consult and without vehiclesNOBaseSiete 
   */
  get showVehicleDataConsult(): boolean {
    if (this.consultFlow && this.vehiclesNOBaseSiete.length === 0) {
      return false;
    }
    return true;
  }

  /**
   * 
   */
  created() {
    this.flowId = NotificationsUtils.getCurrentFlowId();
  }

  /**
   * Show errors messages exists any vehicle that must to be delete
   *
   */
  showErrorVehicles() {
    if (this.model.errorsVehicles?.length) {
      this.model.errorsVehicles.forEach(errVehicle => {
        this.vehicles = this.vehicles.map(vehicle => {
          if (errVehicle.offerNumber === vehicle.offerNumber) {
            return {
              ...vehicle,
              isExistErrorVehicle: true
            };
          }
          return vehicle;
        });
      });
 
      // Throw warnings messages to notify PMA error
      this.model.errorsVehicles.forEach(vehicle => {
        if (!vehicle.isError) {
          NotificationsUtils.throwWarning(
            this.$t('fleets.fleetsFlow.inputVehiclesData.baseSieteResultsNotifications.errorPMA.errorMessage',
              {
                plateNumber: vehicle.plateNumber,
                errorMessage: vehicle.errorMessage
              }).toString()
          );
        }
      });

      // Throw error to delete the vehicles
      const isExistErrors = this.model.errorsVehicles.some(vehicle => vehicle.isError);
      if (isExistErrors) {
        throw new EAValidationError(
          this.$t('fleets.fleetsFlow.inputVehiclesData.editVehicle.vehuclesMustBeDeleted').toString()
        );
      }
    }
  }

  /**
   * Watch for `vehicles` changes and emits enabled/disabled continue button status.
   */
  @Watch('vehicles', {
    deep: true,
    immediate: true
  })
  continueButtonDisabled() {
    const enabled =
      this.vehiclesNOBaseSiete.length === 0
      && this.vehiclesBaseSieteOk.length > 0
      && !this.anyBaseSieteOkVehiclesInvalid
      && !this.anyBaseSieteOkVehiclesInvalidDate
      && !this.anyBaseSieteOkVehiclesInvalidValues
      && !this.model.errorsVehicles?.length;
    this.$emit('continueButtonDisabled', !enabled);
  }

  /**
   * Watch for `callingToBaseSiete` changes and emit the value.
   */
  @Watch('callingToBaseSiete')
  onDisabledBaseSieteChange() {
    this.$emit('disabledBase7Mode', this.callingToBaseSiete);
  }

  /**
   * Throw warning by event buss
   * @param {VehicleFormModel[]} vehiclesAlreadyAdded
   */
  throwWarningForAlreadyAddedVehicles(vehiclesAlreadyAdded: VehicleFormModel[]) {
    vehiclesAlreadyAdded.forEach(vehicle => {
      const message = this.$t(`fleets.fleetsFlow.inputVehiclesData.manualInput.duplicatedVehicle`, {
        plateNumber: vehicle.plateNumber,
      }).toString();
      NotificationsUtils.throwWarning(message);
    });
  }

  /**
   * OnInsertVehicle
   * @param {VehicleFormModel[]} vehiclesIncomming
   */
  @EAMethod({
    loading: true,
  })
  public async onInsertVehicle(vehiclesIncomming: VehicleFormModel[]) {
    let unifiedExpirationDate: Date | undefined;

   
    if (this.fleetUnifiedExpirationModel.formValues.hasUnifiedExpiration &&
     this.operationType !== OperationTypeEnum.MANTENIMIENTO) {
      unifiedExpirationDate = cloneDeep(this.fleetUnifiedExpirationModel.formValues.unifiedExpirationDate) as Date;

      // Effective date is the day after the unified expiration date
      unifiedExpirationDate.setDate(unifiedExpirationDate.getDate() + 1);
    }

    vehiclesIncomming.forEach((vehicle: VehicleFormModel) => {
      // Fall back to the vehicle's effective date if its not unified
      vehicle.effectiveDate = unifiedExpirationDate || vehicle.effectiveDate as Date;
      vehicle.plateNumber = vehicle.plateNumber?.toString()?.toUpperCase();
    });

    await this.processIncommingVehicles(vehiclesIncomming);
    
  }

  /**
   * Process incomming vehicles
   * @param {VehicleFormModel[]} vehiclesIncomming
   */
  async processIncommingVehicles(vehiclesIncomming: VehicleFormModel[]) {
    //Nuevos vehiculos que habrá que dar de alta en la flota / crear oferta
    let newVehicles: VehicleFormModel[] = [];
    const vehiclesAlreadyAdded: VehicleFormModel[] = [];
   
    this.comprobeIsNewVehicle(vehiclesIncomming, newVehicles, vehiclesAlreadyAdded);
 
    this.throwWarningForAlreadyAddedVehicles(vehiclesAlreadyAdded);

    if (newVehicles.length) {
      
      const maxVehiclesValidationsResult = this.validateMaxVehicles(this.vehicles.length, newVehicles.length);

      if (maxVehiclesValidationsResult.canAddVehicles) {
        // Si es carga manual y el vehiculo es un vehiculo sin matricula, se gestiona diferente
        if (newVehicles.length === 1 && newVehicles[0].plateNumberType === TipoMatriculaEnum.NOMATRICULA) {
          await this.createNewVehicleWithoutPlateNumber(newVehicles);
        } else if (!newVehicles.every(newVehicle => newVehicle.invalid)) {
          const addVehicleResponse = await this.createVehiclesOffer(newVehicles);
          newVehicles = this.setOfferToVehicles(addVehicleResponse, newVehicles);

          this.vehicles.unshift(...newVehicles);
          this.vehicles = FleetInputVehiclesDataUtils.sortVehicleList(this.vehicles);
          this.update();
        }
        
      }
      if (maxVehiclesValidationsResult.warningMessage !== '') {
        NotificationsUtils.throwWarning(maxVehiclesValidationsResult.warningMessage);
      }
    }
  }

  /**
   * Create new vehicle without plateNumber and control errors
   * @param {VehicleFormModel[]}  newVehicles
   */
  async createNewVehicleWithoutPlateNumber(newVehicles: VehicleFormModel[]) {
    const createVehicleResponse = await this.createVehicleWithoutPlateNumberOffer(newVehicles[0]);

    if (createVehicleResponse?.ok) {

      newVehicles = this.setOfferWithoutPlateNumberToVehicle(createVehicleResponse, newVehicles);

      this.vehicles.unshift(...newVehicles);
      this.vehicles = FleetInputVehiclesDataUtils.sortVehicleList(this.vehicles);
      this.update();

      // If create risk but exist any problem and must to throw any notification
      if (createVehicleResponse?.errors?.[0]?.message) {
        const isErrorMessage = createVehicleResponse.errors.length > 1 ? true : !!createVehicleResponse.vehiculoValido;
        this.handleCreatedVehicleWithErros(
          newVehicles[0], createVehicleResponse.errors?.[0].message, isErrorMessage
        );
      }
      
    } else {
      throwIfResponseHasErrors(createVehicleResponse as ResponseWithErrors);
    }
  }

  /**
   * Set description
   * @param {VehicleFormModel[]} vehiclesIncomming 
   * @param {VehicleFormModel[]} newVehicles 
   * @param {VehicleFormModel[]} vehiclesAlreadyAdded 
   */
  comprobeIsNewVehicle(
    vehiclesIncomming: VehicleFormModel[],
    newVehicles: VehicleFormModel[],
    vehiclesAlreadyAdded: VehicleFormModel[]) {

    for (const incommingVehicle of vehiclesIncomming) {
      // In case 'NO-MATRICULA' is needed to compare only the 12 last elements
      if (incommingVehicle.plateNumberType === TipoMatriculaEnum.NOMATRICULA &&
      this.vehicles.find(vehicle => vehicle.plateNumber === incommingVehicle.plateNumber?.slice(5))) {
        vehiclesAlreadyAdded.push(incommingVehicle);
      } else if (this.vehicles.find(vehicle => vehicle.plateNumber === incommingVehicle.plateNumber)) {
        //Si el vehiculo no pertenece a la flota y no está duplicado en los entrantes se añadira a nuevos vehiculos
        vehiclesAlreadyAdded.push(incommingVehicle);
      } else {
        //Añadimos los vehiculos al array de nuevos vehiculos para controlar que no se añaden mas de 1 vez
        newVehicles.push(incommingVehicle);
      }
    }
  }

  /**
   * Show delete vehicles confirmation dialog.
   * 
   * @param {Row[]} rows Array of selected rows to remove
   */
  onRemoveVehiclesBtn(rows: Row[]) {
    // Limpiar posible errores mostrados anteriormente
    NotificationsUtils.clearNotifications();
    EAErrorManager.clearError();
    if (rows.length) {
      const vehicles = rows.map(row => row.getData() as VehicleFormModel);
      const modifier: 'single' | 'multi' = vehicles.length === 1 ? 'single' : 'multi';
      const label = `fleets.fleetsFlow.inputVehiclesData.deleteDialog.description.${modifier}`;
      this.confirmVehicleDeletionDescription = `${this.$t(label)}`;
      this.isConfirmVehicleDeletionVisible = true;
      this.vehiclesToDelete = vehicles;
    }
  }

  /**
   * Close delete vehicle modal and reset vehicles to delet array.
   */
  cancelDeletion(): void {
    this.isConfirmVehicleDeletionVisible = false;
    this.vehiclesToDelete = [];
  }

  /**
   * Delete selected vehicles form fleet.
   */
  @EAMethod({
    loading: true
  })
  async deleteVehicles(): Promise<void> {
    const vehiclesToDelete = cloneDeep(this.vehiclesToDelete);
    const vehiclesWithOfferToDelete = vehiclesToDelete.filter(vehicle => vehicle.offerNumber);
    const vehiclesNonOfferToDelete = vehiclesToDelete.filter(vehicle => !vehicle.offerNumber);
    this.cancelDeletion();
    const deleteApi = new EADeleteVehicleApi();
    const deleteResult = await deleteApi.deleteVehicleOperation({
      deleteVehicleRequest: {
        codigoFlota: this.fleetCode,
        versionFlota: this.fleetVersion,
        vehiculos: vehiclesWithOfferToDelete.map(vehicle => {
          return {
            codigoPoliza: vehicle.offerNumber as string,
            versionPoliza: vehicle.offerVersion as number
          };
        })
      }
    });

    if (deleteResult) {
      // Take correct deletions
      const deletedOk = deleteResult.resultados?.filter(res => res.ok) || [];
      this.deleteVehiclesFromTable(deletedOk);
      this.deleteNonOfferVehicles(vehiclesNonOfferToDelete);

      // Show success notification
      if (deletedOk.length || vehiclesNonOfferToDelete.length) {
        const modifier = deletedOk.concat(vehiclesNonOfferToDelete as DeleteVehicleResponseResultados[]).length > 1
          ? 'multi' : 'single';
        const label = `fleets.fleetsFlow.inputVehiclesData.deleteConfirmationMessage.${modifier}`;
        NotificationsUtils.throwSuccess(`${this.$t(label)}`);
      }

      this.update();
      this.vehiclesToDelete = []; // Clear vehicles to delete
      throwIfResponseHasErrors(deleteResult as ResponseWithErrors); // At the end of logic, show errors in screen
    }
    this.vehiclesToDelete = []; // Clear vehicles to delete
    
    this.onTableCheckChange(this.tableReferences.NonBaseSieteVehicles);
    this.onTableCheckChange(this.tableReferences.OkBaseSieteVehicles);
  }

  /**
   * Delete given vehicles from table.
   * @param {VehicleFormModel[]} vehicles 
   */
  deleteNonOfferVehicles(vehicles: VehicleFormModel[]) {
    if (vehicles.length) {
      const plateNumbersToDelete = vehicles.map(vehicle => vehicle.plateNumber);
      const nonDeletedVehicles = this.vehicles.filter(
        vehicle => plateNumbersToDelete.indexOf(vehicle.plateNumber as string) === -1
      );
      this.vehicles = nonDeletedVehicles;

      // Refresh tables data
      this.mapTablesDataToRows();
    }
  }

  /**
   * Delete given vehicles from table.
   * @param {DeleteVehicleResponseResultados[]} vehicles 
   */
  deleteVehiclesFromTable(vehicles: DeleteVehicleResponseResultados[]) {
    if (vehicles.length) {
      const offerNumbersToDelete = vehicles.map(vehicle => vehicle.codigoPoliza);
      this.model.errorsVehicles = this.model.errorsVehicles.filter(
        err => offerNumbersToDelete.indexOf(err.offerNumber as string) === -1
      );
      const nonDeletedVehicles = this.vehicles.filter(
        vehicle => offerNumbersToDelete.indexOf(vehicle.offerNumber as string) === -1
      );
      this.vehicles = nonDeletedVehicles;

      // Refresh tables data
      this.mapTablesDataToRows();
    }
  }

  /**
   * Perform a query to BaseSIETE asynchronously
   */
  @EAMethod()
  async queryBaseSiete(): Promise<void> {
    let baseSieteResults: QueryBaseSieteResponse | null = null;
    let errorVehicle = false;
    // Limpiar posible errores mostrados anteriormente
    NotificationsUtils.clearNotifications();
    EAErrorManager.clearError();
    this.callingToBaseSiete = true;
    // Filter only vehicles that have not been already consukted to BaseSIETE
    this.vehiclesToBaseSiete = this.vehicles.filter(vehicle => !vehicle.isBaseSieteData);
  
    try {
      const apiQueryBaseSiete = new EAQueryBaseSieteApi();
      baseSieteResults = await apiQueryBaseSiete.queryBaseSieteOperation({
        queryBaseSieteRequest: {
          codigoFlota: this.fleetCode,
          versionFlota: this.fleetVersion,
          listaMatriculasVehiculo: this.mapVehiclesToBaseSieteRequest(this.vehiclesToBaseSiete)
        }
      });
    } catch (err) {
      this.callingToBaseSiete = false;
      this.showNotifyMessagePopup(NotificationsTypeEnum.Error);
      throw err;
    }

    // Base Siete results OK
    if (baseSieteResults?.resultadosVehiculos?.length) {

      baseSieteResults.resultadosVehiculos.forEach(vehicleResult => {
        const foundVehicle = this.vehicles.find(vehicle => vehicle.offerNumber === vehicleResult.codigoPoliza);

        if (vehicleResult.mensajesError?.length &&
        vehicleResult.mensajesError[0].descripcionError &&
        vehicleResult.ok &&
        foundVehicle) {
          errorVehicle = true;
          // If create risk but exist any problem and must to throw any notification
          this.handleCreatedVehicleWithErros(
            foundVehicle,
            vehicleResult.mensajesError[0].descripcionError,
            !!vehicleResult.vehiculoValido
          );
        }
      });
    
      // Check for already insured vehicles and update list
      this.checkAlreadyInsuredVehicle(baseSieteResults.errors as Error[]);

      this.setBaseSieteDataToVehicles(baseSieteResults.resultadosVehiculos);
      const anyKoVehicle = baseSieteResults.resultadosVehiculos.find(result => !result.ok);
      if (anyKoVehicle) {
        this.showNotifyErrorMessageIfDifferentFlow(NotificationsTypeEnum.Warning);
      } else if (!errorVehicle) {
        this.showNotifyErrorMessageIfDifferentFlow(NotificationsTypeEnum.Success);
      }
      this.update();
    }
    
    this.callingToBaseSiete = false;
    if (!errorVehicle) {
      NotificationsUtils.comprobeErrors(baseSieteResults as ResponseWithErrors, true);
    }
  }
  
  /**
   * Check response error code and results to determine if global error must be thrown or not.
   * @param {QueryBaseSieteResponse} response 
   */
  throwIfResponseIsKnownErrorCode(response: QueryBaseSieteResponse) {
    // Check if only 1 error and error code is the one given by BFF when Cobol service has failed
    const foundOKVehicle = response.resultadosVehiculos?.find(result => result.ok);
    if (
      response?.errors?.length === 1
      && response.errors[0].code === ZON_ERROR_BASESIETE
      && !foundOKVehicle
    ) {
      this.callingToBaseSiete = false;
      this.showNotifyMessagePopup(NotificationsTypeEnum.Error);
      throwIfResponseHasErrors(response as ResponseWithErrors);
    }
  }

  /**
   * Set flag `isBaseSieteData` to vehicles that result has been OK.
   * 
   * @param {QueryBaseSieteResponseResultadosVehiculos[]} vehicleResults
   */
  setBaseSieteDataToVehicles(vehicleResults: QueryBaseSieteResponseResultadosVehiculos[]) {

    vehicleResults.forEach(vehicleResult => {
      const foundVehicle = this.vehicles.find(vehicle => vehicle.offerNumber === vehicleResult.codigoPoliza);

      if (foundVehicle) {
        foundVehicle.isBaseSieteData = vehicleResult.posiblesVehiculos?.length === 1 || vehicleResult.ok;
        foundVehicle.vehicleType = vehicleResult.tipoVehiculo;
        foundVehicle.possibleVehicles = vehicleResult.posiblesVehiculos || [];

        foundVehicle.accessoriesValue = vehicleResult.valorAccesoriosTotal;
        foundVehicle.vehicleValue = vehicleResult.valorVehiculo;
        foundVehicle.vehicleMaxWeight = vehicleResult.valorPesoMaximo;

        // Set Brand / Model / Version to vehicle
        this.setBrandModelVersionDescriptions(foundVehicle, vehicleResult.posiblesVehiculos || []);

        if (vehicleResult.ok) {
  
          foundVehicle.invalid = vehicleResult.posiblesVehiculos && vehicleResult.posiblesVehiculos.length !== 1;

          foundVehicle.invalidValues = !vehicleResult.valorVehiculo ||
              (this.pamVehicleTypes.includes(vehicleResult.tipoVehiculo as string) && !vehicleResult.valorPesoMaximo);
          
        } else {
          foundVehicle.invalid = true;
          foundVehicle.invalidValues = true;
        }

        // Delete old element and substitue by a new one
        const findIndex = this.vehicles.findIndex(vehicle => vehicle.offerNumber === vehicleResult.codigoPoliza);
        if (findIndex !== -1) {
          this.vehicles.splice(findIndex, 1, foundVehicle);
        }
      }
    });

    // Refresh tables data
    this.mapTablesDataToRows();
  }

  /**
   * Set descriptions for brand / model / version given the possible combinations.
   * @param {VehicleFormModel} vehicle 
   * @param {QueryBaseSieteResponsePosiblesVehiculos[]} combinations 
   */
  setBrandModelVersionDescriptions(vehicle: VehicleFormModel, combinations: QueryBaseSieteResponsePosiblesVehiculos[]) {
    let brand: string | undefined;
    let isBrandDifferent = false;
    let model: string | undefined;
    let modelCode: string | undefined;
    let isModelDifferent = false;

    // Only 1 combination
    if (combinations.length === 1) {
      vehicle.vehicleBrand = combinations[0].descripcionMarcaVehiculo;
      vehicle.vehicleModel = combinations[0].descripcionModeloVehiculo;
      vehicle.vehicleVersion = combinations[0].descripcionVersionVehiculo;
      vehicle.codigoVehiculo = combinations[0].codigoVehiculo;
      return;
    }

    // More than 1 combination
    for (const combination of combinations) {
      if (brand !== undefined && brand !== combination.descripcionMarcaVehiculo) {
        isBrandDifferent = true;
      }
      brand = combination.descripcionMarcaVehiculo;
      const currentCombinationModelCode = combination.codigoVehiculo.slice(4, 7);

      if (model !== undefined) {
        if (model !== combination.descripcionModeloVehiculo || modelCode !== currentCombinationModelCode) {
          isModelDifferent = true;
        }
      }
      model = combination.descripcionModeloVehiculo;
      modelCode = currentCombinationModelCode;
    }

    // Set brand if unique
    if (!isBrandDifferent && brand) {
      vehicle.vehicleBrand = brand;
    }

    // Set model if unique
    if (!isModelDifferent && model) {
      vehicle.vehicleModel = model;
    }
  }

  /**
   * 
   * @param {VehicleFormModel[]} vehicles
   * @returns {QueryBaseSieteRequestListaMatriculasVehiculo[]}
   */
  mapVehiclesToBaseSieteRequest(vehicles: VehicleFormModel[]): QueryBaseSieteRequestListaMatriculasVehiculo[] {
    return vehicles.map(vehicle =>
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions, implicit-arrow-linebreak
      ({
        codigoPoliza: vehicle.offerNumber,
        versionPoliza: vehicle.offerVersion,
        tipoMatricula: vehicle.plateNumberType,
        matriculaVehiculo: vehicle.plateNumber
      } as QueryBaseSieteRequestListaMatriculasVehiculo)
    );
  }

  /**
   * Show top-right notification error if different current flow than initial flow
   * @param {'success' | 'warning' | 'error'} type 
   */
  showNotifyErrorMessageIfDifferentFlow(type: NotificationsTypeEnum) {
    this.showNotifyMessage(type);
    this.showNotifyMessagePopup(type);
  }

  /**
   * Show overlayed notify message in the top right corner of the screen.
   * @param {'success' | 'warning' | 'error'} type 
   */
  showNotifyMessagePopup(type: NotificationsTypeEnum) {
    const isSame = NotificationsUtils.isCurrentFlowSameThan(this.flowId);
    if (!isSame) {
      const title = `fleets.fleetsFlow.inputVehiclesData.`+
      `baseSieteResultsNotifications.${type.toLocaleLowerCase()}.title`;
      const message = `fleets.fleetsFlow.inputVehiclesData.`+
      `baseSieteResultsNotifications.${type.toLocaleLowerCase()}.message`;
      this.$eaNotify({
        title: `${this.$t(title)}`,
        message: `${this.$t(message)}`,
        duration: 0, // Infinite duration
        type: type.toLocaleLowerCase() as EaMessageType
      });
    }
  }

  /**
   * Show overlayed notify message in the top right corner of the screen.
   * @param {'success' | 'warning' | 'error'} type 
   */
  showNotifyMessage(type: NotificationsTypeEnum) {
    const message = `fleets.fleetsFlow.inputVehiclesData.`+
    `baseSieteResultsNotifications.${type.toLocaleLowerCase()}.message`;
    NotificationsUtils.launchNotifications([
      {
        title: type,
        message: `${this.$t(message)}`,
        type
      }], this.flowId);
  }
  
  /**
   * Sets the 
   * @param {AddVehicleResponseResultados[] | undefined} addVehicleResponse
   * @param {VehicleFormModel[]} newVehicles
   * @returns {AddVehicleRequestListaMatriculasVehiculo}
   */
  public setOfferToVehicles(
    addVehicleResponse: AddVehicleResponseResultados[] | undefined,
    newVehicles: VehicleFormModel[]
  ): VehicleFormModel[] {
    if (addVehicleResponse?.length) {
      for (const vehicleResponse of addVehicleResponse) {
        const vehicleFound: VehicleFormModel = newVehicles.find(
          vehicle => vehicleResponse.numeroMatriculaSinValidaciones === vehicle.plateNumber
            && vehicleResponse.tipoMatricula === vehicle.plateNumberType) as VehicleFormModel ;
        if (vehicleResponse?.ok) {
          vehicleFound.offerNumber = vehicleResponse.codigoPoliza;
          vehicleFound.offerVersion = vehicleResponse.versionPoliza;
          vehicleFound.plateNumberType = vehicleResponse.tipoMatricula;
          vehicleFound.plateNumber = vehicleResponse.numeroMatriculaSinValidaciones;
        } else {
          vehicleFound.invalid = true;
          const message = this.$t(`fleets.fleetsFlow.inputVehiclesData.manualInput.createVehicleOfferError`, {
            plateNumber: vehicleResponse.numeroMatriculaSinValidaciones,
          }).toString();
          NotificationsUtils.throwWarning(message);
        }
      }
    }
    return newVehicles;
  }

  /**
   * Sets the 
   * @param {CreateVehicleAndRiskWithoutPlateNumberResponse | undefined} createVehicleWithoutPlateNumberResponse
   * @param {VehicleFormModel[]} newVehicles
   * @returns {AddVehicleRequestListaMatriculasVehiculo}
   */
  public setOfferWithoutPlateNumberToVehicle(
    createVehicleWithoutPlateNumberResponse: CreateVehicleAndRiskWithoutPlateNumberResponse | null,
    newVehicles: VehicleFormModel[]
  ): VehicleFormModel[] {
    if (createVehicleWithoutPlateNumberResponse) {
      const vehicleFound: VehicleFormModel = newVehicles.find(
        vehicle => createVehicleWithoutPlateNumberResponse.numeroMatriculaSinValidaciones
          === vehicle.plateNumber) as VehicleFormModel;
      if (vehicleFound) {
        vehicleFound.offerNumber = createVehicleWithoutPlateNumberResponse.codigoPoliza;
        vehicleFound.offerVersion = createVehicleWithoutPlateNumberResponse.versionPoliza;
        vehicleFound.isBaseSieteData = true;
      }
    }
    return newVehicles;
  }

  /**
   * Method to map vehicles
   * @param {VehicleFormModel} incommingVehicle
   * @returns {AddVehicleRequestListaMatriculasVehiculo}
   */
  public mapVehicleToRequest(incommingVehicle: VehicleFormModel): AddVehicleRequestListaMatriculasVehiculo {
    //Añadimos los nuevos vehiculos a la petición si no hay que editarlos antes
    return {
      codigoUsoPorMatricula: `${incommingVehicle.plateUseId}`,
      fechaEfectoPoliza: Utils.convertDateToBffString(incommingVehicle.effectiveDate),
      numeroMatriculaSinValidaciones: incommingVehicle.plateNumber ? incommingVehicle.plateNumber : '',
      tipoMatricula: String(incommingVehicle.plateNumberType) as TipoMatriculaEnum,
      numeroPolizaActual: `${incommingVehicle.policyNumber}`,
      valorAccesoriosTotal: incommingVehicle.accessoriesValue,
    };
  }

  /**
   * CreateVehiclesOffer
   * @param {AddVehicleRequestListaMatriculasVehiculo[]} listaMatriculasVehiculo
   */
  @EAMethod({
    loading: true,
  })
  public async createVehiclesOffer(listaMatriculasVehiculo: VehicleFormModel[]):
  Promise<AddVehicleResponseResultados[] | undefined> {
    //Creamos la petición que vamos a realizar
    const addVehicleRequest: AddVehicleRequest = {
      codigoFlota: this.fleetCode,
      versionFlota: this.fleetVersion,
      listaMatriculasVehiculo: [],
    };
    for (const vehicle of listaMatriculasVehiculo) {
      if (!vehicle.invalid) {
        addVehicleRequest.listaMatriculasVehiculo.push(this.mapVehicleToRequest(vehicle));
      }
        
    }
    
    const addVehicleResponse = await this.vehicleApi.addVehicleOperation({
      addVehicleRequest,
    });
    throwIfResponseHasErrors(addVehicleResponse as ResponseWithErrors);
    return addVehicleResponse?.resultados;
  }

  /**
   * CreateVehicleWithoutPlateNumberOffer
   * @param {VehicleFormModel} vehicleWithoutMat
   */
  @EAMethod({
    loading: true,
  })
  public async createVehicleWithoutPlateNumberOffer(vehicleWithoutMat: VehicleFormModel):
  Promise<CreateVehicleAndRiskWithoutPlateNumberResponse | null> {
    //Creamos la petición que vamos a realizar
    const createVehicleAndRiskWithoutPlateNumberRequest: CreateVehicleAndRiskWithoutPlateNumberRequest = {
      codigoFlota: this.fleetCode,
      versionFlota: this.fleetVersion,
      numeroMatriculaSinValidaciones: vehicleWithoutMat.plateNumber as string,
      codigoVehiculo: vehicleWithoutMat.codigoVehiculo as string,
      valorAccesoriosTotal: vehicleWithoutMat.accessoriesValue,
      codigoUsoPorMatricula: vehicleWithoutMat.plateUseId,
      anoMatriculacion:
        vehicleWithoutMat.vehicleYear ? vehicleWithoutMat.vehicleYear.getFullYear().toString() : undefined,
      mesMatriculacion:
        vehicleWithoutMat.vehicleYear ? `0${vehicleWithoutMat.vehicleYear.getMonth() + 1}`.slice(-2): undefined,
      fechaEfectoMovimiento:
        vehicleWithoutMat.effectiveDate ? Utils.convertDateToBffString(vehicleWithoutMat.effectiveDate) : undefined,
      numeroPolizaActual: vehicleWithoutMat.policyNumber,
      valorVehiculo: vehicleWithoutMat.vehicleValue as number,
      valorPesoMaximo: vehicleWithoutMat.vehicleMaxWeight as number
    };

    const createVehicleResponse = await this.createVehicleWithoutPlateNumberApi.
      createVehicleAndRiskWithoutPlateNumberOperation({
        createVehicleAndRiskWithoutPlateNumberRequest
      });

    return createVehicleResponse;
  }

  /**
   * Activate edit vehicle dialog and show vehicle data
   * @param {OpenEditVehicleEventData} data 
   */
  public onOpenEditVehicle(data: OpenEditVehicleEventData) {
    this.editionType = data.editionType;
    this.model.fleetsEditVehicleModel.errorMsg = null;
    this.editingVehicleIndex = this.vehicles.findIndex(vehicle => vehicle.plateNumber === data.row.plateNumber);
    this.isEditVehicleOpen = true;
    this.model.fleetsEditVehicleModel.formValues = data.row;
  }

  /**
   * Update vehicle edited data
   * @param {VehicleFormModel} editedVehicle
   */
  @EAMethod({
    loading: true
  })
  public async updateVehicle(editedVehicle: VehicleFormModel) {
    // Limpiar posible errores mostrados anteriormente
    NotificationsUtils.clearNotifications();
    EAErrorManager.clearError();
    
    const foundVehicleIndex = this.vehicles.findIndex(vehicle => vehicle.plateNumber === editedVehicle.plateNumber);
    const vehicleIndex: number = this.editingVehicleIndex as number;
    this.isEditVehicleOpen = false;
    
    if (foundVehicleIndex === vehicleIndex || foundVehicleIndex === -1) {
      // Elimina el vehiculo del array para tratarlo como nuevo vehiculo y crear la oferta.
      this.vehicles.splice(vehicleIndex, 1);
      try {
      //Si todo ha ido bien, especificamos que el vehiculo es válido
        editedVehicle.invalid = false;
        editedVehicle.invalidValues = false;
        editedVehicle.invalidDate = false;
        // Y lo tratamos como un vehiculo nuevo a añadir
        await this.onInsertVehicle([editedVehicle]);
        NotificationsUtils.throwSuccess(
          this.$t(`fleets.fleetsFlow.inputVehiclesData.editVehicle.alertSuccess`).toString());
      } catch (exception) {
        editedVehicle.invalid = true;
        // Si la comunicación falla por lo que sea volvemos a poner el vehiculo en la tabla
        this.vehicles.unshift(editedVehicle);
        throw exception;
      }
      this.update();
    } else {
      this.throwWarningForAlreadyAddedVehicles([editedVehicle]);
    }
  }
  

  /**
   * Returns table component referenced as 'nonBaseSieteVehicles'.
   * @returns {Table | undefined} 
   */
  getTableNoBaseSieteComponent(): Table | undefined {
    return this.$refs[this.tableReferences.NonBaseSieteVehicles] as Table | undefined;
  }

  /**
   * Returns table component referenced as 'okBaseSieteVehicles'.
   * @returns {Table | undefined} 
   */
  getTableBaseSieteComponent(): Table | undefined {
    return this.$refs[this.tableReferences.OkBaseSieteVehicles] as Table | undefined;
  }

  /**
   * Returns correspondant table component by it's reference.
   * 
   * @param {string} ref
   * @returns {Table | undefined} table component or `undefined` if reference not found
   */
  getTableComponentByRef(ref: string): Table | undefined {
    if (ref === this.tableReferences.NonBaseSieteVehicles) {
      return this.getTableNoBaseSieteComponent();
    } else if (ref === this.tableReferences.OkBaseSieteVehicles) {
      return this.getTableBaseSieteComponent();
    } else {
      return undefined;
    }
  }

  /**
   * Do a mapDataToRows() in both tables.
   */
  mapTablesDataToRows() {
    const table1 = this.getTableNoBaseSieteComponent();
    table1?.mapDataToRows();
    const table2 = this.getTableBaseSieteComponent();
    table2?.mapDataToRows();
  }

  /**
   * Update common data from vehicle
   * @param {VehicleFormModel} editedVehicle
   */
  @EAMethod({
    loading: true
  })
  public async onUpdateCommonData(editedVehicle: VehicleFormModel) {

    // Limpiar posible errores mostrados anteriormente
    NotificationsUtils.clearNotifications();
    EAErrorManager.clearError();

    /**
     * TODO: Incluir campo de tipo vehiculo al modificar el uso
     * Se presupone que los usos a elegir serán los válidos
     */

    try {
      const vehicleIndex: number = this.editingVehicleIndex as number;
      if (editedVehicle.offerNumber) {
        if (editedVehicle.vehicleUseId) {
          
          const updatedRiskResponse = await FleetInputVehiclesDataApi.updateRisk(
            editedVehicle, this.fleetCode, this.fleetVersion);
          // Not show GDC error
          const filterError = updatedRiskResponse?.errors?.filter(item => item.code !== FleetGDCError.ErrorGDC);
          throwIfResponseHasErrors({
            errors: filterError
          });

        } else {
          
          const updatedDateResponse = await FleetInputVehiclesDataApi.updateVehicleDate(
            editedVehicle, this.fleetCode, this.fleetVersion);
          throwIfResponseHasErrors(updatedDateResponse as unknown as ResponseWithErrors);

        }

        editedVehicle.invalidValues = false;
        editedVehicle.invalidDate = false;

        // Quitamos la versión sin modificar del vehículo
        this.vehicles.splice(vehicleIndex, 1);

        // Incluimos la versión modificada del vehículo en la tabla
        this.vehicles.push(editedVehicle);
      } else {
        // Quitamos la versión sin modificar del vehículo
        this.vehicles.splice(vehicleIndex, 1);

        editedVehicle.invalid = false;
        editedVehicle.invalidValues = false;
        await this.onInsertVehicle([editedVehicle]);
      }

     
      this.isEditVehicleOpen = false;
      NotificationsUtils.throwSuccess(
        this.$t(`fleets.fleetsFlow.inputVehiclesData.editVehicle.alertSuccess`).toString());
      this.update();
     
    } catch (error) {
      const eaError = error as EAError;
      if (this.isEditVehicleOpen && eaError && eaError.getMessage) {
        this.model.fleetsEditVehicleModel.errorMsg = await eaError.getMessage();
      } else {
        throw error;
      }
    }
    
  }

  /**
   * Save edit vehicle after complete data after checking it with baseSIETE
   * @param {VehicleFormModel} editedVehicle
   */
  @EAMethod({
    loading: true
  })
  public async onSaveEditedVehicleAfterBaseSiete(editedVehicle: VehicleFormModel) {
    // Limpiar posible errores mostrados anteriormente
    NotificationsUtils.clearNotifications();
    EAErrorManager.clearError();
    
    const createdRisk = await FleetInputVehiclesDataApi.createRiskWithManualData(
      editedVehicle, this.fleetCode, this.fleetVersion);
       
    // Comprobamos que el vehículo no esté ya asegurados
    this.checkAlreadyInsuredVehicle(createdRisk?.errors as Error[]);
    const vehicleIsAlreadyInsuredError =
        createdRisk?.errors?.filter(error => error.code === ALREADY_VEHICLE_INSURED_ERROR_CODE);
    if (vehicleIsAlreadyInsuredError?.length) {
      const showWarning = true;
      NotificationsUtils.comprobeErrors({
        errors: vehicleIsAlreadyInsuredError
      } as unknown as ResponseWithErrors, showWarning);
      this.isEditVehicleOpen = false;
      return;
    }

    if (createdRisk?.ok) {
      if (createdRisk?.errors?.find(error => error.code === '6088')) {
        //Preparamos un único posible vehículo para el mapeo de los controles marca/modelo/versión
        editedVehicle.possibleVehicles = [{
          codigoVehiculo: editedVehicle.codigoVehiculo as string,
          descripcionMarcaVehiculo: editedVehicle.vehicleBrand as string,
          descripcionModeloVehiculo: editedVehicle.vehicleModel as string,
          descripcionVersionVehiculo: editedVehicle.vehicleVersion as string
        }];
        this.onOpenEditVehicle({
          editionType: VehicleEditionTypeEnum.invalid,
          row: editedVehicle
        });
         
      } else if (createdRisk?.errors?.[0]?.message) {
        const isErrorMessage = createdRisk.errors.length > 1 ? true : !!createdRisk.vehiculoValido;
        // If create risk but exist any problem and must to throw any notification
        this.handleCreatedVehicleWithErros(
          editedVehicle, createdRisk.errors?.[0].message, isErrorMessage
        );
      } else {
        try {
          const showLowSeverityErrors = true;
          const errors = createdRisk?.errors?.filter(error => error.code !== '6088');
          NotificationsUtils.comprobeErrors({
            errors
          } as unknown as ResponseWithErrors, showLowSeverityErrors);
          editedVehicle.invalid = false;
          editedVehicle.invalidValues = false;
          editedVehicle.invalidDate = false;
       
          this.updateVehicleData(editedVehicle);

          this.isEditVehicleOpen = false;
          NotificationsUtils.throwSuccess(
            this.$t(`fleets.fleetsFlow.inputVehiclesData.editVehicle.alertSuccess`).toString());
          this.mapTablesDataToRows();
          this.update();
        } catch (errors) {
          this.isEditVehicleOpen = false;
          throw errors;
        }
      }
    } else {
      throwIfResponseHasErrors(createdRisk as ResponseWithErrors);
    }
  }

  /**
   * Set description
   * @param {VehicleFormModel} editedVehicle 
   */
  updateVehicleData(editedVehicle: VehicleFormModel) {

    const vehicleIndex: number =
      this.vehicles.findIndex(vehicle => vehicle.plateNumber?.slice(-12) === editedVehicle.plateNumber?.slice(-12));
    // Quitamos la versión sin modificar del vehículo
    this.vehicles.splice(vehicleIndex, 1);

    editedVehicle.plateNumber = editedVehicle.plateNumber?.toString()?.toUpperCase();
    // Incluimos la versión modificada del vehículo en la tabla
    this.vehicles.unshift(editedVehicle);

    this.vehicles = FleetInputVehiclesDataUtils.sortVehicleList(this.vehicles);
  }


  /**
   * Method to look for the already insured vehicle error and update the list
   * @param {Error} errors 
   */
  checkAlreadyInsuredVehicle(errors: Error[]) {
    const plateNumbersToDelete: string[] = [];
    const alreadyInsuredVehicleError: Error[] = [];
    errors.forEach(error => {
      if (error.code === ALREADY_VEHICLE_INSURED_ERROR_CODE) {
        alreadyInsuredVehicleError.push(error);
        //Set error 6887 as warning
        error.severity = 2;
      }
    });
    alreadyInsuredVehicleError?.forEach(error => {
      this.vehicles.forEach(vehicle => {
        if (vehicle.plateNumber &&
             error.message.toUpperCase().includes(vehicle.plateNumber?.toUpperCase())) {
          plateNumbersToDelete.push(vehicle.plateNumber);
        }
      });
    });
    const vehiclesNotInsured = this.vehicles
      .filter(vehicle => !plateNumbersToDelete.some(plateNumber => plateNumber === vehicle.plateNumber as string)
      );
    //Reassign array to trigger updates
    this.vehicles = [...vehiclesNotInsured];
  }

  /**
   * Save edit vehicle after complete data after checking it with baseSIETE
   * @param {VehicleFormModel} editedVehicle
   */
  @EAMethod({
    loading: true
  })
  public async onSaveEditedVehicleAfterEditUse(editedVehicle: VehicleFormModel) {
    await this.onSaveEditedVehicleAfterBaseSiete(editedVehicle);
  }


  /**
   * Save edit vehicle after complete data after checking it with baseSIETE
   * @param {VehicleFormModel} editedVehicle
   */
  @EAMethod({
    loading: true
  })
  public async onSaveEditedVehicleAfterEditDate(editedVehicle: VehicleFormModel) {
    const updatedVehicle = await FleetInputVehiclesDataApi.updateVehicleDate(
      editedVehicle, this.fleetCode, this.fleetVersion);
    try {
      const showLowSeverityErrors = false;
      const errors = updatedVehicle?.errors?.filter(error => error.code !== '6088');
      NotificationsUtils.comprobeErrors({
        errors
      } as unknown as ResponseWithErrors, showLowSeverityErrors);
      editedVehicle.invalidDate = false;
      //TODO: Susceptible de encapsular a parte
      const vehicleIndex: number = this.editingVehicleIndex as number;
      // Quitamos la versión sin modificar del vehículo
      this.vehicles.splice(vehicleIndex, 1);

      editedVehicle.plateNumber = editedVehicle.plateNumber?.toString()?.toUpperCase();
      // Incluimos la versión modificada del vehículo en la tabla
      this.vehicles.unshift(editedVehicle);
      this.vehicles = FleetInputVehiclesDataUtils.sortVehicleList(this.vehicles);
      this.isEditVehicleOpen = false;
      NotificationsUtils.throwSuccess(
        this.$t(`fleets.fleetsFlow.inputVehiclesData.editVehicle.alertSuccess`).toString());
      this.mapTablesDataToRows();
      this.update();
    } catch (errors) {
      this.isEditVehicleOpen = false;
      throw errors;
    }
  }
  

  //  -- INTERFACE OPERATIONS --
  
  /**
   * Look for any active checks in the given table ref.
   * @param {string} tableRef
   */
  onTableCheckChange(tableRef: string) {
    EAErrorManager.clearError();
    const eaTable = this.getTableComponentByRef(tableRef);
    if (eaTable) {
      const rows = eaTable.getSelectedRows();
      if (rows.length === 0) {
        this.hasActiveChecks[tableRef] = false;
      } else {
        this.hasActiveChecks[tableRef] = true;
      }
    } else {
      this.hasActiveChecks[tableRef] = false;
    }
  }

  /**
   * 
   * @param {number} totalVehiclesAdded 
   * @param {number} totalVehiclesIncomming 
   * @returns {MaxVehiclesValidations}
   */
  public validateMaxVehicles(totalVehiclesAdded: number,
    totalVehiclesIncomming: number): MaxVehiclesValidations {
    const totalVehicles = totalVehiclesAdded + totalVehiclesIncomming + this.alreadyEmmitedVehicles;
    const canProcessVehiles = totalVehicles <= this.vehiclesNumberRange.max;
    let warningMessage = '';
    if (this.operationType === OperationTypeEnum.MANTENIMIENTO) {
      if (totalVehicles === this.vehiclesNumberRange.max) {
        warningMessage = this.$t(`fleets.fleetsMaintenancePolicyFlow.fleetMaintenanceGroupVehicleTable`+
          `.inputVehiclesData.reachedLimitedVehicle`, {
          max: this.vehiclesNumberRange.max,
        }).toString();
      } else if (totalVehicles > this.vehiclesNumberRange.max) {
        warningMessage = this.$t('fleets.fleetsMaintenancePolicyFlow.fleetMaintenanceGroupVehicleTable.'+
          'inputVehiclesData.excedeedLimitedVehicle').toString();
      }
    } else if (totalVehicles > this.vehiclesNumberRange.max) {
      warningMessage = this.$t(`fleets.fleetsFlow.inputVehiclesData.groupInput.excedeedLimitedVehicle`, {
        max: this.vehiclesNumberRange.max,
      }).toString();
    }
    const maxVehiclesValidations: MaxVehiclesValidations = {
      canAddVehicles: canProcessVehiles,
      warningMessage: warningMessage
    };
    return maxVehiclesValidations;

  }

  /**
   * Add vehicle to list and mark it red with error icon
   * @param {VehicleFormModel} vehicleAdded
   * @param {string} errorMessage
   * @param {boolean} isShowError
   */
  handleCreatedVehicleWithErros(vehicleAdded: VehicleFormModel, errorMessage: string, isShowError: boolean) {
    vehicleAdded.isExistErrorVehicle = true; // To show the warning and the color in the table

    this.updateVehicleData(vehicleAdded);
    this.isEditVehicleOpen = false;

    if (vehicleAdded.offerNumber) {

      const findVehicle = this.model.errorsVehicles.find(vehicle => vehicle.offerNumber === vehicleAdded.offerNumber);
      // Keep the errors messages in the model
      if (!findVehicle) {
        this.model.errorsVehicles.push({
          offerNumber: vehicleAdded.offerNumber,
          isError: isShowError,
          plateNumber: vehicleAdded.plateNumber,
          errorMessage
        });
      }
    }

    this.update();
    if (isShowError) {
      // Show the error message to delete the vehicle
      const message = vehicleAdded.plateNumberType === TipoMatriculaEnum.NOMATRICULA ?
        this.$t(`fleets.fleetsFlow.inputVehiclesData.editVehicle.vehicleWithoutPlateNumberMustBeDeleted`).toString() :
        this.$t(`fleets.fleetsFlow.inputVehiclesData.editVehicle.vehicleMustBeDeleted`, {
          plateNumber: vehicleAdded.plateNumber
        }).toString();

      const warningAsNotification: ZZNotification[] = [
        {
          type: NotificationsTypeEnum.Error,
          message,
          title: NotificationsTypeEnum.Error
        }
      ];
      NotificationsUtils.launchNotifications(warningAsNotification, this.flowId);

    } else {
      // Show the warning message to contact with email or delete the vehicle
      NotificationsUtils.throwWarning(
        this.$t('fleets.fleetsFlow.inputVehiclesData.baseSieteResultsNotifications.errorPMA.errorMessage',
          {
            plateNumber: vehicleAdded.plateNumber,
            errorMessage
          }).toString()
      );
    }
  }
}
</script>
