import {
  EAFlowModel
} from '@zurich-es-npm/ea-front-web-core';
import QbSearcherModel from '@/business-components/qb-searcher/qb-searcher-model';
import {
  ZZNotification
} from '@/utils/notifications/notifications-utils';

export enum FlowViewsStepsModel {
  SearchOfferStep = 0
}

export const DEFAULT_PAGE_SIZE = 50;

/**
 * Flow model
 *
 * @export
 * @class SearchOfferModel
 * @extends {EAFlowModel}
 */
export class SearchOfferModel extends EAFlowModel {

  public searcherModel = new QbSearcherModel();
  
  public notifications: ZZNotification[] = [];
  
}
