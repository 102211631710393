/**
 * 
 */
export class SelectItemType {

  public id: string = '';

  public code: string = '';

  public name: string = '';
}
