/**
 * Text utils object with auxiliar functions
 */
export class TextUtils {

  /**
   *
   * @param {string} input
   * @return {string}
   */
  public static capitalizeFirstLetterOneWord(input: string): string {
    return input.charAt(0).toUpperCase() + input.slice(1).toLocaleLowerCase();
  }

  /**
   *
   * @param {string} input
   * @return {string}
   */
  public static capitalizeFirstLetterOfWords(input: string): string {
    return input.trim()
      .split(' ')
      .map(word => {
        const exceptions = ['de', 'DE', 'del', 'DEL', 'y', 'Y'];
        if (word.includes('-')) {
          return word.split('-')
            .map(hyphenWord => this.capitalizeFirstLetterOneWord(hyphenWord))
            .join('-');
        }
        if (word.includes('/')) {
          return word.split('/')
            .map(slashWord => this.capitalizeFirstLetterOneWord(slashWord))
            .join('/');
        }
        if (exceptions.includes(word)) {
          return word.toLocaleLowerCase();
        }
        return this.capitalizeFirstLetterOneWord(word);
      })
      .join(' ');
  }

  /**
   * Compares to strings
   * @param {string} inputA
   * @param {string} inputB
   * @returns { boolean } true if inputs are equal ignoring case; false otherwise
   */
  public static equalIgnoreCase(inputA?: string, inputB?: string): boolean {
    if (inputA && inputB) {
      return inputA.localeCompare(inputB, undefined, {
        sensitivity: 'accent'
      }) === 0;
    }

    return false;
  }


  /**
   * Delete accents and spaces
   * Example: Producción Nueva -> produccionnueva
   * @param {string} input
   * @return {string}
   */
  public static deleteAccentsAndSpaces(input: string): string {
    return input.normalize('NFD').replace(/[\u0300-\u036f-/ ]/g, '');
  }

  
  /**
   * Returns string without accent marks
   * @param {string} str
   * @returns {string}
   */
  public static removeAccents(str: string): string {
    return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  }
}

export default TextUtils;
