<template>
  <div>
    <qb-offer-issuance-data id="offerIssuanceData" v-model="model.offerIssuanceDataModel" />
    <ea-card class="m-t-16">
      <div slot="cardbody" class="p-a-16">
        <ea-row>
          <ea-col :span="12">
            <zz-client-basic-info
              id="showClientData"
              :documentationClientData="tomadorDocumentationClientData"
              class="m-b-32"
            ></zz-client-basic-info>

            <qb-document-check-emit
              id="documentList"
              :documentList="documentsToSendList"
              :documentTextCodesNoPreview="documentTextCodesNoPreview"
              v-model="checkEmitDocumentsModel"
              @showDocumentPreview="showDocumentPreview"
              title="quoteBuyGenericFlow.titleSendDocuments"
              class="m-b-32"
            ></qb-document-check-emit>

            <qb-documentation-insurance-emit
              id="restDocumentList"
              :restDocumentsList="restDocumentsList"
              :documentTextCodesNoPreview="documentTextCodesNoPreview"
              v-model="selectInsuranceTypeModel"
              @showDocumentPreview="showDocumentPreview"
              class="m-b-32"
            ></qb-documentation-insurance-emit>

            <qb-document-check-emit
              id="comunicationsList"
              :documentList="comunicationsList"
              v-model="checkEmitComunicationsModel"
              title="quoteBuyGenericFlow.titleComunications"
            ></qb-document-check-emit>

          </ea-col>

          <ea-col :span="12">
            <ea-heading level="5" class="m-b-16">
              {{$t('quoteBuyGenericFlow.selectDocumentationSendingAdress.sendDocumentation')}}
            </ea-heading>
            <multiple-email-input
              id="multipleEmailInput"
              v-model="multipleEmailInputModel"
              ref="emailInputComp"
              :isEmailRequired="isEmailRequired"
            ></multiple-email-input>
            
            <qb-select-documentation-sending-address
              v-if="showDocumentationSendingAddress"
              id="addressSelection"
              ref="addressSelectionComp"
              class="m-b-32"
              v-model="selectDocumentationSendingAddressModel"
              :corpTableDocumentsAddressType="corpTableDocumentsAddressType"
              :corpTableDocumentsCountries="corpTableDocumentsCountries"
              :documentationClientData="tomadorDocumentationClientData"
            ></qb-select-documentation-sending-address>

            <ea-row v-if="isPhoneRequired">
              <ea-col :span="12">
                <ea-heading level="5" class="m-b-16">
                  {{$t('quoteBuyGenericFlow.selectDocumentationSendingAdress.sendCommunications')}}
                </ea-heading>
                <add-edit-phone
                  style="max-width: 208px;"
                  id="addEditPhone"
                  v-model="addEditPhoneModel"
                  ref="addEditPhoneComp"
                  :documentationClientData="tomadorDocumentationClientData"
                  :isPhoneRequired="true"
                  @savePhoneAddress="savePhoneAddress"
                >
                </add-edit-phone>
              </ea-col>
            </ea-row>
          </ea-col>
        </ea-row>
      </div>
    </ea-card>

    <ea-row class="m-t-16 d-display-flex d-justify-flex-end">
      <ea-col :span="12">
        <ea-button @click="saveEmission" type="primary" size="medium">
          {{ $t('quoteBuyGenericFlow.offerIssuanceData.emitAndSend') }}
        </ea-button>
      </ea-col>
    </ea-row>
  </div>
</template>

<script lang="ts">
import {
  Component, Prop
} from 'vue-property-decorator';

import {
  mixins
} from 'vue-class-component';

import {
  EAApplicationLogger,
  EAError,
  EAMethod,
  EAValidationError,
  EAView,
  throwIfResponseHasErrors,
  ResponseWithErrors,
} from '@zurich-es-npm/ea-front-web-core';
import QbOfferIssuanceDataBusiness
  from '@/business-components/qb-offer-issuance-data/qb-offer-issuance-data-business.vue';
import {
  QuoteBuyModel,
  FlowHeaderStepsModel,
  FlowViewsStepsModel,
} from '../quote-buy-model';
import {
  EAEmitAndSendOfferApi,
  EmitAndSendOfferRequestEntradaDireccionPostal,
  EmitAndSendOfferRequestEntradaSeleccionesPostalDigital
} from '../../../services/V1/quoteAndBuy/emitAndSendOfferOperation/post';
import {
  EAGetIssueOfferDataApi,
  GetIssueOfferDataRequestTipoPolizaEnum as TipoPolizaEnum,
  GetIssueOfferDataResponseDireccionIntermediarioProductor,
  GetIssueOfferDataResponseListaDocumentos
} from '@/services/V1/quoteAndBuy/getIssueOfferDataOperation/post';
import ProductBase from '@/utils/quote-buy-product-factory/products/product-base';
import QbSelectDocumentationSendingAddressBusiness
  // eslint-disable-next-line max-len
  from '@/business-components/qb-select-documentation-sending-address/qb-select-documentation-sending-address-business.vue';
import QbSelectDocumentationSendingAddressModel
, {
  SelectedAddressEnum
} from '@/business-components/qb-select-documentation-sending-address/qb-select-documentation-sending-address-model';
import {
  AddressUtils
} from '@/utils/address-utils';
import {
  ParsedTableData, fetchCorporateTable, CorpTableNames, parseCorpTableDocuments
} from '@/utils/corporate-tables';
import AddEditPhoneModel
  from '@/business-components/addresses/selectables/add-edit-phone/add-edit-phone-model';
import AddEditPhoneBusiness
  from '@/business-components/addresses/selectables/add-edit-phone/add-edit-phone-business.vue';
import MultipleEmailInputModel from '@/business-components/multiple-email-input/multiple-email-input-model';
import MultipleEmailInputBusiness from '@/business-components/multiple-email-input/multiple-email-input-business.vue';
import {
  GetPersonAddressesResponseDomicilios,
  GetPersonAddressesResponseTelefonos
} from '@/services/V1/persons/getPersonAddressesOperation/post';
import QbDocumentCheckEmitBusiness
  from '@/business-components/qb-document-check-emit/qb-document-check-emit-business.vue';
import QbDocumentationInsuranceEmitBusiness
  from '@/business-components/qb-documentation-insurance-emit/qb-documentation-insurance-emit-business.vue';
import QbDocumentationInsuranceEmitModel
  from '@/business-components/qb-documentation-insurance-emit/qb-documentation-insurance-emit-model';
import {
  PolicyType
} from '@/types/policy-types/policy-types-enum.types';
import QbDocumentCheckEmitModel from '@/business-components/qb-document-check-emit/qb-document-check-emit-model';
import {
  EmitAndSendOfferRequestEntradaSeleccionesPostalDigitalSeleccionPostalDigitalEnum as SeleccionPostalDigitalEnum
} from '@/services/V1/quoteAndBuy/emitAndSendOfferOperation/post';
import Utils from '@/utils/utils';
import {
  EAPreviewDownloadFilesApi
} from '@/services/V1/common/previewDownloadFilesOperation/post';
import {
  PersonRole
} from '@/utils/quote-buy-product-factory/types/product-role-types';
import {
  NotificationsTypeEnum, NotificationsUtils
} from '@/utils/notifications/notifications-utils';
import {
  Roles
} from '@/types/roles/roles-enum.types';
import PersonUtils from '@/utils/person-utils';
import ZzClientBasicInfo from '@/presentational-components/clientBasicInfo/clientBasicInfo.vue';
import {
  DocumentationClientData
} from '@/presentational-components/clientBasicInfo/clientBasicInfo.type';

export interface AddressSelectionModel {
  address?: GetIssueOfferDataResponseDireccionIntermediarioProductor | GetPersonAddressesResponseDomicilios;
  secuentialCode?: string;
  filiationCode?: string;
}


@Component({
  components: {
    QbOfferIssuanceData: QbOfferIssuanceDataBusiness,
    QbSelectDocumentationSendingAddress: QbSelectDocumentationSendingAddressBusiness,
    AddEditPhone: AddEditPhoneBusiness,
    MultipleEmailInput: MultipleEmailInputBusiness,
    QbDocumentCheckEmit: QbDocumentCheckEmitBusiness,
    QbDocumentationInsuranceEmit: QbDocumentationInsuranceEmitBusiness,
    'zz-client-basic-info': ZzClientBasicInfo
  }
})

/**
 * IssueOffer view
 *
 */
export default class IssueOfferView extends mixins<EAView<QuoteBuyModel>>(EAView) {
  
  @Prop({
    required: true,
  })
    productFactory!: ProductBase;

  tomadorPerson!: PersonRole;

  public documentationData: GetIssueOfferDataResponseListaDocumentos[] = [];

  public producerIntermediaryAddress?: GetIssueOfferDataResponseDireccionIntermediarioProductor;

  public selectDocumentationSendingAddressModel = new QbSelectDocumentationSendingAddressModel();

  public multipleEmailInputModel: MultipleEmailInputModel = new MultipleEmailInputModel();
  
  public addEditPhoneModel = new AddEditPhoneModel();

  public corpTableDocumentsAddressType: ParsedTableData[] = [];

  public corpTableDocumentsCountries: ParsedTableData[] = [];

  public phoneList: GetPersonAddressesResponseTelefonos[] = [];

  public policyTakerSelectedPhone: string = '';

  public documentsToSendList: GetIssueOfferDataResponseListaDocumentos[] = [];

  public comunicationsList: GetIssueOfferDataResponseListaDocumentos[] = [];

  public restDocumentsList: GetIssueOfferDataResponseListaDocumentos[] = [];

  public selectInsuranceTypeModel: QbDocumentationInsuranceEmitModel = new QbDocumentationInsuranceEmitModel();

  public checkEmitDocumentsModel: QbDocumentCheckEmitModel = new QbDocumentCheckEmitModel();

  public checkEmitComunicationsModel: QbDocumentCheckEmitModel = new QbDocumentCheckEmitModel();

  /**
   * Text codes for documents with no preview
   */
  public documentTextCodesNoPreview = [
    'TRB00003', 'TRB00006'
  ];

  /**
   * Hook on created.
   *
   * @returns {void}
   */
  @EAMethod({
    loading: true,
  })
  public async created(): Promise<void> {
    this.tomadorPerson = this.productFactory.personRoles.find(person => person.role === Roles.Tomador) as PersonRole;
   
    // Set policy taker postal address
    this.selectDocumentationSendingAddressModel.policyTakerAddressList =
      this.tomadorPerson.addressesModel.addEditAddress.addressList;
    this.selectDocumentationSendingAddressModel.policyTakerSelectedAddressCode =
      this.tomadorPerson.addressesModel.addEditAddress.selectedAddress?.codigoSecuencialDomicilio;

    // Set policy taker selected email
    if (this.tomadorPerson.addressesModel.addEditEmail.selectedEmail?.emailContacto) {
      this.multipleEmailInputModel.emailList =
        [this.tomadorPerson.addressesModel.addEditEmail.selectedEmail?.emailContacto];
    }

    // Set policy taker phone list
    this.addEditPhoneModel.phoneList = this.tomadorPerson.addressesModel.addEditPhone.phoneList;
    this.addEditPhoneModel.selectedPhoneNumber = this.tomadorPerson.addressesModel.addEditPhone.selectedPhoneNumber;
    
    await this.fetchData();
    await this.getIssueOfferData();

    this.normalizeProducerIntermediaryAddress();
  }

  /**
   * Normalizes producer intermediary address to show it to user
   */
  public normalizeProducerIntermediaryAddress() {
    const parsedAddressType = this.corpTableDocumentsAddressType.find(
      addressType => addressType.value === this.producerIntermediaryAddress?.tipoViaDireccion
    )?.label;

    this.selectDocumentationSendingAddressModel.intermediaryAddress = AddressUtils.normalizeAddress({
      addressType: parsedAddressType,
      addressName: this.producerIntermediaryAddress?.nombreDireccion,
      addressNumber: this.producerIntermediaryAddress?.numeroDireccion,
      floorOrInformacionAdicional: this.producerIntermediaryAddress?.informacionAdicional,
      zipCode: this.producerIntermediaryAddress?.codigoPostalConLetras,
      cityName: this.producerIntermediaryAddress?.nombrePoblacion
    });
  }

  /**
   * Gets user selected address
   * @returns {AddressSelectionModel}
   */
  getAddressSelection() {
  // eslint-disable-next-line max-len
    let address: GetIssueOfferDataResponseDireccionIntermediarioProductor | GetPersonAddressesResponseDomicilios | undefined;
    let secuentialCode: string | undefined;
    let filiationCode: string | undefined;

    if (this.selectDocumentationSendingAddressModel.radioBtnSelectedAddress ===
      SelectedAddressEnum.POLICY_TAKER_ADDRESS
    ) {
      secuentialCode = this.selectDocumentationSendingAddressModel.policyTakerSelectedAddressCode;
      address = this.selectDocumentationSendingAddressModel.policyTakerAddressList.find(
        policyTakerAddress => policyTakerAddress.codigoSecuencialDomicilio === secuentialCode
      );
      filiationCode = this.tomadorDocumentationClientData.clientData?.datosBasicosPersona?.codigoFiliacion;
    } else if (this.selectDocumentationSendingAddressModel.radioBtnSelectedAddress ===
      SelectedAddressEnum.INTERMEDIARY_ADDRESS
    ) {
      address = this.producerIntermediaryAddress;
      secuentialCode = address?.codigoSecuencialDomicilio;
      filiationCode = address?.codigoFiliacion;
    }

    return {
      address,
      secuentialCode,
      filiationCode
    };
  }

  /**
   * Generates postal direction for sending to bff
   * @param {string | undefined} secuentialCode
   * @param {string | undefined} filiationCode
   * @param {GetIssueOfferDataResponseDireccionIntermediarioProductor |
   *  GetPersonAddressesResponseDomicilios | undefined} address
   * @returns {EmitAndSendOfferRequestEntradaDireccionPostal}
   */
  public generateDireccionPostal(
    secuentialCode?: string,
    filiationCode?: string,
    address?: GetIssueOfferDataResponseDireccionIntermediarioProductor | GetPersonAddressesResponseDomicilios
  ): EmitAndSendOfferRequestEntradaDireccionPostal {
    const parsedAddressType = this.corpTableDocumentsAddressType.find(
      addressType => addressType.value === address?.tipoViaDireccion
    )?.label;

    return {
      codigoFiliacion: filiationCode,
      codigoSecuencialDomicilio: secuentialCode || '   ',
      descripcionTipoViaDireccion: parsedAddressType || '',
      nombreDireccion: address?.nombreDireccion || '',
      numeroDireccion: address?.numeroDireccion || '',
      codigoPostalConLetras: address?.codigoPostalConLetras || '',
      nombrePoblacion: address?.nombrePoblacion || '',
      nombreProvincia: address?.nombreProvincia || '',
      codigoPais: address?.codigoPais || '',
      informacionAdicional: address?.informacionAdicional || ''
    };
  }

  /**
   * Fetch emitAndSendOfferOperation to send the offert
   */
  @EAMethod({
    loading: true
  })
  async saveEmission() {
    this.clearFlowErrors();

    await this.validateInnerComponents();

    if (this.showDocumentationSendingAddress) {
      this.updatePostalAddressSelectionComp();
    }
    

    try {
      const emit = new EAEmitAndSendOfferApi();
  
      let motivoMovimientoPoliza;
      if (this.model.datosCabecera?.elementosPoliza) {
        const code = 'TCMOMOVP';
        const policyElements = this.model.datosCabecera.elementosPoliza;
        motivoMovimientoPoliza = policyElements.find(element => element.codigoElemento === code)?.valorElemento;
      }

      const {
        address,
        secuentialCode,
        filiationCode
      } = this.getAddressSelection();

      NotificationsUtils.launchNotifications([{
        title: NotificationsTypeEnum.Info,
        message: this.$t('quoteBuyGenericFlow.issueOffer.showDocGenerationWarning.description').toString(),
        type: NotificationsTypeEnum.Warning
      }]);

      const seleccionesPostalDigital = this.generateSeleccionesPostalDigital();

      const emissionResponse = await emit.emitAndSendOfferOperation(
        {
          emitAndSendOfferRequest: {
            entrada: {
              codigoPoliza: this.model.offerNumber,
              versionPoliza: this.model.offerVersion,
              motivoMovimientoPoliza,
              listaDocumentos: this.documentationData,
              emails: this.multipleEmailInputModel.emailList
                .filter(email => email !== '')
                .map(email => {
                  return {
                    emailContacto: email,
                  };
                }),
              telefonos: this.isPhoneRequired ? [
                {
                  telefonoContacto: this.addEditPhoneModel.selectedPhoneNumber || ''
                }
              ] : [],
              direccionPostal: this.showDocumentationSendingAddress ?
                this.generateDireccionPostal(secuentialCode, filiationCode, address) : undefined,
              seleccionesPostalDigital
            }
          }
        }
      );

      NotificationsUtils.clearNotifications();
      const notificationsLaunched = NotificationsUtils.comprobeErrors(emissionResponse as ResponseWithErrors, true);

      if (notificationsLaunched > 0) {
        // Avoid going to next step if any notification is launched
        return;
      }
      
      this.model.offerNumber = emissionResponse?.data?.codigoPoliza || this.model.offerNumber;
      this.model.offerVersion = emissionResponse?.data?.versionPoliza || this.model.offerVersion;
      this.model.offerType = PolicyType.Pliza;
      this.model.policyDocumentEmit = this.getPreviewDocuments(this.documentationData);
      this.update();
      this.onGoToNextStep(
        FlowViewsStepsModel.SummaryAndDownloadPolicyStep, FlowHeaderStepsModel.SummaryAndDownloadPolicyStep
      );

    } catch (error) {
      NotificationsUtils.clearNotifications();
      throw error;
    }
  }

  /**
   * Generates an array with postal/digital selections
   * @returns {EmitAndSendOfferRequestEntradaSeleccionesPostalDigital[]}
   */
  generateSeleccionesPostalDigital(): EmitAndSendOfferRequestEntradaSeleccionesPostalDigital[] {
    const result: EmitAndSendOfferRequestEntradaSeleccionesPostalDigital[] = [];
    for (const key in this.selectInsuranceTypeModel.postalDigitalSelections) {
      result.push({
        nombreDocumento: key,
        seleccionPostalDigital: this.selectInsuranceTypeModel.postalDigitalSelections[key]
      });
    }
    return result;
  }

  /**
   * Fetches data for issue offer view
   */
  @EAMethod({
    loading:true
  })
  async fetchData() {
    try {
      await this.getCorpTablesData();
    } catch (error) {
      const eaError = error as EAError;
      new EAApplicationLogger().error(
        `IssueOfferView::created:: ${eaError.message}`
      );
      throw error;
    }
  }

  /**
   * Fetch corporate tables to retrieve products names and parse data.
   */
  @EAMethod({
    loading:true
  })
  async getCorpTablesData(): Promise<void> {
    try {
      const promises = [fetchCorporateTable(CorpTableNames.AddressType), fetchCorporateTable(CorpTableNames.Countries)];
      const results = await Promise.all(promises);

      this.corpTableDocumentsAddressType = parseCorpTableDocuments(results[0]);
      this.corpTableDocumentsCountries = parseCorpTableDocuments(results[1]);
    } catch (error) {
      const eaError = error as EAError;
      new EAApplicationLogger().error(
        `IssueOfferView::getCorpTablesData:: fetch corporate table :: ${eaError.message}`
      );
      throw error;
    }
  }

  /**
   * Gets data for view
   */
  @EAMethod({
    loading: true
  })
  async getIssueOfferData() {
    const api = new EAGetIssueOfferDataApi();
    const output = await api.getIssueOfferDataOperation({
      getIssueOfferDataRequest: {
        codigoPoliza: this.model.offerNumber,
        versionPoliza: this.model.offerVersion,
        tipoPoliza: TipoPolizaEnum.Incompleta
      }
    });
      
    if (output) {
      throwIfResponseHasErrors(output as ResponseWithErrors);
      if (output.listaDocumentos) {
        this.documentationData = output.listaDocumentos;
        this.producerIntermediaryAddress = output.direccionIntermediarioProductor;

        if (this.documentationData) {
          this.comunicationsList = this.getComunicationsData(this.documentationData);
          this.documentsToSendList = this.getDocumentsToSendData(this.documentationData);
          this.restDocumentsList = this.getRestOfDocuments(this.documentationData);
          this.update();
        }
      }
    }
  }

  /**
   * Updates postal address selection component
   */
  updatePostalAddressSelectionComp() {
    const addressSelectionComp: QbSelectDocumentationSendingAddressBusiness = this.$refs
      .addressSelectionComp as QbSelectDocumentationSendingAddressBusiness;
    addressSelectionComp.update();
  }

  /**
   * Get list for comunications
   * @param {GetIssueOfferDataResponseListaDocumentos[]} data
   * @returns {array}
   */
  getComunicationsData(data: GetIssueOfferDataResponseListaDocumentos[]) {
    return data.filter(item => (item.puedeEmail || item.indicadorEnvioSms) && !item.indicadorVisualizacion);
  }

  /**
   * Get list for documets to send 
   * @param {GetIssueOfferDataResponseListaDocumentos[]} data
   * @returns {array}
   */
  getDocumentsToSendData(data: GetIssueOfferDataResponseListaDocumentos[]) {
    return data.filter(item => item.puedeEmail && item.indicadorVisualizacion);
  }

  /**
   * Get list for the rest of documents 
   * @param {GetIssueOfferDataResponseListaDocumentos[]} data
   * @returns {array}
   */
  getRestOfDocuments(data: GetIssueOfferDataResponseListaDocumentos[]) {
    return data.filter(item => !item.puedeEmail && !item.indicadorEnvioSms);
  }

  /**
   * Check if the email must be required
   * @returns {boolean}
   */
  get isEmailRequired() {
    return this.documentationData?.some(
      item => item.indicadorEnvioMail && !item.indicadorEnvioSms && item.indicadorDocumentoMarcado
    );
  }

  /**
   * Check is the sms must be required
   * @returns {boolean}
   */
  get isPhoneRequired() {
    return this.documentationData?.some(
      item => !item.indicadorEnvioMail && item.indicadorEnvioSms && item.indicadorDocumentoMarcado
    );
  }

  /**
   * Validates inner components like email/phone selection
   */
  async validateInnerComponents() {
    const emailInputComp: MultipleEmailInputBusiness = this.$refs
      .emailInputComp as MultipleEmailInputBusiness;
    const phoneAddressSelectionComp: AddEditPhoneBusiness = this.$refs
      .addEditPhoneComp as AddEditPhoneBusiness;
    try {
      await emailInputComp.validation().validate();
      if (this.isPhoneRequired) {
        await phoneAddressSelectionComp.validation().validate();
        phoneAddressSelectionComp.update();
      }
    } catch (err) {
      throw new EAValidationError(this.$t('common.label.validation.formWithErrors').toString());
    }
    emailInputComp.update();
  }

  /**
   * Get list for the documents with preview
   * @param {GetIssueOfferDataResponseListaDocumentos[]} data
   * @returns {GetIssueOfferDataResponseListaDocumentos[]}
   */
  getPreviewDocuments(data: GetIssueOfferDataResponseListaDocumentos[]): GetIssueOfferDataResponseListaDocumentos[] {
    const documents = data.filter(item => item.indicadorVisualizacion);
    return documents.filter(
      item => !!item.nombreDocumento && !!this.selectInsuranceTypeModel.postalDigitalSelections &&
        (
          !this.selectInsuranceTypeModel.postalDigitalSelections[item.nombreDocumento] ||
          this.selectInsuranceTypeModel.postalDigitalSelections[item.nombreDocumento]
            === SeleccionPostalDigitalEnum.Digital
        )
    );
  }

  /**
   * Saves prices and emits change step event
   * @param {FlowViewsStepsModel} step
   * @param {FlowHeaderStepsModel} headerStep
   */
  onGoToNextStep(step: FlowViewsStepsModel, headerStep: FlowHeaderStepsModel) {
    this.$emit('changeStep', step, headerStep);
  }

  /**
   * Downloads selected document
   * @param {GetIssueOfferDataResponseListaDocumentos} document 
   */
  @EAMethod({
    loading: true
  })
  public async showDocumentPreview(document: GetIssueOfferDataResponseListaDocumentos) {
    if (document && document.codigoDocumento && document.codigoDocumentoTexto) {
      const previewDownloadFilesApi = new EAPreviewDownloadFilesApi();
      const output = await previewDownloadFilesApi.previewDownloadFilesOperation({
        previewDownloadFilesRequest: {
          documentos: [
            {
              codigoDocumento: document.codigoDocumento,
              nombreDocumento: document.nombreDocumento,
            }
          ]
        }
      });

      if (output) {
        const highSeverityErrors = output.errors?.filter(error => error.severity >= 4);
        throwIfResponseHasErrors({
          errors: highSeverityErrors
        });
        Utils.downloadFiles(output.documentos);
      }
    }
  }

  /**
   * Getter for showing documentation sending address component
   * True if any document has postal sending
   */
  get showDocumentationSendingAddress() {
    for (const key in this.selectInsuranceTypeModel.postalDigitalSelections) {
      if (this.selectInsuranceTypeModel.postalDigitalSelections[key] === SeleccionPostalDigitalEnum.Postal) {
        return true;
      }
    }

    return false;
  }

  /**
   * Handles savePhoneAddress event
   * @param {GetPersonAddressesResponseDomicilios} phoneAddress 
   */
  savePhoneAddress(phoneAddress: GetPersonAddressesResponseDomicilios) {
    this.addEditPhoneModel.phoneList.push(
      phoneAddress as GetPersonAddressesResponseTelefonos
    );
  }

  /**
   * Retrieves documentation client data model for person with 'tomador' role
   * @returns {DocumentationClientData}
   */
  public get tomadorDocumentationClientData(): DocumentationClientData {
    return PersonUtils.getDocumentationClientData(this.productFactory.personRoles, Roles.Tomador);
  }
}
</script>
