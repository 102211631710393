import {
  ParsedTableData
} from '@/utils/corporate-tables';
import QbEmployeeModalIntermediariesModel
  from '../qb-employee-modal-intermediaries/qb-employee-modal-intermediaries-model';
import QbPersonsModel from '../qb-persons/qb-persons-model';

// Declare interfaces
export interface IntermediaryList {
  intermediaryName?: string;
  intermediaryCode?: string;
}

export interface BussinessProductList {
  intermediaryName?: string;
  intermediaryCode?: string;
  businessName?: string;
  businessCode?: string;
  productName?: string;
  productComercialCode?: string;
  productTechnicalCode?: string;
}

export interface BusinessList {
  businessName?: string;
  businessCode?: string;
}

export interface ProductList {
  productName?: string;
  productComercialCode?: string;
  productTechnicalCode?: string;
  productCode?: string;
}

/** 
 * Q&B Model new offer
 */
class QbNewOfferBusinessModel {
  public intermediaryCode: string | undefined = '';

  public intermediaryName: string | undefined = '';

  public businessCode: string | undefined = '';

  public businessName: string | undefined = '';

  public productComercialCode: string | undefined = '';

  public productTechnicalCode: string | undefined = '';

  public productCode: string | undefined = '';

  public productName: string | undefined = '';

  public intermediariesEmployeeModal = new QbEmployeeModalIntermediariesModel();

  public documentTypeList: ParsedTableData[] = [];

  public countryList: ParsedTableData[] = [];

  public personsModel: QbPersonsModel = new QbPersonsModel();


}

export default QbNewOfferBusinessModel;
