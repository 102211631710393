/* eslint-disable */

/**
 * zonazurich
 * Portal de Mediación Digital
 *
 * The version of the OpenAPI document: 1
 * Contact: digitalarchitecture@zurich.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import {
  EASDKConfig,
  EAUrlUtils
} from '@zurich-es-npm/ea-shared-utils-js';

import * as globalImportUrl from 'url';
import {
  Configuration
} from './configuration';
import globalAxios, {
  AxiosPromise,
  AxiosInstance,
  AxiosResponse
} from 'axios';

const BASE_PATH = EAUrlUtils.removeLastForwardSlash('http://localhost:3000');

/**
 *
 * @export
 */
export const COLLECTION_FORMATS = {
  csv: ',',
  ssv: ' ',
  tsv: '\t',
  pipes: '|',
};

/**
 *  
 * @export
 * @interface RequestArgs
 */
export interface RequestArgs {
  url: string;
  options: any;
}

/**
 * 
 * @export
 * @class BaseAPI
 */
export class BaseAPI {
  protected configuration: Configuration | undefined;

  /**
   *Creates an instance of BaseAPI.
   * @param {Configuration} [configuration]
   * @param {string} [basePath=BASE_PATH]
   * @param {AxiosInstance} [axios=globalAxios]
   * @memberof BaseAPI
   */
  public constructor(
    configuration?: Configuration,
    protected basePath: string = BASE_PATH,
    protected axios: AxiosInstance = globalAxios) {
    if (configuration) {
      this.configuration = configuration;
      this.basePath = configuration.basePath || this.basePath;
    }
  }
}

/**
 * 
 * @export
 * @class RequiredError
 * @extends {Error}
 */
export class RequiredError extends Error {
  public name: 'RequiredError' = 'RequiredError';

  public field: string;

  /**
   *Creates an instance of RequiredError.
   * @param {string} field
   * @param {string} [msg]
   * @memberof RequiredError
   */
  public constructor(field: string, msg?: string) {
    super(msg);
    this.field=field;
  }
}

/**
 * 
 * @export
 * @class AsyncRequestError
 * @extends {Error}
 */
export class AsyncRequestError extends Error {
  public name: 'AsyncRequestError' = 'AsyncRequestError';

  /**
   *Creates an instance of AsyncRequestError.
   * @param {string} field
   * @param {string} [msg]
   * @memberof AsyncRequestError
   */
  public constructor(msg?: string) {
    super(msg);
  }
}

/**
 * 
 * @export
 * @interface Error
 */
export interface Error {
    /**
     * 
     * @type {string}
     * @memberof Error
     */
    code: string;
    /**
     * 
     * @type {string}
     * @memberof Error
     */
    message: string;
    /**
     * 
     * @type {number}
     * @memberof Error
     */
    severity: number;
    /**
     * 
     * @type {Array<object>}
     * @memberof Error
     */
    childErrors?: Array<object>;
}/**
 * 
 * @export
 * @interface GetGroupedVehicleListRequest
 */
export interface GetGroupedVehicleListRequest {
    /**
     * 
     * @type {string}
     * @memberof GetGroupedVehicleListRequest
     */
    codigoFlota: string;
    /**
     * 
     * @type {number}
     * @memberof GetGroupedVehicleListRequest
     */
    versionFlota: number;
    /**
     * 
     * @type {string}
     * @memberof GetGroupedVehicleListRequest
     */
    codigoOperacionFlota?: string;
    /**
     * 
     * @type {string}
     * @memberof GetGroupedVehicleListRequest
     */
    estadoPoliza?: GetGroupedVehicleListRequestEstadoPolizaEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum GetGroupedVehicleListRequestEstadoPolizaEnum {
    Anulada = 'Anulada',
    Pendiente = 'Pendiente',
    Suspendida = 'Suspendida',
    Vigor = 'Vigor',
    Modelo = 'Modelo',
    Formalizada = 'Formalizada',
    VigorOferta = 'VigorOferta',
    PendienteOferta = 'PendienteOferta',
    Caducada = 'Caducada'
}
/**
 * 
 * @export
 * @interface GetGroupedVehicleListResponse
 */
export interface GetGroupedVehicleListResponse {
    /**
     * 
     * @type {number}
     * @memberof GetGroupedVehicleListResponse
     */
    primaTotalFlota?: number;
    /**
     * 
     * @type {number}
     * @memberof GetGroupedVehicleListResponse
     */
    numeroVehiculosVigor?: number;
    /**
     * 
     * @type {Array<GetGroupedVehicleListResponseListaAgrupaciones>}
     * @memberof GetGroupedVehicleListResponse
     */
    listaAgrupaciones?: Array<GetGroupedVehicleListResponseListaAgrupaciones>;
    /**
     * 
     * @type {Array<Error>}
     * @memberof GetGroupedVehicleListResponse
     */
    errors?: Array<Error>;
}/**
 * 
 * @export
 * @interface GetGroupedVehicleListResponseListaAgrupaciones
 */
export interface GetGroupedVehicleListResponseListaAgrupaciones {
    /**
     * 
     * @type {string}
     * @memberof GetGroupedVehicleListResponseListaAgrupaciones
     */
    codigoAgrupacionFlota?: string;
    /**
     * 
     * @type {string}
     * @memberof GetGroupedVehicleListResponseListaAgrupaciones
     */
    descripcionAgrupacionFlota?: string;
    /**
     * 
     * @type {Array<GetGroupedVehicleListResponseListaVehiculos>}
     * @memberof GetGroupedVehicleListResponseListaAgrupaciones
     */
    listaVehiculos?: Array<GetGroupedVehicleListResponseListaVehiculos>;
}/**
 * 
 * @export
 * @interface GetGroupedVehicleListResponseListaVehiculos
 */
export interface GetGroupedVehicleListResponseListaVehiculos {
    /**
     * 
     * @type {string}
     * @memberof GetGroupedVehicleListResponseListaVehiculos
     */
    codigoFlota?: string;
    /**
     * 
     * @type {number}
     * @memberof GetGroupedVehicleListResponseListaVehiculos
     */
    versionFlota?: number;
    /**
     * 
     * @type {string}
     * @memberof GetGroupedVehicleListResponseListaVehiculos
     */
    ultimaVersionFlota?: string;
    /**
     * 
     * @type {string}
     * @memberof GetGroupedVehicleListResponseListaVehiculos
     */
    codigoPoliza?: string;
    /**
     * 
     * @type {number}
     * @memberof GetGroupedVehicleListResponseListaVehiculos
     */
    versionPoliza?: number;
    /**
     * 
     * @type {string}
     * @memberof GetGroupedVehicleListResponseListaVehiculos
     */
    codigoTecnicoProducto?: string;
    /**
     * 
     * @type {string}
     * @memberof GetGroupedVehicleListResponseListaVehiculos
     */
    codigoComercialProducto?: string;
    /**
     * 
     * @type {string}
     * @memberof GetGroupedVehicleListResponseListaVehiculos
     */
    codigoEstadoPoliza?: string;
    /**
     * 
     * @type {string}
     * @memberof GetGroupedVehicleListResponseListaVehiculos
     */
    estadoPolizaBatch?: string;
    /**
     * 
     * @type {string}
     * @memberof GetGroupedVehicleListResponseListaVehiculos
     */
    numeroMatriculaSinValidaciones?: string;
    /**
     * 
     * @type {string}
     * @memberof GetGroupedVehicleListResponseListaVehiculos
     */
    codigoAgrupacionFlota?: string;
    /**
     * 
     * @type {number}
     * @memberof GetGroupedVehicleListResponseListaVehiculos
     */
    valorVehiculo?: number;
    /**
     * 
     * @type {number}
     * @memberof GetGroupedVehicleListResponseListaVehiculos
     */
    valorPesoMaximo?: number;
    /**
     * 
     * @type {string}
     * @memberof GetGroupedVehicleListResponseListaVehiculos
     */
    codigoPaquete?: string;
    /**
     * 
     * @type {string}
     * @memberof GetGroupedVehicleListResponseListaVehiculos
     */
    indicadorGarantiasAdicionales?: string;
    /**
     * 
     * @type {number}
     * @memberof GetGroupedVehicleListResponseListaVehiculos
     */
    primaNeta?: number;
    /**
     * 
     * @type {number}
     * @memberof GetGroupedVehicleListResponseListaVehiculos
     */
    importeTotal?: number;
    /**
     * 
     * @type {string}
     * @memberof GetGroupedVehicleListResponseListaVehiculos
     */
    fechaEfectoMovimiento?: string;
    /**
     * 
     * @type {string}
     * @memberof GetGroupedVehicleListResponseListaVehiculos
     */
    descripcionErrorTarificacion?: string;
    /**
     * 
     * @type {string}
     * @memberof GetGroupedVehicleListResponseListaVehiculos
     */
    fechaVencimiento?: string;
    /**
     * 
     * @type {string}
     * @memberof GetGroupedVehicleListResponseListaVehiculos
     */
    tipoMatricula?: GetGroupedVehicleListResponseListaVehiculosTipoMatriculaEnum;
    /**
     * 
     * @type {string}
     * @memberof GetGroupedVehicleListResponseListaVehiculos
     */
    codigoVehiculo?: string;
    /**
     * 
     * @type {string}
     * @memberof GetGroupedVehicleListResponseListaVehiculos
     */
    codigoError?: string;
    /**
     * 
     * @type {string}
     * @memberof GetGroupedVehicleListResponseListaVehiculos
     */
    descripcionMarcaVehiculo?: string;
    /**
     * 
     * @type {string}
     * @memberof GetGroupedVehicleListResponseListaVehiculos
     */
    descripcionModeloVehiculo?: string;
    /**
     * 
     * @type {string}
     * @memberof GetGroupedVehicleListResponseListaVehiculos
     */
    descripcionVersionVehiculo?: string;
    /**
     * 
     * @type {number}
     * @memberof GetGroupedVehicleListResponseListaVehiculos
     */
    valorAccesoriosTotal?: number;
    /**
     * 
     * @type {string}
     * @memberof GetGroupedVehicleListResponseListaVehiculos
     */
    codigoUsoPorMatricula?: string;
    /**
     * 
     * @type {string}
     * @memberof GetGroupedVehicleListResponseListaVehiculos
     */
    codigoNumeroPoliza?: string;
    /**
     * 
     * @type {string}
     * @memberof GetGroupedVehicleListResponseListaVehiculos
     */
    codigoIdentificacionConductor?: string;
    /**
     * 
     * @type {string}
     * @memberof GetGroupedVehicleListResponseListaVehiculos
     */
    nombreConductor?: string;
    /**
     * 
     * @type {string}
     * @memberof GetGroupedVehicleListResponseListaVehiculos
     */
    tipoDocumentoConductor?: string;
    /**
     * 
     * @type {string}
     * @memberof GetGroupedVehicleListResponseListaVehiculos
     */
    primerApellidoConductor?: string;
    /**
     * 
     * @type {string}
     * @memberof GetGroupedVehicleListResponseListaVehiculos
     */
    segundoApellidoConductor?: string;
    /**
     * 
     * @type {string}
     * @memberof GetGroupedVehicleListResponseListaVehiculos
     */
    codigoFiliacionConductor?: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetGroupedVehicleListResponseListaVehiculos
     */
    indicadorConductorNominado?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetGroupedVehicleListResponseListaVehiculos
     */
    tipoDocumentoAseguradoBeneficiario?: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetGroupedVehicleListResponseListaVehiculos
     */
    indicadorAsegurado?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetGroupedVehicleListResponseListaVehiculos
     */
    codigoIdentificacionAsegurado?: string;
    /**
     * 
     * @type {string}
     * @memberof GetGroupedVehicleListResponseListaVehiculos
     */
    nombreAsegurado?: string;
    /**
     * 
     * @type {string}
     * @memberof GetGroupedVehicleListResponseListaVehiculos
     */
    tipoPersonaAsegurada?: string;
    /**
     * 
     * @type {string}
     * @memberof GetGroupedVehicleListResponseListaVehiculos
     */
    primerApellidoAsegurado?: string;
    /**
     * 
     * @type {string}
     * @memberof GetGroupedVehicleListResponseListaVehiculos
     */
    segundoApellidoAsegurado?: string;
    /**
     * 
     * @type {string}
     * @memberof GetGroupedVehicleListResponseListaVehiculos
     */
    codigoFiliacionAsegurado?: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetGroupedVehicleListResponseListaVehiculos
     */
    indicadorPropietario?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetGroupedVehicleListResponseListaVehiculos
     */
    tipoDocumentoPropietario?: string;
    /**
     * 
     * @type {string}
     * @memberof GetGroupedVehicleListResponseListaVehiculos
     */
    tipoPersonaPropietario?: string;
    /**
     * 
     * @type {string}
     * @memberof GetGroupedVehicleListResponseListaVehiculos
     */
    codigoIdentificacionPropietario?: string;
    /**
     * 
     * @type {string}
     * @memberof GetGroupedVehicleListResponseListaVehiculos
     */
    nombrePropietario?: string;
    /**
     * 
     * @type {string}
     * @memberof GetGroupedVehicleListResponseListaVehiculos
     */
    primerApellidoPropietario?: string;
    /**
     * 
     * @type {string}
     * @memberof GetGroupedVehicleListResponseListaVehiculos
     */
    segundoApellidoPropietario?: string;
    /**
     * 
     * @type {string}
     * @memberof GetGroupedVehicleListResponseListaVehiculos
     */
    codigoFiliacionPropietario?: string;
    /**
     * 
     * @type {string}
     * @memberof GetGroupedVehicleListResponseListaVehiculos
     */
    tipoVehiculo?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum GetGroupedVehicleListResponseListaVehiculosTipoMatriculaEnum {
    M1 = 'M1',
    E1 = 'E1',
    TE = 'TE',
    I = 'I',
    C = 'C',
    _1 = '1',
    P = 'P',
    R = 'R',
    D = 'D',
    K = 'K',
    T1 = 'T1',
    T = 'T',
    E = 'E',
    TT = 'TT',
    R1 = 'R1',
    H = 'H',
    M = 'M',
    NOMATRICULA = 'NO-MATRICULA'
}


type getGroupedVehicleListOperationParams = {
  getGroupedVehicleListRequest: GetGroupedVehicleListRequest,
};

/**
 * GetGroupedVehicleListApi - axios parameter creator
 * @param {Configuration} [configuration]
 * @returns {any}
 */
const GetGroupedVehicleListApiAxiosParamCreator = function(configuration?: Configuration): any {
  return {
    
    /**
     * Añadir vihiculo a la oferta o poliza de flotas
     * @param {GetGroupedVehicleListRequest} getGroupedVehicleListRequest 
     * @param {string} [acceptLanguage] El Accept-Language request HTTP header indica en que lenguaje debe responder el backend.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @returns {RequestArgs}
     */
    getGroupedVehicleListOperation(params: getGroupedVehicleListOperationParams, options: any = {}): RequestArgs {
      // Verify required parameter 'getGroupedVehicleListRequest' is not null or undefined
      if (params.getGroupedVehicleListRequest === null || params.getGroupedVehicleListRequest === undefined) {
        throw new RequiredError('getGroupedVehicleListRequest', 'Required parameter getGroupedVehicleListRequest was null or undefined when calling getGroupedVehicleListOperation.');
      }
      const localVarPath = `/v1/fleets/getGroupedVehicleListOperation`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true) as Partial<globalImportUrl.UrlWithParsedQuery>;
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;


  
      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
      // Fix override query string Detail: https://stackoverflow.com/a/7517673/1077943

      delete localVarUrlObj.search;
      localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
      const needsSerialization = ('GetGroupedVehicleListRequest' as any !== 'string') ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization ? JSON.stringify(params.getGroupedVehicleListRequest || {}) : params.getGroupedVehicleListRequest || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * GetGroupedVehicleListApi - functional programming interface
 * @param {Configuration} [configuration]
 * @returns {any}
 */
const GetGroupedVehicleListApiFp = function(configuration?: Configuration): any {
  return {
    
    /**
     * Añadir vihiculo a la oferta o poliza de flotas
     * @param {GetGroupedVehicleListRequest} getGroupedVehicleListRequest 
     * @param {string} [acceptLanguage] El Accept-Language request HTTP header indica en que lenguaje debe responder el backend.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @returns {any}
     */
    getGroupedVehicleListOperation(params: getGroupedVehicleListOperationParams, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetGroupedVehicleListResponse> {
      const localVarAxiosArgs = GetGroupedVehicleListApiAxiosParamCreator(configuration).getGroupedVehicleListOperation(params, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH): any => {
        const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * GetGroupedVehicleListApi - factory interface
 * @param {Configuration} configuration
 * @param {string} basePath
 * @param {AxiosInstance} axios
 * @export
 * @returns {any}
 */
const GetGroupedVehicleListApiFactory = function(configuration?: Configuration, basePath?: string, axios?: AxiosInstance): any {
  return {
    
    /**
     * Añadir vihiculo a la oferta o poliza de flotas
     * @param {GetGroupedVehicleListRequest} getGroupedVehicleListRequest 
     * @param {string} [acceptLanguage] El Accept-Language request HTTP header indica en que lenguaje debe responder el backend.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @returns {any}
     */
    getGroupedVehicleListOperation(params: getGroupedVehicleListOperationParams, options?: any): any {
      return GetGroupedVehicleListApiFp(configuration).getGroupedVehicleListOperation(params, options)(axios, basePath);
    },
  };
};

/** 
 * GetGroupedVehicleListApi - object-oriented interface
 * @export
 * @class GetGroupedVehicleListApi
 * @extends {BaseAPI}
 */
export class GetGroupedVehicleListApi extends BaseAPI {
  
  /**
   * Añadir vihiculo a la oferta o poliza de flotas
   * @param {GetGroupedVehicleListRequest} getGroupedVehicleListRequest 
   * @param {string} [acceptLanguage] El Accept-Language request HTTP header indica en que lenguaje debe responder el backend.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @returns {any}
   * @memberof GetGroupedVehicleListApi
   */
  public getGroupedVehicleListOperation(params: getGroupedVehicleListOperationParams, options?: any): any {
    return GetGroupedVehicleListApiFp(this.configuration).getGroupedVehicleListOperation(params, options)(this.axios, this.basePath);
  }

}

/**
 * 
 * @export
 * @class EAgetGroupedVehicleListOperationInputMessage
 */
export class EAgetGroupedVehicleListOperationInputMessage {
  
  /**
   * 
   * @type {GetGroupedVehicleListRequest}
   * @memberof EAgetGroupedVehicleListOperationInputMessage
   */
  public getGroupedVehicleListRequest: GetGroupedVehicleListRequest;

  public constructor(getGroupedVehicleListRequest: GetGroupedVehicleListRequest, ){
  
  this.getGroupedVehicleListRequest=getGroupedVehicleListRequest;
  } 
}



/** 
 * EAGetGroupedVehicleListApi - Architecture client for getGroupedVehicleListOperation
 * @export
 * @class EAGetGroupedVehicleListApi
 */
export class EAGetGroupedVehicleListApi {

  /**
   * 
   * @param {GetGroupedVehicleListRequest} getGroupedVehicleListRequest
   
   * @param {Configuration} configuration
   * @throws {AsyncRequestError | RequiredError}
   * @returns {Promise<GetGroupedVehicleListResponse | null>}
  */ 
  public async getGroupedVehicleListOperation(params: getGroupedVehicleListOperationParams, configuration: Configuration = {}): Promise<GetGroupedVehicleListResponse | null> {
    return this.getGroupedVehicleListOperationPromise(params, configuration);
  }

  /**
   * 
   * @param {GetGroupedVehicleListRequest} getGroupedVehicleListRequest
   
   * @param {Configuration} configuration
   * @param {Function} callback
   * @throws {AsyncRequestError | RequiredError}
   */
  public async getGroupedVehicleListOperationSteps(params: getGroupedVehicleListOperationParams, configuration: Configuration, callback: (res: AxiosResponse<GetGroupedVehicleListResponse | null>) => void) {
    let done: boolean = false;
    const httpClient: AxiosInstance = EASDKConfig.getHttpClient('/v1/fleets/getGroupedVehicleListOperation', 'getGroupedVehicleList', 'getGroupedVehicleListOperation');
    const api = new GetGroupedVehicleListApi(configuration, '', httpClient);
    const sleep = async(ms: number) => {
      return new Promise(resolve => setTimeout(resolve, ms));
    };
    const headers: { ['Async-Id']?: string } = {};
    while (!done) {
      const output = await api.getGroupedVehicleListOperation(params, { headers }) as AxiosResponse<GetGroupedVehicleListResponse | null>;
      callback(output);
      if (output.status === 202) {
        try {
          const retry = parseInt(output.headers['retry']);
          headers['Async-Id'] = output.headers['request-id'];
          if (isNaN(retry) || retry <= 0 || retry > 1000 * 3600) {
            throw new Error(`Retry time not acceptable (in milliseconds): ${retry}`);
          }
          await sleep(retry);
        } catch (err) {
          throw new Error(`Error in Async Request ${err}`);
        }
      } else {
        done = true;
      }
    }
  }

  /**
   * 
   * @param {GetGroupedVehicleListRequest} getGroupedVehicleListRequest
   
   * @param {Configuration} configuration
   * @throws {AsyncRequestError | RequiredError}
   * @returns {Promise<GetGroupedVehicleListResponse | null>}
   */
  public async getGroupedVehicleListOperationPromise(params: getGroupedVehicleListOperationParams, configuration: Configuration): Promise<GetGroupedVehicleListResponse | null> {
    return new Promise((resolve, reject) => {
      this.getGroupedVehicleListOperationSteps(params, configuration, (response: AxiosResponse<GetGroupedVehicleListResponse | null>) => {
        if (response.status !== 202) {
          resolve(response.data);
        }
      })
      .catch((err) => {
        reject(err);
      });
    });
  } 
}


