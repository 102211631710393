import {
  GetPersonAddressesResponseDomicilios
} from '@/services/V1/persons/getPersonAddressesOperation/post';
import AddAddressTomadorModel from '../../modals/add-address-tomador/add-address-tomador-model';

/**
 * Model add-edit-address
 */
class AddEditAddressModel {
  public selectedAddress?: GetPersonAddressesResponseDomicilios = undefined; // Initialised to make it reactive

  public selectedAddressCode?: string = undefined; // Initialised to make it reactive

  public addressList: GetPersonAddressesResponseDomicilios[] = [];

  public addAddressTomadorModel: AddAddressTomadorModel = new AddAddressTomadorModel();
}

export default AddEditAddressModel;
