<template>
  <div>
    <qb-download-documentation
      id="downloadDocumentation"
      v-model="downloadDocumentationModel"
      :policyNumber="model.policyNumber"
      :policyVersion="model.policyVersion"
      :policyType="model.policyType"
      :reloadDocumentationFlag="reloadDocumentationFlag"
      :isFleet="model.isFleet"
    ></qb-download-documentation>

    <attach-documentation
      id="attach-documentation"
      v-if="model.allowAttachments"
      v-model="attachDocumentationModel"
      :policyNumber="model.policyNumber"
      :policyVersion="model.policyVersion"
      @reloadDocumentation="onReloadDocumentation"
    ></attach-documentation>
    
    <ea-button type="secondary" @click="onGoBack()">
      {{ $t('common.label.back') }}
    </ea-button>
  </div>
</template>

<script lang="ts">
import {
  Component
} from 'vue-property-decorator';

import {
  mixins
} from 'vue-class-component';

import {
  EAView,
  EAMethod
} from '@zurich-es-npm/ea-front-web-core';

import {
  DocumentationModel
} from '../documentation-model';
import QbDownloadDocumentationBusiness
  from '@/business-components/qb-download-documentation/qb-download-documentation-business.vue';
import AttachDocumentationBusiness from '@/business-components/attach-documentation/attach-documentation-business.vue';
import AttachDocumentationModel from '@/business-components/attach-documentation/attach-documentation-model';
import QbDownloadDocumentationModel
  from '@/business-components/qb-download-documentation/qb-download-documentation-model';

@Component({
  components: {
    QbDownloadDocumentation: QbDownloadDocumentationBusiness,
    AttachDocumentation: AttachDocumentationBusiness
  }
})

/**
 * Documentation view
 *
 */
export default class Documentation extends mixins<EAView<DocumentationModel>>(EAView) {

  public downloadDocumentationModel: QbDownloadDocumentationModel = new QbDownloadDocumentationModel();

  public attachDocumentationModel: AttachDocumentationModel = new AttachDocumentationModel();

  public reloadDocumentationFlag = 0;

  /**
   * Go back
   */
  @EAMethod()
  onGoBack() {
    this.$emit('back');
  }

  /**
   * Handles reloadDocumentation event
   * Increases flag account so child component calls corresponding bff
   */
  onReloadDocumentation() {
    this.reloadDocumentationFlag++;
  }
}
</script>
