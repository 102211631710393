
<template>
  <div class="m-b-16">
    <notes-description-modal id="description-modal" v-model="model.descriptionModal"
        @closeModal="updateModal" @relaunchGetNotes="relaunchGetNotes"></notes-description-modal>

    <ea-card>
      <div slot="cardbody" class="p-a-16">
        <div v-if="!employeeUser" class="d-display-flex d-justify-flex-end m-b-16 d-align-items-center">
          <ea-button type="primary" @click.native.prevent="onCreateNote()">
            {{$t('notesFlow.mainNotesView.createNote')}}
          </ea-button>
        </div>
        
        <div v-if="data" class="m-b-24">
          <ea-table :data="data">
            <ea-table-column :label="$t('notesFlow.mainNotesView.tableLabelEstado')" show="codigoEstadoNota"
              headerClass="no-sortBy">
              <template slot-scope="row">
                <span v-if="row.codigoEstadoNota === 'Pendiente'">
                  <ea-text
                    type="icon"
                    icon="z-mail-closed"
                    icon-position="left"
                  >
                    {{$t('notesFlow.mainNotesView.unread')}}
                  </ea-text>
                </span>
                <span v-else-if="row.codigoEstadoNota === 'Leida'">
                  <ea-text
                    type="icon"
                    icon="z-mail-open"
                    icon-position="left"
                  >
                    {{$t('notesFlow.mainNotesView.read')}}
                  </ea-text>
                </span>
              </template>
            </ea-table-column>
            <ea-table-column
              show="fechaCreacionNota"
              :formatter="formatter"
              :label="$t('notesFlow.mainNotesView.tableLabelFecha')"
              headerClass="no-sortBy"
            />
            <ea-table-column show="tituloNota" :label="$t('notesFlow.mainNotesView.tableLabelTitulo')"
              headerClass="no-sortBy"/>
            <ea-table-column show="descripcionRemitenteNota"
              headerClass="no-sortBy"
              :label="$t('notesFlow.mainNotesView.tableLabelRemitente')"
              data-type="string" />
            <ea-table-column show="descripcionDestinatarioNota"
              headerClass="no-sortBy"
              :label="$t('notesFlow.mainNotesView.tableLabelDestinatario')" />
            <ea-table-column
              headerClass="no-sortBy"
              cellClass="t-align-center">
              <template slot-scope="row">
                <ea-button-icon
                  size="medium"
                  icon="el-icon-view"
                  @click="openDescriptionModal(row.detalleNota,
                    row.tituloNota,
                    row.puedeMarcarLeida,
                    row.codigoTareaAviso)
                ">
                </ea-button-icon>
              </template>
            </ea-table-column>

            <template slot="noResults">
              <div class="loading-wrapper">
                <div class="items-status">
                  <p class="loading-message">{{ $t('regularizationFlow.regularizationList.table.noData') }}</p>
                </div>
              </div>
            </template>
          </ea-table>
        </div>
    
      </div>
    </ea-card>
    <ea-button class="m-t-16" type="secondary" @click="goToParentFlow">
      {{$t('notesFlow.mainNotesView.goBack')}}
    </ea-button>

    <create-note-modal
      id="create-note-modal"
      v-model="createNoteModalModel"
      :policyNumber="model.policyNumber"
      :policyVersion="model.policyVersion"
      :policyType="model.policyType"
      @noteSuccessfullySaved="onNoteSuccessfullySaved"
      @handleGenericError="handleGenericError"
      @showError="showError"
    ></create-note-modal>
  </div>
</template>

<script lang="ts">

import {
  EAGetNotesListApi,
  GetNotesListRequest,
  GetNotesListRequestCodigoOperacionEnum,
  GetNotesListRequestTipoPolizaEnum,
  GetNotesListResponseData
} from '@/services/V1/notes/getNotesListOperation/post';
import {
  Component
} from 'vue-property-decorator';

import {
  mixins
} from 'vue-class-component';

import {
  EAView, EAMethod, ResponseWithErrors, throwIfResponseHasErrors
} from '@zurich-es-npm/ea-front-web-core';

import {
  NotesModel
} from '../notes-model';

import NotesDescriptionModalBusiness from
  '@/business-components/notes-description-modal/notes-description-modal-business.vue';
import Utils from '@/utils/utils';
import CreateNoteModalBusiness from '@/business-components/create-note-modal/create-note-modal-business.vue';
import CreateNoteModalModel from '@/business-components/create-note-modal/create-note-modal-model';
import {
  NotificationsUtils
} from '@/utils/notifications/notifications-utils';
import {
  GenericErrorData
} from '@/business-components/qb-generic-error/qb-generic-error-business.vue';
import PermissionUtils from '@/utils/permissions-utils';
import {
  GetDefaultUserValuesResponseCodigoTipoUsuarioEnum as UserTypesEnum
} from '@/services/V1/home/getDefaultUserValuesOperation/post';

@Component({
  components: {
    'notes-description-modal': NotesDescriptionModalBusiness,
    CreateNoteModal: CreateNoteModalBusiness,
  }
})

/**
 * Notes view
 *
 */
export default class NotesView extends mixins<EAView<NotesModel>>(EAView) {

  public data: GetNotesListResponseData[] = [];

  public employeeUser: boolean = false;

  public dataTable = {
    byOrder: 'poliza',
    order: 'asc',
    headerm: 'cliente',
    tableMode: {
      mode: 'collapse',
      titleFieldName: 'title',
    }
  };

  public createNoteModalModel: CreateNoteModalModel = new CreateNoteModalModel();

  /**
   * On component created
   */
  created() {
    this.getNotesList();

    const userType = PermissionUtils.getUserType();
    this.employeeUser = PermissionUtils.isUserOfType(userType, UserTypesEnum.EMP);
  }

  /**
   * Go to parent flolow
   */
  goToParentFlow() {
    this.sendEventWithModel('goToParentFlow');
  }

  /**
   * Formatea la fecha
   * @param {string} value fecha sin formatear
   * @returns {String} 
   */
  formatter(value: string): string {
    return value ? Utils.convertDateToString(value) : '';
  }


  /**
   * @param {string} description
   * @param {string} noteTittle
   * @param {boolean} canMark
   * @param {string} codigoTareaAviso
   * Open modal
   */
  openDescriptionModal(description: string = '',
    noteTittle: string = '',
    canMark = false,
    codigoTareaAviso: string ='') {
    this.model.descriptionModal.showModalNotes = true;
    this.model.descriptionModal.description = description;
    this.model.descriptionModal.noteTitle = noteTittle;
    this.model.descriptionModal.canMark = canMark;
    this.model.descriptionModal.codigoTareaAviso = codigoTareaAviso;
    this.update();
  }


  /**
   * Update modal
   */
  updateModal() {
    this.model.descriptionModal.showModalNotes = false;
    this.model.descriptionModal.description = '';
    this.model.descriptionModal.noteTitle = '';
    this.model.descriptionModal.canMark = false;
    this.model.descriptionModal.codigoTareaAviso = '';
    this.update();
  }


  /**
   * @param {Date} fromDate
   * Get notes list
   */
  @EAMethod({
    loading:true
  })
  async getNotesList(fromDate?: Date) {
    const api = new EAGetNotesListApi();
    const body: GetNotesListRequest = {
      codigoPoliza: this.model.policyNumber,
      tipoPoliza: this.model.policyType as unknown as GetNotesListRequestTipoPolizaEnum,
      versionPoliza: this.model.policyVersion,
      codigoOperacion: GetNotesListRequestCodigoOperacionEnum.LO,
      numeroElementosPagina: 20,
      numeroPagina: 0
    };
    if (fromDate) {
      const formatedDate = fromDate.toISOString().split('T')[0];
      body.fechaDesde = formatedDate;
    }
    const response = await api.getNotesListOperation({
      getNotesListRequest: body
    });
    throwIfResponseHasErrors(response as ResponseWithErrors);
    this.data = response?.data || [];
  }

  /**
   * Handles "New note" button. Opens create note modal
   */
  onCreateNote() {
    this.createNoteModalModel.hidden = false;
  }

  /**
   * Handles noteSuccesfullySaved event
   * Shows notification + reloads note list
   */
  onNoteSuccessfullySaved() {
    NotificationsUtils.throwSuccess(`${this.$t('notesFlow.notifications.successfullyCreated')}`);
    this.getNotesList();
  }

  /**
   * ReLaunch getNotes
   */
  relaunchGetNotes() {
    this.getNotesList();
  }

  /**
   * Emits showError event
   * @param {GenericErrorData} genericErrorData
   */
  showError(genericErrorData: GenericErrorData) {
    this.$emit('showError', genericErrorData);
  }

  /**
   * Emits handleGenericError event
   * @param {any} args
   */
  handleGenericError(args: any) {
    const {
      error, errorCode
    } = args;
    this.$emit('handleGenericError', {
      error,
      errorCode,
    });
  }
}

</script>
