import {
  GetIntermediaryBusinessProductListRequestCodigoRamoEnum
} from '@/services/V1/quoteAndBuy/getIntermediaryBusinessProductListOperation/post';
import {
  EAFlowModel, EAView
} from '@zurich-es-npm/ea-front-web-core';

import QBNewOfferBusinessModel from '@/business-components/qb-new-offer/qb-new-offer-model';
import
FleetSearchPolicyHolderModel
  from '@/business-components/fleets-search-policyholder/fleet-search-policyholder-model';
import FleetGeneralInfoModel from '@/business-components/fleet-general-info/fleet-general-info-model';
import FleetsRiskManagementModel from '@/business-components/fleets-risk-management/fleets-risk-management-model';

import FleetUnifiedExpirationModel from '@/business-components/fleet-unified-expiration/fleet-unified-expiration-model';
import FleetInputVehiclesDataModel
  from '@/business-components/fleet-input-vehicles-data/fleet-input-vehicles-data-model';
import FleetQuotePaymentFormModel from '@/business-components/fleet-quote-payment-form/fleet-quote-payment-form-model';
import {
  NewFleetFlowConfig
} from './model/new-fleet-flow-config-model';
import FleetVehicleGroupsModel from '@/business-components/fleet-vehicle-groups/fleet-vehicle-groups-model';
import {
  PolicyType
} from '@/types/policy-types/policy-types-enum.types';
import {
  ParsedTableData
} from '@/utils/corporate-tables';
import QbPersonsModel from '@/business-components/qb-persons/qb-persons-model';
import QbDocumentationSelectDocumentationModel
  from '@/business-components/qb-documentation-select-documentation/qb-documentation-select-documentation-model';
import {
  Roles
} from '@/types/roles/roles-enum.types';
import QbSearchPersonModel from '@/business-components/qb-search-person/qb-search-person-model';
import QbAddressesPersonModel from '@/business-components/addresses/qb-addresses-person-model';
import QbIbanCodeModel from '@/business-components/qb-iban-code/qb-iban-code-model';
import MultipleEmailInputModel from '@/business-components/multiple-email-input/multiple-email-input-model';
import FleetIssuanceDataModel from '@/business-components/fleet-issuance-data/fleet-issuance-data-model';
import FleetPaymentDataModel from '@/business-components/fleet-payment-data/fleet-payment-data-model';
import QbGenericErrorModel from '@/business-components/qb-generic-error/qb-generic-error-model';
import FleetCompetencesManagementModalModel
  from '@/business-components/fleet-competences-management-modal/fleet-competences-management-modal-model';
import QbBillingDataModel from '../../business-components/qb-billing-data/qb-billing-data-model';
import QbShowPersonInfoModel from '@/business-components/qb-show-person-info/qb-show-person-info-business-model';

export interface StepRelationInterface {
  step: FlowViewsStepsModel | FlowConsultViewsStepsModel;
  title?: string;
  component: typeof EAView;
}

export enum FlowViewsStepsModel {
  NewOfferStep = 0,
  FleetDataStep = 1,
  VehiclesDataStep = 2,
  QuoteDataStep = 3,
  InterveningDataStep = 4,
  GenerateDocumentationStep = 5,
  OfferIssuanceDataStep = 6
}

export enum FlowConsultViewsStepsModel {
  FleetDataStep = 1,
  VehiclesDataStep = 2,
  QuoteDataStep = 3
}

export enum FlowHeaderStepsModel {
  FleetDataStep = 0,
  VehiclesDataStep = 1,
  QuoteDataStep = 2,
  InterveningDataStep = 3,
  OfferIssuanceDataStep = 4
}


export enum OperationTypeEnum {
  MANTENIMIENTO = 'MANTENIMIENTO',
  NUEVA = 'NUEVA',
  CONSULTA = 'CONSULTA'
}

export enum InterveningStep {
  GOBACK = 'GOBACK',
  GONEXT = 'GONEXT'
}

export interface InputModel {
  codigoPoliza: string;
  versionPoliza: number;
  tipoPoliza: PolicyType;
  maintenanceMode: boolean;
  consultMode: boolean;
  hasParentFlow: boolean;
  operationType: OperationTypeEnum;
}

export enum FleetDocumentCodes {
  IPID = 'ipid',
  BUDGET = 'pptoflota'
}

export enum FleetPolicyMovementType {
  Oferta = '9',
  Anulada = '3',
  AnuladaAlVencimiento = 'A',
  ProduccionNueva = '1',
  Suplemento = '2',
  Cartera = '4'
}

export enum FleetGDCError {
  ErrorGDC = '8213'
}

/**
 * Flow model
 *
 * @export
 * @class FleetsModel
 * @extends {EAFlowModel}
 */
export class FleetsModel extends EAFlowModel {
  public offerNumber: string = '';
  
  public offerVersion: number = 0;

  public operationType: string = '';

  // BranchCode is 'codigoRamo'
  public branchCode = GetIntermediaryBusinessProductListRequestCodigoRamoEnum.THFL;

  public genericErrorModel: QbGenericErrorModel = new QbGenericErrorModel();

  public qbNewOfferBusinessModel: QBNewOfferBusinessModel = new QBNewOfferBusinessModel();

  public fleetSearchPolicyHolderModel: FleetSearchPolicyHolderModel = new FleetSearchPolicyHolderModel();

  public fleetGeneralInfoModel: FleetGeneralInfoModel = new FleetGeneralInfoModel();

  public fleetsRiskManagementModel: FleetsRiskManagementModel = new FleetsRiskManagementModel();

  public flowConfiguration: NewFleetFlowConfig = new NewFleetFlowConfig();

  public fleetUnifiedExpirationModel: FleetUnifiedExpirationModel = new FleetUnifiedExpirationModel();

  public fleetInputVehiclesDataModel = new FleetInputVehiclesDataModel();

  public fleetQuotePaymentFormModel = new FleetQuotePaymentFormModel();

  public fleetVehicleGroupsModel = new FleetVehicleGroupsModel();

  public fleetIssuanceDataModel = new FleetIssuanceDataModel();

  public fleetPaymentDataModel = new FleetPaymentDataModel();

  public documentationSelectDocumentationModel: QbDocumentationSelectDocumentationModel = {
    multipleEmailInputModel: new MultipleEmailInputModel(),
    documentList: [{
      nombreDocumento: 'IPID',
      codigoDocumento: FleetDocumentCodes.IPID,
      codigoDocumentoTexto: FleetDocumentCodes.IPID,
      indicadorDocumentoBloqueado: false,
      indicadorDocumentoMarcado: true,
      indicadorEnvioMail: true,
      indicadorEnvioSms: true,
      indicadorVisualizacion: true,
      indicadorCentralizacion: true,
      indicadorEnvioPostal: true,
      puedeEmail: false,
      puedeDigital: true
    }, {
      nombreDocumento: 'Presupuesto flota',
      codigoDocumento: FleetDocumentCodes.BUDGET,
      codigoDocumentoTexto: FleetDocumentCodes.BUDGET,
      indicadorDocumentoBloqueado: false,
      indicadorDocumentoMarcado: true,
      indicadorEnvioMail: true,
      indicadorEnvioSms: true,
      indicadorVisualizacion: true,
      indicadorCentralizacion: true,
      indicadorEnvioPostal: true,
      puedeEmail: false,
      puedeDigital: true
    }]
  };

  public documentTypeList: ParsedTableData[] = [];

  public countryList: ParsedTableData[] = [];

  public policyEmitedStatusList: ParsedTableData[] = [];

  public batchOnlineStatusList: ParsedTableData[] = [];
  
  public personsModel: QbPersonsModel = {
    personRoles : [
      // ### TOMADOR ROLE ###
      {
        disabled: false,
        role: Roles.Tomador,
        searchModel: new QbSearchPersonModel(),
        addressesModel: new QbAddressesPersonModel(),
        ibanList: [],
        ibanCodeModel: new QbIbanCodeModel(),
        billing: new QbBillingDataModel(),
        taxTreatmentArea: '',
        canBeChanged: true,
        showPersonInfoModel: new QbShowPersonInfoModel()
      }, {
        role: Roles.Asegurado,
        disabled: false,
        ibanCodeModel: new QbIbanCodeModel(),
        billing: new QbBillingDataModel(),
        ibanList: [],
        addressesModel: new QbAddressesPersonModel(),
        searchModel: {
          ...new QbSearchPersonModel(),
          selectedPerson: null
        },
        roleEqualStatements: [{
          hidden: false,
          roleCopyFrom: Roles.Tomador,
          roleCopyTo: Roles.Asegurado,
          value: true
        }],
        canBeChanged: true,
        showPersonInfoModel: new QbShowPersonInfoModel()
      },
      {
        role: Roles.Propietario,
        disabled: false,
        ibanCodeModel: new QbIbanCodeModel(),
        billing: new QbBillingDataModel(),
        ibanList: [],
        addressesModel: new QbAddressesPersonModel(),
        searchModel: {
          ...new QbSearchPersonModel(),
          selectedPerson: null
        },
        roleEqualStatements: [{
          hidden: false,
          roleCopyFrom: Roles.Tomador,
          roleCopyTo: Roles.Propietario,
          value: true
        }],
        canBeChanged: true,
        showPersonInfoModel: new QbShowPersonInfoModel()
      },
      {
        role: Roles.Conductor,
        disabled: false,
        ibanCodeModel: new QbIbanCodeModel(),
        billing: new QbBillingDataModel(),
        ibanList: [],
        addressesModel: new QbAddressesPersonModel(),
        searchModel: {
          ...new QbSearchPersonModel(),
          selectedPerson: null
        },
        roleEqualStatements: [{
          hidden: false,
          roleCopyFrom: Roles.Tomador,
          roleCopyTo: Roles.Conductor,
          value: true
        }],
        canBeChanged: true,
        showPersonInfoModel: new QbShowPersonInfoModel()
      }
    ]
  };

  public fleetCompetencesManagementModalModel = new FleetCompetencesManagementModalModel();

}


