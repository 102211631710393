import {
  FleetGeneralInfoRestrictions
} from './fleet-general-info-restrictions-model';
import {
  FleetSearchPolicyholderRules
} from './fleet-search-policy-holder-rules-model';
import {
  LabelValueType
} from './label-value-type-model';

/**
 * 
 */
export class GeneralDataView {
  public fleetSearchPolicyholderRules: FleetSearchPolicyholderRules = new FleetSearchPolicyholderRules();

  public showPolicyholderInfoDocumentTypes: LabelValueType[] = [];

  public fleetCommitContractPolicyTypes: LabelValueType[]= [];

  public fleetGeneralInfoRestrictions: FleetGeneralInfoRestrictions = new FleetGeneralInfoRestrictions();

  public fleetSearchPolicyholderJuridicalPersonDocumentType: string []= [];

  public fleetSearchPolicyholderSecondCatVehiclesList: string []= [];
}
