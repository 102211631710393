<template>
  <div>
    <ea-flow-container>

      <!-- Notifications container -->
      <div>
        <notification-handler/>
      </div>

      <!-- Flow content: Header + Views -->
      <ea-row v-if="model.flowConfiguration.branchCode">
        <ea-col>
          <qb-header
            id="fleets-qb-header"
            :key="qbHeaderKey"
            v-model="undefined"
            :title="$t('fleets.fleetsFlow.titleDefault')"
            :subtitle="subTitle"
            :showSteps="showSteps"
            :steps="steps"
            :activeStep="currentHeaderStep"
            :offerNumber="model.offerNumber"
            :offerVersion="model.offerVersion"
            :parentFlowId="flowId"
            :showCreateNoteButton="false"
            :showQueryNoteButton="false"
            :showDocumentationButton="showDocumentationBtn"
            @openSubflow="onOpenSubflow"
            />
        </ea-col>
        <ea-col>
          <component
            v-if="stepComponent"
            :is="stepComponent"
            v-model="model"
            :currentHeaderStep="currentHeaderStep"
            @changeStep="changeToStep"
            @showError="showGenericError"
            @handleGenericError="handleGenericError"
            @closeFlow="closeFlow"
            @goToPreviousFlow="goToPreviousFlow"
          />
          <!-- TODO: temporal template -->
          <template v-else>
            <div>
              <pre>{{
                `¡Vista asociada al navigation.step = '${navigation.step}' no implementada!`
              }}</pre>
            </div>
          </template>
        </ea-col>
      </ea-row>
      
      <!-- Generic Error Business Component -->
      <qb-generic-error
        id="genericError"
        v-model="model.genericErrorModel"
        :isVisible="isGenericErrorVisible"
        :modalData="genericErrorData"
        @close="closeGenericErrorDialog"
      ></qb-generic-error>
    </ea-flow-container>
  </div>
</template>

<script lang="ts">
import {
  Component
} from 'vue-property-decorator';
import {
  mixins
} from 'vue-class-component';
import {
  EAFlow,
  EAFlowNavigation,
  EAApplicationLogger,
  EAView,
  EALocaleManager,
  EAMethod,
  throwIfResponseHasErrors,
  ResponseWithErrors,
  EAMultiError,
  EAError,
  EAApplicationError
} from '@zurich-es-npm/ea-front-web-core';

import NotificationHandler from '@/presentational-components/notification-handler/notificationHandler.vue';
import QbHeaderBusiness from '@/business-components/qb-header/qb-header-business.vue';
import NewOfferView from './views/new-offer-view.vue';
import GeneralDataView from './views/general-data-view.vue';
import VehiclesDataView from './views/vehicles-data-view.vue';
import QuoteView from './views/quote-view.vue';
import {
  FleetsModel,
  FlowConsultViewsStepsModel,
  FlowHeaderStepsModel,
  FlowViewsStepsModel,
  InputModel,
  OperationTypeEnum,
  StepRelationInterface
} from './fleets-model';
import {
  NewFleetFlowConfig
} from './model/new-fleet-flow-config-model';
import {
  ConfigFlowUtils
} from '@/utils/config-flow-utils';
import InterveningDataView from './views/intervening-data-view.vue';
import GenerateDocumentationView from './views/generate-documentation-view.vue';
import OfferIssuanceDataView from './views/offer-issuance-data-view.vue';
import QbGenericErrorBusiness, {
  GenericErrorData
} from '@/business-components/qb-generic-error/qb-generic-error-business.vue';
import {
  SubflowThunderNameEnum
} from '@/types/flow/flow-enum.types';

const logger = new EAApplicationLogger();

/**
 * Step / component relationship
 */
export const StepComponentRelationship: {
  step: FlowViewsStepsModel;
  title?: string;
  component: typeof EAView;
}[] = [
  {
    step: FlowViewsStepsModel.NewOfferStep,
    component: NewOfferView
  },
  {
    step: FlowViewsStepsModel.FleetDataStep,
    title: 'fleets.fleetsFlow.header.steps.fleetData',
    component: GeneralDataView
  },
  {
    step: FlowViewsStepsModel.VehiclesDataStep,
    title: 'fleets.fleetsFlow.header.steps.vehicleData',
    component: VehiclesDataView
  },
  {
    step: FlowViewsStepsModel.QuoteDataStep,
    title: 'fleets.fleetsFlow.header.steps.quote',
    component: QuoteView
  },
  {
    step: FlowViewsStepsModel.InterveningDataStep,
    title: 'fleets.fleetsFlow.header.steps.interveningData',
    component: InterveningDataView
  },
  {
    step: FlowViewsStepsModel.GenerateDocumentationStep,
    component: GenerateDocumentationView
  },
  {
    step: FlowViewsStepsModel.OfferIssuanceDataStep,
    title: 'fleets.fleetsFlow.header.steps.issuance',
    component: OfferIssuanceDataView
  }
];

/**
 * Step / component relationship
 */
export const StepConsultFlowRelationship: StepRelationInterface[] = [
  {
    step: FlowConsultViewsStepsModel.FleetDataStep,
    title: 'fleets.fleetsFlow.header.steps.fleetData',
    component: GeneralDataView
  },
  {
    step: FlowConsultViewsStepsModel.VehiclesDataStep,
    title: 'fleets.fleetsFlow.header.steps.vehicleData',
    component: VehiclesDataView
  },
  {
    step: FlowConsultViewsStepsModel.QuoteDataStep,
    title: 'fleets.fleetsFlow.header.steps.quote',
    component: QuoteView
  }
];

@Component({
  components: {
    QbHeader: QbHeaderBusiness,
    newOfferView: NewOfferView,
    vehiclesDataView: VehiclesDataView,
    QbGenericError: QbGenericErrorBusiness,
    'notification-handler': NotificationHandler
  }
})

/** 
 * Fleets flow
 * 
 * 
 */
export default class FleetsFlow extends mixins<EAFlow<FleetsModel>>(EAFlow) {

  public currentHeaderStep: number = -1;

  public showSteps: boolean = false;

  public flowId: string = '';
  
  public qbHeaderKey: number = 0;

  public genericErrorData: GenericErrorData = {};

  public isGenericErrorVisible = false;

  public fleetName = 'FleetNew';

  /**
   * Get the correct flow steps
   */
  get getStepsFlow(): StepRelationInterface[] {
    return this.model.operationType === OperationTypeEnum.CONSULTA ?
      StepConsultFlowRelationship :
      StepComponentRelationship;
  }

  /**
   * Get the correct view steps
   */
  get getStepsViews(): any {
    return this.model.operationType === OperationTypeEnum.CONSULTA ?
      FlowConsultViewsStepsModel :
      FlowViewsStepsModel;
  }

  /**
   * Get correspondant step view component
   */
  get stepComponent(): typeof EAView | null {
    const relationship = this.getStepsFlow.find(
      rel => rel.step === this.navigation.step
    );
    if (relationship) {
      return relationship.component;
    }
    return null;
  }

  /**
   * Get flow subtitle
   */
  get subTitle(): string {
    if (this.navigation.step === this.getStepsViews.NewOfferStep || !this.model.offerNumber) {
      return '';
    } else {
      return EALocaleManager.i18n.t('fleets.fleetsFlow.header.offerNumber', {
        offerNumber: this.model.offerNumber
      }) as string;
    }
  }

  /** 
   * Get steps array
   */
  get steps(): {
    step: FlowViewsStepsModel | FlowConsultViewsStepsModel;
    title?: string;
    component: typeof EAView;
  }[] {
    return this.getStepsFlow;
  }

  /**
   * Handler documentation button visibility on consult mode
   */
  get showDocumentationBtn() {
    return this.model.operationType === OperationTypeEnum.CONSULTA;
  }

  /** 
   * Initialize flow model
   */
  constructor() {
    super();
    logger.debug('FleetsFlow::constructor');
    this.model = new FleetsModel();
    this.navigation = new EAFlowNavigation();
    this.navigation.maxWindows = 1;
  }

  /**
   * Hooks on created
   */
  @EAMethod({
    loading: true
  })
  async created() {
  
    try {
      this.model.flowConfiguration = await ConfigFlowUtils.getConfigFlowByName(this.fleetName) as NewFleetFlowConfig;
    } catch (err) {
      throwIfResponseHasErrors(err as ResponseWithErrors);
    }
    this.setInitialStep();
    this.getStepsFlow.forEach(step => {
      if (step.title) {
        step.title = EALocaleManager.i18n.t(step.title) as string;
      }
    });
  }


  /**
   * Reset data obtained when created a new offer.
   * This is reset `offerNumber` and `offerVersion` model properties.
   */
  resetOfferCreatedData(): void {
    this.model.offerNumber = '';
    this.model.offerVersion = 0;
  }

  /**
   * Method 
   * @param {number} step
   * @param {number} headerStep
   */
  changeToStep(step: number, headerStep: number) {
    if (step === this.getStepsViews.NewOfferStep) {
      this.resetOfferCreatedData();
      this.showSteps=false;
    } else {
      this.showSteps=true;
    }

    this.navigation.step = step;
    this.currentHeaderStep = headerStep;
    scrollTo(0, 0);
  }

  /**
   * Handles the navigatino to the next step
   * - If this.inputModel is informed, the next step is NewOfferStep
   * - If this.inputModel has no data, the next step is GeneralDataStep
   */
  setInitialStep() {
    const {
      codigoPoliza, versionPoliza, operationType, hasParentFlow
    }: InputModel = this.inputModel;
    this.model.hasParentFlow = hasParentFlow;
    this.model.operationType = operationType;
    if (codigoPoliza !== undefined && versionPoliza !== undefined) {
      this.model.offerNumber = codigoPoliza;
      this.model.offerVersion = versionPoliza;
    
      if (this.model.operationType === OperationTypeEnum.MANTENIMIENTO ||
      this.model.operationType === OperationTypeEnum.CONSULTA) {
        this.changeToStep(this.getStepsViews.FleetDataStep, FlowHeaderStepsModel.FleetDataStep);
      }
    } else {
      this.model.operationType = OperationTypeEnum.NUEVA;
      this.changeToStep(this.getStepsViews.NewOfferStep, FlowHeaderStepsModel.FleetDataStep);
    }
  }

  /**
   * Shows error modal
   * @param {GenericErrorData} args - params for error modal
   */
  showGenericError(args: GenericErrorData) {
    const {
      title,
      message,
      messages,
      errors,
      changeStepOnClose,
      showStepOnClose
    } = args;

    this.genericErrorData = {
      title,
      message,
      messages,
      errors,
      changeStepOnClose,
      showStepOnClose
    };
    this.isGenericErrorVisible = true;
  }

  /**
   * Handles general data services errors
   * @param {any} args
   */
  public handleGenericError(args: any) {
    const {
      error, errorCode, changeStepOnClose, showStepOnClose
    } = args;
    // eslint-disable-next-line no-extra-parens
    if ((error as EAMultiError).getErrorList) {
      const multiError = error as EAMultiError;
      this.genericErrorData = {
        title: 'Error',
        errors: multiError.getErrorList().map(_err => {
          return {
            message: _err.message
          };
        }),
        changeStepOnClose,
        showStepOnClose
      };
      this.isGenericErrorVisible = true;
    } else {
      const _error = error as EAError;
      this.genericErrorData = {
        title: 'Error',
        message: _error.message,
        changeStepOnClose,
        showStepOnClose
      };
      this.isGenericErrorVisible = true;
      throw new EAApplicationError(errorCode, [_error.message]);
    }
  }

  /**
   * Closes generic dialog
   */
  closeGenericErrorDialog() {
    const changeStep = this.genericErrorData.changeStepOnClose;
    const showStep = this.genericErrorData.showStepOnClose;
    this.genericErrorData = {
      title: '',
      changeStepOnClose: undefined,
      showStepOnClose: undefined
    };
    this.isGenericErrorVisible = false;
    if (changeStep !== undefined && showStep !== undefined) {
      this.changeToStep(changeStep, showStep);
    }
  }

  /**
   * Método que abre un subflujo
   * @param {SubflowThunderNameEnum} flowName 
   */
  @EAMethod()
  onOpenSubflow(flowName: SubflowThunderNameEnum) {
    if (flowName === SubflowThunderNameEnum.DocumentationFlow) {
      // Fleet policy documentation
      const inputChildModel = {
        codigoPoliza: this.model.offerNumber,
        versionPoliza: this.model.offerVersion,
        tipoPoliza: this.model.offerType,
        allowAttachments: false,
        isFleet: true
      };

      this.goToFlow(SubflowThunderNameEnum.DocumentationFlow, inputChildModel);
    }
  }


  /**
   * Opens previous flow (ie: FleetsSearchOfferFlow...)
   */
  @EAMethod({
    loading: true
  })
  goToPreviousFlow() {
    this.returnToParentFlow(undefined);
  }
}
</script>
