import QbProductoAseguradoTrConstruccionMontajeModel
  // eslint-disable-next-line max-len
  from '@/business-components/qb-producto-asegurado-tr-construccion-montaje/qb-producto-asegurado-tr-construccion-montaje-model';
import {
  QuoteBuyModel
} from '../../../../flows/quote-buy/quote-buy-model';

/**
 * TodoRiesgoConstruccionMontajeModel model
 *
 * @export
 * @class TodoRiesgoConstruccionMontajeModel
 * @extends {QuoteBuyModel}
 */
export default class TodoRiesgoConstruccionMontajeModel extends QuoteBuyModel {
  
  public productModel = new QbProductoAseguradoTrConstruccionMontajeModel();

}
