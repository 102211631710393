<template>
  <div>
    <div>
      <ea-card shadow="hidden">
        <div slot="cardbody" class="p-a-16">
          <ea-row extraClass="d-display-flex">
            <ea-col extraClass="d-align-self-stretch">
              <qb-documentation-select-proposal
                id="selectProposal"
                v-model="model.documentationSelectProposalModel"
                :warrantiesProposalsNumbers="shownWarrantiesProposalsNumbers"
                :warrantyRates="model.warrantyRates"
                :continentPrices="model.continentPrices"
                :contentPrices="model.contentPrices"
                :productFactory="productFactory"
                :productModel="model.productModel"
              ></qb-documentation-select-proposal>
            </ea-col>
            <ea-col extraClass="d-align-self-stretch">
              <qb-documentation-select-documentation
                id="selectDocumentation"
                v-model="model.documentationSelectDocumentationModel"
                ref="selectDocumentationComp"
              ></qb-documentation-select-documentation>
            </ea-col>
          </ea-row>
          <qb-addresses-person
            id="addresses-person"
            v-model="addressPersonModel"
            :showClientData="true"
            :documentationClientData="tomadorDocumentationClientData"
            @refreshData="onRefreshData"
            @handleGenericError="handleGenericError"
            @showError="showError"
          >
          </qb-addresses-person>
        </div>
      </ea-card>

      <ea-row class="m-t-16">
        <ea-col :span="12" class="d-display-flex d-justify-flex-start">
          <ea-button type="secondary" @click="onGoBack" size="medium">
            {{ $t('common.label.back') }}
          </ea-button>
        </ea-col>
        <ea-col :span="12" class="d-display-flex d-justify-flex-end">
          <ea-button-group>
            <ea-button type="primary" @click="onDownloadAndSend" size="medium">
              {{ $t('quoteBuyGenericFlow.generateDocumentation.downloadAndSend') }}
            </ea-button>
          </ea-button-group>
        </ea-col>
      </ea-row>
    </div>
  </div>
</template>

<script lang="ts">
import {
  Component, Prop
} from 'vue-property-decorator';

import {
  mixins
} from 'vue-class-component';

import {
  EAApplicationLogger,
  EAError,
  EAMethod,
  EAValidationError,
  EAView,
  ResponseWithErrors,
  throwIfResponseHasErrors,
} from '@zurich-es-npm/ea-front-web-core';
/* eslint-disable max-len */
import QbDocumentationSelectDocumentationBusiness from '@/business-components/qb-documentation-select-documentation/qb-documentation-select-documentation-business.vue';
import QbDocumentationSelectProposalBusiness from '@/business-components/qb-documentation-select-proposal/qb-documentation-select-proposal-business.vue';
/* eslint-enable max-len */
import QbAddressesPersonModel from '@/business-components/addresses/qb-addresses-person-model';
import QbAddressesPersonBusiness from '@/business-components/addresses/qb-addresses-person.vue';
import {
  GetPersonAddressesResponse,
  GetPersonAddressesResponseDomicilios
} from '@/services/V1/persons/getPersonAddressesOperation/post';
import {
  EAGenerateOfferDocumentationApi,
  GenerateOfferDocumentationRequestTipoPolizaEnum as GenerateOfferTipoPolizaEnum
} from '@/services/V1/quoteAndBuy/generateOfferDocumentationOperation/post';
import {
  EAGetDocumentationDataApi,
  GetDocumentationDataRequestTipoPolizaEnum as TipoPolizaEnum,
} from '@/services/V1/quoteAndBuy/getDocumentationDataOperation/post';
import {
  AddressUtils
} from '@/utils/address-utils';
import {
  CorpTableNames,
  fetchCorporateTable,
  parseCorpTableDocuments,
  ParsedTableData,
} from '@/utils/corporate-tables';
import ProductBase from '@/utils/quote-buy-product-factory/products/product-base';
import {
  FlowHeaderStepsModel, FlowViewsStepsModel, PolicyModel
} from '../policy-model';
import Utils from '@/utils/utils';
import {
  GenericErrorData
} from '@/business-components/qb-generic-error/qb-generic-error-business.vue';
import {
  NotificationsUtils
} from '@/utils/notifications/notifications-utils';
import PersonUtils from '@/utils/person-utils';
import {
  Roles
} from '@/types/roles/roles-enum.types';
import {
  SetPersonAddressesRequestDomicilios
} from '@/services/V1/persons/setPersonAddressesOperation/post';
import {
  DocumentationClientData
} from '@/presentational-components/clientBasicInfo/clientBasicInfo.type';

@Component({
  components: {
    QbDocumentationSelectProposal: QbDocumentationSelectProposalBusiness,
    QbDocumentationSelectDocumentation: QbDocumentationSelectDocumentationBusiness,
    QbAddressesPerson: QbAddressesPersonBusiness,
  },
})

/**
 * GenerateDocumentation view
 *
 */
export default class GenerateDocumentationView extends mixins<EAView<PolicyModel>>(EAView) {
  @Prop({
    required: true,
  })
    productFactory!: ProductBase;

  @Prop({
    required: true,
  })
    currentHeaderStep!: number;

  domiciliosTomador: GetPersonAddressesResponseDomicilios[] = [];

  addressPersonModel?: QbAddressesPersonModel = new QbAddressesPersonModel();

  corpTableDocumentsAddressType: ParsedTableData[] = [];

  /**
   * Hook on create
   */
  @EAMethod({
    loading: true,
  })
  async created() {

    await this.getCorpTablesData();

    // eslint-disable-next-line max-len
    this.model.documentationSelectProposalModel.generateDocumentationViewLabels = this.productFactory.getGenerateDocumentationViewLabels(
      this.model,
      this.corpTableDocumentsAddressType
    );

    await this.fetchDocumentationData();
    await this.fetchParseAddressesData();
  }

  /**
   * Calls auxiliar method to retrieve addresses data + parse it to model
   * @param {SetPersonAddressesRequestDomicilios | undefined} lastAddedAddress
   */
  @EAMethod({
    loading: true
  })
  public async fetchParseAddressesData(
    lastAddedAddress?: SetPersonAddressesRequestDomicilios
  ) {
    const fetchAddressesDataOutput = await this._fetchAddressesData(this.model.filiationCode);
    
    if (fetchAddressesDataOutput) {
      this._parseFetchedAddressesToModel(fetchAddressesDataOutput);
      AddressUtils.selectMainAddresses(fetchAddressesDataOutput, this.addressPersonModel, lastAddedAddress);
    }
  }

  /**
   * Calls bff and retrieves addresses
   * Throws error if any
   * @param {string} filiationCode
   * @returns {Promise<GetPersonAddressesResponse | null>}
   */
  public async _fetchAddressesData(filiationCode: string): Promise<GetPersonAddressesResponse | null> {
    const fetchAddressesDataOutput = await AddressUtils.fetchAddressesData(
      filiationCode
    );
    if (fetchAddressesDataOutput) {
      throwIfResponseHasErrors(fetchAddressesDataOutput as ResponseWithErrors);
    }
    return fetchAddressesDataOutput;
  }

  /**
   * Parses fetchAddressesDataOutput to 'tomador' person model
   * @param {GetPersonAddressesResponse} fetchAddressesDataOutput
   */
  public _parseFetchedAddressesToModel(
    fetchAddressesDataOutput: GetPersonAddressesResponse
  ): void {
    this.addressPersonModel = AddressUtils.addressesResponseToModel(
      fetchAddressesDataOutput, this.addressPersonModel
    );
  }

  /**
   * Fetch corporate tables to retrieve products names and parse data.
   */
  @EAMethod({
    loading: true,
  })
  async getCorpTablesData(): Promise<void> {
    try {
      const result = await fetchCorporateTable(CorpTableNames.AddressType);
      this.corpTableDocumentsAddressType = parseCorpTableDocuments(result);
    } catch (error) {
      const eaError = error as EAError;
      new EAApplicationLogger().error(
        `GenerateDocumentationView::getCorpTablesData:: fetch corporate table :: ${eaError.message}`
      );
      throw error;
    }
  }

  /**
   * Getter for shown warranty proposals number
   * If last step was issue offer step => Only selected proposal should be shown
   */
  get shownWarrantiesProposalsNumbers() {
    if (this.currentHeaderStep === FlowHeaderStepsModel.WarrantiesStep) {
      return this.model.warrantiesProposals;
    }

    return [this.model.selectedProposal];
  }

  /**
   * Gets document list
   */
  @EAMethod({
    loading: true,
  })
  async fetchDocumentationData(): Promise<void> {
    const api = new EAGetDocumentationDataApi();
    const output = await api.getDocumentationDataOperation({
      getDocumentationDataRequest: {
        codigoPoliza: this.model.offerNumber,
        versionPoliza: this.model.offerVersion,
        tipoPoliza: TipoPolizaEnum.Incompleta,
      },
    });
    if (output) {
      throwIfResponseHasErrors(output as ResponseWithErrors);
      if (output.listaDocumentos) {
        this.model.documentationSelectDocumentationModel.documentList = output.listaDocumentos;
      }
    }
  }

  /**
   * Go back button handler
   */
  onGoBack() {
    this.clearFlowErrors();
    NotificationsUtils.clearNotifications();
    this.$emit('changeStep', FlowViewsStepsModel.WarrantiesStep, FlowHeaderStepsModel.WarrantiesStep);
  }

  /**
   * Validates documentation select data (inputted email)
   * If validation OK => Updates model
   * If validation NOK => Throws EAValidationError
   */
  @EAMethod()
  async validateUpdateSelectDocumentationComp() {
    const selectDocumentationComp: QbDocumentationSelectDocumentationBusiness = this.$refs
      .selectDocumentationComp as QbDocumentationSelectDocumentationBusiness;
    try {
      await selectDocumentationComp.validation().validate();
    } catch (error) {
      throw new EAValidationError(this.$t('common.label.validation.formWithErrors').toString());
    }
    selectDocumentationComp.update();
  }

  /**
   * Validates selectAddressCode
   *
   */
  validateSelectedAddressCode() {
    const selectAddressCode = this.addressPersonModel?.addEditAddress.selectedAddressCode;
    if (!selectAddressCode) {
      throw new EAValidationError(this.$t('common.label.validation.directionFieldRequired').toString());
    }
  }

  /**
   * Download and send button handler
   */
  @EAMethod({
    loading: true,
  })
  async onDownloadAndSend() {
    // Validate
    this.clearFlowErrors();

    await this.validateUpdateSelectDocumentationComp();
    this.validateSelectedAddressCode();
    const api = new EAGenerateOfferDocumentationApi();
    const output = await api.generateOfferDocumentationOperation({
      generateOfferDocumentationRequest: {
        codigoOperacionPoliza: 'IM',
        codigoPoliza: this.model.offerNumber,
        versionPoliza: this.model.offerVersion,
        tipoPoliza: GenerateOfferTipoPolizaEnum.Incompleta,
        codigoSecuencialDomicilio: this.addressPersonModel?.addEditAddress.selectedAddressCode || '',
        emails: this.model.documentationSelectDocumentationModel.multipleEmailInputModel.emailList
          .filter(email => email !== '')
          .map(email => {
            return {
              emailContacto: email,
            };
          }),
        listaDocumentos: this.model.documentationSelectDocumentationModel.documentList,
        codigosPropuesta: this.model.documentationSelectProposalModel.warrantiesProposals
          .filter(warrantiesProposal => warrantiesProposal.checked)
          .map(warrantiesProposal => {
            return {
              codigoPropuesta: warrantiesProposal.proposalNumber,
            };
          }),
      },
    });

    if (output) {
      const errorsPackage = output.errors?.filter(error => error.code === '1009' || error.code === '8857');
      if (errorsPackage) {
        await this.fetchDocumentationData();
      }
      const severityErrors = output.errors?.filter(error => error.severity >= 4);
      throwIfResponseHasErrors({
        errors: severityErrors
      });
      Utils.downloadFiles(output.documentos);
      if (this.model.pendingIPIDDocumentation === true) {
        this.model.pendingIPIDDocumentation = false;
        NotificationsUtils.clearNotifications();
        this.$emit(
          'changeStep',
          FlowViewsStepsModel.IssueOfferStep,
          FlowHeaderStepsModel.IssueOfferStep
        );
      } else {
        this.onGoBack();
      }
    }
  }

  /**
   * Handles "refreshData" event
   * Calls fetchParseAddressesData function keeping last selected address
   * @param {SetPersonAddressesRequestDomicilios} lastAddedAddress
   */
  onRefreshData(lastAddedAddress: SetPersonAddressesRequestDomicilios) {
    this.fetchParseAddressesData(lastAddedAddress);
  }

  /**
   * Emits showError event
   * @param {GenericErrorData} genericErrorData
   */
  showError(genericErrorData: GenericErrorData) {
    this.$emit('showError', genericErrorData);
  }

  /**
   * Emits handleGenericError event
   * @param {any} args
   */
  handleGenericError(args: any) {
    const {
      error, errorCode
    } = args;
    this.$emit('handleGenericError', {
      error,
      errorCode,
    });
  }

  /**
   * Retrieves documentation client data model for person with 'tomador' role
   * @returns {DocumentationClientData}
   */
  public get tomadorDocumentationClientData(): DocumentationClientData {
    return PersonUtils.getDocumentationClientData(this.productFactory.personRoles, Roles.Tomador);
  }
}
</script>
