<template>
  <div>
    <ea-collapse v-if="group" header-background-color="white">
      <ea-collapse-item
        :title="
          $t('fleets.fleetsMaintenancePolicyFlow.fleetMaintenanceGroupVehicleTable.titlePrefix') +
            (group.descripcionAgrupacionFlota ? group.descripcionAgrupacionFlota : '')"
      >
        <ea-table
          :data="sortedListaVehiculos"
          :ref="'ref' + group.codigoAgrupacionFlota"
          :id="group.codigoAgrupacionFlota"
          :infinite-scroll="true"
          :simpleSelect="!queryPolicy"
          :disabledRows="disabledRows"
          @rowClick="onSelectVehicle"
          :class="!queryPolicy ? 'thead-fixed' : 'thead-fixed col-fixed-right'"
        >
          <template slot="top-table">
            <div class="ea-toolbar-action-wrapper d-display-flex d-direction-reverse">
              <ea-button
                @click="anulateVehicle"
                type="secondary"
                :disabled="!selectedVehicle">
                {{ $t('fleets.fleetsMaintenancePolicyFlow.fleetMaintenanceGroupVehicleTable.anulate') }}
              </ea-button>
            </div>
          </template>
          <ea-table-column
            headerClass="no-sortBy"
            :label="$t('fleets.fleetsMaintenancePolicyFlow.fleetMaintenanceGroupVehicleTable.'+
            'tableDataHeaders.policyNumber')"
            show="codigoPoliza"
          >
            <template slot-scope="row">
              <span
                v-if="!queryPolicy">
                {{ row.codigoPoliza }}
              </span>
              <ea-link v-else
                @click="queryVehicle(row)">
                {{ row.codigoPoliza }}
              </ea-link>
            </template>
          </ea-table-column>
          <ea-table-column
            headerClass="no-sortBy"
            :label="$t('fleets.fleetsMaintenancePolicyFlow.fleetMaintenanceGroupVehicleTable.'+
              'tableDataHeaders.licensePlateNumber')"
            show="numeroMatriculaSinValidaciones"
          >
            <template slot-scope="row">
              <span>
                {{ row.numeroMatriculaSinValidaciones }}
              </span>
            </template>
          </ea-table-column>
          <ea-table-column
            headerClass="no-sortBy"
            :label="$t('fleets.fleetsMaintenancePolicyFlow.fleetMaintenanceGroupVehicleTable.tableDataHeaders.version')"
            show="versionPoliza"
          >
            <template slot-scope="row">
              <span>
                {{ row.versionPoliza }}
              </span>
            </template>
          </ea-table-column>
          <ea-table-column
            headerClass="no-sortBy"
            :label="$t('fleets.fleetsMaintenancePolicyFlow.fleetMaintenanceGroupVehicleTable.tableDataHeaders.brand')"
            show="descripcionMarcaVehiculo"
          >
            <template slot-scope="row">
              <span>
                {{ row.descripcionMarcaVehiculo }}
              </span>
            </template>
          </ea-table-column>
          <ea-table-column
            headerClass="no-sortBy"
            :label="$t('fleets.fleetsMaintenancePolicyFlow.fleetMaintenanceGroupVehicleTable.tableDataHeaders.model')"
            show="descripcionModeloVehiculo"
          >
            <template slot-scope="row">
              <span>
                {{ row.descripcionModeloVehiculo }}
              </span>
            </template>
          </ea-table-column>
          <ea-table-column
            headerClass="no-sortBy"
            :label="$t('fleets.fleetsMaintenancePolicyFlow.fleetMaintenanceGroupVehicleTable.'+
              'tableDataHeaders.policyEfectDate')"
            show="fechaEfectoMovimiento"
            data-type="date"
          >
            <template slot-scope="row">
              <span>
                {{ dateFormatter(row.fechaEfectoMovimiento) }}
              </span>
            </template>
          </ea-table-column>

          <ea-table-column
            headerClass="no-sortBy"
            :label="$t('fleets.fleetsMaintenancePolicyFlow.fleetMaintenanceGroupVehicleTable.'+
              'tableDataHeaders.policyEndDate')"
            show="fechaVencimiento"
            data-type="date"
          >
            <template slot-scope="row">
              <span>
                {{ dateFormatter(row.fechaVencimiento) }}
              </span>
            </template>
          </ea-table-column>

          <ea-table-column
            headerClass="no-sortBy"
            :label="$t('fleets.fleetsMaintenancePolicyFlow.fleetMaintenanceGroupVehicleTable.tableDataHeaders.package')"
            :sortable="false"
          >
            <template slot-scope="col">
              <span>
                {{
                  col.codigoPaquete
                    ? $t(`fleets.fleetsFlow.fleetPackagesWarrantiesModal.codes.${col.codigoPaquete}`)
                    : '-'
                }}
              </span>
            </template>
          </ea-table-column>

          <!-- TODO: cambiar por la prima anual cuando exista -->
          <ea-table-column
            headerClass="no-sortBy"
            :label="$t('fleets.fleetsMaintenancePolicyFlow.fleetMaintenanceGroupVehicleTable.'+
              'tableDataHeaders.annualPremium')"
            show="importeTotal"
          >
            <template slot-scope="row">
              <div class="wp-80 d-display-flex d-justify-flex-end">
                <ea-text>
                  {{ digitsFormatter(row.importeTotal) }}
                </ea-text>
              </div>
            </template>
          </ea-table-column>

          <ea-table-column
            :label="$t('fleets.fleetsMaintenancePolicyFlow.fleetMaintenanceGroupVehicleTable.tableDataHeaders.status')"
            :sortable="false"
          >
            <template slot-scope="col">
              <span class="d-display-flex d-align-items-center">
                <ea-icon
                  class="m-r-8"
                  v-if="col.codigoEstadoPoliza === PolicyEmitedStatus.VIGOR"
                  status="success"
                  icon="el-icon-circle-check"
                />
                <ea-icon
                  v-else-if="col.codigoEstadoPoliza === PolicyEmitedStatus.ANULADA"
                  class="m-r-8"
                  status="danger"
                  icon="el-icon-remove-outline"
                />
                {{ getStatusText(col) }}
              </span>
            </template>
          </ea-table-column>

          <ea-table-column
            headerClass="no-sortBy"
            :label="''"
            v-if="queryPolicy"
          >
            <template slot-scope="row">
              <ea-tooltip
                placement="top"
                :content="$t('searchOfferFlow.searcher.documentation')">
                <ea-button-icon
                  class="m-b-2"
                  size="medium"
                  @click="onShowDocumentation(row)"
                  icon="z-document"/>
              </ea-tooltip>
            </template>
          </ea-table-column>
        </ea-table>
      </ea-collapse-item>
    </ea-collapse>
  </div>
</template>

<script lang="ts">
import {
  Component, Prop
} from 'vue-property-decorator';

import {
  mixins
} from 'vue-class-component';

import {
  EABusinessComponent, EAErrorManager,
  EAValidationError
} from '@zurich-es-npm/ea-front-web-core';

import FleetMaintenanceGroupVehicleTableModel from './fleet-maintenance-group-vehicle-table-model';
import Utils from '@/utils/utils';
import {
  GetGroupedVehicleListResponseListaAgrupaciones, GetGroupedVehicleListResponseListaVehiculos
} from '@/services/V1/fleets/getGroupedVehicleListOperation/post';
import {
  ParsedTableData
} from '@/utils/corporate-tables';
import {
  PolicyEmitedStatus
} from '@/flows/fleets-maintenance-policy/fleets-maintenance-policy-model';

import Row from '@zurich-es-npm/ea-front-web-ui/lib/ea/data/table/src/class/Row';
import {
  NotificationsUtils
} from '@/utils/notifications/notifications-utils';

@Component({
  name: 'fleet-maintenance-group-vehicle-table'
})

/**
 * Business Component fleet-maintenance-group-vehicle-table
 */
export default class FleetMaintenanceGroupVehicleTableBusiness
  extends mixins<EABusinessComponent<FleetMaintenanceGroupVehicleTableModel>>(EABusinessComponent) {

  @Prop()
    group!: GetGroupedVehicleListResponseListaAgrupaciones;

  @Prop()
    queryPolicy!: boolean;

  @Prop({
    required: true
  })
    policyEmitedStatusList!: ParsedTableData[];

  PolicyEmitedStatus = PolicyEmitedStatus;

  selectedVehicle!: GetGroupedVehicleListResponseListaVehiculos;

  flowId = ''; // Used to show notifications into correct flow

  /**
   * Sorts vehicle list with invalid vehicles first.
   * @returns {string[]}
   */
  get disabledRows(): string[] {
    const rowIndexs: string[] = [];

    /*
     * This.group.listaVehiculos?.forEach((vehicle, index) => {
     *
     *
     * //TODO: uncomment when arq give us a functionality to disable table's radio buttons
     * if (vehicle.codigoEstadoPoliza !== PolicyEmitedStatus.VIGOR) {
     * rowIndexs.push(`${index}`);
     * }
     * }); 
     */
    return rowIndexs;
  }
  
  /**
   * Sorts vehicle list with invalid vehicles first.
   * @param {VehicleFormModel[]} vehicleList 
   * @returns {VehicleFormModel[]}
   */
  public get sortedListaVehiculos(): GetGroupedVehicleListResponseListaVehiculos[] | undefined {
    // eslint-disable-next-line max-len
    return this.group.listaVehiculos?.
      sort((vehicleA, vehicleB) => Number(vehicleA.valorVehiculo || false) - Number(vehicleB.valorVehiculo || false));
  }

  /**
   * Computed to know if component has predefined set of brands/models/versions
   */
  public get groupTotal(): number {
    let total = 0;
    if (this.group.listaVehiculos) {
      for (const vehiculo of this.group.listaVehiculos) {
        if (vehiculo.importeTotal) {
          total += vehiculo.importeTotal;
        }
      }
    }
    return total;
  }


  /**
   * Hook on created
   */
  created() {
    this.flowId = NotificationsUtils.getCurrentFlowId();
  }

  /**
   * Formatea el precio ejemplo : 500 -> 500.00€
   * @param {String} price valor sin formato 500
   * @returns {String} valor con formato 500.00 €
   */
  digitsFormatter(price: number): string {
    return price ? `${Utils.formatFourDigitNumber(this.$n(price))} €` : '-';
  }

  /**
   * Formatea el valor de la fecha en formato "DD/MM/YYYY"
   * @param {String} value fecha sin en formato YYYY-MM-DD
   * @returns {String} fecha en formato "DD/MM/YYYY"
   */
  dateFormatter(value: string): string {
    return value ? Utils.convertDateToString(value) : '';
  }

  /**
   * Emite el vehiculo a consultar
   * @param {GetGroupedVehicleListResponseListaVehiculos} row con los datos del vehiculo
   */
  queryVehicle(row: GetGroupedVehicleListResponseListaVehiculos): void {
    this.$emit('queryVehicle', row, this.group.descripcionAgrupacionFlota);
  }

  /**
   * Return the text of the table from policyEmitedStatusList
   * @param {GetGroupedVehicleListResponseListaVehiculos} col con los datos del vehiculo
   * @returns {string}
   */
  getStatusText(col: GetGroupedVehicleListResponseListaVehiculos) {
    return this.policyEmitedStatusList.find(item => item.value === col.codigoEstadoPoliza)?.label;
  }

  /**
   * Emite el vehiculo del que ver su documentación
   * @param {Row} row con los datos del vehiculo
   */
  onShowDocumentation(row: Row): void {
    this.$emit('showDocumentation', row);
  }

  /**
   * @param {Row} row to select
   */
  onSelectVehicle(row: Row) {
    this.selectedVehicle = row.getData() as GetGroupedVehicleListResponseListaVehiculos;
    this.$forceUpdate();
  }


  /**
   * Anulate vehicle
   */
  anulateVehicle() {
    if (this.checkIfVehicleCanBeAnulated()) {
      this.$emit('anulateVehicle', this.selectedVehicle);
    } else {
      // eslint-disable-next-line max-len
      const errorMessage = this.$t(`fleets.fleetsMaintenancePolicyFlow.fleetMaintenanceGroupVehicleTable.cantBeAnulateError`).toString();
      EAErrorManager.sendError(new EAValidationError(errorMessage), this.flowId);
    }
  }


  /**
   * Anulate vehicle
   * @Return {boolean}
   */
  checkIfVehicleCanBeAnulated() {
    return this.selectedVehicle.codigoEstadoPoliza === PolicyEmitedStatus.VIGOR;
  }
  
}
</script>
