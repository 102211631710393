<template>
  <div class="m-b-16">
    <ea-card
     shadow="hidden"
    >
      <div slot="cardbody" class="fleet-info-card p-a-16">
        <h3>{{ $t('documentationFlow.documentationView.attachedDcoumentation.title') }}</h3>
     
        <ea-table
          v-if="documentsList && documentsList.length > 0"
          :data="documentsList"
          ref="documentationTable"
          id="documentationTable"
          :infinite-scroll="true"
          class="thead-fixed table-height-limited"
        >
          <ea-table-column
            v-for="column in columnList"
            :key="column.propKey"
            :label="column.labelKey ?
              $t(`documentationFlow.documentationView.attachedDcoumentation.${column.labelKey}`) : ''"
            headerClass="no-sortBy"
          >
            <template slot-scope="row">
              <template v-if="column.colType === ColumnItemType.Date">
                {{ dateFormatter(row[column.propKey]) }}
              </template>

              <template v-else-if="column.propKey === 'indicadorDescarga'">
                <ea-tooltip v-if="row.indicadorDescarga" placement="top">
                  <div slot="content">
                    {{ $t('documentationFlow.documentationView.attachedDcoumentation.downloadDocument') }}
                  </div>
                  <ea-button-icon
                    icon="z-download"
                    @click="onDownload(row)">
                  </ea-button-icon>
                </ea-tooltip>
              </template>

              <template v-else>
                <p class="ea-table-td-content" tabindex="0">
                  {{ row[column.propKey] }}
                </p>
              </template>
            </template>
          </ea-table-column>
        </ea-table>
         <p class="t-size-small" v-else>
          {{ $t('documentationFlow.documentationView.attachedDcoumentation.noDocuments') }}
        </p>
      </div>
    </ea-card>
  </div>
</template>

<script lang="ts">
import {
  Component, Prop, Watch
} from 'vue-property-decorator';

import {
  mixins
} from 'vue-class-component';

import {
  EABusinessComponent,
  EAMethod,
  ResponseWithErrors,
  throwIfResponseHasErrors
} from '@zurich-es-npm/ea-front-web-core';

import QbDownloadDocumentationModel from './qb-download-documentation-model';
import {
  ColumnItem, ColumnItemType
} from '../qb-policy-history-modal/qb-policy-history-modal-model';
import Utils from '@/utils/utils';
import {
  EAGetDocumentListApi,
  GetDocumentListRequestTipoPolizaEnum,
  GetDocumentListResponseData
} from '@/services/V1/documentation/getDocumentListOperation/post';
import {
  EAGetFleetDocumentListApi, GetFleetDocumentListResponseData,
} from '@/services/V1/fleets/getFleetDocumentListOperation/post';
import {
  DownloadFilesRequestDocumentos, EADownloadFilesApi
} from '@/services/V1/common/downloadFilesOperation/post';
import {
  NotificationsUtils
} from '@/utils/notifications/notifications-utils';

@Component({
  name: 'qb-download-documentation'
})

/**
 * Business Component qb-download-documentation
 */
export default class QbDownloadDocumentationBusiness
  extends mixins<EABusinessComponent<QbDownloadDocumentationModel>>(EABusinessComponent) {

  documentsList: GetDocumentListResponseData[] | undefined = [];

  ColumnItemType = ColumnItemType;

  columnList: ColumnItem[] = [
    {
      propKey: 'nombreDocumento',
      labelKey: 'documentDescriptionLabel',
    },
    {
      propKey: 'fechaSubidaDocumento',
      labelKey: 'documentDateLabel',
      colType: ColumnItemType.Date
    },
    {
      propKey: 'codigoDocumento',
      labelKey: 'documentNumberLabel',
    },
    {
      propKey: 'indicadorDescarga',
      labelKey: '',
      colType: ColumnItemType.IconButton,
    }
  ];

  @Prop({
    required: true
  })
  public policyNumber!: string;
  
  @Prop({
    required: true
  })
  public policyVersion!: number;
  
  @Prop({
    required: true
  })
  public policyType!: string;
  
  @Prop()
  public reloadDocumentationFlag!: number;

  @Prop()
  public isFleet!: boolean;

  /**
   * Get the list of documents
   */
  async created() {
    await this.getDocumentsListBff();
  }
  

  /**
   * Formatea el valor de la fecha en formato "DD/MM/YYYY"
   * @param {String} value fecha sin en formato YYYY-MM-DD
   * @returns {String} fecha en formato "DD/MM/YYYY"
   */
  dateFormatter(value: string): string {
    return value ? Utils.convertDateToString(value) : '';
  }
  
  /**
   * Download the document
   * @param { GetDocumentListResponseData } document selected document data
   * @returns {Promise}
   */
  async onDownload(document: GetDocumentListResponseData): Promise<void> {
    this.clearFlowErrors();
    NotificationsUtils.clearNotifications();
    await this.downloadDocuments([document]);
  }
  
  /**
   * Get the documents list
   */
  async getDocumentsListBff() {
    if (this.isFleet === true) {
      this.documentsList = await this.getFleetDocuments();
    } else {
      this.documentsList = await this.getPolicyDocuments();
    }
  }
  
  /**
   * Downloads selected document
   * @returns {GetDocumentListResponseData[] | undefined} documents 
   */
  @EAMethod({
    loading: true,
  })
  async getPolicyDocuments(): Promise<GetDocumentListResponseData[] | undefined> {
    const api = new EAGetDocumentListApi();
    const response = await api.getDocumentListOperation({
      getDocumentListRequest: {
        codigoPoliza: this.policyNumber,
        versionPoliza: this.policyVersion,
        tipoPoliza: this.policyType as unknown as GetDocumentListRequestTipoPolizaEnum
      }
    });
    

    if (response) {
      throwIfResponseHasErrors(response as ResponseWithErrors);
      return response.data;
    }
  }

  /**
   * Downloads selected document
   * @returns {GetFleetDocumentListResponseData[] | undefined} documents 
   */
  @EAMethod({
    loading: true,
  })
  async getFleetDocuments(): Promise<GetFleetDocumentListResponseData[] | undefined> {
    const api = new EAGetFleetDocumentListApi();
    const response = await api.getFleetDocumentListOperation({
      getFleetDocumentListRequest: {
        codigoFlota: this.policyNumber,
        versionFlota: this.policyVersion
      }
    });
    

    if (response) {
      throwIfResponseHasErrors(response as ResponseWithErrors);
      return response.data;
    }
  }

  /**
   * Downloads selected document
   * @param {GetDocumentListResponseData[] | undefined} documents 
   */
  @EAMethod({
    loading: true
  })
  public async downloadDocuments(documents?: GetDocumentListResponseData[]) {
    if (!documents) {
      return;
    }

    const documentos: DownloadFilesRequestDocumentos[] = [];

    documents.forEach(document => {
      if (document.codigoDocumento && document.indicadorDescarga && document.nombreDocumento) {
        documentos.push({
          codigoDocumento: document.codigoDocumento,
          nombreDocumento: document.nombreDocumento,
          tipoSoporte: document.tipoSoporte
        });
      }
    });

    const downloadFilesApi = new EADownloadFilesApi();
    const output = await downloadFilesApi.downloadFilesOperation({
      downloadFilesRequest: {
        documentos
      }
    });

    if (output) {
      const highSeverityErrors = output.errors?.filter(error => error.severity >= 4);
      throwIfResponseHasErrors({
        errors: highSeverityErrors
      });
      Utils.downloadFiles(output.documentos);
    }
  }

  /**
   * Watcher for reloadDocumentationFlag
   * Calls auxiliar method to retrieve document list
   */
  @Watch('reloadDocumentationFlag')
  async onReloadDocumentation() {
    await this.getDocumentsListBff();
  }
}
</script>

<style scoped>
::v-deep .ea-table .ea-table-component table tbody tr td:last-child .ea-tooltip i {
  cursor: pointer;
  font-weight: 500;
  font-size: 20px;
}
</style>
