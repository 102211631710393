/* eslint-disable */

/**
 * zonazurich
 * Portal de Mediación Digital
 *
 * The version of the OpenAPI document: 1
 * Contact: digitalarchitecture@zurich.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import {
  EASDKConfig,
  EAUrlUtils
} from '@zurich-es-npm/ea-shared-utils-js';

import * as globalImportUrl from 'url';
import {
  Configuration
} from './configuration';
import globalAxios, {
  AxiosPromise,
  AxiosInstance,
  AxiosResponse
} from 'axios';

const BASE_PATH = EAUrlUtils.removeLastForwardSlash('http://localhost:3000');

/**
 *
 * @export
 */
export const COLLECTION_FORMATS = {
  csv: ',',
  ssv: ' ',
  tsv: '\t',
  pipes: '|',
};

/**
 *  
 * @export
 * @interface RequestArgs
 */
export interface RequestArgs {
  url: string;
  options: any;
}

/**
 * 
 * @export
 * @class BaseAPI
 */
export class BaseAPI {
  protected configuration: Configuration | undefined;

  /**
   *Creates an instance of BaseAPI.
   * @param {Configuration} [configuration]
   * @param {string} [basePath=BASE_PATH]
   * @param {AxiosInstance} [axios=globalAxios]
   * @memberof BaseAPI
   */
  public constructor(
    configuration?: Configuration,
    protected basePath: string = BASE_PATH,
    protected axios: AxiosInstance = globalAxios) {
    if (configuration) {
      this.configuration = configuration;
      this.basePath = configuration.basePath || this.basePath;
    }
  }
}

/**
 * 
 * @export
 * @class RequiredError
 * @extends {Error}
 */
export class RequiredError extends Error {
  public name: 'RequiredError' = 'RequiredError';

  public field: string;

  /**
   *Creates an instance of RequiredError.
   * @param {string} field
   * @param {string} [msg]
   * @memberof RequiredError
   */
  public constructor(field: string, msg?: string) {
    super(msg);
    this.field=field;
  }
}

/**
 * 
 * @export
 * @class AsyncRequestError
 * @extends {Error}
 */
export class AsyncRequestError extends Error {
  public name: 'AsyncRequestError' = 'AsyncRequestError';

  /**
   *Creates an instance of AsyncRequestError.
   * @param {string} field
   * @param {string} [msg]
   * @memberof AsyncRequestError
   */
  public constructor(msg?: string) {
    super(msg);
  }
}

/**
 * 
 * @export
 * @interface Error
 */
export interface Error {
    /**
     * 
     * @type {string}
     * @memberof Error
     */
    code: string;
    /**
     * 
     * @type {string}
     * @memberof Error
     */
    message: string;
    /**
     * 
     * @type {number}
     * @memberof Error
     */
    severity: number;
    /**
     * 
     * @type {Array<object>}
     * @memberof Error
     */
    childErrors?: Array<object>;
}/**
 * 
 * @export
 * @interface GetLocationsByZipCodeRequest
 */
export interface GetLocationsByZipCodeRequest {
    /**
     * 
     * @type {string}
     * @memberof GetLocationsByZipCodeRequest
     */
    codigoPostal: string;
}/**
 * 
 * @export
 * @interface GetLocationsByZipCodeResponse
 */
export interface GetLocationsByZipCodeResponse {
    /**
     * 
     * @type {Array<GetLocationsByZipCodeResponseData>}
     * @memberof GetLocationsByZipCodeResponse
     */
    data?: Array<GetLocationsByZipCodeResponseData>;
    /**
     * 
     * @type {Array<Error>}
     * @memberof GetLocationsByZipCodeResponse
     */
    errors?: Array<Error>;
}/**
 * 
 * @export
 * @interface GetLocationsByZipCodeResponseData
 */
export interface GetLocationsByZipCodeResponseData {
    /**
     * 
     * @type {string}
     * @memberof GetLocationsByZipCodeResponseData
     */
    nombrePoblacion?: string;
    /**
     * 
     * @type {string}
     * @memberof GetLocationsByZipCodeResponseData
     */
    codigoPoblacion?: string;
    /**
     * 
     * @type {number}
     * @memberof GetLocationsByZipCodeResponseData
     */
    poblacionTotal?: number;
    /**
     * 
     * @type {string}
     * @memberof GetLocationsByZipCodeResponseData
     */
    nombreProvincia?: string;
    /**
     * 
     * @type {number}
     * @memberof GetLocationsByZipCodeResponseData
     */
    superficiePoblacion?: number;
}

type getLocationsByZipCodeOperationParams = {
  getLocationsByZipCodeRequest: GetLocationsByZipCodeRequest,
};

/**
 * GetLocationsByZipCodeApi - axios parameter creator
 * @param {Configuration} [configuration]
 * @returns {any}
 */
const GetLocationsByZipCodeApiAxiosParamCreator = function(configuration?: Configuration): any {
  return {
    
    /**
     * Recupera ciudades en base al código postal recibido
     * @param {GetLocationsByZipCodeRequest} getLocationsByZipCodeRequest 
     * @param {string} [acceptLanguage] El Accept-Language request HTTP header indica en que lenguaje debe responder el backend.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @returns {RequestArgs}
     */
    getLocationsByZipCodeOperation(params: getLocationsByZipCodeOperationParams, options: any = {}): RequestArgs {
      // Verify required parameter 'getLocationsByZipCodeRequest' is not null or undefined
      if (params.getLocationsByZipCodeRequest === null || params.getLocationsByZipCodeRequest === undefined) {
        throw new RequiredError('getLocationsByZipCodeRequest', 'Required parameter getLocationsByZipCodeRequest was null or undefined when calling getLocationsByZipCodeOperation.');
      }
      const localVarPath = `/v1/persons/getLocationsByZipCodeOperation`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true) as Partial<globalImportUrl.UrlWithParsedQuery>;
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;


  
      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
      // Fix override query string Detail: https://stackoverflow.com/a/7517673/1077943

      delete localVarUrlObj.search;
      localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
      const needsSerialization = ('GetLocationsByZipCodeRequest' as any !== 'string') ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization ? JSON.stringify(params.getLocationsByZipCodeRequest || {}) : params.getLocationsByZipCodeRequest || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * GetLocationsByZipCodeApi - functional programming interface
 * @param {Configuration} [configuration]
 * @returns {any}
 */
const GetLocationsByZipCodeApiFp = function(configuration?: Configuration): any {
  return {
    
    /**
     * Recupera ciudades en base al código postal recibido
     * @param {GetLocationsByZipCodeRequest} getLocationsByZipCodeRequest 
     * @param {string} [acceptLanguage] El Accept-Language request HTTP header indica en que lenguaje debe responder el backend.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @returns {any}
     */
    getLocationsByZipCodeOperation(params: getLocationsByZipCodeOperationParams, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetLocationsByZipCodeResponse> {
      const localVarAxiosArgs = GetLocationsByZipCodeApiAxiosParamCreator(configuration).getLocationsByZipCodeOperation(params, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH): any => {
        const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * GetLocationsByZipCodeApi - factory interface
 * @param {Configuration} configuration
 * @param {string} basePath
 * @param {AxiosInstance} axios
 * @export
 * @returns {any}
 */
const GetLocationsByZipCodeApiFactory = function(configuration?: Configuration, basePath?: string, axios?: AxiosInstance): any {
  return {
    
    /**
     * Recupera ciudades en base al código postal recibido
     * @param {GetLocationsByZipCodeRequest} getLocationsByZipCodeRequest 
     * @param {string} [acceptLanguage] El Accept-Language request HTTP header indica en que lenguaje debe responder el backend.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @returns {any}
     */
    getLocationsByZipCodeOperation(params: getLocationsByZipCodeOperationParams, options?: any): any {
      return GetLocationsByZipCodeApiFp(configuration).getLocationsByZipCodeOperation(params, options)(axios, basePath);
    },
  };
};

/** 
 * GetLocationsByZipCodeApi - object-oriented interface
 * @export
 * @class GetLocationsByZipCodeApi
 * @extends {BaseAPI}
 */
export class GetLocationsByZipCodeApi extends BaseAPI {
  
  /**
   * Recupera ciudades en base al código postal recibido
   * @param {GetLocationsByZipCodeRequest} getLocationsByZipCodeRequest 
   * @param {string} [acceptLanguage] El Accept-Language request HTTP header indica en que lenguaje debe responder el backend.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @returns {any}
   * @memberof GetLocationsByZipCodeApi
   */
  public getLocationsByZipCodeOperation(params: getLocationsByZipCodeOperationParams, options?: any): any {
    return GetLocationsByZipCodeApiFp(this.configuration).getLocationsByZipCodeOperation(params, options)(this.axios, this.basePath);
  }

}

/**
 * 
 * @export
 * @class EAgetLocationsByZipCodeOperationInputMessage
 */
export class EAgetLocationsByZipCodeOperationInputMessage {
  
  /**
   * 
   * @type {GetLocationsByZipCodeRequest}
   * @memberof EAgetLocationsByZipCodeOperationInputMessage
   */
  public getLocationsByZipCodeRequest: GetLocationsByZipCodeRequest;

  public constructor(getLocationsByZipCodeRequest: GetLocationsByZipCodeRequest, ){
  
  this.getLocationsByZipCodeRequest=getLocationsByZipCodeRequest;
  } 
}



/** 
 * EAGetLocationsByZipCodeApi - Architecture client for getLocationsByZipCodeOperation
 * @export
 * @class EAGetLocationsByZipCodeApi
 */
export class EAGetLocationsByZipCodeApi {

  /**
   * 
   * @param {GetLocationsByZipCodeRequest} getLocationsByZipCodeRequest
   
   * @param {Configuration} configuration
   * @throws {AsyncRequestError | RequiredError}
   * @returns {Promise<GetLocationsByZipCodeResponse | null>}
  */ 
  public async getLocationsByZipCodeOperation(params: getLocationsByZipCodeOperationParams, configuration: Configuration = {}): Promise<GetLocationsByZipCodeResponse | null> {
    return this.getLocationsByZipCodeOperationPromise(params, configuration);
  }

  /**
   * 
   * @param {GetLocationsByZipCodeRequest} getLocationsByZipCodeRequest
   
   * @param {Configuration} configuration
   * @param {Function} callback
   * @throws {AsyncRequestError | RequiredError}
   */
  public async getLocationsByZipCodeOperationSteps(params: getLocationsByZipCodeOperationParams, configuration: Configuration, callback: (res: AxiosResponse<GetLocationsByZipCodeResponse | null>) => void) {
    let done: boolean = false;
    const httpClient: AxiosInstance = EASDKConfig.getHttpClient('/v1/persons/getLocationsByZipCodeOperation', 'getLocationsByZipCode', 'getLocationsByZipCodeOperation');
    const api = new GetLocationsByZipCodeApi(configuration, '', httpClient);
    const sleep = async(ms: number) => {
      return new Promise(resolve => setTimeout(resolve, ms));
    };
    const headers: { ['Async-Id']?: string } = {};
    while (!done) {
      const output = await api.getLocationsByZipCodeOperation(params, { headers }) as AxiosResponse<GetLocationsByZipCodeResponse | null>;
      callback(output);
      if (output.status === 202) {
        try {
          const retry = parseInt(output.headers['retry']);
          headers['Async-Id'] = output.headers['request-id'];
          if (isNaN(retry) || retry <= 0 || retry > 1000 * 3600) {
            throw new Error(`Retry time not acceptable (in milliseconds): ${retry}`);
          }
          await sleep(retry);
        } catch (err) {
          throw new Error(`Error in Async Request ${err}`);
        }
      } else {
        done = true;
      }
    }
  }

  /**
   * 
   * @param {GetLocationsByZipCodeRequest} getLocationsByZipCodeRequest
   
   * @param {Configuration} configuration
   * @throws {AsyncRequestError | RequiredError}
   * @returns {Promise<GetLocationsByZipCodeResponse | null>}
   */
  public async getLocationsByZipCodeOperationPromise(params: getLocationsByZipCodeOperationParams, configuration: Configuration): Promise<GetLocationsByZipCodeResponse | null> {
    return new Promise((resolve, reject) => {
      this.getLocationsByZipCodeOperationSteps(params, configuration, (response: AxiosResponse<GetLocationsByZipCodeResponse | null>) => {
        if (response.status !== 202) {
          resolve(response.data);
        }
      })
      .catch((err) => {
        reject(err);
      });
    });
  } 
}


