import { render, staticRenderFns } from "./show-policyholder-info-component.vue?vue&type=template&id=f8612f2a&scoped=true"
import script from "./show-policyholder-info-component.vue?vue&type=script&lang=ts"
export * from "./show-policyholder-info-component.vue?vue&type=script&lang=ts"
import style0 from "./show-policyholder-info-component.vue?vue&type=style&index=0&id=f8612f2a&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f8612f2a",
  null
  
)

export default component.exports