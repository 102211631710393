/* eslint-disable */

/**
 * zonazurich
 * Portal de Mediación Digital
 *
 * The version of the OpenAPI document: 1
 * Contact: digitalarchitecture@zurich.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import {
  EASDKConfig,
  EAUrlUtils
} from '@zurich-es-npm/ea-shared-utils-js';

import * as globalImportUrl from 'url';
import {
  Configuration
} from './configuration';
import globalAxios, {
  AxiosPromise,
  AxiosInstance,
  AxiosResponse
} from 'axios';

const BASE_PATH = EAUrlUtils.removeLastForwardSlash('http://localhost:3000');

/**
 *
 * @export
 */
export const COLLECTION_FORMATS = {
  csv: ',',
  ssv: ' ',
  tsv: '\t',
  pipes: '|',
};

/**
 *  
 * @export
 * @interface RequestArgs
 */
export interface RequestArgs {
  url: string;
  options: any;
}

/**
 * 
 * @export
 * @class BaseAPI
 */
export class BaseAPI {
  protected configuration: Configuration | undefined;

  /**
   *Creates an instance of BaseAPI.
   * @param {Configuration} [configuration]
   * @param {string} [basePath=BASE_PATH]
   * @param {AxiosInstance} [axios=globalAxios]
   * @memberof BaseAPI
   */
  public constructor(
    configuration?: Configuration,
    protected basePath: string = BASE_PATH,
    protected axios: AxiosInstance = globalAxios) {
    if (configuration) {
      this.configuration = configuration;
      this.basePath = configuration.basePath || this.basePath;
    }
  }
}

/**
 * 
 * @export
 * @class RequiredError
 * @extends {Error}
 */
export class RequiredError extends Error {
  public name: 'RequiredError' = 'RequiredError';

  public field: string;

  /**
   *Creates an instance of RequiredError.
   * @param {string} field
   * @param {string} [msg]
   * @memberof RequiredError
   */
  public constructor(field: string, msg?: string) {
    super(msg);
    this.field=field;
  }
}

/**
 * 
 * @export
 * @class AsyncRequestError
 * @extends {Error}
 */
export class AsyncRequestError extends Error {
  public name: 'AsyncRequestError' = 'AsyncRequestError';

  /**
   *Creates an instance of AsyncRequestError.
   * @param {string} field
   * @param {string} [msg]
   * @memberof AsyncRequestError
   */
  public constructor(msg?: string) {
    super(msg);
  }
}

/**
 * 
 * @export
 * @interface Error
 */
export interface Error {
    /**
     * 
     * @type {string}
     * @memberof Error
     */
    code: string;
    /**
     * 
     * @type {string}
     * @memberof Error
     */
    message: string;
    /**
     * 
     * @type {number}
     * @memberof Error
     */
    severity: number;
    /**
     * 
     * @type {Array<object>}
     * @memberof Error
     */
    childErrors?: Array<object>;
}/**
 * 
 * @export
 * @interface GetIbanRequest
 */
export interface GetIbanRequest {
    /**
     * 
     * @type {string}
     * @memberof GetIbanRequest
     */
    codigoFiliacion: string;
    /**
     * 
     * @type {string}
     * @memberof GetIbanRequest
     */
    numeroCuentaBanco?: string;
    /**
     * 
     * @type {string}
     * @memberof GetIbanRequest
     */
    codigoIBAN?: string;
}/**
 * 
 * @export
 * @interface GetIbanResponse
 */
export interface GetIbanResponse {
    /**
     * 
     * @type {GetIbanResponseData}
     * @memberof GetIbanResponse
     */
    data?: GetIbanResponseData;
    /**
     * 
     * @type {string}
     * @memberof GetIbanResponse
     */
    fechaAlta?: string;
    /**
     * 
     * @type {Array<Error>}
     * @memberof GetIbanResponse
     */
    errors?: Array<Error>;
}/**
 * 
 * @export
 * @interface GetIbanResponseData
 */
export interface GetIbanResponseData {
    /**
     * 
     * @type {Array<GetIbanResponseDataIbanList>}
     * @memberof GetIbanResponseData
     */
    ibanList?: Array<GetIbanResponseDataIbanList>;
    /**
     * 
     * @type {number}
     * @memberof GetIbanResponseData
     */
    numeroDeRegistros?: number;
}/**
 * 
 * @export
 * @interface GetIbanResponseDataIbanList
 */
export interface GetIbanResponseDataIbanList {
    /**
     * 
     * @type {string}
     * @memberof GetIbanResponseDataIbanList
     */
    numeroCuentaBanco?: string;
    /**
     * 
     * @type {string}
     * @memberof GetIbanResponseDataIbanList
     */
    nombreTitular?: string;
    /**
     * 
     * @type {string}
     * @memberof GetIbanResponseDataIbanList
     */
    nombreCotitular?: string;
    /**
     * 
     * @type {string}
     * @memberof GetIbanResponseDataIbanList
     */
    tiempoUltimaModificacion?: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetIbanResponseDataIbanList
     */
    indicadorCuentaPrincipal?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetIbanResponseDataIbanList
     */
    codigoIBAN?: string;
}

type getIbanOperationParams = {
  getIbanRequest: GetIbanRequest,
};

/**
 * GetIbanApi - axios parameter creator
 * @param {Configuration} [configuration]
 * @returns {any}
 */
const GetIbanApiAxiosParamCreator = function(configuration?: Configuration): any {
  return {
    
    /**
     * Recupera la lista de los iban a partir de codigo filiación
     * @param {GetIbanRequest} getIbanRequest 
     * @param {string} [acceptLanguage] El Accept-Language request HTTP header indica en que lenguaje debe responder el backend.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @returns {RequestArgs}
     */
    getIbanOperation(params: getIbanOperationParams, options: any = {}): RequestArgs {
      // Verify required parameter 'getIbanRequest' is not null or undefined
      if (params.getIbanRequest === null || params.getIbanRequest === undefined) {
        throw new RequiredError('getIbanRequest', 'Required parameter getIbanRequest was null or undefined when calling getIbanOperation.');
      }
      const localVarPath = `/v1/quoteAndBuy/getIbanOperation`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true) as Partial<globalImportUrl.UrlWithParsedQuery>;
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;


  
      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
      // Fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
      const needsSerialization = ('GetIbanRequest' as any !== 'string') ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization ? JSON.stringify(params.getIbanRequest || {}) : params.getIbanRequest || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * GetIbanApi - functional programming interface
 * @param {Configuration} [configuration]
 * @returns {any}
 */
const GetIbanApiFp = function(configuration?: Configuration): any {
  return {
    
    /**
     * Recupera la lista de los iban a partir de codigo filiación
     * @param {GetIbanRequest} getIbanRequest 
     * @param {string} [acceptLanguage] El Accept-Language request HTTP header indica en que lenguaje debe responder el backend.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @returns {any}
     */
    getIbanOperation(params: getIbanOperationParams, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetIbanResponse> {
      const localVarAxiosArgs = GetIbanApiAxiosParamCreator(configuration).getIbanOperation(params, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH): any => {
        const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * GetIbanApi - factory interface
 * @param {Configuration} configuration
 * @param {string} basePath
 * @param {AxiosInstance} axios
 * @export
 * @returns {any}
 */
const GetIbanApiFactory = function(configuration?: Configuration, basePath?: string, axios?: AxiosInstance): any {
  return {
    
    /**
     * Recupera la lista de los iban a partir de codigo filiación
     * @param {GetIbanRequest} getIbanRequest 
     * @param {string} [acceptLanguage] El Accept-Language request HTTP header indica en que lenguaje debe responder el backend.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @returns {any}
     */
    getIbanOperation(params: getIbanOperationParams, options?: any): any {
      return GetIbanApiFp(configuration).getIbanOperation(params, options)(axios, basePath);
    },
  };
};

/** 
 * GetIbanApi - object-oriented interface
 * @export
 * @class GetIbanApi
 * @extends {BaseAPI}
 */
export class GetIbanApi extends BaseAPI {
  
  /**
   * Recupera la lista de los iban a partir de codigo filiación
   * @param {GetIbanRequest} getIbanRequest 
   * @param {string} [acceptLanguage] El Accept-Language request HTTP header indica en que lenguaje debe responder el backend.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @returns {any}
   * @memberof GetIbanApi
   */
  public getIbanOperation(params: getIbanOperationParams, options?: any): any {
    return GetIbanApiFp(this.configuration).getIbanOperation(params, options)(this.axios, this.basePath);
  }

}

/**
 * 
 * @export
 * @class EAgetIbanOperationInputMessage
 */
export class EAgetIbanOperationInputMessage {
  
  /**
   * 
   * @type {GetIbanRequest}
   * @memberof EAgetIbanOperationInputMessage
   */
  public getIbanRequest: GetIbanRequest;

  public constructor(getIbanRequest: GetIbanRequest, ){
  
  this.getIbanRequest=getIbanRequest;
  } 
}



/** 
 * EAGetIbanApi - Architecture client for getIbanOperation
 * @export
 * @class EAGetIbanApi
 */
export class EAGetIbanApi {

  /**
   * 
   * @param {GetIbanRequest} getIbanRequest
   
   * @throws {AsyncRequestError | RequiredError}
   * @returns {Promise<GetIbanResponse | null>}
  */ 
  public async getIbanOperation(params: getIbanOperationParams): Promise<GetIbanResponse | null> {
    return this.getIbanOperationPromise(params);
  }

  /**
   * 
   * @param {GetIbanRequest} getIbanRequest
   
   * @param {Function} callback
   * @throws {AsyncRequestError | RequiredError}
   */
  public async getIbanOperationSteps(params: getIbanOperationParams, callback: (res: AxiosResponse<GetIbanResponse | null>) => void) {
    let done: boolean = false;
    const httpClient: AxiosInstance = EASDKConfig.getHttpClient('/v1/quoteAndBuy/getIbanOperation', 'getIban', 'getIbanOperation');
    const config: Configuration = {};
    const api = new GetIbanApi(config, '', httpClient);
    const sleep = async(ms: number) => {
      return new Promise(resolve => setTimeout(resolve, ms));
    };
    const headers: { ['Async-Id']?: string } = {};
    while (!done) {
      const output = await api.getIbanOperation(params, { headers }) as AxiosResponse<GetIbanResponse | null>;
      callback(output);
      if (output.status === 202) {
        try {
          const retry = parseInt(output.headers['retry']);
          headers['Async-Id'] = output.headers['request-id'];
          if (isNaN(retry) || retry <= 0 || retry > 1000 * 3600) {
            throw new Error(`Retry time not acceptable (in milliseconds): ${retry}`);
          }
          await sleep(retry);
        } catch (err) {
          throw new Error(`Error in Async Request ${err}`);
        }
      } else {
        done = true;
      }
    }
  }

  /**
   * 
   * @param {GetIbanRequest} getIbanRequest
   
   * @throws {AsyncRequestError | RequiredError}
   * @returns {Promise<GetIbanResponse | null>}
   */
  public async getIbanOperationPromise(params: getIbanOperationParams): Promise<GetIbanResponse | null> {
    return new Promise((resolve, reject) => {
      this.getIbanOperationSteps(params, (response: AxiosResponse<GetIbanResponse | null>) => {
        if (response.status !== 202) {
          resolve(response.data);
        }
      })
      .catch((err) => {
        reject(err);
      });
    });
  } 
}


