<template>
  <ea-dialog
    v-if="modalData"
    :title="modalData.title"
    :visible="isVisible"
    :beforeClose="onClose"
    type="recognition"
    @button-click="onClose()"
  >
    <div slot="default">
      <p v-if="modalData.message">{{ modalData.message }}</p>
      <div v-else-if="modalData.errors && modalData.errors.length > 0">
        <p v-for="(error, index) in modalData.errors" :key="index">
          {{ error.message }}
        </p>
      </div>
      <div v-else-if="modalData.messages && modalData.messages.length > 0">
        <p v-for="(message, index) in modalData.messages" :key="index">
          {{ message }}
        </p>
      </div>
    </div>
  </ea-dialog>
</template>

<script lang="ts">
import {
  Component, Prop
} from 'vue-property-decorator';
import {
  mixins
} from 'vue-class-component';
import {
  EABusinessComponent
} from '@zurich-es-npm/ea-front-web-core';
import QbGenericErrorModel from './qb-generic-error-model';

export interface GenericErrorData {
  title?: string;
  message?: string;
  messages?: string[];
  errors?: {
    message: string;
  }[];
  changeStepOnClose?: number; // Step to go when dialog is closed
  showStepOnClose?: number; // Step to show when dialog is closed
  closeAction?: Function;
}

@Component({
  name: 'qb-generic-error',
})

/**
 * Business Component qb-generic-error
 */
export default class QbGenericErrorBusiness extends mixins<EABusinessComponent<QbGenericErrorModel>>(
  EABusinessComponent
) {

  @Prop()
  public modalData!: GenericErrorData;

  /**
   * Reactive variable. Needs to be initialised in parent component
   * Modal will hide/show everytime isVisible variable changes
   */
  @Prop({
    required: false,
    'default': () => false,
  })
    isVisible?: boolean;

  /**
   * Closes the dialog
   */
  onClose() {
    this.$emit('close');
  }
}
</script>
