<template>
  <div class="m-b-16">
    <ea-card>
      <div slot="cardbody" class="p-a-16">
        <h3>{{ $t('attachDocumentation.title') }}</h3>

        <ea-form ref="form"
          :model="model"
        >
          <ea-row>
            <ea-col :span="6">
              <ea-form-item
                prop="documentType"
                :label="$t('attachDocumentation.fields.documentType')"
                required
              >
                <ea-select
                  v-model="model.documentType"
                  :placeholder="$t('common.label.select')"
                  @change="onDocumentTypeChange"
                >
                  <ea-option
                    v-for="documentType in corpTableDocumentsDocumentType"
                    :key="documentType.documentType"
                    :label="documentType.documentAssociatedTypeDescription"
                    :value="documentType.documentType"
                  />
                </ea-select>
              </ea-form-item>
            </ea-col>

            <ea-col :span="6">
              <ea-form-item
                v-if="model.documentType && filteredCorpTableDocumentsDocumentSubtype.length !== 0"
                prop="documentSubtype"
                :label="$t('attachDocumentation.fields.documentSubtype')"
                required
              >
                <ea-select
                  v-model="model.documentSubtype"
                  :placeholder="$t('common.label.select')"
                >
                  <ea-option
                    v-for="documentSubtype in filteredCorpTableDocumentsDocumentSubtype"
                    :key="documentSubtype.documentSubtypeCode"
                    :label="documentSubtype.documentSubtypeDescription"
                    :value="documentSubtype.documentSubtypeCode"
                  />
                </ea-select>
              </ea-form-item>
            </ea-col>
          </ea-row>
        </ea-form>

        <div class="d-display-flex d-justify-center d-align-items-center d-direction-column">
          <ea-upload
            v-model="model.documentsToUpload"
            class="w-50"
            :drag="true"
            :limit="5"
            :maxFileSize="20000000"
            accept="image/jpeg,image/bmp,image/png,image/tiff,application/msword,text/plain,
              application/pdf,application/vnd.ms-excel,
              application/vnd.openxmlformats-officedocument.wordprocessingml.document,
              application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,
              application/vnd.ms-powerpoint,
              application/vnd.openxmlformats-officedocument.presentationml.presentation,
              application/rtf,text/html,video/x-msvideo,video/x-matroska,video/mp4,"
            :description="$t('attachDocumentation.extraInfo')"
          >
            <div slot="text">
              <p class="">
                {{ $t('attachDocumentation.dragItemsTittle') }}
                <br>
                {{ $t('attachDocumentation.uploadDocumentsButton') }}
              </p>
            </div>
            <div slot="error">
              <p class="">{{ $t('attachDocumentation.errorMessage') }}</p>
              <p class="tooltip__extra-info">{{ $t('attachDocumentation.extraInfo') }}</p>
            </div>
            <div slot="uploading">
              <p class="">{{ $t('attachDocumentation.uploadingFiles') }}</p>
            </div>
            <div slot="success">
              <p class="">{{ $t('attachDocumentation.fileUploaded') }}</p>
            </div>
          </ea-upload>

          <ea-button
            :disabled="!model.documentsToUpload.length"
            class="m-t-24"
            type="primary"
            @click="onAttachDocumentation()"
          >
            {{ $t('attachDocumentation.attachDocumentation') }}
          </ea-button>
        </div>
      </div>
    </ea-card>
  </div>
</template>

<script lang="ts">
import {
  Component, Prop
} from 'vue-property-decorator';

import {
  mixins
} from 'vue-class-component';

import {
  EAApplicationError,
  EAApplicationLogger,
  EABusinessComponent,
  EAError,
  EAMethod,
  EAMultiError,
  ResponseWithErrors,
  throwIfResponseHasErrors
} from '@zurich-es-npm/ea-front-web-core';

import AttachDocumentationModel from './attach-documentation-model';
import {
  CorpTableData,
  CorpTableNames,
  fetchCorporateTable
} from '@/utils/corporate-tables';
import Utils from '@/utils/utils';
import {
  EAGetDocumentUploadLinkApi, GetDocumentUploadLinkResponseEnlacesCarga
} from '@/services/V1/common/getDocumentUploadLinkOperation/post';
import {
  EAIndexDocumentUploadApi,
  IndexDocumentUploadRequestGrupoDocumentoEnum as GrupoDocumentoEnum
} from '@/services/V1/common/indexDocumentUploadOperation/post';
import {
  padStart
} from 'lodash';
import {
  EAValidation, Form
} from '@zurich-es-npm/ea-front-web-ui';
import {
  NotificationsUtils
} from '@/utils/notifications/notifications-utils';

interface AttachDocumentationTypeData {
  documentaryGroupCode: string;
  documentType: string;
  documentAssociatedTypeDescription: string;
  lopdManagementIndicator: string;
  defaultSecurityLevel: string;
}

interface AttachDocumentationSubTypeData {
  documentaryGroupCode: string;
  documentSubtypeCode: string;
  documentSubtypeDescription: string;
  lopdManagementIndicator: string;
  defaultSecurityLevel: string;
}

@Component({
  name: 'attach-documentation'
})

/**
 * Business Component attach-documentation
 */
export default class AttachDocumentationBusiness extends mixins<
EABusinessComponent<AttachDocumentationModel>
>(EABusinessComponent) {

  @Prop({
    required: true
  })
  public policyNumber!: string;
  
  @Prop({
    required: true
  })
  public policyVersion!: number;

  public corpTableDocumentsDocumentType: AttachDocumentationTypeData[] = [];

  public corpTableDocumentsDocumentSubtype: AttachDocumentationSubTypeData[] = [];

  public filteredCorpTableDocumentsDocumentSubtype: AttachDocumentationSubTypeData[] = [];

  /**
   * Hook on created.
   *
   * @returns {Promise<void>}
   */
  public async created(): Promise<void> {
    await this.fetchDropdownData();
  }

  /**
   * Return form validation object
   * @returns {EAValidation}
   */
  public validation(): EAValidation {
    const form: Form = this.$refs.form as Form;
    return form.validation();
  }

  /**
   * Fetch documentType and country dropdown data
   * @returns {Promise<void>}
   */
  @EAMethod({
    loading: true,
  })
  public async fetchDropdownData(): Promise<void> {
    try {
      const promises = [
        fetchCorporateTable(CorpTableNames.AttachDocumentTypes),
        fetchCorporateTable(CorpTableNames.AttachDocumentSubtypes),
      ];
      const results = await Promise.all(promises);

      this.corpTableDocumentsDocumentType = this.parseCorpTableDocumentsDocumentType(results[0]).filter(
        element => element.documentaryGroupCode === 'POL'
      );
      this.corpTableDocumentsDocumentSubtype = this.parseCorpTableDocumentsDocumentSubtype(results[1]);
    } catch (err) {
      const loggerErrorMessages: string[] = [];
      let errors: EAError[] = [];

      // eslint-disable-next-line no-extra-parens
      if (typeof (err as any).getErrorList === 'function') {
        // eslint-disable-next-line no-extra-parens
        errors = (err as EAMultiError).getErrorList() as EAError[];
      } else {
        errors = [err as EAError];
      }

      errors.forEach(error => {
        loggerErrorMessages.push(`AttachDocumentationBusiness::fetchDropdownData ${error.message}`);
      });

      const logger = new EAApplicationLogger();
      loggerErrorMessages.forEach(message => {
        logger.error(message);
      });

      throw new EAApplicationError(
        this.$t('attachDocumentation.errorRetrievingCorpTables').toString(), loggerErrorMessages
      );
    }
  }

  /**
   * Parses document type corp data
   * @param { CorpTableData } corpTableData
   * @returns { AttachDocumentationTypeData[] }
   */
  parseCorpTableDocumentsDocumentType(
    corpTableData: CorpTableData
  ): AttachDocumentationTypeData[] {
    const parsedDocs = corpTableData.data.tableDocuments.map(
      elem => {
        return {
          documentaryGroupCode: elem.TCCDGDOC,
          documentType: elem.TCTIPDOC,
          documentAssociatedTypeDescription: elem.DSTIPO,
          lopdManagementIndicator: elem.INGELOPD,
          defaultSecurityLevel: elem.CDNIVSDF,
        };
      }
    );
    return Utils.sortObjectArrayByProperty(parsedDocs, 'documentAssociatedTypeDescription');
  }

  /**
   * Parses document subtype corp data
   * @param { CorpTableData } corpTableData
   * @returns { AttachDocumentationTypeData[] }
   */
  parseCorpTableDocumentsDocumentSubtype(
    corpTableData: CorpTableData
  ): AttachDocumentationSubTypeData[] {
    const parsedDocs = corpTableData.data.tableDocuments.map(
      elem => {
        return {
          documentaryGroupCode: elem.TCTIPDOC,
          documentSubtypeCode: elem.TCSUBTIP,
          documentSubtypeDescription: elem.DSSUBTIP,
          lopdManagementIndicator: elem.INGELOPD,
          defaultSecurityLevel: elem.CDNIVSDF,
        };
      }
    );
    return Utils.sortObjectArrayByProperty(parsedDocs, 'documentSubtypeDescription');
  }

  /**
   * Handles document type change
   * Filters subtype corp table based on selected type
   * @param {string} documentType
   */
  public onDocumentTypeChange(documentType: string) {
    this.model.documentSubtype = '';
    this.filteredCorpTableDocumentsDocumentSubtype = this.corpTableDocumentsDocumentSubtype.filter(
      element => element.documentaryGroupCode === documentType
    );
  }

  /**
   * Handles attach documentation button
   */
  @EAMethod({
    loading: true
  })
  public async onAttachDocumentation() {
    this.clearFlowErrors();
    NotificationsUtils.clearNotifications();

    try {
      await this.validation().validate();

      const uploadLinks = await this._getUploadLink();

      if (uploadLinks) {
        await this._uploadFiles(uploadLinks);
        await this._indexDocuments();
      }

      this.clearDataForm();
      this.$emit('reloadDocumentation');
    } catch (err) {
      // Design system will show form errors
      const eaError = err as EAError;
      new EAApplicationLogger().error(
        `AttachDocumentationBusiness::getDocumentUploadLinkOperation:: ${eaError.message}`
      );
      throw err;
    }
  }


  /**
   * Clear the model data
   */
  public clearDataForm(): void {
    this.model.documentType = '';
    this.model.documentSubtype = '';
    this.model.documentsToUpload = [];
  }

  /**
   * Calls bff for getting upload link
   * @returns {Promise<GetDocumentUploadLinkResponseEnlacesCarga[] | undefined>}
   */
  public async _getUploadLink(): Promise<GetDocumentUploadLinkResponseEnlacesCarga[] | undefined> {
    const api = new EAGetDocumentUploadLinkApi();
    const output = await api.getDocumentUploadLinkOperation({
      getDocumentUploadLinkRequest: {
        documentos: this.model.documentsToUpload.map(
          document => {
            return {
              nombreDocumento: document.name
            };
          }
        )
      }
    });

    if (output) {
      throwIfResponseHasErrors(output as ResponseWithErrors);
      return output.enlacesCarga;
    }
  }

  /**
   * Calls Utils auxiliar method for uploading documents
   * @param {GetDocumentUploadLinkResponseEnlacesCarga[]} uploadLinks 
   */
  public async _uploadFiles(
    uploadLinks: GetDocumentUploadLinkResponseEnlacesCarga[]
  ) {
    await Utils.uploadFiles(
      this.model.documentsToUpload, uploadLinks
    );
  }

  /**
   * Indexes documents
   * @param {GetDocumentUploadLinkResponseEnlacesCarga[]} uploadLinks 
   */
  public async _indexDocuments() {
    const idObjetoNegocio = this.policyNumber && this.policyVersion !== undefined ?
      `${this.policyNumber}${padStart(this.policyVersion.toString(10), 6, '0')}` : '';

    
    const api = new EAIndexDocumentUploadApi();
    const output = await api.indexDocumentUploadOperation({
      indexDocumentUploadRequest: {
        tipoDocumentoUpload: this.model.documentType,
        subtipoDocumentoUpload: this.model.documentSubtype,
        grupoDocumento: GrupoDocumentoEnum.POL,
        idObjetoNegocio
      }
    });

    if (output) {
      throwIfResponseHasErrors(output as ResponseWithErrors);
    }
  }

}
</script>
