import {
  GetPersonAddressesResponseDomicilios
} from '@/services/V1/persons/getPersonAddressesOperation/post';
import MainAddressChangeModel from '../main-address-change/main-address-change-model';

export interface EmailEditionFormModel{
  email?: string;
  emailType?: string;
}

/**
 * Model add-address-tomador
 */
class AddEmailTomadorModel {
  public emails: GetPersonAddressesResponseDomicilios[] = [];

  public selectedEmailCode?: string = '';

  public emailInEditionIdx: number | undefined = undefined;

  public emailEditionForm: EmailEditionFormModel = {
    emailType: '',
    email: '',
  };

  public isAddingEditting: boolean = false;

  public mainAddressChangeModel: MainAddressChangeModel = new MainAddressChangeModel();
}

export default AddEmailTomadorModel;
