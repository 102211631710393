<template>
  <div>
    <ea-dialog
      :visible="!hidden"
      :beforeClose="onCancel"
      size="large"
      height="75vh">
      <!-- Pagador -->
      <zz-client-basic-info
        id="showClientData"
        class="m-b-40"
        :titleLabelOverride="'common.roles.pagador'"
        :documentationClientData="documentationClientData"
      ></zz-client-basic-info>

      <ea-row extraClass="m-l-0 m-r-0">
        <ea-col :span="12" extraClass="p-l-0 p-r-0">
          <!-- Domiciliaciones bancarias -->
          <ea-heading level="6" class="m-b-0">{{ $t('quoteBuyGenericFlow.ibanCode.ibanListTitle') }}</ea-heading>
          <a href="#" @click="onAddNewIbanClick" class="t-weight-semibold t-color-primary cursor-pointer">
            {{ $t('quoteBuyGenericFlow.ibanCode.addNewIban') }}
          </a>
          <p v-if="ibanList && ibanList.length === 0" class="t-weight-normal m-t-32">
            {{ $t('quoteBuyGenericFlow.ibanCode.noIbanListItems') }}
          </p>
          <ea-radio-group v-else class="m-t-32" @change="onRadioChange" v-model="model.selectedIbanCode">
            <ea-radio
              v-for="(ibanData, index) in ibanList"
              :label="ibanData.codigoIBAN"
              class="m-x-8"
              :key="index"
            >
              <span>{{ibanData.codigoIBAN}} {{ibanData.nombreTitular}} {{ibanData.nombreCotitular}}</span>
              <ea-icon
                v-if="ibanData.indicadorCuentaPrincipal"
                class="m-x-8"
                icon="z-star-solid"/>
              <ea-button-icon
                @click="onUpdateIbanClick(ibanData)"
                icon="z-edit"
                class="m-l-8"/>
            </ea-radio>
          </ea-radio-group>
        </ea-col>
        <ea-col :span="11" v-if="addNewIbanMode || updateIbanMode">
          <!-- Añadir / Modificar cuenta bancaria -->
          <h4 class="m-b-0" v-if="addNewIbanMode">{{ $t('quoteBuyGenericFlow.ibanCode.addNewIbanTitle') }}</h4>
          <h4 class="m-b-0" v-if="updateIbanMode">{{ $t('quoteBuyGenericFlow.ibanCode.updateIbanTitle') }}</h4>
          <ea-form
            ref="form"
            :model="ibanForm"
            :validationOptions="formValidationOptions"
            :validateOnRuleChange="false">
            <ea-row>
              <ea-col>
                <ea-form-item prop="primaryAccountIndicator">
                  <ea-checkbox
                    v-model="ibanForm.primaryAccountIndicator"
                    :checked="ibanForm.primaryAccountIndicator"
                    class="m-t-4 m-b-0"
                    :label="$t('quoteBuyGenericFlow.ibanCode.primaryAccountIndicatorCheckboxLabel')"
                    @change="onChangePrimaryAccountCheckbox"
                    :disabled="ibanForm.primaryAccountIndicator"
                  />
                </ea-form-item>
              </ea-col>
              <ea-col>
                <ea-form-item prop="iban" :label="$t('quoteBuyGenericFlow.ibanCode.ibanLabel')" required>
                  <ea-input-text v-model="ibanForm.iban" :readonly="updateIbanMode"/>
                </ea-form-item>
              </ea-col>
              <ea-col :span="12">
                <ea-form-item
                  prop="primaryHolder"
                  :label="$t('quoteBuyGenericFlow.ibanCode.primaryHolderLabel')"
                  required
                >
                  <ea-input-text v-model="ibanForm.primaryHolder" />
                </ea-form-item>
              </ea-col>
              <ea-col :span="12">
                <ea-form-item prop="secondaryHolder" :label="$t('quoteBuyGenericFlow.ibanCode.secondaryHolderLabel')">
                  <ea-input-text v-model="ibanForm.secondaryHolder" />
                </ea-form-item>
              </ea-col>
            </ea-row>
          </ea-form>
        </ea-col>
      </ea-row>
      <div slot="footer" class="d-display-flex d-justify-flex-end">
        <!-- Cancelar / Selecionar / Guardar -->
        <ea-button type="secondary" @click="onCancel()" class="m-r-16">
          {{ $t('common.label.cancel') }}
        </ea-button>
        <ea-button type="primary" @click="onSelect()" v-if="!addNewIbanMode && !updateIbanMode">
          {{ $t('common.label.select') }}
        </ea-button>
        <ea-button type="primary" @click="onSave()" v-if="addNewIbanMode">
          {{ $t('common.label.save') }}
        </ea-button>
        <ea-button type="primary" @click="onUpdate()" v-if="updateIbanMode">
          {{ $t('common.label.save') }}
        </ea-button>
      </div>
    </ea-dialog>

    <ea-dialog
      :visible="!primaryAccountChangeModalHidden"
      :beforeClose="onCancelPrimaryAccountChange"
      size="small"
      :title="$t('quoteBuyGenericFlow.ibanCode.primaryAccountChangeModal.title')"
    >
      <!-- Cambio de cuenta principal -->
      <p class="m-b-40"> {{ $t('quoteBuyGenericFlow.ibanCode.primaryAccountChangeModal.description') }} </p>
      <div class="d-display-flex d-justify-flex-end">
        <ea-button type="secondary" @click="onCancelPrimaryAccountChange()" class="m-r-16">
          {{ $t('common.label.cancel') }}
        </ea-button>
        <ea-button type="primary" @click="onAcceptPrimaryAccountChange()">
          {{ $t('common.label.accept') }}
        </ea-button>
      </div>
    </ea-dialog>
  </div>
</template>

<script lang="ts">
import {
  Component, Prop
} from 'vue-property-decorator';

import {
  mixins
} from 'vue-class-component';

import {
  EABusinessComponent, EAMethod
} from '@zurich-es-npm/ea-front-web-core';

import QbIbanCodeModalModel from './qb-iban-code-modal-model';
import {
  GetIbanResponseDataIbanList
} from '@/services/V1/quoteAndBuy/getIbanOperation/post';
import {
  EASaveIbanApi
} from '@/services/V1/quoteAndBuy/saveIbanOperation/post';
import {
  EAUpdateIbanApi
} from '@/services/V1/quoteAndBuy/updateIbanOperation/post';
import {
  EAFormValidationOptions,
  eaPatternValidation,
  EAValidation,
  Form,
} from '@zurich-es-npm/ea-front-web-ui';
import {
  GenericErrorData
} from '../qb-generic-error/qb-generic-error-business.vue';
import ZzClientBasicInfo from '@/presentational-components/clientBasicInfo/clientBasicInfo.vue';
import {
  DocumentationClientData
} from '@/presentational-components/clientBasicInfo/clientBasicInfo.type';

@Component({
  name: 'qb-iban-code-modal',
  components: {
    'zz-client-basic-info': ZzClientBasicInfo
  }
})

/**
 * Business Component qb-iban-code-modal
 */
export default class QbIbanCodeModalBusiness extends mixins<EABusinessComponent<QbIbanCodeModalModel>>(
  EABusinessComponent
) {
  @Prop()
    documentationClientData!: DocumentationClientData;

  @Prop()
    ibanList?: GetIbanResponseDataIbanList[];

  @Prop({
    required: false,
    'default': () => true,
  })
    hidden?: boolean;

  ibanForm = {
    primaryAccountIndicator: false,
    iban: '',
    primaryHolder: '',
    secondaryHolder: ''
  };

  primaryAccountChangeModalHidden = true;

  addNewIbanMode = false;

  updateIbanMode = false;

  primaryAccountChangeAccepted = false;

  ibanRegExp: RegExp = /^[a-zA-Z0-9 ]{14,34}$/;
  
  /**
   *
   */
  get primaryAccountExists() {
    return this.ibanList?.find(iban => iban.indicadorCuentaPrincipal === true)?.indicadorCuentaPrincipal || false;
  }

  /**
   * Returns form validation object.
   *
   * @returns {EAValidation} Form validation object
   */
  public validation(): EAValidation {
    const form: Form = this.$refs.form as Form;
    return form.validation();
  }

  /**
   * Execute form validation.
   *
   * @returns {Promise<void>}
   */
  @EAMethod()
  public async doValidation(): Promise<void> {
    await this.validation().validate();
  }

  /**
   * Handles add new uiban text link
   */
  @EAMethod()
  onAddNewIbanClick() {
    this.resetFields();

    this.ibanForm.primaryAccountIndicator = !this.primaryAccountExists;

    this.addNewIbanMode = true;
    this.updateIbanMode = false;
  }

  /**
   * Handles radio button change
   */
  @EAMethod()
  onRadioChange(): void {
    this.addNewIbanMode = false;
    this.updateIbanMode = false;
  }

  /**
   * Handles cancel button
   * @param { GetIbanResponseDataIbanList } ibanData
   */
  @EAMethod()
  onUpdateIbanClick(ibanData: GetIbanResponseDataIbanList) {
    this.resetFields();
    this.updateIbanMode = true;
    this.addNewIbanMode = false;

    this.ibanForm.iban = ibanData.codigoIBAN as string;
    this.ibanForm.primaryAccountIndicator = ibanData.indicadorCuentaPrincipal as boolean;
    this.ibanForm.primaryHolder = ibanData.nombreTitular as string;
    this.ibanForm.secondaryHolder = ibanData.nombreCotitular as string;
    this.model.selectedIbanCode = ibanData.codigoIBAN as string;
  }

  /**
   * Handles cancel button
   */
  @EAMethod()
  onCancel(): void {
    this.resetFields();
    this.closeModal();
  }

  /**
   * Handles select button
   */
  @EAMethod()
  onSelect(): void {
    this.resetFields();
    this.closeModal();
    this.updateParentModel();
  }
  
  /**
   * Handles save button
   */
  @EAMethod({
    loading: true,
  })
  async onSave(): Promise<void> {
    this.clearFlowErrors();
    
    if (!this.filiationCode) {
      return;
    }

    await this.doValidation();
  
    const api = new EASaveIbanApi();
    const codigoIBanNuevo = this.ibanForm.iban.replace(/\s/g, '');
    
    try {
      const saveIbanApiResponse = await api.saveIbanOperation({
        saveIbanRequest: {
          codigoFiliacion: this.filiationCode,
          codigoIBanNuevo,
          indicadorCuentaPrincipal: this.ibanForm.primaryAccountIndicator,
          nombreTitular: this.ibanForm.primaryHolder,
          nombreCotitular: this.ibanForm.secondaryHolder,
        },
      });

      if (!saveIbanApiResponse) {
        return;
      }

      if (saveIbanApiResponse.errors?.length) {
        const genericErrorData: GenericErrorData = {
          title: 'Error',
          errors: saveIbanApiResponse.errors,
        };
        this.$emit('showError', genericErrorData);
      } else if (saveIbanApiResponse.data) {
        this.resetFields();
        this.closeModal();
        this.$emit('recallGetIbanList', codigoIBanNuevo);
      }
    } catch (error) {
      this.$emit('handleGenericError', {
        error,
        errorCode: 'ZON00081',
      });
    }
  }

  /**
   * Handles save (while in edit mode) button
   */
  @EAMethod({
    loading: true,
  })
  async onUpdate(): Promise<void> {
    this.clearFlowErrors();
    
    if (this.filiationCode) {
      this.doValidation();
      this.update();

      const api = new EAUpdateIbanApi();
      const codigoIBanNuevo = this.ibanForm.iban.replace(/\s/g, '');

      const updateIbanApiResponse = await api.updateIbanOperation({
        updateIbanRequest: {
          codigoFiliacion: this.filiationCode,
          codigoIBAN: codigoIBanNuevo,
          indicadorCuentaPrincipal: this.ibanForm.primaryAccountIndicator,
          nombreTitular: this.ibanForm.primaryHolder,
          nombreCotitular: this.ibanForm.secondaryHolder,
        },
      });

      if (!updateIbanApiResponse) {
        return;
      }

      if (updateIbanApiResponse.errors) {
        const genericErrorData: GenericErrorData = {
          title: 'Error',
          errors: updateIbanApiResponse.errors,
        };
        this.$emit('showError', genericErrorData);
      }

      if (updateIbanApiResponse.data) {
        this.resetFields();
        this.closeModal();
        this.$emit('recallGetIbanList', codigoIBanNuevo);
      }
    }
  }

  /**
   * Resets the modal fields
   */
  @EAMethod()
  resetFields(): void {
    this.ibanForm.iban = '';
    this.ibanForm.primaryAccountIndicator = false;
    this.ibanForm.primaryHolder = '';
    this.ibanForm.secondaryHolder = '';
  }

  /**
   * Closes the modal fields
   */
  @EAMethod()
  closeModal(): void {
    this.addNewIbanMode = false;
    this.updateIbanMode = false;
    this.$emit('closeModal');
  }

  /**
   * Handles the accept button of the primary account modal
   */
  @EAMethod()
  onAcceptPrimaryAccountChange(): void {
    this.ibanForm.primaryAccountIndicator = true;
    this.primaryAccountChangeModalHidden = true;
  }

  /**
   * Handles the cancel button of the primary account modal
   */
  @EAMethod()
  onCancelPrimaryAccountChange(): void {
    this.ibanForm.primaryAccountIndicator = false;
    this.primaryAccountChangeModalHidden = true;
  }

  /**
   * Checks if the primary account checkbox has a truthy value
   * 
   * @param {boolean} checked
   */
  @EAMethod()
  onChangePrimaryAccountCheckbox(checked: boolean): void {
    if (checked && this.ibanList && this.ibanList.length > 0) {
      this.primaryAccountChangeModalHidden = false;
    }
  }

  /**
   * Getter for selectedPerson's filliation code
   * @returns {string}
   */
  get filiationCode(): string | undefined {
    return this.documentationClientData.clientData?.datosBasicosPersona?.codigoFiliacion;
  }

  /**
   * Updates parent component model
   */
  public updateParentModel(): void {
    this.update();
    this.$nextTick(() => {
      this.$emit('updateParentModel');
    });
  }

  /**
   * Getter for form validation object
   * Iban validation is empty if updating as only primer titular and segundo titular can be updated
   */
  get formValidationOptions(): EAFormValidationOptions {
    return {
      rules: {
        iban: this.updateIbanMode ?
          [] : [eaPatternValidation(this.ibanRegExp, 'quoteBuyGenericFlow.ibanCode.ibanValidationError')]
      }
    };
  }
}
</script>

