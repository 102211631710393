<template>
  <div>
    <ea-card class="m-t-24 p-a-16">
      <div v-if="!issuedFleet" slot="cardbody">
        <ea-row class="t-size-small">
          <ea-form
            ref="refFleetQuotePaymentForm"
            :model="model"
            :validationOptions="validationOptions"
            :validateOnRuleChange="false"
          >
          <ea-col :span="12">
            <ea-row>
              <ea-col :span="24" class="m-b-12">
                <ea-heading level="4">
                  {{ $t('fleets.fleetsFlow.offerIssuanceDataView.paymentDataComponent.premium') }}
                </ea-heading>
              </ea-col>
              <ea-col :span="12">
                
                <ea-form-item
                  :label="$t('fleets.fleetsFlow.offerIssuanceDataView.paymentDataComponent.paymentForm')"
                  prop="paymentForm">
                <ea-select :value="paymentForm" :readonly="true">
                  <ea-option
                    v-for="option of paymentFormOptions"
                    :key="option"
                    :label="$t(`fleets.fleetsFlow.fleetQuotePaymentForm.options.${option}`)"
                    :value="option"
                  />
                </ea-select>
              </ea-form-item>
              </ea-col>
              <ea-col :span="12">
                <ea-form-item
                :label="$t('fleets.fleetsFlow.offerIssuanceDataView.paymentDataComponent.annualPremiumTotal')"
                prop="vehiclesNumber">
                <div slot="tooltipIconInfo">
                  <div slot="content">
                    <span>{{$t('fleets.fleetsFlow.offerIssuanceDataView.'+
                    'paymentDataComponent.annualPremiumTotal')}}</span>
                  </div>
                </div>
                <ea-input-text
                  :readonly="true"
                  :value = "annualPremiumTotal"
                 />
              </ea-form-item>
              </ea-col>
            </ea-row>
          </ea-col>
          <ea-col :span="12">
            <ea-row>
              <ea-col :span="24" class="m-b-12">
                <ea-heading level="4">
                  {{ $t('fleets.fleetsFlow.offerIssuanceDataView.paymentDataComponent.paymentData') }}
                </ea-heading>
              </ea-col>
              <ea-col :span="24" class="t-size-large m-b-16">
                
                  <ea-row>
                    <ea-col :span="12">
                      <ea-form-item
                        :label="$t('fleets.fleetsFlow.offerIssuanceDataView.paymentDataComponent.paymentChannel')"
                        prop="paymentChannel"
                        :required="!isReadonly"
                      >
                        <ea-select
                          v-model="model.paymentChannel"
                          :readonly="availablePaymentChannels.length === 1 || isReadonly"
                          :placeholder="
                            $t('fleets.fleetsFlow.offerIssuanceDataView.' + 'paymentDataComponent.placeholder')
                          "
                        >
                          <ea-option
                            v-for="(option, index) in availablePaymentChannels"
                            :key="index"
                            :label="
                              $t(
                                'fleets.fleetsFlow.offerIssuanceDataView.paymentDataComponent.options.' +
                                  option.tipoCanalCobro
                              )
                            "
                            :value="option.tipoCanalCobro"
                          />
                        </ea-select>
                      </ea-form-item>
                    </ea-col>
                    <ea-col :span="12" v-if="IsIBANvisible">
                      <qb-iban-code
                        v-model="model.ibanCodeModel"
                        id="qb-iban-code"
                        :documentationClientData="documentationClientData"
                        :consultaOperation="false"
                        :isIbanRequired="true"
                        :ibanSelectSpan="24"
                        :isReadonly="isReadonly"
                        @refreshData="onRefreshPersonIbanList"
                        @showError="onShowError"
                        @updateParentModel="update"
                        @handleGenericError="onHandleGenericError"
                      ></qb-iban-code>
                    </ea-col>
                  </ea-row>
              </ea-col>
            </ea-row>
            
          </ea-col>
          
        </ea-form>
        </ea-row>
      </div>
      <div v-else>
        <zz-client-basic-info
          id="showClientData"
          :documentationClientData="documentationClientData"
        ></zz-client-basic-info>
      </div>
    </ea-card>
  </div>
</template>

<script lang="ts">
import {
  Component, Prop, Watch
} from 'vue-property-decorator';

import {
  mixins
} from 'vue-class-component';

import {
  EABusinessComponent, ResponseWithErrors, throwIfResponseHasErrors
} from '@zurich-es-npm/ea-front-web-core';

import FleetPaymentDataModel from './fleet-payment-data-model';
import {
  EAFormValidationOptions, eaRequiredValidation
} from '@zurich-es-npm/ea-front-web-ui';
import {
  PaymentChannelOptions
} from '@/flows/fleets/model/payment-channel-options-model';
import {
  OfferPolicyUtils
} from '@/utils/offer-policy-utils';
import {
  GenericErrorData
} from '../qb-generic-error/qb-generic-error-business.vue';
import {
  PaymentChannelEnum
} from '@/types/payment-channel/payment-channel-enum.types';
import {
  SearchPolicyHolderResponseJuridicalPeople
} from '@/services/V1/fleets/searchPolicyHolderOperation/post';
import {
  Roles
} from '@/types/roles/roles-enum.types';
import QbIbanCodeBusiness from '../qb-iban-code/qb-iban-code-business.vue';
import {
  GetPersonsResponseListaPersonasTipoPersonaEnum
} from '@/services/V1/quoteAndBuy/getPersonsOperation/post/api';
import {
  IbanUtils
} from '@/utils/iban-utils';
import {
  DocumentationClientData
} from '@/presentational-components/clientBasicInfo/clientBasicInfo.type';
import ZzClientBasicInfo from '@/presentational-components/clientBasicInfo/clientBasicInfo.vue';

@Component({
  name: 'fleet-payment-data',
  components: {
    QbIbanCode: QbIbanCodeBusiness,
    'zz-client-basic-info': ZzClientBasicInfo
  },
})

/**
 * Business Component fleet-payment-data
 */
export default class FleetPaymentDataBusiness extends mixins<EABusinessComponent<FleetPaymentDataModel>>(
  EABusinessComponent
) {
  @Prop({
    required: true,
  })
    payerPerson!: SearchPolicyHolderResponseJuridicalPeople;

  @Prop()
    paymentForm!: string;

  @Prop()
    annualPremiumTotal!: string;

  @Prop({
    required: true,
    'default': () => [],
  })
    paymentChannelOptions!: PaymentChannelOptions[];

  @Prop({
    required: true,
  })
    paymentFormOptions!: string[];

  @Prop()
    issuedFleet: boolean = false;

  @Prop()
    isReadonly?: boolean;

  availablePaymentChannels: PaymentChannelOptions[] = [];

  documentationClientData?: DocumentationClientData;

  public formValidation: EAFormValidationOptions = {
    rules: {
      paymentChannel: [eaRequiredValidation('common.label.validation.fieldRequired')],
    },
  };

  /**
   * Initialize flow model
   */
  constructor() {
    super();
    this.model = new FleetPaymentDataModel();
    this.documentationClientData = {
      clientData: {
        datosBasicosPersona: {
          nombrePropio: '',
          primerApellido: this.payerPerson.razonSocialMatriz,
          segundoApellido: '',
          tipoPersona: GetPersonsResponseListaPersonasTipoPersonaEnum.J,
          codigoIdentificacionFiscal: this.payerPerson.codigoIdentificacionFiscal,
          codigoFiliacion: this.payerPerson.codigoFiliacion,
        },
      },
      clientRole: Roles.Tomador,
    };

    this.onRefreshPersonIbanList();
  }

  /**
   * Hook on created.
   *
   * @returns {void}
   */
  public created(): void {
    if (OfferPolicyUtils.userHasOnlyBankCharge()) {
      this.availablePaymentChannels = this.paymentChannelOptions.filter(
        channel => channel.tipoCanalCobro === PaymentChannelEnum.Banco
      );
      this.model.paymentChannel = PaymentChannelEnum.Banco;
      this.update();
    } else {
      this.availablePaymentChannels = this.paymentChannelOptions;
    }
  }

  /**
   * Refreshes given person iban list
   * @param {PersonRole} personToRefresh
   */
  async onRefreshPersonIbanList() {
    const ibanListOutput = await IbanUtils.getIbanList(this.payerPerson.codigoFiliacion);

    if (ibanListOutput) {
      throwIfResponseHasErrors(ibanListOutput as ResponseWithErrors);
      if (ibanListOutput.data?.ibanList) {
        this.model.ibanList = ibanListOutput.data.ibanList;
      }
      this.updateSelectableIban();
      // Need to update the model in the view
      this.update();
    }
  }

  /**
   * Updates received person's selectable iban list based on 'role equal' statements values
   * @param {PersonRole} personToRefresh
   */
  updateSelectableIban() {
    const ibanCodeModel = this.model.ibanCodeModel;
    ibanCodeModel.selectableIbanList = this.model.ibanList;
    if (
      !ibanCodeModel.qbIbanCodeModalModel.selectedIbanCode ||
      !ibanCodeModel.selectableIbanList.find(
        ibanElem => ibanElem.codigoIBAN === ibanCodeModel.qbIbanCodeModalModel.selectedIbanCode
      )
    ) {

      /**
       * Selected iban code undefined or not listed in person's selectable iban list => Select main iban
       */
      IbanUtils.selectMarkedAsPrincipalIban(ibanCodeModel.selectableIbanList, ibanCodeModel.qbIbanCodeModalModel);
    }
  }

  /**
   * Emit the refresh data event from address to parent component
   *
   * @param {any} args
   */
  onHandleGenericError(args: any) {
    this.$emit('handleGenericError', args);
  }

  /**
   * Emit the refresh data event from address to parent component
   *
   * @param {GenericErrorData} genericErrorData
   */
  onShowError(genericErrorData: GenericErrorData) {
    this.$emit('showError', genericErrorData);
  }

  /**
   * Check if is IBAN visible
   * @returns {boolean}
   */
  get IsIBANvisible(): boolean {
    return this.model.paymentChannel === PaymentChannelEnum.Banco;
  }

  /**
   * @returns {EAFormValidationOptions}
   */
  get validationOptions(): EAFormValidationOptions {
    return {
      rules: {
        paymentChannel: [eaRequiredValidation('common.label.validation.fieldRequired')],
      },
    };
  }

  /**
   * Watch for paymentChannel changes and update model into view.
   */
  @Watch('model.paymentChannel', {
    immediate: true,
  })
  updatePaymentChannelValue() {
    this.update();
  }
}
</script>
