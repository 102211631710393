import {
  EACorporateTableService, eAScrollCorporateTableService
} from '@zurich-es-npm/ea-front-web-core';
import {
  EACorporateTableLoadedStateModel, EACorporateTableState
} from '@zurich-es-npm/ea-front-web-core/lib/store/contexts/corporate-tables/ea-corporate-tables-context-types';
import TextUtils from './text-utils';
import Utils from './utils';

export interface CorpTableData {
  data: EACorporateTableLoadedStateModel;
}

export interface ParsedTableData {
  label: string;
  value: string;
}

export const CorpTableNames = {
  AddressType: 'KTCTTVG',
  AttachDocumentSubtypes: 'KMCT25S',
  AttachDocumentTypes: 'KMCT23S',
  Countries: 'KTCTPAG',
  CountryPhonePrefixes: 'KTCTPTG',
  DocumentTypes: 'KPETIFG',
  EmailTypes: 'KPETCDG',
  GiOperations: 'KMKTHPS',
  PaymentMethodChannels: 'KCIT34G',
  PhoneTypes: 'KPETCOG',
  RegularizationProductTypes: 'KCITRAG',
  RegularizationStateTypes: 'KCITERG',
  VidaOperations: 'KAETORS',
  tourDates: 'KCITONS',
  PolicyMovementType: 'KCIT26S',
  ProductTypes: 'KCITSTS',
  ListProductSearch: 'KCITUPS',
  PlateNumberSearch: 'KCIT71G', // Búsqueda por "matrícula"
  InternationalTransport: 'KT0161G',
  VehicleUse: 'KCIT88S',
  PlateNumType: 'KCIT86S',
  PolicyEmitedStatus: 'KCIT32G',
  BatchOnlineStatus: 'KCITQEG',
  PrintingMotives: 'KCITPSG',
  WorkDescriptions: 'KT2F61S'
};

export enum CorpTableFilterTypes {
  Contains = 'contains',
  Equals = 'equals'
}

export const CountryCorpTables = ['KTCTPAG', 'KTCTPTG'];
export const DocumentTypeTables = ['KPETIFG'];

/**
 * Returns data for the table.
 * @returns {Promise<EACorporateTableState>} promise with the result
 * @param {string} corpTable to search regularizations
 */
export function fetchCorporateTable(corpTable: string): Promise<CorpTableData> {
  return new EACorporateTableService(corpTable).fetchCorporateTable()
    .then((response: EACorporateTableState) => response as unknown as CorpTableData);
}

/**
 * Sort alphabetically by property
 * @param {any[]} array
 * @param {string} sortProperty
 */
export function corporativeTableSortAlphabetically(array: any[], sortProperty: string): void {
  Utils.sortObjectArrayByProperty(array, sortProperty);
}

/**
 * Set last element
 * @param {any[]} array
 * @param {string} compareProperty
 * @param {string} contains
 */
export function corporativeTableSetLastElement(array: any[], compareProperty: string, contains: string): void {
  Utils.setObjectArrayLastElement(array, compareProperty, contains);
}

/**
 * Orders country table => Spain goes first
 * @param {ParsedTableData[]} documents
 * @param {string} tableCode
 * @returns {ParsedTableData[]}
 */
export function orderCountryTableIfNecessary(
  documents: ParsedTableData[],
  tableCode: string
): ParsedTableData[] {
  if (CountryCorpTables.includes(tableCode)) {
    const spainElementIdx: number = documents.findIndex(doc => TextUtils.equalIgnoreCase(doc.label, 'ESPAÑA'));
    const spainElement: ParsedTableData = documents[spainElementIdx];
    documents.splice(spainElementIdx, 1);
    documents = [spainElement].concat(documents);
  }
  return documents;
}

/**
 * Filter 
 * @param {ParsedTableData[]} documents
 * @param {string} tableCode
 * @returns {ParsedTableData[]}
 */
export function filterDocumentTypeIfNecessary(
  documents: ParsedTableData[],
  tableCode: string
): ParsedTableData[] {
  if (DocumentTypeTables.includes(tableCode)) {
    documents = documents.filter(doc => doc.value !== 'S');
  }
  return documents;
}

/**
 * Parse corp table documents.
 * @param {CorpTableData} corpTableData - corp table data source
 * @param {string | undefined} labelCode - corp table label code
 * @param {string | undefined} valueCode - corp table value code
 * @returns {OperationsData[]} corp table data parsed
 */
export function parseCorpTableDocuments(
  corpTableData: CorpTableData, labelCode?: string, valueCode?: string
): ParsedTableData[] {
  let documents = corpTableData.data.tableDocuments
    .map(doc => {
      return {
        label: labelCode ? doc[labelCode]?.trim() : doc.DSELEMEN?.trim(),
        value: valueCode ? doc[valueCode] : doc.CDELEMEN
      };
    });
  Utils.sortObjectArrayByProperty(documents, 'label');
  documents = orderCountryTableIfNecessary(documents, corpTableData.data.tableCode);
  documents = filterDocumentTypeIfNecessary(documents, corpTableData.data.tableCode);
  return documents;
}

/**
 * Fetch suggestions
 *
 * @param {string} tableCode
 * @param {string} fieldToFilter
 * @param {string} filter
 * @param {CorpTableFilterTypes} filterType
 * @returns {Promise<EACorporateTableState>} promise with the result
 */
export function scrollCorporateTable(tableCode: string, fieldToFilter: string,
  filter: string, filterType: CorpTableFilterTypes = CorpTableFilterTypes.Equals): Promise<CorpTableData> {
  return eAScrollCorporateTableService({
    tableCode,
    filter: `${fieldToFilter}:${filterType}:${filter}`
  }).then((response: EACorporateTableState) => response as CorpTableData);
}
