import QbProductoAseguradoComunidadesModel
  from '@/business-components/qb-producto-asegurado-comunidades/qb-producto-asegurado-comunidades-model';
import {
  PolicyModel
} from '../../../../flows/policy/policy-model';

/**
 * Comunidades model
 *
 * @export
 * @class ComunidadesPolicyModel
 * @extends {PolicyModel}
 */
export default class ComunidadesPolicyModel extends PolicyModel {
  
  public productModel = new QbProductoAseguradoComunidadesModel();

}
