<template>
  <div>

    <ea-card shadow="hidden">
      <div slot="cardbody" class="p-a-16">
        <ea-form
          ref="form"
          :model="model"
          :validationOptions="formValidation"
          :validateOnRuleChange="false"
        >
          <ea-row>

            <!-- LEFT SECTON: Intermediary, business, product and movement type & reason -->
            <ea-col :span="12">

              <!-- INTERMEDIARY -->
              <ea-row>
                <!-- Nombre intermediario -->
                <ea-col :span="12">
                  <ea-form-item
                    :label="$t('policyMaintenanceFlow.supplements.field.intermediaryName')"
                    prop="intermediaryCode"
                    required
                  >
                    <ea-select
                      v-model="model.intermediaryCode"
                      :placeholder="$t('policyMaintenanceFlow.supplements.field.intermediaryPlaceholder')"
                      :readonly="intermediaryList && intermediaryList.length === 1"
                    >
                      <ea-option
                        v-for="interm in intermediaryList"
                        :key="interm.value"
                        :label="intermediaryList &&
                          intermediaryList.length > 1 ?
                          interm.value + ' ' + interm.label : interm.label"
                        :value="interm.value"
                      />
                    </ea-select>
                  </ea-form-item>
                </ea-col>

                <!-- Código intermediario -->
                <ea-col :span="12" v-if="model.intermediaryCode">
                  <ea-form-item :label="$t('policyMaintenanceFlow.supplements.field.intermediaryCode')">
                    <ea-input-text v-model="model.intermediaryCode" :readonly="true" />
                  </ea-form-item>
                </ea-col>
              </ea-row>

              <!-- BUSINESS -->
              <ea-row v-if="supplementData && supplementData.codigoNegocio">
                <!-- Nombre negocio -->
                <ea-col :span="12">
                  <ea-form-item
                    :label="$t('policyMaintenanceFlow.supplements.field.businessName')"
                    prop="businessCode"
                    :required="businessIsRequired"
                  >
                    <ea-select
                      v-model="model.businessCode"
                      :readonly="isBusinessReadonly"
                    >
                      <ea-option
                        v-for="business in businessList"
                        :key="business.value"
                        :label="business.label"
                        :value="business.value"
                      />
                    </ea-select>
                  </ea-form-item>
                </ea-col>
                
                <!-- Codigo negocio -->
                <ea-col :span="12" v-if="model.businessCode">
                  <ea-form-item :label="$t('policyMaintenanceFlow.supplements.field.businessCode')">
                    <ea-input-text v-model="model.businessCode" :readonly="true" />
                  </ea-form-item>
                </ea-col>
              </ea-row>

              <!-- PRODUCT -->
              <ea-row>
                <!-- Nombre producto -->
                <ea-col :span="12">
                  <ea-form-item :label="$t('policyMaintenanceFlow.supplements.field.productName')">
                    <ea-input-text v-model="model.productName" :readonly="true" />
                  </ea-form-item>
                </ea-col>
                
                <!-- Codigo producto -->
                <ea-col :span="12">
                  <ea-form-item :label="$t('policyMaintenanceFlow.supplements.field.productCode')">
                    <ea-input-text v-model="model.productCode" :readonly="true" />
                  </ea-form-item>
                </ea-col>
              </ea-row>

              <!-- MOVEMENT TYPE & REASON -->
              <ea-row>
                <!-- Tipo movimiento -->
                <ea-col :span="12">
                  <ea-form-item :label="$t('policyMaintenanceFlow.supplements.field.movementType')">
                    <ea-input-text v-model="model.movementType" :readonly="true" />
                  </ea-form-item>
                </ea-col>

                <!-- Motivo movimiento -->
                <ea-col :span="12">
                  <ea-form-item
                    :label="$t('policyMaintenanceFlow.supplements.field.movementReason')"
                    prop="movementReason"
                    required
                  >
                    <ea-select
                      v-model="model.movementReason"
                      :placeholder="$t('policyMaintenanceFlow.supplements.field.reasonPlaceholder')"
                    >
                      <ea-option
                        v-for="reason in movementReasonList"
                        :key="reason.value"
                        :label="reason.label"
                        :value="reason.value"
                      />
                    </ea-select>
                  </ea-form-item>
                </ea-col>
              </ea-row>

            </ea-col>

            <!-- RIGHT SECTION: Dates & Suplemento checkbox -->
            <ea-col :span="12">
              <ea-row>
                <!-- Check suplemento a vencimiento -->
                <ea-col :span="12" class="m-b-16">
                  <ea-form-item
                    prop="isExpiration"
                  >
                    <ea-checkbox
                      v-model="model.isExpiration"
                      :label="$t('policyMaintenanceFlow.supplements.field.expirationSupplement')"
                      @change="onIsExpirationCheckboxChange"
                    ></ea-checkbox>
                  </ea-form-item>
                </ea-col>

                <!-- Change itermediary link -->
                <ea-col :span="12" v-if="showLinkIntermediaries" class="d-display-flex d-justify-flex-start">
                  <ea-button
                    @click="onChangeIntermediary"
                    type="text"
                  >
                    <ea-icon icon="z-user-male" />
                    {{ $t('policyMaintenanceFlow.supplements.field.searchIntermediaryButton') }}
                  </ea-button>
                </ea-col>
              </ea-row>

              <ea-row>
                <!-- Fecha efecto natural -->
                <ea-col :span="12">
                   <ea-form-item
                    :label="$t('policyMaintenanceFlow.supplements.field.naturalEffectDate')"
                    prop="naturalEffectDate"
                  >
                    <ea-date-picker
                      v-model="model.naturalEffectDate"
                      size="small"
                      :disabled="true"
                    />
                  </ea-form-item>
                </ea-col>
              </ea-row>

              <ea-row>
                <!-- Fecha efecto -->
                <ea-col :span="12">
                  <ea-form-item
                    :label="$t('policyMaintenanceFlow.supplements.field.effectDate')"
                    prop="effectDate"
                    required
                  >
                    <ea-date-picker
                      v-model="model.effectDate"
                      size="small"
                    />
                  </ea-form-item>
                </ea-col>

                <!-- Fecha vencimiento -->
                <ea-col :span="12">
                  <ea-form-item
                    :label="$t('policyMaintenanceFlow.supplements.field.dueDate')"
                    prop="dueDate"
                    :required="!isDueDateReadonly"
                  >
                    <ea-date-picker
                      v-model="model.dueDate"
                      size="small"
                      :disabled="isDueDateReadonly"
                    />
                  </ea-form-item>
                </ea-col>

              </ea-row>
            </ea-col>
          </ea-row>
          
        </ea-form>
        
      </div>
    </ea-card>

    <!-- Back & Next buttons -->
    <div class="m-t-24 d-display-flex d-justify-space-between">
      
      <ea-button type="secondary" @click="onGoBack()">
          {{ $t('common.label.back') }}
        </ea-button>

      <ea-button @click="doValidation">
        {{ $t('common.label.next') }}
      </ea-button>
    </div>

    <!-- SI ESL USUARIO ES EMPLEADO -->
    <qb-employee-modal-intermediaries
      id="intermediaries"
      v-model="model.intermediariesEmployeeModal"
      :codigoRamo="productFactory.codigoRamo"
      :codeStructure="model.codeStructure"
      @intermediarySelected="modalIntermediarySelected"
      ref="employeeModal">
    </qb-employee-modal-intermediaries>

  </div>
</template>

<script lang="ts">
import {
  Component, Prop, Watch
} from 'vue-property-decorator';
import {
  mixins
} from 'vue-class-component';
import {
  uniqBy
} from 'lodash';
import {
  EABusinessComponent,
  EAMethod,
  EAMultiError,
  EAValidationError,
  ResponseWithErrors,
  throwIfResponseHasErrors
} from '@zurich-es-npm/ea-front-web-core';
import {
  EAFormValidationOptions,
  eaRequiredValidation,
  EAValidation,
  EAValidationTriggers,
  EAValidationTypes,
  Form
} from '@zurich-es-npm/ea-front-web-ui';
import {
  GetSupplementCreationDataResponse
} from '@/services/V1/policy/getSupplementCreationDataOperation/post';
import {
  EAGetMovementTypeTableApi,
  GetMovementTypeTableRequestTipoOperacionSuplementoEnum as SupplementMovementType,
  GetMovementTypeTableResponseTablaRestricciones
} from '@/services/V1/policy/getMovementTypeTableOperation/post';
import {
  EAGetBusinessListSupplementApi
} from '@/services/V1/policy/getBusinessListSupplementOperation/post';
import QbSupplementMovementReasonModel from './qb-supplement-movement-reason-model';
import {
  LabelValuePair,
  MovementReasonEnum
} from './qb-supplement-movement-reason.types';
import {
  SupplementsUtils
} from '../../utils/supplement-utils';
import Utils from '@/utils/utils';
import QbEmployeeModalIntermediariesBusiness
  from '../qb-employee-modal-intermediaries/qb-employee-modal-intermediaries-business.vue';
import PermissionUtils from '@/utils/permissions-utils';
import {
  GetDefaultUserValuesResponseCodigoTipoUsuarioEnum as UserTypesEnum
} from '@/services/V1/home/getDefaultUserValuesOperation/post';
import ProductBase from '@/utils/quote-buy-product-factory/products/product-base';

@Component({
  name: 'qb-supplement-movement-reason',
  components: {
    QbEmployeeModalIntermediaries: QbEmployeeModalIntermediariesBusiness
  },
})

/**
 * Business Component qb-supplement-movement-reason
 */
export default class QbSupplementMovementReasonBusiness
  extends mixins<EABusinessComponent<QbSupplementMovementReasonModel>>(EABusinessComponent) {

  @Prop({
    required: true
  })
    codigoPoliza?: string;

  @Prop({
    required: true
  })
    versionPoliza?: number;

  @Prop({
    required: true
  })
    supplementData?: GetSupplementCreationDataResponse;

  @Prop({
    required: true,
  })
    productFactory!: ProductBase;


  employeeUser: boolean = false;

  showLinkIntermediaries: boolean = false;

  // Form rules definition
  formValidation: EAFormValidationOptions = {
    rules: {
      movementReason: [eaRequiredValidation('common.label.validation.fieldRequired')],
      effectDate: [
        eaRequiredValidation(
          'common.label.validation.fieldRequired',
          EAValidationTriggers.BLUR,
          EAValidationTypes.DATE
        ),
        // Needed for trigger validation when date is cleared clicking icon
        eaRequiredValidation(
          'common.label.validation.fieldRequired',
          EAValidationTriggers.CHANGE,
          EAValidationTypes.DATE
        )
      ],
      dueDate: [
        eaRequiredValidation(
          'common.label.validation.fieldRequired',
          EAValidationTriggers.BLUR,
          EAValidationTypes.DATE
        ),
        // Needed for trigger validation when date is cleared clicking icon
        eaRequiredValidation(
          'common.label.validation.fieldRequired',
          EAValidationTriggers.CHANGE,
          EAValidationTypes.DATE
        )
      ],
      intermediaryCode: [eaRequiredValidation('common.label.validation.fieldRequired')],
    }
  };

  movementReasonList: LabelValuePair[] = [];

  intermediaryList: LabelValuePair[] = [];

  businessList: LabelValuePair[] = [];

  businessIsRequired: boolean = false;

  cachedMovementReasons: Record<keyof typeof SupplementMovementType, LabelValuePair[]> = {
    SuplementoVencimiento: [],
    Suplemento: []
  };

  /**
   * On created hook.
   */
  async created() {
    this.model.dueDate = new Date(this.supplementData?.fechaVencimiento || '');

    if (this.supplementData?.fechaEfectoNatural) {
      this.model.naturalEffectDate = new Date(this.supplementData.fechaEfectoNatural);
    }
  
    // Set default intermediary (the one in policy)
    const foundProducerInterm = this.supplementData?.intermediarios?.find(
      interm => interm.codigoIntermediario === this.model.producerIntermediary
    );
    if (foundProducerInterm && foundProducerInterm.codigoIntermediario) {
      this.model.intermediaryCode = foundProducerInterm.codigoIntermediario;
    }

    this.model.businessCode = this.supplementData?.codigoNegocio || '';
    this.model.businessName = this.supplementData?.nombreNegocio || '';

    this.model.productCode = this.getDisplayedProductCode();
    this.model.productName = this.supplementData?.nombreProducto || '';

    this.model.codeStructure = this.supplementData?.codigoEstructura || '';

    this.intermediaryList = this.getIntermediariesSelectData();

    this.setBusinessMandatoriness();

    // Set businessList if has business
    if (this.model.businessCode) {
      this.businessList = [{
        label: this.model.businessName,
        value: this.model.businessCode
      }];
    }

    // Set movement reasons (for type 'Suplemento' -> default movement type) to cache
    if (this.supplementData?.tablaRestricciones) {
      this.cachedMovementReasons.Suplemento = this.parseMovementReasonsSelectData(
        this.supplementData.tablaRestricciones
      );
    }
    
    this.onIsExpirationCheckboxChange();

    const userType = PermissionUtils.getUserType();
    this.employeeUser = PermissionUtils.isUserOfType(userType, UserTypesEnum.EMP);

    if (this.employeeUser) {
      this.showLinkIntermediaries = true;
    }
  }

  /**
   * Computed property to know if dueDate calendar is readonly.
   */
  get isDueDateReadonly(): boolean {
    // Only can be modified if Motivo Movimiento !== '180' (Cambio de vencimiento)
    return this.model.movementReason !== MovementReasonEnum.CambioVencimiento;
  }

  /**
   * Computed property to know if business field is readonly.
   */
  get isBusinessReadonly(): boolean {
    // Business can only be editable if motivo === CambioCEC && exist a businessCode.
    return !(this.model.movementReason === MovementReasonEnum.CambioCEC && !!this.model.businessCode);
  }

  /**
   * Watches changes in checkbox 'isExpiration' and assings model.movementType automatically.
   */
  @Watch('model.isExpiration', {
    immediate: true,
  })
  movementTypeLiteral() {
    const literal =
      this.$t(`policyMaintenanceFlow.supplements.${this.model.isExpiration ? 'expirationSupplement' : 'supplement'}`);
    this.model.movementType = `${literal}`;
  }

  /**
   * Watches changes in movementReason dropdown and reset dueDate and businessCode every time it changes.
   */
  @Watch('model.movementReason')
  async movementReasonChanges() {
    // Reset due date to default one
    this.model.dueDate = new Date(this.supplementData?.fechaVencimiento || '');
    // Reset business code to default one
    this.model.businessCode = this.supplementData?.codigoNegocio || '';

    /*
     * If movement reason is CambioCEC (700) and there is business code --> fetch business list.
     * In other case, reset business list
     */
    if (this.model.movementReason === MovementReasonEnum.CambioCEC && !!this.model.businessCode) {
      this.businessList = await this.fetchBusinessList();
    } else {
      this.businessList = [];
    }
  }

  /**
   * Listen to intermediary selected changes.
   * If Business dropdown is not readonly, reset business and fetch busnessList again.
   */
  @Watch('model.intermediaryCode')
  async intermediaryChanges() {
    if (!this.isBusinessReadonly) {
      // Reset business data
      this.model.businessCode = this.supplementData?.codigoNegocio || '';
      this.model.businessName = this.supplementData?.nombreNegocio || '';

      // Set business list to empty
      this.businessList = [];

      // Fetch new business list
      this.businessList = await this.fetchBusinessList();
    }
  }

  /**
   * Get product code concatenation.
   * 
   * @returns {string}
   */
  getDisplayedProductCode(): string {
    if (!this.supplementData) {
      return '';
    }
    return `${this.supplementData.codigoTecnicoProducto}-${this.supplementData.codigoComercialProducto}`;
  }

  /**
   * Get interediaries select data.
   * @returns {LabelValuePair[]}
   */
  getIntermediariesSelectData(): LabelValuePair[] {
    if (this.supplementData && this.supplementData.intermediarios) {
      const uniqueIntermediaries = uniqBy(this.supplementData.intermediarios, 'codigoIntermediario');
      return uniqueIntermediaries
        .filter(intermediary => intermediary.codigoIntermediario && intermediary.nombreIntermediario)
        .map(intermediary => {
          return {
            label: intermediary.nombreIntermediario as string,
            value: intermediary.codigoIntermediario as string
          };
        });
    } else {
      return [];
    }
  }

  /**
   * Execute form validation
   */
  async doValidation() {
    this.clearFlowErrors();
    await this.validationFileRequired();

    this.extraValidations();

    this.update();
    this.$emit('createSupplement'); // Tell view to create supplement movement
  }

  /**
   * Return form validation object
   * @returns {EAValidation}
   */
  public validation(): EAValidation {
    const form: Form = this.$refs.form as Form;
    return form.validation();
  }

  /**
   * Execute file required validation
   * 
   */
  async validationFileRequired() {
    try {
      await this.$nextTick();
      await this.validation().validate();
    } catch (error) {
      throw new EAValidationError(this.$t('common.label.validation.formWithErrors').toString());
    }
  }

  /**
   * If validation is NOT-OK throws error/s. In other case do not return anything to be able to continue.
   */
  public extraValidations() {
    const fechaInicioPoliza = new Date(this.supplementData?.fechaInicioPoliza || '');
    const fechaEfectoNatural = new Date(this.supplementData?.fechaEfectoNatural || '');

    const errors: EAValidationError[] = [];

    if (Number.isNaN(fechaInicioPoliza.getTime())) {
      errors.push(new EAValidationError(`${this.$t('policyMaintenanceFlow.supplements.validation.noInitDate')}`));
    }

    if (Number.isNaN(fechaEfectoNatural.getTime())) {
      errors.push(new EAValidationError(
        `${this.$t('policyMaintenanceFlow.supplements.validation.noNaturalEffectDate')}`
      ));
    }

    // Throw if data cannot be obtained
    if (errors.length) {
      throw errors.length === 1 ? errors[0] : new EAMultiError(errors);
    }

    /* Check validations */
    const validationsErrors = SupplementsUtils.checkValidations(
      this.productFactory.shouldValidate1YearMoreDate,
      fechaInicioPoliza,
      fechaEfectoNatural,
      this.model.dueDate,
      this.model.effectDate,
      this.model.isExpiration
    );
 
    // Throws if any validation errors
    if (validationsErrors.length) {
      throw validationsErrors.length === 1 ? validationsErrors[0] : new EAMultiError(validationsErrors);
    }
  }

  /**
   * On checkbox 'Suplemento a vencimiento' change
   */
  async onIsExpirationCheckboxChange() {
    const movementReasons = await this.getMovementReasons();
    this.movementReasonList = movementReasons;
  
    Utils.sortObjectArrayByProperty(this.movementReasonList, 'label');
  }

  /**
   * Get movement reasons by the current select supplement type (Suplemento/SuplementoVencimiento).
   * First of all, give list from cache or fetch list from endpoint if cache has no information
   */
  async getMovementReasons(): Promise<LabelValuePair[]> {
    // Reset movementReason form value
    this.model.movementReason = '';

    // Get current supplement movement type (given by checkbox)
    const supplementMovType = this.model.isExpiration
      ? SupplementMovementType.SuplementoVencimiento
      : SupplementMovementType.Suplemento;

    // Search movement reason list in cache, and fetch it if cache has no data
    return this.cachedMovementReasons[supplementMovType] && this.cachedMovementReasons[supplementMovType].length > 0
      ? this.cachedMovementReasons[supplementMovType]
      : this.fetchMovementReasons(supplementMovType);
  }

  /**
   * Fetch movement reasons list based on check ('Supplemento a Vencimiento') state.
   * @param {SupplementMovementType} supplementMovType supplement type
   */
  @EAMethod({
    loading: true
  })
  async fetchMovementReasons(
    supplementMovType: SupplementMovementType
  ): Promise<LabelValuePair[]> {
    // Fetch new reasons list
    const api = new EAGetMovementTypeTableApi();
    const result = await api.getMovementTypeTableOperation({
      getMovementTypeTableRequest: {
        tipoOperacionSuplemento: supplementMovType
      }
    });

    if (result) {
      throwIfResponseHasErrors(result as ResponseWithErrors);

      // Save list to cache
      if (result.tablaRestricciones && result.tablaRestricciones.length) {
        const parsedMovementReasons = this.parseMovementReasonsSelectData(result.tablaRestricciones);
        this.cachedMovementReasons[supplementMovType] = parsedMovementReasons;
        return parsedMovementReasons;
      }
    }

    return [];
  }

  /**
   * Parse and mount movement reasons select list
   * @param {GetMovementTypeTableResponseTablaRestricciones[]} restriccions
   * @returns {LabelValuePair[]}
   */
  parseMovementReasonsSelectData(restriccions: GetMovementTypeTableResponseTablaRestricciones[]): LabelValuePair[] {
    return restriccions.
      filter(restric => restric.nombreRestriccion && restric.valorRestriccion)
      // eslint-disable-next-line arrow-body-style
      .map(restric => ({
        label: restric.nombreRestriccion as string,
        value: restric.valorRestriccion as string
      }));
  }

  /**
   * Fetch business list
   */
  @EAMethod({
    loading: true
  })
  async fetchBusinessList(): Promise<LabelValuePair[]> {
    if (
      this.supplementData
      && this.supplementData.codigoComercialProducto
      && this.supplementData.codigoTecnicoProducto
      && this.model.producerIntermediary
    ) {
      const api = new EAGetBusinessListSupplementApi();
      const result = await api.getBusinessListSupplementOperation({
        getBusinessListSupplementRequest: {
          codigoComercialProducto: this.supplementData.codigoComercialProducto,
          codigoTecnicoProducto: this.supplementData.codigoTecnicoProducto,
          claveIntermediarioProductor1: this.model.intermediaryCode // Selected intermediary in dropdown
        }
      });

      if (result) {
        throwIfResponseHasErrors(result as ResponseWithErrors);
        // eslint-disable-next-line arrow-body-style
        return (result.negocios || []).map(business => ({
          label: business.nombreNegocio as string,
          value: business.codigoNegocio as string
        }));
      }
    }

    // Return empty array as default
    return [];
  }

  /**
   * Open intermediary modal
   */
  onChangeIntermediary() {
    this.model.intermediariesEmployeeModal.showModalIntermediaries = true;
    this.model.intermediariesEmployeeModal.intermediaryCode = '';
    this.model.intermediariesEmployeeModal.intermediaryName = '';
    
    const modalComp = this.$refs.employeeModal as QbEmployeeModalIntermediariesBusiness;
    modalComp.showTable = false;
    modalComp.isSelectButtonEnabled = false;
  }

  /**
   * Intermediary info handler
   */
  modalIntermediarySelected() {
    this.intermediaryList = [{
      label: this.model.intermediariesEmployeeModal.intermediaryName,
      value: this.model.intermediariesEmployeeModal.intermediaryCode
    }];
    this.model.intermediaryCode = this.model.intermediariesEmployeeModal.intermediaryCode;
  }

  /**
   * Handles back button
   */
  onGoBack() {
    this.$emit('goToPreviousFlow');
  }

  /**
   * Sets mandatoriness for Bussiness field in depence of cdstruc
   */
  setBusinessMandatoriness() {
    this.businessIsRequired =
      this.model.codeStructure === '1006' ||
      this.model.codeStructure === '1008' ||
      this.model.codeStructure === '1009';
  }

}
</script>
