import {
  GetGeneralDataResponseDataDatosObjeto
} from '@/services/V1/quoteAndBuy/getGeneralDataOperation/post';

/** 
 * Model qb-additional-data
 */
class QbOfferInssuanceAdditionalDataModel {


  public additionalData: GetGeneralDataResponseDataDatosObjeto[] = [];
}

export default QbOfferInssuanceAdditionalDataModel;
