export interface MinMaxValue {
  valorMinimoElemento?: number;
  valorMaximoElemento?: number;
}

export interface TableValues {
  nombreRestriccion: string;
  valorRestriccion: string;
}

export interface FieldStructure {
  codigoElemento: string;

  tipoElemento: string;

  tipoMascara?: string;

  tablaElemento: string;

  valorElemento: string;

  valorMaximoElemento?: number;

  valorMinimoElemento?: number;

  elementoRequerido: boolean;

  elementoModificable: boolean;

  longitudElemento?: number;

  numeroDecimalesElemento?: number;

  tablaRestricciones: TableValues[];
}

export const FIELD_TYPES = {
  DATE: 'date',
  NUMBER: 'number',
  STRING: 'string',
  DEFAULT: 'default'
};
