import QbProductoAseguradoHogarModel
  from '@/business-components/qb-producto-asegurado-hogar/qb-producto-asegurado-hogar-model';
import {
  QuoteBuyModel
} from '../../../../flows/quote-buy/quote-buy-model';

/**
 * Hogar model
 *
 * @export
 * @class HogarModel
 * @extends {QuoteBuyModel}
 */
export default class HogarModel extends QuoteBuyModel {
  
  public productModel = new QbProductoAseguradoHogarModel();

}
