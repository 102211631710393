/** 
 * Model fleet-commit-contract
 */
class FleetCommitContractModel {
  
  public formValues = {
    selectedValues: [] as string[],
  };
}

export default FleetCommitContractModel;
