export enum SearchTypeEnum {
  NumeroOferta = 'numeroOferta',
  NumeroPoliza = 'numeroPoliza',
  TipoPoliza = 'tipoPoliza',
  Tomador = 'tomador',
  AffiliationCode = 'affiliationCode',
  IdFiscal = 'fiscalId',
  RazonSocial = 'businessName',
  NombreApellido = 'nameAndSurname',
  Producto = 'producto',
  Usuario = 'usuario',
  SituacionRiesgo = 'situacionRiesgo',
  Matricula = 'matricula',
  CodigoFlota = 'codigoFlota',
  NombreFlota = 'nombreFlota',
  Intermediario = 'intermediario',
  Estado = 'estado'
}

export enum SearcherModeEnum {
  MANTENIMIENTO = 'mantenimiento',
  CONSULTA = 'consulta'
}
