import {
  GetWarrantiesByPackageResponse
} from '@/services/V1/fleets/getWarrantiesByPackageOperation/post';

/** 
 * Model fleet-package-select
 */
class FleetPackageSelectModel {

  public packageSelected = '';
  
  public packageModalitySelected = '';

  public warranties: GetWarrantiesByPackageResponse = {
    garantiasIncluidas: [],
    garantiasOpcionales: [],
    franquiciasValor: []
  };

}

export default FleetPackageSelectModel;
