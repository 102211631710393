<template>
  <div>
    <ea-dialog
      :title="$t('fleets.cancelFleet.title')"
      :visible="visible"
      size="medium"
      :closeOnClickModal="false"
      :before-close="handleClose"
    >

      <!-- Alert component -->
      <ea-alert
        v-if="alertMessage"
        :type="alertType"
        :title="alertTitle"
        :description="alertMessage"
        @close="alertMessage = ''"
      ></ea-alert>
    
      <ea-form
        class="m-t-16"
        ref="form"
        v-if="model"
        :model="model"
        :validationOptions="validationOptions"
        :validateOnRuleChange="false"
      >
        <ea-row>
          <ea-col :span="8">
            <ea-form-item :label="$t('fleets.cancelFleet.policyCode')">
              <ea-input-text
                :readonly="true"
                :value="model.codigoPoliza"
              ></ea-input-text>
            </ea-form-item>
          </ea-col>
        </ea-row>
        <ea-row>
          <ea-col :span="8">
            <ea-form-item :label="$t('fleets.cancelFleet.naturalEffectiveDate')">
              <ea-input-text
                :readonly="true"
                :value="formatDateToString(model.fechaEfectoNatural)"
              ></ea-input-text>
            </ea-form-item>
          </ea-col>
          <ea-col :span="8">
            <ea-form-item :label="$t('fleets.cancelFleet.effectiveMovementDate')">
              <ea-input-text
                :readonly="true"
                :value="formatDateToString(model.fechaEfectoMovimiento)"
              ></ea-input-text>
            </ea-form-item>
          </ea-col>
          <ea-col :span="8">
            <ea-form-item :label="$t('fleets.cancelFleet.dueDate')">
              <ea-input-text
                :readonly="true"
                :value="formatDateToString(model.fechaVencimiento)"
              ></ea-input-text>
            </ea-form-item>
          </ea-col>
          <ea-col :span="8">
            <ea-form-item
              :label="$t('fleets.cancelFleet.movementReason')"
              prop="motivoMovimientoPoliza"
              required
            >
              <ea-select
                v-model="model.motivoMovimientoPoliza"
                :placeholder="$t('common.label.select')"
                :disabled="flagRevokeCancelNeeded"
              >
                <ea-option
                  v-for="item in model.motivosMovimiento"
                  :key="`mov-reason-${item.motivoMovimientoPoliza}`"
                  :label="item.descripcionMotivoMovimiento"
                  :value="item.motivoMovimientoPoliza" />
              </ea-select>
            </ea-form-item>
          </ea-col>
          <ea-col :span="8">
            <ea-form-item
              :label="$t('fleets.cancelFleet.movementType')"
              prop="tipoDeAnulacion"
              required
            >
              <ea-select
                v-model="model.tipoDeAnulacion"
                :placeholder="$t('common.label.select')"
                :disabled="flagRevokeCancelNeeded"
              >
                <ea-option
                  v-for="item in model.tiposMovimiento"
                  :key="`mov-type-${item.value}`"
                  :label="item.label"
                  :value="item.value"/>
              </ea-select>
            </ea-form-item>
          </ea-col>
          <ea-col :span="8">
            <ea-form-item
              :label="$t('fleets.cancelFleet.cancelDate')"
              prop="fechaAnulacion"
              required
            >
              <ea-date-picker
                :readonly="isMovementAlVencimiento"
                v-model="model.fechaAnulacion"
                :disabled="flagRevokeCancelNeeded"
                :pickerOptions="datepickerOptions"
              />
            </ea-form-item>
          </ea-col>
        </ea-row>

        <!-- Displayed fields when cancelling with return -->
        <ea-row v-if="isMovementWithReturn && isCancelResponseWithReturn && cancelResponse">
          <ea-col :span="8">
            <ea-form-item :label="$t('fleets.cancelFleet.withReturn.effectiveDate')">
              <ea-date-picker
                :readonly="true"
                :value="new Date(cancelResponse.fechaEfectoRecibo || '')"
              />
            </ea-form-item>
          </ea-col>
          <ea-col :span="8">
            <ea-form-item :label="$t('fleets.cancelFleet.withReturn.dueDate')">
              <ea-date-picker
                :readonly="true"
                :value="new Date(cancelResponse.fechaVencimientoRecibo || '')"
              />
            </ea-form-item>
          </ea-col>
          <ea-col :span="8">
            <ea-form-item :label="$t('fleets.cancelFleet.withReturn.receiptAmount')">
              <ea-input-currency
                :readonly="true"
                :value="cancelResponse.importeTotalRecibo"
                :precision="2"
              />
            </ea-form-item>
          </ea-col>
        </ea-row>
      </ea-form>

      <div slot="footer" class="d-display-flex d-justify-flex-end">
        <ea-button type="secondary" class="m-r-16" @click="handleClose">
          {{ $t('common.label.close') }}
        </ea-button>
        <ea-button type="primary" v-if="isCancelResponseWithReturn" @click="emitWithReturn">
          {{ $t('fleets.cancelFleet.cancel') }}
        </ea-button>
        <ea-button type="primary" @click="initiatePolicyCancel" v-else>
          {{ isMovementWithReturn ? $t('fleets.cancelFleet.calculate') : $t('fleets.cancelFleet.cancel') }}
        </ea-button>
      </div>
    </ea-dialog>
  </div>
</template>

<script lang="ts">
import {
  Component, Prop, Watch
} from 'vue-property-decorator';
import {
  mixins
} from 'vue-class-component';
import moment from 'moment';
import {
  EABusinessComponent, EAError,
  EAMethod, ResponseWithErrors
} from '@zurich-es-npm/ea-front-web-core';
import {
  EAFormValidationOptions,
  EAValidation,
  EAValidationTriggers,
  EAValidationTypes,
  Form,
  eaRequiredValidation
} from '@zurich-es-npm/ea-front-web-ui';
import {
  DatePickerOptions
} from 'element-ui/types/date-picker';
import Utils from '@/utils/utils';
import FleetsCancelVehicleModalModel from './fleets-cancel-vehicle-modal-model';
import {
  EACancelPolicyApi,
  CancelPolicyRequestIndicadorPolizaEnum,
  CancelPolicyResponse
} from '../../services/V1/policy/cancelPolicyOperation/post';
import {
  EARevokeCancelPolicyApi
} from '../../services/V1/policy/revokeCancelPolicyOperation/post';
import {
  EACancellationMovementIssueWithReturnApi
} from '../../services/V1/policy/cancellationMovementIssueWithReturnOperation/post';
import {
  NotificationsTypeEnum,
  NotificationsUtils
} from '@/utils/notifications/notifications-utils';

@Component({
  name: 'fleet-cancel-vehicle-modal',
  components: {}
})

/**
 * Business Component fleet-anulate-vehicle-modal
 */
export default class FleetCancelVehicleModal
  extends mixins<EABusinessComponent<FleetsCancelVehicleModalModel>>(EABusinessComponent) {

  @Prop({
    required: true
  })
    visible: boolean = false;

  cancelResponse: CancelPolicyResponse | null = null;

  flagRevokeCancelNeeded = false;

  alertType: 'info' | 'success' | 'warning' | 'error' = 'error';

  alertMessage = '';

  cancelDateReadonly = false;

  datepickerOptions: DatePickerOptions = {
    firstDayOfWeek: 1
  };

  /**
   * Hook on created
   */
  created() {
    // Set datepicker invalid dates configuration
    this.datepickerOptions.disabledDate = this._disabledDate;
  }

  /**
   * Gets alert title
   */
  get alertTitle(): string {
    switch (this.alertType) {
      case 'info':
        return NotificationsTypeEnum.Info;
      case 'success':
        return NotificationsTypeEnum.Success;
      case 'warning':
        return NotificationsTypeEnum.Warning;
      case 'error':
        return NotificationsTypeEnum.Error;
      default:
        return NotificationsTypeEnum.Error;
    }
  }

  /**
   * @returns {EAFormValidationOptions}
   */
  get validationOptions(): EAFormValidationOptions {
    return {
      rules: {
        motivoMovimientoPoliza: [eaRequiredValidation('common.label.validation.fieldRequired')],
        tipoDeAnulacion: [eaRequiredValidation('common.label.validation.fieldRequired')],
        fechaAnulacion: [
          eaRequiredValidation(
            'common.label.validation.fieldRequired',
            EAValidationTriggers.BLUR,
            EAValidationTypes.DATE
          )
        ]
      },
    };
  }

  /**
   * Returns when movement type selected is 'Anulación con Extorno'
   */
  get isMovementWithReturn(): boolean {
    const WITH_RETURN = 'ANULACION_EXTORNO';
    return this.model.tipoDeAnulacion === WITH_RETURN;
  }

  /**
   * Returns when movement type selected is 'Anulación al Vencimiento'
   */
  get isMovementAlVencimiento(): boolean {
    const VENCIMIENTO = 'ANULACION_VENCIMIENTO';
    return this.model.tipoDeAnulacion === VENCIMIENTO;
  }

  /**
   * Returns when a OK cancel response has `indicadorGeneracionExtorno` to 'S' (true).
   */
  get isCancelResponseWithReturn(): boolean {
    return this.cancelResponse?.indicadorGeneracionExtorno === 'S';
  }

  /**
   * Listens to tipoDeAnulacion field and set fechaAnulacion as corresponds.
   */
  @Watch('model.tipoDeAnulacion', {
    immediate: true
  })
  onTipoAnulacionChange() {
    if (this.isMovementAlVencimiento) {
      // If 'Anulacion al Vencimiento' -> set fechaAnulacion equal to fechaVencimiento
      this.model.fechaAnulacion = new Date(this.model.fechaVencimiento);
    }
  }

  /**
   * Handles 'close' action and
   * @param {Function} done 
   */
  async handleClose(done?: Function) {
    try {
      // Check if revoke pending cancel is needed, then close modal
      if (this.flagRevokeCancelNeeded) {
        await this.revokeCurrentCancel();
      }

      this.$emit('closeModal', false);

      if (typeof done === 'function') {
        done();
      }
    } catch (err) {
      // Does nothing, only catch error
    }
  }

  /**
   * Format date type to string
   * @param {Date | null} date
   * @returns {string} string date
   */
  formatDateToString(date: Date | string | null | undefined): string {
    if (date === null || date === undefined || !moment(date).isValid()) {
      return '';
    }
    return Utils.formatDateToString(date as Date | undefined);
  }

  /**
   * Performs a policy cancel to BFF.
   */
  async initiatePolicyCancel() {
    try {
      await this.validateForm().validate();
      await this.performPolicyCancel();
    } catch (err) {
      // Just catch the error and does nothing
    }
  }

  /**
   * Performs a policy cancel to BFF.
   */
  @EAMethod({
    loading: true
  })
  async performPolicyCancel(): Promise<void> {
    this.cancelResponse = null; // Reset cancel response data

    try {
      const api = new EACancelPolicyApi();
      const response = await api.cancelPolicyOperation({
        cancelPolicyRequest: {
          codigoPoliza: this.model.codigoPoliza,
          versionPoliza: this.model.versionPoliza,
          tipoMovimientoPolizaPersonalizado: this.model.tipoDeAnulacion,
          motivoMovimientoPoliza: this.model.motivoMovimientoPoliza,
          fechaCambioEstado: Utils.convertDateToBffString(this.model.fechaAnulacion || undefined),
          indicadorPoliza: CancelPolicyRequestIndicadorPolizaEnum.Complete
        }
      });

      if (response) {
        const isError = this.checkIfResponseHasErrorToShow(response as ResponseWithErrors);
        if (!isError) {
          this.cancelResponse = response;

          if (response.indicadorGeneracionExtorno === 'N') {
            // No extorno
            NotificationsUtils.throwSuccess(`${this.$t('fleets.cancelFleet.success', {
              plateNumber: this.model.plateNumber
            })}`);
            this.$emit('closeModal', true);
          } else {
            this.flagRevokeCancelNeeded = true;
          }
        }
      }
    } catch (err) {
      this._manageCatchedError(err);
    }
  }

  /**
   * Validates form.
   * @returns {EAValidation}
   */
  validateForm(): EAValidation {
    const formRef = this.$refs.form as Form;
    return formRef?.validation();
  }

  /**
   * Check if response has error to show and set correspondant variables.
   * @param {ResponseWithErrors} response 
   * @returns {boolean}
   */
  checkIfResponseHasErrorToShow(response: ResponseWithErrors): boolean {
    this.alertMessage = '';
    if (response?.errors?.length) {
      const error = response.errors[0];

      let errorSeverity: number = error.severity;
      if (errorSeverity === undefined) {
        // Example case: EATechnicalError
        const technicalError: { _severity: number } = error as unknown as { _severity: number };
        errorSeverity = technicalError._severity;
      }

      // Set alert type
      if (errorSeverity <= 1) {
        this.alertType = 'info';
      } else if (errorSeverity >= 4) {
        this.alertType = 'error';
      } else if (errorSeverity > 1 && errorSeverity < 4) {
        this.alertType = 'warning';
      } else {
        // Default type set to error
        this.alertType = 'error';
      }
      // Set alert description
      this.alertMessage = error.message;
    }
    return !!this.alertMessage;
  }

  /**
   * Emit an initiated cancellation movement (with return).
   */
  @EAMethod({
    loading: true
  })
  async emitWithReturn(): Promise<void> {
    try {
      const api = new EACancellationMovementIssueWithReturnApi();
      const response = await api.cancellationMovementIssueWithReturnOperation({
        cancellationMovementIssueWithReturnRequest: {
          codigoPoliza: this.model.codigoPoliza,
          motivoMovimientoPoliza: this.model.motivoMovimientoPoliza
        }
      });

      if (response) {
        const isError = this.checkIfResponseHasErrorToShow(response as unknown as ResponseWithErrors);
        if (!isError) {
          NotificationsUtils.throwSuccess(`${this.$t('fleets.cancelFleet.success', {
            plateNumber: this.model.plateNumber
          })}`);
          NotificationsUtils.throwWarning(`${this.$t('fleets.cancelFleet.warningWithReturn')}`);
          this.flagRevokeCancelNeeded = false;
          this.$emit('closeModal', true);
        }
      }
    } catch (err) {
      this._manageCatchedError(err);
    }
  }

  /**
   * Revokes an initiated policy cancel.
   */
  @EAMethod({
    loading: true
  })
  async revokeCurrentCancel(): Promise<void> {
    try {
      const api = new EARevokeCancelPolicyApi();
      const response = await api.revokeCancelPolicyOperation({
        revokeCancelPolicyRequest: {
          codigoPoliza: this.model.codigoPoliza,
          versionPoliza: this.cancelResponse?.versionGenerar || 0 // Fallback to 0 to avoid possible nullish value
        }
      });

      if (response) {
        const isError = this.checkIfResponseHasErrorToShow(response as ResponseWithErrors);
        if (isError) {
          throw response.errors?.[0]; // To stop execution if revoking has failed
        } else {
          // Close modal
          this.flagRevokeCancelNeeded = false;
        }
      }
    } catch (err) {
      this._manageCatchedError(err);
      throw err;
    }
  }

  /**
   * Configuration for disabled dates.
   * 
   * @param {Date} date 
   * @returns {boolean}
   */
  _disabledDate(date: Date): boolean {
    // Only enabled dates between fechaEfectoMovimiento and fecha Vencimiento (both included).
    const initialDate = new Date(this.model.fechaEfectoMovimiento);
    const endDate = new Date(this.model.fechaVencimiento);

    initialDate.setDate(initialDate.getDate() - 1);

    // If date is before initialDate or after endDate, disable this date
    if (date.getTime() < initialDate.getTime() || date.getTime() > endDate.getTime()) {
      return true;
    }

    // Any date between initialDate & endDate is a valid date
    return false;
  }

  /**
   * Manage catched errors when calling endpoints.
   * @param {unknown} err 
   */
  _manageCatchedError(err: unknown) {
    const error: EAError = err as EAError;
    const fakeResponse: ResponseWithErrors = {
      errors: [error]
    } as unknown as ResponseWithErrors;
    this.checkIfResponseHasErrorToShow(fakeResponse);
  }

}
</script>
