<template>
  <div>
  <ea-row>
      <ea-col>
        <ea-link
          icon-left="z-clipboard-list"
          @click="onOpenDialogClick()"
          v-if="hasCompetencesManagement && fleetHasPrice">
          {{ $t('fleets.fleetsFlow.fleetCompetencesManagementModal.title') }}
        </ea-link>
      </ea-col>
    </ea-row>
    <ea-dialog
      size="medium"
      :visible="showCompetencesDialog"
      :title="$t('fleets.fleetsFlow.fleetCompetencesManagementModal.title')"
      @close="showCompetencesDialog = false"
    >
    <ea-row>
      <ea-col class="m-t-16">
        <ea-text>
          {{ $t('fleets.fleetsFlow.fleetCompetencesManagementModal.body1') }}
        </ea-text>
        <ea-link :underline="false" :href="webpage" target="_blank">
          {{ $t('fleets.fleetsFlow.fleetCompetencesManagementModal.webpage') }}
        </ea-link>
        <ea-text>
          {{ $t('fleets.fleetsFlow.fleetCompetencesManagementModal.body2') }}
        </ea-text>
        <ea-link :underline="false" :href="`mailto:${email}`">
          {{ $t('fleets.fleetsFlow.fleetCompetencesManagementModal.email') }}
        </ea-link>
        <ea-text>
          {{ $t('fleets.fleetsFlow.fleetCompetencesManagementModal.body3') }}
        </ea-text>
      </ea-col>
    </ea-row>
    <ea-row>
      <ea-col >
        <ea-text>
          {{ $t('fleets.fleetsFlow.fleetCompetencesManagementModal.body4') }}
        </ea-text>
        <ea-link :underline="false" :href="`mailto:${email}`">
          {{ $t('fleets.fleetsFlow.fleetCompetencesManagementModal.email') }}
        </ea-link>
        <ea-text>
          {{ $t(`fleets.fleetsFlow.fleetCompetencesManagementModal.body5`, {fleetNumber: fleetNumber}) }}
        </ea-text >
        <ea-text :in-line="false">
          {{ $t('fleets.fleetsFlow.fleetCompetencesManagementModal.body6') }}
        </ea-text>
      </ea-col>
    </ea-row>
    <ea-row
      v-for="(vehicle, index) of model.vehiculos"
      :key="index"
      :data="vehicle.matriculaVehiculo"
      class="m-t-16 d-display-flex d-direction-column m-b-32"
      >
      <ea-col class="m-b-16">
        <ea-text size="large" weight="semibold">
          {{ $t('fleets.fleetsFlow.fleetCompetencesManagementModal.field.plateNumber') }}
          {{vehicle.matriculaVehiculo}}
        </ea-text>
      </ea-col>
      <ea-col>
        <ea-table
          class="d-display-flex d-justify-space-between"
          :data="vehicle.competencias">
            <ea-table-column
              :label="$t('fleets.fleetsFlow.fleetCompetencesManagementModal.field.type')"
              show="descripcionErrorAutorizacion"
              headerClass="no-sortBy"
            >
              <template slot-scope="row">
                <span class="text-wrap">
                  {{ row.descripcionErrorAutorizacion }}
                </span>
              </template>
            </ea-table-column>
            <ea-table-column
            cellClass="t-align-right"
              :label="$t('fleets.fleetsFlow.fleetCompetencesManagementModal.field.status')"
              show="descripcionEstadoControlCompetencias"
              headerClass="no-sortBy"
            >
              <template slot-scope="row">
                <div class="ea-table-td-content t-align-left">
                  <ea-tag
                    class="m-l-0"
                    :status="getTagStatus(row.estadoControlCompetencias)">
                    {{  capitalizeFirstLetter(row.descripcionEstadoControlCompetencias) }}
                  </ea-tag>
                </div>
              </template>
            </ea-table-column>
        </ea-table>
      </ea-col>
    </ea-row>
    </ea-dialog>
  </div>
</template>

<script lang="ts">
import {
  Component, Prop
} from 'vue-property-decorator';

import {
  mixins
} from 'vue-class-component';

import {
  EABusinessComponent
} from '@zurich-es-npm/ea-front-web-core';

import FleetCompetencesManagementModalModel from './fleet-competences-management-modal-model';
import TextUtils from '@/utils/text-utils';
import {
  GetFleetCompetencesManagementResponseCompetenciasEstadoControlCompetenciasEnum
} from '@/services/V1/fleets/getFleetCompetencesManagementOperation/post';

@Component({
  name: 'fleet-competences-management-modal'
})

/**
 * Business Component fleet-competences-management-modal
 */
export default class FleetCompetencesManagementModalBusiness extends
  mixins<EABusinessComponent<FleetCompetencesManagementModalModel>>(EABusinessComponent) {
      
  @Prop({
    'default': () => false
  })
    hasCompetencesManagement!: boolean;
 
        
  @Prop({
    'default': () => ''
  })
    fleetNumber!: string;
  
  @Prop({
    required: true
  })
    fleetHasPrice!: boolean;

  showCompetencesDialog: boolean = false;


  /**
   * Getter email contact
   */
  get email(): string {
    return this.$t('fleets.fleetsFlow.fleetCompetencesManagementModal.email').toString();
  }

  /**
   * Getter webpage url
   */
  get webpage(): string {
    return this.$t('fleets.fleetsFlow.fleetCompetencesManagementModal.url').toString();
  }

  /**
   *
   */
  onOpenDialogClick() {
    this.showCompetencesDialog = true;
  }

  /**
   * Gets tag status based on received rowStatus
   * @param {string} rowStatus
   * @returns {string}
   */
  getTagStatus(rowStatus: string): string {
    switch (rowStatus) {
      case GetFleetCompetencesManagementResponseCompetenciasEstadoControlCompetenciasEnum.P:
        return 'warning';
      case GetFleetCompetencesManagementResponseCompetenciasEstadoControlCompetenciasEnum.R:
        return 'danger';
      default:
        return '';
    }
  }

  /**
   * Capitalizes first letter of received string
   * @param {string} string
   * @returns {string}
   */
  capitalizeFirstLetter(string: string): string {
    return TextUtils.capitalizeFirstLetterOneWord(string.toLowerCase());
  }

}
</script>
