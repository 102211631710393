<!-- eslint-disable max-lines -->
<template>
  <div>
    <ea-card v-if="shouldShow">
      <div slot="cardbody" class="p-a-24">
        <!-- Form -->
        <ea-form
          ref="form"
          reference="model"
          :model="model"
          :validationOptions="formValidation"
          @submit.native.prevent
        >
          <!-- Intermediary Name / Code -->
          <ea-row>
            <ea-col :span="6">
              <ea-form-item
                :label="$t('quoteBuyGenericFlow.newOffer.intermediaryName')"
                prop="intermediaryCode"
              >
                <ea-select
                  v-model="model.intermediaryCode"
                  :placeholder="$t('quoteBuyGenericFlow.newOffer.intermediarySelectPlaceholder')"
                  :readonly="intermediaryList && intermediaryList.length === 1"
                  @input="changeIntermediary"
                >
                  <ea-option
                    v-for="interm in intermediaryList"
                    :key="interm.intermediaryCode"
                    :label="intermediaryList &&
                      intermediaryList.length > 1 ?
                      interm.intermediaryCode + ' ' + interm.intermediaryName : interm.intermediaryName"
                    :value="interm.intermediaryCode"
                  />
                </ea-select>
              </ea-form-item>
            </ea-col>
            <ea-col v-if="model.intermediaryCode" :span="6">
              <ea-form-item :label="$t('quoteBuyGenericFlow.newOffer.intermediaryCode')">
                <ea-input-text readonly v-model="model.intermediaryCode" />
              </ea-form-item>
            </ea-col>

            <ea-button
              v-if="showLinkIntermediaries"
              @click="onChangeIntermediry"
              type="text"
              class="itermediarieBtn"
            >
              <ea-icon icon="z-user-male" />
              {{ $t('regularizationFlow.regularizationList.searchIntermediaryButton') }}</ea-button
            >
          </ea-row>
          <!-- Business Name / Code -->
          <ea-row v-if="businessList && businessList.length > 0">
            <ea-col :span="6">
              <ea-form-item :label="$t('quoteBuyGenericFlow.newOffer.businessName')"
                prop="businessCode"
                :required="partnerUser">
                <ea-select
                  v-model="model.businessCode"
                  :placeholder="$t('quoteBuyGenericFlow.newOffer.businessSelectPlaceholder')"
                  :readonly="businessList && businessList.length === 1"
                  @input="changeBusiness"
                >
                  <ea-option
                    v-for="business in businessList"
                    :key="business.businessCode"
                    :label="business.businessName"
                    :value="business.businessCode"
                  />
                </ea-select>
              </ea-form-item>
            </ea-col>
            <ea-col :span="6">
              <ea-form-item :label="$t('quoteBuyGenericFlow.newOffer.businessCode')">
                <ea-input-text readonly :value="businessCodeDisplay" />
              </ea-form-item>
            </ea-col>
          </ea-row>
          <!-- Product Name / Code -->
          <ea-row v-if="productList && productList.length > 0">
            <ea-col :span="6">
              <ea-form-item :label="$t('quoteBuyGenericFlow.newOffer.productName')" prop="productCode">
                <ea-select
                  v-model="model.productCode"
                  :placeholder="$t('quoteBuyGenericFlow.newOffer.productSelectPlaceholder')"
                  :readonly="productList && productList.length === 1"
                  @input="changeProduct"
                >
                  <ea-option
                    v-for="product in productList"
                    :key="product.productCode"
                    :label="product.productName"
                    :value="product.productCode"
                  />
                </ea-select>
              </ea-form-item>
            </ea-col>
            <ea-col :span="6">
              <ea-form-item :label="$t('quoteBuyGenericFlow.newOffer.productCode')">
                <ea-input-text readonly v-model="model.productCode" />
              </ea-form-item>
            </ea-col>
          </ea-row>
        </ea-form>

        <!-- Create Offer button -->
        <div class="m-t-8 d-display-flex d-justify-flex-end" v-if="!showTomadorForm">
          <ea-button :disabled="!isNextButtonEnabled" @click="doValidation">
            {{ $t('quoteBuyGenericFlow.newOffer.createOffer') }}
          </ea-button>
        </div>
      </div>
    </ea-card>

    <!-- Tomador data for construction -->
    <div v-if="isTomadorFormVisible">
      <qb-persons
        v-model="model.personsModel"
        :codigoRamo="codigoRamo"
        :documentTypeList="model.documentTypeList"
        :countryList="model.countryList"
        :allowedRoles="allowedRoles"
        :showTaxTreatmentAreaField=false
        :shouldShowAddresses=false
        :showBillingField="true"
        :shouldShowNewSearchInShowPersonInfo="true"
        :axesorSearchDocumentTypes="axesorSearchDocumentTypes"
        id="qb-persons"
        ref="personsComp"
        @changeTomador="onChangeTomador"
      ></qb-persons>

      <!-- Create Offer button -->
      <div class="m-t-8 d-display-flex d-justify-flex-end">
        <ea-button :disabled="!isTomadorSelected" @click="doValidation">
          {{ $t('quoteBuyGenericFlow.newOffer.createOffer') }}
        </ea-button>
      </div>
    </div>

    <!-- SI ESL USUARIO ES EMPLEADO -->
    <qb-employee-modal-intermediaries
      id="intermediaries"
      v-model="model.intermediariesEmployeeModal"
      :codigoRamo="codigoRamo"
      :closeAllFlow="closeAllFlow"
      @changeIntermediary="changeIntermediary"
      @showError="showError"
      @handleGenericError="onHandleGenericError"
      @intermediarySelected="modalIntermediarySelected"
      @closeGenericErrorDialog="closeGenericErrorDialog"
      ref="employeeModal">
    </qb-employee-modal-intermediaries>

  </div>
</template>

<script lang="ts">
import {
  Component, Prop
} from 'vue-property-decorator';

import {
  mixins
} from 'vue-class-component';

import {
  uniqBy
} from 'lodash';

import {
  EAApplicationError,
  EAApplicationLogger,
  EABusinessComponent,
  EAError,
  EAMethod,
  EAMultiError,
  EAValidationError
} from '@zurich-es-npm/ea-front-web-core';

import {
  EAFormValidationOptions, eaRequiredValidation, EAValidation, Form, EACompositeValidation
} from '@zurich-es-npm/ea-front-web-ui';

import QbNewOfferBusinessModel, {
  BusinessList, IntermediaryList, ProductList
} from './qb-new-offer-model';

import QbGenericErrorBusiness, {
  GenericErrorData
} from '../qb-generic-error/qb-generic-error-business.vue';

import {
  GetIntermediaryBusinessProductListRequest,
  EAGetIntermediaryBusinessProductListApi,
  GetIntermediaryBusinessProductListRequestCodigoOperacionIntermediarioEnum,
  GetIntermediaryBusinessProductListResponseListaIntermediarios,
  GetIntermediaryBusinessProductListRequestCodigoRamoEnum,
} from '../../services/V1/quoteAndBuy/getIntermediaryBusinessProductListOperation/post';

import {
  EACreateOfferApi,
  CreateOfferRequest,
  CreateOfferRequestTomador,
  CreateOfferRequestTomadorTipoDocumentoEnum,
  CreateOfferRequestTomadorTipoPersonaEnum
} from '../../services/V1/quoteAndBuy/createOfferOperation/post';

import Utils from '../../utils/utils';
import PermissionUtils from '@/utils/permissions-utils';
import {
  GetDefaultUserValuesResponseCodigoTipoUsuarioEnum as UserTypesEnum
} from '@/services/V1/home/getDefaultUserValuesOperation/post';
import QbEmployeeModalIntermediariesBusiness
  from '../qb-employee-modal-intermediaries/qb-employee-modal-intermediaries-business.vue';
import {
  SearchBussinessProductResponseListaNegociosProductos
} from '@/services/V1/quoteAndBuy/searchBussinessProductOperation/post/api';
import QbPersonsBusiness from '../qb-persons/qb-persons-business.vue';
import {
  Roles
} from '@/types/roles/roles-enum.types';
import {
  CorpTableNames, fetchCorporateTable, parseCorpTableDocuments
} from '@/utils/corporate-tables';
import ProductBase from '@/utils/quote-buy-product-factory/products/product-base';
import PersonUtils from '@/utils/person-utils';
import {
  PersonRole
} from '@/utils/quote-buy-product-factory/types/product-role-types';
import {
  DocumentType
} from '@/types/document-types/document-types-enum.types';

const logger = new EAApplicationLogger();

interface PreviousDataCombination {
  intermediaryCode?: string;
  businessCode?: string;
  productCode?: string;
}

@Component({
  name: 'qb-new-offer',
  components: {
    QbGenericError: QbGenericErrorBusiness,
    QbEmployeeModalIntermediaries: QbEmployeeModalIntermediariesBusiness,
    QbPersons: QbPersonsBusiness
  },
})

/**
 * Business Component Q&B New Offer
 */
export default class QbNewOfferBusiness extends mixins<EABusinessComponent<QbNewOfferBusinessModel>>(
  EABusinessComponent
) {
  @Prop()
    productFactory?: ProductBase;

  @Prop({
    required: true,
  })
    codigoRamo!: GetIntermediaryBusinessProductListRequestCodigoRamoEnum;

  @Prop({
    required: false,
    'default': () => true
  })
    canCreateOffer?: boolean;

  permutations?: GetIntermediaryBusinessProductListResponseListaIntermediarios[] = [];

  intermediaryList?: IntermediaryList[] = [];

  businessList?: BusinessList[] = [];

  productList?: ProductList[] = [];

  previousData: PreviousDataCombination | false = false; // Stored previous data

  isPreviousData = false;

  closeAllFlow: boolean = true;

  showLinkIntermediaries: boolean = false;

  resetTable: boolean = false;

  employeeUser: boolean = false;

  partnerUser: boolean = false;

  allowedRoles = [Roles.Tomador];

  // Form rules definition
  formValidation: EAFormValidationOptions = {
    rules: {
      intermediaryCode: [eaRequiredValidation()],
      productCode: [eaRequiredValidation()],
    },
  };

  /**
   * Getter to compute if next button must be enabled or not
   */
  get isNextButtonEnabled(): boolean {
    return !!this.model.intermediaryCode && !!this.model.productCode;
  }

  /**
   * Getter to compute if prev search shold be shown under modal
   */
  get shouldShow(): boolean {
    if ((this.employeeUser && this.model.intermediaryCode) || !this.employeeUser) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * Getter to compute if next button must be enabled or not
   */
  get businessCodeDisplay(): undefined | string {
    switch (this.model.businessCode) {
      case '':
        return undefined;
      case ' ':
        return this.$t('quoteBuyGenericFlow.newOffer.noBusiness').toString();
      default:
        return this.model.businessCode;
    }
  }

  /**
   * If showTomadorForm prop is true and the intermediaryCode is selected => show the form
   */
  get isTomadorFormVisible(): boolean {
    return this.showTomadorForm && !!this.model.intermediaryCode;
  }

  /**
   * Cheks if component shows tomador form for current product
   */
  get showTomadorForm(): boolean {
    return !!this.productFactory?.shouldAskForTomadorInNewOfferView;
  }

  /**
   * Disabled the button when Tomador not exist
   */
  get isTomadorSelected() {
    return !!this.model.personsModel?.personRoles?.find(
      person => person.role === Roles.Tomador
    )?.searchModel.selectedPerson;
  }

  /**
   * Hook on created
   */
  async created() {
    if (this.productFactory?.personRoles) {
      this.model.personsModel.personRoles = this.productFactory.personRoles;
    }
  
    const userType = PermissionUtils.getUserType();

    this.employeeUser = PermissionUtils.isUserOfType(userType, UserTypesEnum.EMP);

    const partnerUserTypes = [UserTypesEnum.PAU, UserTypesEnum.PBC, UserTypesEnum.PAF, UserTypesEnum.ORA];
    this.partnerUser = PermissionUtils.isUserOfType(userType, partnerUserTypes);

    this.previousData = this.getPreviousData();
    this.isPreviousData = !!this.previousData;
    await this.fetchData();

    if (!this.model.documentTypeList.length || !this.model.countryList.length) {
      await this.fetchDropdownData();
    }
   
  }


  /**
   * Get tomador data from personRoles
   * @returns {PersonRole}
   */
  getTomadorData(): PersonRole {
    return PersonUtils.getPersonByRole(this.model.personsModel.personRoles, Roles.Tomador) as PersonRole;
  }
  
  /**
   * Fetch documentType and country dropdown data
   * @returns {Promise<void>}
   */
  @EAMethod({
    loading: true,
  })
  public async fetchDropdownData(): Promise<void> {

    try {
      const promises = [
        fetchCorporateTable(CorpTableNames.DocumentTypes),
        fetchCorporateTable(CorpTableNames.Countries),
      ];
      const results = await Promise.all(promises);

      this.model.documentTypeList = parseCorpTableDocuments(results[0]);
      this.model.countryList = parseCorpTableDocuments(results[1]);

      this.update();
    } catch (err) {
      const loggerErrorMessages: string[] = [];
      let errors: EAError[] = [];

      // eslint-disable-next-line no-extra-parens
      if (typeof err.getErrorList === 'function') {
        // eslint-disable-next-line no-extra-parens
        errors = (err as EAMultiError).getErrorList() as EAError[];
      } else {
        errors = [err as EAError];
      }

      errors.forEach(error => {
        loggerErrorMessages.push(`QbSearchPersonBusiness::performSearchPerson ${error.message}`);
      });

      loggerErrorMessages.forEach(message => {
        logger.error(message);
      });

      throw new EAApplicationError('ZON00031', loggerErrorMessages);
    }
  }


  /**
   * Fetch dropdowns data
   */
  async fetchData() {
    if (this.employeeUser) {
      this.model.intermediariesEmployeeModal.showModalIntermediaries = true;
      this.showLinkIntermediaries = true;
    } else {
      this.processData(await this.fetchIntermediaryBusinessProductList());
    }
  }

  /**
   * Return previous stored data or false if no stored data
   *
   * @returns {PreviousDataCombination | false} previous data or `false` if not
   */
  getPreviousData(): PreviousDataCombination | false {
    if (this.model.intermediaryCode?.trim()) {
      const {
        intermediaryCode, businessCode, productCode
      } = this.model;
      const previous = {
        intermediaryCode: intermediaryCode,
        businessCode: businessCode,
        productCode: productCode,
      };
      return previous;
    }
    return false;
  }

  /**
   * Remove previous data object.
   */
  removePreviousData(): void {
    this.previousData = false;
  }

  /**
   * Gets the intermediary, business and product lists with given intermediary code and branch code
   */
  @EAMethod({
    loading: true,
  })
  async fetchIntermediaryBusinessProductList() {
    const api = new EAGetIntermediaryBusinessProductListApi();

    const body: GetIntermediaryBusinessProductListRequest = {
      codigoOperacionIntermediario: GetIntermediaryBusinessProductListRequestCodigoOperacionIntermediarioEnum.OF,
      codigoRamo: this.codigoRamo,
    };

    try {
      const response = await api.getIntermediaryBusinessProductListOperation({
        getIntermediaryBusinessProductListRequest: body,
      });

      if (response?.errors && response.errors[0]) {
        const genericErrorData: GenericErrorData = {
          title: 'Error',
          message: response.errors[0].message
        };
        this.showError(genericErrorData);
      } else {
        return response?.listaIntermediarios;
      }
    } catch (error) {
      const _error = error as EAError;
      logger.error(_error);
      throw new EAApplicationError('ZON00035', [_error.message]);
    }
  }

  /**
   * Intermediary select input handler
   */
  changeIntermediary() {
    this.businessList = [];
    this.productList = [];
    this.model.businessCode = '';
    this.model.productComercialCode = '';
    this.model.productTechnicalCode = '';
    this.model.productCode = '';
    const selectedIntermediary =
      this.intermediaryList?.find(intermediary => intermediary.intermediaryCode === this.model.intermediaryCode);
    this.model.intermediaryName = selectedIntermediary?.intermediaryName;
    const _businessList =
      this.permutations &&
      this.permutations.filter(permutation => permutation.codigoIntermediario === this.model.intermediaryCode);

    if (_businessList) {
      this.businessList = _businessList.map(business => {
        return {
          businessName: business.nombreNegocio || `${this.$t('quoteBuyGenericFlow.newOffer.noBusiness')}`,
          businessCode: business.codigoNegocio || ' ',
        };
      });

      this.businessList = uniqBy(this.businessList, 'businessCode');

      Utils.sortObjectArrayByProperty(this.businessList, 'businessName');
      Utils.setObjectArrayLastElement(this.businessList, 'businessName', ' ');
      
      // If we have previous stored data, set the previous business code
      if (this.previousData) {
        this.model.businessCode = this.previousData.businessCode;
        this.changeBusiness();
        return;
      }

      // If "Sin negocio" is an option, select it by default
      const foundNoBusiness = this.businessList.find(business => business.businessCode === ' ');
      if (foundNoBusiness) {
        this.model.businessCode = foundNoBusiness.businessCode;
        this.model.businessName = foundNoBusiness.businessName;
        this.changeBusiness();
        return;
      }

      if (this.businessList.length === 1) {
        this.model.businessCode = this.businessList[0].businessCode;
        this.model.businessName = this.businessList[0].businessName;
        this.changeBusiness();
      }
    }
  }

  /**
   * Intermediary info handler
   * @param {SearchBussinessProductResponseListaNegociosProductos[]} permutations
   */
  modalIntermediarySelected(permutations: SearchBussinessProductResponseListaNegociosProductos[]) {
    this.processData(
      permutations as unknown as GetIntermediaryBusinessProductListResponseListaIntermediarios[]);

    this.changeIntermediary();
  }

  /**
   * Intermediary info processor
   * @param {GetIntermediaryBusinessProductListResponseListaIntermediarios[]} permutations
   */
  processData(permutations: GetIntermediaryBusinessProductListResponseListaIntermediarios[] | undefined) {
    this.permutations = permutations;
    this.intermediaryList =
      this.permutations &&
      this.permutations.map(permutation => {
        return {
          intermediaryName: permutation.nombreIntermediario,
          intermediaryCode: permutation.codigoIntermediario,
        };
      });

    this.intermediaryList = uniqBy(this.intermediaryList, 'intermediaryCode');

    Utils.sortObjectArrayByProperty(this.intermediaryList, 'intermediaryName');

    // If we have previous stored data, set the previous intermediary code
    if (this.previousData) {
      this.changeIntermediary();
      return;
    }

    if (this.intermediaryList.length === 1) {
      this.model.intermediaryCode = this.intermediaryList[0].intermediaryCode;
      this.model.intermediaryName = this.intermediaryList[0].intermediaryName;
    }
  }

  /**
   * Business select input handler
   */
  changeBusiness() {
    this.productList = [];
    this.model.productComercialCode = '';
    this.model.productTechnicalCode = '';
    this.model.productCode = '';

    const _productList =
      this.permutations &&
      this.permutations.filter(
        permut => permut.codigoIntermediario === this.model.intermediaryCode &&
          permut.codigoNegocio === this.model.businessCode?.trim()
      );
      
    if (_productList) {
      this.productList = _productList.map(product => {
        return {
          productName: product.nombreProducto,
          productComercialCode: product.codigoComercialProducto,
          productTechnicalCode: product.codigoTecnicoProducto,
          productCode: `${product.codigoTecnicoProducto}-${product.codigoComercialProducto}`,
        };
      });

      this.productList = uniqBy(this.productList, 'productCode');

      Utils.sortObjectArrayByProperty(this.productList, 'productName');

      // If we have previous stored data, set the previous product code
      if (this.previousData) {
        this.model.productCode = this.previousData.productCode;
        this.changeProduct();
        this.removePreviousData(); // Remove previous data (all data is already used and restored)
        return;
      }

      if (this.productList.length === 1) {
        this.model.productCode = this.productList[0].productCode;
        this.model.productName = this.productList[0].productName;
        this.changeProduct();
      }
    }
  }

  /**
   * Product select input handler
   */
  changeProduct() {
    const _productList =
      this.productList && this.productList.find(product => product.productCode === this.model.productCode);

    if (_productList) {
      this.model.productComercialCode = _productList.productComercialCode;
      this.model.productTechnicalCode = _productList.productTechnicalCode;
    }

    if (
      this.intermediaryList?.length === 1 &&
      this.businessList?.length === 1 &&
      this.productList?.length === 1 &&
      !this.isPreviousData
    ) {
      // When Intermediary user we do need to do manual validation
      if (!this.employeeUser) {
        this.doValidation();
      }
    }
  }

  /**
   * Return form validation object
   * @returns {EAValidation}
   */
  public validation(): EAValidation {
    const form: Form = this.$refs.form as Form;
    // Billing validate in TRC case
    if (this.codigoRamo === GetIntermediaryBusinessProductListRequestCodigoRamoEnum.ZTRC) {
      const personComponentRef = this.$refs.personsComp as QbPersonsBusiness;

      return new EACompositeValidation(
        [form.validation(), personComponentRef.validateSelectedBilling()], this.$tc.bind(this)
      );
    }

    return form.validation();
  }

  /**
   * Updates model for the billingValue
   */
  @EAMethod()
  public onUpdateBillingData() {
    this.$nextTick(() => {
      this.update();
    });
  }

  /**
   * Execute form validation
   */
  async doValidation() {
    this.clearFlowErrors();
    await this.$nextTick();
    try {
      await this.validation().validate();
    } catch (error) {
  
      throw new EAValidationError(this.$t('common.label.validation.formWithErrors').toString());
    }

    await this.createOffer();
  }

  /**
   * Get tomador data to save when create the offer
   * @returns {CreateOfferRequestTomador | undefined}
   */
  getTomadorDataToSave(): CreateOfferRequestTomador | undefined {
    const tomador = this.getTomadorData()?.searchModel.selectedPerson;

    if (tomador) {
      const result = {
        codigoFiliacion: tomador.datosBasicosPersona?.codigoFiliacion,
        tipoPersona: Utils.getPersonType(tomador) as unknown as CreateOfferRequestTomadorTipoPersonaEnum,
        codigoIdentificacionFiscal: tomador.
          datosBasicosPersona?.codigoIdentificacionFiscal,
        primerApellido: tomador.datosBasicosPersona?.primerApellido,
        segundoApellido: tomador.datosBasicosPersona?.segundoApellido,
        nombrePropio: tomador.datosBasicosPersona?.nombrePropio,
        tipoDocumento: tomador.
          datosBasicosPersona?.tipoDocumento as unknown as CreateOfferRequestTomadorTipoDocumentoEnum,
      };

      return result;
    }
  }

  /**
   * Clear billingValue when the tomador change
   */
  onChangeTomador() {
    const tomadorIndex = this.model.personsModel.personRoles.findIndex(person => person.role === Roles.Tomador);
    this.model.personsModel.personRoles[tomadorIndex].billing.billingValue = undefined;
  }

  /**
   * Save data to view model and emit the event 'createOffer' to the view
   */
  @EAMethod({
    loading: true,
  })
  async createOffer() {
    if (this.canCreateOffer) {
      const api = new EACreateOfferApi();

      const foundPermutation =
      this.permutations &&
      this.permutations.find(
        permut => permut.codigoIntermediario === this.model.intermediaryCode &&
          permut.codigoNegocio === this.model.businessCode?.trim() &&
          permut.codigoTecnicoProducto === this.model.productTechnicalCode &&
          permut.codigoComercialProducto === this.model.productComercialCode
      );
      
      if (!foundPermutation) {
        return;
      }

      const body: CreateOfferRequest = {
        codigoEstructura: foundPermutation.codigoEstructura,
        codigoIntermediario: foundPermutation.codigoIntermediario,
        codigoNegocio: foundPermutation.codigoNegocio,
        codigoTecnicoProducto: foundPermutation.codigoTecnicoProducto,
        codigoComercialProducto: foundPermutation.codigoComercialProducto,
        tomador: this.getTomadorDataToSave(),
        facturacionEmpresa: this.getTomadorData()?.billing.billingValue ?? 0
      };

      try {
        const response = await api.createOfferOperation({
          createOfferRequest: body,
        });

        if (response?.errors?.length) {
          const genericErrorData: GenericErrorData = {
            title: 'Error',
            message: response.errors[0].message
          };
          this.showError(genericErrorData);
        } else if (response) {
          this.update();
          this.$emit('offerCreated', response.codigoPoliza, response.versionPoliza);
        }
      } catch (error) {
        // eslint-disable-next-line no-extra-parens
        if ((error as EAMultiError).getErrorList) {
          const multiError = error as EAMultiError;
          const genericErrorData: GenericErrorData = {
            title: 'Error',
            message: multiError.getErrorList()[0].message,
          };
          this.showError(genericErrorData);
        } else {
          const _error = error as EAError;
          logger.error(_error);
          const genericErrorData: GenericErrorData = {
            title: 'Error',
            message: `${this.$t('quoteBuyGenericFlow.newOffer.genericError')}`,
          };
          this.showError(genericErrorData);
          throw new EAApplicationError('ZON00036', [_error.message]);
        }
      }
    } else {
      this.update();
      this.$emit('dataSelected');
    }
  }

  /**
   * Open intermediary modal
   */
  onChangeIntermediry() {
    this.model.intermediariesEmployeeModal.showModalIntermediaries = true;
    this.model.intermediariesEmployeeModal.intermediaryCode = '';
    this.model.intermediariesEmployeeModal.intermediaryName = '';
    
    const modalComp = this.$refs.employeeModal as QbEmployeeModalIntermediariesBusiness;
    modalComp.showTable = false;
    modalComp.isSelectButtonEnabled = false;

    this.closeAllFlow = false;
    this.update();
  }

  /**
   * Emits showError event
   * @param {GenericErrorData} genericErrorData
   */
  showError(genericErrorData: GenericErrorData) {
    this.$emit('showError', genericErrorData);
  }

  /**
   * Emit the refresh data event from address to parent component
   *
   * @param {any} args
   */
  onHandleGenericError(args: any) {
    this.$emit('handleGenericError', args);
  }

  /**
   * Emits closeGenericErrorDialog event
   *
   * @param {any} args
   */
  closeGenericErrorDialog() {
    this.$emit('closeGenericErrorDialog');
  }

  /**
   * Getter for axesorSearchDocumentTypes param
   */
  get axesorSearchDocumentTypes(): DocumentType[] | undefined {
    return this.productFactory?.axesorSearchDocumentTypes;
  }
}
</script>
