/* eslint-disable max-len */
import QbAddressesPersonModel from '@/business-components/addresses/qb-addresses-person-model';
import QbGeneralDataInformationModel from '@/business-components/qb-general-data-information/qb-general-data-information-model';
import QbGenericErrorModel from '@/business-components/qb-generic-error/qb-generic-error-model';
import QbHeaderModel from '@/business-components/qb-header/qb-header-model';
import QbFractionalPaymentModel from '@/business-components/qb-fractional-payment-modal/qb-fractional-payment-modal-model';
import QbOfferInssuanceAdditionalDataModel from '@/business-components/qb-offer-issuance-additional-data/qb-offer-issuance-additional-data-model';
import QbPremiumAdjustmentsModel from '@/business-components/qb-premium-adjustments/qb-premium-adjustments-model';
import QbProductoAseguradoComunidadesModel from '@/business-components/qb-producto-asegurado-comunidades/qb-producto-asegurado-comunidades-model';
import QbProficiencyManagementModel from '@/business-components/qb-proficiency-management/qb-proficiency-management-model';
import QbSupplementMovementReasonModel from '@/business-components/qb-supplement-movement-reason/qb-supplement-movement-reason-model';
import {
  GetGeneralDataRequestTipoOperacionOfertaEnum as OperationType,
  GetGeneralDataResponseDataDatosCabecera,
  GetGeneralDataResponseDataDatosCabeceraElementosPoliza,
  GetGeneralDataResponseDataDatosCabeceraTablaRestricciones,
  GetGeneralDataResponseDataDatosObjeto
} from '@/services/V1/quoteAndBuy/getGeneralDataOperation/post';
import {
  ParsedTableData
} from '@/utils/corporate-tables';
import {
  EAFlowModel
} from '@zurich-es-npm/ea-front-web-core';
import QbOfferIssuanceBonusDataFormModel from '@/business-components/qb-offer-issuance-bonus-data-form/qb-offer-issuance-bonus-data-form-model';
import QbOfferIssuanceBillingDataFormModel from '@/business-components/qb-offer-issuance-billing-data-form/qb-offer-issuance-billing-data-form-model';
import {
  GetIssueOfferDataResponseListaDocumentos
} from '@/services/V1/quoteAndBuy/getIssueOfferDataOperation/post';
import QbDocumentationSelectDocumentationModel from '@/business-components/qb-documentation-select-documentation/qb-documentation-select-documentation-model';
import QbDocumentationSelectProposalModel from '@/business-components/qb-documentation-select-proposal/qb-documentation-select-proposal-model';
import QbOfferIssuanceDataModel from '@/business-components/qb-offer-issuance-data/qb-offer-issuance-data-model';
import {
  PolicyType
} from '@/types/policy-types/policy-types-enum.types';
import {
  Roles
} from '@/types/roles/roles-enum.types';
import QbReplacementDataModel from '@/business-components/qb-replacement-data/qb-replacement-data-model';
import QbProductoAseguradoHogarModel from '@/business-components/qb-producto-asegurado-hogar/qb-producto-asegurado-hogar-model';
import QbProductoAseguradoTrConstruccionMontajeModel from '@/business-components/qb-producto-asegurado-tr-construccion-montaje/qb-producto-asegurado-tr-construccion-montaje-model';

export interface PagadorIsSelected {
  pagadorIgualQueTomador: boolean;
  pagadorIgualQueAsegurado: boolean;
}

export enum FlowViewsStepsModel {
  MovementReasonStep = 99,
  ReplacementDataStep = 98,
  GeneralDataStep = 0,
  WarrantiesStep = 1,
  GenerateDocumentationStep = 2,
  IssueOfferStep = 3,
  SummaryAndDownloadPolicyStep = 4
}

export enum FlowHeaderStepsModel {
  GeneralDataStep = 0,
  WarrantiesStep = 1,
  IssueOfferStep = 2,
  SummaryAndDownloadPolicyStep = 3
}

export enum PolicyMovementType {
  Incompleta = '1',
  Suplemento = '2',
  SuplementoVencimiento = 'S',
}

export type ProductModel = QbProductoAseguradoComunidadesModel | QbProductoAseguradoTrConstruccionMontajeModel | QbProductoAseguradoHogarModel;

export interface BffCodesFrontRelationModel {
  bffCode: string;
  frontCode: string;
}

export interface InputModel {
  codigoPoliza: string;
  versionPoliza: number;
  tipoPoliza: PolicyType;
  hasParentFlow: boolean;
  lastSituation: string;
  maintenanceMode: boolean;
  supplementMode: boolean;
  replacementMode: boolean;
  intermediarioProductor: string;
}

export interface AdressPersonBlock {
  isVisible: boolean;
  role?: Roles;
  addressPersonModel?: QbAddressesPersonModel;
}

export enum MovementReasonEnum {
  CambioVencimiento = '180',
  CambioCEC = '700'
}

/**
 * Policy flow model
 *
 * @export
 * @class PolicyModel
 * @extends {EAFlowModel}
 */
export abstract class PolicyModel extends EAFlowModel {

  public offerNumber: string = '';

  public offerVersion: number = -1;

  public movementTypeCode: string = '';

  public offerType: PolicyType = PolicyType.Oferta;

  public operationType?: OperationType = undefined;

  public qbHeader: QbHeaderModel = new QbHeaderModel();

  public datosCabecera: GetGeneralDataResponseDataDatosCabecera = {};

  public datosAdicionales: GetGeneralDataResponseDataDatosObjeto[] = [];

  public generalDataInformationModel: QbGeneralDataInformationModel = new QbGeneralDataInformationModel();

  abstract productModel: ProductModel;

  public taxTreatmentArea: string = '';

  public taxTreatmentAreaTable: GetGeneralDataResponseDataDatosCabeceraTablaRestricciones[] = [];

  public genericErrorModel: QbGenericErrorModel = new QbGenericErrorModel();

  public isProductDataShown = false;

  public proficiencyManagementModel: QbProficiencyManagementModel = new QbProficiencyManagementModel();

  public premiumAdjustmentsModel: QbPremiumAdjustmentsModel = new QbPremiumAdjustmentsModel();

  public documentTypeList: ParsedTableData[] = [];

  public countryList: ParsedTableData[] = [];

  public fractionalPaymentModel = new QbFractionalPaymentModel();

  // eslint-disable-next-line max-len
  public documentationSelectProposalModel: QbDocumentationSelectProposalModel = new QbDocumentationSelectProposalModel();

  // eslint-disable-next-line max-len
  public documentationSelectDocumentationModel: QbDocumentationSelectDocumentationModel = new QbDocumentationSelectDocumentationModel();

  public addressPersonModel: QbAddressesPersonModel = new QbAddressesPersonModel();

  public selectedProposal: string = '';

  public warrantiesProposals: string[] = [];

  public warrantyRates: Record<string, string | number> = {}; // Relationship proposal/price (for all proposals)

  public pendingCompetenceManagement: boolean = false;

  public pendingIPIDDocumentation: boolean = false;

  public continentPrices: Record<string, string | number> = {};

  public contentPrices: Record<string, string | number> = {};

  public offerIssuanceBonusDataFormModel: QbOfferIssuanceBonusDataFormModel = new QbOfferIssuanceBonusDataFormModel();

  // eslint-disable-next-line max-len
  public offerIssuanceBillingDataFormModel: QbOfferIssuanceBillingDataFormModel = new QbOfferIssuanceBillingDataFormModel();

  // eslint-disable-next-line max-len
  public offerIssuanceAdditionalDataFormModel: QbOfferInssuanceAdditionalDataModel = new QbOfferInssuanceAdditionalDataModel();

  public filiationCode: string = '';

  public policyDocumentEmit?: GetIssueOfferDataResponseListaDocumentos [];

  public offerIssuanceDataModel: QbOfferIssuanceDataModel = new QbOfferIssuanceDataModel();

  public policyElements?: GetGeneralDataResponseDataDatosCabeceraElementosPoliza[];

  public emitOfferCode?: string = '';

  public supplementReasonModel = new QbSupplementMovementReasonModel();

  public replacementDataModel = new QbReplacementDataModel();

  public ficoFicError: string[] = [];

  public hasParentFlow: boolean = false;

  public supplementMode: boolean = false;

  public replacementMode: boolean = false;

}
