/**
 * Model main-address-change
 */
class MainAddressChangeModel {

  public hidden: boolean = true;

}

export default MainAddressChangeModel;
