export enum FlowThunderNameEnum {
  QuoteAndBuyMaintenance = 'QuoteAndBuyMaintenance',
  QuoteAndBuyInquiry = 'QuoteAndBuyInquiry',
  PolicyInquiry = 'PolicyInquiry',
  SearchPolicyFlow = 'PolicyList',
  SearchOfferFlow = 'OfferList',
  OfferMaintenance = 'OfferMaintenance',
  PolicyMaintenance = 'PrepolicySearch',
  SupplementsList = 'SupplementsList',
  FleetMaintenance = 'FleetMaintenance',
  FleetMaintenancePolicy = 'FleetMaintenancePolicy',
  InsuranceDocument = 'InsuranceDocument'
}


export enum SubflowThunderNameEnum {
  DocumentationFlow='DocumentationFlow',
  CompetencesFlow='CompetencesFlow',
  NotesFlow='NotesFlow'
}

export enum FlowEnum { // Not necessary flownames, logic purposes
  Fleets = 'Fleets',
  Policy = 'Policy',
  Supplements = 'Supplements',
  Replacements = 'Replacements',
  Offer = 'Offer',
  CardPaymentPolicies = 'CardPaymentPolicies',
  InsuranceDocument = 'InsuranceDocument'
}

