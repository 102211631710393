<template>

  <ea-dialog
    :visible.sync="model.showModalNotes"
    @close="onCloseModal"
    type="passive"
    :closeOnClickModal="false"
    size="small"
    :title="$t('notesModal.title')"
    >
    <span>
      <ea-text :in-line="false" size="medium" weight="semibold" class="p-b-20 p-t-8">
        {{ model.noteTitle }}
      </ea-text>
      <div>
        {{ model.description }}
      </div>
    </span>

    <div class="d-display-flex d-align-flex-start bd-highlight m-t-32">
      <ea-button class="bd-highlight"
      type="secondary" @click="onCloseModal" size="medium">{{ $t('notesModal.close') }}</ea-button>
      <ea-button
      class="bd-highlight m-l-auto"
      type="primary"
      @click="onReadNoteClick" :disabled="!model.canMark" size="medium">{{ $t('notesModal.read') }}</ea-button>
    </div>
  </ea-dialog>

</template>

<script lang="ts">
import {
  Component
} from 'vue-property-decorator';

import {
  mixins
} from 'vue-class-component';

import {
  EABusinessComponent, EAMethod, ResponseWithErrors, throwIfResponseHasErrors
} from '@zurich-es-npm/ea-front-web-core';

import NotesDescriptionModalModel from './notes-description-modal-model';

import {
  EAUpdateNotesReadApi
} from '@/services/V1/notes/updateNotesReadOperation/post';

@Component({
  name: 'notes-description-modal'
})

/**
 * Business Component notes-description-modal
 */
export default class
NotesDescriptionModalBusiness extends mixins<EABusinessComponent<NotesDescriptionModalModel>>(EABusinessComponent) {

  /**
   * Close modal
   */
  onCloseModal() {
    this.model.showModalNotes = false;
    this.update();
  }

  /**
   * Read Note
   */
  @EAMethod({
    loading: true,
  })
  async onReadNoteClick() {
    await this.updateNoteRead();
    this.$emit('relaunchGetNotes');
    this.model.showModalNotes = false;
    this.update();
    
  }

  /**
   * Read Note BFF
   */
  @EAMethod({
    loading: true,
  })
  async updateNoteRead() {
    const api = new EAUpdateNotesReadApi();
    const response = await api.updateNotesReadOperation({
      updateNotesReadRequest: {
        codigoTareaAviso: this.model.codigoTareaAviso
      }
    });

    if (response) {
      throwIfResponseHasErrors(response as ResponseWithErrors);
    }
  }

}
</script>
