import {
  RangeRestriction
} from './range-restriction-model';

/**
 * 
 */
export class FleetRestriction {
  public range: RangeRestriction = new RangeRestriction();
}
