/* eslint-disable */

/**
 * zonazurich
 * Portal de Mediación Digital
 *
 * The version of the OpenAPI document: 1
 * Contact: digitalarchitecture@zurich.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import {
  EASDKConfig,
  EAUrlUtils
} from '@zurich-es-npm/ea-shared-utils-js';

import * as globalImportUrl from 'url';
import {
  Configuration
} from './configuration';
import globalAxios, {
  AxiosPromise,
  AxiosInstance,
  AxiosResponse
} from 'axios';

const BASE_PATH = EAUrlUtils.removeLastForwardSlash('http://localhost:3000');

/**
 *
 * @export
 */
export const COLLECTION_FORMATS = {
  csv: ',',
  ssv: ' ',
  tsv: '\t',
  pipes: '|',
};

/**
 *  
 * @export
 * @interface RequestArgs
 */
export interface RequestArgs {
  url: string;
  options: any;
}

/**
 * 
 * @export
 * @class BaseAPI
 */
export class BaseAPI {
  protected configuration: Configuration | undefined;

  /**
   *Creates an instance of BaseAPI.
   * @param {Configuration} [configuration]
   * @param {string} [basePath=BASE_PATH]
   * @param {AxiosInstance} [axios=globalAxios]
   * @memberof BaseAPI
   */
  public constructor(
    configuration?: Configuration,
    protected basePath: string = BASE_PATH,
    protected axios: AxiosInstance = globalAxios) {
    if (configuration) {
      this.configuration = configuration;
      this.basePath = configuration.basePath || this.basePath;
    }
  }
}

/**
 * 
 * @export
 * @class RequiredError
 * @extends {Error}
 */
export class RequiredError extends Error {
  public name: 'RequiredError' = 'RequiredError';

  public field: string;

  /**
   *Creates an instance of RequiredError.
   * @param {string} field
   * @param {string} [msg]
   * @memberof RequiredError
   */
  public constructor(field: string, msg?: string) {
    super(msg);
    this.field=field;
  }
}

/**
 * 
 * @export
 * @class AsyncRequestError
 * @extends {Error}
 */
export class AsyncRequestError extends Error {
  public name: 'AsyncRequestError' = 'AsyncRequestError';

  /**
   *Creates an instance of AsyncRequestError.
   * @param {string} field
   * @param {string} [msg]
   * @memberof AsyncRequestError
   */
  public constructor(msg?: string) {
    super(msg);
  }
}

/**
 * 
 * @export
 * @interface Error
 */
export interface Error {
    /**
     * 
     * @type {string}
     * @memberof Error
     */
    code: string;
    /**
     * 
     * @type {string}
     * @memberof Error
     */
    message: string;
    /**
     * 
     * @type {number}
     * @memberof Error
     */
    severity: number;
    /**
     * 
     * @type {Array<object>}
     * @memberof Error
     */
    childErrors?: Array<object>;
}/**
 * 
 * @export
 * @interface GetProductListRequest
 */
export interface GetProductListRequest {
    /**
     * 
     * @type {string}
     * @memberof GetProductListRequest
     */
    codigoTecnicoProducto?: string;
    /**
     * 
     * @type {string}
     * @memberof GetProductListRequest
     */
    codigoComercialProducto?: string;
    /**
     * 
     * @type {string}
     * @memberof GetProductListRequest
     */
    codigoIntermediario?: string;
    /**
     * 
     * @type {string}
     * @memberof GetProductListRequest
     */
    codigoNegocio?: string;
    /**
     * 
     * @type {string}
     * @memberof GetProductListRequest
     */
    codigoTipologia?: string;
    /**
     * 
     * @type {string}
     * @memberof GetProductListRequest
     */
    codigoOperacion?: GetProductListRequestCodigoOperacionEnum;
    /**
     * 
     * @type {string}
     * @memberof GetProductListRequest
     */
    codigoRamo?: GetProductListRequestCodigoRamoEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum GetProductListRequestCodigoOperacionEnum {
    LO = 'LO',
    RE = 'RE',
    DE = 'DE'
}
/**
    * @export
    * @enum {string}
    */
export enum GetProductListRequestCodigoRamoEnum {
    THCO = 'THCO',
    COMU = 'COMU',
    LPAU = 'LPAU',
    THFL = 'THFL',
    ZTRC = 'ZTRC',
    LPHG = 'LPHG',
    HOME = 'HOME',
    THHG = 'THHG',
    THHD = 'THHD'
}
/**
 * 
 * @export
 * @interface GetProductListResponse
 */
export interface GetProductListResponse {
    /**
     * 
     * @type {Array<GetProductListResponseListaProductos>}
     * @memberof GetProductListResponse
     */
    listaProductos?: Array<GetProductListResponseListaProductos>;
    /**
     * 
     * @type {number}
     * @memberof GetProductListResponse
     */
    numeroDeRegistros?: number;
    /**
     * 
     * @type {boolean}
     * @memberof GetProductListResponse
     */
    esUltimaPagina?: boolean;
    /**
     * 
     * @type {Array<Error>}
     * @memberof GetProductListResponse
     */
    errors?: Array<Error>;
}/**
 * 
 * @export
 * @interface GetProductListResponseListaProductos
 */
export interface GetProductListResponseListaProductos {
    /**
     * 
     * @type {string}
     * @memberof GetProductListResponseListaProductos
     */
    codigoViaAcceso?: string;
    /**
     * 
     * @type {string}
     * @memberof GetProductListResponseListaProductos
     */
    codigoTipologia?: string;
    /**
     * 
     * @type {string}
     * @memberof GetProductListResponseListaProductos
     */
    codigoRamo?: GetProductListResponseListaProductosCodigoRamoEnum;
    /**
     * 
     * @type {number}
     * @memberof GetProductListResponseListaProductos
     */
    codigoSecuenciaListado?: number;
    /**
     * 
     * @type {string}
     * @memberof GetProductListResponseListaProductos
     */
    indicadorConsulta?: string;
    /**
     * 
     * @type {string}
     * @memberof GetProductListResponseListaProductos
     */
    indicadorGestionOferta?: string;
    /**
     * 
     * @type {string}
     * @memberof GetProductListResponseListaProductos
     */
    indicadorOferta?: string;
    /**
     * 
     * @type {string}
     * @memberof GetProductListResponseListaProductos
     */
    indicadorSuplementoEconomico?: string;
    /**
     * 
     * @type {string}
     * @memberof GetProductListResponseListaProductos
     */
    indicadorReemplazo?: string;
    /**
     * 
     * @type {string}
     * @memberof GetProductListResponseListaProductos
     */
    indicadorPolizaCI?: string;
    /**
     * 
     * @type {string}
     * @memberof GetProductListResponseListaProductos
     */
    indicadorMedidador?: string;
    /**
     * 
     * @type {string}
     * @memberof GetProductListResponseListaProductos
     */
    codigoTecnicoProducto?: string;
    /**
     * 
     * @type {string}
     * @memberof GetProductListResponseListaProductos
     */
    codigoComercialProducto?: string;
    /**
     * 
     * @type {string}
     * @memberof GetProductListResponseListaProductos
     */
    descripcionProducto?: string;
    /**
     * 
     * @type {string}
     * @memberof GetProductListResponseListaProductos
     */
    codigoEstructuraProducto?: string;
    /**
     * 
     * @type {string}
     * @memberof GetProductListResponseListaProductos
     */
    codigoPanel?: string;
    /**
     * 
     * @type {string}
     * @memberof GetProductListResponseListaProductos
     */
    indicadorNegocio?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum GetProductListResponseListaProductosCodigoRamoEnum {
    THCO = 'THCO',
    COMU = 'COMU',
    LPAU = 'LPAU',
    THFL = 'THFL',
    ZTRC = 'ZTRC',
    LPHG = 'LPHG',
    HOME = 'HOME',
    THHG = 'THHG',
    THHD = 'THHD'
}


type getProductListOperationParams = {
  getProductListRequest: GetProductListRequest,
};

/**
 * GetProductListApi - axios parameter creator
 * @param {Configuration} [configuration]
 * @returns {any}
 */
const GetProductListApiAxiosParamCreator = function(configuration?: Configuration): any {
  return {
    
    /**
     * Devuelve la lista de productos a partir de unos criterios
     * @param {GetProductListRequest} getProductListRequest 
     * @param {string} [acceptLanguage] El Accept-Language request HTTP header indica en que lenguaje debe responder el backend.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @returns {RequestArgs}
     */
    getProductListOperation(params: getProductListOperationParams, options: any = {}): RequestArgs {
      // Verify required parameter 'getProductListRequest' is not null or undefined
      if (params.getProductListRequest === null || params.getProductListRequest === undefined) {
        throw new RequiredError('getProductListRequest', 'Required parameter getProductListRequest was null or undefined when calling getProductListOperation.');
      }
      const localVarPath = `/v1/common/getProductListOperation`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true) as Partial<globalImportUrl.UrlWithParsedQuery>;
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;


  
      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
      // Fix override query string Detail: https://stackoverflow.com/a/7517673/1077943

      delete localVarUrlObj.search;
      localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
      const needsSerialization = ('GetProductListRequest' as any !== 'string') ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization ? JSON.stringify(params.getProductListRequest || {}) : params.getProductListRequest || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * GetProductListApi - functional programming interface
 * @param {Configuration} [configuration]
 * @returns {any}
 */
const GetProductListApiFp = function(configuration?: Configuration): any {
  return {
    
    /**
     * Devuelve la lista de productos a partir de unos criterios
     * @param {GetProductListRequest} getProductListRequest 
     * @param {string} [acceptLanguage] El Accept-Language request HTTP header indica en que lenguaje debe responder el backend.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @returns {any}
     */
    getProductListOperation(params: getProductListOperationParams, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetProductListResponse> {
      const localVarAxiosArgs = GetProductListApiAxiosParamCreator(configuration).getProductListOperation(params, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH): any => {
        const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * GetProductListApi - factory interface
 * @param {Configuration} configuration
 * @param {string} basePath
 * @param {AxiosInstance} axios
 * @export
 * @returns {any}
 */
const GetProductListApiFactory = function(configuration?: Configuration, basePath?: string, axios?: AxiosInstance): any {
  return {
    
    /**
     * Devuelve la lista de productos a partir de unos criterios
     * @param {GetProductListRequest} getProductListRequest 
     * @param {string} [acceptLanguage] El Accept-Language request HTTP header indica en que lenguaje debe responder el backend.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @returns {any}
     */
    getProductListOperation(params: getProductListOperationParams, options?: any): any {
      return GetProductListApiFp(configuration).getProductListOperation(params, options)(axios, basePath);
    },
  };
};

/** 
 * GetProductListApi - object-oriented interface
 * @export
 * @class GetProductListApi
 * @extends {BaseAPI}
 */
export class GetProductListApi extends BaseAPI {
  
  /**
   * Devuelve la lista de productos a partir de unos criterios
   * @param {GetProductListRequest} getProductListRequest 
   * @param {string} [acceptLanguage] El Accept-Language request HTTP header indica en que lenguaje debe responder el backend.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @returns {any}
   * @memberof GetProductListApi
   */
  public getProductListOperation(params: getProductListOperationParams, options?: any): any {
    return GetProductListApiFp(this.configuration).getProductListOperation(params, options)(this.axios, this.basePath);
  }

}

/**
 * 
 * @export
 * @class EAgetProductListOperationInputMessage
 */
export class EAgetProductListOperationInputMessage {
  
  /**
   * 
   * @type {GetProductListRequest}
   * @memberof EAgetProductListOperationInputMessage
   */
  public getProductListRequest: GetProductListRequest;

  public constructor(getProductListRequest: GetProductListRequest, ){
  
  this.getProductListRequest=getProductListRequest;
  } 
}



/** 
 * EAGetProductListApi - Architecture client for getProductListOperation
 * @export
 * @class EAGetProductListApi
 */
export class EAGetProductListApi {

  /**
   * 
   * @param {GetProductListRequest} getProductListRequest
   
   * @param {Configuration} configuration
   * @throws {AsyncRequestError | RequiredError}
   * @returns {Promise<GetProductListResponse | null>}
  */ 
  public async getProductListOperation(params: getProductListOperationParams, configuration: Configuration = {}): Promise<GetProductListResponse | null> {
    return this.getProductListOperationPromise(params, configuration);
  }

  /**
   * 
   * @param {GetProductListRequest} getProductListRequest
   
   * @param {Configuration} configuration
   * @param {Function} callback
   * @throws {AsyncRequestError | RequiredError}
   */
  public async getProductListOperationSteps(params: getProductListOperationParams, configuration: Configuration, callback: (res: AxiosResponse<GetProductListResponse | null>) => void) {
    let done: boolean = false;
    const httpClient: AxiosInstance = EASDKConfig.getHttpClient('/v1/common/getProductListOperation', 'getProductList', 'getProductListOperation');
    const api = new GetProductListApi(configuration, '', httpClient);
    const sleep = async(ms: number) => {
      return new Promise(resolve => setTimeout(resolve, ms));
    };
    const headers: { ['Async-Id']?: string } = {};
    while (!done) {
      const output = await api.getProductListOperation(params, { headers }) as AxiosResponse<GetProductListResponse | null>;
      callback(output);
      if (output.status === 202) {
        try {
          const retry = parseInt(output.headers['retry']);
          headers['Async-Id'] = output.headers['request-id'];
          if (isNaN(retry) || retry <= 0 || retry > 1000 * 3600) {
            throw new Error(`Retry time not acceptable (in milliseconds): ${retry}`);
          }
          await sleep(retry);
        } catch (err) {
          throw new Error(`Error in Async Request ${err}`);
        }
      } else {
        done = true;
      }
    }
  }

  /**
   * 
   * @param {GetProductListRequest} getProductListRequest
   
   * @param {Configuration} configuration
   * @throws {AsyncRequestError | RequiredError}
   * @returns {Promise<GetProductListResponse | null>}
   */
  public async getProductListOperationPromise(params: getProductListOperationParams, configuration: Configuration): Promise<GetProductListResponse | null> {
    return new Promise((resolve, reject) => {
      this.getProductListOperationSteps(params, configuration, (response: AxiosResponse<GetProductListResponse | null>) => {
        if (response.status !== 202) {
          resolve(response.data);
        }
      })
      .catch((err) => {
        reject(err);
      });
    });
  } 
}


