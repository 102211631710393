import {
  ParsedTableData
} from '@/utils/corporate-tables';
import PostCodeLocationInputModel from '../post-code-location-input/post-code-location-input-model';

export interface FormValues {
  contractNumber?: string;
  applicationNumber?: string;
  officeCode?: string;
  officeDescription?: string;
  agencyAddress?: string;
  agencyCity?: string;
  agencyProvince?: string;
  agencyCP?: string;
}


/** 
 * Model qb-bank-details
 */
class QbBankDetailsModel {
  public FIELD_TYPES = {
    NUMBER: 'number',
    STRING: 'string',
    DEFAULT: 'default',
  };

  public formValues: FormValues = {
    contractNumber: '',
    applicationNumber: '',
    officeCode: '',
    officeDescription: '',
    agencyAddress: '',
    agencyCity: '',
    agencyProvince: '',
    agencyCP: ''
  };

  public countryList: ParsedTableData[] = [];

  public postCodeLocationInputModel: PostCodeLocationInputModel = new PostCodeLocationInputModel();

}

export default QbBankDetailsModel;
