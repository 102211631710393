<template>
  <div class="slide d-display-flex d-direction-column d-justify-space-between h-100">
    <div>
      <h2
        v-if="!hideFirstTitle"
        class="t-color-primary m-b-0"
        >
        {{ $t('onboarding.carNbLc.firstSlide.title1') }}
      </h2>
      <i18n path="onboarding.carNbLc.firstSlide.title2.text" tag="p">
        <span class="t-weight-semibold">{{ $t('onboarding.carNbLc.firstSlide.title2.bold') }}</span>
      </i18n>
    </div>
    
    <div class="d-display-flex d-align-items-center d-justify-center p-a-32 w-100 t-bg-color-gray-20">
      <img
        :src="require(`@/assets/images/onboarding/carNbLc/onboarding1.png`)"
        :alt="$t('onboarding.carNbLc.firstSlide.title1').toString()"
      >
    </div>

    <div>
      <i18n path="onboarding.carNbLc.firstSlide.listItems.first.text" tag="p" class="m-b-0">
        <span class="t-weight-semibold">{{ $t('onboarding.carNbLc.firstSlide.listItems.first.bold') }}</span>
      </i18n>
      <i18n path="onboarding.carNbLc.firstSlide.listItems.second.text" tag="p" class="m-b-0">
        <span class="t-weight-semibold">
          {{ $t('onboarding.carNbLc.firstSlide.listItems.second.bold') }}
        </span>
      </i18n>
      <i18n path="onboarding.carNbLc.firstSlide.listItems.third.text" tag="p" class="m-b-0">
        <span class="t-weight-semibold">{{ $t('onboarding.carNbLc.firstSlide.listItems.third.bold') }}</span>
      </i18n>
    </div>

    <i18n path="onboarding.carNbLc.firstSlide.footer.text" tag="p">
      <a target="_blank" rel="noopener" href="https://bcove.video/4bDCbWH">
        {{ $t('onboarding.carNbLc.firstSlide.footer.link') }}
      </a>
    </i18n>
  </div>
</template>
<script lang="ts">

import Vue from 'vue';
import {
  Component, Prop
} from 'vue-property-decorator';

@Component({
  name: 'car-nb-lc-onboarding-1'
})

/**
 * Comunidades onboarding component
 */
export default class CarNbLc1Component extends Vue {

  @Prop({
    required: false,
    'default': () => false,
  })
    hideFirstTitle?: boolean;
}
</script>
