import {
  PersonRole
} from '@/utils/quote-buy-product-factory/types/product-role-types';

/** 
 * Model qb-persons
 */
class QbPersonsModel {

  public personRoles: PersonRole[] = [];

  public indicadorDataQuality?: boolean;

  public scoringFieldsInvalidated?: boolean;

}

export default QbPersonsModel;
