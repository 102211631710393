<template>
  <div>
     <ea-table
        :data="model"
        ref="driverTable"
        id="driverTable"
        :infinite-scroll="true"
        class="thead-fixed"
        multiSelect
      >
        <template slot="top-table">
          <div class="ea-toolbar-action-wrapper d-display-flex d-direction-reverse">
            <ea-button type="secondary" @click="onDeleteDriver">
              {{ $t('common.label.remove') }}
            </ea-button>
          </div>
        </template>
        <ea-table-column
          v-for="column in columnList"
          :key="column.propKey"
          :label="column.labelKey ?
            $t(`fleets.fleetsFlow.fleetsInterveningDriverTable.tableDataHeaders.${column.labelKey}`) : ''"
          headerClass="no-sortBy"
          
        >
          <template slot-scope="row">
            <template v-if="column.propKey === 'edit'">
              <ea-button-icon
                @click="onEdit(row)"
                type="text"
                class="t-size-14"
                icon="z-edit">
              </ea-button-icon>
            </template>
            <template v-else>
              <p class="ea-table-td-content" tabindex="0">
                {{ row[column.propKey] }}
              </p>
            </template>
          </template>
   
        </ea-table-column>
      </ea-table>
  </div>
</template>

<script lang="ts">
import {
  Component, Prop
} from 'vue-property-decorator';

import {
  mixins
} from 'vue-class-component';

import {
  EAApplicationLogger,
  EABusinessComponent
} from '@zurich-es-npm/ea-front-web-core';
import {
  ColumnItem, ColumnItemType
} from '../qb-policy-history-modal/qb-policy-history-modal-model';

import FleetsInterveningDriverTableModel, {
  DriverListInterface
} from './fleets-intervening-driver-table-model';
import {
  Table
} from '@zurich-es-npm/ea-front-web-ui';
import {
  SelectDriverInterface
} from '../qb-search-person/qb-search-person-model';


@Component({
  name: 'fleets-intervening-driver-table'
})

/**
 * Business Component fleets-intervening-driver-table
 */
export default class FleetsInterveningDriverTableBusiness extends
  mixins<EABusinessComponent<FleetsInterveningDriverTableModel>>(EABusinessComponent) {

  @Prop()
    addDrivers!: SelectDriverInterface[]; // Get the list from searchModel.selectedDrivers

  ColumnItemType = ColumnItemType;

  columnList: ColumnItem[] = [
    {
      propKey: 'plateNumber',
      labelKey: 'plateNumber',
    },
    {
      propKey: 'brand',
      labelKey: 'brand',
    },
    {
      propKey: 'model',
      labelKey: 'model',
    },
    {
      propKey: 'version',
      labelKey: 'version',
    },
    {
      propKey: 'documentNumber',
      labelKey: 'documentNumber',
    },
    {
      propKey: 'firstName',
      labelKey: 'firstName',
    },
    {
      propKey: 'lastName',
      labelKey: 'lastName',
    },
    {
      propKey: 'edit',
      labelKey: '',
      colType: ColumnItemType.IconButton,
    }
  ];

  deleteDrivers: SelectDriverInterface[] = [];


  /**
   * Emit onEditDriverPerson to show the edit modal
   * @param {DriverListInterface} row
   */
  onEdit(row: DriverListInterface) {
    this.$emit('onEditDriverPerson', row);
  }

  /**
   * Delete driver data
   */
  onDeleteDriver() {
    const eaTable = this.$refs.driverTable as Table ;
    if (!eaTable) {
      new EAApplicationLogger().error(
        `FleetInterveningDriverTable::onDeleteDriver:: table component driverTable not found`
      );
      return;
    }

    const rows = eaTable.getSelectedRows();

    if (!rows.length) {
      new EAApplicationLogger().error(
        `FleetInterveningDriverTable::onDeleteDriver:: rows of table component driverTable not found`
      );
      return;
    }
    const drivers = rows.map(row => row.getData()) as DriverListInterface[];

    this.deleteDrivers = [];

    // Find all the data from searchModel.selectedDrivers to push it in deleteDrivers
    drivers.forEach(driverTable => {
      this.addDrivers.forEach(data => {
        if (driverTable.plateNumber === data.vehicle?.plateNumber) {
          this.deleteDrivers.push(data);
        }
      });
    });

    // Emit the list of vehicles to delete
    this.$emit('driversToDelete', this.deleteDrivers);
  }

}
</script>
