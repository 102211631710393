import {
  EACorpTablesApi,
  MenuItem
} from '../../services/V1/coexistence/menu/get';

/**
 * Returns the list of operation
 *
 * @class EAPivotService
 */
class EAPivotService {

  // Operations
  private static operations: MenuItem[] | undefined;

  /**
   *
   * Get pivot operations from service
   *
   * @static
   * @returns {Promise<MenuItem[]>}
   * @memberof EAPivotService
   */
  public static async getPivotOperations(): Promise<MenuItem[]> {

    if (EAPivotService.operations) {
      return EAPivotService.operations;
    }

    const api = new EACorpTablesApi();

    const response = await api.coexistenceMenu({ });

    if (response && response.menu) {
      // Sort by operation id
      const menu = response.menu ?
        response.menu.sort((itemA, itemB) => parseInt(itemA.id || '') - parseInt(itemB.id || '')) : [];
      
      EAPivotService.operations = menu;
      return menu;

    } else {
      throw new Error('No pivot operations found');
    }

  }

  /**
   * Returns an operation item
   *
   * @static
   * @param {string} operationId
   * @returns {MenuItem | undefined}
   * @memberof EAPivotService
   */
  public static async getPivotOperation(operationId: string): Promise<MenuItem | undefined> {
    const operations = await EAPivotService.getPivotOperations();

    return operations.find(item => item.id === operationId);
  }

}

export {
  EAPivotService
};
