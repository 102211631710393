<template>
  <div>
    <ea-flow-container>
      <div>
        <notification-handler/>
      </div>
      
      <div>
        <ea-row>
          <ea-col>
            <qb-header
              id="policy-qb-header"
              v-model="model"
              :title="title"
              :subtitle="subTitle"
              :parentFlowId="flowId"
              :headerSpan="12"
              ></qb-header>
          </ea-col>
          <ea-col>
            <notes
              v-model="model"
              @goToParentFlow="goToParentFlow"
              @showError="showGenericError"
              @handleGenericError="handleGenericError"
            />
          </ea-col>
        </ea-row>
      </div>

      <!-- Generic Error Business Component -->
      <qb-generic-error
        id="genericError"
        v-model="model.genericErrorModel"
        :isVisible="isGenericErrorVisible"
        :modalData="genericErrorData"
        @close="closeGenericErrorDialog"
      ></qb-generic-error>
    </ea-flow-container>
  </div>
</template>

<script lang="ts">
import {
  Component
} from 'vue-property-decorator';

import {
  mixins
} from 'vue-class-component';

import {
  EAFlow,
  EAFlowNavigation,
  EAApplicationLogger,
  EAMethod,
  EALocaleManager,
  EAApplicationError,
  EAError,
  EAMultiError
} from '@zurich-es-npm/ea-front-web-core';

import NotesView from './views/notes-view.vue';

import {
  InputModel,
  NotesModel
} from './notes-model';
import QbHeaderBusiness from '@/business-components/qb-header/qb-header-business.vue';
import {
  PolicyType
} from '@/types/policy-types/policy-types-enum.types';
import QbGenericErrorBusiness, {
  GenericErrorData
} from '@/business-components/qb-generic-error/qb-generic-error-business.vue';
import NotificationHandler from '@/presentational-components/notification-handler/notificationHandler.vue';

const logger = new EAApplicationLogger();

@Component({
  components: {
    QbHeader: QbHeaderBusiness,
    notes: NotesView,
    QbGenericError: QbGenericErrorBusiness,
    'notification-handler': NotificationHandler
  }
})

/** 
 * Notes flow
 * 
 */
export default class NotesFlow extends mixins<EAFlow<NotesModel>>(EAFlow) {
  
  public flowId: string = '';

  public genericErrorData: GenericErrorData = {};

  public isGenericErrorVisible = false;

  /** 
   * Initialize flow model
   */
  constructor() {
    super();
    logger.debug('NotesFlow::constructor');
    this.model = new NotesModel();
    this.navigation = new EAFlowNavigation();
    this.navigation.maxWindows = 1;
  }

  /** 
   * Share policyCode
   */
  created(): void {
    const {
      codigoPoliza, versionPoliza, tipoPoliza
    }: InputModel = this.inputModel;
    this.model.policyNumber = codigoPoliza;
    this.model.policyType = tipoPoliza;
    this.model.policyVersion = versionPoliza;
    this.model.genericErrorModel = {
      title: '',
      isVisible: false,
      changeStepOnClose: undefined,
      showStepOnClose: undefined
    };
  }

  /** 
   * Get title
   */
  get title(): string {
    return EALocaleManager.i18n.t('notesFlow.mainNotesView.title') as string;
  }


  /** 
   * Get subtitle
   */
  get subTitle(): string {
    const version = EALocaleManager.i18n.t('notesFlow.mainNotesView.version', {
      version: this.model.policyVersion
    }) as string;

    const policy = this.model.policyType === PolicyType.Oferta ?
      EALocaleManager.i18n.t('notesFlow.mainNotesView.subTitleOfferType', {
        number: this.model.policyNumber
      }) as string : EALocaleManager.i18n.t('notesFlow.mainNotesView.subTitlePolicyType', {
        number: this.model.policyNumber
      }) as string;

    return this.model.policyVersion > 0 ? `${policy} ${version}` : `${policy}`;
  }

  /** 
   * Returns control to the parent flow and returns to the previous view.
   */
  @EAMethod()
  goToParentFlow() {
    const outputModel = {
      codigoPoliza: this.model.policyNumber,
    };
  
    this.returnToParentFlow(outputModel);
  }

  /**
   * Shows error modal
   * @param {GenericErrorData} args - params for error modal
   */
  showGenericError(args: GenericErrorData) {
    const {
      title,
      message,
      messages,
      errors,
      changeStepOnClose,
      showStepOnClose
    } = args;

    this.genericErrorData = {
      title,
      message,
      messages,
      errors,
      changeStepOnClose,
      showStepOnClose
    };
    this.isGenericErrorVisible = true;
  }

  /**
   * Handles general data services errors
   * @param {any} args
   */
  public handleGenericError(args: any) {
    const {
      error, errorCode, changeStepOnClose
    } = args;
    // eslint-disable-next-line no-extra-parens
    if ((error as EAMultiError).getErrorList) {
      const multiError = error as EAMultiError;
      this.genericErrorData = {
        title: 'Error',
        errors: multiError.getErrorList().map(_err => {
          return {
            message: _err.message
          };
        }),
        changeStepOnClose
      };
      this.isGenericErrorVisible = true;
    } else {
      const _error = error as EAError;
      this.genericErrorData = {
        title: 'Error',
        message: _error.message,
        changeStepOnClose
      };
      this.isGenericErrorVisible = true;
      throw new EAApplicationError(errorCode, [_error.message]);
    }
  }

  /**
   * Closes generic dialog
   */
  closeGenericErrorDialog() {
    this.genericErrorData = {
      title: '',
      changeStepOnClose: undefined,
      showStepOnClose: undefined
    };
    this.isGenericErrorVisible = false;
  }

}
</script>
