<template>
  <div
    :id="loadingContainerId"
    v-loading="loading"
    :element-loading-text="$t('fleets.fleetsFlow.inputVehiclesData.loadingBaseSiete.loadingText')"
  >
    <div>
      <div>
        <fleet-unified-expiration
          ref="refFleetUnifiedExpirationBC"
          id="unified-expiration"
          v-model="model.fleetUnifiedExpirationModel"
          :disabledBase7Mode="disabledBase7Mode"
          :showUpdateDateButton="false"
          :readOnlyOperation="readonlyVehiclesExpiration">
        </fleet-unified-expiration>

       <fleet-input-vehicles-data
          id="input-vehicles"
          class="m-t-24"
          v-model="model.fleetInputVehiclesDataModel"
          :fleetUnifiedExpirationModel="model.fleetUnifiedExpirationModel"
          :allowedPlateTypes="allowedPlateTypes"
          :noPlateOption="noPlateOption"
          :vehicleRestrictions="model.flowConfiguration.vehiclesDataView.unifiedDateRestriction.vehiclesRestrictions"
          :fleetCode="model.offerNumber"
          :fleetVersion="model.offerVersion"
          :vehiclesNumberRange="vehiclesNumberRange"
          :allVehicleUses="allVehicleUses"
          :inputMode="manualMode"
          :operationType="model.operationType"
          :alreadyEmmitedVehicles="model.emmitedVehicles"
          :pamVehicleTypes="pamVehicleTypes"
          @disabledBase7Mode="onDisabledBase7Mode"
          @continueButtonDisabled="continueButtonDisabled = $event;">
        </fleet-input-vehicles-data>
      </div>
      <ea-row extraClass="m-t-24">
        <ea-col class="d-display-flex d-align-items-center d-justify-space-between">
          <ea-button
            type="secondary"
            :disabled="disabledBase7Mode"
            @click="onGoBack()"
          >
            {{ $t('common.label.back') }}
          </ea-button>
          <ea-button
            id="nextStep"
            type="primary"
            :disabled="continueButtonDisabled"
            @click="onGoNext()"
            >
            {{ $t('common.label.next') }}
          </ea-button>
        </ea-col>
      </ea-row>
    </div>
  </div>
</template>

<script lang="ts">
import {
  Component, Watch
} from 'vue-property-decorator';
import {
  mixins
} from 'vue-class-component';
import {
  EAApplicationError,
  EAApplicationLogger,
  EAError,
  EAErrorManager,
  EAMethod,
  EAView,
  ResponseWithErrors,
  throwIfResponseHasErrors
} from '@zurich-es-npm/ea-front-web-core';
import {
  FleetsMaintenancePolicyModel,
  OperationTypeEnum,
  FlowViewsStepsModel,
  FlowHeaderStepsModel
} from '../fleets-maintenance-policy-model';
import {
  VehicleFormModel,
  VehicleUseData
} from '@/business-components/fleet-manual-upload-vehicle/fleet-manual-upload-vehicle-model';
// eslint-disable-next-line max-len
import FleetUnifiedExpirationBusiness from '@/business-components/fleet-unified-expiration/fleet-unified-expiration-business.vue';
// eslint-disable-next-line max-len
import FleetInputVehiclesDataBusiness from '@/business-components/fleet-input-vehicles-data/fleet-input-vehicles-data-business.vue';
import {
  RangeRestriction
} from '@/flows/fleets/model/range-restriction-model';
import {
  SelectItemType
} from '@/flows/fleets/model/select-Item-type-model';
import {
  InputVehiclesMode
} from '@/types/input-vehicles-mode/input-vehicles-mode-enum.types';
import {
  CorpTableNames, corporativeTableSortAlphabetically, fetchCorporateTable
} from '@/utils/corporate-tables';
import Utils from '@/utils/utils';
import {
  VehicleGroupValidations
} from '@/business-components/fleet-group-upload-vehicles/fleet-group-upload-vehicles-model';
import {
  NotificationsUtils
} from '@/utils/notifications/notifications-utils';
import cloneDeep from 'lodash/cloneDeep';
import {
  EAGetVehicleListFleetApi, GetVehicleListFleetResponse
} from '@/services/V1/fleets/getVehicleListFleetOperation/post';
import {
  GetOfferListByRiskRequestSearchByPlateNumberTipoMatriculaEnum as TipoMatriculaEnum
} from '@/services/V1/search-offer/getOfferListByRiskOperation/post';

@Component({
  components: {
    'fleet-unified-expiration': FleetUnifiedExpirationBusiness,
    'fleet-input-vehicles-data': FleetInputVehiclesDataBusiness
  }
})

/**
 * VehiclesData view
 *
 */
export default class VehiclesDataView extends mixins<EAView<FleetsMaintenancePolicyModel>>(EAView) {

  disabledBase7Mode: boolean = false;

  continueButtonDisabled: boolean = true;

  readonlyDate: boolean = false;

  readonlyVehiclesExpiration: boolean = true;

  allVehicleUses: VehicleUseData[] = [];

  manualMode: InputVehiclesMode = InputVehiclesMode.Manual;

  loading: boolean = false;

  loadingContainerId = 'loading-container-fleets-maintenance-vehicles-view';

  pamVehicleTypes = ['300', '350', '400', '470', '500', '900', '910'];

  /**
   * COMPUTED
   */

  /**
   * Getter for allowedPlateTypes
   */
  get allowedPlateTypes(): string[] {
    return this.model.flowConfiguration.vehiclesDataView?.allowedPlateTypes || [];
  }

  /**
   * Getter for noPlateOption
   */
  get noPlateOption(): SelectItemType {
    return this.model.flowConfiguration.vehiclesDataView?.noPlateOption || [];
  }


  /**
   * Getter for vehiclesNumberRange
   */
  get vehiclesNumberRange(): RangeRestriction {
    return {
      min: 1, max: this.model.flowConfiguration.fleetsMaintenancePolicy.fleetSummaryView.maxVehicles
    };
  }

  /**
   * Getter for maintenance flow
   */
  get maintenanceFlow() {
    return this.model.operationType === OperationTypeEnum.MANTENIMIENTO;
  }

  /**
   * 
   */
  get totalVehicles(): number {
    return this.model.emmitedVehicles + this.model.fleetInputVehiclesDataModel.vehicles.length;
  }

  /**
   * Watches `loading` changes and scrolls to the center of the page if loader is active.
   * @param {boolean} newValue 
   */
  @Watch('loading')
  async onLoadingStatusChange(newValue: boolean) {
    if (newValue) {
      await Utils.scrollToCenterOfElement(`#${this.loadingContainerId}`);
    }
  }
  
  /**
   * METHODS
   */

  /**
   * Hook on created.
   * Get the vehicles list in case of maintenance
   * @returns {Promise<void>}
   */
  @EAMethod({
    loading: true,
  })
  async created(): Promise<void> {
    await Promise.all([this.fetchPlateNumberListCorpTable(), this.fetchVehicleUseCorporateTable()]);

    if (this.maintenanceFlow) {
      await this.getVehiclesListBff();
    }
  }

  /**
   * Gets the plate number (matrículas) types for a <select> element.
   */
  @EAMethod({
    loading: true,
  })
  public async fetchPlateNumberListCorpTable(): Promise<void> {
    // Avoid several same call / fetch
    if (this.model.fleetInputVehiclesDataModel.manualUploadVehicleModel.plateTypeSearchList.length > 0) {
      return;
    }
    // Fetch
    try {
      const corpTableProductResult = await fetchCorporateTable(CorpTableNames.PlateNumberSearch);
      if (corpTableProductResult.data) {
        corporativeTableSortAlphabetically(corpTableProductResult.data.tableDocuments, 'DSELEMEN');
        this.model.fleetInputVehiclesDataModel.manualUploadVehicleModel.plateTypeSearchList =
        corpTableProductResult.data.tableDocuments
          .map((document, index) => {
            return {
              id: `${document.CDELEMEN}-${index + 1}`,
              code: document.CDELEMEN,
              name: document.DSELEMEN,
            };
          })
          .filter(plateType => this.allowedPlateTypes.find(allowedPlateType => allowedPlateType === plateType.code)
          );
        this.model.fleetInputVehiclesDataModel.manualUploadVehicleModel.plateTypeSearchList.unshift(
          this.noPlateOption);
        // Cargamos la informacion en el modelo de carga grupal también.
        this.model.fleetInputVehiclesDataModel.groupUploadVehiclesModel.plateTypeSearchList =
          this.model.fleetInputVehiclesDataModel.manualUploadVehicleModel.plateTypeSearchList;
        this.model.fleetInputVehiclesDataModel.fleetsEditVehicleModel.plateTypeSearchList =
          this.model.fleetInputVehiclesDataModel.manualUploadVehicleModel.plateTypeSearchList;
      } else {
        throw new EAApplicationError('ZON00087');
      }
    } catch (error) {
      const eaError = error as EAError;
      new EAApplicationLogger().error(
        // eslint-disable-next-line max-len
        `FleetInputVehiclesDataBusiness::fetchPlateNumberListCorpTable:: fetch corporate table KCIT71G :: ${eaError.message}`
      );
      throw error;
    }
  }

  /**
   * Fetch corp table data for vehicle uses and parses documents.
   */
  @EAMethod({
    loading: true,
  })
  public async fetchVehicleUseCorporateTable() {
    const corpTableData = await fetchCorporateTable(CorpTableNames.VehicleUse);
    // Map documents with only relevant information
    const documentsParsed: VehicleUseData[] = corpTableData.data.tableDocuments.map(doc => {
      return {
        name: doc.DSELEM.trim(),
        value: doc.CDUSOMAT.trim(),
        plateType: doc.TIMATVCO.trim() === '' ? TipoMatriculaEnum.NOMATRICULA : doc.TIMATVCO.trim(),
      };
    });
    Utils.sortObjectArrayByProperty(documentsParsed, 'name');
    this.allVehicleUses = documentsParsed;
  }

  /**
   * Get vehicles list of fleet
   */
  @EAMethod({
    loading: true,
  })
  async getVehiclesListBff() {

    const api = new EAGetVehicleListFleetApi();
    const output = await api.getVehicleListFleetOperation({
      getVehicleListFleetRequest: {
        codigoFlota: this.model.offerNumber,
        versionFlota:  this.model.offerVersion,
      }
    });

    if (output) {
      throwIfResponseHasErrors(output as ResponseWithErrors);
      const vehiclesList = this.getVehiclesFormat(output);
      this.readonlyDate = !!output.flota?.fechaInicioPoliza;
      const expirationDate = output.flota?.fechaVencimientoFlota
        ? new Date(output.flota.fechaVencimientoFlota)
        : null;
      const currentDate = new Date();
      currentDate.setHours(0, 0, 0, 0);
      const effectiveDate: Date | null = output.flota?.fechaEfectoMovimiento
        ? new Date(output.flota.fechaEfectoMovimiento)
        : null;
      const validationDate = effectiveDate && effectiveDate > currentDate
        ? effectiveDate : currentDate;
      const expirationDateVehicleValidations =
      this.validateVehicles(vehiclesList, false, validationDate);
      this.notifyVehicleValidations(expirationDateVehicleValidations);
      this.model.fleetUnifiedExpirationModel.formValues.unifiedExpirationDate = null;
      this.model.fleetUnifiedExpirationModel.formValues.notUnifiedExpirationDate = null;
      this.model = {
        ...this.model,
        fleetInputVehiclesDataModel: {
          ...this.model.fleetInputVehiclesDataModel,
          vehicles: vehiclesList
        },
        fleetUnifiedExpirationModel: {
          ...this.model.fleetUnifiedExpirationModel,
          formValues: {
            ...this.model.fleetUnifiedExpirationModel.formValues,
            expirationDate,
            effectiveDate
          }
        },
        fleetQuotePaymentFormModel: {
          ...this.model.fleetQuotePaymentFormModel,
          paymentForm: output.flota?.formaPago || '',
        }
      };
      this.update();
    }
  }

  /**
   * Get vehicles format
   * @param {GetVehicleListFleetResponse} output
   * @returns {VehicleFormModel[]}
   */
  getVehiclesFormat(output: GetVehicleListFleetResponse): VehicleFormModel[] {
    const data = output.lista?.map(vehicle => {
      const plateTypeVehicle = vehicle.tipoMatricula ? vehicle.tipoMatricula : TipoMatriculaEnum.NOMATRICULA;
      const plateTypeName = this.model.fleetInputVehiclesDataModel.manualUploadVehicleModel.plateTypeSearchList
        .find(plateType => plateType.code === plateTypeVehicle)?.name;

      const vehicleUseName = this.allVehicleUses.find(
        code => code.value === vehicle.codigoUsoPorMatricula && code.plateType === vehicle.tipoMatricula
      )?.name;
  
      return {
        offerNumber: vehicle.codigoPoliza,
        offerVersion: vehicle.versionPoliza,
        plateNumberType: vehicle.tipoMatricula,
        plateNumber: vehicle.numeroMatriculaSinValidaciones,
        isBaseSieteData: !!vehicle.descripcionMarcaVehiculo,
        plateTypeName,
        effectiveDate: vehicle.fechaEfectoMovimiento ? new Date(vehicle.fechaEfectoMovimiento) : undefined,
        accessoriesValue: vehicle.valorAccesoriosTotal,
        vehicleBrand: vehicle.descripcionMarcaVehiculo,
        vehicleModel: vehicle.descripcionModeloVehiculo,
        vehicleVersion: vehicle.descripcionVersionVehiculo,
        vehicleUseName,
        plateUseId: vehicle.codigoUsoPorMatricula,
        policyNumber: vehicle.codigoNumeroPoliza,
        vehicleType: vehicle.tipoVehiculo,
        vehicleValue: vehicle.valorVehiculo,
        vehicleMaxWeight: vehicle.valorPesoMaximo,
        invalidValues: !vehicle.valorVehiculo ||
              (this.pamVehicleTypes.includes(vehicle.tipoVehiculo as string) && !vehicle.valorPesoMaximo)
      };
    });

    return data || [];
  }

  /**
   * Validates a vehicle plate and effective data and mark it as invalid or not
   * @param {VehicleFormModel []} vehicles 
   * @param {boolean} hasUnifiedExpiration 
   * @param {Date} currentEffectiveDate 
   * @returns {VehicleGroupValidations}
   */
  validateVehicles(vehicles: VehicleFormModel [], hasUnifiedExpiration: boolean,
    currentEffectiveDate: Date): VehicleGroupValidations [] {
    const vehiclesValidations: VehicleGroupValidations [] = [];
    vehicles.forEach(vehicle => {
      const isEffectiveDateValid =
        this.validateEffectiveDate(vehicle, hasUnifiedExpiration, currentEffectiveDate);
      vehiclesValidations.push({
        isEffectiveDateValid
      });
      vehicle.invalidDate = !isEffectiveDateValid;
    });

    return vehiclesValidations;
  }

  /**
   * Send warning messages for invalid vehicles
   * @param {VehicleGroupValidations []} vehicleValidations 
   */
  notifyVehicleValidations(vehicleValidations: VehicleGroupValidations []) {
    const effectiveDateWarning =
    vehicleValidations.some(vehicleValidation => !vehicleValidation.isEffectiveDateValid);
    if (effectiveDateWarning) {
      const message =
    this.$t(`fleets.fleetsFlow.inputVehiclesData.warningValidations.effectiveDateWarning`)
      .toString();
      NotificationsUtils.throwWarning(message);
    }
  }


  /**
   * Returns if the effective date is valid based on the effective date of the fleet
   * if it is not unified
   * @returns {boolean}
   * @param {VehicleFormModel} vehicle 
   * @param {boolean} hasUnifiedExpiration 
   * @param {Date} currentEffectiveDate 
   */
  validateEffectiveDate(vehicle: VehicleFormModel,
    hasUnifiedExpiration: boolean, currentEffectiveDate: Date): boolean {
    
    let isEffectiveDateValid = true;
    const effectiveVehicleDate = vehicle.effectiveDate as Date;
    if (!hasUnifiedExpiration) {
      const endDate = cloneDeep(currentEffectiveDate);
      endDate.setFullYear(endDate.getFullYear() + 1);
      endDate.setDate(endDate.getDate()-1);
      isEffectiveDateValid = effectiveVehicleDate >= currentEffectiveDate && effectiveVehicleDate < endDate;
    }
    return isEffectiveDateValid;
  }
  
  /**
   * Handler for disable component caused by BaseSiete call.
   * @param {boolean} disabled
   */
  onDisabledBase7Mode(disabled: boolean) {
    this.disabledBase7Mode = disabled;
    this.changeLoaderVisibility(disabled);
  }

  /**
   * Go to the next step
   */
  @EAMethod({
    loading: true
  })
  async onGoNext() {
    // Reset warning messages
    NotificationsUtils.clearNotifications();
    EAErrorManager.clearError();

    this.$emit('changeStep', FlowViewsStepsModel.QuoteDataStep, FlowHeaderStepsModel.QuoteDataStep);
  }
    
  /**
   * 
   * @param {boolean} isVisible 
   */
  changeLoaderVisibility(isVisible: boolean) {
    this.loading = isVisible;
  }

  /**
   * Goes to previous step
   */
  @EAMethod({
    loading: true
  })
  async onGoBack() {
    NotificationsUtils.clearNotifications();
    EAErrorManager.clearError();

    this.model.operationType = OperationTypeEnum.MANTENIMIENTO;
    this.update();
    this.$emit('changeStep', FlowViewsStepsModel.FleetSummary, FlowViewsStepsModel.FleetSummary);
  }
}
</script>
