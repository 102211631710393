<!-- eslint-disable max-lines -->
<template>
  <ea-card>
    <div slot="cardbody" class="p-a-24">
      <ea-form ref="form" :model="model" :validationOptions="validationOptions">
        <ea-row class="m-b-0">
          <ea-col :span="12">
            <!-- NOMBRE DE INTERMEDIARIO -->
            <ea-row>
              <ea-col :span="12">
                <ea-form-item :label="$t('quoteBuyGenericFlow.newOffer.intermediaryName')" prop="intermediaryName">
                  <ea-input-text v-model="model.intermediaryName" readonly/>
                </ea-form-item>
              </ea-col>
              <ea-col :span="12">
                <ea-form-item :label="$t('quoteBuyGenericFlow.newOffer.intermediaryCode')" prop="intermediaryCode">
                  <ea-input-text v-model="model.intermediaryCode" readonly />
                </ea-form-item>
              </ea-col>
            </ea-row>
            <!-- NOMBRE DE NEGOCIO -->
            <ea-row v-if="model.businessName">
              <ea-col :span="12">
                <ea-form-item
                  v-if="model.businessName"
                  :label="$t('quoteBuyGenericFlow.newOffer.businessName')"
                  prop="businessName"
                >
                  <ea-input-text v-model="model.businessName" readonly />
                </ea-form-item>
              </ea-col>
              <ea-col :span="12">
                <ea-form-item
                  v-if="model.businessCode"
                  :label="$t('quoteBuyGenericFlow.newOffer.businessCode')"
                  prop="businessCode"
                >
                  <ea-input-text v-model="model.businessCode" readonly />
                </ea-form-item>
              </ea-col>
            </ea-row>
            <!-- NOMBRE DE PRODUCTO -->
            <ea-row>
              <ea-col :span="12">
                <ea-form-item :label="$t('quoteBuyGenericFlow.newOffer.productName')" prop="productName">
                  <ea-input-text v-model="model.productName" readonly />
                </ea-form-item>
              </ea-col>
              <ea-col :span="12">
                <ea-form-item :label="$t('quoteBuyGenericFlow.newOffer.productCode')" prop="productCode">
                  <ea-input-text v-model="model.productCode" readonly />
                </ea-form-item>
              </ea-col>
            </ea-row>
            <!-- MOVIMIENTO -->
            <ea-row v-if="isIncomplete">
              <ea-col :span="12">
                <ea-form-item :label="$t('quoteBuyGenericFlow.newOffer.movementType')" prop="">
                  <ea-input-text v-model="movementType" readonly />
                </ea-form-item>
              </ea-col>
              <ea-col :span="12">
                <ea-form-item :label="$t('quoteBuyGenericFlow.newOffer.movementMotive')" prop="">
                  <ea-input-text v-model="movementMotive" readonly />
                </ea-form-item>
              </ea-col>
            </ea-row>
          </ea-col>
          <ea-col :span="12">
            <!-- DURACIÓN -->
            <ea-row>
              <ea-col :span="12" v-if="isIncomplete">
                <ea-form-item
                  :label="$t('quoteBuyGenericFlow.newOffer.efectNaturalDate')"
                  :required="false"
                >
                  <ea-date-picker
                    v-model="efectNaturalDate"
                    size="medium"
                    :disabled="true"
                    @change="updateValue"
                  />
                </ea-form-item>
              </ea-col>
              <ea-col :span="12">
                <ea-form-item
                  :label="$t('quoteBuyGenericFlow.newOffer.duration')"
                  :required="!consultaOperation && isElementModificable('DUCONTRA')"
                  prop="durationValue"
                >
                  <ea-select
                    :placeholder="$t('common.label.select')"
                    v-model="model.durationValue"
                    id="duration-sel"
                    :readonly="!isElementModificable('DUCONTRA')"
                    @change="onDurationChange"
                  >
                    <ea-option
                      v-for="constraint in durationValueList"
                      :key="constraint.valorRestriccion"
                      :label="constraint.nombreRestriccion"
                      :value="constraint.valorRestriccion"
                    />
                  </ea-select>
                </ea-form-item>
              </ea-col>
            </ea-row>
            <!-- FECHA EFECTO MOVIMIENTO -->
            <ea-row>
              <ea-col :span="12">
                <ea-form-item
                  :label="$t('quoteBuyGenericFlow.newOffer.efectMovementDate')"
                  prop="movementEfect"
                  :required="!consultaOperation && !areDatesReadonly"
                >
                  <ea-date-picker
                    v-model="model.movementEfect"
                    size="medium"
                    @change="onMovementEfectInputChange"
                    :disabled="!isElementModificable('FEEFTOMO')"
                    :readonly="areDatesReadonly"
                  />
                </ea-form-item>
              </ea-col>
              <ea-col :span="12">
                <ea-form-item
                  :label="$t('quoteBuyGenericFlow.newOffer.movementDue')"
                  prop="movementDue"
                  :required="!consultaOperation && !areDatesReadonly"
                >
                  <ea-date-picker
                    v-model="model.movementDue"
                    size="medium"
                    @change="updateValue"
                    :disabled="isDueDateReadonly"
                    :readonly="areDatesReadonly"
                  />
                </ea-form-item>
              </ea-col>
            </ea-row>
            <!-- CANAL DE COBRO Y FORMA DE PAGO -->
            <ea-row>
              <ea-col :span="12" v-if="!isIncomplete && showPaymentChannel">
                <ea-form-item
                  :label="$t('quoteBuyGenericFlow.newOffer.paymentChannel')"
                  :required="!consultaOperation"
                  prop="paymentChannel"
                >
                  <ea-select
                    :placeholder="$t('common.label.select')"
                    v-model="model.paymentChannel"
                    id="duration-sel"
                    @change="updateValue"
                    :readonly="!isElementModificable('TCCANCO1')"
                  >
                    <ea-option
                      v-for="constraint in paymentChannelList"
                      :key="constraint.valorRestriccion"
                      :label="constraint.nombreRestriccion"
                      :value="constraint.valorRestriccion"
                    />
                  </ea-select>
                </ea-form-item>
              </ea-col>
              <ea-col :span="12" v-if="isIncomplete && model.offerIssuanceBonusDataFormModel">
                <ea-form-item
                  :label="$t('quoteBuyGenericFlow.newOffer.paymentMode')"
                  :required="!consultaOperation && !isReadonlyPaymentForm"
                >
                  <ea-select
                    :placeholder="$t('common.label.select')"
                    v-model="model.offerIssuanceBonusDataFormModel.paymentForm"
                    id="payment-mode"
                    @change="updateValue"
                    :readonly="isReadonlyPaymentForm"
                  >
                    <ea-option
                      v-for="paymentForm in paymentForms"
                      :key="paymentForm.valorRestriccion"
                      :label="paymentForm.nombreRestriccion"
                      :value="paymentForm.nombreRestriccion"
                    />
                  </ea-select>
                </ea-form-item>
              </ea-col>
              <ea-col :span="12">
                <!-- IDIOMA: Uses Getter function to read value -->
                <ea-form-item
                  :label="$t('quoteBuyGenericFlow.newOffer.documentationLanguage')"
                  :required="!consultaOperation && isElementModificable('INIDIOMA')"
                  prop="documentationLanguage"
                >
                  <ea-select
                    :placeholder="$t('common.label.select')"
                    v-model="model.documentationLanguage"
                    id="documentation-language"
                    :readonly="!isElementModificable('INIDIOMA')"
                  >
                    <ea-option
                      v-for="documentationLanguage in documentationLanguageList"
                      :key="documentationLanguage.valorRestriccion"
                      :label="documentationLanguage.nombreRestriccion"
                      :value="documentationLanguage.valorRestriccion"
                    />
                  </ea-select>
                </ea-form-item>
              </ea-col>
            </ea-row>
            <!-- CANALES DE COBRO -->
            <ea-row v-if="isIncomplete && model.offerIssuanceBillingDataFormModel && showPaymentChannel">
              <ea-col :span="12">
                <ea-form-item
                  :label="$t('quoteBuyGenericFlow.newOffer.firstReceiptPaymentChannel')"
                  :required="!consultaOperation"
                >
                  <ea-select
                    :placeholder="$t('common.label.select')"
                    v-model="model.offerIssuanceBillingDataFormModel.paymentChannelFirstReceipt"
                    id="duration-sel"
                    @change="onPaymentChannelChange"
                    :readonly="!isElementModificable('TCCANCO1')"
                  >
                    <ea-option
                      v-for="paymentChannel in model.offerIssuanceBillingDataFormModel.paymentChannels"
                      :key="paymentChannel.value"
                      :label="paymentChannel.label"
                      :value="paymentChannel.value"
                    />
                  </ea-select>
                </ea-form-item>
              </ea-col>
              <ea-col :span="12">
                <ea-form-item
                  :label="$t('quoteBuyGenericFlow.newOffer.successiveReceiptsPaymentChannel')"
                  :required="!consultaOperation"
                >
                  <ea-select
                    :placeholder="$t('common.label.select')"
                    v-model="model.offerIssuanceBillingDataFormModel.paymentChannelSuccessiveReceipts"
                    id="payment-mode"
                    @change="onPaymentChannelChange"
                    :readonly="!isElementModificable('TCCANCOS')"
                  >
                  <ea-option
                      v-for="paymentChannel in model.offerIssuanceBillingDataFormModel.paymentChannels"
                      :key="paymentChannel.value"
                      :label="paymentChannel.label"
                      :value="paymentChannel.value"
                    />
                  </ea-select>
                </ea-form-item>
              </ea-col>
            </ea-row>
            <ea-row v-if="isIncomplete && model.offerIssuanceBillingDataFormModel && showPaymentChannel">
              <ea-col :span="12"
                v-if="isIncomplete
                && model.offerIssuanceBonusDataFormModel
                && model.offerIssuanceBillingDataFormModel.debtCollectorIntermediaryFirstReceiptList.length > 1"
              >
                <ea-form-item
                  :label="$t('quoteBuyGenericFlow.newOffer.firstReceiptDebtCollectorIntermediary')"
                  :required="!consultaOperation"
                >
                  <ea-select
                    v-model="model.offerIssuanceBillingDataFormModel.debtCollectorIntermediaryFirstReceipt"
                    :placeholder="$t('common.label.select')"
                    @change="updateValue"
                    :readonly="!isElementModificable('CLINTEC1')"
                  >
                    <ea-option
                      v-for="debtCollectorIntermediary in
                      model.offerIssuanceBillingDataFormModel.debtCollectorIntermediaryFirstReceiptList"
                      :key="debtCollectorIntermediary.codigoIntermediario"
                      :label="debtCollectorIntermediary.nombreIntermediario"
                      :value="debtCollectorIntermediary.codigoIntermediario"
                    />
                  </ea-select>
                </ea-form-item>
              </ea-col>
              <ea-col :span="12"
                v-if="isIncomplete
                && model.offerIssuanceBonusDataFormModel
                && model.offerIssuanceBillingDataFormModel.debtCollectorIntermediarySuccessiveReceiptsList.length > 1"
              >
                <ea-form-item
                  :label="$t('quoteBuyGenericFlow.newOffer.successiveReceiptsDebtCollectorItermediary')"
                  :required="!consultaOperation"
                >
                  <ea-select
                    v-model="model.offerIssuanceBillingDataFormModel.debtCollectorIntermediarySuccessiveReceiptsList"
                    :placeholder="$t('common.label.select')"
                    @change="updateValue"
                    :readonly="!isElementModificable('CLINTECS')"
                  >
                    <ea-option
                      v-for="debtCollectorIntermediary in
                      model.offerIssuanceBillingDataFormModel.debtCollectorIntermediarySuccessiveReceiptsList"
                      :key="debtCollectorIntermediary.codigoIntermediario"
                      :label="debtCollectorIntermediary.nombreIntermediario"
                      :value="debtCollectorIntermediary.codigoIntermediario"
                    />
                  </ea-select>
                </ea-form-item>
              </ea-col>
            </ea-row>
          </ea-col>
        </ea-row>

        <!-- MÁS INFORMACIÓN -->
        <ea-row class="m-b-0" v-if="model.additionalDataList.length > 0">
          <ea-col :span="12" :offset="12">
            <ea-link
              @click="model.showMore = !model.showMore"
              :underline="false">
              {{ model.showMore ? $t('common.label.seeLessInfo') : $t('common.label.seeMoreInfo') }}
              <ea-icon :icon="model.showMore ? 'z-arrow-up' : 'z-arrow-down'" />
            </ea-link>
          </ea-col>
        </ea-row>
        <ea-row class="m-t-24" v-if="model.showMore">
          <ea-col :span="24">
            <ea-heading level="5" class="m-b-16">
              {{ $t('quoteBuyGenericFlow.newOffer.tableTitle') }}
            </ea-heading>
            <ea-table :data="model.additionalDataList">
              <ea-table-column
                :label="$t('quoteBuyGenericFlow.newOffer.tableElementLabel')"
                show="descripcionElementoTp"
                headerClass="no-sortBy"
              />
              <ea-table-column
                :label="$t('quoteBuyGenericFlow.newOffer.tableValueLabel')"
                show="valorElementoAdicional"
                cellClass="t-align-left"
                headerClass="no-sortBy"
              />
            </ea-table>
          </ea-col>
        </ea-row>
      </ea-form>
    </div>
  </ea-card>
</template>

<script lang="ts">
import {
  Component, Prop
} from 'vue-property-decorator';
import {
  mixins
} from 'vue-class-component';
import {
  EABusinessComponent, EAInfoDateConverter, EAMethod
} from '@zurich-es-npm/ea-front-web-core';
import SimpleTableComponent from './components/simple-table-component.vue';
import {
  GetGeneralDataResponseDataDatosCabecera,
  GetGeneralDataResponseDataDatosCabeceraTablaRestricciones
} from '../../../src/services/V1/quoteAndBuy/getGeneralDataOperation/post';
import moment from 'moment';
import {
  eaCustomValidation,
  eaRequiredValidation,
  EAValidation,
  EAValidationTriggers,
  EAValidationTypes,
  Form,
} from '@zurich-es-npm/ea-front-web-ui';
import Utils from '../../utils/utils';
import {
  EAValidatorFunction
} from '@zurich-es-npm/ea-front-web-core/lib/validation-rules';
import {
  PolicyMovementType, QuoteBuyModel
} from '@/flows/quote-buy/quote-buy-model';
import {
  MovementReasonEnum,
  PolicyModel
} from '@/flows/policy/policy-model';
import {
  GetContractDataResponseFormasPago
} from '@/services/V1/quoteAndBuy/getContractDataOperation/post';
import {
  cloneDeep
} from 'lodash';
import {
  GetFractionalPaymentsRequestTipoFormaPagoTarificacionGarantiasEnum
} from '@/services/V1/quoteAndBuy/getFractionalPaymentsOperation/post/api';
import {
  OfferPolicyUtils
} from '@/utils/offer-policy-utils';

@Component({
  name: 'qb-general-data-information',
  components: {
    SimpleTable: SimpleTableComponent,
  },
})

/**
 * Business Component qb-general-data-information
 */
export default class QbGeneralDataInformationBusiness extends mixins<
EABusinessComponent<PolicyModel | QuoteBuyModel>
>(EABusinessComponent) {
  @Prop()
    consultaOperation?: boolean;

  @Prop()
    datosCabecera?: GetGeneralDataResponseDataDatosCabecera;

  @Prop()
    areDatesReadonly?: boolean;

  public movementDueMessageErrorLabel: string = '';

  public durationValueList?: GetGeneralDataResponseDataDatosCabeceraTablaRestricciones[] = [];

  public paymentChannelList?: GetGeneralDataResponseDataDatosCabeceraTablaRestricciones[] = [];

  public taxTreatmentAreaList?: GetGeneralDataResponseDataDatosCabeceraTablaRestricciones[] = [];

  public paymentModeList?: GetGeneralDataResponseDataDatosCabeceraTablaRestricciones[] = [];

  public documentationLanguageList?: GetGeneralDataResponseDataDatosCabeceraTablaRestricciones[] = [];

  public movementType?: string = '';

  public movementMotive?: string = '';

  public movementMotiveValue?: string = '';

  public policyMovementType: string = '';

  public efectNaturalDate?: any = '';

  public paymentForms: GetContractDataResponseFormasPago[] = [];

  public componentCreationFinished = false;

  /**
   * Hook on created
   */
  public async created(): Promise<void> {
    this.mapGeneralDataFields();
    this.componentCreationFinished = true;
  }


  /**
   * Getter for incomplete policies movementType
   * @returns { boolean }
   */
  get isIncomplete(): boolean {
    return this.datosCabecera?.datosPoliza?.codigoTipoMovimiento !== PolicyMovementType.Oferta;
  }

  /**
   * Returns if payment channel must be shown.
   * Payment channel must be displayed in the following:
   *  - In inquiry mode
   *  - In maintenance or new offer && not only bank charge
   */
  get showPaymentChannel(): boolean {
    const onlyBankCharge = OfferPolicyUtils.userHasOnlyBankCharge();
    return !!this.consultaOperation || (!this.consultaOperation && !onlyBankCharge);
  }

  /**
   * Computed property to know if supplement flow
   */
  get isSupplementFlow(): boolean {
    if (this.datosCabecera?.datosPoliza?.codigoTipoMovimiento === PolicyMovementType.Suplemento ||
    this.datosCabecera?.datosPoliza?.codigoTipoMovimiento === PolicyMovementType.SuplementoVencimiento) {
      return true;
    }
    return false;
  }

  /**
   * Computed property to know if dueDate calendar is readonly.
   */
  get isDueDateReadonly(): boolean {
    const motivoMovValue = this.getPolicyElement('TCMOMOVP')?.valorElemento || '';
    // Readonly if Motivo Movimiento !== '180' (Cambio de vencimiento) in supplement case
    if (this.isSupplementFlow) {
      return motivoMovValue !== MovementReasonEnum.CambioVencimiento;
    } else {
      return !this.isElementModificable('FEVENCIM');
    }
  }

  /**
   * Should return is paymentForm is readonly
   */
  get isReadonlyPaymentForm(): boolean {
    if (this.paymentForms.length === 1) {
      return true;
    }
    return !this.isElementModificable('TCFORPAG');
  }

  /**
   * Method that maps the generalData fields to the model fields
   */
  @EAMethod()
  mapGeneralDataFields() {
    this.paymentForms = cloneDeep(this.model.offerIssuanceBonusDataFormModel.paymentForms);
    this.model.intermediaryName = this.datosCabecera?.datosIntermediario?.nombreIntermediario || '';
    this.model.intermediaryCode = this.datosCabecera?.datosPoliza?.codigoIntermediario || '';
    this.model.businessName = this.datosCabecera?.nombreNegocio;
    this.model.businessCode = this.datosCabecera?.codigoNegocio;
    this.model.productName = this.datosCabecera?.datosProducto?.nombreProducto || '';
    this.model.productCode = `${this.datosCabecera?.datosPoliza?.codigoTecnicoProducto
      || ''}-${this.datosCabecera?.datosPoliza?.codigoComercialProducto || ''}`;
    this.model.productComercialCode = this.datosCabecera?.datosPoliza?.codigoComercialProducto || '';
    this.model.productTechnicalCode = this.datosCabecera?.datosPoliza?.codigoTecnicoProducto || '';
    this.model.additionalDataList = this.datosCabecera?.datosAdicionales || [];

    this.updateMovementDates();

    if (this.datosCabecera?.elementosPoliza) {
      this.model.durationValue = this.getPolicyElement('DUCONTRA')?.valorElemento || '';
      this.model.paymentChannel = this.getPolicyElement('TCCANCO1')?.valorElemento || '';
      this.model.paymentMode = this.getPolicyElement('TCFORPAG')?.valorElemento || '';
      this.model.documentationLanguage = this.getPolicyElement('INIDIOMA')?.valorElemento || '';
      this.efectNaturalDate =
      this.dateFormatter(EAInfoDateConverter.convertInfoDateToRFC3339(
        parseInt(this.getPolicyElement('FEEFTONA')?.valorElemento || '')
      ));

      this.update();
    }

    this.durationValueList = this.getConstraintCorpTableByElementName('DUCONTRA');
    Utils.sortObjectArrayByProperty(this.durationValueList, 'nombreRestriccion');

    this.paymentChannelList = this.getConstraintCorpTableByElementName('TCCANCO1');
    Utils.sortObjectArrayByProperty(this.paymentChannelList, 'nombreRestriccion');

    this.paymentModeList = this.getConstraintCorpTableByElementName('TCFORPAG');
    Utils.sortObjectArrayByProperty(this.paymentModeList, 'nombreRestriccion');

    this.documentationLanguageList = this.getConstraintCorpTableByElementName('INIDIOMA');
    Utils.sortObjectArrayByProperty(this.paymentModeList, 'nombreRestriccion');

    this.mapMovementValues();
    this.update();
    this.$emit('updateParentView');
  }

  /**
   * Sets the validation rules for current form
   */
  get validationOptions() {
    let movementDueValidationOptions = [
      eaCustomValidation(
        this.dueDateSmallerThanEffectDateValidator(),
        'quoteBuyGenericFlow.newOffer.movementDueError.smallerThanMovementEfect',
        EAValidationTriggers.BLUR,
        EAValidationTypes.DATE
      ),
      eaCustomValidation(
        this.dueDateSmallerThanEffectDateValidator(),
        'quoteBuyGenericFlow.newOffer.movementDueError.smallerThanMovementEfect',
        EAValidationTriggers.CHANGE,
        EAValidationTypes.DATE
      ),
      eaRequiredValidation(
        'common.label.validation.fieldRequired',
        EAValidationTriggers.BLUR,
        EAValidationTypes.DATE
      ),
      // Needed for trigger validation when date is cleared clicking icon
      eaRequiredValidation(
        'common.label.validation.fieldRequired',
        EAValidationTriggers.CHANGE,
        EAValidationTypes.DATE
      )
    ];

    if (!this.areDatesReadonly) {

      /**
       * Due date greater than effect date + 1 year is allowed for products
       *  that can change the dates in their risk form (eg: TRC)
       */
      movementDueValidationOptions = movementDueValidationOptions.concat([
        eaCustomValidation(
          this.dueDateGreaterThanEffectDatePlusOneYearValidator(),
          'quoteBuyGenericFlow.newOffer.movementDueError.greaterThanOneYearMinusOneDay',
          EAValidationTriggers.BLUR,
          EAValidationTypes.DATE
        ),
        eaCustomValidation(
          this.dueDateGreaterThanEffectDatePlusOneYearValidator(),
          'quoteBuyGenericFlow.newOffer.movementDueError.greaterThanOneYearMinusOneDay',
          EAValidationTriggers.CHANGE,
          EAValidationTypes.DATE
        ),
      ]);
    }

    return {
      rules: {
        movementEfect: [
          eaRequiredValidation(
            'common.label.validation.fieldRequired',
            EAValidationTriggers.BLUR,
            EAValidationTypes.DATE
          ),
          // Needed for trigger validation when date is cleared clicking icon
          eaRequiredValidation(
            'common.label.validation.fieldRequired',
            EAValidationTriggers.CHANGE,
            EAValidationTypes.DATE
          )
        ],
        movementDue: movementDueValidationOptions,
      },
    };
  }

  /**
   * Sets movement type and movement motive
   */
  mapMovementValues() {
    const tipomovValue = this.getPolicyElement('TCTIMOVP')?.valorElemento || '';
    const motivoMovValue = this.getPolicyElement('TCMOMOVP')?.valorElemento || '';

    const tipoMov = this.getConstraintCorpTableByElementName('TCTIMOVP') || [];
    if (tipomovValue) {
      this.movementType = tipoMov.find(elem => elem.valorRestriccion === tipomovValue)?.nombreRestriccion || '';
    }

    const motivoMov = this.getConstraintCorpTableByElementName('TCMOMOVP') || [];
    if (tipomovValue && motivoMovValue) {
      const motivoMovValueParsed = tipomovValue + motivoMovValue;
      this.movementMotive =
        motivoMov.find(elem => elem.valorRestriccion === motivoMovValueParsed)?.nombreRestriccion || '';
      this.movementMotiveValue =
             motivoMov.find(elem => elem.valorRestriccion === motivoMovValueParsed)?.valorRestriccion || '';
    }
  }

  /**
   * Formatea el valor de la fecha en formato "DD/MM/YYYY"
   * @param {String} value fecha sin en formato YYYY-MM-DD
   * @returns {String} fecha en formato "DD/MM/YYYY"
   */
  dateFormatter(value: string): string {
    return value ? Utils.convertDateToString(value) : '';
  }

  /**
   * Retrieves policy element by name
   * @param {string} searchPolicyElementName - Name of element to find
   * @returns {GetGeneralDataResponseDataDatosCabeceraElementosPoliza | undefined} found element if any
   */
  getPolicyElement(searchPolicyElementName: string) {
    if (!this.datosCabecera?.elementosPoliza || !this.datosCabecera?.elementosPoliza.length) {
      return undefined;
    }

    return this.datosCabecera?.elementosPoliza
      .find(element => element.codigoElemento === searchPolicyElementName);
  }

  /**
   * Gets retrieved and filtered constraint table for given policy element name
   * @param {string} name - Policy element name
   * @returns {GetGeneralDataResponseDataDatosCabeceraTablaRestricciones[]} Corp table for given name if any
   */
  getConstraintCorpTableByElementName(name: string): GetGeneralDataResponseDataDatosCabeceraTablaRestricciones[] {
    return this.datosCabecera?.
      elementosPoliza?.find(element => element.codigoElemento === name)?.tablaRestricciones || [];
  }

  /**
   * Returns if element is modificable
   * @param {string} code
   * @returns { boolean }
   */
  isElementModificable(code: string): boolean {
    const element = this.getPolicyElement(code);
    return element && element.elementoModificable !== undefined ? element.elementoModificable : true;
  }

  /**
   * Calculates movement due date when duration value is equal to 'Renewable'
   * @return { Date } movement effect date + 1 year - 1 day (+ 1 year if leap year (bisiesto))
   */
  calculateMovementDueMax(): Date {
    const rdo = moment(this.model.movementEfect)
      .add(1, 'years')
      .subtract(1, 'days');

    if (
      moment(this.model.movementEfect).isLeapYear() &&
      moment(this.model.movementEfect).month() === 1 &&
      moment(this.model.movementEfect).date() === 29
    ) {
      rdo.add(1, 'days');
    }

    return moment(rdo).toDate();
  }

  /**
   * Handles movementEfect input change
   * Converts received date to string one in order to avoid validation errors
   * @param {Date} value - inputted date
   */
  onMovementEfectInputChange() {
    //Triggers only when theres info in the value
    if (this.model.movementEfect && this.componentCreationFinished) {
      if (this.model.durationValue === 'R' && !this.isSupplementFlow) {
        this.model.movementDue = this.calculateMovementDueMax();
        this.$forceUpdate();
      }
    }
    //Update movementEfect for general data validate
    this.update();
  }

  /**
   * Validates if due date is smaller than effect date
   *
   * @returns {EAValidatorFunction}
   */
  dueDateSmallerThanEffectDateValidator(): EAValidatorFunction {
    return (_rule: any, _value: any, callback: Function) => {
      if (this.model.movementEfect && this.model.movementDue) {
        if (this.model.movementEfect > this.model.movementDue) {
          return callback(new Error());
        }
      }

      return callback();
    };
  }

  /**
   * Validates if due date is greater than effect date plus one year
   *
   * @returns {EAValidatorFunction}
   */
  dueDateGreaterThanEffectDatePlusOneYearValidator(): EAValidatorFunction {
    return (_rule: any, _value: any, callback: Function) => {
      if (this.model.movementEfect && this.model.movementDue) {
        if (this.model.movementDue > this.calculateMovementDueMax()) {
          return callback(new Error());
        }
      }

      return callback();
    };
  }

  /**
   * Returns validation object
   * @returns {EAValidation}
   */
  public validation(): EAValidation {
    const form = this.$refs.form as Form;
    return form?.validation();
  }

  /**
   * Event to update model
   * @param {string} value
   */
  onDurationChange(value: string) {
    const elementForPag = this.getPolicyElement('TCFORPAG');

    if (this.isIncomplete) {
      if (value === 'T') {
        const uniqueForPag = elementForPag?.tablaRestricciones?.
          find(restriccion => restriccion.valorRestriccion === 'U');
        if (uniqueForPag) {
          this.paymentForms = [uniqueForPag];
          this.model.offerIssuanceBonusDataFormModel.paymentForm =
            GetFractionalPaymentsRequestTipoFormaPagoTarificacionGarantiasEnum.Unica;
        }
      } else {
        const allForPag = elementForPag?.tablaRestricciones?.filter(element => element.valorRestriccion !== 'U');
        this.paymentForms = allForPag || [];
        if (GetFractionalPaymentsRequestTipoFormaPagoTarificacionGarantiasEnum.Unica ===
        this.model.offerIssuanceBonusDataFormModel.paymentForm) {
          this.model.offerIssuanceBonusDataFormModel.paymentForm =
            GetFractionalPaymentsRequestTipoFormaPagoTarificacionGarantiasEnum.Anual;
        }
      }
      this.updateValue();
    }
    this.updateValue();
  }

  
  /**
   * Event to update model
   *
   */
  updateMovementDates() {
    if (this.datosCabecera?.datosPoliza?.fechaEfectoMovimiento) {
      this.model.movementEfect = new Date(this.datosCabecera.datosPoliza?.fechaEfectoMovimiento);
      this.model.movementDue = this.datosCabecera.datosPoliza?.fechaVencimiento ?
        new Date(this.datosCabecera.datosPoliza?.fechaVencimiento) :
        this.calculateMovementDueMax();
      this.model.movementEfect.setHours(0, 0, 0, 0);
      this.model.movementDue.setHours(0, 0, 0, 0);
    }
  }

  /**
   * Event to update model
   * 
   */
  onPaymentChannelChange(): void {
    this.$nextTick(() => {
      this.update();
      this.$emit('paymentChannelChange');
    });
  }

  /**
   * Event to update model
   * 
   */
  updateValue(): void {
    this.$nextTick(() => {
      this.update();
    });
  }
}
</script>
<style lang="scss" scoped>
::v-deep {
  //TODO: change when the issue in readonly is fixed
  .el-form-item .el-form-item__content .ea-form-item__label > label.is-disabled  {
  opacity: 1;
  }
  .ea-date-picker {
     .el-date-editor.is-disabled .el-input__prefix  {
      display: none;
    }
    .el-input.is-disabled {
      opacity: 1;
      .el-input__inner {
         background: white;
         color: black;
         border: none;
      }
    }
  }
}

</style>
