<template>
  <div class="slide d-display-flex d-direction-column d-justify-space-between h-100">
        <div>
          <h2 v-if="!hideFirstTitle"  class="t-color-primary">{{ $t('onboarding.cardPayment.thirdSlide.title') }}</h2>

          <i18n path="onboarding.cardPayment.thirdSlide.descriptionFirst.text" tag="p" class="m-b-0">
            <span class="t-weight-semibold">{{ $t('onboarding.cardPayment.thirdSlide.descriptionFirst.bold1') }}</span>
          </i18n>

          <i18n path="onboarding.cardPayment.thirdSlide.descriptionSecond.text" tag="p">
            <span class="t-weight-semibold">{{ $t('onboarding.cardPayment.thirdSlide.descriptionSecond.bold1') }}</span>
            <span class="t-weight-semibold">{{ $t('onboarding.cardPayment.thirdSlide.descriptionSecond.bold2') }}</span>
          </i18n>
        </div>
        
        <div class="d-display-flex d-align-items-center d-justify-center p-a-32 w-100 t-bg-color-gray-20">
          <img
            :src="require(`@/assets/images/onboarding/cardpayment/onboarding3.png`)"
            :alt="$t('onboarding.cardPayment.thirdSlide.title').toString()"
          >
        </div>
      </div>
</template>
<script  lang="ts">

import Vue from 'vue';
import {
  Component, Prop
} from 'vue-property-decorator';

@Component({
  name: 'card-payment-onboarding-3'
})

/**
 * Comunidades onboarding component
 */
export default class CardPaymentOnboarding3Component extends Vue {

  @Prop({
    required: false,
    'default': () => false,
  })
    hideFirstTitle?: boolean;
}
</script>
