<template>
  <div>
    <ea-dialog
      id="postalAddressDialog"
      :visible="isVisible"
      @open="onDialogOpen"
      :beforeClose="onCancel"
      size="large"
      height="75vh"
    >
      <zz-client-basic-info
        id="showClientData"
        :documentationClientData="documentationClientData"
        ref="documentationClientDataComp"
        class="m-b-40"
      ></zz-client-basic-info>

      <div class="d-display-flex d-justify-space-between">
        <!-- Select address column -->
        <div>
          <h4 class="m-b-0">{{ selectedAddressTitle }}</h4>
          <ea-link
            @click="enterAddingAddressMode">
            {{ addLinkText }}
          </ea-link>

          <div v-if="model.addresses.length === 0" class="t-weight-normal">{{ emptyResultText }}</div>

          <ea-radio-group
            class="m-t-32"
            v-if="model.addresses.length > 0"
            v-model="model.selectedAddressCode"
            @change="onRadioBtnSelectionChange"
          >
            <div v-for="(address, index) in model.addresses" :key="index"
              class="d-display-flex">
              <ea-radio class="m-r-8 m-b-16" :label="address.codigoSecuencialDomicilio">
                {{ labelMapMethod(address) }}
              </ea-radio>
              <ea-icon
                icon="z-star-solid"
                class="t-size-base m-r-8"
                v-if="isMainAddress(address)"
              />
              <ea-button-icon
                @click="enterEditionAddressMode(index)"
                icon="z-edit"/>
            </div>
          </ea-radio-group>
        </div>

        <!-- Add/Edit column -->
        <div class="m-r-24" v-if="model.isAddingEditting">
          <h4 class="m-b-0" v-if="model.addressInEditionIdx === undefined">{{ addColumnTitle }}</h4>
          <h4 class="m-b-0" v-if="model.addressInEditionIdx !== undefined">{{ editColumnTitle }}</h4>

          <ea-form ref="form" :model="model.addressEditionForm" :validateOnRuleChange="false">
            <ea-row v-if="showMainAddressCheckbox">
              <ea-col>
                <ea-form-item prop="isMainAddress" class=" m-b-16">
                  <ea-checkbox
                    v-model="model.addressEditionForm.isMainAddress"
                    :checked="model.addressEditionForm.isMainAddress"
                    class="m-t-4 m-b-0"
                    :label="$t('addAddressModal.fields.setMainAddress')"
                    @change="showMainAddressChangeModal"
                    :disabled="isMainAddressCheckboxDisabled"
                  ></ea-checkbox>
                </ea-form-item>
              </ea-col>
            </ea-row>

            <ea-row>
              <ea-col :span="12">
                <ea-form-item prop="addressType" :label="$t('addAddressModal.fields.addressType')" required>
                  <ea-select v-model="model.addressEditionForm.addressType" :placeholder="$t('common.label.select')">
                    <ea-option
                      v-for="addressType in corpTableDocumentsAddressType"
                      :key="addressType.value"
                      :label="addressType.label"
                      :value="addressType.value"
                    />
                  </ea-select>
                </ea-form-item>
              </ea-col>
              <ea-col :span="12">
                <ea-form-item prop="addressName" :label="$t('addAddressModal.fields.addressName')" required>
                  <ea-input-text v-model="model.addressEditionForm.addressName" />
                </ea-form-item>
              </ea-col>
            </ea-row>

            <ea-row>
              <ea-col :span="6">
                <ea-form-item prop="addressNumber" :label="$t('addAddressModal.fields.addressNumber')">
                  <ea-input-text v-model="model.addressEditionForm.addressNumber" />
                </ea-form-item>
              </ea-col>
              <ea-col :span="18">
                <ea-form-item prop="additionalInfo" :label="$t('addAddressModal.fields.additionalInfo')">
                  <ea-input-text v-model="model.addressEditionForm.additionalInfo" />
                </ea-form-item>
              </ea-col>
            </ea-row>

            <post-code-location-input
              id="post-code-location-input"
              ref="postCodeLocationInputBC"
              v-if="!isForeignCountry"
              v-model="model.postCodeLocationInputModel"
              :isSelectedCountryAndorra="isSelectedCountryAndorra"
              :cityListRetrievalCode="zipCodeCompCityListRetrievalCode"
            ></post-code-location-input>

            <ea-row v-if="isForeignCountry">
              <ea-col :span="12">
                <ea-form-item
                  prop="zipCode"
                  :label="$t('quoteBuyGenericFlow.searchPerson.field.zipCode')"
                >
                  <ea-input-text v-model="model.addressEditionForm.zipCode" />
                </ea-form-item>
              </ea-col>
              <ea-col :span="12">
                <ea-form-item
                  prop="city"
                  :label="$t('quoteBuyGenericFlow.searchPerson.field.city')"
                  required
                >
                  <ea-input-text v-model="model.addressEditionForm.city" />
                </ea-form-item>
              </ea-col>
            </ea-row>

            <ea-row>
              <ea-col :span="12">
                <!-- Province input when country is NOT Spain or Andorra -->
                <ea-form-item
                  v-if="isForeignCountry"
                  prop="province"
                  :label="$t('quoteBuyGenericFlow.searchPerson.field.province')"
                  required
                >
                  <ea-input-text v-model="model.addressEditionForm.province" />
                </ea-form-item>

                <!-- Province input when country is Spain or Andorra -->
                <ea-form-item
                  v-if="!isForeignCountry && model.postCodeLocationInputModel.province"
                  prop="province"
                  :label="$t('quoteBuyGenericFlow.searchPerson.field.province')"
                >
                  <ea-input-text v-model="model.postCodeLocationInputModel.province" readonly />
                </ea-form-item>
              </ea-col>

              <ea-col :span="12">
                <ea-form-item prop="addressCountry" :label="$t('addAddressModal.fields.addressCountry')" required>
                  <ea-select
                    v-model="model.addressEditionForm.addressCountry"
                    :placeholder="$t('common.label.select')"
                    @change="onCountryChange"
                  >
                    <ea-option
                      v-for="addressCountry in corpTableDocumentsCountries"
                      :key="addressCountry.value"
                      :label="addressCountry.label"
                      :value="addressCountry.value"
                    />
                  </ea-select>
                </ea-form-item>
              </ea-col>
            </ea-row>
          </ea-form>
        </div>
      </div>

      <!-- Cancel and save button -->
      <div slot="footer" class="m-r-24 d-display-flex d-justify-flex-end">
        <ea-button type="secondary" @click="onCancel()" class="m-r-16">
          {{ $t('common.label.cancel') }}
        </ea-button>
        <ea-button type="primary" @click="onSave()" v-if="model.isAddingEditting">
          {{ $t('common.label.save') }}
        </ea-button>
        <ea-button type="primary" @click="onSelect()" v-if="!model.isAddingEditting">
          {{ $t('common.label.select') }}
        </ea-button>
      </div>
    </ea-dialog>

    <main-address-change
      id="main-address-change-modal"
      v-model="model.mainAddressChangeModel"
      @cancelMainAddressChange="cancelMainAddressChange"
    >
    </main-address-change>
  </div>
</template>

<script lang="ts">
import {
  Component, Prop
} from 'vue-property-decorator';

import Vue from 'vue';
import {
  mixins
} from 'vue-class-component';

import {
  EABusinessComponent, EAMethod
} from '@zurich-es-npm/ea-front-web-core';

import AddAddressTomadorModel from './add-address-tomador-model';
// eslint-disable-next-line max-len
import PostCodeLocationInputBusiness from '@/business-components/post-code-location-input/post-code-location-input-business.vue';
// eslint-disable-next-line max-len
import {
  GetPersonAddressesResponseDomicilios
} from '@/services/V1/persons/getPersonAddressesOperation/post';
import {
  SetPersonAddressesRequestDomicilios
} from '@/services/V1/persons/setPersonAddressesOperation/post';
import MainAddressChangeBusiness from '../main-address-change/main-address-change-business.vue';

import {
  ParsedTableData
} from '@/utils/corporate-tables';

import {
  EACompositeValidation, EAValidation, Form
} from '@zurich-es-npm/ea-front-web-ui';
import {
  cloneDeep
} from 'lodash';
import ZzClientBasicInfo from '@/presentational-components/clientBasicInfo/clientBasicInfo.vue';
import {
  DocumentationClientData
} from '@/presentational-components/clientBasicInfo/clientBasicInfo.type';

@Component({
  name: 'add-address-tomador',
  components: {
    PostCodeLocationInput: PostCodeLocationInputBusiness,
    MainAddressChange: MainAddressChangeBusiness,
    'zz-client-basic-info': ZzClientBasicInfo
  },
})

/**
 * Business Component add-address-tomador
 */
export default class AddAddressTomadorBusiness extends mixins<EABusinessComponent<AddAddressTomadorModel>>(
  EABusinessComponent
) {
  @Prop()
    selectedAddressTitle!: string;

  @Prop()
    addColumnTitle!: string;

  @Prop()
    editColumnTitle!: string;

  @Prop()
    addLinkText!: string;

  @Prop()
    emptyResultText!: string;

  @Prop()
    labelMapMethod!: (...args: any) => string;

  @Prop({
    'default': () => [],
  })
  public corpTableDocumentsAddressType?: ParsedTableData[];

  @Prop({
    'default': () => [],
  })
  public corpTableDocumentsCountries?: ParsedTableData[];

  @Prop({
    'default': () => true,
  })
  public showMainAddressCheckbox?: boolean;

  @Prop()
    documentationClientData!: DocumentationClientData;

  public zipCodeCompCityListRetrievalCode: string = '';

  public isVisible = false;

  /**
   * Gets if selected country is equal to Andorra
   * @returns {boolean} true if selected country equal to AND; false otherwise
   */
  get isSelectedCountryAndorra(): boolean {
    return this.model.addressEditionForm.addressCountry === 'AND';
  }

  /**
   * Handles cancel button
   */
  @EAMethod()
  public onCancel(): void {
    this.close();
    this.update();
    this.$nextTick(() => {
      this.$emit('cancel');
    });
  }

  /**
   * Opens modal
   */
  open(): void {
    this.isVisible = true;
  }

  /**
   * Closes modal
   */
  close(): void {
    this.isVisible = false;
  }

  /**
   * Returns validation object
   * @returns {EAValidation }
   */
  public modalFormValidation(): EAValidation {
    const form = this.$refs.form as Form;

    if (this.isForeignCountry) {
      return form.validation();
    }

    const postCodeLocationInputBC: PostCodeLocationInputBusiness = this.$refs
      .postCodeLocationInputBC as PostCodeLocationInputBusiness;
    postCodeLocationInputBC.update();
    return new EACompositeValidation([form.validation(), postCodeLocationInputBC.validation()], this.$tc.bind(this));
  }

  /**
   * Handles save button
   */
  public async onSave(): Promise<void> {
    this.clearFlowErrors();
    
    try {
      await this.modalFormValidation().validate();
      this.onPerformSave();
    } catch (err) {
      // Design system will show form errors
      const modalHtml = document.querySelector(`#postalAddressDialog .el-dialog`);
      modalHtml?.scrollTo(0, 0);
    }
  }

  /**
   * Performs saving operation
   * Emits select and save address
   * DOES NOT CLOSE EDITION MODAL AS IT HAS TO REMAIN OPEN IN CASE OF AN ERROR
   */
  @EAMethod()
  public onPerformSave() {
    let saveAddress: SetPersonAddressesRequestDomicilios = {};
    if (this.model.addressInEditionIdx === undefined) {
      // ADDING
      saveAddress = {
        codigoFiliacion: this.documentationClientData.clientData?.datosBasicosPersona?.codigoFiliacion,
        codigoSecuencialDomicilio: '',
        tipoDomicilio: 'P',
      };
    } else {
      // EDITTING
      saveAddress = cloneDeep(this.model.addresses[this.model.addressInEditionIdx]);
    }

    saveAddress.indicadorDomicilioPrincipal = this.model.addressEditionForm.isMainAddress ? 'S' : 'N';
    saveAddress.tipoViaDireccion = this.model.addressEditionForm.addressType;
    saveAddress.numeroDireccion = this.model.addressEditionForm.addressNumber;
    saveAddress.nombreDireccion = this.model.addressEditionForm.addressName;
    saveAddress.informacionAdicional = this.model.addressEditionForm.additionalInfo;
    saveAddress.codigoPais = this.model.addressEditionForm.addressCountry;
    saveAddress.codigoResidencia =
      this.documentationClientData.clientData?.datosGeneralesPersona?.codigoResidencia;

    if (this.isForeignCountry) {
      saveAddress.codigoPostalConLetras = this.model.addressEditionForm.zipCode === '' ?
        undefined : this.model.addressEditionForm.zipCode;
      saveAddress.nombrePoblacion = this.model.addressEditionForm.city;
      saveAddress.nombreProvincia = this.model.addressEditionForm.province;
    } else {
      saveAddress.codigoPostalConLetras = this.model.postCodeLocationInputModel.zipCode === '' ?
        undefined : this.model.postCodeLocationInputModel.zipCode;
      saveAddress.poblacionTomador = this.model.postCodeLocationInputModel.cityCode;
      saveAddress.nombrePoblacion = this.model.postCodeLocationInputModel.city;
      saveAddress.nombreProvincia = this.model.postCodeLocationInputModel.province;
    }

    this.$emit('savePostalAddress', saveAddress);
  }

  /**
   * Gets if received address is main adress
   * @param {any} address
   * @returns {boolean}
   */
  public isMainAddress(address: GetPersonAddressesResponseDomicilios): boolean {
    return address.indicadorDomicilioPrincipal === 'S';
  }

  /**
   * Shows add address form
   */
  @EAMethod()
  public enterAddingAddressMode() {
    this.resetEditionForm();
    this.model.isAddingEditting = true;
    this.model.addressInEditionIdx = undefined;
    this.model.postCodeLocationInputModel.isProvinceShown = false;
    this.model.postCodeLocationInputModel.columnSpan = 12;
    this.update();
  }

  /**
   * Selects address to edit
   * @param {number} index
   */
  @EAMethod()
  public enterEditionAddressMode(index: number) {
    this.model.isAddingEditting = true;

    this.model.addressInEditionIdx = index;
    const addressInEdition = this.model.addresses[this.model.addressInEditionIdx];
    this.model.addressEditionForm = {
      isMainAddress: addressInEdition.indicadorDomicilioPrincipal === 'S',
      addressType: addressInEdition.tipoViaDireccion,
      addressName: addressInEdition.nombreDireccion,
      addressNumber: addressInEdition.numeroDireccion,
      addressCountry: addressInEdition.codigoPais,
      additionalInfo: addressInEdition.informacionAdicional,
    };

    if (this.isForeignCountry) {
      this.model.addressEditionForm.zipCode = addressInEdition.codigoPostalConLetras || '';
      this.model.addressEditionForm.city = addressInEdition.nombrePoblacion || '';
      this.model.addressEditionForm.province = addressInEdition.nombreProvincia || '';
    } else {
      this.model.postCodeLocationInputModel.zipCode = addressInEdition.codigoPostalConLetras || '';
      this.zipCodeCompCityListRetrievalCode = addressInEdition.codigoPostalConLetras || '';
      this.model.postCodeLocationInputModel.city = addressInEdition.nombrePoblacion || '';
      this.model.postCodeLocationInputModel.province = addressInEdition.nombreProvincia || '';
    }
    
    this.model.postCodeLocationInputModel.isProvinceShown = false;
    this.model.postCodeLocationInputModel.columnSpan = 12;
    this.model.selectedAddressCode = addressInEdition.codigoSecuencialDomicilio;

    this.update();
  }

  /**
   * Handles set main address checkbox change
   * Shows main address change modal if:
   *  - Checkbox is checked
   *  - Another address is already main address
   */
  public showMainAddressChangeModal() {
    // Wait for model to change
    Vue.nextTick(() => {
      if (
        this.model.addressEditionForm.isMainAddress &&
        this.model.addresses.find(address => address.indicadorDomicilioPrincipal)
      ) {
        this.model.mainAddressChangeModel.hidden = false;
      }
    });
  }

  /**
   * Resets isMainAddress flag to false
   */
  public cancelMainAddressChange() {
    this.model.addressEditionForm.isMainAddress = false;
  }

  /**
   * Resets edition form
   */
  public resetEditionForm() {
    this.model.addressEditionForm = {
      isMainAddress: !this.model.addresses.length,
      addressType: '',
      addressName: '',
      addressNumber: '',
      addressCountry: 'ESP',
      additionalInfo: '',
    };
    this.resetZipCodeForm();
  }

  /**
   * Resets zipCode selection elements
   */
  public resetZipCodeForm() {
    this.model.postCodeLocationInputModel.city = '';
    this.model.postCodeLocationInputModel.cityCode = '';
    this.model.postCodeLocationInputModel.province = '';
    this.model.postCodeLocationInputModel.zipCode = '';
  }

  /**
   * Getter for main address checkbox disable indicator
   * @returns {boolean} true if form address is main address
   */
  get isMainAddressCheckboxDisabled() {
    return this.model.addressEditionForm.isMainAddress;
  }

  /**
   * Handles country selection change
   * Resets zipCode selection elements
   */
  public onCountryChange() {
    this.resetZipCodeForm();
  }

  /**
   * Listens dialog open event
   * Resets dialog
   * Recalculate person's info labels
   */
  public onDialogOpen() {
    this.resetEditionForm();
    this.model.isAddingEditting = false;
    this.model.addressInEditionIdx = undefined;

    const qbDocumentationClientDataComp: ZzClientBasicInfo =
      this.$refs.documentationClientDataComp as ZzClientBasicInfo;
    if (qbDocumentationClientDataComp) {
      qbDocumentationClientDataComp.calculateLabels();
    }
  }

  /**
   * Handles select button
   * Saves marked address as selected address
   */
  @EAMethod()
  public onSelect() {
    this.$emit('selectPostalAddress', this.model.selectedAddressCode);
  }

  /**
   * Handler for radiobuttons change
   */
  public onRadioBtnSelectionChange() {
    this.model.isAddingEditting = false;
    this.model.addressInEditionIdx = undefined;
  }

  /**
   * Checks if selected country is a foreign country (distinct from Spain and Andorra)
   */
  get isForeignCountry() {
    return this.model.addressEditionForm.addressCountry !== 'ESP' &&
      this.model.addressEditionForm.addressCountry !== 'AND';
  }
}
</script>
