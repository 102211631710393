import {
  CorpTableData,
  CorpTableNames, scrollCorporateTable
} from '@/utils/corporate-tables';
import {
  EAInfoDateConverter
} from '@zurich-es-npm/ea-front-web-core';
import {
  Tour, TourDates
} from '../ea-tour-model';

/**
 * Utils object with auxiliar functions
 */
export class TourUtils {

  /**
   * Do correspondient comprobations to check if have to show tour
   *
   * @param {Tour} tour
   * @returns {boolean}
   */
  public static hasToShowTour(tour: Tour): boolean {
    let hasToDoTour = false;
    let tourStore: {[key: string]: boolean} = {};
    const localStorageItem = localStorage.getItem('tours');
    const todayInMillis = new Date().getTime();
    const tourSinceDateInMillis = tour.startDate?.getTime() || null;
    const tourUntilDateInMillis = tour.endDate?.getTime() || null;
    if (tourSinceDateInMillis && tourUntilDateInMillis &&
        todayInMillis > tourSinceDateInMillis && todayInMillis < tourUntilDateInMillis) {
      hasToDoTour = true;
      tourStore = localStorageItem ? JSON.parse(localStorageItem) : {};
      if (tourStore[tour.name]) {
        hasToDoTour = false;
      }
    }
    return hasToDoTour;
  }

  /**
   * Save tour name in localStorage
   *
   * @param {Tour} tour
   */
  public static closeTour(tour: Tour): void {
    let tourStore: {[key: string]: boolean} = {};
    const localStorageItem = localStorage.getItem('tours');
    tourStore = localStorageItem ? JSON.parse(localStorageItem) : {};
    tourStore[tour.name] = true;
    localStorage.setItem('tours', JSON.stringify(tourStore));
  }

  /**
   * Load dates from KCITONS corporate table
   *
   * @param {string} tourName
   * @return {Promise} toursDate
   */
  public static async getTourDates(tourName: string): Promise<TourDates> {
    const table: CorpTableData = await scrollCorporateTable(CorpTableNames.tourDates, 'DSCONCEP', tourName);
    let endDate = null;
    let startDate = null;
    if (table?.data?.tableDocuments.length) {
      endDate = table.data.tableDocuments[0].FEFINACT ?
        new Date(EAInfoDateConverter.convertInfoDateToRFC3339(parseInt(table.data.tableDocuments[0].FEFINACT))) : null;
      startDate = table.data.tableDocuments[0].FEINIACT ?
        new Date(EAInfoDateConverter.convertInfoDateToRFC3339(parseInt(table.data.tableDocuments[0].FEINIACT))) : null;
    }
    return {
      endDate,
      startDate
    };
  }
}
