import IntermediarySelectionModel from '../intermediary-selection/intermediary-selection-model';

/** 
 * Model qb-replacement-data
 */
class QbReplacementDataModel {

  public producerIntermediary: string = '';

  public productName: string = '';

  public productCode: string = '';

  public movementType: string = '';

  public movementReason: string = '';

  public intermediaryName: string = '';

  public intermediaryCode: string = '';

  public replacingProductName: string = '';

  public replacingProductCode: string = '';

  public businessName: string = '';

  public businessCode: string = '';

  public naturalEffectDate: string = '';

  public effectDate?: Date;

  public dueDate?: Date;

  public intermediarySelectionModel = new IntermediarySelectionModel();

}

export default QbReplacementDataModel;
