<template>
  <div>
    <ea-heading level="3" class="m-b-16">
      {{ getTitle() }}
    </ea-heading>

    <ea-form
      ref="form"
      :model="model.formValues"
      :validateOnRuleChange="false"
      :validationOptions="formValidationOptions"
    >
      <ea-row v-if="isElementPrintable(['CDPAIS'])">
        <ea-col :span="6" v-if="existsElement('CDPAIS', datosObjeto)">
          <ea-form-item
            prop="country"
            :label="$t('quoteBuyGenericFlow.riskData.field.country')"
            :required="isElementRequired('CDPAIS', datosObjeto)"
          >
            <ea-select v-model="model.formValues.country" :readonly="!isElementModificable('CDPAIS', datosObjeto)">
              <ea-option
                v-for="country in countryList"
                :key="country.value"
                :label="country.label"
                :value="country.value"
              />
            </ea-select>
          </ea-form-item>
        </ea-col>
      </ea-row>

      <ea-row v-if="isElementPrintable(['CDDIRESR', 'NODIRESR', 'NUDIRESR', 'CDBLOQSR', 'VAACCDSR'])">
        <ea-col :span="6" v-if="isElementPrintable(['CDDIRESR']) && existsElement('CDDIRESR', datosObjeto)">
          <ea-form-item
            prop="roadType"
            :label="$t('quoteBuyGenericFlow.riskData.field.roadType')"
            :required="isElementRequired('CDDIRESR', datosObjeto)"
          >
            <ea-select v-model="model.formValues.roadType" :readonly="!isElementModificable('CDDIRESR', datosObjeto)">
              <ea-option
                v-for="roadType in addressTypeList"
                :key="roadType.value"
                :label="roadType.label"
                :value="roadType.value"
              />
            </ea-select>
          </ea-form-item>
        </ea-col>

        <ea-col :span="6" v-if="isElementPrintable(['NODIRESR']) && existsElement('NODIRESR', datosObjeto)">
          <ea-form-item
            prop="roadName"
            :label="$t('quoteBuyGenericFlow.riskData.field.roadName')"
            :required="isElementRequired('NODIRESR', datosObjeto)"
          >
            <ea-input-text
              v-model="model.formValues.roadName"
              :readonly="!isElementModificable('NODIRESR', datosObjeto)"
            />
          </ea-form-item>
        </ea-col>

        <ea-col :span="6" v-if="isElementPrintable(['NUDIRESR']) && existsElement('NUDIRESR', datosObjeto)">
          <ea-form-item
            prop="roadNumber"
            :label="$t('quoteBuyGenericFlow.riskData.field.roadNumber')"
            :required="isElementRequired('NUDIRESR', datosObjeto)"
          >
            <ea-input-number
              v-model="model.formValues.roadNumber"
              :precision="returnElementMaxDecimalsByCode('NUDIRESR')"
              :readonly="!isElementModificable('NUDIRESR', datosObjeto)"
            />
          </ea-form-item>
        </ea-col>

        <ea-col :span="6" v-if="isElementPrintable(['CDBLOQSR']) && existsElement('CDBLOQSR', datosObjeto)">
          <ea-form-item
            v-if="!consultaOperation || !elementValueIsAnEmptyString('CDBLOQSR', datosObjeto)"
            prop="blockStair"
            :label="$t('quoteBuyGenericFlow.riskData.field.blockStair')"
            :required="isElementRequired('CDBLOQSR', datosObjeto)"
          >
            <ea-input-text
              v-model="model.formValues.blockStair"
              :readonly="!isElementModificable('CDBLOQSR', datosObjeto)"
            />
          </ea-form-item>
        </ea-col>
        
        <ea-col :span="6" v-else-if="isElementPrintable(['VAACCDSR']) && existsElement('VAACCDSR', datosObjeto)">
          <ea-form-item
            v-if="!consultaOperation || !elementValueIsAnEmptyString('VAACCDSR', datosObjeto)"
            prop="extraInfo"
            :label="$t('quoteBuyGenericFlow.propertyData.field.extraInfo')"
            :required="!consultaOperation && isElementRequired('VAACCDSR', datosObjeto)"
            >
            <ea-input-text
              v-model="model.formValues.extraInfo"
              :readonly="!isElementModificable('VAACCDSR', datosObjeto)"
            />
          </ea-form-item>
        </ea-col>
      </ea-row>

      <ea-row v-if="isElementPrintable(['CDPLANSR', 'CDESCASR', 'CDPUERSR'])">
        <ea-col :span="6" v-if="isElementPrintable(['CDPLANSR']) && existsElement('CDPLANSR', datosObjeto)">
          <ea-form-item
            v-if="!consultaOperation || !elementValueIsAnEmptyString('CDPLANSR', datosObjeto)"
            prop="floor"
            :label="$t('quoteBuyGenericFlow.riskData.field.floor')"
            :required="!consultaOperation && isElementRequired('CDPLANSR', datosObjeto)"
          >
            <ea-input-text
              v-model="model.formValues.floor"
              :readonly="!isElementModificable('CDPLANSR', datosObjeto)"
            />
          </ea-form-item>
        </ea-col>

        <ea-col :span="6" v-if="isElementPrintable(['CDPUERSR']) && existsElement('CDPUERSR', datosObjeto)">
          <ea-form-item
            v-if="!consultaOperation || !elementValueIsAnEmptyString('CDPUERSR', datosObjeto)"
            prop="doorNumber"
            :label="$t('quoteBuyGenericFlow.riskData.field.doorNumber')"
            :required="!consultaOperation && isElementRequired('CDPUERSR', datosObjeto)"
          >
            <ea-input-text
              v-model="model.formValues.doorNumber"
              :readonly="!isElementModificable('CDPUERSR', datosObjeto)"
            />
          </ea-form-item>
        </ea-col>

        <ea-col :span="6" v-if="isElementPrintable(['CDESCASR']) && existsElement('CDESCASR', datosObjeto)">
          <ea-form-item
            v-if="!consultaOperation || !elementValueIsAnEmptyString('CDESCASR', datosObjeto)"
            prop="stairwell"
            :label="$t('quoteBuyGenericFlow.riskData.field.stairwell')"
            :required="!consultaOperation && isElementRequired('CDESCASR', datosObjeto)"
          >
            <ea-input-text
              v-model="model.formValues.stairwell"
              :readonly="!isElementModificable('CDESCASR', datosObjeto)"
            />
          </ea-form-item>
        </ea-col>

        <ea-col :span="6" v-if="isElementPrintable(['VAACCDSR']) && existsElement('VAACCDSR', datosObjeto)">
            <ea-form-item
              v-if="!consultaOperation || !elementValueIsAnEmptyString('VAACCDSR', datosObjeto)"
              prop="extraInfo"
              :label="$t('quoteBuyGenericFlow.propertyData.field.extraInfo')"
              :required="!consultaOperation && isElementRequired('VAACCDSR', datosObjeto)"
            >
            <ea-input-text
              v-model="model.formValues.extraInfo"
              :readonly="!isElementModificable('VAACCDSR', datosObjeto)"
            />
          </ea-form-item>
        </ea-col>
      </ea-row>

      <ea-row>
        <ea-col v-if="existAnyElement(['TCCOPOST', 'CDPOST'], datosObjeto)">
          <post-code-location-input
            id="post-code-location-input"
            ref="postCodeLocationInputBC"
            v-model="model.postCodeLocationInputModel"
            :datosObjeto="datosObjeto"
            :isSelectedCountryAndorra="isSelectedCountryAndorra"
            :cityListRetrievalCode="zipCodeCompCityListRetrievalCode"
            :isReadonly="consultaOperation"
            :isElementModificable="isElementModificable"
            :isAnyElementModificable="isAnyElementModificable"
          ></post-code-location-input>
        </ea-col>
      </ea-row>

      <ea-row v-if="showLocationFields">
        <ea-col :span="6" v-if="isElementPrintable(['CDLATITU']) && existsElement('CDLATITU', datosObjeto)">
          <ea-form-item
            prop="latitude"
            :label="$t('quoteBuyGenericFlow.riskData.field.latitude')"
            :required="isElementRequired('CDLATITU', datosObjeto)"
          >
            <ea-input-text
              v-model="model.formValues.latitude"
              :readonly="!isElementModificable('CDLATITU', datosObjeto)"
            />
          </ea-form-item>
        </ea-col>

        
        <ea-col :span="6" v-if="isElementPrintable(['CDLONGI2']) && existsElement('CDLONGI2', datosObjeto)">
          <ea-form-item
            prop="longitude"
            :label="$t('quoteBuyGenericFlow.riskData.field.longitude')"
            :required="isElementRequired('CDLONGI2', datosObjeto)"
          >
            <ea-input-text
              v-model="model.formValues.longitude"
              :readonly="!isElementModificable('CDLONGI2', datosObjeto)"
            />
          </ea-form-item>
        </ea-col>
      </ea-row>
    </ea-form>
  </div>
</template>

<script lang="ts">
import {
  Component, Prop
} from 'vue-property-decorator';

import {
  mixins
} from 'vue-class-component';

import {
  EABusinessComponent
} from '@zurich-es-npm/ea-front-web-core';

import QbRiskSituationModel from './qb-risk-situation-model';
import {
  EACompositeValidation, EAFormValidationOptions, EAValidation, Form
} from '@zurich-es-npm/ea-front-web-ui';
import {
  GetGeneralDataResponseDataDatosObjeto
} from '@/services/V1/quoteAndBuy/getGeneralDataOperation/post';
import PostCodeLocationInputBusiness from '../post-code-location-input/post-code-location-input-business.vue';
import {
  LabelValue
} from '../qb-producto-asegurado-comunidades/qb-producto-asegurado-comunidades-model';
import {
  existAnyElement,
  existsElement,
  getFieldRules,
  isElementModificable,
  isAnyElementModificable,
  isElementRequired,
  returnElementByCode,
  returnElementListByCode,
  returnElementValueByCode,
  returnElementValueByPossibleCodes,
  returnNumericElementValueByCode,
  elementValueIsAnEmptyString
} from '@/utils/object-utils';
import PermissionUtils from '@/utils/permissions-utils';
import {
  GetDefaultUserValuesResponseCodigoTipoUsuarioEnum as UserTypesEnum
} from '@/services/V1/home/getDefaultUserValuesOperation/post';

@Component({
  name: 'qb-risk-situation',
  components: {
    PostCodeLocationInput: PostCodeLocationInputBusiness,
  },
})

/**
 * Business Component qb-risk-situation
 */
export default class QbRiskSituationBusiness extends mixins<EABusinessComponent<QbRiskSituationModel>>(
  EABusinessComponent
) {

  @Prop({
    required: true,
  })
    datosObjeto!: GetGeneralDataResponseDataDatosObjeto[];

  @Prop({
    'default': () => false
  })
    consultaOperation?: boolean;

  @Prop({
    'default': () => () => true
  })
    isElementPrintable!: Function;

  @Prop()
    title?: string;
    
  countryList?: LabelValue[] = [];

  addressTypeList?: LabelValue[] = [];

  public zipCodeCompCityListRetrievalCode: string = '';

  public isElementRequired = isElementRequired;

  public isElementModificable = isElementModificable;

  public isAnyElementModificable = isAnyElementModificable;

  public existsElement = existsElement;

  public existAnyElement = existAnyElement;

  public elementValueIsAnEmptyString = elementValueIsAnEmptyString;

  public employeeUser = false;

  /**
   * Hook on created.
   *
   * @returns {void}
   */
  public created(): void {
    const userType = PermissionUtils.getUserType();
    this.employeeUser = PermissionUtils.isUserOfType(userType, UserTypesEnum.EMP);
  }

  /**
   * Gets if locations fields are shown
   * True if:
   *  - User is employee
   *  - consultaOperation is true and fields have value
   */
  get showLocationFields(): boolean {
    return this.employeeUser || (
      !!this.consultaOperation && (
        this.model.formValues.latitude !== '' || this.model.formValues.longitude !== ''
      )
    );
  }

  /**
   * Gets form validation options
   */
  get formValidationOptions(): EAFormValidationOptions {
    return {
      rules: {
        country: getFieldRules(this.model.FIELD_TYPES.DEFAULT, 'CDPAIS', this.datosObjeto),
        roadType: getFieldRules(this.model.FIELD_TYPES.DEFAULT, 'CDDIRESR', this.datosObjeto),
        roadName: getFieldRules(this.model.FIELD_TYPES.STRING, 'NODIRESR', this.datosObjeto),
        roadNumber: getFieldRules(this.model.FIELD_TYPES.NUMBER, 'NUDIRESR', this.datosObjeto),
        extraInfo: getFieldRules(this.model.FIELD_TYPES.STRING, 'VAACCDSR', this.datosObjeto),
        blockStair: getFieldRules(this.model.FIELD_TYPES.STRING, 'CDBLOQSR', this.datosObjeto),
        floor: getFieldRules(this.model.FIELD_TYPES.STRING, 'CDPLANSR', this.datosObjeto),
        stairwell: getFieldRules(this.model.FIELD_TYPES.STRING, 'CDESCASR', this.datosObjeto),
        doorNumber: getFieldRules(this.model.FIELD_TYPES.STRING, 'CDPUERSR', this.datosObjeto),
        latitude: getFieldRules(this.model.FIELD_TYPES.STRING, 'CDLATITU', this.datosObjeto),
        longitude: getFieldRules(this.model.FIELD_TYPES.STRING, 'CDLONGI2', this.datosObjeto)
      }
    };
  }

  /**
   * Gets if selected country is equal to Andorra
   * @returns {boolean} true if selected country equal to AND; false otherwise
   */
  get isSelectedCountryAndorra(): boolean {
    return this.model.formValues.country === 'AND';
  }

  /**
   * Get risk situation business title
   * @returns {string}
   */
  getTitle(): string {
    return this.title ?
      this.title : this.$t('quoteBuyGenericFlow.riskData.title').toString();
  }

  /**
   * Sets form initial values
   */
  setFormValues() {
    this.countryList = returnElementListByCode('CDPAIS', this.datosObjeto);
    this.addressTypeList = returnElementListByCode('CDDIRESR', this.datosObjeto);

    this.model.formValues.country = returnElementValueByCode('CDPAIS', this.datosObjeto);
    this.model.formValues.roadType = returnElementValueByCode('CDDIRESR', this.datosObjeto);
    this.model.formValues.roadName = returnElementValueByCode('NODIRESR', this.datosObjeto);
    this.model.formValues.roadNumber = returnNumericElementValueByCode('NUDIRESR', this.datosObjeto);
    this.model.formValues.blockStair = returnElementValueByCode('CDBLOQSR', this.datosObjeto);
    this.model.formValues.floor = returnElementValueByCode('CDPLANSR', this.datosObjeto);
    this.model.formValues.stairwell = returnElementValueByCode('CDESCASR', this.datosObjeto);
    this.model.formValues.doorNumber = returnElementValueByCode('CDPUERSR', this.datosObjeto);
    this.model.formValues.extraInfo = returnElementValueByCode('VAACCDSR', this.datosObjeto);
    this.model.formValues.latitude = returnElementValueByCode('CDLATITU', this.datosObjeto);
    this.model.formValues.longitude = returnElementValueByCode('CDLONGI2', this.datosObjeto);

    this.model.postCodeLocationInputModel.zipCode = returnElementValueByPossibleCodes(
      ['CDPOST', 'TCCOPOST'],
      this.datosObjeto
    );
    this.zipCodeCompCityListRetrievalCode = this.model.postCodeLocationInputModel.zipCode;
    this.model.postCodeLocationInputModel.city = returnElementValueByCode('NOPOBLSR', this.datosObjeto);
    this.model.postCodeLocationInputModel.province = returnElementValueByCode('DSPROVIN', this.datosObjeto);

    this.update();
  }

  /**
   * Returns element max length
   * @param {string} code
   * @returns { number | undefined }
   */
  returnElementMaxDecimalsByCode(code: string): number | undefined {
    const element = returnElementByCode(code, this.datosObjeto);
    if (element) {
      return element.numeroDecimalesElemento;
    }
  }

  /**
   * Returns validation object
   * @returns {EAValidation }
   */
  public validation(): EAValidation {
    const form = this.$refs.form as Form;
    const postCodeLocationInputBC: PostCodeLocationInputBusiness =
      this.$refs.postCodeLocationInputBC as PostCodeLocationInputBusiness;
    return new EACompositeValidation([form.validation(), postCodeLocationInputBC.validation()], this.$tc.bind(this));
  }

  /**
   * Updates component + child components
   */
  public updateAllData() {
    const comp = this.$refs.postCodeLocationInputBC as PostCodeLocationInputBusiness;
    comp.update();
    this.update();
  }
}
</script>
