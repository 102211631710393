<template>
  <div>
    <ea-dialog
      id="createNoteDialog"
      :title="$t('createNoteModal.title')"
      :visible="!model.hidden"
      @open="onDialogOpen"
      :beforeClose="closeModal"
    >
      <ea-form
        ref="form"
        :model="model.createNoteForm"
        :validationOptions="validationOptions"
        :validateOnRuleChange="false"
      >
        <ea-row>
          <ea-col :span="12">
            <!-- Tipo nota -->
            <ea-form-item prop="noteType" :label="$t('createNoteModal.fields.noteType')" required>
              <div slot="tooltipIconInfo">
                <div slot="content" class="t-align-left">
                  <span>
                    {{ $t('createNoteModal.tooltipFirstLine') }}<br/>
                    {{ $t('createNoteModal.tooltipSecondLine') }}
                  </span>
                </div>
              </div>

              <ea-select
                v-model="model.createNoteForm.noteType"
                :placeholder="$t('common.label.select')"
                @change="onNoteTypeChange()"
              >
                <ea-option
                  v-for="noteType in noteTypeList"
                  :key="noteType"
                  :label="getNoteTypeLabel(noteType)"
                  :value="noteType"
                />
              </ea-select>
            </ea-form-item>
          </ea-col>

          <ea-col :span="12">
            <!-- Título nota -->
            <ea-form-item
              prop="noteTitle"
              :label="$t('createNoteModal.fields.noteTitle')"
              :required="noteTitleList.length !== 1">
              <ea-select
                v-model="model.createNoteForm.noteTitle"
                :placeholder="$t('common.label.select')"
                :readonly="noteTitleList.length === 1">
                <ea-option
                  v-for="noteTitle in noteTitleList"
                  :key="noteTitle"
                  :label="noteTitle"
                  :value="noteTitle"
                />
              </ea-select>
            </ea-form-item>
          </ea-col>
        </ea-row>

        <ea-row>
          <ea-col :span="24">
            <!-- Motivo nota -->
            <ea-form-item
              prop="noteBody"
              :label="$t('createNoteModal.fields.noteBody')"
              :required="isNoteBodyRequired"
            >
              <ea-input-textarea
                :maxlength="1024"
                :placeholder="$t('createNoteModal.placeholders.noteBody')"
                v-model="model.createNoteForm.noteBody"
              />
            </ea-form-item>
          </ea-col>
        </ea-row>
      </ea-form>

      <!-- Cancel and save button -->
      <div slot="footer" class="d-display-flex d-justify-flex-end">
        <ea-button type="secondary" @click="closeModal()" class="m-r-16">
          {{ $t('common.label.cancel') }}
        </ea-button>
        <ea-button type="primary" @click="onSave()">
          {{ $t('common.label.save') }}
        </ea-button>
      </div>
    </ea-dialog>
  </div>
</template>

<script lang="ts">
import {
  Component, Prop
} from 'vue-property-decorator';

import {
  mixins
} from 'vue-class-component';

import {
  EABusinessComponent, EAMethod, ResponseWithErrors, throwIfResponseHasErrors
} from '@zurich-es-npm/ea-front-web-core';

import CreateNoteModalModel from './create-note-modal-model';
import {
  EAGetDefaultNoteListApi,
  GetDefaultNoteListRequestTipoPolizaEnum,
  GetDefaultNoteListResponseNotas,
  GetDefaultNoteListResponseNotasTipoDestinoNotaEnum
} from '@/services/V1/notes/getDefaultNoteListOperation/post';
import {
  EASendNoteApi,
  SendNoteRequestTipoPolizaEnum,
  SendNoteRequestTipoDestinoNotaEnum
} from '@/services/V1/notes/sendNoteOperation/post';
import {
  uniqBy
} from 'lodash';
import {
  NoteTypeLabelEnum
} from './create-note-modal-types';
import {
  EAFormValidationOptions,
  eaRequiredValidation,
  eaRangeValidation,
  EAValidation,
  Form
} from '@zurich-es-npm/ea-front-web-ui';
import {
  GenericErrorData
} from '../qb-generic-error/qb-generic-error-business.vue';
import {
  NotificationsUtils
} from '@/utils/notifications/notifications-utils';

@Component({
  name: 'create-note-modal'
})

/**
 * Business Component create-note-modal
 */
export default class CreateNoteModalBusiness extends mixins<
EABusinessComponent<CreateNoteModalModel>
>(EABusinessComponent) {

  public defaultNoteList?: GetDefaultNoteListResponseNotas[];

  public noteTypeList: GetDefaultNoteListResponseNotasTipoDestinoNotaEnum[] = [];

  public noteTitleList: string[] = [];

  @Prop()
    policyNumber!: string;

  @Prop()
    policyVersion!: number;

  @Prop()
    policyType!: string;

  /**
   * Handles dialog open event
   */
  public async onDialogOpen() {
    await this.$nextTick(); // Wait until dialog is open to ensure spinner is shown above
    await this.getDefaultNoteList();
  }

  /**
   * Calls Bff to get default note list
   */
  @EAMethod({
    loading: true
  })
  public async getDefaultNoteList() {
    const api = new EAGetDefaultNoteListApi();
    const response = await api.getDefaultNoteListOperation({
      getDefaultNoteListRequest: {
        tipoPoliza: this.policyType as GetDefaultNoteListRequestTipoPolizaEnum
      }
    });
    if (response) {
      throwIfResponseHasErrors(response as ResponseWithErrors);
      this.defaultNoteList = response.notas;
      this.processDefaultNoteList();
    }
  }

  /**
   * Process received note list
   */
  public processDefaultNoteList() {
    if (!this.defaultNoteList?.length) {
      return;
    }

    this.noteTypeList = uniqBy(this.defaultNoteList, 'tipoDestinoNota')
      .map(element => element.tipoDestinoNota)
      .filter(
        element => !!element
      ) as GetDefaultNoteListResponseNotasTipoDestinoNotaEnum[];
  }

  /**
   * Handles save button
   */
  @EAMethod({
    loading: true
  })
  public async onSave() {
    try {
      await this.validation().validate();
      await this.sendNote();
    } catch (err) {
      // Design system will show form errors
    }
  }

  /**
   * Return form validation object
   * @returns {EAValidation}
   */
  public validation(): EAValidation {
    const form: Form = this.$refs.form as Form;
    return form.validation();
  }

  /**
   * Calls Bff for saving note
   */
  public async sendNote() {
    this.clearFlowErrors();
    NotificationsUtils.clearNotifications();

    try {
      const api = new EASendNoteApi();
      const output = await api.sendNoteOperation({
        sendNoteRequest: {
          codigoPoliza: this.policyNumber,
          versionPoliza: this.policyVersion,
          tipoPoliza: this.policyType as SendNoteRequestTipoPolizaEnum,
          titularNota: this.model.createNoteForm.noteTitle,
          motivoNota: this.model.createNoteForm.noteBody,
          tipoDestinoNota: this.getTipoDestinoNota(),
          codigoTareaAvisoNota: this.getCodigoTareaAvisoNota() || ''
        }
      });

      if (output) {
        if (output.errors?.length) {
          const genericErrorData: GenericErrorData = {
            title: 'Error',
            errors: output.errors,
          };
          this.$emit('showError', genericErrorData);
        } else {
          this.closeModal();
          this.$emit('noteSuccessfullySaved');
        }
      }
    } catch (error) {
      this.$emit('handleGenericError', {
        error,
        errorCode: error.code
      });
    }
  }

  /**
   * Gets tipoDestinoNota property for bff
   * @returns {SendNoteRequestTipoDestinoNotaEnum}
   */
  public getTipoDestinoNota(): SendNoteRequestTipoDestinoNotaEnum {
    return this.model.createNoteForm.noteType === GetDefaultNoteListResponseNotasTipoDestinoNotaEnum.Grupo ?
      SendNoteRequestTipoDestinoNotaEnum.Grupo: SendNoteRequestTipoDestinoNotaEnum.SinDestino;
  }

  /**
   * Closes modal
   */
  closeModal() {
    this.clearModal();
    this.model.hidden = true;
    this.update();
  }

  /**
   * Clears modal data
   */
  clearModal(): void {
    this.model.createNoteForm = {
      noteTitle: '',
      noteBody: ''
    };
    this.noteTitleList = [];
    const form: Form = this.$refs.form as Form;
    form.clearValidate();
  }

  /**
   * Gets note type label
   * @param {GetDefaultNoteListResponseNotasTipoDestinoNotaEnum} noteTypeValue
   * @returns {string}
   */
  public getNoteTypeLabel(noteTypeValue: GetDefaultNoteListResponseNotasTipoDestinoNotaEnum): string {
    return this.$t(NoteTypeLabelEnum[noteTypeValue]).toString();
  }

  /**
   * Handles noteType change. Sets noteTitle list
   */
  public async onNoteTypeChange() {
    await this.$nextTick();

    this.noteTitleList = this.defaultNoteList?.filter(
      note => note.tipoDestinoNota === this.model.createNoteForm.noteType
    ).map(filteredElem => filteredElem.titularNota) as string[];
    this.model.createNoteForm.noteTitle = '';
    this.noteTitleList.sort((elemA, elemB) => elemA.localeCompare(elemB));

    if (this.noteTitleList?.length === 1) {
      this.model.createNoteForm.noteTitle = this.noteTitleList[0];
    }
  }

  /**
   * Gets 'CodigoTareaAvisoNota' for service input
   * @returns {string | undefined}
   */
  public getCodigoTareaAvisoNota(): string | undefined {
    return this.defaultNoteList?.find(
      note => note.tipoDestinoNota === this.model.createNoteForm.noteType &&
        note.titularNota === this.model.createNoteForm.noteTitle
    )?.codigoTareaAvisoNota;
  }

  /**
   * Gets if note body is required for current selection
   */
  get isNoteBodyRequired(): boolean {
    if (!this.model.createNoteForm.noteType || !this.model.createNoteForm.noteTitle) {
      return true;
    }

    const foundNote = this.findDefaultNote(
      this.model.createNoteForm.noteType,
      this.model.createNoteForm.noteTitle
    );

    return !foundNote || !!foundNote.motivoNotaObligatorio;
  }

  /**
   * Finds a note by given params
   * @param {GetDefaultNoteListResponseNotasTipoDestinoNotaEnum} tipoDestinoNota
   * @param {string} titularNota
   * @returns {GetDefaultNoteListResponseNotas[] | undefined}
   */
  findDefaultNote(
    tipoDestinoNota: GetDefaultNoteListResponseNotasTipoDestinoNotaEnum, titularNota: string
  ): GetDefaultNoteListResponseNotas | undefined {
    return this.defaultNoteList?.find(
      note => note.tipoDestinoNota === tipoDestinoNota &&
        note.titularNota === titularNota
    );
  }
  
  /**
   * @returns {EAFormValidationOptions}
   */
  get validationOptions(): EAFormValidationOptions {
    const noteBodyValidations = [
      eaRangeValidation(0, 1024, 'common.label.validation.invalidLength')
    ];

    if (this.isNoteBodyRequired) {
      noteBodyValidations.push(eaRequiredValidation('common.label.validation.fieldRequired'));
    }
    return {
      rules: {
        noteType: [eaRequiredValidation('common.label.validation.fieldRequired')],
        noteTitle: [eaRequiredValidation('common.label.validation.fieldRequired')],
        noteBody: noteBodyValidations
      }
    };
  }

}
</script>
