import {
  GetPersonAddressesResponseDomicilios, GetPersonAddressesResponseTelefonos
} from '@/services/V1/persons/getPersonAddressesOperation/post';
import AddPhoneTomadorModel from '../../modals/add-phone-tomador/add-phone-tomador-model';

export interface IndexedGetPersonAddressesResponseDomicilios {
  phone: GetPersonAddressesResponseTelefonos;
  secuencialCode?: string;
  phoneIndex?: number;
}

/**
 * Model add-edit-phone
 */
class AddEditPhoneModel {
  public phoneList: GetPersonAddressesResponseDomicilios[] = [];

  public indexedPhoneList: IndexedGetPersonAddressesResponseDomicilios[] = [];

  public selectedIndexedPhone?: IndexedGetPersonAddressesResponseDomicilios;

  public selectedPhoneNumber?: string = undefined; // Initialised to make it reactive

  public selectedPhoneNumberCode?: string = undefined; // Initialised to make it reactive

  public selectedPhoneNumberPrefix?: string = undefined;

  public claseDomicilioTelefono?: string = undefined;

  public addPhoneTomadorModel: AddPhoneTomadorModel = new AddPhoneTomadorModel();
}

export default AddEditPhoneModel;
