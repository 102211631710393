<template>
  <div>
    <qb-replacement-data
      v-if="isReplacementData"
      id="replacement-data-form"
      ref="replacementDataForm"
      v-model="model.replacementDataModel"
      :codigoPoliza="model.offerNumber"
      :versionPoliza="model.offerVersion"
      :codigoRamo="productFactory.codigoRamo"
      :productFactory="productFactory"
      :replacementData="replacementData"
      :isReplacementDataView="isReplacementDataView"
      @createReplacement="createReplacement"
      @goToPreviousFlow="goToPreviousFlow"
      @showError="showError"
      @handleGenericError="onHandleGenericError"
      @closeGenericErrorDialog="closeGenericErrorDialog"
    />
  </div>
</template>

<script lang="ts">
import {
  Component, Prop
} from 'vue-property-decorator';

import {
  mixins
} from 'vue-class-component';

import {
  EAView,
  EAMethod,
  ResponseWithErrors,
  throwIfResponseHasErrors,
  EAError,
  EAApplicationLogger,
  EAFlowNavigationUtils
} from '@zurich-es-npm/ea-front-web-core';

import {
  FlowHeaderStepsModel,
  FlowViewsStepsModel,
  PolicyModel
} from '../policy-model';
import QbReplacementDataBusiness from '@/business-components/qb-replacement-data/qb-replacement-data-business.vue';
import ProductBase from '@/utils/quote-buy-product-factory/products/product-base';
import {
  GenericErrorData
} from '@/business-components/qb-generic-error/qb-generic-error-business.vue';
import {
  GetReplacementCreationDataResponse, EAGetReplacementCreationDataApi
} from '@/services/V1/policy/getReplacementCreationDataOperation/post';
import {
  EAGetNewSupplementMovementApi, GetNewSupplementMovementRequestTipoOperacionSuplementoEnum
} from '@/services/V1/policy/getNewSupplementMovementOperation/post';
import {
  NotificationsUtils
} from '@/utils/notifications/notifications-utils';
import moment from 'moment';
import {
  CorpTableData,
  CorpTableNames, fetchCorporateTable
} from '@/utils/corporate-tables';
import {
  OperationsData
} from '@/flows/search-policy/search-policy-model';
import {
  CoexistenceId
} from '@/types/coexistence/coexistence-enum.types';

@Component({
  components: {
    QbReplacementData: QbReplacementDataBusiness
  }
})

/**
 * ReplacementData view
 *
 */
export default class ReplacementDataView extends mixins<EAView<PolicyModel>>(EAView) {
  
  @Prop({
    required: true,
  })
    productFactory!: ProductBase;

  replacementData: GetReplacementCreationDataResponse = {};

  isReplacementDataView: boolean = true;

  corpTableDocumentsProductType: OperationsData[] = [];
  
  /**
   * Hook on created
   */
  @EAMethod({
    loading: true,
  })
  async created() {
    await this.fetchReplacementData();
  }

  /**
   * Fetch data for replacement creation.
   */
  @EAMethod({
    loading: true
  })
  async fetchReplacementData() {
    const api = new EAGetReplacementCreationDataApi();
    const replacementData = await api.getReplacementCreationDataOperation({
      getReplacementCreationDataRequest: {
        codigoPoliza: this.model.offerNumber,
        versionPoliza: this.model.offerVersion
      }
    });

    if (replacementData) {
      // Display errors if any
      throwIfResponseHasErrors(replacementData as ResponseWithErrors);
      
      this.replacementData = replacementData;
    }
  }

  /**
   * Handles replacement creation
   */
  @EAMethod({
    loading: true
  })
  async createReplacement(): Promise<void> {
    const api = new EAGetNewSupplementMovementApi();
    const result = await api.getNewSupplementMovementOperation({
      getNewSupplementMovementRequest: {
        codigoPoliza: this.model.offerNumber,
        versionPoliza: this.model.offerVersion,
        tipoOperacionSuplemento: GetNewSupplementMovementRequestTipoOperacionSuplementoEnum.Suplemento,
        motivoMovimientoPoliza: this.model.replacementDataModel.movementReason || '114',
        codigoComercialProducto: this.model.replacementDataModel.intermediarySelectionModel.productComercialCode || '',
        codigoTecnicoProducto: this.model.replacementDataModel.intermediarySelectionModel.productTechnicalCode || '',
        codigoIntermediario: this.model.replacementDataModel.intermediarySelectionModel.intermediaryCode,
        fechaEfectoMovimiento: this.formatDateToBff(this.model.replacementDataModel.effectDate as Date),
        fechaVencimiento: this.formatDateToBff(this.model.replacementDataModel.dueDate as Date),
        codigoNegocio: this.model.replacementDataModel.intermediarySelectionModel.businessCode || undefined,
      }
    });

    if (result) {
      throwIfResponseHasErrors(result as ResponseWithErrors);

      this.model.offerNumber = result.codigoPoliza || '';
      this.model.offerVersion = result.versionPoliza || 0;
      if (this.model.datosCabecera.datosPoliza) {
        this.model.datosCabecera.datosPoliza.codigoTecnicoProducto =
          this.model.replacementDataModel.intermediarySelectionModel.productTechnicalCode;
        this.model.datosCabecera.datosPoliza.codigoComercialProducto =
          this.model.replacementDataModel.intermediarySelectionModel.productComercialCode;
      }
      this.update();

      // Clear Notifications before going next step
      NotificationsUtils.clearNotifications();

      this.navigateNextStep();
    }
  }

  /**
   * Fetch corporate tables to retrieve products names and parse data.
   */
  @EAMethod()
  async getCorpTablesData(): Promise<void> {
    try {
      const tableData = await fetchCorporateTable(CorpTableNames.ProductTypes);
      if (tableData.data.tableDocuments) {
        this.corpTableDocumentsProductType = this.parseProductTypeTableDocuments(tableData);
      }
    } catch (error) {
      const eaError = error as EAError;
      new EAApplicationLogger().error(
        `SearchPrepolicy::getCorpTablesData:: fetch corporate table :: ${eaError.message}`
      );
      throw error;
    }
  }

  /**
   *
   * Parse corp table documents.
   * @param {CorpTableData} data - corp table data source
   * @returns {OperationsData[]} corp table data parsed
   */
  parseProductTypeTableDocuments(data: CorpTableData): OperationsData[] {
    return data.data.tableDocuments.map(doc => {
      return {
        codProductoCo: doc.CDPRODCO?.trim(),
        codProductoTe: doc.CDPRODTE?.trim(),
        isThunder: doc.INDOFE?.trim()
      };
    });
  }

  /**
   * Go to quote and buy new offer flow and apss the offer data and version to the flow
   *
   * @param {GetOfferListResponseListaOfertas} policy
   */
  @EAMethod({
    loading: true
  })
  async navigateNextStep() {

    if (!this.corpTableDocumentsProductType.length) {
      await this.getCorpTablesData();
    }

    /*
     * GO TO Thunder or Pivot flow
     */
    const productFound = this.corpTableDocumentsProductType.find(
      ele => ele.codProductoTe === this.model.replacementDataModel.intermediarySelectionModel.productTechnicalCode &&
        ele.codProductoCo === this.model.replacementDataModel.intermediarySelectionModel.productComercialCode &&
        ele.isThunder === 'S'
    );

    if (productFound) {
      // Go to next step (general data step)
      this.$emit('changeStep', FlowViewsStepsModel.GeneralDataStep, FlowHeaderStepsModel.GeneralDataStep);
    } else {
      EAFlowNavigationUtils.navigate(this, 'ZonaZurich', {
        operationId: CoexistenceId.MantenimientoIncompletas,
        operationParams: JSON.stringify({
          cdnumpol: this.model.offerNumber
        }),
      });
    }
  }

  /**
   * Format date to 'YYYY-MM-DD' format.
   * @param {Date} date 
   * @returns {string} date formatted
   */
  formatDateToBff(date: Date): string {
    if (date === undefined || !moment(date).isValid()) {
      return '';
    }
    return moment(date).format('YYYY-MM-DD');
  }
  

  /**
   * Return if exist data in replacementData
   */
  get isReplacementData(): boolean {
    return !!this.replacementData && Object.keys(this.replacementData).length > 0;
  }

  /**
   * Emits goToPreviousFlow event
   */
  @EAMethod()
  goToPreviousFlow() {
    this.$emit('goToPreviousFlow');
  }

  /**
   * Emits showError event
   * @param {GenericErrorData} genericErrorData
   */
  @EAMethod()
  showError(genericErrorData: GenericErrorData) {
    this.$emit('showError', genericErrorData);
  }

  /**
   * Emit the refresh data event from address to parent component
   *
   * @param {any} args
   */
  @EAMethod()
  onHandleGenericError(args: any) {
    this.$emit('handleGenericError', args);
  }

  /**
   * Emits closeGenericErrorDialog event
   *
   * @param {any} args
   */
  @EAMethod()
  closeGenericErrorDialog() {
    this.$emit('closeGenericErrorDialog');
  }
}
</script>
