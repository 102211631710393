<template>
  <div class="slide d-display-flex d-direction-column d-justify-space-between h-100">
        <div>
          <h2 v-if="!hideFirstTitle"  class="t-color-primary">{{ $t('onboarding.cardPayment.fourthSlide.title') }}</h2>

          <p class="m-b-0">
            {{ $t('onboarding.cardPayment.fourthSlide.descriptionFirst') }}
          </p>
          <ul class="m-l-28">
            <i18n path="onboarding.cardPayment.fourthSlide.listItems.first.text" tag="li">
              <span class="t-weight-semibold">{{ $t('onboarding.cardPayment.fourthSlide.listItems.first.bold') }}</span>
            </i18n>
            <i18n path="onboarding.cardPayment.fourthSlide.listItems.second.text" tag="li">
              <span class="t-weight-semibold">
                {{ $t('onboarding.cardPayment.fourthSlide.listItems.second.bold') }}
              </span>
            </i18n>
            <i18n path="onboarding.cardPayment.fourthSlide.listItems.third.text" tag="li">
              <span class="t-weight-semibold">{{ $t('onboarding.cardPayment.fourthSlide.listItems.third.bold') }}</span>
            </i18n>
          </ul>
        </div>
        
        <div class="d-display-flex d-align-items-center d-justify-center p-a-32 w-100 t-bg-color-gray-20">
          <img
            :src="require(`@/assets/images/onboarding/cardpayment/onboarding4.png`)"
            :alt="$t('onboarding.cardPayment.fourthSlide.title').toString()"
          >
        </div>
      </div>
</template>
<script lang="ts">

import Vue from 'vue';
import {
  Component, Prop
} from 'vue-property-decorator';

@Component({
  name: 'card-payment-onboarding-4'
})

/**
 * Comunidades onboarding component
 */
export default class CardPaymentOnboarding4Component extends Vue {

  @Prop({
    required: false,
    'default': () => false,
  })
    hideFirstTitle?: boolean;
}
</script>
