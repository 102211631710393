/** 
 * Model fleet-unified-expiration
 */
class FleetUnifiedExpirationModel {
  public formValues: FleetUnifiedExpirationFormValues = {
    hasUnifiedExpiration: undefined,
    unifiedExpirationDate: '' as unknown as Date,
    notUnifiedExpirationDate: '' as unknown as Date,
    effectiveDate: '' as unknown as Date,
    expirationDate: '' as unknown as Date
  };
}

/**
 * 
 */
class FleetUnifiedExpirationFormValues {
  // Tiene vencimiento unificado
  public hasUnifiedExpiration?: boolean;

  public unifiedExpirationDate: Date | null = null;

  public notUnifiedExpirationDate: Date | null = null;
  
  public effectiveDate: Date | null = null;

  public expirationDate: Date | null = null;
}

export default FleetUnifiedExpirationModel;
