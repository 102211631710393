<template>
  <div>
    <ea-form ref="form" :model="model">
      <ea-heading level="4" class="m-b-8">
        {{ $t('quoteBuyGenericFlow.offerIssuanceData.bonus') }}
      </ea-heading>

      <ea-row class="m-b-0">
        <ea-col :span="6">
          <ea-form-item prop="paymentForm" :label="$t('quoteBuyGenericFlow.offerIssuanceData.field.paymentForm')">
            <ea-select
              v-model="model.paymentForm"
              :placeholder="$t('common.label.select')"
              @change="onPaymentFormChange"
              :readonly="model.paymentForms.length === 1"
            >
              <ea-option
                v-for="paymentForm in model.paymentForms"
                :key="paymentForm.valorRestriccion"
                :label="paymentForm.nombreRestriccion"
                :value="paymentForm.nombreRestriccion"
              />
            </ea-select>
          </ea-form-item>
        </ea-col>

        <ea-col :span="6" class="m-b-24">
          <span class="t-weight-bold d-block line-height-18 m-b-16">
            {{ $t('quoteBuyGenericFlow.offerIssuanceData.field.price') }}
          </span>
          <span class="t-weight-normal">
            {{ priceLabel }}
          </span>
        </ea-col>

        <ea-col :span="6" v-if="showFirstSuccessiveReceipts">
          <span class="t-weight-bold d-block line-height-18 m-b-16">
            {{ $t('quoteBuyGenericFlow.offerIssuanceData.field.firstReceipt') }}
          </span>
          <span class="t-weight-normal">
            {{ firstReceiptLabel }}
          </span>
        </ea-col>

        <ea-col :span="6" v-if="showFirstSuccessiveReceipts">
          <span class="t-weight-bold d-block line-height-18 m-b-16">
            {{ $t('quoteBuyGenericFlow.offerIssuanceData.field.successiveReceipts') }}
          </span>
          <span class="t-weight-normal">
            {{ successiveReceiptsLabel }}
          </span>
        </ea-col>
      </ea-row>
    </ea-form>
  </div>
</template>

<script lang="ts">
import {
  Component
} from 'vue-property-decorator';

import {
  mixins
} from 'vue-class-component';

import {
  EABusinessComponent
} from '@zurich-es-npm/ea-front-web-core';

import {
  GetFractionalPaymentsRequestTipoFormaPagoTarificacionGarantiasEnum as FormasPagoEnum
} from '@/services/V1/quoteAndBuy/getFractionalPaymentsOperation/post/api';
import QbOfferIssuanceBonusDataFormModel from './qb-offer-issuance-bonus-data-form-model';

import Utils from '@/utils/utils';

@Component({
  name: 'qb-offer-issuance-bonus-data-form',
})

/**
 * Business Component qb-offer-issuance-bonus-data-form
 */
export default class QbOfferIssuanceBonusDataFormBusiness extends mixins<
EABusinessComponent<QbOfferIssuanceBonusDataFormModel>
>(EABusinessComponent) {

  /**
   * Gets if first / successive receipts are shown
   */
  get showFirstSuccessiveReceipts() {
    return this.model.paymentForm !== FormasPagoEnum.Anual && this.model.paymentForm !== FormasPagoEnum.Unica;
  }

  /**
   * Computed for price Label
   */
  get priceLabel(): string {
    if (this.model.noMinimumInsurance) {
      return this.$t('quoteBuyGenericFlow.offerIssuanceData.notAllowed').toString();
    }
    return this.model.price ? `${Utils.formatFourDigitNumber(this.$n(+this.model.price, 'decimal'))} €` : '-';
  }

  /**
   * Computed for firstReceipt Label
   */
  get firstReceiptLabel(): string {
    if (this.model.noMinimumInsurance) {
      return this.$t('quoteBuyGenericFlow.offerIssuanceData.notAllowed').toString();
    }
    return this.model.firstReceipt
      ? `${Utils.formatFourDigitNumber(this.$n(+this.model.firstReceipt, 'decimal'))} €`
      : '-';
  }

  /**
   * Computed for successiveReceipts Label
   */
  get successiveReceiptsLabel(): string {
    if (this.model.noMinimumInsurance) {
      return this.$t('quoteBuyGenericFlow.offerIssuanceData.notAllowed').toString();
    }
    return this.model.successiveReceipts
      ? `${Utils.formatFourDigitNumber(this.$n(+this.model.successiveReceipts, 'decimal'))} €`
      : '-';
  }

  /**
   * Listener for payment form change
   * Resets prices
   */
  onPaymentFormChange() {
    this.$nextTick(() => {
      this.update();
      this.$emit('resetOfferIssuance');
    });
  }
}
</script>
