<!-- eslint-disable max-len -->
<template>
  <div class="m-b-16">
    <ea-card
     shadow="hidden"
    >
      <div slot="cardbody" class="p-a-16">
        <ea-form ref="form" :model="model">
          <ea-row>
            <ea-col>
              <h3>{{ $t('competencesFlow.competencesView.competencesData.titleData') }}</h3>
            </ea-col>
          </ea-row>
          <ea-row>
            <ea-col :span="6">
              <ea-form-item :label="$t('competencesFlow.competencesView.competencesData.solicitante')" prop="">
                <ea-input-text v-model="competencesData.codigoUsuario" readonly />
              </ea-form-item>
            </ea-col>
            <ea-col :span="6">
              <ea-form-item :label="$t('competencesFlow.competencesView.competencesData.numPoliza')" prop="">
                <ea-input-text v-model="competencesData.codigoPoliza" readonly />
              </ea-form-item>
            </ea-col>
            <ea-col :span="6">
              <ea-form-item :label="$t('competencesFlow.competencesView.competencesData.nombreProducto')" prop="">
                <ea-input-text v-model="competencesData.nombreProducto" readonly />
              </ea-form-item>
            </ea-col>
            <ea-col :span="6">
              <ea-form-item :label="$t('competencesFlow.competencesView.competencesData.fecha')" prop="">
                <ea-input-text :value="dateFormatter(competencesData.fechaInicioPoliza)" readonly />
              </ea-form-item>
            </ea-col>
          </ea-row>
          <ea-row>
            <ea-col :span="6">
              <ea-form-item :label="$t('competencesFlow.competencesView.competencesData.oficinaProductora')" prop="">
                <ea-input-text v-model="competencesData.descripcionOficinaProductora" readonly />
              </ea-form-item>
            </ea-col>
          </ea-row>
          <ea-row>
            <ea-col>
              <h3>{{ $t('competencesFlow.competencesView.competencesData.titleDetail') }}</h3>
            </ea-col>
            <ea-col>
              <ea-table
                v-if="competencesData.competencesManagementList && competencesData.competencesManagementList.length > 0"
                :data="competencesData.competencesManagementList"
                ref="competencesTable"
                id="competencesTable"
                :infinite-scroll="true"
                class="thead-fixed"
              >
                <ea-table-column
                  v-for="column in columnList"
                  :key="column.propKey"
                  :label="column.labelKey ?
                    $t(`competencesFlow.competencesView.competencesData.${column.labelKey}`) : ''"
                  headerClass="no-sortBy"
                >
                  <template slot-scope="row">
                    <template>
                      <p class="ea-table-td-content" tabindex="0">
                        {{ row[column.propKey] }}
                      </p>
                    </template>
                  </template>
                </ea-table-column>
              </ea-table>
            </ea-col>
          </ea-row>
        </ea-form>
      </div>
    </ea-card>
  </div>
</template>

<script lang="ts">
import {
  Component, Prop
} from 'vue-property-decorator';

import {
  mixins
} from 'vue-class-component';

import {
  EABusinessComponent
} from '@zurich-es-npm/ea-front-web-core';

import CompetencesDataModel, {
  ColumnItem,
  ColumnItemType
} from './competences-data-model';
import {
  GetCompetencesManagementDataResponse
} from '@/services/V1/quoteAndBuy/getCompetencesManagementDataOperation/post/api';
import Utils from '@/utils/utils';

@Component({
  name: 'competences-data'
})

/**
 * Business Component competences-data
 */
export default class
CompetencesDataBusiness extends mixins<EABusinessComponent<CompetencesDataModel>>(EABusinessComponent) {

  ColumnItemType = ColumnItemType;

  columnList: ColumnItem[] = [
    {
      propKey: 'descripcionErrorAutorizacion',
      labelKey: 'descripcionErrorAutorizacion',
    },
    {
      propKey: '',
      labelKey: '',
    }
  ];

  fechaInicio: string = '';
  
  @Prop({
    required: true
  })
    competencesData!: GetCompetencesManagementDataResponse;

  /**
   * Created
   */
  created() {
    this.checkIndicadorPuedeSolicitar();
  }

  /**
   * Formatea el valor de la fecha en formato "DD/MM/YYYY"
   * @param {String} value fecha sin en formato YYYY-MM-DD
   * @returns {String} fecha en formato "DD/MM/YYYY"
   */
  dateFormatter(value: string): string {
    return value ? Utils.convertDateToString(value) : '';
  }

  /**
   * Checks competences list & Emits to Set Button Send to Disabled state
   */
  checkIndicadorPuedeSolicitar() {
    const competencesList = this.competencesData.competencesManagementList;
    if (this.competencesData && competencesList) {
      if (competencesList.find(elem => elem.competenciasIndicadorPuedeSolicitar === false)) {
        this.$emit('sendBtnDisabled');
      }
    }
  }
}
</script>
