import QbProductoAseguradoComunidadesModel
  from '@/business-components/qb-producto-asegurado-comunidades/qb-producto-asegurado-comunidades-model';
import {
  QuoteBuyModel
} from '../../../../flows/quote-buy/quote-buy-model';

/**
 * Comunidades model
 *
 * @export
 * @class ComunidadesModel
 * @extends {QuoteBuyModel}
 */
export default class ComunidadesModel extends QuoteBuyModel {
  
  public productModel = new QbProductoAseguradoComunidadesModel();

}
