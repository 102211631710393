import {
  GetGroupedVehicleListResponseListaAgrupaciones
} from '@/services/V1/fleets/getGroupedVehicleListOperation/post';
import FleetGroupVehicleTableModel from '../fleet-group-vehicle-table/fleet-group-vehicle-table-model';
import FleetPackagesWarrantiesModalModel
  from '../fleet-packages-warranties-modal/fleet-packages-warranties-modal-model';

/** 
 * Model fleet-vehicle-groups
 */
class FleetVehicleGroupsModel {
  public fleetGroupVehicleTableModel = new FleetGroupVehicleTableModel();

  public fleetGroupedVehicleList: GetGroupedVehicleListResponseListaAgrupaciones[] = [];

  public fleetPackagesWarrantiesModal = new FleetPackagesWarrantiesModalModel();

  public fleetShowTotal: boolean = false;

  public annualPremiumEmittedVehicles: number = 0;

  public totalEmittedVehicles: number = 0;
}

export default FleetVehicleGroupsModel;
