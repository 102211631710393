import {
  PolicyMovementType
} from '@/flows/policy/policy-model';
import {
  GetGeneralDataResponseDataDatosCabeceraDatosAdicionales
} from '@/services/V1/quoteAndBuy/getGeneralDataOperation/post';
import QbOfferIssuanceBillingDataFormModel
  from '../qb-offer-issuance-billing-data-form/qb-offer-issuance-billing-data-form-model';
import QbOfferIssuanceBonusDataFormModel
  from '../qb-offer-issuance-bonus-data-form/qb-offer-issuance-bonus-data-form-model';

/**
 * Model qb-general-data-information
 */
class QbGeneralDataInformationModel {

  public intermediaryName: string = '';

  public intermediaryCode: string = '';

  public businessName?: string = '';

  public businessCode?: string = '';

  public productName: string = '';

  public productCode: string = '';

  public productComercialCode: string = '';

  public productTechnicalCode: string = '';

  public durationValue: string = '';

  public paymentChannel: string = '';
  
  public additionalDataList: GetGeneralDataResponseDataDatosCabeceraDatosAdicionales[] = [];

  public movementEfect: Date | undefined = new Date();

  public movementDue: Date | undefined =
    new Date(new Date(new Date(new Date().setFullYear(new Date().getFullYear() + 1))).getTime() - (24*60*60*1000));

  public showMore: boolean = false;

  public selectedPayment: string = '0';

  public policyMovementType: PolicyMovementType = PolicyMovementType.Incompleta;

  public paymentMode: string = '';

  public documentationLanguage: string = '';

  public offerIssuanceBonusDataFormModel: QbOfferIssuanceBonusDataFormModel = new QbOfferIssuanceBonusDataFormModel();

  // eslint-disable-next-line max-len
  public offerIssuanceBillingDataFormModel: QbOfferIssuanceBillingDataFormModel = new QbOfferIssuanceBillingDataFormModel();
}
export interface BodyTable {
  key: string;
  value: string;
}

export default QbGeneralDataInformationModel;
