import NotesDescriptionModalModel from '@/business-components/notes-description-modal/notes-description-modal-model';
import {
  PolicyType
} from '@/types/policy-types/policy-types-enum.types';
import {
  EAFlowModel
} from '@zurich-es-npm/ea-front-web-core';

export interface InputModel {
  codigoPoliza: string;
  versionPoliza: number;
  tipoPoliza: PolicyType;
}

/**
 * Flow model
 *
 * @export
 * @class NotesModel
 * @extends {EAFlowModel}
 */
export class NotesModel extends EAFlowModel {
  public descriptionModal: NotesDescriptionModalModel = new NotesDescriptionModalModel();

  public policyNumber: string = '';

  public policyVersion: number = 0;

  public policyType: PolicyType = PolicyType.Incompleta;

  public getFromDate!: Date;
}
