import {
  EAApplicationLogger
} from '@zurich-es-npm/ea-front-web-core';

const MESSAGE_EVENT_NAME = 'message';

/** 
 * Bind event handler, listen for iframe post messages
 *
 * @param {Window} window
 * @param {EventListenerOrEventListenerObject} eventHandler
 */
export function bindMessageEventHandler(
  window: Window,
  eventHandler: EventListenerOrEventListenerObject
): void {
  const logger = new EAApplicationLogger();
  
  if (window.addEventListener) {
    window.addEventListener(MESSAGE_EVENT_NAME, eventHandler, false);
  } else { //IE compatibility
    const elementAsAttacheEvent = window as unknown as {
      attachEvent: Function;
    };
    if (elementAsAttacheEvent.attachEvent) {
      elementAsAttacheEvent.attachEvent(`on${MESSAGE_EVENT_NAME}`, eventHandler);
    } else {
      logger.warn('EAPivotFlow::bindMessageEventHandler:: Cannot bind handler to message event');
    }
  }
}

/** 
 * Unbind event handler, listen for iframe post messages
 *
 * @param {Window} window
 * @param {EventListenerOrEventListenerObject} eventHandler
 */
export function unbindMessageEventHandler(
  window: Window,
  eventHandler: EventListenerOrEventListenerObject
): void {
  const logger = new EAApplicationLogger();
  
  if (window.removeEventListener) {
    window.removeEventListener(MESSAGE_EVENT_NAME, eventHandler, false);
  } else { //IE compatibility
    const elementAsAttacheEvent = window as unknown as {
      detachEvent: Function;
    };
    if (elementAsAttacheEvent.detachEvent) {
      elementAsAttacheEvent.detachEvent(`on${MESSAGE_EVENT_NAME}`, eventHandler);
    } else {
      logger.warn('EAPivotFlow::unbindMessageEventHandler:: Cannot unbind handler to message event');
    }
  }
}
