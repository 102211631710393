import {
  GetGeneralDataResponseDataDatosCabeceraTablaRestricciones
} from '@/services/V1/quoteAndBuy/getGeneralDataOperation/post';
import {
  GetPersonsResponseDatosPersona,
  GetPersonsResponseListaPersonas
} from '@/services/V1/quoteAndBuy/getPersonsOperation/post';
import {
  VehicleFormModel
} from '../fleet-manual-upload-vehicle/fleet-manual-upload-vehicle-model';
import PostCodeLocationInputModel from '../post-code-location-input/post-code-location-input-model';
import QbGenericErrorModel from '../qb-generic-error/qb-generic-error-model';
import {
  mockEmptyDatosBasicosPersona, mockEmptyDatosGeneralesPersona, mockEmptyEmpresa, mockEmptyListaCarnetsPersona,
  mockEmptyMarketing, mockEmptyRegistroEmpresa, mockEmptyRegistroEmpresaDetalles, mockEmptyTitulacionPersona
} from './mocks/qb-search-person-business-mocks';
import FleetsInterveningDriverTableModel
  from '../fleets-intervening-driver-table/fleets-intervening-driver-table-model';

export interface DriverInterface {
  documentNumber: string;
  name: string;
  lastName: string;
}
export interface SelectDriverInterface {
  driver?: AddEditPersonForm;
  vehicle?: VehicleFormModel;
}

/**
 * Model qb-search-client
 */
class QbSearchPersonModel {

  public searchPersonData: SearchPersonData = {
    documentType: '',
    documentNumber: '',
    firstName: '',
    lastName: '',
    lastName2: '',
    businessName: ''
  };

  public selectedPerson: GetPersonsResponseDatosPersona | null = null;

  public selectedDrivers: SelectDriverInterface[] = [];

  public driverListTable: FleetsInterveningDriverTableModel = new FleetsInterveningDriverTableModel();

  public personList: GetPersonsResponseListaPersonas[] = [];

  public addEditPersonForm: AddEditPersonForm | null = null;

  public idTypeList: string[] = [];

  public searchPersonResultsDialogVisible = false;

  public isResultsTableVisible = false;

  public isNoResultsDialogVisible = false;

  public noResultsDialogMessage = '';

  public isAdding = false;

  public isEditting = false;

  public postCodeLocationInputModel = new PostCodeLocationInputModel();

  public taxTreatmentAreaTable: GetGeneralDataResponseDataDatosCabeceraTablaRestricciones[] = [];

  public taxTreatmentArea: string | undefined = undefined;

  public genericErrorModel = new QbGenericErrorModel();

  public searchPerformed = false;

  public dateHandleFormat = 'YYYY-MM-DDTHH:mm:SS.SSS';

  public showDocumentInvalidError = false;

  public emptyEditPersonRequest = {
    datosBasicosPersona: mockEmptyDatosBasicosPersona,
    datosGeneralesPersona: mockEmptyDatosGeneralesPersona,
    titulacionPersona: mockEmptyTitulacionPersona,
    empresa: mockEmptyEmpresa,
    registroEmpresa: mockEmptyRegistroEmpresa,
    registroEmpresaDetalles: mockEmptyRegistroEmpresaDetalles,
    listaCarnetsPersona: mockEmptyListaCarnetsPersona,
    marketing: mockEmptyMarketing
  };
}

export interface SearchPersonData {
  documentType: string;
  documentNumber: string;
  firstName: string;
  lastName: string;
  lastName2: string;
  businessName: string;
}

export interface AddEditPersonForm {
  documentType: string;
  documentNumber: string;
  firstName: string;
  lastName: string;
  lastName2: string;
  businessName: string;
  gender: string | undefined;
  country: string;
  birthDate?: Date;
  zipCode: string;
  city: string;
  taxTreatmentArea?: string;
  updateDocLanguageValue?: string;
  nationality?: string;
  driverLisenceDate?: Date;
  driverFiliationCode?: string;
}

export default QbSearchPersonModel;
