var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('ea-row',[_c('ea-col',{attrs:{"span":24}},[_c('ea-card',{staticClass:"p-a-16 t-bg-color-primary-20",attrs:{"shadow":"hidden"}},[_c('div',{attrs:{"slot":"cardbody"},slot:"cardbody"},[_c('ea-row',{staticClass:"m-b-12"},[_c('ea-col',{attrs:{"span":12}},[_c('ea-heading',{attrs:{"level":"4"}},[_vm._v(" "+_vm._s(_vm.$t('fleets.fleetsFlow.offerIssuanceDataView.offerIssuanceDataComponent.fleet.title'))+" ")])],1),_c('ea-col',{attrs:{"span":12}},[_c('ea-heading',{attrs:{"level":"4"}},[_vm._v(" "+_vm._s(_vm.$t('fleets.fleetsFlow.offerIssuanceDataView.offerIssuanceDataComponent.fleet.holder.title'))+" ")])],1)],1),_c('ea-form',{attrs:{"model":_vm.componentData}},[_c('ea-row',[_c('ea-col',{attrs:{"span":6}},[_c('ea-form-item',{attrs:{"label":_vm.$t(
                'fleets.fleetsFlow.offerIssuanceDataView.offerIssuanceDataComponent.fleet.summary.vehiclesNumber'
              )}},[_c('ea-input-number',{attrs:{"readonly":""},model:{value:(_vm.componentData.numberOfVehicles),callback:function ($$v) {_vm.$set(_vm.componentData, "numberOfVehicles", $$v)},expression:"componentData.numberOfVehicles"}})],1)],1),_c('ea-col',{attrs:{"span":6}},[_c('ea-form-item',{attrs:{"label":_vm.$t('fleets.fleetsFlow.offerIssuanceDataView.offerIssuanceDataComponent.fleet.summary.date')}},[_c('ea-input-text',{attrs:{"readonly":""},model:{value:(_vm.componentData.dates),callback:function ($$v) {_vm.$set(_vm.componentData, "dates", $$v)},expression:"componentData.dates"}})],1)],1),_c('ea-col',{attrs:{"span":6}},[_c('ea-form-item',{attrs:{"label":_vm.$t(
                'fleets.fleetsFlow.offerIssuanceDataView.offerIssuanceDataComponent.fleet.holder.fiscalId'
              )}},[_c('ea-input-text',{attrs:{"readonly":"","value":_vm.componentData.documentId}})],1)],1),_c('ea-col',{attrs:{"span":6}},[_c('ea-form-item',{attrs:{"label":_vm.$t(
                'fleets.fleetsFlow.offerIssuanceDataView.offerIssuanceDataComponent.fleet.holder.socialReason'
              )}},[_c('ea-input-text',{attrs:{"readonly":""},model:{value:(_vm.componentData.businessName),callback:function ($$v) {_vm.$set(_vm.componentData, "businessName", $$v)},expression:"componentData.businessName"}})],1)],1)],1)],1)],1)])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }