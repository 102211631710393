import {
  VueConstructor
} from 'vue';

import {
  GenerateDocumentationViewLabelsModel
} from '@/business-components/qb-documentation-select-proposal/qb-documentation-select-proposal-model';
import {
  OfferIssuanceDataModel
} from '@/business-components/qb-offer-issuance-data/qb-offer-issuance-data-model';
import {
  GetWarrantiesResponseDataDatosGarantias
} from '@/services/V1/quoteAndBuy/getWarrantiesOperation/post';
import {
  SaveOfferDataRequestElementosPoliza
} from '@/services/V1/quoteAndBuy/saveOfferDataOperation/post';
import {
  ParsedTableData
} from '@/utils/corporate-tables';
import {
  EAValidationError
} from '@zurich-es-npm/ea-front-web-core';
import {
  VisibleElementsWarranties,
  PersonRole
} from '../types/product-role-types';
import {
  PolicyModel
} from '@/flows/policy/policy-model';
import {
  QuoteBuyModel
} from '@/flows/quote-buy/quote-buy-model';
import {
  Roles
} from '@/types/roles/roles-enum.types';
import QbGeneralDataInformationModel
  from '@/business-components/qb-general-data-information/qb-general-data-information-model';
import {
  GetGeneralDataResponseDataDatosObjeto
} from '@/services/V1/quoteAndBuy/getGeneralDataOperation/post';
import {
  CodigoRamo
} from '@/types/codigo-ramo/codigo-ramo-enum.types';
import {
  WarrantiesHeaderConfig
} from '@/business-components/qb-warranty/warranties-header/warranties-header-business.vue';
import {
  DocumentType
} from '@/types/document-types/document-types-enum.types';

/**
 * Product Base class
 *
 * @export
 * @class ProductBase
 */
export abstract class ProductBase {
  abstract codigoRamo?: CodigoRamo;

  abstract readonly productName: string;

  abstract readonly productTitle: string;

  abstract readonly productTranslationLabel: string;

  abstract personRoles: PersonRole[];

  abstract readonly objetoAseguradoComponent: VueConstructor;

  abstract readonly documentationSelectProposalComponent: VueConstructor;

  abstract readonly shouldAskForTomadorInNewOfferView: boolean;
  
  abstract readonly shouldShowNewSearchInShowPersonInfo: boolean;

  abstract readonly axesorSearchDocumentTypes: DocumentType[];

  abstract readonly shouldReadonlyDatesInGeneralDataInfo: boolean;

  abstract readonly allowedRolesAtGeneralDataView: Roles[];

  abstract readonly shouldShowProposalTitle: boolean;

  abstract readonly shouldShowCustomPackageName: boolean;

  abstract readonly containerWarrantyCode: string;

  abstract readonly contentWarrantyCode: string;

  abstract readonly shouldShowTypeProposal: boolean;

  abstract readonly shouldValidate1YearMoreDate: boolean;

  abstract readonly alwaysVisibleElementsWarranties: VisibleElementsWarranties[];

  abstract readonly warrantiesHeaderConfig: WarrantiesHeaderConfig;

  abstract readonly warrantiesHeaderPolicyConfig: WarrantiesHeaderConfig;
  
  abstract createModelInstance(): QuoteBuyModel | PolicyModel;

  abstract createPolicyModelInstance(): QuoteBuyModel | PolicyModel;

  abstract generateOfferData(productModel: QuoteBuyModel | PolicyModel): SaveOfferDataRequestElementosPoliza[] | any[];

  abstract getGenerateDocumentationViewLabels(
    productModel: QuoteBuyModel | PolicyModel,
    roadTypeCorpTable: ParsedTableData[]
  ): GenerateDocumentationViewLabelsModel;

  abstract getOfferIssuanceViewLabels(
    productModel: QuoteBuyModel | PolicyModel,
    roadTypeCorpTable: ParsedTableData[]
  ): OfferIssuanceDataModel[];

  abstract validateWarranties(warranties: GetWarrantiesResponseDataDatosGarantias[]): EAValidationError[] | undefined;

  abstract getSecuentialAddressCode(
    role: Roles
  ): string | undefined;

  abstract generatePolicyData(
    model: QuoteBuyModel | PolicyModel,
    filiationCodePagador?: string
  ): { codigoElemento: string; valorElemento: string | undefined }[];

  abstract setDefaultPaymentChannel(generalDataInformationModel: QbGeneralDataInformationModel): void;

  abstract generateAditionalOfferData(
    personModel: PersonRole,
    aditionalData: GetGeneralDataResponseDataDatosObjeto[],
    productModel: QuoteBuyModel | PolicyModel,
    doSaveObjetoAsegurado: boolean
  ): SaveOfferDataRequestElementosPoliza[];


}

export default ProductBase;
