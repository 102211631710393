// eslint-disable-next-line max-len
import QbEmployeeModalIntermediariesModel from '../qb-employee-modal-intermediaries/qb-employee-modal-intermediaries-model';

/** 
 * Model qb-supplement-movement-reason
 */
class QbSupplementMovementReasonModel {

  public movementType = '';

  public movementReason = '';

  public effectDate?: Date = undefined; // Must be blank so user can select a date

  public dueDate = new Date(); // Temporal until data is received

  public naturalEffectDate = new Date(); // Temporal until data is received

  public isExpiration = false;

  public intermediaryCode = '';

  public businessCode = '';

  public businessName = '';

  public productCode = '';

  public productName = '';

  public producerIntermediary = ''; // Intermediario productor

  public lastSituation = '';

  public intermediariesEmployeeModal = new QbEmployeeModalIntermediariesModel();

  public codeStructure = '';

}

export default QbSupplementMovementReasonModel;
