<template>
  <ea-form
    ref="form"
    :model="model"
    :validateOnRuleChange="false"
    :validationOptions="validationOptions"
    class="m-t-8"
  >
    <div
      v-if="!disabled"
      class="d-display-flex d-justify-flex-end top-right m-t-24 m-r-24"
    >
      <ea-tooltip placement="top">
        <ea-button
          v-if="shouldShowNewSearchInShowPersonInfo && canBeChanged"
          type="text"
          @click="onNewSearch()"
        >
          {{ $t("quoteBuyGenericFlow.searchPerson.newSearch.title") }}
        </ea-button>
        <div slot="content">
          {{ $t("quoteBuyGenericFlow.searchPerson.newSearch.tooltip") }}
        </div>
      </ea-tooltip>
      <ea-button type="text" icon="z-edit" @click="onEnterEditionMode()">
        {{ $t("common.label.edit") }}
      </ea-button>
    </div>

    <div v-if="selectedPerson && selectedPerson.datosBasicosPersona">
      <div>
        <ea-row>
          <ea-col :span="6">
            <ea-form-item
              :label="$t('quoteBuyGenericFlow.searchPerson.field.documentType')"
              prop="documentType"
            >
              <ea-input-text
                v-model="model.documentType"
                readonly
              />
            </ea-form-item>
          </ea-col>
          <ea-col :span="6">
            <ea-form-item
              :label="
                $t('quoteBuyGenericFlow.searchPerson.field.documentNumber')
              "
              prop="documentNumber"
            >
              <ea-input-text
                v-model="model.documentNumber"
                readonly
              />
            </ea-form-item>
          </ea-col>
        </ea-row>

        <ea-row v-if="!isSelectedPersonJuridicalPerson">
          <ea-col :span="6">
            <ea-form-item
              :label="$t('quoteBuyGenericFlow.searchPerson.field.firstName')"
              prop="firstName"
            >
              <ea-input-text v-model="model.firstName" readonly />
            </ea-form-item>
          </ea-col>
          <ea-col :span="6">
            <ea-form-item
              :label="$t('quoteBuyGenericFlow.searchPerson.field.lastName')"
              prop="lastName"
            >
              <ea-input-text v-model="model.lastName" readonly />
            </ea-form-item>
          </ea-col>
          <ea-col :span="6" v-if="model.lastName2">
            <ea-form-item
              :label="$t('quoteBuyGenericFlow.searchPerson.field.lastName2')"
              prop="lastName2"
            >
              <ea-input-text v-model="model.lastName2" readonly />
            </ea-form-item>
          </ea-col>
        </ea-row>

        <ea-row v-if="isSelectedPersonJuridicalPerson">
          <ea-col :span="6">
            <ea-form-item
              :label="$t('quoteBuyGenericFlow.searchPerson.field.businessName')"
              prop="businessName"
            >
              <ea-input-text
                v-model="model.businessName"
                readonly
              />
            </ea-form-item>
          </ea-col>
        </ea-row>
      </div>
    </div>

    <div v-if="selectedPerson && selectedPerson.datosGeneralesPersona">
      <ea-row>
        <template v-if="!isSelectedPersonJuridicalPerson">
          <ea-col :span="6">
            <ea-form-item
              :label="
                $t('quoteBuyGenericFlow.searchPerson.field.gender.gender')
              "
              prop="gender"
            >
              <ea-input-text v-model="model.gender" readonly />
            </ea-form-item>
          </ea-col>
          <ea-col :span="6">
            <ea-form-item
              :label="$t('quoteBuyGenericFlow.searchPerson.field.birthDate')"
              prop="birthDate"
            >
              <ea-date-picker
                v-if="isTomador && existsTomadorScoreElement('FENACSCR')"
                v-model="model.birthDate"
                :readonly="isReadonly"
              />
              <ea-input-text
                v-else
                v-model="model.birthDate"
                readonly
              />
            </ea-form-item>
          </ea-col>
          <ea-col :span="6">
            <ea-form-item
              :label="$t('quoteBuyGenericFlow.searchPerson.field.country')"
              prop="country"
            >
              <ea-select
                v-if="isTomador && existsTomadorScoreElement('CDPAISCR')"
                v-model="model.country"
                :readonly="isReadonly"
                @change="resetZipCodeFields()"
              >
                <ea-option
                  v-for="country in scoreCountryList"
                  :key="country.valorRestriccion"
                  :label="country.nombreRestriccion"
                  :value="country.valorRestriccion"
                />
              </ea-select>
              <ea-input-text
                v-else
                v-model="model.country"
                readonly
              />
            </ea-form-item>
          </ea-col>
        </template>

        <template v-if="isSelectedPersonJuridicalPerson">
          <ea-col :span="6">
            <ea-form-item
              :label="$t('quoteBuyGenericFlow.searchPerson.field.country')"
              prop="country"
            >
              <ea-select
                v-if="isTomador && existsTomadorScoreElement('CDPAISCR')"
                v-model="model.country"
                :readonly="isReadonly"
                @change="resetZipCodeFields()"
              >
                <ea-option
                  v-for="country in scoreCountryList"
                  :key="country.valorRestriccion"
                  :label="country.nombreRestriccion"
                  :value="country.valorRestriccion"
                />
              </ea-select>
              <ea-input-text
                v-else
                v-model="model.country"
                readonly
              />
            </ea-form-item>
          </ea-col>
        </template>

        <ea-col v-if="shouldShowTaxTreatmentAreaField" :span="6">
          <ea-form-item
            prop="taxTreatmentArea"
            :label="
              $t('quoteBuyGenericFlow.searchPerson.field.taxTreatmentArea')
            "
          >
            <ea-input-text
              v-model="model.taxTreatmentArea"
              readonly
            />
          </ea-form-item>
        </ea-col>
      </ea-row>
      <template v-if="isTomador && existsTomadorScoreElement('TCPFJSCR')">
        <ea-form
          v-if="isForeignCountry"
          ref="zipCodeForm"
          :model="model.postalCodeModel"
          :validateOnRuleChange="false"
        >
          <ea-row>
            <ea-col :span="6">
              <ea-form-item
                prop="zipCode"
                :label="$t('quoteBuyGenericFlow.searchPerson.field.zipCode')"
              >
                <ea-input-text
                  v-model="model.postalCodeModel.zipCode"
                  :readonly="isReadonly"
                />
              </ea-form-item>
            </ea-col>
            <ea-col :span="6">
              <ea-form-item
                prop="city"
                :label="$t('quoteBuyGenericFlow.searchPerson.field.city')"
                required
              >
                <ea-input-text
                  v-model="model.postalCodeModel.city"
                  :readonly="isReadonly"
                />
              </ea-form-item>
            </ea-col>
            <ea-col :span="6">
              <ea-form-item
                prop="province"
                :label="$t('quoteBuyGenericFlow.searchPerson.field.province')"
              >
                <ea-input-text
                  v-model="model.postalCodeModel.province"
                  :readonly="isReadonly"
                />
              </ea-form-item>
            </ea-col>
          </ea-row>
        </ea-form>
        <postal-code
          v-else
          id="policyHolder-postal-code"
          ref="postalCodeComp"
          class="w-100"
          v-model="model.postalCodeModel"
          :datosObjeto="[]"
          :isSelectedCountryAndorra="isSelectedCountryAndorra"
          :isReadonly="isReadonly"
        />
      </template>
    </div>
  </ea-form>
</template>

<script lang="ts">
import PostCodeLocationInputBusiness
  from '@/business-components/post-code-location-input/post-code-location-input-business.vue';
import {
  GetGeneralDataResponseDataDatosCabeceraElementosPoliza,
  GetGeneralDataResponseDataDatosCabeceraTablaRestricciones, GetGeneralDataResponseDataDatosObjeto
} from '@/services/V1/quoteAndBuy/getGeneralDataOperation/post';
import {
  GetPersonsResponseDatosPersona
} from '@/services/V1/quoteAndBuy/getPersonsOperation/post';
import {
  Roles
} from '@/types/roles/roles-enum.types';
import {
  ParsedTableData
} from '@/utils/corporate-tables';
import {
  NotificationsUtils
} from '@/utils/notifications/notifications-utils';
import {
  EABusinessComponent,
  EAErrorManager
} from '@zurich-es-npm/ea-front-web-core';
import {
  EACompositeValidation,
  EAFormValidationOptions, eaRequiredValidation, EAValidation, EAValidationTypes, Form
} from '@zurich-es-npm/ea-front-web-ui';
import moment from 'moment';
import {
  mixins
} from 'vue-class-component';
import {
  Component, Prop, Watch
} from 'vue-property-decorator';
import QbShowPersonInfoModel from './qb-show-person-info-business-model';
import {
  ScoringOnlineField,
  ScoringOnlineFieldsEnum
} from '../../types/scoring-online/scoring-online-enum.types';

@Component({
  name: 'qb-show-person-info',
  components: {
    'postal-code': PostCodeLocationInputBusiness
  }
})

/**
 * Show Person Info Component
 */
export default class QbShowPersonInfoComponent extends mixins<EABusinessComponent<QbShowPersonInfoModel>>(
  EABusinessComponent
) {
  @Prop()
    role?: Roles;

  @Prop()
    selectedPerson?: GetPersonsResponseDatosPersona;

  @Prop()
    documentTypeList?: ParsedTableData[];

  @Prop()
    countryList?: ParsedTableData[];

  @Prop()
    taxTreatmentAreaList?: GetGeneralDataResponseDataDatosCabeceraTablaRestricciones[];

  @Prop()
    taxTreatmentArea?: string;

  @Prop()
    disabled!: boolean;

  @Prop()
    showTaxTreatmentAreaField?: boolean;

  @Prop({
    'default': () => true
  })
    shouldShowNewSearchInShowPersonInfo?: boolean;

  @Prop({
    'default': () => true
  })
    canBeChanged?: boolean;

  @Prop({
    required: false,
    'default': () => false,
  })
    consultaOperation?: boolean;

  @Prop()
    additionalElements?: GetGeneralDataResponseDataDatosObjeto[];

  @Prop()
    scoringFieldsInvalidated?: boolean;

  @Prop()
    policyElementsScore?: GetGeneralDataResponseDataDatosCabeceraElementosPoliza[];

  validationOptions: EAFormValidationOptions = {
    rules: {},
  };
  
  scoreCountryList?: GetGeneralDataResponseDataDatosCabeceraTablaRestricciones[];

  /**
   * Gets if selected country is equal to Andorra
   * @returns {boolean} true if selected country equal to AND; false otherwise
   */
  get isSelectedCountryAndorra(): boolean {
    return this.model.country === 'AND';
  }

  /**
   * Returns if editable fields must be shown as readonly.
   */
  get isReadonly(): boolean {
    return !!this.consultaOperation;
  }

  /**
   * Lifecycle created hook
   */
  created() {
    if (this.isTomador && !this.consultaOperation) {
      // Add validation rules for Scoring fields if they are present
      if (this.existsTomadorScoreElement(ScoringOnlineFieldsEnum.FechaNacimiento)) {
        // FECHA NACIMIENTO
        this.validationOptions.rules.birthDate = [
          eaRequiredValidation('common.label.validation.fieldRequired',
            undefined,
            EAValidationTypes.DATE
          )
        ];
      }
      if (this.existsTomadorScoreElement(ScoringOnlineFieldsEnum.Pais)) {
        // PAIS
        this.validationOptions.rules.country = [eaRequiredValidation('common.label.validation.fieldRequired')];
      }
    }

    // Map person fields
    if (this.selectedPerson) {
      this.mapPersonFields();
    }

    if (this.documentTypeList?.length) {
      this.mapPersonDocumentTypeFields();
    }

    if (this.countryList?.length) {
      this.mapPersonCountryFields();
    }
  }

  /**
   * Method that maps the person fields to the model fields.
   */
  mapPersonFields() {
    this.model.documentNumber =
      this.selectedPerson?.datosBasicosPersona?.codigoIdentificacionFiscal?.toUpperCase() || '';

    if (this.isSelectedPersonJuridicalPerson) {
      this.model.businessName = this.getBusinessName().toUpperCase() || '';
    } else {
      this.model.firstName = this.selectedPerson?.datosBasicosPersona?.nombrePropio?.toUpperCase() || '';
      this.model.lastName = this.selectedPerson?.datosBasicosPersona?.primerApellido?.toUpperCase() || '';
      this.model.lastName2 =
        this.selectedPerson?.datosBasicosPersona?.segundoApellido?.toUpperCase() || '';
      this.model.gender = this.$t(
        this.getGenderTitleKey(this.selectedPerson?.datosGeneralesPersona?.sexo || '')
      ).toString()
        .toUpperCase();

      // Birth date
      this.mapBirthDate();
    }

    this.mapPostalCode();
  }

  /**
   * 
   * @param {ScoringOnlineFieldsEnum} scoreField
   * @returns {boolean}
   */
  takeDataFromScore(scoreField: ScoringOnlineFieldsEnum): boolean {
    return !this.scoringFieldsInvalidated && this.existsTomadorScoreElement(scoreField);
  }

  /**
   * Map postal code field.
   */
  mapPostalCode() {
    const POLICY_ELEMENT_CITY_CODE = 'TCPOBTOM';
    const POLICY_ELEMENT_CITY_NAME = 'NOPOBTOM';
    const takeDataFromScore = this.takeDataFromScore(ScoringOnlineFieldsEnum.CodigoPostal);
    let cityCode: string; // City code to set
    let cityName: string; // City name to set

    if (takeDataFromScore) {
      const postalCodeScoreValue = this.additionalElements?.find(
        el => el.codigoElemento === ScoringOnlineFieldsEnum.CodigoPostal
      )?.valorElemento || '';
      this.model.postalCodeModel.zipCode = postalCodeScoreValue;
      cityCode = this.policyElementsScore?.find( // Take 'cityCode' from policy elements
        element => element.codigoElemento === POLICY_ELEMENT_CITY_CODE
      )?.valorElemento || '';
      cityName = this.policyElementsScore?.find( // Take 'cityName' from policy elements
        element => element.codigoElemento === POLICY_ELEMENT_CITY_NAME
      )?.valorElemento || '';
    } else {
      this.model.postalCodeModel.zipCode = this.selectedPerson?.domicilioPrincipal?.codigoPostalConLetras || '';
      cityCode = this.selectedPerson?.domicilioPrincipal?.codigoPoblacionINE || '';
      cityName = this.selectedPerson?.domicilioPrincipal?.nombrePoblacion || '';
    }

    // If field is editable (has scoring) and there is zipcode --> force refresh data
    if (this.existsTomadorScoreElement(ScoringOnlineFieldsEnum.CodigoPostal) && this.model.postalCodeModel.zipCode) {
      this.$nextTick(() => {
        const postalCodeComp = this.$refs.postalCodeComp as PostCodeLocationInputBusiness | undefined;
        if (postalCodeComp) {
          postalCodeComp.onZipCodeChange(this.model.postalCodeModel.zipCode);
          postalCodeComp.$nextTick(() => {
            postalCodeComp.model.cityCode = cityCode;
            this.model.postalCodeModel.cityCode = cityCode;
            postalCodeComp.model.city = cityName;
            this.model.postalCodeModel.city = cityName;
            postalCodeComp.onCityChange();
            postalCodeComp.update();
          });
        }
      });
    }
  }

  /**
   * Map birth date field
   */
  mapBirthDate() {
    const takeDataFromScore = this.takeDataFromScore(ScoringOnlineFieldsEnum.FechaNacimiento);

    if (takeDataFromScore) {
      // Date from additional element
      const birthDateScoreValue = this.additionalElements?.find(
        el => el.codigoElemento === ScoringOnlineFieldsEnum.FechaNacimiento
      )?.valorElemento || '0';
      this.model.birthDate = birthDateScoreValue === '0'
        ? ''
        : new Date(moment(birthDateScoreValue).format('YYYY-MM-DD'));
    } else if (this.existsTomadorScoreElement(ScoringOnlineFieldsEnum.FechaNacimiento)) {
      // Data from Person but editable for score
      this.model.birthDate = this.selectedPerson?.datosGeneralesPersona?.fechaNacimiento
        ? new Date(this.selectedPerson?.datosGeneralesPersona?.fechaNacimiento)
        : '';
    } else {
      // Date from person
      this.model.birthDate = this.getFormattedDate(this.selectedPerson?.datosGeneralesPersona?.fechaNacimiento) || '';
    }
  }

  /**
   * Method that maps the person document type fields to the model fields
   */
  mapPersonDocumentTypeFields() {
    this.model.documentType = this.getDocumentTypeNameByCode(
      this.selectedPerson?.datosBasicosPersona?.tipoDocumento?.toString().toUpperCase() || ''
    );
  }

  /**
   * Method that maps the person country fields to the model fields
   */
  mapPersonCountryFields() {
    const takeDataFromScore = this.takeDataFromScore(ScoringOnlineFieldsEnum.Pais);

    this.scoreCountryList =
      this.additionalElements?.find(el => el.codigoElemento === ScoringOnlineFieldsEnum.Pais)?.tablaRestricciones || [];

    if (takeDataFromScore) {
      // Date from additional element
      const paisScoreValue = this.additionalElements?.find(
        el => el.codigoElemento === ScoringOnlineFieldsEnum.Pais
      )?.valorElemento || '';
      this.model.country = paisScoreValue;
    } else if (this.existsTomadorScoreElement(ScoringOnlineFieldsEnum.Pais)) {
      // Data from Person but editable for score
      this.model.country = this.selectedPerson?.datosGeneralesPersona?.codigoResidencia || '';
    } else {
      this.model.country = this.getCountryNameByCode(
        this.selectedPerson?.datosGeneralesPersona?.codigoResidencia || ''
      ).toUpperCase();
    }
  }

  /**
   * Method that maps the tax treatment area fields to the model fields
   */
  mapTaxTreatmentAreaFields() {
    this.model.taxTreatmentArea = this.taxTreatmentAreaName?.toUpperCase() || '';
  }

  /**
   * Handle cancel button and dispatch the event.
   */
  onEnterEditionMode() {
    this.$emit('enterEditionMode');
  }

  /**
   * Gets document type name by code
   * @param { string | null } documentTypeCode
   * @return { string } name corresponding to received code
   */
  getDocumentTypeNameByCode(documentTypeCode: string | null) {
    if (!this.documentTypeList || !documentTypeCode) {
      return '';
    }

    const result = this.documentTypeList.find(documentType => documentType.value === documentTypeCode);

    if (result) {
      return result.label;
    }

    return '';
  }

  /**
   * Gets country name by code
   * @param { string | null } countryCode
   * @return { string } name corresponding to received code
   */
  getCountryNameByCode(countryCode: string | null) {
    if (!this.countryList || !countryCode) {
      return '';
    }

    const result = this.countryList.find(country => country.value === countryCode);

    if (result) {
      return result.label;
    }

    return '';
  }

  /**
   * Gets tax treatment area name by code
   * @return { string } name corresponding to component's tax treatment
   */
  get taxTreatmentAreaName() {
    if (!this.taxTreatmentAreaList || !this.taxTreatmentArea) {
      return '';
    }

    const result = this.taxTreatmentAreaList.find(
      taxTreatmentAreaEl => taxTreatmentAreaEl.valorRestriccion === this.taxTreatmentArea
    );

    if (result) {
      return result.nombreRestriccion;
    }

    return '';
  }

  /**
   * Checks if selected person's document type refers to a juridical person (persona física)
   * @return { boolean } true if document type refers to a juridical person; false otherwise
   */
  get isSelectedPersonJuridicalPerson(): boolean {
    if (!this.selectedPerson?.datosBasicosPersona?.tipoDocumento) {
      return false;
    }
    const juridicalPersonDocumentType = ['E', 'X', 'C'];
    return juridicalPersonDocumentType.includes(this.selectedPerson.datosBasicosPersona.tipoDocumento);
  }

  /**
   * Get business name from name, lastName & lastName 2 fields
   * @returns {string} business name
   */
  getBusinessName(): string {
    return (
      (this.selectedPerson?.datosBasicosPersona?.primerApellido || '') +
      (this.selectedPerson?.datosBasicosPersona?.segundoApellido || '') +
      (this.selectedPerson?.datosBasicosPersona?.nombrePropio || '')
    );
  }

  /**
   * Gets date in display format
   * @param {string} date - date to be formatted
   * @returns {string} date formatted for display
   */
  getFormattedDate(date?: string) {
    if (!date) {
      return '';
    }

    return moment(new Date(date)).format('DD/MM/YYYY');
  }

  /**
   * Formats gender key to show "Male" or "Female" in the view.
   *
   * @param {string} gender - A gender
   * @returns {string} - Gender value key
   */
  getGenderTitleKey(gender: string): string {
    return `quoteBuyGenericFlow.searchPerson.field.gender.${gender === 'V' ? 'male' : 'female'}`;
  }

  /**
   * Emits event 'resetSelection'
   */
  onNewSearch() {
    NotificationsUtils.clearNotifications();
    EAErrorManager.clearError();
    
    this.$emit('resetSelection');
  }

  /**
   * Watcher for selectedPerson prop.
   * @param {GetPersonsResponseDatosPersona} newPerson - new selected person
   * @param {GetPersonsResponseDatosPersona} oldPerson - old selected person
   */
  @Watch('selectedPerson')
  onSelectedPersonChange(newPerson: GetPersonsResponseDatosPersona, oldPerson: GetPersonsResponseDatosPersona) {
    if ((JSON.stringify(newPerson) !== JSON.stringify(oldPerson)) || this.model.documentNumber === '') {
      // Only execute function if objects are different
      if (this.selectedPerson) {
        this.mapPersonFields();
      }

      if (this.documentTypeList?.length) {
        this.mapPersonDocumentTypeFields();
      }

      if (this.countryList?.length) {
        this.mapPersonCountryFields();
      }
    }
  }

  /**
   * Watcher for documentTypeList prop
   */
  @Watch('documentTypeList', {
    immediate: true,
  })
  onDocumentTypeListChange() {
    if (this.selectedPerson && this.documentTypeList?.length) {
      this.mapPersonDocumentTypeFields();
    }
  }

  /**
   * Watcher for countryList prop
   */
  @Watch('countryList', {
    immediate: true,
  })
  onCountryListChange() {
    if (this.selectedPerson && this.countryList?.length) {
      this.mapPersonCountryFields();
    }
  }

  /**
   * Watcher for taxTreatmentAreaName prop
   */
  @Watch('taxTreatmentAreaName', {
    immediate: true,
  })
  onTaxTreatmentAreaNameChange() {
    if (this.taxTreatmentAreaName) {
      this.mapTaxTreatmentAreaFields();
    }
  }

  /**
   * Returns true if user role is 'Tomador' and
   * given additional element is present and `elementoModificable` is different than undefined.
   * @param {ScoringOnlineField} elementCode 
   * @returns {boolean}
   */
  existsTomadorScoreElement(elementCode: ScoringOnlineField): boolean {
    return this.isTomador
      && this.additionalElements?.find(el => el.codigoElemento === elementCode)?.elementoModificable !== undefined;
  }

  /**
   * Getter to know if person has 'tomador' role
   */
  get isTomador(): boolean {
    return this.role === Roles.Tomador;
  }

  /**
   * Getter that retrieves if tax treatment area field is visible in form
   */
  get shouldShowTaxTreatmentAreaField(): boolean {
    return this.isTomador && !!this.showTaxTreatmentAreaField;
  }


  /**
   * Checks if selected country is a foreign country (distinct from Spain and Andorra)
   */
  get isForeignCountry() {
    return this.model.country !== 'ESP' &&
      this.model.country !== 'AND';
  }

  /**
   * Return form validation object
   * @returns {EAValidation}
   */
  public validation(): EAValidation {
    const form: Form = this.$refs.form as Form;
    return form.validation();
  }

  /**
   * Reset postal code, city and province when country changes
   */
  resetZipCodeFields() {
    this.model.postalCodeModel.city = '';
    this.model.postalCodeModel.cityCode = '';
    this.model.postalCodeModel.province = '';
    this.model.postalCodeModel.populationNumber = 0;
    this.model.postalCodeModel.zipCode = '';
  }

  /**
   * Checks if selected country is a foreign country (distinct from Spain and Andorra)
   */
  async validateScoreFields() {
    if (this.isTomador && !this.consultaOperation) {
      const validations:EAValidation[] = [this.validation()];
      if (this.existsTomadorScoreElement(ScoringOnlineFieldsEnum.TipoPersona)) {
        if (this.isForeignCountry) {
          // Formulario custom de zipCode
          const zipCodeForm: Form =
          this.$refs.zipCodeForm as Form;
          validations.push(zipCodeForm.validation());
        } else {
          const postalCodeComp = this.$refs.postalCodeComp as PostCodeLocationInputBusiness;
          validations.push(postalCodeComp.validation());
        }
      }
      // Validations
      const validationForms = new EACompositeValidation(validations, this.$tc.bind(this));

      await validationForms.validate();
    }
  }
}
</script>

<style scoped>
.top-right {
  position: absolute;
  top: 0;
  right: 0;
}
</style>
