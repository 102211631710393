/* eslint-disable max-lines */
import {
  EARouteConfig
} from '@zurich-es-npm/ea-front-web-core';

import DefaultLayout from '../layout/default-layout.vue';
import DefaultPublicLayout from '../layout/default-public-layout.vue';

/**
 * Architecture: Security route entries
 * Don't modify or delete
 */
export const securityRouter: EARouteConfig[] = [
  {
    path: '/sec',
    name: 'Security',
    redirect: '/sec/logout',
    component: DefaultPublicLayout,
    children: [
      {
        path: 'logout',
        name: 'EALogoutFlow',
        component: () => import('../flows/ea/security/ea-logout-flow.vue'),
        meta: {
          skipMultiOperation: true,
          isPublic: true
        }
      },
      {
        path: 'login',
        name: 'EALoginFlow',
        props: true,
        component: () => import('../flows/ea/security/ea-login-flow.vue'),
        meta: {
          skipMultiOperation: true,
          isPublic: true
        }
      },
      {
        path: 'do-login',
        name: 'EADoLoginAction',
        meta: {
          skipMultiOperation: true
        }
      },
      {
        path: 'session-expired',
        name: 'EASessionExpiredFlow',
        props: true,
        component: () => import('../flows/ea/security/ea-session-expired-flow.vue'),
        meta: {
          skipMultiOperation: true,
          isPublic: true
        }
      },
      {
        path: 'unauthorized',
        name: 'EAUnauthorizedFlow',
        props: true,
        component: () => import('../flows/ea/security/ea-unauthorized-flow.vue'),
        meta: {
          skipMultiOperation: true,
          isPublic: true
        }
      }
    ]
  }
];

/**
 * Architecture: Security route entries
 * Don't modify or delete
 */
export const securityPrivateRouter: EARouteConfig[] = [
  {
    path: '/private-sec',
    name: 'Authorization',
    redirect: '/sec/logout',
    component: DefaultLayout,
    children: [
      {
        path: 'unauthorized',
        name: 'EAPrivateUnauthorizedFlow',
        props: true,
        component: () => import('../flows/ea/security/ea-unauthorized-flow.vue')
      }
    ]
  }
];

/**
 * Architecture: Error route entries
 * Don't modify or delete
 */
export const errorRouter: EARouteConfig[] = [
  {
    path: '/error',
    name: 'Error',
    redirect: '/error/404',
    component: DefaultPublicLayout,
    children: [
      {
        path: '404',
        component: () => import('../flows/ea/error/ea-not-found-flow.vue'),
        meta: {
          skipMultiOperation: true,
          isPublic: true
        }
      },
    ]
  },
  {
    path: '*',
    redirect: '/error/404',
    meta: {
      skipMultiOperation: true,
      isPublic: true
    }
  }
];

/** 
 * Home route entry
 */
export const homeRouter: EARouteConfig[] = [
  {
    path: '/',
    name: 'HomeBase',
    redirect: '/home',
    component: DefaultLayout,
    children: [
      {
        path: 'home',
        name: 'Dashboard',
        component: () => import('../flows/home-flow/home-flow.vue'),
        meta: {
          title: 'Inicio'
        }
      },
    ],
  },
];

/** 
 * Flow route entries
 */
export const appRouter: EARouteConfig[] = [
  {
    path: '/competences-management',
    name: 'CompetencesManagement',
    redirect: '',
    component: DefaultLayout,
    children: [
      {
        path: 'competences-management-summary',
        name: 'CompetencesManagementSummary',
        component: () => import(
          '../flows/competences-management/competences-management-summary/competences-management-summary-flow.vue'
        ),
        props: true,
        meta: {
          title: 'competencesManagementSummary.title'
        }
      }
    ]
  },
  {
    path: '/business-opportunities',
    name: 'BusinessOpportunities',
    redirect: '',
    component: DefaultLayout,
    children: [
      {
        path: 'business-opportunities-summary',
        name: 'BusinessOpportunitiesSummary',
        component: () => import(
          '../flows/business-opportunities/business-opportunities-summary/business-opportunities-summary-flow.vue'
        ),
        props: true,
        meta: {
          title: 'businessOpportunitiesSummary.title'
        }
      }
    ]
  },
  {
    path: '/returned-receipts',
    name: 'ReturnedReceipts',
    redirect: '',
    component: DefaultLayout,
    children: [
      {
        path: 'returned-receipts-summary',
        name: 'ReturnedReceiptsSummary',
        component: () => import(
          '../flows/returned-receipts/returned-receipts-summary/returned-receipts-summary-flow.vue'
        ),
        props: true,
        meta: {
          title: 'returnedReceiptsSummary.title'
        }
      }
    ]
  },
  {
    path: '/claims',
    name: 'Claims',
    redirect: '',
    component: DefaultLayout,
    children: [
      {
        path: 'claims-summary',
        name: 'ClaimsSummary',
        component: () => import('../flows/claims/claims-summary/claims-summary-flow.vue'),
        props: true,
        meta: {
          title: 'claimsSummary.title'
        }
      }
    ]
  },
  {
    path: '/new-offer',
    name: 'newOfferFlow',
    redirect: '',
    component: DefaultLayout,
    children: [
      {
        path: 'new-offer-selection',
        name: 'newOfferSelection',
        component: () => import('../flows/new-offer/new-offer-flow.vue'),
        props: true,
        meta: {
          title: 'newOfferFlow.title'
        }
      }
    ]
  }, {
    path: '/contacts',
    name: 'Contacts',
    redirect: '',
    component: DefaultLayout,
    children: [
      {
        path: 'all-contacts-details',
        name: 'allContactsDetails',
        component: () => import('../flows/contacts/all-contacts-details/all-contacts-details-flow.vue'),
        props: true,
        meta: {
          title: 'allContactsDetails.title'
        }
      }
    ]
  }, {
    path: '/quote-buy',
    name: 'QuoteAndBuy',
    redirect: '',
    component: DefaultLayout,
    children: [
      {
        path: 'quote-buy-new',
        name: 'QuoteAndBuyNew',
        component: () => import('../flows/quote-buy/quote-buy-flow.vue'),
        props: true,
        meta: {
          title: 'quoteBuyGenericFlow.titleDefault.THCO'
        }
      },
      {
        path: 'all-construction-risk-new',
        name: 'AllConstructionRiskNew',
        component: () => import('../flows/quote-buy/quote-buy-flow.vue'),
        props: true,
        meta: {
          title: 'quoteBuyGenericFlow.titleDefault.ZTRC'
        }
      },
      {
        path: 'quote-buy-maintenance',
        name: 'QuoteAndBuyMaintenance',
        component: () => import('../flows/quote-buy/quote-buy-flow.vue'),
        props: true,
        meta: {
          title: 'offerMaintenanceFlow.title'
        }
      },
      {
        path: 'quote-buy-inquiry',
        name: 'QuoteAndBuyInquiry',
        component: () => import('../flows/quote-buy/quote-buy-flow.vue'),
        props: true,
        meta: {
          title: 'offerListFlow.title'
        }
      },
      {
        path: 'home-orange-new',
        name: 'HomeOrangeNew',
        component: () => import('../flows/quote-buy/quote-buy-flow.vue'),
        props: true,
        meta: {
          title: 'quoteBuyGenericFlow.titleDefault.THHG'
        }
      },
      {
        path: 'home-db-new',
        name: 'HomeDBNew',
        component: () => import('../flows/quote-buy/quote-buy-flow.vue'),
        props: true,
        meta: {
          title: 'quoteBuyGenericFlow.titleDefault.THHD'
        }
      }
    ]
  }, {
    path: '/search-offer',
    name: 'SearchOffer',
    redirect: '',
    component: DefaultLayout,
    children: [
      {
        path: 'offer-list',
        name: 'OfferList',
        component: () => import('../flows/search-offer/search-offer-flow.vue'),
        props: {
          maintenanceMode: false
        },
        meta: {
          title: 'offerListFlow.title'
        }
      }
    ]
  }, {
    path: '/offer-maintenance-search',
    name: 'OfferMaintenanceSearch',
    redirect: '',
    component: DefaultLayout,
    children: [
      {
        path: 'offer-maintenance',
        name: 'OfferMaintenance',
        component: () => import('../flows/search-offer/search-offer-flow.vue'),
        props: {
          maintenanceMode: true
        },
        meta: {
          title: 'offerMaintenanceFlow.title'
        }
      }
    ]
  },
  {
    path: '/regularizations',
    name: 'Regularizations',
    component: DefaultLayout,
    children: [
      {
        path: 'regularizations-list',
        name: 'RegularizationsList',
        component: () => import('../flows/regularizations/regularizations-flow.vue'),
        meta: {
          title: 'regularizationFlow.title'
        }
      },
    ],
  }, {
    path: '/search-policy',
    name: 'SearchPolicy',
    redirect: '',
    component: DefaultLayout,
    children: [
      {
        path: 'policy-list',
        name: 'PolicyList',
        component: () => import('../flows/search-policy/search-policy-flow.vue'),
        props: true,
        meta: {
          title: 'searchPolicyFlow.title'
        }
      }
    ]
  }, {
    path: '/policy-replacement-search',
    name: 'Replacements',
    redirect: '',
    component: DefaultLayout,
    children: [
      {
        path: 'replacements-list',
        name: 'ReplacementsList',
        component: () => import('../flows/replacements/replacements-flow.vue'),
        props: {
          replacementMode: true
        },
        meta: {
          title: 'replacementsFlow.title'
        }
      }
    ]
  }, {
    path: '/policy',
    name: 'Policy',
    redirect: '',
    component: DefaultLayout,
    children: [
      {
        path: 'search',
        name: 'PrepolicySearch',
        component: () => import('../flows/search-prepolicy/search-prepolicy-flow.vue'),
        props: {
          maintenanceMode: true
        },
        meta: {
          title: 'policyMaintenanceFlow.title'
        }
      },
      {
        path: 'maintenance',
        name: 'PrepolicyMaintenance',
        component: () => import('../flows/policy/policy-flow.vue'),
        props: true,
        meta: {
          title: 'policyMaintenanceFlow.title'
        }
      },
      {
        path: 'inquiry',
        name: 'PolicyInquiry',
        component: () => import('../flows/policy/policy-flow.vue'),
        props: true,
        meta: {
          title: 'policyInquiryFlow.title'
        }
      },
      {
        path: 'supplement',
        name: 'PolicySupplement',
        component: () => import('../flows/policy/policy-flow.vue'),
        props: true,
        meta: {
          title: 'supplementsFlow.title'
        }
      },
      {
        path: 'replacement',
        name: 'PolicyReplacement',
        component: () => import('../flows/policy/policy-flow.vue'),
        props: true,
        meta: {
          title: 'replacementsFlow.title'
        }
      }
    ]
  }, {
    path: '/prepolicy-search',
    name: 'PrepolicySearch',
    redirect: '',
    component: DefaultLayout,
    children: [
      {
        path: 'maintenance',
        name: 'Maintenance',
        component: () => import('../flows/search-prepolicy/search-prepolicy-flow.vue'),
        props: {
          maintenanceMode: true
        },
        meta: {
          title: 'policyMaintenanceFlow.title'
        }
      }
    ]
  }, {
    path: '/policy-supplements-search',
    name: 'Supplements',
    redirect: '',
    component: DefaultLayout,
    children: [
      {
        path: 'supplements-list',
        name: 'SupplementsList',
        component: () => import('../flows/supplements/supplements-flow.vue'),
        props: {
          supplementMode: true
        },
        meta: {
          title: 'supplementsFlow.title'
        }
      }
    ]
  }, {
    path: '/documentation',
    name: 'DocumentationFlow',
    redirect: '',
    component: DefaultLayout,
    children: [
      {
        path: 'check-and-maintain',
        name: 'Documentation',
        component: () => import('../flows/documentation/documentation-flow.vue'),
        props: true,
        meta: {
          title: 'documentationFlow.title'
        }
      }
    ]
  }, {
    path: '/competences',
    name: 'CompetencesFlow',
    redirect: '',
    component: DefaultLayout,
    children: [
      {
        path: 'management',
        name: 'Competences',
        component: () => import('../flows/competences/competences-flow.vue'),
        props: true,
        meta: {
          title: 'competencesFlow.title'
        }
      }
    ]
  },
  {
    path: '/notes',
    name: 'NotesFlow',
    redirect: '',
    component: DefaultLayout,
    children: [
      {
        path: 'notes-maintenance',
        name: 'notesMaintenance',
        component: () => import('../flows/notes/notes-flow.vue'),
        props: true,
        meta: {
          title: 'notesFlow.title'
        }
      }
    ]
  },
  {
    path: '/fleets',
    name: 'Fleets',
    redirect: '',
    component: DefaultLayout,
    children: [
      {
        path: 'fleet-new',
        name: 'FleetNew',
        component: () => import('../flows/fleets/fleets-flow.vue'),
        meta: {
          title: 'fleets.tabs.newFleet'
        }
      }
    ]
  },
  {
    path: '/card-payment-policies',
    name: 'CardPaymentPolicies',
    redirect: '',
    component: DefaultLayout,
    children: [
      {
        path: 'management',
        name: 'CardPaymentPoliciesManagement',
        component: () => import('../flows/card-payment-policies-management/card-payment-policies-management-flow.vue'),
        meta: {
          title: 'cardPaymentPoliciesManagementFlow.tabs.title'
        }
      }
    ]
  },
  {
    path: '/intermediary-retention-certificates',
    name: 'IntermediaryRetentionCertificates',
    redirect: '',
    component: DefaultLayout,
    children: [
      {
        path: 'view',
        name: 'IntermediaryRetentionCertificatesView',
        // eslint-disable-next-line max-len
        component: () => import('../flows/intermediary-retention-certificates/intermediary-retention-certificates-flow.vue'),
        meta: {
          title: 'intermediaryRetentionCertificatesFlow.tabs.title'
        }
      }
    ]
  },
  {
    path: '/fleets-search-policy-consult',
    name: 'FleetsSearchPolicyConsult',
    redirect: '',
    component: DefaultLayout,
    children: [
      {
        path: 'fleets-consult',
        name: 'FleetsConsult',
        props: {
          consultMode: true
        },
        component: () => import('../flows/fleets-search-offer/fleets-search-offer-flow.vue'),
        meta: {
          title: 'fleets.tabs.fleetsConsult'
        }
      }
    ]
  },
  {
    path: '/fleets-search-policy',
    name: 'FleetsSearchPolicy',
    redirect: '',
    component: DefaultLayout,
    children: [
      {
        path: 'fleets-policy-list',
        name: 'FleetsPolicyList',
        props: {
          maintenanceMode: true
        },
        component: () => import('../flows/fleets-search-offer/fleets-search-offer-flow.vue'),
        meta: {
          title: 'fleets.tabs.fleetsMaintenancePolicy'
        }
      }
    ]
  },
  {
    path: '/send-insurance-document',
    name: 'InsuranceDocument',
    redirect: '',
    component: DefaultLayout,
    children: [
      {
        path: 'search',
        name: 'InsuranceDocumentView',
        props: {
          maintenanceMode: true
        },
        component: () => import('../flows/insurance-document/insurance-document-flow.vue'),
        meta: {
          title: 'insuranceDocumentFlow.title'
        }
      }
    ]
  },
  {
    path: '/clients',
    name: 'Clients',
    redirect: '',
    component: DefaultLayout,
    children: [
      {
        path: 'clients-management',
        name: 'ClientsManagement',
        component: () => import('../flows/clients-management/clients-management-flow.vue'),
        meta: {
          title: 'clientsManagementFlow.title'
        }
      }
    ]
  }
];

export const coexistenceRoute: EARouteConfig[] = [
  {
    path: '/zona-zurich',
    name: 'ZonaZurichBase',
    component: DefaultLayout,
    children: [
      {
        path: 'flow/generic',
        name: 'ZonaZurichGenericFlow',
        component: () => import('../coexistence/pivot/ea-pivot-flow.vue'),
        meta: {
          custom: {
            flow: '',
            currentFlow: '',
            name: 'Zona Zurich',
            status: ''
          }
        }
      },
      {
        path: 'flow/:operationId',
        name: 'ZonaZurich',
        component: () => import('../coexistence/pivot/ea-pivot-flow.vue'),
        props: true,
        meta: {
          custom: {
            flow: '',
            currentFlow: '',
            name: 'Zona Zurich',
            status: ''
          }
        }
      },
      {
        path: 'flow/:operationId/parentFlowId/:parentFlowId',
        name: 'ZonaZurichWithParentFlowId',
        component: () => import('../coexistence/pivot/ea-pivot-flow.vue'),
        props: true,
        meta: {
          custom: {
            flow: '',
            currentFlow: '',
            name: 'Zona Zurich',
            status: ''
          }
        }
      }
    ]
  },
];

/** 
 * Export route entries
 */
export const appRoutes: EARouteConfig[] = [
  ...securityRouter,
  ...securityPrivateRouter,
  ...errorRouter,
  ...homeRouter,
  ...appRouter,
  ...coexistenceRoute
];
