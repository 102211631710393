/* eslint-disable id-length */
import {
  GetOfferListByRiskRequestSearchByPlateNumberTipoMatriculaEnum as TipoMatriculaEnum
} from '@/services/V1/search-offer/getOfferListByRiskOperation/post';

import {
  eaCustomValidation,
  eaLengthValidation, eaRangeValidation, eaRequiredValidation, EAValidationTriggers, EAValidationTypes
} from '@zurich-es-npm/ea-front-web-ui';
import {
  PLateNumberVerify
} from './plate-number-validations.types';

const onlyLettersAndNumbersMask = /(?![A-Z0-9]).|_/gi;
const onlyLettersAndNumbersAndSpaceMask = /(?![A-Z0-9 ]).|_/gi;
const onlyLettersNumbersSpacesHyphensAndSlashes = /(?![A-Z0-9 /-]).|_/gi; // Hyphen: -, Slash: /

const ordinariaPattern = /^(\d{1,4}[BCDFGHJKLMNPRSTVWXYZ]{3}|([A-Z ]{1,2}\d{1,6}[ABCDEFGHIJKLMNOPRSTUVWXYZ ]{0,2}))$/i;
const ciclomotorPattern = /^C\d{4}[BCDFGHJKLMNPRSTVWXYZ]{3}$/i;
const especialPattern = /^[A-Z ]{1,2}\d{1,6}VE$/i;
const diplomaticoPattern = /^[A-Z]{2}\d{6}$/i;
const especialRdE1Pattern = /^E\d{4}[BCDFGHJKLMNPRSTVWXYZ]{3}$/i;
const historicoPattern = /^H\d{4}[BCDFGHJKLMNPRSTVWXYZ]{3}$/i;
const itvPattern = /^[A-Z]{1,2}ITV\d{4}$/i;
const otrasPattern = /^[0-9ABCDEFGHIJKLMNOPRSTVWXYZ]{1,12}$/i;
// eslint-disable-next-line max-len
const estadoPattern = /^(((A|AE)\s?(\d{1,6})\s?([A-Z]))|((A|AE)(\s?)(\d{1,6})([A-Z]{2}))|((ET|FN|MMA|EA|MF)(\s?)(\d{1,6}))|((PME)(\d{1,6})(\s?)([A-Z]))|((PMM|CNP|DGP)(\d{1,6})([A-Z]{2}))|((PGC|DGP|MOP)(\d{1,6})(\s?)([A-Z]))|((PGC|DGP|MOP)(\d{1,6})))$/i;
const estadoRdPattern = /^[A-Z]{1,3}VE\d{6}$/i;
const pruebasPattern = /^[A-Z]{1,2}\d{2}[P|T]\d{4}([1-2]\d{2})*$/i;
const remolquePattern = /^[A-Z ]{1,2}\d{1,6}R$/i;
const remolqueRdPattern = /^R\d{4}[BCDFGHJKLMNPRSTVWXYZ]{3}$/i;
const turisticaPattern = /^\d{2}[A-Z]{1,2}\d{4}(((0[1-9])||(1[012]))\d{2})*$/i;
const turisticaRdPattern = /^T\d{4}[BCDFGHJKLMNPRSTVWXYZ]{3}$/i;
const temporalPattern = /^[A-Z ]{1,2}\d{1,4}[TR]\d{1,2}$/i;
const autorizacionTemporalPattern = /^[P|V|S]\d{4}[BCDFGHJKLMNPRSTVWXYZ]{3}$/i;
const noMatriculaPattern = /^.{1,25}$/i;
export const plateNumberValidations: Record<TipoMatriculaEnum, PLateNumberVerify> = {
  // PLATE_CICLOMOTOR C: c9999bbb
  C: {

    /**
     * Validates a ORDINARIA (1) Plate Number in a eaCustomValidation() function.
     * @param {any} _rule
     * @param {string} value - NIF
     * @param {Function} callback - callback() is called if the NIF is valid, callback(new Error()) otherwise
     * @param {RegExp} reg
     * @returns {void}
     */
    myValidation: function(_rule: any, value: string, callback: Function) {
      
      
      return ciclomotorPattern.test(value) ? callback() : callback(new Error());
    },

    /**
     * @returns {EAIValidationRule[]}
     */
    validationsRules: function() {
      return [
        eaRequiredValidation('common.label.validation.fieldRequired'),
        eaLengthValidation(8, 'common.label.validation.invalidLength'),
        eaCustomValidation(this.myValidation, 'common.label.validation.incorrectFormat')
      ];
    },
    pattern: ciclomotorPattern,
    mask: /(?![BCDFGHJKLMNPRSTVWXYZ0-9]).|_/gi,
    tooltip: 'C2345BCD'
  },
  // PLATE_ESPECIAL E: a3ve / aa333333ve / a 333333ve /
  E: {

    /**
     * Validates a ORDINARIA (1) Plate Number in a eaCustomValidation() function.
     * @param {any} _rule
     * @param {string} value - NIF
     * @param {Function} callback - callback() is called if the NIF is valid, callback(new Error()) otherwise
     * @param {RegExp} reg
     * @returns {void}
     */
    myValidation: function(_rule: any, value: string, callback: Function) {
     
      
      return especialPattern.test(value) ? callback() : callback(new Error());
    },

    /**
     * @returns {EAIValidationRule[]}
     */
    validationsRules: function() {
      return [
        eaRequiredValidation('common.label.validation.fieldRequired'),
        eaRangeValidation(4, 10, 'common.label.validation.invalidLength'),
        eaCustomValidation(this.myValidation, 'common.label.validation.incorrectFormat')
      ];
    },
    pattern: especialPattern,
    mask: onlyLettersAndNumbersAndSpaceMask,
    tooltip: 'AL123456VE'
  },
  // PLATE_DIPLOMATICO D: aa999999 
  D: {

    /**
     * Validates a ORDINARIA (1) Plate Number in a eaCustomValidation() function.
     * @param {any} _rule
     * @param {string} value - NIF
     * @param {Function} callback - callback() is called if the NIF is valid, callback(new Error()) otherwise
     * @param {RegExp} reg
     * @returns {void}
     */
    myValidation: function(_rule: any, value: string, callback: Function) {
      
      
      return diplomaticoPattern.test(value) ? callback() : callback(new Error());
    },

    /**
     * @returns {EAIValidationRule[]}
     */
    validationsRules: function() {
      return [
        eaRequiredValidation('common.label.validation.fieldRequired'),
        eaLengthValidation(8, 'common.label.validation.invalidLength'),
        eaCustomValidation(this.myValidation, 'common.label.validation.incorrectFormat')
      ];
    },
    pattern: diplomaticoPattern,
    mask: onlyLettersAndNumbersMask,
    tooltip: 'CD012012'
  },
  // PLATE_ESPECIAL_RD E1: e0000sss
  E1: {

    /**
     * Validates a ORDINARIA (1) Plate Number in a eaCustomValidation() function.
     * @param {any} _rule
     * @param {string} value - NIF
     * @param {Function} callback - callback() is called if the NIF is valid, callback(new Error()) otherwise
     * @param {RegExp} reg
     * @returns {void}
     */
    myValidation: function(_rule: any, value: string, callback: Function) {
      
      
      return especialRdE1Pattern.test(value) ? callback() : callback(new Error());
    },

    /**
     * @returns {EAIValidationRule[]}
     */
    validationsRules: function() {
      return [
        eaRequiredValidation('common.label.validation.fieldRequired'),
        eaLengthValidation(8, 'common.label.validation.invalidLength'),
        eaCustomValidation(this.myValidation, 'common.label.validation.incorrectFormat')
      ];
    },
    pattern: especialRdE1Pattern,
    mask: /(?![0-9BCDEFGHJKLMNPRSTVWXYZ]).|_/gi,
    tooltip: 'E2345BCD'
  },
  // PLATE_HISTORICO H: h0000sss
  H: {

    /**
     * Validates a ORDINARIA (1) Plate Number in a eaCustomValidation() function.
     * @param {any} _rule
     * @param {string} value - NIF
     * @param {Function} callback - callback() is called if the NIF is valid, callback(new Error()) otherwise
     * @param {RegExp} reg
     * @returns {void}
     */
    myValidation: function(_rule: any, value: string, callback: Function) {
      
      
      return historicoPattern.test(value) ? callback() : callback(new Error());
    },
    
    /**
     * @returns {EAIValidationRule[]}
     */
    validationsRules: function() {
      return [
        eaRequiredValidation('common.label.validation.fieldRequired'),
        eaLengthValidation(8, 'common.label.validation.invalidLength'),
        eaCustomValidation(this.myValidation, 'common.label.validation.incorrectFormat')
      ];
    },
    pattern: historicoPattern,
    mask: /(?![0-9BCDFGHJKLMNPRSTVWXYZ]).|_/gi,
    tooltip: 'H2345BCD'
  },
  // PLATE_ITV I: aITV0000
  I: {

    /**
     * Validates a ORDINARIA (1) Plate Number in a eaCustomValidation() function.
     * @param {any} _rule
     * @param {string} value - NIF
     * @param {Function} callback - callback() is called if the NIF is valid, callback(new Error()) otherwise
     * @param {RegExp} reg
     * @returns {void}
     */
    myValidation: function(_rule: any, value: string, callback: Function) {
      
      
      return itvPattern.test(value) ? callback() : callback(new Error());
    },
    
    /**
     * @returns {EAIValidationRule[]}
     */
    validationsRules: function() {
      return [
        eaRequiredValidation('common.label.validation.fieldRequired'),
        eaRangeValidation(8, 9, 'common.label.validation.invalidLength'),
        eaCustomValidation(this.myValidation, 'common.label.validation.incorrectFormat')
      ];
    },
    pattern: itvPattern,
    mask: onlyLettersAndNumbersMask,
    tooltip: 'BUITV1234'
  },
  // PLATE_OTRAS K: h
  K: {

    /**
     * Validates a ORDINARIA (1) Plate Number in a eaCustomValidation() function.
     * @param {any} _rule
     * @param {string} value - NIF
     * @param {Function} callback - callback() is called if the NIF is valid, callback(new Error()) otherwise
     * @param {RegExp} reg
     * @returns {void}
     */
    myValidation: function(_rule: any, value: string, callback: Function) {
      
      
      return otrasPattern.test(value) ? callback() : callback(new Error());
    },

    /**
     * @returns {EAIValidationRule[]}
     */
    validationsRules: function() {
      return [
        eaRequiredValidation('common.label.validation.fieldRequired'),
        eaRangeValidation(1, 12, 'common.label.validation.invalidLength'),
        eaCustomValidation(this.myValidation, 'common.label.validation.incorrectFormat')
      ];
    },
    pattern: otrasPattern,
    mask: /(?![0-9ABCDEFGHIJKLMNOPRSTVWXYZ]).|_/gi,
    tooltip: '12NXT92'
  },
  // PLATE_ESTADO M: PGC123456
  M: {

    /**
     * Validates a ORDINARIA (1) Plate Number in a eaCustomValidation() function.
     * @param {any} _rule
     * @param {string} value - NIF
     * @param {Function} callback - callback() is called if the NIF is valid, callback(new Error()) otherwise
     * @param {RegExp} reg
     * @returns {void}
     */
    myValidation: function(_rule: any, value: string, callback: Function) {
   
      return estadoPattern.test(value) ? callback() : callback(new Error());
    },

    /**
     * @returns {EAIValidationRule[]}
     */
    validationsRules: function() {
      return [
        eaRequiredValidation('common.label.validation.fieldRequired'),
        eaRangeValidation(6, 12, 'common.label.validation.invalidLength'),
        eaCustomValidation(this.myValidation, 'common.label.validation.incorrectFormat')
      ];
    },
    pattern: estadoPattern,
    tooltip: 'PMM123456AB, PGC123456, MF 123456'
  },
  // PLATE_ESTADO_RD M1: XXXVE123456
  M1: {

    /**
     * Validates a ORDINARIA (1) Plate Number in a eaCustomValidation() function.
     * @param {any} _rule
     * @param {string} value - NIF
     * @param {Function} callback - callback() is called if the NIF is valid, callback(new Error()) otherwise
     * @param {RegExp} reg
     * @returns {void}
     */
    myValidation: function(_rule: any, value: string, callback: Function) {
      
      
      return estadoRdPattern.test(value) ? callback() : callback(new Error());
    },

    /**
     * @returns {EAIValidationRule[]}
     */
    validationsRules: function() {
      return [
        eaRequiredValidation('common.label.validation.fieldRequired'),
        eaRangeValidation(9, 11, 'common.label.validation.invalidLength'),
        eaCustomValidation(this.myValidation, 'common.label.validation.incorrectFormat')
      ];
    },
    pattern: estadoRdPattern,
    mask: onlyLettersAndNumbersMask,
    tooltip: 'PMMVE234567'
  },
  // PLATE_PRUEBAS P: SA28P1234199
  P: {

    /**
     * Validates a ORDINARIA (1) Plate Number in a eaCustomValidation() function.
     * @param {any} _rule
     * @param {string} value - NIF
     * @param {Function} callback - callback() is called if the NIF is valid, callback(new Error()) otherwise
     * @param {RegExp} reg
     * @returns {void}
     */
    myValidation: function(_rule: any, value: string, callback: Function) {
      
      
      return pruebasPattern.test(value) ? callback() : callback(new Error());
    },

    /**
     * @returns {EAIValidationRule[]}
     */
    validationsRules: function() {
      return [
        eaRequiredValidation('common.label.validation.fieldRequired'),
        eaCustomValidation(this.myValidation, 'common.label.validation.incorrectFormat')
      ];
    },
    pattern: pruebasPattern,
    mask: onlyLettersAndNumbersMask,
    tooltip: 'SA28P1234199, SA28T1234298'
  },
  // PLATE_REMOLQUE R: LU123456R
  R: {

    /**
     * Validates a ORDINARIA (1) Plate Number in a eaCustomValidation() function.
     * @param {any} _rule
     * @param {string} value - NIF
     * @param {Function} callback - callback() is called if the NIF is valid, callback(new Error()) otherwise
     * @param {RegExp} reg
     * @returns {void}
     */
    myValidation: function(_rule: any, value: string, callback: Function) {
      

      return remolquePattern.test(value) ? callback() : callback(new Error());
    },

    /**
     * @returns {EAIValidationRule[]}
     */
    validationsRules: function() {
      return [
        eaRequiredValidation('common.label.validation.fieldRequired'),
        eaRangeValidation(3, 9, 'common.label.validation.invalidLength'),
        eaCustomValidation(this.myValidation, 'common.label.validation.incorrectFormat')
      ];
    },
    pattern: remolquePattern,
    mask: onlyLettersAndNumbersAndSpaceMask,
    tooltip: 'TO123456R'
  },
  // PLATE_REMOLQUE_RD R1: R1234XXX
  R1: {

    /**
     * Validates a ORDINARIA (1) Plate Number in a eaCustomValidation() function.
     * @param {any} _rule
     * @param {string} value - NIF
     * @param {Function} callback - callback() is called if the NIF is valid, callback(new Error()) otherwise
     * @param {RegExp} reg
     * @returns {void}
     */
    myValidation: function(_rule: any, value: string, callback: Function) {
      

      return remolqueRdPattern.test(value) ? callback() : callback(new Error());
    },

    /**
     * @returns {EAIValidationRule[]}
     */
    validationsRules: function() {
      return [
        eaRequiredValidation('common.label.validation.fieldRequired'),
        eaLengthValidation(8, 'common.label.validation.invalidLength'),
        eaCustomValidation(this.myValidation, 'common.label.validation.incorrectFormat')
      ];
    },
    pattern: remolqueRdPattern,
    mask: /(?![0-9BCDFGHJKLMNPRSTVWXYZ]).|_/gi,
    tooltip: 'R2345BCD'
  },
  // PLATE_TURISTICA T: 99XX12345678
  T: {

    /**
     * Validates a ORDINARIA (1) Plate Number in a eaCustomValidation() function.
     * @param {any} _rule
     * @param {string} value - NIF
     * @param {Function} callback - callback() is called if the NIF is valid, callback(new Error()) otherwise
     * @param {RegExp} reg
     * @returns {void}
     */
    myValidation: function(_rule: any, value: string, callback: Function) {
      

      return turisticaPattern.test(value) ? callback() : callback(new Error());
    },

    /**
     * @returns {EAIValidationRule[]}
     */
    validationsRules: function() {
      return [
        eaRequiredValidation('common.label.validation.fieldRequired'),
        eaRangeValidation(11, 12, 'common.label.validation.invalidLength'),
        eaCustomValidation(this.myValidation, 'common.label.validation.incorrectFormat')
      ];
    },
    pattern: turisticaPattern,
    mask: onlyLettersAndNumbersAndSpaceMask,
    tooltip: '12GI12341193'
  },
  // PLATE_TURISTICA_RD T1: T1234XXX
  T1: {

    /**
     * Validates a ORDINARIA (1) Plate Number in a eaCustomValidation() function.
     * @param {any} _rule
     * @param {string} value - NIF
     * @param {Function} callback - callback() is called if the NIF is valid, callback(new Error()) otherwise
     * @param {RegExp} reg
     * @returns {void}
     */
    myValidation: function(_rule: any, value: string, callback: Function) {
      

      return turisticaRdPattern.test(value) ? callback() : callback(new Error());
    },

    /**
     * @returns {EAIValidationRule[]}
     */
    validationsRules: function() {
      return [
        eaRequiredValidation('common.label.validation.fieldRequired'),
        eaLengthValidation(8, 'common.label.validation.invalidLength'),
        eaCustomValidation(this.myValidation, 'common.label.validation.incorrectFormat')
      ];
    },
    pattern: turisticaRdPattern,
    mask: /(?![0-9BCDFGHJKLMNPRSTVWXYZ]).|_/gi,
    tooltip: 'T1234BCD'
  },
  // PLATE_TEMPORAL TE: PO1234T13
  TE: {

    /**
     * Validates a ORDINARIA (1) Plate Number in a eaCustomValidation() function.
     * @param {any} _rule
     * @param {string} value - NIF
     * @param {Function} callback - callback() is called if the NIF is valid, callback(new Error()) otherwise
     * @param {RegExp} reg
     * @returns {void}
     */
    myValidation: function(_rule: any, value: string, callback: Function) {
      

      return temporalPattern.test(value) ? callback() : callback(new Error());
    },

    /**
     * @returns {EAIValidationRule[]}
     */
    validationsRules: function() {
      return [
        eaRequiredValidation('common.label.validation.fieldRequired'),
        eaRangeValidation(4, 9, 'common.label.validation.invalidLength'),
        eaCustomValidation(this.myValidation, 'common.label.validation.incorrectFormat')
      ];
    },
    pattern: temporalPattern,
    mask: onlyLettersAndNumbersAndSpaceMask,
    tooltip: 'SA1234T12, M1234R12'
  },
  // PLATE_AUTORIZACION_TEMPORAL TT: PO1234T13
  TT: {

    /**
     * Validates a ORDINARIA (1) Plate Number in a eaCustomValidation() function.
     * @param {any} _rule
     * @param {string} value - NIF
     * @param {Function} callback - callback() is called if the NIF is valid, callback(new Error()) otherwise
     * @param {RegExp} reg
     * @returns {void}
     */
    myValidation: function(_rule: any, value: string, callback: Function) {
      

      return autorizacionTemporalPattern.test(value) ? callback() : callback(new Error());
    },

    /**
     * @returns {EAIValidationRule[]}
     */
    validationsRules: function() {
      return [
        eaRequiredValidation('common.label.validation.fieldRequired'),
        eaLengthValidation(8, 'common.label.validation.invalidLength'),
        eaCustomValidation(this.myValidation, 'common.label.validation.incorrectFormat')
      ];
    },
    pattern: autorizacionTemporalPattern,
    mask: onlyLettersAndNumbersAndSpaceMask,
    tooltip: 'P2345BCD'
  },
  // PLATE_ORDINARIA 1: B1234MT 
  1: {

    /**
     * Validates a ORDINARIA (1) Plate Number in a eaCustomValidation() function.
     * @param {any} _rule
     * @param {string} value - NIF
     * @param {Function} callback - callback() is called if the NIF is valid, callback(new Error()) otherwise
     * @param {RegExp} reg
     * @returns {void}
     */
    myValidation: function(_rule: any, value: string, callback: Function) {
      let correctPlateNumber: boolean = ordinariaPattern.test(value);
      const letterRegExp = /^[A-Z]$/i;
      // If there 
      if (correctPlateNumber && letterRegExp.test(value.charAt(value.length-1))) {
        //Take all numbers in platenumber
        const numbers = parseInt(value.replace(/\D/g, ''));

        correctPlateNumber = numbers.toString().length < 5;
      }
      
  
      return correctPlateNumber ? callback() : callback(new Error());
    },

    /**
     * @returns {EAIValidationRule[]}
     */
    validationsRules: function() {
      return [
        eaRequiredValidation('common.label.validation.fieldRequired'),
        eaRangeValidation(2, 10, 'common.label.validation.invalidLength'),
        eaCustomValidation(this.myValidation, 'common.label.validation.incorrectFormat')
      ];
    },
    pattern: ordinariaPattern,
    mask: onlyLettersAndNumbersAndSpaceMask,
    tooltip: '1234BCD, AV1234RF, B123456'
  },
  //Sin necesidad de informar matricula
  'NO-MATRICULA': {

    /**
     * Validates a NO-MATRICULA (NO-MATRICULA) Plate Number in a eaCustomValidation() function.
     * @param {any} _rule
     * @param {string} value - PlateNumber
     * @param {Function} callback - callback() is called if the PlateNumber is valid, callback(new Error()) otherwise
     * @param {RegExp} reg
     * @returns {void}
     */
    myValidation: function(_rule: any, value: string, callback: Function) {
      return noMatriculaPattern.test(value) ? callback() : callback(new Error());
    },

    /**
     * @returns {EAIValidationRule[]}
     */
    validationsRules: function() {
      return [
        eaRequiredValidation('common.label.validation.fieldRequired',
          EAValidationTriggers.BLUR, EAValidationTypes.STRING),
        eaRangeValidation(1, 25, 'common.label.validation.invalidLength')];
    },
    pattern: noMatriculaPattern,
    tooltip: 'Sin matrícula',
    mask: onlyLettersNumbersSpacesHyphensAndSlashes
  },
};
