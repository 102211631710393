<template><!-- Loader to multioperation tab when quoting (batch online) -->
  <div
    :id="loadingContainerId"
    v-loading="loading"
    :element-loading-text="$t('fleets.fleetsFlow.inputVehiclesData.loadingBaseSiete.loadingText')"
  >
    <div>
      <div>
        <fleet-unified-expiration
          ref="refFleetUnifiedExpirationBC"
          id="unified-expiration"
          v-model="model.fleetUnifiedExpirationModel"
          @unifedExpirationChange="unifedExpirationChange"
          @updateExpirationDate="updateFleetDates"
          :disabledBase7Mode="disabledBase7Mode"
          :showingInputVehiclesData="showInputVehiclesData"
          :consultFlow="consultFlow">
        </fleet-unified-expiration>

        <fleet-input-vehicles-data
          v-if="showInputVehiclesData"
          id="input-vehicles"
          ref="fleetInputVehiclesDataRef"
          class="m-t-24"
          v-model="model.fleetInputVehiclesDataModel"
          :fleetUnifiedExpirationModel="model.fleetUnifiedExpirationModel"
          :allowedPlateTypes="allowedPlateTypes"
          :noPlateOption="noPlateOption"
          :vehicleRestrictions="model.flowConfiguration.vehiclesDataView.unifiedDateRestriction.vehiclesRestrictions"
          :fleetCode="model.offerNumber"
          :fleetVersion="model.offerVersion"
          :vehiclesNumberRange="vehiclesNumberRange"
          :allVehicleUses="allVehicleUses"
          :consultFlow="consultFlow"
          :pamVehicleTypes="pamVehicleTypes"
          @disabledBase7Mode="onDisabledBase7Mode"
          @continueButtonDisabled="continueButtonDisabled = $event;">
        </fleet-input-vehicles-data>
     
      </div>
      <ea-row extraClass="m-t-24">
        <ea-col class="d-display-flex d-align-items-center d-justify-space-between">
          <ea-button
            type="secondary"
            :disabled="disabledBase7Mode"
            @click="onGoBack()"
          >
            {{ $t('common.label.back') }}
          </ea-button>
          <ea-button
            v-if="showInputVehiclesData"
            id="nextStep"
            type="primary"
            :disabled="continueButtonDisabled"
            @click="nextStep()"
            >
            {{ $t('common.label.next') }}
          </ea-button>
          <ea-button
            v-else
            id="updateFleetDates"
            type="primary"
            @click="updateFleetDates()"
          >
            {{ $t('common.label.next') }}
          </ea-button>
        </ea-col>

      </ea-row>
    </div>
  </div>
</template>

<script lang="ts">
import {
  Component, Watch
} from 'vue-property-decorator';

import {
  mixins
} from 'vue-class-component';

import {
  EAView,
  EAMethod,
  throwIfResponseHasErrors,
  EAErrorManager,
  EAApplicationError,
  EAApplicationLogger,
  EAError,
  ResponseWithErrors
} from '@zurich-es-npm/ea-front-web-core';

import {
  FleetsModel,
  FlowHeaderStepsModel,
  FlowViewsStepsModel,
  OperationTypeEnum,
} from '../fleets-model';
import FleetUnifiedExpirationBusiness
  from '@/business-components/fleet-unified-expiration/fleet-unified-expiration-business.vue';
import {
  EACompositeValidation
} from '@zurich-es-npm/ea-front-web-ui';
import {
  RangeRestriction
} from '../model/range-restriction-model';
import
FleetInputVehiclesDataBusiness
  from '@/business-components/fleet-input-vehicles-data/fleet-input-vehicles-data-business.vue';
import {
  SelectItemType
} from '../model/select-Item-type-model';
import {
  EASaveInfoDateApi, SaveInfoDateRequest
} from '@/services/V1/fleets/saveInfoDateOperation/post/api';
import moment from 'moment';
import {
  NotificationsUtils,
} from '@/utils/notifications/notifications-utils';
import {
  EAGetVehicleListFleetApi, GetVehicleListFleetResponse
} from '@/services/V1/fleets/getVehicleListFleetOperation/post';
import {
  corporativeTableSortAlphabetically, CorpTableNames, fetchCorporateTable
} from '@/utils/corporate-tables';
import Utils from '@/utils/utils';
import {
  VehicleFormModel,
  VehicleUseData
} from '@/business-components/fleet-manual-upload-vehicle/fleet-manual-upload-vehicle-model';
import {
  VehicleGroupValidations
} from '@/business-components/fleet-group-upload-vehicles/fleet-group-upload-vehicles-model';
import {
  GetOfferListByRiskRequestSearchByPlateNumberTipoMatriculaEnum as TipoMatriculaEnum
} from '@/services/V1/search-offer/getOfferListByRiskOperation/post';
import {
  cloneDeep
} from 'lodash';

@Component({
  components: {
    'fleet-unified-expiration': FleetUnifiedExpirationBusiness,
    'fleet-input-vehicles-data': FleetInputVehiclesDataBusiness,
  },
})

/**
 * VehiclesData view
 *
 */
export default class VehiclesDataView extends mixins<EAView<FleetsModel>>(EAView) {

  showInputVehiclesData: boolean = false;

  disabledBase7Mode: boolean = false;

  continueButtonDisabled: boolean = true;

  readonlyDate: boolean = false;

  notifyDatesUpdate: boolean = false;

  public allVehicleUses: VehicleUseData[] = [];

  loading: boolean = false;

  loadingContainerId = 'loading-container-fleets-vehicles-view';

  dateValidationsChanged: boolean = false;

  pamVehicleTypes = ['300', '350', '400', '470', '500', '900', '910'];

  /**
   * Getter for unifiedExpirationConfigDateRange
   */
  get unifiedExpirationConfigDateRange(): RangeRestriction {
    return this.model.flowConfiguration.vehiclesDataView?.unifiedDateRestriction.datesRange || {};
  }

  /**
   * Getter for notUnifiedExpirationConfigDateRange
   */
  get notUnifiedExpirationConfigDateRange(): RangeRestriction {
    return this.model.flowConfiguration.vehiclesDataView?.notUnifiedDateRestriction.datesRange || {};
  }

  /**
   * Getter for allowedPlateTypes
   */
  get allowedPlateTypes(): string[] {
    return this.model.flowConfiguration.vehiclesDataView?.allowedPlateTypes || [];
  }

  /**
   * Getter for noPlateOption
   */
  get noPlateOption(): SelectItemType {
    return this.model.flowConfiguration.vehiclesDataView?.noPlateOption || [];
  }


  /**
   * Getter for vehiclesNumberRange
   */
  get vehiclesNumberRange(): RangeRestriction {
    return this.model.flowConfiguration.generalDataView?.fleetGeneralInfoRestrictions?.vehiclesNumber.range;
  }

  /**
   * Getter for maintenance flow
   */
  get maintenanceFlow() {
    return this.model.operationType === OperationTypeEnum.MANTENIMIENTO;
  }

  /**
   * Getter for consult flow
   */
  get consultFlow() {
    return this.model.operationType === OperationTypeEnum.CONSULTA;
  }

  /**
   * Watches `loading` changes and scrolls to the center of the page if loader is active.
   * @param {boolean} newValue 
   */
  @Watch('loading')
  async onLoadingStatusChange(newValue: boolean) {
    if (newValue) {
      await Utils.scrollToCenterOfElement(`#${this.loadingContainerId}`);
    }
  }

  /**
   * Hook on created.
   * Get the vehicles list in case of maintenance
   * @returns {Promise<void>}
   */
  @EAMethod({
    loading: true,
  })
  async created(): Promise<void> {
    await Promise.all([this.fetchPlateNumberListCorpTable(), this.fetchVehicleUseCorporateTable()]);

    if (this.maintenanceFlow || this.consultFlow) {
      this.notifyDatesUpdate = true;
      await this.getVehiclesListBff();
      this.changeDateRangeValidationIfNeeded();
    }
  }

  /**
   * Change date range validation if its needed
   */
  changeDateRangeValidationIfNeeded(): void {
    //If its on maintenance fleet offer save actual date
    if (this.maintenanceFlow) {
      const unifiedExpiration: boolean =
        this.model.fleetUnifiedExpirationModel.formValues.hasUnifiedExpiration as unknown as boolean;
      let fleetDate;
      const actualDate = new Date();
      actualDate.setHours(0, 0, 0, 0);
      const label = `fleets.fleetsFlow.fleet-vehicle-data.unifiedExpirationDate.maintenanceRangeValidation`;
      if (unifiedExpiration) {
        fleetDate = this.model.fleetUnifiedExpirationModel.formValues.unifiedExpirationDate as unknown as Date;
      } else {
        fleetDate = this.model.fleetUnifiedExpirationModel.formValues.notUnifiedExpirationDate as unknown as Date;
      }
      fleetDate?.setHours(0, 0, 0, 0);
      if (fleetDate && actualDate.getTime() > fleetDate.getTime()) {
        this.dateValidationsChanged = true;
        this.model.flowConfiguration.vehiclesDataView.unifiedDateRestriction.datesRange.min = 0;
        this.model.flowConfiguration.vehiclesDataView.notUnifiedDateRestriction.datesRange.min = 0;
        this.showInputVehiclesData = false;
        const dateValidationWarningMessage = this.$t(label, {
          max: this.unifiedExpirationConfigDateRange.max,
        }).toString();
        NotificationsUtils.throwWarning(dateValidationWarningMessage);
        this.update();
      }
    }
  }

  /**
   * Gets the plate number (matrículas) types for a <select> element.
   */
  @EAMethod({
    loading: true,
  })
  public async fetchPlateNumberListCorpTable(): Promise<void> {
    // Avoid several same call / fetch
    if (this.model.fleetInputVehiclesDataModel.manualUploadVehicleModel.plateTypeSearchList.length > 0) {
      return;
    }
    // Fetch
    try {
      const corpTableProductResult = await fetchCorporateTable(CorpTableNames.PlateNumberSearch);
      if (corpTableProductResult.data) {
        corporativeTableSortAlphabetically(corpTableProductResult.data.tableDocuments, 'DSELEMEN');
        this.model.fleetInputVehiclesDataModel.manualUploadVehicleModel.plateTypeSearchList =
          corpTableProductResult.data.tableDocuments
            .map((document, index) => {
              return {
                id: `${document.CDELEMEN}-${index + 1}`,
                code: document.CDELEMEN,
                name: document.DSELEMEN,
              };
            })
            .filter(plateType => this.allowedPlateTypes.find(allowedPlateType => allowedPlateType === plateType.code)
            );
        this.model.fleetInputVehiclesDataModel.manualUploadVehicleModel.plateTypeSearchList.unshift(this.noPlateOption);
        // Cargamos la informacion en el modelo de carga grupal también.
        this.model.fleetInputVehiclesDataModel.groupUploadVehiclesModel.plateTypeSearchList =
           this.model.fleetInputVehiclesDataModel.manualUploadVehicleModel.plateTypeSearchList;
        this.model.fleetInputVehiclesDataModel.fleetsEditVehicleModel.plateTypeSearchList =
           this.model.fleetInputVehiclesDataModel.manualUploadVehicleModel.plateTypeSearchList;
      } else {
        throw new EAApplicationError('ZON00087');
      }
    } catch (error) {
      const eaError = error as EAError;
      new EAApplicationLogger().error(
        // eslint-disable-next-line max-len
        `FleetInputVehiclesDataBusiness::fetchPlateNumberListCorpTable:: fetch corporate table KCIT71G :: ${eaError.message}`
      );
      throw error;
    }
  }

  /**
   * Fetch corp table data for vehicle uses and parses documents.
   */
  @EAMethod({
    loading: true,
  })
  public async fetchVehicleUseCorporateTable() {
    const corpTableData = await fetchCorporateTable(CorpTableNames.VehicleUse);
    // Map documents with only relevant information
    const documentsParsed: VehicleUseData[] = corpTableData.data.tableDocuments.map(doc => {
      return {
        name: doc.DSELEM.trim(),
        value: doc.CDUSOMAT.trim(),
        plateType: doc.TIMATVCO.trim() === '' ? TipoMatriculaEnum.NOMATRICULA : doc.TIMATVCO.trim(),
      };
    });
    Utils.sortObjectArrayByProperty(documentsParsed, 'name');
    this.allVehicleUses = documentsParsed;
  }

  /**
   * Go to the next step
   */
  @EAMethod({
    loading: true
  })
  async nextStep() {
    // Reset warning messages
    NotificationsUtils.clearNotifications();
    EAErrorManager.clearError();
    
    this.$emit('changeStep',
      FlowViewsStepsModel.QuoteDataStep,
      FlowHeaderStepsModel.QuoteDataStep
    );
  }

  
  /**
   * Update fleet dates to continue with vehicle data
   */
  @EAMethod({
    loading: true
  })
  async updateFleetDates() {
    // Limpiar posible errores mostrados anteriormente
    NotificationsUtils.clearNotifications();
    EAErrorManager.clearError();

    try {
      await this.validateUnifiedExpirationValuesAndUpdateModel();
    } catch (error) {
      NotificationsUtils.throwWarning(error as string);
      return;
    }

    if (this.comprobeLogicalBusinessUnifiedExpiration()) {
      const {
        hasUnifiedExpiration, unifiedExpirationDate, notUnifiedExpirationDate
      } = this.model.fleetUnifiedExpirationModel.formValues;
      let fechaEfectoAnual;
      let fechaVencimiento;
      if (hasUnifiedExpiration) {
        fechaVencimiento = moment(unifiedExpirationDate as Date).format('YYYY-MM-DD');
      } else {
        fechaEfectoAnual = moment(notUnifiedExpirationDate as Date).format('YYYY-MM-DD');
      }
      const saveInfoDateRequest: SaveInfoDateRequest = {
        vencimientoUnificado: hasUnifiedExpiration as boolean,
        codigoFlota: this.model.offerNumber,
        versionFlota: this.model.offerVersion,
        fechaEfectoAnual,
        fechaVencimiento
      };

      const api = new EASaveInfoDateApi();
      const saveInfoDateResponse = await api.saveInfoDateOperation({
        saveInfoDateRequest
      });
      const severityErrors = saveInfoDateResponse?.errors?.filter(error => error.severity > 3);
      throwIfResponseHasErrors({
        errors: severityErrors
      });
      this.model.offerNumber = saveInfoDateResponse?.codigoFlota as string;
      this.model.offerVersion = saveInfoDateResponse?.versionFlota as number;
      this.showInputVehiclesData = true;
      if (this.notifyDatesUpdate) {
        NotificationsUtils.throwSuccess(
          this.$t(`fleets.fleetsFlow.inputVehiclesData.updateDateSuccess`).toString());
      }
      this.notifyDatesUpdate = true;
      await this.getVehiclesListBff();
      this.update();
    }
  }

  /**
   *
   */
  unifedExpirationChange() {
    this.showInputVehiclesData = false;
  }

  /**
   *
   */
  async validateUnifiedExpirationValuesAndUpdateModel() {
    
    // Primer bloque: "Vencimiento unificado de vehículos"
    const fleetUnifiedExpirationBusinessBC: FleetUnifiedExpirationBusiness =
        this.$refs.refFleetUnifiedExpirationBC as FleetUnifiedExpirationBusiness;
    // Validations
    const validationForms =
      new EACompositeValidation([fleetUnifiedExpirationBusinessBC.validation()], this.$tc.bind(this));
    
    await validationForms.validate();
    fleetUnifiedExpirationBusinessBC.update();
    this.update();
     
  }

  /**
   * @returns {boolean}
   */
  comprobeLogicalBusinessUnifiedExpiration(): boolean {
    let canContinue = true;
    let dateToCheck;
     
    const unifiedExpiration: boolean =
     this.model.fleetUnifiedExpirationModel.formValues.hasUnifiedExpiration as unknown as boolean;

    if (unifiedExpiration) {
      dateToCheck = this.model.fleetUnifiedExpirationModel.formValues.unifiedExpirationDate as unknown as Date;
      const validationMessage =
        this.dateValidationsChanged ?
          `fleets.fleetsFlow.fleet-vehicle-data.unifiedExpirationDate.maintenanceRangeValidation`
          :`fleets.fleetsFlow.fleet-vehicle-data.unifiedExpirationDate.rangeValidation`;
      canContinue = this._validateDate(dateToCheck, this.unifiedExpirationConfigDateRange, validationMessage);
    } else {
      dateToCheck = this.model.fleetUnifiedExpirationModel.formValues.notUnifiedExpirationDate as unknown as Date;
      const validationMessage =
        this.dateValidationsChanged ?
          `fleets.fleetsFlow.fleet-vehicle-data.notUnifiedExpirationDate.maintenanceRangeValidation`
          :`fleets.fleetsFlow.fleet-vehicle-data.notUnifiedExpirationDate.rangeValidation`;
      canContinue = this._validateDate(dateToCheck, this.notUnifiedExpirationConfigDateRange, validationMessage);
    }
    return canContinue;
  }

  /**
   * 
   * @param {Date} date
   * @param {RangeRestriction} rangeRestriction
   * @param {string} label
   * @return {boolean}
   */
  _validateDate(date: Date, rangeRestriction: RangeRestriction, label: string): boolean {
    let canContinue = true;
    const actualDate = new Date();
    const minDate = new Date(actualDate.getTime());
    minDate.setDate(minDate.getDate() + rangeRestriction.min);
    const maxDate = new Date(actualDate.getTime());
    maxDate.setDate(maxDate.getDate() + rangeRestriction.max);
    //Reseteamos las horas para que la comparación sea estrictamente la fecha
    minDate.setHours(0, 0, 0, 0);
    maxDate.setHours(0, 0, 0, 0);
    date.setHours(0, 0, 0, 0);
    if (date.getTime() > maxDate.getTime() || date.getTime() < minDate.getTime()) {
      canContinue = false;
      
      const dateValidationWarningMessage = this.$t(label, {
        min: rangeRestriction.min,
        max: rangeRestriction.max,
      }).toString();
      NotificationsUtils.throwWarning(dateValidationWarningMessage);
    }
    return canContinue;
  }

  /**
   * Handler for disable component caused by BaseSiete call.
   * @param {boolean} disabled
   */
  onDisabledBase7Mode(disabled: boolean) {
    this.disabledBase7Mode = disabled;
    this.changeLoaderVisibility(disabled);
  }

  /**
   * Get vehicles list of fleet
   */
  @EAMethod({
    loading: true,
  })
  async getVehiclesListBff() {
    const api = new EAGetVehicleListFleetApi();
    const output = await api.getVehicleListFleetOperation({
      getVehicleListFleetRequest: {
        codigoFlota: this.model.offerNumber,
        versionFlota:  this.model.offerVersion,
      }
    });

    if (output) {
      throwIfResponseHasErrors(output as ResponseWithErrors);
      const vehiclesList = this.getVehiclesFormat(output);
      this.readonlyDate = !!output.flota?.fechaInicioPoliza;

      const hasUnifiedExpiration = output.flota?.vencimientoUnificado;

      // UnifiedExpirationDate is one day before fechaInicioPoliza
      const unifiedExpirationDate = hasUnifiedExpiration === true && output.flota?.fechaInicioPoliza
        ? new Date(output.flota.fechaInicioPoliza)
        : null;
      unifiedExpirationDate?.setDate(unifiedExpirationDate.getDate() - 1);

      const notUnifiedExpirationDate = hasUnifiedExpiration === false && output.flota?.fechaInicioPoliza
        ? new Date(output.flota?.fechaInicioPoliza)
        : null;
      notUnifiedExpirationDate?.setHours(0, 0, 0, 0); // Fixes hour not being 00:00:00:00, caused by timezone

      const expirationDateVehicleValidations =
       this.validateVehicles(vehiclesList, hasUnifiedExpiration as boolean, notUnifiedExpirationDate as Date);
      this.notifyVehicleValidations(expirationDateVehicleValidations);
      this.model = {
        ...this.model,
        fleetInputVehiclesDataModel: {
          ...this.model.fleetInputVehiclesDataModel,
          vehicles: vehiclesList
        },
        fleetUnifiedExpirationModel: {
          ...this.model.fleetUnifiedExpirationModel,
          formValues: {
            ...this.model.fleetUnifiedExpirationModel.formValues,

            hasUnifiedExpiration,

            unifiedExpirationDate,
            notUnifiedExpirationDate
          }
        },
        fleetQuotePaymentFormModel: {
          ...this.model.fleetQuotePaymentFormModel,
          paymentForm: output.flota?.formaPago || '',
        }
      };
      this.$nextTick(() => {
        const refFleetInputVehiclesData = this.$refs.fleetInputVehiclesDataRef as FleetInputVehiclesDataBusiness;
        if (refFleetInputVehiclesData) {
          refFleetInputVehiclesData.showErrorVehicles();
        }
      });
       
      this.update();

      if (output.flota?.fechaInicioPoliza) {
        this.showInputVehiclesData = true;
      }
    }
  }

  /**
   * Validates a vehicle plate and effective data and mark it as invalid or not
   * @param {VehicleFormModel []} vehicles 
   * @param {boolean} hasUnifiedExpiration 
   * @param {Date} notUnifiedExpirationDate 
   * @returns {VehicleGroupValidations}
   */
  validateVehicles(vehicles: VehicleFormModel [], hasUnifiedExpiration: boolean,
    notUnifiedExpirationDate: Date): VehicleGroupValidations [] {
    const vehiclesValidations: VehicleGroupValidations [] = [];
    vehicles.forEach(vehicle => {
      const isEffectiveDateValid = this.validateEffectiveDate(vehicle, hasUnifiedExpiration, notUnifiedExpirationDate);
      vehiclesValidations.push({
        isEffectiveDateValid
      });
      vehicle.invalidDate = !isEffectiveDateValid;
    });

    return vehiclesValidations;
  }


  /**
   * Returns if the effective date is valid based on the effective date of the fleet
   * if it is not unified
   * @returns {boolean}
   * @param {VehicleFormModel} vehicle 
   * @param {boolean} hasUnifiedExpiration 
   * @param {Date} notUnifiedExpirationDate 
   */
  validateEffectiveDate(vehicle: VehicleFormModel,
    hasUnifiedExpiration: boolean, notUnifiedExpirationDate: Date): boolean {
    
    let isEffectiveDateValid = true;
    const effectiveVehicleDate = vehicle.effectiveDate as Date;
    if (!hasUnifiedExpiration) {
      const endDate = cloneDeep(notUnifiedExpirationDate);
      endDate.setFullYear(endDate.getFullYear() + 1);
      endDate.setDate(endDate.getDate()-1);
      const expirationDate = notUnifiedExpirationDate;
      isEffectiveDateValid = effectiveVehicleDate >= expirationDate && effectiveVehicleDate < endDate;
    }
    return isEffectiveDateValid;
  }

  
  /**
   * Send warning messages for invalid vehicles
   * @param {VehicleGroupValidations []} vehicleValidations 
   */
  notifyVehicleValidations(vehicleValidations: VehicleGroupValidations []) {
    const effectiveDateWarning = vehicleValidations.some(vehicleValidation => !vehicleValidation.isEffectiveDateValid);
    if (effectiveDateWarning) {
      const message =
      this.$t(`fleets.fleetsFlow.inputVehiclesData.warningValidations.effectiveDateWarning`)
        .toString();
      NotificationsUtils.throwWarning(message);
    }
  }


  /**
   * Get vehicles format
   * @param {GetVehicleListFleetResponse} output
   * @returns {VehicleFormModel[]}
   */
  getVehiclesFormat(output: GetVehicleListFleetResponse): VehicleFormModel[] {
    const data = output.lista?.map(vehicle => {
      const plateTypeVehicle = vehicle.tipoMatricula ? vehicle.tipoMatricula : TipoMatriculaEnum.NOMATRICULA;
      const plateTypeName = this.model.fleetInputVehiclesDataModel.manualUploadVehicleModel.plateTypeSearchList
        .find(plateType => plateType.code === plateTypeVehicle)?.name;

      const vehicleUseName = this.allVehicleUses.find(
        code => code.value === vehicle.codigoUsoPorMatricula && code.plateType === vehicle.tipoMatricula
      )?.name;
   
      return {
        offerNumber: vehicle.codigoPoliza,
        offerVersion: vehicle.versionPoliza,
        plateNumberType: vehicle.tipoMatricula,
        plateNumber: vehicle.numeroMatriculaSinValidaciones,
        isBaseSieteData: !!vehicle.descripcionMarcaVehiculo,
        plateTypeName,
        effectiveDate: vehicle.fechaEfectoMovimiento ? new Date(vehicle.fechaEfectoMovimiento) : undefined,
        accessoriesValue: vehicle.valorAccesoriosTotal,
        vehicleBrand: vehicle.descripcionMarcaVehiculo,
        vehicleModel: vehicle.descripcionModeloVehiculo,
        vehicleVersion: vehicle.descripcionVersionVehiculo,
        vehicleUseName,
        plateUseId: vehicle.codigoUsoPorMatricula,
        policyNumber: vehicle.codigoNumeroPoliza,
        vehicleValue: vehicle.valorVehiculo,
        vehicleMaxWeight: vehicle.valorPesoMaximo,
        vehicleType: vehicle.tipoVehiculo,
        invalidValues: !vehicle.valorVehiculo ||
              (this.pamVehicleTypes.includes(vehicle.tipoVehiculo as string) && !vehicle.valorPesoMaximo)
      };
    });

    return data || [];
  }

  /**
   * 
   * @param {boolean} isVisible 
   */
  changeLoaderVisibility(isVisible: boolean) {
    this.loading = isVisible;
  }

  /**
   * Goes to previous step
   */
  onGoBack() {
    NotificationsUtils.clearNotifications();
    EAErrorManager.clearError();

    this.model.operationType = this.model.operationType === OperationTypeEnum.NUEVA ?
      OperationTypeEnum.MANTENIMIENTO : this.model.operationType;
    this.update();
    this.$emit('changeStep', FlowViewsStepsModel.FleetDataStep, FlowHeaderStepsModel.FleetDataStep);
  }

}
</script>
