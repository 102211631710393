<template>
  <ea-card shadow="hidden">
    <ea-form  class="p-a-16" slot="cardbody" ref="form" :model="model">
      <ea-row v-if="model">
        <ea-col :span="6">
          <ea-form-item :label="$t('fleets.fleetsQueryPolicyFlow.summaryLabels.intermediaryName')">
            <ea-input-text
              :readonly="true"
              :value="model.qbNewOfferBusinessModel.intermediaryName"
            ></ea-input-text>
          </ea-form-item>
        </ea-col>
        <ea-col :span="6">
          <ea-form-item :label="$t('fleets.fleetsQueryPolicyFlow.summaryLabels.intermediaryCode')">
            <ea-input-text
              :readonly="true"
              :value="model.qbNewOfferBusinessModel.intermediaryCode"
            ></ea-input-text>
          </ea-form-item>
        </ea-col>
        <ea-col :span="6">
          <ea-form-item :label="$t('fleets.fleetsQueryPolicyFlow.summaryLabels.effectiveDate')">
            <ea-date-picker
              :readonly="true"
              :value="model.fleetIssuanceDataModel.fechaEfectoMovimiento"
            />
          </ea-form-item>
        </ea-col>
        <ea-col :span="6">
          <ea-form-item :label="$t('fleets.fleetsQueryPolicyFlow.summaryLabels.endDate')">
            <ea-date-picker
              :readonly="true"
              :value="model.fleetIssuanceDataModel.fechaVencimientoFlota"
            />
          </ea-form-item>
        </ea-col>
        <ea-col :span="6">
          <ea-form-item :label="$t('fleets.fleetsQueryPolicyFlow.summaryLabels.fleetCode')">
            <ea-input-text
              :readonly="true"
              :value="model.offerNumber"
            ></ea-input-text>
          </ea-form-item>
        </ea-col>
        <ea-col :span="6">
          <ea-form-item :label="$t('fleets.fleetsQueryPolicyFlow.summaryLabels.fleetName')">
            <ea-input-textarea
              :readonly="true"
              :value="model.fleetName"
              :resize="false"
            ></ea-input-textarea>
          </ea-form-item>
        </ea-col>
        <ea-col :span="6">
          <ea-form-item :label="$t('fleets.fleetsQueryPolicyFlow.summaryLabels.paymentChannel')">
            <ea-input-text
              :readonly="true"
              :value="paymentChannel"
            ></ea-input-text>
          </ea-form-item>
        </ea-col>
        <ea-col :span="6" v-if="isPaymentChannelBank">
          <ea-form-item :label="$t('fleets.fleetsQueryPolicyFlow.summaryLabels.paymentAccount')">
            <ea-input-text
              :readonly="true"
              :value="iban"
            ></ea-input-text>
          </ea-form-item>
        </ea-col>
      </ea-row>
    </ea-form>
  </ea-card>
</template>

<script lang="ts">
import Vue from 'vue';
import {
  Component, Prop
} from 'vue-property-decorator';
import {
  FleetsQueryPolicyModel
} from '@/flows/fleets-query-policy/fleets-query-policy-model';
import {
  FleetsMaintenancePolicyModel
} from '@/flows/fleets-maintenance-policy/fleets-maintenance-policy-model';


@Component({
  name: 'fleet-summary-policy-info',
})

/**
 * Presentational Component fleet summary for issued fleets
 */
export default class FleetSummaryPolicyInfo extends Vue {

  @Prop({
    required: true,
    'default': () => new FleetsQueryPolicyModel()
  })
    model!: FleetsQueryPolicyModel | FleetsMaintenancePolicyModel;

  /** 
   * EffectiveDate getter
   */
  get effectiveDate() {
    return this.model.fleetUnifiedExpirationModel?.formValues?.hasUnifiedExpiration ?
      this.model.fleetUnifiedExpirationModel?.formValues?.unifiedExpirationDate :
      this.model.fleetUnifiedExpirationModel?.formValues?.notUnifiedExpirationDate;
  }

  /** 
   * PaymentChannel description
   */
  get paymentChannel() {
    const paymentChannel = this.model.fleetPaymentDataModel.paymentChannel;
    return paymentChannel
      ? this.$t(`fleets.fleetsFlow.offerIssuanceDataView.paymentDataComponent.options.${paymentChannel}`)
      : '-';
  }

  /**
   * Payment channel code
   */
  get isPaymentChannelBank() {
    return this.model.fleetPaymentDataModel.paymentChannel.toUpperCase() === 'BC';
  }

  /** 
   * Iban getter
   */
  get iban() {
    return this.model.fleetPaymentDataModel?.ibanCodeModel?.qbIbanCodeModalModel?.selectedIbanCode || '-';
  }
}
</script>
