<template>
  <div>
    <ea-row
      v-for="equalStatement, index in shownEqualStatements"
      :key="'row' + equalStatement.roleCopyFrom + equalStatement.roleCopyTo"
    >
      <ea-col>
        <ea-form>
          <ea-form-item :prop="equalStatement.roleCopyTo" class="radiogroup-horizontal m-b-8">
            <!-- LABEL -->
            <span class="radiogroup-label">
              {{ equalStatementTranslatedText(equalStatement) }}
            </span>

            <!-- RADIO BUTTONS -->
            <span v-if="!equalStatement.readonly">
              <ea-radio-group
                :key="equalStatement.roleCopyFrom + equalStatement.roleCopyTo"
                v-model="equalStatement.value"
                direction="horizontal"
                @change="radioChange(index)"
              >
                <ea-radio
                  :label="true"
                  :readonly="equalStatement.readonly !== undefined ? equalStatement.readonly : disabled"
                  class="m-8">{{ $t('common.label.yes') }}
                </ea-radio>
                <ea-radio
                  :label="false"
                  :readonly="equalStatement.readonly !== undefined ? equalStatement.readonly : disabled"
                  class="m-8">{{ $t('common.label.no') }}
                </ea-radio>
              </ea-radio-group>
            </span>
          </ea-form-item>
        </ea-form>
      </ea-col>
    </ea-row>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import {
  Component, Prop
} from 'vue-property-decorator';
import {
  SelectionInFormTabRole,
  PersonRole
} from '@/utils/quote-buy-product-factory/types/product-role-types';

@Component({
  name: 'policy-holder-vs-insured-person'
})

/**
 * Policy holder vs insured person component
 */
export default class PolicyHolderVsInsuredPersonComponent extends Vue {

  @Prop({
    required: true,
  })
    person!: PersonRole;
  
  @Prop()
    disabled?: boolean;

  /**
   * Returns a filtered version of selections.
   *
   * @returns {SelectionInFormTabRole[] | undefined}
   */
  get shownEqualStatements(): SelectionInFormTabRole[] | undefined {
    return this.person.roleEqualStatements?.filter(equalStatement => !equalStatement.hidden && !equalStatement.block);
  }

  /**
   * Handles radio button change.
   * Emits event to inform parent
   * @param {number} index
   */
  radioChange(index: number) {
    this.$emit('equalStatementRadioBtnChange', this.shownEqualStatements?.[index]);
  }


  /**
   * Gets equal statement translated text
   * Use for displaying it to user
   * @param {SelectionInFormTabRole} equalStatement
   * @returns {string}
   */
  equalStatementTranslatedText(
    equalStatement: SelectionInFormTabRole,
  ): string {
    const equalityOperator = this.disabled && !equalStatement.value ? 'notEquals' : 'equals';
    const endOfLinePunctuationSign = this.disabled ? '.' : ':';

    return this.$t(
      `quoteBuyGenericFlow.person.${equalStatement.roleCopyTo}.${equalityOperator}.${equalStatement.roleCopyFrom}`
    ) + endOfLinePunctuationSign;
  }

}

</script>
