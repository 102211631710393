export interface AndorraProvinceModel {
  zipCode: string;
  provinceCode: string;
  province: string;
}

/** 
 * Model post-code-location-input
 */
class PostCodeLocationInputModel {

  public zipCode: string = '';

  public city: string = '';

  public cityCode: string = '';

  public province: string = '';

  public populationNumber: number = 0;

  public poblationArea?: number = 0;

  public andorraProvinces: AndorraProvinceModel[] = [
    {
      zipCode: 'AD1XX',
      provinceCode: 'AD',
      province: 'CANILLO'
    },
    {
      zipCode: 'AD2XX',
      provinceCode: 'AD',
      province: 'ENCAMP'
    },
    {
      zipCode: 'AD3XX',
      provinceCode: 'AD',
      province: 'ORDINO'
    },
    {
      zipCode: 'AD4XX',
      provinceCode: 'AD',
      province: 'LA MASSANA'
    },
    {
      zipCode: 'AD5XX',
      provinceCode: 'AD',
      province: 'ANDORRA LA VELLA'
    },
    {
      zipCode: 'AD6XX',
      provinceCode: 'AD',
      province: 'SANT JULIÀ DE LÒRIA'
    },
    {
      zipCode: 'AD7XX',
      provinceCode: 'AD',
      province: 'ESCALDES-ENGORDANY'
    },
  ];

  public isProvinceShown: boolean = true;

  public columnSpan: number = 6;
}

export default PostCodeLocationInputModel;
