import WarrantiesHeaderGridSelectionModel
  from '../warranties-header-grid-selection/warranties-header-grid-selection-model';
import WarrantiesHeaderInlineSelectionModel
  from '../warranties-header-inline-selection/warranties-header-inline-selection-model';

/** 
 * Model warranties-header
 */
class WarrantiesHeaderModel {

  public warrantiesGroupsInlineSelectionModel: WarrantiesHeaderInlineSelectionModel =
    new WarrantiesHeaderInlineSelectionModel();

  public packagesGroupsInlineSelectionModel: WarrantiesHeaderInlineSelectionModel =
    new WarrantiesHeaderInlineSelectionModel();

  public warrantiesGroupsGridSelectionModel: WarrantiesHeaderGridSelectionModel =
    new WarrantiesHeaderGridSelectionModel();

  public packagesGroupsGridSelectionModel: WarrantiesHeaderGridSelectionModel =
    new WarrantiesHeaderGridSelectionModel();

}

export default WarrantiesHeaderModel;
