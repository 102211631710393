<template>
  <ea-dialog id="phoneAddressDialog"
      :visible="isVisible"
      @open="onDialogOpen"
      :beforeClose="onCancel"
      size="large"
      height="75vh">
    <zz-client-basic-info
      id="showClientData"
      :documentationClientData="documentationClientData"
        ref="documentationClientDataComp"
      class="m-b-40"
    ></zz-client-basic-info>

    <div class="d-display-flex d-justify-space-between">
      <!-- Select address column -->
      <div>
        <h4 class="m-b-0">{{ selectedAddressTitle }}</h4>
        <ea-link
          @click="enterAddingAddressMode" >
            {{ addLinkText }}
        </ea-link>

        <div v-if="model.phoneAddresses && model.phoneAddresses.length === 0" class="t-weight-normal m-t-8">
          {{ emptyResultText }}
        </div>

        <ea-radio-group
          class="m-t-32"
          v-if="model.phoneAddresses && model.phoneAddresses.length > 0"
          v-model="model.selectedPhoneNumber"
          @change="onRadioBtnSelectionChange"
        >
          <div v-for="(phoneAddress, index) in model.phoneAddresses" :key="index" class="d-display-flex">
            <ea-radio class="m-r-8 m-b-16" :label="getPhoneLabel(phoneAddress)">
              {{ getPhoneLabel(phoneAddress) }}
            </ea-radio>
            <ea-button-icon
              @click="enterEdittingAddressMode(phoneAddress)"
              icon="z-edit"/>
          </div>
        </ea-radio-group>
      </div>

      <!-- Add/Edit column -->
      <div
        class="m-r-24"
        v-if="model.isAddingEditting">
        <h4 class="m-b-8" v-if="model.phoneInEditionIdx === undefined">{{ addColumnTitle }}</h4>
        <h4 class="m-b-8" v-if="model.phoneInEditionIdx !== undefined">{{ editColumnTitle }}</h4>

        <ea-form ref="form"
          :model="model.phoneEditionForm" :validationOptions="validationOptions" :validateOnRuleChange="false"
        >
          <ea-row>
            <ea-col :span="12">
              <ea-form-item prop="phoneType" :label="$t('addPhoneModal.fields.phoneType')" required>
                <ea-select v-model="model.phoneEditionForm.phoneType" :placeholder="$t('common.label.select')">
                  <ea-option
                    v-for="phoneType in corpTableDocumentsPhoneTypes"
                    :key="phoneType.value"
                    :label="phoneType.label"
                    :value="phoneType.value"
                  />
                </ea-select>
              </ea-form-item>
            </ea-col>
            <ea-col :span="12">
              <ea-form-item prop="phoneCountry" :label="$t('addPhoneModal.fields.country')" required>
                <ea-select v-model="model.phoneEditionForm.phoneCountry" :placeholder="$t('common.label.select')">
                  <ea-option
                    v-for="phoneCountry in corpTableDocumentsPhonePrefixes"
                    :key="phoneCountry.value"
                    :label="phoneCountry.label"
                    :value="phoneCountry.value"
                  />
                </ea-select>
              </ea-form-item>
            </ea-col>
          </ea-row>

          <ea-row>
            <ea-col :span="12">
              <ea-form-item prop="phoneNumber" :label="$t('addPhoneModal.fields.phoneNumber')" required>
                <ea-input-number
                  :use-grouping="false"
                  v-model="model.phoneEditionForm.phoneNumber"
                />
              </ea-form-item>
            </ea-col>
          </ea-row>
        </ea-form>
      </div>
    </div>

    <!-- Cancel and save button -->
    <div slot="footer" class="m-r-24 d-display-flex d-justify-flex-end">
      <ea-button type="secondary" @click="onCancel()" class="m-r-16">
        {{ $t('common.label.cancel') }}
      </ea-button>
      <ea-button type="primary" @click="onSave()" v-if="model.isAddingEditting">
        {{ $t('common.label.save') }}
      </ea-button>
      <ea-button type="primary" @click="onSelect()" v-if="!model.isAddingEditting">
        {{ $t('common.label.select') }}
      </ea-button>
    </div>
  </ea-dialog>
</template>

<script lang="ts">
import {
  Component, Prop, Watch
} from 'vue-property-decorator';

import {
  mixins
} from 'vue-class-component';

import {
  EAApplicationLogger,
  EABusinessComponent,
  EACorporateTableDirective,
  EAError,
  EAMethod,
} from '@zurich-es-npm/ea-front-web-core';
import AddPhoneTomadorModel from './add-phone-tomador-model';
// eslint-disable-next-line max-len
import {
  CorpTableNames,
  fetchCorporateTable,
  parseCorpTableDocuments,
  ParsedTableData,
} from '@/utils/corporate-tables';
import {
  IndexedGetPersonAddressesResponseDomicilios
} from '../../selectables/add-edit-phone/add-edit-phone-model';

import {
  eaCustomValidation,
  EAFormValidationOptions,
  eaRequiredNumberValidation,
  eaRequiredValidation,
  EAValidation,
  Form
} from '@zurich-es-npm/ea-front-web-ui';
import {
  cloneDeep
} from 'lodash';
import {
  EAValidatorFunction
} from '@zurich-es-npm/ea-front-web-core/lib/validation-rules';
import ZzClientBasicInfo from '@/presentational-components/clientBasicInfo/clientBasicInfo.vue';
import {
  DocumentationClientData
} from '@/presentational-components/clientBasicInfo/clientBasicInfo.type';

@Component({
  name: 'add-phone-tomador',
  components: {
    'zz-client-basic-info': ZzClientBasicInfo
  },
  directives: {
    'ea-corporate-table': EACorporateTableDirective,
  },
})

/**
 *
 */
export default class AddPhoneTomadorBusiness extends mixins<EABusinessComponent<AddPhoneTomadorModel>>(
  EABusinessComponent
) {
  @Prop()
    selectedAddressTitle!: string;

  @Prop()
    addColumnTitle!: string;

  @Prop()
    editColumnTitle!: string;

  @Prop()
    addLinkText!: string;

  @Prop()
    emptyResultText!: string;

  @Prop()
    documentationClientData!: DocumentationClientData;

  public corpTableDocumentsPhoneTypes: ParsedTableData[] = [];

  public corpTableDocumentsPhonePrefixes: ParsedTableData[] = [];

  public isVisible = false;

  /**
   * Hook on created.
   *
   * @returns {void}
   */
  public created(): void {
    this.getCorpTablesData();
  }

  /**
   * Fetch corporate tables to retrieve products names and parse data.
   */
  @EAMethod({
    loading:true
  })
  async getCorpTablesData(): Promise<void> {
    try {
      const promises = [
        fetchCorporateTable(CorpTableNames.PhoneTypes),
        fetchCorporateTable(CorpTableNames.CountryPhonePrefixes),
      ];
      const results = await Promise.all(promises);

      this.corpTableDocumentsPhoneTypes = parseCorpTableDocuments(results[0])?.filter(
        result => result.value !== 'FA'
      );
      this.corpTableDocumentsPhonePrefixes = parseCorpTableDocuments(results[1]);
    } catch (error) {
      const eaError = error as EAError;
      new EAApplicationLogger().error(
        `NewOfferBusiness::getCorpTablesData:: fetch corporate table :: ${eaError.message}`
      );
      throw error;
    }
  }

  /**
   * Handles cancel button
   */
  @EAMethod()
  public onCancel(): void {
    this.close();
    this.update();
    this.$nextTick(() => {
      this.$emit('cancel');
    });
  }

  /**
   * Opens modal
   */
  open(): void {
    this.isVisible = true;
  }

  /**
   * Closes modal
   */
  close(): void {
    this.isVisible = false;
  }

  /**
   * Return form validation object
   * @returns {EAValidation}
   */
  public validation(): EAValidation {
    const form: Form = this.$refs.form as Form;
    return form.validation();
  }

  /**
   * Handles save button
   * Emits select and save address
   * DOES NOT CLOSE EDITION MODAL AS IT HAS TO REMAIN OPEN IN CASE OF AN ERROR
   */
  @EAMethod()
  public async onSave() {
    this.clearFlowErrors();

    try {
      await this.validation().validate();

      let savedPhone: IndexedGetPersonAddressesResponseDomicilios | undefined;

      if (this.model.phoneInEditionIdx === undefined) {
        // ADDING
        savedPhone = {
          phone: {},
          secuencialCode: '',
          phoneIndex: this.model.phoneAddresses.filter(phoneAddress => phoneAddress.secuencialCode === '').length,
        };
      } else {
        // EDITTING
        savedPhone = cloneDeep(
          this.model.phoneAddresses.find(
            phone => phone.secuencialCode === this.model.phoneInEditionSecCode &&
              phone.phoneIndex === this.model.phoneInEditionIdx
          )
        );
      }

      if (savedPhone) {
        savedPhone.phone = {
          tipoTelefono: this.model.phoneEditionForm.phoneType,
          prefijoPais: this.model.phoneEditionForm.phoneCountry,
          numeroTelefono: this.model.phoneEditionForm.phoneNumber?.toString().replace(/\s/g, ''),
        };

        this.$emit('savePhoneAddress', savedPhone);
      }
    } catch (err) {
      // Design System will show form errors
      const modalHtml = document.querySelector(`#phoneAddressDialog .el-dialog`);
      modalHtml?.scrollTo(0, 0);
    }
  }

  /**
   * Shows add address form
   */
  @EAMethod()
  public enterAddingAddressMode() {
    this.resetEditionForm();
    this.model.isAddingEditting = true;
    this.model.phoneInEditionIdx = undefined;
    this.update();
  }

  /**
   * Sets phoneCountry to España
   */
  @Watch('model.phoneEditionForm.phoneCountry', {
    immediate: true,
  })
  setPhoneCountryDeafultValue() {
    const selectPhoneCountryValue = this.model.phoneEditionForm.phoneCountry;
    
    if (selectPhoneCountryValue === '') {
      this.model.phoneEditionForm.phoneCountry = '34'; // España
    }
  }

  /**
   * Selects address to edit
   * @param {IndexedGetPersonAddressesResponseDomicilios} address
   */
  @EAMethod()
  public enterEdittingAddressMode(address: IndexedGetPersonAddressesResponseDomicilios) {
    this.model.isAddingEditting = true;
    this.model.phoneInEditionIdx = address.phoneIndex;
    this.model.phoneInEditionSecCode = address.secuencialCode;

    if (this.model.phoneInEditionIdx !== undefined && this.model.phoneInEditionSecCode) {
      const phoneInEdition = this.model.phoneAddresses.find(
        phoneAddress => phoneAddress.secuencialCode === this.model.phoneInEditionSecCode &&
          phoneAddress.phoneIndex === this.model.phoneInEditionIdx
      );

      if (phoneInEdition && phoneInEdition.phone && phoneInEdition.phone.numeroTelefono) {
        this.model.phoneEditionForm = {
          phoneNumber: phoneInEdition.phone.numeroTelefono,
          phoneType: phoneInEdition.phone.tipoTelefono,
          phoneCountry: phoneInEdition.phone.prefijoPais,
        };

        this.model.selectedPhoneNumber = this.getPhoneLabel(phoneInEdition);
      }
    }

    this.update();
  }

  /**
   * Resets edition form
   */
  public resetEditionForm() {
    this.model.phoneEditionForm = {
      phoneNumber: undefined,
      phoneType: '',
      phoneCountry: '',
    };
  }

  /**
   * Parses postal label to display format
   * @param {IndexedGetPersonAddressesResponseDomicilios} phone
   * @returns {string}
   */
  public getPhoneLabel(phone: IndexedGetPersonAddressesResponseDomicilios): string {
    return phone.phone.numeroTelefono || '';
  }

  /**
   * Listens dialog open event
   * Resets dialog
   * Recalculate person's info labels
   */
  public onDialogOpen() {
    this.resetEditionForm();
    this.model.isAddingEditting = false;
    this.model.phoneInEditionIdx = undefined;

    const qbDocumentationClientDataComp: ZzClientBasicInfo =
      this.$refs.documentationClientDataComp as ZzClientBasicInfo;
    if (qbDocumentationClientDataComp) {
      qbDocumentationClientDataComp.calculateLabels();
    }
  }

  /**
   * Handles select button
   * Saves marked address as selected address
   */
  @EAMethod()
  public onSelect() {
    this.$emit('selectPhoneNumber', this.model.selectedPhoneNumber);
  }

  /**
   * Handler for radiobuttons change
   */
  public onRadioBtnSelectionChange() {
    this.model.isAddingEditting = false;
    this.model.phoneInEditionIdx = undefined;
    this.model.phoneInEditionSecCode = undefined;
  }

  /**
   * @returns {EAFormValidationOptions}
   */
  get validationOptions(): EAFormValidationOptions {
    return {
      rules: {
        phoneNumber: [
          eaCustomValidation(this.phoneNumberValidation(), 'common.label.validation.invalidRange'),
          eaRequiredNumberValidation()
        ],
        phoneType: [eaRequiredValidation()],
        phoneCountry: [eaRequiredValidation()]
      }
    };
  }

  /**
   * Validates if phone number length is ok
   *
   * @returns {EAValidatorFunction}
   */
  phoneNumberValidation(): EAValidatorFunction {
    return (_rule: any, _value: any, callback: Function) => {
      if (
        !!this.model.phoneEditionForm.phoneNumber &&
        this.model.phoneEditionForm.phoneNumber.toString().length !== 9 &&
        this.model.phoneEditionForm.phoneNumber.toString().length !== 10
      ) {
        return callback(new Error());
      }

      return callback();
    };
  }
}
</script>
