/* eslint-disable */

/**
 * zonazurich
 * Portal de Mediación Digital
 *
 * The version of the OpenAPI document: 1
 * Contact: digitalarchitecture@zurich.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import {
  EASDKConfig,
  EAUrlUtils
} from '@zurich-es-npm/ea-shared-utils-js';

import * as globalImportUrl from 'url';
import {
  Configuration
} from './configuration';
import globalAxios, {
  AxiosPromise,
  AxiosInstance,
  AxiosResponse
} from 'axios';

const BASE_PATH = EAUrlUtils.removeLastForwardSlash('http://localhost:3000');

/**
 *
 * @export
 */
export const COLLECTION_FORMATS = {
  csv: ',',
  ssv: ' ',
  tsv: '\t',
  pipes: '|',
};

/**
 *  
 * @export
 * @interface RequestArgs
 */
export interface RequestArgs {
  url: string;
  options: any;
}

/**
 * 
 * @export
 * @class BaseAPI
 */
export class BaseAPI {
  protected configuration: Configuration | undefined;

  /**
   *Creates an instance of BaseAPI.
   * @param {Configuration} [configuration]
   * @param {string} [basePath=BASE_PATH]
   * @param {AxiosInstance} [axios=globalAxios]
   * @memberof BaseAPI
   */
  public constructor(
    configuration?: Configuration,
    protected basePath: string = BASE_PATH,
    protected axios: AxiosInstance = globalAxios) {
    if (configuration) {
      this.configuration = configuration;
      this.basePath = configuration.basePath || this.basePath;
    }
  }
}

/**
 * 
 * @export
 * @class RequiredError
 * @extends {Error}
 */
export class RequiredError extends Error {
  public name: 'RequiredError' = 'RequiredError';

  public field: string;

  /**
   *Creates an instance of RequiredError.
   * @param {string} field
   * @param {string} [msg]
   * @memberof RequiredError
   */
  public constructor(field: string, msg?: string) {
    super(msg);
    this.field=field;
  }
}

/**
 * 
 * @export
 * @class AsyncRequestError
 * @extends {Error}
 */
export class AsyncRequestError extends Error {
  public name: 'AsyncRequestError' = 'AsyncRequestError';

  /**
   *Creates an instance of AsyncRequestError.
   * @param {string} field
   * @param {string} [msg]
   * @memberof AsyncRequestError
   */
  public constructor(msg?: string) {
    super(msg);
  }
}

/**
 * 
 * @export
 * @interface Error
 */
export interface Error {
    /**
     * 
     * @type {string}
     * @memberof Error
     */
    code: string;
    /**
     * 
     * @type {string}
     * @memberof Error
     */
    message: string;
    /**
     * 
     * @type {number}
     * @memberof Error
     */
    severity: number;
    /**
     * 
     * @type {Array<object>}
     * @memberof Error
     */
    childErrors?: Array<object>;
}/**
 * 
 * @export
 * @interface GetPersonsRequest
 */
export interface GetPersonsRequest {
    /**
     * 
     * @type {string}
     * @memberof GetPersonsRequest
     */
    codigoFiliacion?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonsRequest
     */
    tipoPersona?: GetPersonsRequestTipoPersonaEnum;
    /**
     * 
     * @type {string}
     * @memberof GetPersonsRequest
     */
    codigoIdentificacionFiscal?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonsRequest
     */
    tipoDocumento?: GetPersonsRequestTipoDocumentoEnum;
    /**
     * 
     * @type {string}
     * @memberof GetPersonsRequest
     */
    primerApellido?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonsRequest
     */
    segundoApellido?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonsRequest
     */
    nombrePropio?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonsRequest
     */
    campoPaginacion?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum GetPersonsRequestTipoPersonaEnum {
    F = 'F',
    J = 'J'
}
/**
    * @export
    * @enum {string}
    */
export enum GetPersonsRequestTipoDocumentoEnum {
    C = 'C',
    E = 'E',
    N = 'N',
    P = 'P',
    R = 'R',
    S = 'S',
    X = 'X'
}
/**
 * 
 * @export
 * @interface GetPersonsResponse
 */
export interface GetPersonsResponse {
    /**
     * 
     * @type {Array<GetPersonsResponseListaPersonas>}
     * @memberof GetPersonsResponse
     */
    listaPersonas?: Array<GetPersonsResponseListaPersonas>;
    /**
     * 
     * @type {GetPersonsResponseDatosPersona}
     * @memberof GetPersonsResponse
     */
    datosPersona?: GetPersonsResponseDatosPersona;
    /**
     * 
     * @type {boolean}
     * @memberof GetPersonsResponse
     */
    nombreIncompletoDetectado?: boolean;
    /**
     * 
     * @type {Array<Error>}
     * @memberof GetPersonsResponse
     */
    errors?: Array<Error>;
}/**
 * 
 * @export
 * @interface GetPersonsResponseDatosPersona
 */
export interface GetPersonsResponseDatosPersona {
    /**
     * 
     * @type {GetPersonsResponseListaPersonas}
     * @memberof GetPersonsResponseDatosPersona
     */
    datosBasicosPersona?: GetPersonsResponseListaPersonas;
    /**
     * 
     * @type {GetPersonsResponseDatosPersonaDatosGeneralesPersona}
     * @memberof GetPersonsResponseDatosPersona
     */
    datosGeneralesPersona?: GetPersonsResponseDatosPersonaDatosGeneralesPersona;
    /**
     * 
     * @type {GetPersonsResponseDatosPersonaTitulacionPersona}
     * @memberof GetPersonsResponseDatosPersona
     */
    titulacionPersona?: GetPersonsResponseDatosPersonaTitulacionPersona;
    /**
     * 
     * @type {GetPersonsResponseDatosPersonaEmpresa}
     * @memberof GetPersonsResponseDatosPersona
     */
    empresa?: GetPersonsResponseDatosPersonaEmpresa;
    /**
     * 
     * @type {GetPersonsResponseDatosPersonaRegistroEmpresa}
     * @memberof GetPersonsResponseDatosPersona
     */
    registroEmpresa?: GetPersonsResponseDatosPersonaRegistroEmpresa;
    /**
     * 
     * @type {GetPersonsResponseDatosPersonaRegistroEmpresaDetalles}
     * @memberof GetPersonsResponseDatosPersona
     */
    registroEmpresaDetalles?: GetPersonsResponseDatosPersonaRegistroEmpresaDetalles;
    /**
     * 
     * @type {Array<GetPersonsResponseDatosPersonaListaCarnetsPersona>}
     * @memberof GetPersonsResponseDatosPersona
     */
    listaCarnetsPersona?: Array<GetPersonsResponseDatosPersonaListaCarnetsPersona>;
    /**
     * 
     * @type {GetPersonsResponseDatosPersonaMarketing}
     * @memberof GetPersonsResponseDatosPersona
     */
    marketing?: GetPersonsResponseDatosPersonaMarketing;
    /**
     * 
     * @type {GetPersonsResponseDatosPersonaDomicilioPrincipal}
     * @memberof GetPersonsResponseDatosPersona
     */
    domicilioPrincipal?: GetPersonsResponseDatosPersonaDomicilioPrincipal;
}/**
 * 
 * @export
 * @interface GetPersonsResponseDatosPersonaDatosGeneralesPersona
 */
export interface GetPersonsResponseDatosPersonaDatosGeneralesPersona {
    /**
     * 
     * @type {string}
     * @memberof GetPersonsResponseDatosPersonaDatosGeneralesPersona
     */
    tratamiento?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonsResponseDatosPersonaDatosGeneralesPersona
     */
    sexo?: GetPersonsResponseDatosPersonaDatosGeneralesPersonaSexoEnum;
    /**
     * 
     * @type {string}
     * @memberof GetPersonsResponseDatosPersonaDatosGeneralesPersona
     */
    fechaNacimiento?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonsResponseDatosPersonaDatosGeneralesPersona
     */
    nombrePoblacion?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonsResponseDatosPersonaDatosGeneralesPersona
     */
    poblacionIneNacimiento?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonsResponseDatosPersonaDatosGeneralesPersona
     */
    nacionalidadPersona?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonsResponseDatosPersonaDatosGeneralesPersona
     */
    codigoResidencia?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonsResponseDatosPersonaDatosGeneralesPersona
     */
    estadoCivil?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonsResponseDatosPersonaDatosGeneralesPersona
     */
    tipoContactoZurich?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonsResponseDatosPersonaDatosGeneralesPersona
     */
    idiomaCorrespondencia?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonsResponseDatosPersonaDatosGeneralesPersona
     */
    especialidadPrincipal?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonsResponseDatosPersonaDatosGeneralesPersona
     */
    indicadorListaRobinson?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonsResponseDatosPersonaDatosGeneralesPersona
     */
    codigoCnae?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonsResponseDatosPersonaDatosGeneralesPersona
     */
    indicadorPersoonaRestringida?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum GetPersonsResponseDatosPersonaDatosGeneralesPersonaSexoEnum {
    V = 'V',
    M = 'M'
}
/**
 * 
 * @export
 * @interface GetPersonsResponseDatosPersonaDomicilioPrincipal
 */
export interface GetPersonsResponseDatosPersonaDomicilioPrincipal {
    /**
     * 
     * @type {string}
     * @memberof GetPersonsResponseDatosPersonaDomicilioPrincipal
     */
    tipoViaDireccion?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonsResponseDatosPersonaDomicilioPrincipal
     */
    nombreDireccion?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonsResponseDatosPersonaDomicilioPrincipal
     */
    numeroDireccion?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonsResponseDatosPersonaDomicilioPrincipal
     */
    nombrePoblacion?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonsResponseDatosPersonaDomicilioPrincipal
     */
    codigoPoblacionINE?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonsResponseDatosPersonaDomicilioPrincipal
     */
    nombreProvincia?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonsResponseDatosPersonaDomicilioPrincipal
     */
    codigoPostalConLetras?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonsResponseDatosPersonaDomicilioPrincipal
     */
    codigoPais?: string;
}/**
 * 
 * @export
 * @interface GetPersonsResponseDatosPersonaEmpresa
 */
export interface GetPersonsResponseDatosPersonaEmpresa {
    /**
     * 
     * @type {string}
     * @memberof GetPersonsResponseDatosPersonaEmpresa
     */
    nombreEmpresaTrabajo?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonsResponseDatosPersonaEmpresa
     */
    codigoCategoriaEmpresa?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonsResponseDatosPersonaEmpresa
     */
    tipoContrato?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonsResponseDatosPersonaEmpresa
     */
    fechaAltaEmpresa?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonsResponseDatosPersonaEmpresa
     */
    fechaBajaEmpresa?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonsResponseDatosPersonaEmpresa
     */
    codigoBajaEmpresa?: string;
}/**
 * 
 * @export
 * @interface GetPersonsResponseDatosPersonaListaCarnetsPersona
 */
export interface GetPersonsResponseDatosPersonaListaCarnetsPersona {
    /**
     * 
     * @type {string}
     * @memberof GetPersonsResponseDatosPersonaListaCarnetsPersona
     */
    tipoPermiso?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonsResponseDatosPersonaListaCarnetsPersona
     */
    numeroPermiso?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonsResponseDatosPersonaListaCarnetsPersona
     */
    fechaObtencionPermiso?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonsResponseDatosPersonaListaCarnetsPersona
     */
    fechaUltimaExpedicion?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonsResponseDatosPersonaListaCarnetsPersona
     */
    fechaCaducidadPermiso?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonsResponseDatosPersonaListaCarnetsPersona
     */
    estadoPermiso?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonsResponseDatosPersonaListaCarnetsPersona
     */
    fechaEstadoPermiso?: string;
}/**
 * 
 * @export
 * @interface GetPersonsResponseDatosPersonaMarketing
 */
export interface GetPersonsResponseDatosPersonaMarketing {
    /**
     * 
     * @type {string}
     * @memberof GetPersonsResponseDatosPersonaMarketing
     */
    timestampPersonaFisica?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonsResponseDatosPersonaMarketing
     */
    timestampPersonaJuridica?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonsResponseDatosPersonaMarketing
     */
    codigoFicheroExterno?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonsResponseDatosPersonaMarketing
     */
    indicadorFicheroExterno?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonsResponseDatosPersonaMarketing
     */
    indicadorDatosNormalizados?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonsResponseDatosPersonaMarketing
     */
    codigoOrigenPersona?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonsResponseDatosPersonaMarketing
     */
    indicadorFraude?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonsResponseDatosPersonaMarketing
     */
    codigoUnidad?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonsResponseDatosPersonaMarketing
     */
    claveSBU?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonsResponseDatosPersonaMarketing
     */
    codigoPasaporte?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonsResponseDatosPersonaMarketing
     */
    tipoRegimenFiscal?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonsResponseDatosPersonaMarketing
     */
    fechaEmisionCertificadoResidencia?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonsResponseDatosPersonaMarketing
     */
    indicadorNifCifModificado?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonsResponseDatosPersonaMarketing
     */
    codigoRiskName?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonsResponseDatosPersonaMarketing
     */
    fechaAltaRiskName?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonsResponseDatosPersonaMarketing
     */
    fechaBajaRiskName?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonsResponseDatosPersonaMarketing
     */
    codigoPaisDocumentoCee?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonsResponseDatosPersonaMarketing
     */
    indicadorMorosidadZfs?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonsResponseDatosPersonaMarketing
     */
    indicadorTipoPersona?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonsResponseDatosPersonaMarketing
     */
    tipoCliente?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonsResponseDatosPersonaMarketing
     */
    estadoPersona?: GetPersonsResponseDatosPersonaMarketingEstadoPersonaEnum;
    /**
     * 
     * @type {string}
     * @memberof GetPersonsResponseDatosPersonaMarketing
     */
    campoPaginacion?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum GetPersonsResponseDatosPersonaMarketingEstadoPersonaEnum {
    T = 'T',
    A = 'A'
}
/**
 * 
 * @export
 * @interface GetPersonsResponseDatosPersonaRegistroEmpresa
 */
export interface GetPersonsResponseDatosPersonaRegistroEmpresa {
    /**
     * 
     * @type {string}
     * @memberof GetPersonsResponseDatosPersonaRegistroEmpresa
     */
    codigoEpigrafe?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonsResponseDatosPersonaRegistroEmpresa
     */
    codigoDelegacionHacienda?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonsResponseDatosPersonaRegistroEmpresa
     */
    codigoIcea?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonsResponseDatosPersonaRegistroEmpresa
     */
    codigoLetraIcea?: string;
}/**
 * 
 * @export
 * @interface GetPersonsResponseDatosPersonaRegistroEmpresaDetalles
 */
export interface GetPersonsResponseDatosPersonaRegistroEmpresaDetalles {
    /**
     * 
     * @type {string}
     * @memberof GetPersonsResponseDatosPersonaRegistroEmpresaDetalles
     */
    codigoProvinciaInscripcionRm?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonsResponseDatosPersonaRegistroEmpresaDetalles
     */
    fechaInscripcionRm?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonsResponseDatosPersonaRegistroEmpresaDetalles
     */
    codigoHojaRm?: string;
}/**
 * 
 * @export
 * @interface GetPersonsResponseDatosPersonaTitulacionPersona
 */
export interface GetPersonsResponseDatosPersonaTitulacionPersona {
    /**
     * 
     * @type {string}
     * @memberof GetPersonsResponseDatosPersonaTitulacionPersona
     */
    titulacionAcademica?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonsResponseDatosPersonaTitulacionPersona
     */
    profesion?: string;
}/**
 * 
 * @export
 * @interface GetPersonsResponseListaPersonas
 */
export interface GetPersonsResponseListaPersonas {
    /**
     * 
     * @type {string}
     * @memberof GetPersonsResponseListaPersonas
     */
    codigoFiliacion?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonsResponseListaPersonas
     */
    codigoIdentificacionFiscal?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonsResponseListaPersonas
     */
    tipoDocumento?: GetPersonsResponseListaPersonasTipoDocumentoEnum;
    /**
     * 
     * @type {string}
     * @memberof GetPersonsResponseListaPersonas
     */
    descripcionDireccionCliente?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonsResponseListaPersonas
     */
    nombreCompactadoPersonas?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonsResponseListaPersonas
     */
    estadoPersona?: GetPersonsResponseListaPersonasEstadoPersonaEnum;
    /**
     * 
     * @type {string}
     * @memberof GetPersonsResponseListaPersonas
     */
    primerApellido?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonsResponseListaPersonas
     */
    segundoApellido?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonsResponseListaPersonas
     */
    nombrePropio?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonsResponseListaPersonas
     */
    tipoPersona?: GetPersonsResponseListaPersonasTipoPersonaEnum;
    /**
     * 
     * @type {string}
     * @memberof GetPersonsResponseListaPersonas
     */
    codigoExterno?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonsResponseListaPersonas
     */
    telefonoContacto?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum GetPersonsResponseListaPersonasTipoDocumentoEnum {
    C = 'C',
    E = 'E',
    N = 'N',
    P = 'P',
    R = 'R',
    S = 'S',
    X = 'X'
}
/**
    * @export
    * @enum {string}
    */
export enum GetPersonsResponseListaPersonasEstadoPersonaEnum {
    T = 'T',
    A = 'A'
}
/**
    * @export
    * @enum {string}
    */
export enum GetPersonsResponseListaPersonasTipoPersonaEnum {
    F = 'F',
    J = 'J'
}


type getPersonsOperationParams = {
  getPersonsRequest: GetPersonsRequest,
};

/**
 * GetPersonsApi - axios parameter creator
 * @param {Configuration} [configuration]
 * @returns {any}
 */
const GetPersonsApiAxiosParamCreator = function(configuration?: Configuration): any {
  return {
    
    /**
     * Devuelve los datos de la persona
     * @param {GetPersonsRequest} getPersonsRequest 
     * @param {string} [acceptLanguage] El Accept-Language request HTTP header indica en que lenguaje debe responder el backend.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @returns {RequestArgs}
     */
    getPersonsOperation(params: getPersonsOperationParams, options: any = {}): RequestArgs {
      // Verify required parameter 'getPersonsRequest' is not null or undefined
      if (params.getPersonsRequest === null || params.getPersonsRequest === undefined) {
        throw new RequiredError('getPersonsRequest', 'Required parameter getPersonsRequest was null or undefined when calling getPersonsOperation.');
      }
      const localVarPath = `/v1/quoteAndBuy/getPersonsOperation`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true) as Partial<globalImportUrl.UrlWithParsedQuery>;
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;


  
      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
      // Fix override query string Detail: https://stackoverflow.com/a/7517673/1077943

      delete localVarUrlObj.search;
      localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
      const needsSerialization = ('GetPersonsRequest' as any !== 'string') ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization ? JSON.stringify(params.getPersonsRequest || {}) : params.getPersonsRequest || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * GetPersonsApi - functional programming interface
 * @param {Configuration} [configuration]
 * @returns {any}
 */
const GetPersonsApiFp = function(configuration?: Configuration): any {
  return {
    
    /**
     * Devuelve los datos de la persona
     * @param {GetPersonsRequest} getPersonsRequest 
     * @param {string} [acceptLanguage] El Accept-Language request HTTP header indica en que lenguaje debe responder el backend.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @returns {any}
     */
    getPersonsOperation(params: getPersonsOperationParams, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetPersonsResponse> {
      const localVarAxiosArgs = GetPersonsApiAxiosParamCreator(configuration).getPersonsOperation(params, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH): any => {
        const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * GetPersonsApi - factory interface
 * @param {Configuration} configuration
 * @param {string} basePath
 * @param {AxiosInstance} axios
 * @export
 * @returns {any}
 */
const GetPersonsApiFactory = function(configuration?: Configuration, basePath?: string, axios?: AxiosInstance): any {
  return {
    
    /**
     * Devuelve los datos de la persona
     * @param {GetPersonsRequest} getPersonsRequest 
     * @param {string} [acceptLanguage] El Accept-Language request HTTP header indica en que lenguaje debe responder el backend.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @returns {any}
     */
    getPersonsOperation(params: getPersonsOperationParams, options?: any): any {
      return GetPersonsApiFp(configuration).getPersonsOperation(params, options)(axios, basePath);
    },
  };
};

/** 
 * GetPersonsApi - object-oriented interface
 * @export
 * @class GetPersonsApi
 * @extends {BaseAPI}
 */
export class GetPersonsApi extends BaseAPI {
  
  /**
   * Devuelve los datos de la persona
   * @param {GetPersonsRequest} getPersonsRequest 
   * @param {string} [acceptLanguage] El Accept-Language request HTTP header indica en que lenguaje debe responder el backend.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @returns {any}
   * @memberof GetPersonsApi
   */
  public getPersonsOperation(params: getPersonsOperationParams, options?: any): any {
    return GetPersonsApiFp(this.configuration).getPersonsOperation(params, options)(this.axios, this.basePath);
  }

}

/**
 * 
 * @export
 * @class EAgetPersonsOperationInputMessage
 */
export class EAgetPersonsOperationInputMessage {
  
  /**
   * 
   * @type {GetPersonsRequest}
   * @memberof EAgetPersonsOperationInputMessage
   */
  public getPersonsRequest: GetPersonsRequest;

  public constructor(getPersonsRequest: GetPersonsRequest, ){
  
  this.getPersonsRequest=getPersonsRequest;
  } 
}



/** 
 * EAGetPersonsApi - Architecture client for getPersonsOperation
 * @export
 * @class EAGetPersonsApi
 */
export class EAGetPersonsApi {

  /**
   * 
   * @param {GetPersonsRequest} getPersonsRequest
   
   * @param {Configuration} configuration
   * @throws {AsyncRequestError | RequiredError}
   * @returns {Promise<GetPersonsResponse | null>}
  */ 
  public async getPersonsOperation(params: getPersonsOperationParams, configuration: Configuration = {}): Promise<GetPersonsResponse | null> {
    return this.getPersonsOperationPromise(params, configuration);
  }

  /**
   * 
   * @param {GetPersonsRequest} getPersonsRequest
   
   * @param {Configuration} configuration
   * @param {Function} callback
   * @throws {AsyncRequestError | RequiredError}
   */
  public async getPersonsOperationSteps(params: getPersonsOperationParams, configuration: Configuration, callback: (res: AxiosResponse<GetPersonsResponse | null>) => void) {
    let done: boolean = false;
    const httpClient: AxiosInstance = EASDKConfig.getHttpClient('/v1/quoteAndBuy/getPersonsOperation', 'getPersons', 'getPersonsOperation');
    const api = new GetPersonsApi(configuration, '', httpClient);
    const sleep = async(ms: number) => {
      return new Promise(resolve => setTimeout(resolve, ms));
    };
    const headers: { ['Async-Id']?: string } = {};
    while (!done) {
      const output = await api.getPersonsOperation(params, { headers }) as AxiosResponse<GetPersonsResponse | null>;
      callback(output);
      if (output.status === 202) {
        try {
          const retry = parseInt(output.headers['retry']);
          headers['Async-Id'] = output.headers['request-id'];
          if (isNaN(retry) || retry <= 0 || retry > 1000 * 3600) {
            throw new Error(`Retry time not acceptable (in milliseconds): ${retry}`);
          }
          await sleep(retry);
        } catch (err) {
          throw new Error(`Error in Async Request ${err}`);
        }
      } else {
        done = true;
      }
    }
  }

  /**
   * 
   * @param {GetPersonsRequest} getPersonsRequest
   
   * @param {Configuration} configuration
   * @throws {AsyncRequestError | RequiredError}
   * @returns {Promise<GetPersonsResponse | null>}
   */
  public async getPersonsOperationPromise(params: getPersonsOperationParams, configuration: Configuration): Promise<GetPersonsResponse | null> {
    return new Promise((resolve, reject) => {
      this.getPersonsOperationSteps(params, configuration, (response: AxiosResponse<GetPersonsResponse | null>) => {
        if (response.status !== 202) {
          resolve(response.data);
        }
      })
      .catch((err) => {
        reject(err);
      });
    });
  } 
}


