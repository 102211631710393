<template>
  <div>
    <ea-form ref="form" :model="model" :validateOnRuleChange="false" class="m-b-32">
      <ea-form-item
        prop="selectedPhoneNumber"
        :label="$t('common.field.phone')"
        :required="isPhoneRequired && !consultaOperation"
        :class="[model.phoneList.length === 0 ? 'm-b-64' : '']"
      >
        <ea-select
          v-if="model.phoneList.length !== 0"
          :placeholder="$t('common.placeholder.selectPhone')"
          :readonly="consultaOperation"
          v-model="model.selectedPhoneNumber"
          id="email-sel"
          @change="onSelectedPhoneChange"
        >
        <ea-option
          v-for="(indexedPhone, index) in model.indexedPhoneList"
          :key="`${index}-${indexedPhone.phone.numeroTelefono}`"
          :label="indexedPhone.phone.numeroTelefono"
          :value="indexedPhone.phone.numeroTelefono"
        /></ea-select>
      </ea-form-item>
    </ea-form>

    <div v-if="canAddEdit && !consultaOperation">
      <ea-link  @click="openAddEditModal">
        {{ $t('common.link.addEditPhone') }}
      </ea-link>
    </div>

    <add-phone-tomador
      id="add-email-tomador-modal"
      ref="editionModal"
      v-model="model.addPhoneTomadorModel"
      :selectedAddressTitle="$t('addPhoneModal.addedPhones')"
      :addColumnTitle="$t('addPhoneModal.addPhone')"
      :editColumnTitle="$t('addPhoneModal.editPhone')"
      :addLinkText="$t('addPhoneModal.addNewPhone')"
      :emptyResultText="$t('addPhoneModal.noAddedPhones')"
      :documentationClientData="documentationClientData"
      @savePhoneAddress="savePhoneAddress"
      @selectPhoneNumber="selectPhoneNumber"
      @cancel="updateModel"
    ></add-phone-tomador>
  </div>
</template>

<script lang="ts">
import {
  Component, Prop, Watch
} from 'vue-property-decorator';

import {
  mixins
} from 'vue-class-component';

import {
  EABusinessComponent, EAMethod
} from '@zurich-es-npm/ea-front-web-core';
import AddEditPhoneModel, {
  IndexedGetPersonAddressesResponseDomicilios
} from './add-edit-phone-model';
import AddPhoneTomadorBusiness from '../../modals/add-phone-tomador/add-phone-tomador-business.vue';
import {
  GetPersonAddressesResponseDomicilios
} from '@/services/V1/persons/getPersonAddressesOperation/post';
import AddPhoneTomadorModel from '../../modals/add-phone-tomador/add-phone-tomador-model';
import {
  EAValidation, Form
} from '@zurich-es-npm/ea-front-web-ui';
import {
  DocumentationClientData
} from '@/presentational-components/clientBasicInfo/clientBasicInfo.type';

@Component({
  name: 'add-edit-phone',
  components: {
    AddPhoneTomador: AddPhoneTomadorBusiness,
  }
})

/**
 * Business Component add-edit-phone
 */
export default class AddEditPhoneBusiness extends mixins<EABusinessComponent<AddEditPhoneModel>>(EABusinessComponent) {

  @Prop()
    documentationClientData!: DocumentationClientData;

  @Prop({
    required: false,
    'default': () => true,
  })
    canAddEdit?: boolean;

  @Prop({
    required: false,
    'default': () => false,
  })
    isPhoneRequired?: boolean;

  @Prop()
    consultaOperation?: boolean;

  /**
   * Handles add edit address button click
   */
  openAddEditModal() {
    this.openEditionModal();
    this.model.addPhoneTomadorModel = {
      ...this.model.addPhoneTomadorModel,
      selectedPhoneNumber: this.model.selectedPhoneNumber
    };
  }

  /**
   * Handles save phone address event
   * @param {GetPersonAddressesResponseDomicilios} phoneAddressToSave
   */
  savePhoneAddress(phoneAddressToSave: IndexedGetPersonAddressesResponseDomicilios
  ): void {
    if (phoneAddressToSave.secuencialCode && phoneAddressToSave.secuencialCode !== '') {
      this.saveEditedPhoneAddress(phoneAddressToSave);
    } else {
      this.saveNewAddedPhoneAddress(phoneAddressToSave);
    }
  }

  /**
   * Handles save phone address event for editted phone addresses
   * @param {GetPersonAddressesResponseDomicilios | undefined} phoneAddressToSave
   */
  @EAMethod()
  saveEditedPhoneAddress(phoneAddressToSave?: IndexedGetPersonAddressesResponseDomicilios
  ): void {
    if (!phoneAddressToSave || phoneAddressToSave.phoneIndex === undefined) {
      return;
    }

    const updatedPhoneAddress = this.model.phoneList.find(
      phoneAddress => phoneAddress.codigoSecuencialDomicilio === phoneAddressToSave.secuencialCode
    );

    if (updatedPhoneAddress && updatedPhoneAddress.telefonos) {
      updatedPhoneAddress.telefonos[phoneAddressToSave.phoneIndex] = phoneAddressToSave.phone;
      updatedPhoneAddress.indicadorDomicilioPrincipal = '';
      this.model.selectedPhoneNumber = phoneAddressToSave.phone.numeroTelefono;
      this.updateModel();
    }
    
    this.$emit('savePhoneAddress', updatedPhoneAddress);
  }

  /**
   * Handles save phone address event for new phone addresses
   * @param {IndexedGetPersonAddressesResponseDomicilios} phoneAddressToSave
   */
  saveNewAddedPhoneAddress(phoneAddressToSave: IndexedGetPersonAddressesResponseDomicilios
  ): void {
    const newPhoneAddress: GetPersonAddressesResponseDomicilios = {
      codigoFiliacion: this.documentationClientData.clientData?.datosBasicosPersona?.codigoFiliacion,
      codigoSecuencialDomicilio: '',
      tipoDomicilio: 'T',
      telefonos: [
        {
          tipoTelefono: phoneAddressToSave.phone.tipoTelefono,
          prefijoPais: phoneAddressToSave.phone.prefijoPais,
          numeroTelefono: phoneAddressToSave.phone.numeroTelefono
        }
      ]
    };
    this.$emit('savePhoneAddress', newPhoneAddress);
  }

  /**
   * Watcher for phoneList model property
   * - Parses phones to IndexedGetPersonAddressesResponseDomicilios
   *
   */
  @Watch('model.phoneList', {
    deep: true,
    immediate: true
  })
  onPhoneListChange() {
    this.model.indexedPhoneList = [];
    this.model.addPhoneTomadorModel = new AddPhoneTomadorModel();

    this.model.phoneList.forEach(phoneAddress => {
      if (phoneAddress.telefonos) {
        phoneAddress.telefonos.forEach((phone, index) => {
          this.model.indexedPhoneList.push({
            phone,
            secuencialCode: phoneAddress.codigoSecuencialDomicilio || '',
            phoneIndex: index,
          });
        });
      }
    });

    this.model.addPhoneTomadorModel.phoneAddresses = this.model.indexedPhoneList;

    if (!this.model.selectedPhoneNumber || this.model.selectedPhoneNumber === '') {
      if (!this.model.selectedIndexedPhone) {
        this.model.selectedIndexedPhone = this.model.indexedPhoneList[this.model.indexedPhoneList.length - 1];
      }
        
      this.model.selectedPhoneNumber = this.model.selectedIndexedPhone?.phone.numeroTelefono;
      this.onSelectedPhoneChange();
    }
    
  }

  /**
   * Event listener for phone selector
   * Assigns selectedIndexedPhone based on selected phone
   */
  onSelectedPhoneChange() {
    this.$nextTick(() => {
      if (this.model.selectedPhoneNumber) {
        this.model.selectedIndexedPhone = this.model.indexedPhoneList.find(
          indexedPhone => indexedPhone.phone === this.model.selectedPhoneNumber
        );
        this.updateModel();
      }
      
    });
  }

  /**
   * Selected received postal address
   * @param {string} selectedPhoneNumber
   */
  @EAMethod()
  selectPhoneNumber(selectedPhoneNumber: string) {
    this.closeEditionModal();
    this.model.selectedPhoneNumber = selectedPhoneNumber;
    this.onSelectedPhoneChange();
  }

  /**
   * Returns form validation object.
   *
   * @returns {EAValidation} Form validation object
   */
  public validation(): EAValidation {
    const form: Form = this.$refs.form as Form;
    return form.validation();
  }

  /**
   * Updates model and emits event to parent component
   */
  updateModel() {
    this.update();
    this.$nextTick(() => {
      this.$emit('updateViewModel');
    });
  }

  /**
   * Opens edition modal
   */
  openEditionModal() {
    const editionModalComp = this.$refs.editionModal as AddPhoneTomadorBusiness;
    if (editionModalComp) {
      editionModalComp.open();
    }
  }

  /**
   * Closes edition modal
   */
  closeEditionModal() {
    const editionModalComp = this.$refs.editionModal as AddPhoneTomadorBusiness;
    if (editionModalComp) {
      editionModalComp.close();
    }
  }
}
</script>
