/**
 * 
 */
export class QuoteDataView {
  public paymentFormOptions: string[] = [];

  public defaultPaymentFormOption: string = '';
  
  public limitPrizeTarification: number = 0;

  public maintenanceLimitPrizeTarification: number = 0;
}
