import {
  LabelValue, MinMaxValue
} from '@/business-components/qb-producto-asegurado-comunidades/qb-producto-asegurado-comunidades-model';
import {
  GetGeneralDataResponseDataDatosObjeto
} from '@/services/V1/quoteAndBuy/getGeneralDataOperation/post';
import {
  EAValidatorFunction
} from '@zurich-es-npm/ea-front-web-core/lib/validation-rules';
import {
  EAIValidationRule,
  eaRequiredNumberValidation,
  eaCustomValidation,
  eaRequiredValidation,
  eaRangeValidation,
  EAValidationTriggers,
  EAValidationTypes,
} from '@zurich-es-npm/ea-front-web-ui';
import {
  isBoolean
} from 'lodash';
import Utils from './utils';

/**
 * Return element list by code
 * @param {string} code
 * @param {GetGeneralDataResponseDataDatosObjeto[] | undefined} datosObjeto
 * @returns {LabelValue[] | undefined}
 */
export function returnElementListByCode(
  code: string,
  datosObjeto?: GetGeneralDataResponseDataDatosObjeto[]
): LabelValue[] | undefined {
  const foundElem = datosObjeto?.filter(datoObjeto => datoObjeto.codigoElemento === code)[0];

  if (!foundElem) {
    return undefined;
  }

  const result = foundElem.tablaRestricciones?.map(restriccion => {
    return {
      label: restriccion.nombreRestriccion,
      value: restriccion.valorRestriccion,
    };
  });

  if (result) {
    return Utils.sortObjectArrayByProperty(result, 'label');
  }

  return undefined;
}

/**
 * Return element by code
 * @param {string} code
 * @param {GetGeneralDataResponseDataDatosObjeto[]} datosObjeto
 * @returns {GetGeneralDataResponseDataDatosObjeto  | undefined}
 */
export function returnElementByCode(
  code: string,
  datosObjeto?: GetGeneralDataResponseDataDatosObjeto[]
): GetGeneralDataResponseDataDatosObjeto | undefined {
  return datosObjeto ? datosObjeto.find(element => element.codigoElemento === code) : undefined;
}

/**
 * Retrieves element value that can have more than 1 code
 * (eg: zipCode is 'TCCOPOST' for 'Comunidades' product but 'CDPOST' for 'TRC' product)
 * @param {string[]} codes
 * @param {GetGeneralDataResponseDataDatosObjeto[] | undefined} datosObjeto
 * @returns {string}
 */
export function returnElementValueByPossibleCodes(
  codes: string[],
  datosObjeto?: GetGeneralDataResponseDataDatosObjeto[]
): string {
  for (const code of codes) {
    const element = returnElementByCode(code, datosObjeto);
    if (element) {
      return element?.valorElemento ?? '';
    }
  }
  return '';
}

/**
 * Return element value by code
 * @param {string} code
 * @param {GetGeneralDataResponseDataDatosObjeto[]} datosObjeto
 * @returns {string}
 */
export function returnElementValueByCode(code: string, datosObjeto?: GetGeneralDataResponseDataDatosObjeto[]): string {
  const valorElemento = returnElementByCode(code, datosObjeto)?.valorElemento;
  return valorElemento ?? '';
}

/**
 * Returns element max length
 * @param {string} code
 * @param {GetGeneralDataResponseDataDatosObjeto[]} datosObjeto
 * @returns { number | undefined }
 */
export function returnElementLengthByCode(
  code: string,
  datosObjeto?: GetGeneralDataResponseDataDatosObjeto[]
): number | null {
  const element = returnElementByCode(code, datosObjeto);

  if (element) {
    if (code === 'TXDSRIE1') {
      const descriptionEl1 = returnElementByCode('TXDSRIE1', datosObjeto);
      const descriptionEl2 = returnElementByCode('TXDSRIE2', datosObjeto);
      const descriptionEl3 = returnElementByCode('TXDSRIE3', datosObjeto);

      if (descriptionEl1?.longitudElemento && descriptionEl2?.longitudElemento && descriptionEl3?.longitudElemento) {
        return descriptionEl1.longitudElemento + descriptionEl2.longitudElemento + descriptionEl3.longitudElemento;
      }
    }

    return element.longitudElemento ?? null;
  }
  return null;
}

/**
 * Return numeric element value by code
 * @param {string} code
 * @param {GetGeneralDataResponseDataDatosObjeto[]} datosObjeto
 * @returns {number | undefined}
 */
export function returnNumericElementValueByCode(
  code: string,
  datosObjeto?: GetGeneralDataResponseDataDatosObjeto[]
): number | undefined {
  if (isElementNotNaN(code, datosObjeto)) {
    return Number.parseInt(returnElementValueByCode(code, datosObjeto));
  } else {
    return undefined;
  }
}

/**
 * Checks if the element is not NaN
 * @param {string} code
 * @param {GetGeneralDataResponseDataDatosObjeto[]} datosObjeto
 * @returns {boolean}
 */
export function isElementNotNaN(code: string, datosObjeto?: GetGeneralDataResponseDataDatosObjeto[]): boolean {
  return !isNaN(Number.parseInt(returnElementValueByCode(code, datosObjeto)));
}

/**
 * Returns if element is required
 * @param {string} code
 * @param {GetGeneralDataResponseDataDatosObjeto[]} datosObjeto
 * @returns { boolean }
 */
export function isElementRequired(code: string, datosObjeto?: GetGeneralDataResponseDataDatosObjeto[]): boolean {
  const element = returnElementByCode(code, datosObjeto);

  /*
   * If for the object is required but the value of elementoModificable is false
   * set false to no required in the form
   * because the input is readonly
   */
  if (element && isBoolean(element.elementoRequerido)) {
    return element.elementoModificable ? element.elementoRequerido : false;
  }
  
  return true;
}

/**
 * Returns requred element exceptions as not required
 * And not requred element exceptions as required
 * For visual UX/UI purpose
 * @param { string } code 
 * @param { string[] } requiredExceptionList 
 * @param { string[] } notRequiredExceptionList 
 * @param {GetGeneralDataResponseDataDatosObjeto[]} datosObjeto
 * @returns {boolean}
 */
export function isElementRequiredException(
  code: string,
  requiredExceptionList: string[],
  notRequiredExceptionList: string[],
  datosObjeto?: GetGeneralDataResponseDataDatosObjeto[]
): boolean {
  const element = returnElementByCode(code, datosObjeto);

  const requiredException = requiredExceptionList.find(exception => exception === code);
  const notRequiredException = notRequiredExceptionList.find(exception => exception === code);

  if (requiredException) {
    return true;
  } else if (notRequiredException) {
    return false;
  }

  // eslint-disable-next-line no-negated-condition
  return element?.elementoRequerido !== undefined ? element.elementoRequerido : true;
}

/**
 * Returns if element is in range
 * @param {string} code
 * @param {GetGeneralDataResponseDataDatosObjeto[]} datosObjeto
 * @returns { MinMaxValue | undefined }
 */
export function returnMinMaxValues(
  code: string,
  datosObjeto?: GetGeneralDataResponseDataDatosObjeto[]
): MinMaxValue | undefined {
  const element = returnElementByCode(code, datosObjeto);
  if (element) {
    return {
      valorMinimoElemento: element.valorMinimoElemento,
      valorMaximoElemento: element.valorMaximoElemento,
    };
  }
}

/**
 * Validates if the construction year is between the inputed min and max value
 *
 * @returns {EAValidatorFunction}
 * @param {number} min
 * @param {number} max
 */
export function minMaxValidation(min?: number, max?: number): EAValidatorFunction {
  return (_rule: any, value: any, callback: Function) => {
    if (value !== null && (min || max)) {
      if (min !== undefined && value < min) {
        return callback(new Error());
      }
      if (max !== undefined && value > max) {
        return callback(new Error());
      }
    }

    return callback();
  };
}

/**
 * Gets field rules
 * @param { string } type - field type (numeric, string, etc.)
 * @param { string } label - field object label
 * @param {GetGeneralDataResponseDataDatosObjeto[]} datosObjeto
 * @return { EAIValidationRule[] } rules for object
 */
export function getFieldRules(
  type: string,
  label: string,
  datosObjeto?: GetGeneralDataResponseDataDatosObjeto[]
): EAIValidationRule[] {
  const rules: EAIValidationRule[] = [];
  const FIELD_TYPES = {
    NUMBER: 'number',
    STRING: 'string',
    DEFAULT: 'default',
    DATE: 'date',
  };

  switch (type) {
    case FIELD_TYPES.NUMBER: {
      if (isElementRequired(label, datosObjeto)) {
        rules.push(eaRequiredNumberValidation('common.label.validation.fieldRequired'));
      }

      const minMaxValues = returnMinMaxValues(label, datosObjeto);
      if (minMaxValues) {
        rules.push(
          eaCustomValidation(
            minMaxValidation(minMaxValues.valorMinimoElemento, minMaxValues.valorMaximoElemento),
            'common.label.validation.invalidRange'
          )
        );
      }
      break;
    }
    case FIELD_TYPES.STRING: {
      if (isElementRequired(label, datosObjeto)) {
        rules.push(eaRequiredValidation('common.label.validation.fieldRequired'));
      }

      const ruleMaxLength = returnElementLengthByCode(label, datosObjeto);
      if (ruleMaxLength) {
        rules.push(eaRangeValidation(0, ruleMaxLength, 'common.label.validation.invalidLength'));
      }
      break;
    }

    case FIELD_TYPES.DATE: {
      if (isElementModificable(label, datosObjeto)) {
        rules.push(
          eaRequiredValidation(
            'common.label.validation.fieldRequired',
            EAValidationTriggers.BLUR,
            EAValidationTypes.DATE
          ),
          // Needed for trigger validation when date is cleared clicking icon
          eaRequiredValidation(
            'common.label.validation.fieldRequired',
            EAValidationTriggers.CHANGE,
            EAValidationTypes.DATE
          )
        );
      }
      break;
    }

    default:
      if (isElementRequired(label, datosObjeto)) {
        rules.push(eaRequiredValidation('common.label.validation.fieldRequired'));
      }
      return rules;
  }

  return rules;
}

/**
 * Returns if element is modificable
 * @param {string} code
 * @param {GetGeneralDataResponseDataDatosObjeto[]} datosObjeto
 * @returns { boolean }
 */
export function isElementModificable(
  code: string,
  datosObjeto?: GetGeneralDataResponseDataDatosObjeto[]
): boolean {
  const element = returnElementByCode(code, datosObjeto);
  return element && isBoolean(element.elementoModificable) ? element.elementoModificable : true;
}

/**
 * Returns if any of received elements is modificable
 * @param {string[]} codes
 * @param {GetGeneralDataResponseDataDatosObjeto[]} datosObjeto
 * @returns { boolean }
 */
export function isAnyElementModificable(
  codes: string[],
  datosObjeto?: GetGeneralDataResponseDataDatosObjeto[]
): boolean {
  for (const code of codes) {
    if (existsElement(code, datosObjeto) && isElementModificable(code, datosObjeto)) {
      return true;
    }
  }
  return false;
}

/**
 * Checks if element exists in received datosObjeto array
 * @param {string} code 
 * @param {GetGeneralDataResponseDataDatosObjeto[] | undefined} datosObjeto 
 * @returns {boolean}
 */
export function existsElement(
  code: string, datosObjeto?: GetGeneralDataResponseDataDatosObjeto[]
): boolean {
  const element = returnElementByCode(code, datosObjeto);
  return !!element;
}

/**
 * Checks if any of received elements exists in received datosObjeto array
 * @param {string[]} codes
 * @param {GetGeneralDataResponseDataDatosObjeto[] | undefined} datosObjeto 
 * @returns {boolean}
 */
export function existAnyElement(
  codes: string[], datosObjeto?: GetGeneralDataResponseDataDatosObjeto[]
): boolean {
  for (const code of codes) {
    if (existsElement(code, datosObjeto)) {
      return true;
    }
  }
  return false;
}

/**
 * Returns true if the element value is an empty string
 * @param {string} code
 * @param {GetGeneralDataResponseDataDatosObjeto[] | undefined} datosObjeto
 * @returns {boolean}
 */
export function elementValueIsAnEmptyString(
  code: string,
  datosObjeto?: GetGeneralDataResponseDataDatosObjeto[]): boolean {
  if (datosObjeto) {
    const elementCode = datosObjeto.find(element => element.codigoElemento === code);
    return elementCode?.valorElemento === '';
  } else {
    return false;
  }
}

  
/**
 * Returns if element is printable
 * @param {array} codes
 * @param {GetGeneralDataResponseDataDatosObjeto[]} datosObjeto
 * @param {boolean} consultaOperation
 * @returns { boolean }
 */
export function isElementPrintable(
  codes: string[],
  datosObjeto: GetGeneralDataResponseDataDatosObjeto[],
  consultaOperation?: boolean): boolean {
  if (consultaOperation) {
    return isElementPrintableQuery(codes, datosObjeto);
  }
  return isElementPrintablePN(codes, datosObjeto);
      
}

/**
 * Returns if element is printable Nueva Producción
 * @param {array} codes
 * @param {GetGeneralDataResponseDataDatosObjeto[]} datosObjeto
 * @returns { boolean }
 */
export function isElementPrintablePN(
  codes: string[], datosObjeto: GetGeneralDataResponseDataDatosObjeto[]
): boolean {
  for (const code of codes) {
    const element = returnElementByCode(code, datosObjeto);
    if (element && isBoolean(element.elementoModificable)) {
      return true;
    }
  }
  return false;
}

  
/**
 * Returns if element is printable query
 * @param {array} codes
 * @param {GetGeneralDataResponseDataDatosObjeto[]} datosObjeto
 * @returns { boolean }
 */
export function isElementPrintableQuery(
  codes: string[], datosObjeto: GetGeneralDataResponseDataDatosObjeto[]
): boolean {
  let visible = true;
  for (const code of codes) {
    const element = returnElementByCode(code, datosObjeto);
    visible = element?.valorElemento !== undefined;
  }
  return visible;
}
