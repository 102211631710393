<template>
  <div>
    <ea-card>
      <div slot="cardbody" class="p-a-24 cardbody">
        <qb-risk-situation
          id="qb-risk-situation"
          ref="riskSituationBC"
          v-model="model.riskSituation"
          :datosObjeto="datosObjeto"
          :consultaOperation="consultaOperation"
        ></qb-risk-situation>

        <qb-technical-considerations
          id="qb-technical-considerations"
          ref="technicalConsiderationsBC"
          v-model="model.technicalConsiderations"
          :datosObjeto="datosObjeto"
          :consultaOperation="consultaOperation"
          @updateDates="onUpdateDates"
        ></qb-technical-considerations>
      </div>
    </ea-card>
  </div>
</template>

<script lang="ts">
import {
  Component, Prop
} from 'vue-property-decorator';

import {
  mixins
} from 'vue-class-component';

import {
  EABusinessComponent
} from '@zurich-es-npm/ea-front-web-core';
import {
  FormValues
} from '../qb-technical-considerations/qb-technical-considerations-model';

import QbProductoAseguradoTrConstruccionMontajeModel from './qb-producto-asegurado-tr-construccion-montaje-model';
import QbTechnicalConsiderationsBusiness from '../qb-technical-considerations/qb-technical-considerations-business.vue';
import QbRiskSituationBusiness from '../qb-risk-situation/qb-risk-situation-business.vue';
import {
  GetGeneralDataResponseDataDatosObjeto
} from '@/services/V1/quoteAndBuy/getGeneralDataOperation/post';
import {
  EAValidation, EACompositeValidation
} from '@zurich-es-npm/ea-front-web-ui';

@Component({
  name: 'qb-producto-asegurado-tr-construccion-montaje',
  components: {
    QbRiskSituation: QbRiskSituationBusiness,
    QbTechnicalConsiderations: QbTechnicalConsiderationsBusiness
  }
})

/**
 * Business Component qb-producto-asegurado-tr-construccion-montaje
 */
export default class QbProductoAseguradoTrConstruccionMontajeBusiness extends mixins<
EABusinessComponent<QbProductoAseguradoTrConstruccionMontajeModel>
>(EABusinessComponent) {

  @Prop({
    required: true
  })
    datosObjeto!: GetGeneralDataResponseDataDatosObjeto[];

  @Prop({
    'default': () => false
  })
    consultaOperation?: boolean;

  /**
   * Hook on created.
   */
  created() {
    this.$nextTick(() => {
      const comp = this.$refs.riskSituationBC as QbRiskSituationBusiness;
      comp.setFormValues();
      this.update();
    });
  }

  /**
   * Returns validation object
   * @returns {EAValidation }
   */
  public validation(): EAValidation {
    const riskSituationBC: QbRiskSituationBusiness = this.$refs.riskSituationBC as QbRiskSituationBusiness;
    riskSituationBC.updateAllData();
    const technicalConsiderationsBC = this.$refs.technicalConsiderationsBC as QbTechnicalConsiderationsBusiness;
    technicalConsiderationsBC.update();

    return new EACompositeValidation([
      riskSituationBC.validation(), technicalConsiderationsBC.validation()
    ], this.$tc.bind(this));
  }

  /**
   * Updates zip and current model
   */
  public updateAllData() {
    this.updateChildBusinessComponents();
    this.update();
  }

  /**
   * Updates child business components
   */
  public updateChildBusinessComponents() {
    const riskSituationBC: QbRiskSituationBusiness = this.$refs.riskSituationBC as QbRiskSituationBusiness;
    riskSituationBC.updateAllData();
    const technicalConsiderationsBC = this.$refs.technicalConsiderationsBC as QbTechnicalConsiderationsBusiness;
    technicalConsiderationsBC.update();
  }

  /**
   * Updates date from general data info with the dates from qb-technical-considerations for trc
   * @param {FormValues} formValues
   */
  public onUpdateDates(formValues: FormValues) {
    this.$emit('updateDates', formValues);
  }
}
</script>
