
/** 
 * Model qb-search-plate-number
 */
class QbSearchPlateNumberModel {
  
  public plateNumberType: string = '';

  public plateNumber: string = '';

}

export default QbSearchPlateNumberModel;
