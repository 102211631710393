import {
  EAFlowModel
} from '@zurich-es-npm/ea-front-web-core';
import QbSearcherModel from '@/business-components/qb-searcher/qb-searcher-model';
import QbGenericErrorModel from '@/business-components/qb-generic-error/qb-generic-error-model';

export enum FlowViewsStepsModel {
  SearchPolicyStep = 0
}

export const DEFAULT_PAGE_SIZE = 50;

export interface OperationsData {
  codProductoTe: string;
  codProductoCo: string;
  isThunder: string;
}

/**
 * Flow model
 *
 * @export
 * @class SearchPolicyModel
 * @extends {EAFlowModel}
 */
export class SearchPolicyModel extends EAFlowModel {
  public searcherModel = new QbSearcherModel();

  public genericErrorModel: QbGenericErrorModel = new QbGenericErrorModel();
}
