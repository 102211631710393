import QbObservationsAuthorizationModel
  from '@/business-components/qb-observations-authorization/qb-observations-authorization-model';
import {
  EAFlowModel
} from '@zurich-es-npm/ea-front-web-core';
import {
  PolicyType
} from '@/types/policy-types/policy-types-enum.types';
import CompetencesExtraDataEmployeeModel
  from '@/business-components/competences-extra-data-employee/competences-extra-data-employee-model';
import {
  ZZNotification
} from '@/utils/notifications/notifications-utils';
import CompetencesDataModel from '@/business-components/competences-data/competences-data-model';

export interface InputModel {
  codigoPoliza: string;
  versionPoliza: number;
  tipoPoliza: PolicyType;
}

export interface WorksCenters {
  codigoCentroTrabajo?: string;
  codigoCentroTramitador?: string;
  descripcionCentroTrabajo?: string;
  codigosCentro?: string;
}

/**
 * Flow model
 *
 * @export
 * @class DocumentationModel
 * @extends {EAFlowModel}
 */
export class CompetencesModel extends EAFlowModel {
  public policyNumber: string = '';

  public policyVersion: number = 0;

  public typePolicy: PolicyType =
    PolicyType.Incompleta;

  public notifications: ZZNotification[] = [];

  public observations: QbObservationsAuthorizationModel = new QbObservationsAuthorizationModel();

  public extraDataEmployee: CompetencesExtraDataEmployeeModel = new CompetencesExtraDataEmployeeModel();

  public competences: CompetencesDataModel = new CompetencesDataModel();
}
