export enum stepStatus{
  'wait',
  'process',
  'error',
  'success'
}

/** 
 * Model qb-header
 */
class QbHeaderModel {
}

export default QbHeaderModel;
