<template>
  <div>
    <ea-flow-container>
      <div>
        <ea-row extraClass="d-align-items-center d-display-flex d-justify-space-between">
          <ea-col :span="21">
            <qb-header
              id="fleets-qb-header"
              :key="qbHeaderKey"
              v-model="undefined"
              :title="$t('fleets.fleetsQueryPolicyFlow.title')"
              :subtitle="''"
              :showSteps="false"
              :steps="[]"
              :activeStep="0"
              :offerNumber="model.offerNumber"
              :offerVersion="model.offerVersion"
              :showCreateNoteButton="false"
              :showQueryNoteButton="false"
              :showDocumentationButton="true"
            />
          </ea-col>
          <ea-col :span="3">
            <ea-tooltip
              placement="top"
              class="m-l-8 t-align-right"
              :content="$t('quoteBuyGenericFlow.header.createConsultDocuments')">
              <ea-link @click.native="onOpenSubflow">
                {{$t('quoteBuyGenericFlow.header.documents')}}
              </ea-link>
            </ea-tooltip>
          </ea-col>
        </ea-row>
        <ea-row>
          <ea-col :span="24">
            <div v-if="navigation.step === 0">
              <fleets-query-policy
                v-if="!loadingData"
                v-model="model"
                :addressTypesList="addressTypesList"
                :documentTypeList="documentTypeList"
                @openSubflow="onOpenSubflow"
                @goToPreviousFlow="goToPreviousFlow"
              />
            </div>
          </ea-col>
        </ea-row>
      </div>
    </ea-flow-container>
  </div>
</template>

<script lang="ts">
import {
  Component
} from 'vue-property-decorator';
import {
  mixins
} from 'vue-class-component';
import {
  EAFlow,
  EAFlowNavigation,
  EAMethod,
  ResponseWithErrors,
  throwIfResponseHasErrors
} from '@zurich-es-npm/ea-front-web-core';
import FleetsQueryPolicyView from './views/fleets-query-policy-view.vue';
import {
  FleetsQueryPolicyModel
} from './fleets-query-policy-model';
import FlowModelFillUtils, {
  VehicleListOperationEnum
} from '../fleets/utils/flow-model-fill.utils';
import {
  InputModel
} from '../fleets/fleets-model';
import QbHeaderBusiness from '@/business-components/qb-header/qb-header-business.vue';
import {
  CorpTableData, CorpTableNames, ParsedTableData, fetchCorporateTable, parseCorpTableDocuments
} from '@/utils/corporate-tables';
import {
  FlowThunderNameEnum,
  SubflowThunderNameEnum
} from '@/types/flow/flow-enum.types';
import {
  OpenSubflowEventData
} from '@/utils/open-new-flow/open-new-flow-utils';
import {
  EAGetVehicleUsesApi
} from '@/services/V1/fleets/getVehicleUsesOperation/post';
import {
  uniqBy
} from 'lodash';
import {
  ConfigFlowUtils
} from '@/utils/config-flow-utils';
import {
  NewFleetFlowConfig
} from '../fleets/model/new-fleet-flow-config-model';


@Component({
  components: {
    'fleets-query-policy': FleetsQueryPolicyView,
    QbHeader: QbHeaderBusiness
  }
})

/** 
 * Fleets-query-policy flow
 * 
 */
export default class FleetsQueryPolicyFlow extends mixins<EAFlow<FleetsQueryPolicyModel>>(EAFlow) {

  loadingData = false;

  getVehicleUsesApi = new EAGetVehicleUsesApi();

  public addressTypesList: ParsedTableData[] = [];

  public documentTypeList: ParsedTableData[] = [];

  /** 
   * Initialize flow model
   */
  constructor() {
    super();
    this.model = new FleetsQueryPolicyModel();
    
    this.navigation = new EAFlowNavigation();
    this.navigation.maxWindows = 1;
  }

  /** 
   * Created lifecycle hook
   */
  @EAMethod({
    loading: true,
  })
  async created() {
    try {
      this.model.flowConfiguration =
        await ConfigFlowUtils.getConfigFlowByName(FlowThunderNameEnum.FleetMaintenancePolicy) as NewFleetFlowConfig;
      
    } catch (err) {
      throwIfResponseHasErrors(err as ResponseWithErrors);
    }

    this.loadingData = true;
    const {
      codigoPoliza, versionPoliza, hasParentFlow
    }: InputModel = this.inputModel;
    
    this.model.offerNumber = codigoPoliza;
    this.model.offerVersion = versionPoliza;
    this.model.hasParentFlow = hasParentFlow;
    const indicadorCompromisoContrato = true;

    const results = await Promise.all([
      FlowModelFillUtils.loadFleetData(this.model, indicadorCompromisoContrato),
      FlowModelFillUtils.loadVehicleList(this.model, VehicleListOperationEnum.Emitidos),
      fetchCorporateTable(CorpTableNames.AddressType),
      fetchCorporateTable(CorpTableNames.DocumentTypes),
      fetchCorporateTable(CorpTableNames.PolicyEmitedStatus),
      this.getAllVehicleUses()
    ]);

    this.addressTypesList = parseCorpTableDocuments(results[2] as CorpTableData);
    this.documentTypeList = parseCorpTableDocuments(results[3] as CorpTableData);
    this.model.policyEmitedStatusList = parseCorpTableDocuments(results[4] as CorpTableData);

    this.loadingData = false;
  }
  
  /**
   *
   */
  async getAllVehicleUses(): Promise<void> {
    const vehicleUsesResponse = await this.getVehicleUsesApi.getVehicleUsesOperation({});

    throwIfResponseHasErrors(vehicleUsesResponse as ResponseWithErrors);

    // Map documents with only relevant information
    if (vehicleUsesResponse?.resultadosUsos) {
      const mappedVehicleUses = vehicleUsesResponse.resultadosUsos.map(use => {
        return {
          name: use.descripcionElemento,
          value: use.codigoUsoPorMatricula,
          plateType: use.tipoMatricula,
  
          vehicleUseCode: use.codigoUsoVehiculo,
          plateUseCode: use.codigoUsoPorMatricula,
          vehicleType: use.tipoVehiculo
        };
      });
      this.model.parsedVehicleUses = uniqBy(mappedVehicleUses, 'value');
    }
  }

  /**
   * Método que abre un subflujo
   * @param {OpenSubflowEventData} data 
   */
  @EAMethod()
  onOpenSubflow(data: OpenSubflowEventData) {
    // Vehicle policy documentation
    if (data.name === SubflowThunderNameEnum.DocumentationFlow) {
      const inputChildModel = {
        codigoPoliza: data.codigoPoliza,
        versionPoliza: data.versionPoliza,
        tipoPoliza: data.tipoPoliza,
        allowAttachments: false
      };
  
      this.goToFlow(SubflowThunderNameEnum.DocumentationFlow, inputChildModel);
    } else {
      // Fleet policy documentation
      const inputChildModel = {
        codigoPoliza: this.model.offerNumber,
        versionPoliza: this.model.offerVersion,
        tipoPoliza: this.model.offerType,
        allowAttachments: false,
        isFleet: true
      };

      this.goToFlow(SubflowThunderNameEnum.DocumentationFlow, inputChildModel);
    }
  }

  /**
   * Opens previous flow (ie: FleetsSearchOfferFlow...)
   */
  @EAMethod({
    loading: true
  })
  goToPreviousFlow() {
    this.returnToParentFlow(undefined);
  }


}
</script>
