<template>
  <ea-dialog
    :visible="!model.hidden"
    :beforeClose="onCancel"
    size="small"
    :title="$t('mainAddressChangeModal.title')"
  >
    <p class="m-b-40"> {{ $t('mainAddressChangeModal.description') }} </p>

    <!-- Cancel and save button -->
    <div class="d-display-flex d-justify-flex-end">
      <ea-button type="secondary" @click="onCancel()" class="m-r-16">
        {{ $t('common.label.cancel') }}
      </ea-button>
      <ea-button type="primary" @click="onAccept()">
        {{ $t('common.label.accept') }}
      </ea-button>
    </div>
  </ea-dialog>
</template>

<script lang="ts">
import {
  Component
} from 'vue-property-decorator';
import {
  mixins
} from 'vue-class-component';
import {
  EABusinessComponent
} from '@zurich-es-npm/ea-front-web-core';
import MainAddressChangeModel from './main-address-change-model';

@Component({
  name: 'main-address-change',
})

/**
 * Business Component add-address-tomador
 */
export default class MainAddressChangeBusiness extends mixins<EABusinessComponent<MainAddressChangeModel>>(
  EABusinessComponent
) {

  /**
   * Accept button handler
   */
  onAccept() {
    this.onClose();
  }

  /**
   * Cancel button handler
   */
  onCancel() {
    this.$emit('cancelMainAddressChange');
    this.onClose();
  }

  /**
   * Close model
   */
  onClose() {
    this.model.hidden = true;
    this.update();
  }

}

</script>
