<template>
  <ea-dialog
    :visible="true"
    @close="onCancel"
  >
    <template slot='title'>
      <h3>{{getTitle()}}</h3>

      <ea-col
        v-if="isInvalidLicenseDate">
        <ea-alert
          type="error"
          :title="$t('quoteBuyGenericFlow.searchPerson.validation.invalidLicenseDate')"
          class="m-b-16"/>
      </ea-col>

      <ea-row>
        <ea-col :span="6">
          <ea-paragraph
          size="medium"
          class="m-b-16 m-t-20 t-weight-semibold">
            {{$t('fleets.fleetsFlow.fleetsInterveningDriverTable.tableDataHeaders.plateNumOnly')}}
          </ea-paragraph>
          <ea-paragraph
            size="medium"
            class="m-b-44">
              {{vehicleData.plateNumber}}
          </ea-paragraph>
        </ea-col>

        <ea-col :span="6">
          <ea-paragraph
            size="medium"
            class="m-b-16 m-t-20 t-weight-semibold">
              {{$t('fleets.fleetsFlow.fleetsInterveningDriverTable.tableDataHeaders.brand')}}
          </ea-paragraph>
          <ea-paragraph
            size="medium"
            class="m-b-44">
              {{vehicleData.vehicleBrand}}
          </ea-paragraph>
        </ea-col>

        <ea-col :span="6">
          <ea-paragraph
            size="medium"
            class="m-b-16 m-t-20 t-weight-semibold">
              {{$t('fleets.fleetsFlow.fleetsInterveningDriverTable.tableDataHeaders.model')}}
          </ea-paragraph>
          <ea-paragraph
            size="medium"
            class="m-b-44">
              {{vehicleData.vehicleModel}}
          </ea-paragraph>
        </ea-col>

        <ea-col :span="6">
          <ea-paragraph
            size="medium"
            class="m-b-16 m-t-20 t-weight-semibold">
              {{$t('fleets.fleetsFlow.fleetsInterveningDriverTable.tableDataHeaders.version')}}
          </ea-paragraph>
          <ea-paragraph
            size="medium"
            class="m-b-44">
              {{vehicleData.vehicleVersion}}
          </ea-paragraph>
        </ea-col>
      </ea-row>
    </template>

    <div>
      <ea-form
        ref="form"
        v-if="addEditPersonForm"
        :model="addEditPersonForm"
        :validationOptions="addEditPersonValidation"
        :validateOnRuleChange="false"
      >
        <ea-row>
          <ea-col :span="6">
            <ea-form-item
              :label="$t('quoteBuyGenericFlow.searchPerson.field.documentType')"
              prop="documentType"
            >
              <ea-select
                v-model="addEditPersonForm.documentType"
                :placeholder="$t('common.label.select')"
              >
                <ea-option
                  v-for="documentType in documentTypeList"
                  :key="documentType.value"
                  :label="documentType.label"
                  :value="documentType.value"
                />
              </ea-select>
            </ea-form-item>
          </ea-col>

          <ea-col :span="6">
            <ea-form-item
              :label="$t('quoteBuyGenericFlow.searchPerson.field.documentNumber')"
              prop="documentNumber"
            >
              <ea-input-text v-model="addEditPersonForm.documentNumber" @change="onDocumentNumberChange" />
            </ea-form-item>
          </ea-col>
        </ea-row>

        <ea-row>
          <ea-col :span="6">
            <ea-form-item :label="$t('quoteBuyGenericFlow.searchPerson.field.firstName')" prop="firstName" required>
              <ea-input-text v-model="addEditPersonForm.firstName" />
            </ea-form-item>
          </ea-col>

          <ea-col :span="6">
            <ea-form-item :label="$t('quoteBuyGenericFlow.searchPerson.field.lastName')" prop="lastName" required>
              <ea-input-text v-model="addEditPersonForm.lastName" />
            </ea-form-item>
          </ea-col>

          <ea-col :span="6">
            <ea-form-item
              :label="$t('quoteBuyGenericFlow.searchPerson.field.lastName2')"
              prop="lastName2"
              :required="isLastName2Required() ? true : false"
            >
            <ea-input-text v-model="addEditPersonForm.lastName2" />
            </ea-form-item>
          </ea-col>
        </ea-row>

        <ea-row>
          <ea-col :span="6">
            <ea-form-item
              prop="gender"
              :label="$t('quoteBuyGenericFlow.searchPerson.field.gender.gender')"
              required
            >
              <ea-radio-group v-model="addEditPersonForm.gender" class="m-t-16"
              >
                <ea-radio label="V">
                  {{$t('quoteBuyGenericFlow.searchPerson.field.gender.male')}}
                </ea-radio>
                <ea-radio label="M">
                  {{$t('quoteBuyGenericFlow.searchPerson.field.gender.female')}}
                </ea-radio>
              </ea-radio-group>
            </ea-form-item>
          </ea-col>

          <ea-col :span="6">
            <ea-form-item :label="$t('quoteBuyGenericFlow.searchPerson.field.birthDate')" prop="birthDate" required>
              <ea-date-picker
                v-model="addEditPersonForm.birthDate"
                :placeholder="$t('common.label.dateSelect')"
                :pickerOptions="datepickerOptionsLegaleAge"
                @change="isInvalidLicenseDate=false"
              ></ea-date-picker>
            </ea-form-item>
          </ea-col>

          <ea-col :span="6">
            <ea-form-item
              :label="$t('quoteBuyGenericFlow.searchPerson.field.residenceCountry')"
              prop="country"
              :required="!disableCountry">
              <ea-select
                v-model="addEditPersonForm.country"
                :placeholder="$t('common.label.select')"
                :readonly="disableCountry"
              >
                <ea-option
                  v-for="country in countryList"
                  :key="country.value"
                  :label="country.label"
                  :value="country.value"
                />
              </ea-select>
            </ea-form-item>
          </ea-col>
        </ea-row>

        <ea-row>
          <ea-col :span="6">
            <ea-form-item
              :label="$t('quoteBuyGenericFlow.searchPerson.field.driverLisenceDate')"
              prop="driverLisenceDate"
              required>
              <ea-date-picker
                v-model="addEditPersonForm.driverLisenceDate"
                :placeholder="$t('common.label.dateSelect')"
                :pickerOptions="datepickerOptionsLicenseDate"
                :disabled="!addEditPersonForm.birthDate"
                @change="isInvalidLicenseDate=false"
              ></ea-date-picker>
            </ea-form-item>
          </ea-col>

          <ea-col :span="6">
            <ea-form-item
              :label="$t('quoteBuyGenericFlow.searchPerson.field.nationality')"
              prop="nationality"
              :required="!disableNationality">
              <ea-select
                v-model="addEditPersonForm.nationality"
                :placeholder="$t('common.label.select')"
                :readonly="disableNationality"
              >
                <ea-option
                  v-for="country in countryList"
                  :key="country.value"
                  :label="country.label"
                  :value="country.value"
                />
              </ea-select>
            </ea-form-item>
          </ea-col>
        </ea-row>
      </ea-form>

      <div class="d-display-flex d-justify-flex-end">
        <ea-button type="secondary" @click="onCancel()" class="m-r-16">
          {{ $t('common.label.cancel') }}
        </ea-button>
        <ea-button type="primary" @click="onSave()">
          {{ $t('common.label.save') }}
        </ea-button>
      </div>
    </div>
  </ea-dialog>
</template>

<script lang="ts">
import Vue from 'vue';
import {
  Component, Prop, Watch
} from 'vue-property-decorator';

import {
  ParsedTableData
} from '@/utils/corporate-tables';
import {
  EAFormValidationOptions, eaRequiredValidation, EAValidation, EAValidationTriggers, EAValidationTypes, Form
} from '@zurich-es-npm/ea-front-web-ui';
import {
  VehicleFormModel
} from '@/business-components/fleet-manual-upload-vehicle/fleet-manual-upload-vehicle-model';
import {
  AddEditPersonForm
} from '../qb-search-person-model';
import SearchPersonUtils from '../utils/qb-search-person-utils';
import {
  EAErrorManager
} from '@zurich-es-npm/ea-front-web-core';
import moment from 'moment';
import {
  DatePickerOptions
} from 'element-ui/types/date-picker';

@Component({
  name: 'add-edit-driver-person'
})

/**
 * Business Component add-edit-driver-person
 */
export default class AddEditDriverPersonComponent extends Vue {

  @Prop()
    isAdding?: boolean;

  @Prop()
    isEditting?: boolean;

  @Prop()
    addEditPersonForm?: AddEditPersonForm;

  @Prop()
    documentTypeList?: ParsedTableData[];

  @Prop()
    countryList?: ParsedTableData[];

  @Prop()
    vehicleData?: VehicleFormModel;

  @Prop()
    showDocumentInvalidError?: boolean;

  addEditPersonValidation: EAFormValidationOptions = {
    rules: {
      documentType: [eaRequiredValidation('common.label.validation.fieldRequired')],
      documentNumber: [eaRequiredValidation('common.label.validation.fieldRequired')],
      firstName: [eaRequiredValidation('common.label.validation.fieldRequired')],
      lastName: [eaRequiredValidation('common.label.validation.fieldRequired')],
      gender: [eaRequiredValidation('common.label.validation.fieldRequired')],
      birthDate: [eaRequiredValidation(
        'common.label.validation.fieldRequired', EAValidationTriggers.CHANGE, EAValidationTypes.DATE
      )],
      driverLisenceDate: [eaRequiredValidation(
        'common.label.validation.fieldRequired', EAValidationTriggers.CHANGE, EAValidationTypes.DATE
      )],
      nationality: [eaRequiredValidation('common.label.validation.fieldRequired')],
      country: [eaRequiredValidation('common.label.validation.fieldRequired')]
    },
  };

  isInvalidLicenseDate: boolean = false;

  datepickerOptionsLegaleAge: DatePickerOptions = {
    firstDayOfWeek: 1
  };

  datepickerOptionsLicenseDate: DatePickerOptions = {
    firstDayOfWeek: 1
  };

  /**
   * Hook on created
   */
  created() {
    // Set datepicker invalid dates configuration
    this.datepickerOptionsLegaleAge.disabledDate = this._disabledLegalAge;
    this.datepickerOptionsLicenseDate.disabledDate = this._disabledLicenseDate;
  }

  /**
   * Disabled country in case of R
   * @returns {boolean}
   */
  get disableCountry() {
    if (this.addEditPersonForm?.documentType === 'R' && this.isEditting) {
      return true;
    }
    return false;
  }

  /**
   * Disabled nationality in case of N
   * @returns {boolean}
   */
  get disableNationality() {
    if (this.addEditPersonForm?.documentType === 'N' && this.isEditting) {
      return true;
    }
    return false;
  }

  /**
   * Getter for dialog title
   * @returns {string}
   */
  getTitle(): string {
    if (this.isAdding) {
      return this.$t('quoteBuyGenericFlow.searchPerson.addDriver').toString();
    } else {
      return this.$t('quoteBuyGenericFlow.searchPerson.editDriver').toString();
    }
  }

  /**
   * Validates search form before dispatching the save event
   */
  async onSave() {
    EAErrorManager.clearError();

    
    try {
      await this.validation()?.validate();
      if (this._disabledLicenseDate(this.addEditPersonForm?.driverLisenceDate as Date)) {
        this.isInvalidLicenseDate=true;
        return;
      }
      this.$emit('save');
    } catch (err) {
      // Design System will show form errors
    }
  }

  /**
   * Returns validation object
   * @returns {EAValidation}
   */
  public validation(): EAValidation {
    const form = this.$refs.form as Form;
    return form?.validation();
  }

  /**
   * Handle cancel button and dispatch the event.
   */
  onCancel() {
    this.$emit('cancel');
  }

  /**
   * Checks if lastName2 is required in search form
   * @return { boolean } true if it is required (ie: documentType==='N'); false otherwise
   */
  isLastName2Required(): boolean {
    return this.addEditPersonForm?.documentType === 'N';
  }

  /**
   * Changes documentNumber value to uppercase and unsets its error.
   *
   * @returns { void }
   */
  onDocumentNumberChange(): void {
    this.showDocumentInvalidError = false;
    if (this.addEditPersonForm?.documentNumber) {
      this.addEditPersonForm.documentNumber = this.addEditPersonForm.documentNumber.toUpperCase();
    }
  }

  /**
   * Watcher for addEditPersonForm prop
   * @param {AddEditPersonForm} addEditPersonForm
   *
   */
  @Watch('addEditPersonForm')
  onAddEditPersonFormChange() {
    if (!this.addEditPersonValidation) {
      return;
    }

    if (this.isLastName2Required()) {
      this.addEditPersonValidation.rules.lastName2 = [eaRequiredValidation('common.label.validation.fieldRequired')];
    } else {
      this.addEditPersonValidation.rules.lastName2 = [];
    }
  }

  /**
   * Validates document number within the corporate table.
   *
   * @param {any} _rule
   * @param {any} _value
   * @param {Function} callback
   * @return {void} callback() if document number is valid; callback(new Error()) otherwise
   */
  documentNumberCorporateTableValidation(_rule: any, _value: any, callback: Function): void {
    const result = this.showDocumentInvalidError ? callback(new Error()) : callback();
    this.showDocumentInvalidError = false;
    return result;
  }

  /**
   * Update validation rules when user changes document type as it is mandatory for NIF value
   */
  onDocumentTypeChange() {
    // Wait first for model to be updated
    this.$nextTick(() => {
      if (!this.addEditPersonForm || !this.addEditPersonValidation) {
        return;
      }

      this.addEditPersonValidation.rules.documentNumber = SearchPersonUtils.getDocumentNumberValidationRules(
        this.addEditPersonForm.documentType, this.documentNumberCorporateTableValidation
      );

      if (this.isLastName2Required()) {
        this.addEditPersonValidation.rules.lastName2 = [eaRequiredValidation('common.label.validation.fieldRequired')];
      } else {
        this.addEditPersonValidation.rules.lastName2 = [];
      }

      // Clear all fields
      this.addEditPersonForm.documentNumber = '';
      this.addEditPersonForm.firstName = '';
      this.addEditPersonForm.lastName = '';
      this.addEditPersonForm.lastName2 = '';

      const form: Form = this.$refs.form as Form;
      form.clearValidate();
    });
  }

  /**
   * Configuration for disabled birth dates.
   * 
   * @param {Date} date 
   * @returns {boolean}
   */
  _disabledLegalAge(date: Date): boolean {
    // Only enabled dates before 18 years ago.
    const endDate = moment(new Date()).subtract(18, 'years');

    // If date is after endDate, disable this date
    if (date.getTime() > endDate.toDate().getTime()) {
      return true;
    }

    // Any date before 18 years
    return false;
  }

  /**
   * Configuration for disabled birth dates.
   * 
   * @param {Date} date 
   * @returns {boolean}
   */
  _disabledLicenseDate(date: Date): boolean {
    // Only enabled dates before 18 years ago.
    const initDate = moment(this.addEditPersonForm?.birthDate).add(18, 'years');
    const endDate = new Date();

    // If date is after endDate, disable this date
    if (date.getTime() < initDate.toDate().getTime() || date.getTime() > endDate.getTime()) {
      return true;
    }

    // Any date before 18 years
    return false;
  }

  /**
   * Watcher for showDocumentInvalidError prop
   * @param {boolean} showDocumentInvalidError
   *
   */
  @Watch('showDocumentInvalidError')
  onShowDocumentInvalidError() {
    if (this.showDocumentInvalidError) {
      this.validation()
        ?.validate()
        .catch(() => {
          // Form errors will be shown automatically
        });
    }
  }

  
}
</script>
