import {
  LabelValueType
} from './label-value-type-model';

/**
 * 
 */
export class FleetCancelPolicy {
  public movementTypes: LabelValueType[] = [];
}
