/* eslint-disable */

/**
 * zonazurich
 * Portal de Mediación Digital
 *
 * The version of the OpenAPI document: 1
 * Contact: digitalarchitecture@zurich.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import {
  EASDKConfig,
  EAUrlUtils
} from '@zurich-es-npm/ea-shared-utils-js';

import * as globalImportUrl from 'url';
import {
  Configuration
} from './configuration';
import globalAxios, {
  AxiosPromise,
  AxiosInstance,
  AxiosResponse
} from 'axios';

const BASE_PATH = EAUrlUtils.removeLastForwardSlash('http://localhost:3000');

/**
 *
 * @export
 */
export const COLLECTION_FORMATS = {
  csv: ',',
  ssv: ' ',
  tsv: '\t',
  pipes: '|',
};

/**
 *  
 * @export
 * @interface RequestArgs
 */
export interface RequestArgs {
  url: string;
  options: any;
}

/**
 * 
 * @export
 * @class BaseAPI
 */
export class BaseAPI {
  protected configuration: Configuration | undefined;

  /**
   *Creates an instance of BaseAPI.
   * @param {Configuration} [configuration]
   * @param {string} [basePath=BASE_PATH]
   * @param {AxiosInstance} [axios=globalAxios]
   * @memberof BaseAPI
   */
  public constructor(
    configuration?: Configuration,
    protected basePath: string = BASE_PATH,
    protected axios: AxiosInstance = globalAxios) {
    if (configuration) {
      this.configuration = configuration;
      this.basePath = configuration.basePath || this.basePath;
    }
  }
}

/**
 * 
 * @export
 * @class RequiredError
 * @extends {Error}
 */
export class RequiredError extends Error {
  public name: 'RequiredError' = 'RequiredError';

  public field: string;

  /**
   *Creates an instance of RequiredError.
   * @param {string} field
   * @param {string} [msg]
   * @memberof RequiredError
   */
  public constructor(field: string, msg?: string) {
    super(msg);
    this.field=field;
  }
}

/**
 * 
 * @export
 * @class AsyncRequestError
 * @extends {Error}
 */
export class AsyncRequestError extends Error {
  public name: 'AsyncRequestError' = 'AsyncRequestError';

  /**
   *Creates an instance of AsyncRequestError.
   * @param {string} field
   * @param {string} [msg]
   * @memberof AsyncRequestError
   */
  public constructor(msg?: string) {
    super(msg);
  }
}

/**
 * 
 * @export
 * @interface Error
 */
export interface Error {
    /**
     * 
     * @type {string}
     * @memberof Error
     */
    code: string;
    /**
     * 
     * @type {string}
     * @memberof Error
     */
    message: string;
    /**
     * 
     * @type {number}
     * @memberof Error
     */
    severity: number;
    /**
     * 
     * @type {Array<object>}
     * @memberof Error
     */
    childErrors?: Array<object>;
}/**
 * 
 * @export
 * @interface GetFractionalPaymentsRequest
 */
export interface GetFractionalPaymentsRequest {
    /**
     * 
     * @type {string}
     * @memberof GetFractionalPaymentsRequest
     */
    codigoPoliza: string;
    /**
     * 
     * @type {number}
     * @memberof GetFractionalPaymentsRequest
     */
    versionPoliza: number;
    /**
     * 
     * @type {string}
     * @memberof GetFractionalPaymentsRequest
     */
    tipoPoliza: GetFractionalPaymentsRequestTipoPolizaEnum;
    /**
     * 
     * @type {string}
     * @memberof GetFractionalPaymentsRequest
     */
    tipoFormaPagoTarificacionGarantias: GetFractionalPaymentsRequestTipoFormaPagoTarificacionGarantiasEnum;
    /**
     * 
     * @type {Array<GetFractionalPaymentsRequestCodigosPropuesta>}
     * @memberof GetFractionalPaymentsRequest
     */
    codigosPropuesta: Array<GetFractionalPaymentsRequestCodigosPropuesta>;
    /**
     * 
     * @type {string}
     * @memberof GetFractionalPaymentsRequest
     */
    tipoCanalCobro1Recibo?: string;
    /**
     * 
     * @type {string}
     * @memberof GetFractionalPaymentsRequest
     */
    tipoCanalCobroSucesivos?: string;
    /**
     * 
     * @type {string}
     * @memberof GetFractionalPaymentsRequest
     */
    intermediarioCobrador1Recibo?: string;
    /**
     * 
     * @type {string}
     * @memberof GetFractionalPaymentsRequest
     */
    intermediarioCobradorSucesivos?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum GetFractionalPaymentsRequestTipoPolizaEnum {
    Oferta = 'Oferta',
    Pliza = 'Póliza',
    Incompleta = 'Incompleta'
}
/**
    * @export
    * @enum {string}
    */
export enum GetFractionalPaymentsRequestTipoFormaPagoTarificacionGarantiasEnum {
    Todas = 'Todas',
    Anual = 'Anual',
    Semestral = 'Semestral',
    Trimestral = 'Trimestral',
    Mensual = 'Mensual',
    Unica = 'Unica'
}
/**
 * 
 * @export
 * @interface GetFractionalPaymentsRequestCodigosPropuesta
 */
export interface GetFractionalPaymentsRequestCodigosPropuesta {
    /**
     * 
     * @type {string}
     * @memberof GetFractionalPaymentsRequestCodigosPropuesta
     */
    codigoPropuesta?: string;
}/**
 * 
 * @export
 * @interface GetFractionalPaymentsResponse
 */
export interface GetFractionalPaymentsResponse {
    /**
     * 
     * @type {Array<GetFractionalPaymentsResponseDatosTarificacion>}
     * @memberof GetFractionalPaymentsResponse
     */
    datosTarificacion?: Array<GetFractionalPaymentsResponseDatosTarificacion>;
    /**
     * 
     * @type {Array<GetFractionalPaymentsResponseDatosGarantias>}
     * @memberof GetFractionalPaymentsResponse
     */
    datosGarantias?: Array<GetFractionalPaymentsResponseDatosGarantias>;
    /**
     * 
     * @type {Array<GetFractionalPaymentsResponseDatosGestionCompetencias>}
     * @memberof GetFractionalPaymentsResponse
     */
    datosGestionCompetencias?: Array<GetFractionalPaymentsResponseDatosGestionCompetencias>;
    /**
     * 
     * @type {Array<GetFractionalPaymentsResponseDatosAjustesPrimas>}
     * @memberof GetFractionalPaymentsResponse
     */
    datosAjustesPrimas?: Array<GetFractionalPaymentsResponseDatosAjustesPrimas>;
    /**
     * 
     * @type {Array<Error>}
     * @memberof GetFractionalPaymentsResponse
     */
    errors?: Array<Error>;
}/**
 * 
 * @export
 * @interface GetFractionalPaymentsResponseDatosAjustesPrimas
 */
export interface GetFractionalPaymentsResponseDatosAjustesPrimas {
    /**
     * 
     * @type {string}
     * @memberof GetFractionalPaymentsResponseDatosAjustesPrimas
     */
    codigoElemento?: string;
    /**
     * 
     * @type {string}
     * @memberof GetFractionalPaymentsResponseDatosAjustesPrimas
     */
    tablaElemento?: string;
    /**
     * 
     * @type {string}
     * @memberof GetFractionalPaymentsResponseDatosAjustesPrimas
     */
    valorElemento?: string;
    /**
     * 
     * @type {number}
     * @memberof GetFractionalPaymentsResponseDatosAjustesPrimas
     */
    valorMaximoElemento?: number;
    /**
     * 
     * @type {number}
     * @memberof GetFractionalPaymentsResponseDatosAjustesPrimas
     */
    valorMinimoElemento?: number;
    /**
     * 
     * @type {boolean}
     * @memberof GetFractionalPaymentsResponseDatosAjustesPrimas
     */
    elementoRequerido?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetFractionalPaymentsResponseDatosAjustesPrimas
     */
    elementoModificable?: boolean;
    /**
     * 
     * @type {number}
     * @memberof GetFractionalPaymentsResponseDatosAjustesPrimas
     */
    longitudElemento?: number;
    /**
     * 
     * @type {number}
     * @memberof GetFractionalPaymentsResponseDatosAjustesPrimas
     */
    numeroDecimalesElemento?: number;
    /**
     * 
     * @type {Array<GetFractionalPaymentsResponseTablaRestricciones>}
     * @memberof GetFractionalPaymentsResponseDatosAjustesPrimas
     */
    tablaRestricciones?: Array<GetFractionalPaymentsResponseTablaRestricciones>;
}/**
 * 
 * @export
 * @interface GetFractionalPaymentsResponseDatosGarantias
 */
export interface GetFractionalPaymentsResponseDatosGarantias {
    /**
     * 
     * @type {string}
     * @memberof GetFractionalPaymentsResponseDatosGarantias
     */
    codigoGarantia?: string;
    /**
     * 
     * @type {Array<GetFractionalPaymentsResponseGarantiaSeleccionada>}
     * @memberof GetFractionalPaymentsResponseDatosGarantias
     */
    garantiaSeleccionada?: Array<GetFractionalPaymentsResponseGarantiaSeleccionada>;
    /**
     * 
     * @type {Array<GetFractionalPaymentsResponseElementosGarantias>}
     * @memberof GetFractionalPaymentsResponseDatosGarantias
     */
    elementosGarantias?: Array<GetFractionalPaymentsResponseElementosGarantias>;
}/**
 * 
 * @export
 * @interface GetFractionalPaymentsResponseDatosGestionCompetencias
 */
export interface GetFractionalPaymentsResponseDatosGestionCompetencias {
    /**
     * 
     * @type {string}
     * @memberof GetFractionalPaymentsResponseDatosGestionCompetencias
     */
    codigoObjeto?: string;
    /**
     * 
     * @type {string}
     * @memberof GetFractionalPaymentsResponseDatosGestionCompetencias
     */
    codigoOpcion?: string;
    /**
     * 
     * @type {string}
     * @memberof GetFractionalPaymentsResponseDatosGestionCompetencias
     */
    codigoMCT?: string;
    /**
     * 
     * @type {string}
     * @memberof GetFractionalPaymentsResponseDatosGestionCompetencias
     */
    descripcionElemento?: string;
    /**
     * 
     * @type {number}
     * @memberof GetFractionalPaymentsResponseDatosGestionCompetencias
     */
    codigoErrorAutorizacion?: number;
    /**
     * 
     * @type {string}
     * @memberof GetFractionalPaymentsResponseDatosGestionCompetencias
     */
    estadoControlCompetencias?: GetFractionalPaymentsResponseDatosGestionCompetenciasEstadoControlCompetenciasEnum;
    /**
     * 
     * @type {string}
     * @memberof GetFractionalPaymentsResponseDatosGestionCompetencias
     */
    descripcionErrorAutorizacion?: string;
    /**
     * 
     * @type {string}
     * @memberof GetFractionalPaymentsResponseDatosGestionCompetencias
     */
    descripcionEstadoControlCompetencias?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum GetFractionalPaymentsResponseDatosGestionCompetenciasEstadoControlCompetenciasEnum {
    A = 'A',
    P = 'P',
    R = 'R'
}
/**
 * 
 * @export
 * @interface GetFractionalPaymentsResponseDatosPropuestas
 */
export interface GetFractionalPaymentsResponseDatosPropuestas {
    /**
     * 
     * @type {string}
     * @memberof GetFractionalPaymentsResponseDatosPropuestas
     */
    codigoPropuesta?: string;
    /**
     * 
     * @type {string}
     * @memberof GetFractionalPaymentsResponseDatosPropuestas
     */
    valorElemento?: string;
}/**
 * 
 * @export
 * @interface GetFractionalPaymentsResponseDatosTarificacion
 */
export interface GetFractionalPaymentsResponseDatosTarificacion {
    /**
     * 
     * @type {string}
     * @memberof GetFractionalPaymentsResponseDatosTarificacion
     */
    codigoPropuesta?: string;
    /**
     * 
     * @type {number}
     * @memberof GetFractionalPaymentsResponseDatosTarificacion
     */
    importeAcumuladoRecibo?: number;
    /**
     * 
     * @type {number}
     * @memberof GetFractionalPaymentsResponseDatosTarificacion
     */
    importePrimerRecibo?: number;
    /**
     * 
     * @type {number}
     * @memberof GetFractionalPaymentsResponseDatosTarificacion
     */
    importeRecibosSucesivos?: number;
    /**
     * 
     * @type {number}
     * @memberof GetFractionalPaymentsResponseDatosTarificacion
     */
    importeTotalRecibo?: number;
    /**
     * 
     * @type {string}
     * @memberof GetFractionalPaymentsResponseDatosTarificacion
     */
    formaPagoTarificacion?: GetFractionalPaymentsResponseDatosTarificacionFormaPagoTarificacionEnum;
    /**
     * 
     * @type {boolean}
     * @memberof GetFractionalPaymentsResponseDatosTarificacion
     */
    noPrimaMinima?: boolean;
}

/**
    * @export
    * @enum {string}
    */
export enum GetFractionalPaymentsResponseDatosTarificacionFormaPagoTarificacionEnum {
    Anual = 'Anual',
    Unica = 'Unica',
    Semestral = 'Semestral',
    Trimestral = 'Trimestral',
    Mensual = 'Mensual'
}
/**
 * 
 * @export
 * @interface GetFractionalPaymentsResponseElementosGarantias
 */
export interface GetFractionalPaymentsResponseElementosGarantias {
    /**
     * 
     * @type {string}
     * @memberof GetFractionalPaymentsResponseElementosGarantias
     */
    codigoElemento?: string;
    /**
     * 
     * @type {Array<GetFractionalPaymentsResponseDatosPropuestas>}
     * @memberof GetFractionalPaymentsResponseElementosGarantias
     */
    datosPropuestas?: Array<GetFractionalPaymentsResponseDatosPropuestas>;
}/**
 * 
 * @export
 * @interface GetFractionalPaymentsResponseGarantiaSeleccionada
 */
export interface GetFractionalPaymentsResponseGarantiaSeleccionada {
    /**
     * 
     * @type {string}
     * @memberof GetFractionalPaymentsResponseGarantiaSeleccionada
     */
    codigoPropuesta?: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetFractionalPaymentsResponseGarantiaSeleccionada
     */
    garantiaSeleccionada?: boolean;
}/**
 * 
 * @export
 * @interface GetFractionalPaymentsResponseTablaRestricciones
 */
export interface GetFractionalPaymentsResponseTablaRestricciones {
    /**
     * 
     * @type {string}
     * @memberof GetFractionalPaymentsResponseTablaRestricciones
     */
    nombreRestriccion?: string;
    /**
     * 
     * @type {string}
     * @memberof GetFractionalPaymentsResponseTablaRestricciones
     */
    valorRestriccion?: string;
}

type getFractionalPaymentsOperationParams = {
  getFractionalPaymentsRequest: GetFractionalPaymentsRequest,
};

/**
 * GetFractionalPaymentsApi - axios parameter creator
 * @param {Configuration} [configuration]
 * @returns {any}
 */
const GetFractionalPaymentsApiAxiosParamCreator = function(configuration?: Configuration): any {
  return {
    
    /**
     * Tarifica las formas de pago fraccionadas. Si se especifica una forma de pago concreta, guarda información acerca del canal de cobro e intermediario cobrador.
     * @param {GetFractionalPaymentsRequest} getFractionalPaymentsRequest 
     * @param {string} [acceptLanguage] El Accept-Language request HTTP header indica en que lenguaje debe responder el backend.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @returns {RequestArgs}
     */
    getFractionalPaymentsOperation(params: getFractionalPaymentsOperationParams, options: any = {}): RequestArgs {
      // Verify required parameter 'getFractionalPaymentsRequest' is not null or undefined
      if (params.getFractionalPaymentsRequest === null || params.getFractionalPaymentsRequest === undefined) {
        throw new RequiredError('getFractionalPaymentsRequest', 'Required parameter getFractionalPaymentsRequest was null or undefined when calling getFractionalPaymentsOperation.');
      }
      const localVarPath = `/v1/quoteAndBuy/getFractionalPaymentsOperation`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true) as Partial<globalImportUrl.UrlWithParsedQuery>;
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;


  
      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
      // Fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
      const needsSerialization = ('GetFractionalPaymentsRequest' as any !== 'string') ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization ? JSON.stringify(params.getFractionalPaymentsRequest || {}) : params.getFractionalPaymentsRequest || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * GetFractionalPaymentsApi - functional programming interface
 * @param {Configuration} [configuration]
 * @returns {any}
 */
const GetFractionalPaymentsApiFp = function(configuration?: Configuration): any {
  return {
    
    /**
     * Tarifica las formas de pago fraccionadas. Si se especifica una forma de pago concreta, guarda información acerca del canal de cobro e intermediario cobrador.
     * @param {GetFractionalPaymentsRequest} getFractionalPaymentsRequest 
     * @param {string} [acceptLanguage] El Accept-Language request HTTP header indica en que lenguaje debe responder el backend.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @returns {any}
     */
    getFractionalPaymentsOperation(params: getFractionalPaymentsOperationParams, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetFractionalPaymentsResponse> {
      const localVarAxiosArgs = GetFractionalPaymentsApiAxiosParamCreator(configuration).getFractionalPaymentsOperation(params, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH): any => {
        const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * GetFractionalPaymentsApi - factory interface
 * @param {Configuration} configuration
 * @param {string} basePath
 * @param {AxiosInstance} axios
 * @export
 * @returns {any}
 */
const GetFractionalPaymentsApiFactory = function(configuration?: Configuration, basePath?: string, axios?: AxiosInstance): any {
  return {
    
    /**
     * Tarifica las formas de pago fraccionadas. Si se especifica una forma de pago concreta, guarda información acerca del canal de cobro e intermediario cobrador.
     * @param {GetFractionalPaymentsRequest} getFractionalPaymentsRequest 
     * @param {string} [acceptLanguage] El Accept-Language request HTTP header indica en que lenguaje debe responder el backend.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @returns {any}
     */
    getFractionalPaymentsOperation(params: getFractionalPaymentsOperationParams, options?: any): any {
      return GetFractionalPaymentsApiFp(configuration).getFractionalPaymentsOperation(params, options)(axios, basePath);
    },
  };
};

/** 
 * GetFractionalPaymentsApi - object-oriented interface
 * @export
 * @class GetFractionalPaymentsApi
 * @extends {BaseAPI}
 */
export class GetFractionalPaymentsApi extends BaseAPI {
  
  /**
   * Tarifica las formas de pago fraccionadas. Si se especifica una forma de pago concreta, guarda información acerca del canal de cobro e intermediario cobrador.
   * @param {GetFractionalPaymentsRequest} getFractionalPaymentsRequest 
   * @param {string} [acceptLanguage] El Accept-Language request HTTP header indica en que lenguaje debe responder el backend.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @returns {any}
   * @memberof GetFractionalPaymentsApi
   */
  public getFractionalPaymentsOperation(params: getFractionalPaymentsOperationParams, options?: any): any {
    return GetFractionalPaymentsApiFp(this.configuration).getFractionalPaymentsOperation(params, options)(this.axios, this.basePath);
  }

}

/**
 * 
 * @export
 * @class EAgetFractionalPaymentsOperationInputMessage
 */
export class EAgetFractionalPaymentsOperationInputMessage {
  
  /**
   * 
   * @type {GetFractionalPaymentsRequest}
   * @memberof EAgetFractionalPaymentsOperationInputMessage
   */
  public getFractionalPaymentsRequest: GetFractionalPaymentsRequest;

  public constructor(getFractionalPaymentsRequest: GetFractionalPaymentsRequest, ){
  
  this.getFractionalPaymentsRequest=getFractionalPaymentsRequest;
  } 
}



/** 
 * EAGetFractionalPaymentsApi - Architecture client for getFractionalPaymentsOperation
 * @export
 * @class EAGetFractionalPaymentsApi
 */
export class EAGetFractionalPaymentsApi {

  /**
   * 
   * @param {GetFractionalPaymentsRequest} getFractionalPaymentsRequest
   
   * @throws {AsyncRequestError | RequiredError}
   * @returns {Promise<GetFractionalPaymentsResponse | null>}
  */ 
  public async getFractionalPaymentsOperation(params: getFractionalPaymentsOperationParams): Promise<GetFractionalPaymentsResponse | null> {
    return this.getFractionalPaymentsOperationPromise(params);
  }

  /**
   * 
   * @param {GetFractionalPaymentsRequest} getFractionalPaymentsRequest
   
   * @param {Function} callback
   * @throws {AsyncRequestError | RequiredError}
   */
  public async getFractionalPaymentsOperationSteps(params: getFractionalPaymentsOperationParams, callback: (res: AxiosResponse<GetFractionalPaymentsResponse | null>) => void) {
    let done: boolean = false;
    const httpClient: AxiosInstance = EASDKConfig.getHttpClient('/v1/quoteAndBuy/getFractionalPaymentsOperation', 'getFractionalPayments', 'getFractionalPaymentsOperation');
    const config: Configuration = {};
    const api = new GetFractionalPaymentsApi(config, '', httpClient);
    const sleep = async(ms: number) => {
      return new Promise(resolve => setTimeout(resolve, ms));
    };
    const headers: { ['Async-Id']?: string } = {};
    while (!done) {
      const output = await api.getFractionalPaymentsOperation(params, { headers }) as AxiosResponse<GetFractionalPaymentsResponse | null>;
      callback(output);
      if (output.status === 202) {
        try {
          const retry = parseInt(output.headers['retry']);
          headers['Async-Id'] = output.headers['request-id'];
          if (isNaN(retry) || retry <= 0 || retry > 1000 * 3600) {
            throw new Error(`Retry time not acceptable (in milliseconds): ${retry}`);
          }
          await sleep(retry);
        } catch (err) {
          throw new Error(`Error in Async Request ${err}`);
        }
      } else {
        done = true;
      }
    }
  }

  /**
   * 
   * @param {GetFractionalPaymentsRequest} getFractionalPaymentsRequest
   
   * @throws {AsyncRequestError | RequiredError}
   * @returns {Promise<GetFractionalPaymentsResponse | null>}
   */
  public async getFractionalPaymentsOperationPromise(params: getFractionalPaymentsOperationParams): Promise<GetFractionalPaymentsResponse | null> {
    return new Promise((resolve, reject) => {
      this.getFractionalPaymentsOperationSteps(params, (response: AxiosResponse<GetFractionalPaymentsResponse | null>) => {
        if (response.status !== 202) {
          resolve(response.data);
        }
      })
      .catch((err) => {
        reject(err);
      });
    });
  } 
}


