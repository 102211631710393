/* eslint-disable */

/**
 * zonazurich
 * Portal de Mediación Digital
 *
 * The version of the OpenAPI document: 1
 * Contact: digitalarchitecture@zurich.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import {
  EASDKConfig,
  EAUrlUtils
} from '@zurich-es-npm/ea-shared-utils-js';

import * as globalImportUrl from 'url';
import {
  Configuration
} from './configuration';
import globalAxios, {
  AxiosPromise,
  AxiosInstance,
  AxiosResponse
} from 'axios';

const BASE_PATH = EAUrlUtils.removeLastForwardSlash('http://localhost:3000');

/**
 *
 * @export
 */
export const COLLECTION_FORMATS = {
  csv: ',',
  ssv: ' ',
  tsv: '\t',
  pipes: '|',
};

/**
 *  
 * @export
 * @interface RequestArgs
 */
export interface RequestArgs {
  url: string;
  options: any;
}

/**
 * 
 * @export
 * @class BaseAPI
 */
export class BaseAPI {
  protected configuration: Configuration | undefined;

  /**
   *Creates an instance of BaseAPI.
   * @param {Configuration} [configuration]
   * @param {string} [basePath=BASE_PATH]
   * @param {AxiosInstance} [axios=globalAxios]
   * @memberof BaseAPI
   */
  public constructor(
    configuration?: Configuration,
    protected basePath: string = BASE_PATH,
    protected axios: AxiosInstance = globalAxios) {
    if (configuration) {
      this.configuration = configuration;
      this.basePath = configuration.basePath || this.basePath;
    }
  }
}

/**
 * 
 * @export
 * @class RequiredError
 * @extends {Error}
 */
export class RequiredError extends Error {
  public name: 'RequiredError' = 'RequiredError';

  public field: string;

  /**
   *Creates an instance of RequiredError.
   * @param {string} field
   * @param {string} [msg]
   * @memberof RequiredError
   */
  public constructor(field: string, msg?: string) {
    super(msg);
    this.field=field;
  }
}

/**
 * 
 * @export
 * @class AsyncRequestError
 * @extends {Error}
 */
export class AsyncRequestError extends Error {
  public name: 'AsyncRequestError' = 'AsyncRequestError';

  /**
   *Creates an instance of AsyncRequestError.
   * @param {string} field
   * @param {string} [msg]
   * @memberof AsyncRequestError
   */
  public constructor(msg?: string) {
    super(msg);
  }
}

/**
 * 
 * @export
 * @interface Error
 */
export interface Error {
    /**
     * 
     * @type {string}
     * @memberof Error
     */
    code: string;
    /**
     * 
     * @type {string}
     * @memberof Error
     */
    message: string;
    /**
     * 
     * @type {number}
     * @memberof Error
     */
    severity: number;
    /**
     * 
     * @type {Array<object>}
     * @memberof Error
     */
    childErrors?: Array<object>;
}/**
 * 
 * @export
 * @interface SaveWarrantiesRequest
 */
export interface SaveWarrantiesRequest {
    /**
     * 
     * @type {string}
     * @memberof SaveWarrantiesRequest
     */
    codigoFlota: string;
    /**
     * 
     * @type {number}
     * @memberof SaveWarrantiesRequest
     */
    versionFlota: number;
    /**
     * 
     * @type {string}
     * @memberof SaveWarrantiesRequest
     */
    codigoPaqueteGarantia: string;
    /**
     * 
     * @type {string}
     * @memberof SaveWarrantiesRequest
     */
    codigoFranquiciaModalidad?: string;
    /**
     * 
     * @type {string}
     * @memberof SaveWarrantiesRequest
     */
    formaPagoTarificacion: SaveWarrantiesRequestFormaPagoTarificacionEnum;
    /**
     * 
     * @type {Array<SaveWarrantiesRequestVehiculos>}
     * @memberof SaveWarrantiesRequest
     */
    vehiculos: Array<SaveWarrantiesRequestVehiculos>;
    /**
     * 
     * @type {Array<SaveWarrantiesRequestGarantiasIncluidas>}
     * @memberof SaveWarrantiesRequest
     */
    garantiasIncluidas: Array<SaveWarrantiesRequestGarantiasIncluidas>;
    /**
     * 
     * @type {Array<SaveWarrantiesRequestGarantiasOpcionales>}
     * @memberof SaveWarrantiesRequest
     */
    garantiasOpcionales?: Array<SaveWarrantiesRequestGarantiasOpcionales>;
}

/**
    * @export
    * @enum {string}
    */
export enum SaveWarrantiesRequestFormaPagoTarificacionEnum {
    Anual = 'Anual',
    Unica = 'Unica',
    Semestral = 'Semestral',
    Trimestral = 'Trimestral',
    Mensual = 'Mensual'
}
/**
 * 
 * @export
 * @interface SaveWarrantiesRequestElementos
 */
export interface SaveWarrantiesRequestElementos {
    /**
     * 
     * @type {string}
     * @memberof SaveWarrantiesRequestElementos
     */
    codigoPaqueteGarantia?: string;
    /**
     * 
     * @type {string}
     * @memberof SaveWarrantiesRequestElementos
     */
    codigoElemento?: string;
    /**
     * 
     * @type {string}
     * @memberof SaveWarrantiesRequestElementos
     */
    tablaElemento?: string;
    /**
     * 
     * @type {string}
     * @memberof SaveWarrantiesRequestElementos
     */
    valorElemento?: string;
    /**
     * 
     * @type {number}
     * @memberof SaveWarrantiesRequestElementos
     */
    valorMinimoElemento?: number;
    /**
     * 
     * @type {number}
     * @memberof SaveWarrantiesRequestElementos
     */
    valorMaximoElemento?: number;
    /**
     * 
     * @type {boolean}
     * @memberof SaveWarrantiesRequestElementos
     */
    elementoModificable?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SaveWarrantiesRequestElementos
     */
    elementoRequerido?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SaveWarrantiesRequestElementos
     */
    elementoVisible?: boolean;
    /**
     * 
     * @type {number}
     * @memberof SaveWarrantiesRequestElementos
     */
    longitudElemento?: number;
    /**
     * 
     * @type {number}
     * @memberof SaveWarrantiesRequestElementos
     */
    numeroDecimalesElemento?: number;
    /**
     * 
     * @type {string}
     * @memberof SaveWarrantiesRequestElementos
     */
    tipoVariable?: SaveWarrantiesRequestElementosTipoVariableEnum;
    /**
     * 
     * @type {string}
     * @memberof SaveWarrantiesRequestElementos
     */
    tipoMascara?: SaveWarrantiesRequestElementosTipoMascaraEnum;
    /**
     * 
     * @type {string}
     * @memberof SaveWarrantiesRequestElementos
     */
    tipoPropuesta?: SaveWarrantiesRequestElementosTipoPropuestaEnum;
    /**
     * 
     * @type {string}
     * @memberof SaveWarrantiesRequestElementos
     */
    indicadorModalidadGarantia?: string;
    /**
     * 
     * @type {Array<SaveWarrantiesRequestTablaRestricciones>}
     * @memberof SaveWarrantiesRequestElementos
     */
    tablaRestricciones?: Array<SaveWarrantiesRequestTablaRestricciones>;
}

/**
    * @export
    * @enum {string}
    */
export enum SaveWarrantiesRequestElementosTipoVariableEnum {
    A = 'A',
    N = 'N'
}
/**
    * @export
    * @enum {string}
    */
export enum SaveWarrantiesRequestElementosTipoMascaraEnum {
    A = 'A',
    C = 'C',
    D = 'D',
    F = 'F',
    I = 'I',
    M = 'M',
    P = 'P',
    R = 'R',
    T = 'T'
}
/**
    * @export
    * @enum {string}
    */
export enum SaveWarrantiesRequestElementosTipoPropuestaEnum {
    M = 'M',
    S = 'S',
    O = 'O',
    N = 'N'
}
/**
 * 
 * @export
 * @interface SaveWarrantiesRequestGarantiasIncluidas
 */
export interface SaveWarrantiesRequestGarantiasIncluidas {
    /**
     * 
     * @type {string}
     * @memberof SaveWarrantiesRequestGarantiasIncluidas
     */
    codigoGarantia: string;
    /**
     * 
     * @type {string}
     * @memberof SaveWarrantiesRequestGarantiasIncluidas
     */
    claveModificadorLiteralGarantia?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SaveWarrantiesRequestGarantiasIncluidas
     */
    garantiaSeleccionada: boolean;
    /**
     * 
     * @type {string}
     * @memberof SaveWarrantiesRequestGarantiasIncluidas
     */
    indicadorModalidadGarantia?: string;
    /**
     * 
     * @type {Array<SaveWarrantiesRequestElementos>}
     * @memberof SaveWarrantiesRequestGarantiasIncluidas
     */
    elementos?: Array<SaveWarrantiesRequestElementos>;
}/**
 * 
 * @export
 * @interface SaveWarrantiesRequestGarantiasOpcionales
 */
export interface SaveWarrantiesRequestGarantiasOpcionales {
    /**
     * 
     * @type {string}
     * @memberof SaveWarrantiesRequestGarantiasOpcionales
     */
    codigoGarantia: string;
    /**
     * 
     * @type {string}
     * @memberof SaveWarrantiesRequestGarantiasOpcionales
     */
    claveModificadorLiteralGarantia?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SaveWarrantiesRequestGarantiasOpcionales
     */
    garantiaSeleccionada?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SaveWarrantiesRequestGarantiasOpcionales
     */
    indicadorModalidadGarantia?: string;
    /**
     * 
     * @type {Array<SaveWarrantiesRequestElementos>}
     * @memberof SaveWarrantiesRequestGarantiasOpcionales
     */
    elementos?: Array<SaveWarrantiesRequestElementos>;
}/**
 * 
 * @export
 * @interface SaveWarrantiesRequestTablaRestricciones
 */
export interface SaveWarrantiesRequestTablaRestricciones {
    /**
     * 
     * @type {string}
     * @memberof SaveWarrantiesRequestTablaRestricciones
     */
    nombreRestriccion?: string;
    /**
     * 
     * @type {string}
     * @memberof SaveWarrantiesRequestTablaRestricciones
     */
    valorRestriccion?: string;
}/**
 * 
 * @export
 * @interface SaveWarrantiesRequestVehiculos
 */
export interface SaveWarrantiesRequestVehiculos {
    /**
     * 
     * @type {string}
     * @memberof SaveWarrantiesRequestVehiculos
     */
    codigoPoliza: string;
    /**
     * 
     * @type {number}
     * @memberof SaveWarrantiesRequestVehiculos
     */
    versionPoliza: number;
    /**
     * 
     * @type {string}
     * @memberof SaveWarrantiesRequestVehiculos
     */
    tipoMatricula?: SaveWarrantiesRequestVehiculosTipoMatriculaEnum;
    /**
     * 
     * @type {string}
     * @memberof SaveWarrantiesRequestVehiculos
     */
    numeroMatricula?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum SaveWarrantiesRequestVehiculosTipoMatriculaEnum {
    M1 = 'M1',
    E1 = 'E1',
    TE = 'TE',
    I = 'I',
    C = 'C',
    _1 = '1',
    P = 'P',
    R = 'R',
    D = 'D',
    K = 'K',
    T1 = 'T1',
    T = 'T',
    E = 'E',
    TT = 'TT',
    R1 = 'R1',
    H = 'H',
    M = 'M',
    NOMATRICULA = 'NO-MATRICULA'
}
/**
 * 
 * @export
 * @interface SaveWarrantiesResponse
 */
export interface SaveWarrantiesResponse {
    /**
     * 
     * @type {Array<SaveWarrantiesResponseResultados>}
     * @memberof SaveWarrantiesResponse
     */
    resultados: Array<SaveWarrantiesResponseResultados>;
    /**
     * 
     * @type {Array<Error>}
     * @memberof SaveWarrantiesResponse
     */
    errors?: Array<Error>;
}/**
 * 
 * @export
 * @interface SaveWarrantiesResponseResultados
 */
export interface SaveWarrantiesResponseResultados {
    /**
     * 
     * @type {string}
     * @memberof SaveWarrantiesResponseResultados
     */
    codigoPoliza: string;
    /**
     * 
     * @type {number}
     * @memberof SaveWarrantiesResponseResultados
     */
    versionPoliza: number;
    /**
     * 
     * @type {boolean}
     * @memberof SaveWarrantiesResponseResultados
     */
    ok: boolean;
}

type saveWarrantiesOperationParams = {
  saveWarrantiesRequest: SaveWarrantiesRequest,
};

/**
 * SaveWarrantiesApi - axios parameter creator
 * @param {Configuration} [configuration]
 * @returns {any}
 */
const SaveWarrantiesApiAxiosParamCreator = function(configuration?: Configuration): any {
  return {
    
    /**
     * Guarda las garantías para una lista de vehículos (flujo Flotas)
     * @param {SaveWarrantiesRequest} saveWarrantiesRequest 
     * @param {string} [acceptLanguage] El Accept-Language request HTTP header indica en que lenguaje debe responder el backend.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @returns {RequestArgs}
     */
    saveWarrantiesOperation(params: saveWarrantiesOperationParams, options: any = {}): RequestArgs {
      // Verify required parameter 'saveWarrantiesRequest' is not null or undefined
      if (params.saveWarrantiesRequest === null || params.saveWarrantiesRequest === undefined) {
        throw new RequiredError('saveWarrantiesRequest', 'Required parameter saveWarrantiesRequest was null or undefined when calling saveWarrantiesOperation.');
      }
      const localVarPath = `/v1/fleets/saveWarrantiesOperation`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true) as Partial<globalImportUrl.UrlWithParsedQuery>;
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;


  
      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
      // Fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
      const needsSerialization = ('SaveWarrantiesRequest' as any !== 'string') ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization ? JSON.stringify(params.saveWarrantiesRequest || {}) : params.saveWarrantiesRequest || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * SaveWarrantiesApi - functional programming interface
 * @param {Configuration} [configuration]
 * @returns {any}
 */
const SaveWarrantiesApiFp = function(configuration?: Configuration): any {
  return {
    
    /**
     * Guarda las garantías para una lista de vehículos (flujo Flotas)
     * @param {SaveWarrantiesRequest} saveWarrantiesRequest 
     * @param {string} [acceptLanguage] El Accept-Language request HTTP header indica en que lenguaje debe responder el backend.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @returns {any}
     */
    saveWarrantiesOperation(params: saveWarrantiesOperationParams, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<SaveWarrantiesResponse> {
      const localVarAxiosArgs = SaveWarrantiesApiAxiosParamCreator(configuration).saveWarrantiesOperation(params, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH): any => {
        const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * SaveWarrantiesApi - factory interface
 * @param {Configuration} configuration
 * @param {string} basePath
 * @param {AxiosInstance} axios
 * @export
 * @returns {any}
 */
const SaveWarrantiesApiFactory = function(configuration?: Configuration, basePath?: string, axios?: AxiosInstance): any {
  return {
    
    /**
     * Guarda las garantías para una lista de vehículos (flujo Flotas)
     * @param {SaveWarrantiesRequest} saveWarrantiesRequest 
     * @param {string} [acceptLanguage] El Accept-Language request HTTP header indica en que lenguaje debe responder el backend.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @returns {any}
     */
    saveWarrantiesOperation(params: saveWarrantiesOperationParams, options?: any): any {
      return SaveWarrantiesApiFp(configuration).saveWarrantiesOperation(params, options)(axios, basePath);
    },
  };
};

/** 
 * SaveWarrantiesApi - object-oriented interface
 * @export
 * @class SaveWarrantiesApi
 * @extends {BaseAPI}
 */
export class SaveWarrantiesApi extends BaseAPI {
  
  /**
   * Guarda las garantías para una lista de vehículos (flujo Flotas)
   * @param {SaveWarrantiesRequest} saveWarrantiesRequest 
   * @param {string} [acceptLanguage] El Accept-Language request HTTP header indica en que lenguaje debe responder el backend.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @returns {any}
   * @memberof SaveWarrantiesApi
   */
  public saveWarrantiesOperation(params: saveWarrantiesOperationParams, options?: any): any {
    return SaveWarrantiesApiFp(this.configuration).saveWarrantiesOperation(params, options)(this.axios, this.basePath);
  }

}

/**
 * 
 * @export
 * @class EAsaveWarrantiesOperationInputMessage
 */
export class EAsaveWarrantiesOperationInputMessage {
  
  /**
   * 
   * @type {SaveWarrantiesRequest}
   * @memberof EAsaveWarrantiesOperationInputMessage
   */
  public saveWarrantiesRequest: SaveWarrantiesRequest;

  public constructor(saveWarrantiesRequest: SaveWarrantiesRequest, ){
  
  this.saveWarrantiesRequest=saveWarrantiesRequest;
  } 
}



/** 
 * EASaveWarrantiesApi - Architecture client for saveWarrantiesOperation
 * @export
 * @class EASaveWarrantiesApi
 */
export class EASaveWarrantiesApi {

  /**
   * 
   * @param {SaveWarrantiesRequest} saveWarrantiesRequest
   
   * @throws {AsyncRequestError | RequiredError}
   * @returns {Promise<SaveWarrantiesResponse | null>}
  */ 
  public async saveWarrantiesOperation(params: saveWarrantiesOperationParams): Promise<SaveWarrantiesResponse | null> {
    return this.saveWarrantiesOperationPromise(params);
  }

  /**
   * 
   * @param {SaveWarrantiesRequest} saveWarrantiesRequest
   
   * @param {Function} callback
   * @throws {AsyncRequestError | RequiredError}
   */
  public async saveWarrantiesOperationSteps(params: saveWarrantiesOperationParams, callback: (res: AxiosResponse<SaveWarrantiesResponse | null>) => void) {
    let done: boolean = false;
    const httpClient: AxiosInstance = EASDKConfig.getHttpClient('/v1/fleets/saveWarrantiesOperation', 'saveWarranties', 'saveWarrantiesOperation');
    const config: Configuration = {};
    const api = new SaveWarrantiesApi(config, '', httpClient);
    const sleep = async(ms: number) => {
      return new Promise(resolve => setTimeout(resolve, ms));
    };
    const headers: { ['Async-Id']?: string } = {};
    while (!done) {
      const output = await api.saveWarrantiesOperation(params, { headers }) as AxiosResponse<SaveWarrantiesResponse | null>;
      callback(output);
      if (output.status === 202) {
        try {
          const retry = parseInt(output.headers['retry']);
          headers['Async-Id'] = output.headers['request-id'];
          if (isNaN(retry) || retry <= 0 || retry > 1000 * 3600) {
            throw new Error(`Retry time not acceptable (in milliseconds): ${retry}`);
          }
          await sleep(retry);
        } catch (err) {
          throw new Error(`Error in Async Request ${err}`);
        }
      } else {
        done = true;
      }
    }
  }

  /**
   * 
   * @param {SaveWarrantiesRequest} saveWarrantiesRequest
   
   * @throws {AsyncRequestError | RequiredError}
   * @returns {Promise<SaveWarrantiesResponse | null>}
   */
  public async saveWarrantiesOperationPromise(params: saveWarrantiesOperationParams): Promise<SaveWarrantiesResponse | null> {
    return new Promise((resolve, reject) => {
      this.saveWarrantiesOperationSteps(params, (response: AxiosResponse<SaveWarrantiesResponse | null>) => {
        if (response.status !== 202) {
          resolve(response.data);
        }
      })
      .catch((err) => {
        reject(err);
      });
    });
  } 
}


