import {
  PolicyMovementType
} from '@/flows/policy/policy-model';

/** 
 * Model qb-fractional-payment
 */
class QbFractionalPaymentModalModel {
  public fractionalPaymentData: FractionalPaymentDataModel[] = [];

  public title?: string = ''; // Title displayed above the table (modal title)

  public movementType: PolicyMovementType = PolicyMovementType.Incompleta;

  public isVisible: boolean = false;

  public isSingularTitle?: boolean = false; // TODO: FUTURE: Consider delete this and use only 'movementType' property

  public proposalNumbers: string[] = [];
}

export interface FractionalPaymentDataModel {
  paymentForm: string;
  paymentFormValues: Record<string, string | number>;
  showInBold?: boolean;
}

export default QbFractionalPaymentModalModel;
