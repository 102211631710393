import FleetVehicleGroupsModel from '@/business-components/fleet-vehicle-groups/fleet-vehicle-groups-model';

import {
  FleetsModel
} from '../fleets/fleets-model';
import {
  ParsedTableData
} from '@/utils/corporate-tables';
import FleetsCancelVehicleModalModel
  from '@/business-components/fleets-cancel-vehicle/fleets-cancel-vehicle-modal-model';

export enum FlowViewsStepsModel {
  FleetSummary = 0, // Fleet summary + tomador summary + issued vehicles + pending vehicles
  VehiclesDataStep = 1,
  QuoteDataStep = 2,
  InterveningIssuanceStep = 3,
  OfferIssuanceDataStep = 4
}

export enum FlowHeaderStepsModel {
  VehiclesDataStep = 0,
  QuoteDataStep = 1,
  InterveningIssuanceDataStep = 2
}

export interface InputModel {
  codigoPoliza: string;
  versionPoliza: number;
  maintenanceMode: boolean;
  consultMode: boolean;
  hasParentFlow: boolean;
}

export enum OperationTypeEnum {
  MANTENIMIENTO = 'MANTENIMIENTO',
  CONSULTA = 'CONSULTA'
}

export enum PolicyEmitedStatus {
  VIGOR = 'V',
  ANULADA = 'A',
  PENDIENTE = 'P',
  SUSPENDIDA = 'S',
  MODELO = '0',
  FORMALIZADA = '1',
  VIGOR_OFERTA = '2',
  PENDIENTE_OFERTA = '3',
  CADUCADA = '4'
}

export interface MaxVehiclesValidations {
  canAddVehicles: boolean;
  warningMessage: string;
}

/**
 * Flow model
 *
 * @export
 * @class FleetsMaintenancePolicyModel
 * @extends {FleetsModel - EAFlowModel}
 */
export class FleetsMaintenancePolicyModel extends FleetsModel {
  public fleetEmittedVehicleGroupsModel = new FleetVehicleGroupsModel();
  
  public addressTypesList: ParsedTableData[] = [];

  public hasParentFlow: boolean = false;

  public emmitedVehicles: number = 0;

  public vehicleToCancel: FleetsCancelVehicleModalModel | null = null;
}


