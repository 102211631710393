import Vue from 'vue';
import {
  CorpTableNames,
  fetchCorporateTable
} from '@/utils/corporate-tables';
import {
  EAMethod,
  EAValidationError
} from '@zurich-es-npm/ea-front-web-core';
import {
  isFinite as _isFinite
} from 'lodash';
import {
  plateNumberTypeEnum
} from './plate-number-validations.enum';


const MASK_EMPTY = '';
const BLANK = ' ';
const PARQUE_MOVIL_MINISTERIAL = 'PMM';
const PARQUE_MOVIL_ESTADO = 'PME';
const MINISTERIO_MEDIO_AMBIENTE = 'MMA';
const EJERCITO_TIERRA = 'ET';
const FUERZAS_NAVALES = 'FN';
const MINISTERIO_FOMENTO = 'MF';
const EJERCITO_AIRE = 'EA';
const GUARDIA_CIVIL = 'PGC';
const DIRECCION_GENERAL_POLICIA = 'DGP';
const MINISTERIO_OBRAS_PUBLICAS = 'MOP';
const CUERPO_POLICIA_NACIONAL = 'CNP';
const AENA_1 = 'A';
const AENA_2 = 'AE';

/**
 * ExtraPlateValidations class. Extra plates validations utility.
 */
export class ExtraPlateValidations {

  /**
   * Extra validations for searchBy = Plate.
   * 
   * @param {string} plateType
   * @param {string} plateNumber
   * 
   * @returns {EAValidationError[] | undefined}
   */
  public static async extraPlateValidations(
    plateType: string, plateNumber: string
  ): Promise<EAValidationError[] | undefined> {
    if (plateType === plateNumberTypeEnum.PLATE_DIPLOMATICO) { // Diplomatico
      return this.validationTypeDiplomatico(plateNumber);
    } else if (plateType === plateNumberTypeEnum.PLATE_ESPECIAL ||
      plateType === plateNumberTypeEnum.PLATE_REMOLQUE ||
      plateType === plateNumberTypeEnum.PLATE_TEMPORAL) { // Especial, Remolque, Temporal 5/30
      return this.validationTypeEspecialRemolqueTemporal530(plateNumber);
    } else if (plateType === plateNumberTypeEnum.PLATE_ITV) { // ITV
      return this.validationTypeITV(plateNumber);
    } else if (plateType === plateNumberTypeEnum.PLATE_ESTADO) { // Vehiculos Estado
      return this.validationTypeVehiculosEstado(plateNumber);
    } else if (plateType === plateNumberTypeEnum.PLATE_PRUEBAS) { // Pruebas/Transporte
      return this.validationTypePruebasTransporte(plateNumber);
    } else if (plateType === plateNumberTypeEnum.PLATE_TURISTICA) { // Turistica
      return this.validationTypeTuristica(plateNumber);
    } else if (plateType === plateNumberTypeEnum.PLATE_ORDINARIA) { // Ordinaria
      return this.validationTypeOrdinaria(plateNumber);
    }
  }

  /**
   * Extra validation for plate type 'D' (Diplomatico).
   * @param {string} plateNumber
   * @returns {Promise<EAValidationError[] | undefined>}
   */
  private static async validationTypeDiplomatico(plateNumber: string): Promise<EAValidationError[] | undefined> {
    const CORPORATE_TABLE = 'KCIT85G';
    if (!await this.elementExistsInCorpTable(plateNumber.substring(0, 2), CORPORATE_TABLE)) {
      return [
        new EAValidationError(Vue.prototype.$t('searchOfferFlow.searcher.extraPlateValidationError.genericError'))
      ];
    }
  }

  /**
   * Extra validation for plate types 'E' (Especial), 'R' (Remolque) and 'TE' (Temporal 5/30)
   * @param {string} plateNumber
   * @returns {Promise<EAValidationError[] | undefined>}
   */
  private static async validationTypeEspecialRemolqueTemporal530(
    plateNumber: string
  ): Promise<EAValidationError[] | undefined> {
    const CORPORATE_TABLE = 'KCIT79G';
    let county = plateNumber.substring(0, 2);
    county = this.charactersUntilDigit(county);
    if (!await this.elementExistsInCorpTable(county, CORPORATE_TABLE)) {
      return [
        new EAValidationError(Vue.prototype.$t('searchOfferFlow.searcher.extraPlateValidationError.genericError'))
      ];
    }
  }

  /**
   * Extra validation for plate type 'I' (ITV).
   * @param {string} plateNumber
   * @returns {Promise<EAValidationError[] | undefined>}
   */
  private static async validationTypeITV(plateNumber: string): Promise<EAValidationError[] | undefined> {
    const CORPORATE_TABLE = 'KCIT79G';
    const county = this.charactersUntilDigit(plateNumber.substring(0, 2));
    if (!await this.elementExistsInCorpTable(county, CORPORATE_TABLE)) {
      return [
        new EAValidationError(Vue.prototype.$t('searchOfferFlow.searcher.extraPlateValidationError.genericError'))
      ];
    }
  }

  /**
   * Extra validation for plate type 'M' (Vehiculos Estado).
   * @param {string} plateNumber
   * @returns {Promise<EAValidationError[] | undefined>}
   */
  private static async validationTypeVehiculosEstado(plateNumber: string): Promise<EAValidationError[] | undefined> {
    const CORPORATE_TABLE = 'KCIT84G';

    // Extra regex validation TODO: regex does not fit requirements ?

    const county = this.charactersUntilDigit(plateNumber.substring(0, 3));
    if (!await this.elementExistsInCorpTable(county, CORPORATE_TABLE)) {
      return [
        new EAValidationError(Vue.prototype.$t('searchOfferFlow.searcher.extraPlateValidationError.genericError'))
      ];
    }
  }

  /**
   * Extra validation for plate type 'P' (Pruebas/Transporte).
   * @param {string} plateNumber
   * @returns {Promise<EAValidationError[] | undefined>}
   */
  private static async validationTypePruebasTransporte(plateNumber: string): Promise<EAValidationError[] | undefined> {
    const CORPORATE_TABLE = 'KCIT79G';
    const regexp = /[pP|tT]\d{4}/;
    const splitted = plateNumber.split(regexp);
    if (!this.ninetyNineValidation(splitted, 1)) {
      return [
        new EAValidationError(Vue.prototype.$t('searchOfferFlow.searcher.extraPlateValidationError.genericError'))
      ];
    }

    if (!await this.elementExistsInCorpTable(splitted[0], CORPORATE_TABLE)) {
      return [
        new EAValidationError(Vue.prototype.$t('searchOfferFlow.searcher.extraPlateValidationError.genericError'))
      ];
    }
  }

  /**
   * Extra validation for plate type 'T' (Turistica).
   * @param {string} plateNumber
   * @returns {Promise<EAValidationError[] | undefined>}
   */
  private static async validationTypeTuristica(plateNumber: string): Promise<EAValidationError[] | undefined> {
    const CORPORATE_TABLE = 'KCIT79G';
    const regexp = /[a-zA-Z]{1,2}\d{4}/;
    const splitted = plateNumber.split(regexp);
    if (!this.ninetyNineValidation(splitted, 2)) {
      return [
        new EAValidationError(Vue.prototype.$t('searchOfferFlow.searcher.extraPlateValidationError.genericError'))
      ];
    }

    let county = plateNumber.substring(2, 4);
    // County can have 1 or 2 characters. Validate the second one is a character or a number
    county = this.charactersUntilDigit(county);
    // Validate that the county exists
    if (!await this.elementExistsInCorpTable(county, CORPORATE_TABLE)) {
      return [
        new EAValidationError(Vue.prototype.$t('searchOfferFlow.searcher.extraPlateValidationError.genericError'))
      ];
    }
  }

  /**
   * Extra validation for plate type '1' (Ordinaria).
   * @param {string} plateNumber
   * @returns {Promise<EAValidationError[] | undefined>}
   */
  private static async validationTypeOrdinaria(plateNumber: string): Promise<EAValidationError[] | undefined> {
    const CORPORATE_TABLE = 'KCIT79G';
    if (!this.extraOrdinariaValidation(plateNumber)) {
      return [
        new EAValidationError(Vue.prototype.$t('searchOfferFlow.searcher.extraPlateValidationError.genericError'))
      ];
    }

    // Ordinaria. Validate county characters
    let county = plateNumber.substring(0, 2);
    // There are 3 types of ordinaria plate. One of them does not have the county as initial characters
    if (!this.isDigit(county.charAt(0))) {
      // County can have 1 or 2 characters. Validate the second one is a character or a number
      if (this.isDigit(county.charAt(1))) {
        // If the second is a number, remove it from the string
        county = county.substring(0, 1);
      }
      if (!await this.elementExistsInCorpTable(county, CORPORATE_TABLE)) {
        return [
          new EAValidationError(Vue.prototype.$t('searchOfferFlow.searcher.extraPlateValidationError.genericError'))
        ];
      }
    }
  }

  /**
   * Check ninety nine validation.
   * @param {string[]} array 
   * @param {number} position 
   * @returns {boolean}
   */
  public static ninetyNineValidation(array: string[], position: number): boolean {
    let isValid = true;

    if (array.length === 2) {
      const year = parseInt(array[1].substring(position, array[1].length));
      isValid = year < 99;
    }

    return isValid;
  }

  /**
   * 
   * @param {string} plate 
   * @returns {boolean}
   */
  public static extraOrdinariaValidation(plate: string): boolean {
    let isValid = false;
    
    if (plate.length > 9 && !this.isDigit(plate.charAt(8))) {
      const plateNumber = plate.substring(2, 8);
      const plateNumberToNum = plateNumber as unknown as number / 1;
      if (_isFinite(plateNumberToNum) && plateNumberToNum < 10000) {
        isValid = true;
      }
    } else {
      isValid = true;
    }
    return isValid;
  }

  /**
   * Check if code exists in a given corporate table.
   * @param {string} code
   * @param {string} tableCode 
   * 
   * @returns {Promise<boolean>}
   */
  @EAMethod({
    loading: true
  })
  public static async elementExistsInCorpTable(code: string, tableCode: string): Promise<boolean> {
    const corpTableProductResult = await fetchCorporateTable(tableCode);
    // TODO: catch fetchCorporateTable ERROR ???
    if (corpTableProductResult?.data && corpTableProductResult?.data.tableDocuments) {
      const foundDescription =
        corpTableProductResult.data.tableDocuments.find(item => item.CDELEMEN.trim() === code.trim());
      return !!foundDescription;
    }
    return false;
  }

  /**
   * Returns the characters before the 1st found digit.
   * Example: 'AB1234XYZ' returns 'AB'
   * @param {string} text
   * 
   * @returns {string} the characters before first digit found.
   */
  public static charactersUntilDigit(text: string): string {
    let returnText = '';
    for (let index = 0; index < text.length; index++) {
      if (this.isDigit(text.charAt(index))) {
        break;
      }
      returnText = returnText.concat(text.charAt(index));
    }
    return returnText;
  }

  /**
   * Check if a character is a number.
   * @param {string} character
   * 
   * @returns {boolean}
   */
  public static isDigit(character: string): boolean {
    return (/\d/).test(character);
  }

  /**
   * Format plate 
   * @param {string} plateNum
   * @param {string} type
   *
   * @returns {string}
   */
  public static async formatPlate(plateNum: string, type: string): Promise<string> {
    let formatted;

    formatted = plateNum.toUpperCase();
    if (type === plateNumberTypeEnum.PLATE_REMOLQUE || type === plateNumberTypeEnum.PLATE_ESPECIAL) {
      formatted = this.formatPlateEspeRemol(formatted);
    }
    if (type === plateNumberTypeEnum.PLATE_ESTADO) {
      const newFormat = await this.formatPlateEstado(formatted, type);
      if (newFormat) {
        formatted = newFormat;
      }
    
    }
    if (type === plateNumberTypeEnum.PLATE_PRUEBAS) {
      if (this.isDigit(formatted.charAt(1))) {
        formatted = `${formatted.substring(0, 1)} ${formatted.substring(1, 10)}`;
      }
    }
    formatted = this.secondFormatPlate(formatted, type);
    formatted = this.thirdFormatPlate(formatted, type);

    return formatted;
  }

  /**
   * Format plate for some cases
   * @param {string} plateNum
   * @param {string} type
   *
   * @returns {string}
   */
  public static secondFormatPlate(plateNum: string, type: string): string {
    let formatted = plateNum;
    if (type === plateNumberTypeEnum.PLATE_TURISTICA) {
      if (this.isDigit(plateNum.charAt(3))) {
        formatted = `${plateNum.substring(0, 3) } ${ plateNum.substring(3, 8)}`;
      }
    }
    if (type === plateNumberTypeEnum.PLATE_TEMPORAL) {
      formatted = this.formatPlateTemporal(plateNum);
    }
    if (type === plateNumberTypeEnum.PLATE_ITV) {
      if (this.isDigit(plateNum.charAt(4))) {
        formatted = `${plateNum.substring(0, 1) } ${ plateNum.substring(1, 8)}`;
      }
    }
    if (type === plateNumberTypeEnum.PLATE_ESTADO_RD) {
      if (this.isDigit(plateNum.charAt(1))) {
        formatted = `${plateNum.substring(0, 1) }  ${ plateNum.substring(1, 7)}`;
      } else if (this.isDigit(plateNum.charAt(2))) {
        formatted = `${plateNum.substring(0, 2) } ${ plateNum.substring(2, 7)}`;
      }
    }

    return formatted;
  }

  /**
   * Format plate for Temporal
   * @param {string} plateNum
   * @param {string} type
   *
   * @returns {string}
   */
  public static formatPlateTemporal(plateNum: string): string {
    plateNum = plateNum.split(' ').join('');
    let letters = plateNum.substring(0, 2);
    if (this.isDigit(plateNum.charAt(1))) {
      letters = `${plateNum.substring(0, 1)} `;
    }

    const finalLetters = [];
    const numbers = [];
    const lastNumbers = [];
    let character = false;

    for (let index = letters.trim().length; index < plateNum.length; index++) {
      if (this.isDigit(plateNum.charAt(index))) {
        if (character) {
          lastNumbers.push(plateNum.charAt(index));
        } else {
          numbers.push(plateNum.charAt(index));
        }
      } else {
        finalLetters.push(plateNum.charAt(index));
        character = true;
      }
    }

    const finalLettersString = finalLetters.toString().trim();
    let formatted = `${letters}${numbers.join(MASK_EMPTY).padStart(4, '0')}${finalLettersString}`;
  
    if (lastNumbers.length < 2) {
      formatted = `${formatted}${lastNumbers.join(MASK_EMPTY).padStart(2, '0')}`;
    } else {
      formatted = `${formatted}${lastNumbers.join(MASK_EMPTY)}`;
    }

    return formatted;
  }

  /**
   * Format plate for Especial or Remolque case
   * @param {string} plateNum
   * @param {string} type
   *
   * @returns {string}
   */
  public static formatPlateEspeRemol(plateNum: string): string {
    let letters = plateNum.substring(0, 2);
    if (this.isDigit(plateNum.charAt(1))) {
      letters = `${plateNum.substring(0, 1) } `;
    }
    const finalLetters = [];
    const numbers = [];
    for (let index = letters.trim().length; index < plateNum.length; index++) {
      if (this.isDigit(plateNum.charAt(index))) {
        numbers.push(plateNum.charAt(index));
      } else {
        finalLetters.push(plateNum.charAt(index));
      }
    }
  
    const finalLettersString = finalLetters.join('').trim();
    let formatted = `${letters}${numbers.join(MASK_EMPTY).padStart(6, '0')}${finalLettersString}`;
    formatted = formatted.trim();
    return formatted;
  }

  /**
   * Format plate for some cases
   * @param {string} plateNum
   * @param {string} type
   *
   * @returns {string}
   */
  public static thirdFormatPlate(plateNum: string, type: string): string {
    let formatted = plateNum;
    if (type === plateNumberTypeEnum.PLATE_ORDINARIA || type === plateNumberTypeEnum.PLATE_BLANK) {
 
      if (!this.isDigit(plateNum.charAt(0))) {
        formatted = this.thirdFormatConditionPlate(plateNum);
        formatted = formatted.trim();
      } else if (plateNum.length > 1) {
        formatted = formatted.padStart(7, '0');
      } else {
        formatted = formatted.split(BLANK).join(MASK_EMPTY);
      }
    }

    return formatted;
  }


  /**
   * Format plate for some cases
   * @param {string} plateNum
   *
   * @returns {string}
   */
  public static thirdFormatConditionPlate(plateNum: string): string {
    let letters = plateNum.substring(0, 2);
    if (this.isDigit(plateNum.charAt(1))) {
      letters = `${plateNum.substring(0, 1)} `;
    }
    const finalLetters = [];
    const numbers = [];

    for (let index = letters.trim().length; index < plateNum.length; index++) {
      if (this.isDigit(plateNum.charAt(index))) {
        numbers.push(plateNum.charAt(index));
      } else {
        finalLetters.push(plateNum.charAt(index));
      }
    }
  
    let finalLettersString = finalLetters.join(MASK_EMPTY);
    if (finalLettersString.length === 1) {
      finalLettersString = ` ${ finalLettersString}`;
    }
    return `${letters}${numbers.join(MASK_EMPTY).padStart(6, '0')}${finalLettersString}`;
  }

  /**
   * Get the validate list for Estado case
   * @param {string} type
   *
   * @returns {string}
   */
  public static async getValidateRules(type: string): Promise<string[] | null> {
    let listValidations = null;
    const table = await fetchCorporateTable(CorpTableNames.PlateNumType);
    const format = table.data.tableDocuments.find(elem => elem.CDMATRIC === type)?.TXVALMAT;

    if (!format) {
      return null;
    }

    // Se extraen las reglas de validacion que hay que aplicar 
    listValidations = format.split(BLANK);
 
    return listValidations;
  }

  /**
   * Format plate estado
   *
   * @param {string} value
   * @param {string} type
   * 
   * @return {string}
   */
  public static async formatPlateEstado(value: string, type: string): Promise<string | null> {
    let formatted: string | null = MASK_EMPTY;
    let reglaValidacion = MASK_EMPTY;
    const listaReglasValidacion = await this.getValidateRules(type);

    if (listaReglasValidacion) {
      listaReglasValidacion.forEach((elem, index) => {
        if (listaReglasValidacion[index] !== null) {
          // INC11187873 -I
          reglaValidacion = listaReglasValidacion[index];
          formatted = this.formatMatriculaEstado(reglaValidacion, value.toUpperCase());
          // INC11187873 -F
           
        }
        if (formatted !== null && formatted === MASK_EMPTY) {
          return value;
        }
      });
    }
 
    return formatted;
  }

  /**
   * Formatea matricula estado. "3O6N", "3O6N2B";
   *
   * @param {string} reglaValidacion 
   * @param {string}  cadenaValidacion 
   * @return {string | null} format
   */
  public static formatMatriculaEstado(reglaValidacion: string, cadenaValidacion: string): string | null {
    let contadorRV = 0;
    let contadorMat = 0;
    let repeticiones = 0;
    let validado = true;
    let hasta = 0;
    let caracterValidacion;
    let tipo;
    const matriculaCICOS = [];
    let cadenaformateada;
  
    // Se recorre la regla de validacion y se formatea la matricula
    while (cadenaValidacion !== null && contadorRV < reglaValidacion.length && validado) {
      caracterValidacion = reglaValidacion.charAt(contadorRV);
      // Se comprueba si el caracter leido es una numLetras
      // eslint-disable-next-line no-negated-condition
      if (!this.isDigit(caracterValidacion)) {
        if (caracterValidacion === cadenaValidacion.charAt(contadorMat)) {
          matriculaCICOS.push(caracterValidacion);
          contadorRV++;
          contadorMat++;
        } else {
          validado = false;
        }
      } else {
        // Se leen el tipo de caracter a validar y cuantas veces
        repeticiones = parseInt(caracterValidacion, 36);
        contadorRV++;
        tipo = reglaValidacion.charAt(contadorRV);

        switch (tipo) {
          case 'B': // Valida caracteres alfanumericos y espacios.   
            hasta = contadorMat + repeticiones;
            this.recuperaNumLetras(cadenaValidacion, contadorMat, hasta, matriculaCICOS, repeticiones);
            break;
          case 'O':      // Valida los tipos de matricula de Vehiculos del estado
            hasta = contadorMat + repeticiones;
            contadorMat = this.recuperaLongTipoPrefijo(cadenaValidacion, contadorMat, hasta, matriculaCICOS);
            break;
          case 'N':   // Valida los caracteres numericos
            hasta = contadorMat + repeticiones;
            contadorMat = this.recuperaLongNum(cadenaValidacion, contadorMat, hasta, matriculaCICOS, repeticiones);
            break;
          case 'W' : // Valida por medio de una expresion regular.
            cadenaformateada = this.formatearCadenaValidacion(cadenaValidacion);
            matriculaCICOS.push(cadenaformateada);
            validado = true;
            break;
            // INC11187873  -F
          default:
            break;
        }
        contadorRV++;
      }
    }

    if (validado) {
      return matriculaCICOS.toString();
    }
    return null;
  }


  /** 
   *
   *  Formatea la cadena Validacion a la expresion que tendria que tener segun especificacion.
   *  @param {string} cadena
   *
   *  @return {string}
   *
   */
  public static formatearCadenaValidacion(cadena: string): string {
    let cadenaFormateada = MASK_EMPTY;
    const cadenaValidacion = cadena.split(BLANK).join(MASK_EMPTY);

    if (cadenaValidacion.startsWith(PARQUE_MOVIL_MINISTERIAL) || cadenaValidacion.startsWith(CUERPO_POLICIA_NACIONAL)) {
      cadenaFormateada = this.formatPmmCnp(cadenaValidacion, 3, cadenaValidacion.substring(0, 3));
    } else if (cadenaValidacion.startsWith(PARQUE_MOVIL_ESTADO)) {
      cadenaFormateada = this.formatPme(cadenaValidacion, 3, PARQUE_MOVIL_ESTADO);
    } else if (cadenaValidacion.startsWith(MINISTERIO_MEDIO_AMBIENTE)) {
      cadenaFormateada = this.formatMma(cadenaValidacion, 3, MINISTERIO_MEDIO_AMBIENTE);
    } else if (this.startsWithEaEtFnMf(cadenaValidacion)) {
      cadenaFormateada = this.formatEaEtFnMf(cadenaValidacion, 2, cadenaValidacion.substring(0, 2));
    } else if (this.startsPgcMop(cadenaValidacion)) {
      cadenaFormateada = this.formatPgcMop(cadenaValidacion, 3, cadenaValidacion.substring(0, 3));
    } else if (cadenaValidacion.startsWith(DIRECCION_GENERAL_POLICIA)) {
      cadenaFormateada = this.formatDgp(cadenaValidacion);
    } else if (cadenaValidacion.startsWith(AENA_2)) {
      cadenaFormateada = this.formatAena(cadenaValidacion, 2);
    } else if (cadenaValidacion.startsWith(AENA_1)) {
      cadenaFormateada = this.formatAena(cadenaValidacion, 1);
    }
    return cadenaFormateada;
  }

  /**
   * Formato de Pmm Cnp
   *
   * @param {string} cadenaValidacion
   * @param {string}  charNumber
   * @param {string} siglas
   * @return {string}  formato
   */
  public static formatPmmCnp(cadenaValidacion: string, charNumber: number, siglas: string): string {
    const numberPart = cadenaValidacion.substring(charNumber, cadenaValidacion.length - 2);
    const lastLetters = cadenaValidacion.substring(cadenaValidacion.length - 2, cadenaValidacion.length);
    const numberPartAddZeros = numberPart.padStart(6, '0');
    return siglas.concat(numberPartAddZeros).concat(lastLetters);
  }

  /**
   * Formato de Pmm Cnp
   *
   * @param {string} cadenaValidacion 
   * @param {string}  charNumber
   * @param {string} siglas
   * @return {string}  formato
   */
  public static formatPme(cadenaValidacion: string, charNumber: number, siglas: string): string {
    const numberPart = cadenaValidacion.substring(charNumber, cadenaValidacion.length - 1);
    const lastLetters = cadenaValidacion.substring(cadenaValidacion.length - 1, cadenaValidacion.length);
    const numberPartAddZeros = numberPart.padStart(6, '0');
    return siglas
      .concat(numberPartAddZeros).concat(BLANK)
      .concat(lastLetters);
  }

  /**
   * Formato de Pmm Cnp
   *
   * @param {string} cadenaValidacion 
   * @param {string}  charNumber
   * @param {string} siglas
   * @return {string}  formato
   */
  public static formatMma(cadenaValidacion: string, charNumber: number, siglas: string): string {
    const numberPart = cadenaValidacion.substring(charNumber, cadenaValidacion.length);
    const numberPartAddZeros = numberPart.padStart(6, '0');
    return siglas.concat(numberPartAddZeros);
  }

  /**
   * Formato de Pmm Cnp
   *
   * @param {string} cadenaValidacion 
   * @param {string}  charNumber
   * @param {string} siglas
   * @return {string}  formato
   */
  public static formatEaEtFnMf(cadenaValidacion: string, charNumber: number, siglas: string): string {
    const numberPart = cadenaValidacion.substring(charNumber, cadenaValidacion.length);
    const numberPartAddZeros = numberPart.padStart(6, '0');
    return siglas.concat(BLANK).concat(numberPartAddZeros);
  }

  /**
   * Formato de Pgc Dgp Mop
   *
   * @param {string} cadenaValidacion 
   * @param {number}  charNumber
   * @param {string}  siglas
   * @return {string}  formato
   */
  public static formatPgcMop(cadenaValidacion: string, charNumber: number, siglas: string): string {
    let numberPart;
    let lastLetter = MASK_EMPTY;
    if (this.isDigit(cadenaValidacion.charAt(cadenaValidacion.length - 1))) {
      numberPart = cadenaValidacion.substring(charNumber, cadenaValidacion.length);
    } else {
      numberPart = cadenaValidacion.substring(charNumber, cadenaValidacion.length - 1);
      lastLetter = BLANK.concat(cadenaValidacion.substring(cadenaValidacion.length - 1, cadenaValidacion.length));
    }
    const numberPartAddZeros = numberPart.padStart(6, '0');
    return siglas.concat(numberPartAddZeros).concat(lastLetter);
  }

  /**
   * Starts With Ea Et Fn Mf
   *
   * @param {string} cadenaValidacion 
   * @return {boolean} boolean
   */
  public static startsWithEaEtFnMf(cadenaValidacion: string): boolean {
    return cadenaValidacion.startsWith(EJERCITO_TIERRA)
            || cadenaValidacion.startsWith(FUERZAS_NAVALES)
            || cadenaValidacion.startsWith(MINISTERIO_FOMENTO)
            || cadenaValidacion.startsWith(EJERCITO_AIRE);
  }

  /**
   * Starts With Pgc Dgp Mop
   *
   * @param {string} cadenaValidacion 
   * @return {boolean} boolean
   */
  public static startsPgcMop(cadenaValidacion: string): boolean {
    return cadenaValidacion.startsWith(GUARDIA_CIVIL)
              || cadenaValidacion.startsWith(MINISTERIO_OBRAS_PUBLICAS);
  }

  /**
   * Formato de Dirección General de Policía
   * @param {string} cadenaValidacion
   * @returns {string}
   */
  public static formatDgp(cadenaValidacion: string): string {
    let numberPart = '';
    let lastLetter = '';
    const onlyLettersPattern = /^[a-zA-Z]+$/;

    if (onlyLettersPattern.test(cadenaValidacion.slice(-2))) {
      // 2 last digits are letters
      numberPart = cadenaValidacion.substring(3, cadenaValidacion.length -2);
      lastLetter = cadenaValidacion.substring(cadenaValidacion.length -2, cadenaValidacion.length);
    } else if (onlyLettersPattern.test(cadenaValidacion.slice(-1))) {
      // Last digit is letter
      numberPart = cadenaValidacion.substring(3, cadenaValidacion.length -1);
      lastLetter = ` ${ cadenaValidacion.substring(cadenaValidacion.length -1, cadenaValidacion.length)}`;
    } else {
      numberPart = cadenaValidacion.substring(3, cadenaValidacion.length);
    }

    const numberPartAddZeros = numberPart.padStart(6, '0');
    return DIRECCION_GENERAL_POLICIA + numberPartAddZeros + lastLetter;
  }

  /**
   * Formato de Aena
   *
   * @param {string} cadenaValidacion 
   * @param {string} charNumber
   * @return {string} formato
   */
  public static formatAena(cadenaValidacion: string, charNumber: number): string {
    const lettersPartIni = cadenaValidacion.substring(0, charNumber);
    const cadenaSinSiglas = cadenaValidacion.substring(charNumber, cadenaValidacion.length);
    let lastLetters = MASK_EMPTY;
    let numberPart = MASK_EMPTY;
    for (let index = 0; index < cadenaSinSiglas.length; index++) {
      const caracter = cadenaSinSiglas.charAt(index);
      if (this.isDigit(caracter)) {
        numberPart += caracter;
               
      } else {
        lastLetters += caracter;
      }
    }
 
    const numberPartAddZeros = numberPart.padStart(6, '0');
    const firtsLettersFormat = lettersPartIni.padEnd(3, BLANK);
    const lastLettersFormat = lastLetters.padStart(2, BLANK);
    return firtsLettersFormat.concat(numberPartAddZeros).concat(lastLettersFormat);
  }

  /**
   * Recupera num letras.
   *
   * @param {string} cadenaValidacion
   * @param {number} contadorMat 
   * @param {number} hasta 
   * @param {string[]} matriculaCICOS 
   * @param {number} repeticiones
   */
  public static recuperaNumLetras(cadenaValidacion: string,
    contadorMat: number, hasta: number, matriculaCICOS: string[], repeticiones: number): void {
    let caracterIntroducido;
    let validado = true;
    let numLetras = 0;
    let aux;
    let de = 0;
    for (de = contadorMat; de < hasta; de++) {
      if (de < cadenaValidacion.length) {
        caracterIntroducido = cadenaValidacion.charAt(de);
        if (this.isDigit(caracterIntroducido)) {
          numLetras++;
        }
      } else {
        break;
      }
    }
    validado = numLetras > 0;
    if (validado) {
      aux = cadenaValidacion.substring(contadorMat, de).trim();
      while (aux.length < repeticiones) {
        aux = BLANK + aux.trim();
      }
      matriculaCICOS.push(aux);
    }
  }

  /**
   * Recupera long tipo prefijo.
   *
   * @param {string} cadenaValidacion
   * @param {number} contadorMat 
   * @param {number} hasta 
   * @param {string[]} matriculaCICOS 
   * 
   * @returns {number}
   */
  public static recuperaLongTipoPrefijo(cadenaValidacion: string,
    contadorMat: number, hasta: number, matriculaCICOS: string[]): number {
    let contadorMatPrefijo = 0;

    //("(EA|ET|FN|MF).*")) {
    if ((/([A-Z]{2}|[A-Z]{2}(\\s))(\\d*)([A-Z]{0,2}|(\\s)[A-Z])/).test(cadenaValidacion)) {
      matriculaCICOS.push(cadenaValidacion.substring(contadorMat, hasta - 1) + BLANK);
      contadorMatPrefijo = hasta - 1;
    } else if ((/[A-Z]{3}(\\d*)([A-Z]{0,2}|(\\s)[A-Z])/).test(cadenaValidacion)) {
      //("(DGP|MMA|MOP|PGC|PME|PMM).*"))
      matriculaCICOS.push(cadenaValidacion.substring(contadorMat, hasta));
      contadorMatPrefijo = hasta;
    }
    return contadorMatPrefijo;
  }


  /**
   * Recupera long num.
   *
   * @param {string} cadenaValidacion
   * @param {number} contadorMat 
   * @param {number} hasta 
   * @param {string[]} matriculaCICOS 
   * @param {number} repeticiones
   * @return {number} the int
   */
  public static recuperaLongNum(cadenaValidacion: string,
    contadorMat: number, hasta: number, matriculaCICOS: string[], repeticiones: number): number {
    let de = 0;
    let caracterIntroducido;
    let numNumeros = 0;
    let validado = true;
    let aux;
    let contadorMatLongNum = 0;
    for (de = contadorMat; de < hasta; de++) {
      if (de < cadenaValidacion.length) {
        caracterIntroducido = cadenaValidacion.charAt(de);
        if (this.isDigit(caracterIntroducido)) {
          numNumeros++;
        } else if (!caracterIntroducido.includes(BLANK)) {
          break;
        }
      } else {
        break;
      }
    }
    if (numNumeros === 0) {
      validado = false;
    }
    if (validado) {
      aux = cadenaValidacion.substring(contadorMat, de);
      while (aux.length < repeticiones) {
        aux = `0${ aux.trim()}`;
      }
      matriculaCICOS.push(aux);
      contadorMatLongNum = de;
    }
    return contadorMatLongNum;
  }
}

