
export interface PaginationData<T> {
  items: T[];
  currentPage: number;
  isLastPage: boolean | undefined;
  status: IntermediariesTableStatus;
}

export enum IntermediariesTableStatus {
  NOT_LOADED = 'NOT_LOADED',
  LOADING = 'LOADING',
  LOADED = 'LOADED',
  ERROR_LOADING = 'ERROR_LOADING'
}

/** 
 * Model qb-employee-modal-intermediaries
 */
class QbEmployeeModalIntermediariesModel {

  public intermediaryName: string = '';

  public intermediaryCode: string = '';

  public showModalIntermediaries?: boolean = false;
  
}

export default QbEmployeeModalIntermediariesModel;
