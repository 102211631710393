<template>
  <div v-if="restDocumentsList && restDocumentsList.length > 0">
    <div>
      <ea-heading level="5" class="d-display-inline">
        {{$t('quoteBuyGenericFlow.titleDocumentation')}}
      </ea-heading>
      
      <ea-tooltip placement="top" class="d-display-inline">
        <ea-icon
          class="m-l-8"
          icon="z-info" />
        <div slot="content">
          {{ $t('quoteBuyGenericFlow.generateDocumentation.tooltip.documentationEmit') }}
        </div>
      </ea-tooltip>
    </div>
   
    <ea-form :model="model">
      <div class="d-display-flex d-direction-column">
        <div v-for="(document, index) in restDocumentsList"
          :key="index">
            <ea-form-item
              v-if="document.nombreDocumento &&
              model.postalDigitalSelections &&
              model.postalDigitalSelections[document.nombreDocumento] &&
              document.indicadorEnvioPostal"
              prop="radioBtnSelectedDocument"
              class="radiogroup-horizontal m-b-0"
              :showLabel="false"
            >
              <ea-radio-group direction="vertical"
                v-model="model.postalDigitalSelections[document.nombreDocumento]"
                @change="onDocumentSelectionChange(document)"
              >
                <ea-radio label="Digital">
                  {{document.nombreDocumento + ' ' + $t('quoteBuyGenericFlow.documentation.documentDigitalInsurance')}}
                  <ea-link class="m-l-16"
                    @click="showDocumentPreview(document)" >
                      {{ $t('common.link.preview') }}
                  </ea-link>
                </ea-radio>

                <ea-radio label="Postal">
                  {{document.nombreDocumento + ' ' + $t('quoteBuyGenericFlow.documentation.documentPostalInsurance')}}
                </ea-radio>
              </ea-radio-group>
            </ea-form-item>

            <div v-if="document.nombreDocumento && (
              !model.postalDigitalSelections ||
              !model.postalDigitalSelections[document.nombreDocumento]
            )"
              class="p-t-16"
              :class="document.indicadorEnvioPostal ? 'p-b-16' : 'p-b-14'">
              <span class="t-size-small">
                  {{document.nombreDocumento}}
              </span>
              <ea-link class="m-l-16"
                v-if="!isDocumentInNoPreviewList(document)"
                @click="showDocumentPreview(document)" >
                  {{ $t('common.link.preview') }}
              </ea-link>
            </div>

            <div v-if="!document.indicadorEnvioPostal &&
              document.nombreDocumento &&
              model.postalDigitalSelections[document.nombreDocumento]">
              <p class="t-size-small">
                {{document.nombreDocumento + ' ' + $t('quoteBuyGenericFlow.documentation.documentDigitalInsurance')}}
              </p>
            </div>
        </div>
      </div>
    </ea-form>

    <ea-dialog
      :title="$t('quoteBuyGenericFlow.issueOffer.postalSendingConfirmation.title')"
      :visible.sync="saveEnvironmentVisible"
      size="small"
      @close="saveEnvironmentVisible = false"
      type="passive"
    >
      <p>{{ $t('quoteBuyGenericFlow.issueOffer.postalSendingConfirmation.description') }}</p>

      <div slot="footer" class="d-display-flex d-justify-flex-end">
        <ea-button type="secondary" class="m-r-40" @click="saveEnvironmentVisible = false">
          {{ $t('common.label.no') }}
        </ea-button>
        <ea-button type="primary" @click="saveEnvironment">
          {{ $t('common.label.yes') }}
        </ea-button>
      </div>
    </ea-dialog>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import {
  Component, Prop, Watch
} from 'vue-property-decorator';

import {
  mixins
} from 'vue-class-component';

import {
  EABusinessComponent
} from '@zurich-es-npm/ea-front-web-core';

import QbDocumentationInsuranceEmitModel from './qb-documentation-insurance-emit-model';
import {
  GetIssueOfferDataResponseListaDocumentos
} from '@/services/V1/quoteAndBuy/getIssueOfferDataOperation/post';
import {
  EmitAndSendOfferRequestEntradaSeleccionesPostalDigitalSeleccionPostalDigitalEnum as SeleccionPostalDigitalEnum
} from '@/services/V1/quoteAndBuy/emitAndSendOfferOperation/post';

@Component({
  name: 'qb-documentation-insurance-emit'
})

/**
 * Business Component qb-documentation-insurance-emit
 */
export default class QbDocumentationInsuranceEmitBusiness extends mixins<
EABusinessComponent<QbDocumentationInsuranceEmitModel>
>(EABusinessComponent) {
  @Prop({
    'default': () => [],
  })
    restDocumentsList?: GetIssueOfferDataResponseListaDocumentos[];

  environmentModalDocName?: string;

  public saveEnvironmentVisible: boolean = false;
  
  @Prop({
    'default': () => [],
  })
    documentTextCodesNoPreview?: string[];

  /**
   * Hook on create
   */
  created() {
    this.initialisePostalDigitalSelections();
  }

  /**
   * Initialises postal /digital selection record
   */
  initialisePostalDigitalSelections() {
    this.restDocumentsList?.forEach(doc => {
      if (doc.puedeDigital && doc.indicadorVisualizacion && doc.nombreDocumento) {
        this.model.postalDigitalSelections[doc.nombreDocumento] = SeleccionPostalDigitalEnum.Digital;
      }
    });
  }

  /**
   * Handles radiobutton change
   * Shows modal when postal document is selected
   * @param {GetIssueOfferDataResponseListaDocumentos} document
   */
  public onDocumentSelectionChange(document: GetIssueOfferDataResponseListaDocumentos) {
    Vue.nextTick(() => {
      if (
        document.nombreDocumento &&
        this.model.postalDigitalSelections[document.nombreDocumento] === SeleccionPostalDigitalEnum.Postal
      ) {
        this.environmentModalDocName = document.nombreDocumento;
        this.saveEnvironmentVisible = true;
      }

      this.update();
    });
  }

  /**
   * Handles 'yes' button on saveEnvironment modal
   */
  public saveEnvironment() {
    if (this.environmentModalDocName) {
      this.model.postalDigitalSelections[this.environmentModalDocName] = SeleccionPostalDigitalEnum.Digital;
      this.environmentModalDocName = undefined;
    }
    this.saveEnvironmentVisible = false;
    this.update();
  }

  /**
   * Emits event for document preview
   * @param {GetIssueOfferDataResponseListaDocumentos} document 
   */
  public showDocumentPreview(document: GetIssueOfferDataResponseListaDocumentos) {
    this.$emit('showDocumentPreview', document);
  }

  /**
   * Watcher for documentTypeList prop
   */
  @Watch('restDocumentsList', {
    immediate: true,
  })
  onRestDocumentsListChange() {
    this.initialisePostalDigitalSelections();
  }

  /**
   * Checks if received document is in documentTextCodesNoPreview prop array
   * @param {GetIssueOfferDataResponseListaDocumentos} document
   * @returns {boolean}
   */
  isDocumentInNoPreviewList(document: GetIssueOfferDataResponseListaDocumentos): boolean {
    return !!this.documentTextCodesNoPreview?.some(docTextCode => docTextCode === document.codigoDocumentoTexto);
  }

}
</script>
