import {
  GetWarrantiesResponseDataLanzadorPorValor,
} from '@/services/V1/quoteAndBuy/getWarrantiesOperation/post';

export type ValueType = string | number | boolean;

export interface WarrantyProperties {
  readonly: boolean;
  placeholder: string;
  precision?: number;
  label?: string;
  checked?: boolean;
  class?: string;
}

export interface EventChange {
  emitter: WarrantyElement;
  receiver: WarrantyElement;
  action: WarrantyAction;
}

export interface WarrantyElement {
  warrantyCode: string;
  proposalCode: string;
  elementCode?: string;
}

export interface WarrantyAction {
  type: ActionType;
  value?: ValueType | WarrantyElement;
}

export enum ActionType {
  CHANGE_HIDDEN = 'changeHidden',
  CHANGE_READONLY = 'changeReadOnly',
  CHANGE_VALUE = 'changeValue',
  COPY_VALUE = 'copyValue',
  NO_ACTION = 'noAction',
}

export interface TriggerType {
  triggerByCheck?: GetWarrantiesResponseDataLanzadorPorValor[];
  triggerByValue?: GetWarrantiesResponseDataLanzadorPorValor[];
}

export type TriggerList = GetWarrantiesResponseDataLanzadorPorValor;

export enum DatosAjustesPrimasEnum {
  TipoComision = 'TCTIPCOM',
  Descuento = 'POREDWEB',
  Campana = 'NOMMKCAM',
  DescuentoMultiProduct = 'POREDMPP',
  DescuentoCampana = 'POREDECM',
  DescuentoMax = 'POMAXRD'
}

export enum AjustesPrimasRecargoDescuentoEnum {
  Recargo = '1',
  Descuento = '-1'
}

export type WarrantyElementComponentType =
  'ea-checkbox' | 'ea-select' | 'ea-input-currency' | 'ea-date-picker' | 'ea-input-number' | 'ea-input-text';
