<template>
  <div>
    <ea-card class="t-bg-color-primary-20 m-b-16"
      v-for="(group, index) in groupedVehicleList" :key="index">
      <ea-row>
        <ea-col :span="24">
          <ea-heading level="6" class="m-b-16">
            {{$t('fleets.fleetsFlow.generateDocumentationView.groupTitlePrefix') + ' ' +
          (group.descripcionAgrupacionFlota ? group.descripcionAgrupacionFlota : '')}}
          </ea-heading>
        </ea-col>
        <ea-col>
          <ea-row>
            <ea-col :span="24"
              class="d-display-flex d-justify-flex-start d-align-items-end d-direction-row">
              <ea-col class="p-l-0 t-align-left d-display-flex d-justify-flex-end d-align-items-end d-direction-column">
                <span class="t-size-small">
                  {{
                    (group.listaVehiculos && group.listaVehiculos.length ?
                    group.listaVehiculos.length.toString() : '') + ' ' +
                    $t('fleets.fleetsFlow.generateDocumentationView.numVehicles')
                  }}
                </span>
              </ea-col>
              <ea-col :span="24" class="t-align-right">
                <ea-row>
                  <ea-col>
                    <span class="t-weight-bold t-size-small">
                      {{ $t('fleets.fleetsFlow.generateDocumentationView.yearlyTotal') }}
                    </span>
                  </ea-col>
                </ea-row>
                <ea-row>
                  <ea-col><span class="t-weight-bold t-size-large">{{ groupTotals[index] }}</span></ea-col>
                </ea-row>
              </ea-col>
            </ea-col>
          </ea-row>
        </ea-col>
      </ea-row>
    </ea-card>
    <ea-card class="t-bg-color-primary-20 m-b-16">
      <ea-row>
        <ea-col :span="24"
          class="d-display-flex d-justify-flex-start d-align-items-end d-direction-row">
          <ea-col class="p-l-0 t-align-left d-display-flex d-justify-flex-end d-align-items-end d-direction-column">
            <span class="t-size-small">
              {{
                countVehicles + ' ' + $t('fleets.fleetsFlow.generateDocumentationView.numVehicles')
              }}
            </span>
          </ea-col>
          <ea-col :span="24" class="t-align-right">
            <ea-row>
              <ea-col>
                <span class="t-weight-bold t-size-small">
                  {{ $t('fleets.fleetsFlow.generateDocumentationView.yearlyTotal') }}
                </span>
              </ea-col>
            </ea-row>
            <ea-row>
              <ea-col><span class="t-weight-bold t-size-large">{{ allGroupsTotal }}</span></ea-col>
            </ea-row>
          </ea-col>
        </ea-col>
      </ea-row>
    </ea-card>
  </div>
</template>

<script lang="ts">
import {
  Component, Prop, Vue, Watch
} from 'vue-property-decorator';
import {
  GetGroupedVehicleListResponseListaAgrupaciones
} from '@/services/V1/fleets/getGroupedVehicleListOperation/post';
import Utils from '@/utils/utils';

@Component({
  name: 'fleets-documentation-group-vehicle-totals'
})

/**
 * Business Component fleets-documentation-group-vehicle-totals
 */
export default class FleetsDocumentationGroupVehicleTotals extends Vue {

  @Prop()
    groupedVehicleList!: GetGroupedVehicleListResponseListaAgrupaciones[];

  groupTotals: string[] = [];
  
  allGroupsTotal: string = '';

  countVehicles: number = 0;

  /**
   * Hook on create
   */
  async created() {
    this.calcTotals();
  }

  /**
   * Calculate group prize total and all groups total
   */
  calcTotals() {
    let countVehicles = 0;
    let allGroupsTotal = 0;
    const groupTotals = [];
    
    for (const group of this.groupedVehicleList) {
      if (group.listaVehiculos) {
        let groupTotal = 0;
        for (const vehiculo of group.listaVehiculos) {
          if (vehiculo.importeTotal) {
            groupTotal += vehiculo.importeTotal;
          }
          countVehicles++;
        }
        groupTotals.push(groupTotal);
        allGroupsTotal += groupTotal;
      }
    }
    this.groupTotals = groupTotals.map(group => Utils.digitsFormatter(this.$n(group)));
    this.allGroupsTotal = Utils.digitsFormatter(this.$n(allGroupsTotal));
    this.countVehicles = countVehicles;
  }

  /**
   * Updates model
   */
  @Watch('groupedVehicleList', {
    deep: true,
    immediate: true,
  })
  onGroupedVehicleListChange() {
    this.calcTotals();
  }
}
</script>
