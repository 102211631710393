<template>
  <div>
    <p class="t-weight-normal m-b-0 m-l-24">
      <span>{{ `${$t('quoteBuyGenericFlow.generateDocumentation.field.constructionTotalValue')}: `}}</span>
      <span>{{
        constructionTotalValue ? `${getRate(constructionTotalValue)} €` : '-'
      }}</span>
    </p>
  </div>
</template>

<script lang="ts">
import {
  WarrantiesProposal
} from '@/business-components/qb-documentation-select-proposal/qb-documentation-select-proposal-model';
import QbProductoAseguradoTrConstruccionMontajeModel
  // eslint-disable-next-line max-len
  from '@/business-components/qb-producto-asegurado-tr-construccion-montaje/qb-producto-asegurado-tr-construccion-montaje-model';
import Vue from 'vue';
import {
  Component, Prop
} from 'vue-property-decorator';

@Component({
  name: 'trc-documentation-proposal-card'
})

/**
 * Proposal card component
 */
export default class TrcDocumentationProposalCardComponent extends Vue {

  @Prop({
    required: true
  })
    warrantiesProposal!: WarrantiesProposal;

  @Prop({
    required: true
  })
    productModel!: QbProductoAseguradoTrConstruccionMontajeModel;

  @Prop({
    required: true
  })
    getRate!: Function;

  /**
   * Getter for construction total value
   */
  get constructionTotalValue(): string | number {
    return this.productModel?.technicalConsiderations.formValues.totalConstructionValue ?? '';
  }

}
</script>
