<template>
  <div class="slide d-display-flex d-direction-column d-justify-space-between h-100">
        <div>
          <h2 v-if="!hideFirstTitle"  class="t-color-primary">{{ $t('onboarding.cardPayment.fifthSlide.title') }}</h2>

          <i18n path="onboarding.cardPayment.fifthSlide.descriptionFirst.text" tag="p" class="m-b-0">
            <span class="t-weight-semibold">{{ $t('onboarding.cardPayment.fifthSlide.descriptionFirst.bold1') }}</span>
            <span class="t-weight-semibold">{{ $t('onboarding.cardPayment.fifthSlide.descriptionFirst.bold2') }}</span>
            <span class="t-weight-semibold">{{ $t('onboarding.cardPayment.fifthSlide.descriptionFirst.bold3') }}</span>
          </i18n>

          <i18n path="onboarding.cardPayment.fifthSlide.descriptionSecond.text" tag="p">
            <span class="t-weight-semibold">{{ $t('onboarding.cardPayment.fifthSlide.descriptionSecond.bold1') }}</span>
            <span class="t-weight-semibold">{{ $t('onboarding.cardPayment.fifthSlide.descriptionSecond.bold2') }}</span>
          </i18n>
        </div>
        
        <div
          class="d-display-flex d-direction-column d-align-items-center
            d-justify-center p-a-32 w-100 t-bg-color-gray-20"
        >
          <img
            class="m-b-12"
            :src="require(`@/assets/images/onboarding/cardpayment/onboarding5-1.png`)"
            :alt="$t('onboarding.cardPayment.fifthSlide.title').toString()"
          >

          <div class="w-25 separator t-color-primary m-b-12"></div>
          
          <img
            :src="require(`@/assets/images/onboarding/cardpayment/onboarding5-2.png`)"
            :alt="$t('onboarding.cardPayment.fifthSlide.title').toString()"
          >
        </div>
      </div>
</template>
<script lang="ts">

import Vue from 'vue';
import {
  Component, Prop
} from 'vue-property-decorator';

@Component({
  name: 'card-payment-onboarding-5'
})

/**
 * Comunidades onboarding component
 */
export default class CardPaymentOnboarding5Component extends Vue {

  @Prop({
    required: false,
    'default': () => false,
  })
    hideFirstTitle?: boolean;
}
</script>
