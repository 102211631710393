<template>
  <div>
    <ea-row :class="`collapse-header m-0 p-a-16 columnsSpan${columnsSpan}`">
      <ea-col :span="columnsSpan">
        
        <template v-if="!isWarrantyCheckboxShown">&nbsp;</template>

        <ea-checkbox
          v-else
          @change="handlePrimaryChange($event)"
          label=""
          :checked="primaryChecked"
          :indeterminate="indeterminate"
          :readonly="primaryDisabled"
          :value="primaryChecked"
        />
        <div class="t-weight-bold collapse__title t-size-small">
          {{ title }}
          <ea-tooltip placement="top" class="d-display-inline" v-if="$te(tooltipLabel)">
            <ea-icon
              class="m-l-8"
              icon="z-info" />
            <div slot="content">
              {{ $t(tooltipLabel) }}
            </div>
          </ea-tooltip>
        </div>
      </ea-col>

      <!-- Iterating over ALL proposals -->
      <ea-col v-for="(proposalCode, index) in allProposalCodes" :key="index" :span="columnsSpan">

        <!-- If proposal does not exist, print empty blank space -->
        <template v-if="getIndexOfProposal(proposalCode) === -1">&nbsp;</template>

        <!-- If exist beforeVersion and mock data the checkbox must be disabled -->
        <ea-checkbox
          v-else
          :disabled="getSecondaryChecked(proposalCode) === undefined ? true : false"
          @change="handleSecondaryChange($event, getIndexOfProposal(proposalCode))"
          label=""
          :checked="getSecondaryChecked(proposalCode)"
          :readonly="getSecondaryDisabled(proposalCode)"
          :value="getSecondaryChecked(proposalCode)"
        />
        
        <!-- Print collapse icon if last column -->
        <div v-if="proposalCode === lastProposalCode && warrantyHasVisibleElements">
          <div @click="toggleCollapse">
            <ea-icon :icon="iconArrowClass"/>
          </div>
        </div>

      </ea-col>
    </ea-row>

    <div v-bind:class="{'hidden': collapseHidden}" class="collapse-body">
      <!-- Here are printed warranty elements -->
      <slot />
    </div>

  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import {
  Component, Prop, Watch
} from 'vue-property-decorator';

@Component({
  name: 'zz-collapse',
})

/**
 * Presentational Component zz-collapse
 */
export default class ZzCollapse extends Vue {
  @Prop({
    required: true,
  })
    columnsSpan!: number;

  @Prop({
    required: true,
  })
    title!: string;


  @Prop({
    required: true,
  })
    tooltipLabel!: string;

  @Prop({
    required: true,
  })
    columnsChecked!: boolean[];

  @Prop({
    required: true,
  })
    columnsDisabled!: boolean[];

  @Prop({
    required: true,
  })
    columnsCodes!: string[];

  @Prop()
    existBeforeVersion?: boolean;

  @Prop({
    required: true
  })
    allProposalCodes!: string[]; // Needed to know which proposals (columns) are available and which not

  @Prop({
    required: false,
    'default': () => false,
  })
    warrantyHasVisibleElements!: boolean; // If warranty has no elements => Collapse arros won't show

  primaryChecked?: boolean = false;

  primaryDisabled?: boolean = false;

  secondariesChecked: (boolean | undefined)[] = [];

  secondariesDisabled?: boolean[] = [];

  collapseHidden = false;

  collapseDisabled = false;

  /**
   * Get the indeterminate attribute value
   */
  get indeterminate() {
    const checkedSecondaryCheckboxes = this.secondariesChecked.filter(secondaryChecked => secondaryChecked);

    return (
      checkedSecondaryCheckboxes &&
      checkedSecondaryCheckboxes?.length > 0 &&
      checkedSecondaryCheckboxes?.length !== this.secondariesChecked.length
    );
  }

  /**
   * Get the last proposalCode
   * @returns {string}
   */
  get lastProposalCode(): string {
    return this.allProposalCodes[this.allProposalCodes.length - 1];
  }

  /**
   * Get icon for collapse arrow
   */
  get iconArrowClass() {
    return this.collapseHidden ? 'z-arrow-down' : 'z-arrow-up';
  }

  /**
   * Created hook
   */
  created() {
    this.primaryChecked = this.columnsChecked.every(columnsChecked => columnsChecked);
    this.primaryDisabled = this.columnsDisabled.every(columnDisabled => columnDisabled);
    this.secondariesChecked = this.columnsChecked;
    this.secondariesDisabled = this.columnsDisabled;
    this.existSecondColumn();
  }


  /**
   * Add mock data for second column if exist beforeVersion
   *
   */
  existSecondColumn() {
    if (this.secondariesChecked.length === 1 && this.existBeforeVersion) {
      this.secondariesChecked.push(undefined);
    }
  }

  /**
   * Handle primary checkbox change
   * @param {boolean} value
   */
  handlePrimaryChange(value: boolean) {
    this.primaryChecked = value;

    if (this.secondariesChecked) {
      this.secondariesChecked = this.secondariesChecked.map((previousValue, index) => {
        if ((this.secondariesDisabled && this.secondariesDisabled[index]) || previousValue === undefined) {
          return previousValue;
        } else {
          this.$emit('checkChange', {
            value, columnCode: this.columnsCodes[index]
          });
          return value;
        }
      });
    }
  }

  /**
   * Handle secondaries checkboxes change
   * @param {boolean} value
   * @param {number} id
   */
  handleSecondaryChange(value: boolean, id: number) {
    this.secondariesChecked = this.secondariesChecked.map((secondaryChecked, index) => {
      if (index === id) {
        this.$emit('checkChange', {
          value, columnCode: this.columnsCodes[index]
        });
        return value;
      } else {
        return secondaryChecked;
      }
    });

    const allSecondariesChecked = this.secondariesChecked.every(secondaryChecked => secondaryChecked);

    this.primaryChecked = allSecondariesChecked ? true : false;
  }

  /**
   * Toggle collapse items
   */
  toggleCollapse() {
    this.collapseHidden = !this.collapseHidden;
  }

  /**
   * IMPORTANT! Get the working index of the given proposal code.
   * @param {string} proposalCode
   * @returns {number}
   */
  getIndexOfProposal(proposalCode: string): number {
    return this.columnsCodes.indexOf(proposalCode);
  }

  /**
   * Get secondary checked by its `proposalCode`
   * @param {string} proposalCode
   * @returns {boolean}
   */
  getSecondaryChecked(proposalCode: string): boolean {
    return !!this.secondariesChecked[this.getIndexOfProposal(proposalCode)];
  }

  /**
   * Get secondary disabled by its `proposalCode`
   * @param {string} proposalCode
   * @returns {boolean}
   */
  getSecondaryDisabled(proposalCode: string): boolean {
    return !!this.secondariesDisabled && this.secondariesDisabled[this.getIndexOfProposal(proposalCode)];
  }

  /**
   * Gets if checkbox is shown for each proposal
   *  True if we have more than one proposal
   */
  get isWarrantyCheckboxShown() {
    return this.allProposalCodes?.length > 1;
  }

  /**
   * Watcher for primaryChecked prop
   */
  @Watch('primaryChecked')
  onPrimaryCheckedChange() {
    if (this.primaryChecked) {
      this.collapseHidden = false;
    } else {
      this.collapseHidden = true;
    }
  }

  /**
   * Watcher for secondariesChecked prop
   */
  @Watch('secondariesChecked')
  onSecondariesCheckedChange() {
    if (this.secondariesChecked.some(secondaryChecked => secondaryChecked)) {
      this.collapseHidden = false;
    } else {
      this.collapseHidden = true;
    }
  }
}
</script>

<style scoped lang="scss">
// Extendable elements
%align-items-horizontaly {
  align-items: center;
  display: flex;
}

.collapse-header {
  .el-col {
    @extend %align-items-horizontaly;
    .el-checkbox {
      @extend %align-items-horizontaly;
      width: 250px;
      margin: auto;
    }
    &:first-of-type {
      @extend %align-items-horizontaly;
    }
    &:last-of-type {
      @extend %align-items-horizontaly;
      justify-content: space-between;
      .ea-icon {
        @extend %align-items-horizontaly;
        cursor: pointer;
        justify-content: center;
        margin-left: -20px;
        &::before {
          font-size: 1.2rem;
          color: #039;
        }
      }
    }
    &:first-child {
      .el-checkbox {
        width: auto;
        margin: 0;
      }
    }
  }
}

.collapse-body {
  border-top: 1px solid #131313;

  &.hidden {
    display:none;
  }
}
</style>
