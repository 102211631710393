<template>
  <div>
    <ea-web-app-layout
      class="default-public-layout"
      :headerTitle="$t('layout.header.title')"
      :footerTitle="this.$t('layout.footer.copyright')"
      :showUser="userName? true: false"
    >
    </ea-web-app-layout>
  </div>
</template>

<script lang='ts'>
import {
  Vue,
  Component
} from 'vue-property-decorator';

import {
  EaWebAppLayout
} from '@zurich-es-npm/ea-front-web-core';

@Component({
  components: {
    'ea-web-app-layout': EaWebAppLayout
  }
})

/**
 * Application default public layout
 */
export default class DefaultPublicLayout extends Vue {

  /** 
   * Get user name formated
   * @returns {string} - User name
   */
  get userName() {
    return this.$store.getters['security/userName'];
  }

}
</script>
