<template>
    
    <ea-form class="p-a-16" slot="cardbody" ref="form">
      <ea-row>
        <ea-col :span="8">
          <ea-form-item :label="$t('fleets.fleetsQueryPolicyFlow.vehicleInfoLabels.plateNumber')">
            <ea-input-text
              :readonly="true"
              :value="vehicle.numeroMatriculaSinValidaciones"
            ></ea-input-text>
          </ea-form-item>
        </ea-col>
        <ea-col :span="8">
          <ea-form-item :label="$t('fleets.fleetsQueryPolicyFlow.vehicleInfoLabels.group')">
            <ea-input-text
              :readonly="true"
              :value="groupDescription"
            ></ea-input-text>
          </ea-form-item>
        </ea-col>
        <ea-col :span="8">
          <ea-form-item :label="$t('fleets.fleetsQueryPolicyFlow.vehicleInfoLabels.use')">
            <ea-input-text
              :readonly="true"
              :value="vehicleUseDescription"
            ></ea-input-text>
          </ea-form-item>
        </ea-col>
        <ea-col :span="8">
          <ea-form-item :label="$t('fleets.fleetsQueryPolicyFlow.vehicleInfoLabels.brand')">
            <ea-input-text
              :readonly="true"
              :value="vehicle.descripcionMarcaVehiculo"
            ></ea-input-text>
          </ea-form-item>
        </ea-col>
        <ea-col :span="8">
          <ea-form-item :label="$t('fleets.fleetsQueryPolicyFlow.vehicleInfoLabels.model')">
            <ea-input-text
              :readonly="true"
              :value="vehicle.descripcionModeloVehiculo"
            ></ea-input-text>
          </ea-form-item>
        </ea-col>
        <ea-col :span="8">
          <ea-form-item :label="$t('fleets.fleetsQueryPolicyFlow.vehicleInfoLabels.version')">
            <ea-input-text
              :readonly="true"
              :value="vehicle.descripcionVersionVehiculo"
            ></ea-input-text>
          </ea-form-item>
        </ea-col>
        <ea-col :span="8">
          <ea-form-item :label="$t('fleets.fleetsQueryPolicyFlow.vehicleInfoLabels.package')">
            <ea-input-text
              :readonly="true"
              :value="$t(`fleets.fleetsFlow.fleetPackagesWarrantiesModal.codes.${vehicle.codigoPaquete}`)"
            ></ea-input-text>
          </ea-form-item>
        </ea-col>
        <ea-col :span="8">
          <ea-form-item :label="$t('fleets.fleetsQueryPolicyFlow.vehicleInfoLabels.accesoryValue')">
            <ea-input-currency
              :readonly="true"
              :value="vehicle.valorAccesoriosTotal"
            ></ea-input-currency>
          </ea-form-item>
        </ea-col>
      </ea-row>
      <!-- SECCIÓN GARANTÍAS INCLUIDAS -->
      <ea-row
        v-if="warranties">
        <ea-col :span="16">
          <ea-row>
            <ea-col :span="24">
              <div class="t-weight-bold">
                {{$t(`fleets.fleetsFlow.fleetPackagesWarrantiesModal.warrantiesTitle`)}}
              </div>
            </ea-col>
          </ea-row>
          <ea-row>
            <ea-col :span="12"
              :key="warranty.codigoGarantia"
              v-for="warranty of warranties.garantiasIncluidas">
            <div class="m-b-4 m-r-12">
                <span class="m-b-4">
                  - {{$t(`fleets.fleetsFlow.fleetPackagesWarrantiesModal.warrantiesCodes.${warranty.codigoGarantia}`)}}
                </span>
                <template v-if="warranty.elementos && warranty.elementos.length
                  && !warranties.garantiasOpcionales.find(
                        optionalWarranty =>
                          optionalWarranty.codigoGarantia === warranty.codigoGarantia &&
                          optionalWarranty.garantiaSeleccionada)">
                  <ea-form
                    v-for="element of warranty.elementos"
                    :key="element.codigoElemento"
                    :model="element">
                      <ea-form-item
                        prop="valorElemento"
                        :showLabel="false"
                        required>
                        <ea-select
                          :readonly="true"
                          :placeholder="$t('common.label.select')"
                          v-model="element.valorElemento"
                        >
                          <ea-option
                            v-for="restriction in element.tablaRestricciones"
                            :key="restriction.valorRestriccion"
                            :label="restriction.nombreRestriccion"
                            :value="restriction.valorRestriccion"
                          />
                        </ea-select>
                      </ea-form-item>
                  </ea-form>
                </template>
             </div>
            </ea-col>
          </ea-row>
        </ea-col>
      </ea-row>
      <!-- SECCIÓN GARANTÍAS OPCIONALES -->
      <ea-row
        v-if="hasOptionalWarranties">
        <ea-col :span="24">
          <div class="t-weight-bold m-b-16">
          {{$t(`fleets.fleetsFlow.fleetPackagesWarrantiesModal.optionalWarranties.title`)}}
          </div>
        </ea-col>
        <ea-col :span="8" :gutter="40"
          v-for="(warranty, index) of warranties.garantiasOpcionales"
          :key="warranty.codigoGarantia+'-'+index">
          <div class="m-r-12">
              <!-- eslint-disable-next-line max-len -->
            <ea-checkbox :label="$t(`fleets.fleetsFlow.fleetPackagesWarrantiesModal.optionalWarranties.${warranty.codigoGarantia}.${warranty.claveModificadorLiteralGarantia || 'default'}`)"
              v-model="warranty.garantiaSeleccionada"
              :checked="warranty.garantiaSeleccionada"
              :readonly="true"
              v-bind:class="{ 'm-b-64': !warranty.elementos || !warranty.elementos.length }"
              class="m-b-4"
              ellipsis/>
              <template v-if="warranty.elementos && warranty.elementos.length">
                <ea-form
                  v-for="element of warranty.elementos"
                  :key="element.codigoElemento"
                  :model="element">
                    <ea-form-item
                      prop="valorElemento"
                      :showLabel="false"
                      required>
                      <ea-input-currency
                        v-if="getWarrantyFieldType(element) === 'input'"
                        :readonly="true"
                        :value="element.valorElemento"
                      />
                      <ea-input-text
                        v-else
                        :readonly="true"
                        :value="getElementValueDescription(element, warranty.codigoGarantia)"
                      />
                    </ea-form-item>
                </ea-form>
              </template>
          </div>
        </ea-col>
      </ea-row>
    </ea-form>
</template>

<script lang="ts">
import {
  VehicleUseData
} from '@/business-components/fleet-manual-upload-vehicle/fleet-manual-upload-vehicle-model';
import {
  GetGroupedVehicleListResponseListaVehiculos
} from '@/services/V1/fleets/getGroupedVehicleListOperation/post';
import {
  GetWarrantiesByPackageResponse, GetWarrantiesByPackageResponseElementos
} from '@/services/V1/fleets/getWarrantiesByPackageOperation/post';
import Vue from 'vue';
import {
  Component, Prop
} from 'vue-property-decorator';

@Component({
  name: 'fleet-policyholder-policy-info',
})

/**
 * Presentational Component fleet vehicle info
 */
export default class FleetVehicleInfo extends Vue {

  @Prop({
    required: true
  })
    warranties!: GetWarrantiesByPackageResponse;

  @Prop({
    required: true
  })
    vehicle!: GetGroupedVehicleListResponseListaVehiculos;

  @Prop({
    required: true
  })
    groupDescription!: string;

  @Prop({
    required: true
  })
    vehiclesUses!: VehicleUseData[];


  /**
   * Get vehicle use description
   * @return {boolean} 
   */
  get hasOptionalWarranties(): boolean {
    return (this.warranties.garantiasOpcionales?.length || 0) > 0;
  }

  /**
   * Get vehicle use description
   * @return {string} vehicleUse 
   */
  get vehicleUseDescription(): string {
    return this.vehiclesUses
      .find(vehicleUse => vehicleUse.value === this.vehicle.codigoUsoPorMatricula)?.name as string;
  }
 
  /**
   * Get element description value by valorElemento
   * @param {GetWarrantiesByPackageResponseElementos} element 
   * @param {string} codigoGarantia 
   * @return {string}
   */
  getElementValueDescription(element: GetWarrantiesByPackageResponseElementos, codigoGarantia: string): string {
    let value = element.valorElemento;
    if (element.tablaRestricciones?.length) {
      value = element.tablaRestricciones.find(restriction => restriction.valorRestriccion === value)?.nombreRestriccion;
    }
    return this.getModalityLabel(codigoGarantia, value as string);
  }

  /**
   * If translate is found return it, if not exist return literal
   * @param {string} codigoGarantia 
   * @param {string} defaultKey 
   * @returns {string}
   */
  getModalityLabel(codigoGarantia: string, defaultKey: string): string {
    const translateKey =
      `fleets.fleetsFlow.fleetPackagesWarrantiesModal.optionalWarranties.${codigoGarantia}.modalities.${defaultKey}`;
    if (this.$te(translateKey)) {
      return this.$t(translateKey).toString();
    }
    return defaultKey;
  }

  /**
   * Assign warrenty field type to select or input
   * @param {GetWarrantiesByPackageResponseElementos} element 
   * @returns {string}
   */
  getWarrantyFieldType(element: GetWarrantiesByPackageResponseElementos): string {
    return element.tablaRestricciones?.length ? 'select' : 'input';
  }
}
</script>
