<!-- eslint-disable max-lines -->
<template>
  <div>
    <fleet-data-header
      class="m-b-16"
      :data="model.qbNewOfferBusinessModel"
      :interveningFlow="true">
    </fleet-data-header>

    <fleet-search-policyholder
      ref="refFleetSearchPolicyholderBC"
      id="search-person"
      class="m-b-16"
      v-model="model.fleetSearchPolicyHolderModel"
      :intermediaryCode="model.qbNewOfferBusinessModel.intermediaryCode"
      :intermediaryName="model.qbNewOfferBusinessModel.intermediaryName"
      :commitContractPolicyTypes="fleetCommitContractPolicyTypes"
      :documentTypeList="showPolicyholderInfoDocumentTypes"
      :addressTypeList="addressTypeList"
      :interveningFlow="true"
      :readOnlyOperation="true">
    </fleet-search-policyholder>

    <qb-persons
      v-if="isInitialLoadComplete"
      v-model="model.personsModel"
      id="qb-persons"
      ref="personsComp"
      :documentTypeList="model.documentTypeList"
      :countryList="model.countryList"
      :allowedRoles="allowedRoles"
      :interveningFlow="true"
      :vehiclesList="vehiclesList"
      @personChanged="onPersonChanged()"
      @driversToDelete="onDriversToDelete"
      @isDriverIsNominated="onIsDriverIsNominated"
    ></qb-persons>

    <ea-row extraClass="m-t-24">
      <ea-col class="d-display-flex  d-justify-space-between">
        <ea-button type="secondary" @click="onGoBack()">
          {{ $t('common.label.back') }}
        </ea-button>
        <ea-row>
          <ea-col class="d-display-flex  d-justify-space-between">
            <ea-button type="secondary" @click="onGoToDocumentation()" v-if="isGenDocVisible">
              {{ $t('fleets.fleetsFlow.fleet-group-vehicle-table.btnGenerateDocumentation') }}
            </ea-button>
            <ea-button type="primary" @click="onGoNext()" class="m-l-16" :disabled="isNextDisabled">
              {{ $t('common.label.next') }}
            </ea-button>
          </ea-col>
        </ea-row>
      </ea-col>
    </ea-row>

    <!-- Dialog to confirm delete drivers -->
    <ea-dialog
      :visible="isConfirmDeleteDriver"
      size="small"
      :title="$t(confirmDeleteDriverTitle)"
      @close="onCancelDeletion"
      >
      <ea-paragraph
        size="medium"
        class="m-b-40 m-t-16">
        {{ $t(confirmDeleteDriverMessage) }}
      </ea-paragraph>

      <!-- Cancel and save button -->
      <div class="d-display-flex d-justify-flex-end">
        <ea-button type="secondary" class="m-r-16" @click="onCancelDeletion">
          {{ $t('common.label.cancel') }}
        </ea-button>
        <ea-button type="primary" @click="onConfirmedDeleteDrivers">
          {{ $t('common.label.accept') }}
        </ea-button>
      </div>
    </ea-dialog>
  </div>
</template>

<script lang="ts">
import {
  Component
} from 'vue-property-decorator';

import {
  mixins
} from 'vue-class-component';

import {
  EAView,
  EAMethod,
  throwIfResponseHasErrors,
  ResponseWithErrors,
  EAError,
  EAMultiError,
  EAApplicationLogger,
  EAApplicationError,
  EAErrorManager
} from '@zurich-es-npm/ea-front-web-core';

import {
  FleetsModel, FlowHeaderStepsModel, FlowViewsStepsModel, InterveningStep, OperationTypeEnum
} from '../fleets-model';
import FleetDataHeader from '../components/fleet-data-header.vue';
import FleetSearchPolicyHolderBusiness
  from '@/business-components/fleets-search-policyholder/fleet-search-policyholder-business.vue';
import {
  CorpTableNames, fetchCorporateTable, parseCorpTableDocuments, ParsedTableData
} from '@/utils/corporate-tables';
import {
  LabelValueType
} from '../model/label-value-type-model';
import {
  EASearchPolicyHolderApi,
  SearchPolicyHolderResponse,
} from '@/services/V1/fleets/searchPolicyHolderOperation/post';
import QbPersonsModel from '@/business-components/qb-persons/qb-persons-model';
import {
  Roles
} from '@/types/roles/roles-enum.types';
import QbPersonsBusiness from '@/business-components/qb-persons/qb-persons-business.vue';
import {
  EAGetVehicleListFleetApi, GetVehicleListFleetResponse
} from '@/services/V1/fleets/getVehicleListFleetOperation/post';
import {
  VehicleFormModel, VehicleUseData
} from '@/business-components/fleet-manual-upload-vehicle/fleet-manual-upload-vehicle-model';
import {
  NotificationsUtils
} from '@/utils/notifications/notifications-utils';
import FlowModelFillUtils from '../utils/flow-model-fill.utils';
import PermissionUtils from '@/utils/permissions-utils';
import {
  GetDefaultUserValuesResponseCodigoTipoUsuarioEnum as UserTypesEnum
} from '@/services/V1/home/getDefaultUserValuesOperation/post/api';
import {
  PersonRole, SelectionInFormTabRole
} from '@/utils/quote-buy-product-factory/types/product-role-types';
import {
  EASaveIntervenersApi, SaveIntervenersRequest
} from '@/services/V1/fleets/saveIntervenersOperation/post';
import {
  EAGetPersonsApi,
  GetPersonsRequest,
  GetPersonsRequestTipoDocumentoEnum,
  GetPersonsRequestTipoPersonaEnum,
  GetPersonsResponse,
  GetPersonsResponseDatosPersona
} from '@/services/V1/quoteAndBuy/getPersonsOperation/post';
import {
  EASaveFleetDriverPersonApi
} from '@/services/V1/fleets/saveFleetDriverPersonOperation/post';
import {
  SelectDriverInterface
} from '@/business-components/qb-search-person/qb-search-person-model';
import Utils from '@/utils/utils';
import {
  GetOfferListByRiskRequestSearchByPlateNumberTipoMatriculaEnum as TipoMatriculaEnum
} from '@/services/V1/search-offer/getOfferListByRiskOperation/post';

@Component({
  components: {
    'fleet-data-header': FleetDataHeader,
    'fleet-search-policyholder': FleetSearchPolicyHolderBusiness,
    'qb-persons': QbPersonsBusiness
  }
})

/**
 * InterveningData view
 *
 */
export default class InterveningDataView extends mixins<EAView<FleetsModel>>(EAView) {

  addressTypeList: ParsedTableData[] = [];

  allowedRoles = [Roles.Asegurado, Roles.Propietario, Roles.Conductor]; // Roles for interrvening view

  allVehicleUses: VehicleUseData[] = [];

  plateNumberSearch: string = '';

  personsModel: QbPersonsModel = new QbPersonsModel();

  isInitialLoadComplete: boolean = false;

  vehiclesList: VehicleFormModel[] = []; // Get the value from q18 api

  initialPersonRoles: PersonRole[] = [];

  isNextDisabled: boolean = true;

  isGenDocVisible: boolean = false;
  
  changedRoles: string[] = [];

  isConfirmDeleteDriver: boolean = false;

  confirmDeleteDriverTitle: string = '';

  confirmDeleteDriverMessage: string = '';

  confirmDeleteDriverSuccess: string = '';

  driversToDelete: SelectDriverInterface[] = []; //Get the list of vehicles to delete from table

  isNominatedDriver: boolean = false; // Get the value of nominated radio-button

  step: string = '';

  personsErrors: GetPersonsResponse[] = [];

  /**
   * Get fleetCommitContractPolicyTypes data
   */
  get fleetCommitContractPolicyTypes(): LabelValueType[] {
    return this.model.flowConfiguration.generalDataView?.fleetCommitContractPolicyTypes || [];
  }

  /**
   * Get showPolicyholderInfoDocumentTypes data
   */
  get showPolicyholderInfoDocumentTypes(): LabelValueType[] {
    return this.model.flowConfiguration.generalDataView?.showPolicyholderInfoDocumentTypes || [];
  }

  /**
   * Hook created
   */
  @EAMethod({
    loading: true,
  })
  async created() {
    this.changedRoles = [];
    const promises = [fetchCorporateTable(CorpTableNames.AddressType)];
    const results = await Promise.all(promises);

    this.addressTypeList = parseCorpTableDocuments(results[0]);

    // Get the data from getFleetOperation to show the view
    await FlowModelFillUtils.loadFleetData(this.model, false);

    // Get the uses types from table
    await this.fetchVehicleUseCorporateTable();

    if (!this.model.documentTypeList.length || !this.model.countryList.length) {
      this.fetchDropdownData();
    }

    // Get the vehicles list and asegurado/propietario data
    await this.getVehiclesListBff();
  
    // Search person to keep in the flowSession
    if (this.model.fleetSearchPolicyHolderModel.selectedJuridicalPerson.codigoIdentificacionFiscal) {
      await this.performSearchPerson(
        this.model.fleetSearchPolicyHolderModel.selectedJuridicalPerson.codigoIdentificacionFiscal
      );
    }

    this.isInitialLoadComplete = true;
    this.onPersonChanged();

    this.personsErrors.forEach(err => throwIfResponseHasErrors(err as unknown as ResponseWithErrors));
  }

  /**
   * Fetch documentType and country dropdown data
   * @returns {Promise<void>}
   */
  @EAMethod({
    loading: true,
  })
  public async fetchDropdownData(): Promise<void> {
    try {
      const promises = [
        fetchCorporateTable(CorpTableNames.DocumentTypes),
        fetchCorporateTable(CorpTableNames.Countries),
      ];
      const results = await Promise.all(promises);

      this.model.documentTypeList = parseCorpTableDocuments(results[0]);
      this.model.countryList = parseCorpTableDocuments(results[1]);

      this.update();
    } catch (err) {
      const loggerErrorMessages: string[] = [];
      let errors: EAError[] = [];

      // eslint-disable-next-line no-extra-parens
      if (typeof err.getErrorList === 'function') {
        // eslint-disable-next-line no-extra-parens
        errors = (err as EAMultiError).getErrorList() as EAError[];
      } else {
        errors = [err as EAError];
      }

      errors.forEach(error => {
        loggerErrorMessages.push(`QbSearchPersonBusiness::performSearchPerson ${error.message}`);
      });

      const logger = new EAApplicationLogger();
      loggerErrorMessages.forEach(message => {
        logger.error(message);
      });

      throw new EAApplicationError('ZON00031', loggerErrorMessages);
    }
  }

  /**
   * Fetch corp table data for vehicle uses and parses documents.
   */
  @EAMethod({
    loading: true,
  })
  public async fetchVehicleUseCorporateTable() {
    const corpTableData = await fetchCorporateTable(CorpTableNames.VehicleUse);
    // Map documents with only relevant information
    const documentsParsed: VehicleUseData[] = corpTableData.data.tableDocuments.map(doc => {
      return {
        name: doc.DSELEM.trim(),
        value: doc.CDUSOMAT.trim(),
        plateType: doc.TIMATVCO.trim() === '' ? TipoMatriculaEnum.NOMATRICULA : doc.TIMATVCO.trim(),
      };
    });
    Utils.sortObjectArrayByProperty(documentsParsed, 'name');
    this.allVehicleUses = documentsParsed;
  }

  /**
   * Main function for search person
   * @param {string} documentNumber
   * @param {string} codigoFiliacion
   */
  @EAMethod({
    loading: true,
  })
  async performSearchPerson(documentNumber: string, codigoFiliacion?: string) {
    const api = new EASearchPolicyHolderApi();
    const response: SearchPolicyHolderResponse = await api.searchPolicyHolderOperation({
      searchPolicyHolderRequest:{
        codigoIdentificacionFiscal: documentNumber.toUpperCase(),
        identificadorMediador: this.model.qbNewOfferBusinessModel.intermediaryCode || '',
        nombreMediador: this.model.qbNewOfferBusinessModel.intermediaryName || '',
        codigoFiliacion
      }
    }) as SearchPolicyHolderResponse;

    throwIfResponseHasErrors(response as unknown as ResponseWithErrors);
    this.model.selectedJuridicalPerson = response.juridicalPeople?.[0];
  }

  /**
   * Set selectedPerson data for 'Asegurado' and 'Propietario'
   * @param {GetPersonsResponseDatosPersona | null} selectedPerson
   * @param {Roles} role
   * @param {boolean} value
   */
  setPersonsData(
    selectedPerson: GetPersonsResponseDatosPersona | null,
    role: Roles,
    value: boolean
  ) {
    const personIndex = this.model.personsModel.personRoles.findIndex(item => item.role === role);
  
    this.model.personsModel.personRoles[personIndex] = {
      ...this.model.personsModel.personRoles[personIndex],
      searchModel: {
        ...this.model.personsModel.personRoles[personIndex].searchModel,
        selectedPerson,
        searchPersonData: {
          ...this.model.personsModel.personRoles[personIndex].searchModel.searchPersonData,
          documentType: selectedPerson?.datosBasicosPersona?.tipoDocumento || 'C'
        }
      },
      roleEqualStatements: [{
        hidden: false,
        roleCopyFrom: Roles.Tomador,
        roleCopyTo: role,
        value
      }]
    };
  }

  /**
   * Get vehiclesList from q18 api
   * Set the response in vehiclesList with the correct format
   * Set Asegurado and Propietario
   */
  @EAMethod({
    loading: true,
  })
  async getVehiclesListBff() {
    this.personsErrors = [];

    const api = new EAGetVehicleListFleetApi();
    const output = await api.getVehicleListFleetOperation({
      getVehicleListFleetRequest: {
        codigoFlota: this.model.offerNumber,
        versionFlota:  this.model.offerVersion,
      }
    });

    if (output) {
      throwIfResponseHasErrors(output as ResponseWithErrors);
      // Set the list of vehicles with the format and will send it as a prop to qb-persons
      this.vehiclesList = await this.getVehiclesFormat(output);

      // Get asegurado data
      if (output.lista?.[0].codigoFiliacionAsegurado) {
        // In case indicadorAsegurado is true => selectedPerson must be null and the value true
        if (output.lista[0].indicadorAsegurado) {
          this.setPersonsData(null, Roles.Asegurado, true);
        } else {
          // In case indicadorAsegurado is false => call getPersonsOperation to get all the data
          const data = {
            codigoFiliacion: output.lista[0].codigoFiliacionAsegurado,
            codigoIdentificacionFiscal: output.lista[0].codigoIdentificacionAsegurado,
            primerApellido: output.lista[0].primerApellidoAsegurado,
            segundoApellido: output.lista[0].segundoApellidoAsegurado,
            nombrePropio: output.lista[0].nombreAsegurado,
            tipoPersona: output.lista[0].tipoPersonaAsegurada as GetPersonsRequestTipoPersonaEnum,
            tipoDocumento: output.lista[0].tipoDocumentoAseguradoBeneficiario as GetPersonsRequestTipoDocumentoEnum
          };
          await this.getInterveningPersons(data, Roles.Asegurado);
        }
      }

      // Get propietario data
      if (output.lista?.[0].codigoFiliacionPropietario) {
        // In case indicadorPropietario is true, selectedPerson must be null and the value true
        if (output.lista[0].indicadorPropietario) {
          this.setPersonsData(null, Roles.Propietario, true);
        } else {
          // In case indicadorPropietario is false => call getPersonsOperation to get all the data
          const data = {
            codigoFiliacion: output.lista[0].codigoFiliacionPropietario,
            codigoIdentificacionFiscal: output.lista[0].codigoIdentificacionPropietario,
            primerApellido: output.lista[0].primerApellidoPropietario,
            segundoApellido: output.lista[0].segundoApellidoPropietario,
            nombrePropio: output.lista[0].nombrePropietario,
            tipoPersona: output.lista[0].tipoPersonaPropietario as GetPersonsRequestTipoPersonaEnum,
            tipoDocumento: output.lista[0].tipoDocumentoPropietario as GetPersonsRequestTipoDocumentoEnum
          };
          await this.getInterveningPersons(data, Roles.Propietario);
        }
      }
      
      // Update the model with the selectedPerson for each role
      this.update();
    }
  }

  /**
   * Call getPersonsOperation api to get the data of person
   * @param {GetPersonsRequest} getPersonsRequest
   * @param {Roles} role
   */
  @EAMethod({
    loading: true,
  })
  async getInterveningPersons(getPersonsRequest: GetPersonsRequest, role: Roles) {
    NotificationsUtils.clearNotifications();
    EAErrorManager.clearError();

    const api = new EAGetPersonsApi();
    const personResponse = await api.getPersonsOperation({
      getPersonsRequest
    });
    
    if (personResponse) {
      if (personResponse.errors && personResponse.errors.length > 0) {

        /*
         * In case of error set the model with selectedPerson must be null and the value true
         * Show the component
         * Throw the error after all
         */
        this.isInitialLoadComplete = true;
        this.changedRoles.push(role);
        this.setPersonsData(null, role, true);
        this.personsErrors.push(personResponse);
      }
      
      if (personResponse.datosPersona) {
        // Set selectedPerson with personResponse.datosPersona
        this.setPersonsData(personResponse.datosPersona, role, false);
      }
    }
  }

  /**
   * Get the format for vehiclesList
   * @param {GetVehicleListFleetResponse} output
   * @returns {Promise<VehicleFormModel[]>}
   */
  async getVehiclesFormat(output: GetVehicleListFleetResponse): Promise<VehicleFormModel[]> {
    const data = output.lista?.map(vehicle => {
      const plateTypeVehicle = vehicle.tipoMatricula ? vehicle.tipoMatricula : TipoMatriculaEnum.NOMATRICULA;
      const plateTypeName = this.model.fleetInputVehiclesDataModel.manualUploadVehicleModel.plateTypeSearchList
        .find(plateType => plateType.code === plateTypeVehicle)?.name;
      const vehicleUseName = this.allVehicleUses.find(
        code => code.value === vehicle.codigoUsoPorMatricula && code.plateType === vehicle.tipoMatricula
      )?.name;

      return {
        offerNumber: vehicle.codigoPoliza,
        offerVersion: vehicle.versionPoliza,
        plateNumberType: vehicle.tipoMatricula,
        plateNumber: vehicle.numeroMatriculaSinValidaciones,
        isBaseSieteData: !!vehicle.descripcionMarcaVehiculo,
        plateTypeName,
        effectiveDate: vehicle.fechaEfectoMovimiento ? new Date(vehicle.fechaEfectoMovimiento) : undefined,
        accessoriesValue: vehicle.valorAccesoriosTotal,
        vehicleBrand: vehicle.descripcionMarcaVehiculo,
        vehicleModel: vehicle.descripcionModeloVehiculo,
        vehicleVersion: vehicle.descripcionVersionVehiculo,
        vehicleUseName,
        plateUseId: vehicle.codigoUsoPorMatricula,
        productComercialCode: vehicle.codigoComercialProducto,
        productTechnicalCode: vehicle.codigoTecnicoProducto,
        driverDocumentNumber: vehicle.codigoIdentificacionConductor,
        driverFisrtName: vehicle.nombreConductor,
        driverLastName: vehicle.primerApellidoConductor,
        driverFiliationCode: vehicle.codigoFiliacionConductor,
        nominatedDriver: vehicle.indicadorConductorNominado
      };
    });

    return data || [];
  }

  /**
   * Goes to generate documentation step
   */
  onGoToDocumentation() {
    NotificationsUtils.clearNotifications();
    EAErrorManager.clearError();
    this.$emit('changeStep', FlowViewsStepsModel.GenerateDocumentationStep, FlowHeaderStepsModel.InterveningDataStep);
  }

  /**
   * Goes to previous step
   */
  onGoBack() {
    NotificationsUtils.clearNotifications();
    EAErrorManager.clearError();

    this.model.operationType = OperationTypeEnum.MANTENIMIENTO;

    // If nominated radio-button is false and have selectedDrivers show the dialog to confirm delete drivers
    if (!this.isNominatedDriver && this.model.personsModel.personRoles[3].searchModel.selectedDrivers.length > 0) {
      this.onDriversToDelete(this.model.personsModel.personRoles[3].searchModel.selectedDrivers);
      this.step = InterveningStep.GOBACK;
    }

    this.update();
    // Go back if not change nominated value
    if (!this.isConfirmDeleteDriver) {
      this.$emit('changeStep', FlowViewsStepsModel.QuoteDataStep, FlowHeaderStepsModel.QuoteDataStep);
    }
  }


  /**
   * Goes to next step
   */
  async onGoNext() {
    NotificationsUtils.clearNotifications();
    EAErrorManager.clearError();
    
    // If nominated is false and have selectedDrivers show the dialog to confirm delete drivers
    if (!this.isNominatedDriver && this.model.personsModel.personRoles[3].searchModel.selectedDrivers.length > 0) {
      this.onDriversToDelete(this.model.personsModel.personRoles[3].searchModel.selectedDrivers);
      this.step = InterveningStep.GONEXT;
      this.update();
    }

    
    // Go next if not change nominated value
    if (!this.isConfirmDeleteDriver && !this.hasMissingPersons()) {
      // Save asegurado/propietario in INFO
      await this.saveIntervenersIfChanged();
      this.$emit('changeStep', FlowViewsStepsModel.OfferIssuanceDataStep, FlowHeaderStepsModel.OfferIssuanceDataStep);
    }
  }

  /**
   * Save interveners data in INFO
   * @param {string[]} changedRoles
   * @param {SaveIntervenersRequest} saveIntervenersRequest
   */
  @EAMethod({
    loading: true,
  })
  async onSaveIntervenersBff(changedRoles: string[], saveIntervenersRequest: SaveIntervenersRequest) {
    if (changedRoles.length > 0) {
      const api = new EASaveIntervenersApi();
      const intervenersResponse = await api.saveIntervenersOperation({
        saveIntervenersRequest
      });

      if (intervenersResponse) {
        NotificationsUtils.comprobeErrors(intervenersResponse as ResponseWithErrors);
      }
    }
  }

  /**
   * Handles next button visibility
   */
  async saveIntervenersIfChanged() {
    const saveIntervenersRequest: SaveIntervenersRequest = {
      codigoFlota: this.model.offerNumber,
      versionFlota: this.model.offerVersion
    };

    for (const role of this.changedRoles) {
      const currentPersonRole = this.model.personsModel.personRoles.find(
        person => person.role.toLowerCase() === role.toString().toLowerCase()
      ) as PersonRole;
      
      if (role === Roles.Tomador) {
        saveIntervenersRequest.codigoFiliacionTomador =
          this.model.fleetSearchPolicyHolderModel.selectedJuridicalPerson.codigoFiliacion;
        if (this.hasPolicyHolderAddressChanged()) {
          saveIntervenersRequest.codigoSecuencialDomicilio =
            this.model.fleetSearchPolicyHolderModel.selectedJuridicalPerson.codigoSecuencialDomicilio;
        }
      } else if (role === Roles.Asegurado) {
        const roleEquals = currentPersonRole.roleEqualStatements as SelectionInFormTabRole[];
        saveIntervenersRequest.indicadorTomadorIgualAsegurado = roleEquals[0].value;

        saveIntervenersRequest.codigoFiliacionAsegurado = saveIntervenersRequest.indicadorTomadorIgualAsegurado ?
          this.model.fleetSearchPolicyHolderModel.selectedJuridicalPerson.codigoFiliacion :
          currentPersonRole.searchModel.selectedPerson?.datosBasicosPersona?.codigoFiliacion;
      } else if (role === Roles.Propietario) {
        const roleEquals = currentPersonRole.roleEqualStatements as SelectionInFormTabRole[];
        saveIntervenersRequest.indicadorTomadorIgualPropietario = roleEquals[0].value;

        saveIntervenersRequest.codigoFiliacionPropietario = saveIntervenersRequest.indicadorTomadorIgualPropietario ?
          this.model.fleetSearchPolicyHolderModel.selectedJuridicalPerson.codigoFiliacion :
          currentPersonRole.searchModel.selectedPerson?.datosBasicosPersona?.codigoFiliacion;
      }
    }

    await this.onSaveIntervenersBff(this.changedRoles, saveIntervenersRequest);
  }

  /**
   * Handles next button visibility
   */
  onPersonChanged() {
    const currentUserType = PermissionUtils.getUserType();
    const hasPersonChanged = this.hasAnyPersonChanged();
    if (PermissionUtils.isUserOfType(currentUserType, UserTypesEnum.AGE)) {
      this.isNextDisabled = hasPersonChanged;
      this.isGenDocVisible = hasPersonChanged;
    } else {
      this.isNextDisabled = false;
      this.isGenDocVisible = true;
    }
  }

  /**
   * Checks if any person has changed
   * @returns {boolean}
   */
  hasAnyPersonChanged(): boolean {
    let hasChanged = false;
   
    for (const initPerson of this.value.personsModel.personRoles) {
      const currentPerson = this.model.personsModel.personRoles.find(
        person => person.role.toLowerCase() === initPerson.role.toString().toLowerCase()
      );
    
      if (!currentPerson) {
        hasChanged = true;
        this.changedRoles.push(initPerson.role);
        break;
      }

      if (initPerson.role === Roles.Tomador) {
        if (this.hasPolicyholderChanges() || this.hasPolicyHolderAddressChanged()) {
          hasChanged = true;
          this.changedRoles.push(initPerson.role);
        }
      } else if (initPerson.role === Roles.Conductor && this.hasDriverChanges(initPerson, currentPerson)) {
        hasChanged = true;
      } else if (initPerson.searchModel.selectedPerson?.datosBasicosPersona?.codigoIdentificacionFiscal !==
          currentPerson?.searchModel.selectedPerson?.datosBasicosPersona?.codigoIdentificacionFiscal) {
        hasChanged = true;
        this.changedRoles.push(initPerson.role);
      }
    }
    return hasChanged;
  }

  /**
   * Checks if policyholder has changed return true
   * @returns {boolean}
   */
  hasPolicyholderChanges(): boolean {
    let hasChanges = false;
    const selectedJuridicalPerson = this.value.fleetSearchPolicyHolderModel.selectedJuridicalPerson;
    const currentJuridicalPerson = this.model.fleetSearchPolicyHolderModel.selectedJuridicalPerson;
    if (selectedJuridicalPerson?.codigoIdentificacionFiscal !== currentJuridicalPerson?.codigoIdentificacionFiscal ||
          selectedJuridicalPerson?.razonSocialMatriz !== currentJuridicalPerson?.razonSocialMatriz) {
      hasChanges = true;
    }
    return hasChanges;
  }

  /**
   * Checks if driver has changed return true
   * @param {PersonRole} initPerson 
   * @param {PersonRole} currentPerson
   * @returns {boolean}
   */
  hasDriverChanges(initPerson: PersonRole, currentPerson: PersonRole): boolean {
    let hasChanges = false;
    // If model and value have the same length comprove if documentNumber is the same
    if (initPerson?.searchModel.selectedDrivers.length === currentPerson?.searchModel.selectedDrivers.length) {
      for (let index = 0; index < initPerson?.searchModel.selectedDrivers.length; index++) {
        const initDriver = initPerson?.searchModel.selectedDrivers[index];
        // When model and value have the same length and different documentNumber hasChanges is true
        if (initDriver.driver?.documentNumber !==
            currentPerson.searchModel.selectedDrivers[index].driver?.documentNumber) {
          hasChanges = true;
        }
      }
    } else {
      // When model and value don't have the same length hasChanges is true
      hasChanges = true;
    }
    return hasChanges;
  }

  /**
   * Checks if any person has changed
   * @returns {boolean}
   */
  hasPolicyHolderAddressChanged(): boolean {
    if (
      this.value.fleetSearchPolicyHolderModel.selectedJuridicalPerson?.nombreDireccionTomador !==
      this.model.fleetSearchPolicyHolderModel.selectedJuridicalPerson?.nombreDireccionTomador
      ||
      this.value.fleetSearchPolicyHolderModel.selectedJuridicalPerson?.nombrePoblacionTomador !==
      this.model.fleetSearchPolicyHolderModel.selectedJuridicalPerson?.nombrePoblacionTomador
      ||
      this.value.fleetSearchPolicyHolderModel.selectedJuridicalPerson?.nombreProvinciaTomador !==
      this.model.fleetSearchPolicyHolderModel.selectedJuridicalPerson?.nombreProvinciaTomador
      ||
      this.value.fleetSearchPolicyHolderModel.selectedJuridicalPerson?.codigoPostal !==
      this.model.fleetSearchPolicyHolderModel.selectedJuridicalPerson?.codigoPostal
      ||
      this.value.fleetSearchPolicyHolderModel.selectedJuridicalPerson?.numeroDireccionTomador !==
      this.model.fleetSearchPolicyHolderModel.selectedJuridicalPerson?.numeroDireccionTomador
      ||
      this.value.fleetSearchPolicyHolderModel.selectedJuridicalPerson?.numeroDireccionTomador !==
      this.model.fleetSearchPolicyHolderModel.selectedJuridicalPerson?.numeroDireccionTomador
      ||
      this.value.fleetSearchPolicyHolderModel.selectedJuridicalPerson?.tipoViaDireccionLarga !==
      this.model.fleetSearchPolicyHolderModel.selectedJuridicalPerson?.tipoViaDireccionLarga
    ) {
      return true;
    }
    return false;
  }

  /**
   * Delete driver data
   * Use saveFleetDriverPersonOperation api
   * All value must be empty and conductorNominado equal false
   * @param {SelectDriverInterface} data
   */
  @EAMethod({
    loading: true,
  })
  async deleteDriverData(data: SelectDriverInterface) {
    const saveDriverApi = new EASaveFleetDriverPersonApi();

    const personResponse = await saveDriverApi.saveFleetDriverPersonOperation({
      saveFleetDriverPersonRequest: {
        codigoPoliza: data.vehicle?.offerNumber || '',
        versionPoliza: data.vehicle?.offerVersion || 0,
        conductor: {
          conductorNominado: false,
          codigoFiliacionConductor: '',
          tipoDocumentoConductor: '',
          codigoIdentificacionConductor: '',
          nombreConductor: '',
          primerApellidoConductor: '',
          segundoApellidoConductor: '',
        }
      },
    });

    NotificationsUtils.comprobeErrors(personResponse as ResponseWithErrors);

    if (personResponse?.ok) {
      // Update selectedDrivers and driverList model
      if (this.model.personsModel.personRoles[3].searchModel.selectedDrivers.length > 0) {
        const findIndexSelectedDriver = this.model.personsModel.personRoles[3].searchModel.selectedDrivers.findIndex(
          vehicle => vehicle.vehicle?.plateNumber === data.vehicle?.plateNumber
        );
        this.model.personsModel.personRoles[3].searchModel.selectedDrivers.splice(findIndexSelectedDriver, 1);

        const findIndexTable = this.model.personsModel.personRoles[3].searchModel.driverListTable.driverList.findIndex(
          vehicle => vehicle?.plateNumber === data.vehicle?.plateNumber
        );

        this.model.personsModel.personRoles[3].searchModel.driverListTable.driverList.splice(findIndexTable, 1);
      }
    }
  }

  /**
   * Get nominated value
   * @param {boolean} value
   */
  onIsDriverIsNominated(value: boolean) {
    this.isNominatedDriver = value;
  }

  /**
   * Get the drivers to delete and show the dialog
   * @param {SelectDriverInterface[]} drivers
   */
  onDriversToDelete(drivers: SelectDriverInterface[]) {
    this.driversToDelete = drivers;

    const modifier: 'single' | 'multi' = drivers.length === 1 ? 'single' : 'multi';

    this.confirmDeleteDriverTitle =
      `fleets.fleetsFlow.fleetsInterveningDriverTable.dialog.${modifier}.title`;

    this.confirmDeleteDriverMessage =
      `fleets.fleetsFlow.fleetsInterveningDriverTable.dialog.${modifier}.message`;

    this.isConfirmDeleteDriver = true;
  }

  /**
   * Close dialog
   */
  onCancelDeletion(): void {
    this.isConfirmDeleteDriver = false;
  }

  /**
   * Delete drivers, show the confirmation and go to anothers steps
   */
  @EAMethod({
    loading: true,
  })
  async onConfirmedDeleteDrivers() {
    // Clean previous errors
    NotificationsUtils.clearNotifications();
    EAErrorManager.clearError();

    // Delete each vehicle
    await Promise.all(this.driversToDelete.map(item => this.deleteDriverData(item)));

    const modifier: 'single' | 'multi' = this.driversToDelete?.length === 1 ? 'single' : 'multi';

    this.confirmDeleteDriverSuccess =
      `fleets.fleetsFlow.fleetsInterveningDriverTable.confirmation.${modifier}`;

    NotificationsUtils.throwSuccess(this.$t(this.confirmDeleteDriverSuccess).toString());
    this.isConfirmDeleteDriver = false;

    this.$nextTick(async() => {
      this.update();

      if (this.step === InterveningStep.GOBACK) {
        this.$emit('changeStep', FlowViewsStepsModel.QuoteDataStep, FlowHeaderStepsModel.QuoteDataStep);
      } else if (this.step === InterveningStep.GONEXT) {
        await this.saveIntervenersIfChanged();
        this.$emit(
          'changeStep', FlowViewsStepsModel.OfferIssuanceDataStep, FlowHeaderStepsModel.OfferIssuanceDataStep
        );
      }
    });
  }

  /**
   * Checks if the roleEquals of any role is set to false and no person is selected
   * @return {boolean}
   */
  hasMissingPersons(): boolean {
    let hasMissingPersons = false;
    let missingRoles = '';

    //Asegurado
    const asegurado = this.model.personsModel.personRoles.find(
      person => person.role.toLowerCase() === Roles.Asegurado.toLowerCase()
    );

    // eslint-disable-next-line no-extra-parens
    if ((asegurado?.roleEqualStatements as SelectionInFormTabRole[])[0].value === false &&
      !asegurado?.searchModel.selectedPerson) {
      missingRoles+=Roles.Asegurado;
      hasMissingPersons = true;
    }

    //Propietario
    const propietario = this.model.personsModel.personRoles.find(
      person => person.role.toLowerCase() === Roles.Propietario.toLowerCase()
    );

    // eslint-disable-next-line no-extra-parens
    if ((propietario?.roleEqualStatements as SelectionInFormTabRole[])[0].value === false &&
      !propietario?.searchModel.selectedPerson) {
      missingRoles+=Roles.Propietario;
      hasMissingPersons = true;
    }

    //Conductor
    const conductor = this.model.personsModel.personRoles.find(
      person => person.role.toLowerCase() === Roles.Conductor.toLowerCase()
    );

    if (this.isNominatedDriver && !conductor?.searchModel.selectedDrivers.length) {
      missingRoles+=Roles.Conductor;
      hasMissingPersons = true;
    }
    
    if (hasMissingPersons) {
      NotificationsUtils.throwWarning(
        this.$t(`fleets.fleetsFlow.interveningDataView.missingPersonsWarningMessages.${missingRoles}`).toString());
    }
    return hasMissingPersons;
  }

}
</script>
