<template>
  <div class="d-display-flex d-justify-center">
    <template v-if="canSelectMultipleOptions">
      <ea-checkbox-group
        v-model="model.multipleSelectedOptions"
        direction="horizontal"
        @change="onSelectedOptionChange"
      >
        <ea-checkbox
          v-for="(option, index) in optionsToSelect" class="m-r-64" :key="index"
          :label="EXPOSE_UTILS.capitalizeFirstCharacter(option.label)" :value="option.value"
        ></ea-checkbox>
      </ea-checkbox-group>
    </template>

    <template v-else>
      <ea-radio-group v-model="model.selectedOption" direction="horizontal" @change="onSelectedOptionChange">
        <ea-radio
          v-for="(option, index) in optionsToSelect" class="m-r-64"
          :key="index" :label="option.value"
        >{{ EXPOSE_UTILS.capitalizeFirstCharacter(option.label) }}</ea-radio>
      </ea-radio-group>
    </template>
  </div>
</template>

<script lang="ts">
import {
  Component, Prop
} from 'vue-property-decorator';
import {
  mixins
} from 'vue-class-component';
import {
  EABusinessComponent
} from '@zurich-es-npm/ea-front-web-core';
import WarrantiesHeaderInlineSelectionModel from './warranties-header-inline-selection-model';
import {
  LabelValue
} from '../../qb-producto-asegurado-comunidades/qb-producto-asegurado-comunidades-model';
import Utils from '../../../utils/utils';

@Component({
  name: 'warranties-header-inline-selection'
})

/**
 * Business Component warranties-header-inline-selection
 */
export default class WarrantiesHeaderInlineSelectionBusiness extends mixins<
EABusinessComponent<WarrantiesHeaderInlineSelectionModel>
>(EABusinessComponent) {
  
  @Prop({
    required: false,
    'default': () => [],
  })
    optionsToSelect!: LabelValue[];

  @Prop({
    required: true,
  })
    canSelectMultipleOptions!: boolean;

  public EXPOSE_UTILS = Utils;

  /**
   * Handles selection change
   */
  async onSelectedOptionChange() {
    await this.$nextTick();
    this.update();
    this.$emit('selectedOptionChange');
  }
}
</script>
