/** 
 * Model qb-proficiency-management
 */
class QbProficiencyManagementModel {

  public proposalNumbersOrder: string[] = [];

}

export default QbProficiencyManagementModel;
