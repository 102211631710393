import {
  EAFlowModel
} from '@zurich-es-npm/ea-front-web-core';

/** 
 * Multioperation data event send from pivot
 */
export interface EAMultioperationData {
  link: string;
  title: string;
}

/** 
 * Page configuration
 */
export interface EAPageConfig {
  height: string;
}

/** 
 * Data event send from pivot 
 */
export interface EADataMessageEvent {
  type: string;
  url: string;
  view: string;
  code?: string;
  multiOperationData: EAMultioperationData[];
  conf: EAPageConfig;
}

/** 
 * Error codes returned from pivot
 */
export enum PivotError {
  NO_CLOSE = '7001',
  MAX_FLOWS = '7002',
  GLOBAL_ERROR = '7003'
}

/** 
 * Error codes returned from pivot
 */
export enum PivotEventType {
  PAGE = 'PAGE',
  ERROR = 'ERROR',
  OPENFLOW = 'openFlow'
}

export interface PivotOpenPolicyFlowDataInterface {
  codigoPoliza: string;
  tipoPoliza: string;
  maintenanceMode: boolean;
  flowId: string;
  versionPoliza: number;
  codigoProductoTecnico: string
}

export interface DataFromPivotInterfaceMap {
  codigoPoliza: string;
  tipoPoliza: string;
  maintenanceMode: string;
  flowId: string;
  versionPoliza: string;
  technicalProductCode: string;
}

export interface DataFromPivotInterface {
  type: string;
  map: DataFromPivotInterfaceMap;
}

export interface EventDataFromPivotInterface extends EADataMessageEvent {
  data: DataFromPivotInterface;
}


/**
 * Pivot flow model
 */
export class EAPivotModel extends EAFlowModel {

  // Div id
  public containerId = '';
  
  // Frame id
  public frameId = '';

  // Initial pivot url
  public baseUrl = '';
  
  // Current pivot url
  public currentUrl = '';

  // Pivot flow id
  public pivotId = '';

}
