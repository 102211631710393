<!-- eslint-disable max-lines -->
<template>
  <div>
    <ea-row extraClass="m-t-8">
      <ea-col>
        <qb-general-data-information
          v-if="isLoadingFinish"
          v-model="model.generalDataInformationModel"
          :datosCabecera="model.datosCabecera"
          id="qb-general-data-information"
          ref="generalDataComp"
          :consultaOperation="consultaOperation"
          :areDatesReadonly="areDatesReadonly"
          @updateParentView="update"
          @paymentChannelChange="paymentChannelChange"
        ></qb-general-data-information>
      </ea-col>
    </ea-row>

    <qb-persons
      v-model="personsModel"
      id="qb-persons"
      ref="personsComp"
      :codigoRamo="productFactory.codigoRamo"
      :indicadorDataQuality="model.datosCabecera.indicadorDataQuality"
      :taxTreatmentAreaTable="model.taxTreatmentAreaTable"
      :documentTypeList="model.documentTypeList"
      :countryList="model.countryList"
      :consultaOperation="consultaOperation"
      :isIBANVisible="isIBANVisible"
      :showNationalitySelector="true"
      :allowedRoles="allowedRoles"
      :paymentChannelIsBank="paymentChannelIsBank"
      :updateGeneralDataParty="true"
      :additionalElements="model.datosAdicionales"
      :policyElementsScore="getPolicyElementsScore()"
      :axesorSearchDocumentTypes="productFactory.axesorSearchDocumentTypes"
      @showError="showError"
      @handleGenericError="handleGenericError"
      @changeTomador="onChangeTomador"
    ></qb-persons>

    <ea-row extraClass="m-t-16" v-if="isProductDataShown && isLoadingFinish">
      <ea-col>
        <component
          v-model="model.productModel"
          id="objeto-aseguradoComponent-qb"
          ref="objetoAseguradoComp"
          :is="productFactory.objetoAseguradoComponent"
          :datosObjeto="datosObjeto"
          :consultaOperation="consultaOperation"
          @updateDates="onUpdateDates"
        />
      </ea-col>
    </ea-row>

    <qb-offer-issuance-additional-data
      v-if="model.offerIssuanceAdditionalDataFormModel.additionalData.length && isProductDataShown"
      id="offerIssuanceAdditionalDataForm"
      v-model="model.offerIssuanceAdditionalDataFormModel"
      :consultaOperation="consultaOperation"
      :cessionRights="model.cessionRights"
      :loanNumber="model.loanNumber"
    ></qb-offer-issuance-additional-data>

    <ea-row extraClass="m-t-16" v-if="isProductDataShown">
      <ea-col :span="12" class="d-display-flex d-justify-flex-start">
        <ea-button type="secondary" @click="onGoBack()" v-if="model.hasParentFlow && !model.replacementMode">
          {{ $t('common.label.back') }}
        </ea-button>
      </ea-col>
      <ea-col
        :span="model.hasParentFlow  && !model.replacementMode ? 12 : 24"
        class="d-display-flex d-justify-flex-end">
        <div>
          <ea-button v-if="consultaOperation" class="m-r-24" type="secondary" @click="onGoReceipts()">
            {{ $t('homeFlow.chartkpi.receipts.title') }}
          </ea-button>
          <ea-button v-if="consultaOperation" class="m-r-24" type="secondary" @click="onGoSinisters()">
            {{ $t('homeFlow.chartkpi.sinisters.title') }}
          </ea-button>
          <ea-button type="primary" @click="continuetoNextStep()">
            {{ $t('common.label.next') }}
          </ea-button>
        </div>
      </ea-col>
    </ea-row>
  </div>
</template>

<script lang="ts">
import {
  EAApplicationError,
  EAApplicationLogger,
  EAError,
  EAFlowNavigationUtils,
  EAMethod,
  EAMultiError,
  EAValidationError,
  EAView,
  throwIfResponseHasErrors,
} from '@zurich-es-npm/ea-front-web-core';
import {
  mixins
} from 'vue-class-component';
import {
  Component, Prop
} from 'vue-property-decorator';
import {
  BffCodesFrontRelationModel,
  FlowHeaderStepsModel,
  FlowViewsStepsModel,
  PolicyModel,
  PolicyMovementType
} from '../policy-model';
// eslint-disable-next-line max-len
import QbGeneralDataInformationBusiness from '@/business-components/qb-general-data-information/qb-general-data-information-business.vue';
import {
  EAGetGeneralDataApi,
  GetGeneralDataRequestCodigoRamoEnum,
  GetGeneralDataRequestTipoOperacionOfertaEnum as OperationType,
  GetGeneralDataResponseDataDatosPersonas,
  GetGeneralDataResponseDataDatosObjeto,
  GetGeneralDataResponseDataDatosCabeceraTablaRestricciones,
  GetGeneralDataResponse,
  GetGeneralDataResponseDataListaCanalesCobroSucesivos,
  GetGeneralDataRequestTipoPolizaEnum,
  GetGeneralDataResponseDataListaIntermediarios,
  GetGeneralDataResponseDataListaCanalesCobroSucesivosTipoCobroEnum,
  GetGeneralDataResponseDataDatosCabeceraElementosPoliza,
} from '@/services/V1/quoteAndBuy/getGeneralDataOperation/post';
import {
  GetPersonsResponseDatosPersona
} from '@/services/V1/quoteAndBuy/getPersonsOperation/post';
import {
  SaveOfferDataRequestDataQuality,
  SaveOfferDataRequestDatosPersonas,
  SaveOfferDataRequestDatosPersonasRolClienteEnum,
  SaveOfferDataRequestDatosPersonasTipoDocumentoEnum,
  SaveOfferDataRequestElementosPoliza,
} from '@/services/V1/quoteAndBuy/saveOfferDataOperation/post';
import {
  CorpTableNames, fetchCorporateTable, parseCorpTableDocuments, ParsedTableData
} from '@/utils/corporate-tables';
import ProductBase from '@/utils/quote-buy-product-factory/products/product-base';
import Utils from '@/utils/utils';
// eslint-disable-next-line max-len
import QbProductoAseguradoComunidadesBusiness from '@/business-components/qb-producto-asegurado-comunidades/qb-producto-asegurado-comunidades-business.vue';
// eslint-disable-next-line max-len
import QbOfferIssuanceAdditionalDataBusiness from '@/business-components/qb-offer-issuance-additional-data/qb-offer-issuance-additional-data-business.vue';
import {
  GetContractDataResponseFormasPago
} from '@/services/V1/quoteAndBuy/getContractDataOperation/post';
import {
  GetFractionalPaymentsRequestTipoFormaPagoTarificacionGarantiasEnum as FormasPagoEnum,
} from '@/services/V1/quoteAndBuy/getFractionalPaymentsOperation/post/api';
import {
  EASaveOfferGenerateDocApi,
  SaveOfferGenerateDocRequest,
  SaveOfferGenerateDocRequestDatosPersonas,
  SaveOfferGenerateDocRequestTipoPolizaEnum,
  SaveOfferGenerateDocResponse,
  Error
} from '@/services/V1/quoteAndBuy/saveOfferGenerateDocOperation/post';
import QbOfferIssuanceBillingDataFormModel
  from '@/business-components/qb-offer-issuance-billing-data-form/qb-offer-issuance-billing-data-form-model';
import {
  SaveEmissionDataRequestDatosPersonasTipoDocumentoEnum
} from '@/services/V1/quoteAndBuy/saveEmissionDataOperation/post';
import {
  ErrorCodeFicoFic,
  QuoteBuyModel
} from '@/flows/quote-buy/quote-buy-model';
import {
  CoexistenceId
} from '@/types/coexistence/coexistence-enum.types';
import {
  GenericErrorData
} from '@/business-components/qb-generic-error/qb-generic-error-business.vue';
import {
  SupplementsUtils
} from '@/utils/supplement-utils';
import {
  NotificationsUtils
} from '@/utils/notifications/notifications-utils';
import QbPersonsBusiness from '@/business-components/qb-persons/qb-persons-business.vue';
import QbPersonsModel from '@/business-components/qb-persons/qb-persons-model';
import {
  Roles
} from '@/types/roles/roles-enum.types';
import PersonUtils from '@/utils/person-utils';
import moment from 'moment';
import PermissionUtils from '@/utils/permissions-utils';
import {
  GetDefaultUserValuesResponseCodigoTipoUsuarioEnum as UserTypesEnum
} from '@/services/V1/home/getDefaultUserValuesOperation/post';
import {
  GetPersonAddressesResponseDomicilios, GetPersonAddressesResponseTelefonos
} from '@/services/V1/persons/getPersonAddressesOperation/post';
import {
  PersonRole
} from '@/utils/quote-buy-product-factory/types/product-role-types';
import {
  FormValues
} from '@/business-components/qb-technical-considerations/qb-technical-considerations-model';
import {
  isElementPrintable, returnElementByCode
} from '@/utils/object-utils';

@Component({
  components: {
    QbPersons: QbPersonsBusiness,
    QbGeneralDataInformation: QbGeneralDataInformationBusiness,
    QbProductoAseguradoComunidades: QbProductoAseguradoComunidadesBusiness,
    QbOfferIssuanceAdditionalData: QbOfferIssuanceAdditionalDataBusiness,
  },
})

/**
 * Policy maintenance GeneralData view
 *
 */
export default class GeneralDataView extends mixins<EAView<PolicyModel | QuoteBuyModel>>(EAView) {
  @Prop({
    required: true,
  })
    productFactory!: ProductBase;

  @Prop(
    {
      required: true,
      'default': () => ''
    }
  )
    parentFlowId!: string;

  datosObjeto?: GetGeneralDataResponseDataDatosObjeto[] = [];

  elementosPoliza?: GetGeneralDataResponseDataDatosCabeceraElementosPoliza[] = [];

  isIBANVisible: boolean = false;

  isLoadingFinish: boolean = false;

  listaCanalesCobro1Recibo: GetGeneralDataResponseDataListaCanalesCobroSucesivos[] | undefined;

  listaCanalesCobroSucesivos: GetGeneralDataResponseDataListaCanalesCobroSucesivos[] | undefined;

  personsModel: QbPersonsModel = new QbPersonsModel();

  allowedRoles = [Roles.Tomador, Roles.Asegurado, Roles.Pagador];

  professionalUser: boolean = false;

  indicadorDataQuality?: boolean;

  isElementPrintable = isElementPrintable;

  /**
   * Checks if the operation type is of type maintenence
   */
  get consultaOperation() {
    return this.model.operationType === OperationType.CONSULTA;
  }

  /**
   * Checks if the dates from general-data-info must be readonly
   */
  get areDatesReadonly() {
    return this.productFactory.shouldReadonlyDatesInGeneralDataInfo && this.model.isProductDataShown;
  }

  /**
   * Hook on created
   *
   * @returns {Promise<void>}
   */
  @EAMethod({
    loading: true,
  })
  public async created(): Promise<void> {
    this.getPersonRoles();

    await this.fetchGeneralData();
    PersonUtils.updatePersonsCanBeChangedFlag(
      this.personsModel.personRoles, this.elementosPoliza, this.datosObjeto
    );
    this.paymentChannelChange();

    if (!this.model.documentTypeList.length || !this.model.countryList.length) {
      this.fetchDropdownData();
    }
    
    await this.$nextTick(); // Wait for model to be setted

    this.isLoadingFinish = true;
    this.update();
  }

  /**
   * Clear ficoFicError when change tomador
   */
  onChangeTomador() {
    this.model.ficoFicError = [];
    this.update();
  }

  /**
   * Get tabItems data
   */
  getPersonRoles() {
    this.personsModel.personRoles = this.productFactory.personRoles;
    const asegurado = this.productFactory.personRoles.find(person => person.role === Roles.Asegurado);
    const pagador = this.productFactory.personRoles.find(person => person.role === Roles.Pagador);

    if (this.$router.currentRoute.path.includes('inquiry')) {
      if (asegurado) {
        asegurado.roleEqualStatements?.forEach(equalStatement => {
          equalStatement.readonly = true;
        });
      }
      if (pagador) {
        pagador.roleEqualStatements?.forEach(equalStatement => {
          equalStatement.readonly = true;
        });
      }
    }
  }

  /**
   * Method run in created() hook
   *
   * @returns {Promise<void>}
   */
  async fetchGeneralData(): Promise<void> {
    if (!this.model.offerNumber || this.model.offerVersion === undefined) {
      return;
    }

    const api = new EAGetGeneralDataApi();
    const generalData = await api.getGeneralDataOperation({
      getGeneralDataRequest: {
        codigoPoliza: this.model.offerNumber,
        versionPoliza: this.model.offerVersion,
        tipoPoliza: this.model.offerType as unknown as GetGeneralDataRequestTipoPolizaEnum,
        codigoRamo: this.productFactory.codigoRamo as unknown as GetGeneralDataRequestCodigoRamoEnum,
        tipoOperacionOferta: this.model.operationType,
      },
    });

    if (!generalData) {
      return;
    }

    if (generalData?.errors?.length) {
      this.showReceivedErrors(generalData.errors);
    }
      
    this.elementosPoliza = generalData.data?.datosCabecera?.elementosPoliza;
    this.datosObjeto = generalData?.data?.datosObjeto;
    this.model.isProductDataShown = true;

    this.loadHeaderData(generalData);
    await this.setPersonsData(generalData.data?.datosPersonas);
    this.setPagadorPersonIbanCode();
    this.loadTaxTreatmentData();
    this.saveAdditionalValues();
    this.saveOfferIssuanceAdditionalData();
    this.model.datosCabecera.indicadorDataQuality = generalData.data?.datosCabecera?.indicadorDataQuality;
  }
  

  /**
   * Shows received errors
   * Error if severity > 4
   * Warning if severity <= 4
   * @param {Error[]} errors
   */
  showReceivedErrors(errors: Error[]): void {
    const highSeverityErrors = errors?.filter(error => error.severity > 4);
    throwIfResponseHasErrors({
      errors: highSeverityErrors
    });
    const lowSeverityErrors = errors?.filter(error => error.severity <= 4);
    lowSeverityErrors?.forEach(error => {
      NotificationsUtils.throwWarning(error.message);
    });
  }

  /**
   * Sets persons data
   * It makes persons component to call addresses and iban services under same spinner
   * @param {GetGeneralDataResponseDataDatosPersonas[] | undefined} personsData
   */
  public async setPersonsData(personsData?: GetGeneralDataResponseDataDatosPersonas[]) {
    if (!personsData) {
      return;
    }
    
    const personsComp: QbPersonsBusiness = this.$refs.personsComp as QbPersonsBusiness;
    await personsComp.setPersonsFromGeneralData(personsData);
  }

  /**
   * Sets iban default code for person with "pagador" role
   */
  public setPagadorPersonIbanCode() {
    const ibanCodeElement = this.getPolicyElementByCode('CDIBANUM');
    const personsComp: QbPersonsBusiness = this.$refs.personsComp as QbPersonsBusiness;
    personsComp.setPagadorPersonIbanCode(ibanCodeElement?.valorElemento || '');
  }

  /**
   * Saves in model received tax treatment information
   * It will be used in search-person component
   */
  public loadTaxTreatmentData() {
    const taxTreatmentElement = this.getPolicyElementByCode('TCINFISC');

    if (taxTreatmentElement) {
      const personsComp: QbPersonsBusiness = this.$refs.personsComp as QbPersonsBusiness;
      personsComp.setTomadorPersonTaxTreatmentArea(taxTreatmentElement?.valorElemento || '');
      
      this.model.taxTreatmentAreaTable = Utils.sortObjectArrayByProperty(
        taxTreatmentElement.tablaRestricciones || [], 'nombreRestriccion'
      );
    }
  }

  /**
   * Saves received cession rights | loan number values in model
   */
  saveAdditionalValues() {
    const cessionRights = this.getRiskElementByCode('NOTITCES');
    if (cessionRights) {

      if (!cessionRights.valorElemento && this.consultaOperation) {
        cessionRights.valorElemento = this.$t('policyMaintenanceFlow.noAssignmentRights').toString();
      }

      const findCessionRights = this.model.offerIssuanceAdditionalDataFormModel.additionalData.find(
        elem => elem.codigoElemento === cessionRights?.codigoElemento
      );

      if (!findCessionRights) {
        this.model.offerIssuanceAdditionalDataFormModel.additionalData.push(cessionRights);
      }
    }

    const loanNumber = this.getRiskElementByCode('NOCUECES');
    if (loanNumber) {
      const findLoanNumber = this.model.offerIssuanceAdditionalDataFormModel.additionalData.find(
        elem => elem.codigoElemento === loanNumber.codigoElemento
      );
      if (!findLoanNumber) {
        this.model.offerIssuanceAdditionalDataFormModel.additionalData.push(loanNumber);
      }
    }
  }

  /**
   * Load header data mapping the correspondient fields
   * @param {GetGeneralDataResponse} generalData
   */
  loadHeaderData(
    generalData: GetGeneralDataResponse
  ): void {
    if (generalData?.data?.datosCabecera) {
      this.model.datosCabecera = generalData?.data?.datosCabecera;
      this.model.datosAdicionales = generalData?.data?.datosAdicionales || [];
      this.model.movementTypeCode = this.model.datosCabecera?.datosPoliza?.codigoTipoMovimiento || '';
        
      const durationMovement = this.getPolicyElementByCode('DUCONTRA');
      if (durationMovement) {
        this.model.generalDataInformationModel.durationValue = durationMovement.valorElemento as string;
      }

      const poblationCode = this.getRiskElementByCode('CDPOBLA');
      if (poblationCode) {
        this.model.productModel.riskSituation.postCodeLocationInputModel.cityCode =
          poblationCode.valorElemento as string;
      }

      this.fillBillingForm(generalData);
      //PAYMENT FORMS
      const formasPago = this.getPolicyElementByCode('TCFORPAG');
      if (!formasPago || !formasPago.valorElemento) {
        return;
      }
      if (generalData.data.datosCabecera.elementosPoliza && generalData.data?.formasPago) {
        this.model.generalDataInformationModel.offerIssuanceBonusDataFormModel.paymentForms =
          generalData.data?.formasPago;
        this.model.generalDataInformationModel.offerIssuanceBonusDataFormModel.paymentForm =
          this.getPaymentFormNameFromCode(formasPago?.valorElemento, generalData.data?.formasPago);
      } else {
        const paymentFormValue = formasPago.tablaRestricciones?.find(
          res => res.valorRestriccion === formasPago.valorElemento)?.nombreRestriccion as FormasPagoEnum
            || FormasPagoEnum.Anual;
        this.model.generalDataInformationModel.offerIssuanceBonusDataFormModel.paymentForm = paymentFormValue;
      }
    }
  }

  /**
   * Maps GetGeneralDataResponseDataDatosCabeceraTablaRestricciones[] to
   *  GetGeneralDataResponseDataListaCanalesCobroSucesivos[]
   * @param {GetGeneralDataResponseDataDatosCabeceraTablaRestricciones[]} tablaRestricciones
   * @returns {GetGeneralDataResponseDataListaCanalesCobroSucesivos[]}
   */
  mapRestrictionTable(
    tablaRestricciones: GetGeneralDataResponseDataDatosCabeceraTablaRestricciones[]
  ): GetGeneralDataResponseDataListaCanalesCobroSucesivos[] {
    return tablaRestricciones.map(el => ({
      tipoCanalCobro: el.valorRestriccion,
      tipoCobro: '' as GetGeneralDataResponseDataListaCanalesCobroSucesivosTipoCobroEnum,
      listaIntermediarios: [] as GetGeneralDataResponseDataListaIntermediarios[]
    } as unknown as GetGeneralDataResponseDataListaCanalesCobroSucesivos));
  }

  /**
   * Sets person data
   * @param {GetGeneralDataResponse} generalData
   */
  fillBillingForm(generalData: GetGeneralDataResponse) {
    const billing = this.model.generalDataInformationModel.offerIssuanceBillingDataFormModel;
    let listaCanalesCobro1Recibo: GetGeneralDataResponseDataListaCanalesCobroSucesivos[] | undefined =
      generalData.data?.listaCanalesCobro1Recibo;
    let listaCanalesCobroSucesivos: GetGeneralDataResponseDataListaCanalesCobroSucesivos[] | undefined =
      generalData.data?.listaCanalesCobroSucesivos;

    this.listaCanalesCobro1Recibo = listaCanalesCobro1Recibo;
    this.listaCanalesCobroSucesivos = listaCanalesCobroSucesivos;
    
    if (!this.model.datosCabecera || !this.model.datosCabecera.elementosPoliza) {
      return;
    }

    const listaCanalesCobro1Cabecera = this.getPolicyElementByCode('TCCANCO1');
    const listaCanalesCobroSucesivosCabecera = this.getPolicyElementByCode('TCCANCOS');

    if (!listaCanalesCobro1Recibo && listaCanalesCobro1Cabecera?.tablaRestricciones) {
      listaCanalesCobro1Recibo = this.mapRestrictionTable(listaCanalesCobro1Cabecera.tablaRestricciones);
    }

    if (!listaCanalesCobroSucesivos && listaCanalesCobroSucesivosCabecera?.tablaRestricciones) {
      listaCanalesCobroSucesivos = this.mapRestrictionTable(listaCanalesCobroSucesivosCabecera.tablaRestricciones);
    }

    if (!listaCanalesCobro1Recibo || !listaCanalesCobroSucesivos) {
      return;
    }
    

    //PAYMENT CHANNELS
    const paymentChannel1st = this.generateKeyValueChannels(listaCanalesCobro1Cabecera, listaCanalesCobro1Recibo);
    if (paymentChannel1st) {
      billing.paymentChannels = paymentChannel1st;
    }

    //SELECTED CHANNEL AND DEBT COLLECTOR FOR 1ST RECEIPT 
    this.fillPaymentChannelAndIntermediaryFirstReceipt(
      billing, listaCanalesCobro1Recibo, listaCanalesCobro1Cabecera
    );

    //SELECTED CHANNEL AND DEBT COLLECTOR FOR SUCESIVE RECEIPT
    this.fillPaymentChannelAndIntermediarySuccessiveReceipts(
      billing, listaCanalesCobroSucesivos, listaCanalesCobroSucesivosCabecera
    );
  }

  /**
   * Fills billing data with payment channel and debt collector intermediary for 1st receipt
   * @param {QbOfferIssuanceBillingDataFormModel} billing 
   * @param {GetGeneralDataResponseDataListaCanalesCobroSucesivos[]} listaCanalesCobro 
   * @param {GetGeneralDataResponseDataDatosObjeto | undefined} listaCanalesCobroCabecera 
   */
  fillPaymentChannelAndIntermediaryFirstReceipt(
    billing: QbOfferIssuanceBillingDataFormModel,
    listaCanalesCobro: GetGeneralDataResponseDataListaCanalesCobroSucesivos[],
    listaCanalesCobroCabecera?: GetGeneralDataResponseDataDatosObjeto
  ): void {
    billing.paymentChannelFirstReceipt =
      listaCanalesCobroCabecera?.valorElemento ? listaCanalesCobroCabecera.valorElemento : '';

    const paymentChannelSelectedSucessive = listaCanalesCobro.
      find(channel => channel.tipoCanalCobro === billing.paymentChannelFirstReceipt);

    if (paymentChannelSelectedSucessive?.listaIntermediarios) {
      const cdIntermediary = listaCanalesCobroCabecera?.valorElemento;
      billing.debtCollectorIntermediaryFirstReceiptList = paymentChannelSelectedSucessive.listaIntermediarios;
      billing.debtCollectorIntermediaryFirstReceipt = cdIntermediary || '';
    }
  }

  /**
   * Fills billing data with payment channel and debt collector intermediary for successive receipts
   * @param {QbOfferIssuanceBillingDataFormModel} billing 
   * @param {GetGeneralDataResponseDataListaCanalesCobroSucesivos[]} listaCanalesCobro 
   * @param {GetGeneralDataResponseDataDatosObjeto | undefined} listaCanalesCobroCabecera 
   */
  fillPaymentChannelAndIntermediarySuccessiveReceipts(
    billing: QbOfferIssuanceBillingDataFormModel,
    listaCanalesCobro: GetGeneralDataResponseDataListaCanalesCobroSucesivos[],
    listaCanalesCobroCabecera?: GetGeneralDataResponseDataDatosObjeto
  ): void {
    billing.paymentChannelSuccessiveReceipts =
      listaCanalesCobroCabecera?.valorElemento ? listaCanalesCobroCabecera.valorElemento : '';

    const paymentChannelSelectedSucessive = listaCanalesCobro.
      find(channel => channel.tipoCanalCobro === billing.paymentChannelFirstReceipt);

    if (paymentChannelSelectedSucessive?.listaIntermediarios) {
      const cdIntermediary = listaCanalesCobroCabecera?.valorElemento;
      billing.debtCollectorIntermediarySuccessiveReceiptsList = paymentChannelSelectedSucessive.listaIntermediarios;
      billing.debtCollectorIntermediarySuccessiveReceipts = cdIntermediary || '';
    }
  }

  /**
   * Sets person data
   * @param {GetGeneralDataResponseDataDatosObjeto} restriccionObjeto
   * @param {GetGeneralDataResponseDataListaCanalesCobroSucesivos[]} listaCanalesCobro
   * @return {any[]} datosCabecera
   */
  generateKeyValueChannels(
    restriccionObjeto: GetGeneralDataResponseDataDatosObjeto | undefined,
    listaCanalesCobro: GetGeneralDataResponseDataListaCanalesCobroSucesivos[]
  ): ParsedTableData[] | undefined {
    if (!restriccionObjeto) {
      return;
    }
    return restriccionObjeto?.tablaRestricciones?.
      filter(canalCabecera => listaCanalesCobro?.
        find(canalCuerpo => canalCuerpo.tipoCanalCobro === canalCabecera.valorRestriccion)
      ).map(canalCabecera => {
        const item = {
          label: canalCabecera.nombreRestriccion ? canalCabecera.nombreRestriccion : '',
          value: canalCabecera.valorRestriccion ? canalCabecera.valorRestriccion : ''
        };
        return item;
      });
  }


  /**
   * Checks if the payment channel is bank to determine if the iban must be shown o not
   */
  get paymentChannelIsBank(): boolean {
    // eslint-disable-next-line max-len
    return this.model.generalDataInformationModel.offerIssuanceBillingDataFormModel.paymentChannelFirstReceipt === 'BC' ||
    this.model.generalDataInformationModel.offerIssuanceBillingDataFormModel.paymentChannelSuccessiveReceipts === 'BC';
  }

  /**
   * Handles payment channel change
   * If the payment channel is bank => Show iban block
   */
  paymentChannelChange() {
    if (this.paymentChannelIsBank) {
      this.isIBANVisible = true;
    } else {
      this.isIBANVisible = false;
    }
  }

  /**
   * Return risk element by code (Datos objeto)
   * @param {string} code
   * @returns {GetGeneralDataResponseDataDatosObjeto  | undefined}
   */
  getRiskElementByCode(code: string): GetGeneralDataResponseDataDatosObjeto | undefined {
    return this.datosObjeto?.find(element => element.codigoElemento === code);
  }

  /**
   * Return policy element by code (Datos cabecera)
   * @param {string} code
   * @returns {GetGeneralDataResponseDataDatosCabeceraElementosPoliza  | undefined}
   */
  getPolicyElementByCode(code: string): GetGeneralDataResponseDataDatosObjeto | undefined {
    return this.model.datosCabecera.elementosPoliza?.find(element => element.codigoElemento === code);
  }

  /**
   * Validates objeto asegurado data component's form
   * If validation OK => Updates model
   * If validation NOK => Throws EAValidationError
   */
  async validateUpdateObjetoAseguradoComp() {
    const aseguradoComp = this.$refs.objetoAseguradoComp as QbProductoAseguradoComunidadesBusiness;
    aseguradoComp.updateAllData();
    try {
      await aseguradoComp.validation().validate();
    } catch (error) {
      if (error instanceof EAApplicationError) {
        throw error;
      }
      throw new EAValidationError(this.$t('common.label.validation.formWithErrors').toString());
    }
  }

  /**
   * Gets if tomador has been selected in search-person component
   * @returns {boolean} value - true if tomador has been selected; false otherwise
   */
  get tomadorIsSelected(): boolean {
    return !!this.personsModel.personRoles.find(
      person => person.role === Roles.Tomador
    )?.searchModel.selectedPerson?.datosBasicosPersona?.codigoFiliacion;
  }

  /**
   * Validates general data component's form (HEADER DATA)
   * If validation OK => Updates model
   * If validation NOK => Throws EAValidationError
   */
  async validateUpdateGeneralDataComp() {
    const generalDataComp: QbGeneralDataInformationBusiness =
      this.$refs.generalDataComp as QbGeneralDataInformationBusiness;
    try {
      await generalDataComp.validation().validate();
    } catch (error) {
      throw new EAValidationError(this.$t('common.label.validation.formWithErrors').toString());
    }
    generalDataComp.update();
    this.update();
  }
  
  /**
   * Validates persons component's form
   * If validation OK => Updates model
   * If validation NOK => Throws EAValidationError
   */
  async validateUpdatePersonsComponent() {
    const personsComp: QbPersonsBusiness = this.$refs.personsComp as QbPersonsBusiness;
    try {
      await personsComp.validateUpdatePersonsComponent();
      this.update();
    } catch (error) {
      throw new EAValidationError(this.$t('common.label.validation.formWithErrors').toString());
    }
  }

  /**
   * Gets person fields in Bff format
   * @param {GetPersonsResponseDatosPersona} person
   * @param {SaveOfferDataRequestDatosPersonasRolClienteEnum} personRole
   * @param {string | undefined} codSecuenciaDomicilio
   * @returns {SaveOfferDataRequestDatosPersonas}
   */
  getPersonFieldsToSave(
    person: GetPersonsResponseDatosPersona,
    personRole: SaveOfferDataRequestDatosPersonasRolClienteEnum,
    codSecuenciaDomicilio?: string | undefined
  ): SaveOfferDataRequestDatosPersonas {
    const tipoDocumento = (person?.datosBasicosPersona
      ?.tipoDocumento as unknown) as SaveEmissionDataRequestDatosPersonasTipoDocumentoEnum;
    const result = {
      rolCliente: personRole,
      codigoFiliacion: person?.datosBasicosPersona?.codigoFiliacion,
      tipoPersona: Utils.getPersonType(person),
      codigoIdentificacionFiscal: person?.
        datosBasicosPersona?.codigoIdentificacionFiscal,
      primerApellido: person?.datosBasicosPersona?.primerApellido,
      segundoApellido: person?.datosBasicosPersona?.segundoApellido,
      nombrePropio: person?.datosBasicosPersona?.nombrePropio,
      tipoDocumento: person?.
        datosBasicosPersona?.tipoDocumento as unknown as SaveOfferDataRequestDatosPersonasTipoDocumentoEnum,
      indicadorFiscal: PersonUtils.getPersonTaxTreatmentArea(this.personsModel.personRoles, personRole),
      codigoSecuencialDomicilio: codSecuenciaDomicilio,
      nacionalidadPersona: tipoDocumento === SaveEmissionDataRequestDatosPersonasTipoDocumentoEnum.P
        || tipoDocumento === SaveEmissionDataRequestDatosPersonasTipoDocumentoEnum.R ?
        PersonUtils.getSelectedNationalityByRole(this.personsModel.personRoles, personRole) : 'ESP'
    };

    return result;
  }

  /**
   * Gets person fields in Bff format
   * @returns {SaveOfferDataRequestDatosPersonas}
   */
  getDataQualityToSave(
  ): SaveOfferDataRequestDataQuality {
    const tomador = this.personsModel.personRoles.find(person => person.role === Roles.Tomador);
    const selectedPhoneAddress = this.findTomadorSelectedPhoneAddress(tomador);
    return {
      codigoFiliacion: tomador?.searchModel.selectedPerson?.datosBasicosPersona?.codigoFiliacion,
      emailTomador: tomador?.addressesModel.addEditEmail.selectedEmail?.emailContacto,
      telefonoTomador: selectedPhoneAddress?.selectedPhone?.numeroTelefono,
      prefijoPais: selectedPhoneAddress?.selectedPhone?.prefijoPais,
      codigoSecuenciaDomicilioEmail: tomador?.addressesModel.addEditEmail.selectedEmail?.codigoSecuencialDomicilio,
      codigoSecuenciaDomicilioTelefono: selectedPhoneAddress?.phoneAddress.codigoSecuencialDomicilio,
      claseDomicilioEmail: tomador?.addressesModel.addEditEmail.selectedEmail?.claseDomicilio,
      claseDomicilioTelefono: selectedPhoneAddress?.phoneAddress.claseDomicilio
    };
  }

  /**
   * Finds Phone address for selected phone in dropdown
   * @param {PersonRole | undefined} tomador
   * @returns {Object} 
   */
  findTomadorSelectedPhoneAddress(tomador: PersonRole | undefined): {
    phoneAddress: GetPersonAddressesResponseDomicilios;
    selectedPhone: GetPersonAddressesResponseTelefonos | undefined;
  } | undefined {
    const selectedPhoneNumber = tomador?.addressesModel.addEditPhone.selectedPhoneNumber;
    const phoneList = tomador?.addressesModel.addEditPhone.phoneList;
    if (phoneList) {
      for (const phoneAddress of phoneList) {
        const selectedPhone =
          phoneAddress.telefonos?.find(phone => phone.numeroTelefono === selectedPhoneNumber);
        if (selectedPhone) {
          return {
            phoneAddress,
            selectedPhone
          };
        }
      }
    }
  }

  /** 
   * Gets policy fields in Bff format
   * @returns {SaveOfferDataRequestElementosPoliza[] }
   */
  getCabeceraFieldsToSave(
  ): SaveOfferDataRequestElementosPoliza[] {
    let paymentFormToSave = '';
    
    const selectedPaymentForm = this.model.generalDataInformationModel.offerIssuanceBonusDataFormModel.paymentForm;
    const paymentFormRestrictionsTable = this.model.datosCabecera?.elementosPoliza?.find(
      element => element.codigoElemento ==='TCFORPAG')?.tablaRestricciones;

    if (paymentFormRestrictionsTable) {
      paymentFormToSave = paymentFormRestrictionsTable.find(
        payment => payment.nombreRestriccion === selectedPaymentForm ||
          payment.valorRestriccion === selectedPaymentForm)?.valorRestriccion || '';
    }
    
    if (!paymentFormToSave) {
      paymentFormToSave = selectedPaymentForm[0];
    }

    const iniDate = this.model.generalDataInformationModel.movementEfect
      ? moment(this.model.generalDataInformationModel.movementEfect).format('YYYYMMDD')
      : '';
    const endData = this.model.generalDataInformationModel.movementDue
      ? moment(this.model.generalDataInformationModel.movementDue).format('YYYYMMDD')
      : '';

    const tomador = PersonUtils.getPersonByRole(this.personsModel.personRoles, Roles.Tomador);
    const pagador = PersonUtils.getPersonByRole(this.personsModel.personRoles, Roles.Pagador);
    const ibanCodeModel = pagador?.ibanCodeModel;

    const cabeceraFields = [
      {
        codigoElemento: 'FEEFTOMO',
        valorElemento: iniDate
      }, {
        codigoElemento: 'FEEFTONA',
        valorElemento: this.getNaturalIniDate(iniDate, 'FEEFTONA')
      }, {
        codigoElemento: 'FEINIPOL',
        valorElemento: this.getNaturalIniDate(iniDate, 'FEINIPOL')
      }, {
        codigoElemento: 'FEFINEFE',
        valorElemento: endData
      }, {
        codigoElemento: 'FEVENCIM',
        valorElemento: endData
      }, {
        codigoElemento: 'TCCANCO1',
        // eslint-disable-next-line max-len
        valorElemento: this.model.generalDataInformationModel.offerIssuanceBillingDataFormModel.paymentChannelFirstReceipt
      },
      {
        codigoElemento: 'TCCANCOS',
        // eslint-disable-next-line max-len
        valorElemento: this.model.generalDataInformationModel.offerIssuanceBillingDataFormModel.paymentChannelSuccessiveReceipts
      },
      {
        codigoElemento: 'CLINTEC1',
        // eslint-disable-next-line max-len
        valorElemento: this.model.generalDataInformationModel.offerIssuanceBillingDataFormModel.debtCollectorIntermediaryFirstReceipt
      },
      {
        codigoElemento: 'CLINTECS',
        // eslint-disable-next-line max-len
        valorElemento: this.model.generalDataInformationModel.offerIssuanceBillingDataFormModel.debtCollectorIntermediarySuccessiveReceipts,
      },
      {
        codigoElemento: 'DUCONTRA',
        valorElemento: this.model.generalDataInformationModel.durationValue
      },
      {
        codigoElemento: 'TCINFISC',
        valorElemento: tomador?.taxTreatmentArea
      },
      {
        codigoElemento: 'TCFORPAG',
        valorElemento: paymentFormToSave
      },
      {
        codigoElemento: 'TCFOPASU',
        valorElemento: paymentFormToSave
      },
      {
        codigoElemento: 'CDIBANUM',
        valorElemento: ibanCodeModel?.qbIbanCodeModalModel.selectedIbanCode,
      },
      {
        codigoElemento: 'CLFILPAG',
        valorElemento: pagador?.searchModel.selectedPerson?.datosBasicosPersona?.codigoFiliacion,
      },
      {
        codigoElemento: 'CDSECPAG',
        valorElemento: this.productFactory.getSecuentialAddressCode(Roles.Pagador),
      },
      {
        codigoElemento: 'INIDIOMA',
        valorElemento: this.model.generalDataInformationModel.documentationLanguage,
      },
      // Header score elements
      {
        codigoElemento: 'NOPOBTOM',
        valorElemento: tomador?.showPersonInfoModel.postalCodeModel.city
      },
      {
        codigoElemento: 'TCPOBTOM',
        valorElemento: tomador?.showPersonInfoModel.postalCodeModel.cityCode
      },
      {
        codigoElemento: 'TCCOPTOM',
        valorElemento: tomador?.showPersonInfoModel.postalCodeModel.zipCode
      }
    ];
    return this.filterExcludeNonModificableElements(cabeceraFields);
  }

  /**
   * Filters those fields that are modificable from receivedFields array
   * @param {SaveOfferDataRequestElementosPoliza[] | undefined} receivedFields
   * @param {boolean | undefined} riskElement
   * @returns {SaveOfferDataRequestElementosPoliza[]}
   */
  filterExcludeNonModificableElements(
    receivedFields?: SaveOfferDataRequestElementosPoliza[],
    riskElement?: boolean
  ): SaveOfferDataRequestElementosPoliza[] {
    if (!receivedFields) {
      return [];
    }
    
    return receivedFields.filter(field => {
      if (!field.codigoElemento) {
        return false;
      }

      const policyElement = riskElement ?
        this.getRiskElementByCode(field.codigoElemento) : this.getPolicyElementByCode(field.codigoElemento);

      if (!policyElement) {
        return false;
      }

      const modificable = policyElement.elementoModificable;

      return modificable === undefined || modificable === true;
    });
  }

  /**
   * Gets natural ini date
   * Returns value received from bff when we don't want to change it
   * @param {string} iniDate
   * @param {string} codigoElemento
   * @returns {string | undefined}
   */
  getNaturalIniDate(iniDate: string, codigoElemento: string): string | undefined {
    return this.model.movementTypeCode !== PolicyMovementType.Suplemento &&
      this.model.movementTypeCode !== PolicyMovementType.SuplementoVencimiento ?
      iniDate : this.model.datosCabecera.elementosPoliza?.find(
        elem => elem.codigoElemento === codigoElemento
      )?.valorElemento;
  }

  /**
   * Validates persons existance
   * Throws error if any person is missing
   */
  validatePersonsExistance() {
    PersonUtils.getPersonDataByRole(this.personsModel.personRoles, Roles.Tomador);
    PersonUtils.getPersonDataByRole(this.personsModel.personRoles, Roles.Asegurado);
    PersonUtils.getPersonDataByRole(this.personsModel.personRoles, Roles.Pagador);
  }

  /**
   * Handles continue button
   * @param {Function} callbackIfOk - Function to be called if service call returns OK result
   */
  async saveOfferData(callbackIfOk: Function) {
    this.clearFlowErrors();

    const selectedPersonTomadorData = PersonUtils.getPersonDataByRole(this.personsModel.personRoles, Roles.Tomador);
    const selectedPersonAseguradoData = PersonUtils.getPersonDataByRole(
      this.personsModel.personRoles, Roles.Asegurado
    );
    const selectedPersonPagadorData = PersonUtils.getPersonDataByRole(this.personsModel.personRoles, Roles.Pagador);

    const paymentChannelFirstReceipt =
      this.model.generalDataInformationModel.offerIssuanceBillingDataFormModel.paymentChannelFirstReceipt;
    const foundChannel1st =
      this.listaCanalesCobro1Recibo?.find(canal => canal.tipoCanalCobro === paymentChannelFirstReceipt);
    if (foundChannel1st?.listaIntermediarios?.length) {
      this.model.generalDataInformationModel.offerIssuanceBillingDataFormModel.debtCollectorIntermediaryFirstReceipt =
      foundChannel1st.listaIntermediarios[0].codigoIntermediario as string;
    }

    const paymentChannelSuccessiveReceipts =
      this.model.generalDataInformationModel.offerIssuanceBillingDataFormModel.paymentChannelSuccessiveReceipts;
    const foundChannelSuccessive =
      this.listaCanalesCobro1Recibo?.find(canal => canal.tipoCanalCobro === paymentChannelSuccessiveReceipts);
    if (foundChannelSuccessive?.listaIntermediarios?.length) {
      // eslint-disable-next-line max-len
      this.model.generalDataInformationModel.offerIssuanceBillingDataFormModel.debtCollectorIntermediarySuccessiveReceipts =
        foundChannelSuccessive.listaIntermediarios[0].codigoIntermediario as string;
    }

    
    const elementosPoliza = this.getCabeceraFieldsToSave();

    const dataQuality = this.getDataQualityToSave();

    const tomador = PersonUtils.getPersonByRole(this.personsModel.personRoles, Roles.Tomador) as PersonRole;
    const elementosAdicionales = this.productFactory.generateAditionalOfferData(tomador, this.model.datosAdicionales,
      this.model, true);

    const saveOfferGenerateDocRequest: SaveOfferGenerateDocRequest = {
      codigoPoliza: this.model.offerNumber,
      versionPoliza: this.model.offerVersion,
      tipoPoliza: SaveOfferGenerateDocRequestTipoPolizaEnum.Incompleta,
      codigoSecuencialDomicilio: this.personsModel.personRoles.find(
        person => person.role === 'tomador'
      )?.addressesModel.addEditAddress.selectedAddressCode,
      guardarDatosObjeto: true,
      datosPersonas: [
        this.getPersonFieldsToSave(
          selectedPersonTomadorData,
          SaveOfferDataRequestDatosPersonasRolClienteEnum.Tomador,
          this.productFactory.getSecuentialAddressCode(Roles.Tomador)
        ) as SaveOfferGenerateDocRequestDatosPersonas,
        this.getPersonFieldsToSave(
          selectedPersonAseguradoData,
          SaveOfferDataRequestDatosPersonasRolClienteEnum.Asegurado,
          this.productFactory.getSecuentialAddressCode(Roles.Asegurado)
        ) as SaveOfferGenerateDocRequestDatosPersonas,
        this.getPersonFieldsToSave(
          selectedPersonPagadorData,
          SaveOfferDataRequestDatosPersonasRolClienteEnum.Pagador,
          this.productFactory.getSecuentialAddressCode(Roles.Pagador)
        ) as SaveOfferGenerateDocRequestDatosPersonas,
      ],
      elementosPoliza,
      dataQuality,
      elementosAdicionales
    };

    this.addObjectElements(saveOfferGenerateDocRequest);

    const offerData = this.productFactory.generateOfferData(this.model);

    if (offerData) {
      saveOfferGenerateDocRequest.elementosObjeto = this.filterExcludeNonModificableElements(
        saveOfferGenerateDocRequest.elementosObjeto?.concat(offerData), true
      );
    }
     
    const resp = await this.callSaveOfferGenerateDocBff(saveOfferGenerateDocRequest);
    if (resp) {
      this.model.ficoFicError = [];
      if (resp.errors) {
        const errrorFIC =
          resp.errors.filter(err => err.code === ErrorCodeFicoFic.Fico || err.code === ErrorCodeFicoFic.Fic);

        if (errrorFIC.length > 0) {
          // Update the model to show the fic - fico error in offer-issuance
          errrorFIC.forEach(err => this.model.ficoFicError.push(err.code));
          this.update();
        }
        // Clear the FICO - FIC warnigns
        const errorsWithoutFICO = resp.errors.filter(err => err.severity >= 4);
        if (errorsWithoutFICO.length > 0) {
          const multiError = new EAMultiError();
          errorsWithoutFICO.forEach(
            (error: Error) => multiError.push(new EAValidationError(this.translateError(error))));
          throw multiError;
        }
     
        callbackIfOk();
      } else {
        callbackIfOk();
      }
    }
  }

  /**
   *  Gets the elements for the object to save
   * @param {SaveOfferGenerateDocRequest} request
   * 
   */
  addObjectElements(request: SaveOfferGenerateDocRequest) {
    request.elementosObjeto = [];

    const cessionRights = this.model.offerIssuanceAdditionalDataFormModel.additionalData.find(
      elem => elem.codigoElemento === 'NOTITCES'
    );
    if (cessionRights) {
      request.elementosObjeto.push(
        {
          codigoElemento: 'NOTITCES',
          valorElemento: cessionRights.valorElemento,
        }
      );
    }

    const loanNumber = this.model.offerIssuanceAdditionalDataFormModel.additionalData.find(
      elem => elem.codigoElemento === 'NOCUECES'
    );
    if (loanNumber) {
      request.elementosObjeto.push(
        {
          codigoElemento: 'NOCUECES',
          valorElemento: Utils.parseNumericValueIfReceived(
            Number(loanNumber.valorElemento), 0
          ).toString()
        }
      );
    }
  }

  /**
   * @param {SaveOfferGenerateDocRequest} saveOfferGenerateDocRequest
   * @returns {Promise<SaveOfferGenerateDocResponse | null>}
   */
  @EAMethod({
    loading: true,
  })
  async callSaveOfferGenerateDocBff(
    saveOfferGenerateDocRequest: SaveOfferGenerateDocRequest
  ): Promise<SaveOfferGenerateDocResponse | null> {
    const api = new EASaveOfferGenerateDocApi();
    return api.saveOfferGenerateDocOperation({
      saveOfferGenerateDocRequest,
    });
  }

  /**
   * Translates error codes into legible ones
   * @param {Error} error - Bff received error
   * @returns {string} translated errors
   */
  translateError(error: Error): string {
    const splitted = error.message.split(':');

    if (splitted.length < 2) {
      return error.message;
    }

    const codeRelation = this.model.productModel.bffCodesFrontRelation.find(
      (rel: BffCodesFrontRelationModel) => rel.bffCode === splitted[1].trim()
    );

    if (codeRelation) {
      const frontCodeTranslated = this.$t(codeRelation.frontCode);
      const errorMessageTranslated = this.$t(`common.label.validation.${splitted[2]}`);
      error.message = `${splitted[0]} => ${frontCodeTranslated}: ${errorMessageTranslated}`;
    }

    return error.message;
  }

  /**
   * Save and continue to warranties step
   */
  @EAMethod({
    loading: true
  })
  async continuetoNextStep() {
    this.clearFlowErrors();
    NotificationsUtils.clearNotifications();

    await this.areDataBlocksValid();
    const callbackFn = () => this.$emit(
      'changeStep', FlowViewsStepsModel.WarrantiesStep, FlowHeaderStepsModel.WarrantiesStep);
    if (this.consultaOperation) {
      callbackFn();
    } else {
      await this.saveOfferData(callbackFn);
    }
  }

  /**
   * Checks if data is valid
   */
  async areDataBlocksValid() {
    let errors: EAValidationError[] = [];

    await this.validateUpdateGeneralDataComp();
    await this.validateUpdateObjetoAseguradoComp();

    // ### Validate persons ###
    await this.validateUpdatePersonsComponent();
    this.validatePersonsExistance();
    
    this.productFactory.personRoles = this.personsModel.personRoles;

    // ### Validate addresses ###
    const personsComp: QbPersonsBusiness = this.$refs.personsComp as QbPersonsBusiness;
    errors = errors.concat(
      personsComp.validateSelectedAddresses()
    );

    // ### Validate nationality ###
    errors = errors.concat(
      await personsComp.validateSelectedNationality()
    );

    // ### Validate selected IBAN code ###
    const ibanCodeModel = PersonUtils.getPersonByRole(this.personsModel.personRoles, Roles.Pagador)?.ibanCodeModel;
    
    if (this.paymentChannelIsBank && this.isIBANVisible && !ibanCodeModel?.qbIbanCodeModalModel.selectedIbanCode) {
      errors.push(
        new EAValidationError(this.$t('quoteBuyGenericFlow.error.ibanRequired').toString())
      );
    }
 
    if (this.model.datosCabecera.versionAnterior) {
      const fechaInicioPoliza = new Date(this.model.datosCabecera.versionAnterior.fechaInicioPoliza || '');
      const fechaEfectoNatural = new Date(this.model.datosCabecera.versionAnterior.fechaEfectoNatural || '');
      const fechaVencimiento = new Date(this.model.datosCabecera.versionAnterior.fechaVencimiento || '');
      const fechaEfectoPoliza = this.model.generalDataInformationModel.movementEfect;

      /* Check validations for dates */
      const validationsErrors = SupplementsUtils.checkValidations(
        this.productFactory.shouldValidate1YearMoreDate,
        fechaInicioPoliza,
        fechaEfectoNatural,
        fechaVencimiento,
        fechaEfectoPoliza,
        this.model.movementTypeCode === PolicyMovementType.SuplementoVencimiento
      );

      if (validationsErrors.length) {
        validationsErrors.forEach(error => errors.push(error));
      }
    }

    if (errors.length) {
      const multiError = new EAMultiError();
      errors.forEach(error => multiError.push(new EAValidationError(error.message)));
      throw multiError;
    }
  }

  /**
   * Gets if product data section is shown
   * @returns {boolean} true if it is shown; false otherwise
   */
  get isProductDataShown() {
    return this.model.isProductDataShown && !!this.datosObjeto?.length;
  }

  /**
   * Fetch documentType and country dropdown data
   * @returns {Promise<void>}
   */
  @EAMethod({
    loading: true,
  })
  public async fetchDropdownData(): Promise<void> {

    try {
      const promises = [
        fetchCorporateTable(CorpTableNames.DocumentTypes),
        fetchCorporateTable(CorpTableNames.Countries),
      ];
      const results = await Promise.all(promises);

      this.model.documentTypeList = parseCorpTableDocuments(results[0]);
      this.model.countryList = parseCorpTableDocuments(results[1]);

      this.update();
    } catch (err) {
      const loggerErrorMessages: string[] = [];
      let errors: EAError[] = [];

      // eslint-disable-next-line no-extra-parens
      if (typeof err.getErrorList === 'function') {
        // eslint-disable-next-line no-extra-parens
        errors = (err as EAMultiError).getErrorList() as EAError[];
      } else {
        errors = [err as EAError];
      }

      errors.forEach(error => {
        loggerErrorMessages.push(`QbSearchPersonBusiness::performSearchPerson ${error.message}`);
      });

      const logger = new EAApplicationLogger();
      loggerErrorMessages.forEach(message => {
        logger.error(message);
      });

      throw new EAApplicationError('ZON00031', loggerErrorMessages);
    }
  }

  /**
   * Get payment form descriptive name from payment form code
   * if it exists in valid options array
   *
   * @param {string} paymentFormCode
   * @param {validPaymentForms} validPaymentForms
   *
   * @returns {FormasPagoEnum | undefined}
   */
  getPaymentFormNameFromCode(
    paymentFormCode?: string,
    validPaymentForms?: GetContractDataResponseFormasPago[]
  ): FormasPagoEnum {
    let paymentFormValue: FormasPagoEnum = FormasPagoEnum.Anual; //Default Value

    if (paymentFormCode && validPaymentForms && validPaymentForms.length > 0) {
      const foundPaymentForm = validPaymentForms.find(
        validPaymentForm => validPaymentForm.valorRestriccion === paymentFormCode
      );

      if (foundPaymentForm?.nombreRestriccion) {
        paymentFormValue = foundPaymentForm.nombreRestriccion as FormasPagoEnum;
      }
    }
    return paymentFormValue;
  }
  
  /**
   * @description end to user to pivot receipts coexitencia page
   */
  @EAMethod()
  onGoReceipts() {
    const userType = PermissionUtils.getUserType();
    this.professionalUser = PermissionUtils.isUserOfType(userType, UserTypesEnum.PRO);

    // Different operationId if user is PRO type or not
    EAFlowNavigationUtils.navigate(this, 'ZonaZurich', {
      operationId: this.professionalUser ? CoexistenceId.RecibosUsuarioProfesional : CoexistenceId.Recibos,
      operationParams: JSON.stringify({
        cdnumpol: this.model.offerNumber,
      }),
      parentFlowId: this.parentFlowId
    });
  }

  /**
   * @description end to user to pivot sinisters coexitencia page 
   */
  @EAMethod()
  onGoSinisters() {
    EAFlowNavigationUtils.navigate(this, 'ZonaZurich', {
      operationId: CoexistenceId.Siniestros,
      operationParams: JSON.stringify({
        cdnumpol: this.model.offerNumber,
      }),
      parentFlowId: this.parentFlowId
    });
  }

  /**
   * Updates date from general data info with the dates from qb-technical-considerations for trc
   * @param {FormValues} formValues
   */
  public onUpdateDates(formValues: FormValues) {
    this.model.generalDataInformationModel.movementEfect = formValues.startDate;
    this.model.generalDataInformationModel.movementDue = formValues.endDate;
  }

  /**
   * Saves in model data that will be used in offer issuance step
   */
  saveOfferIssuanceAdditionalData(): void {
    this.model.cessionRights = returnElementByCode('NOTITCES', this.datosObjeto);
    this.model.loanNumber = returnElementByCode('NOCUECES', this.datosObjeto);
  }

  /**
   * Gets policy elements for score
   * @returns {GetGeneralDataResponseDataDatosCabeceraElementosPoliza[]}
   */
  getPolicyElementsScore(): GetGeneralDataResponseDataDatosCabeceraElementosPoliza[] {
    const elements = ['NOPOBTOM', 'TCPOBTOM', 'TCCOPTOM'];
    return this.model.datosCabecera.elementosPoliza?.filter(
      elem => elem.codigoElemento && elements.includes(elem.codigoElemento)
    ) || [];
  }

  /**
   * Handles back button
   */
  onGoBack() {
    this.$emit('goToPreviousFlow');
  }

  /**
   * Emits showError event
   * @param {GenericErrorData} genericErrorData
   */
  showError(genericErrorData: GenericErrorData) {
    this.$emit('showError', genericErrorData);
  }

  /**
   * Emits handleGenericError event
   * @param {any} args
   */
  handleGenericError(args: any) {
    const {
      error, errorCode
    } = args;
    this.$emit('handleGenericError', {
      error,
      errorCode,
    });
  }
}
</script>

<style scoped lang="scss">
  .hidden {
    display: none;
  }
</style>
