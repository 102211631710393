<template>
  <div>
    <ea-card
      class="m-t-20 p-16 t-bg-color-primary-20">
      <div class="d-display-flex d-justify-space-between">
        <div class="d-display-flex d-align-items-flex-end">
          <span v-if ="showEmittedVehicles && totalEmittedVehicles" class="t-weight-regular t-size-small">
            {{ totalEmittedVehicles }}
            {{ $t('fleets.fleetsFlow.fleet-group-vehicle-table.tableDataFooter.emittedVehicles') }}
          </span>
        </div>
        <div class="d-display-flex d-direction-column d-justify-flex-end">
          <ea-row>
            <ea-col :span="23">
              <span class="t-align-right t-weight-bold t-size-medium d-display-flex d-justify-flex-end m-b-8 p-r-32">
                {{ $t('fleets.fleetsFlow.fleet-group-vehicle-table.tableDataFooter.premiumYearlyTotal') }}
              </span>
            </ea-col>
            <ea-col :span="1"> </ea-col>
          </ea-row>
          <ea-row>
            <div class="d-display-flex d-direction-horizontal d-justify-flex-end m-r-16">
              <ea-col :span="23">
                <span class="t-weight-bold t-size-medium d-display-flex d-justify-flex-end">
                  {{ digitsFormatter(totalAnnualPremium) }}
                </span>
              </ea-col>
              <ea-col :span="1">
                <ea-tooltip
                  placement="top">
                  <div slot="content">
                    {{ $t('fleets.fleetsFlow.fleet-group-vehicle-table.taxesIncludes') }}
                  </div>
                  <ea-icon status="info" icon="z-info" />
                </ea-tooltip>
              </ea-col>
            </div>
          </ea-row>
        </div>
      </div>
    </ea-card>
    
  </div>
</template>

<script lang="ts">

import {
  Component, Prop, Vue
} from 'vue-property-decorator';
import Utils from '@/utils/utils';

@Component({
  name: 'fleet-footer-total-annual-premium'
})

/**
 * Business Component fleet-footer-total-annual-premium
 */
export default class FleetFooterTotalAnnualPremium extends Vue {

  @Prop({
    'default': () => 0,
  })
    totalAnnualPremium?: number;

  @Prop({
    'default': () => 0,
  })
    totalEmittedVehicles?: number;
    
  @Prop({
    'default': () => false,
  })
    showEmittedVehicles?: boolean;
  
  /**
   * Formatea el precio ejemplo : 500 -> 500.00€
   * @param {String} price valor sin formato 500
   * @returns {String} valor con formato 500.00 €
   */
  digitsFormatter(price: number): string {
    return price ? `${Utils.formatFourDigitNumber(this.$n(price))} €` : '-';
  }
}
</script>
