import FleetGroupUploadVehiclesModel from '../fleet-group-upload-vehicles/fleet-group-upload-vehicles-model';
import FleetManualUploadVehicleModel, {
  VehicleFormModel
} from '../fleet-manual-upload-vehicle/fleet-manual-upload-vehicle-model';


export interface ErrorsVehicles {
  offerNumber: string;
  isError: boolean;
  errorMessage: string;
  plateNumber?: string;
}

/** 
 * Model fleet-input-vehicles-data
 */
class FleetInputVehiclesDataModel {
  public manualUploadVehicleModel = new FleetManualUploadVehicleModel();

  public groupUploadVehiclesModel = new FleetGroupUploadVehiclesModel();

  public fleetsEditVehicleModel = new FleetManualUploadVehicleModel();

  public vehicles: VehicleFormModel[] = []; // Table vehicles

  public errorsVehicles: ErrorsVehicles[] = [];
}

export default FleetInputVehiclesDataModel;
