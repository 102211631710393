<template>
  <div class="notification-handler">
    <ea-alert
      v-for="(notification, index) in notifications"
      :key="modifications+'-'+index"
      :type="notification.type"
      @close="closeNotification(index)"
      :title="getTitle(notification.title)"
      :description="notification.message"
      class="m-b-16"/>
  </div>
</template>

<script lang="ts">

import Vue from 'vue';
import {
  Component, Prop
} from 'vue-property-decorator';
import {
  EAEventBus
} from '@zurich-es-npm/ea-front-web-core';
import {
  NotificationsUtils,
  SEND_NOTIFICATIONS_EVENT,
  SEND_NOTIFICATIONS_EVENT_IN_MODAL,
  ZZNotification
} from '@/utils/notifications/notifications-utils';

@Component({
  name: 'notification-handler',
})

/**
 * Presentational Component notification-handler
 */
export default class NotificationHandler extends Vue {

  /**
   * `true` if component is instanciated in to a modal.
   * `false` default on flows
   */
  @Prop({
    required: false
  })
    isIntoModal!: boolean;
  
  notifications: ZZNotification[] = [];

  modifications: number = -1;

  private flowId: string = '';

  private currentEventListener: string = '';

  /**
   * Hook when page is created.
   */
  created() {
    this.flowId = NotificationsUtils.getCurrentFlowId();
    // eslint-disable-next-line max-len
    this.currentEventListener = `${SEND_NOTIFICATIONS_EVENT}${this.flowId}${this.isIntoModal? SEND_NOTIFICATIONS_EVENT_IN_MODAL: ''}`;
    EAEventBus.$on(this.currentEventListener, this.setNotifications);
  }

  /** 
   * Update Notifications key
   * @param {number} index 
   */
  closeNotification(index: number) {
    this.modifications++;
    this.notifications.splice(index, 1);
  }

  /** 
   * Update Notifications key
   * @param {string} title 
   * @returns {string}
   */
  getTitle(title: string): string {
    if (this.$te(`notificationHandlerComponent.notificationTitleTypes.${title}`)) {
      return this.$t(`notificationHandlerComponent.notificationTitleTypes.${title}`).toString();
    }
    return title;
  }

  /** 
   * Update Notifications key
   * @param {ZZNotification[]} notifications 
   */
  setNotifications(notifications: ZZNotification[]) {
    this.modifications++;
    if (notifications.length) {
      this.notifications.push(...notifications);
    } else {
      this.notifications = [];
    }
  }

  /** 
   * Hook beforeDestroy
   */
  beforeDestroy() {
    EAEventBus.$off(this.currentEventListener, this.setNotifications);
  }

}
</script>

