export interface FormValuesInterface {
  majorVehiclesHasAdvancedDriverTechnology?: boolean;
  anyDriversCurrentlyDoingEfficiencyCourses?: boolean;
  securityControlsRiskManagement?: boolean;
}

/** 
 * Model fleets-risk-management
 */
class FleetsRiskManagementModel {

  public formValues: FormValuesInterface = {
    majorVehiclesHasAdvancedDriverTechnology: undefined,
    anyDriversCurrentlyDoingEfficiencyCourses: undefined,
    securityControlsRiskManagement: undefined
  };

}

export default FleetsRiskManagementModel;
