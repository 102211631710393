<template>
  <ea-dialog
    v-if="tour"
    :title="$t('tour.modalTitle')"
    :closeOnClickModal="false"
    :visible.sync="showTour"
    size="extralarge"
    @close="endTour">
        <ea-carousel class="tour d-align-items-center t-align-center"
        :loop="false"
        height="430px">
          <ea-carousel-item  v-for="(step, index) in steps" :key="index">
              <div class="slide" >
                <h1 v-if="step.title">
                  {{ $t(step.title) }}
                </h1>
                <h4 class="t-weight-regular">
                  {{ $t(step.description) }}
                </h4>
                <img v-if="step.imgSrc || step.imgAlt"
                    :src="require(`@/assets/images/${step.imgSrc}`)"
                    :alt="step.imgAlt" />
            </div>
          </ea-carousel-item>
        </ea-carousel>
  </ea-dialog>
</template>

<script lang="ts">

import {
  EAEventBus
} from '@zurich-es-npm/ea-front-web-core';
import {
  Vue,
  Component,
} from 'vue-property-decorator';
import {
  Tour, TourStep
} from './ea-tour-model';
import {
  TourUtils
} from './utils/tour-utils';


@Component

/**
 * Beginner application tour feature
 */
export default class EATour extends Vue {

  tour: Tour | null = null;

  showTour: boolean = false;
  
  currentStep: number = 0;

  /**
   * Get tour steps
   */
  get steps(): TourStep[] {
    return this.tour ? this.tour.steps : [];
  }


  /**
   * 
   */
  created() {
    EAEventBus.$on('regularizationTourOpen', (tour: Tour) => {
      this.tour = tour;
      this.showTour = true;
    });
  }

  /**
   * Remove the listeners created for analytics tracking
   * @memberof EAAnalyticsTracking
   */
  beforeDestroy(): void {
    EAEventBus.$off('regularizationTourOpen');
  }

  /**
   * Navigate to the next step of the tour
   */
  public nextStep() {
    this.currentStep++;
    if (this.currentStep === this.steps.length) {
      this.endTour();
    }
  }

  /**
   * Navigate to the next step of the tour
   */
  public previousStep() {
    if (this.currentStep > 0) {
      this.currentStep--;
    }
  }

  /**
   * Finish tour
   */
  public endTour() {
    TourUtils.closeTour(this.tour as Tour);
    this.showTour = false;
    this.tour = null;
  }
}
</script>

<style lang="scss" scoped>
  .tour {
    img {
      max-height: 400px;
      margin: 0 auto;
    }
  }
@media only screen and (max-width: 1024px) {
  .tour {
    img {
      max-width: 600px;
    }
  }
}
@media only screen and (max-width: 820px) {
  .tour {
    img {
      max-width: 575px;
    }
  }
}
@media only screen and (max-width: 768px) {
  .tour {
    img {
      max-width: 550px;
    }
  }
}
</style>

