<!-- eslint-disable max-lines -->
<template>
  <div>
    <warranties-header
      id="warrantiesHeader"
      ref="warrantiesHeader"
      v-model="warrantiesHeaderModel"
      :warrantiesGroups="model.productModel.warrantyGroupList"
      :showWarrantiesGroups="productFactory.warrantiesHeaderPolicyConfig.showWarrantiesGroups"
      :canSelectMultipleWarrantiesGroups="productFactory.warrantiesHeaderPolicyConfig.canSelectMultipleWarrantiesGroups"
      :warrantiesGroupsDisplayMode="productFactory.warrantiesHeaderPolicyConfig.warrantiesGroupsDisplayMode"
      :packagesGroups="packagesGroups"
      :showPackagesGroups="productFactory.warrantiesHeaderPolicyConfig.showPackagesGroups"
      :canSelectMultiplePackagesGroups="productFactory.warrantiesHeaderPolicyConfig.canSelectMultiplePackagesGroups"
      :packagesGroupsDisplayMode="productFactory.warrantiesHeaderPolicyConfig.packagesGroupsDisplayMode"
      :canLoadMoreOptions="false"
      :consultaOperation="consultaOperation"
      @callTarification="onHeaderRetarification"
      @showEmptyWarrantiesMessage="showEmptyWarrantiesMessage"
    >
      <!-- Slot for searcher + proficiency mgmt -->
      <div class="w-25 d-display-flex d-direction-column m-b-16">
        <ea-form
          ref="form"
          :model="searchTermForm"
          :validationOptions="searchFormValidationOptions"
        >
          <ea-form-item :label="$t('common.label.search')" prop="searchTerm">
            <div class="d-display-flex d-align-items-center">
              <ea-input-text
                :disabled="!!emptyWarrantiesMessage"
                v-model="searchTermForm.searchTerm"
                :placeholder="$t('warranties.shared.searchPlaceholder')"
                @focus="onSearchTermFocusGain"
                @blur="onSearchTermFocusLost"
                @input="onSearchTermInput"
              ></ea-input-text>
            </div>
          </ea-form-item>
        </ea-form>
      
        <qb-proficiency-management
          id="qb-proficiency-management"
          v-model="proficiencyManagementModelCopy"
          :checkGestionCompetencias="model.pendingCompetenceManagement"
          :proficiencyManagementList="proficiencyManagementList"
          :productFactory="productFactory"
          ref="proficiencyMgmtTop"
          @retrieveProficiencyManagementData="refreshProficiencyManagementData('proficiencyMgmtTop')"
          @close="closeProficiencyManagementDialog('proficiencyMgmtTop')"
        ></qb-proficiency-management>
      </div>
    </warranties-header>

    <ea-row v-if="emptyWarrantiesMessage">
      <ea-col :sm="24" :md="24" :lg="24" :xl="24" class="m-b-24">
        <ea-card>
          <ea-result
          :title="emptyWarrantiesMessage"
          :description="$t('warranties.refreshCard.description')"
          :type="'empty'"
          icon="z-boat"/>
        </ea-card>
      </ea-col>
    </ea-row>
    <template v-else>
      <!-- Warranties header -->
      <ea-row class="t-bg-color-primary-20 m-b-16 p-x-24 p-y-16 t-size-large">
        <ea-col :span="calculateSpans" :class="getHeaderPadding()">
          <ea-heading level="5">
            {{ $t('warranties.shared.warrantyTitle') }}
          </ea-heading>
        </ea-col>
        <ea-col v-for="(proposal, index) of proposalsData"
        :key="index"
        :span="calculateSpans"
        class="font-size-bold t-align-center">
          <ea-heading level="5">
            {{ getTitleProposal(index) }}
          </ea-heading>
          <ea-text size="large" v-if="productFactory.shouldShowCustomPackageName">
            {{ getSubTitleProposal(index, proposal) }}
          </ea-text>
          <div :class="getHeaderPadding(true)"
            v-if="proposal.codigoPropuesta"
          >
            <span class="m-b-16 t-weight-bold"
              :class="{
                't-color-primary': showingProposalPrice[proposal.codigoPropuesta],
                't-color-warning': !showingProposalPrice[proposal.codigoPropuesta]
              }"
            >
              {{ showingProposalPrice[proposal.codigoPropuesta] && model.warrantyRates[proposal.codigoPropuesta] ?
                `${getProposalPrice(proposal)} €` : '-'
              }}
            </span>
          </div>
        </ea-col>
      </ea-row>

      <!-- Warranty business components -->
      <ea-row v-for="(warrantyData, index) in warrantiesData" :key="warrantyData.codigoGarantia" class="m-b-16">
        <ea-col>
          <!-- Title -->
          <h4 v-if="warrantiesData && $te(`warranties.${warrantyData.codigoGarantia}.title`)">
            {{ $t(`warranties.${warrantyData.codigoGarantia}.title`) }}
          </h4>

          <ea-card shadow="hidden" class="warranty-card">
            <div slot="cardbody">
              <warranty-business v-if="warrantiesData"
                :ref="warrantyData.codigoGarantia"
                :id="index"
                :columnsSpan="calculateSpans"
                v-model="warrantiesData[index]"
                :warrantiesData="warrantiesData"
                :eventsEnabled="eventsEnabled"
                :existBeforeVersion="existBeforeVersion"
                :allProposalCodes="model.warrantiesProposals"
                :productFactory="productFactory"
                @qbWarrantyChange="onQbWarrantyChange"
                @qbWarrantyReset="onQbWarrantyReset"
              />
            </div>
          </ea-card>
        </ea-col>
      </ea-row>

      <!-- Premium adjustments -->
      <ea-row v-if="premiumAdjustmentsData && premiumAdjustmentsData.length" class="m-b-0">
        <ea-col>
          <ea-card shadow="hidden">
            <div slot="cardbody" class="premium-adjustments-container">
              <ea-row>
                <ea-col :span="calculateSpans" class="border-right-lightGrey p-a-16 p-l-28">
                  <qb-premium-adjustments
                    id="qb-general-data-information"
                    ref="premiumAdjustmentsComp"
                    v-model="model.premiumAdjustmentsModel"
                    :consultaOperation="consultaOperation"
                    :premiumAdjustmentsData="premiumAdjustmentsData"
                    :readOnly="consultaOperation"
                    @qbWarrantyReset="onQbWarrantyReset"
                  ></qb-premium-adjustments>
                </ea-col>
                <ea-col v-for="(proposal, index) of proposalsData"
                  class="proposals"
                  :key="index"
                  :span="calculateSpans"
                >
                  <div class="d-display-flex d-direction-column d-align-items-center m-t-64 p-t-32 price-container"
                    :class="calculateSpans === 12 ? 'd-align-items-start single-col' : 'd-align-items-left'"
                    v-if="proposal.codigoPropuesta"
                  >
                    <span class="option-rate m-b-16 t-weight-bold"
                      :class="{
                        't-color-primary': showingProposalPrice[proposal.codigoPropuesta],
                        't-color-warning': !showingProposalPrice[proposal.codigoPropuesta]
                      }"
                    >
                      {{ showingProposalPrice[proposal.codigoPropuesta]
                        && model.warrantyRates[proposal.codigoPropuesta] ? `${getProposalPrice(proposal)} €` : '-'
                      }}
                    </span>
                    <ea-button
                      v-if="viewContractButton(proposal, index)"
                      type="primary"
                      size="medium"
                      @click="onRateSelect(proposal.codigoPropuesta)"
                    >
                      {{ $t(getTitleButton) }}
                    </ea-button>
                    <ea-button
                      v-if="viewRequoteButton(proposal, index)"
                      type="warning"
                      size="medium"
                      @click="onRequote()"
                    >
                      {{ $t('warranties.pricing') }}
                    </ea-button>
                  </div>
                </ea-col>
              </ea-row>
              <!-- Fractional payments button -->
              <ea-row class="fractional-payment">
                <ea-col
                  :offset="calculateSpans"
                  :span="calculateSpans * (numberOfColumns - 1)"
                  class="t-align-center fractional-payment-button border-left-lightGrey">
                  <ea-button
                    class="d-display-flex d-justify-center"
                    type="text" @click="openFractionalPaymentDialog"
                    v-if="fractionalPaymentLinkShown"
                  >
                    {{ isMovementTypeNormalSupplement()
                      ? $t('warranties.fractionalPaymentSupplement')
                      : $t('warranties.fractionalPaymentPolicy')
                    }}
                  </ea-button>
                </ea-col>
              </ea-row>
            </div>
          </ea-card>
        </ea-col>
      </ea-row>
    </template>

    <!-- View Buttons-->
    <div class="d-display-flex d-justify-space-between m-t-24">
      <div class="d-display-flex d-align-items-center">
        <ea-button type="secondary" @click="onGoBack()">
          {{ $t('common.label.back') }}
        </ea-button>
      </div>
      <div class="d-display-flex d-align-items-center">
        <qb-proficiency-management
          id="qb-proficiency-management"
          class="m-r-24"
          v-model="proficiencyManagementModelCopy"
          :checkGestionCompetencias="model.pendingCompetenceManagement"
          :proficiencyManagementList="proficiencyManagementList"
          :productFactory="productFactory"
          ref="proficiencyMgmtBottom"
          @retrieveProficiencyManagementData="refreshProficiencyManagementData('proficiencyMgmtBottom')"
          @close="closeProficiencyManagementDialog('proficiencyMgmtBottom')"
        ></qb-proficiency-management>
        
        <ea-button
          type="secondary"
          v-if="!consultaOperation"
          :disabled="generateDocDisabled"
          @click="onGoToGenerateDocumentation"
        >
          {{ $t('warranties.generateDocumentation') }}
        </ea-button>
      </div>
    </div>

    <!-- Fractional Payment Business Component -->
    <qb-fractional-payment-modal
      id="fractionalPayment-policy"
      v-model="model.fractionalPaymentModel"
      :productFactory="productFactory"
    ></qb-fractional-payment-modal>
    
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import {
  Component, Prop
} from 'vue-property-decorator';
import {
  mixins
} from 'vue-class-component';

import {
  cloneDeep, isNumber
} from 'lodash';

import {
  EAError,
  EAErrorManager,
  EAMethod,
  EAMultiError,
  EAValidationError,
  EAView,
  ResponseWithErrors,
  throwIfResponseHasErrors
} from '@zurich-es-npm/ea-front-web-core';

import {
  EACompositeValidation,
  eaCustomValidation,
  EAFormValidationOptions,
  EAValidation,
  Form
} from '@zurich-es-npm/ea-front-web-ui';

import {
  FlowHeaderStepsModel,
  FlowViewsStepsModel,
  PolicyModel,
  PolicyMovementType
} from '../policy-model';

import QbProficiencyManagementBusiness
  from '@/business-components/qb-proficiency-management/qb-proficiency-management-business.vue';
import {
  ActionType, EventChange, WarrantyElement
} from '@/business-components/qb-warranty/warranty.types';
import WarrantyFieldUtils from '@/business-components/qb-warranty/warranty-field-utils';
import QbPremiumAdjustmentsBusiness
  from '@/business-components/qb-premium-adjustments/qb-premium-adjustments-business.vue';
import WarrantyBusiness from '@/business-components/qb-warranty/warranty-business.vue';
import QbFractionalPaymentModal
  from '../../../business-components/qb-fractional-payment-modal/qb-fractional-payment-business-modal.vue';

import {
  EAGetCompetencesManagementByOfferApi,
  GetCompetencesManagementByOfferResponseCompetencesManagementByOfferData
} from '@/services/V1/quoteAndBuy/getCompetencesManagementByOfferOperation/post';
import {
  EAGetWarrantiesPolicyApi,
  GetWarrantiesPolicyRequestTipoPolizaEnum,
  GetWarrantiesPolicyResponse,
  GetWarrantiesPolicyResponseData,
  GetWarrantiesPolicyResponseDataDatosAjustesPrimas,
  GetWarrantiesPolicyResponseDataDatosGarantias,
  GetWarrantiesPolicyResponseDataDatosPropuestas,
  GetWarrantiesPolicyResponseDataDatosTarificacion,
  GetWarrantiesPolicyResponseDataDatosTarificacionFormaPagoTarificacionEnum as FormaPagoTarificacion,
  GetWarrantiesPolicyResponseDataElementosGarantias,
  GetWarrantiesPolicyResponseDataGarantiaSeleccionada,
  GetWarrantiesPolicyResponseDataTablaRestricciones
} from '@/services/V1/quoteAndBuy/getWarrantiesPolicyOperation/post';

import {
  GetWarrantiesResponseDataDatosGarantias,
  GetWarrantiesResponseDataDatosPropuestas
} from '@/services/V1/quoteAndBuy/getWarrantiesOperation/post';

import {
  GetGeneralDataRequestTipoOperacionOfertaEnum as OperationType,
} from '@/services/V1/quoteAndBuy/getGeneralDataOperation/post';

import {
  GetFractionalPaymentsRequestCodigosPropuesta,
} from '@/services/V1/quoteAndBuy/getFractionalPaymentsOperation/post';

import {
  EAContractOfferPolicyApi
} from '@/services/V1/policy/contractOfferPolicyOperation/post';

import {
  EAUpdateWarrantiesPolicyAndRateApi,
  UpdateWarrantiesPolicyAndRateRequestDatosGarantias,
  UpdateWarrantiesPolicyAndRateRequestDatosPropuestas,
  UpdateWarrantiesPolicyAndRateRequestDatosPropuestasTipoMascaraEnum as TipoMascaraEnum,
  UpdateWarrantiesPolicyAndRateRequestTipoPolizaEnum,
  UpdateWarrantiesPolicyAndRateResponse,
  UpdateWarrantiesPolicyAndRateResponseDatosGarantias,
} from '@/services/V1/quoteAndBuy/updateWarrantiesPolicyAndRateOperation/post';

import Utils from '@/utils/utils';
import ProductBase from '@/utils/quote-buy-product-factory/products/product-base';
import QbSelectDocumentationSendingAddressModel
  from '@/business-components/qb-select-documentation-sending-address/qb-select-documentation-sending-address-model';
import MultipleEmailInputModel from '@/business-components/multiple-email-input/multiple-email-input-model';
import AddEditPhoneModel from '@/business-components/addresses/selectables/add-edit-phone/add-edit-phone-model';
import {
  EAGetWarrantiesPolicyConsultationModeApi,
  GetWarrantiesPolicyConsultationModeRequestTipoPolizaEnum as TipoPolizaEnum
} from '@/services/V1/quoteAndBuy/getWarrantiesPolicyConsultationModeOperation/post';
import {
  PersonRole
} from '@/utils/quote-buy-product-factory/types/product-role-types';
import {
  FractionalPaymentDataModel
} from '@/business-components/qb-fractional-payment-modal/qb-fractional-payment-modal-model';
import {
  SubflowThunderNameEnum
} from '@/types/flow/flow-enum.types';
import ZzCollapse from '@/presentational-components/collapse/collapse.vue';
import {
  EAValidatorFunction
} from '@zurich-es-npm/ea-front-web-core/lib/validation-rules';
import {
  NotificationsTypeEnum, NotificationsUtils
} from '@/utils/notifications/notifications-utils';
import TextUtils from '@/utils/text-utils';
import {
  Roles
} from '@/types/roles/roles-enum.types';
import {
  codeMessageFicoFic, ErrorCodesGoBack
} from '@/flows/quote-buy/quote-buy-model';
import WarrantiesHeaderBusiness
, {
  WARRANTIES_HEADER_DISPLAY_MODE, WARRANTIES_HEADER_SELECTIONS
} from '@/business-components/qb-warranty/warranties-header/warranties-header-business.vue';
import WarrantiesHeaderModel from '@/business-components/qb-warranty/warranties-header/warranties-header-model';
import {
  LabelValue
} from '@/business-components/qb-producto-asegurado-comunidades/qb-producto-asegurado-comunidades-model';

@Component({
  components: {
    QbProficiencyManagement: QbProficiencyManagementBusiness,
    WarrantyBusiness: WarrantyBusiness,
    QbPremiumAdjustments: QbPremiumAdjustmentsBusiness,
    QbFractionalPaymentModal: QbFractionalPaymentModal,
    WarrantiesHeader: WarrantiesHeaderBusiness
  }
})

/**
 * Warranties view for Policy flow
 *
 */
export default class WarrantiesView extends mixins<EAView<PolicyModel>>(EAView) {

  @Prop({
    required: true,
  })
    productFactory!: ProductBase;

  @Prop({
    required: true,
  })
    hasTestAbility!: boolean;

  tomadorPerson!: PersonRole;

  warrantiesData: GetWarrantiesPolicyResponseDataDatosGarantias[] = [];

  pricingData: GetWarrantiesPolicyResponseDataDatosTarificacion[] = [];

  showingProposalPrice: Record<string, boolean> = {}; // Relationship proposal/ready to buyout (for all proposals)

  fractionalPaymentForms: GetWarrantiesPolicyResponseDataTablaRestricciones[] = [];

  proficiencyManagementList?: GetCompetencesManagementByOfferResponseCompetencesManagementByOfferData[] = [];

  bannedPaymentForms = ['U'];

  premiumAdjustmentsData?: GetWarrantiesPolicyResponseDataDatosAjustesPrimas[] = [];

  isInitialTarification = true; // Flag to know if is the first time getting price

  public selectDocumentationSendingAddressModel = new QbSelectDocumentationSendingAddressModel();

  public multipleEmailInputModel: MultipleEmailInputModel = new MultipleEmailInputModel();
  
  public addEditPhoneModel = new AddEditPhoneModel();

  public beforeVersion?: GetWarrantiesPolicyResponseData;

  existBeforeVersion?: boolean = false;

  beforeVersionNumber?: number;

  requoteProposal?: boolean = false;

  isReplacement: boolean = false;

  /*
   * Events will be disabled until user starts to make changes
   * It prevents prices to be resetted when creating form for first time
   * or when requote is triggered
   */
  eventsEnabled = false;

  // Search warranties variables
  searchTermForm = {
    searchTerm: ''
  };

  lastSearchTerm = '';

  searchInputHasFocus = false;

  scrollElementIndex = 0;

  proposalsData: GetWarrantiesPolicyResponseDataDatosPropuestas[] = [];

  warrantiesHeaderModel: WarrantiesHeaderModel = new WarrantiesHeaderModel();

  emptyWarrantiesMessage: string = '';

  retrievedProposalNumbers: string[] = [];

  /* LIFECYCLE HOOKS */
  /**
   * Hook on created
   * @returns {Promise<void>}
   */
  @EAMethod({
    loading: true,
  })
  public async created(): Promise<void> {
    this.resetInfoMessage();

    if (this.consultaOperation) {
      await this.fetchWarrantiesConsultionMode();
    } else {
      await this._fetchWarranties();
    }

    this.tomadorPerson = this.productFactory.personRoles.find(person => person.role === Roles.Tomador) as PersonRole;

    // Set filiationCode 
    if (this.tomadorPerson.searchModel.selectedPerson?.datosBasicosPersona?.codigoFiliacion) {
      this.model.filiationCode = this.tomadorPerson.searchModel.selectedPerson.datosBasicosPersona.codigoFiliacion;
    }

    // Set policy taker postal address
    this.selectDocumentationSendingAddressModel.policyTakerAddressList =
      this.tomadorPerson.addressesModel.addEditAddress.addressList;
    this.selectDocumentationSendingAddressModel.policyTakerSelectedAddressCode =
      this.tomadorPerson.addressesModel.addEditAddress.selectedAddress?.codigoSecuencialDomicilio;
    
    // Set policy taker selected email
    if (this.tomadorPerson.addressesModel.addEditEmail.selectedEmail?.emailContacto) {
      this.multipleEmailInputModel.emailList =
        [this.tomadorPerson.addressesModel.addEditEmail.selectedEmail?.emailContacto];
    }

    // Set policy taker phone list
    this.addEditPhoneModel.phoneList = this.tomadorPerson.addressesModel.addEditPhone.phoneList;
    this.addEditPhoneModel.selectedPhoneNumber = this.tomadorPerson.addressesModel.addEditPhone.selectedPhoneNumber;
    this.showFicoFicWarning();
    
    window.addEventListener('keydown', event => {
      (async() => {
        await this.onKeyPressed(event);
      })();
    });
    
    this.update();
  }

  /* GETTERS / COMPUTEDS and similar */

  /**
   * Checks if the operation type is of type maintenence
   */
  get consultaOperation() {
    return this.model.operationType === OperationType.CONSULTA;
  }

  /**
   * Get number of columns.
   * The number of columns is calculated by adding 1 to warranty elements.
   */
  get numberOfColumns() {
    return this.proposalsData.length + 1;
  }

  /**
   * Calculate spans.
   * Divide the max number of spans (24) by the number of warranty columns.
   */
  get calculateSpans() {
    return 24 / this.numberOfColumns;
  }

  /**
   * Get disabled state for generate documentation step
   *
   * @returns {boolean}
   */
  get generateDocDisabled(): boolean {
    if (this.existBeforeVersion) {
      // In supplement case must have price the movement and the earlier version 

      return this.isAnyPriceShown() === 1;
    }

    if (this.isAnyPriceShown() && this.emptyWarrantiesMessage === '') {
      return false;
    } else {
      return true;
    }
  }

  /**
   * Get the calculated percentage (discount) user informed in premium adjustments (ajusteComercialCotizacion)
   */
  get ajusteComercialCotizacion(): number {
    const discountValue = this.model.premiumAdjustmentsModel.discountValue || 0;
    const discountType: 1 | -1 = this.model.premiumAdjustmentsModel.discountType === '-1' ? -1 : 1;
    return discountValue * discountType;
  }

  /**
   * Get button name
   * @returns {string}
   */
  get getTitleButton(): string {
    return this.model.pendingCompetenceManagement ?
      'quoteBuyGenericFlow.offerIssuanceData.requestAuthorizationBtn' :
      'warranties.emit';
  }

  /**
   * Get proposal title
   * @param {number} index
   * @param {GetWarrantiesPolicyResponseDataDatosPropuestas} proposal
   * @returns {string}
   */
  getTitleProposal(index: number): string {
    
    /**
     * Always show title for Supplements as we will have 2 proposals (current one and previous one)
     */
    if (this.productFactory.shouldShowProposalTitle || this.isMovementTypeNormalSupplement()) {
      if (this.productFactory.shouldShowCustomPackageName) {
        return this.getTitleProposalCustom(index);
      } else {
        if (index === 0) {
          // Title for first proposal
          return this.$t('warranties.shared.proposalMovement').toString();
        }
        if (index === 1) {
          // Title for earlier version with the number
          return this.$t('warranties.shared.earlierVersion', {
            number: this.beforeVersionNumber
          }).toString();
        }
      }
    }
    return '';
  }

  /**
   * Get proposal title
   * @param {number} index
   * @param {GetWarrantiesPolicyResponseDataDatosPropuestas} proposal
   * @returns {string}
   */
  getSubTitleProposal(index: number, proposal: GetWarrantiesPolicyResponseDataDatosPropuestas): string {
    if (index === 0) {
      // Title for first package
      const packageNameTranslation = this.$t(`warranties.packageName.${proposal.codigoPropuesta}`).toString();
      return `${packageNameTranslation}`;
    }

    if (index === 1) {
      // Title for earlier version package
      const packageNameTranslation = this.$t(`warranties.packageName.${
        proposal.codigoPropuesta?.slice(0, -2) // Remove 'VA' characters for translation purposes
      }`).toString();
      return `${packageNameTranslation}`;
    }

    return '';
  }


  /**
   * Get proposal title for custom packages
   * @param {number} index
   * @returns {string}
   */
  getTitleProposalCustom(index: number): string {
    if (index === 0) {
      // Title for first proposal
      const proposalMovementTranslation = this.$t('warranties.shared.proposalMovement').toString();
      return `${proposalMovementTranslation}`;
    }

    if (index === 1) {
      // Title for earlier version with the number
      const earlierVersionTranslation = this.$t('warranties.shared.earlierVersion', {
        number: this.beforeVersionNumber
      }).toString();
      return `${earlierVersionTranslation}`;
    }

    return '';
  }

  /**
   * Get header padding
   * @param {boolean} price
   * @returns {string | undefined}
   */
  getHeaderPadding(price?: boolean): string | undefined {
    if (this.proposalsData.length === 2) {
      return 'p-t-16';
    }

    // Center price when there is only one propouse
    if (price) {
      return 'p-t-8';
    }
  }

  /**
   *
   * @param {GetWarrantiesResponseDataDatosPropuestas} proposal
   * @return {string | undefined}
   */
  getProposalPrice(proposal: GetWarrantiesPolicyResponseDataDatosPropuestas): string | undefined {
    if (!proposal.codigoPropuesta) {
      return;
    }
    const warrantyRate = this.$n(+this.model.warrantyRates[proposal.codigoPropuesta], 'decimal');
    return Utils.formatFourDigitNumber(warrantyRate);
  }

  /**
   * Return an array of proposal codes that we currently do not have price.
   * 
   * @returns {string[]}
   */
  getPlainArrayProposalsToPrice(): string[] {
    return Object.entries(this.showingProposalPrice)
      .filter(entry => !entry[1])
      .map(entry => entry[0]);
  }

  /* TEMPLATE HANDLERS */

  /**
   * Goes to previous step
   */
  onGoBack() {
    // ### HIDE COMPETENCY MGMT ALERT ###
    NotificationsUtils.clearNotifications();
    
    this.$emit('changeStep', FlowViewsStepsModel.GeneralDataStep, FlowHeaderStepsModel.GeneralDataStep);
  }

  /**
   * Listener: select a rate.
   *
   * @param {string} codigoPropuesta
   */
  public async onRateSelect(codigoPropuesta: string | undefined): Promise<void> {
    if (codigoPropuesta) {
      const asegurado = this.productFactory.personRoles.filter(person => person.role === Roles.Asegurado)[0];
      asegurado.roleEqualStatements?.forEach(roleEqual => {
        roleEqual.readonly = true;
      });
      this.model.selectedProposal = codigoPropuesta;
      this.clearFlowErrors();
      await this.callContractOffer();
    }
  }
  
  /**
   * Handle for Warranty Change event
   *
   * @param {EventChange} eventChange
   * @return {void}
   */
  onQbWarrantyChange(eventChange: EventChange): void {
    let datosPropuestasReceiver = [];

    if (eventChange.receiver.proposalCode === 'All') {
      datosPropuestasReceiver = this.findAllWarrantyProposalData(eventChange.receiver) || [];
    } else {
      const foundDatosPropuestaReceiver = this.findWarrantyProposalData(eventChange.receiver);
      if (foundDatosPropuestaReceiver) {
        datosPropuestasReceiver.push(foundDatosPropuestaReceiver);
      }
    }

    if (!datosPropuestasReceiver || !datosPropuestasReceiver.length) {
      return;
    }
    
    for (const datosPropuestaReceiver of datosPropuestasReceiver) {
      this.performEvent(eventChange, datosPropuestaReceiver);
    }
  }

  /**
   * Handle for Warranty Reset event
   * @param {string | undefined} codigoPropuesta - Resetted proposal
   * @return {void}
   */
  onQbWarrantyReset(codigoPropuesta?: string): void {
    if (codigoPropuesta) {
      Vue.set(this.showingProposalPrice, codigoPropuesta, false);
      Vue.set(this.model.warrantyRates, codigoPropuesta, undefined);
    } else {
      this.model.warrantiesProposals.forEach((proposalCode, index) => {
        if (this.beforeVersion && index === 1) {
          return;
        }
        Vue.set(this.showingProposalPrice, proposalCode, false);
        Vue.set(this.model.warrantyRates, proposalCode, undefined);
      });
    }
    this.model.selectedProposal = '';
  }

  /**
   * Open fractional payment dialog
   */
  openFractionalPaymentDialog() {
    const proposalNumbers = this.model.warrantiesProposals;
    const fractionalPaymentData = this.getFractionalPaymentData();
    const pricingData: GetWarrantiesPolicyResponseDataDatosTarificacion =
      // eslint-disable-next-line max-len
      this.pricingData.find(price => price.codigoPropuesta && price.formaPagoTarificacion) as GetWarrantiesPolicyResponseDataDatosTarificacion;
    this.model.fractionalPaymentModel = {
      title: this.isMovementTypeNormalSupplement()
        ? this.$t('warranties.fractionalPaymentUpperTitleSupplements', {
          payment: pricingData.formaPagoTarificacion
        }).toString() // Only pass title if Supplement ('2')
        : '',
      movementType: this.model.movementTypeCode as PolicyMovementType,
      isVisible: true,
      fractionalPaymentData,
      isSingularTitle: true,
      proposalNumbers
    };
  }

  /**
   * Get fractional payment data (only one proposla and only the current payment form)
   * 
   * @returns {FractionalPaymentDataModel[]}
   */
  getFractionalPaymentData(): FractionalPaymentDataModel[] {
    const pricingData: GetWarrantiesPolicyResponseDataDatosTarificacion =
      // eslint-disable-next-line max-len
      this.pricingData.find(price => price.codigoPropuesta && price.formaPagoTarificacion) as GetWarrantiesPolicyResponseDataDatosTarificacion;

    if (this.isMovementTypeNormalSupplement()) {
      return this.getFractionalPaymentDataForNormalSupplement(pricingData);
    }

    // Total price
    const paymentFormValuesTotal: Record<string, string | number> = {};
    paymentFormValuesTotal[`${pricingData.codigoPropuesta}`] = `${pricingData.importeTotalRecibo}`;

    // 1st receipt
    const paymentFormValues1st: Record<string, string | number> = {};
    paymentFormValues1st[`${pricingData.codigoPropuesta}`] = `${pricingData.importePrimerRecibo}`;

    // Successive receipts
    const paymentFormValuesSuccessive: Record<string, string | number> = {};
    paymentFormValuesSuccessive[`${pricingData.codigoPropuesta}`] = `${pricingData.importeRecibosSucesivos}`;

    // Mount fractional data for the modal
    const fractionalPaymentData: FractionalPaymentDataModel[] = [
      { // Total payment form price
        paymentForm: `${pricingData.formaPagoTarificacion}`,
        paymentFormValues: paymentFormValuesTotal,
        showInBold: true
      },
      { // Price 1st receipt
        paymentForm: `${this.$t('warranties.firstReceipt')}`,
        paymentFormValues: paymentFormValues1st,
      },
      { // Price successive receipts
        paymentForm: `${this.$t('warranties.successiveReceipts')}`,
        paymentFormValues: paymentFormValuesSuccessive,
      }
    ];

    return fractionalPaymentData;
  }

  /**
   * Get fractional payment data for normal Supplements
   * @param {GetWarrantiesPolicyResponseDataDatosTarificacion} pricingData 
   * @returns {FractionalPaymentDataModel}
   */
  getFractionalPaymentDataForNormalSupplement(
    pricingData: GetWarrantiesPolicyResponseDataDatosTarificacion
  ): FractionalPaymentDataModel[] {
    // Annual total
    const paymentFormValuesAnualTotal: Record<string, string | number> = {};
    paymentFormValuesAnualTotal[`${pricingData.codigoPropuesta}`] = `${pricingData.importeTotalRecibo}`;

    // Movement
    const paymentFormValuesMovement: Record<string, string | number> = {};
    paymentFormValuesMovement[`${pricingData.codigoPropuesta}`] = `${pricingData.importePrimerRecibo}`;

    // 1st receipt
    const paymentFormValues1stReceipt: Record<string, string | number> = {};

    if (pricingData.importeAcumuladoRecibo) {
      paymentFormValues1stReceipt[`${pricingData.codigoPropuesta}`] = `${pricingData.importeAcumuladoRecibo}`;
    }

    // Successive receipts
    const paymentFormValuesSuccessive: Record<string, string | number> = {};
    paymentFormValuesSuccessive[`${pricingData.codigoPropuesta}`] = `${pricingData.importeRecibosSucesivos}`;

    // Mount fractional data for the modal
    const fractionalPaymentData: FractionalPaymentDataModel[] = [
      { // Annual total
        paymentForm: `${this.$t('warranties.annualTotal')}`,
        paymentFormValues: paymentFormValuesAnualTotal,
        showInBold: true
      },
      { // Movement
        paymentForm: `${this.$t('warranties.movementPay')}`,
        paymentFormValues: paymentFormValuesMovement,
      },
      { // 1st receipt
        paymentForm: `${this.$t('warranties.firstReceipt')}`,
        paymentFormValues: paymentFormValues1stReceipt,
      },
      { // Successive
        paymentForm: `${this.$t('warranties.successiveReceipts')}`,
        paymentFormValues: paymentFormValuesSuccessive,
      }
    ];

    // #### Si el pago no es fraccionado, no debe aparecer prima sucesivos
    if (
      pricingData.formaPagoTarificacion === FormaPagoTarificacion.Anual
      || pricingData.formaPagoTarificacion === FormaPagoTarificacion.Unica
    ) {
      fractionalPaymentData.splice(3, 1);
    }

    // #### Si la prima movimiento es igual a la prima del primer recibo, solo debe visualizarse la prima del movimiento
    if (!pricingData.importeAcumuladoRecibo) {
      fractionalPaymentData.splice(2, 1);
    }

    return fractionalPaymentData;
  }

  /**
   * Closes proficiency management dialog
   * @param {string} componentReference
   */
  closeProficiencyManagementDialog(componentReference: string) {
    const proficiencyMgmtComp: QbProficiencyManagementBusiness =
        this.$refs[componentReference] as QbProficiencyManagementBusiness;

    if (proficiencyMgmtComp) {
      proficiencyMgmtComp.hideModal();
    }
    this.proficiencyManagementList = [];
  }

  /* VIEW METHODS */

  /**
   * Handle generate documentation button click
   * Emits change step event
   */
  onGoToGenerateDocumentation() {
    if (this.beforeVersion) {
      this.model.warrantiesProposals = [this.model.warrantiesProposals[0]];
    }
    this.onGoToNextStep(FlowViewsStepsModel.GenerateDocumentationStep, FlowHeaderStepsModel.WarrantiesStep, true);
  }

  /**
   * Saves prices and emits change step event
   * @param {FlowViewsStepsModel} step
   * @param {FlowHeaderStepsModel} headerStep
   * @param {boolean} clearNotifications
   */
  onGoToNextStep(step: FlowViewsStepsModel, headerStep: FlowHeaderStepsModel, clearNotifications?: boolean) {
    // ### HIDE COMPETENCY MGMT ALERT ###
    if (clearNotifications) {
      NotificationsUtils.clearNotifications();
    }
    
    this.saveContinentContentPrices();
    this.update();
    this.$emit('changeStep', step, headerStep);
  }

  /**
   * Saves "Continente" and "Contenido" prices in model
   */
  saveContinentContentPrices() {
    const containerWarrantyCode = this.productFactory.containerWarrantyCode;
    const contentWarrantyCode = this.productFactory.contentWarrantyCode;
    const priceElementCode = 'CPASEGUR';

    const continentWarranty = this.findWarrantyByCode(containerWarrantyCode);
    const contentWarranty = this.findWarrantyByCode(contentWarrantyCode);

    if (continentWarranty) {
      this.model.continentPrices = this.getWarrantyElementProposalPrices(continentWarranty, priceElementCode);
    }

    if (contentWarranty) {
      this.model.contentPrices = this.getWarrantyElementProposalPrices(contentWarranty, priceElementCode);
    }
  }

  /**
   * Finds warranty by given code
   * @param {string} warrantyCode
   * @returns {GetWarrantiesResponseDataDatosGarantias | undefined}
   */
  findWarrantyByCode(warrantyCode: string): GetWarrantiesPolicyResponseDataDatosGarantias | undefined {
    return this.warrantiesData.find(warranty => warranty.codigoGarantia === warrantyCode);
  }

  /**
   * Gets prices for each proposal given a warranty element
   * @param {GetWarrantiesResponseDataDatosGarantias} warranty 
   * @param {string} elementCode
   * @returns {Record <string, string | number>}
   */
  getWarrantyElementProposalPrices(
    warranty: GetWarrantiesPolicyResponseDataDatosGarantias,
    elementCode: string
  ): Record <string, string | number> {
    const warrantyElement = this.findWarrantyElementByCode(warranty, elementCode);
    const result = {};

    if (warrantyElement && warrantyElement.datosPropuestas) {
      for (const datoPropuesta of warrantyElement.datosPropuestas) {
        if (datoPropuesta && datoPropuesta.codigoPropuesta) {
          Vue.set(result, datoPropuesta.codigoPropuesta, datoPropuesta.valorElemento);
        }
      }
    }

    return result;
  }

  /**
   * Returns warranty element given element code and warranty to look into
   * @param {GetWarrantiesResponseDataDatosGarantias} warranty 
   * @param {string} elementCode
   * @returns {GetWarrantiesResponseDataElementosGarantias | undefined} 
   */
  findWarrantyElementByCode(
    warranty: GetWarrantiesPolicyResponseDataDatosGarantias,
    elementCode: string
  ): GetWarrantiesPolicyResponseDataElementosGarantias | undefined {
    return warranty.elementosGarantias?.find(
      element => element.codigoElemento === elementCode
    );
  }

  /**
   * @description asiginación de los datos para su mostrado
   * @argument {GetWarrantiesPolicyResponseData | undefined} data
   */
  public _assignData(data: GetWarrantiesPolicyResponseData | undefined) {
    if (data) {
      this.model.pendingCompetenceManagement = data.datosGestionCompetencias?.gestionDeCompetencias || false;
      this.showCompetenceWarning();
      this.warrantiesData = data.datosGarantias || [];
      this.loadAllproposalCodes();
      this.setHeaderSelectedOptions();

      this.pricingData = data.datosTarificacion || [];
      this.fillNonPricedProposalsIntoPricingData();
      this.fractionalPaymentForms = this.getSortedFractionalPaymentForms(
        data.fraccionamientoFormasPago
      );

      this.premiumAdjustmentsData = data.datosAjustesPrimas;
      this.recalculateShowingProposalPrices();
      this.recalculateWarrantyRates();

      this.isInitialTarification = false;
      this.retrievedProposalNumbers = data.codigosPropuestas ?? [];
    }
    this.enableEvents();
    this.emptyWarrantiesMessage = '';
  }

  /**
   * Add an empty price into `pricingData` for the proposals we didn't received price data.
   */
  loadAllproposalCodes() {
    this.model.warrantiesProposals = [];
    this.proposalsData = [];
    this.warrantiesData.forEach(warrantyData => {
      warrantyData?.elementosGarantias?.forEach(warrantyElement => {
        warrantyElement.datosPropuestas?.forEach(proposalData => {
          if (proposalData.codigoPropuesta &&
              this.model.warrantiesProposals.indexOf(proposalData.codigoPropuesta) === -1) {
            this.proposalsData.push(proposalData);
            this.model.warrantiesProposals.push(proposalData.codigoPropuesta);
          }
        });
      });
    });
  }


  /**
   * Reset generalData info message
   */
  resetInfoMessage() {
    NotificationsUtils.clearNotifications();
  }

  /**
   * Show competences warning message
   */
  showCompetenceWarning() {
    if (this.model.pendingCompetenceManagement) {
      NotificationsUtils.launchNotifications([{
        title: 'Proficiency',
        message: this.$t('warranties.proficiencyWarningDescription').toString(),
        type: NotificationsTypeEnum.Warning
      }]);
    }
  }

  /**
   * Show competences warning message
   */
  showFicoFicWarning() {
    if (this.model.ficoFicError.length > 0) {
      this.model.ficoFicError.forEach(err => {
        NotificationsUtils.launchNotifications([{
          title: NotificationsTypeEnum.Warning,
          message: this.$t(`warranties.${codeMessageFicoFic[err]}`).toString(),
          type: NotificationsTypeEnum.Warning
        }]);
      });
    }
  }

  /**
   * Find proposal data for given warranty element
   * @param {WarrantyElement} warrantyElement 
   * @return {GetWarrantiesResponseDataDatosPropuestas[] | undefined}
   */
  findAllWarrantyProposalData(
    warrantyElement: WarrantyElement
  ): GetWarrantiesPolicyResponseDataDatosPropuestas[] | undefined {
    return this.warrantiesData
      ?.find(element => element.codigoGarantia === warrantyElement.warrantyCode)
      ?.elementosGarantias?.find(element => element.codigoElemento === warrantyElement.elementCode)
      ?.datosPropuestas;
  }

  /**
   * Find the proposal code
   *
   * @param {WarrantyElement} warrantyElement
   * @return {GetWarrantiesResponseDataDatosPropuestas | undefined}
   */
  findWarrantyProposalData(warrantyElement: WarrantyElement):
  GetWarrantiesPolicyResponseDataDatosPropuestas | undefined {
    return this.warrantiesData
      ?.find(element => element.codigoGarantia === warrantyElement.warrantyCode)
      ?.elementosGarantias?.find(element => element.codigoElemento === warrantyElement.elementCode)
      ?.datosPropuestas?.find(element => element.codigoPropuesta === warrantyElement.proposalCode);
  }

  /**
   * Performs event action on receiver element
   * @param {EventChange} eventChange
   * @param {GetWarrantiesResponseDataDatosPropuestas} datosPropuestaReceiver
   */
  performEvent(
    eventChange: EventChange,
    datosPropuestaReceiver: GetWarrantiesPolicyResponseDataDatosPropuestas,
  ): void {

    const fieldStructure = WarrantyFieldUtils.convertToFieldStructure(
      datosPropuestaReceiver as GetWarrantiesResponseDataDatosPropuestas
    );
    const cmpType = WarrantyFieldUtils.getComponentType(fieldStructure);
    if (cmpType === 'ea-checkbox') {
      const eventValue = eventChange.action.value;
      const isTruthy = eventValue === true || eventValue === 'true' || eventValue === 'S';
      eventChange.action.value = isTruthy ? 'S' : 'N';
    }

    if (eventChange.action.type === ActionType.CHANGE_HIDDEN) {
      datosPropuestaReceiver.elementoVisible = !!eventChange.action.value;
    } else if (eventChange.action.type === ActionType.CHANGE_READONLY) {
      datosPropuestaReceiver.elementoModificable = !!eventChange.action.value;
    } else if (eventChange.action.type === ActionType.CHANGE_VALUE) {
      datosPropuestaReceiver.valorElemento = eventChange.action.value ?
        eventChange.action.value.toString() : undefined;
    } else if (eventChange.action.type === ActionType.COPY_VALUE) {
      const elementToCopyValueFrom = eventChange.action.value;
      datosPropuestaReceiver.valorElemento = typeof elementToCopyValueFrom === 'object' ?
        this.getElementValue(elementToCopyValueFrom) : eventChange.action.value?.toString();
    }
  }

  /**
   * Get Value from passed warranty element
   * 
   * @param {WarrantyElement} element
   * @return {string | undefined}
   */
  getElementValue(element: WarrantyElement): string | undefined {
    const datosPropuestaReceiver = this.findWarrantyProposalData(element);
    return datosPropuestaReceiver?.valorElemento;
  }

  /**
   * Add an empty price into `pricingData` for the proposals we didn't received price data.
   */
  fillNonPricedProposalsIntoPricingData() {
    const allProposalCodes = this.model.warrantiesProposals;
    for (const proposal of allProposalCodes) {
      const foundData = this.pricingData.find(price => price.codigoPropuesta === proposal);
      if (!foundData) {
        this.pricingData.push({
          codigoPropuesta: proposal
        });
      }
    }
  }

  /**
   * Sorts payment forms based on custom order
   * @param {GetWarrantiesResponseDataTablaRestricciones[] | undefined} fraccionamientoFormasPago
   * @returns {GetWarrantiesResponseDataTablaRestricciones[]}
   */
  public getSortedFractionalPaymentForms(
    fraccionamientoFormasPago?: GetWarrantiesPolicyResponseDataTablaRestricciones[]
  ): GetWarrantiesPolicyResponseDataTablaRestricciones[] {
    if (!fraccionamientoFormasPago || !fraccionamientoFormasPago.length) {
      return [];
    }

    const order: Record<string, number> = {
      Unica: 1,
      Anual: 2,
      Semestral: 3,
      Trimestral: 4,
      Mensual: 5
    };

    fraccionamientoFormasPago.sort((elemA, elemB) => order[elemA.nombreRestriccion as keyof Record<string, number>] -
    order[elemB.nombreRestriccion as keyof Record<string, number>]);

    return fraccionamientoFormasPago.filter(
      elem => elem.valorRestriccion && !this.bannedPaymentForms.includes(elem.valorRestriccion)
    );
  }

  /**
   * Initializes status of select proposal buttons.
   *
   * Match incoming proposals in `datosGarantias` and `datosTarificacion`.
   *
   * For the ones matched and existing `importeTotalRecibo`, we set a `true` value and `false` for the other ones. 
   */
  recalculateShowingProposalPrices() {
    this.showingProposalPrice = {};
    for (const price of this.pricingData) {
      if (price.codigoPropuesta) {
        Vue.set(this.showingProposalPrice, price.codigoPropuesta, !!price.importeTotalRecibo);
      }
    }
  }

  /**
   * Initializes status of proposal prices.
   *
   * Set prize for the incoming proposals in `pricingData` (datosTarificacion). If a price is not received, set 0.
   */
  recalculateWarrantyRates() {
    for (const price of this.pricingData) {
      if (price.codigoPropuesta) {
        Vue.set(this.model.warrantyRates, price.codigoPropuesta, price.importeTotalRecibo || 0);
      }
    }
  }
  

  /**
   * Method run in created() hook
   * @param {number} version
   */
  @EAMethod()
  async fetchWarrantiesConsultionMode(version?: number): Promise<void> {
    const api = new EAGetWarrantiesPolicyConsultationModeApi();
    const output = await api.getWarrantiesPolicyConsultationModeOperation({
      getWarrantiesPolicyConsultationModeRequest: {
        codigoPoliza: this.model.offerNumber,
        versionPoliza: isNumber(version) ? version : this.model.offerVersion,
        tipoPoliza: isNumber(version) ? TipoPolizaEnum.Pliza : this.model.offerType as unknown as TipoPolizaEnum
      }
    });

    if (output?.data && !isNumber(version)) {
      this._assignData(output.data as GetWarrantiesPolicyResponseData);
    } else if (output?.data && isNumber(version)) {
      this.beforeVersion = output.data as GetWarrantiesPolicyResponseData;
      this.existBeforeVersion = true;
    }
    throwIfResponseHasErrors(output as ResponseWithErrors);
  }

  /**
   * Method run in created() hook
   *
   * @returns {Promise<void>}
   */
  @EAMethod()
  public async _fetchWarranties(): Promise<void> {
    const api = new EAGetWarrantiesPolicyApi();
    const output = await api.getWarrantiesPolicyOperation({
      getWarrantiesPolicyRequest: {
        codigoPoliza: this.model.offerNumber,
        versionPoliza: this.model.offerVersion,
        tipoPoliza: this.model.offerType as unknown as GetWarrantiesPolicyRequestTipoPolizaEnum
      },
    });

    // Go back showing error if error forces going back
    const goBackError = output?.errors?.find(
      err => ErrorCodesGoBack.includes(err.code)
    );
    if (goBackError) {
      this.onGoBack();
      NotificationsUtils.launchNotifications([{
        title: NotificationsTypeEnum.Error,
        message: goBackError.message,
        type: NotificationsTypeEnum.Error
      }]);

      return;
    }

    if (output?.data) {
      this.isReplacement = !!output.data.isReemplazo;
      const response = await this.getSecondProposalData(output);
      this._assignData(response.data);
    }

    throwIfResponseHasErrors(output as ResponseWithErrors);
  }

  /**
   * Concat second proposal for origin version
   *
   * @returns {Promise<void>}
   * @param {GetWarrantiesPolicyResponse} output
   */
  @EAMethod({
    loading: true,
  })
  async getSecondProposalData(output: GetWarrantiesPolicyResponse): Promise<GetWarrantiesPolicyResponse> {
    if (output?.data?.versionAnteriorData) {
      this.beforeVersionNumber = output.data.versionAnteriorData?.versionAnterior;
      await this.fetchWarrantiesConsultionMode(output.data.versionAnteriorData?.versionAnterior);
    }

    this.appendBeforeVersionData(output);
    return output;
  }

  /**
   * Appends before version warranties data to received
   *  warranties current version response
   * @param {GetWarrantiesPolicyResponse} warrantiesPolicyResponse
   */
  appendBeforeVersionData(
    warrantiesPolicyResponse: GetWarrantiesPolicyResponse
  ) {
    if (this.beforeVersion?.datosGarantias &&
    warrantiesPolicyResponse.data?.datosGarantias &&
    warrantiesPolicyResponse.data?.datosTarificacion &&
      this.beforeVersion.datosTarificacion) {
        
      this.appendWarrantiesData(warrantiesPolicyResponse.data.datosGarantias);
      this.appendTarificationData(warrantiesPolicyResponse, this.beforeVersion.datosTarificacion);
    }
  }

  /**
   * Appends warranties data from warranties before version
   *  to current version warranties
   * @param {GetWarrantiesPolicyResponseDataDatosGarantias[]} warrantiesDataCurrentVersion
   */
  appendWarrantiesData(
    warrantiesDataCurrentVersion: GetWarrantiesPolicyResponseDataDatosGarantias[],
  ): void {
    warrantiesDataCurrentVersion.forEach(item => {
      const addItem = this.beforeVersion?.datosGarantias?.find(
        element => element.codigoGarantia === item.codigoGarantia
      );
      item.elementosGarantias?.forEach((element, elementIndex) => {
        if (item.codigoGarantia === addItem?.codigoGarantia) {
          const proposalCode = addItem?.elementosGarantias?.[elementIndex]?.datosPropuestas?.[0]?.codigoPropuesta;
          element.datosPropuestas?.push(
            {
              ...addItem?.elementosGarantias?.[elementIndex]?.datosPropuestas?.[0],
              // Change proposalCode as it may be repeated
              codigoPropuesta: proposalCode ?
                `${addItem?.elementosGarantias?.[elementIndex]?.datosPropuestas?.[0]?.codigoPropuesta}VA` : undefined
            } as GetWarrantiesPolicyResponseDataGarantiaSeleccionada
          );
        }
      });
      if (item.codigoGarantia === addItem?.codigoGarantia) {
        item.garantiaSeleccionada?.push(addItem?.garantiaSeleccionada?.[0] as
            GetWarrantiesPolicyResponseDataGarantiaSeleccionada);
      }
    });
  }

  /**
   * Appends received tarification data from warranties before version
   *  to received warranties response from warranties current version
   * @param {GetWarrantiesPolicyResponse} warrantiesPolicyResponse
   * @param {GetWarrantiesPolicyResponseDataDatosTarificacion[]} beforeVersionDatosTarificacion
   */
  appendTarificationData(
    warrantiesPolicyResponse: GetWarrantiesPolicyResponse,
    beforeVersionDatosTarificacion: GetWarrantiesPolicyResponseDataDatosTarificacion[],
  ): void {
    const mappedDatosTarificacion = beforeVersionDatosTarificacion.map(tarifElem => {
      return {
        ...tarifElem,
        codigoPropuesta: `${tarifElem.codigoPropuesta}VA`
      };
    });
    warrantiesPolicyResponse.data = {
      ...warrantiesPolicyResponse.data,
      datosTarificacion: (warrantiesPolicyResponse.data?.datosTarificacion ?? []).concat(mappedDatosTarificacion),
    };
  }
  
  /**
   * Fetches proficiency management list
   *
   * @returns {Promise<void>}
   */
  @EAMethod({
    loading: true,
  })
  async fetchProficiencyManagement():
  Promise<GetCompetencesManagementByOfferResponseCompetencesManagementByOfferData[]| undefined> {
    const api = new EAGetCompetencesManagementByOfferApi();
    const output = await api.getCompetencesManagementByOfferOperation({
      getCompetencesManagementByOfferRequest: {
        codigoPoliza: this.model.offerNumber,
        versionPoliza: this.model.offerVersion
      },
    });
    throwIfResponseHasErrors(output as ResponseWithErrors);
    if (output?.competencesManagementByOfferData) {
      return output.competencesManagementByOfferData;
    }
  }

  /**
   * Refreshes Proficiency Management Data
   * @param {string} componentReference
   */
  @EAMethod({})
  async refreshProficiencyManagementData(componentReference: string) {
    const data = await this.fetchProficiencyManagement();
    
    const proposalNumbersOrder = this.model.warrantiesProposals;
    
    if (data) {
      this.proficiencyManagementList = data;
      this.model.pendingCompetenceManagement = true;
      this.model.proficiencyManagementModel.proposalNumbersOrder = proposalNumbersOrder;
      
      const proficiencyMgmtComp: QbProficiencyManagementBusiness =
        this.$refs[componentReference] as QbProficiencyManagementBusiness;

      if (proficiencyMgmtComp) {
        proficiencyMgmtComp.showModal();
      }
    }
  }

  /**
   * Calls IPID validation service
   * 
   */
  @EAMethod({
    loading: true
  })
  async callContractOffer() {
    try {
      const api = new EAContractOfferPolicyApi();
      const contractOfferResponse = await api.contractOfferPolicyOperation({
        contractOfferPolicyRequest: {
          codigoPoliza: this.model.offerNumber,
          versionPoliza: this.model.offerVersion,
          codigoOperacionPoliza: 'CO'
        }
      });

      if (contractOfferResponse) {
        if (contractOfferResponse.pendingCompetenceManagement) {
          this.$emit('openSubflow', SubflowThunderNameEnum.CompetencesFlow);
        } else if (contractOfferResponse?.errors?.length) {
          if (contractOfferResponse.errors[0].code === '0950' || contractOfferResponse.errors[0].code === '0951') {
            this.model.pendingIPIDDocumentation = true;
            NotificationsUtils.launchNotifications([{
              title: NotificationsTypeEnum.Info,
              message: contractOfferResponse.errors[0].message,
              type: NotificationsTypeEnum.Warning
            }]);
            this.onGoToGenerateDocumentation();
          } else {
            throwIfResponseHasErrors(contractOfferResponse as ResponseWithErrors);
          }
          
        } else {
          this.onGoToNextStep(FlowViewsStepsModel.IssueOfferStep, FlowHeaderStepsModel.IssueOfferStep, true);
        }
      }
    } catch (error) {
      const eaError = error as EAError;
      throw new EAValidationError(eaError.message);
    }
  }

  /**
   * Listener for "retarificar" buttons.
   * Save and requote proposals. Then refresh warranties data.
   */
  public async onRequote() {
    this.disableEvents();
    this.onRateUnselect();
    this.clearFlowErrors(); // Clear errors section

    // Do previous validations
    try {
      this.productWarrantiesValidation();
      await this.validateWarrantyBusinessComp();
      await this.validatePremiumAdjustmentsComp();
    } catch (error) {
      // Enable warranty events again and throw validation errors
      this.enableEvents();
      throw error;
    }

    try {
      NotificationsUtils.clearNotifications();
      this.update(); // Workaround to keep "model.premiumAdjustmentsModel" values and not resetted by initial values
      const data = await this.requoteProposals();

      if (data?.datosGarantias) {
        this.model.pendingCompetenceManagement =
          !!data.datosGestionCompetencias && data.datosGestionCompetencias.length > 0;

        this.showCompetenceWarning();
        this.showFicoFicWarning();
        scrollTo(0, 0);
        
        // Merge current warranties data with the incoming data
        this.warrantiesData = this.mergeWarrantyData(data.datosGarantias || []);

        // Update pricingData array 1 by 1
        const proposalsToPrice = this.getPlainArrayProposalsToPrice();
        const ensureDatosTarificacion = data.datosTarificacion || [];
        const matchedDatosTarif = proposalsToPrice.map(proposal => {
          let incomingPrice = ensureDatosTarificacion.find(tarif => tarif.codigoPropuesta === proposal);
          if (!incomingPrice) {
            incomingPrice = {
              codigoPropuesta: proposal
            };
          }
          return incomingPrice;
        });

        this.pricingData = this.pricingData.map(price => {
          const foundInMatched = matchedDatosTarif.find(tarif => tarif.codigoPropuesta === price.codigoPropuesta);
          return foundInMatched as GetWarrantiesPolicyResponseDataDatosTarificacion || price;
        });

        // Recalculate some data
        this.recalculateShowingProposalPrices();
        this.recalculateWarrantyRates();
      }
      throwIfResponseHasErrors(data as ResponseWithErrors);
      this.requoteProposal = false;
      this.hideHeaderRequoteButton();
    } catch (error) {
      this.enableEvents();
      throw error;
    }
    this.enableEvents();
  }

  /**
   * Save and requote proposals call.
   */
  @EAMethod({
    loading: true
  })
  async requoteProposals(): Promise<UpdateWarrantiesPolicyAndRateResponse | null> {
    const datosGarantias: UpdateWarrantiesPolicyAndRateRequestDatosGarantias[] = cloneDeep(
      this.warrantiesData
    ) as UpdateWarrantiesPolicyAndRateRequestDatosGarantias[];

    this.filterWarrantiesDataOnlyNonPriceProposals(datosGarantias);

    const api = new EAUpdateWarrantiesPolicyAndRateApi();
    const response = await api.updateWarrantiesPolicyAndRateOperation({
      updateWarrantiesPolicyAndRateRequest: {
        codigoPoliza: this.model.offerNumber,
        versionPoliza: this.model.offerVersion,
        tipoPoliza: UpdateWarrantiesPolicyAndRateRequestTipoPolizaEnum.Incompleta,
        datosGarantias: datosGarantias,
        ajusteComercialCotizacion: this.ajusteComercialCotizacion,
        tipoComision: this.model.premiumAdjustmentsModel.comissionType || ''
      }
    });
    return response;
  }

  /**
   * Merge `warrantiesData` with incoming warranty data (from BFF).
   * The most important data is the incoming one.
   * 
   * @param {UpdateWarrantiesAndRateResponseDatosGarantias[]} incomingData
   * 
   * @returns {GetWarrantiesPolicyResponseDataDatosGarantias[]} merged data array
   */
  mergeWarrantyData(
    incomingData: UpdateWarrantiesPolicyAndRateResponseDatosGarantias[]
  ): GetWarrantiesPolicyResponseDataDatosGarantias[] {
    const currentWarranties = cloneDeep(this.warrantiesData);
    currentWarranties.forEach(currWarranty => {
      // Selected
      (currWarranty.garantiaSeleccionada || []).forEach((currSelected, indexSelected) => {
        const foundSelected = incomingData
          .find(prop => prop.codigoGarantia === currWarranty.codigoGarantia)?.garantiaSeleccionada?.
          find(sel => sel.codigoPropuesta === currSelected.codigoPropuesta);
        if (foundSelected) {
          (currWarranty.garantiaSeleccionada || []).splice(indexSelected, 1, {
            ...foundSelected
          });
        }
      });

      // Elements
      (currWarranty.elementosGarantias || []).forEach((currElement, indexElement) => {
        (currElement.datosPropuestas || []).forEach((currProposalData, indexProposal) => {
          const foundProposalData = incomingData
            .find(prop => prop.codigoGarantia === currWarranty.codigoGarantia)?.elementosGarantias?.
            find(elem => elem.codigoElemento === currElement.codigoElemento)?.datosPropuestas?.
            find(proposalData => proposalData.codigoPropuesta === currProposalData.codigoPropuesta);
          if (foundProposalData) {
            (currWarranty.elementosGarantias || [])[indexElement].datosPropuestas?.splice(indexProposal, 1, {
              ...foundProposalData
            });
          }
        });
      });
    });

    return currentWarranties;
  }

  /**
   * Listener: unselect a rate.
   */
  public onRateUnselect(): void {
    this.model.selectedProposal = '';
  }

  /** 
   * Does a validation of warranties based on the Product Factory (PF).
   */
  public productWarrantiesValidation(): void {
    const errors = this.productFactory.validateWarranties(
      this.warrantiesData as unknown as GetWarrantiesResponseDataDatosGarantias[]
    );
    if (errors && errors.length) {
      throw errors.length === 1 ? errors[0] : new EAMultiError(errors);
    }
  }

  /**
   * Retrieves warranty-bussiness components list
   * @returns {WarrantyBusiness[]}
   */
  retrieveWarrantiesComponents(): WarrantyBusiness[] {
    const warrantiesComponentList: WarrantyBusiness[] = [];
    this.warrantiesData.forEach((warrantyData: GetWarrantiesPolicyResponseDataDatosGarantias) => {
      if (warrantyData.codigoGarantia) {
        const warrantyComponent = this.$refs[warrantyData.codigoGarantia] as WarrantyBusiness[];
        warrantiesComponentList.push(warrantyComponent[0]);
      }
    });
    return warrantiesComponentList;
  }

  /**
   * Validates WarrantyBusiness component's forms
   * If validation OK => Updates model
   * If validation NOK => Throws EAValidationError
   */
  async validateWarrantyBusinessComp() {
    const warrantiesComponentList = this.retrieveWarrantiesComponents();
    let isError = false;
    const compositeValidation: EACompositeValidation = new EACompositeValidation(
      [], this.$tc.bind(this)
    );

    for (const warrantyComponent of warrantiesComponentList) {
      const form = warrantyComponent.$refs[`form-${warrantyComponent.model.codigoGarantia}`] as Form;
      if (form) {
        compositeValidation.add(form.validation());
      }
    }
    
    try {
      await compositeValidation.validate();
    } catch (error) {
      isError = true;
    }
    if (isError) {
      throw new EAValidationError(this.$t('common.label.validation.formWithErrors').toString());
    }
  }

  /**
   * Validates premium adjustments component's form
   * If validation OK => The flow continues
   * If validation NOK => Throws EAValidationError
   */
  async validatePremiumAdjustmentsComp() {
    const premiumAdjustmentsComp: QbPremiumAdjustmentsBusiness =
      this.$refs.premiumAdjustmentsComp as QbPremiumAdjustmentsBusiness;

    try {
      await premiumAdjustmentsComp.validation().validate();
    } catch (error) {
      throw new EAValidationError(this.$t('common.label.validation.formWithErrors').toString());
    }
  }

  /**
   * Disable Events
   * 
   */
  public disableEvents(): void {
    this.eventsEnabled = false;
  }

  /**
   * Enable Events
   * 
   */
  public enableEvents(): void {
    this.$nextTick(() => {
      this.eventsEnabled = true;
    });
  }

  /**
   * Getter that states if fractional payment link is shown
   */
  get fractionalPaymentLinkShown() {
    return !!this.pricingData
      && this.pricingData.length > 0
      && (this.isFormaPagoDifferentThanAnualOrUnique(this.pricingData) || this.isMovementTypeNormalSupplement())
      && !!this.showingProposalPrice
      && this.isAnyPriceShown() > 0
      && this.model.movementTypeCode !== PolicyMovementType.SuplementoVencimiento
      && !this.requoteProposal;

  }

  /**
   * Returns if forma de pago tarificada i different than Anual, Unica (and !== empty)
   * @param {GetWarrantiesPolicyResponseDataDatosTarificacion[]} pricingData 
   * 
   * @returns {boolean} If forma pago is different than Anual, Unica and empty
   */
  isFormaPagoDifferentThanAnualOrUnique(pricingData: GetWarrantiesPolicyResponseDataDatosTarificacion[]): boolean {
    return !!pricingData.find(price => price.formaPagoTarificacion
      && price.formaPagoTarificacion !== FormaPagoTarificacion.Unica
      && price.formaPagoTarificacion !== FormaPagoTarificacion.Anual
    );
  }

  /**
   * Returns if movement is normal supplement ('2').
   * 
   * @returns {boolean}
   */
  isMovementTypeNormalSupplement(): boolean {
    return this.model.movementTypeCode === PolicyMovementType.Suplemento;
  }

  /**
   * Returns if any proposal price is shown
   * @returns {boolean}
   */
  isAnyPriceShown() {
    return this.getProposalsWithPricesCodes().length;
  }

  /**
   * Gets proposal codes for proposal with prices
   * @returns {GetFractionalPaymentsRequestCodigosPropuesta[] }
   */
  getProposalsWithPricesCodes(): GetFractionalPaymentsRequestCodigosPropuesta[] {
    const proposalCodes = [];

    for (const [key] of Object.entries(this.showingProposalPrice)) {
      if (this.showingProposalPrice[key]) {
        proposalCodes.push({
          codigoPropuesta: key
        });
      }
    }

    return proposalCodes;
  }

  /**
   * Checks if contract button is show
   * @param {GetWarrantiesResponseDataDatosPropuestas} proposal
   * @param {number} index
   * @returns {boolean}
   */
  viewContractButton(proposal: GetWarrantiesPolicyResponseDataDatosPropuestas, index?: number): boolean {
    if (this.consultaOperation) {
      return false;
    }
    if (!this.consultaOperation && this.showingProposalPrice[proposal.codigoPropuesta as string]) {
      if (this.beforeVersion && index === 1) {
        return false;
      }
      return true;
    }

    return false;
  }

  /**
   * Checks if requote button is show
   * @param {GetWarrantiesResponseDataDatosPropuestas} proposal
   * @param {number} index
   * @returns {boolean}
   */
  viewRequoteButton(proposal: GetWarrantiesPolicyResponseDataDatosPropuestas, index?: number): boolean {
    if (this.consultaOperation || this.isReplacement) {
      return false;
    }
    if (!this.consultaOperation && !this.showingProposalPrice[proposal.codigoPropuesta as string]) {
      if (this.beforeVersion && index === 1) {
        return false;
      }
      this.requoteProposal = true;
      this.showHeaderRequoteButton();
      return true;
    }
    return false;
  }

  /**
   * Filter only the warranties for the proposals that prize is not in screen.
   * 
   * Note: Additionally, parse each warranty element and set a default value for empty timascar properties.
   * 
   * @param {UpdateWarrantiesAndRateRequestDatosGarantias[]} warranties
   */
  filterWarrantiesDataOnlyNonPriceProposals(
    warranties: UpdateWarrantiesPolicyAndRateRequestDatosGarantias[]
  ): void {
    const proposalsToPrice = this.getPlainArrayProposalsToPrice();

    for (const warranty of warranties) {
      // eslint-disable-next-line no-extra-parens
      warranty.garantiaSeleccionada =
        warranty.garantiaSeleccionada?.filter(select => proposalsToPrice.indexOf(select.codigoPropuesta || '') !== -1)
        || [];
      for (const element of warranty.elementosGarantias || []) {
        element.datosPropuestas =
          element.datosPropuestas?.filter(
            proposalElement => proposalsToPrice.indexOf(proposalElement.codigoPropuesta || '') !== -1
          );
        this.setDefaultTimascarToElements(element.datosPropuestas);
      }
    }
  }

  /**
   * Set default timascar to elements.
   * 
   * @param {UpdateWarrantiesAndRateRequestDatosPropuestas[] | undefined} elements
   */
  setDefaultTimascarToElements(elements: UpdateWarrantiesPolicyAndRateRequestDatosPropuestas[] | undefined) {
    if (!elements) {
      return;
    }
    for (const elem of elements) {
      elem.tipoMascara = elem.tipoMascara || TipoMascaraEnum.T; // Set default tipo mascara to 'T' if empty
    }
  }

  /**
   * Handles key pressed
   * Calls validateAndSearch if pressed key is Tabulator or Intro and focus is in search field
   * @param {KeyboardEvent} event
   */
  async onKeyPressed(event: KeyboardEvent) {
    if ((event.key === 'Tab' || event.key === 'Enter') && this.searchInputHasFocus) {
      event.preventDefault();
      await this.validateAndSearch();
    }
  }

  /**
   * Reset styles
   * @param {string} value
   */
  onSearchTermInput(value: string) {
    if (value.length === 0) {
      this.removeClassFromElements('highlighted');
      this.removeClassFromElements('scroll-highlighted');
    }
  }

  /**
   * @returns {EAFormValidationOptions}
   */
  get searchFormValidationOptions(): EAFormValidationOptions {
    return {
      rules: {
        searchTerm: [
          eaCustomValidation(
            this.minLengthValidator(4),
            'common.label.validation.invalidLength'
          ),
          eaCustomValidation(
            this.resultsFoundValidator(),
            'common.label.validation.warrantiesSearchNoResult'
          )
        ]
      }
    };
  }
  
  /**
   * Min length custom validator function
   * @param {number} minLength
   * @returns {EAValidatorFunction}
   */
  minLengthValidator(minLength: number): EAValidatorFunction {
    return (_rule: any, value: any, callback: Function) => {
      if (
        this.searchTermForm.searchTerm?.length &&
        this.searchTermForm.searchTerm.length < minLength
      ) {
        return callback(new Error());
      }

      return callback();
    };
  }
  

  /**
   * Validates if search term has any result
   *
   * @returns {EAValidatorFunction}
   */
  resultsFoundValidator(): EAValidatorFunction {
    return (_rule: any, _value: any, callback: Function) => {
      if (this.retrieveMatchingItems().length) {
        return callback();
      }
      
      return callback(new Error());
    };
  }

  /**
   * Handles search term input focus
   */
  onSearchTermFocusGain() {
    this.searchInputHasFocus = true;
  }

  /**
   * Handles search term input blur
   */
  onSearchTermFocusLost() {
    this.searchInputHasFocus = false;
  }

  /**
   * Return form validation object
   * @returns {EAValidation}
   */
  public searchFormValidation(): EAValidation {
    const form: Form = this.$refs.form as Form;
    return form.validation();
  }

  /**
   * Validates seach form and calls auxiliar search function
   */
  @EAMethod()
  public async validateAndSearch() {
    try {
      await this.searchFormValidation().validate();
      this.search();
    } catch (err) {
      scrollTo(0, 0);
    }
  }

  /**
   * Search
   */
  public search() {
    if (this.searchTermForm.searchTerm === '' || this.searchTermForm.searchTerm.length < 4) {
      this.removeClassFromElements('highlighted');
      this.removeClassFromElements('scroll-highlighted');
      return;
    }

    this.calculateSearchParams();

    const matchingWarrantyElements = this.retrieveMatchingItems();
    this.expandCollapseElementsBasedOnMatch();

    matchingWarrantyElements.forEach(matchingWarrantyEl => {
      this.highlightMatchingText(matchingWarrantyEl, 'highlighted');
    });

    this.scrollToNextElement(matchingWarrantyElements);
  }


  /**
   * Calculates search necessary params like scrollElementIndex
   * And resets highlighted elements if search is new search
   */
  calculateSearchParams() {
    if (this.searchTermForm.searchTerm === this.lastSearchTerm) {
      this.scrollElementIndex++;
    } else {
      // ### New search ###
      this.scrollElementIndex = 0;
      this.lastSearchTerm = this.searchTermForm.searchTerm;
      this.removeClassFromElements('highlighted');
      this.removeClassFromElements('scroll-highlighted');
    }
  }

  /**
   * Retrieves warranty elements that match introduced search term
   * @returns {any[]}
   */
  public retrieveMatchingItems() {
    const warrantiesCard: any[] = Array.from(document.getElementsByClassName('warranty-card'));
    let matchingWarrantyElements: any[] = [];
    const parsedSearchTerm = TextUtils.removeAccents(this.searchTermForm.searchTerm).toLowerCase();

    warrantiesCard.forEach(warrantyCard => {
      const warrantyTitleElements: {innerText: string}[] = Array.from(
        warrantyCard.querySelectorAll('.warranty__title, .collapse__title')
      );
      matchingWarrantyElements = matchingWarrantyElements.concat(
        warrantyTitleElements.filter(
          wTElem => TextUtils.removeAccents(wTElem.innerText).toLowerCase()
            .includes(parsedSearchTerm)
        )
      );
    });

    return matchingWarrantyElements;
  }

  /**
   * Removes every 'highlighted' class in DOM
   * @param {string} className
   */
  public removeClassFromElements(className: string) {
    const elementsWithHighlightedClass = document.querySelectorAll(`.${ className}`);

    elementsWithHighlightedClass.forEach(element => {
      element.classList.remove(className);
    });
  }

  /**
   * Expands warranties with elements that match search term
   * Collapses not selected warranties that are expanded and whose elements don't match search term
   * @param {any} matchingWarrantyEl 
   */
  public expandCollapseElementsBasedOnMatch() {
    const warrantiesComponentList = this.retrieveWarrantiesComponents();
    
    warrantiesComponentList.forEach(warrantyComponent => {
      const parsedSearchTerm = TextUtils.removeAccents(this.searchTermForm.searchTerm).toLowerCase();
      const matchFound = warrantyComponent.model.elementosGarantias.some(
        elementoGarantia => TextUtils.removeAccents(this.$t(
          `warranties.${warrantyComponent.model.codigoGarantia}.elements.${elementoGarantia.codigoElemento}`
        ).toString())
          .toLowerCase()
          .includes(parsedSearchTerm)
      );

      if (matchFound) {
        this.expandWarrantyComponent(warrantyComponent);
      } else {
        this.collapseWarrantyComponentIfNotChecked(warrantyComponent);
      }
    });
  }

  /**
   * Expands warranty component
   * @param {WarrantyBusiness} warrantyComponent
   */
  expandWarrantyComponent(warrantyComponent: WarrantyBusiness) {
    const collapseRef = warrantyComponent.$refs.zzCollapse as ZzCollapse;
    if (collapseRef) {
      collapseRef.collapseHidden = false;
    }
  }

  /**
   * Collapses warranty component if no proposal is checked
   * @param {WarrantyBusiness} warrantyComponent
   */
  collapseWarrantyComponentIfNotChecked(warrantyComponent: WarrantyBusiness) {
    if (warrantyComponent.model.garantiaSeleccionada?.some(elem => !!elem.garantiaSeleccionada)) {
      // ### Return if any proposal is checked ###
      return;
    }

    const collapseRef = warrantyComponent.$refs.zzCollapse as ZzCollapse;
    if (collapseRef) {
      collapseRef.collapseHidden = true;
    }
  }

  /**
   * Highlights user introduced term in matching warranty element
   * @param {any} matchingWarrantyEl
   * @param {string} className
   */
  public highlightMatchingText(matchingWarrantyEl: any, className: string) {
    const parsedSearchTerm = TextUtils.removeAccents(this.searchTermForm.searchTerm).toLowerCase();
    const idx = TextUtils.removeAccents(matchingWarrantyEl.innerText).toLowerCase()
      .indexOf(parsedSearchTerm);

    if (idx === -1) {
      return;
    }

    matchingWarrantyEl.innerHTML =
      `${matchingWarrantyEl.innerText.substring(0, idx)
      }<span class="${className}">${
        matchingWarrantyEl.innerText.substring(idx, idx + this.searchTermForm.searchTerm.length)
      }</span>${
        matchingWarrantyEl.innerText.substring(idx + this.searchTermForm.searchTerm.length)
      }`;
  }

  /**
   * Scrolls to next element + adds scroll-highlighted class
   * @param {any[]} matchingWarrantyElements
   */
  public scrollToNextElement(matchingWarrantyElements: any[]) {
    this.removeClassFromElements('scroll-highlighted');

    if (!matchingWarrantyElements[this.scrollElementIndex]) {
      // ### Restart index as no element has been found ###
      this.scrollElementIndex = 0;
    }

    if (matchingWarrantyElements[this.scrollElementIndex]) {
      this.highlightMatchingText(matchingWarrantyElements[this.scrollElementIndex], 'scroll-highlighted');
      const posY = matchingWarrantyElements[this.scrollElementIndex].getBoundingClientRect().top + window.scrollY;
      const HEADERS_HEIGHT_SUM = 150;
      const SPACE_BELOW = window.innerHeight / 4;
      scrollTo(0, posY - HEADERS_HEIGHT_SUM - SPACE_BELOW);
    }
  }

  /**
   * Gets a copy of proficiencyManagementModel
   */
  get proficiencyManagementModelCopy() {
    return cloneDeep(this.model.proficiencyManagementModel);
  }

  /**
   * Sets empty warranties message
   * @param {string} emptyWarrantiesMessage
   */
  showEmptyWarrantiesMessage(emptyWarrantiesMessage: string): void {
    this.emptyWarrantiesMessage = emptyWarrantiesMessage;
  }

  /**
   * Sets header selected options
   */
  setHeaderSelectedOptions(): void {
    if (this.productFactory.warrantiesHeaderPolicyConfig.canSelectMultiplePackagesGroups) {
      this.warrantiesHeaderModel.packagesGroupsInlineSelectionModel.multipleSelectedOptions =
        this.model.warrantiesProposals;
    } else {
      this.warrantiesHeaderModel.packagesGroupsInlineSelectionModel.selectedOption =
        this.model.warrantiesProposals[0];
    }
  }

  /**
   * Get selectable package groups
   * @returns {LabelValue[]}
   */
  get packagesGroups(): LabelValue[] {
    return this.retrievedProposalNumbers.map(elem => {
      return {
        value: elem,
        label: this.$t(`warranties.packageName.${elem}`).toString()
      };
    });
  }

  /**
   * Handles header tarification button
   * @param {WARRANTIES_HEADER_SELECTIONS[]} headerSelections
   */
  async onHeaderRetarification(headerSelections: WARRANTIES_HEADER_SELECTIONS[]) {
    if (headerSelections.length === 2) {
      // TODO: Both selections changed - will not happen yet
    } else if (headerSelections.includes(WARRANTIES_HEADER_SELECTIONS.WARRANTIES_GROUPS)) {
      // TODO: Will not happen yet
    } else if (headerSelections.includes(WARRANTIES_HEADER_SELECTIONS.PACKAGES_GROUPS)) {
      await this.getWarrantiesByProposal();
    } else {
      // Requote current option as some warranty element has changed
      this.onRequote();
    }
  }

  /**
   * Retrieve warranties by given proposal
   */
  @EAMethod({
    loading: true
  })
  async getWarrantiesByProposal() {
    this.disableEvents();
    NotificationsUtils.clearNotifications();
    EAErrorManager.clearError();
    
    const api = new EAGetWarrantiesPolicyApi();
    const output = await api.getWarrantiesPolicyOperation({
      getWarrantiesPolicyRequest: {
        codigoPoliza: this.model.offerNumber,
        versionPoliza: this.model.offerVersion,
        tipoPoliza: this.model.offerType as unknown as GetWarrantiesPolicyRequestTipoPolizaEnum,
        codigosPropuestas: this.getSelectedWarrantyPackage(),
        borrarGarantias: true
      },
    });

    if (output?.data) {
      this.appendBeforeVersionData(output);
      this._assignData(output.data);

      const warrantiesHeader = this.$refs.warrantiesHeader as WarrantiesHeaderBusiness;
      if (warrantiesHeader) {
        warrantiesHeader.hideRequoteButton();
      }
    }
    throwIfResponseHasErrors(output as ResponseWithErrors);
  }

  /**
   * Gets selected warranty group for tarification call
   * @returns {string[] | undefined}
   */
  getSelectedWarrantyGroup(): string[] | undefined {
    const model =
      this.productFactory.warrantiesHeaderPolicyConfig.warrantiesGroupsDisplayMode
        === WARRANTIES_HEADER_DISPLAY_MODE.GRID ?
        this.warrantiesHeaderModel.warrantiesGroupsGridSelectionModel :
        this.warrantiesHeaderModel.warrantiesGroupsInlineSelectionModel;
        
    const uniqueSelectedOption = model.selectedOption ? [model.selectedOption] : undefined;
    return this.productFactory.warrantiesHeaderPolicyConfig.canSelectMultipleWarrantiesGroups
      ? model.multipleSelectedOptions : uniqueSelectedOption;
  }

  /**
   * Gets selected warranty group for tarification call
   * @returns {string[] | undefined}
   */
  getSelectedWarrantyPackage(): string[] | undefined {
    const model =
      this.productFactory.warrantiesHeaderPolicyConfig.packagesGroupsDisplayMode ===
        WARRANTIES_HEADER_DISPLAY_MODE.GRID ?
        this.warrantiesHeaderModel.packagesGroupsGridSelectionModel :
        this.warrantiesHeaderModel.packagesGroupsInlineSelectionModel;

    const uniqueSelectedOption = model.selectedOption ? [model.selectedOption] : undefined;
    return this.productFactory.warrantiesHeaderPolicyConfig.canSelectMultiplePackagesGroups ?
      model.multipleSelectedOptions : uniqueSelectedOption;
  }

  /**
   * Shows requote button in warranties header
   */
  showHeaderRequoteButton() {
    const warrantiesHeaderComp: WarrantiesHeaderBusiness =
      this.$refs.warrantiesHeader as WarrantiesHeaderBusiness;
    if (warrantiesHeaderComp) {
      warrantiesHeaderComp.showRequoteButton();
    }
  }

  /**
   * Hides requote button in warranties header
   */
  hideHeaderRequoteButton() {
    const warrantiesHeaderComp: WarrantiesHeaderBusiness =
      this.$refs.warrantiesHeader as WarrantiesHeaderBusiness;
    if (warrantiesHeaderComp) {
      warrantiesHeaderComp.hideRequoteButton();
    }
  }
}
</script>

<style scoped lang="scss">
/*TODO LO TENDRÁ QUE REVISAR COMPAÑERO EQUIPO 1 */
$backgroud-color: #e7eaf7;
$font-size: 20px;
$color: #000;
$letter-spacing: 0.71px;
$price-font-size: 20px;


.el-form-item__content {
  margin: auto !important;
}

.collapse-header .el-col .el-checkbox {
  width: 250px;
}

.border-right-lightGrey {
  border-right: 1px solid #EEEEEE;
}

.border-left-lightGrey {
  border-left: 1px solid #EEEEEE;
}

.premium-adjustments-container {
  ::v-deep .el-row {
    align-items: stretch;
    display: flex;
  }
  .proposals {
    .price-container {

      &.single-col{
        text-align: center;
      }

      .option-rate {
        font-size: $price-font-size;
        font-weight: 700;
        line-height: $price-font-size;
      }
    }
  }
  .fractional-payment {
    .fractional-payment-button {
      display: flex;
      justify-content: center;
      margin-top: -8px;
      padding: 8px 0;
    }
  }
}

::v-deep {
  .highlighted {
    background-color: yellow;
  }

  .scroll-highlighted {
    background-color: orange;
  }
}
</style>

