export enum ScoringOnlineFieldsEnum {
  FechaNacimiento = 'FENACSCR',
  Pais = 'CDPAISCR',
  TipoPersona = 'TCPFJSCR',
  TipoDocumento = 'TCDOCSCR',
  IdFiscal = 'CDNIFSCR',
  CodigoPostal = 'TCPOSSCR'
}

export type ScoringOnlineField = `${ScoringOnlineFieldsEnum}`;
