import {
  EmitAndSendOfferRequestEntradaSeleccionesPostalDigitalSeleccionPostalDigitalEnum as SeleccionPostalDigitalEnum
} from '@/services/V1/quoteAndBuy/emitAndSendOfferOperation/post';

/** 
 * Model qb-documentation-insurance-emit
 */
class QbDocumentationInsuranceEmitModel {

  public postalDigitalSelections: Record<string, SeleccionPostalDigitalEnum> = {};
  
}

export default QbDocumentationInsuranceEmitModel;
