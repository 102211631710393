/* eslint-disable */

/**
 * zonazurich
 * Portal de Mediación Digital
 *
 * The version of the OpenAPI document: 1
 * Contact: digitalarchitecture@zurich.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import {
  EASDKConfig,
  EAUrlUtils
} from '@zurich-es-npm/ea-shared-utils-js';

import * as globalImportUrl from 'url';
import {
  Configuration
} from './configuration';
import globalAxios, {
  AxiosPromise,
  AxiosInstance,
  AxiosResponse
} from 'axios';

const BASE_PATH = EAUrlUtils.removeLastForwardSlash('http://localhost:3000');

/**
 *
 * @export
 */
export const COLLECTION_FORMATS = {
  csv: ',',
  ssv: ' ',
  tsv: '\t',
  pipes: '|',
};

/**
 *  
 * @export
 * @interface RequestArgs
 */
export interface RequestArgs {
  url: string;
  options: any;
}

/**
 * 
 * @export
 * @class BaseAPI
 */
export class BaseAPI {
  protected configuration: Configuration | undefined;

  /**
   *Creates an instance of BaseAPI.
   * @param {Configuration} [configuration]
   * @param {string} [basePath=BASE_PATH]
   * @param {AxiosInstance} [axios=globalAxios]
   * @memberof BaseAPI
   */
  public constructor(
    configuration?: Configuration,
    protected basePath: string = BASE_PATH,
    protected axios: AxiosInstance = globalAxios) {
    if (configuration) {
      this.configuration = configuration;
      this.basePath = configuration.basePath || this.basePath;
    }
  }
}

/**
 * 
 * @export
 * @class RequiredError
 * @extends {Error}
 */
export class RequiredError extends Error {
  public name: 'RequiredError' = 'RequiredError';

  public field: string;

  /**
   *Creates an instance of RequiredError.
   * @param {string} field
   * @param {string} [msg]
   * @memberof RequiredError
   */
  public constructor(field: string, msg?: string) {
    super(msg);
    this.field=field;
  }
}

/**
 * 
 * @export
 * @class AsyncRequestError
 * @extends {Error}
 */
export class AsyncRequestError extends Error {
  public name: 'AsyncRequestError' = 'AsyncRequestError';

  /**
   *Creates an instance of AsyncRequestError.
   * @param {string} field
   * @param {string} [msg]
   * @memberof AsyncRequestError
   */
  public constructor(msg?: string) {
    super(msg);
  }
}

/**
 * 
 * @export
 * @interface Error
 */
export interface Error {
    /**
     * 
     * @type {string}
     * @memberof Error
     */
    code: string;
    /**
     * 
     * @type {string}
     * @memberof Error
     */
    message: string;
    /**
     * 
     * @type {number}
     * @memberof Error
     */
    severity: number;
    /**
     * 
     * @type {Array<object>}
     * @memberof Error
     */
    childErrors?: Array<object>;
}/**
 * 
 * @export
 * @interface SetPersonAddressesRequest
 */
export interface SetPersonAddressesRequest {
    /**
     * 
     * @type {Array<SetPersonAddressesRequestDomicilios>}
     * @memberof SetPersonAddressesRequest
     */
    domicilios?: Array<SetPersonAddressesRequestDomicilios>;
}/**
 * 
 * @export
 * @interface SetPersonAddressesRequestDomicilios
 */
export interface SetPersonAddressesRequestDomicilios {
    /**
     * 
     * @type {string}
     * @memberof SetPersonAddressesRequestDomicilios
     */
    indicadorDomicilioPrincipal?: string;
    /**
     * 
     * @type {string}
     * @memberof SetPersonAddressesRequestDomicilios
     */
    codigoFiliacion?: string;
    /**
     * 
     * @type {string}
     * @memberof SetPersonAddressesRequestDomicilios
     */
    codigoSecuencialDomicilio?: string;
    /**
     * 
     * @type {string}
     * @memberof SetPersonAddressesRequestDomicilios
     */
    tipoDomicilio?: string;
    /**
     * 
     * @type {string}
     * @memberof SetPersonAddressesRequestDomicilios
     */
    claseDomicilio?: string;
    /**
     * 
     * @type {string}
     * @memberof SetPersonAddressesRequestDomicilios
     */
    personaContacto?: string;
    /**
     * 
     * @type {string}
     * @memberof SetPersonAddressesRequestDomicilios
     */
    tipoViaDireccion?: string;
    /**
     * 
     * @type {string}
     * @memberof SetPersonAddressesRequestDomicilios
     */
    numeroDireccion?: string;
    /**
     * 
     * @type {string}
     * @memberof SetPersonAddressesRequestDomicilios
     */
    nombrePoblacion?: string;
    /**
     * 
     * @type {string}
     * @memberof SetPersonAddressesRequestDomicilios
     */
    nombreProvincia?: string;
    /**
     * 
     * @type {string}
     * @memberof SetPersonAddressesRequestDomicilios
     */
    nombreDireccion?: string;
    /**
     * 
     * @type {string}
     * @memberof SetPersonAddressesRequestDomicilios
     */
    informacionAdicional?: string;
    /**
     * 
     * @type {string}
     * @memberof SetPersonAddressesRequestDomicilios
     */
    emailContacto?: string;
    /**
     * 
     * @type {string}
     * @memberof SetPersonAddressesRequestDomicilios
     */
    codigoPais?: string;
    /**
     * 
     * @type {string}
     * @memberof SetPersonAddressesRequestDomicilios
     */
    codigoPostalConLetras?: string;
    /**
     * 
     * @type {string}
     * @memberof SetPersonAddressesRequestDomicilios
     */
    tiempoUltimaModificacion?: string;
    /**
     * 
     * @type {string}
     * @memberof SetPersonAddressesRequestDomicilios
     */
    codigoResidencia?: string;
    /**
     * 
     * @type {string}
     * @memberof SetPersonAddressesRequestDomicilios
     */
    poblacionTomador?: string;
    /**
     * 
     * @type {Array<SetPersonAddressesRequestTelefonos>}
     * @memberof SetPersonAddressesRequestDomicilios
     */
    telefonos?: Array<SetPersonAddressesRequestTelefonos>;
}/**
 * 
 * @export
 * @interface SetPersonAddressesRequestTelefonos
 */
export interface SetPersonAddressesRequestTelefonos {
    /**
     * 
     * @type {string}
     * @memberof SetPersonAddressesRequestTelefonos
     */
    tipoTelefono?: string;
    /**
     * 
     * @type {string}
     * @memberof SetPersonAddressesRequestTelefonos
     */
    prefijoPais?: string;
    /**
     * 
     * @type {string}
     * @memberof SetPersonAddressesRequestTelefonos
     */
    numeroTelefono?: string;
    /**
     * 
     * @type {string}
     * @memberof SetPersonAddressesRequestTelefonos
     */
    numeroExtensionTelefono?: string;
    /**
     * 
     * @type {string}
     * @memberof SetPersonAddressesRequestTelefonos
     */
    indicadorDomicilioPrincipal?: string;
}/**
 * 
 * @export
 * @interface SetPersonAddressesResponse
 */
export interface SetPersonAddressesResponse {
    /**
     * 
     * @type {boolean}
     * @memberof SetPersonAddressesResponse
     */
    ok?: boolean;
    /**
     * 
     * @type {Array<Error>}
     * @memberof SetPersonAddressesResponse
     */
    errors?: Array<Error>;
}

type setPersonAddressesOperationParams = {
  setPersonAddressesRequest: SetPersonAddressesRequest,
};

/**
 * SetPersonAddressesApi - axios parameter creator
 * @param {Configuration} [configuration]
 * @returns {any}
 */
const SetPersonAddressesApiAxiosParamCreator = function(configuration?: Configuration): any {
  return {
    
    /**
     * Guarda los domicilios de una persona
     * @param {SetPersonAddressesRequest} setPersonAddressesRequest 
     * @param {string} [acceptLanguage] El Accept-Language request HTTP header indica en que lenguaje debe responder el backend.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @returns {RequestArgs}
     */
    setPersonAddressesOperation(params: setPersonAddressesOperationParams, options: any = {}): RequestArgs {
      // Verify required parameter 'setPersonAddressesRequest' is not null or undefined
      if (params.setPersonAddressesRequest === null || params.setPersonAddressesRequest === undefined) {
        throw new RequiredError('setPersonAddressesRequest', 'Required parameter setPersonAddressesRequest was null or undefined when calling setPersonAddressesOperation.');
      }
      const localVarPath = `/v1/persons/setPersonAddressesOperation`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true) as Partial<globalImportUrl.UrlWithParsedQuery>;
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;


  
      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
      // Fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
      const needsSerialization = ('SetPersonAddressesRequest' as any !== 'string') ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization ? JSON.stringify(params.setPersonAddressesRequest || {}) : params.setPersonAddressesRequest || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * SetPersonAddressesApi - functional programming interface
 * @param {Configuration} [configuration]
 * @returns {any}
 */
const SetPersonAddressesApiFp = function(configuration?: Configuration): any {
  return {
    
    /**
     * Guarda los domicilios de una persona
     * @param {SetPersonAddressesRequest} setPersonAddressesRequest 
     * @param {string} [acceptLanguage] El Accept-Language request HTTP header indica en que lenguaje debe responder el backend.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @returns {any}
     */
    setPersonAddressesOperation(params: setPersonAddressesOperationParams, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<SetPersonAddressesResponse> {
      const localVarAxiosArgs = SetPersonAddressesApiAxiosParamCreator(configuration).setPersonAddressesOperation(params, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH): any => {
        const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * SetPersonAddressesApi - factory interface
 * @param {Configuration} configuration
 * @param {string} basePath
 * @param {AxiosInstance} axios
 * @export
 * @returns {any}
 */
const SetPersonAddressesApiFactory = function(configuration?: Configuration, basePath?: string, axios?: AxiosInstance): any {
  return {
    
    /**
     * Guarda los domicilios de una persona
     * @param {SetPersonAddressesRequest} setPersonAddressesRequest 
     * @param {string} [acceptLanguage] El Accept-Language request HTTP header indica en que lenguaje debe responder el backend.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @returns {any}
     */
    setPersonAddressesOperation(params: setPersonAddressesOperationParams, options?: any): any {
      return SetPersonAddressesApiFp(configuration).setPersonAddressesOperation(params, options)(axios, basePath);
    },
  };
};

/** 
 * SetPersonAddressesApi - object-oriented interface
 * @export
 * @class SetPersonAddressesApi
 * @extends {BaseAPI}
 */
export class SetPersonAddressesApi extends BaseAPI {
  
  /**
   * Guarda los domicilios de una persona
   * @param {SetPersonAddressesRequest} setPersonAddressesRequest 
   * @param {string} [acceptLanguage] El Accept-Language request HTTP header indica en que lenguaje debe responder el backend.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @returns {any}
   * @memberof SetPersonAddressesApi
   */
  public setPersonAddressesOperation(params: setPersonAddressesOperationParams, options?: any): any {
    return SetPersonAddressesApiFp(this.configuration).setPersonAddressesOperation(params, options)(this.axios, this.basePath);
  }

}

/**
 * 
 * @export
 * @class EAsetPersonAddressesOperationInputMessage
 */
export class EAsetPersonAddressesOperationInputMessage {
  
  /**
   * 
   * @type {SetPersonAddressesRequest}
   * @memberof EAsetPersonAddressesOperationInputMessage
   */
  public setPersonAddressesRequest: SetPersonAddressesRequest;

  public constructor(setPersonAddressesRequest: SetPersonAddressesRequest, ){
  
  this.setPersonAddressesRequest=setPersonAddressesRequest;
  } 
}



/** 
 * EASetPersonAddressesApi - Architecture client for setPersonAddressesOperation
 * @export
 * @class EASetPersonAddressesApi
 */
export class EASetPersonAddressesApi {

  /**
   * 
   * @param {SetPersonAddressesRequest} setPersonAddressesRequest
   
   * @throws {AsyncRequestError | RequiredError}
   * @returns {Promise<SetPersonAddressesResponse | null>}
  */ 
  public async setPersonAddressesOperation(params: setPersonAddressesOperationParams): Promise<SetPersonAddressesResponse | null> {
    return this.setPersonAddressesOperationPromise(params);
  }

  /**
   * 
   * @param {SetPersonAddressesRequest} setPersonAddressesRequest
   
   * @param {Function} callback
   * @throws {AsyncRequestError | RequiredError}
   */
  public async setPersonAddressesOperationSteps(params: setPersonAddressesOperationParams, callback: (res: AxiosResponse<SetPersonAddressesResponse | null>) => void) {
    let done: boolean = false;
    const httpClient: AxiosInstance = EASDKConfig.getHttpClient('/v1/persons/setPersonAddressesOperation', 'setPersonAddresses', 'setPersonAddressesOperation');
    const config: Configuration = {};
    const api = new SetPersonAddressesApi(config, '', httpClient);
    const sleep = async(ms: number) => {
      return new Promise(resolve => setTimeout(resolve, ms));
    };
    const headers: { ['Async-Id']?: string } = {};
    while (!done) {
      const output = await api.setPersonAddressesOperation(params, { headers }) as AxiosResponse<SetPersonAddressesResponse | null>;
      callback(output);
      if (output.status === 202) {
        try {
          const retry = parseInt(output.headers['retry']);
          headers['Async-Id'] = output.headers['request-id'];
          if (isNaN(retry) || retry <= 0 || retry > 1000 * 3600) {
            throw new Error(`Retry time not acceptable (in milliseconds): ${retry}`);
          }
          await sleep(retry);
        } catch (err) {
          throw new Error(`Error in Async Request ${err}`);
        }
      } else {
        done = true;
      }
    }
  }

  /**
   * 
   * @param {SetPersonAddressesRequest} setPersonAddressesRequest
   
   * @throws {AsyncRequestError | RequiredError}
   * @returns {Promise<SetPersonAddressesResponse | null>}
   */
  public async setPersonAddressesOperationPromise(params: setPersonAddressesOperationParams): Promise<SetPersonAddressesResponse | null> {
    return new Promise((resolve, reject) => {
      this.setPersonAddressesOperationSteps(params, (response: AxiosResponse<SetPersonAddressesResponse | null>) => {
        if (response.status !== 202) {
          resolve(response.data);
        }
      })
      .catch((err) => {
        reject(err);
      });
    });
  } 
}


