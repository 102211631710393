import {
  VehicleFormModel
} from '../fleet-manual-upload-vehicle/fleet-manual-upload-vehicle-model';
import {
  SelectList
} from '../qb-searcher/qb-searcher.interface';


export interface ExcelVehicleData {
  [key: string]: string;
}

export interface VehicleGroupValidations {
  isEffectiveDateValid?: boolean;
  isValidPlate?: boolean;
}


export const vehicleExcelFields: Record<string, string> = {
  plateNumber: 'A',
  vehicleUseId: 'E',
  plateNumberType: 'F',
  effectiveDate: 'G',
  accessoriesValue: 'H',
  policyNumber: 'I'
};

export interface MappingExcelError {
  field: string;
  rowNumber: number;
}
export interface MappedExcelVehicleResponse {
  vehicleData: VehicleFormModel;
  mappingErrors: MappingExcelError [];
}

/** 
 * Model fleet-group-upload-vehicles
 */
class FleetGroupUploadVehiclesModel {
  public formValues: VehicleFormModel = {};

  public plateTypeSearchList: SelectList[] = [];

}

export default FleetGroupUploadVehiclesModel;
