import 'exceljs/dist/exceljs.min.js';
import 'core-js/stable';
import 'regenerator-runtime/runtime';

import Vue from 'vue';
import 'vue-i18n';

import merge from 'lodash/merge';

import {
  EAVueApp,
  EAVueAppParameters,
  EAHookService,
  EAVueAppConfiguration,
  EABootstrapHooks,
  EAApplicationLogger,
  EASDKCallback
} from '@zurich-es-npm/ea-front-web-core';

import {
  initialConfig,
  vApplication
} from './config/config';

import {
  EAPivotSecurityHooks
} from './coexistence/pivot/security/ea-pivot-security-hooks';

import {
  EAApplicationSDKConfig
} from './config/ea-application-sdk-config';

import locales from './locales/i18n';
import changeBrandingStyles, {
  BASE_BRANDING
} from './locales/branding';

import {
  requestNoCacheHeadersInterceptor
} from './interceptors/no-cache-headers-interceptor';
import {
  EACSRFSDKConfig
} from './config/csrf-sdk-config';

import AppBusinessFlows from './flows';

import 'element-ui/lib/theme-chalk/index.css';
import './assets/scss/zurich/base/base.scss';

import AnalyticsEvents from './utils/analytics/analytics-events';

// eslint-disable-next-line @typescript-eslint/naming-convention
declare const _CONFIG_: EAVueAppConfiguration;


Vue.config.productionTip = false;
Vue.use(AppBusinessFlows);

EAHookService.addHookLogout({
  calls: () => {
    EAPivotSecurityHooks.logout();
    AnalyticsEvents.logout();
  }
});

EAHookService.addHookSessionRefresh({
  calls: () => {
    EAPivotSecurityHooks.refreshSession();
  }
});

EAHookService.addHookSessionExpired({
  calls: () => {
    EAPivotSecurityHooks.logout();
    AnalyticsEvents.sessionExpired();
  }
});

const parameters: Partial<EAVueAppConfiguration> = _CONFIG_ ? merge(initialConfig, _CONFIG_) : initialConfig;

parameters.appVersion = vApplication.version;

const hooks: EABootstrapHooks = {};

const defaultBranding = parameters.defaultBranding || 'zurich';
const supportedLocales = parameters.supportedLocales || ['es'];

const params: EAVueAppParameters = {
  configuration: parameters,
  bootstrapHooks: hooks,
  messages: locales(defaultBranding, supportedLocales)
};

EAApplicationSDKConfig.configure(parameters.integration);

// eslint-disable-next-line
const app = new EAVueApp(params);

EASDKCallback.addRequestInterceptor('requestNoCacheHeadersInterceptor', requestNoCacheHeadersInterceptor);
EACSRFSDKConfig.configure(parameters.integration);

const logger = new EAApplicationLogger();
logger.info('main::Application started');

if (defaultBranding !== BASE_BRANDING) {
  changeBrandingStyles(defaultBranding);
}
