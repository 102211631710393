<template>
  <ea-card shadow="hidden">

    <ea-form class="p-a-16" slot="cardbody" ref="form" :model="model">

      <!-- Title -->
      <ea-heading level="4" class="m-b-12">{{ $t('common.roles.tomador')}}</ea-heading>

      <ea-row v-if="model">
        <ea-col :span="6">
          <ea-form-item :label="$t('fleets.fleetsQueryPolicyFlow.policyholderLabels.documentType')">
            <ea-input-text
              :readonly="true"
              :value="documentType"
            ></ea-input-text>
          </ea-form-item>
        </ea-col>
        <ea-col :span="6">
          <ea-form-item :label="$t('fleets.fleetsQueryPolicyFlow.policyholderLabels.documentNumber')">
            <ea-input-text
              :readonly="true"
              :value="policyholder.codigoIdentificacionFiscal"
            ></ea-input-text>
          </ea-form-item>
        </ea-col>
        <ea-col :span="12">
          <ea-form-item :label="$t('fleets.fleetsQueryPolicyFlow.policyholderLabels.name')">
            <ea-input-text
              :readonly="true"
              :value="policyholder.razonSocialMatriz"
            ></ea-input-text>
          </ea-form-item>
        </ea-col>
        <ea-col :span="6">
          <ea-form-item :label="$t('fleets.fleetsQueryPolicyFlow.policyholderLabels.viaType')">
            <ea-input-text
              :readonly="true"
              :value="addressType"
            ></ea-input-text>
          </ea-form-item>
        </ea-col>
        <ea-col :span="6">
          <ea-form-item :label="$t('fleets.fleetsQueryPolicyFlow.policyholderLabels.viaName')">
            <ea-input-text
              :readonly="true"
              :value="policyholder.nombreDireccionTomador"
            ></ea-input-text>
          </ea-form-item>
        </ea-col>
        <ea-col :span="6">
          <ea-form-item :label="$t('fleets.fleetsQueryPolicyFlow.policyholderLabels.viaNumber')">
            <ea-input-text
              :readonly="true"
              :value="policyholder.numeroDireccionTomador"
            ></ea-input-text>
          </ea-form-item>
        </ea-col>
        <ea-col :span="6">
          <ea-form-item :label="$t('fleets.fleetsQueryPolicyFlow.policyholderLabels.additionalInfo')">
            <ea-input-text
              :readonly="true"
              :value="policyholder.informacionAdicional"
            ></ea-input-text>
          </ea-form-item>
        </ea-col>
        <ea-col :span="6">
          <ea-form-item :label="$t('fleets.fleetsQueryPolicyFlow.policyholderLabels.postalCode')">
            <ea-input-text
              :readonly="true"
              :value="policyholder.codigoPostal"
            ></ea-input-text>
          </ea-form-item>
        </ea-col>
        <ea-col :span="6">
          <ea-form-item :label="$t('fleets.fleetsQueryPolicyFlow.policyholderLabels.poblation')">
            <ea-input-text
              :readonly="true"
              :value="policyholder.nombrePoblacionTomador"
            ></ea-input-text>
          </ea-form-item>
        </ea-col>
        <ea-col :span="6">
          <ea-form-item :label="$t('fleets.fleetsQueryPolicyFlow.policyholderLabels.province')">
            <ea-input-text
              :readonly="true"
              :value="policyholder.nombreProvinciaTomador"
            ></ea-input-text>
          </ea-form-item>
        </ea-col>
        <ea-col :span="24" v-if="model.offerVersion === 0">
          <!-- Commit contract only displayed in policy version = 0 -->
          <ea-form-item :label="$t('fleets.fleetsQueryPolicyFlow.policyholderLabels.commitContracts')">
            <ea-input-text
              :readonly="true"
              :value="commitContracts"
            ></ea-input-text>
          </ea-form-item>
        </ea-col>
      </ea-row>
    </ea-form>
  </ea-card>
</template>

<script lang="ts">
import Vue from 'vue';
import {
  Component, Prop
} from 'vue-property-decorator';
import {
  FleetsQueryPolicyModel
} from '@/flows/fleets-query-policy/fleets-query-policy-model';
import {
  FleetsMaintenancePolicyModel
} from '@/flows/fleets-maintenance-policy/fleets-maintenance-policy-model';
import {
  ParsedTableData
} from '@/utils/corporate-tables';


@Component({
  name: 'fleet-policyholder-policy-info',
})

/**
 * Presentational Component fleet summary
 */
export default class FleetPolicyholderPolicyInfo extends Vue {

  @Prop({
    required: true,
    'default': () => new FleetsQueryPolicyModel()
  })
    model!: FleetsQueryPolicyModel | FleetsMaintenancePolicyModel;

  @Prop({
    required: true,
    'default': () => []
  })
    addressTypesList!: ParsedTableData[];

  @Prop({
    required: true,
    'default': () => []
  })
    documentTypeList!: ParsedTableData[];

  /** 
   * Policyholder info
   */
  get policyholder() {
    return this.model.fleetSearchPolicyHolderModel.selectedJuridicalPerson;
  }

  /** 
   * Document type description
   */
  get documentType() {
    return this.documentTypeList.find(document => document.value === this.policyholder.tipoDocumento)?.label;
  }

  /** 
   * Address type description
   */
  get addressType() {
    return this.addressTypesList
      .find(address => address.value === this.policyholder.tipoViaDireccionLarga)?.label;
  }

  /** 
   * Commit contracts getter
   */
  get commitContracts() {
    return this.model.fleetSearchPolicyHolderModel?.fleetCommitContract?.formValues?.selectedValues?.join(', ');
  }
}
</script>
