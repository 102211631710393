import {
  GetWarrantiesResponseDataDatosPropuestas,
  GetWarrantiesResponseDataDatosPropuestasTipoMascaraEnum,
  GetWarrantiesResponseDataDatosPropuestasTipoVariableEnum,
} from '@/services/V1/quoteAndBuy/getWarrantiesOperation/post/api';
import {
  FieldStructure, FIELD_TYPES
} from '@/utils/fields/fields.types';
import {
  FieldUtils
} from '@/utils/fields/field-utils';
import {
  WarrantyElementComponentType
} from './warranty.types';

/**
 * Warranty Field Utils
 * Utilities methods to deal with warranty fields
 *
 */
export default class WarrantyFieldUtils extends FieldUtils {

  /**
   * Get Component type
   *
   * @param {FieldStructure} field
   * @returns {string}
   */
  public static getComponentType(field: FieldStructure): WarrantyElementComponentType {
    if (field.tablaElemento) {
      // Elements that have restrictions (they come as a list)
      if (field.tablaElemento === 'KTCTSNG') {
        // True/false values (S/N)
        return 'ea-checkbox';
      } else {
        // Multiple values
        return 'ea-select';
      }
    } else if (field.tipoElemento === GetWarrantiesResponseDataDatosPropuestasTipoVariableEnum.N) {
      // Elements that are numbers whose representation changes with "tipoMascara"
      if (field.tipoMascara === GetWarrantiesResponseDataDatosPropuestasTipoMascaraEnum.M) {
        // Currencies (€)
        return 'ea-input-currency';
      } else if (field.tipoMascara === GetWarrantiesResponseDataDatosPropuestasTipoMascaraEnum.F) {
        // Dates
        return 'ea-date-picker';
      } else {
        // Number without an specific format
        return 'ea-input-number';
      }
    } else {
      // Text without an specific format
      return 'ea-input-text';
    }
  }

  /**
   * Get Field type
   *
   * @param {FieldStructure} field
   * @returns {string}
   */
  public static getFieldType(field: FieldStructure): string {
    if (field.tablaElemento) {
      return FIELD_TYPES.DEFAULT;
    }
    if (field.tipoElemento && field.tipoElemento === GetWarrantiesResponseDataDatosPropuestasTipoVariableEnum.N) {
      if (field.tipoMascara === GetWarrantiesResponseDataDatosPropuestasTipoMascaraEnum.F) {
        return FIELD_TYPES.DATE;
      } else {
        return FIELD_TYPES.NUMBER;
      }
    }
    return FIELD_TYPES.STRING;
  }

  /**
   * ConvertToFieldStructure
   *
   * @param {GetWarrantiesResponseDataDatosPropuestas} input
   * @param {string} codigoElemento
   * @returns {FieldStructure}
   */
  public static convertToFieldStructure(
    input: GetWarrantiesResponseDataDatosPropuestas,
    codigoElemento?: string
  ): FieldStructure {
    return {
      codigoElemento: codigoElemento ? codigoElemento : '',
      tipoElemento: input.tipoVariable
        ? input.tipoVariable
        : GetWarrantiesResponseDataDatosPropuestasTipoVariableEnum.N,
      tipoMascara: input.tipoMascara,
      tablaElemento: input.tablaElemento ? input.tablaElemento : '',
      valorElemento: input.valorElemento ? input.valorElemento : '',
      valorMaximoElemento: input.valorMaximoElemento,
      valorMinimoElemento: input.valorMinimoElemento,
      elementoRequerido: input.elementoRequerido ? input.elementoRequerido : false,
      elementoModificable: input.elementoModificable ? input.elementoModificable : false,
      longitudElemento: input.longitudElemento,
      numeroDecimalesElemento: input.numeroDecimalesElemento,
      tablaRestricciones: [],
    };
  }
}
