<template>
  <div class="slide">
        <h2 v-if="!hideFirstTitle" class="t-color-primary m-b-20">
          {{ $t('onboarding.fleets.firstSlide.title1') }}
        </h2>

        <i18n path="onboarding.fleets.firstSlide.descriptionFirst.text" tag="p">
          <span class="t-weight-semibold">{{ $t('onboarding.fleets.firstSlide.descriptionFirst.bold1') }}</span>
          <span class="t-weight-semibold">{{ $t('onboarding.fleets.firstSlide.descriptionFirst.bold2') }}</span>
          <span class="t-weight-semibold">{{ $t('onboarding.fleets.firstSlide.descriptionFirst.bold3') }}</span>
          <span class="t-weight-semibold">{{ $t('onboarding.fleets.firstSlide.descriptionFirst.bold4') }}</span>
        </i18n>
        
        <div class="
          d-display-flex d-align-items-center d-justify-center m-t-40 m-b-8 p-a-32 w-100 t-bg-color-gray-20
        ">
          <img
            :src="require(`@/assets/images/onboarding/fleets/onboarding1.png`)"
            :alt="$t('onboarding.fleets.firstSlide.imageAltText')"
          >
        </div>
      </div>
</template>
<script lang="ts">

import Vue from 'vue';
import {
  Component, Prop
} from 'vue-property-decorator';

@Component({
  name: 'fleets-onboarding-1'
})

/**
 * Comunidades onboarding component
 */
export default class FleetsOnboarding1Component extends Vue {

  @Prop({
    required: false,
    'default': () => false,
  })
    hideFirstTitle?: boolean;
}
</script>
