/**
 * Model qb-premium-adjustments
 */
class QbPremiumAdjustmentsModel {
  public comissionType?: string = 'T';

  public comissionTypeModificable?: boolean = false;

  public discountType?: string = '';

  public discountValue?: number = 0;

  public campaignName?: string = '';

  public campaignDiscount?: string = '';

  public discountMax?: string = '';

  public multiProductDiscount?: string = '';
}

export default QbPremiumAdjustmentsModel;
