import Vue from 'vue';

import {
  EAApplicationLogger, EAContextManager
} from '@zurich-es-npm/ea-front-web-core';

import {
  EAPivotCompatibility
} from '../ea-pivot-compatibility';

/** 
 * Pivot hooks in architecture
 */
class EAPivotSecurityHooks {

  public static isRefreshingPivot = false;

  private static PIVOT_AUTHENTICATION = 'pivotAuthentication';

  /**
   * Getter for pivotAuthenticated
   * @returns {boolean}
   */
  public static isPivotAuthenticated(): boolean {
    return EAContextManager.getInstance().getSessionValue(this.PIVOT_AUTHENTICATION) as boolean;
  }

  /**
   * Setter for pivotAuthenticated
   * @param {boolean} pivotAuthentication
   */
  public static async setPivotAuthenticated(pivotAuthentication: boolean): Promise<void> {
    EAContextManager.getInstance().setSessionValue(this.PIVOT_AUTHENTICATION, pivotAuthentication);
  }

  /**
   * Force Pivot Authenticated Timeout
   * Set pivotAuthentication to true after [seconds]
   * @param {number} seconds
   * @return {void}
   */
  public static forcePivotAuthenticatedTimeout(seconds: number): void {
    setTimeout(() => {
      EAPivotSecurityHooks.setPivotAuthenticated(true);
    }, seconds*1000);
  }


  /**
   * Load a url into the iframe
   * 
   * @param {string} url
   * @return {Promise<void>}
   * @static
   * @memberof EAPivotCoexistence
   */
  public static loadIframeUrl(url: string): Promise<void> {

    return new Promise((resolve: Function) => {
      const logger = new EAApplicationLogger();
      const iframeElement: HTMLElement | null = document.getElementById('coexSecIframe');
      if (iframeElement) {
        
        EAPivotSecurityHooks.isRefreshingPivot = true;

        const iframe = iframeElement as unknown as {
          src: string;
          onload: Function | undefined;
        };
  
        iframe.onload = () => {
          EAPivotSecurityHooks.isRefreshingPivot = false;
          logger.info('EAPivotSecurityHooks::loadIframeUrl::Iframe has been loaded');
          iframe.src = 'about:blank';
          iframe.onload = undefined;
          resolve();
        };

        iframe.src = url;
  
      } else {
        logger.warn('EAPivotSecurityHooks::loadIframeUrl::Cannot find iframe');
      }
    });

    
  }

  /**
   * Do pivot logout
   * 
   * @static
   * @memberof EAPivotCoexistence
   */
  public static async logout(): Promise<void> {

    const pivotBaseUrl = Vue.prototype.pivotBaseUrl;
    const pivotLogout = Vue.prototype.pivotLogout;

    const logout = `${pivotBaseUrl}${pivotLogout}`;

    if (EAPivotCompatibility.canUseIframe()) {
      await EAPivotSecurityHooks.loadIframeUrl(logout);
    }

    EAPivotSecurityHooks.setPivotAuthenticated(false);

  }
 
  /**
   * Do pivot refresh session
   * 
   * @static
   * @memberof EAPivotCoexistence
   */
  public static async refreshSession(): Promise<void> {

    await EAPivotSecurityHooks.home();

  }

  /**
   * Go to home
   * 
   * @static
   * @memberof EAPivotCoexistence
   */
  public static async home(): Promise<void> {

    const pivotBaseUrl = Vue.prototype.pivotBaseUrl;
    const pivotHome = Vue.prototype.pivotHome;

    const home = `${pivotBaseUrl}${pivotHome}?coexistence=true`;

    if (EAPivotCompatibility.canUseIframe()) {
      await EAPivotSecurityHooks.loadIframeUrl(home);
    }

  }

}

export {
  EAPivotSecurityHooks
};
