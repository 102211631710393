<template>
  <div v-if="documentList && documentList.length > 0">
    <ea-heading v-if="title" level="5">
      {{$t(title)}}
    </ea-heading>
    <ea-form>
      <ea-form-item
        class="m-b-0"
        v-for="(document, index ) in documentList"
        :key="index"
        :showLabel="false">

        <div class="d-display-flex m-t-16 hp-24">
          <ea-checkbox
            v-model="document.indicadorDocumentoMarcado"
            :checked="download ? true : document.indicadorDocumentoMarcado"
            :label="document.nombreDocumento"
            :disabled="download ? false : document.indicadorDocumentoBloqueado"
          >
          </ea-checkbox>
          <ea-link class="m-l-16 m-b-4"
            size="medium"
            v-if="document.indicadorVisualizacion && !download && !isDocumentInNoPreviewList(document)"
            @click="showDocumentPreview(document)" >
              {{ $t('common.link.preview') }}
          </ea-link>
        </div>
      </ea-form-item>
    </ea-form>

  </div>
</template>

<script lang="ts">
import {
  Component, Prop
} from 'vue-property-decorator';

import {
  mixins
} from 'vue-class-component';

import {
  EABusinessComponent
} from '@zurich-es-npm/ea-front-web-core';

import QbDocumentCheckEmitModel from './qb-document-check-emit-model';
import {
  GetIssueOfferDataResponseListaDocumentos
} from '@/services/V1/quoteAndBuy/getIssueOfferDataOperation/post';

@Component({
  name: 'qb-document-check-emit'
})

/**
 * Business Component qb-document-chek-emit
 */
export default class QbDocumentCheckEmitBusiness extends mixins<
EABusinessComponent<QbDocumentCheckEmitModel>
>(EABusinessComponent) {
  @Prop({
    'default': () => '',
  })
    title?: string;

  @Prop({
    'default': () => [],
  })
    documentList?: GetIssueOfferDataResponseListaDocumentos[];

  @Prop({
    'default': false,
  })
    download?: boolean;

  @Prop({
    'default': () => [],
  })
    documentTextCodesNoPreview?: string[];

  /**
   * Emits event for document preview
   * @param {GetIssueOfferDataResponseListaDocumentos} document 
   */
  public showDocumentPreview(document: GetIssueOfferDataResponseListaDocumentos) {
    this.$emit('showDocumentPreview', document);
  }

  /**
   * Checks if received document is in documentTextCodesNoPreview prop array
   * @param {GetIssueOfferDataResponseListaDocumentos} document
   * @returns {boolean}
   */
  isDocumentInNoPreviewList(document: GetIssueOfferDataResponseListaDocumentos): boolean {
    return !!this.documentTextCodesNoPreview?.some(docTextCode => docTextCode === document.codigoDocumentoTexto);
  }
}
</script>
