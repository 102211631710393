/* eslint-disable */

/**
 * zonazurich
 * Portal de Mediación Digital
 *
 * The version of the OpenAPI document: 1
 * Contact: digitalarchitecture@zurich.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import {
  EASDKConfig,
  EAUrlUtils
} from '@zurich-es-npm/ea-shared-utils-js';

import * as globalImportUrl from 'url';
import {
  Configuration
} from './configuration';
import globalAxios, {
  AxiosPromise,
  AxiosInstance,
  AxiosResponse
} from 'axios';

const BASE_PATH = EAUrlUtils.removeLastForwardSlash('http://localhost:3000');

/**
 *
 * @export
 */
export const COLLECTION_FORMATS = {
  csv: ',',
  ssv: ' ',
  tsv: '\t',
  pipes: '|',
};

/**
 *  
 * @export
 * @interface RequestArgs
 */
export interface RequestArgs {
  url: string;
  options: any;
}

/**
 * 
 * @export
 * @class BaseAPI
 */
export class BaseAPI {
  protected configuration: Configuration | undefined;

  /**
   *Creates an instance of BaseAPI.
   * @param {Configuration} [configuration]
   * @param {string} [basePath=BASE_PATH]
   * @param {AxiosInstance} [axios=globalAxios]
   * @memberof BaseAPI
   */
  public constructor(
    configuration?: Configuration,
    protected basePath: string = BASE_PATH,
    protected axios: AxiosInstance = globalAxios) {
    if (configuration) {
      this.configuration = configuration;
      this.basePath = configuration.basePath || this.basePath;
    }
  }
}

/**
 * 
 * @export
 * @class RequiredError
 * @extends {Error}
 */
export class RequiredError extends Error {
  public name: 'RequiredError' = 'RequiredError';

  public field: string;

  /**
   *Creates an instance of RequiredError.
   * @param {string} field
   * @param {string} [msg]
   * @memberof RequiredError
   */
  public constructor(field: string, msg?: string) {
    super(msg);
    this.field=field;
  }
}

/**
 * 
 * @export
 * @class AsyncRequestError
 * @extends {Error}
 */
export class AsyncRequestError extends Error {
  public name: 'AsyncRequestError' = 'AsyncRequestError';

  /**
   *Creates an instance of AsyncRequestError.
   * @param {string} field
   * @param {string} [msg]
   * @memberof AsyncRequestError
   */
  public constructor(msg?: string) {
    super(msg);
  }
}

/**
 * 
 * @export
 * @interface Error
 */
export interface Error {
    /**
     * 
     * @type {string}
     * @memberof Error
     */
    code: string;
    /**
     * 
     * @type {string}
     * @memberof Error
     */
    message: string;
    /**
     * 
     * @type {number}
     * @memberof Error
     */
    severity: number;
    /**
     * 
     * @type {Array<object>}
     * @memberof Error
     */
    childErrors?: Array<object>;
}/**
 * 
 * @export
 * @interface GetCancelPolicyInformationRequest
 */
export interface GetCancelPolicyInformationRequest {
    /**
     * 
     * @type {string}
     * @memberof GetCancelPolicyInformationRequest
     */
    codigoPoliza: string;
    /**
     * 
     * @type {number}
     * @memberof GetCancelPolicyInformationRequest
     */
    versionPoliza: number;
}/**
 * 
 * @export
 * @interface GetCancelPolicyInformationResponse
 */
export interface GetCancelPolicyInformationResponse {
    /**
     * 
     * @type {string}
     * @memberof GetCancelPolicyInformationResponse
     */
    codigoPoliza?: string;
    /**
     * 
     * @type {number}
     * @memberof GetCancelPolicyInformationResponse
     */
    versionPoliza?: number;
    /**
     * 
     * @type {string}
     * @memberof GetCancelPolicyInformationResponse
     */
    fechaEfectoNatural?: string;
    /**
     * 
     * @type {string}
     * @memberof GetCancelPolicyInformationResponse
     */
    fechaEfectoMovimiento?: string;
    /**
     * 
     * @type {string}
     * @memberof GetCancelPolicyInformationResponse
     */
    fechaVencimiento?: string;
    /**
     * 
     * @type {Array<GetCancelPolicyInformationResponseMotivosMovimiento>}
     * @memberof GetCancelPolicyInformationResponse
     */
    motivosMovimiento?: Array<GetCancelPolicyInformationResponseMotivosMovimiento>;
    /**
     * 
     * @type {Array<Error>}
     * @memberof GetCancelPolicyInformationResponse
     */
    errors?: Array<Error>;
}/**
 * 
 * @export
 * @interface GetCancelPolicyInformationResponseMotivosMovimiento
 */
export interface GetCancelPolicyInformationResponseMotivosMovimiento {
    /**
     * 
     * @type {string}
     * @memberof GetCancelPolicyInformationResponseMotivosMovimiento
     */
    motivoMovimientoPoliza: string;
    /**
     * 
     * @type {string}
     * @memberof GetCancelPolicyInformationResponseMotivosMovimiento
     */
    descripcionMotivoMovimiento: string;
}

type getCancelPolicyInformationOperationParams = {
  getCancelPolicyInformationRequest: GetCancelPolicyInformationRequest,
};

/**
 * GetCancelPolicyInformationApi - axios parameter creator
 * @param {Configuration} [configuration]
 * @returns {any}
 */
const GetCancelPolicyInformationApiAxiosParamCreator = function(configuration?: Configuration): any {
  return {
    
    /**
     * Devuelve información necesaria para empazar la anulación de una póliza
     * @param {GetCancelPolicyInformationRequest} getCancelPolicyInformationRequest 
     * @param {string} [acceptLanguage] El Accept-Language request HTTP header indica en que lenguaje debe responder el backend.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @returns {RequestArgs}
     */
    getCancelPolicyInformationOperation(params: getCancelPolicyInformationOperationParams, options: any = {}): RequestArgs {
      // Verify required parameter 'getCancelPolicyInformationRequest' is not null or undefined
      if (params.getCancelPolicyInformationRequest === null || params.getCancelPolicyInformationRequest === undefined) {
        throw new RequiredError('getCancelPolicyInformationRequest', 'Required parameter getCancelPolicyInformationRequest was null or undefined when calling getCancelPolicyInformationOperation.');
      }
      const localVarPath = `/v1/policy/getCancelPolicyInformationOperation`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true) as Partial<globalImportUrl.UrlWithParsedQuery>;
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;


  
      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
      // Fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
      const needsSerialization = ('GetCancelPolicyInformationRequest' as any !== 'string') ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization ? JSON.stringify(params.getCancelPolicyInformationRequest || {}) : params.getCancelPolicyInformationRequest || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * GetCancelPolicyInformationApi - functional programming interface
 * @param {Configuration} [configuration]
 * @returns {any}
 */
const GetCancelPolicyInformationApiFp = function(configuration?: Configuration): any {
  return {
    
    /**
     * Devuelve información necesaria para empazar la anulación de una póliza
     * @param {GetCancelPolicyInformationRequest} getCancelPolicyInformationRequest 
     * @param {string} [acceptLanguage] El Accept-Language request HTTP header indica en que lenguaje debe responder el backend.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @returns {any}
     */
    getCancelPolicyInformationOperation(params: getCancelPolicyInformationOperationParams, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCancelPolicyInformationResponse> {
      const localVarAxiosArgs = GetCancelPolicyInformationApiAxiosParamCreator(configuration).getCancelPolicyInformationOperation(params, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH): any => {
        const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * GetCancelPolicyInformationApi - factory interface
 * @param {Configuration} configuration
 * @param {string} basePath
 * @param {AxiosInstance} axios
 * @export
 * @returns {any}
 */
const GetCancelPolicyInformationApiFactory = function(configuration?: Configuration, basePath?: string, axios?: AxiosInstance): any {
  return {
    
    /**
     * Devuelve información necesaria para empazar la anulación de una póliza
     * @param {GetCancelPolicyInformationRequest} getCancelPolicyInformationRequest 
     * @param {string} [acceptLanguage] El Accept-Language request HTTP header indica en que lenguaje debe responder el backend.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @returns {any}
     */
    getCancelPolicyInformationOperation(params: getCancelPolicyInformationOperationParams, options?: any): any {
      return GetCancelPolicyInformationApiFp(configuration).getCancelPolicyInformationOperation(params, options)(axios, basePath);
    },
  };
};

/** 
 * GetCancelPolicyInformationApi - object-oriented interface
 * @export
 * @class GetCancelPolicyInformationApi
 * @extends {BaseAPI}
 */
export class GetCancelPolicyInformationApi extends BaseAPI {
  
  /**
   * Devuelve información necesaria para empazar la anulación de una póliza
   * @param {GetCancelPolicyInformationRequest} getCancelPolicyInformationRequest 
   * @param {string} [acceptLanguage] El Accept-Language request HTTP header indica en que lenguaje debe responder el backend.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @returns {any}
   * @memberof GetCancelPolicyInformationApi
   */
  public getCancelPolicyInformationOperation(params: getCancelPolicyInformationOperationParams, options?: any): any {
    return GetCancelPolicyInformationApiFp(this.configuration).getCancelPolicyInformationOperation(params, options)(this.axios, this.basePath);
  }

}

/**
 * 
 * @export
 * @class EAgetCancelPolicyInformationOperationInputMessage
 */
export class EAgetCancelPolicyInformationOperationInputMessage {
  
  /**
   * 
   * @type {GetCancelPolicyInformationRequest}
   * @memberof EAgetCancelPolicyInformationOperationInputMessage
   */
  public getCancelPolicyInformationRequest: GetCancelPolicyInformationRequest;

  public constructor(getCancelPolicyInformationRequest: GetCancelPolicyInformationRequest, ){
  
  this.getCancelPolicyInformationRequest=getCancelPolicyInformationRequest;
  } 
}



/** 
 * EAGetCancelPolicyInformationApi - Architecture client for getCancelPolicyInformationOperation
 * @export
 * @class EAGetCancelPolicyInformationApi
 */
export class EAGetCancelPolicyInformationApi {

  /**
   * 
   * @param {GetCancelPolicyInformationRequest} getCancelPolicyInformationRequest
   
   * @throws {AsyncRequestError | RequiredError}
   * @returns {Promise<GetCancelPolicyInformationResponse | null>}
  */ 
  public async getCancelPolicyInformationOperation(params: getCancelPolicyInformationOperationParams): Promise<GetCancelPolicyInformationResponse | null> {
    return this.getCancelPolicyInformationOperationPromise(params);
  }

  /**
   * 
   * @param {GetCancelPolicyInformationRequest} getCancelPolicyInformationRequest
   
   * @param {Function} callback
   * @throws {AsyncRequestError | RequiredError}
   */
  public async getCancelPolicyInformationOperationSteps(params: getCancelPolicyInformationOperationParams, callback: (res: AxiosResponse<GetCancelPolicyInformationResponse | null>) => void) {
    let done: boolean = false;
    const httpClient: AxiosInstance = EASDKConfig.getHttpClient('/v1/policy/getCancelPolicyInformationOperation', 'getCancelPolicyInformation', 'getCancelPolicyInformationOperation');
    const config: Configuration = {};
    const api = new GetCancelPolicyInformationApi(config, '', httpClient);
    const sleep = async(ms: number) => {
      return new Promise(resolve => setTimeout(resolve, ms));
    };
    const headers: { ['Async-Id']?: string } = {};
    while (!done) {
      const output = await api.getCancelPolicyInformationOperation(params, { headers }) as AxiosResponse<GetCancelPolicyInformationResponse | null>;
      callback(output);
      if (output.status === 202) {
        try {
          const retry = parseInt(output.headers['retry']);
          headers['Async-Id'] = output.headers['request-id'];
          if (isNaN(retry) || retry <= 0 || retry > 1000 * 3600) {
            throw new Error(`Retry time not acceptable (in milliseconds): ${retry}`);
          }
          await sleep(retry);
        } catch (err) {
          throw new Error(`Error in Async Request ${err}`);
        }
      } else {
        done = true;
      }
    }
  }

  /**
   * 
   * @param {GetCancelPolicyInformationRequest} getCancelPolicyInformationRequest
   
   * @throws {AsyncRequestError | RequiredError}
   * @returns {Promise<GetCancelPolicyInformationResponse | null>}
   */
  public async getCancelPolicyInformationOperationPromise(params: getCancelPolicyInformationOperationParams): Promise<GetCancelPolicyInformationResponse | null> {
    return new Promise((resolve, reject) => {
      this.getCancelPolicyInformationOperationSteps(params, (response: AxiosResponse<GetCancelPolicyInformationResponse | null>) => {
        if (response.status !== 202) {
          resolve(response.data);
        }
      })
      .catch((err) => {
        reject(err);
      });
    });
  } 
}


