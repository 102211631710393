<template>

  <!-- Loader to multioperation tab when quoting (batch online) -->
  <div
    :id="loadingContainerId"
    v-loading="loading"
    :element-loading-text="$t('fleets.fleetsFlow.issueFleet.loadingText')"
  >
    <fleet-issuance-data
      id="fleetIssuanceData"
      v-model="model.fleetIssuanceDataModel"
      v-if="isInitialLoadComplete"
      :fleetData = 'model.qbNewOfferBusinessModel'
      :policyHolder = 'model.fleetSearchPolicyHolderModel'
      :unifiedExpirationData='model.fleetUnifiedExpirationModel'
      :groupedVehicleList='model.fleetVehicleGroupsModel.fleetGroupedVehicleList'
      :issuedFleet="issuedFleet">
    </fleet-issuance-data>

    <fleet-payment-data
      id="fleetPaymentData"
      v-if="isInitialLoadComplete"
      v-model="model.fleetPaymentDataModel"
      :paymentForm="model.fleetQuotePaymentFormModel.paymentForm"
      :annualPremiumTotal="allGroupsTotal"
      :paymentFormOptions="model.flowConfiguration.quoteDataView.paymentFormOptions"
      :paymentChannelOptions="model.flowConfiguration.offerIssuanceDataView.paymentChannelSucessiveReceiptsOptions"
      :payerPerson="model.fleetSearchPolicyHolderModel.selectedJuridicalPerson"
      :issuedFleet="issuedFleet"
      @showError="showError"
      @handleGenericError="handleGenericError"
    >
    </fleet-payment-data>

    <ea-row class="m-t-16">
        <ea-col v-if="!issuedFleet" :span="12" class="d-display-flex d-justify-flex-start">
          <ea-button type="secondary" @click="onGoBack">
            {{ $t('common.label.back') }}
          </ea-button>
        </ea-col>
        <ea-col v-else :span="12" class="d-display-flex d-justify-flex-start">
          <ea-button type="secondary" @click="onClose">
            {{ $t('common.label.close') }}
          </ea-button>
        </ea-col>
        <ea-col :span="12" class="d-display-flex d-justify-flex-end">
          <ea-button v-if="!issuedFleet" type="primary" @click="onEmitClick" :disabled="isSaveDisabled">
            {{ $t('fleets.fleetsFlow.offerIssuanceDataView.saveAndEmitBtn') }}
          </ea-button>
        </ea-col>
      </ea-row>
  </div>
</template>

<script lang="ts">
import {
  Component, Watch
} from 'vue-property-decorator';

import {
  mixins
} from 'vue-class-component';

import {
  EAView,
  EAMethod,
  EAErrorManager,
  ResponseWithErrors,
  throwIfResponseHasErrors,
  EAValidationError
} from '@zurich-es-npm/ea-front-web-core';

import {
  FleetsModel,
  FlowHeaderStepsModel,
  FlowViewsStepsModel
} from '../fleets-model';
import FlowModelFillUtils from '../utils/flow-model-fill.utils';
import FleetIssuanceDataBusiness from '@/business-components/fleet-issuance-data/fleet-issuance-data-business.vue';
import {
  NotificationsUtils
} from '@/utils/notifications/notifications-utils';
import FleetPaymentDataBusiness from '@/business-components/fleet-payment-data/fleet-payment-data-business.vue';
import Utils from '@/utils/utils';
import {
  PersonRole
} from '@/utils/quote-buy-product-factory/types/product-role-types';
import {
  GenericErrorData
} from '@/business-components/qb-generic-error/qb-generic-error-business.vue';
import {
  PaymentChannelEnum
} from '@/types/payment-channel/payment-channel-enum.types';
import {
  CreateIssueFleetRequest, CreateIssueFleetRequestTipoCanalCobroEnum as CanalCobro,
  CreateIssueFleetResponseEstadoProcesoBatchOnlineEnum as BatchStatus, EACreateIssueFleetApi
} from '../../../services/V1/fleets/createIssueFleetOperation/post/api';
import {
  EAGetFleetCompetencesManagementApi
} from '@/services/V1/fleets/getFleetCompetencesManagementOperation/post';

@Component({
  components: {
    FleetIssuanceData: FleetIssuanceDataBusiness,
    FleetPaymentData: FleetPaymentDataBusiness
  }
})

/**
 * OfferIssuanceData view
 *
 */
export default class OfferIssuanceDataView extends mixins<EAView<FleetsModel>>(EAView) {
  api: EACreateIssueFleetApi = new EACreateIssueFleetApi();

  isInitialLoadComplete: boolean = false;

  allGroupsTotal: string = '';

  payerPerson!: PersonRole;

  idBatchOnline: string | undefined;

  timeToRetry: number = 5000;

  loading = false; // Loader on multioperation tab

  loadingContainerId = 'loading-container-fleets-issue-view';

  issuedFleet = false;

  flowId = ''; // Used to know if we are in the same flow when requtoe has finished

  totalNumVehicles: number = 0;

  hasCompetencesManagement: boolean = false;

  isAuthorized: boolean = false;

  /**
   * Getter on minimum vehicles number range
   */
  get minVehiclesNumberRange(): number {
    return this.model.flowConfiguration.generalDataView.fleetGeneralInfoRestrictions.vehiclesNumber.range.min;
  }

  /**
   * Hook on create
   */
  @EAMethod({
    loading: true,
  })
  async created() {
    
    this.flowId = NotificationsUtils.getCurrentFlowId();
    await Promise.all([
      FlowModelFillUtils.loadFleetData(this.model, true),
      FlowModelFillUtils.loadVehicleList(this.model),
      this.fetchCompetencesManagement()
    ]);
    this.calcTotals();
    this.isInitialLoadComplete = true;
  }

  /**
   * Watches `loading` changes and scrolls to the center of the page if loader is active.
   * @param {boolean} newValue 
   */
  @Watch('loading')
  async onLoadingStatusChange(newValue: boolean) {
    if (newValue) {
      await Utils.scrollToCenterOfElement(`#${this.loadingContainerId}`);
    }
  }

  /**
   * Calculate group prize total and all groups total
   */
  calcTotals() {
    let calcVehicles = 0;
    let allGroupsTotalP = 0;
    for (const group of this.model.fleetVehicleGroupsModel.fleetGroupedVehicleList) {
      if (group.listaVehiculos) {
        let groupTotal = 0;
        calcVehicles = calcVehicles + group.listaVehiculos.length;
        for (const vehiculo of group.listaVehiculos) {
          if (vehiculo.importeTotal) {
            groupTotal += vehiculo.importeTotal;
          }
        }
        allGroupsTotalP += groupTotal;
      }
    }
    this.totalNumVehicles = calcVehicles;
    this.allGroupsTotal = Utils.digitsFormatter(this.$n(allGroupsTotalP));
  }
  
  /**
   * Go back button handler
   */
  onGoBack() {
    NotificationsUtils.clearNotifications();
    EAErrorManager.clearError();
    this.$emit('changeStep', FlowViewsStepsModel.InterveningDataStep, FlowHeaderStepsModel.InterveningDataStep);
  }
    
  /**
   * Go back button handler
   */
  onClose() {
    this.$emit('closeFlow');
  }

  /**
   * Emit button handler
   */
  async onEmitClick() {
    // Clear errors and notifications
    EAErrorManager.clearError();
    NotificationsUtils.clearNotifications();
    this.isEmitAuthorized();

    if (this.isAuthorized) {
      await this.saveAndEmit();
    }
  }

  /**
   * Guardar y emitir
   */
  @EAMethod()
  async saveAndEmit() {
    // Validate
    EAErrorManager.clearError();
    NotificationsUtils.clearNotifications();

    this.loading = true; // To put loader into full tab

    const timer = this.timeToRetry;
    const paymentChannel = this.model.fleetPaymentDataModel.paymentChannel as
     CanalCobro;
    const createIssueFleetRequest: CreateIssueFleetRequest = {
      codigoFlota: this.model.offerNumber,
      versionFlota:this.model.offerVersion,
      tipoCanalCobro: paymentChannel,
      codigoIBAN: paymentChannel === CanalCobro.BC ?
        this.model.fleetPaymentDataModel.ibanCodeModel.qbIbanCodeModalModel.selectedIbanCode : undefined,
      idProcesoBatchOnline: this.idBatchOnline,
      codigoFiliacion: this.model.fleetSearchPolicyHolderModel.selectedJuridicalPerson.codigoFiliacion as string
    };

    try {
      const response = await this.api.createIssueFleetOperation({
        createIssueFleetRequest
      });
      throwIfResponseHasErrors(response as unknown as ResponseWithErrors);
 
      this.idBatchOnline = response?.idProcesoBatchOnline as string;
      if (response?.estadoProcesoBatchOnline === BatchStatus.Finalizado) {
        this.idBatchOnline = undefined;
        this.loading = false;
        this.issuedFleet = true;

        if (response.polizasNoEmitidas) {
          // Needed to show as error message in case any policy not emited
          throw new EAValidationError(this.$t('fleets.fleetsFlow.issueFleet.policyNotEmited.message').toString());
        } else {
          this.showNotifySuccessMessages();
           
          // Mark stepper as checked by using a high-enough number 
          this.$emit('changeStep',
            FlowViewsStepsModel.OfferIssuanceDataStep,
            99
          );
        }

      } else if (response?.estadoProcesoBatchOnline === BatchStatus.EnCurso) {
        await this.sleep(timer);
        await this.saveAndEmit();
      } else if (response?.estadoProcesoBatchOnline === BatchStatus.Error) {
        this.loading = false;
        this.idBatchOnline = undefined;
 
        // Top-right notification error
        this.showNotifyErrorMessageIfDifferentFlow();
      }
    } catch (err) {
      this.idBatchOnline = undefined;
      // In case of throw error, we must catch the error to close loader and then, throw again
      this.loading = false;
      // Top-right notification error
      this.showNotifyErrorMessageIfDifferentFlow();

      throw err;
    }
  }

  /**
   * Fetches competences management boolean
   */
  @EAMethod({
    loading: true,
  })
  async fetchCompetencesManagement() {
    const api = new EAGetFleetCompetencesManagementApi();
    const output = await api.getFleetCompetencesManagementOperation({
      getFleetCompetencesManagementRequest: {
        codigoFlota: this.model.offerNumber,
        versionFlota: this.model.offerVersion
      },
    });
    throwIfResponseHasErrors(output as ResponseWithErrors);
    this.hasCompetencesManagement = output?.gestionDeCompetencias as boolean;
  }

  /**
   * Check if Emition is authorized
   *
   */
  isEmitAuthorized(): void {
    this.isAuthorized = true;
    // The issuance is not allowed for less than 10 vehicles
    if (this.totalNumVehicles < this.minVehiclesNumberRange) {
      NotificationsUtils.throwWarning(
        this.$t('fleets.fleetsFlow.issueFleet.emitNotAuthorized.minNumberVechicles').toString());
      this.isAuthorized = false;
    }
    if (this.hasCompetencesManagement) {
      NotificationsUtils.throwWarning(
        this.$t('fleets.fleetsFlow.issueFleet.emitNotAuthorized.compentecyManagement').toString());
      this.isAuthorized = false;
    }
  }

  /**
   * Show top-right notification error if different current flow than initial flow
   */
  showNotifyErrorMessageIfDifferentFlow() {
    const isSame = NotificationsUtils.isCurrentFlowSameThan(this.flowId);
    if (!isSame) {
      this.showNotifyMessage('error');
    }
  }

  /**
   * Show overlayed notify message in the top right corner of the screen.
   * @param {'success' | 'warning' | 'error'} type 
   */
  showNotifyMessage(type: 'success' | 'warning' | 'error') {
    const title = `fleets.fleetsFlow.issueFleet.issueFleetResultsTopRightNotifications.${type}.title`;
    const message = `fleets.fleetsFlow.issueFleet.issueFleetResultsTopRightNotifications.${type}.message`;
    this.$eaNotify({
      title: `${this.$t(title)}`,
      message: `${this.$t(message)}`,
      duration: 0, // Infinite duration
      type
    });
  }


  /**
   * Hold app sleep
   * @param {number} ms
   */
  async sleep(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }


  /**
   * Show top-right notification error if different current flow than initial flow
   */
  showNotifySuccessMessages() {
    const isSameFlow = this.flowId ? NotificationsUtils.isCurrentFlowSameThan(this.flowId) : true;
    NotificationsUtils.throwSuccess(`${this.$t('fleets.fleetsFlow.issueFleet.success.message', {
      policy: this.model.offerNumber
    })}`, this.flowId);
    NotificationsUtils.throwInfo(`${this.$t('fleets.fleetsFlow.issueFleet.info.message', {
      policy: this.model.offerNumber
    })}`, this.flowId);
    if (!isSameFlow) {
      // Top-right notification success
      this.showNotifyMessage('success');
    }
  }


  /**
   * Emits showError event
   * @param {GenericErrorData} genericErrorData
   */
  showError(genericErrorData: GenericErrorData) {
    this.$emit('showError', genericErrorData);
  }

  /**
   * Emits handleGenericError event
   * @param {any} args
   */
  handleGenericError(args: any) {
    const {
      error, errorCode
    } = args;
    this.$emit('handleGenericError', {
      error,
      errorCode,
    });
  }

  /**
   * Handle save button availability 
   */
  get isSaveDisabled() {
    if (this.model.fleetPaymentDataModel.paymentChannel) {
      if (this.model.fleetPaymentDataModel.paymentChannel === PaymentChannelEnum.Banco &&
        this.model.fleetPaymentDataModel.ibanCodeModel.qbIbanCodeModalModel.selectedIbanCode === ''
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }
}
</script>
