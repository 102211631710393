/* eslint-disable */

/**
 * zonazurich
 * Portal de Mediación Digital
 *
 * The version of the OpenAPI document: 1
 * Contact: digitalarchitecture@zurich.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import {
  EASDKConfig,
  EAUrlUtils
} from '@zurich-es-npm/ea-shared-utils-js';

import * as globalImportUrl from 'url';
import {
  Configuration
} from './configuration';
import globalAxios, {
  AxiosPromise,
  AxiosInstance,
  AxiosResponse
} from 'axios';

const BASE_PATH = EAUrlUtils.removeLastForwardSlash('http://localhost:3000');

/**
 *
 * @export
 */
export const COLLECTION_FORMATS = {
  csv: ',',
  ssv: ' ',
  tsv: '\t',
  pipes: '|',
};

/**
 *  
 * @export
 * @interface RequestArgs
 */
export interface RequestArgs {
  url: string;
  options: any;
}

/**
 * 
 * @export
 * @class BaseAPI
 */
export class BaseAPI {
  protected configuration: Configuration | undefined;

  /**
   *Creates an instance of BaseAPI.
   * @param {Configuration} [configuration]
   * @param {string} [basePath=BASE_PATH]
   * @param {AxiosInstance} [axios=globalAxios]
   * @memberof BaseAPI
   */
  public constructor(
    configuration?: Configuration,
    protected basePath: string = BASE_PATH,
    protected axios: AxiosInstance = globalAxios) {
    if (configuration) {
      this.configuration = configuration;
      this.basePath = configuration.basePath || this.basePath;
    }
  }
}

/**
 * 
 * @export
 * @class RequiredError
 * @extends {Error}
 */
export class RequiredError extends Error {
  public name: 'RequiredError' = 'RequiredError';

  public field: string;

  /**
   *Creates an instance of RequiredError.
   * @param {string} field
   * @param {string} [msg]
   * @memberof RequiredError
   */
  public constructor(field: string, msg?: string) {
    super(msg);
    this.field=field;
  }
}

/**
 * 
 * @export
 * @class AsyncRequestError
 * @extends {Error}
 */
export class AsyncRequestError extends Error {
  public name: 'AsyncRequestError' = 'AsyncRequestError';

  /**
   *Creates an instance of AsyncRequestError.
   * @param {string} field
   * @param {string} [msg]
   * @memberof AsyncRequestError
   */
  public constructor(msg?: string) {
    super(msg);
  }
}

/**
 * 
 * @export
 * @interface Error
 */
export interface Error {
    /**
     * 
     * @type {string}
     * @memberof Error
     */
    code: string;
    /**
     * 
     * @type {string}
     * @memberof Error
     */
    message: string;
    /**
     * 
     * @type {number}
     * @memberof Error
     */
    severity: number;
    /**
     * 
     * @type {Array<object>}
     * @memberof Error
     */
    childErrors?: Array<object>;
}/**
 * 
 * @export
 * @interface GetIntermediaryBusinessProductListRequest
 */
export interface GetIntermediaryBusinessProductListRequest {
    /**
     * 
     * @type {string}
     * @memberof GetIntermediaryBusinessProductListRequest
     */
    codigoOperacionIntermediario: GetIntermediaryBusinessProductListRequestCodigoOperacionIntermediarioEnum;
    /**
     * 
     * @type {string}
     * @memberof GetIntermediaryBusinessProductListRequest
     */
    codigoRamo: GetIntermediaryBusinessProductListRequestCodigoRamoEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum GetIntermediaryBusinessProductListRequestCodigoOperacionIntermediarioEnum {
    OF = 'OF'
}
/**
    * @export
    * @enum {string}
    */
export enum GetIntermediaryBusinessProductListRequestCodigoRamoEnum {
    THCO = 'THCO',
    COMU = 'COMU',
    LPAU = 'LPAU',
    THFL = 'THFL',
    ZTRC = 'ZTRC',
    LPHG = 'LPHG',
    HOME = 'HOME',
    THHG = 'THHG',
    THHD = 'THHD'
}
/**
 * 
 * @export
 * @interface GetIntermediaryBusinessProductListResponse
 */
export interface GetIntermediaryBusinessProductListResponse {
    /**
     * 
     * @type {Array<GetIntermediaryBusinessProductListResponseListaIntermediarios>}
     * @memberof GetIntermediaryBusinessProductListResponse
     */
    listaIntermediarios?: Array<GetIntermediaryBusinessProductListResponseListaIntermediarios>;
    /**
     * 
     * @type {Array<Error>}
     * @memberof GetIntermediaryBusinessProductListResponse
     */
    errors?: Array<Error>;
}/**
 * 
 * @export
 * @interface GetIntermediaryBusinessProductListResponseListaIntermediarios
 */
export interface GetIntermediaryBusinessProductListResponseListaIntermediarios {
    /**
     * 
     * @type {string}
     * @memberof GetIntermediaryBusinessProductListResponseListaIntermediarios
     */
    nombreIntermediario?: string;
    /**
     * 
     * @type {string}
     * @memberof GetIntermediaryBusinessProductListResponseListaIntermediarios
     */
    codigoIntermediario?: string;
    /**
     * 
     * @type {string}
     * @memberof GetIntermediaryBusinessProductListResponseListaIntermediarios
     */
    codigoEstructura?: string;
    /**
     * 
     * @type {string}
     * @memberof GetIntermediaryBusinessProductListResponseListaIntermediarios
     */
    codigoNegocio?: string;
    /**
     * 
     * @type {string}
     * @memberof GetIntermediaryBusinessProductListResponseListaIntermediarios
     */
    nombreNegocio?: string;
    /**
     * 
     * @type {string}
     * @memberof GetIntermediaryBusinessProductListResponseListaIntermediarios
     */
    codigoTecnicoProducto?: string;
    /**
     * 
     * @type {string}
     * @memberof GetIntermediaryBusinessProductListResponseListaIntermediarios
     */
    codigoComercialProducto?: string;
    /**
     * 
     * @type {string}
     * @memberof GetIntermediaryBusinessProductListResponseListaIntermediarios
     */
    nombreProducto?: string;
}

type getIntermediaryBusinessProductListOperationParams = {
  getIntermediaryBusinessProductListRequest: GetIntermediaryBusinessProductListRequest,
};

/**
 * GetIntermediaryBusinessProductListApi - axios parameter creator
 * @param {Configuration} [configuration]
 * @returns {any}
 */
const GetIntermediaryBusinessProductListApiAxiosParamCreator = function(configuration?: Configuration): any {
  return {
    
    /**
     * Devuelve un listado de intermediarios, negocios y productos a partir de las permutaciones que se le permiten seleccionar al usuario
     * @param {GetIntermediaryBusinessProductListRequest} getIntermediaryBusinessProductListRequest 
     * @param {string} [acceptLanguage] El Accept-Language request HTTP header indica en que lenguaje debe responder el backend.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @returns {RequestArgs}
     */
    getIntermediaryBusinessProductListOperation(params: getIntermediaryBusinessProductListOperationParams, options: any = {}): RequestArgs {
      // Verify required parameter 'getIntermediaryBusinessProductListRequest' is not null or undefined
      if (params.getIntermediaryBusinessProductListRequest === null || params.getIntermediaryBusinessProductListRequest === undefined) {
        throw new RequiredError('getIntermediaryBusinessProductListRequest', 'Required parameter getIntermediaryBusinessProductListRequest was null or undefined when calling getIntermediaryBusinessProductListOperation.');
      }
      const localVarPath = `/V1/quoteAndBuy/getIntermediaryBusinessProductListOperation`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true) as Partial<globalImportUrl.UrlWithParsedQuery>;
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;


  
      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
      // Fix override query string Detail: https://stackoverflow.com/a/7517673/1077943

      delete localVarUrlObj.search;
      localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
      const needsSerialization = ('GetIntermediaryBusinessProductListRequest' as any !== 'string') ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization ? JSON.stringify(params.getIntermediaryBusinessProductListRequest || {}) : params.getIntermediaryBusinessProductListRequest || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * GetIntermediaryBusinessProductListApi - functional programming interface
 * @param {Configuration} [configuration]
 * @returns {any}
 */
const GetIntermediaryBusinessProductListApiFp = function(configuration?: Configuration): any {
  return {
    
    /**
     * Devuelve un listado de intermediarios, negocios y productos a partir de las permutaciones que se le permiten seleccionar al usuario
     * @param {GetIntermediaryBusinessProductListRequest} getIntermediaryBusinessProductListRequest 
     * @param {string} [acceptLanguage] El Accept-Language request HTTP header indica en que lenguaje debe responder el backend.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @returns {any}
     */
    getIntermediaryBusinessProductListOperation(params: getIntermediaryBusinessProductListOperationParams, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetIntermediaryBusinessProductListResponse> {
      const localVarAxiosArgs = GetIntermediaryBusinessProductListApiAxiosParamCreator(configuration).getIntermediaryBusinessProductListOperation(params, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH): any => {
        const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * GetIntermediaryBusinessProductListApi - factory interface
 * @param {Configuration} configuration
 * @param {string} basePath
 * @param {AxiosInstance} axios
 * @export
 * @returns {any}
 */
const GetIntermediaryBusinessProductListApiFactory = function(configuration?: Configuration, basePath?: string, axios?: AxiosInstance): any {
  return {
    
    /**
     * Devuelve un listado de intermediarios, negocios y productos a partir de las permutaciones que se le permiten seleccionar al usuario
     * @param {GetIntermediaryBusinessProductListRequest} getIntermediaryBusinessProductListRequest 
     * @param {string} [acceptLanguage] El Accept-Language request HTTP header indica en que lenguaje debe responder el backend.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @returns {any}
     */
    getIntermediaryBusinessProductListOperation(params: getIntermediaryBusinessProductListOperationParams, options?: any): any {
      return GetIntermediaryBusinessProductListApiFp(configuration).getIntermediaryBusinessProductListOperation(params, options)(axios, basePath);
    },
  };
};

/** 
 * GetIntermediaryBusinessProductListApi - object-oriented interface
 * @export
 * @class GetIntermediaryBusinessProductListApi
 * @extends {BaseAPI}
 */
export class GetIntermediaryBusinessProductListApi extends BaseAPI {
  
  /**
   * Devuelve un listado de intermediarios, negocios y productos a partir de las permutaciones que se le permiten seleccionar al usuario
   * @param {GetIntermediaryBusinessProductListRequest} getIntermediaryBusinessProductListRequest 
   * @param {string} [acceptLanguage] El Accept-Language request HTTP header indica en que lenguaje debe responder el backend.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @returns {any}
   * @memberof GetIntermediaryBusinessProductListApi
   */
  public getIntermediaryBusinessProductListOperation(params: getIntermediaryBusinessProductListOperationParams, options?: any): any {
    return GetIntermediaryBusinessProductListApiFp(this.configuration).getIntermediaryBusinessProductListOperation(params, options)(this.axios, this.basePath);
  }

}

/**
 * 
 * @export
 * @class EAgetIntermediaryBusinessProductListOperationInputMessage
 */
export class EAgetIntermediaryBusinessProductListOperationInputMessage {
  
  /**
   * 
   * @type {GetIntermediaryBusinessProductListRequest}
   * @memberof EAgetIntermediaryBusinessProductListOperationInputMessage
   */
  public getIntermediaryBusinessProductListRequest: GetIntermediaryBusinessProductListRequest;

  public constructor(getIntermediaryBusinessProductListRequest: GetIntermediaryBusinessProductListRequest, ){
  
  this.getIntermediaryBusinessProductListRequest=getIntermediaryBusinessProductListRequest;
  } 
}



/** 
 * EAGetIntermediaryBusinessProductListApi - Architecture client for getIntermediaryBusinessProductListOperation
 * @export
 * @class EAGetIntermediaryBusinessProductListApi
 */
export class EAGetIntermediaryBusinessProductListApi {

  /**
   * 
   * @param {GetIntermediaryBusinessProductListRequest} getIntermediaryBusinessProductListRequest
   
   * @param {Configuration} configuration
   * @throws {AsyncRequestError | RequiredError}
   * @returns {Promise<GetIntermediaryBusinessProductListResponse | null>}
  */ 
  public async getIntermediaryBusinessProductListOperation(params: getIntermediaryBusinessProductListOperationParams, configuration: Configuration = {}): Promise<GetIntermediaryBusinessProductListResponse | null> {
    return this.getIntermediaryBusinessProductListOperationPromise(params, configuration);
  }

  /**
   * 
   * @param {GetIntermediaryBusinessProductListRequest} getIntermediaryBusinessProductListRequest
   
   * @param {Configuration} configuration
   * @param {Function} callback
   * @throws {AsyncRequestError | RequiredError}
   */
  public async getIntermediaryBusinessProductListOperationSteps(params: getIntermediaryBusinessProductListOperationParams, configuration: Configuration, callback: (res: AxiosResponse<GetIntermediaryBusinessProductListResponse | null>) => void) {
    let done: boolean = false;
    const httpClient: AxiosInstance = EASDKConfig.getHttpClient('/V1/quoteAndBuy/getIntermediaryBusinessProductListOperation', 'getIntermediaryBusinessProductList', 'getIntermediaryBusinessProductListOperation');
    const api = new GetIntermediaryBusinessProductListApi(configuration, '', httpClient);
    const sleep = async(ms: number) => {
      return new Promise(resolve => setTimeout(resolve, ms));
    };
    const headers: { ['Async-Id']?: string } = {};
    while (!done) {
      const output = await api.getIntermediaryBusinessProductListOperation(params, { headers }) as AxiosResponse<GetIntermediaryBusinessProductListResponse | null>;
      callback(output);
      if (output.status === 202) {
        try {
          const retry = parseInt(output.headers['retry']);
          headers['Async-Id'] = output.headers['request-id'];
          if (isNaN(retry) || retry <= 0 || retry > 1000 * 3600) {
            throw new Error(`Retry time not acceptable (in milliseconds): ${retry}`);
          }
          await sleep(retry);
        } catch (err) {
          throw new Error(`Error in Async Request ${err}`);
        }
      } else {
        done = true;
      }
    }
  }

  /**
   * 
   * @param {GetIntermediaryBusinessProductListRequest} getIntermediaryBusinessProductListRequest
   
   * @param {Configuration} configuration
   * @throws {AsyncRequestError | RequiredError}
   * @returns {Promise<GetIntermediaryBusinessProductListResponse | null>}
   */
  public async getIntermediaryBusinessProductListOperationPromise(params: getIntermediaryBusinessProductListOperationParams, configuration: Configuration): Promise<GetIntermediaryBusinessProductListResponse | null> {
    return new Promise((resolve, reject) => {
      this.getIntermediaryBusinessProductListOperationSteps(params, configuration, (response: AxiosResponse<GetIntermediaryBusinessProductListResponse | null>) => {
        if (response.status !== 202) {
          resolve(response.data);
        }
      })
      .catch((err) => {
        reject(err);
      });
    });
  } 
}


