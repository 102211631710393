import {
  FileUploadModel
} from '@/utils/utils';

/** 
 * Model attach-documentation
 */
class AttachDocumentationModel {

  public documentType: string = '';

  public documentSubtype: string = '';

  public documentsToUpload: FileUploadModel[] = [];

}

export default AttachDocumentationModel;
