import {
  LabelValue
} from '../qb-producto-asegurado-comunidades/qb-producto-asegurado-comunidades-model';
import QbRiskSituationModel from '../qb-risk-situation/qb-risk-situation-model';
import QbTechnicalConsiderationsModel from '../qb-technical-considerations/qb-technical-considerations-model';

/** 
 * Model qb-producto-asegurado-tr-construccion-montaje
 */
class QbProductoAseguradoTrConstruccionMontajeModel {

  
  public warranty?: string = '';

  public bffCodesFrontRelation = [
    {
      bffCode: 'TCCANCO1',
      frontCode: 'quoteBuyGenericFlow.newOffer.paymentChannel'
    },
    {
      bffCode: 'FEEFTOMO',
      frontCode: 'quoteBuyGenericFlow.newOffer.efectMovementDate'
    },
    {
      bffCode: 'FEEFTONA',
      frontCode: 'quoteBuyGenericFlow.newOffer.efectMovementDate'
    },
    {
      bffCode: 'FEINIPOL',
      frontCode: 'quoteBuyGenericFlow.newOffer.efectMovementDate'
    },
    {
      bffCode: 'FEFINEFE',
      frontCode: 'quoteBuyGenericFlow.newOffer.movementDue'
    },
    {
      bffCode: 'FEVENCIM',
      frontCode: 'quoteBuyGenericFlow.newOffer.movementDue'
    },
    {
      bffCode: 'INIDIOMA',
      frontCode: 'quoteBuyGenericFlow.newOffer.documentationLanguage'
    },
    {
      bffCode: 'CDPAIS',
      frontCode: 'quoteBuyGenericFlow.propertyData.field.country'
    },
    {
      bffCode: 'CDACTVEL',
      frontCode: 'quoteBuyGenericFlow.technicalConsiderationsBusiness.field.constructionType'
    },
    {
      bffCode: 'CDCONTEC',
      frontCode: 'quoteBuyGenericFlow.technicalConsiderationsBusiness.field.technicalConsiderations'
    },
    {
      bffCode: 'DUOBRAMO',
      frontCode: 'quoteBuyGenericFlow.technicalConsiderationsBusiness.field.constructionTime'
    },
    {
      bffCode: 'NUPLASOB',
      frontCode: 'quoteBuyGenericFlow.technicalConsiderationsBusiness.field.numberBuildingPlant'
    },
    {
      bffCode: 'CDPAIS',
      frontCode: 'quoteBuyGenericFlow.propertyData.field.country'
    },
    {
      bffCode: 'CDPOST',
      frontCode: 'quoteBuyGenericFlow.searchPerson.field.zipCode'
    },
    {
      bffCode: 'NOPOBLSR',
      frontCode: 'quoteBuyGenericFlow.propertyData.field.city'
    },
    {
      bffCode: 'CDPOBLA',
      frontCode: 'quoteBuyGenericFlow.propertyData.field.city'
    },
    {
      bffCode: 'DSPOBTOT',
      frontCode: 'quoteBuyGenericFlow.propertyData.field.city'
    },
    {
      bffCode: 'TCPROVIN',
      frontCode: 'quoteBuyGenericFlow.propertyData.field.provinceName'
    },
    {
      bffCode: 'DSPROVIN',
      frontCode: 'quoteBuyGenericFlow.propertyData.field.provinceName'
    },
    {
      bffCode: 'CDDIRESR',
      frontCode: 'quoteBuyGenericFlow.propertyData.field.roadType'
    },
    {
      bffCode: 'NODIRESR',
      frontCode: 'quoteBuyGenericFlow.propertyData.field.roadName'
    },
    {
      bffCode: 'NUDIRESR',
      frontCode: 'quoteBuyGenericFlow.propertyData.field.roadNumber'
    },
    {
      bffCode: 'NUSOTANO',
      frontCode: 'quoteBuyGenericFlow.technicalConsiderationsBusiness.field.numberBasements'
    },
    {
      bffCode: 'CDPENTER',
      frontCode: 'quoteBuyGenericFlow.technicalConsiderationsBusiness.field.landSlope'
    },
    {
      bffCode: 'IMSADAOB',
      frontCode: 'quoteBuyGenericFlow.technicalConsiderationsBusiness.field.totalConstructionValue'
    },
    {
      bffCode: 'CDFQDAOB',
      frontCode: 'quoteBuyGenericFlow.technicalConsiderationsBusiness.field.franchise'
    },
    {
      bffCode: 'FEINIOBM',
      frontCode: 'quoteBuyGenericFlow.technicalConsiderationsBusiness.field.startDate'
    },
    {
      bffCode: 'FEFINOBM',
      frontCode: 'quoteBuyGenericFlow.technicalConsiderationsBusiness.field.endDate'
    },
    {
      bffCode: 'TXDSRIE1',
      frontCode: 'quoteBuyGenericFlow.technicalConsiderationsBusiness.field.workDescription'
    },
    {
      bffCode: 'CDDSTRAB',
      frontCode: 'quoteBuyGenericFlow.technicalConsiderationsBusiness.field.workDescription'
    },
    {
      bffCode: 'CDOBREMP',
      frontCode: 'quoteBuyGenericFlow.constructionMaterials.field.advancedWork'
    },
    {
      bffCode: 'INPANSAN',
      frontCode: 'quoteBuyGenericFlow.constructionMaterials.field.sandwichPanels'
    },
    {
      bffCode: 'INELEPRE',
      frontCode: 'quoteBuyGenericFlow.constructionMaterials.field.premadeOrWoodenElementsConstruction'
    },
    {
      bffCode: 'INOBRPAR',
      frontCode: 'quoteBuyGenericFlow.constructionMaterials.field.paralisedOrIncludedConstruction'
    },
  ];

  public technicalConsiderations: QbTechnicalConsiderationsModel = new QbTechnicalConsiderationsModel();

  public riskSituation: QbRiskSituationModel = new QbRiskSituationModel();

  public warrantyGroupList?: LabelValue[] = [];

}

export default QbProductoAseguradoTrConstruccionMontajeModel;
