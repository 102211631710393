<template>
  <div>
    <ea-row>
      <ea-col :span="12">
        <ea-paragraph
          size="medium"
          class="m-t-8">
          {{ $t('fleets.fleetsFlow.inputVehiclesData.groupInput.helpStep1') }}
        </ea-paragraph>
        <ea-paragraph
          size="medium"
          class="m-t-8">
          {{ $t('fleets.fleetsFlow.inputVehiclesData.groupInput.helpStep2') }}
        </ea-paragraph>
        <ea-paragraph
          size="medium"
          class="m-t-8">
          {{ $t('fleets.fleetsFlow.inputVehiclesData.groupInput.helpStep3') }}
        </ea-paragraph>
        <div>
          <ea-button type="secondary" icon="z-download" @click="onDownloadTemplateFile()"  class="m-t-16">
            {{ $t('fleets.fleetsFlow.inputVehiclesData.groupInput.downloadBtn') }}
          </ea-button>
        </div>
      </ea-col>
      <ea-col :span="12">
        <ea-upload
          v-model="files"
          :hasIcon="(!processingFiles && processedFile) ? true : false"
          :drag="true"
          :limit="filesLimit"
          :maxFileSize="fileMaxSize"
          accept=".xlsx"
          :disabled="disabledBase7Mode"
          @change="resetState"
          :description="$t(uploadDescriptionText)"
          :error="filesLimit === files.length ?
             $t('fleets.fleetsFlow.inputVehiclesData.groupInput.upload.errorFileLimit')
            :$t('fleets.fleetsFlow.inputVehiclesData.groupInput.upload.error')"
        >
        </ea-upload>
      </ea-col>

      
      <ea-col :span="24">
        <div class="m-t-24 d-display-flex d-justify-flex-end">
          <ea-button :disabled="!files.length || disabledBase7Mode" @click="processDocument">
              {{$t('fleets.fleetsFlow.inputVehiclesData.groupInput.addButtonText')}}
          </ea-button>
        </div>
      </ea-col>
    </ea-row>
  </div>
</template>

<script lang="ts">
import {
  Component, Prop
} from 'vue-property-decorator';

import {
  mixins
} from 'vue-class-component';

import {
  EAApplicationError,
  EAApplicationLogger,
  EABusinessComponent, EAErrorManager, EAFilesManager, EAMethod, ResponseWithErrors, throwIfResponseHasErrors
} from '@zurich-es-npm/ea-front-web-core';

import FleetGroupUploadVehiclesModel, {
  MappedExcelVehicleResponse,
  VehicleGroupValidations,
  vehicleExcelFields
} from './fleet-group-upload-vehicles-model';
import {
  Workbook, Worksheet, Row
} from 'exceljs';
import {
  EADownloadStaticFilesApi
} from '@/services/V1/common/downloadStaticFilesOperation/post';
import {
  EAFileListItem
} from '@zurich-es-npm/ea-front-web-ui';
import {
  VehicleFormModel,
  VehicleUseData
} from '../fleet-manual-upload-vehicle/fleet-manual-upload-vehicle-model';
import {
  RangeRestriction
} from '@/flows/fleets/model/range-restriction-model';
import {
  NotificationsUtils,
} from '@/utils/notifications/notifications-utils';
import {
  cloneDeep
} from 'lodash';
import {
  plateNumberValidations
} from '@/utils/plate-number-validations/plate-number-validations';
import {
  GetOfferListByRiskRequestSearchByPlateNumberTipoMatriculaEnum as TipoMatriculaEnum
} from '@/services/V1/search-offer/getOfferListByRiskOperation/post';
import FleetUnifiedExpirationModel from '../fleet-unified-expiration/fleet-unified-expiration-model';
import {
  ExtraPlateValidations
} from '@/utils/plate-number-validations/extra-plate-validations.utils';

@Component({
  name: 'fleet-group-upload-vehicles',
})

/**
 * Business Component fleet-group-upload-vehicles
 */
export default class FleetGroupUploadVehiclesBusiness extends mixins<
EABusinessComponent<FleetGroupUploadVehiclesModel>>(EABusinessComponent) {
  
  @Prop({
    required: true,
    'default': () => []
  })
    allVehicleUses!: VehicleUseData[];
  
  @Prop({})
    vehiclesNumberRange!: RangeRestriction;
  
  @Prop({})
    fleetUnifiedExpirationModel!: FleetUnifiedExpirationModel;

  @Prop()
    disabledBase7Mode!: boolean;

  files: EAFileListItem[] = [];

  filesLimit: number = 1;

  fileMaxSize: number = 500000;

  processingFiles: boolean = false;

  processedFile: boolean = false;

  worksheetDataName = 'Datos';

  /**
   * Returns upload Description Text
   */
  get uploadDescriptionText() {
    if (this.processingFiles && !this.processedFile) {
      return 'fleets.fleetsFlow.inputVehiclesData.groupInput.upload.processingText';

    } else if (!this.processingFiles && this.processedFile) {
      return 'fleets.fleetsFlow.inputVehiclesData.groupInput.upload.processedText';
    }
    return 'fleets.fleetsFlow.inputVehiclesData.groupInput.upload.description';
  }

  /**
   * Triggered when click on dowload template file button
   */
  async onDownloadTemplateFile() {
    // Call download static files endpoint to get 'fleets vehicles form' document and download it to the user
    const downloadResponse = await new EADownloadStaticFilesApi().downloadStaticFilesOperation({
      downloadStaticFilesRequest: {
        documentCodes: [{
          codigoDocumento: 'FleetVehicleForm'
        }]
      }
    });

    throwIfResponseHasErrors(downloadResponse as ResponseWithErrors);

    if (downloadResponse?.documentos?.length) {
      EAFilesManager.getInstance().downloadBase64File({
        fileName: downloadResponse.documentos[0].nombreDocumento,
        base64Data: downloadResponse.documentos[0].contenidoArchivo,
        mimeType: downloadResponse.documentos[0].tipoMimeArchivo
      });
    }
  }
  
  /**
   * Triggered when click on add button
   */
  @EAMethod()
  async processDocument() {
    NotificationsUtils.clearNotifications();
    EAErrorManager.clearError();

    this.processingFiles = true;

    if (this.files.length) {
      let workbook = new Workbook();
      const arrayBuffer = await this.files[0].raw.arrayBuffer();
      try {
        workbook = await workbook.xlsx.load(arrayBuffer);
      } catch (err) {
        const eaError = new EAApplicationError('ZON00215');
        new EAApplicationLogger().error(eaError);
        new EAApplicationLogger().error(`FleetGroupUploadVehiclesBusiness::processDocument: ${err.message}`);
        throw eaError;
      }
      const worksheet = workbook.getWorksheet(this.worksheetDataName);
      //Eliminamos la cabecera que es donde van los titulos
      worksheet.spliceRows(0, 1);
      if (worksheet.actualRowCount > this.vehiclesNumberRange.max) {
        const message = this.$t(`fleets.fleetsFlow.inputVehiclesData.groupInput.excedeedLimitedVehicle`,
          {
            max: this.vehiclesNumberRange.max
          }).toString();
        NotificationsUtils.throwWarning(message);
      } else {
        const processedWorksheet = await this.processWorksheet(worksheet);
        const vehiclesData = processedWorksheet.map(mappingExcelData => mappingExcelData.vehicleData);
        const duplicatedVehicles = this.getDuplicatePlateNumbers(vehiclesData);
        let uniqueVehicles = this.excludeDuplicatedVehicles(vehiclesData);
        uniqueVehicles = this.excludeAndNotifyVehiclesWithErrors(uniqueVehicles);
        const vehicleValidations = this.validateVehicles(uniqueVehicles);
        this.insertVehicles(uniqueVehicles);
        this.notifyVehicleValidations(vehicleValidations);
        this.notifyDuplicatedVehicles(duplicatedVehicles);
        
      }
    }
    this.processedFile = true;
    this.processingFiles = false;
  }
  
  /**
   * Process excell worksheet and return the data parsed
   * @returns {VehicleFormModel[]}
   * @param {object} worksheet
   */
  async processWorksheet(worksheet: Worksheet): Promise<MappedExcelVehicleResponse[]> {
    const vehiclesExcelData: MappedExcelVehicleResponse[] = [];
    
    worksheet.eachRow(row => {
      if (row.getCell(vehicleExcelFields.plateNumber).text) {
        vehiclesExcelData.push(this.mapVehicleData(row));
      }
    });
    //Worksheet.eachRow doesnt support asyncronous
    for (const excelVechicle of vehiclesExcelData) {
      const vehicleData = excelVechicle.vehicleData;
      const withoutSpaces = vehicleData.plateNumber?.split(' ').join('');
      vehicleData.plateNumber =
            await ExtraPlateValidations.formatPlate(withoutSpaces as string, vehicleData.plateNumberType as string);
    }
    return vehiclesExcelData;
  }

  /**
   * @returns {Set<string>}
   * @param {VehicleFormModel[]} vehiclesExcelData 
   */
  getDuplicatePlateNumbers(vehiclesExcelData: VehicleFormModel[]): Set<string> {
    const duplicatePlateNumbersSet = new Set<string>();
    const uniquePlateNumbersSet = new Set<string>();
    for (const vehicleData of vehiclesExcelData) {
      const {
        plateNumber
      } = vehicleData;

      if (uniquePlateNumbersSet.has(plateNumber as string)) {
        duplicatePlateNumbersSet.add(plateNumber as string);
      } else {
        uniquePlateNumbersSet.add(plateNumber as string);
      }
    }
    return duplicatePlateNumbersSet;
  }

  /**
   * Validates a vehicle plate and effective data and mark it as invalid or not
   * @param {VehicleFormModel []} vehicles 
   * @returns {VehicleGroupValidations}
   */
  validateVehicles(vehicles: VehicleFormModel []): VehicleGroupValidations [] {
    const vehiclesValidations: VehicleGroupValidations [] = [];
    vehicles.forEach(vehicle => {
      const isValidPlate = this.validateVehiclePlate(vehicle);
      const isEffectiveDateValid = this.validateEffectiveDate(vehicle);
      vehiclesValidations.push({
        isEffectiveDateValid,
        isValidPlate
      });
      vehicle.invalid = !isValidPlate || !isEffectiveDateValid;
    });

    return vehiclesValidations;
  }

  /**
   * Returns if the plate number is valid based on the plate type
   * @returns {boolean}
   * @param {VehicleFormModel} vehicle 
   */
  validateVehiclePlate(vehicle: VehicleFormModel): boolean {
    const plateShape = plateNumberValidations[vehicle.plateNumberType as TipoMatriculaEnum].pattern as RegExp;
    return plateShape.test(`${vehicle.plateNumber}`);
  }

  /**
   * Returns if the effective date is valid based on the effective date of the fleet
   * if it is not unified
   * @returns {boolean}
   * @param {VehicleFormModel} vehicle 
   */
  validateEffectiveDate(vehicle: VehicleFormModel): boolean {
    
    let isEffectiveDateValid = true;
    const effectiveVehicleDate = vehicle.effectiveDate as Date;
    if (!this.fleetUnifiedExpirationModel.formValues.hasUnifiedExpiration) {
      const endDate = cloneDeep(this.fleetUnifiedExpirationModel.formValues.notUnifiedExpirationDate as Date);
      endDate.setFullYear(endDate.getFullYear() + 1);
      endDate.setDate(endDate.getDate()-1);
      const expirationDate = this.fleetUnifiedExpirationModel.formValues.notUnifiedExpirationDate as Date;
      isEffectiveDateValid = effectiveVehicleDate >= expirationDate && effectiveVehicleDate < endDate;
    }
    return isEffectiveDateValid;
  }

  /**
   * Send warning messages for invalid vehicles
   * @param {VehicleGroupValidations []} vehicleValidations 
   */
  notifyVehicleValidations(vehicleValidations: VehicleGroupValidations []) {
    const effectiveDateWarning = vehicleValidations.some(vehicleValidation => !vehicleValidation.isEffectiveDateValid);
    const plateNumberWarning = vehicleValidations.some(vehicleValidation => !vehicleValidation.isValidPlate);
    if (effectiveDateWarning || plateNumberWarning) {
      const message =
      this.$t(`fleets.fleetsFlow.inputVehiclesData.groupInput.warningValidations.wrongVehiclesDataText`)
        .toString();
      NotificationsUtils.throwWarning(message);
    }
  }

  /**
   * Maps the excel data row to an usable object
   * @param {Row} excelDataRowValues 
   * @returns {MappedExcelVehicleResponse}
   */
  mapVehicleData(excelDataRowValues: Row): MappedExcelVehicleResponse {
    const mappedVehicleInfo: MappedExcelVehicleResponse = {
      vehicleData: {
        vehicleBrand: '',
        vehicleModel: '',
        vehicleVersion: ''
      },
      mappingErrors: []
    };
    const plateTypeName = this.model.plateTypeSearchList
      // eslint-disable-next-line max-len
      .find(plateType => plateType.code === `${excelDataRowValues.getCell(vehicleExcelFields.plateNumberType).text}`)?.name || '';
    const vehicleUseName = this.allVehicleUses
      // eslint-disable-next-line max-len
      .find(use => use.value === `${excelDataRowValues.getCell(vehicleExcelFields.vehicleUseId).text}`)?.name || '';
    mappedVehicleInfo.vehicleData.plateNumberType =
    `${excelDataRowValues.getCell(vehicleExcelFields.plateNumberType).text}`;
    mappedVehicleInfo.vehicleData.plateTypeName = plateTypeName;
    mappedVehicleInfo.vehicleData.plateNumber = `${excelDataRowValues.getCell(vehicleExcelFields.plateNumber).text}`;
    mappedVehicleInfo.vehicleData.vehicleUseName = vehicleUseName;
    // eslint-disable-next-line max-len
    mappedVehicleInfo.vehicleData.effectiveDate =
    excelDataRowValues.getCell(vehicleExcelFields.effectiveDate).text ?
      new Date(excelDataRowValues.getCell(vehicleExcelFields.effectiveDate).text) : new Date();
    
    mappedVehicleInfo.vehicleData.plateUseId = excelDataRowValues.getCell(vehicleExcelFields.vehicleUseId).text;
    // eslint-disable-next-line max-len
    mappedVehicleInfo.vehicleData.accessoriesValue = +excelDataRowValues.getCell(vehicleExcelFields.accessoriesValue).text;
    mappedVehicleInfo.vehicleData.policyNumber = excelDataRowValues.getCell(vehicleExcelFields.policyNumber).text;

    return mappedVehicleInfo;
  }

  /**
   * Returns array of vehicles without duplicateds
   * @param {VehicleFormModel []} vehiclesData 
   * @returns {VehicleFormModel []}
   */
  excludeDuplicatedVehicles(vehiclesData: VehicleFormModel []): VehicleFormModel [] {

    const uniqueVehicles: VehicleFormModel[] = [];
    const uniquePlates: { [key: string]: boolean } = {};

    for (const vehicle of vehiclesData) {
      const plateNumber = vehicle.plateNumber as string;
      if (!uniquePlates[plateNumber]) {
        uniquePlates[plateNumber] = true;
        uniqueVehicles.push(vehicle);
      }
    }
    return uniqueVehicles;
  }

  /**
   * Creates and sends notifications to the message service as warining
   * for each duplicated plate
   * @param {Set<string>} duplicatedPlates 
   */
  notifyDuplicatedVehicles(duplicatedPlates: Set<string>) {
    duplicatedPlates.forEach(duplicatedPlate => {
      const message =
        this.$t(`fleets.fleetsFlow.inputVehiclesData.groupInput.warningValidations.duplicatedPlatesExcel`, {
          duplicatedPlate: duplicatedPlate.toUpperCase()
        })
          .toString();
      NotificationsUtils.throwWarning(message);
    });
  }

  /**
   * Returns array of vehicles without errors
   * @param {VehicleFormModel []} vehiclesData 
   * @returns {VehicleFormModel []}
   */
  excludeAndNotifyVehiclesWithErrors(vehiclesData: VehicleFormModel []): VehicleFormModel [] {
    const validVehicles: VehicleFormModel[] = [];
    for (const vehicle of vehiclesData) {
      //If use id is empty do not add it and show
      if (vehicle.plateUseId) {
        validVehicles.push(vehicle);
      } else {
        const message =
          this.$t(`fleets.fleetsFlow.inputVehiclesData.groupInput.warningValidations.emptyUseDataExcel`, {
            plateNumber: vehicle.plateNumber?.toUpperCase()
          }).toString();
        NotificationsUtils.throwWarning(message);
      }
    }
    return validVehicles;
  }

  /**
   * Triggered when formModel (vehicleData) is prepared to be inserted
   * 
   * @param {VehicleFormModel[]} vehicles
   */
  insertVehicles(vehicles: VehicleFormModel[]) {
    this.$emit('onInsertVehicle', vehicles);
  }

  /**
   * Reset uploader state 
   */
  resetState() {
    this.processedFile = false;
    this.processingFiles = false;
  }

}
</script>
