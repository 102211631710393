<template>
  <div>
    <ea-row>
      <ea-col>
        <ea-form
          ref="refCommitContractForm"
          :model="model.formValues"
          :validationOptions="formValidations"
        >
          <ea-form-item
            :label="$t('fleets.fleetsFlow.commit-contract.title')"
            prop="selectedValues"
            :required="!readOnlyOperation"
          >
            <ea-row>
              <ea-col :span="24">
                <ea-paragraph size="medium">
                  {{ $t('fleets.fleetsFlow.commit-contract.description') }}
                </ea-paragraph>
              </ea-col>
            </ea-row>

            <ea-row>
              <ea-col :span="24">
                <ea-checkbox-group
                  @change="checkBoxChanged"
                  v-model="model.formValues.selectedValues"
                  direction="horizontal">
                  <ea-checkbox
                    v-for="(element, index) in elementList"
                    :key="index"
                    :label="element.label"
                    :value="element.value"
                    :readonly="readOnlyOperation"
                    :class="index !== 0 ? 'm-l-8' : ''" />
                </ea-checkbox-group>
              </ea-col>
            </ea-row>

          </ea-form-item>
        </ea-form>
      </ea-col>
    </ea-row>
  </div>
</template>

<script lang="ts">
import {
  Component, Prop
} from 'vue-property-decorator';

import {
  mixins
} from 'vue-class-component';

import {
  EABusinessComponent
} from '@zurich-es-npm/ea-front-web-core';

import FleetCommitContractModel from './fleet-commit-contract-model';
import {
  ParsedTableData
} from '@/utils/corporate-tables';
import {
  Form,
  EAFormValidationOptions,
  EAValidationTriggers,
  EAValidation,
  eaCustomValidation,
  EAValidationTypes,
  eaRequiredValidation
} from '@zurich-es-npm/ea-front-web-ui';
import {
  EAValidatorFunction
} from '@zurich-es-npm/ea-front-web-core/lib/validation-rules';

@Component({
  name: 'fleet-commit-contract'
})

/**
 * Business Component fleet-commit-contract
 */
export default class FleetCommitContractBusiness extends mixins<
EABusinessComponent<FleetCommitContractModel>>(EABusinessComponent) {

  @Prop({
    required: true
  })
    elementList!: ParsedTableData[];

  @Prop()
    readOnlyOperation?: boolean;

  /**
   * Getter for validation options
   * @returns { EAFormValidationOptions }
   */
  formValidations: EAFormValidationOptions = {

    rules: {}

  };

  /**
   * Hook on created
   */
  created() {
    this.formValidations.rules.selectedValues = [
      eaRequiredValidation(
        'common.label.validation.fieldRequired',
        EAValidationTriggers.CHANGE,
        EAValidationTypes.ARRAY
      ),
      eaCustomValidation(
        this.selectedValueValidation,
        'common.label.validation.fieldRequired',
        EAValidationTriggers.CHANGE,
        EAValidationTypes.ARRAY
      )
    ];
  }

  /**
   * Validates if commit contract is selected
   *
   * @param {any} _rule
   * @param {any} _value
   * @param {Function} callback
   * @returns {EAValidatorFunction}
   */
  selectedValueValidation(_rule: any, _value: any, callback: Function): EAValidatorFunction {
    if (!this.model.formValues.selectedValues.length) {
      return callback(new Error());
    }
    return callback();
  }

  /**
   * Save and emit selections
   *
   * @param {string} value
   */
  checkBoxChanged() {
    this.$emit('selectedCommitContractValuesChange', this.model.formValues.selectedValues);
  }

  /**
   * @description: method to validate form in parent component
   * @returns {EAValidation}
   */
  validation(): EAValidation {
    const fleetCommitContractForm: Form = this.$refs.refCommitContractForm as Form;
    return fleetCommitContractForm.validation();
  }
}
</script>
