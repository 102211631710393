import {
  GetWarrantiesResponseDataCadenaLanzamiento,
  GetWarrantiesResponseDataDatosGarantias,
  GetWarrantiesResponseDataLanzadorPorValor,
  GetWarrantiesResponseDataLanzadorPorValorCondicionLanzamientoRestriccionGarantiaEnum,
} from '@/services/V1/quoteAndBuy/getWarrantiesOperation/post';
import Utils from '@/utils/utils';
import {
  ActionType,
  EventChange,
  TriggerType,
  TriggerList,
  WarrantyElement,
  WarrantyAction,
  ValueType
} from './warranty.types';

/**
 * Get Trigger List method
 * Trigger list can be informed on: 
 *  - triggerByCheck field: For warranties checkboxes changes
 *  - triggerByValue field: For elements value changes
 * 
 * @param {TriggerType} trigger 
 * @returns {TriggerList}
 */
export const getTriggerList = (trigger: TriggerType): TriggerList[] | undefined => {
  if (trigger.triggerByCheck) {
    return trigger.triggerByCheck;
  } else if (trigger.triggerByValue) {
    return trigger.triggerByValue;
  }
};

/**
 * Return trigger list based on current element value
 * 
 * @param {TriggerList[]} triggerList
 * @param {ValueType} value
 * @returns {TriggerList[]}
 */
export const filterTriggerList = (
  triggerList: TriggerList[],
  value: ValueType
): TriggerList[] => triggerList.filter(trigger => {
  if (
    trigger.condicionLanzamientoRestriccionGarantia ===
      GetWarrantiesResponseDataLanzadorPorValorCondicionLanzamientoRestriccionGarantiaEnum.Equal
  ) {
    return trigger.condicionLanzamientoValorElemento?.toString() === value.toString();
  }

  return trigger.condicionLanzamientoValorElemento?.toString() !== value.toString();
});


/**
 * Return action type and value based on trigger elements
 * 
 * @param {GetWarrantiesResponseDataCadenaLanzamiento} trigger
 * @param {ValueType} currentElementValue
 * 
 * @returns {WarrantyAction}
 */
export const getActionTypeAndValueToEmit = (
  trigger: GetWarrantiesResponseDataCadenaLanzamiento,
  currentElementValue: ValueType
): WarrantyAction => {

  if (trigger.elementoVisible !== undefined) {
    return {
      type: ActionType.CHANGE_HIDDEN,
      value: trigger.elementoVisible
    };
  }
  
  if (trigger.elementoModificable !== undefined) {
    return {
      type: ActionType.CHANGE_READONLY,
      value: trigger.elementoModificable
    };
  }

  if (trigger.copiaValor !== undefined) {
    let value;
    if (trigger.copiaValor.codigoPropuesta === 'All') {
      value = currentElementValue;
    } else {
      value = {
        warrantyCode: trigger.copiaValor.codigoGarantia,
        proposalCode: trigger.copiaValor.codigoPropuesta,
        elementCode: trigger.copiaValor.codigoElemento
      };
    }
    return {
      type: ActionType.COPY_VALUE,
      value: value
    };
  }

  if (trigger.valorElemento !== undefined) {
    return {
      type: ActionType.CHANGE_VALUE,
      value: trigger.valorElemento
    };
  }

  return {
    type: ActionType.NO_ACTION
  };
};

/**
 * Checks if "condicionLanzamientoValorAnteriorElemento" requirements are met
 *  only if value has changed from previous one
 * @param {GetWarrantiesResponseDataLanzadorPorValor} trigger
 * @param {string} value
 * @param {ValueType | undefined} previousValue
 * @returns {boolean}
 */
export const checkTriggerMeetsPreviousValueConditions = (
  trigger: GetWarrantiesResponseDataLanzadorPorValor,
  value: ValueType,
  previousValue?: ValueType
): boolean => {
  const condicionLanzamientoValorAnterior = trigger.condicionLanzamientoValorAnteriorElemento;

  if (!condicionLanzamientoValorAnterior) {
    return true;
  }

  if (value === previousValue) {
    return false;
  }

  if (condicionLanzamientoValorAnterior.condicionLanzamientoRestriccionGarantia === 'Equal') {
    return previousValue === condicionLanzamientoValorAnterior.condicionLanzamientoValorElemento;
  }

  return previousValue !== condicionLanzamientoValorAnterior.condicionLanzamientoValorElemento;
};

/**
 * Gets third condition requirements are met
 * @param {GetWarrantiesResponseDataCadenaLanzamiento} trigger
 * @param {GetWarrantiesResponseDataDatosGarantias[]} warrantiesData 
 * @returns {boolean}
 */
export const checkThirdPartyCondition = (
  trigger: GetWarrantiesResponseDataCadenaLanzamiento,
  warrantiesData: GetWarrantiesResponseDataDatosGarantias[]
): boolean => {
  const warrantyToCheck = warrantiesData?.find(
    warranty => warranty.codigoGarantia === trigger.copiaValor?.condicionLanzamientoElemento?.codigoGarantia
  )?.elementosGarantias?.find(
    elementoGarantia => elementoGarantia.codigoElemento ===
      trigger.copiaValor?.condicionLanzamientoElemento?.codigoElemento
  )?.datosPropuestas?.find(
    elementoPropuesta => elementoPropuesta.codigoPropuesta ===
      trigger.copiaValor?.condicionLanzamientoElemento?.codigoPropuesta
  );
  
  if (!warrantyToCheck) {
    return false;
  }

  if (trigger.copiaValor?.condicionLanzamientoRestriccionGarantia === 'Equal') {
    return warrantyToCheck.valorElemento === trigger.copiaValor?.condicionLanzamientoValorElemento;
  }

  return warrantyToCheck.valorElemento !== trigger.copiaValor?.condicionLanzamientoValorElemento;
};


/**
 * Return a standard actions list 
 * 
 * @param {WarrantyElement} warrantyElement 
 * @param {GetWarrantiesResponseDataCadenaLanzamiento[]} triggerList 
 * @param {ValueType} currentElementValue
 * @param {GetWarrantiesResponseDataDatosGarantias[]} warrantiesData 
 * @returns {EventChange[]}
 */
export const getActions = (
  warrantyElement: WarrantyElement,
  triggerList: GetWarrantiesResponseDataCadenaLanzamiento[],
  currentElementValue: ValueType,
  warrantiesData: GetWarrantiesResponseDataDatosGarantias[]
): EventChange[] => {
  const actions = triggerList.map(trigger => {

    if (trigger?.copiaValor?.codigoPropuesta === 'Same') {
      trigger.copiaValor.codigoPropuesta = warrantyElement.proposalCode;
    }

    if (trigger?.copiaValor?.condicionLanzamientoElemento?.codigoPropuesta === 'Same') {
      trigger.copiaValor.condicionLanzamientoElemento.codigoPropuesta = warrantyElement.proposalCode;
    }

    // Checks if action requires another warranty to meet certain condition
    const existsThirdPartyCondition = !!trigger?.copiaValor?.condicionLanzamientoElemento;
    if (existsThirdPartyCondition && !checkThirdPartyCondition(trigger, warrantiesData)) {
      return null;
    }

    return {
      emitter: {
        warrantyCode: warrantyElement.warrantyCode,
        proposalCode: warrantyElement.proposalCode,
        elementCode: warrantyElement.elementCode,
      },
      receiver: {
        warrantyCode: trigger.codigoGarantia || '',
        proposalCode: trigger?.copiaValor?.codigoPropuesta === 'All'
          ? trigger?.copiaValor?.codigoPropuesta : warrantyElement.proposalCode,
        elementCode: trigger.codigoElemento,
      },
      action: getActionTypeAndValueToEmit(trigger, currentElementValue)
    };
  });

  return actions.filter(action => !!action) as EventChange[];
};

/**
 * Return a standard Action List for different changes (value/check)
 * 
 * @param {WarrantyElement} emitterElement 
 * @param {boolean | string} value 
 * @param {TriggerType} trigger 
 * @param {GetWarrantiesResponseDataDatosGarantias[]} warrantiesData 
 * @param {string | undefined} previousValue 
 * @returns {EventChange[]}
 */
export const getActionsByWarrantyChange = (
  emitterElement: WarrantyElement,
  value: ValueType,
  trigger: TriggerType,
  warrantiesData: GetWarrantiesResponseDataDatosGarantias[],
  previousValue?: string
): EventChange[] => {
  const triggerList = getTriggerList(trigger);

  const filteredTriggerList = triggerList && filterTriggerList(triggerList, value);

  if (!filteredTriggerList) {
    return [];
  }

  const actions = [];

  for (const filteredTrigger of filteredTriggerList) {
    const meetsPreviousValueConditions = checkTriggerMeetsPreviousValueConditions(
      filteredTrigger, value, previousValue
    );
    if (filteredTrigger.cadenaLanzamiento && meetsPreviousValueConditions) {
      actions.push(
        getActions(emitterElement, filteredTrigger.cadenaLanzamiento, value, warrantiesData)
      );
    }
  }

  return Utils.flatten(actions);
};
