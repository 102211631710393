<!-- eslint-disable lines-around-comment -->
<template>
  <ea-row class="m-b-16">
    <ea-col :span="headerSpan">
      <ea-heading level="3">
        {{ title }}
      </ea-heading>
      <ea-heading level="6">
        {{ subtitle }}
      </ea-heading>
    </ea-col>

    <ea-col v-if="showSteps && steps" :span="10">
      <ea-steps
        direction="horizontal"
        :active="activeStep"
        class="w-100"
        :finish-status="finishStatus"
        item-background-color="gray-05"
      >
        <ea-step v-for="step in steps.filter((x) => x.title)" :key="step.title" :title="step.title" />
      </ea-steps>
    </ea-col>
    <ea-col v-if="showSteps && steps" :span="7"  class="d-display-flex d-justify-flex-end">

      <ea-tooltip
        placement="top"
        class="m-l-8"
        v-if="showDocumentationButton"
        :content="$t('quoteBuyGenericFlow.header.createConsultDocuments')">
        <ea-link @click.native="documents">
          {{$t('quoteBuyGenericFlow.header.documents')}}
        </ea-link>
      </ea-tooltip>

      <ea-tooltip placement="top" class="m-l-8" v-if="showQueryNoteButton"
        :content="getNotesTooltip">
        <ea-link
          @click.native="notes">
          {{$t('quoteBuyGenericFlow.header.notes')}}
        </ea-link>
      </ea-tooltip>

    </ea-col>
  </ea-row>
</template>

<script lang="ts">
import {
  Component, Prop
} from 'vue-property-decorator';

import {
  mixins
} from 'vue-class-component';

import {
  EABusinessComponent, EAFlowNavigationUtils, EAMethod, EAView
} from '@zurich-es-npm/ea-front-web-core';

import QbHeaderModel, {
  stepStatus
} from './qb-header-model';

import {
  FlowViewsStepsModel, PolicyMovementType
} from '@/flows/quote-buy/quote-buy-model';
import {
  CoexistenceId
} from '../../types/coexistence/coexistence-enum.types';
import {
  SubflowThunderNameEnum
} from '@/types/flow/flow-enum.types';
import PermissionUtils from '@/utils/permissions-utils';
import {
  GetDefaultUserValuesResponseCodigoTipoUsuarioEnum as UserTypesEnum
} from '@/services/V1/home/getDefaultUserValuesOperation/post';
import {
  NotificationsUtils
} from '@/utils/notifications/notifications-utils';

@Component({
  name: 'qb-header',
})

/**
 * Q&B Header Business Component
 */
export default class QbHeaderBusiness extends mixins<EABusinessComponent<QbHeaderModel>>(EABusinessComponent) {
  @Prop({
    required: true,
    'default': () => '<DEFAULT_FLOW_TITLE>',
  })
    title?: string;

  @Prop({
    'default': () => '',
  })
    subtitle?: string;

  @Prop({
    'default': false,
  })
    showSteps?: boolean;

  @Prop({
    'default': () => [],
  })
    steps?: {
    step: FlowViewsStepsModel;
    title?: string;
    component: typeof EAView;
  }[];

  @Prop({
    'default': () => -1,
  })
    activeStep?: number;

  @Prop({
    'default': () => 'success',
  })
    finishStatus?: stepStatus;

  @Prop()
    offerNumber!: string;

  @Prop()
    offerVersion!: number;

  @Prop()
    codigoTipoMovimiento?: string;

  @Prop({
    required: false,
    'default': () => false
  })
    hasTestAbility?: boolean;

  @Prop(
    {
      required: true,
      'default': () => ''
    }
  )
    parentFlowId!: string;
  
  @Prop({
    required: false,
    'default': () => true
  })
    showCreateNoteButton!: boolean;
  
  @Prop({
    required: false,
    'default': () => true
  })
    showQueryNoteButton!: boolean;
  
  @Prop({
    required: false,
    'default': () => true
  })
    showDocumentationButton!: boolean;

  @Prop({
    'default': 7
  })
    headerSpan?: number;

  employeeUser: boolean = false;


  /**
   * Get tooltip text
   * @returns {string} 
   */
  get getNotesTooltip(): string {
    return this.employeeUser ?
      this.$t('quoteBuyGenericFlow.header.tooltip.consultNotes').toString() :
      this.$t('quoteBuyGenericFlow.header.tooltip.consultNewNotes').toString();
  }

  /**
   * Hook on created
   */
  created() {
    const userType = PermissionUtils.getUserType();
    this.employeeUser = PermissionUtils.isUserOfType(userType, UserTypesEnum.EMP);
  }

  /**
   * @returns {string}
   */
  private getCodigoTipoMovimiento(): string {
  // TODO falta implementar más tipos de movimientos que haremos más adelante
    if (
      this.codigoTipoMovimiento &&
      (this.codigoTipoMovimiento === PolicyMovementType.Incompleta ||
        this.codigoTipoMovimiento === PolicyMovementType.Oferta)
    ) {
      return 'OWCI';
    }
    return 'PWCI';
  }

  /**
   * @description send to user to pivot coexitencia page for create new note
   */
  @EAMethod()
  newNote() {
    EAFlowNavigationUtils.navigate(this, 'ZonaZurichWithParentFlowId', {
      operationId: CoexistenceId.AltaNotas,
      operationParams: JSON.stringify({
        cdagruta: this.getCodigoTipoMovimiento(),
        cdnumpol: this.offerNumber,
        ctvrspol: this.offerVersion,
      }),
      parentFlowId: this.parentFlowId
    });
  }

  /**
   * @description end to user to pivot coexitencia page for create/ediot documents
   */
  @EAMethod()
  notes() {
    this.clearFlowErrors();
    NotificationsUtils.clearNotifications();
    this.$emit('openSubflow', SubflowThunderNameEnum.NotesFlow);
  }

  /**
   * @description emits to the view to open subflow
   */
  @EAMethod()
  documents(): void {
    this.clearFlowErrors();
    NotificationsUtils.clearNotifications();
    this.$emit('openSubflow', SubflowThunderNameEnum.DocumentationFlow);
  }
}
</script>
