import PostCodeLocationInputModel from '../post-code-location-input/post-code-location-input-model';

/**
 * Model qb-show-person-info
 */
class QbShowPersonInfoModel {
  documentType: string = '';

  documentNumber: string = '';

  firstName: string = '';

  lastName: string = '';

  lastName2: string = '';

  businessName: string = '';

  gender: string = '';

  birthDate: string | Date = '';

  country: string = '';

  taxTreatmentArea: string = '';

  postalCodeModel: PostCodeLocationInputModel = new PostCodeLocationInputModel();
}

export default QbShowPersonInfoModel;
