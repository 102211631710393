<template>
  <div>
    <div>
      <qb-new-offer
      id="new-offer-communities-qb"
      v-model="model.qbNewOfferBusinessModel"
      :canCreateOffer="false"
      :codigoRamo="model.branchCode"
      @dataSelected="dataSelected"
    ></qb-new-offer>
    </div>
  </div>
</template>

<script lang="ts">
import {
  Component
} from 'vue-property-decorator';

import {
  mixins
} from 'vue-class-component';

import {
  EAView,
  EAMethod
} from '@zurich-es-npm/ea-front-web-core';

import {
  FleetsModel, FlowHeaderStepsModel, FlowViewsStepsModel
} from '../fleets-model';

import QBNewOfferBusiness from '@/business-components/qb-new-offer/qb-new-offer-business.vue';
    
@Component({
  components: {
    QbNewOffer: QBNewOfferBusiness
  }
})

/**
 * New offer view (Fleets)
 *
 */
export default class NewOfferView extends mixins<EAView<FleetsModel>>(EAView) {

  /**
   * Go to next view.
   */
  @EAMethod()
  dataSelected() {
    this.update();
    this.$emit('changeStep', FlowViewsStepsModel.FleetDataStep, FlowHeaderStepsModel.FleetDataStep);
  }
}
</script>
