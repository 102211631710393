export interface GenerateDocumentationViewLabelsModel {
  proposalSelectionTitleLabel: string;
  proposalSelectionSubitleLabel: string;
  proposalSelectionSubtitleData: string;
}

export interface WarrantiesProposal {
  proposalNumber: string;
  proposalIndex: number;
  rate: string | number;
  contentRate: string | number;
  continentRate: string | number;
  disabled: boolean;
  checked: boolean;
}

/** 
 * Model qb-documentation-select-proposal
 */
class QbDocumentationSelectProposalModel {

  public generateDocumentationViewLabels: GenerateDocumentationViewLabelsModel = {
    proposalSelectionTitleLabel: '',
    proposalSelectionSubitleLabel: '',
    proposalSelectionSubtitleData: ''
  };

  public warrantiesProposals: WarrantiesProposal[] = [];

}

export default QbDocumentationSelectProposalModel;
