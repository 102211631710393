export interface FormValues {
  constructionType: string;
  constructionTime?: number;
  technicalConsiderations?: string;
  numberBuildingPlant?: number;
  numberBasements?: number;
  landSlope: string;
  totalConstructionValue?: number;
  franchise: string;
  workDescription?: string;
  startDate?: Date;
  endDate?: Date;
  advancedWork?: string;
  workDetailedDescription?: string;
  paralisedOrIncludedConstruction?: boolean;
  sandwichPanels?: boolean;
  premadeOrWoodenElementsConstruction?: boolean;
}

/**
 * Model qb-technical-considerations
 */
class QbTechnicalConsiderationsModel {
  public FIELD_TYPES = {
    NUMBER: 'number',
    STRING: 'string',
    DEFAULT: 'default',
    DATE: 'date',
  };

  public formValues: FormValues = {
    constructionType: '',
    constructionTime: undefined,
    technicalConsiderations: '',
    numberBuildingPlant: undefined,
    numberBasements: undefined,
    landSlope: '',
    totalConstructionValue: undefined,
    franchise: '',
    workDescription: '',
    workDetailedDescription: '',
    startDate: undefined,
    endDate: undefined,
    advancedWork: '',
    paralisedOrIncludedConstruction: false,
    sandwichPanels: false,
    premadeOrWoodenElementsConstruction: false,
  };
}

export default QbTechnicalConsiderationsModel;
