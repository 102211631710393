/* eslint-disable */

/**
 * zonazurich
 * Portal de Mediación Digital
 *
 * The version of the OpenAPI document: 1
 * Contact: digitalarchitecture@zurich.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import {
  EASDKConfig,
  EAUrlUtils
} from '@zurich-es-npm/ea-shared-utils-js';

import * as globalImportUrl from 'url';
import {
  Configuration
} from './configuration';
import globalAxios, {
  AxiosPromise,
  AxiosInstance,
  AxiosResponse
} from 'axios';

const BASE_PATH = EAUrlUtils.removeLastForwardSlash('http://localhost:3000');

/**
 *
 * @export
 */
export const COLLECTION_FORMATS = {
  csv: ',',
  ssv: ' ',
  tsv: '\t',
  pipes: '|',
};

/**
 *  
 * @export
 * @interface RequestArgs
 */
export interface RequestArgs {
  url: string;
  options: any;
}

/**
 * 
 * @export
 * @class BaseAPI
 */
export class BaseAPI {
  protected configuration: Configuration | undefined;

  /**
   *Creates an instance of BaseAPI.
   * @param {Configuration} [configuration]
   * @param {string} [basePath=BASE_PATH]
   * @param {AxiosInstance} [axios=globalAxios]
   * @memberof BaseAPI
   */
  public constructor(
    configuration?: Configuration,
    protected basePath: string = BASE_PATH,
    protected axios: AxiosInstance = globalAxios) {
    if (configuration) {
      this.configuration = configuration;
      this.basePath = configuration.basePath || this.basePath;
    }
  }
}

/**
 * 
 * @export
 * @class RequiredError
 * @extends {Error}
 */
export class RequiredError extends Error {
  public name: 'RequiredError' = 'RequiredError';

  public field: string;

  /**
   *Creates an instance of RequiredError.
   * @param {string} field
   * @param {string} [msg]
   * @memberof RequiredError
   */
  public constructor(field: string, msg?: string) {
    super(msg);
    this.field=field;
  }
}

/**
 * 
 * @export
 * @class AsyncRequestError
 * @extends {Error}
 */
export class AsyncRequestError extends Error {
  public name: 'AsyncRequestError' = 'AsyncRequestError';

  /**
   *Creates an instance of AsyncRequestError.
   * @param {string} field
   * @param {string} [msg]
   * @memberof AsyncRequestError
   */
  public constructor(msg?: string) {
    super(msg);
  }
}

/**
 * 
 * @export
 * @interface Error
 */
export interface Error {
    /**
     * 
     * @type {string}
     * @memberof Error
     */
    code: string;
    /**
     * 
     * @type {string}
     * @memberof Error
     */
    message: string;
    /**
     * 
     * @type {number}
     * @memberof Error
     */
    severity: number;
    /**
     * 
     * @type {Array<object>}
     * @memberof Error
     */
    childErrors?: Array<object>;
}/**
 * 
 * @export
 * @interface GetIssueOfferDataRequest
 */
export interface GetIssueOfferDataRequest {
    /**
     * 
     * @type {string}
     * @memberof GetIssueOfferDataRequest
     */
    codigoPoliza: string;
    /**
     * 
     * @type {number}
     * @memberof GetIssueOfferDataRequest
     */
    versionPoliza: number;
    /**
     * 
     * @type {string}
     * @memberof GetIssueOfferDataRequest
     */
    tipoPoliza: GetIssueOfferDataRequestTipoPolizaEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum GetIssueOfferDataRequestTipoPolizaEnum {
    Oferta = 'Oferta',
    Pliza = 'Póliza',
    Incompleta = 'Incompleta'
}
/**
 * 
 * @export
 * @interface GetIssueOfferDataResponse
 */
export interface GetIssueOfferDataResponse {
    /**
     * 
     * @type {Array<GetIssueOfferDataResponseListaDocumentos>}
     * @memberof GetIssueOfferDataResponse
     */
    listaDocumentos?: Array<GetIssueOfferDataResponseListaDocumentos>;
    /**
     * 
     * @type {GetIssueOfferDataResponseDireccionIntermediarioProductor}
     * @memberof GetIssueOfferDataResponse
     */
    direccionIntermediarioProductor?: GetIssueOfferDataResponseDireccionIntermediarioProductor;
    /**
     * 
     * @type {GetIssueOfferDataResponseDatosCabecera}
     * @memberof GetIssueOfferDataResponse
     */
    datosCabecera?: GetIssueOfferDataResponseDatosCabecera;
    /**
     * 
     * @type {Array<Error>}
     * @memberof GetIssueOfferDataResponse
     */
    errors?: Array<Error>;
}/**
 * 
 * @export
 * @interface GetIssueOfferDataResponseDatosCabecera
 */
export interface GetIssueOfferDataResponseDatosCabecera {
    /**
     * 
     * @type {string}
     * @memberof GetIssueOfferDataResponseDatosCabecera
     */
    fechaVencimiento?: string;
}/**
 * 
 * @export
 * @interface GetIssueOfferDataResponseDireccionIntermediarioProductor
 */
export interface GetIssueOfferDataResponseDireccionIntermediarioProductor {
    /**
     * 
     * @type {string}
     * @memberof GetIssueOfferDataResponseDireccionIntermediarioProductor
     */
    codigoFiliacion?: string;
    /**
     * 
     * @type {string}
     * @memberof GetIssueOfferDataResponseDireccionIntermediarioProductor
     */
    codigoSecuencialDomicilio?: string;
    /**
     * 
     * @type {string}
     * @memberof GetIssueOfferDataResponseDireccionIntermediarioProductor
     */
    tipoViaDireccion?: string;
    /**
     * 
     * @type {string}
     * @memberof GetIssueOfferDataResponseDireccionIntermediarioProductor
     */
    numeroDireccion?: string;
    /**
     * 
     * @type {string}
     * @memberof GetIssueOfferDataResponseDireccionIntermediarioProductor
     */
    nombrePoblacion?: string;
    /**
     * 
     * @type {string}
     * @memberof GetIssueOfferDataResponseDireccionIntermediarioProductor
     */
    nombreProvincia?: string;
    /**
     * 
     * @type {string}
     * @memberof GetIssueOfferDataResponseDireccionIntermediarioProductor
     */
    nombreDireccion?: string;
    /**
     * 
     * @type {string}
     * @memberof GetIssueOfferDataResponseDireccionIntermediarioProductor
     */
    informacionAdicional?: string;
    /**
     * 
     * @type {string}
     * @memberof GetIssueOfferDataResponseDireccionIntermediarioProductor
     */
    codigoPais?: string;
    /**
     * 
     * @type {string}
     * @memberof GetIssueOfferDataResponseDireccionIntermediarioProductor
     */
    codigoPostalConLetras?: string;
}/**
 * 
 * @export
 * @interface GetIssueOfferDataResponseListaDocumentos
 */
export interface GetIssueOfferDataResponseListaDocumentos {
    /**
     * 
     * @type {string}
     * @memberof GetIssueOfferDataResponseListaDocumentos
     */
    nombreDocumento?: string;
    /**
     * 
     * @type {string}
     * @memberof GetIssueOfferDataResponseListaDocumentos
     */
    codigoDocumento?: string;
    /**
     * 
     * @type {string}
     * @memberof GetIssueOfferDataResponseListaDocumentos
     */
    codigoDocumentoTexto?: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetIssueOfferDataResponseListaDocumentos
     */
    indicadorDocumentoMarcado?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetIssueOfferDataResponseListaDocumentos
     */
    indicadorDocumentoBloqueado?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetIssueOfferDataResponseListaDocumentos
     */
    indicadorEnvioMail?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetIssueOfferDataResponseListaDocumentos
     */
    indicadorEnvioSms?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetIssueOfferDataResponseListaDocumentos
     */
    indicadorVisualizacion?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetIssueOfferDataResponseListaDocumentos
     */
    indicadorCentralizacion?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetIssueOfferDataResponseListaDocumentos
     */
    indicadorEnvioPostal?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetIssueOfferDataResponseListaDocumentos
     */
    puedeEmail?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetIssueOfferDataResponseListaDocumentos
     */
    puedeDigital?: boolean;
}

type getIssueOfferDataOperationParams = {
  getIssueOfferDataRequest: GetIssueOfferDataRequest,
};

/**
 * GetIssueOfferDataApi - axios parameter creator
 * @param {Configuration} [configuration]
 * @returns {any}
 */
const GetIssueOfferDataApiAxiosParamCreator = function(configuration?: Configuration): any {
  return {
    
    /**
     * Obtiene los datos de envío de oferta
     * @param {GetIssueOfferDataRequest} getIssueOfferDataRequest 
     * @param {string} [acceptLanguage] El Accept-Language request HTTP header indica en que lenguaje debe responder el backend.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @returns {RequestArgs}
     */
    getIssueOfferDataOperation(params: getIssueOfferDataOperationParams, options: any = {}): RequestArgs {
      // Verify required parameter 'getIssueOfferDataRequest' is not null or undefined
      if (params.getIssueOfferDataRequest === null || params.getIssueOfferDataRequest === undefined) {
        throw new RequiredError('getIssueOfferDataRequest', 'Required parameter getIssueOfferDataRequest was null or undefined when calling getIssueOfferDataOperation.');
      }
      const localVarPath = `/v1/quoteAndBuy/getIssueOfferDataOperation`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true) as Partial<globalImportUrl.UrlWithParsedQuery>;
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;


  
      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
      // Fix override query string Detail: https://stackoverflow.com/a/7517673/1077943

      delete localVarUrlObj.search;
      localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
      const needsSerialization = ('GetIssueOfferDataRequest' as any !== 'string') ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization ? JSON.stringify(params.getIssueOfferDataRequest || {}) : params.getIssueOfferDataRequest || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * GetIssueOfferDataApi - functional programming interface
 * @param {Configuration} [configuration]
 * @returns {any}
 */
const GetIssueOfferDataApiFp = function(configuration?: Configuration): any {
  return {
    
    /**
     * Obtiene los datos de envío de oferta
     * @param {GetIssueOfferDataRequest} getIssueOfferDataRequest 
     * @param {string} [acceptLanguage] El Accept-Language request HTTP header indica en que lenguaje debe responder el backend.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @returns {any}
     */
    getIssueOfferDataOperation(params: getIssueOfferDataOperationParams, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetIssueOfferDataResponse> {
      const localVarAxiosArgs = GetIssueOfferDataApiAxiosParamCreator(configuration).getIssueOfferDataOperation(params, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH): any => {
        const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * GetIssueOfferDataApi - factory interface
 * @param {Configuration} configuration
 * @param {string} basePath
 * @param {AxiosInstance} axios
 * @export
 * @returns {any}
 */
const GetIssueOfferDataApiFactory = function(configuration?: Configuration, basePath?: string, axios?: AxiosInstance): any {
  return {
    
    /**
     * Obtiene los datos de envío de oferta
     * @param {GetIssueOfferDataRequest} getIssueOfferDataRequest 
     * @param {string} [acceptLanguage] El Accept-Language request HTTP header indica en que lenguaje debe responder el backend.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @returns {any}
     */
    getIssueOfferDataOperation(params: getIssueOfferDataOperationParams, options?: any): any {
      return GetIssueOfferDataApiFp(configuration).getIssueOfferDataOperation(params, options)(axios, basePath);
    },
  };
};

/** 
 * GetIssueOfferDataApi - object-oriented interface
 * @export
 * @class GetIssueOfferDataApi
 * @extends {BaseAPI}
 */
export class GetIssueOfferDataApi extends BaseAPI {
  
  /**
   * Obtiene los datos de envío de oferta
   * @param {GetIssueOfferDataRequest} getIssueOfferDataRequest 
   * @param {string} [acceptLanguage] El Accept-Language request HTTP header indica en que lenguaje debe responder el backend.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @returns {any}
   * @memberof GetIssueOfferDataApi
   */
  public getIssueOfferDataOperation(params: getIssueOfferDataOperationParams, options?: any): any {
    return GetIssueOfferDataApiFp(this.configuration).getIssueOfferDataOperation(params, options)(this.axios, this.basePath);
  }

}

/**
 * 
 * @export
 * @class EAgetIssueOfferDataOperationInputMessage
 */
export class EAgetIssueOfferDataOperationInputMessage {
  
  /**
   * 
   * @type {GetIssueOfferDataRequest}
   * @memberof EAgetIssueOfferDataOperationInputMessage
   */
  public getIssueOfferDataRequest: GetIssueOfferDataRequest;

  public constructor(getIssueOfferDataRequest: GetIssueOfferDataRequest, ){
  
  this.getIssueOfferDataRequest=getIssueOfferDataRequest;
  } 
}



/** 
 * EAGetIssueOfferDataApi - Architecture client for getIssueOfferDataOperation
 * @export
 * @class EAGetIssueOfferDataApi
 */
export class EAGetIssueOfferDataApi {

  /**
   * 
   * @param {GetIssueOfferDataRequest} getIssueOfferDataRequest
   
   * @param {Configuration} configuration
   * @throws {AsyncRequestError | RequiredError}
   * @returns {Promise<GetIssueOfferDataResponse | null>}
  */ 
  public async getIssueOfferDataOperation(params: getIssueOfferDataOperationParams, configuration: Configuration = {}): Promise<GetIssueOfferDataResponse | null> {
    return this.getIssueOfferDataOperationPromise(params, configuration);
  }

  /**
   * 
   * @param {GetIssueOfferDataRequest} getIssueOfferDataRequest
   
   * @param {Configuration} configuration
   * @param {Function} callback
   * @throws {AsyncRequestError | RequiredError}
   */
  public async getIssueOfferDataOperationSteps(params: getIssueOfferDataOperationParams, configuration: Configuration, callback: (res: AxiosResponse<GetIssueOfferDataResponse | null>) => void) {
    let done: boolean = false;
    const httpClient: AxiosInstance = EASDKConfig.getHttpClient('/v1/quoteAndBuy/getIssueOfferDataOperation', 'getIssueOfferData', 'getIssueOfferDataOperation');
    const api = new GetIssueOfferDataApi(configuration, '', httpClient);
    const sleep = async(ms: number) => {
      return new Promise(resolve => setTimeout(resolve, ms));
    };
    const headers: { ['Async-Id']?: string } = {};
    while (!done) {
      const output = await api.getIssueOfferDataOperation(params, { headers }) as AxiosResponse<GetIssueOfferDataResponse | null>;
      callback(output);
      if (output.status === 202) {
        try {
          const retry = parseInt(output.headers['retry']);
          headers['Async-Id'] = output.headers['request-id'];
          if (isNaN(retry) || retry <= 0 || retry > 1000 * 3600) {
            throw new Error(`Retry time not acceptable (in milliseconds): ${retry}`);
          }
          await sleep(retry);
        } catch (err) {
          throw new Error(`Error in Async Request ${err}`);
        }
      } else {
        done = true;
      }
    }
  }

  /**
   * 
   * @param {GetIssueOfferDataRequest} getIssueOfferDataRequest
   
   * @param {Configuration} configuration
   * @throws {AsyncRequestError | RequiredError}
   * @returns {Promise<GetIssueOfferDataResponse | null>}
   */
  public async getIssueOfferDataOperationPromise(params: getIssueOfferDataOperationParams, configuration: Configuration): Promise<GetIssueOfferDataResponse | null> {
    return new Promise((resolve, reject) => {
      this.getIssueOfferDataOperationSteps(params, configuration, (response: AxiosResponse<GetIssueOfferDataResponse | null>) => {
        if (response.status !== 202) {
          resolve(response.data);
        }
      })
      .catch((err) => {
        reject(err);
      });
    });
  } 
}


