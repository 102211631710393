<template>
  <div>
     <ea-dialog
      :title="$t('regularizationFlow.regularizationList.searchIntermediaryModal.title')"
      :visible.sync="model.showModalIntermediaries"
      size="medium"
      @close="onCloseModal"
      @open="resetIntermediary"
      type="passive"
      :closeOnClickModal="false"
    >
      <ea-row extraClass="d-display-flex d-align-items-flex-end m-r-0">
        <ea-form
          ref="form"
          reference="model"
          @submit.native.prevent
          class="d-grow-1"
        >
          <ea-col :span="12">
            <ea-form-item>
              <ea-input-text
                :placeholder="$t('searchIntermediaryBusiness.table.intermediaryName')"
                v-model="model.intermediaryName"
                prop="intermediaryName"
                @change="resetPageNumber"
                />
            </ea-form-item>
          </ea-col>
          <ea-col :span="12">
            <ea-form-item>
              <ea-input-text
                :placeholder="$t('searchIntermediaryBusiness.table.intermediaryCode')"
                v-model="model.intermediaryCode"
                :maxlength="10"
                prop="intermediaryCode"
                @change="onIntermediaryCodeChange"
                @input="onNumericInput"
                 />
            </ea-form-item>
          </ea-col>
        </ea-form>
     
        <ea-button
          type="secondary"
          class="m-b-32 m-l-24 d-grow-0"
          @click="onSearch"
          :disabled="!isButtonEnabled">
          {{ $t('common.label.search') }}
        </ea-button>
      </ea-row>

      <ea-table
        v-if="showTable"
        :data="intermediaryList"
        ref="resultTable"
        id="employeeIntermediaryList"
        simpleSelect
        :infinite-scroll="true"
        @rowClick="handleRowSelection"
        @loadMore="loadMoreElements"
        class="thead-fixed table-height-limited"
      >
        <ea-table-column
          :label="$t('searchIntermediaryBusiness.table.intermediaryName')"
          show="intermediaryName"
          headerClass="no-sortBy"
        />
        <ea-table-column
          show="intermediaryCode"
          :label="$t('searchIntermediaryBusiness.table.intermediaryCode')"
          headerClass="no-sortBy"
          cellClass="t-align-left"
        />

        <template slot="tfoot" v-if="intermediariesTable.status === 'LOADING'">
          <div class="loading-wrapper">
            <div class="items-status">
              <transition name="fade" v-loading="intermediariesTable.status === 'LOADING'">
                <div class="loading" v-loading="intermediariesTable.status === 'LOADING'">
                </div>
              </transition>
            </div>
          </div>
        </template>
        <template slot="tfoot"
          v-else-if="intermediaryList.length > 0 && isLastIntermediaryPage"
        >
          <div class="loading-wrapper">
            <div class="items-status">
              <p class="loading-message">{{ $t('searchIntermediaryBusiness.table.noMoreData') }}</p>
            </div>
          </div>
        </template>
        <template slot="tfoot" v-else-if="intermediaryList.length === 0">
          <div class="loading-wrapper">
            <div class="items-status">
              <p class="loading-message">{{ $t('searchIntermediaryBusiness.table.noData') }}</p>
            </div>
          </div>
        </template>
      </ea-table>
   
      <div slot="footer" class="d-display-flex d-justify-flex-end" v-if="showTable">
        <ea-button type="secondary" class="m-r-40" @click="onCloseModal">
          {{ $t('common.label.cancel') }}
        </ea-button>
        <ea-button type="primary"
          :disabled="!isSelectButtonEnabled"
          @click="onSelectPerson">
          {{ $t('common.label.select') }}
        </ea-button>
      </div>
    </ea-dialog>
  </div>
</template>

<script lang="ts">
import {
  Component, Prop
} from 'vue-property-decorator';

import {
  mixins
} from 'vue-class-component';

import {
  EABusinessComponent, EAContextManager, EAErrorManager,
  EAFlowItem, EAFlowNavigationUtils, EAMethod, ResponseWithErrors,
  throwIfResponseHasErrors
} from '@zurich-es-npm/ea-front-web-core';
import QbEmployeeModalIntermediariesModel, {
  IntermediariesTableStatus, PaginationData
} from './qb-employee-modal-intermediaries-model';
import Utils from '@/utils/utils';
import Row from '@zurich-es-npm/ea-front-web-ui/lib/ea/data/table/src/class/Row';
import {
  EAGetIntermediariesForEmployeeApi,
  GetIntermediariesForEmployeeRequestCodigoRamoEnum,
  GetIntermediariesForEmployeeResponse,
} from '../../services/V1/quoteAndBuy/getIntermediariesForEmployeeOperation/post';
import {
  EASearchBussinessProductApi, SearchBussinessProductRequestCodigoRamoEnum
} from '@/services/V1/quoteAndBuy/searchBussinessProductOperation/post/api';
import {
  IntermediaryList
} from '../qb-new-offer/qb-new-offer-model';
import {
  Table
} from '@zurich-es-npm/ea-front-web-ui';
import {
  CodigoRamo
} from '@/types/codigo-ramo/codigo-ramo-enum.types';

@Component({
  name: 'qb-employee-modal-intermediaries'
})

/**
 * Business Component qb-employee-modal-intermediaries
 */
export default class QbEmployeeModalIntermediariesBusiness
  extends mixins<EABusinessComponent<QbEmployeeModalIntermediariesModel>>(EABusinessComponent) {
  
  @Prop({
    required: true,
  })
    codigoRamo!: CodigoRamo;

  @Prop()
    closeAllFlow?: boolean;

  @Prop()
    codeStructure?: string;

  public activeFlow?: EAFlowItem;

  public selectedPerson?: QbEmployeeModalIntermediariesModel = new QbEmployeeModalIntermediariesModel();

  public isCheckSelected: boolean = false;

  public isSelectButtonEnabled: boolean = false;

  public showTable: boolean = false;

  public loadingBussinessProductsByIntermediary: boolean = false;

  public intermediaryList: IntermediaryList[] = [];

  public isSelected: boolean = false;

  tableHtml: Element | null = null;

  public numPage: number = 0;

  public isLastIntermediaryPage = false;

  public isLoadingData = false;

  public intermediariesTable: PaginationData<IntermediaryList> = {
    items: [],
    currentPage: 0,
    isLastPage: true,
    status: IntermediariesTableStatus.NOT_LOADED
  };
  

  // COMPONENT: HOOKS
  /**
   * Hook on updated.
   */
  updated() {
    // Set scroll events on tables
    Utils.setScrollEventOnTable('employeeIntermediaryList', this.loadMoreElements);
    this.tableHtml = document.querySelector(`#employeeIntermediaryList .ea-table-component`);
  }

  /**
   * Handle loadMore event in result table
   */
  async loadMoreElements(): Promise<void> {
    if (!this.isLastIntermediaryPage && this.intermediariesTable.status !== IntermediariesTableStatus.LOADING
      && !this.loadingBussinessProductsByIntermediary) {
      this.isLoadingData = true;
      this.numPage = this.numPage + 1;
      await this.getIntermediaryEmployeBff();
      this.isLoadingData = false;
    }
  }


  /**
   * Getter to compute if button must be enabled or not
   */
  get isButtonEnabled(): boolean {
    return (!!this.model.intermediaryCode || !!this.model.intermediaryName)
      && this.intermediariesTable.status !== IntermediariesTableStatus.LOADING
      ? true
      : false;
  }

  /**
   * Execute form search
   */
  async onSearch() {
    this.numPage = 0;
    this.showTable = true;
    this.isSelectButtonEnabled = false;
    this.intermediaryList = [];
    const table = this.$refs.resultTable as Table;

    // Esperamos a que se actualize el modelo para limpiar la tabla y volver a pedir la lista
    await this.$nextTick();
    table?.mapDataToRows();
    
    await this.getIntermediaryEmployeBff();
  }

  /**
   * Handle for intermediaryCode input
   * @param {string} value
   */
  async onNumericInput(value: string) {
    await this.$nextTick();
    this.model.intermediaryCode = Utils.removeNonNumericCharacters(value);
  }

  /**
   * Pads the intermediaryCode with zeroes to the left side.
   * - Max length: 10
   */
  public onIntermediaryCodeChange() {
    if (this.model.intermediaryCode) {
      this.model.intermediaryCode = this.model.intermediaryCode.padStart(10, '0');
    }
    this.resetPageNumber();
  }

  /**
   * Handles click on person table row
   * @param { Row } event selected row data
   */
  handleRowSelection(event: Row) {
    this.selectedPerson = event.getData() as QbEmployeeModalIntermediariesModel;
    if (this.selectedPerson) {
      this.isSelectButtonEnabled = true;
    }
  }

  /**
   * If exist person close modal and update data
   *
   */
  async onSelectPerson() {
    if (this.selectedPerson) {
      this.model.intermediaryCode = this.selectedPerson.intermediaryCode;
      this.model.intermediaryName = this.selectedPerson.intermediaryName;

      this.isCheckSelected = true;
      this.model.showModalIntermediaries = false;
      this.loadingBussinessProductsByIntermediary = true;
      await this.getbussinessProductsByIntermediary(this.selectedPerson.intermediaryCode);
      this.loadingBussinessProductsByIntermediary = false;
      this.intermediaryList = [];
      this.update();
    }
  }

  /**
   * Close modal
   *
   */
  async onCloseModal() {
    if (this.closeAllFlow && !this.isCheckSelected) {
      this.activeFlow = EAContextManager.getInstance().getFlowContext()?.activeFlow;
      if (this.activeFlow) {
        EAFlowNavigationUtils.closeFlow(this, this.activeFlow);
      }
    }
    this.intermediaryList= [];
    this.model.showModalIntermediaries = false;
     
    this.isCheckSelected = false;
    if (!this.selectedPerson) {
      this.$emit('close');
    }
    this.update();
  }


  /**
   * Gets intermediaries for view
   */
  @EAMethod()
  async getIntermediaryEmployeBff() {
    this.intermediariesTable.status = IntermediariesTableStatus.LOADING;
    try {
      const api = new EAGetIntermediariesForEmployeeApi();
      const output = await api.getIntermediariesForEmployeeOperation({
        getIntermediariesForEmployeeRequest: {
          numeroElementosPagina: 10,
          numeroPagina: this.numPage,
          codigoRamo: this.codigoRamo as unknown as GetIntermediariesForEmployeeRequestCodigoRamoEnum,
          nombreIntermediario: this.model.intermediaryName || '',
          codigoIntermediario: this.model.intermediaryCode || '',
          codigoEstructura: this.codeStructure
        }
      });

      if (output) {
        this.manageOutputGetIntermediaryEmployeBff(output);
      }

    } catch (error) {
      this.showTable = true;
      this.isSelectButtonEnabled = false;
      this.intermediaryList = [];
      this.intermediariesTable.status = IntermediariesTableStatus.ERROR_LOADING;
      throw error;
    }
  }

  /**
   * Gets bussiness and products for selected intermediary view
   * @param { string } intermediaryCode selected intermediaryCode
   */
  @EAMethod({
    loading: true
  })
  async getbussinessProductsByIntermediary(intermediaryCode: string) {
    EAErrorManager.clearError();
    try {
      const api = new EASearchBussinessProductApi();
      const output = await api.searchBussinessProductOperation({
        searchBussinessProductRequest: {
          codigoRamo: this.codigoRamo as unknown as SearchBussinessProductRequestCodigoRamoEnum,
          codigoIntermediario: intermediaryCode
        }
      });
      if (output) {

        /*
         *If (this.model.intermediaryCode !== '0000007112') {
         * throw new Error('Error forzado');
         *}
         */
        throwIfResponseHasErrors(output as ResponseWithErrors);
        if (output.listaNegociosProductos?.length) {

          output.listaNegociosProductos.forEach(item => {
            item.nombreIntermediario = this.selectedPerson?.intermediaryName;
          });

          this.$emit('intermediarySelected', output.listaNegociosProductos);
        }
      }
    } catch (error) {
      this.model.intermediaryCode = '';
      this.model.intermediaryName = '';
      this.$emit('resetIntermediary');
      throw error;
    }
  }

  /**
   * Resets page number and lastPage flag
   * @param { GetIntermediariesForEmployeeResponse } output selected row data
   */
  public manageOutputGetIntermediaryEmployeBff(output: GetIntermediariesForEmployeeResponse) {
    if (output.errors?.length) {
      this.intermediariesTable.status = IntermediariesTableStatus.ERROR_LOADING;
      this.intermediaryList = [];
      this.isSelectButtonEnabled = false;
    } else if (output.listaIntermediarios?.length) {
      // ### MAP RETRIEVED RESULTS ###
      const retrievedList = output.listaIntermediarios.map(item => {
        return {
          intermediaryCode: item.codigoIntermediario,
          intermediaryName: item.nombreIntermediario
        };
      });
      this.isLastIntermediaryPage = !!output.esUltimaPagina;

      if (this.numPage === 0) {
        // ### ASSIGN NEW SEARCH RESULTS + SCROLL TO TOP ###
        this.intermediaryList = retrievedList;
        if (this.tableHtml) {
          this.tableHtml.scrollTo(0, 0);
        }
      } else {
        // ### APPEND RESULTS TO EXISTING ONES ###
        retrievedList.forEach(elem => {
          const existIntermedary = this.intermediaryList.find(item => item.intermediaryCode === elem.intermediaryCode);

          if (!existIntermedary) {
            this.intermediaryList.push(elem);
          }
        });
     
      }
      this.intermediariesTable.status = IntermediariesTableStatus.LOADED;
    } else {
      this.intermediariesTable.status = IntermediariesTableStatus.LOADED;
    }
  }

  /**
   * Resets page number and lastPage flag
   */
  public resetPageNumber() {
    this.numPage = 0;
    this.isLastIntermediaryPage = false;
  }

  /**
   * Reset intermediary selection
   */
  resetIntermediary() {
    this.selectedPerson = undefined;
  }
}
</script>
