<template>
  <div>
    <ea-card
      class="t-bg-color-primary-10 m-b-16"
    >
      <div slot="cardbody" class="p-a-24 t-align-center">
        <h3>{{$t(model.productTranslationLabel)}}</h3>
        <p v-for="(offerIssuanceDataElement, index) in model.offerIssuanceData" :key="index">
          <span class="t-weight-bold">{{`${$t(offerIssuanceDataElement.label)}: `}}</span>
          <span class="t-weight-normal">{{ offerIssuanceDataElement.value }}</span>
        </p>
      </div>
    </ea-card>
  </div>
</template>

<script lang="ts">
import {
  Component
} from 'vue-property-decorator';

import {
  mixins
} from 'vue-class-component';

import {
  EABusinessComponent
} from '@zurich-es-npm/ea-front-web-core';

import QbOfferIssuanceDataModel from './qb-offer-issuance-data-model';

@Component({
  name: 'qb-offer-issuance-data'
})

/**
 * Business Component qb-offer-issuance-data
 */
export default class QbOfferIssuanceDataBusiness extends mixins<
EABusinessComponent<QbOfferIssuanceDataModel>
>(EABusinessComponent) {

}
</script>
