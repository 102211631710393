import {
  GetContractDataResponseListaCanalesCobro1Recibo, GetContractDataResponseListaIntermediarios
} from '@/services/V1/quoteAndBuy/getContractDataOperation/post';
import {
  ParsedTableData
} from '@/utils/corporate-tables';

/** 
 * Model qb-offer-issuance-billing-data-form
 */
class QbOfferIssuanceBillingDataFormModel {

  public paymentChannelFirstReceipt: string = '';

  public paymentChannelSuccessiveReceipts: string = '';

  public selectablePaymentChannelsFirstReceiptList: GetContractDataResponseListaCanalesCobro1Recibo[] = [];

  public selectablePaymentChannelsSuccessiveReceiptsList: GetContractDataResponseListaCanalesCobro1Recibo[] = [];

  public debtCollectorIntermediaryFirstReceiptList: GetContractDataResponseListaIntermediarios[] = [];

  public debtCollectorIntermediarySuccessiveReceiptsList: GetContractDataResponseListaIntermediarios[] = [];

  public debtCollectorIntermediaryFirstReceipt: string = '';

  public debtCollectorIntermediarySuccessiveReceipts: string = '';

  public paymentChannels: ParsedTableData[] = [];

  public producerIntermediaryCode: string = '';

}

export default QbOfferIssuanceBillingDataFormModel;
