<template>
  <div>
    <div>

      <fleet-summary-policy-info :model="model" />

      <fleet-policyholder-policy-info
        class="m-t-24"
        :model="model"
        :addressTypesList="addressTypesList"
        :documentTypeList="documentTypeList"
      />

      <fleet-maintenance-vehicle-groups-business
        id="fleet-maintenance-vehicle-groups"
        ref="fleetMaintenanceVehicleGroups"
        v-model="model.fleetVehicleGroupsModel"
        :queryPolicy="queryPolicy"
        @showDocumentation="onShowDocumentation"
        @queryVehicle="queryVehicle"
        :policyEmitedStatusList="model.policyEmitedStatusList"
      />

      <fleet-footer-total-annual-premium
        :totalAnnualPremium="model.fleetVehicleGroupsModel.annualPremiumEmittedVehicles"
        :totalEmittedVehicles="model.fleetVehicleGroupsModel.totalEmittedVehicles"
        :showEmittedVehicles="true"
      />
    </div>

    <ea-dialog
      :visible.sync="showVehicleInfo"
      @close="onCloseModal"
      size="large"
      :title="$t('fleets.fleetsQueryPolicyFlow.vehicleInfoLabels.title')"
    >
      <fleet-vehicle-info
        :warranties="currentWarrantyInfo"
        :vehicle="currentVehicle"
        :groupDescription="currentVehicleGroupDescription"
        :vehiclesUses="model.parsedVehicleUses"
      />
      
  
      <div slot="footer" class="d-display-flex d-justify-flex-end">
        <ea-button type="primary" @click="onCloseModal">
          {{$t('common.label.close')}}
        </ea-button>
      </div>
    </ea-dialog>
    <ea-row class="d-align-items-flex-end" extraClass="m-t-16">
      <ea-col :span="24" class="d-display-flex d-justify-flex-start">
        <ea-button type="secondary" @click="onGoBack()" v-if="model.hasParentFlow">
          {{ $t('common.label.back') }}
        </ea-button>
      </ea-col>
    </ea-row>
  </div>
</template>

<script lang="ts">
import {
  Component, Prop
} from 'vue-property-decorator';

import {
  mixins
} from 'vue-class-component';

import {
  EAMethod,
  EAView,
  ResponseWithErrors,
  throwIfResponseHasErrors
} from '@zurich-es-npm/ea-front-web-core';

import {
  FleetsQueryPolicyModel
} from '../fleets-query-policy-model';

import FleetPolicyholderPolicyInfo
  from '../../../presentational-components/fleets/summary/fleet-policyholder-policy-info.vue';
import FleetSummaryPolicyInfo
  from '../../../presentational-components/fleets/summary/fleet-summary-policy-info.vue';
import FleetMaintenanceVehicleGroupsBusiness
  from '@/business-components/fleet-maintenance-vehicle-groups/fleet-maintenance-vehicle-groups-business.vue';
import {
  GetGroupedVehicleListResponseListaVehiculos
} from '@/services/V1/fleets/getGroupedVehicleListOperation/post';
import {
  SubflowThunderNameEnum
} from '@/types/flow/flow-enum.types';
import {
  OpenSubflowEventData
} from '@/utils/open-new-flow/open-new-flow-utils';
import {
  EAGetWarrantiesByPackageApi,
  GetWarrantiesByPackageRequest, GetWarrantiesByPackageRequestEstadoPolizaFlotaEnum,
  GetWarrantiesByPackageResponse, GetWarrantiesByPackageResponseGarantiasIncluidas
} from '@/services/V1/fleets/getWarrantiesByPackageOperation/post';
import FleetVehicleInfo from '@/presentational-components/fleets/vehicle/fleet-vehicle-info.vue';
import {
  ParsedTableData
} from '@/utils/corporate-tables';
import FleetFooterTotalAnnualPremium
  from '@/flows/fleets/components/fleet-footer-total-annual-premium.vue';

@Component({
  components: {
    'fleet-maintenance-vehicle-groups-business': FleetMaintenanceVehicleGroupsBusiness,
    'fleet-footer-total-annual-premium': FleetFooterTotalAnnualPremium,
    FleetSummaryPolicyInfo,
    FleetPolicyholderPolicyInfo,
    FleetVehicleInfo
  }
})

/**
 * FleetsQueryPolicy view
 *
 */
export default class FleetsQueryPolicyView extends mixins<EAView<FleetsQueryPolicyModel>>(EAView) {
  
  queryPolicy = true;

  loadedVehicle: Record<string, GetWarrantiesByPackageResponse> = {};
  
  getWarratyByPackageApi: EAGetWarrantiesByPackageApi = new EAGetWarrantiesByPackageApi();

  currentWarrantyInfo!: GetWarrantiesByPackageResponse;

  currentVehicle!: GetGroupedVehicleListResponseListaVehiculos;

  currentVehicleGroupDescription!: string;

  showVehicleInfo = false;

  @Prop({
    required: true,
    'default': () => []
  })
    addressTypesList!: ParsedTableData[];

  @Prop({
    required: true,
    'default': () => []
  })
    documentTypeList!: ParsedTableData[];

  /**
   * Handles show documentation button
   * Calls aux method to open a subflow
   * @param {GetGroupedVehicleListResponseListaVehiculos | undefined} row
   * @returns {void}
   */
  onShowDocumentation(row: GetGroupedVehicleListResponseListaVehiculos | undefined) {
    if (row?.codigoPoliza && row?.versionPoliza !== undefined) {
      this.openSubflow({
        name: SubflowThunderNameEnum.DocumentationFlow,
        codigoPoliza: row.codigoPoliza,
        versionPoliza: row.versionPoliza
      });
    }
  }

  /**
   * Emits open subflow event
   * @param {OpenSubflowEventData} data
   */
  openSubflow(data: OpenSubflowEventData) {
    this.$emit('openSubflow', {
      ...data,
      tipoPoliza: 'Póliza'
    });
  }

  /**
   * Query vehicle
   * @param {GetGroupedVehicleListResponseListaVehiculos} vehicle con los datos del vehiculo
   * @param {string} groupDescription descripción agrupación
   */
  async queryVehicle(vehicle: GetGroupedVehicleListResponseListaVehiculos, groupDescription: string): Promise<void> {
    this.currentVehicleGroupDescription = groupDescription;
    await this.loadVehicleWarranties(vehicle);
  }

  /**
   * @param {GetGroupedVehicleListResponseListaVehiculos} vehicle
   */
  @EAMethod({
    loading: true
  })
  async loadVehicleWarranties(vehicle: GetGroupedVehicleListResponseListaVehiculos) {
    let response: GetWarrantiesByPackageResponse;
    // If warranties are in cache memory wont load again
    if (!this.loadedVehicle[`${vehicle.numeroMatriculaSinValidaciones}`]) {
      const getWarrantiesByPackageRequest: GetWarrantiesByPackageRequest = {
        codigoPaqueteGarantia: vehicle.codigoPaquete as string,
        codigoPoliza: vehicle.codigoPoliza as string,
        versionPoliza: vehicle.versionPoliza as number,
        indicadorEdicion: true,
        codigoAgrupacionFlota: vehicle.codigoAgrupacionFlota as string,
        estadoPolizaFlota: GetWarrantiesByPackageRequestEstadoPolizaFlotaEnum.Emitida
      };
      response =
        await this.getWarratyByPackageApi.getWarrantiesByPackageOperation({
          getWarrantiesByPackageRequest
        }) as GetWarrantiesByPackageResponse;
      throwIfResponseHasErrors(response as ResponseWithErrors);
      response.garantiasOpcionales = response.garantiasOpcionales?.filter(warranty => warranty.garantiaSeleccionada);
      response.garantiasIncluidas = response.garantiasIncluidas?.filter(warranty => warranty.garantiaSeleccionada);
      response.garantiasOpcionales = this.sortWarranties(response.garantiasOpcionales || []);
      response.garantiasIncluidas = this.sortWarranties(response.garantiasIncluidas || []);
      // Relationship of vehicle plate number with its warranties
      this.loadedVehicle[`${vehicle.numeroMatriculaSinValidaciones}`] = response;
    }
    this.currentVehicle = vehicle;
    this.currentWarrantyInfo = this.loadedVehicle[`${vehicle.numeroMatriculaSinValidaciones}`];
    this.showVehicleInfo = true;
  }

  /**
   * Sorts included warrenty list with selectors in the last position
   * @param {GetWarrantiesByPackageResponseGarantiasIncluidas[]} warranties 
   * @returns {GetWarrantiesByPackageResponseGarantiasIncluidas[]}
   */
  sortWarranties(warranties: GetWarrantiesByPackageResponseGarantiasIncluidas[]):
  GetWarrantiesByPackageResponseGarantiasIncluidas[] {
    // eslint-disable-next-line max-len
    return warranties.sort((warrantyA, warrantyB) => Number(warrantyA.elementos?.length || 0) - Number(warrantyB.elementos?.length || 0));
  }

  /**
   * Close modal
   */
  onCloseModal() {
    this.showVehicleInfo = false;
  }

  /**
   * Handles back button
   */
  onGoBack() {
    this.$emit('goToPreviousFlow');
  }
}
</script>
