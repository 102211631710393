export const mockEmptyDatosBasicosPersona = {
  codigoFiliacion: '',
  tipoPersona: '',
  codigoIdentificacionFiscal: '',
  nombrePropio: '',
  primerApellido: '',
  segundoApellido: '',
  tipoDocumento: ''
};

export const mockEmptyDatosGeneralesPersona = {
  tratamiento: '',
  sexo: undefined as unknown as string | undefined,
  fechaNacimiento: undefined as unknown as string | undefined,
  nombrePoblacion: '',
  poblacionIneNacimiento: '',
  nacionalidadPersona: '',
  codigoResidencia: '',
  estadoCivil: '',
  tipoContactoZurich: '',
  idiomaCorrespondencia: '',
  especialidadPrincipal: '',
  indicadorListaRobinson: '',
  codigoCnae: '',
  indicadorPersoonaRestringida: ''
};

export const mockEmptyTitulacionPersona = {
  titulacionAcademica: '',
  profesion: ''
};

export const mockEmptyEmpresa = {
  nombreEmpresaTrabajo: '',
  codigoCategoriaEmpresa: '',
  tipoContrato: '',
  codigoBajaEmpresa: ''
};

export const mockEmptyRegistroEmpresa = {
  codigoEpigrafe: '',
  codigoDelegacionHacienda: '',
  codigoIcea: '',
  codigoLetraIcea: ''
};

export const mockEmptyRegistroEmpresaDetalles = {
  codigoProvinciaInscripcionRm: '',
  codigoHojaRm: ''
};

export const mockEmptyListaCarnetsPersona = [
  {
    tipoPermiso: '',
    numeroPermiso: '',
    estadoPermiso: ''
  },
  {
    tipoPermiso: '',
    numeroPermiso: '',
    estadoPermiso: ''
  }
];

export const mockEmptyMarketing = {
  timestampPersonaFisica: '',
  timestampPersonaJuridica: '',
  codigoOperacionPersona: '',
  codigoFicheroExterno: '',
  indicadorFicheroExterno: '',
  indicadorDatosNormalizados: '',
  codigoOrigenPersona: '',
  indicadorFraude: '',
  codigoUnidad: '',
  claveSBU: '',
  codigoPasaporte: '',
  tipoRegimenFiscal: '',
  indicadorNifCifModificado: '',
  codigoRiskName: '',
  codigoPaisDocumentoCee: '',
  indicadorMorosidadZfs: '',
  indicadorTipoPersona: '',
  tipoCliente: '',
  estadoPersona: '',
  campoPaginacion: ''
};
