/* eslint-disable */

/**
 * zonazurich
 * Portal de Mediación Digital
 *
 * The version of the OpenAPI document: 1
 * Contact: digitalarchitecture@zurich.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import {
  EASDKConfig,
  EAUrlUtils
} from '@zurich-es-npm/ea-shared-utils-js';

import * as globalImportUrl from 'url';
import {
  Configuration
} from './configuration';
import globalAxios, {
  AxiosPromise,
  AxiosInstance,
  AxiosResponse
} from 'axios';

const BASE_PATH = EAUrlUtils.removeLastForwardSlash('http://localhost:3000');

/**
 *
 * @export
 */
export const COLLECTION_FORMATS = {
  csv: ',',
  ssv: ' ',
  tsv: '\t',
  pipes: '|',
};

/**
 *  
 * @export
 * @interface RequestArgs
 */
export interface RequestArgs {
  url: string;
  options: any;
}

/**
 * 
 * @export
 * @class BaseAPI
 */
export class BaseAPI {
  protected configuration: Configuration | undefined;

  /**
   *Creates an instance of BaseAPI.
   * @param {Configuration} [configuration]
   * @param {string} [basePath=BASE_PATH]
   * @param {AxiosInstance} [axios=globalAxios]
   * @memberof BaseAPI
   */
  public constructor(
    configuration?: Configuration,
    protected basePath: string = BASE_PATH,
    protected axios: AxiosInstance = globalAxios) {
    if (configuration) {
      this.configuration = configuration;
      this.basePath = configuration.basePath || this.basePath;
    }
  }
}

/**
 * 
 * @export
 * @class RequiredError
 * @extends {Error}
 */
export class RequiredError extends Error {
  public name: 'RequiredError' = 'RequiredError';

  public field: string;

  /**
   *Creates an instance of RequiredError.
   * @param {string} field
   * @param {string} [msg]
   * @memberof RequiredError
   */
  public constructor(field: string, msg?: string) {
    super(msg);
    this.field=field;
  }
}

/**
 * 
 * @export
 * @class AsyncRequestError
 * @extends {Error}
 */
export class AsyncRequestError extends Error {
  public name: 'AsyncRequestError' = 'AsyncRequestError';

  /**
   *Creates an instance of AsyncRequestError.
   * @param {string} field
   * @param {string} [msg]
   * @memberof AsyncRequestError
   */
  public constructor(msg?: string) {
    super(msg);
  }
}

/**
 * 
 * @export
 * @interface Error
 */
export interface Error {
    /**
     * 
     * @type {string}
     * @memberof Error
     */
    code: string;
    /**
     * 
     * @type {string}
     * @memberof Error
     */
    message: string;
    /**
     * 
     * @type {number}
     * @memberof Error
     */
    severity: number;
    /**
     * 
     * @type {Array<object>}
     * @memberof Error
     */
    childErrors?: Array<object>;
}/**
 * 
 * @export
 * @interface GetWarrantiesByPackageRequest
 */
export interface GetWarrantiesByPackageRequest {
    /**
     * 
     * @type {string}
     * @memberof GetWarrantiesByPackageRequest
     */
    codigoPoliza: string;
    /**
     * 
     * @type {number}
     * @memberof GetWarrantiesByPackageRequest
     */
    versionPoliza: number;
    /**
     * 
     * @type {string}
     * @memberof GetWarrantiesByPackageRequest
     */
    codigoPaqueteGarantia: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetWarrantiesByPackageRequest
     */
    indicadorEdicion?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetWarrantiesByPackageRequest
     */
    codigoAgrupacionFlota: string;
    /**
     * 
     * @type {string}
     * @memberof GetWarrantiesByPackageRequest
     */
    estadoPolizaFlota: GetWarrantiesByPackageRequestEstadoPolizaFlotaEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum GetWarrantiesByPackageRequestEstadoPolizaFlotaEnum {
    Emitida = 'Emitida',
    Oferta = 'Oferta'
}
/**
 * 
 * @export
 * @interface GetWarrantiesByPackageResponse
 */
export interface GetWarrantiesByPackageResponse {
    /**
     * 
     * @type {Array<GetWarrantiesByPackageResponseFranquiciasValor>}
     * @memberof GetWarrantiesByPackageResponse
     */
    franquiciasValor?: Array<GetWarrantiesByPackageResponseFranquiciasValor>;
    /**
     * 
     * @type {Array<GetWarrantiesByPackageResponseGarantiasIncluidas>}
     * @memberof GetWarrantiesByPackageResponse
     */
    garantiasIncluidas?: Array<GetWarrantiesByPackageResponseGarantiasIncluidas>;
    /**
     * 
     * @type {Array<GetWarrantiesByPackageResponseGarantiasIncluidas>}
     * @memberof GetWarrantiesByPackageResponse
     */
    garantiasOpcionales?: Array<GetWarrantiesByPackageResponseGarantiasIncluidas>;
    /**
     * 
     * @type {Array<Error>}
     * @memberof GetWarrantiesByPackageResponse
     */
    errors?: Array<Error>;
}/**
 * 
 * @export
 * @interface GetWarrantiesByPackageResponseElementos
 */
export interface GetWarrantiesByPackageResponseElementos {
    /**
     * 
     * @type {string}
     * @memberof GetWarrantiesByPackageResponseElementos
     */
    codigoPaqueteGarantia?: string;
    /**
     * 
     * @type {string}
     * @memberof GetWarrantiesByPackageResponseElementos
     */
    codigoElemento?: string;
    /**
     * 
     * @type {string}
     * @memberof GetWarrantiesByPackageResponseElementos
     */
    tablaElemento?: string;
    /**
     * 
     * @type {string}
     * @memberof GetWarrantiesByPackageResponseElementos
     */
    valorElemento?: string;
    /**
     * 
     * @type {number}
     * @memberof GetWarrantiesByPackageResponseElementos
     */
    valorMinimoElemento?: number;
    /**
     * 
     * @type {number}
     * @memberof GetWarrantiesByPackageResponseElementos
     */
    valorMaximoElemento?: number;
    /**
     * 
     * @type {boolean}
     * @memberof GetWarrantiesByPackageResponseElementos
     */
    elementoModificable?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetWarrantiesByPackageResponseElementos
     */
    elementoRequerido?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetWarrantiesByPackageResponseElementos
     */
    elementoVisible?: boolean;
    /**
     * 
     * @type {number}
     * @memberof GetWarrantiesByPackageResponseElementos
     */
    longitudElemento?: number;
    /**
     * 
     * @type {number}
     * @memberof GetWarrantiesByPackageResponseElementos
     */
    numeroDecimalesElemento?: number;
    /**
     * 
     * @type {string}
     * @memberof GetWarrantiesByPackageResponseElementos
     */
    tipoVariable?: GetWarrantiesByPackageResponseElementosTipoVariableEnum;
    /**
     * 
     * @type {string}
     * @memberof GetWarrantiesByPackageResponseElementos
     */
    tipoMascara?: GetWarrantiesByPackageResponseElementosTipoMascaraEnum;
    /**
     * 
     * @type {string}
     * @memberof GetWarrantiesByPackageResponseElementos
     */
    tipoPropuesta?: GetWarrantiesByPackageResponseElementosTipoPropuestaEnum;
    /**
     * 
     * @type {string}
     * @memberof GetWarrantiesByPackageResponseElementos
     */
    indicadorModalidadGarantia?: string;
    /**
     * 
     * @type {Array<GetWarrantiesByPackageResponseTablaRestricciones>}
     * @memberof GetWarrantiesByPackageResponseElementos
     */
    tablaRestricciones?: Array<GetWarrantiesByPackageResponseTablaRestricciones>;
}

/**
    * @export
    * @enum {string}
    */
export enum GetWarrantiesByPackageResponseElementosTipoVariableEnum {
    A = 'A',
    N = 'N'
}
/**
    * @export
    * @enum {string}
    */
export enum GetWarrantiesByPackageResponseElementosTipoMascaraEnum {
    A = 'A',
    C = 'C',
    D = 'D',
    F = 'F',
    I = 'I',
    M = 'M',
    P = 'P',
    R = 'R',
    T = 'T'
}
/**
    * @export
    * @enum {string}
    */
export enum GetWarrantiesByPackageResponseElementosTipoPropuestaEnum {
    M = 'M',
    S = 'S',
    O = 'O',
    N = 'N'
}
/**
 * 
 * @export
 * @interface GetWarrantiesByPackageResponseFranquiciasValor
 */
export interface GetWarrantiesByPackageResponseFranquiciasValor {
    /**
     * 
     * @type {boolean}
     * @memberof GetWarrantiesByPackageResponseFranquiciasValor
     */
    opcionSeleccionada: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetWarrantiesByPackageResponseFranquiciasValor
     */
    indicadorModalidadGarantia: string;
}/**
 * 
 * @export
 * @interface GetWarrantiesByPackageResponseGarantiasIncluidas
 */
export interface GetWarrantiesByPackageResponseGarantiasIncluidas {
    /**
     * 
     * @type {string}
     * @memberof GetWarrantiesByPackageResponseGarantiasIncluidas
     */
    codigoGarantia: string;
    /**
     * 
     * @type {string}
     * @memberof GetWarrantiesByPackageResponseGarantiasIncluidas
     */
    claveModificadorLiteralGarantia?: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetWarrantiesByPackageResponseGarantiasIncluidas
     */
    garantiaSeleccionada?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetWarrantiesByPackageResponseGarantiasIncluidas
     */
    indicadorModalidadGarantia?: string;
    /**
     * 
     * @type {Array<GetWarrantiesByPackageResponseElementos>}
     * @memberof GetWarrantiesByPackageResponseGarantiasIncluidas
     */
    elementos?: Array<GetWarrantiesByPackageResponseElementos>;
}/**
 * 
 * @export
 * @interface GetWarrantiesByPackageResponseTablaRestricciones
 */
export interface GetWarrantiesByPackageResponseTablaRestricciones {
    /**
     * 
     * @type {string}
     * @memberof GetWarrantiesByPackageResponseTablaRestricciones
     */
    nombreRestriccion?: string;
    /**
     * 
     * @type {string}
     * @memberof GetWarrantiesByPackageResponseTablaRestricciones
     */
    valorRestriccion?: string;
}

type getWarrantiesByPackageOperationParams = {
  getWarrantiesByPackageRequest: GetWarrantiesByPackageRequest,
};

/**
 * GetWarrantiesByPackageApi - axios parameter creator
 * @param {Configuration} [configuration]
 * @returns {any}
 */
const GetWarrantiesByPackageApiAxiosParamCreator = function(configuration?: Configuration): any {
  return {
    
    /**
     * Obtiene las garantías para un vehículo/agrupación a partir de su paquete
     * @param {GetWarrantiesByPackageRequest} getWarrantiesByPackageRequest 
     * @param {string} [acceptLanguage] El Accept-Language request HTTP header indica en que lenguaje debe responder el backend.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @returns {RequestArgs}
     */
    getWarrantiesByPackageOperation(params: getWarrantiesByPackageOperationParams, options: any = {}): RequestArgs {
      // Verify required parameter 'getWarrantiesByPackageRequest' is not null or undefined
      if (params.getWarrantiesByPackageRequest === null || params.getWarrantiesByPackageRequest === undefined) {
        throw new RequiredError('getWarrantiesByPackageRequest', 'Required parameter getWarrantiesByPackageRequest was null or undefined when calling getWarrantiesByPackageOperation.');
      }
      const localVarPath = `/v1/fleets/getWarrantiesByPackageOperation`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true) as Partial<globalImportUrl.UrlWithParsedQuery>;
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;


  
      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
      // Fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
      const needsSerialization = ('GetWarrantiesByPackageRequest' as any !== 'string') ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization ? JSON.stringify(params.getWarrantiesByPackageRequest || {}) : params.getWarrantiesByPackageRequest || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * GetWarrantiesByPackageApi - functional programming interface
 * @param {Configuration} [configuration]
 * @returns {any}
 */
const GetWarrantiesByPackageApiFp = function(configuration?: Configuration): any {
  return {
    
    /**
     * Obtiene las garantías para un vehículo/agrupación a partir de su paquete
     * @param {GetWarrantiesByPackageRequest} getWarrantiesByPackageRequest 
     * @param {string} [acceptLanguage] El Accept-Language request HTTP header indica en que lenguaje debe responder el backend.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @returns {any}
     */
    getWarrantiesByPackageOperation(params: getWarrantiesByPackageOperationParams, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetWarrantiesByPackageResponse> {
      const localVarAxiosArgs = GetWarrantiesByPackageApiAxiosParamCreator(configuration).getWarrantiesByPackageOperation(params, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH): any => {
        const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * GetWarrantiesByPackageApi - factory interface
 * @param {Configuration} configuration
 * @param {string} basePath
 * @param {AxiosInstance} axios
 * @export
 * @returns {any}
 */
const GetWarrantiesByPackageApiFactory = function(configuration?: Configuration, basePath?: string, axios?: AxiosInstance): any {
  return {
    
    /**
     * Obtiene las garantías para un vehículo/agrupación a partir de su paquete
     * @param {GetWarrantiesByPackageRequest} getWarrantiesByPackageRequest 
     * @param {string} [acceptLanguage] El Accept-Language request HTTP header indica en que lenguaje debe responder el backend.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @returns {any}
     */
    getWarrantiesByPackageOperation(params: getWarrantiesByPackageOperationParams, options?: any): any {
      return GetWarrantiesByPackageApiFp(configuration).getWarrantiesByPackageOperation(params, options)(axios, basePath);
    },
  };
};

/** 
 * GetWarrantiesByPackageApi - object-oriented interface
 * @export
 * @class GetWarrantiesByPackageApi
 * @extends {BaseAPI}
 */
export class GetWarrantiesByPackageApi extends BaseAPI {
  
  /**
   * Obtiene las garantías para un vehículo/agrupación a partir de su paquete
   * @param {GetWarrantiesByPackageRequest} getWarrantiesByPackageRequest 
   * @param {string} [acceptLanguage] El Accept-Language request HTTP header indica en que lenguaje debe responder el backend.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @returns {any}
   * @memberof GetWarrantiesByPackageApi
   */
  public getWarrantiesByPackageOperation(params: getWarrantiesByPackageOperationParams, options?: any): any {
    return GetWarrantiesByPackageApiFp(this.configuration).getWarrantiesByPackageOperation(params, options)(this.axios, this.basePath);
  }

}

/**
 * 
 * @export
 * @class EAgetWarrantiesByPackageOperationInputMessage
 */
export class EAgetWarrantiesByPackageOperationInputMessage {
  
  /**
   * 
   * @type {GetWarrantiesByPackageRequest}
   * @memberof EAgetWarrantiesByPackageOperationInputMessage
   */
  public getWarrantiesByPackageRequest: GetWarrantiesByPackageRequest;

  public constructor(getWarrantiesByPackageRequest: GetWarrantiesByPackageRequest, ){
  
  this.getWarrantiesByPackageRequest=getWarrantiesByPackageRequest;
  } 
}



/** 
 * EAGetWarrantiesByPackageApi - Architecture client for getWarrantiesByPackageOperation
 * @export
 * @class EAGetWarrantiesByPackageApi
 */
export class EAGetWarrantiesByPackageApi {

  /**
   * 
   * @param {GetWarrantiesByPackageRequest} getWarrantiesByPackageRequest
   
   * @throws {AsyncRequestError | RequiredError}
   * @returns {Promise<GetWarrantiesByPackageResponse | null>}
  */ 
  public async getWarrantiesByPackageOperation(params: getWarrantiesByPackageOperationParams): Promise<GetWarrantiesByPackageResponse | null> {
    return this.getWarrantiesByPackageOperationPromise(params);
  }

  /**
   * 
   * @param {GetWarrantiesByPackageRequest} getWarrantiesByPackageRequest
   
   * @param {Function} callback
   * @throws {AsyncRequestError | RequiredError}
   */
  public async getWarrantiesByPackageOperationSteps(params: getWarrantiesByPackageOperationParams, callback: (res: AxiosResponse<GetWarrantiesByPackageResponse | null>) => void) {
    let done: boolean = false;
    const httpClient: AxiosInstance = EASDKConfig.getHttpClient('/v1/fleets/getWarrantiesByPackageOperation', 'getWarrantiesByPackage', 'getWarrantiesByPackageOperation');
    const config: Configuration = {};
    const api = new GetWarrantiesByPackageApi(config, '', httpClient);
    const sleep = async(ms: number) => {
      return new Promise(resolve => setTimeout(resolve, ms));
    };
    const headers: { ['Async-Id']?: string } = {};
    while (!done) {
      const output = await api.getWarrantiesByPackageOperation(params, { headers }) as AxiosResponse<GetWarrantiesByPackageResponse | null>;
      callback(output);
      if (output.status === 202) {
        try {
          const retry = parseInt(output.headers['retry']);
          headers['Async-Id'] = output.headers['request-id'];
          if (isNaN(retry) || retry <= 0 || retry > 1000 * 3600) {
            throw new Error(`Retry time not acceptable (in milliseconds): ${retry}`);
          }
          await sleep(retry);
        } catch (err) {
          throw new Error(`Error in Async Request ${err}`);
        }
      } else {
        done = true;
      }
    }
  }

  /**
   * 
   * @param {GetWarrantiesByPackageRequest} getWarrantiesByPackageRequest
   
   * @throws {AsyncRequestError | RequiredError}
   * @returns {Promise<GetWarrantiesByPackageResponse | null>}
   */
  public async getWarrantiesByPackageOperationPromise(params: getWarrantiesByPackageOperationParams): Promise<GetWarrantiesByPackageResponse | null> {
    return new Promise((resolve, reject) => {
      this.getWarrantiesByPackageOperationSteps(params, (response: AxiosResponse<GetWarrantiesByPackageResponse | null>) => {
        if (response.status !== 202) {
          resolve(response.data);
        }
      })
      .catch((err) => {
        reject(err);
      });
    });
  } 
}


