/* eslint-disable */

/**
 * zonazurich
 * Portal de Mediación Digital
 *
 * The version of the OpenAPI document: 1
 * Contact: digitalarchitecture@zurich.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import {
  EASDKConfig,
  EAUrlUtils
} from '@zurich-es-npm/ea-shared-utils-js';

import * as globalImportUrl from 'url';
import {
  Configuration
} from './configuration';
import globalAxios, {
  AxiosPromise,
  AxiosInstance,
  AxiosResponse
} from 'axios';

const BASE_PATH = EAUrlUtils.removeLastForwardSlash('http://localhost:3000');

/**
 *
 * @export
 */
export const COLLECTION_FORMATS = {
  csv: ',',
  ssv: ' ',
  tsv: '\t',
  pipes: '|',
};

/**
 *  
 * @export
 * @interface RequestArgs
 */
export interface RequestArgs {
  url: string;
  options: any;
}

/**
 * 
 * @export
 * @class BaseAPI
 */
export class BaseAPI {
  protected configuration: Configuration | undefined;

  /**
   *Creates an instance of BaseAPI.
   * @param {Configuration} [configuration]
   * @param {string} [basePath=BASE_PATH]
   * @param {AxiosInstance} [axios=globalAxios]
   * @memberof BaseAPI
   */
  public constructor(
    configuration?: Configuration,
    protected basePath: string = BASE_PATH,
    protected axios: AxiosInstance = globalAxios) {
    if (configuration) {
      this.configuration = configuration;
      this.basePath = configuration.basePath || this.basePath;
    }
  }
}

/**
 * 
 * @export
 * @class RequiredError
 * @extends {Error}
 */
export class RequiredError extends Error {
  public name: 'RequiredError' = 'RequiredError';

  public field: string;

  /**
   *Creates an instance of RequiredError.
   * @param {string} field
   * @param {string} [msg]
   * @memberof RequiredError
   */
  public constructor(field: string, msg?: string) {
    super(msg);
    this.field=field;
  }
}

/**
 * 
 * @export
 * @class AsyncRequestError
 * @extends {Error}
 */
export class AsyncRequestError extends Error {
  public name: 'AsyncRequestError' = 'AsyncRequestError';

  /**
   *Creates an instance of AsyncRequestError.
   * @param {string} field
   * @param {string} [msg]
   * @memberof AsyncRequestError
   */
  public constructor(msg?: string) {
    super(msg);
  }
}

/**
 * 
 * @export
 * @interface Error
 */
export interface Error {
    /**
     * 
     * @type {string}
     * @memberof Error
     */
    code: string;
    /**
     * 
     * @type {string}
     * @memberof Error
     */
    message: string;
    /**
     * 
     * @type {number}
     * @memberof Error
     */
    severity: number;
    /**
     * 
     * @type {Array<object>}
     * @memberof Error
     */
    childErrors?: Array<object>;
}/**
 * 
 * @export
 * @interface GetWarrantiesRequest
 */
export interface GetWarrantiesRequest {
    /**
     * 
     * @type {string}
     * @memberof GetWarrantiesRequest
     */
    codigoPoliza: string;
    /**
     * 
     * @type {number}
     * @memberof GetWarrantiesRequest
     */
    versionPoliza: number;
    /**
     * 
     * @type {string}
     * @memberof GetWarrantiesRequest
     */
    tipoPoliza: GetWarrantiesRequestTipoPolizaEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum GetWarrantiesRequestTipoPolizaEnum {
    Oferta = 'Oferta',
    Pliza = 'Póliza',
    Incompleta = 'Incompleta'
}
/**
 * 
 * @export
 * @interface GetWarrantiesResponse
 */
export interface GetWarrantiesResponse {
    /**
     * 
     * @type {GetWarrantiesResponseData}
     * @memberof GetWarrantiesResponse
     */
    data?: GetWarrantiesResponseData;
    /**
     * 
     * @type {Array<Error>}
     * @memberof GetWarrantiesResponse
     */
    errors?: Array<Error>;
}/**
 * 
 * @export
 * @interface GetWarrantiesResponseData
 */
export interface GetWarrantiesResponseData {
    /**
     * 
     * @type {Array<GetWarrantiesResponseDataDatosTarificacion>}
     * @memberof GetWarrantiesResponseData
     */
    datosTarificacion?: Array<GetWarrantiesResponseDataDatosTarificacion>;
    /**
     * 
     * @type {Array<GetWarrantiesResponseDataDatosGarantias>}
     * @memberof GetWarrantiesResponseData
     */
    datosGarantias?: Array<GetWarrantiesResponseDataDatosGarantias>;
    /**
     * 
     * @type {GetWarrantiesResponseDataDatosGestionCompetencias}
     * @memberof GetWarrantiesResponseData
     */
    datosGestionCompetencias?: GetWarrantiesResponseDataDatosGestionCompetencias;
    /**
     * 
     * @type {Array<GetWarrantiesResponseDataDatosAjustesPrimas>}
     * @memberof GetWarrantiesResponseData
     */
    datosAjustesPrimas?: Array<GetWarrantiesResponseDataDatosAjustesPrimas>;
    /**
     * 
     * @type {Array<GetWarrantiesResponseDataTablaRestricciones>}
     * @memberof GetWarrantiesResponseData
     */
    fraccionamientoFormasPago?: Array<GetWarrantiesResponseDataTablaRestricciones>;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetWarrantiesResponseData
     */
    codigosPropuestas?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetWarrantiesResponseData
     */
    agrupacion?: Array<string>;
}/**
 * 
 * @export
 * @interface GetWarrantiesResponseDataCadenaLanzamiento
 */
export interface GetWarrantiesResponseDataCadenaLanzamiento {
    /**
     * 
     * @type {string}
     * @memberof GetWarrantiesResponseDataCadenaLanzamiento
     */
    codigoGarantia?: string;
    /**
     * 
     * @type {string}
     * @memberof GetWarrantiesResponseDataCadenaLanzamiento
     */
    codigoElemento?: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetWarrantiesResponseDataCadenaLanzamiento
     */
    elementoModificable?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetWarrantiesResponseDataCadenaLanzamiento
     */
    elementoRequerido?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetWarrantiesResponseDataCadenaLanzamiento
     */
    elementoVisible?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetWarrantiesResponseDataCadenaLanzamiento
     */
    valorElemento?: string;
    /**
     * 
     * @type {GetWarrantiesResponseDataCopiaValor}
     * @memberof GetWarrantiesResponseDataCadenaLanzamiento
     */
    copiaValor?: GetWarrantiesResponseDataCopiaValor;
}/**
 * 
 * @export
 * @interface GetWarrantiesResponseDataCondicionLanzamientoValorAnteriorElemento
 */
export interface GetWarrantiesResponseDataCondicionLanzamientoValorAnteriorElemento {
    /**
     * 
     * @type {string}
     * @memberof GetWarrantiesResponseDataCondicionLanzamientoValorAnteriorElemento
     */
    condicionLanzamientoValorElemento?: string;
    /**
     * 
     * @type {string}
     * @memberof GetWarrantiesResponseDataCondicionLanzamientoValorAnteriorElemento
     */
    condicionLanzamientoRestriccionGarantia?: GetWarrantiesResponseDataCondicionLanzamientoValorAnteriorElementoCondicionLanzamientoRestriccionGarantiaEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum GetWarrantiesResponseDataCondicionLanzamientoValorAnteriorElementoCondicionLanzamientoRestriccionGarantiaEnum {
    Equal = 'Equal',
    NotEqual = 'NotEqual',
    Any = 'Any'
}
/**
 * 
 * @export
 * @interface GetWarrantiesResponseDataCopiaValor
 */
export interface GetWarrantiesResponseDataCopiaValor {
    /**
     * 
     * @type {string}
     * @memberof GetWarrantiesResponseDataCopiaValor
     */
    codigoGarantia: string;
    /**
     * 
     * @type {string}
     * @memberof GetWarrantiesResponseDataCopiaValor
     */
    codigoPropuesta: string;
    /**
     * 
     * @type {string}
     * @memberof GetWarrantiesResponseDataCopiaValor
     */
    codigoElemento: string;
    /**
     * 
     * @type {string}
     * @memberof GetWarrantiesResponseDataCopiaValor
     */
    condicionLanzamientoRestriccionGarantia?: GetWarrantiesResponseDataCopiaValorCondicionLanzamientoRestriccionGarantiaEnum;
    /**
     * 
     * @type {string}
     * @memberof GetWarrantiesResponseDataCopiaValor
     */
    condicionLanzamientoValorElemento?: string;
    /**
     * 
     * @type {GetWarrantiesResponseDataCopiaValorCondicionLanzamientoElemento}
     * @memberof GetWarrantiesResponseDataCopiaValor
     */
    condicionLanzamientoElemento?: GetWarrantiesResponseDataCopiaValorCondicionLanzamientoElemento;
}

/**
    * @export
    * @enum {string}
    */
export enum GetWarrantiesResponseDataCopiaValorCondicionLanzamientoRestriccionGarantiaEnum {
    Equal = 'Equal',
    NotEqual = 'NotEqual',
    Any = 'Any'
}
/**
 * 
 * @export
 * @interface GetWarrantiesResponseDataCopiaValorCondicionLanzamientoElemento
 */
export interface GetWarrantiesResponseDataCopiaValorCondicionLanzamientoElemento {
    /**
     * 
     * @type {string}
     * @memberof GetWarrantiesResponseDataCopiaValorCondicionLanzamientoElemento
     */
    codigoGarantia: string;
    /**
     * 
     * @type {string}
     * @memberof GetWarrantiesResponseDataCopiaValorCondicionLanzamientoElemento
     */
    codigoPropuesta: string;
    /**
     * 
     * @type {string}
     * @memberof GetWarrantiesResponseDataCopiaValorCondicionLanzamientoElemento
     */
    codigoElemento: string;
}/**
 * 
 * @export
 * @interface GetWarrantiesResponseDataDatosAjustesPrimas
 */
export interface GetWarrantiesResponseDataDatosAjustesPrimas {
    /**
     * 
     * @type {string}
     * @memberof GetWarrantiesResponseDataDatosAjustesPrimas
     */
    codigoElemento?: string;
    /**
     * 
     * @type {string}
     * @memberof GetWarrantiesResponseDataDatosAjustesPrimas
     */
    tablaElemento?: string;
    /**
     * 
     * @type {string}
     * @memberof GetWarrantiesResponseDataDatosAjustesPrimas
     */
    valorElemento?: string;
    /**
     * 
     * @type {number}
     * @memberof GetWarrantiesResponseDataDatosAjustesPrimas
     */
    valorMaximoElemento?: number;
    /**
     * 
     * @type {number}
     * @memberof GetWarrantiesResponseDataDatosAjustesPrimas
     */
    valorMinimoElemento?: number;
    /**
     * 
     * @type {boolean}
     * @memberof GetWarrantiesResponseDataDatosAjustesPrimas
     */
    elementoRequerido?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetWarrantiesResponseDataDatosAjustesPrimas
     */
    elementoModificable?: boolean;
    /**
     * 
     * @type {number}
     * @memberof GetWarrantiesResponseDataDatosAjustesPrimas
     */
    longitudElemento?: number;
    /**
     * 
     * @type {number}
     * @memberof GetWarrantiesResponseDataDatosAjustesPrimas
     */
    numeroDecimalesElemento?: number;
    /**
     * 
     * @type {Array<GetWarrantiesResponseDataTablaRestricciones>}
     * @memberof GetWarrantiesResponseDataDatosAjustesPrimas
     */
    tablaRestricciones?: Array<GetWarrantiesResponseDataTablaRestricciones>;
}/**
 * 
 * @export
 * @interface GetWarrantiesResponseDataDatosGarantias
 */
export interface GetWarrantiesResponseDataDatosGarantias {
    /**
     * 
     * @type {string}
     * @memberof GetWarrantiesResponseDataDatosGarantias
     */
    codigoGarantia?: string;
    /**
     * 
     * @type {Array<GetWarrantiesResponseDataGarantiaSeleccionada>}
     * @memberof GetWarrantiesResponseDataDatosGarantias
     */
    garantiaSeleccionada?: Array<GetWarrantiesResponseDataGarantiaSeleccionada>;
    /**
     * 
     * @type {Array<GetWarrantiesResponseDataElementosGarantias>}
     * @memberof GetWarrantiesResponseDataDatosGarantias
     */
    elementosGarantias?: Array<GetWarrantiesResponseDataElementosGarantias>;
    /**
     * 
     * @type {Array<GetWarrantiesResponseDataLanzadorPorCheck>}
     * @memberof GetWarrantiesResponseDataDatosGarantias
     */
    lanzadorPorCheck?: Array<GetWarrantiesResponseDataLanzadorPorCheck>;
}/**
 * 
 * @export
 * @interface GetWarrantiesResponseDataDatosGestionCompetencias
 */
export interface GetWarrantiesResponseDataDatosGestionCompetencias {
    /**
     * 
     * @type {boolean}
     * @memberof GetWarrantiesResponseDataDatosGestionCompetencias
     */
    gestionDeCompetencias?: boolean;
    /**
     * 
     * @type {Array<GetWarrantiesResponseDataDatosGestionCompetenciasCompetencesManagementByOfferData>}
     * @memberof GetWarrantiesResponseDataDatosGestionCompetencias
     */
    competencesManagementByOfferData?: Array<GetWarrantiesResponseDataDatosGestionCompetenciasCompetencesManagementByOfferData>;
}/**
 * 
 * @export
 * @interface GetWarrantiesResponseDataDatosGestionCompetenciasCompetencesManagementByOfferData
 */
export interface GetWarrantiesResponseDataDatosGestionCompetenciasCompetencesManagementByOfferData {
    /**
     * 
     * @type {string}
     * @memberof GetWarrantiesResponseDataDatosGestionCompetenciasCompetencesManagementByOfferData
     */
    codigoObjeto?: string;
    /**
     * 
     * @type {string}
     * @memberof GetWarrantiesResponseDataDatosGestionCompetenciasCompetencesManagementByOfferData
     */
    codigoOpcion?: string;
    /**
     * 
     * @type {string}
     * @memberof GetWarrantiesResponseDataDatosGestionCompetenciasCompetencesManagementByOfferData
     */
    codigoPropuesta?: string;
    /**
     * 
     * @type {string}
     * @memberof GetWarrantiesResponseDataDatosGestionCompetenciasCompetencesManagementByOfferData
     */
    codigoMCT?: string;
    /**
     * 
     * @type {string}
     * @memberof GetWarrantiesResponseDataDatosGestionCompetenciasCompetencesManagementByOfferData
     */
    descripcionElemento?: string;
    /**
     * 
     * @type {number}
     * @memberof GetWarrantiesResponseDataDatosGestionCompetenciasCompetencesManagementByOfferData
     */
    codigoErrorAutorizacion?: number;
    /**
     * 
     * @type {string}
     * @memberof GetWarrantiesResponseDataDatosGestionCompetenciasCompetencesManagementByOfferData
     */
    estadoControlCompetencias?: GetWarrantiesResponseDataDatosGestionCompetenciasCompetencesManagementByOfferDataEstadoControlCompetenciasEnum;
    /**
     * 
     * @type {string}
     * @memberof GetWarrantiesResponseDataDatosGestionCompetenciasCompetencesManagementByOfferData
     */
    descripcionErrorAutorizacion?: string;
    /**
     * 
     * @type {string}
     * @memberof GetWarrantiesResponseDataDatosGestionCompetenciasCompetencesManagementByOfferData
     */
    descripcionEstadoControlCompetencias?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum GetWarrantiesResponseDataDatosGestionCompetenciasCompetencesManagementByOfferDataEstadoControlCompetenciasEnum {
    A = 'A',
    P = 'P',
    R = 'R'
}
/**
 * 
 * @export
 * @interface GetWarrantiesResponseDataDatosPropuestas
 */
export interface GetWarrantiesResponseDataDatosPropuestas {
    /**
     * 
     * @type {string}
     * @memberof GetWarrantiesResponseDataDatosPropuestas
     */
    codigoPropuesta?: string;
    /**
     * 
     * @type {string}
     * @memberof GetWarrantiesResponseDataDatosPropuestas
     */
    tablaElemento?: string;
    /**
     * 
     * @type {string}
     * @memberof GetWarrantiesResponseDataDatosPropuestas
     */
    valorElemento?: string;
    /**
     * 
     * @type {number}
     * @memberof GetWarrantiesResponseDataDatosPropuestas
     */
    valorMinimoElemento?: number;
    /**
     * 
     * @type {number}
     * @memberof GetWarrantiesResponseDataDatosPropuestas
     */
    valorMaximoElemento?: number;
    /**
     * 
     * @type {boolean}
     * @memberof GetWarrantiesResponseDataDatosPropuestas
     */
    elementoModificable?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetWarrantiesResponseDataDatosPropuestas
     */
    elementoRequerido?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetWarrantiesResponseDataDatosPropuestas
     */
    elementoVisible?: boolean;
    /**
     * 
     * @type {number}
     * @memberof GetWarrantiesResponseDataDatosPropuestas
     */
    longitudElemento?: number;
    /**
     * 
     * @type {number}
     * @memberof GetWarrantiesResponseDataDatosPropuestas
     */
    numeroDecimalesElemento?: number;
    /**
     * 
     * @type {string}
     * @memberof GetWarrantiesResponseDataDatosPropuestas
     */
    tipoVariable?: GetWarrantiesResponseDataDatosPropuestasTipoVariableEnum;
    /**
     * 
     * @type {string}
     * @memberof GetWarrantiesResponseDataDatosPropuestas
     */
    tipoMascara?: GetWarrantiesResponseDataDatosPropuestasTipoMascaraEnum;
    /**
     * 
     * @type {string}
     * @memberof GetWarrantiesResponseDataDatosPropuestas
     */
    tipoPropuesta?: GetWarrantiesResponseDataDatosPropuestasTipoPropuestaEnum;
    /**
     * 
     * @type {Array<GetWarrantiesResponseDataTablaRestricciones>}
     * @memberof GetWarrantiesResponseDataDatosPropuestas
     */
    tablaRestricciones?: Array<GetWarrantiesResponseDataTablaRestricciones>;
    /**
     * 
     * @type {Array<GetWarrantiesResponseDataLanzadorPorValor>}
     * @memberof GetWarrantiesResponseDataDatosPropuestas
     */
    lanzadorPorValor?: Array<GetWarrantiesResponseDataLanzadorPorValor>;
}

/**
    * @export
    * @enum {string}
    */
export enum GetWarrantiesResponseDataDatosPropuestasTipoVariableEnum {
    A = 'A',
    N = 'N'
}
/**
    * @export
    * @enum {string}
    */
export enum GetWarrantiesResponseDataDatosPropuestasTipoMascaraEnum {
    A = 'A',
    C = 'C',
    D = 'D',
    F = 'F',
    I = 'I',
    M = 'M',
    P = 'P',
    R = 'R',
    T = 'T'
}
/**
    * @export
    * @enum {string}
    */
export enum GetWarrantiesResponseDataDatosPropuestasTipoPropuestaEnum {
    M = 'M',
    S = 'S',
    O = 'O',
    N = 'N'
}
/**
 * 
 * @export
 * @interface GetWarrantiesResponseDataDatosTarificacion
 */
export interface GetWarrantiesResponseDataDatosTarificacion {
    /**
     * 
     * @type {string}
     * @memberof GetWarrantiesResponseDataDatosTarificacion
     */
    codigoPropuesta?: string;
    /**
     * 
     * @type {number}
     * @memberof GetWarrantiesResponseDataDatosTarificacion
     */
    importeAcumuladoRecibo?: number;
    /**
     * 
     * @type {number}
     * @memberof GetWarrantiesResponseDataDatosTarificacion
     */
    importePrimerRecibo?: number;
    /**
     * 
     * @type {number}
     * @memberof GetWarrantiesResponseDataDatosTarificacion
     */
    importeRecibosSucesivos?: number;
    /**
     * 
     * @type {number}
     * @memberof GetWarrantiesResponseDataDatosTarificacion
     */
    importeTotalRecibo?: number;
    /**
     * 
     * @type {string}
     * @memberof GetWarrantiesResponseDataDatosTarificacion
     */
    formaPagoTarificacion?: GetWarrantiesResponseDataDatosTarificacionFormaPagoTarificacionEnum;
    /**
     * 
     * @type {boolean}
     * @memberof GetWarrantiesResponseDataDatosTarificacion
     */
    noPrimaMinima?: boolean;
}

/**
    * @export
    * @enum {string}
    */
export enum GetWarrantiesResponseDataDatosTarificacionFormaPagoTarificacionEnum {
    Anual = 'Anual',
    Unica = 'Unica',
    Semestral = 'Semestral',
    Trimestral = 'Trimestral',
    Mensual = 'Mensual'
}
/**
 * 
 * @export
 * @interface GetWarrantiesResponseDataElementosGarantias
 */
export interface GetWarrantiesResponseDataElementosGarantias {
    /**
     * 
     * @type {string}
     * @memberof GetWarrantiesResponseDataElementosGarantias
     */
    codigoElemento?: string;
    /**
     * 
     * @type {Array<GetWarrantiesResponseDataDatosPropuestas>}
     * @memberof GetWarrantiesResponseDataElementosGarantias
     */
    datosPropuestas?: Array<GetWarrantiesResponseDataDatosPropuestas>;
}/**
 * 
 * @export
 * @interface GetWarrantiesResponseDataGarantiaSeleccionada
 */
export interface GetWarrantiesResponseDataGarantiaSeleccionada {
    /**
     * 
     * @type {string}
     * @memberof GetWarrantiesResponseDataGarantiaSeleccionada
     */
    codigoPropuesta?: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetWarrantiesResponseDataGarantiaSeleccionada
     */
    garantiaSeleccionada?: boolean;
}/**
 * 
 * @export
 * @interface GetWarrantiesResponseDataLanzadorPorCheck
 */
export interface GetWarrantiesResponseDataLanzadorPorCheck {
    /**
     * 
     * @type {string}
     * @memberof GetWarrantiesResponseDataLanzadorPorCheck
     */
    condicionLanzamientoValorElemento?: string;
    /**
     * 
     * @type {string}
     * @memberof GetWarrantiesResponseDataLanzadorPorCheck
     */
    condicionLanzamientoRestriccionGarantia?: GetWarrantiesResponseDataLanzadorPorCheckCondicionLanzamientoRestriccionGarantiaEnum;
    /**
     * 
     * @type {Array<GetWarrantiesResponseDataCadenaLanzamiento>}
     * @memberof GetWarrantiesResponseDataLanzadorPorCheck
     */
    cadenaLanzamiento?: Array<GetWarrantiesResponseDataCadenaLanzamiento>;
}

/**
    * @export
    * @enum {string}
    */
export enum GetWarrantiesResponseDataLanzadorPorCheckCondicionLanzamientoRestriccionGarantiaEnum {
    Equal = 'Equal',
    NotEqual = 'NotEqual',
    Any = 'Any'
}
/**
 * 
 * @export
 * @interface GetWarrantiesResponseDataLanzadorPorValor
 */
export interface GetWarrantiesResponseDataLanzadorPorValor {
    /**
     * 
     * @type {string}
     * @memberof GetWarrantiesResponseDataLanzadorPorValor
     */
    condicionLanzamientoValorElemento?: string;
    /**
     * 
     * @type {string}
     * @memberof GetWarrantiesResponseDataLanzadorPorValor
     */
    condicionLanzamientoRestriccionGarantia?: GetWarrantiesResponseDataLanzadorPorValorCondicionLanzamientoRestriccionGarantiaEnum;
    /**
     * 
     * @type {GetWarrantiesResponseDataCondicionLanzamientoValorAnteriorElemento}
     * @memberof GetWarrantiesResponseDataLanzadorPorValor
     */
    condicionLanzamientoValorAnteriorElemento?: GetWarrantiesResponseDataCondicionLanzamientoValorAnteriorElemento;
    /**
     * 
     * @type {Array<GetWarrantiesResponseDataCadenaLanzamiento>}
     * @memberof GetWarrantiesResponseDataLanzadorPorValor
     */
    cadenaLanzamiento?: Array<GetWarrantiesResponseDataCadenaLanzamiento>;
}

/**
    * @export
    * @enum {string}
    */
export enum GetWarrantiesResponseDataLanzadorPorValorCondicionLanzamientoRestriccionGarantiaEnum {
    Equal = 'Equal',
    NotEqual = 'NotEqual',
    Any = 'Any'
}
/**
 * 
 * @export
 * @interface GetWarrantiesResponseDataTablaRestricciones
 */
export interface GetWarrantiesResponseDataTablaRestricciones {
    /**
     * 
     * @type {string}
     * @memberof GetWarrantiesResponseDataTablaRestricciones
     */
    nombreRestriccion?: string;
    /**
     * 
     * @type {string}
     * @memberof GetWarrantiesResponseDataTablaRestricciones
     */
    valorRestriccion?: string;
}

type getWarrantiesOperationParams = {
  getWarrantiesRequest: GetWarrantiesRequest,
};

/**
 * GetWarrantiesApi - axios parameter creator
 * @param {Configuration} [configuration]
 * @returns {any}
 */
const GetWarrantiesApiAxiosParamCreator = function(configuration?: Configuration): any {
  return {
    
    /**
     * Recupera los datos de garantías en base a una número de oferta
     * @param {GetWarrantiesRequest} getWarrantiesRequest 
     * @param {string} [acceptLanguage] El Accept-Language request HTTP header indica en que lenguaje debe responder el backend.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @returns {RequestArgs}
     */
    getWarrantiesOperation(params: getWarrantiesOperationParams, options: any = {}): RequestArgs {
      // Verify required parameter 'getWarrantiesRequest' is not null or undefined
      if (params.getWarrantiesRequest === null || params.getWarrantiesRequest === undefined) {
        throw new RequiredError('getWarrantiesRequest', 'Required parameter getWarrantiesRequest was null or undefined when calling getWarrantiesOperation.');
      }
      const localVarPath = `/v1/quoteAndBuy/getWarrantiesOperation`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true) as Partial<globalImportUrl.UrlWithParsedQuery>;
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;


  
      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
      // Fix override query string Detail: https://stackoverflow.com/a/7517673/1077943

      delete localVarUrlObj.search;
      localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
      const needsSerialization = ('GetWarrantiesRequest' as any !== 'string') ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization ? JSON.stringify(params.getWarrantiesRequest || {}) : params.getWarrantiesRequest || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * GetWarrantiesApi - functional programming interface
 * @param {Configuration} [configuration]
 * @returns {any}
 */
const GetWarrantiesApiFp = function(configuration?: Configuration): any {
  return {
    
    /**
     * Recupera los datos de garantías en base a una número de oferta
     * @param {GetWarrantiesRequest} getWarrantiesRequest 
     * @param {string} [acceptLanguage] El Accept-Language request HTTP header indica en que lenguaje debe responder el backend.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @returns {any}
     */
    getWarrantiesOperation(params: getWarrantiesOperationParams, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetWarrantiesResponse> {
      const localVarAxiosArgs = GetWarrantiesApiAxiosParamCreator(configuration).getWarrantiesOperation(params, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH): any => {
        const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * GetWarrantiesApi - factory interface
 * @param {Configuration} configuration
 * @param {string} basePath
 * @param {AxiosInstance} axios
 * @export
 * @returns {any}
 */
const GetWarrantiesApiFactory = function(configuration?: Configuration, basePath?: string, axios?: AxiosInstance): any {
  return {
    
    /**
     * Recupera los datos de garantías en base a una número de oferta
     * @param {GetWarrantiesRequest} getWarrantiesRequest 
     * @param {string} [acceptLanguage] El Accept-Language request HTTP header indica en que lenguaje debe responder el backend.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @returns {any}
     */
    getWarrantiesOperation(params: getWarrantiesOperationParams, options?: any): any {
      return GetWarrantiesApiFp(configuration).getWarrantiesOperation(params, options)(axios, basePath);
    },
  };
};

/** 
 * GetWarrantiesApi - object-oriented interface
 * @export
 * @class GetWarrantiesApi
 * @extends {BaseAPI}
 */
export class GetWarrantiesApi extends BaseAPI {
  
  /**
   * Recupera los datos de garantías en base a una número de oferta
   * @param {GetWarrantiesRequest} getWarrantiesRequest 
   * @param {string} [acceptLanguage] El Accept-Language request HTTP header indica en que lenguaje debe responder el backend.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @returns {any}
   * @memberof GetWarrantiesApi
   */
  public getWarrantiesOperation(params: getWarrantiesOperationParams, options?: any): any {
    return GetWarrantiesApiFp(this.configuration).getWarrantiesOperation(params, options)(this.axios, this.basePath);
  }

}

/**
 * 
 * @export
 * @class EAgetWarrantiesOperationInputMessage
 */
export class EAgetWarrantiesOperationInputMessage {
  
  /**
   * 
   * @type {GetWarrantiesRequest}
   * @memberof EAgetWarrantiesOperationInputMessage
   */
  public getWarrantiesRequest: GetWarrantiesRequest;

  public constructor(getWarrantiesRequest: GetWarrantiesRequest, ){
  
  this.getWarrantiesRequest=getWarrantiesRequest;
  } 
}



/** 
 * EAGetWarrantiesApi - Architecture client for getWarrantiesOperation
 * @export
 * @class EAGetWarrantiesApi
 */
export class EAGetWarrantiesApi {

  /**
   * 
   * @param {GetWarrantiesRequest} getWarrantiesRequest
   
   * @param {Configuration} configuration
   * @throws {AsyncRequestError | RequiredError}
   * @returns {Promise<GetWarrantiesResponse | null>}
  */ 
  public async getWarrantiesOperation(params: getWarrantiesOperationParams, configuration: Configuration = {}): Promise<GetWarrantiesResponse | null> {
    return this.getWarrantiesOperationPromise(params, configuration);
  }

  /**
   * 
   * @param {GetWarrantiesRequest} getWarrantiesRequest
   
   * @param {Configuration} configuration
   * @param {Function} callback
   * @throws {AsyncRequestError | RequiredError}
   */
  public async getWarrantiesOperationSteps(params: getWarrantiesOperationParams, configuration: Configuration, callback: (res: AxiosResponse<GetWarrantiesResponse | null>) => void) {
    let done: boolean = false;
    const httpClient: AxiosInstance = EASDKConfig.getHttpClient('/v1/quoteAndBuy/getWarrantiesOperation', 'getWarranties', 'getWarrantiesOperation');
    const api = new GetWarrantiesApi(configuration, '', httpClient);
    const sleep = async(ms: number) => {
      return new Promise(resolve => setTimeout(resolve, ms));
    };
    const headers: { ['Async-Id']?: string } = {};
    while (!done) {
      const output = await api.getWarrantiesOperation(params, { headers }) as AxiosResponse<GetWarrantiesResponse | null>;
      callback(output);
      if (output.status === 202) {
        try {
          const retry = parseInt(output.headers['retry']);
          headers['Async-Id'] = output.headers['request-id'];
          if (isNaN(retry) || retry <= 0 || retry > 1000 * 3600) {
            throw new Error(`Retry time not acceptable (in milliseconds): ${retry}`);
          }
          await sleep(retry);
        } catch (err) {
          throw new Error(`Error in Async Request ${err}`);
        }
      } else {
        done = true;
      }
    }
  }

  /**
   * 
   * @param {GetWarrantiesRequest} getWarrantiesRequest
   
   * @param {Configuration} configuration
   * @throws {AsyncRequestError | RequiredError}
   * @returns {Promise<GetWarrantiesResponse | null>}
   */
  public async getWarrantiesOperationPromise(params: getWarrantiesOperationParams, configuration: Configuration): Promise<GetWarrantiesResponse | null> {
    return new Promise((resolve, reject) => {
      this.getWarrantiesOperationSteps(params, configuration, (response: AxiosResponse<GetWarrantiesResponse | null>) => {
        if (response.status !== 202) {
          resolve(response.data);
        }
      })
      .catch((err) => {
        reject(err);
      });
    });
  } 
}


