<template>
  <div>
    <ea-form ref="form" :model="model" :validationOptions="validationOptions" :validateOnRuleChange="false">
      <ea-row extraClass="d-display-flex d-align-items-flex-end">
        <!-- TEXT INPUT FOR EMPLOYEE USER -->
        <ea-col :xl="8" :lg="8" :md="24" :sm="24" v-if="model.employeeUser">
          <ea-form-item :label="getFieldLabel('intermediaryName', 'selectIntermediary.fields.intermediaryName')">
            <ea-input-text
              :readonly="showLinkIntermediaries || isIntermediarySelectionDisabled"
              v-model="model.intermediaryName"
            />
          </ea-form-item>
        </ea-col>

        <!-- SELECT INPUT FOR NOT EMPLOYEE USER -->
        <ea-col :xl="8" :lg="8" :md="24" :sm="24" v-else>
          <ea-form-item
            :label="getFieldLabel('intermediaryName', 'selectIntermediary.fields.intermediaryName')"
            prop="intermediaryCode"
            required
          >
            <ea-select
              v-model="model.intermediaryCode"
              :placeholder="$t('quoteBuyGenericFlow.newOffer.intermediarySelectPlaceholder')"
              :readonly="(intermediaryList && intermediaryList.length === 1) || isIntermediarySelectionDisabled"
              @change="onSelectedIntermediaryChange"
            >
              <ea-option
                v-for="interm in intermediaryList"
                :key="interm.intermediaryCode"
                :label="intermediaryList &&
                  intermediaryList.length > 1 &&
                  interm.intermediaryCode !== 'ZZZZZZZZZZ' ?
                  interm.intermediaryCode + ' ' + interm.intermediaryName : interm.intermediaryName"
                :value="interm.intermediaryCode"
              />
            </ea-select>
          </ea-form-item>
        </ea-col>
        <ea-col :xl="8" :lg="8" :md="24" :sm="24"
          v-if="(model.intermediaryCode || model.employeeUser) && model.intermediaryCode !== intermediaryCodeAll">
          <ea-form-item :label="getFieldLabel('intermediaryCode', 'selectIntermediary.fields.intermediaryCode')">
            <ea-input-text
              :readonly="!model.employeeUser || showLinkIntermediaries"
              v-model="model.intermediaryCode"
              @change="onIntermediaryCodeChange"
              @input="onNumericInput"
            />
          </ea-form-item>
        </ea-col>

        <ea-col
          :xl="8" :lg="8" :md="24" :sm="24"
          v-if="showSearchIntermediaryButton"
        >
          <ea-button
            @click="searchIntermediary"
            type="secondary"
            class="m-b-32"
          >
            {{ $t('selectIntermediary.buttons.search') }}
          </ea-button>
        </ea-col>

        <ea-col
          :xl="8" :lg="8" :md="24" :sm="24"
          v-if="showLinkIntermediaries"
        >
          <ea-button
            @click="openIntermediaryModal"
            type="text"
            class="m-b-32"
          >
            <ea-icon icon="z-user-male" />
            {{ $t('selectIntermediary.buttons.searchIntermediaryButton') }}
          </ea-button>
        </ea-col>

        <ea-col
          :xl="8" :lg="8" :md="24" :sm="24"
          v-if="showLinkDeleteIntermediary && intermediaryCanBeDeleted"
        >
          <ea-button
            @click="onDeleteIntermediary"
            type="text"
            class="m-b-32"
          >
            <ea-icon icon="z-trash" />
            {{ $t('selectIntermediary.buttons.deleteIntermediaryButton') }}
          </ea-button>
        </ea-col>
      </ea-row>

      <ea-row v-if="showTaxIdentity && model.intermediaryTaxIdentity">
        <ea-col :span="12">
          <ea-form-item
            :label="getFieldLabel('intermediaryTaxIdentity', 'selectIntermediary.fields.intermediaryTaxIdentity')"
          >
            <ea-input-text
              readonly
              v-model="model.intermediaryTaxIdentity"
            />
          </ea-form-item>
        </ea-col>
      </ea-row>


      <!-- Business -->
      <ea-row v-if="showBusinessSelection && businessList && businessList.length > 0">
        <ea-col :span="isReplacementDataView ? 8 : 12">
          <ea-form-item
            :label="getFieldLabel('businessName', 'selectIntermediary.fields.businessName')"
            prop="businessCode"
            :required="businessIsRequired"
          >
            <ea-select
              v-model="model.businessCode"
              :placeholder="$t('selectIntermediary.placeholders.select')"
              :readonly="businessList && businessList.length === 1"
              @input="onBusinessChange"
            >
              <ea-option
                v-for="business in businessList"
                :key="business.businessCode"
                :label="business.businessName"
                :value="business.businessCode"
              />
            </ea-select>
          </ea-form-item>
        </ea-col>
        <ea-col :span="12">
          <ea-form-item :label="getFieldLabel('businessCode', 'selectIntermediary.fields.businessCode')">
            <ea-input-text readonly :value="businessCodeDisplay" />
          </ea-form-item>
        </ea-col>
      </ea-row>


      <!-- Product -->
      <ea-row v-if="showProductSelection && productList && productList.length > 0">
        <ea-col :span="isReplacementDataView ? 8 : 12">
          <ea-form-item
            :label="getFieldLabel('productName', 'selectIntermediary.fields.productName')"
            prop="productCode"
          >
            <ea-select
              v-model="model.productCode"
              :placeholder="$t('selectIntermediary.placeholders.select')"
              :readonly="productList && productList.length === 1"
              @change="productCodeChange"
            >
              <ea-option
                v-for="product in productList"
                :key="product.productCode"
                :label="product.productName"
                :value="product.productCode"
              />
            </ea-select>
          </ea-form-item>
        </ea-col>
        <ea-col :span="12">
          <ea-form-item :label="getFieldLabel('productCode', 'selectIntermediary.fields.productCode')">
            <ea-input-text readonly v-model="model.productCode" />
          </ea-form-item>
        </ea-col>
      </ea-row>
    </ea-form>

    <!-- SI ESL USUARIO ES EMPLEADO -->
    <qb-employee-modal-intermediaries
      id="intermediaries"
      v-model="model.intermediariesEmployeeModal"
      :codigoRamo="productFactory.codigoRamo"
      @changeIntermediary="openIntermediaryModal"
      @showError="showError"
      @handleGenericError="onHandleGenericError"
      @intermediarySelected="modalIntermediarySelected"
      @closeGenericErrorDialog="closeGenericErrorDialog"
      ref="employeeModal">
    </qb-employee-modal-intermediaries>
  </div>
</template>

<script lang="ts">
import {
  Component, Prop
} from 'vue-property-decorator';

import {
  mixins
} from 'vue-class-component';

import {
  EABusinessComponent,
  EAMethod,
  EAValidationError,
  ResponseWithErrors,
  throwIfResponseHasErrors,
} from '@zurich-es-npm/ea-front-web-core';
import IntermediarySelectionModel from './intermediary-selection-model';
import ProductBase from '@/utils/quote-buy-product-factory/products/product-base';
import PermissionUtils from '@/utils/permissions-utils';
import {
  BusinessList,
  IntermediaryList, ProductList
} from '../qb-searcher/qb-searcher.interface';
import QbEmployeeModalIntermediariesBusiness
  from '../qb-employee-modal-intermediaries/qb-employee-modal-intermediaries-business.vue';
import {
  GenericErrorData
} from '../qb-generic-error/qb-generic-error-business.vue';
import {
  GetDefaultUserValuesResponseCodigoTipoUsuarioEnum as UserTypesEnum
} from '@/services/V1/home/getDefaultUserValuesOperation/post';
import {
  EAGetIntermediaryBusinessProductListApi,
  GetIntermediaryBusinessProductListRequest,
  GetIntermediaryBusinessProductListResponseListaIntermediarios,
  GetIntermediaryBusinessProductListRequestCodigoOperacionIntermediarioEnum as CodigoOperacionIntermediarioEnum,
  GetIntermediaryBusinessProductListRequestCodigoRamoEnum as CodigoRamoEnum,
} from '@/services/V1/quoteAndBuy/getIntermediaryBusinessProductListOperation/post';
import {
  SearchBussinessProductResponseListaNegociosProductos
} from '@/services/V1/quoteAndBuy/searchBussinessProductOperation/post';
import Utils from '@/utils/utils';
import {
  uniqBy
} from 'lodash';
import {
  EAValidation,
  Form,
  eaRequiredValidation
} from '@zurich-es-npm/ea-front-web-ui';
import {
  EAGetIntermediaryTaxIdentityApi,
} from '@/services/V1/common/getIntermediaryTaxIdentityOperation/post';

@Component({
  name: 'intermediary-selection',
  components: {
    QbEmployeeModalIntermediaries: QbEmployeeModalIntermediariesBusiness
  }
})

/**
 * Business Component intermediary-selection
 */
export default class IntermediarySelectionBusiness extends mixins<
EABusinessComponent<IntermediarySelectionModel>
>(EABusinessComponent) {

  @Prop({
    required: true,
  })
    productFactory!: ProductBase;

  @Prop({
    required: false,
    'default': () => false,
  })
    intermediaryCanBeDeleted?: boolean;

  @Prop({
    required: false,
    'default': () => false,
  })
    canSelectAllIntermediaries?: boolean;

  @Prop({
    required: false,
    'default': () => false,
  })
    showTaxIdentity?: boolean;

  @Prop({
    required: false,
    'default': () => false,
  })
    showProductSelection?: boolean;

  @Prop({
    required: false,
    'default': () => false,
  })
    showBusinessSelection?: boolean;

  @Prop({
    required: false,
    'default': () => false,
  })
    businessIsRequired?: boolean;

  @Prop({
    required: false,
    'default': () => false,
  })
    emitProductListUpdateEvent?: boolean;

  @Prop({
    required: false,
    'default': () => false,
  })
    emitBusinessListUpdateEvent?: boolean;

  @Prop({
    required: false,
    'default': () => false,
  })
    isReplacementDataView!: boolean;

  @Prop({
    required: false,
    'default': () => false,
  })
    isIntermediarySelectionDisabled!: boolean;

  @Prop()
    fieldLabels?: Record<string, string>;

  intermediaryBusinessProductPermutations?: GetIntermediaryBusinessProductListResponseListaIntermediarios[];

  intermediaryList: IntermediaryList[] = [];

  productList: ProductList[] = [];

  businessList: BusinessList[] = [];

  showLinkIntermediaries: boolean = false;

  showLinkDeleteIntermediary: boolean = false;

  showSearchIntermediaryButton: boolean = false;

  intermediaryCodeAll: string = 'ZZZZZZZZZZ';
  
  /**
   * Hook create
   */
  async created() {
    this.calcIfEmployeeUserWhenNecessary();

    if (this.model.employeeUser) {
      if (this.model.intermediaryCode) {
        this.showLinkIntermediaries = true;
        this.showLinkDeleteIntermediary = true;
      } else {
        this.showSearchIntermediaryButton = true;
      }
    } else {
      this.processData(await this.fetchIntermediaryBusinessProductList());
    }

    if (this.model.intermediaryCode) {
      await this.onSelectedIntermediaryChange();
    }
  }

  /**
   * Calculates if actual user is an employee when necessary
   */
  calcIfEmployeeUserWhenNecessary() {
    if (this.model.employeeUser === undefined) {
      const userType = PermissionUtils.getUserType();
      this.model.employeeUser = PermissionUtils.isUserOfType(userType, UserTypesEnum.EMP);
    }
  }

  /**
   * Getter for form validation options
   */
  get validationOptions() {
    if (this.model.employeeUser) {
      return {
        rules: {}
      };
    } else {
      return {
        rules: {
          intermediaryCode: [
            eaRequiredValidation(
              'common.label.validation.fieldRequired'
            )
          ]
        }
      };
    }
  }

  /**
   * Returns validation object
   * @returns {EAValidation}
   */
  public validation(): EAValidation {
    const form = this.$refs.form as Form;
    return form?.validation();
  }

  /**
   * Gets the intermediary, business and product lists with given intermediary code and branch code
   */
  @EAMethod({
    loading: true,
  })
  async fetchIntermediaryBusinessProductList() {
    const api = new EAGetIntermediaryBusinessProductListApi();

    const body: GetIntermediaryBusinessProductListRequest = {
      codigoOperacionIntermediario: CodigoOperacionIntermediarioEnum.OF,
      codigoRamo: this.productFactory.codigoRamo as unknown as CodigoRamoEnum
    };

    const output = await api.getIntermediaryBusinessProductListOperation({
      getIntermediaryBusinessProductListRequest: body,
    });

    if (output) {
      throwIfResponseHasErrors(output as ResponseWithErrors);
      return output?.listaIntermediarios;
    }
  }
  
  /**
   * Intermediary info handler
   * @param {SearchBussinessProductResponseListaNegociosProductos[]} permutations
   */
  modalIntermediarySelected(permutations: SearchBussinessProductResponseListaNegociosProductos[]) {
    this.processData(
      permutations as unknown as GetIntermediaryBusinessProductListResponseListaIntermediarios[]
    );
    this.onSelectedIntermediaryChange();
  }

  /**
   * Intermediary info processor
   * @param {GetIntermediaryBusinessProductListResponseListaIntermediarios[]} permutations
   */
  processData(permutations: GetIntermediaryBusinessProductListResponseListaIntermediarios[] | undefined) {
    this.intermediaryList = this.mapReceivedPermutations(permutations);
    this.intermediaryList = uniqBy(this.intermediaryList, 'intermediaryCode');
    Utils.sortObjectArrayByProperty(this.intermediaryList, 'intermediaryName');

    if (this.intermediaryList.length >= 2 && this.canSelectAllIntermediaries) {
      this.appendAllGroupIntermediariesOption();
    }
    
    if (this.intermediaryList.length === 1) {
      this.selectFirstIntermediaryInList();
    }

    this.showSearchIntermediaryButton = false;

    if (this.model.employeeUser) {
      this.showLinkIntermediaries = true;
      this.showLinkDeleteIntermediary = true;
    }

    this.intermediaryBusinessProductPermutations = permutations;
  }

  /**
   * Maps received permutations 
   * @param {GetIntermediaryBusinessProductListResponseListaIntermediarios[]} permutations
   * @returns {IntermediaryList[]}
   */
  mapReceivedPermutations(
    permutations: GetIntermediaryBusinessProductListResponseListaIntermediarios[] | undefined
  ): IntermediaryList[] {
    return permutations?.map(permutation => {
      return {
        intermediaryName: permutation.nombreIntermediario,
        intermediaryCode: permutation.codigoIntermediario,
      };
    }) || [];
  }

  /**
   * Append option for "All intermediaries" at the beginning
   */
  appendAllGroupIntermediariesOption() {
    this.intermediaryList.unshift({
      intermediaryName: this.$t('quoteBuyGenericFlow.newOffer.allGroupIntermediaries').toString(),
      intermediaryCode: this.intermediaryCodeAll
    });
  }

  /**
   * Selects first intermediary in intermediaryList
   */
  selectFirstIntermediaryInList() {
    this.model.intermediaryCode = this.intermediaryList[0].intermediaryCode || '';
    this.model.intermediaryName = this.intermediaryList[0].intermediaryName || '';
    this.onSelectedIntermediaryChange();
  }

  /**
   * Get intermediaries list associated at current intermediary code/name
   */
  @EAMethod({
    loading: true,
  })
  public async searchIntermediary() {
    if (!this.model.intermediaryCode && !this.model.intermediaryName) {
      throw new EAValidationError(
        this.$t('common.label.validation.insufficientData').toString()
      );
    }

    this.model.intermediariesEmployeeModal.intermediaryCode = this.model.intermediaryCode;
    this.model.intermediariesEmployeeModal.intermediaryName = this.model.intermediaryName;

    const modalComp = this.$refs.employeeModal as QbEmployeeModalIntermediariesBusiness;
    await modalComp.onSearch();

    if (modalComp.showTable) {
      // Got more than one result => Show intermediaries modal
      this.model.intermediariesEmployeeModal.showModalIntermediaries = true;
    }
  }

  /**
   * Open intermediary modal
   */
  openIntermediaryModal() {
    this.model.intermediariesEmployeeModal.showModalIntermediaries = true;
    this.model.intermediariesEmployeeModal.intermediaryCode = '';
    this.model.intermediariesEmployeeModal.intermediaryName = '';
    
    const modalComp = this.$refs.employeeModal as QbEmployeeModalIntermediariesBusiness;
    modalComp.showTable = false;
    modalComp.isSelectButtonEnabled = false;

    this.update();
  }

  /**
   * Deletes selected intermediary
   */
  onDeleteIntermediary() {
    this.model.intermediaryCode = '';
    this.model.intermediaryName = '';
    this.model.intermediaryTaxIdentity = '';
    this.update(); // Needed to update the model in card-payment-policies-management
    
    this.showLinkIntermediaries = false;
    this.showLinkDeleteIntermediary = false;
    this.showSearchIntermediaryButton = true;
  }

  /**
   * Handle for intermediaryCode input
   * @param {string} value
   */
  async onNumericInput(value: string) {
    await this.$nextTick();
    if (this.model.intermediaryCode !== this.intermediaryCodeAll) {
      this.model.intermediaryCode = Utils.removeNonNumericCharacters(value);
    }
  }

  /**
   * Pads the intermediaryCode with zeroes to the left side.
   * - Max length: 10
   */
  public onIntermediaryCodeChange() {
    if (this.model.intermediaryCode) {
      this.model.intermediaryCode = this.model.intermediaryCode.padStart(10, '0');
    }
    this.updateModel();
  }

  /**
   * Handles intermediary selection change
   * If emitBusinessListUpdateEvent === true => parent will handle business list update
   * If emitProductListUpdateEvent === true => parent will handle product list update
   */
  async onSelectedIntermediaryChange() {
    await this.$nextTick(); // Await for model to be updated
    await this.updateModel();

    if (this.showTaxIdentity) {
      await this.retrieveIntermediaryTaxIdentity();
    }
    
    this.$emit('selectedIntermediaryChange');

    if (this.emitBusinessListUpdateEvent) {
      this.$emit('businessListUpdate');
    } else if (this.showBusinessSelection) {
      this.updateBusinessList();
    }

    if (this.emitProductListUpdateEvent) {
      this.$emit('productListUpdate');
    } else if (this.showProductSelection) {
      this.updateProductList();
    }
  }

  /**
   * Handles product selection change
   * If emitProductListUpdateEvent === true => parent will handle product list update
   */
  async onBusinessChange() {
    await this.$nextTick(); // Await for model to be updated
    await this.updateModel();

    if (this.emitProductListUpdateEvent) {
      this.$emit('productListUpdate');
    } else if (this.showProductSelection) {
      this.updateProductList();
    }
  }

  /**
   * Updates business list acording to selected intermediary/business
   */
  updateBusinessList() {
    this.businessList = [];
    this.model.businessCode = '';
    this.model.businessName = '';

    const _businessList =
      this.intermediaryBusinessProductPermutations?.filter(
        permut => permut.codigoIntermediario === this.model.intermediaryCode
      );
      
    if (_businessList) {
      const mappedList: BusinessList[] = _businessList.map(business => {
        return {
          businessName: business.nombreNegocio,
          businessCode: business.codigoNegocio,
        };
      });

      this.setBusinessList(mappedList);
    }
  }

  /**
   * Sets received business list after filtering + sorting
   * Also adds 'Sin negocio' option + selects unique business if only receive one
   * @param {BusinessList[]} receivedList
   */
  public setBusinessList(receivedList: BusinessList[]): void {
    this.businessList = receivedList;
    this.businessList = uniqBy(this.businessList, 'businessCode');
    Utils.sortObjectArrayByProperty(this.businessList, 'businessName');

    this.businessList = this.businessList.filter(business => business.businessCode !== '');

    if (this.businessList.length === 1 && !this.isReplacementDataView) {
      this.model.businessCode = this.businessList[0].businessCode || '';
      this.model.businessName = this.businessList[0].businessName || '';
      this.onBusinessChange();
    }

    this.addNoBusinessOptionIfApplies();

    // Emits to check if has to be a default selected business
    this.$emit('setDefaultBusiness');
  }

  /**
   * Adds "No business" option to business list if business is NOT required
   */
  addNoBusinessOptionIfApplies() {
    if (!this.businessIsRequired) {
      this.businessList.push({
        businessCode: '',
        businessName: this.$t('selectIntermediary.noBusiness').toString()
      });
    }
  }

  /**
   * Updates product list acording to selected intermediary
   */
  updateProductList() {
    this.productList = [];
    this.model.productCode = '';
    this.model.productComercialCode = '';
    this.model.productTechnicalCode = '';
    this.model.productName = '';

    const _productList =
      this.intermediaryBusinessProductPermutations?.filter(
        permut => permut.codigoIntermediario === this.model.intermediaryCode &&
          permut.codigoNegocio === this.model.businessCode?.trim()
      );
      
    if (_productList) {
      const mappedList = _productList.map(product => {
        return {
          productName: product.nombreProducto,
          productComercialCode: product.codigoComercialProducto,
          productTechnicalCode: product.codigoTecnicoProducto,
          productCode: `${product.codigoTecnicoProducto}-${product.codigoComercialProducto}`
        };
      });
      this.setProductList(mappedList);
    }
  }
  
  /**
   * Sets received product list after filtering + sorting
   * Also selects unique business if only receive one
   * @param {ProductList[]} receivedList
   */
  public setProductList(receivedList: ProductList[]): void {
    this.productList = receivedList;
    this.productList = uniqBy(this.productList, 'productCode');

    Utils.sortObjectArrayByProperty(this.productList, 'productName');

    if (this.productList.length === 1) {
      this.model.productCode = this.productList[0].productCode || '';
      this.model.productComercialCode = this.productList[0].productComercialCode || '';
      this.model.productTechnicalCode = this.productList[0].productTechnicalCode || '';
      this.model.productName = this.productList[0].productName || '';
    }
    this.update();
  }

  /**
   * Calls bff for intermediary tax identity retrieval
   */
  @EAMethod({
    loading: true
  })
  async retrieveIntermediaryTaxIdentity() {
    const api = new EAGetIntermediaryTaxIdentityApi();
    const output = await api.getIntermediaryTaxIdentityOperation({
      getIntermediaryTaxIdentityRequest: {
        codigoIntermediario: this.model.intermediaryCode
      }
    });

    if (output) {
      throwIfResponseHasErrors(output as ResponseWithErrors);
      if (output.codigoIdentificacionFiscal) {
        this.model.intermediaryTaxIdentity = output.codigoIdentificacionFiscal;
      }
    }
  }

  /**
   * Updates model
   */
  async updateModel() {
    await this.$nextTick();
    this.update();
  }

  /**
   * Gets field label from prop if any; otherwise uses default label
   * @param {string} fieldName
   * @param {string} defaultLabel
   * @returns {string}
   */
  getFieldLabel(
    fieldName: string, defaultLabel: string
  ): string {
    if (!this.fieldLabels) {
      return this.$t(defaultLabel).toString();
    }

    return this.$t(this.fieldLabels[fieldName] ? this.fieldLabels[fieldName] : defaultLabel).toString();
  }

  /**
   * Updates model product technical and comercial codes on change event
   * 
   * @param {string} productCode
   */
  async productCodeChange(productCode: string) {
    const codes = productCode.split('-');
    this.model.productTechnicalCode = codes[0].toString();
    this.model.productComercialCode = codes[1].toString();

    await this.$nextTick(); // Await for model to be updated
    await this.updateModel();
  }

  /**
   * Getter for business code display
   */
  get businessCodeDisplay() {
    return this.model.businessCode ?
      this.model.businessCode : this.$t('selectIntermediary.noBusiness').toString();
  }

  /**
   * Emits showError event
   * @param {GenericErrorData} genericErrorData
   */
  showError(genericErrorData: GenericErrorData) {
    this.$emit('showError', genericErrorData);
  }

  /**
   * Emit the refresh data event from address to parent component
   *
   * @param {any} args
   */
  onHandleGenericError(args: any) {
    this.$emit('handleGenericError', args);
  }

  /**
   * Emits closeGenericErrorDialog event
   *
   * @param {any} args
   */
  closeGenericErrorDialog() {
    this.$emit('closeGenericErrorDialog');
  }

}
</script>
