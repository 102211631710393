<template>
  <div>
    <ea-card class="t-bg-color-primary-20 p-a-16">
      <div v-if="!issuedFleet" slot="cardbody" class="t-size-small">
        
        <ea-form
          ref="refFleetIssuanceDataReadonlyForm"
          :model="model">
        <ea-row>
          <!-- FLOTA -->
          <ea-col :span="12">
            <ea-heading level="4" class="m-b-20">
              {{ $t('fleets.fleetsFlow.offerIssuanceDataView.offerIssuanceDataComponent.fleet.title') }}
            </ea-heading>
            <ea-row>
              <ea-col :span="12">
                
            <ea-form-item
                :label="$t('fleets.fleetsFlow.offerIssuanceDataView.offerIssuanceDataComponent.fleet.vehiclesTotal')"
                prop="countVehicles">
                <ea-input-text
                  :readonly="true"
                  v-model = "countVehicles"
                 />
              </ea-form-item>
              </ea-col>
              <ea-col :span="12">
                <ea-form-item
                :label="$t('fleets.fleetsFlow.offerIssuanceDataView.offerIssuanceDataComponent.fleet.date')"
                prop="fechaEfectoMovimiento">
                <ea-date-picker
                  :readonly="true"
                  v-model = "model.fechaEfectoMovimiento"
                 />
              </ea-form-item>
              </ea-col>
            </ea-row>
          </ea-col>
          <!-- TOMADOR -->
          <ea-col :span="12">
            <ea-heading level="4" class="m-b-20">
              {{ $t('fleets.fleetsFlow.offerIssuanceDataView.offerIssuanceDataComponent.fleet.holder.title') }}
            </ea-heading>
            <ea-row>
              <ea-col :span="12">
                <ea-form-item
                :label="$t('fleets.fleetsFlow.offerIssuanceDataView.offerIssuanceDataComponent.fleet.holder.fiscalId')"
                prop="codigoIdentificacionFiscal">
                <ea-input-text
                  :readonly="true"
                  v-model = "policyHolder.selectedJuridicalPerson.codigoIdentificacionFiscal"
                 />
              </ea-form-item>
              </ea-col>
              <ea-col :span="12">
                <ea-form-item
                :label="$t('fleets.fleetsFlow.offerIssuanceDataView.offerIssuanceDataComponent.'+
                'fleet.holder.socialReason')"
                prop="razonSocialMatriz">
                <ea-input-text
                  :readonly="true"
                  v-model = "policyHolder.selectedJuridicalPerson.razonSocialMatriz"
                 />
              </ea-form-item>
              </ea-col>
            </ea-row>
          </ea-col>
        </ea-row>
        <!-- RESUMEN FLOTA -->
        <ea-row>
          <ea-col :span="24">
            <ea-heading level="4" class="m-b-20">
              {{ $t('fleets.fleetsFlow.offerIssuanceDataView.offerIssuanceDataComponent.fleet.summary.title') }}
            </ea-heading>
          </ea-col>
        </ea-row>
        
        <ea-row extraClass="d-display-flex d-align-items-flex-end">
          <!-- AGRUPACIONES -->
          <ea-col :span="6" v-for="(group, index) in groupedVehicleList" :key="index" class="m-b-16">
            <ea-form-item
              :label="$t('fleets.fleetsFlow.offerIssuanceDataView.offerIssuanceDataComponent.'+
              'fleet.summary.groupSuffix') +
              ' ' +
               group.descripcionAgrupacionFlota"
              prop="razonSocialMatriz">
              <ea-input-text
                :readonly="true"
                :value="(group.listaVehiculos ? group.listaVehiculos.length : '0') +
                 ' ' +
                  $t('fleets.fleetsFlow.offerIssuanceDataView.offerIssuanceDataComponent.fleet.summary.vehicles')"
              />
            </ea-form-item>
            <ea-form-item
              :label="$t(
                'fleets.fleetsFlow.offerIssuanceDataView.offerIssuanceDataComponent.' +
                'fleet.summary.annualPremium'
              )"
              prop="razonSocialMatriz">
              <ea-input-text
                :readonly="true"
                :value="groupTotals[index]"
              />
            </ea-form-item>
          </ea-col>
            <!-- PRIMA TOTAL ANUAL-->
            <ea-col :span="6" class="m-b-16">
              <ea-form-item
                :label="$t('fleets.fleetsFlow.offerIssuanceDataView.offerIssuanceDataComponent.' +
                      'fleet.summary.annualPremiumTotal')"
                prop="allGroupsTotal">
                
                <div slot="tooltipIconInfo">
                  <div slot="content">
                    <span>{{$t('fleets.fleetsFlow.offerIssuanceDataView.offerIssuanceDataComponent.' +
                          'fleet.summary.annualPremiumTotal')}}</span>
                  </div>
                </div>
                <ea-input-text
                  :readonly="true"
                  v-model = "allGroupsTotal"
                 />
              </ea-form-item>
            </ea-col>
          </ea-row>
        </ea-form>
      </div>
      
      <div v-else slot="cardbody" class="t-size-small p-a-16">
        <ea-row>
          <ea-col>
            <div class="d-display-flex d-justify-center t-weight-semibold t-size-large m-b-8">
              {{ $t('fleets.fleetsFlow.offerIssuanceDataView.offerIssuanceDataComponent.issuedFleet.title') }}
            </div>
          </ea-col>
        </ea-row>
        <ea-row>
          <ea-col extraClass="d-display-flex d-justify-center m-b-4">
            <ea-text weight="semibold">
              {{ $t('fleets.fleetsFlow.offerIssuanceDataView.offerIssuanceDataComponent.issuedFleet.effectDate',
              {effectDate: formatDateToString(model.fechaEfectoMovimiento)}) }}
            </ea-text>
          </ea-col>
        </ea-row>
        <ea-row>
          <ea-col extraClass="d-display-flex d-justify-center">
            <ea-text weight="semibold">
              {{ $t('fleets.fleetsFlow.offerIssuanceDataView.offerIssuanceDataComponent.issuedFleet.expirationDate',
              {expirationDate: formatDateToString(expirationDate)}) }}
            </ea-text>
          </ea-col>
        </ea-row>
      </div>
    </ea-card>
  </div>
</template>

<script lang="ts">
import {
  Component, Prop, Watch
} from 'vue-property-decorator';

import {
  mixins
} from 'vue-class-component';

import {
  EABusinessComponent
} from '@zurich-es-npm/ea-front-web-core';

import FleetIssuanceDataModel from './fleet-issuance-data-model';
import FleetSearchPolicyHolderModel from '../fleets-search-policyholder/fleet-search-policyholder-model';
import {
  GetGroupedVehicleListResponseListaAgrupaciones
} from '@/services/V1/fleets/getGroupedVehicleListOperation/post';
import QbNewOfferBusinessModel from '../qb-new-offer/qb-new-offer-model';
import FleetUnifiedExpirationModel from '../fleet-unified-expiration/fleet-unified-expiration-model';
import Utils from '@/utils/utils';
import moment from 'moment';

@Component({
  name: 'fleet-issuance-data',
})

/**
 * Business Component fleet-issuance-data
 */
export default class FleetIssuanceDataBusiness extends mixins<EABusinessComponent<FleetIssuanceDataModel>>(
  EABusinessComponent
) {
  @Prop()
    fleetData!: QbNewOfferBusinessModel;

  @Prop()
    policyHolder!: FleetSearchPolicyHolderModel;

  @Prop()
    groupedVehicleList!: GetGroupedVehicleListResponseListaAgrupaciones[];

  @Prop()
    unifiedExpirationData!: FleetUnifiedExpirationModel;

  @Prop({
    required: true
  })
    issuedFleet!: boolean;

  groupTotals: string[] = [];

  allGroupsTotal: string = '';

  countVehicles: number = 0;


  /**
   *
   */
  public get expirationDate(): Date {
    const date = new Date(this.model.fechaEfectoMovimiento as Date);
    date.setFullYear(date.getFullYear() + 1);
    date.setDate(date.getDate() - 1);
    return date;
  }

  /**
   * Hook on create
   */
  async created() {
    this.calcTotals();
  }

  /**
   * Format date type to string
   * @param {Date | null} date
   * @returns {string} string date
   */
  formatDateToString(date: Date | null | undefined): string {
    if (date === null || date === undefined || !moment(date).isValid()) {
      return '';
    }
    return Utils.formatDateToString(date);
  }

  /**
   * Calculate group prize total and all groups total
   */
  calcTotals() {
    let countVehicles = 0;
    let allGroupsTotal = 0;
    const groupTotals = [];
    for (const group of this.groupedVehicleList) {
      if (group.listaVehiculos) {
        let groupTotal = 0;
        for (const vehiculo of group.listaVehiculos) {
          if (vehiculo.importeTotal) {
            groupTotal += vehiculo.importeTotal;
          }
          countVehicles++;
        }
        groupTotals.push(groupTotal);
        allGroupsTotal += groupTotal;
      }
    }
    this.groupTotals = groupTotals.map(group => Utils.digitsFormatter(this.$n(group)));
    this.allGroupsTotal = Utils.digitsFormatter(this.$n(allGroupsTotal));
    this.countVehicles = countVehicles;
  }

  /**
   * Updates model
   */
  @Watch('groupVehicleList', {
    deep: true,
    immediate: true,
  })
  onGroupVehicleListChange() {
    this.calcTotals();
  }
}
</script>
