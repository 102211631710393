<template>
  <div>
    <p class="t-weight-normal m-b-16" v-if="model.generateDocumentationViewLabels">{{
      `${$t(model.generateDocumentationViewLabels.proposalSelectionSubitleLabel)}:
      ${model.generateDocumentationViewLabels.proposalSelectionSubtitleData}`
    }}</p>

    <proposal-card
      v-for="warrantiesProposal of proposalsToShow"
      :id="`productID-${warrantiesProposal.proposalNumber}`"
      :key="warrantiesProposal.proposalNumber"
      ref="`productID-${warrantiesProposal.proposalNumber}`"
      :productModel="productModel"
      :warrantiesProposal="warrantiesProposal"
      :proposalsToShowLength="proposalsToShow.length"
      :productFactory="productFactory"
      @warrantiesProposalCheckedStatusChange="onWarrantiesProposalCheckedChange"
      class="m-b-8"
    />
  </div>
  
</template>

<script lang="ts">
import {
  Component, Prop
} from 'vue-property-decorator';

import {
  mixins
} from 'vue-class-component';

import {
  EABusinessComponent
} from '@zurich-es-npm/ea-front-web-core';

import QbDocumentationSelectProposalModel, {
  WarrantiesProposal
} from './qb-documentation-select-proposal-model';
import ProductBase from '@/utils/quote-buy-product-factory/products/product-base';
import {
  ProductModel
} from '@/flows/quote-buy/quote-buy-model';
import ProposalCardComponent from './components/proposal-card-component.vue';

@Component({
  name: 'qb-documentation-select-proposal',
  components: {
    ProposalCard: ProposalCardComponent,
  }
})

/**
 * Business Component qb-documentation-select-proposal
 */
export default class QbDocumentationSelectProposalBusiness extends mixins<
EABusinessComponent<QbDocumentationSelectProposalModel>
>(EABusinessComponent) {
  @Prop()
    warrantiesProposalsNumbers?: string[];

  @Prop()
    warrantyRates?: Record<string, string | number>;

  @Prop()
    continentPrices?: Record<string, string | number>;

  @Prop()
    contentPrices?: Record<string, string | number>;
    
  @Prop({
    required: true,
  })
    productFactory!: ProductBase;
    
  @Prop({
    required: true,
  })
    productModel!: ProductModel;

  /**
   * OnCreate hook
   * Initializes checkboxes status
   */
  created() {
    const maxItems: string[] = [];
    if (this.warrantiesProposalsNumbers?.length) {
      this.warrantiesProposalsNumbers.forEach((item, idx) => {
        const proposalNumber = this.warrantiesProposalsNumbers?.[idx];
        if (proposalNumber && this.warrantyRates?.[proposalNumber]) {
          maxItems.push(proposalNumber);
        }
      });
    }

    maxItems.forEach((item, idx) => this.updatewarrantiesProposals(item, idx));
    this.update();
  }

  /**
   * Listens to warranties proposal checkbox change event
   * @param {WarrantiesProposal} pWarrantiesProposal 
   */
  onWarrantiesProposalCheckedChange(pWarrantiesProposal: WarrantiesProposal) {
    const changedWarrantiesProposal = this.model.warrantiesProposals.find(
      warrantiesProposal => pWarrantiesProposal.proposalNumber === warrantiesProposal.proposalNumber
    );

    if (changedWarrantiesProposal) {
      changedWarrantiesProposal.checked = pWarrantiesProposal.checked;
      this.updateWarrantiesProposalsCheckboxesDisabledStatus();
    }
    
    this.update();
  }

  /**
   * Updates warranties proposal checkboxes disabled status based on checkboxes checked status
   */
  updateWarrantiesProposalsCheckboxesDisabledStatus(): void {
    for (const changedWarrantiesProposal of this.model.warrantiesProposals) {
      changedWarrantiesProposal.disabled =
        this.getCheckedWarrantiesProposalsCount() === 1 && changedWarrantiesProposal.checked;
    }
  }

  /**
   * Gets checked checkboxes count
   * @return {number}
   */
  getCheckedWarrantiesProposalsCount(): number {
    return this.model.warrantiesProposals.filter(
      warrantiesProposal => warrantiesProposal.checked
    ).length;
  }

  /**
   * Gets checked checkboxes count
   * @param {string} proposalNumber
   * @param {number} idx
   */
  updatewarrantiesProposals(proposalNumber: string, idx: number): void {
    const displayedWarrantiesProposals =
      this.warrantiesProposalsNumbers?.filter(warrantiesProposalNumber => warrantiesProposalNumber &&
        this.warrantyRates && this.warrantyRates[warrantiesProposalNumber]).length;

    this.model.warrantiesProposals.splice(idx, 1, {
      proposalNumber,
      proposalIndex: idx,
      rate: this.warrantyRates?.[proposalNumber] || '',
      contentRate: this.contentPrices?.[proposalNumber] || '',
      continentRate: this.continentPrices?.[proposalNumber] || '',
      disabled: displayedWarrantiesProposals === 1 ? true : false,
      checked: true,
    });
  }

  /**
   * Gets proposals to show => filters based on received proposal numbers
   */
  get proposalsToShow(): WarrantiesProposal[] {
    return this.model.warrantiesProposals.filter(
      proposal => this.warrantiesProposalsNumbers?.includes(proposal.proposalNumber)
    );
  }
}
</script>
