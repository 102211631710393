<template>
  <div>
    <p class="t-weight-normal m-b-0 m-l-24" v-if="warrantiesProposal.continentRate">
      <span>{{ `${$t('quoteBuyGenericFlow.generateDocumentation.field.continent')}: `}}</span>
      <span>{{`${getRate(warrantiesProposal.continentRate)} €`}}</span>
    </p>
    <p class="t-weight-normal m-b-0 m-l-24" v-if="warrantiesProposal.contentRate">
      <span>{{ `${$t('quoteBuyGenericFlow.generateDocumentation.field.content')}: `}}</span>
      <span>{{`${getRate(warrantiesProposal.contentRate)} €`}}</span>
    </p>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import {
  Component, Prop
} from 'vue-property-decorator';
import {
  WarrantiesProposal
} from '@/business-components/qb-documentation-select-proposal/qb-documentation-select-proposal-model';

@Component({
  name: 'comunidades-documentation-proposal-card'
})

/**
 * Proposal card component
 */
export default class ComunidadesDocumentationProposalCardComponent extends Vue {

  @Prop({
    required: true
  })
    warrantiesProposal!: WarrantiesProposal;

  @Prop({
    required: true
  })
    proposalsToShowLength!: number;

  @Prop({
    required: true
  })
    getRate!: Function;

  /**
   * Handles checkbox change event
   * Emits event
   */
  onWarrantiesProposalCheckedStatusChange() {
    if (this.warrantiesProposal) {
      this.warrantiesProposal.checked = !this.warrantiesProposal.checked;
      this.$emit('warrantiesProposalCheckedStatusChange', this.warrantiesProposal);
    }
  }
}

</script>
