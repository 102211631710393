/* eslint-disable max-lines */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  GenerateDocumentationViewLabelsModel
} from '@/business-components/qb-documentation-select-proposal/qb-documentation-select-proposal-model';
import {
  OfferIssuanceDataModel
} from '@/business-components/qb-offer-issuance-data/qb-offer-issuance-data-model';
import {
  ProductBase
} from '../product-base';
// eslint-disable-next-line max-len
import QbAddressesPersonModel from '@/business-components/addresses/qb-addresses-person-model';
// eslint-disable-next-line max-len
import QbProductoAseguradoComunidadesBusiness from '@/business-components/qb-producto-asegurado-comunidades/qb-producto-asegurado-comunidades-business.vue';
import QbSearchPersonModel from '@/business-components/qb-search-person/qb-search-person-model';
import {
  QuoteBuyModel
} from '@/flows/quote-buy/quote-buy-model';
import {
  GetWarrantiesResponseDataDatosGarantias
} from '@/services/V1/quoteAndBuy/getWarrantiesOperation/post';
import {
  SaveOfferDataRequestElementosPoliza
} from '@/services/V1/quoteAndBuy/saveOfferDataOperation/post';
import {
  AddressUtils
} from '@/utils/address-utils';
import {
  ParsedTableData
} from '@/utils/corporate-tables';
import ComunidadesModel from '@/utils/quote-buy-product-factory/products/comunidades/comunidades-model';
import TextUtils from '@/utils/text-utils';
import Utils from '@/utils/utils';
import {
  EALocaleManager,
  EAValidationError
} from '@zurich-es-npm/ea-front-web-core';
import Vue, {
  VueConstructor
} from 'vue';
import {
  VisibleElementsWarranties,
  PersonRole,
} from '../../types/product-role-types';
import ComunidadesPolicyModel from './comunidades-policy-model';
import {
  PolicyModel
} from '@/flows/policy/policy-model';
import moment from 'moment';
import {
  Roles
} from '@/types/roles/roles-enum.types';
import QbIbanCodeModel from '@/business-components/qb-iban-code/qb-iban-code-model';
import PersonUtils from '@/utils/person-utils';
import QbGeneralDataInformationModel
  from '@/business-components/qb-general-data-information/qb-general-data-information-model';
import QbBillingDataModel from '@/business-components/qb-billing-data/qb-billing-data-model';
import ComunidadesDocumentationProposalCardComponent
  // eslint-disable-next-line max-len
  from '@/presentational-components/documentationProposalCard/comunidades-documentationProposalCard/comunidades-documentationProposalCard.vue';
import QbShowPersonInfoModel from '@/business-components/qb-show-person-info/qb-show-person-info-business-model';
import {
  GetGeneralDataResponseDataDatosObjeto
} from '@/services/V1/quoteAndBuy/getGeneralDataOperation/post';
import {
  CodigoRamo
} from '@/types/codigo-ramo/codigo-ramo-enum.types';
import {
  DocumentType
} from '@/types/document-types/document-types-enum.types';

/**
 * Product Comunidades
 *
 * @export
 * @class ProductComunidades
 * @extends {ProductBase}
 */
export class ProductComunidades extends ProductBase {

  public codigoRamo?: CodigoRamo;

  public readonly objetoAseguradoComponent: VueConstructor = QbProductoAseguradoComunidadesBusiness;

  public readonly documentationSelectProposalComponent: VueConstructor = ComunidadesDocumentationProposalCardComponent;

  public readonly allowedRolesAtGeneralDataView = [Roles.Tomador, Roles.Asegurado];

  public personRoles: PersonRole[] = [
    // ### TOMADOR ROLE ###
    {
      disabled: false,
      role: Roles.Tomador,
      searchModel: new QbSearchPersonModel(),
      addressesModel: new QbAddressesPersonModel(),
      ibanList: [],
      ibanCodeModel: new QbIbanCodeModel(),
      billing: new QbBillingDataModel(),
      taxTreatmentArea: '',
      canBeChanged: true,
      showPersonInfoModel: new QbShowPersonInfoModel()
    },
    // ### ASEGURADO ROLE ###
    {
      disabled: false,
      role: Roles.Asegurado,
      searchModel: new QbSearchPersonModel(),
      addressesModel: new QbAddressesPersonModel(),
      ibanList: [],
      ibanCodeModel: new QbIbanCodeModel(),
      billing: new QbBillingDataModel(),
      roleEqualStatements: [{
        // ### TOMADOR EQUAL ASEGURADO STATEMENT ###
        roleCopyTo: Roles.Asegurado,
        roleCopyFrom: Roles.Tomador,
        value: true,
        hidden: false,
      }],
      canBeChanged: true,
      showPersonInfoModel: new QbShowPersonInfoModel()
    },
    // ### PAGADOR ROLE ###
    {
      disabled: false,
      role: Roles.Pagador,
      searchModel: new QbSearchPersonModel(),
      addressesModel: new QbAddressesPersonModel(),
      ibanList: [],
      ibanCodeModel: new QbIbanCodeModel(),
      billing: new QbBillingDataModel(),
      roleEqualStatements: [
        {
          // ### TOMADOR EQUAL PAGADOR STATEMENT ###
          roleCopyTo: Roles.Pagador,
          roleCopyFrom: Roles.Tomador,
          value: true,
          hidden: false,
        },
        {
          // ### ASEGURADO EQUAL PAGADOR STATEMENT ###
          roleCopyTo: Roles.Pagador,
          roleCopyFrom: Roles.Asegurado,
          value: false,
          hidden: true,
        },
      ],
      canBeChanged: true,
      showPersonInfoModel: new QbShowPersonInfoModel()
    }
  ];

  public readonly shouldAskForTomadorInNewOfferView: boolean = false;

  public readonly shouldShowNewSearchInShowPersonInfo: boolean = true;

  public readonly axesorSearchDocumentTypes: DocumentType[] = [];

  public readonly shouldReadonlyDatesInGeneralDataInfo: boolean = false;

  public readonly shouldShowProposalTitle: boolean = true;
  
  public readonly shouldShowCustomPackageName: boolean = false;

  public readonly containerWarrantyCode: string = '0000000639';

  public readonly contentWarrantyCode: string = '0000003279';

  public readonly shouldShowTypeProposal: boolean = false;

  public readonly shouldValidate1YearMoreDate: boolean = true;

  public readonly alwaysVisibleElementsWarranties: VisibleElementsWarranties[] = [];

  /**
   * Warranties header variables
   */
  public readonly warrantiesHeaderConfig = {
    showWarrantiesGroups: false,
    canSelectMultipleWarrantiesGroups: false,
    warrantiesGroupsDisplayMode: undefined,
    showPackagesGroups: false,
    canSelectMultiplePackagesGroups: false,
    packagesGroupsDisplayMode: undefined,
  };
  
  public readonly warrantiesHeaderPolicyConfig = {
    showWarrantiesGroups: false,
    canSelectMultipleWarrantiesGroups: false,
    warrantiesGroupsDisplayMode: undefined,
    showPackagesGroups: false,
    canSelectMultiplePackagesGroups: false,
    packagesGroupsDisplayMode: undefined,
  };

  /**
   * Factory constructor
   * @param {string} codigoRamo 
   */
  constructor(codigoRamo: string) {
    super();
    this.codigoRamo = codigoRamo as CodigoRamo;
  }

  /**
   * Retrieves product title
   */
  get productTitle(): string {
    return `quoteBuyGenericFlow.titleDefault.${this.codigoRamo}`;
  }

  /**
   * Retrieves product translation label
   */
  get productTranslationLabel(): string {
    return `ofertaEmisionBusiness.productsByRamo.${this.codigoRamo}`;
  }

  /**
   * Retrieves product name
   */
  get productName(): string {
    return EALocaleManager.i18n.t(this.productTranslationLabel) as string;
  }

  /**
   * Create Comunidades Model Instance
   *
   * @return {ComunidadesModel}
   */
  public createModelInstance(): ComunidadesModel {
    return new ComunidadesModel();
  }

  /**
   * Create Comunidades Model Instance
   *
   * @return {ComunidadesModel}
   */
  public createPolicyModelInstance(): ComunidadesPolicyModel {
    return new ComunidadesPolicyModel();
  }

  /**
   * Generates offer data for saving service
   *
   * @param {ProductModel} productModel - product model
   * @returns { SaveOfferDataRequestElementosPoliza[] } generated offer
   */
  public generateOfferData(productModel: ComunidadesModel): SaveOfferDataRequestElementosPoliza[] {
    const data: SaveOfferDataRequestElementosPoliza[] = [
      {
        codigoElemento: 'CDPAIS',
        valorElemento: productModel.productModel.riskSituation.formValues.country,
      },
      {
        codigoElemento: 'TCCOPOST',
        valorElemento: productModel.productModel.riskSituation.postCodeLocationInputModel.zipCode,
      },
      {
        codigoElemento: 'NOPOBLSR',
        valorElemento: productModel.productModel.riskSituation.postCodeLocationInputModel.city.substring(0, 30),
      },
      {
        codigoElemento: 'CDPOBLA',
        valorElemento: productModel.productModel.riskSituation.postCodeLocationInputModel.cityCode || '88000000001',
      },
      {
        codigoElemento: 'TCPROVIN',
        valorElemento: productModel.productModel.riskSituation.postCodeLocationInputModel.zipCode?.substring(0, 2),
      },
      {
        codigoElemento: 'DSPROVIN',
        valorElemento: productModel.productModel.riskSituation.postCodeLocationInputModel.province,
      },
      {
        codigoElemento: 'CDDIRESR',
        valorElemento: productModel.productModel.riskSituation.formValues.roadType,
      },
      {
        codigoElemento: 'NODIRESR',
        valorElemento: productModel.productModel.riskSituation.formValues.roadName,
      },
      {
        codigoElemento: 'NUDIRESR',
        valorElemento: Utils.parseNumericValueIfReceived(
          productModel.productModel.riskSituation.formValues.roadNumber, 0
        ).toString(),
      },
      {
        codigoElemento: 'VAACCDSR',
        valorElemento: productModel.productModel.riskSituation.formValues.extraInfo,
      },
      {
        codigoElemento: 'TXDESOB1',
        valorElemento: productModel.productModel.riskExtraInfo?.substring(0, 80),
      },
      {
        codigoElemento: 'TXDESOB2',
        valorElemento: productModel.productModel.riskExtraInfo?.substring(80),
      },
      {
        codigoElemento: 'AACONSTR',
        valorElemento: Utils.parseNumericValueIfReceived(productModel.productModel.constructionYear, 0).toString(),
      },
      {
        codigoElemento: 'AAREFOFA',
        valorElemento: Utils.parseNumericValueIfReceived(productModel.productModel.remodelingYear, 0).toString(),
      },
      {
        codigoElemento: 'AAREFOCU',
        valorElemento: Utils.parseNumericValueIfReceived(productModel.productModel.deckYear, 0).toString(),
      },
      {
        codigoElemento: 'AAREFOEL',
        valorElemento: Utils.parseNumericValueIfReceived(productModel.productModel.electricityYear, 0).toString(),
      },
      {
        codigoElemento: 'AAREFOAG',
        valorElemento: Utils.parseNumericValueIfReceived(productModel.productModel.waterYear, 0).toString(),
      },
      {
        codigoElemento: 'CDNIREAG',
        valorElemento: productModel.productModel.comunitaryWater,
      },
      {
        codigoElemento: 'TCAGUACO',
        valorElemento: productModel.productModel.communityWaterFacilities,
      },
      {
        codigoElemento: 'TCTIPROC',
        valorElemento: productModel.productModel.ownerType,
      },
      {
        codigoElemento: 'TCUSOINM',
        valorElemento: productModel.productModel.realEstateDestination,
      },
      {
        codigoElemento: 'CDACLOCO',
        valorElemento: productModel.productModel.businessPremisesActivity,
      },
      {
        codigoElemento: 'NUEDIFIC',
        valorElemento: Utils.parseNumericValueIfReceived(productModel.productModel.buildingNumber, 0).toString(),
      },
      {
        codigoElemento: 'NUESCBLO',
        valorElemento: Utils.parseNumericValueIfReceived(productModel.productModel.blockStairNumber, 0).toString(),
      },
      {
        codigoElemento: 'NUVIVIEN',
        valorElemento: Utils.parseNumericValueIfReceived(productModel.productModel.livingPlaceNumber, 0).toString(),
      },
      {
        codigoElemento: 'NUPLAGAR',
        valorElemento: Utils.parseNumericValueIfReceived(productModel.productModel.parkingSpacesNumber, 0).toString(),
      },
      {
        codigoElemento: 'NUASCTOT',
        valorElemento: Utils.parseNumericValueIfReceived(productModel.productModel.elevatorNumber, 0).toString(),
      },
      {
        codigoElemento: 'NUALTEDI',
        valorElemento: Utils.parseNumericValueIfReceived(productModel.productModel.buildingPlant, 0).toString(),
      },
      {
        codigoElemento: 'NUALTBRA',
        valorElemento: Utils.parseNumericValueIfReceived(productModel.productModel.groundFloor, 0).toString(),
      },
      {
        codigoElemento: 'INCOFICO',
        valorElemento: productModel.productModel.fico,
      },
      {
        codigoElemento: 'NUSUPINM',
        valorElemento: Utils.parseNumericValueIfReceived(productModel.productModel.propertySize, 0).toString(),
      },
      {
        codigoElemento: 'NUSUPJAR',
        valorElemento: Utils.parseNumericValueIfReceived(productModel.productModel.backyardSize, 0).toString(),
      },
      {
        codigoElemento: 'NUSUPGAR',
        valorElemento: Utils.parseNumericValueIfReceived(productModel.productModel.garageSize, 0).toString(),
      },
      {
        codigoElemento: 'NUSUPTOT',
        valorElemento: Utils.parseNumericValueIfReceived(productModel.productModel.propertyTotalSize, 0).toString(),
      },
      {
        codigoElemento: 'INCOMS25',
        valorElemento: productModel.productModel.moreThan25InCommerce,
      },
      {
        codigoElemento: 'CDMATCON',
        valorElemento: productModel.productModel.edification,
      },
      {
        codigoElemento: 'CDFACRIS',
        valorElemento: productModel.productModel.glazedFacades,
      },
      {
        codigoElemento: 'CDMATTUB',
        valorElemento: productModel.productModel.downspoutPipes,
      },
      {
        codigoElemento: 'CDCALINM',
        valorElemento: productModel.productModel.quality,
      },
      {
        codigoElemento: 'INPANSAN',
        valorElemento: Utils.convertBooleanToString(productModel.productModel.sandwichPanels),
      },
      {
        codigoElemento: 'TCPROQUI',
        valorElemento: productModel.productModel.productType,
      },
      {
        codigoElemento: 'NULITROS',
        valorElemento: Utils.parseNumericValueIfReceived(productModel.productModel.liters, 0).toString(),
      },
      {
        codigoElemento: 'INDEPREC',
        valorElemento: Utils.convertBooleanToString(productModel.productModel.sportsRecreational),
      },
      {
        codigoElemento: 'INPISCIN',
        valorElemento: Utils.convertBooleanToString(productModel.productModel.swimmingPool),
      },
      {
        codigoElemento: 'INGIMNAS',
        valorElemento: Utils.convertBooleanToString(productModel.productModel.gym),
      },
      {
        codigoElemento: 'INLOCSOC',
        valorElemento: Utils.convertBooleanToString(productModel.productModel.socialClub),
      },
      {
        codigoElemento: 'INDPLACS',
        valorElemento: Utils.convertBooleanToString(productModel.productModel.solarPanels),
      },
      {
        codigoElemento: 'INPUNCAR',
        valorElemento: Utils.convertBooleanToString(productModel.productModel.electricVehicleChargingPoints),
      },
      {
        codigoElemento: 'INEXTINT',
        valorElemento: Utils.convertBooleanToString(productModel.productModel.fireExtinguishers),
      },
      {
        codigoElemento: 'INBOCINC',
        valorElemento: Utils.convertBooleanToString(productModel.productModel.fireHydrants),
      },
      {
        codigoElemento: 'INROCIAD',
        valorElemento: Utils.convertBooleanToString(productModel.productModel.sprinklers),
      },
      {
        codigoElemento: 'INDETECT',
        valorElemento: Utils.convertBooleanToString(productModel.productModel.smokeDetectors),
      },
      {
        codigoElemento: 'INCOLHID',
        valorElemento: Utils.convertBooleanToString(productModel.productModel.moisturizingColumns),
      },
      {
        codigoElemento: 'INESCINC',
        valorElemento: Utils.convertBooleanToString(productModel.productModel.fireStairs),
      },
      {
        codigoElemento: 'INCAMSEG',
        valorElemento: Utils.convertBooleanToString(productModel.productModel.securityCamera),
      },
      {
        codigoElemento: 'INVIGPER',
        valorElemento: Utils.convertBooleanToString(productModel.productModel.permanentSurveillance),
      },
    ];

    if (productModel.productModel.riskSituation.postCodeLocationInputModel.cityCode) {
      if (productModel.productModel.riskSituation.postCodeLocationInputModel.cityCode === '88000000001') {
        data.push({
          codigoElemento: 'DSPOBTOT',
          valorElemento: '0',
        });
      } else {
        data.push({
          codigoElemento: 'DSPOBTOT',
          valorElemento:
            productModel.productModel.riskSituation.postCodeLocationInputModel.populationNumber?.toString(),
        });
      }
    }

    if (productModel.productModel.ecologicalCertification) {
      data.push({
        codigoElemento: 'CDNIVCEC',
        valorElemento: productModel.productModel.ecologicalCertification,
      });
    }

    if (productModel.productModel.ecologicalPolicy) {
      data.push({
        codigoElemento: 'CDPOLECO',
        valorElemento: productModel.productModel.ecologicalPolicy,
      });
    }

    return data;
  }

  /**
   * Generates offer data for saving service
   *
   * @param {QuoteBuyModel} model - product model
   * @param {string | undefined} filiationCodePagador
   * @returns { any } } generated offer
   */
  public generatePolicyData(
    model: QuoteBuyModel | PolicyModel,
    filiationCodePagador: string | undefined
  ): { codigoElemento: string; valorElemento: string | undefined }[] {
    const pagador = this.personRoles.find(person => person.role === Roles.Pagador);
    const ibanCode = pagador?.ibanCodeModel.qbIbanCodeModalModel.selectedIbanCode;
    const paymentForm = model.offerIssuanceBonusDataFormModel.paymentForms.find(
      payment => payment.nombreRestriccion === model.offerIssuanceBonusDataFormModel.paymentForm ||
      payment.valorRestriccion === model.offerIssuanceBonusDataFormModel.paymentForm
    )?.valorRestriccion;

    const policyElements = [
      {
        codigoElemento: 'TCCANCO1',
        valorElemento: model.offerIssuanceBillingDataFormModel.paymentChannelFirstReceipt,
      },
      {
        codigoElemento: 'TCCANCOS',
        valorElemento: model.offerIssuanceBillingDataFormModel.paymentChannelSuccessiveReceipts,
      },
      {
        codigoElemento: 'CLINTEC1',
        valorElemento: model.offerIssuanceBillingDataFormModel.debtCollectorIntermediaryFirstReceipt,
      },
      {
        codigoElemento: 'CLINTECS',
        valorElemento: model.offerIssuanceBillingDataFormModel.debtCollectorIntermediarySuccessiveReceipts,
      },
      {
        codigoElemento: 'TCFORPAG',
        valorElemento: paymentForm,
      },
      {
        codigoElemento: 'TCFOPASU',
        valorElemento: paymentForm,
      },
      {
        codigoElemento: 'CDIBANUM',
        valorElemento: ibanCode,
      },
      {
        codigoElemento: 'CLFILPAG',
        valorElemento: filiationCodePagador,
      },
      {
        codigoElemento: 'CDSECPAG',
        valorElemento: this.getSecuentialAddressCode(Roles.Pagador),
      },
    ];

    return policyElements;
  }

  /**
   * Gets labels for generate documentation view
   * @param {ProductModel} productModel - product model
   * @param {ParsedTableData[]} roadTypeCorpTable - corp table for address type
   * @returns {GenerateDocumentationViewLabelsModel}
   */
  public getGenerateDocumentationViewLabels(
    productModel: ComunidadesModel | ComunidadesPolicyModel,
    roadTypeCorpTable: ParsedTableData[]
  ): GenerateDocumentationViewLabelsModel {
    const model = productModel.productModel;
    const parsedRoadType = roadTypeCorpTable.find(
      roadType => roadType.value === model.riskSituation.formValues.roadType
    )?.label;
    const proposalSelectionSubtitleData = AddressUtils.normalizeAddress({
      addressType: parsedRoadType,
      addressName: model.riskSituation.formValues.roadName,
      addressNumber: model.riskSituation.formValues.roadNumber,
      zipCode: model.riskSituation.postCodeLocationInputModel.zipCode,
      cityName: model.riskSituation.postCodeLocationInputModel.city
    });
    return {
      proposalSelectionTitleLabel: this.productTranslationLabel,
      proposalSelectionSubitleLabel: 'quoteBuyGenericFlow.generateDocumentation.field.riskDirection',
      proposalSelectionSubtitleData: proposalSelectionSubtitleData,
    };
  }

  /**
   * Gets labels for offer issuance data view
   * @param {ComunidadesModel | ComunidadesPolicyModel} productModel
   * @param {ParsedTableData[]} roadTypeCorpTable - corp table for address type
   * @returns {OfferIssuanceDataModel[]}
   */
  public getOfferIssuanceViewLabels(
    productModel: ComunidadesModel | ComunidadesPolicyModel,
    roadTypeCorpTable: ParsedTableData[]
  ): OfferIssuanceDataModel[] {
    const model = productModel.productModel;
    const roadNumber = model.riskSituation.formValues.roadNumber ? ` ${model.riskSituation.formValues.roadNumber}` : '';
    const parsedRoadType = roadTypeCorpTable.find(
      roadType => roadType.value === model.riskSituation.formValues.roadType
    )?.label;
    const roadType = parsedRoadType ? `${parsedRoadType} `: '';
    
    const data = [
      {
        label: 'quoteBuyGenericFlow.offerIssuanceData.field.riskDirection',
        value:
          // eslint-disable-next-line max-len
          `${roadType}${model.riskSituation.formValues.roadName}${roadNumber} - ${
            model.riskSituation.postCodeLocationInputModel.zipCode
          } ${TextUtils.capitalizeFirstLetterOneWord(
            model.riskSituation.postCodeLocationInputModel.city.toLowerCase()
          )}`,
      },
      {
        label: 'quoteBuyGenericFlow.offerIssuanceData.field.movementEffectDate',
        value: moment(productModel.generalDataInformationModel.movementEfect).isValid() ?
          moment(productModel.generalDataInformationModel.movementEfect).format('DD/MM/YYYY') : '',
      },
      {
        label: 'quoteBuyGenericFlow.offerIssuanceData.field.movementEndDate',
        value: moment(productModel.generalDataInformationModel.movementDue).isValid() ?
          moment(productModel.generalDataInformationModel.movementDue).format('DD/MM/YYYY') : '',
      },
    ];

    if (productModel.datosCabecera.codigoNegocio && productModel.datosCabecera.nombreNegocio) {
      data.push({
        label: productModel.datosCabecera.nombreNegocio,
        value: productModel.datosCabecera.codigoNegocio,
      });
    }

    return data;
  }

  /**
   * Validates warranties configuration. Only for 'Comunidades'.
   *
   * @param {GetWarrantiesResponseDataDatosGarantias[]} warranties
   * @returns {EAValidationError[] | undefined}
   * @memberof ProductComunidades
   */
  public validateWarranties(warranties: GetWarrantiesResponseDataDatosGarantias[]): EAValidationError[] | undefined {
    const returnedErrors: EAValidationError[] = [];
    const validationErrors = [this.warrantyEmpleadosValidation(warranties)];
    validationErrors.forEach(errors => {
      if (errors && errors.length) {
        returnedErrors.push(...errors);
      }
    });
    return returnedErrors.length ? returnedErrors : undefined;
  }

  /**
   * Empleados warranty validation for a single proposalCode.
   *
   * @param {string} proposalCode
   * @param {GetWarrantiesResponseDataDatosGarantias} foundEmpleados
   * @returns {boolean}
   * @memberof ProductComunidades
   */
  public isEmpleadosValidationError(
    proposalCode: string,
    foundEmpleados: GetWarrantiesResponseDataDatosGarantias
  ): boolean {
    const ELEMENT_INFIDELIDAD = 'IMINFEMP'; // 'Infidelidad' element code
    const ELEMENT_ACCIDENTES = 'IMACCEMP'; // 'Accidentes' element code

    const isSelected =
      foundEmpleados.garantiaSeleccionada?.find(selec => selec.codigoPropuesta === proposalCode)
        ?.garantiaSeleccionada === true;
    if (isSelected) {
      const elementInfidelidad = foundEmpleados.elementosGarantias
        ?.find(selec => selec.codigoElemento === ELEMENT_INFIDELIDAD)
        ?.datosPropuestas?.find(selec => selec.codigoPropuesta === proposalCode);
      const elementAccidentes = foundEmpleados.elementosGarantias
        ?.find(selec => selec.codigoElemento === ELEMENT_ACCIDENTES)
        ?.datosPropuestas?.find(selec => selec.codigoPropuesta === proposalCode);

      const infidelidadIsEmpty =
        elementInfidelidad && (elementInfidelidad?.valorElemento === '0' || !elementInfidelidad?.valorElemento);
      const accidentsIsEmpty =
        elementAccidentes && (elementAccidentes?.valorElemento === '0' || !elementAccidentes?.valorElemento);

      if (infidelidadIsEmpty && accidentsIsEmpty) {
        return true;
      }
    }
    return false;
  }

  /**
   * Validates Empleados warranty.
   *
   * @param {GetWarrantiesResponseDataDatosGarantias[]} warranties
   * @returns {EAValidationError[] | undefined}
   * @memberof ProductComunidades
   */
  public warrantyEmpleadosValidation(
    warranties: GetWarrantiesResponseDataDatosGarantias[]
  ): EAValidationError[] | undefined {
    const WARRANTY_CODE_EMPLEADOS = '0000003261'; // Warranty code for 'Empleados'

    const errors: EAValidationError[] = [];
    const foundEmpleados = (warranties || []).find(warranty => warranty.codigoGarantia === WARRANTY_CODE_EMPLEADOS);

    if (foundEmpleados && foundEmpleados.garantiaSeleccionada && foundEmpleados.elementosGarantias) {
      const proposalCodes = (foundEmpleados.garantiaSeleccionada || [])
        .map(prop => prop.codigoPropuesta || '')
        .filter(prop => !!prop);

      for (const proposalCode of proposalCodes) {
        if (this.isEmpleadosValidationError(proposalCode, foundEmpleados)) {
          errors.push(
            new EAValidationError(
              Vue.prototype.$t(`warranties.customValidations.${WARRANTY_CODE_EMPLEADOS}.oneCapitalNeeded`).toString()
            )
          );
          break; // Stop iterating over proposals. Only 1 validation error (of this type) must be shown in screen
        }
      }
    }

    return errors.length ? errors : undefined;
  }

  /**
   * Returns the expected secuential address code for a filiation code.
   * @param {Roles} role
   * @returns {string | undefined}
   */
  public getSecuentialAddressCode(
    role: Roles
  ): string | undefined {
    return PersonUtils.getPersonByRole(this.personRoles, role)?.addressesModel.addEditAddress.selectedAddressCode;
  }

  /**
   * Sets default payment channel for this product
   * @param {QbGeneralDataInformationModel} generalDataInformationModel
   */
  public setDefaultPaymentChannel(
    generalDataInformationModel: QbGeneralDataInformationModel
  ): void {
    // No implementation necessary for this product
  }

  /**
   * Sets default payment channel for this product
   * @param {PersonRole} personModel
   * @param {GetGeneralDataResponseDataDatosObjeto[]} aditionalData
   * @param {ComunidadesModel} productModel
   * @param {boolean} doSaveObjetoasegurado
   * @returns { GetGeneralDataResponseDataDatosObjeto[] } generated offer
   */
  public generateAditionalOfferData(
    personModel: PersonRole,
    aditionalData: GetGeneralDataResponseDataDatosObjeto[],
    productModel: ComunidadesModel,
    doSaveObjetoasegurado: boolean
  ): SaveOfferDataRequestElementosPoliza[] {
    // No implementation necessary for this product
    return [];
  }
}

export default ProductComunidades;
