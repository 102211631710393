import {
  SearchOfferModel
} from '@/flows/search-offer/search-offer-model';
import {
  SearchPolicyModel
} from '@/flows/search-policy/search-policy-model';
import {
  SearchPrepolicyModel
} from '@/flows/search-prepolicy/search-prepolicy-model';
import {
  CoexistenceId
} from '@/types/coexistence/coexistence-enum.types';
import {
  FlowThunderNameEnum
} from '@/types/flow/flow-enum.types';
import {
  EACorporateTableLoadedStateModel
} from '@zurich-es-npm/ea-front-web-core/lib/store/contexts/corporate-tables/ea-corporate-tables-context-types';

// TODO ver que interface nos llevamos a un nivel superior
export interface ComprobeThunderFlow {
  codProductoTe: string;
  codProductoCo: string;
  isThunder: string;
}

export interface CorpTableData {
  data: EACorporateTableLoadedStateModel;
}

export enum TargetEnum {
  navigate = 'navigate',
  replaceCurrentFlow = 'replaceCurrentFlow'
}

// ID añadiendo interfaces libremente segun necesidad
export type NavigationDataModel =
SearchPolicyModel |
SearchOfferModel |
SearchPrepolicyModel |
ConsultClientCoexistenceNavigateData |
ConsultOfferCoexistenceNavigateData |
ConsultPolicyCoexistenceNavigateData |
ConsultSinisterCoexistenceNavigateData |
ConsultReceiptsCoexistenceNavigateData |
null;

export interface EmitNewFlowData {
  idFlow: FlowThunderNameEnum | CoexistenceId;
  target: TargetEnum;
  isThunder: boolean;
  navigationData: NavigationDataModel;
}


// COEXISTENCE FLOWS INTERFACES

export interface ConsultClientCoexistenceNavigateData {
  cdidfisc: string;
  tctidonc: string;
}

export interface ConsultReceiptsCoexistenceNavigateData{
  receiptNumber: string;
}

export interface ConsultSinisterCoexistenceNavigateData {
  cdsinies: string;
  flow: string;
}

export interface ConsultOfferCoexistenceNavigateData {
  cdnumpol: string;
}

export interface ConsultPolicyCoexistenceNavigateData {
  cdnumpol: string;
}
// Gestión cartera
export interface PortfolioManageCoexistenceNavigateData {
  codigoPoliza: string;
  versionPoliza: number;
  codigoIntermediario: string;
  fechaEfecto: string;
}
