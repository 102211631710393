<!-- eslint-disable max-lines -->
<template>
  <div>
    <ea-card>
      <div slot="cardbody" class="p-a-24 cardbody">
        <ea-heading level="4" class="m-b-16" v-if="!getTitle">
          {{ $t(`common.roles.${model.role}`) }}
        </ea-heading>
        <h2 v-if="getTitle" class="m-b-16">{{ $t(`common.roles.${model.role}`) }}</h2>
        <ea-paragraph
          size="medium"
          class="m-r-8 m-b-8 m-t-8"
          v-if="showSearchExplanation">
          {{ searchExplanation }}
        </ea-paragraph>
        
        <ea-row>
          <ea-col v-if="model.role === Roles.Conductor">
            <policy-holder-vs-driver-person
              id="policy-holder-vs-driver-person"
              ref="driver"
              :person="model"
              :vehiclesList="vehiclesList"
              @onGetVehicleSearched="onGetVehicleSearched"
              @onDriverRadioChange="onDriverRadioChange"
            ></policy-holder-vs-driver-person>
          </ea-col>

          <ea-col v-else>
            <policy-holder-vs-insured-person
              id="policy-holder-vs-insured-person"
              :disabled="disabled || !canBeChanged"
              :person="model"
              @equalStatementRadioBtnChange="onPersonRoleEqualStatementRadioBtnChange"
            ></policy-holder-vs-insured-person>
          </ea-col>
        </ea-row>
        <ea-form
          ref="form"
          :model="model.searchModel.searchPersonData"
          :validationOptions="formValidationOptions"
          :validateOnRuleChange="false"
          v-if="model.searchModel.searchPersonData"
        >
          <div v-if="showSearchPerson">
            <ea-row extraClass="d-display-flex d-wrap d-align-items-flex-end">
              <ea-col :span="6">
                <ea-form-item
                  :label="$t('quoteBuyGenericFlow.searchPerson.field.documentType')"
                  prop="documentType"
                  required
                >
                  <ea-select
                    v-model="model.searchModel.searchPersonData.documentType"
                    :placeholder="disabled ? '' : $t('common.label.select')"
                    :readonly="disabled"
                    @change="onDocumentTypeChange"
                  >
                    <ea-option
                      v-for="documentType in getDocumentTypeList"
                      :key="documentType.value"
                      :label="documentType.label"
                      :value="documentType.value"
                    />
                  </ea-select>
                </ea-form-item>
              </ea-col>

              <ea-col :span="6">
                <ea-form-item
                  :label="$t('quoteBuyGenericFlow.searchPerson.field.documentNumber')"
                  prop="documentNumber"
                  class="false-required"
                >
                  <div slot="tooltipIconInfo">
                    <div slot="content">
                      <i18n path="quoteBuyGenericFlow.searchPerson.documentTypeTootlip" tag="p" class="footer">
                        <br />
                      </i18n>
                    </div>
                  </div>
                  <ea-input-text
                    v-model="model.searchModel.searchPersonData.documentNumber"
                    :readonly="disabled"
                    @change="onDocumentNumberChange"
                  />
                </ea-form-item>
              </ea-col>

              <ea-col :span="6">
                <ea-button
                  v-if="!disabled"
                  type="secondary"
                  class="m-b-32"
                  @click="searchPerson()"
                >
                  {{ $t('common.label.search') }}
                </ea-button>
              </ea-col>
            </ea-row>

            <ea-row v-if="isDocumentTypeSelected && !isSearchPersonJuridicalPerson">
              <ea-col :span="6">
                <ea-form-item
                  :label="$t('quoteBuyGenericFlow.searchPerson.field.firstName')"
                  prop="firstName"
                  class="false-required"
                >
                  <ea-input-text v-model="model.searchModel.searchPersonData.firstName" />
                </ea-form-item>
              </ea-col>
              <ea-col :span="6">
                <ea-form-item
                  :label="$t('quoteBuyGenericFlow.searchPerson.field.lastName')"
                  prop="lastName"
                  class="false-required"
                >
                  <ea-input-text v-model="model.searchModel.searchPersonData.lastName" />
                </ea-form-item>
              </ea-col>
              <ea-col :span="6">
                <ea-form-item
                  :label="$t('quoteBuyGenericFlow.searchPerson.field.lastName2')"
                  prop="lastName2"
                  v-bind:class="{'false-required': isLastName2FalseRequired}"
                >
                  <ea-input-text v-model="model.searchModel.searchPersonData.lastName2" />
                </ea-form-item>
              </ea-col>
            </ea-row>

            <ea-row v-if="isDocumentTypeSelected && isSearchPersonJuridicalPerson">
              <ea-col :span="6">
                <ea-form-item
                  :label="$t('quoteBuyGenericFlow.searchPerson.field.businessName')"
                  prop="businessName"
                  class="false-required"
                >
                  <ea-input-text v-model="model.searchModel.searchPersonData.businessName" />
                </ea-form-item>
              </ea-col>
            </ea-row>
          </div>

          <search-person-results-table
            :resultsTableVisible="resultsTableVisible"
            :personList="model.searchModel.personList"
            @closeResultsDialog="closeResultsDialog"
            @selectPersonAndClose="selectPersonAndClose"
          ></search-person-results-table>
        </ea-form>
        
        <div v-if="isAnyPersonSelected">
          <show-person-info
            id="showPersonInfo"
            ref="showPersonInfoComp"
            :role="model.role"
            :selectedPerson="model.searchModel.selectedPerson"
            :documentTypeList="getDocumentTypeList"
            :countryList="countryList"
            :taxTreatmentArea="model.taxTreatmentArea"
            :taxTreatmentAreaList="taxTreatmentAreaTable"
            :disabled="disabled"
            :canBeChanged="canBeChanged && !axesorPersonFound"
            :shouldShowNewSearchInShowPersonInfo="shouldShowNewSearchInShowPersonInfo"
            :showTaxTreatmentAreaField="showTaxTreatmentAreaField"
            :consultaOperation="consultaOperation"
            :additionalElements="additionalElements"
            :policyElementsScore="policyElementsScore"
            :scoringFieldsInvalidated="scoringFieldsInvalidated"
            @enterEditionMode="enterEditionMode"
            @resetSelection="onResetSelection()"
            @changeRadio="onRoleRadioChange"
            v-model="model.showPersonInfoModel"
          ></show-person-info>
        </div>

        <!-- FACTURACIÓN TRC -->
        <div v-show="isShowBillingField">
          <qb-billing-data
            id="qb-billing-data"
            ref="billingDataRef"
            v-model="model.billing"
            :isReadonly="axesorPersonFound"
            @updateBillingData="onUpdateBillingData"
          ></qb-billing-data>
        </div>

        <div v-if="addressesVisibility">
          <qb-addresses-person
            ref="addressesRef"
            id="addresses-person"
            v-model="model.addressesModel"
            :showNationalitySelector="showNationalitySelector"
            :consultaOperation="consultaOperation"
            :isPhoneRequired="isPhoneRequired"
            :isEmailRequired="isEmailRequired"
            :documentationClientData="documentationClientData"
            @refreshData="onRefreshAddressesData"
            @handleGenericError="onHandleGenericError"
            @updateViewModel="updateViewModel"
            @showError="onShowError"
          ></qb-addresses-person>
        </div>

        <div v-if="isIbanBlockVisible">
          <ea-row class="m-t-16">
            <ea-col>
              <qb-iban-code
                v-model="model.ibanCodeModel"
                id="qb-iban-code"
                :documentationClientData="documentationClientData"
                :consultaOperation="consultaOperation"
                :isIbanRequired="isIbanRequired"
                @refreshData="onRefreshIbanData"
                @showError="onShowError"
                @updateParentModel="updateViewModel"
                @handleGenericError="onHandleGenericError"
              ></qb-iban-code>
            </ea-col>
          </ea-row>
        </div>

        <div
          v-if="model.searchModel.driverListTable.driverList.length > 0 &&
          !hiddeVehicleTable &&
          model.role === Roles.Conductor">
          <ea-row class="m-t-16">
            <ea-col>
              <fleets-intervening-driver-table
                id="fleets-intervening-driver-table"
                v-model="model.searchModel.driverListTable.driverList"
                :addDrivers="model.searchModel.selectedDrivers"
                @onEditDriverPerson="onEditDriverPerson"
                @driversToDelete="onDriversToDelete"
              ></fleets-intervening-driver-table>
            </ea-col>
          </ea-row>
        </div>
      </div>
    </ea-card>

    <ea-dialog
      :title="$t('quoteBuyGenericFlow.searchPerson.noSearchResults.title')"
      :visible="noResultsDialogVisible"
      @close="closeNoResultsDialog"
      type="passive"
    >
      <p>{{ model.searchModel.noResultsDialogMessage }}</p>

      <div slot="footer" class="d-display-flex d-justify-flex-end">
        <ea-button type="secondary" @click="onSearchAgain()" class="m-r-16">
          {{ $t('quoteBuyGenericFlow.searchPerson.searchClient') }}
        </ea-button>
        <ea-button type="primary" @click="addPerson()">
          {{ $t('quoteBuyGenericFlow.searchPerson.addClient') }}
        </ea-button>
      </div>
    </ea-dialog>

    <!-- Generic error component. Will trigger extra action on close if search fails -->
    <qb-generic-error
      id="genericError"
      v-model="model.searchModel.genericErrorModel"
      :modalData="genericErrorData"
      :isVisible="isGenericErrorVisible"
      @close="closeGenericErrorDialog"
    ></qb-generic-error>

    <add-edit-person
      id="add-edit-person"
      v-if="isPersonEditionVisible"
      :role="model.role"
      :addEditPersonForm="model.searchModel.addEditPersonForm"
      :documentTypeList="getDocumentTypeList"
      :countryList="countryList"
      :isAdding="model.searchModel.isAdding"
      :isEditting="model.searchModel.isEditting"
      :dateHandleFormat="model.searchModel.dateHandleFormat"
      :taxTreatmentAreaList="taxTreatmentAreaTable"
      :showDocumentInvalidError="model.searchModel.showDocumentInvalidError"
      :showTaxTreatmentAreaField="showTaxTreatmentAreaField"
      @cancel="cancelAddEdit"
      @save="saveAddEdit"
      @clearFlowErrors="onClearFlowErrors"
    >
    </add-edit-person>

    <!-- MODEL FOR DRIVER -->
    <add-edit-driver-person
      id="add-edit-driver-person"
      v-if="addEditDriverModal"
      :addEditPersonForm="model.searchModel.addEditPersonForm"
      :documentTypeList="documentTypeList"
      :countryList="countryList"
      :isAdding="isAddDriver"
      :isEditting="isEditDriver"
      :vehicleData="vehicleData"
      :showDocumentInvalidError="model.searchModel.showDocumentInvalidError"
      @cancel="cancelAddEdit"
      @save="saveAddEdit"
    ></add-edit-driver-person>
  </div>
</template>

<script lang="ts">
/* eslint-disable max-lines */
import {
  Component, Prop, Watch
} from 'vue-property-decorator';
import {
  mixins
} from 'vue-class-component';
import {
  EAApplicationLogger,
  EABusinessComponent,
  EAError,
  EAMethod,
  EAMultiError,
  EAValidationError,
  ResponseWithErrors,
  throwIfResponseHasErrors,
} from '@zurich-es-npm/ea-front-web-core';
import {
  EAFormValidationOptions,
  eaRequiredValidation,
  EAValidation,
  Form,
} from '@zurich-es-npm/ea-front-web-ui';
import {
  GetPersonsRequest,
  EAGetPersonsApi,
  GetPersonsRequestTipoDocumentoEnum as TipoDocumentoEnum,
  GetPersonsRequestTipoPersonaEnum as TipoPersonaEnum,
  GetPersonsResponseDatosPersona,
  GetPersonsResponse,
  GetPersonsResponseListaPersonas,
  GetPersonsRequestTipoPersonaEnum,
  GetPersonsRequestTipoDocumentoEnum,
} from '../../services/V1/quoteAndBuy/getPersonsOperation/post';
import {
  GetPersonsAxesorRequest,
  EAGetPersonsAxesorApi,
  GetPersonsAxesorResponse
} from '../../services/V1/quoteAndBuy/getPersonsAxesorOperation/post';
import {
  EAGetDriverPersonDataApi, GetDriverPersonDataResponse
} from '../../services/V1/fleets/getDriverPersonDataOperation/post';
import {
  EAEditPersonApi,
  EditPersonRequest,
  EditPersonRequestDatosBasicosPersonaTipoDocumentoEnum,
  EditPersonRequestDatosBasicosPersonaTipoPersonaEnum,
  EditPersonRequestMarketingCodigoOperacionPersonaEnum,
  EditPersonRequestMarketingEstadoPersonaEnum,
  EditPersonResponse,
} from '../../services/V1/quoteAndBuy/editPersonOperation/post';
import {
  EASaveFleetDriverPersonApi
} from '../../services/V1/fleets/saveFleetDriverPersonOperation/post';
import AddEditPersonComponent from './components/add-edit-person-component.vue';
import AddEditDriverPersonComponent from './components/add-edit-driver-person-component.vue';
import QbShowPersonInfoComponent from '../qb-show-person-info/qb-show-person-info-business-component.vue';
import QbGenericErrorBusiness, {
  GenericErrorData
} from '../qb-generic-error/qb-generic-error-business.vue';
import moment from 'moment';
import {
  cloneDeep as _cloneDeep
} from 'lodash';
import PostCodeLocationInputModel from '../post-code-location-input/post-code-location-input-model';
import SearchPersonResultsTableComponent from './components/search-person-results-table.vue';
import {
  GetGeneralDataResponseDataDatosCabeceraElementosPoliza,
  GetGeneralDataResponseDataDatosCabeceraTablaRestricciones, GetGeneralDataResponseDataDatosObjeto
} from '@/services/V1/quoteAndBuy/getGeneralDataOperation/post';
import {
  SelectionInFormTabRole,
  PersonRole
} from '@/utils/quote-buy-product-factory/types/product-role-types';
import PolicyHolderVsInsuredPersonComponent from './components/policy-holder-vs-insured-person-component.vue';
import {
  SearchPersonUtils
} from './utils/qb-search-person-utils';
import {
  ParsedTableData
} from '@/utils/corporate-tables';
import QbAddressesPersonBusiness from '../addresses/qb-addresses-person.vue';
import QbAddressesPersonModel from '../addresses/qb-addresses-person-model';
import QbIbanCodeBusiness from '../qb-iban-code/qb-iban-code-business.vue';
import {
  AddEditPersonForm, SearchPersonData, SelectDriverInterface
} from './qb-search-person-model';
import {
  GetIntermediaryBusinessProductListRequestCodigoRamoEnum
} from '@/services/V1/quoteAndBuy/getIntermediaryBusinessProductListOperation/post';
import {
  Roles
} from '@/types/roles/roles-enum.types';
import {
  SetPersonAddressesRequestDomicilios
} from '@/services/V1/persons/setPersonAddressesOperation/post';
import QbIbanCodeModel from '../qb-iban-code/qb-iban-code-model';
import {
  NotificationsTypeEnum, NotificationsUtils
} from '@/utils/notifications/notifications-utils';
import PolicyHolderVsDriverPersonComponent from './components/policy-holder-vs-driver-person-component.vue';
import {
  VehicleFormModel
} from '../fleet-manual-upload-vehicle/fleet-manual-upload-vehicle-model';
import FleetsInterveningDriverTableBusiness
  from '../fleets-intervening-driver-table/fleets-intervening-driver-table-business.vue';
import {
  DriverListInterface
} from '../fleets-intervening-driver-table/fleets-intervening-driver-table-model';
import {
  DocumentationClientData
} from '@/presentational-components/clientBasicInfo/clientBasicInfo.type';
import QbBillingDataBusiness from '../qb-billing-data/qb-billing-data-business.vue';
import {
  DocumentType
} from '@/types/document-types/document-types-enum.types';

export interface SelectPersonEventData {
  selectedPerson: GetPersonsResponseDatosPersona;
  role: Roles;
  showEqualityErrorMessage: boolean;
  taxTreatmentArea?: string;
  updateDocLanguageValue?: string;
  billingValue?: number;
}

export interface RefreshAddressesEventData {
  role: Roles;
  lastAddedAddress: SetPersonAddressesRequestDomicilios;
}

export interface RefreshIbanEventData {
  role: Roles;
  lastAddedIban: string;
}

@Component({
  name: 'qb-search-person',
  components: {
    AddEditPerson: AddEditPersonComponent,
    ShowPersonInfo: QbShowPersonInfoComponent,
    SearchPersonResultsTable: SearchPersonResultsTableComponent,
    QbGenericError: QbGenericErrorBusiness,
    PolicyHolderVsInsuredPerson: PolicyHolderVsInsuredPersonComponent,
    QbAddressesPerson: QbAddressesPersonBusiness,
    QbIbanCode: QbIbanCodeBusiness,
    PolicyHolderVsDriverPerson: PolicyHolderVsDriverPersonComponent,
    FleetsInterveningDriverTable: FleetsInterveningDriverTableBusiness,
    AddEditDriverPerson: AddEditDriverPersonComponent,
    QbBillingData: QbBillingDataBusiness
  },
})

/**
 * Business Component qb-search-person
 */
export default class QbSearchPersonBusiness extends mixins<EABusinessComponent<PersonRole>>(
  EABusinessComponent
) {
  Roles = Roles;

  @Prop()
    codigoRamo?: string;

  @Prop()
    taxTreatmentAreaTable?: GetGeneralDataResponseDataDatosCabeceraTablaRestricciones[];

  @Prop()
    documentTypeList?: ParsedTableData[];

  @Prop()
    countryList?: ParsedTableData[];

  @Prop()
    disabled!: boolean;

  @Prop()
    canBeChanged!: boolean;

  @Prop()
    emisionData?: boolean;

  @Prop()
    isNewOffer!: boolean;

  @Prop()
    addressesVisibility?: boolean;

  @Prop({
    required: false,
    'default': () => false,
  })
    consultaOperation?: boolean;

  @Prop()
    indicadorDataQuality?: boolean;

  @Prop({
    'default': false
  })
    isPersonEqualToAnotherRole?: boolean;

  @Prop({
    'default': false
  })
    isIbanBlockVisible?: boolean;

  @Prop({
    'default': false
  })
    isIbanRequired?: boolean;

  @Prop({
    'default': false
  })
    showNationalitySelector?: boolean;

  @Prop({
    'default': false
  })
    showBillingField?: boolean;

  @Prop()
    vehiclesList?: VehicleFormModel[];

  @Prop()
    documentationClientData!: DocumentationClientData;

  @Prop()
    interveningFlow?: boolean;

  @Prop({
    required: false,
    'default': () => true,
  })
    showTaxTreatmentAreaField?: boolean;

  @Prop({
    'default': () => true
  })
    shouldShowNewSearchInShowPersonInfo?: boolean;
        
  @Prop()
    additionalElements?: GetGeneralDataResponseDataDatosObjeto[];

  @Prop()
    policyElementsScore?: GetGeneralDataResponseDataDatosCabeceraElementosPoliza[];

  @Prop()
    scoringFieldsInvalidated?: boolean;
  
  /**
   * Indicates if we want to update "birth date, sex and country" when editting a person
   *  This is because we don't want to update these fields until "offer issuance" step when policyType === 'Offer'
   */
  @Prop({
    'default': () => true
  })
    updateGeneralDataParty?: boolean;

  @Prop({
    'default': () => []
  })
    axesorSearchDocumentTypes?: DocumentType[];

  public genericErrorData: GenericErrorData = {};

  public isGenericErrorVisible: boolean = false;

  public existVehicleDriver: boolean = false;

  public hiddeVehicleTable: boolean = true;

  public plateNumberSelected: string = '';

  public isEditDriver: boolean = false;

  public isAddDriver: boolean = false;

  public vehicleData: VehicleFormModel | undefined = undefined;

  public driverFilationCode: string | undefined = undefined;

  public isEmailRequired?: boolean = false;

  public isPhoneRequired?: boolean = false;

  public axesorPersonFound?: boolean = false;

  /**
   * Updates model + emits event
   */
  @EAMethod()
  created() {

    /*
     * Get drivers data from vehiclesList
     * vehiclesList is getting in the intervening-data-view
     */
    
    if (this.model.role === Roles.Conductor) {
      this.getDriverTableList();
    }
  }


  /**
   * Updates model + emits event
   */
  @EAMethod()
  public async onUpdateBillingData() {
    this.update();

    this.$nextTick(() => {
      this.$emit('updateBilling');
    });
  
  }

  /**
   * Updates model + emits event
   */
  @EAMethod()
  public async updateViewModel() {
    this.updateNationalityField();
    //Validar score fields si fuese necesario
    if (this.isAnyPersonSelected) {
      const showInfoPersonComp = this.$refs.showPersonInfoComp as QbShowPersonInfoComponent;
      await showInfoPersonComp.validateScoreFields();
      showInfoPersonComp.update();
    }

    /**
     * We use a promise here because $nextTick is not compatible with async/await
     */
    await new Promise(resolve => {
      this.$nextTick(() => {
        this.update();
        resolve(0);
      });
    });
  }
  
  /**
   * Updates nationality field if neccessary
   */
  updateNationalityField() {
    if (this.model.addressesModel.selectNationality && this.model.searchModel.selectedPerson?.datosGeneralesPersona) {
      this.model.searchModel.selectedPerson.datosGeneralesPersona.nacionalidadPersona =
        this.model.addressesModel.selectNationality.selectedNationality;
    }
  }

  /**
   * Sets documentType default value depending on the product
   */
  @Watch('model.searchModel.searchPersonData.documentType', {
    immediate: true,
  })
  setDocumentTypeDeafultValue() {
    const selectDocumentTypeValue = this.model.searchModel.searchPersonData.documentType;
    if ((this.codigoRamo === GetIntermediaryBusinessProductListRequestCodigoRamoEnum.THCO //Comunidades
      && selectDocumentTypeValue === '') ||
      (this.interveningFlow && selectDocumentTypeValue === '' &&
      this.model.role !== Roles.Conductor) ||
      (this.codigoRamo === GetIntermediaryBusinessProductListRequestCodigoRamoEnum.ZTRC //Construcción
      && selectDocumentTypeValue === '')
    ) {
      this.model.searchModel.searchPersonData.documentType = 'C'; // CIF
    } else if (this.codigoRamo !== GetIntermediaryBusinessProductListRequestCodigoRamoEnum.THCO //Other prod
      && selectDocumentTypeValue === '') {
      this.model.searchModel.searchPersonData.documentType = 'N'; // NIF
    }
  }

  /**
   * Watcher for indicadorDataQuality // to condition mandatoriness on phone and email
   */
  @Watch('indicadorDataQuality', {
    immediate: true,
  })
  indicadorDataQualityWatcher() {
    this.setEmailPhoneMandatoriness();
  }

  /**
   * Watcher for tipoDocumento // to condition mandatoriness on phone and email
   */
  @Watch('model.searchModel.selectedPerson.datosBasicosPersona.tipoDocumento', {
    immediate: true,
  })
  tipoDocumentoWatcher() {
    this.setEmailPhoneMandatoriness();
  }

  /**
   * Sets mandatoriness for phone and email
   */
  setEmailPhoneMandatoriness() {
    this.isPhoneRequired = false;
    this.isEmailRequired = false;
    const documentType = this.model.searchModel.selectedPerson?.datosBasicosPersona?.tipoDocumento || '';
    const personType = this.getPersonType(documentType);
    if (personType === 'F' &&
      this.indicadorDataQuality === true &&
      this.model.role === Roles.Tomador) {
      this.isPhoneRequired = true;
      this.isEmailRequired = true;
    }
  }

  /**
   * Validates search form before performing search
   */
  @EAMethod()
  async searchPerson() {
    this.clearFlowErrors();

    if (this.shouldThrowInsufficientDataError()) {
      throw new EAValidationError(this.$t('quoteBuyGenericFlow.searchPerson.validation.insufficientData').toString());
    }
    
    try {
      await this.validation()?.validate();
    } catch (err) {
      // Design System will show form errors
      return;
    }

    const personInput = this.getPersonServiceInput(this.model.searchModel.searchPersonData);

    if (
      this.axesorSearchDocumentTypes?.includes(this.model.searchModel.searchPersonData.documentType as DocumentType)
    ) {
      await this.performAxesorSearchPerson(personInput as GetPersonsAxesorRequest);
    } else {
      await this.performSearchPerson(personInput);
    }
  }

  /**
   * Perform search person with AXESOR
   * @param { GetPersonsAxesorRequest } personInput
   * @param { string | undefined} codigoFiliacion
   */
  @EAMethod({
    loading: true,
  })
  async performAxesorSearchPerson(personInput: GetPersonsAxesorRequest): Promise<void> {
    this.model.billing.billingValue = undefined;
    this.axesorPersonFound = false;

    const api = new EAGetPersonsAxesorApi();
    const personResponse = await api.getPersonsAxesorOperation({
      getPersonsAxesorRequest: personInput
    });

    if (personResponse) {
      if (personResponse.errors && personResponse.errors[0]) {
        this.handleSearchPersonErrors(personResponse as GetPersonsResponse);
      } else {
        await this.handleAxesorSearchPersonResponse(personResponse);
        NotificationsUtils.clearNotifications();
      }

      this.model.searchModel.searchPerformed = true;
    }
  }

  /**
   * Handle search person response
   * @param { GetPersonsAxesorResponse } response
   */
  @EAMethod({
    loading: true,
  })
  async handleAxesorSearchPersonResponse(response: GetPersonsAxesorResponse) {
    if (response.listaPersonas) {
      // Received more than 1 result => Show table
      this.model.searchModel.isResultsTableVisible = true;
      this.model.searchModel.personList = response.listaPersonas as GetPersonsResponseListaPersonas[];
    } else {
      // Assign received budget
      this.model.billing.billingValue = response.facturacionEmpresa;
      this.axesorPersonFound = response.indicadorDatosEncontradosAxesor;

      // Set person
      this.selectPersonFromSearchPersonResponse(response as GetPersonsResponse);
    }
  }

  /**
   * Main function for search person
   * @param {GetPersonsRequest} personServiceInput
   */
  @EAMethod({
    loading: true,
  })
  async performSearchPerson(personServiceInput: GetPersonsRequest) {
    this.model.searchModel.personList = [];
    this.model.searchModel.selectedPerson = null;
  
    try {
      const api = new EAGetPersonsApi();
      const personResponse = await api.getPersonsOperation({
        getPersonsRequest: personServiceInput,
      });

      if (personResponse) {
        if (personResponse.errors && personResponse.errors[0]) {
          this.handleSearchPersonErrors(personResponse);
        } else {
          await this.selectPersonFromSearchPersonResponse(personResponse);
          NotificationsUtils.clearNotifications();
        }

        this.model.searchModel.searchPerformed = true;
      }
    } catch (err) {
      await this.handleApiError('QbSearchPersonBusiness::performSearchPerson', err);
    }
  }

  /**
   * Selects person from service response
   * @param {GetPersonsResponse} personResponse
   */
  async selectPersonFromSearchPersonResponse(personResponse: GetPersonsResponse) {
    if (personResponse.listaPersonas) {
      // Received more than 1 result
      this.model.searchModel.isResultsTableVisible = true;
      this.model.searchModel.personList = personResponse.listaPersonas;
    } else if (personResponse.datosPersona) {

      // Set driver for fleet flow
      if (this.model.role === Roles.Conductor) {
      
        // Set the driver person when search
        this.model.searchModel.addEditPersonForm = this.parseGetPersonsResponseDatosPersonaToAddEditPersonForm(
          personResponse.datosPersona
        );
        // Open the edit driver modal
        this.isAddDriver = true;
      } else {

        /**
         * Emit event => Parent component will decide if selected person is valid or not.
         * It will be invalid if selected person is already selected in another role
         */
        this.$emit('selectPerson', {
          selectedPerson: personResponse.datosPersona,
          role: this.model.role,
          showEqualityErrorMessage: true,
          // Used when adding/editting:
          taxTreatmentArea: this.model.searchModel.addEditPersonForm?.taxTreatmentArea,
          updateDocLanguageValue: this.model.searchModel.addEditPersonForm?.updateDocLanguageValue,
          billingValue: this.model.billing.billingValue
        });
      }
    }
  }

  /**
   * Update driverList for the table
   *
   */
  updateVehicleData() {
    const findVehicle =
      this.model.searchModel.selectedDrivers.find(item => item.vehicle?.plateNumber === this.plateNumberSelected);

    if (findVehicle) {
      const updateVehicle = {
        documentNumber: findVehicle.driver?.documentNumber,
        firstName: findVehicle.driver?.firstName,
        lastName: findVehicle.driver?.lastName,
        plateNumber: findVehicle.vehicle?.plateNumber,
        brand: findVehicle.vehicle?.vehicleBrand,
        model: findVehicle.vehicle?.vehicleModel,
        version: findVehicle.vehicle?.vehicleVersion,
      };
        // Delete old element and substitue by a new one
      const findIndex = this.model.searchModel.driverListTable.driverList.findIndex(
        item => item.plateNumber === this.plateNumberSelected
      );

      if (findIndex === -1) {
        this.model.searchModel.driverListTable.driverList.push(updateVehicle);
      } else {
        this.model.searchModel.driverListTable.driverList.splice(findIndex, 1, updateVehicle);
      }
    }
  }

  /**
   * Set driver data person in selectedDrivers
   * @param {AddEditPersonForm} datosPersona - Received response
   * @param {string} driverFiliationCode
   * 
   */
  setDriverPersonData(datosPersona: AddEditPersonForm, driverFiliationCode: string): void {
    // Find the vehicle and set person data
    this.model.searchModel.selectedDrivers = this.model.searchModel.selectedDrivers.map(item => {
      if (item.vehicle?.plateNumber === this.plateNumberSelected) {
        return {
          ...item,
          driver: {
            firstName: datosPersona.firstName.toUpperCase(),
            lastName: datosPersona.lastName.toUpperCase(),
            lastName2: datosPersona.lastName2.toUpperCase(),
            gender: datosPersona.gender || undefined,
            birthDate: datosPersona.birthDate ?
              new Date(datosPersona.birthDate) :
              undefined,
            country: datosPersona.country,
            nationality: datosPersona.nationality,
            documentNumber: datosPersona.documentNumber,
            documentType: datosPersona.documentType,
            businessName: '',
            zipCode: '',
            city: datosPersona.city,
            driverFiliationCode
          }
        };
      }
      return item;
    });
  
    this.updateVehicleData();
 
    // Reset all
    this.$nextTick(() => {
      const plateNumberForm = this.$refs.driver as PolicyHolderVsDriverPersonComponent;
      plateNumberForm.formValues.plateNumber = '';
    });
    this.existVehicleDriver = false;
    this.onSearchAgain();
    this.model.searchModel.addEditPersonForm = null;
    this.update();

    this.$emit('selectDriver');
  }


  /**
   * Handles search person service errors
   * @param {GetPersonsResponse} searchPersonResponse - Received response
   */
  handleSearchPersonErrors(searchPersonResponse: GetPersonsResponse) {
    const errorNotFound = searchPersonResponse.errors?.find(error => error.code === 'ZON00031');
    const restrictedDataError = searchPersonResponse.errors?.find(error => error.code === 'ZON00220');
    if (errorNotFound || restrictedDataError) {
      this.model.searchModel.isNoResultsDialogVisible = true;
      if (errorNotFound) {
        this.model.searchModel.noResultsDialogMessage = errorNotFound?.message;
      } else {
        this.model.searchModel.noResultsDialogMessage = restrictedDataError?.message || '';
      }
    } else if (searchPersonResponse.errors) {

      const incorrectNameErr = searchPersonResponse.errors?.find(err => err.code === 'ZON00032');
      
      if (incorrectNameErr) {
        NotificationsUtils.clearNotifications();
        this.selectPersonFromSearchPersonResponse(searchPersonResponse);
        NotificationsUtils.launchNotifications([{
          title: 'Error',
          message: searchPersonResponse.errors?.[0].message,
          type: NotificationsTypeEnum.Warning
        }]);
      } else {
        throwIfResponseHasErrors(searchPersonResponse as ResponseWithErrors);
      }
    }
  }
  
  /**
   * Get person service input based on provided form
   * @param {SearchPersonData | AddEditPersonForm} form - provided form
   * @param {string} codigoFiliacion - persón's affiliation code if present
   * @return {GetPersonsRequest} person service input
   */
  getPersonServiceInput(form: SearchPersonData | AddEditPersonForm, codigoFiliacion?: string): GetPersonsRequest {
    const personType = this.getPersonType(form.documentType);

    if (personType === 'J') {
      const nameSearchData = this.getJuridicalPersonNameData(form.businessName);
      form.lastName = nameSearchData[0];
      form.lastName2 = nameSearchData[1];
      form.firstName = nameSearchData[2];
    }

    const inputMessage = {
      tipoPersona: personType as TipoPersonaEnum,
      tipoDocumento: form.documentType as TipoDocumentoEnum,
      codigoIdentificacionFiscal: form.documentNumber.toUpperCase(),
      nombrePropio: form.firstName.trim(),
      primerApellido: form.lastName.trim(),
      segundoApellido: form.lastName2.trim(),
      codigoFiliacion
    };

    return inputMessage;
  }

  /**
   * Gets data divided in order to perform juridical person search
   * @Param {string} businessName - Juridical person business name
   * @return { string[] } data for lastName, lastName2, name fields
   */
  getJuridicalPersonNameData(businessName: string): string[] {
    return [businessName.substring(0, 30), businessName.substring(30, 60), businessName.substring(60, 100)];
  }

  /**
   * Returns validation object
   * @returns {EAValidation}
   */
  public validation(): EAValidation {
    const form = this.$refs.form as Form;
    return form?.validation();
  }

  /**
   * Closes results table dialog without selecting person
   */
  closeResultsDialog() {
    this.model.searchModel.selectedPerson = null;
    this.model.searchModel.isResultsTableVisible = false;
  }

  /**
   * Handles search again button
   * Resets form + closes no results dialog
   */
  onSearchAgain() {
    this.model.searchModel.searchPersonData = {
      documentType: '',
      documentNumber: '',
      firstName: '',
      lastName: '',
      lastName2: '',
      businessName: ''
    };
    this.closeNoResultsDialog();
    if (this.model.role !== Roles.Conductor) {
      this.update();
    }
    this.$emit('clearPerson', this.model);
  }

  /**
   * Closes no results dialog
   */
  closeNoResultsDialog() {
    this.model.searchModel.isNoResultsDialogVisible = false;
  }

  /**
   * Closes generic dialog + triggers close modal action
   */
  closeGenericErrorDialog() {
    this.isGenericErrorVisible = false;

    if (this.genericErrorData.closeAction) {
      this.genericErrorData.closeAction();
    }
  }

  /**
   * @Param { GetPersonsResponseListaPersonas | undefined } tableSelectedPerson - Selected person in results table
   * Closes the dialog selecting a person
   * Calls method which provides person full data
   */
  @EAMethod()
  selectPersonAndClose(tableSelectedPerson: GetPersonsResponseListaPersonas | undefined) {
    if (!tableSelectedPerson) {
      return;
    }

    this.model.searchModel.isResultsTableVisible = false;

    const newSearchInput: GetPersonsRequest = {
      tipoPersona: tableSelectedPerson.tipoPersona ?
        tableSelectedPerson.tipoPersona as unknown as TipoPersonaEnum : undefined,
      tipoDocumento: tableSelectedPerson.tipoDocumento ?
        tableSelectedPerson.tipoDocumento as unknown as TipoDocumentoEnum : undefined,
      codigoIdentificacionFiscal: tableSelectedPerson.codigoIdentificacionFiscal?.toUpperCase(),
      nombrePropio: tableSelectedPerson.nombrePropio,
      primerApellido: tableSelectedPerson.primerApellido,
      segundoApellido: tableSelectedPerson.segundoApellido,
      codigoFiliacion: tableSelectedPerson.codigoFiliacion
    };

    if (
      this.axesorSearchDocumentTypes?.includes(this.model.searchModel.searchPersonData.documentType as DocumentType)
    ) {
      this.performAxesorSearchPerson(newSearchInput as GetPersonsAxesorRequest);
    } else {
      this.performSearchPerson(newSearchInput);
    }
  }

  /**
   * Opens Add Person Business Component
   */
  @EAMethod()
  addPerson() {
    this.closeNoResultsDialog();
    this.model.searchModel.addEditPersonForm =
      this.parseFromSearchPersonDataToAddEditPersonForm(this.model.searchModel.searchPersonData);
    if (this.model.role === Roles.Conductor) {
      this.isAddDriver = true;
    } else {
      this.model.searchModel.isAdding = true;
    }

  }

  /**
   * Shows edition mode
   */
  enterEditionMode() {
    if (!this.model.searchModel.selectedPerson) {
      return;
    }

    this.model.searchModel.addEditPersonForm = this.parseGetPersonsResponseDatosPersonaToAddEditPersonForm(
      this.model.searchModel.selectedPerson
    );
    this.model.searchModel.isEditting = true;
  }

  /**
   * Parses from SearchPersonData type to AddEditPersonForm type
   * @param { SearchPersonData } searchPersonData - Object to be parsed
   * @return { AddEditPersonForm } parsed object
   */
  parseFromSearchPersonDataToAddEditPersonForm(searchPersonData: SearchPersonData): AddEditPersonForm {
    return {
      documentType: searchPersonData?.documentType,
      documentNumber: searchPersonData?.documentNumber,
      firstName: searchPersonData?.firstName,
      lastName: searchPersonData?.lastName,
      lastName2: searchPersonData?.lastName2,
      businessName: searchPersonData?.businessName,
      gender: undefined,
      country: 'ESP',
      birthDate: undefined,
      driverLisenceDate: undefined,
      zipCode: '',
      city: '',
      taxTreatmentArea: 'E',
      nationality: searchPersonData?.documentType === 'N' ? 'ESP' : ''
    };
  }

  /**
   * Parses from GetPersonsResponseDatosPersona type to AddEditPersonForm type
   * @param { GetPersonsResponseDatosPersona } getPersonsResponseDatosPersona - Object to be parsed
   * @return { AddEditPersonForm } parsed object
   */
  parseGetPersonsResponseDatosPersonaToAddEditPersonForm(
    getPersonsResponseDatosPersona: GetPersonsResponseDatosPersona
  ): AddEditPersonForm {
    const documentType = getPersonsResponseDatosPersona?.datosBasicosPersona?.tipoDocumento || '';
    const documentNumber = getPersonsResponseDatosPersona?.datosBasicosPersona?.codigoIdentificacionFiscal || '';

    return {
      documentType,
      documentNumber,
      firstName: getPersonsResponseDatosPersona?.datosBasicosPersona?.nombrePropio || '',
      lastName: getPersonsResponseDatosPersona?.datosBasicosPersona?.primerApellido || '',
      lastName2: getPersonsResponseDatosPersona?.datosBasicosPersona?.segundoApellido || '',
      businessName:
        (getPersonsResponseDatosPersona?.datosBasicosPersona?.primerApellido || '') +
        (getPersonsResponseDatosPersona?.datosBasicosPersona?.segundoApellido || '') +
        (getPersonsResponseDatosPersona?.datosBasicosPersona?.nombrePropio || ''),
      gender: getPersonsResponseDatosPersona?.datosGeneralesPersona?.sexo || undefined,
      country: getPersonsResponseDatosPersona?.datosGeneralesPersona?.codigoResidencia || '',
      birthDate: getPersonsResponseDatosPersona?.datosGeneralesPersona?.fechaNacimiento
        ? new Date(getPersonsResponseDatosPersona?.datosGeneralesPersona?.fechaNacimiento)
        : undefined,
      zipCode: '',
      city: getPersonsResponseDatosPersona?.datosGeneralesPersona?.nombrePoblacion || '',
      taxTreatmentArea: this.model.taxTreatmentArea,
      driverLisenceDate: undefined,
      nationality: getPersonsResponseDatosPersona.datosGeneralesPersona?.nacionalidadPersona || ''
    };
  }

  /**
   * Main function for adding/editting person
   * @param {AddEditPersonForm} addEditPersonForm
   * @param {EditPersonResponse} editPersonResponse
   */
  @EAMethod({
    loading: true,
  })
  async getPersonAndSave(addEditPersonForm: AddEditPersonForm, editPersonResponse: EditPersonResponse) {
    const getPersonApi = new EAGetPersonsApi();
    const personResponse = await getPersonApi.getPersonsOperation({
      getPersonsRequest: this.getPersonServiceInput(
        addEditPersonForm, editPersonResponse.codigoFiliacion
      ),
    });

    NotificationsUtils.comprobeErrors(personResponse as ResponseWithErrors);
    if (personResponse?.datosPersona) {
      this.selectAddedEdittedPerson(personResponse);
    }

    // ### Reset adding/editting flags ###
    this.model.searchModel.isAdding = false;
    this.model.searchModel.isEditting = false;
  }

  /**
   * Save driver data
   * @param {AddEditPersonForm} addEditPersonForm
   * @param {EditPersonResponse} editPersonResponse
   */
  @EAMethod({
    loading: true,
  })
  async getDriverAndSave(addEditPersonForm: AddEditPersonForm, editPersonResponse: EditPersonResponse) {
    this.driverFilationCode = editPersonResponse.codigoFiliacion;
  
    const vehicle = this.vehiclesList?.find(item => item.plateNumber === this.plateNumberSelected);
    const saveDriverApi = new EASaveFleetDriverPersonApi();

    const personResponse = await saveDriverApi.saveFleetDriverPersonOperation({
      saveFleetDriverPersonRequest: {
        codigoPoliza: vehicle?.offerNumber || '',
        versionPoliza: vehicle?.offerVersion || 0,
        conductor: {
          conductorNominado: true,
          codigoFiliacionConductor: editPersonResponse.codigoFiliacion || '',
          tipoDocumentoConductor: addEditPersonForm.documentType,
          codigoIdentificacionConductor: addEditPersonForm.documentNumber.toUpperCase(),
          nombreConductor: addEditPersonForm.firstName.toUpperCase(),
          primerApellidoConductor: addEditPersonForm.lastName.toUpperCase(),
          segundoApellidoConductor: addEditPersonForm.lastName2.toUpperCase(),
          fechaNacimientoConductor:
                moment(addEditPersonForm.birthDate).format('YYYY-MM-DD'),
          fechaCarneConductor:
                moment(addEditPersonForm.driverLisenceDate).format('YYYY-MM-DD'),
        }
      },
    });

    NotificationsUtils.comprobeErrors(personResponse as ResponseWithErrors);
    if (personResponse?.ok && editPersonResponse.codigoFiliacion) {
      this.setDriverPersonData(addEditPersonForm, editPersonResponse.codigoFiliacion);
    }

    // ### Reset adding/editting flags ###
    this.isAddDriver = false;
    this.driverFilationCode = undefined;
  }

  /**
   * Main function for adding/editting person
   */
  @EAMethod({
    loading: true,
  })
  async saveAddEdit() {
    const editPersonRequest = this.getEditPersonInput();

    if (!editPersonRequest) {
      return;
    }

    try {
      const editPersonApi = new EAEditPersonApi();
      const editPersonResponse = await editPersonApi.editPersonOperation({
        editPersonRequest
      });

      if (!editPersonResponse) {
        return;
      }

      if (editPersonResponse.errors) {
        let closeAction;

        if (editPersonResponse.codigoFiliacion) {

          /**
           * Person added/edited with errors => Error message wil be shown to user
           * Create closeAction for error message modal
           */
          closeAction = async() => {
            await this.getAlreadyExistingAddedEditedPerson({
              codigoFiliacion: editPersonResponse.codigoFiliacion,
              tipoPersona: editPersonRequest.datosBasicosPersona?.
                tipoPersona?.toString() as TipoPersonaEnum,
              tipoDocumento: editPersonRequest.datosBasicosPersona?.
                tipoDocumento?.toString() as TipoDocumentoEnum,
              codigoIdentificacionFiscal: editPersonRequest.datosBasicosPersona?.codigoIdentificacionFiscal,
              nombrePropio: editPersonRequest.datosBasicosPersona?.nombrePropio,
              primerApellido: editPersonRequest.datosBasicosPersona?.primerApellido,
              segundoApellido: editPersonRequest.datosBasicosPersona?.segundoApellido
            });
          };
        }
  
        /**
         * Use component's generic error modal as close action triggers a function from this component
         */
        this.genericErrorData = {
          title: 'Error',
          errors: editPersonResponse.errors,
          closeAction
        };
        this.isGenericErrorVisible = true;
      } else if (editPersonResponse.codigoFiliacion && this.model.searchModel.addEditPersonForm) {
        if (this.model.role === Roles.Conductor) {
          await this.getDriverAndSave(this.model.searchModel.addEditPersonForm, editPersonResponse);
        } else {
          await this.getPersonAndSave(this.model.searchModel.addEditPersonForm, editPersonResponse);
        }
      }
    } catch (err) {
      await this.handleApiError('QbSearchPersonBusiness::saveAddEdit', err);
    }
  }

  /**
   * Method that fetches person after "person already exists error" in add/edit service
   * @param {GetPersonsRequest} serviceInput 
   * @returns {Promise<void>}
   */
  @EAMethod({
    loading: true,
  })
  async getAlreadyExistingAddedEditedPerson(
    serviceInput: GetPersonsRequest
  ): Promise<void> {
    const getPersonApi = new EAGetPersonsApi();
    const personResponse = await getPersonApi.getPersonsOperation({
      getPersonsRequest: serviceInput
    });

    if (personResponse?.datosPersona) {
      this.selectAddedEdittedPerson(personResponse);
    }
  }

  /**
   * Get driver data from getDriverPersonDataOperation api
   * @returns {Promise<void>}
   */
  @EAMethod({
    loading: true,
  })
  async getDriverPersonDataApi(): Promise<GetDriverPersonDataResponse | null> {
    // Find the vehicle of the table with plateNumberSelected value
    const vehicleData =
      this.vehiclesList?.find(item => item.plateNumber === this.plateNumberSelected);
    if (vehicleData) {
      const getDriverPersonData = new EAGetDriverPersonDataApi();
      const personResponse = await getDriverPersonData.getDriverPersonDataOperation({
        getDriverPersonDataRequest: {
          codigoPoliza: vehicleData.offerNumber || '',
          versionPoliza: vehicleData.offerVersion || 0,
          codigoTecnicoProducto: vehicleData.productTechnicalCode || '',
          codigoComercialProducto: vehicleData.productComercialCode || ''
        }
      });
      NotificationsUtils.comprobeErrors(personResponse as ResponseWithErrors);
      // Return the person data
      return personResponse;
    }
    return null;
  }

  /**
   * Selected received person after create/edit
   * @param { GetPersonsResponse | null } personResponse
   */
  selectAddedEdittedPerson(personResponse: GetPersonsResponse | null) {
    if (personResponse) {
      this.selectPersonFromSearchPersonResponse(personResponse);
    }
  }

  /**
   * Generate input for edit person service
   * @param {string} personType
   */
  getJuridicalPersonData(personType: string) {
    if (personType === 'J' && this.model.searchModel.addEditPersonForm) {
      const nameAddEditData = this.getJuridicalPersonNameData(this.model.searchModel.addEditPersonForm.businessName);
      this.model.searchModel.addEditPersonForm.lastName = nameAddEditData[0];
      this.model.searchModel.addEditPersonForm.lastName2 = nameAddEditData[1];
      this.model.searchModel.addEditPersonForm.firstName = nameAddEditData[2];
    }
  }

  /**
   * Generate input for edit person service in case of driver
   * @returns {inputMessage} - edit person service input message
   */
  getDriverRequestData() {
    return {
      nacionalidadPersona: this.model.searchModel.addEditPersonForm?.nationality || '',
      codigoOperacionPersona: EditPersonRequestMarketingCodigoOperacionPersonaEnum.MO
    };
  }

  /**
   * Generate input for edit person service
   * @returns {inputMessage} - edit person service input message
   */
  getEditPersonInput(): EditPersonRequest | null {
    if (!this.model.searchModel.addEditPersonForm) {
      return null;
    }

    const inputMessage = _cloneDeep(this.model.searchModel.emptyEditPersonRequest);

    const documentType = this.model.searchModel.addEditPersonForm.documentType;
    const personType = this.getPersonType(documentType);

    this.getJuridicalPersonData(personType);
   
    inputMessage.datosBasicosPersona.codigoIdentificacionFiscal =
      this.model.searchModel.addEditPersonForm.documentNumber;
    inputMessage.datosBasicosPersona.tipoDocumento =
      documentType as EditPersonRequestDatosBasicosPersonaTipoDocumentoEnum;
    inputMessage.datosBasicosPersona.primerApellido = this.model.searchModel.addEditPersonForm.lastName;
    inputMessage.datosBasicosPersona.segundoApellido = this.model.searchModel.addEditPersonForm.lastName2;
    inputMessage.datosBasicosPersona.nombrePropio = this.model.searchModel.addEditPersonForm.firstName;
    inputMessage.datosBasicosPersona.tipoPersona = personType as EditPersonRequestDatosBasicosPersonaTipoPersonaEnum;

    inputMessage.datosGeneralesPersona.sexo = this.model.searchModel.addEditPersonForm.gender;
    inputMessage.datosGeneralesPersona.fechaNacimiento = this.model.searchModel.addEditPersonForm.birthDate
      ? moment(this.model.searchModel.addEditPersonForm.birthDate).format('YYYY-MM-DD')
      : undefined;
    inputMessage.datosGeneralesPersona.nombrePoblacion = this.model.searchModel.addEditPersonForm.city;
    inputMessage.datosGeneralesPersona.codigoResidencia = this.model.searchModel.addEditPersonForm.country;

    inputMessage.marketing.codigoOperacionPersona = this.model.searchModel.isEditting ?
      EditPersonRequestMarketingCodigoOperacionPersonaEnum.MO :
      EditPersonRequestMarketingCodigoOperacionPersonaEnum.AL;
    inputMessage.marketing.estadoPersona = EditPersonRequestMarketingEstadoPersonaEnum.A;

    if (this.model.searchModel.isAdding) {
      inputMessage.marketing.codigoPaisDocumentoCee = this.model.searchModel.addEditPersonForm.country;
    }

    if (documentType === 'N' || documentType === 'C') {
      inputMessage.datosGeneralesPersona.nacionalidadPersona = 'ESP';
    }

    if (documentType === 'X' || documentType === 'P' || documentType === 'E') {
      inputMessage.marketing.codigoPasaporte = this.model.searchModel.addEditPersonForm.documentNumber;
    }

    if (this.isAddDriver) {
      const getDriverData = this.getDriverRequestData();
      inputMessage.marketing.codigoPaisDocumentoCee = this.model.searchModel.addEditPersonForm.country;
      inputMessage.datosGeneralesPersona.nacionalidadPersona = getDriverData.nacionalidadPersona;
    }

    if (this.model.searchModel.isEditting && this.model.searchModel.selectedPerson) {
      inputMessage.marketing.timestampPersonaFisica =
        this.model.searchModel.selectedPerson.marketing?.timestampPersonaFisica || '';
      inputMessage.marketing.timestampPersonaJuridica =
        this.model.searchModel.selectedPerson.marketing?.timestampPersonaJuridica || '';
      inputMessage.datosBasicosPersona.codigoFiliacion =
        this.model.searchModel.selectedPerson.datosBasicosPersona?.codigoFiliacion || '';
    }

    if (this.driverFilationCode) {
      const getDriverData = this.getDriverRequestData();
      inputMessage.datosBasicosPersona.codigoFiliacion = this.driverFilationCode;
      inputMessage.datosGeneralesPersona.nacionalidadPersona = getDriverData.nacionalidadPersona;
      inputMessage.marketing.codigoOperacionPersona = getDriverData.codigoOperacionPersona;
    }

    const inputMessageTyped = (inputMessage as unknown) as EditPersonRequest;
    inputMessageTyped.actualizarDatosGenerales = this.updateGeneralDataParty;
   
    return inputMessageTyped;
  }

  /**
   * Cancel edition mode
   */
  cancelAddEdit() {
    if (this.model.searchModel.isAdding) {
      this.model.searchModel.selectedPerson = null;
    }

    this.model.searchModel.postCodeLocationInputModel = new PostCodeLocationInputModel();
    this.model.searchModel.isEditting = false;
    this.model.searchModel.isAdding = false;

    this.isAddDriver = false;
    this.isEditDriver = false;

    if (this.driverFilationCode) {
      this.driverFilationCode = undefined;
    }
  }

  /**
   * Checks if document type refers to a juridical person (persona física)
   * @param { string } documentType
   * @return { boolean } true if document type refers to a juridical person; false otherwise
   */
  isJuridicalPerson(documentType: string): boolean {
    const juridicalPersonDocumentType = ['E', 'X', 'C'];
    return juridicalPersonDocumentType.includes(documentType);
  }

  /**
   * Gets person type based on received codument type code
   * @param { string } documentTypeCode
   * @return { string } person type
   */
  getPersonType(documentTypeCode: string) {
    return this.isJuridicalPerson(documentTypeCode) ? 'J' : 'F';
  }

  /**
   * Update validation rules when user changes document type as it is mandatory for NIF value
   */
  onDocumentTypeChange() {
    // Wait first for model to be updated
    this.$nextTick(() => {

      // Clear all fields
      this.model.searchModel.searchPersonData.documentNumber = '';
      this.model.searchModel.searchPersonData.firstName = '';
      this.model.searchModel.searchPersonData.lastName = '';
      this.model.searchModel.searchPersonData.lastName2 = '';
      this.model.searchModel.searchPersonData.businessName = '';

      const form: Form = this.$refs.form as Form;
      form.clearValidate();
    });
  }

  /**
   * Changes documentNumber value to uppercase and unsets its error.
   *
   * @returns { void }
   */
  onDocumentNumberChange(): void {
    this.model.searchModel.showDocumentInvalidError = false;

    if (this.model.searchModel.searchPersonData.documentNumber) {
      this.model.searchModel.searchPersonData.documentNumber =
        this.model.searchModel.searchPersonData.documentNumber.toUpperCase();

      // Validate documentNumber
      this.formValidationOptions.rules.documentNumber = SearchPersonUtils.getDocumentNumberValidationRules(
        this.model.searchModel.searchPersonData.documentType, this.documentNumberCorporateTableValidation
      );
    }
  }

  /**
   * Validates document number within the corporate table.
   *
   * @param {any} _rule
   * @param {any} _value
   * @param {Function} callback
   * @return {void} callback() if document number is valid; callback(new Error()) otherwise
   */
  documentNumberCorporateTableValidation(_rule: any, _value: any, callback: Function): void {
    const result = this.model.searchModel.showDocumentInvalidError ? callback(new Error()) : callback();
    this.model.searchModel.showDocumentInvalidError = false;
    return result;
  }

  /**
   * Handles API response errors.
   *
   * @param {string} prefix Prefix for log messages
   * @param {any} err Error
   * @returns {Promise<void>}
   */
  async handleApiError(prefix: string, err: any): Promise<void> {
    const loggerErrorMessages: string[] = [];
    const visibleErrorMessages: string[] = [];
    const asyncError = await err;
    let errors: EAError[] = [];

    // Check error type
    if (typeof asyncError.getErrorList === 'function') {
      // eslint-disable-next-line no-extra-parens
      errors = (asyncError as EAMultiError).getErrorList() as EAError[];
    } else {
      errors = [asyncError as EAError];
    }

    // Check invalid document error, because the field must be invalid
    const invalidDocErrorIndex = errors.findIndex(error => error.code === 'ZON00043');
    if (invalidDocErrorIndex !== -1) {
      errors.splice(invalidDocErrorIndex, 1);
      this.model.searchModel.showDocumentInvalidError = true;
      this.validation()
        ?.validate()
        .catch(() => {
          // Form errors will be shown automatically
        });
    }

    // Parse error messages
    errors.forEach(error => {
      loggerErrorMessages.push(`${prefix} ${error.message}`);
    });
    errors.forEach(error => {
      visibleErrorMessages.push(error.message);
    });

    // Open dialog with those errors
    if (visibleErrorMessages.length > 0) {
      const genericErrorData: GenericErrorData = {
        title: 'Error',
        messages: visibleErrorMessages
      };
      this.onShowError(genericErrorData);
    }

    // Log error messages
    const logger = new EAApplicationLogger();
    loggerErrorMessages.forEach(message => {
      logger.error(message);
    });
  }

  /**
   * Resets selectedPerson
   */
  onResetSelection() {
    this.model.searchModel.selectedPerson = null;
    this.model.searchModel.searchPersonData = {
      documentType: '',
      documentNumber: '',
      firstName: '',
      lastName: '',
      lastName2: '',
      businessName: ''
    };
    this.model.addressesModel = new QbAddressesPersonModel();
    this.model.ibanCodeModel = new QbIbanCodeModel();
    this.model.showPersonInfoModel.postalCodeModel = new PostCodeLocationInputModel();
    this.update();
    this.$emit('resetPerson', this.model.role);
  }

  /**
   * Checks search data introduced to search data form and returns if data is insufficient:
   * - When no documentType has been informed
   * - When checking for natural/juridical person fails
   * @return {boolean}
   */
  shouldThrowInsufficientDataError(): boolean {
    if (!this.model.searchModel.searchPersonData.documentType ||
      this.model.searchModel.searchPersonData.documentType === '') {
      return true;
    }

    if (
      this.model.searchModel.searchPersonData.documentType === 'N' ||
      this.model.searchModel.searchPersonData.documentType === 'P' ||
      this.model.searchModel.searchPersonData.documentType === 'R'
    ) {
      return this.shouldThrowInsufficientDataErrorNaturalPerson();
    } else {
      return this.shouldThrowInsufficientDataErrorJuridicalPerson();
    }
  }


  /**
   * Checks search data introduced to search data form and returns if data is insufficient for natural person:
   * - When documentNumber, firstName and lastName1 is missing
   * @return {boolean}
   */
  shouldThrowInsufficientDataErrorNaturalPerson(): boolean {
    if (this.model.searchModel.searchPersonData.documentNumber) {
      return false;
    }

    return !(
      (this.model.searchModel.searchPersonData.firstName && this.model.searchModel.searchPersonData.lastName) ||
      (this.model.searchModel.searchPersonData.lastName && this.model.searchModel.searchPersonData.lastName2)
    );
  }

  /**
   * Checks search data introduced to search data form and returns if data is insufficient for juridical person:
   * - When no data has been introduced
   * @return {boolean}
   */
  shouldThrowInsufficientDataErrorJuridicalPerson(): boolean {
    return !(this.model.searchModel.searchPersonData.documentNumber ||
      this.model.searchModel.searchPersonData.businessName);
  }

  /**
   * Returns person nationality validation
   * @returns {EAValidation}
   */
  public nationalityValidation(): EAValidation {
    const addressesComp = this.getAddressesComp();
    return addressesComp.nationalityValidation();
  }

  /**
   * Gets search person component given person's role
   * @param {string} role
   * @returns {QbAddressesPersonBusiness}
   */
  getAddressesComp(): QbAddressesPersonBusiness {
    let addressesComponentRef = this.$refs.addressesRef as unknown;

    if (Array.isArray(addressesComponentRef)) {
      addressesComponentRef = addressesComponentRef[0];
    }

    return addressesComponentRef as QbAddressesPersonBusiness;
  }

  /**
   * Returns person billing validation
   * @returns {EAValidation}
   */
  public billingValidation(): EAValidation {
    const billingDataComp = this.$refs.billingDataRef as QbBillingDataBusiness;

    if (billingDataComp) {
      billingDataComp.update();
    }
    
    return billingDataComp.validation();
  }


  // EVENT EMITTERS:

  /**
   * Emit the refresh data event from address to parent component
   * @param {SetPersonAddressesRequestDomicilios} lastAddedAddress
   */
  onRefreshAddressesData(lastAddedAddress: SetPersonAddressesRequestDomicilios) {
    this.$emit('refreshAddressesData', {
      lastAddedAddress,
      role: this.model.role
    });
  }

  /**
   * Emit the refresh data event from address to parent component
   * 
   * @param {string} lastAddedIban
   */
  onRefreshIbanData(lastAddedIban: string) {
    this.$emit('refreshIbanData', {
      lastAddedIban,
      role: this.model.role
    });
  }

  /**
   * Emit the refresh data event from address to parent component
   * 
   * @param {any} args
   */
  onHandleGenericError(args: any) {
    this.$emit('handleGenericError', args);
  }

  /**
   * Emit the refresh data event from address to parent component
   * 
   * @param {GenericErrorData} genericErrorData
   */
  onShowError(genericErrorData: GenericErrorData) {
    this.$emit('showError', genericErrorData);
  }

  /**
   * Handles equalStatementRadioBtnChange event
   * Emits event to parent component
   * @param {SelectionInFormTabRole | undefined} changedEqualStatement
   */
  onPersonRoleEqualStatementRadioBtnChange(changedEqualStatement?: SelectionInFormTabRole) {
    this.update();
    this.$emit('personRoleEqualStatementRadioBtnChange', changedEqualStatement);
  }

  /**
   * Triggers on change radio from SelectionInFormTabRole and sets values and visibility from other roles
   * @param { SelectionInFormTabRole } equal
   */
  onRoleRadioChange(equal: SelectionInFormTabRole) {
    this.$emit('roleRadioChange', equal);
  }

  /**
   * Find person data and set in addEditPersonForm is case of edit
   * @param {DriverListInterface} row
   */
  @EAMethod()
  async onEditDriverPerson(row: DriverListInterface) {
    this.isEditDriver = false;
    // Get plateNumber from the table
    this.plateNumberSelected = row.plateNumber || '';

    // Find the driver data
    const selectedDriverData =
      this.model.searchModel.selectedDrivers.find(
        item => item.driver?.documentNumber === row.documentNumber &&
        item.vehicle?.plateNumber === this.plateNumberSelected
      );

    if (selectedDriverData?.driver) {

      /*
       * Get data from kcilmron
       * Use plateNumberSelected value to find the person in vehiclesList 
       * To get all the data needed to call getDriverPersonDataOperation
       */
      const data = await this.getDriverPersonDataApi();
      if (data?.codigoFiliacionConductor) {
        this.driverFilationCode = data?.codigoFiliacionConductor;
        // Get data from persons
        await this.getAlreadyExistingAddedEditedPerson({
          codigoFiliacion: data.codigoFiliacionConductor,
          tipoPersona: 'F' as GetPersonsRequestTipoPersonaEnum,
          tipoDocumento: data.tipoDocumentoConductor as GetPersonsRequestTipoDocumentoEnum,
          codigoIdentificacionFiscal: data.codigoIdentificacionConductor,
          nombrePropio: data.nombreConductor,
          primerApellido: data.primerApellidoConductor,
          segundoApellido: data.segundoApellidoConductor
        });

        if (this.model.searchModel.addEditPersonForm) {

          /*
           * Set driverLisenceDate that get from kcilmron
           * fechaCarneConductor is the unic data that don't get from personas
           */
          this.model.searchModel.addEditPersonForm = {
            ...this.model.searchModel.addEditPersonForm,
            driverLisenceDate: data?.fechaCarneConductor ? new Date(data?.fechaCarneConductor) : undefined
          };
        }
      }
      this.isEditDriver = true;
    }
  }

  /**
   * Get the vehicle data searched for driver person from the input
   * @param { VehicleFormModel } vehicle
   * @param {string} plateNumber
   */
  onGetVehicleSearched(vehicle: VehicleFormModel, plateNumber: string) {
    this.existVehicleDriver = false;

    // Filter selectedDrivers to get all the vehicles that have driver data
    this.model.searchModel.selectedDrivers = this.model.searchModel.selectedDrivers.filter(item => item.driver);

    // Find the vehicle with the plateNumber selected in the input 
    const findVehicle = this.model.searchModel.selectedDrivers.find(item => item.vehicle?.plateNumber === plateNumber);
    this.vehicleData = vehicle;

    // If the vehicle exist in the table not push again
    if (vehicle && !findVehicle) {

      // Show the vehicle table
      this.existVehicleDriver = true;

      // Set the vehicle selected
      this.model.searchModel.selectedDrivers.push({
        vehicle
      });

      // Set the plateNumber selected
      this.plateNumberSelected = plateNumber;
    }
  }

  /**
   * Watcher for model.searchModel.driverListTable.driverList to change the nominated value
   */
  @Watch('model.searchModel.driverListTable.driverList')
  onUpdateNominatedValue() {
    if (this.model.searchModel.driverListTable.driverList.length === 0) {
      const driverTableRef = this.$refs.driver as PolicyHolderVsDriverPersonComponent;
      if (driverTableRef) {
        driverTableRef.formValues.nominated = false;
      }
    }
  }

  /**
   * Get driver list with the correct format
   * 
   */
  getDriverTableList() {
    // Set the default values
    this.model.searchModel.selectedDrivers = [];
    this.model.searchModel.driverListTable.driverList = [];

    // VehiclesList is getting from the api in the view
    this.vehiclesList?.forEach(item => {
      // If exist driver nominated
      if (item.nominatedDriver && item.plateNumber) {
        // Set the driver and vehicle values in driverList for the table
        this.model.searchModel.driverListTable.driverList.push({
          documentNumber: item.driverDocumentNumber?.toUpperCase(),
          firstName: item.driverFisrtName?.toUpperCase(),
          lastName: item.driverLastName?.toUpperCase(),
          plateNumber: item.plateNumber,
          brand: item.vehicleBrand,
          model: item.vehicleModel,
          version: item.vehicleVersion
        });
      
        // Set the vehicle data in selectedDrivers
        this.onGetVehicleSearched(item, item.plateNumber);
 
        // Set the person data in selectedDrivers
        this.model.searchModel.selectedDrivers = this.model.searchModel.selectedDrivers.map(ele => {
          if (ele.vehicle?.plateNumber === item.plateNumber) {
            return {
              ...ele,
              driver: {
                firstName: item.driverFisrtName|| '',
                lastName: item.driverFisrtName || '',
                lastName2: '',
                gender: undefined,
                birthDate: undefined,
                country: '',
                nationality: '',
                documentNumber: item.driverDocumentNumber || '',
                documentType: '',
                businessName: '',
                zipCode: '',
                city: '',
              }
            };
          }
          return ele;
        });
      }
    });

    this.update();
    // If exist selectedDrivers show the table 
    if (this.model.searchModel.selectedDrivers.length > 0) {

      // Tell to view driver list is updated
      this.$emit('getDriverList');

      this.existVehicleDriver = false;
      this.hiddeVehicleTable = false;
      this.$nextTick(() => {
        const plateNumberForm = this.$refs.driver as PolicyHolderVsDriverPersonComponent;
        plateNumberForm.formValues.nominated = true;
        this.$emit('isDriverIsNominated', true);
      });
    }
  }

  /**
   * Handles clearFlowErrors event
   */
  onClearFlowErrors() {
    this.clearFlowErrors();
  }
  
  /**
   * Reset the driver list
   * @param {boolean} value
   */
  onDriverRadioChange(value: boolean) {
    // If value equal false not delete the data only hidde the table
    this.hiddeVehicleTable = !value;
    this.$emit('isDriverIsNominated', value);

    if (!value) {
      // Update the model
      this.$emit('getDriverList');

      // Reset the form data
      this.existVehicleDriver = value;
      const plateNumberForm = this.$refs.driver as PolicyHolderVsDriverPersonComponent;
      plateNumberForm.formValues.plateNumber = '';
      this.onSearchAgain();
    }
  }

  /**
   * Emit delete driver data
   * Reset the form data
   * Update the model
   * @param {SelectDriverInterface[]} data
   */
  onDriversToDelete(data: SelectDriverInterface[]) {
    // Reset the form data
    this.existVehicleDriver = false;
    this.plateNumberSelected = '';
    const plateNumberForm = this.$refs.driver as PolicyHolderVsDriverPersonComponent;
    plateNumberForm.formValues.plateNumber = '';

    // Update the model
    this.$emit('getDriverList');

    // Emit delete driver data
    this.$emit('driversToDelete', data);
  }

  // GETTERS:

  /**
   * Checks if the document type is selected
   * @return { boolean } true if user has selected any document type; false otherwise
   */
  get isDocumentTypeSelected(): boolean {
    return !!this.model.searchModel.searchPersonData.documentType;
  }
  
  /**
   * Checks if we have retrieved full data on selected person
   * @return { boolean } true if user has selected any person on table; false otherwise
   */
  get isAnyPersonSelected(): boolean {
    return !!this.model.searchModel.selectedPerson?.datosBasicosPersona?.codigoFiliacion;
  }

  /**
   * Checks if show the billing input
   * @return { boolean }
   */
  get isShowBillingField(): boolean {
    return !!this.isAnyPersonSelected && !!this.showBillingField;
  }

  /**
   * Checks if no results dialog has to be shown
   * @return { boolean } true if user has clicked search button and results list doesn't have any record
   */
  get noResultsDialogVisible(): boolean {
    return this.model.searchModel.isNoResultsDialogVisible;
  }

  /**
   * Checks results table dialog has to be shown
   * @return { boolean } true if user has clicked search button and results list has any record
   */
  get resultsTableVisible(): boolean {
    return this.model.searchModel.isResultsTableVisible;
  }

  /**
   * Returns if lastName2 field is required
   * True when documentType is NIF
   */
  get isLastName2FalseRequired() {
    return this.model.searchModel.searchPersonData.documentType === 'N';
  }

  /**
   * Checks results table dialog has to be shown
   * @return { boolean } true if we have person full data and user has clicked on edition mode; false otherwise
   */
  get isPersonEditionVisible(): boolean {
    return !!this.model.searchModel.addEditPersonForm &&
      (this.model.searchModel.isEditting || this.model.searchModel.isAdding);
  }

  /**
   * Checks results table dialog has to be shown
   * @return { boolean } true if we have person full data and user has clicked on edition mode; false otherwise
   */
  get addEditDriverModal(): boolean {
    return !!this.model.searchModel.addEditPersonForm && (this.isAddDriver || this.isEditDriver);
  }

  /**
   * Checks if selected document in search form type refers to a juridical person (persona física)
   * @return { boolean } true if document type refers to a juridical person; false otherwise
   */
  get isSearchPersonJuridicalPerson(): boolean {
    return this.isJuridicalPerson(this.model.searchModel.searchPersonData.documentType);
  }

  /**
   * Retrieves if search explanation should be shown
   * True for 'tomador' role when searching a person
   * @returns {boolean}
   */
  get showSearchExplanation(): boolean {
    return this.model.role === Roles.Tomador && !this.isAnyPersonSelected;
  }

  /**
   * Get the title for interveningFlow
   */
  get getTitle(): boolean | undefined {
    return this.interveningFlow;
  }

  /**
   * Retrieves if search explanation should be shown
   * Use existVehicleDriver in driver case
   * @returns {boolean}
   */
  get showSearchPerson(): boolean {
    if ((!this.isAnyPersonSelected && !this.isPersonEqualToAnotherRole) || this.existVehicleDriver) {
      return true;
    }
    return false;
  }

  /**
   * Get the document type list in case of driver person
   */
  get getDocumentTypeList(): ParsedTableData[] | undefined {
    if (this.model.role === Roles.Conductor) {
      return this.documentTypeList?.filter(type => type.value === 'N' || type.value === 'R');
    }
    return this.documentTypeList;
  }

  /**
   * Gets search explanation for searcher
   */
  get searchExplanation(): string {
    return this.axesorSearchDocumentTypes?.includes(
      this.model.searchModel.searchPersonData.documentType as DocumentType
    ) ? this.$t('common.label.blank').toString() :
      this.$t('quoteBuyGenericFlow.searchPerson.searchExplanation').toString();
  }

  /**
   * Getter for form validation
   */
  get formValidationOptions(): EAFormValidationOptions {
    const documentNumberRules = SearchPersonUtils.getDocumentNumberValidationRules(
      this.model.searchModel.searchPersonData.documentType, this.documentNumberCorporateTableValidation
    );
    if (this.axesorSearchDocumentTypes?.includes(
      this.model.searchModel.searchPersonData.documentType as DocumentType
    )) {
      documentNumberRules.push(eaRequiredValidation('common.label.validation.fieldRequired'));
    }
    return {
      rules: {
        documentType: [eaRequiredValidation('common.label.validation.fieldRequired')],
        documentNumber: documentNumberRules
      }
    };
  }

}
</script>

<style scoped lang="scss">

/*TODO: QUITAR CLASES POR LAS DE ADECUACIÓN DE MARCA */
::v-deep .false-required .ea-form-item__label>label::before {
  content: '*';
  flex-basis: 7px;
  font-size: 14px;
  font-weight: 600;
  text-align: left;
  margin-right: 4px;
  color: #EA1B03;
}
::v-deep .radiogroup-horizontal .el-form-item__content {
  display: flex;
  flex-direction: row;
  align-items: flex-start;

  .radiogroup-label {
    font-weight: 400;
    margin-right: 8px;

    // This should be enabled when a US requires multiselection
    // min-width: 300px;
    // max-width: 300px;
  }
}
.cardbody{
  position:relative;
}
</style>
