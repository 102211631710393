<template>
  <div>
    <ea-link icon-left="z-clipboard-list" @click="openDialog()" v-if="checkGestionCompetencias">
      {{ $t('quoteBuyGenericFlow.proficiencyManagement.linkTitle') }}
    </ea-link>
    <ea-dialog
      :visible.sync="proficiencyMgmtDialogVisible"
      :beforeClose="onClose"
      size="extralarge"
      :title="$t('quoteBuyGenericFlow.proficiencyManagement.title')"
    >
    <div class="d-display-flex flex-gap m-b-32" v-if="proposalTableData.length > 1 && originFlow !== 'quoteAndBuy'
      || originFlow === 'quoteAndBuy' && proposalTableData.length">
      <ea-table
        v-for="(proposalDataElem, index) of proposalTableData"
        :key="index"
        :data="proposalDataElem.elements"
        class="d-grow-1 proposalTableData p-r-8"
      >
        <ea-table-column
          :label="getProposalTitle(proposalDataElem.code)"
          show="descripcionErrorAutorizacion"
          headerClass="no-sortBy"
        >
          <template slot-scope="row">
            <span class="text-wrap">
              {{ row.descripcionErrorAutorizacion }}
            </span>
          </template>
        </ea-table-column>
        <ea-table-column
          :label="$t('quoteBuyGenericFlow.proficiencyManagement.field.status')"
          show="descripcionEstadoControlCompetencias"
          headerClass="no-sortBy"
        >
          <template slot-scope="row">
            <div class="ea-table-td-content t-align-left">
              <ea-tag
                class="m-l-0"
                :status="getTagStatus(row.estadoControlCompetencias)"
              >
                {{ capitalizeFirstLetter(row.descripcionEstadoControlCompetencias) }}
              </ea-tag>
            </div>
          </template>
        </ea-table-column>
      </ea-table>
    </div>
    <div v-if="policyTableData.length > 0">
      <ea-table
        :data="policyTableData"
      >
        <ea-table-column
          :label="$t('common.label.policy')"
          show="descripcionErrorAutorizacion"
          headerClass="no-sortBy"
        >
          <template slot-scope="row">
            <span class="text-wrap">
              {{ row.descripcionErrorAutorizacion }}
            </span>
          </template>
        </ea-table-column>
        <ea-table-column
          :label="$t('quoteBuyGenericFlow.proficiencyManagement.field.status')"
          show="descripcionEstadoControlCompetencias"
          headerClass="no-sortBy"
        >
          <template slot-scope="row">
            <div class="ea-table-td-content t-align-left">
              <ea-tag
                class="m-l-0"
                :status="getTagStatus(row.estadoControlCompetencias)"
              >
                {{ capitalizeFirstLetter(row.descripcionEstadoControlCompetencias) }}
              </ea-tag>
            </div>
          </template>
        </ea-table-column>
      </ea-table>
    </div>
    </ea-dialog>
  </div>
</template>

<script lang="ts">
import {
  Component, Prop
} from 'vue-property-decorator';
import {
  mixins
} from 'vue-class-component';

import {
  EABusinessComponent
} from '@zurich-es-npm/ea-front-web-core';

import QbProficiencyManagementModel from './qb-proficiency-management-model';
import Utils from '@/utils/utils';
import TextUtils from '@/utils/text-utils';
import {
  GetCompetencesManagementByOfferResponseCompetencesManagementByOfferData,
  GetCompetencesManagementByOfferResponseCompetencesManagementByOfferDataEstadoControlCompetenciasEnum
} from '@/services/V1/quoteAndBuy/getCompetencesManagementByOfferOperation/post';
import ProductBase from '@/utils/quote-buy-product-factory/products/product-base';

interface ProposalModel {
  code: string;
  elements: GetCompetencesManagementByOfferResponseCompetencesManagementByOfferData[];
}

@Component({
  name: 'qb-proficiency-management'
})

/**
 * Business Component qb-proficiency-management
 */
export default class QbProficiencyManagementBusiness
  extends mixins<EABusinessComponent<QbProficiencyManagementModel>>(EABusinessComponent) {

  @Prop() proficiencyManagementList?: GetCompetencesManagementByOfferResponseCompetencesManagementByOfferData[];

  @Prop({
    'default': () => false
  })
    checkGestionCompetencias!: boolean;

  @Prop({
    'default': () => ''
  })
    originFlow?: string;

  @Prop({
    required: true,
  })
    productFactory!: ProductBase;

  proficiencyMgmtDialogVisible: boolean = false;

  /**
   * Filters proficiency management elements
   * @returns {GetCompetencesManagementByOfferResponseCompetencesManagementByOfferData[]} pending or rejected status
   */
  getFilteredShownProficiencyManagementElements():
  GetCompetencesManagementByOfferResponseCompetencesManagementByOfferData[] {
    if (!this.proficiencyManagementList || !this.proficiencyManagementList.length) {
      return [];
    }

    return Utils.sortObjectArrayByProperty(
      this.proficiencyManagementList.filter(
        element => element && (element.estadoControlCompetencias ===
        GetCompetencesManagementByOfferResponseCompetencesManagementByOfferDataEstadoControlCompetenciasEnum.P ||
        element.estadoControlCompetencias ===
        GetCompetencesManagementByOfferResponseCompetencesManagementByOfferDataEstadoControlCompetenciasEnum.R)
      ), 'descripcionErrorAutorizacion'
    );
    
  }

  /**
   * Gets tag status based on received rowStatus
   * @param {string} rowStatus
   * @returns {string}
   */
  getTagStatus(rowStatus: string): string {
    switch (rowStatus) {
      case 'P':
        return 'warning';
      case 'R':
        return 'danger';
      default:
        return '';
    }
  }

  /**
   * Capitalizes first letter of received string
   * @param {string} string
   * @returns {string}
   */
  capitalizeFirstLetter(string: string): string {
    return TextUtils.capitalizeFirstLetterOneWord(string.toLowerCase());
  }

  /**
   * Shows proficiency management modal
   */
  showModal() {
    this.proficiencyMgmtDialogVisible = true;
  }

  /**
   * Hides proficiency management modal
   */
  hideModal() {
    this.proficiencyMgmtDialogVisible = false;
  }

  /**
   * Gets policy data
   * @returns {GetCompetencesManagementByOfferResponseCompetencesManagementByOfferData[]} array to be displayed
   */
  get policyTableData(): GetCompetencesManagementByOfferResponseCompetencesManagementByOfferData[] {
    const policyElements: GetCompetencesManagementByOfferResponseCompetencesManagementByOfferData[] = [];
    const pendingRejectedElements = this.getFilteredShownProficiencyManagementElements();
  
    for (const elem of pendingRejectedElements) {
      if (elem.codigoMCT === '0000000000') {
        policyElements.push(elem);
      }
    }

    if (this.proposalTableData.length === 1 && this.originFlow !== 'quoteAndBuy') {
      policyElements.push(...this.proposalTableData[0].elements);
    }

    return policyElements;
  }


  /**
   * Gets proposal data
   * @returns {ProposalModel[]} array to be displayed in template
   */
  get proposalTableData(): ProposalModel[] {
    const tableData: ProposalModel[] = [];
    const pendingRejectedElements = this.getFilteredShownProficiencyManagementElements();

    for (const elem of pendingRejectedElements) {
      if (elem.codigoMCT !== '0000000000' && elem.codigoPropuesta) {
        const tableDataPackage = tableData.find(pack => pack.code === elem.codigoPropuesta);

        if (tableDataPackage) {
          tableDataPackage.elements.push(elem);
        } else {
          tableData.push({
            code: elem.codigoPropuesta,
            elements: [elem]
          });
        }
      }
    }

    if (tableData.length) {
      Utils.sortObjectArrayByProperty(tableData, 'code');
    }

    return tableData;
  }

  /**
   * Emits to refresh data and open modal
   *
   */
  openDialog(): void {
    this.$emit('retrieveProficiencyManagementData');
  }

  /**
   * Emits to close the modal
   */
  onClose() {
    this.$emit('close');
  }

  /**
   * Get proposal title
   * @param {string} proposalCode
   * @returns {stirng}
   */
  getProposalTitle(proposalCode: string): string {
  
    if (this.productFactory.shouldShowCustomPackageName) {
      return this.$t(`warranties.packageName.${proposalCode}`).toString();
    } else {
      return this.$t('quoteBuyGenericFlow.proficiencyManagement.field.proposalNumber', {
        number: this.model.proposalNumbersOrder.findIndex(proposal => proposal === proposalCode) + 1
      }).toString();
  
    }
  }

}
</script>
<style lang="scss" scoped>
.text-wrap {
  text-wrap: initial;
}
</style>
