import {
  ParsedTableData
} from '@/utils/corporate-tables';
import {
  FleetsModel
} from '../fleets/fleets-model';
import {
  VehicleUseData
} from '@/business-components/fleet-manual-upload-vehicle/fleet-manual-upload-vehicle-model';

/**
 * Flow model
 *
 * @export
 * @class FleetsQueryPolicyModel
 * @extends {EAFlowModel}
 */
export class FleetsQueryPolicyModel extends FleetsModel {
  public addressTypesList: ParsedTableData[] = [];

  public parsedVehicleUses: VehicleUseData[] = [];
  
  public policyEmitedStatusList: ParsedTableData[] = [];
}
