<template>
  <ea-row>
    <ea-col
      :span="24">
      <ea-card shadow="hidden">
        <div slot="cardbody" class="p-l-16 p-t-16">
          <!-- Form -->
          <ea-form :model="data">
            <!-- Intermediary Name / Code -->
            <ea-row>
              <ea-col :span="6">
                <ea-form-item :label="$t('fleets.fleetsFlow.header.intermediaryName')">
                  <ea-input-text readonly v-model="data.intermediaryName" />
                </ea-form-item>
              </ea-col>
              <ea-col :span="6">
                <ea-form-item :label="$t('fleets.fleetsFlow.header.intermediaryCode')">
                  <ea-input-text readonly  v-model="data.intermediaryCode" />
                </ea-form-item>
              </ea-col>
              <ea-col :span="6">
                <ea-form-item :label="$t('fleets.fleetsFlow.header.productName')">
                  <ea-input-text readonly :value="data.productName" />
                </ea-form-item>
              </ea-col>
              <ea-col :span="6" v-if="showDataIntervening">
                <ea-form-item :label="$t('fleets.fleetsFlow.header.productCode')">
                  <ea-input-text readonly v-model="data.productCode" />
                </ea-form-item>
              </ea-col>
              <ea-col :span="6" v-if="showDataIntervening">
                <ea-form-item :label="$t('fleets.fleetsFlow.header.businessName')">
                  <ea-input-text readonly  v-model="data.businessName" />
                </ea-form-item>
              </ea-col>
              <ea-col :span="6" v-if="showDataIntervening">
                <ea-form-item :label="$t('fleets.fleetsFlow.header.businessCode')">
                  <ea-input-text readonly  :value="data.businessName" />
                </ea-form-item>
              </ea-col>
            </ea-row>
          </ea-form>
        </div>
      </ea-card>
    </ea-col>
  </ea-row>
</template>

<script lang="ts">
import Vue from 'vue';
import {
  Component, Prop
} from 'vue-property-decorator';

import QBNewOfferBusinessModel from '@/business-components/qb-new-offer/qb-new-offer-model';

@Component({
  name: 'fleet-data-header',
})

/**
 * Presentational Component FleetDataHeader
 */
export default class FleetDataHeader extends Vue {
  @Prop({
    required: true,
  })
    data!: QBNewOfferBusinessModel;

  @Prop()
    interveningFlow?: boolean;


  /**
   * Presentational Component FleetDataHeader
   */
  get showDataIntervening() {
    if (this.interveningFlow) {
      return false;
    }
    return true;
  }

}
</script>
