import merge from 'lodash/merge';
import {
  EAApplicationError
} from '@zurich-es-npm/ea-front-web-core';

interface LocaleMessages {
  [key: string]: object;
}

const DEFAULT_BRANDING = 'zurich';

/**
 * Get branding locales
 * @param {string} branding
 * @param {string[]} supportedLocales
 * @return {*} devuelve todos las traduciones del showcase
 */
export default function loadLocaleMessages(branding: string, supportedLocales: string[]): object {
  const locales = require.context('../locales', true, /[A-Za-z0-9-_,\s]+\.json$/i);
  const messages: LocaleMessages = {};
  supportedLocales.forEach(async locale => {
    try {
      const defaultLocaleKey = `./${locale}/${DEFAULT_BRANDING}.json`;
      let localeMessages = locales(defaultLocaleKey);
      if (branding !== DEFAULT_BRANDING) {
        const brandingLocaleKey = `./${locale}/${branding}.json`;
        localeMessages = merge({}, localeMessages, locales(brandingLocaleKey));
      }
      messages[locale] = localeMessages;
    } catch (err) {

      throw new EAApplicationError('ARC00001', ['Error getting locales and branding locales']);

    }
  });
  return messages;
}
