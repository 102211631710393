import {
  GetCancelPolicyInformationResponseMotivosMovimiento
} from '@/services/V1/policy/getCancelPolicyInformationOperation/post';
import {
  LabelValueType
} from '@/flows/fleets/model/label-value-type-model';

/** 
 * Model fleets-cancel-vehicle
 */
class FleetsCancelVehicleModalModel {

  public codigoPoliza: string = '';

  public versionPoliza: number = 0;

  public plateNumber: string = '';

  public fechaEfectoNatural: string = '';

  public fechaEfectoMovimiento: string = '';

  public fechaVencimiento: string = '';

  public fechaAnulacion: Date | null = null;

  public motivosMovimiento: GetCancelPolicyInformationResponseMotivosMovimiento[] = [];

  public tiposMovimiento: LabelValueType[] = [];

  public motivoMovimientoPoliza: string = '';

  public tipoDeAnulacion: string = '';

}

export default FleetsCancelVehicleModalModel;
