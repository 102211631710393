<template>
  <div>
    <ea-table
      :data="filteredVehicles"
      :ref="tableRef"
      :id="tableRef"
      :multiSelect="!consultFlow"
      :infinite-scroll="true"
      @checkBoxChange="onTableCheckChange()"
      @checkBoxAllChange="onTableCheckChange()"
      class="thead-fixed"
    >
          <template v-if="!consultFlow" slot="top-table">
            <div class="ea-toolbar-action-wrapper d-display-flex d-direction-reverse">
              <ea-button type="secondary"
              :disabled="!hasActiveChecks"
              @click="onRemoveVehiclesBtn()">
                {{ $t('common.label.remove') }}
              </ea-button>
            </div>
          </template>

          <ea-table-column
            headerClass="no-sortBy"
            :label="$t('fleets.fleetsFlow.inputVehiclesData.tableDataHeaders.licensePlateNumber')"
            show="plateNumber"
          >
            <template slot-scope="row">
              <div class="wp-100">
                <ea-text ellipsis v-bind:class="getTableRowColor(row)">
                  {{ row.plateNumber }}
                </ea-text>
              </div>
            </template>
          </ea-table-column>
          <ea-table-column
            headerClass="no-sortBy"
            :label="$t('fleets.fleetsFlow.inputVehiclesData.manualInput.licensePlateType')"
            show="plateTypeName"
          >
            <template slot-scope="row">
              <div class="wp-100">
                <ea-text ellipsis v-bind:class="getTableRowColor(row)"> {{ row.plateTypeName }} </ea-text>
              </div>
            </template>
          </ea-table-column>
          <ea-table-column
            headerClass="no-sortBy"
            :label="$t('fleets.fleetsFlow.inputVehiclesData.manualInput.vehicleUse')"
            show="vehicleUseName"
          >
            <template slot-scope="row">
              <div class="minw-90">
                <ea-text ellipsis v-bind:class="getTableRowColor(row)"> {{ row.vehicleUseName }} </ea-text>
              </div>
            </template>
          </ea-table-column>
          <ea-table-column
            headerClass="no-sortBy"
            :label="$t('fleets.fleetsFlow.inputVehiclesData.tableDataHeaders.brand')"
          >
            <template slot-scope="row">
                <ea-text ellipsis v-bind:class="getTableRowColor(row)">
                  {{ valueOrHyphenFormatter(row.vehicleBrand) }}
                </ea-text>
            </template>
          </ea-table-column>
          <ea-table-column
            headerClass="no-sortBy"
            :label="$t('fleets.fleetsFlow.inputVehiclesData.tableDataHeaders.model')"
          >
            <template slot-scope="row">
                <ea-text ellipsis v-bind:class="getTableRowColor(row)">
                  {{ valueOrHyphenFormatter(row.vehicleModel) }}
                </ea-text>
            </template>
          </ea-table-column>
          <ea-table-column
            headerClass="no-sortBy"
            :label="$t('searchOfferFlow.searcher.policyVersionLabel')"
          >
            <template slot-scope="row">
                <ea-text ellipsis v-bind:class="getTableRowColor(row)">
                 {{ valueOrHyphenFormatter(row.vehicleVersion) }}
                </ea-text>
            </template>
          </ea-table-column>
          <ea-table-column
            headerClass="no-sortBy"
            :label="$t('fleets.fleetsFlow.inputVehiclesData.tableDataHeaders.policyEfectDate')"
            show="effectiveDate"
            data-type="date"
          >
            <template slot-scope="row">
              <div class="minw-90">
                <ea-text ellipsis v-bind:class="getTableRowColor(row)">
                 {{ dateFormatter(row.effectiveDate) }}
                </ea-text>
              </div>
            </template>
          </ea-table-column>
          <ea-table-column
            headerClass="no-sortBy"
            :label="$t('fleets.fleetsFlow.inputVehiclesData.manualInput.accessoriesValue')"
            :formatter="digitsFormatter"
            show="accessoriesValue"
          >
            <template slot-scope="row">
                <div class="wp-100 d-display-flex d-justify-flex-end">
                  <ea-text ellipsis class="t-align-right" v-bind:class="getTableRowColor(row)">
                  {{ digitsFormatter(row.accessoriesValue) }}
                </ea-text>
              </div>
            </template>
          </ea-table-column>

          <ea-table-column
            headerClass="no-sortBy"
            label="" v-if="!isAfterBaseSieteTable">
            <template slot-scope="row" v-if="row.isExistErrorVehicle" >
              <ea-tooltip
                  placement="top"
                  class="kpi-title-tooltip d-display-flex d-align-items-center"
                  :content="getErrorMessageByVehicle(row)"
                >
                  <ea-icon class="m-r-8" icon="z-alert-triangle" />
                </ea-tooltip>
            </template>
          </ea-table-column>

          <ea-table-column
            v-if="!consultFlow"
            headerClass="no-sortBy"
            label="">
            <template slot-scope="row" v-if="!isAfterBaseSieteTable">
              <ea-button-icon
                v-if="row.invalid"
                @click="openEditVehicleInvalid(row)"
                type="text"
                class="t-size-14"
                icon="z-edit">
              </ea-button-icon>
              <ea-button-icon
                v-else-if="row.invalidDate"
                @click="openEditVehicleWrongDate(row)"
                type="text"
                class="t-size-14"
                icon="z-edit">
              </ea-button-icon>
            </template>
            <template slot-scope="row" v-if="!row.isExistErrorVehicle && isAfterBaseSieteTable">
              <ea-button-icon
                v-if="row.invalid && isVehicleComplete(row)"
                @click="openEditVehicleInvalidUse(row)"
                type="text"
                class="t-size-14"
                icon="z-edit">
              </ea-button-icon>
              <ea-button-icon
                v-else-if="row.invalid"
                @click="openEditVehicleBaseSiete(row)"
                type="text"
                class="t-size-14"
                icon="z-edit">
              </ea-button-icon>
              <ea-button-icon
                v-else-if="row.invalidDate"
                @click="openEditVehicleWrongDate(row)"
                type="text"
                class="t-size-14"
                icon="z-edit">
              </ea-button-icon>
              <ea-button-icon
                v-else
                @click="openEditVehicleNonBaseSiete(row)"
                type="text"
                class="t-size-14"
                icon="z-edit">
              </ea-button-icon>
            </template>
          </ea-table-column>

          <template slot="tfoot">
            <div class="m-a-16 d-display-flex d-align-items-center">
              <span class="t-weight-normal">
                {{ $tc('fleets.fleetsFlow.inputVehiclesData.tableDataHeaders.rowsCount',
                    filteredVehicles.length,
                    { count: filteredVehicles.length }
                  )
                }}
              </span>
            </div>
          </template>
        </ea-table>
  </div>
</template>
<script lang="ts">

import {
  Component, Prop
} from 'vue-property-decorator';
import Vue from 'vue';
import {
  VehicleFormModel
} from '@/business-components/fleet-manual-upload-vehicle/fleet-manual-upload-vehicle-model';
import {
  VehicleEditionTypeEnum
} from '@/types/edition/edition-enum.types';
import Utils from '@/utils/utils';
import {
  ErrorsVehicles
} from '../fleet-input-vehicles-data-model';
import {
  EAErrorManager
} from '@zurich-es-npm/ea-front-web-core';
import {
  Table
} from '@zurich-es-npm/ea-front-web-ui';
import {
  FleetInputVehiclesDataUtils
} from '../utils/fleet-input-vehicles-data-utils';


@Component({
  name: 'vehicles-data-table',
})

/**
 * Edit policyholder Component
 */
export default class VehiclesDataTableComponent extends Vue {

  @Prop({
    required: false,
    'default': () => false,
  })
    isAfterBaseSieteTable?: boolean;

  @Prop({
    required: true,
    'default': () => [],
  })
    vehicleList!: VehicleFormModel[];

  @Prop({
    required: false,
    'default': () => []
  })
    errorsVehicles?: ErrorsVehicles[];

  @Prop()
    consultFlow?: boolean;

  @Prop()
    isEditVehicleOpen?: boolean;

  public hasActiveChecks: boolean = false;

  tableReferences = {
    NonBaseSieteVehicles: 'nonBaseSieteVehicles',
    OkBaseSieteVehicles: 'okBaseSieteVehicles'
  };

  /**
   * Look for any active checks in the given table ref.
   */
  onTableCheckChange() {
    EAErrorManager.clearError();
    const eaTable = this.$refs[this.tableRef] as Table | undefined;

    if (eaTable) {
      const rows = eaTable.getSelectedRows();
      if (rows.length === 0) {
        this.hasActiveChecks = false;
      } else {
        this.hasActiveChecks = true;
      }
    } else {
      this.hasActiveChecks = false;
    }

  }

  /**
   * Emit remove event with the select rows to remove.
   */
  onRemoveVehiclesBtn() {
    const eaTable = this.$refs[this.tableRef] as Table | undefined;

    if (eaTable) {
      const rows = eaTable.getSelectedRows();
      this.$emit('removeVehiclesBtn', rows);
    }
  }

  /**
   * Returns the class that the table row should use
   * @param {VehicleFormModel} row
   * @returns {string}
   */
  getTableRowColor(row: VehicleFormModel): string {
    if (row.isExistErrorVehicle) {
      return 't-color-danger';
    }

    if (this.isRowInvalid(row)) {
      return 't-color-warning';
    }

    return '';
  }

  /**
   * Método usado para saber en vista si es un vehículo que mostrar inválido o no
   * @param {VehicleFormModel} row 
   * @returns {boolean}
   */
  isRowInvalid(row: VehicleFormModel): boolean {
    return row.invalid as boolean || row.invalidDate as boolean || row.invalidValues as boolean;
  }

  /**
   * Formatea el precio ejemplo : 500 -> 500.00€
   * @param {String} price valor sin formato 500
   * @returns {String} valor con formato 500.00 €
   */
  digitsFormatter(price: number): string {
    return price ? `${Utils.formatFourDigitNumber(this.$n(price))} €` : '-';
  }
  
  /**
   * Formatea el valor de la fecha en formato "DD/MM/YYYY"
   * @param {String} value fecha sin en formato YYYY-MM-DD
   * @returns {String} fecha en formato "DD/MM/YYYY"
   */
  dateFormatter(value: string): string {
    return value ? Utils.convertDateToString(value) : '';
  }

  /**
   * 
   * @param {VehicleFormModel} row
   * @returns {boolean}
   */
  isVehicleComplete(row: VehicleFormModel): boolean {
    if (this.isEditVehicleOpen) {
      return !!row.effectiveDate && !!row.plateNumber && !!row.plateUseId;
    } else {
      return !!row.vehicleBrand && !!row.vehicleModel && !!row.vehicleVersion;
    }
  }

  /**
   * Set description
   * @param {VehicleFormModel} row 
   * @returns {string}
   */
  getErrorMessageByVehicle(row: VehicleFormModel): string {
    const findErrorText = this.errorsVehicles?.find(vehicle => row.offerNumber === vehicle.offerNumber);
    return findErrorText?.errorMessage || '';
  }

  /**
   * @description Superior layer to call edition when vehicle data is invalid
   * @param {VehicleFormModel} row 
   */
  public openEditVehicleInvalid(row: VehicleFormModel) {
    this.$emit('openEditVehicle', {
      editionType: VehicleEditionTypeEnum.invalid,
      row
    });
  }

  /**
   * @description Superior layer to call edition when vehicle data is not complete after baseSIETE
   * @param {VehicleFormModel} row 
   */
  public openEditVehicleBaseSiete(row: VehicleFormModel) {
    this.$emit('openEditVehicle', {
      editionType: VehicleEditionTypeEnum.basesiete,
      row
    });
    
  }


  /**
   * @description Superior layer to call edition when vehicle data is complete after baseSIETE but non valid use
   * @param {VehicleFormModel} row 
   */
  public openEditVehicleInvalidUse(row: VehicleFormModel) {
    this.$emit('openEditVehicle', {
      editionType: VehicleEditionTypeEnum.use,
      row
    });
  }


  /**
   * @description Superior layer to call edition when vehicle data is complete after baseSIETE
   * @param {VehicleFormModel} row 
   */
  public openEditVehicleNonBaseSiete(row: VehicleFormModel) {
    this.$emit('openEditVehicle', {
      editionType: VehicleEditionTypeEnum.common,
      row
    });
  }
  
  /**
   * @description Superior layer to call edition when vehicle data is complete after baseSIETE
   * @param {VehicleFormModel} row 
   */
  public openEditVehicleWrongDate(row: VehicleFormModel) {
    this.$emit('openEditVehicle', {
      editionType: VehicleEditionTypeEnum.date,
      row
    });
  }

  /**
   * @description Superior layer to call edition when vehicle data is complete after baseSIETE
   * @param {VehicleFormModel} row 
   */
  public openEditVehicleWrongValues(row: VehicleFormModel) {
    this.$emit('openEditVehicle', {
      editionType: VehicleEditionTypeEnum.values,
      row
    });
  }

  /**
   *
   * @param {string} valor 
   * @returns {string}
   */
  valueOrHyphenFormatter(valor: string): string {
    return Utils.valueOrHyphen(valor);
  }

  /**
   * Return vehicles not called to BaseSIETE
   */
  get filteredVehicles(): VehicleFormModel[] {
    const filtered = this.vehicleList.filter(vehicle => {
      if (this.isAfterBaseSieteTable) {
        return vehicle.isBaseSieteData;
      } else {
        return !vehicle.isBaseSieteData;
      }
    });
    return FleetInputVehiclesDataUtils.sortVehicleList(filtered);
  }

  /**
   * Return ref for the current table
   */
  get tableRef(): string {
    if (this.isAfterBaseSieteTable) {
      return this.tableReferences.OkBaseSieteVehicles;
    } else {
      return this.tableReferences.NonBaseSieteVehicles;
    }
  }
}
</script>
