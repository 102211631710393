import FleetPackageSelectModel from '../fleet-package-select/fleet-package-select-model';

/** 
 * Model fleet-packages-warranties-modal
 */
class FleetPackagesWarrantiesModalModel {

  public fleetPackageSelectModel = new FleetPackageSelectModel();
  
}

export default FleetPackagesWarrantiesModalModel;
