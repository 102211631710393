export interface FromValuesInterface {
  vehiclesNumber?: number;
  annualInsurancePremium?: number;
  rentingVehicle?: boolean;
  secondCategoryVehicle?: boolean;
  secondCategoryVehicleScope?: string;
  underageConductor?: boolean;
}

/** 
 * Model fleet-general-info
 */
class FleetGeneralInfoModel {
  public formValues: FromValuesInterface = {
    vehiclesNumber: undefined,
    annualInsurancePremium: undefined,
    rentingVehicle: undefined,
    secondCategoryVehicle: undefined,
    secondCategoryVehicleScope: undefined,
    underageConductor: undefined,
  };

}

export default FleetGeneralInfoModel;
