import {
  PaymentChannelOptions
} from './payment-channel-options-model';

/**
 * 
 */
export class OfferIssuanceDataView {
  public paymentChannel1stReceiptOptions: PaymentChannelOptions[] = [];

  public paymentChannelSucessiveReceiptsOptions: PaymentChannelOptions[] = [];
}
