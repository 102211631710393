<template>
  <div>
    <ea-dialog
      id="emailAddressDialog"
      :title="titleModal"
      :visible="isVisible"
      @open="onDialogOpen"
      :beforeClose="onCancel"
      size="large"
      height="75vh"
    >
      <zz-client-basic-info
        id="showClientData"
        class="m-b-40"
        ref="documentationClientDataComp"
        :documentationClientData="documentationClientData"
      ></zz-client-basic-info>

      <div class="d-display-flex d-justify-space-between">
        <!-- Select address column -->
        <div>
          <h4 class="m-b-0">{{ selectedAddressTitle }}</h4>
          <ea-link
            @click="enterAddingAddressMode">
            {{ addLinkText }}
          </ea-link>

          <div v-if="model.emails.length === 0" class="t-weight-normal">{{ emptyResultText }}</div>

          <ea-radio-group
            class="m-t-32"
            v-if="model.emails.length > 0"
            v-model="model.selectedEmailCode"
            @change="onRadioBtnSelectionChange"
            
          >
            <div v-for="(address, index) in model.emails" :key="index" class="d-display-flex">
              <ea-radio class="m-r-8 m-b-16" :label="address.codigoSecuencialDomicilio">
                {{ labelMapMethod(address) }}
              </ea-radio>
              <ea-button-icon
                @click="enterEditionAddressMode(index)"
                icon="z-edit"/>
            </div>
          </ea-radio-group>
        </div>

        <!-- Add/Edit column -->
        <div class="m-r-24" v-if="model.isAddingEditting">
          <h4 class="m-b-0" v-if="model.emailInEditionIdx === undefined">{{ addColumnTitle }}</h4>
          <h4 class="m-b-0" v-if="model.emailInEditionIdx !== undefined">{{ editColumnTitle }}</h4>

          <ea-form
            ref="form"
            :model="model.emailEditionForm"
            :validateOnRuleChange="false"
            :validationOptions="validationOptions"
          >
            <ea-row>
              <ea-col :span="12">
                <ea-form-item prop="emailType" :label="$t('addEmailModal.fields.emailType')">
                  <ea-select v-model="model.emailEditionForm.emailType" :placeholder="$t('common.label.select')">
                    <ea-option
                      v-for="emailType in corpTableDocumentsEmailType"
                      :key="emailType.value"
                      :label="emailType.label"
                      :value="emailType.value"
                    />
                  </ea-select>
                </ea-form-item>
              </ea-col>
              <ea-col :span="12">
                <ea-form-item prop="email" :label="$t('addEmailModal.fields.email')" required>
                  <ea-input-text v-model="model.emailEditionForm.email" />
                </ea-form-item>
              </ea-col>
            </ea-row>
          </ea-form>
        </div>
      </div>

      <!-- Cancel and save button -->
      <div slot="footer" class="m-r-24 d-display-flex d-justify-flex-end">
        <ea-button type="secondary" @click="onCancel()" class="m-r-16">
          {{ $t('common.label.cancel') }}
        </ea-button>
        <ea-button type="primary" @click="onSave()" v-if="model.isAddingEditting">
          {{ $t('common.label.save') }}
        </ea-button>
        <ea-button type="primary" @click="onSelect()" v-if="!model.isAddingEditting">
          {{ $t('common.label.select') }}
        </ea-button>
      </div>
    </ea-dialog>
  </div>
</template>

<script lang="ts">
import {
  Component, Prop
} from 'vue-property-decorator';

import {
  mixins
} from 'vue-class-component';

import {
  EAApplicationLogger, EABusinessComponent, EAError, EAMethod
} from '@zurich-es-npm/ea-front-web-core';

import AddEmailTomadorModel from './add-email-tomador-model';
import {
  GetPersonAddressesResponseDomicilios
} from '@/services/V1/persons/getPersonAddressesOperation/post';
import {
  SetPersonAddressesRequestDomicilios
} from '@/services/V1/persons/setPersonAddressesOperation/post';

import {
  CorpTableNames,
  fetchCorporateTable,
  parseCorpTableDocuments,
  ParsedTableData,
} from '@/utils/corporate-tables';

import {
  EAFormValidationOptions,
  EAIValidationRule,
  eaTypeValidation,
  EAValidation,
  EAValidationTypes,
  Form,
} from '@zurich-es-npm/ea-front-web-ui';

import {
  cloneDeep
} from 'lodash';
import ZzClientBasicInfo from '@/presentational-components/clientBasicInfo/clientBasicInfo.vue';
import {
  DocumentationClientData
} from '@/presentational-components/clientBasicInfo/clientBasicInfo.type';

@Component({
  name: 'add-email-tomador',
  components: {
    'zz-client-basic-info': ZzClientBasicInfo
  }
})

/**
 *
 */
export default class AddEmailTomadorBusiness extends mixins<EABusinessComponent<AddEmailTomadorModel>>(
  EABusinessComponent
) {
  @Prop()
    titleModal!: string;
  
  @Prop()
    selectedAddressTitle!: string;

  @Prop()
    addColumnTitle!: string;

  @Prop()
    editColumnTitle!: string;

  @Prop()
    addLinkText!: string;

  @Prop()
    emptyResultText!: string;

  @Prop()
    labelMapMethod!: (...args: any) => string;

  @Prop()
    documentationClientData!: DocumentationClientData;

  public corpTableDocumentsEmailType: ParsedTableData[] = [];

  public isVisible = false;

  /**
   * Getter for validation options
   * @returns { EAFormValidationOptions }
   */
  get validationOptions(): EAFormValidationOptions {
    const emailRules: EAIValidationRule[] = [
      eaTypeValidation(EAValidationTypes.EMAIL, 'common.label.validation.invalidEmail'),
    ];

    const validationOptions = {
      rules: {
        email: emailRules,
      },
    };

    return validationOptions;
  }

  /**
   * Hook on created.
   *
   * @returns {void}
   */
  public created(): void {
    this.getCorpTablesData();
  }

  /**
   * Fetch corporate tables to retrieve products names and parse data.
   */
  @EAMethod({
    loading:true
  })
  async getCorpTablesData(): Promise<void> {
    try {
      const promises = [fetchCorporateTable(CorpTableNames.EmailTypes)];
      const results = await Promise.all(promises);

      this.corpTableDocumentsEmailType = parseCorpTableDocuments(results[0]).filter(result => result.value !== 'R');
    } catch (error) {
      const eaError = error as EAError;
      new EAApplicationLogger().error(
        `NewOfferBusiness::getCorpTablesData:: fetch corporate table :: ${eaError.message}`
      );
      throw error;
    }
  }

  /**
   * Handles cancel button
   */
  @EAMethod()
  public onCancel(): void {
    this.close();
    this.update();
    this.$nextTick(() => {
      this.$emit('cancel');
    });
  }

  /**
   * Opens modal
   */
  open(): void {
    this.isVisible = true;
  }

  /**
   * Closes modal
   */
  close(): void {
    this.isVisible = false;
  }

  /**
   * Return form validation object
   * @returns {EAValidation}
   */
  public validation(): EAValidation {
    const form: Form = this.$refs.form as Form;
    return form.validation();
  }

  /**
   * Handles save button.
   * Emits select and save address
   * DOES NOT CLOSE EDITION MODAL AS IT HAS TO REMAIN OPEN IN CASE OF AN ERROR
   */
  @EAMethod()
  public async onSave() {
    this.clearFlowErrors();
    
    try {
      await this.validation().validate();

      let saveEmail: GetPersonAddressesResponseDomicilios = {};
      if (this.model.emailInEditionIdx === undefined) {
        // ADDING
        saveEmail = {
          codigoSecuencialDomicilio: '',
          tipoDomicilio: 'E',
          codigoFiliacion: this.documentationClientData.clientData?.datosBasicosPersona?.codigoFiliacion,
        };
      } else {
        // EDITTING
        saveEmail = {
          ...cloneDeep(this.model.emails[this.model.emailInEditionIdx]),
          indicadorDomicilioPrincipal: ''
        };
      }

      saveEmail.emailContacto = this.model.emailEditionForm.email;
      saveEmail.claseDomicilio = this.model.emailEditionForm.emailType;

      this.$emit('saveEmailAddress', saveEmail as SetPersonAddressesRequestDomicilios);
    } catch (err) {
      // Design system will show form errors
      const modalHtml = document.querySelector(`#emailAddressDialog .el-dialog`);
      modalHtml?.scrollTo(0, 0);
    }
  }

  /**
   * Gets if received address is main adress
   * @param {any} address
   * @returns {boolean}
   */
  public isMainAddress(address: GetPersonAddressesResponseDomicilios): boolean {
    return address.indicadorDomicilioPrincipal === 'S';
  }

  /**
   * Shows add address form
   */
  @EAMethod()
  public enterAddingAddressMode() {
    this.resetEditionForm();
    this.model.isAddingEditting = true;
    this.model.emailInEditionIdx = undefined;
    this.update();
  }

  /**
   * Selects address to edit
   * @param {number} index
   */
  @EAMethod()
  public enterEditionAddressMode(index: number) {
    this.model.isAddingEditting = true;

    this.model.emailInEditionIdx = index;
    const emailInEdition = this.model.emails[this.model.emailInEditionIdx];
    this.model.emailEditionForm = {
      email: emailInEdition.emailContacto,
      emailType: emailInEdition.claseDomicilio,
    };

    this.model.selectedEmailCode = emailInEdition.codigoSecuencialDomicilio;
    this.update();
  }

  /**
   * Resets edition form
   */
  public resetEditionForm() {
    this.model.emailEditionForm = {
      email: '',
      emailType: '',
    };
  }

  /**
   * Listens dialog open event
   * Resets dialog
   * Recalculate person's info labels
   */
  public onDialogOpen() {
    this.resetEditionForm();
    this.model.isAddingEditting = false;
    this.model.emailInEditionIdx = undefined;

    const qbDocumentationClientDataComp: ZzClientBasicInfo =
      this.$refs.documentationClientDataComp as ZzClientBasicInfo;
    if (qbDocumentationClientDataComp) {
      qbDocumentationClientDataComp.calculateLabels();
    }
  }

  /**
   * Handles select button
   * Saves marked address as selected address
   */
  @EAMethod()
  public onSelect() {
    this.$emit('selectEmailAddress', this.model.selectedEmailCode);
  }

  /**
   * Handler for radiobuttons change
   */
  public onRadioBtnSelectionChange() {
    this.model.isAddingEditting = false;
    this.model.emailInEditionIdx = undefined;
  }
}
</script>
