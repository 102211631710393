<template>
  <div class="massiveSearch">
    <ea-select
      v-model="tipoBusquedaMasiva"
      :placeholder="$t(`massiveSearchComponent.placerholders.select`)"
      @change="setSearchType($event)"
      class="massiveSearchSelect"
    >
      <ea-option
        v-for="(item, index) in searchTypes"
        :key="index"
        :label="$t(`massiveSearchComponent.searchTypes.${item.name}`)"
        :value="item.value"/>
    </ea-select>
    <ea-input-text
      class="massiveSearchInput"
      :placeholder="$t(`massiveSearchComponent.placerholders.${tipoBusquedaMasiva || 'base'}`)"
      v-model="valorBusquedaMasiva"
      @input="searchValueHandler($event)"
      @blur="completePolicyNumber"
      :maxlength="maxLength"
    />
    <ea-button
        square
        icon="z-search"
        :disabled="!tipoBusquedaMasiva || !valorBusquedaMasiva"
        @click="onClickSendSearch"/>
  </div>
</template>

<script lang="ts">
import {
  EAMassiveSearchApi,
  MassiveSearchRequest,
  MassiveSearchRequestTipoBusquedaMasivaEnum as TipoBusquedaMasivaEnum,
  MassiveSearchResponse,
  Error
} from '@/services/V1/home/massiveSearchOperation/post/api';
import PermissionUtils from '@/utils/permissions-utils';
import {
  EAApplicationError,
  EAApplicationLogger,
  EAError,
  EAEventBus, EAMethod
} from '@zurich-es-npm/ea-front-web-core';
import {
  CoexistenceId
} from '../../types/coexistence/coexistence-enum.types';
import Vue from 'vue';
import {
  Component
} from 'vue-property-decorator';
import {
  MassiveSearchSelect,
  NO_RESULT_FOUND_CODES,
  TipoBusquedaMasivaNameEnum
} from './massiveSearch.select';
import {
  EaMessageOptions, EaMessageType, EANotification
} from '@zurich-es-npm/ea-front-web-ui/lib/ea/rich/message/src/message-model';
import {
  isValidCif,
  isValidNie,
  isValidNif
} from '@/utils/document-validations';
import {
  FlowThunderNameEnum
} from '@/types/flow/flow-enum.types';
import {
  EmitNewFlowData,
  NavigationDataModel,
  TargetEnum
} from '../../utils/open-new-flow/open-new-flow.types';
import {
  SearchTypeEnum
} from '@/utils/fields/fields.enum';
import {
  SearchPolicyModel
} from '@/flows/search-policy/search-policy-model';
import {
  SearchOfferModel
} from '@/flows/search-offer/search-offer-model';

@Component({
  name: 'massive-search',
})

/**
 * Presentational Component massive-search
 */
export default class MassiveSearch extends Vue {

  maxLength: number = 12;

  searchTypes: MassiveSearchSelect[] = [];

  activeErrors: EANotification[] = [];

  tipoBusquedaMasiva: TipoBusquedaMasivaEnum | null = null;

  valorBusquedaMasiva: string | null = null;

  /**
   * Hook when page is created.
   */
  created() {
    this.searchTypes = [{
      name: TipoBusquedaMasivaNameEnum.Client,
      value: TipoBusquedaMasivaEnum.Cliente
    }];
    const abilities = PermissionUtils.getUserAbilities();
    if (abilities.length > 0) {
      if (PermissionUtils.hasAbility(abilities, 'massive-search-policy')) {
        this.searchTypes.push({
          name: TipoBusquedaMasivaNameEnum.Policy,
          value: TipoBusquedaMasivaEnum.Poliza
        });
      }
      if (PermissionUtils.hasAbility(abilities, 'massive-search-offer')) {
        this.searchTypes.push({
          name: TipoBusquedaMasivaNameEnum.Offer,
          value: TipoBusquedaMasivaEnum.Oferta
        });
      }
      if (PermissionUtils.hasAbility(abilities, 'massive-search-receipt')) {
        this.searchTypes.push({
          name: TipoBusquedaMasivaNameEnum.Receipt,
          value: TipoBusquedaMasivaEnum.Recibo
        });
      }
      if (PermissionUtils.hasAbility(abilities, 'massive-search-sinister')) {
        this.searchTypes.push({
          name: TipoBusquedaMasivaNameEnum.Sinister,
          value: TipoBusquedaMasivaEnum.Siniestro
        });
      }
    }
  }

  /**
   * @param {string} searchType - 'oferta' | 'poliza' | 'cliente' | 'recibo' | 'siniestro';
   */
  setSearchType(searchType: TipoBusquedaMasivaEnum) {
    this.tipoBusquedaMasiva = searchType;
    this.valorBusquedaMasiva = null;

    if (this.tipoBusquedaMasiva === TipoBusquedaMasivaEnum.Poliza ||
        this.tipoBusquedaMasiva === TipoBusquedaMasivaEnum.Oferta) {
      this.maxLength = 14;
    } else if (this.tipoBusquedaMasiva === TipoBusquedaMasivaEnum.Recibo) {
      this.maxLength = 12;
    } else if (this.tipoBusquedaMasiva === TipoBusquedaMasivaEnum.Siniestro) {
      this.maxLength = 10;
    } else {
      this.maxLength = 9;
    }
    this.$forceUpdate();
  }

  /**
   * Send search Event => función controladora
   */
  async onClickSendSearch() {
    // Clean errors
    this.activeErrors.forEach(activeError => activeError.close());
    this.activeErrors = [];

    // Comprobe identity
    if (this.tipoBusquedaMasiva === TipoBusquedaMasivaEnum.Cliente
        && this._isInvalidIdentity() === true) {
      const messageError = this.$t(`massiveSearchComponent.errors.clientFormatError`).toString();
      this.pushEaMessage(messageError);
      return;
    }

    // Fetch()
    let response;
    
    try {
      const params: MassiveSearchRequest = {
        tipoBusquedaMasiva: this.tipoBusquedaMasiva as TipoBusquedaMasivaEnum,
        valorBusquedaMasiva: this.valorBusquedaMasiva as string
      };
      response = await this._fetchMassiveSearch(params);
      // ! Response && comprobe Bussiness errors
      if (this._hasBussinessErrors(response)) {
        return;
      }
    } catch (err) {
      // 500
      const eaError = err as EAError;
      throw new EAApplicationError(eaError.message);
      
    }

    this.goToFlow(response as MassiveSearchResponse, this.tipoBusquedaMasiva as TipoBusquedaMasivaEnum);
    this.tipoBusquedaMasiva = null;
    this.valorBusquedaMasiva = null;
  }

  /**
   * @returns {boolean}
   */
  _isInvalidIdentity(): boolean {
    return !isValidNif(this.valorBusquedaMasiva as string)
          && !isValidNie(this.valorBusquedaMasiva as string)
          && !isValidCif(this.valorBusquedaMasiva as string);
  }

  /**
   * @param {MassiveSearchResponse | null} response 
   * @returns {boolean}
   */
  _hasBussinessErrors(response: MassiveSearchResponse | null): boolean {
    if (!response) {
      // TODO: la búsqueda no se ha podido realizar en este momento
      const messageError = this.$t(`massiveSearchComponent.errors.noResultsFound`).toString();
      this.pushEaMessage(messageError);
      return true;
    }

    // Error bussiness
    if (response?.errors?.length) {
      response.errors.forEach((err: Error, index: number) => {
        if (NO_RESULT_FOUND_CODES.find((code: string) => code===err.code)) {
          err.message = this.$t(`massiveSearchComponent.errors.noResultsFound`).toString();
        }
        this.pushEaMessage(err.message, (index+1)*35);
      });
    }
    return response?.errors?.length ? true : false;
  }
  
  /**
   * @param {MassiveSearchRequest} massiveSearchRequest
   */
  @EAMethod()
  async _fetchMassiveSearch(
    massiveSearchRequest: MassiveSearchRequest
  ): Promise<MassiveSearchResponse | null> {
    const massiveSearchApi = new EAMassiveSearchApi();
    return massiveSearchApi.massiveSearchOperation({
      massiveSearchRequest: massiveSearchRequest
    });
  }


  /**
   * @param {MassiveSearchResponse} response
   * @param {MassiveSearchType} tipoBusquedaMasiva - 'oferta' | 'poliza' | 'cliente' | 'recibo' | 'siniestro';
   */
  goToFlow(
    response: MassiveSearchResponse,
    tipoBusquedaMasiva?: TipoBusquedaMasivaEnum
  ): void {
    if (!tipoBusquedaMasiva || !response[tipoBusquedaMasiva]) {
      return;
    }
    let navigationData: NavigationDataModel;
    let emitNewFlowData: EmitNewFlowData;
    switch (tipoBusquedaMasiva) {
      case TipoBusquedaMasivaEnum.Poliza:
        navigationData = new SearchPolicyModel();
        navigationData.searcherModel.offerNumber = response.poliza?.codigoPoliza as string;
        navigationData.searcherModel.searchBy = SearchTypeEnum.NumeroPoliza;
        emitNewFlowData = {
          idFlow : FlowThunderNameEnum.SearchPolicyFlow,
          isThunder: true,
          target: TargetEnum.navigate,
          navigationData: navigationData
        };
        EAEventBus.$emit('openNewFlow', emitNewFlowData);
        break;

      case TipoBusquedaMasivaEnum.Oferta:
        navigationData = new SearchOfferModel();
        navigationData.searcherModel.searchBy = SearchTypeEnum.NumeroOferta;
        navigationData.searcherModel.offerNumber = response.oferta?.codigoOferta as string;
        emitNewFlowData = {
          idFlow : FlowThunderNameEnum.SearchOfferFlow,
          isThunder: true,
          target: TargetEnum.navigate,
          navigationData: navigationData
        };
        EAEventBus.$emit('openNewFlow', emitNewFlowData);
        break;
      
      case TipoBusquedaMasivaEnum.Cliente:
        //TODO: revisar tipado con toni
        navigationData = {
          cdidfisc: response.cliente?.indicadorFiscal as string,
          tctidonc: response.cliente?.tipoDocumento as string,
        };
        emitNewFlowData = {
          idFlow : CoexistenceId.ConsultaCliente,
          isThunder: false,
          target: TargetEnum.navigate,
          navigationData: navigationData
        };
        EAEventBus.$emit('openNewFlow', emitNewFlowData);
        break;

      case TipoBusquedaMasivaEnum.Siniestro:
        //TODO: revisar tipado con toni
        navigationData = {
          cdsinies: response.siniestro?.codigoSiniestro as string,
          flow: 'claimTreeClaimData'
        };
        emitNewFlowData = {
          idFlow : CoexistenceId.Siniestros,
          isThunder: false,
          target: TargetEnum.navigate,
          navigationData: navigationData
        };
        EAEventBus.$emit('openNewFlow', emitNewFlowData);
        return;
      
      case TipoBusquedaMasivaEnum.Recibo:
        //TODO: revisar tipado con toni
        navigationData = {
          receiptNumber: response.recibo?.codigoRecibo as string
        };
        emitNewFlowData = {
          idFlow: CoexistenceId.Recibos,
          isThunder: false,
          target: TargetEnum.navigate,
          navigationData: navigationData
        };
        EAEventBus.$emit('openNewFlow', emitNewFlowData);
        return;


      default:
        new EAApplicationLogger().error(
          `MassiveSearch::goToFlow:: switch tipoBusqueda :: Default case - Type not match`
        );
        
    }
    

    /*
     * If (tipoBusquedaMasiva === TipoBusquedaMasivaEnum.Poliza) {
     *   cdnumpol = response[tipoBusquedaMasiva]?.codigoPoliza;
     *   operationId = CoexistenceId.ConsultaPoliza;
     *   coexistenceObj = {
     *     cdnumpol
     *   };
     * } else if (tipoBusquedaMasiva === TipoBusquedaMasivaEnum.Oferta) {
     *   cdnumpol = response[tipoBusquedaMasiva]?.codigoOferta;
     *   if (!cdnumpol) {
     *     // PARCHE POR ERROR DEL COMPORTAMIENTO DEL SERVICIO COBOL
     *     const message = this.$t(`massiveSearchComponent.errors.noResultsFound`).toString();
     *     this.pushEaMessage(message, 35);
     */
        
    /*
     *   }
     *   operationId = CoexistenceId.ConsultaOferta;
     *   coexistenceObj = {
     *     cdnumpol
     *   };
     * } else if (tipoBusquedaMasiva === TipoBusquedaMasivaEnum.Cliente) {
     *   const cdidfisc = response[tipoBusquedaMasiva]?.indicadorFiscal;
     *   const tctidonc = response[tipoBusquedaMasiva]?.tipoDocumento;
     *   operationId = '0802';
     *   coexistenceObj = {
     *     cdidfisc,
     *     tctidonc
     *   };
     * } else if (tipoBusquedaMasiva === TipoBusquedaMasivaEnum.Siniestro) {
     *   const cdsinies = response[tipoBusquedaMasiva]?.codigoSiniestro;
     *   operationId = '0430';
     *   coexistenceObj = {
     *     cdsinies,
     *     flow: 'claimTreeClaimData'
     *   };
     * } else if (tipoBusquedaMasiva === TipoBusquedaMasivaEnum.Recibo) {
     *   const receiptNumber = response[tipoBusquedaMasiva]?.codigoRecibo;
     *   operationId = '0510';
     *   coexistenceObj = {
     *     receiptNumber
     *   };
     * }
     * if (operationId) {
     *   EAFlowNavigationUtils.navigate(this, 'ZonaZurich', {
     *     operationId,
     *     operationParams: JSON.stringify(coexistenceObj)
     *   });
     * }
     */
  }

  /**
   * @param {string} newInputValue to select
   * work around while v-mask is fixed
   */
  async searchValueHandler(newInputValue: string) {
    await this.$nextTick();
    if (this.tipoBusquedaMasiva === TipoBusquedaMasivaEnum.Oferta ||
        this.tipoBusquedaMasiva === TipoBusquedaMasivaEnum.Poliza ||
        this.tipoBusquedaMasiva === TipoBusquedaMasivaEnum.Recibo ||
        this.tipoBusquedaMasiva === TipoBusquedaMasivaEnum.Siniestro) {
      if (newInputValue) {
        this.valorBusquedaMasiva = newInputValue.replace(/\D/g, '');
      }
    }
  }
  
  /**
   * Complete policy number with 0 to left
   */
  completePolicyNumber() {
    if (this.tipoBusquedaMasiva === TipoBusquedaMasivaEnum.Siniestro &&
        this.valorBusquedaMasiva) {
      this.valorBusquedaMasiva = this.valorBusquedaMasiva?.padStart(10, '0');
    }
    if (this.tipoBusquedaMasiva === TipoBusquedaMasivaEnum.Poliza &&
        this.valorBusquedaMasiva) {
      this.valorBusquedaMasiva = this.valorBusquedaMasiva?.padStart(14, '0');
    }
    if (this.tipoBusquedaMasiva === TipoBusquedaMasivaEnum.Oferta &&
        this.valorBusquedaMasiva) {
      this.valorBusquedaMasiva = this.valorBusquedaMasiva?.padStart(12, '0');
      this.valorBusquedaMasiva = this.valorBusquedaMasiva?.padStart(14, '8');
    }
    if (this.tipoBusquedaMasiva === TipoBusquedaMasivaEnum.Recibo &&
        this.valorBusquedaMasiva) {
      this.valorBusquedaMasiva = this.valorBusquedaMasiva?.padStart(12, '0');
    }
  }


  /**
   * @param {string} message
   * @param {number} offset por defecto 20 como indica en la documentación oficial de element ui
   * @param {EaMessageType} type
   */
  pushEaMessage(message: string, offset: number = 20, type: EaMessageType = 'error') {
    const messageOptions: EaMessageOptions = {
      message,
      type,
      duration: 7000,
      showClose: true,
      offset
    };
    const activeError: EANotification = this.$eaMessage(messageOptions);
    this.activeErrors.push(activeError);
  }
}
</script>

<style scoped lang="scss">
.massiveSearchInput {
  width: 45% !important;
  margin-right: 4px;
}
.massiveSearchSelect {
  width: 35% !important;
  margin-right: 4px;
}
</style>
