<template>
  <div>
    <ea-row v-if="isDataLoaded">
      <!-- Policy summary -->
      <ea-col :span="24" class="m-b-16">
        <fleet-summary-policy-info :model="model" />
      </ea-col>

      <!-- Policy holder summary -->
      <ea-col :span="24" class="m-b-16">
        <fleet-policyholder-policy-info
          :model="model"
          :addressTypesList="addressTypesList"
          :documentTypeList="documentTypeList"
        />
      </ea-col>
      
      <ea-col :span="24">
        <ea-card shadow="hidden">
          <div v-if="isEmptyPendingVehicleList">
            <fleet-maintenance-vehicle-groups-business
              id="fleet-maintenance-vehicle-groups"
              ref="fleetMaintenanceVehicleGroups"
              v-model="model.fleetEmittedVehicleGroupsModel"
              :policyEmitedStatusList="policyEmitedStatusList"
              @anulateVehicle="onAnulateVehicle"
            />
          </div>
          <ea-tabs v-if="!isEmptyPendingVehicleList">
            <ea-tab-pane class="p-a-16"
              :label="$t('fleets.fleetsMaintenancePolicyFlow.fleetMaintenanceGroupVehicleTable.tabTitleEmitted')">
              <fleet-maintenance-vehicle-groups-business
                id="fleet-maintenance-vehicle-groups"
                ref="fleetMaintenanceVehicleGroups"
                v-model="model.fleetEmittedVehicleGroupsModel"
                :policyEmitedStatusList="policyEmitedStatusList"
                @anulateVehicle="onAnulateVehicle"
              />
            </ea-tab-pane>
            <ea-tab-pane
              class="p-a-16"
              :label="$t('fleets.fleetsMaintenancePolicyFlow.fleetMaintenanceGroupVehicleTable.tabTitlePending')"
              name="1" >
              <ea-table
                :data="this.pendindVehicleList"
                :id="pendindVehicleList"
                :infinite-scroll="true"
                class="thead-fixed"
              >

                <ea-table-column
                  headerClass="no-sortBy"
                  :label="$t('fleets.fleetsMaintenancePolicyFlow.fleetMaintenanceGroupVehicleTable.'+
                    'tableDataHeaders.licensePlateNumber')"
                  show="numeroMatriculaSinValidaciones"
                >
                  <template slot-scope="row">
                    <span>
                      {{ row.numeroMatriculaSinValidaciones }}
                    </span>
                  </template>
                </ea-table-column>

                <ea-table-column
                  headerClass="no-sortBy"
                  :label="$t('fleets.fleetsMaintenancePolicyFlow.fleetMaintenanceGroupVehicleTable.'+
                    'tableDataHeaders.brand')"
                  show="descripcionMarcaVehiculo"
                >
                  <template slot-scope="row">
                    <span>
                      {{ row.descripcionMarcaVehiculo }}
                    </span>
                  </template>
                </ea-table-column>

                <ea-table-column
                  headerClass="no-sortBy"
                  :label="$t('fleets.fleetsMaintenancePolicyFlow.fleetMaintenanceGroupVehicleTable.'+
                    'tableDataHeaders.model')"
                  show="descripcionModeloVehiculo"
                >
                  <template slot-scope="row">
                    <span>
                      {{ row.descripcionModeloVehiculo }}
                    </span>
                  </template>
                </ea-table-column>

                <ea-table-column
                  headerClass="no-sortBy"
                  :label="$t('fleets.fleetsMaintenancePolicyFlow.fleetMaintenanceGroupVehicleTable.'+
                    'tableDataHeaders.version')"
                  show="descripcionVersionVehiculo"
                >
                  <template slot-scope="row">
                    <span>
                      {{ row.descripcionVersionVehiculo }}
                    </span>
                  </template>
                </ea-table-column>

                <ea-table-column
                  headerClass="no-sortBy"
                  :label="$t('fleets.fleetsMaintenancePolicyFlow.fleetMaintenanceGroupVehicleTable.'+
                  'tableDataHeaders.package')"
                  :sortable="false"
                >
                  <template slot-scope="col">
                    <span>
                      {{
                        col.codigoPaquete
                          ? $t(`fleets.fleetsFlow.fleetPackagesWarrantiesModal.codes.${col.codigoPaquete}`)
                          : '-'
                      }}
                    </span>
                  </template>
                </ea-table-column>

                <ea-table-column
                  headerClass="no-sortBy"
                  :label="$t('fleets.fleetsMaintenancePolicyFlow.fleetMaintenanceGroupVehicleTable.'+
                    'tableDataHeaders.annualPremium')"
                  show="importeTotal"
                >
                  <template slot-scope="row">
                    <div class="wp-80 d-display-flex d-justify-flex-end">
                      <ea-text>
                        {{ digitsFormatter(row.importeTotal) }}
                      </ea-text>
                    </div>
                  </template>
                </ea-table-column>

                <ea-table-column
                  :label="$t('fleets.fleetsMaintenancePolicyFlow.fleetMaintenanceGroupVehicleTable.'+
                    'tableDataHeaders.status')"
                  :sortable="false"
                >
                  <template slot-scope="col">
                    <span class="d-display-flex d-align-items-center">
                      <ea-icon
                        class="m-r-8"
                        v-if="col.estadoPolizaBatch === PolicyBatchOnline.PolicyPendingEmit ||
                          col.estadoPolizaBatch === PolicyBatchOnline.NotRate ||
                          col.estadoPolizaBatch === PolicyBatchOnline.PendingRate"
                        status="info"
                        icon="el-icon-warning-outline"
                      />
                      <ea-icon
                        class="m-r-8"
                        v-else-if="col.estadoPolizaBatch === PolicyBatchOnline.PolicyEmitted ||
                          col.estadoPolizaBatch === PolicyBatchOnline.Rated"
                        status="success"
                        icon="el-icon-circle-check"
                      />
                      <ea-icon
                        class="m-r-8"
                        v-else-if="col.estadoPolizaBatch === PolicyBatchOnline.GDC"
                        status="warning"
                        icon="el-icon-warning-outline"
                      />
                      <ea-tooltip
                        v-else-if="col.estadoPolizaBatch === PolicyBatchOnline.PolicyError ||
                          col.estadoPolizaBatch === PolicyBatchOnline.Error"
                        placement="top"
                        class="kpi-title-tooltip d-display-flex d-align-items-center"
                        :content="col.descripcionErrorTarificacion"
                      >
                        <ea-icon class="m-r-8" status="danger" icon="el-icon-remove-outline" />
                      </ea-tooltip>
                      {{ getStatusText(col) }}
                    </span>
                  </template>
                </ea-table-column>
              </ea-table>
              
            </ea-tab-pane>
          </ea-tabs>
        </ea-card>
      </ea-col>
    </ea-row>

    <fleet-footer-total-annual-premium
      :totalAnnualPremium="annualPremiumEmittedVehicles"
      :totalEmittedVehicles="totalEmittedVehicles"
      :showEmittedVehicles="true"
    />

    <ea-row
      extraClass="m-t-8"
    >
      <ea-col :span="12" class="d-display-flex d-justify-flex-start">
        <ea-button type="secondary" @click="onGoBack()" v-if="model.hasParentFlow">
          {{ $t('common.label.back') }}
        </ea-button>
      </ea-col>
      <ea-col :span="12" class="d-display-flex d-justify-flex-end">
        <ea-button
          type="primary"
          @click="nextStep()"
        >
          {{ $t('fleets.fleetsMaintenancePolicyFlow.summaryView.addVehiclesButton')  }}
        </ea-button>
      </ea-col>
    </ea-row>

    <template v-if="model.vehicleToCancel">
      <fleet-cancel-vehicle-modal
        id="cancel-vehicle-modal"
        v-model="model.vehicleToCancel"
        :visible="cancelModalVisible"
        @closeModal="onCancelVehicleModal">
      </fleet-cancel-vehicle-modal>
    </template>

  </div>
</template>

<script lang="ts">
import {
  Component
} from 'vue-property-decorator';
import {
  mixins
} from 'vue-class-component';
import {
  EAErrorManager,
  EAMethod,
  EAView,
  ResponseWithErrors,
  throwIfResponseHasErrors
} from '@zurich-es-npm/ea-front-web-core';
import {
  FleetsMaintenancePolicyModel,
  FlowViewsStepsModel,
  FlowHeaderStepsModel
} from '../fleets-maintenance-policy-model';
import FleetSummaryPolicyInfo
  from '../../../presentational-components/fleets/summary/fleet-summary-policy-info.vue';
import FleetPolicyholderPolicyInfo
  from '../../../presentational-components/fleets/summary/fleet-policyholder-policy-info.vue';
import FlowModelFillUtils, {
  VehicleListOperationEnum
} from '@/flows/fleets/utils/flow-model-fill.utils';
import {
  NotificationsUtils
} from '@/utils/notifications/notifications-utils';
import FleetMaintenanceVehicleGroupsBusiness
  from '@/business-components/fleet-maintenance-vehicle-groups/fleet-maintenance-vehicle-groups-business.vue';
import {
  cloneDeep
} from 'lodash';
import FleetVehicleGroupsModel from '@/business-components/fleet-vehicle-groups/fleet-vehicle-groups-model';
import {
  GetGroupedVehicleListResponseListaAgrupaciones,
  GetGroupedVehicleListResponseListaVehiculos
} from '@/services/V1/fleets/getGroupedVehicleListOperation/post';
import {
  EAGetCancelPolicyInformationApi, GetCancelPolicyInformationResponse
} from '@/services/V1/policy/getCancelPolicyInformationOperation/post';
import {
  PolicyBatchOnline
} from '@/types/batch-online/batch-online.types';
import Utils from '@/utils/utils';
import {
  CorpTableNames,
  ParsedTableData,
  fetchCorporateTable,
  parseCorpTableDocuments
} from '@/utils/corporate-tables';
import FleetCancelVehicleModal
  from '@/business-components/fleets-cancel-vehicle/fleets-cancel-vehicle-modal-business.vue';
import FleetsCancelVehicleModalModel
  from '@/business-components/fleets-cancel-vehicle/fleets-cancel-vehicle-modal-model';
import FleetFooterTotalAnnualPremium
  from '@/flows/fleets/components/fleet-footer-total-annual-premium.vue';

@Component({
  components: {
    'fleet-maintenance-vehicle-groups-business': FleetMaintenanceVehicleGroupsBusiness,
    'fleet-footer-total-annual-premium': FleetFooterTotalAnnualPremium,
    FleetPolicyholderPolicyInfo,
    FleetSummaryPolicyInfo,
    FleetCancelVehicleModal
  }
})

/**
 * FleetSummary view
 *
 */
export default class FleetSummaryView extends mixins<EAView<FleetsMaintenancePolicyModel>>(EAView) {
  public pendindVehicleList: GetGroupedVehicleListResponseListaVehiculos[] = [];

  public PolicyBatchOnline = PolicyBatchOnline;

  public isEmptyPendingVehicleList = true;

  public addressTypesList: ParsedTableData[] = [];

  public documentTypeList: ParsedTableData[] = [];

  public policyEmitedStatusList!: ParsedTableData[];

  isDataLoaded = false; // Flag to know when data is ready in prder ro print components

  public annualPremiumEmittedVehicles: number = 0;

  public totalEmittedVehicles: number = 0;

  vehicleToCancel: GetGroupedVehicleListResponseListaVehiculos | null = null;

  cancelModalVisible: boolean = false;

  /**
   * 
   */
  get maxVehiclesFleet() {
    return this.model.flowConfiguration.fleetsMaintenancePolicy.fleetSummaryView.maxVehicles;
  }

  /**
   * Hooks on created
   */
  async created() {
    await this.refreshData();
  }

  /**
   * Refresh entire data
   */
  @EAMethod({
    loading: true
  })
  async refreshData() {
    this.isDataLoaded = false;

    const results = await Promise.all([
      FlowModelFillUtils.loadFleetData(this.model, false),
      fetchCorporateTable(CorpTableNames.AddressType),
      fetchCorporateTable(CorpTableNames.DocumentTypes),
      fetchCorporateTable(CorpTableNames.PolicyEmitedStatus),
      fetchCorporateTable(CorpTableNames.BatchOnlineStatus)
    ]);
    this.addressTypesList = parseCorpTableDocuments(results[1]);
    this.documentTypeList = parseCorpTableDocuments(results[2]);
    this.policyEmitedStatusList = parseCorpTableDocuments(results[3]);
    this.model.batchOnlineStatusList = parseCorpTableDocuments(results[4]);

    const resEmittedGroupedVehicleList =
      await FlowModelFillUtils.loadVehicleList(this.model, VehicleListOperationEnum.Emitidos);
    if (resEmittedGroupedVehicleList) {
      this.model.fleetEmittedVehicleGroupsModel.fleetGroupedVehicleList =
        cloneDeep(resEmittedGroupedVehicleList.listaAgrupaciones as GetGroupedVehicleListResponseListaAgrupaciones[]);
      // We get the number of vehicles and the total annual premium of EMITTED vehicles from COBOL
      this.totalEmittedVehicles = resEmittedGroupedVehicleList.numeroVehiculosVigor as number;
      this.model.emmitedVehicles = resEmittedGroupedVehicleList.numeroVehiculosVigor as number;
      this.annualPremiumEmittedVehicles = resEmittedGroupedVehicleList.primaTotalFlota as number;
      this.model.fleetVehicleGroupsModel = new FleetVehicleGroupsModel();
    }

    //TODO: Cambiar por Pendientes
    const resPendingGroupedVehicleList =
      await FlowModelFillUtils.loadVehicleList(this.model, VehicleListOperationEnum.Pendientes);
    if (resPendingGroupedVehicleList?.listaAgrupaciones?.length) {
      this.isEmptyPendingVehicleList = false;
      this.pendindVehicleList =this.concatVehiclesFromGroups(
        cloneDeep(resPendingGroupedVehicleList.listaAgrupaciones)
      );
      this.model.fleetVehicleGroupsModel = new FleetVehicleGroupsModel();
    }

    // Data is loaded
    this.isDataLoaded = true;
    
    this.update();
  }

  /**
   * Concat all vehicles from all groups in the same list
   * @param {GetGroupedVehicleListResponseListaAgrupaciones[]} listaAgrupaciones
   * @returns {GetGroupedVehicleListResponseListaVehiculos[]}
   */
  concatVehiclesFromGroups(listaAgrupaciones: GetGroupedVehicleListResponseListaAgrupaciones[]):
  GetGroupedVehicleListResponseListaVehiculos[] {
    let fullList: GetGroupedVehicleListResponseListaVehiculos[] = [];
    for (const group of listaAgrupaciones) {
      if (group.listaVehiculos) {
        fullList = fullList.concat(group.listaVehiculos);
      }
    }
    return fullList;
  }

  /**
   * Formatea el precio ejemplo : 500 -> 500.00€
   * @param {String} price valor sin formato 500
   * @returns {String} valor con formato 500.00 €
   */
  digitsFormatter(price: number): string {
    return price ? `${Utils.formatFourDigitNumber(this.$n(price))} €` : '-';
  }

  /**
   * Go to the next step
   */
  @EAMethod({
    loading: true
  })
  async nextStep() {
    // Reset warning messages
    NotificationsUtils.clearNotifications();
    EAErrorManager.clearError();
 
    // We get the total number of emitted vehicles from COBOL
    if (this.totalEmittedVehicles < this.maxVehiclesFleet) {
      this.$emit('changeStep',
        FlowViewsStepsModel.VehiclesDataStep,
        FlowHeaderStepsModel.VehiclesDataStep
      );
    } else {
      NotificationsUtils.throwWarning(
        this.$t('fleets.fleetsMaintenancePolicyFlow.notifications.maxVehicles').toString());
    }
    
  }

  /**
   * Return the text of the table from policyEmitedStatusList
   * @param {GetGroupedVehicleListResponseListaVehiculos} col con los datos del vehiculo
   * @returns {string}
   */
  getStatusText(col: GetGroupedVehicleListResponseListaVehiculos) {
    // El estado en la tabla flexible corresponde al estadoPolizaBatch añadiendo una “P” delante
    const statusText = this.model.batchOnlineStatusList.find(item => item.value === `P${col.estadoPolizaBatch}`);
    return statusText?.label;
  }

  /**
   * Handles back button
   * @param {GetGroupedVehicleListResponseListaVehiculos} vehicle con los datos del vehiculo
   */
  @EAMethod({
    loading: true
  })
  async onAnulateVehicle(vehicle: GetGroupedVehicleListResponseListaVehiculos) {
    const api = new EAGetCancelPolicyInformationApi();
    const getCancelPolicyInformationRequest = {
      codigoPoliza: vehicle.codigoPoliza as string,
      versionPoliza: vehicle.versionPoliza as number
    };

    const response: GetCancelPolicyInformationResponse = await api.getCancelPolicyInformationOperation({
      getCancelPolicyInformationRequest
    }) as GetCancelPolicyInformationResponse;

    throwIfResponseHasErrors(response as ResponseWithErrors);

    //Cast to map fields coincidences
    this.model.vehicleToCancel = new FleetsCancelVehicleModalModel();
    this.model.vehicleToCancel = {
      ...this.model.vehicleToCancel,
      ...response as FleetsCancelVehicleModalModel,
      tiposMovimiento: this.model.flowConfiguration.cancelFleet.movementTypes,
      plateNumber: vehicle.numeroMatriculaSinValidaciones || ''
    };

    await this.$nextTick();

    NotificationsUtils.clearNotifications();
    EAErrorManager.clearError();

    this.cancelModalVisible = true;
  }


  /**
   * Handles cancel vehicle modal
   * @param {boolean | undefined} mustRefresh
   */
  async onCancelVehicleModal(mustRefresh?: boolean) {
    //Unset model
    this.model.vehicleToCancel = null;
    this.cancelModalVisible = false;

    if (mustRefresh) {
      await this.refreshData();
    }
  }

  /**
   * Handles back button
   */
  onGoBack() {
    this.$emit('goToPreviousFlow');
  }
}
</script>
