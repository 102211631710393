<template>
  <div class="d-display-flex d-justify-center" v-if="optionsToSelect.length">
    <template v-if="canSelectMultipleOptions">
      <ea-checkbox-group v-model="model.multipleSelectedOptions" @change="onSelectedOptionChange">
        <ea-row v-for="row in numRows" :key="`row_${row}`" class="d-flex">
          <ea-col v-for="col in numCols" :key="`col_${col}`" :span="colSpan">
            <div v-if="isTableLastElem(row, col)" class="d-display-flex align-items-center">
              <ea-button type="text">
                {{ $t("common.label.seeMore") }}
                <ea-icon class="m-t-8" icon='z-arrow-down' />
              </ea-button>
            </div>
            <template v-else>
              <ea-checkbox
                v-if="getOption(row, col)"
                class="m-r-64" :key="`${row}_${col}`"
                :label="getOptionLabel(row, col)"
                :value="getOptionValue(row, col)"
              ></ea-checkbox>
            </template>
          </ea-col>
        </ea-row>
      </ea-checkbox-group>
    </template>


    <template v-else>
      <ea-radio-group v-model="model.selectedOption" @change="onSelectedOptionChange">
        <ea-row v-for="row in numRows" :key="`row_${row}`" class="d-flex">
          <ea-col v-for="col in numCols" :key="`col_${col}`" :span="colSpan">
            <div v-if="isTableLastElem(row, col)" class="d-display-flex align-items-center">
              <ea-button type="text">
                {{ $t("common.label.seeMore") }}
                <ea-icon class="m-t-8" icon='z-arrow-down' />
              </ea-button>
            </div>
            <template v-else>
              <ea-radio
                v-if="getOption(row, col)" class="m-r-64"
                :label="getOptionValue(row, col)"
              >{{ getOptionLabel(row, col) }}</ea-radio>
            </template>
          </ea-col>
        </ea-row>
      </ea-radio-group>
    </template>
  </div>
</template>

<script lang="ts">
import {
  Component, Prop
} from 'vue-property-decorator';

import {
  mixins
} from 'vue-class-component';

import {
  EABusinessComponent
} from '@zurich-es-npm/ea-front-web-core';

import WarrantiesHeaderGridSelectionModel from './warranties-header-grid-selection-model';
import Utils from '@/utils/utils';
import {
  LabelValue
} from '@/business-components/qb-producto-asegurado-comunidades/qb-producto-asegurado-comunidades-model';

@Component({
  name: 'warranties-header-grid-selection'
})

/**
 * Business Component warranties-header-grid-selection
 */
export default class WarrantiesHeaderGridSelectionBusiness extends mixins<
EABusinessComponent<WarrantiesHeaderGridSelectionModel>
>(EABusinessComponent) {

  @Prop({
    'default': () => [],
  })
    optionsToSelect!: LabelValue[];

  @Prop({
    required: true,
  })
    canSelectMultipleOptions!: boolean;

  public EXPOSE_UTILS = Utils;

  /**
   * Getter for row number
   */
  get numRows() {
    return 3;
  }

  /**
   * Getter for col number
   */
  get numCols() {
    return 3;
  }

  /**
   * Getter for col span
   */
  get colSpan() {
    return 24/this.numCols;
  }

  /**
   * Handles selection change
   */
  async onSelectedOptionChange() {
    await this.$nextTick();
    this.update();
    this.$emit('selectedOptionChange');
  }

  /**
   * Gets option given row and col
   * @param {number} row
   * @param {number} col
   * @returns {LabelValue}
   */
  getOption(row: number, col: number): LabelValue | undefined {
    return this.optionsToSelect[(row - 1) + ((col - 1) * this.numRows)];
  }

  /**
   * Gets option label given row and col
   * @param {number} row
   * @param {number} col
   * @returns {string | undefined}
   */
  getOptionLabel(row: number, col: number): string | undefined {
    const option = this.getOption(row, col);
    return Utils.capitalizeFirstCharacter(option?.label);
  }

  /**
   * Gets option value given row and col
   * @param {number} row
   * @param {number} col
   * @returns {string | undefined}
   */
  getOptionValue(row: number, col: number): string | undefined {
    const option = this.getOption(row, col);
    return option?.value;
  }

  /**
   * Retrieves if received element is table's last element
   * @param {number} row
   * @param {number} col
   * @returns {boolean}
   */
  isTableLastElem(row: number, col: number): boolean {
    return row === this.numRows && col === this.numCols;
  }

}
</script>
