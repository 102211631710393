<template>
  <div>
      <div>
        <ea-form
          ref="form"
          v-if="juridicalPersonFormValues"
          :model="juridicalPersonFormValues"
          :validationOptions="addEditPersonValidation"
          :validateOnRuleChange="false"
        >
          <ea-row>
            <ea-col :span="6">
              <ea-form-item
                :label="$t('fleets.fleetsFlow.edit-policyholder.field.documentType')"
                prop="tipoDocumento"
                :required="false"
              >
                <ea-select
                  v-model="juridicalPersonFormValues.tipoDocumento"
                  :placeholder="$t('common.label.select')"
                  :readonly="true"
                >
                  <ea-option
                    v-for="documentType in documentTypeList"
                    :key="documentType.value"
                    :label="documentType.label"
                    :value="documentType.value"
                  />
                </ea-select>
              </ea-form-item>
            </ea-col>

            <ea-col :span="6">
              <ea-form-item
                :label="$t('fleets.fleetsFlow.edit-policyholder.field.documentNumber')"
                prop="codigoIdentificacionFiscal"
                :required="false"
              >
                <ea-input-text
                  v-model="juridicalPersonFormValues.codigoIdentificacionFiscal"
                  :readonly="true"
                />
              </ea-form-item>
            </ea-col>
            <ea-col :span="6">
              <ea-form-item
                :label="$t('fleets.fleetsFlow.edit-policyholder.field.businessName')"
                prop="razonSocialMatriz"
                required
              >
                <ea-input-text v-model="juridicalPersonFormValues.razonSocialMatriz" />
              </ea-form-item>
            </ea-col>
          </ea-row>

          <ea-row>
            <ea-col :span="6">
              <ea-form-item prop="tipoViaDireccionLarga" :label="$t('addAddressModal.fields.addressType')" required>
                <ea-select
                v-model="juridicalPersonFormValues.tipoViaDireccionLarga"
                 :placeholder="$t('common.label.select')">
                  <ea-option
                    v-for="addressType in addressTypeList"
                    :key="addressType.value"
                    :label="addressType.label"
                    :value="addressType.value"
                  />
                </ea-select>
              </ea-form-item>
            </ea-col>
            <ea-col :span="6">
              <ea-form-item prop="nombreDireccionTomador" :label="$t('addAddressModal.fields.addressName')" required>
                <ea-input-text
                  v-model="juridicalPersonFormValues.nombreDireccionTomador"
                 />
              </ea-form-item>
            </ea-col>
            <ea-col :span="6">
              <ea-form-item prop="numeroDireccionTomador" :label="$t('addAddressModal.fields.addressNumber')">
                <ea-input-text
                  v-model="juridicalPersonFormValues.numeroDireccionTomador" />
              </ea-form-item>
            </ea-col>
            <ea-col :span="6">
              <ea-form-item prop="informacionAdicional" :label="$t('addAddressModal.fields.additionalInfo')">
                <ea-input-text
                  v-model="juridicalPersonFormValues.informacionAdicional" />
              </ea-form-item>
            </ea-col>
          </ea-row>

          <postal-code
          ref="postalCodeComp"
          id="fleet-postal-code"
          v-model="postCodeLocationInputModel"
          :cityListRetrievalCode="postCodeLocationInputModel.zipCode"/>

        </ea-form>
        <div class="d-display-flex d-justify-flex-end">
          <ea-button
            v-if="!forceEdit"
            type="secondary"
            @click="onCancel()"
            class="m-r-16">
            {{ $t('common.label.cancel') }}
          </ea-button>
          <ea-button type="primary" @click="onSave()">
            {{ $t('common.label.save') }}
          </ea-button>
        </div>
      </div>
  </div>
</template>
<script lang="ts">
import {
  EAErrorManager
} from '@zurich-es-npm/ea-front-web-core';
import {
  EAFormValidationOptions,
  eaRequiredValidation,
  EAValidation,
  Form,
} from '@zurich-es-npm/ea-front-web-ui';
import {
  Component, Prop, Watch
} from 'vue-property-decorator';

import {
  ParsedTableData
} from '@/utils/corporate-tables';

import PostCodeLocationInputBusiness
  from '@/business-components/post-code-location-input/post-code-location-input-business.vue';

import Vue from 'vue';
import PostCodeLocationInputModel from '@/business-components/post-code-location-input/post-code-location-input-model';
import {
  SearchPolicyHolderResponseJuridicalPeople as JuridicalPerson,
  SearchPolicyHolderResponseJuridicalPeopleTipoDocumentoEnum
} from '@/services/V1/fleets/searchPolicyHolderOperation/post';

import {
  UpdatePolicyHolderRequest
} from '@/services/V1/fleets/updatePolicyHolderOperation/post/api';

@Component({
  name: 'edit-policyholder',
  components: {
    'postal-code': PostCodeLocationInputBusiness
  }
})

/**
 * Edit policyholder Component
 */
export default class EditPolicyHolderComponent extends Vue {

  @Prop()
    selectedJuridicalPerson!: JuridicalPerson;

  @Prop()
    forceEdit!: boolean;

  juridicalPersonFormValues: JuridicalPerson = {
    tipoDocumento: SearchPolicyHolderResponseJuridicalPeopleTipoDocumentoEnum.C,
    codigoIdentificacionFiscal: '',
    razonSocialMatriz: '',
    tipoViaDireccionLarga: '',
    nombreDireccionTomador: '',
    numeroDireccionTomador: '',
    informacionAdicional: ''
  };
  
  @Prop()
    documentTypeList?: ParsedTableData[];

  @Prop()
    addressTypeList?: ParsedTableData[];

  addEditPersonValidation: EAFormValidationOptions = {
    rules: {
      razonSocialMatriz: [eaRequiredValidation('common.label.validation.fieldRequired')],
      tipoViaDireccionLarga: [eaRequiredValidation('common.label.validation.fieldRequired')],
      nombreDireccionTomador: [eaRequiredValidation('common.label.validation.fieldRequired')],
    },
  };

  postCodeLocationInputModel = new PostCodeLocationInputModel();


  /**
   * Updates model
   */
  @Watch('selectedJuridicalPerson', {
    deep: true,
    immediate: true,
  })
  public selectedJuridicalPersonChanged(): void {
    this.juridicalPersonFormValues.tipoDocumento = this.selectedJuridicalPerson.tipoDocumento;
    this.juridicalPersonFormValues.codigoIdentificacionFiscal = this.selectedJuridicalPerson.codigoIdentificacionFiscal;
    this.juridicalPersonFormValues.razonSocialMatriz = this.selectedJuridicalPerson.razonSocialMatriz;
    this.juridicalPersonFormValues.tipoViaDireccionLarga = this.selectedJuridicalPerson.tipoViaDireccionLarga;
    this.juridicalPersonFormValues.nombreDireccionTomador = this.selectedJuridicalPerson.nombreDireccionTomador;
    this.juridicalPersonFormValues.numeroDireccionTomador = this.selectedJuridicalPerson.numeroDireccionTomador;
    this.juridicalPersonFormValues.informacionAdicional = this.selectedJuridicalPerson.informacionAdicional;
    this.postCodeLocationInputModel.zipCode = this.selectedJuridicalPerson.codigoPostal as string;
    this.postCodeLocationInputModel.city = this.selectedJuridicalPerson.nombrePoblacionTomador as string;
    this.postCodeLocationInputModel.province =
      this.selectedJuridicalPerson.nombreProvinciaTomador as string;
  }

  /**
   * Handle cancel button and dispatch the event.
   */
  onCancel() {
    this.$emit('cancel');
  }

  /**
   * Validates search form before dispatching the save event
   */
  async onSave() {
    EAErrorManager.clearError();

    try {
      const postalCodeComp = this.$refs.postalCodeComp as PostCodeLocationInputBusiness;
      const validations = [postalCodeComp.validation().validate(), this.validation().validate()];
      await Promise.all(validations);
      this.juridicalPersonFormValues.codigoPostal = this.postCodeLocationInputModel.zipCode;
      this.juridicalPersonFormValues.nombrePoblacionTomador = this.postCodeLocationInputModel.city;
      this.juridicalPersonFormValues.nombreProvinciaTomador = this.postCodeLocationInputModel.province;
      const updatedValues: UpdatePolicyHolderRequest =
         this.juridicalPersonFormValues as unknown as UpdatePolicyHolderRequest;
      updatedValues.codigoPoblacion = this.postCodeLocationInputModel.cityCode;
      this.$emit('save', updatedValues);
    } catch (err) {
      // Design System will show form errors
    }
  }

  /**
   * Returns validation object
   * @returns {EAValidation}
   */
  public validation(): EAValidation {
    const form = this.$refs.form as Form;
    return form.validation();
  }

}
</script>
