/* eslint-disable max-len */

import QbAddressesPersonModel from '@/business-components/addresses/qb-addresses-person-model';
import QbDocumentationSelectDocumentationModel from '@/business-components/qb-documentation-select-documentation/qb-documentation-select-documentation-model';
import QbDocumentationSelectProposalModel from '@/business-components/qb-documentation-select-proposal/qb-documentation-select-proposal-model';
import QbFractionalPaymentModel from '@/business-components/qb-fractional-payment-modal/qb-fractional-payment-modal-model';
import QbGeneralDataInformationModel from '@/business-components/qb-general-data-information/qb-general-data-information-model';
import QbGenericErrorModel from '@/business-components/qb-generic-error/qb-generic-error-model';
import QbHeaderModel from '@/business-components/qb-header/qb-header-model';
import QBNewOfferBusinessModel from '@/business-components/qb-new-offer/qb-new-offer-model';
import QbOfferInssuanceAdditionalDataModel from '@/business-components/qb-offer-issuance-additional-data/qb-offer-issuance-additional-data-model';
import QbOfferIssuanceBillingDataFormModel from '@/business-components/qb-offer-issuance-billing-data-form/qb-offer-issuance-billing-data-form-model';
import QbOfferIssuanceBonusDataFormModel from '@/business-components/qb-offer-issuance-bonus-data-form/qb-offer-issuance-bonus-data-form-model';
import QbOfferIssuanceDataModel from '@/business-components/qb-offer-issuance-data/qb-offer-issuance-data-model';
import QbPremiumAdjustmentsModel from '@/business-components/qb-premium-adjustments/qb-premium-adjustments-model';
import QbProductoAseguradoComunidadesModel from '@/business-components/qb-producto-asegurado-comunidades/qb-producto-asegurado-comunidades-model';
import QbProductoAseguradoHogarModel from '@/business-components/qb-producto-asegurado-hogar/qb-producto-asegurado-hogar-model';
import QbProductoAseguradoTrConstruccionMontajeModel from '@/business-components/qb-producto-asegurado-tr-construccion-montaje/qb-producto-asegurado-tr-construccion-montaje-model';
import QbProficiencyManagementModel from '@/business-components/qb-proficiency-management/qb-proficiency-management-model';
import QbRiskSituationModel from '@/business-components/qb-risk-situation/qb-risk-situation-model';
import QbTechnicalConsiderationsModel from '@/business-components/qb-technical-considerations/qb-technical-considerations-model';
import {
  GetPersonAddressesResponseDomicilios
} from '@/services/V1/persons/getPersonAddressesOperation/post';
import {
  GetGeneralDataRequestTipoOperacionOfertaEnum as OperationType,
  GetGeneralDataResponseDataDatosCabecera,
  GetGeneralDataResponseDataDatosCabeceraTablaRestricciones,
  GetGeneralDataResponseDataDatosObjeto
} from '@/services/V1/quoteAndBuy/getGeneralDataOperation/post';
import {
  GetIssueOfferDataResponseListaDocumentos
} from '@/services/V1/quoteAndBuy/getIssueOfferDataOperation/post';
import {
  GetPersonsResponseDatosPersona
} from '@/services/V1/quoteAndBuy/getPersonsOperation/post';
import {
  CodigoRamo
} from '@/types/codigo-ramo/codigo-ramo-enum.types';
import {
  PolicyType
} from '@/types/policy-types/policy-types-enum.types';
import {
  Roles
} from '@/types/roles/roles-enum.types';
import {
  ParsedTableData
} from '@/utils/corporate-tables';
import {
  EAFlowModel
} from '@zurich-es-npm/ea-front-web-core';

export interface PagadorIsSelected {
  pagadorIgualQueTomador: boolean;
  pagadorIgualQueAsegurado: boolean;
}

export enum FlowViewsStepsModel {
  NewOfferStep = 0,
  GeneralDataStep = 1,
  WarrantiesStep = 2,
  OfferIssuanceDataStep = 3,
  GenerateDocumentationStep = 4,
  IssueOfferStep = 5,
  SummaryAndDownloadPolicyStep = 6
}

export enum FlowHeaderStepsModel {
  GeneralDataStep = 0,
  WarrantiesStep = 1,
  OfferIssuanceDataStep = 2,
  IssueOfferStep = 3,
  SummaryAndDownloadPolicyStep = 4
}

export enum PolicyMovementType {
  Oferta = '9',
  Incompleta = '1',
  Suplemento = '2',
  SuplementoVencimiento = 'S',
}

// Codes with the correct message
export const codeMessageFicoFic: Record<string, string> = {
  8061: 'ficoError',
  8392: 'ficError'
};

export enum ErrorCodeFicoFic {
  Fico = '8061',
  Fic = '8392'
}

// Codes that force going back in flow
export const ErrorCodesGoBack = ['6447', '6449'];


export type ProductModel = QbProductoAseguradoComunidadesModel | QbProductoAseguradoTrConstruccionMontajeModel | QbProductoAseguradoHogarModel;

export interface BffCodesFrontRelationModel {
  bffCode: string;
  frontCode: string;
}

export interface InputModel {
  codigoPoliza: string;
  versionPoliza: number;
  maintenanceMode: boolean;
  hasParentFlow: boolean;
  codigoRamo: CodigoRamo;
}

export interface AdressPersonBlock {
  isVisible: boolean;
  role?: Roles;
  addressPersonModel?: QbAddressesPersonModel;
  ref?: string;
}

/**
 * Quote-buy flow model
 *
 * @export
 * @class QuoteBuyModel
 * @extends {EAFlowModel}
 */
export abstract class QuoteBuyModel extends EAFlowModel {

  public offerNumber: string = '';

  public offerVersion: number = -1;

  public offerType: PolicyType = PolicyType.Oferta;

  public movementTypeCode: string = '';

  public operationType?: OperationType = undefined;

  public newOfferModel: QBNewOfferBusinessModel = new QBNewOfferBusinessModel();

  public qbHeader: QbHeaderModel = new QbHeaderModel();

  public datosCabecera: GetGeneralDataResponseDataDatosCabecera = {};

  public datosAdicionales: GetGeneralDataResponseDataDatosObjeto[] = [];

  public generalDataInformationModel: QbGeneralDataInformationModel = new QbGeneralDataInformationModel();

  abstract productModel: ProductModel;

  public taxTreatmentArea: string = '';

  public taxTreatmentAreaTable: GetGeneralDataResponseDataDatosCabeceraTablaRestricciones[] = [];

  public genericErrorModel: QbGenericErrorModel = new QbGenericErrorModel();

  public isProductDataShown = false;

  public proficiencyManagementModel: QbProficiencyManagementModel = new QbProficiencyManagementModel();

  public premiumAdjustmentsModel: QbPremiumAdjustmentsModel = new QbPremiumAdjustmentsModel();

  public documentTypeList: ParsedTableData[] = [];

  public countryList: ParsedTableData[] = [];

  public addressTypeList?: ParsedTableData[] = [];

  public fractionalPaymentModel = new QbFractionalPaymentModel();

  // eslint-disable-next-line max-len
  public documentationSelectProposalModel: QbDocumentationSelectProposalModel = new QbDocumentationSelectProposalModel();

  // eslint-disable-next-line max-len
  public documentationSelectDocumentationModel: QbDocumentationSelectDocumentationModel = new QbDocumentationSelectDocumentationModel();

  public addressPersonModel: QbAddressesPersonModel = new QbAddressesPersonModel();

  public selectedProposal: string = '';

  public warrantiesProposals: string[] = [];

  public warrantyRates: Record<string, string | number> = {}; // Relationship proposal/price (for all proposals)

  public pendingCompetenceManagement: boolean = false;

  public pendingIPIDDocumentation: boolean = false;

  public continentPrices: Record<string, string | number> = {};

  public contentPrices: Record<string, string | number> = {};

  public offerIssuanceDataModel: QbOfferIssuanceDataModel = new QbOfferIssuanceDataModel();

  public offerIssuanceBonusDataFormModel: QbOfferIssuanceBonusDataFormModel = new QbOfferIssuanceBonusDataFormModel();

  // eslint-disable-next-line max-len
  public offerIssuanceBillingDataFormModel: QbOfferIssuanceBillingDataFormModel = new QbOfferIssuanceBillingDataFormModel();

  // eslint-disable-next-line max-len
  public offerIssuanceAdditionalDataFormModel: QbOfferInssuanceAdditionalDataModel = new QbOfferInssuanceAdditionalDataModel();

  public filiationCode: string = '';

  public selectedPerson?: GetPersonsResponseDatosPersona | null = {};

  public policyTakerAddressList: GetPersonAddressesResponseDomicilios[] = [];

  public policyTakerSelectedAddress?: GetPersonAddressesResponseDomicilios;

  public policyTakerEmailList: GetPersonAddressesResponseDomicilios[] = [];

  public policyTakerSelectedEmail?: GetPersonAddressesResponseDomicilios;

  public policyTakerPhoneList: GetPersonAddressesResponseDomicilios[] = [];

  public policyTakerSelectedPhone?: string;

  public policyDocumentEmit?: GetIssueOfferDataResponseListaDocumentos[];

  public ficoFicError: string[] = [];

  public hasParentFlow: boolean = false;

  public technicalConsiderations?: QbTechnicalConsiderationsModel = new QbTechnicalConsiderationsModel();

  public riskSituation?: QbRiskSituationModel = new QbRiskSituationModel();

  public datosObjeto: GetGeneralDataResponseDataDatosObjeto[] = [];

  public cessionRights: GetGeneralDataResponseDataDatosObjeto | undefined = undefined; // Cesión titular

  public loanNumber: GetGeneralDataResponseDataDatosObjeto | undefined = undefined; // Nº de prestamo
}
