// TODO NODEJS20
import {
  InternalAxiosRequestConfig
} from 'axios';

/**
 * RequestNoCacheHeadersInterceptor
 * @param {InternalAxiosRequestConfig} config
 * @returns {InternalAxiosRequestConfig}
 */
export function requestNoCacheHeadersInterceptor(config: InternalAxiosRequestConfig): InternalAxiosRequestConfig {
  const newConfig: InternalAxiosRequestConfig = {
    ...config
  };
  
  // Get language from language manager
  if (newConfig.headers) {
    newConfig.headers.Pragma = 'no-cache';
    newConfig.headers['Cache-Control'] = 'no-cache';
    newConfig.headers.Expires = 'Sat, 01 Jan 2000 00:00:00 GMT';
    newConfig.headers['If-Modified-Since'] = '0';
  }

  return newConfig;
}
