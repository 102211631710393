/* eslint-disable */

/**
 * zonazurich
 * Portal de Mediación Digital
 *
 * The version of the OpenAPI document: 1
 * Contact: digitalarchitecture@zurich.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import {
  EASDKConfig,
  EAUrlUtils
} from '@zurich-es-npm/ea-shared-utils-js';

import * as globalImportUrl from 'url';
import {
  Configuration
} from './configuration';
import globalAxios, {
  AxiosPromise,
  AxiosInstance,
  AxiosResponse
} from 'axios';

const BASE_PATH = EAUrlUtils.removeLastForwardSlash('http://localhost:3000');

/**
 *
 * @export
 */
export const COLLECTION_FORMATS = {
  csv: ',',
  ssv: ' ',
  tsv: '\t',
  pipes: '|',
};

/**
 *  
 * @export
 * @interface RequestArgs
 */
export interface RequestArgs {
  url: string;
  options: any;
}

/**
 * 
 * @export
 * @class BaseAPI
 */
export class BaseAPI {
  protected configuration: Configuration | undefined;

  /**
   *Creates an instance of BaseAPI.
   * @param {Configuration} [configuration]
   * @param {string} [basePath=BASE_PATH]
   * @param {AxiosInstance} [axios=globalAxios]
   * @memberof BaseAPI
   */
  public constructor(
    configuration?: Configuration,
    protected basePath: string = BASE_PATH,
    protected axios: AxiosInstance = globalAxios) {
    if (configuration) {
      this.configuration = configuration;
      this.basePath = configuration.basePath || this.basePath;
    }
  }
}

/**
 * 
 * @export
 * @class RequiredError
 * @extends {Error}
 */
export class RequiredError extends Error {
  public name: 'RequiredError' = 'RequiredError';

  public field: string;

  /**
   *Creates an instance of RequiredError.
   * @param {string} field
   * @param {string} [msg]
   * @memberof RequiredError
   */
  public constructor(field: string, msg?: string) {
    super(msg);
    this.field=field;
  }
}

/**
 * 
 * @export
 * @class AsyncRequestError
 * @extends {Error}
 */
export class AsyncRequestError extends Error {
  public name: 'AsyncRequestError' = 'AsyncRequestError';

  /**
   *Creates an instance of AsyncRequestError.
   * @param {string} field
   * @param {string} [msg]
   * @memberof AsyncRequestError
   */
  public constructor(msg?: string) {
    super(msg);
  }
}

/**
 * 
 * @export
 * @interface Error
 */
export interface Error {
    /**
     * 
     * @type {string}
     * @memberof Error
     */
    code: string;
    /**
     * 
     * @type {string}
     * @memberof Error
     */
    message: string;
    /**
     * 
     * @type {number}
     * @memberof Error
     */
    severity: number;
    /**
     * 
     * @type {Array<object>}
     * @memberof Error
     */
    childErrors?: Array<object>;
}/**
 * 
 * @export
 * @interface GetWarrantiesPolicyRequest
 */
export interface GetWarrantiesPolicyRequest {
    /**
     * 
     * @type {string}
     * @memberof GetWarrantiesPolicyRequest
     */
    codigoPoliza: string;
    /**
     * 
     * @type {number}
     * @memberof GetWarrantiesPolicyRequest
     */
    versionPoliza: number;
    /**
     * 
     * @type {string}
     * @memberof GetWarrantiesPolicyRequest
     */
    tipoPoliza: GetWarrantiesPolicyRequestTipoPolizaEnum;
    /**
     * 
     * @type {boolean}
     * @memberof GetWarrantiesPolicyRequest
     */
    borrarGarantias?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetWarrantiesPolicyRequest
     */
    codigosPropuestas?: Array<string>;
}

/**
    * @export
    * @enum {string}
    */
export enum GetWarrantiesPolicyRequestTipoPolizaEnum {
    Oferta = 'Oferta',
    Pliza = 'Póliza',
    Incompleta = 'Incompleta'
}
/**
 * 
 * @export
 * @interface GetWarrantiesPolicyResponse
 */
export interface GetWarrantiesPolicyResponse {
    /**
     * 
     * @type {GetWarrantiesPolicyResponseData}
     * @memberof GetWarrantiesPolicyResponse
     */
    data?: GetWarrantiesPolicyResponseData;
    /**
     * 
     * @type {Array<Error>}
     * @memberof GetWarrantiesPolicyResponse
     */
    errors?: Array<Error>;
}/**
 * 
 * @export
 * @interface GetWarrantiesPolicyResponseData
 */
export interface GetWarrantiesPolicyResponseData {
    /**
     * 
     * @type {GetWarrantiesPolicyResponseDataVersionAnteriorData}
     * @memberof GetWarrantiesPolicyResponseData
     */
    versionAnteriorData?: GetWarrantiesPolicyResponseDataVersionAnteriorData;
    /**
     * 
     * @type {Array<GetWarrantiesPolicyResponseDataDatosTarificacion>}
     * @memberof GetWarrantiesPolicyResponseData
     */
    datosTarificacion?: Array<GetWarrantiesPolicyResponseDataDatosTarificacion>;
    /**
     * 
     * @type {Array<GetWarrantiesPolicyResponseDataDatosGarantias>}
     * @memberof GetWarrantiesPolicyResponseData
     */
    datosGarantias?: Array<GetWarrantiesPolicyResponseDataDatosGarantias>;
    /**
     * 
     * @type {GetWarrantiesPolicyResponseDataDatosGestionCompetencias}
     * @memberof GetWarrantiesPolicyResponseData
     */
    datosGestionCompetencias?: GetWarrantiesPolicyResponseDataDatosGestionCompetencias;
    /**
     * 
     * @type {Array<GetWarrantiesPolicyResponseDataDatosAjustesPrimas>}
     * @memberof GetWarrantiesPolicyResponseData
     */
    datosAjustesPrimas?: Array<GetWarrantiesPolicyResponseDataDatosAjustesPrimas>;
    /**
     * 
     * @type {Array<GetWarrantiesPolicyResponseDataTablaRestricciones>}
     * @memberof GetWarrantiesPolicyResponseData
     */
    fraccionamientoFormasPago?: Array<GetWarrantiesPolicyResponseDataTablaRestricciones>;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetWarrantiesPolicyResponseData
     */
    codigosPropuestas?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof GetWarrantiesPolicyResponseData
     */
    isReemplazo?: boolean;
}/**
 * 
 * @export
 * @interface GetWarrantiesPolicyResponseDataCadenaLanzamiento
 */
export interface GetWarrantiesPolicyResponseDataCadenaLanzamiento {
    /**
     * 
     * @type {string}
     * @memberof GetWarrantiesPolicyResponseDataCadenaLanzamiento
     */
    codigoGarantia?: string;
    /**
     * 
     * @type {string}
     * @memberof GetWarrantiesPolicyResponseDataCadenaLanzamiento
     */
    codigoElemento?: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetWarrantiesPolicyResponseDataCadenaLanzamiento
     */
    elementoModificable?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetWarrantiesPolicyResponseDataCadenaLanzamiento
     */
    elementoRequerido?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetWarrantiesPolicyResponseDataCadenaLanzamiento
     */
    elementoVisible?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetWarrantiesPolicyResponseDataCadenaLanzamiento
     */
    valorElemento?: string;
    /**
     * 
     * @type {GetWarrantiesPolicyResponseDataCopiaValor}
     * @memberof GetWarrantiesPolicyResponseDataCadenaLanzamiento
     */
    copiaValor?: GetWarrantiesPolicyResponseDataCopiaValor;
}/**
 * 
 * @export
 * @interface GetWarrantiesPolicyResponseDataCondicionLanzamientoValorAnteriorElemento
 */
export interface GetWarrantiesPolicyResponseDataCondicionLanzamientoValorAnteriorElemento {
    /**
     * 
     * @type {string}
     * @memberof GetWarrantiesPolicyResponseDataCondicionLanzamientoValorAnteriorElemento
     */
    condicionLanzamientoValorElemento?: string;
    /**
     * 
     * @type {string}
     * @memberof GetWarrantiesPolicyResponseDataCondicionLanzamientoValorAnteriorElemento
     */
    condicionLanzamientoRestriccionGarantia?: GetWarrantiesPolicyResponseDataCondicionLanzamientoValorAnteriorElementoCondicionLanzamientoRestriccionGarantiaEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum GetWarrantiesPolicyResponseDataCondicionLanzamientoValorAnteriorElementoCondicionLanzamientoRestriccionGarantiaEnum {
    Equal = 'Equal',
    NotEqual = 'NotEqual',
    Any = 'Any'
}
/**
 * 
 * @export
 * @interface GetWarrantiesPolicyResponseDataCopiaValor
 */
export interface GetWarrantiesPolicyResponseDataCopiaValor {
    /**
     * 
     * @type {string}
     * @memberof GetWarrantiesPolicyResponseDataCopiaValor
     */
    codigoGarantia: string;
    /**
     * 
     * @type {string}
     * @memberof GetWarrantiesPolicyResponseDataCopiaValor
     */
    codigoPropuesta: string;
    /**
     * 
     * @type {string}
     * @memberof GetWarrantiesPolicyResponseDataCopiaValor
     */
    codigoElemento: string;
    /**
     * 
     * @type {string}
     * @memberof GetWarrantiesPolicyResponseDataCopiaValor
     */
    condicionLanzamientoRestriccionGarantia?: GetWarrantiesPolicyResponseDataCopiaValorCondicionLanzamientoRestriccionGarantiaEnum;
    /**
     * 
     * @type {string}
     * @memberof GetWarrantiesPolicyResponseDataCopiaValor
     */
    condicionLanzamientoValorElemento?: string;
    /**
     * 
     * @type {GetWarrantiesPolicyResponseDataCopiaValorCondicionLanzamientoElemento}
     * @memberof GetWarrantiesPolicyResponseDataCopiaValor
     */
    condicionLanzamientoElemento?: GetWarrantiesPolicyResponseDataCopiaValorCondicionLanzamientoElemento;
}

/**
    * @export
    * @enum {string}
    */
export enum GetWarrantiesPolicyResponseDataCopiaValorCondicionLanzamientoRestriccionGarantiaEnum {
    Equal = 'Equal',
    NotEqual = 'NotEqual',
    Any = 'Any'
}
/**
 * 
 * @export
 * @interface GetWarrantiesPolicyResponseDataCopiaValorCondicionLanzamientoElemento
 */
export interface GetWarrantiesPolicyResponseDataCopiaValorCondicionLanzamientoElemento {
    /**
     * 
     * @type {string}
     * @memberof GetWarrantiesPolicyResponseDataCopiaValorCondicionLanzamientoElemento
     */
    codigoGarantia: string;
    /**
     * 
     * @type {string}
     * @memberof GetWarrantiesPolicyResponseDataCopiaValorCondicionLanzamientoElemento
     */
    codigoPropuesta: string;
    /**
     * 
     * @type {string}
     * @memberof GetWarrantiesPolicyResponseDataCopiaValorCondicionLanzamientoElemento
     */
    codigoElemento: string;
}/**
 * 
 * @export
 * @interface GetWarrantiesPolicyResponseDataDatosAjustesPrimas
 */
export interface GetWarrantiesPolicyResponseDataDatosAjustesPrimas {
    /**
     * 
     * @type {string}
     * @memberof GetWarrantiesPolicyResponseDataDatosAjustesPrimas
     */
    codigoElemento?: string;
    /**
     * 
     * @type {string}
     * @memberof GetWarrantiesPolicyResponseDataDatosAjustesPrimas
     */
    tablaElemento?: string;
    /**
     * 
     * @type {string}
     * @memberof GetWarrantiesPolicyResponseDataDatosAjustesPrimas
     */
    valorElemento?: string;
    /**
     * 
     * @type {number}
     * @memberof GetWarrantiesPolicyResponseDataDatosAjustesPrimas
     */
    valorMaximoElemento?: number;
    /**
     * 
     * @type {number}
     * @memberof GetWarrantiesPolicyResponseDataDatosAjustesPrimas
     */
    valorMinimoElemento?: number;
    /**
     * 
     * @type {boolean}
     * @memberof GetWarrantiesPolicyResponseDataDatosAjustesPrimas
     */
    elementoRequerido?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetWarrantiesPolicyResponseDataDatosAjustesPrimas
     */
    elementoModificable?: boolean;
    /**
     * 
     * @type {number}
     * @memberof GetWarrantiesPolicyResponseDataDatosAjustesPrimas
     */
    longitudElemento?: number;
    /**
     * 
     * @type {number}
     * @memberof GetWarrantiesPolicyResponseDataDatosAjustesPrimas
     */
    numeroDecimalesElemento?: number;
    /**
     * 
     * @type {Array<GetWarrantiesPolicyResponseDataTablaRestricciones>}
     * @memberof GetWarrantiesPolicyResponseDataDatosAjustesPrimas
     */
    tablaRestricciones?: Array<GetWarrantiesPolicyResponseDataTablaRestricciones>;
}/**
 * 
 * @export
 * @interface GetWarrantiesPolicyResponseDataDatosGarantias
 */
export interface GetWarrantiesPolicyResponseDataDatosGarantias {
    /**
     * 
     * @type {string}
     * @memberof GetWarrantiesPolicyResponseDataDatosGarantias
     */
    codigoGarantia?: string;
    /**
     * 
     * @type {Array<GetWarrantiesPolicyResponseDataGarantiaSeleccionada>}
     * @memberof GetWarrantiesPolicyResponseDataDatosGarantias
     */
    garantiaSeleccionada?: Array<GetWarrantiesPolicyResponseDataGarantiaSeleccionada>;
    /**
     * 
     * @type {Array<GetWarrantiesPolicyResponseDataElementosGarantias>}
     * @memberof GetWarrantiesPolicyResponseDataDatosGarantias
     */
    elementosGarantias?: Array<GetWarrantiesPolicyResponseDataElementosGarantias>;
    /**
     * 
     * @type {Array<GetWarrantiesPolicyResponseDataLanzadorPorCheck>}
     * @memberof GetWarrantiesPolicyResponseDataDatosGarantias
     */
    lanzadorPorCheck?: Array<GetWarrantiesPolicyResponseDataLanzadorPorCheck>;
}/**
 * 
 * @export
 * @interface GetWarrantiesPolicyResponseDataDatosGestionCompetencias
 */
export interface GetWarrantiesPolicyResponseDataDatosGestionCompetencias {
    /**
     * 
     * @type {boolean}
     * @memberof GetWarrantiesPolicyResponseDataDatosGestionCompetencias
     */
    gestionDeCompetencias?: boolean;
    /**
     * 
     * @type {Array<GetWarrantiesPolicyResponseDataDatosGestionCompetenciasCompetencesManagementByOfferData>}
     * @memberof GetWarrantiesPolicyResponseDataDatosGestionCompetencias
     */
    competencesManagementByOfferData?: Array<GetWarrantiesPolicyResponseDataDatosGestionCompetenciasCompetencesManagementByOfferData>;
}/**
 * 
 * @export
 * @interface GetWarrantiesPolicyResponseDataDatosGestionCompetenciasCompetencesManagementByOfferData
 */
export interface GetWarrantiesPolicyResponseDataDatosGestionCompetenciasCompetencesManagementByOfferData {
    /**
     * 
     * @type {string}
     * @memberof GetWarrantiesPolicyResponseDataDatosGestionCompetenciasCompetencesManagementByOfferData
     */
    codigoObjeto?: string;
    /**
     * 
     * @type {string}
     * @memberof GetWarrantiesPolicyResponseDataDatosGestionCompetenciasCompetencesManagementByOfferData
     */
    codigoOpcion?: string;
    /**
     * 
     * @type {string}
     * @memberof GetWarrantiesPolicyResponseDataDatosGestionCompetenciasCompetencesManagementByOfferData
     */
    codigoPropuesta?: string;
    /**
     * 
     * @type {string}
     * @memberof GetWarrantiesPolicyResponseDataDatosGestionCompetenciasCompetencesManagementByOfferData
     */
    codigoMCT?: string;
    /**
     * 
     * @type {string}
     * @memberof GetWarrantiesPolicyResponseDataDatosGestionCompetenciasCompetencesManagementByOfferData
     */
    descripcionElemento?: string;
    /**
     * 
     * @type {number}
     * @memberof GetWarrantiesPolicyResponseDataDatosGestionCompetenciasCompetencesManagementByOfferData
     */
    codigoErrorAutorizacion?: number;
    /**
     * 
     * @type {string}
     * @memberof GetWarrantiesPolicyResponseDataDatosGestionCompetenciasCompetencesManagementByOfferData
     */
    estadoControlCompetencias?: GetWarrantiesPolicyResponseDataDatosGestionCompetenciasCompetencesManagementByOfferDataEstadoControlCompetenciasEnum;
    /**
     * 
     * @type {string}
     * @memberof GetWarrantiesPolicyResponseDataDatosGestionCompetenciasCompetencesManagementByOfferData
     */
    descripcionErrorAutorizacion?: string;
    /**
     * 
     * @type {string}
     * @memberof GetWarrantiesPolicyResponseDataDatosGestionCompetenciasCompetencesManagementByOfferData
     */
    descripcionEstadoControlCompetencias?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum GetWarrantiesPolicyResponseDataDatosGestionCompetenciasCompetencesManagementByOfferDataEstadoControlCompetenciasEnum {
    A = 'A',
    P = 'P',
    R = 'R'
}
/**
 * 
 * @export
 * @interface GetWarrantiesPolicyResponseDataDatosPropuestas
 */
export interface GetWarrantiesPolicyResponseDataDatosPropuestas {
    /**
     * 
     * @type {string}
     * @memberof GetWarrantiesPolicyResponseDataDatosPropuestas
     */
    codigoPropuesta?: string;
    /**
     * 
     * @type {string}
     * @memberof GetWarrantiesPolicyResponseDataDatosPropuestas
     */
    tablaElemento?: string;
    /**
     * 
     * @type {string}
     * @memberof GetWarrantiesPolicyResponseDataDatosPropuestas
     */
    valorElemento?: string;
    /**
     * 
     * @type {number}
     * @memberof GetWarrantiesPolicyResponseDataDatosPropuestas
     */
    valorMinimoElemento?: number;
    /**
     * 
     * @type {number}
     * @memberof GetWarrantiesPolicyResponseDataDatosPropuestas
     */
    valorMaximoElemento?: number;
    /**
     * 
     * @type {boolean}
     * @memberof GetWarrantiesPolicyResponseDataDatosPropuestas
     */
    elementoModificable?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetWarrantiesPolicyResponseDataDatosPropuestas
     */
    elementoRequerido?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetWarrantiesPolicyResponseDataDatosPropuestas
     */
    elementoVisible?: boolean;
    /**
     * 
     * @type {number}
     * @memberof GetWarrantiesPolicyResponseDataDatosPropuestas
     */
    longitudElemento?: number;
    /**
     * 
     * @type {number}
     * @memberof GetWarrantiesPolicyResponseDataDatosPropuestas
     */
    numeroDecimalesElemento?: number;
    /**
     * 
     * @type {string}
     * @memberof GetWarrantiesPolicyResponseDataDatosPropuestas
     */
    tipoVariable?: GetWarrantiesPolicyResponseDataDatosPropuestasTipoVariableEnum;
    /**
     * 
     * @type {string}
     * @memberof GetWarrantiesPolicyResponseDataDatosPropuestas
     */
    tipoMascara?: GetWarrantiesPolicyResponseDataDatosPropuestasTipoMascaraEnum;
    /**
     * 
     * @type {string}
     * @memberof GetWarrantiesPolicyResponseDataDatosPropuestas
     */
    tipoPropuesta?: GetWarrantiesPolicyResponseDataDatosPropuestasTipoPropuestaEnum;
    /**
     * 
     * @type {Array<GetWarrantiesPolicyResponseDataTablaRestricciones>}
     * @memberof GetWarrantiesPolicyResponseDataDatosPropuestas
     */
    tablaRestricciones?: Array<GetWarrantiesPolicyResponseDataTablaRestricciones>;
    /**
     * 
     * @type {Array<GetWarrantiesPolicyResponseDataLanzadorPorValor>}
     * @memberof GetWarrantiesPolicyResponseDataDatosPropuestas
     */
    lanzadorPorValor?: Array<GetWarrantiesPolicyResponseDataLanzadorPorValor>;
}

/**
    * @export
    * @enum {string}
    */
export enum GetWarrantiesPolicyResponseDataDatosPropuestasTipoVariableEnum {
    A = 'A',
    N = 'N'
}
/**
    * @export
    * @enum {string}
    */
export enum GetWarrantiesPolicyResponseDataDatosPropuestasTipoMascaraEnum {
    A = 'A',
    C = 'C',
    D = 'D',
    F = 'F',
    I = 'I',
    M = 'M',
    P = 'P',
    R = 'R',
    T = 'T'
}
/**
    * @export
    * @enum {string}
    */
export enum GetWarrantiesPolicyResponseDataDatosPropuestasTipoPropuestaEnum {
    M = 'M',
    S = 'S',
    O = 'O',
    N = 'N'
}
/**
 * 
 * @export
 * @interface GetWarrantiesPolicyResponseDataDatosTarificacion
 */
export interface GetWarrantiesPolicyResponseDataDatosTarificacion {
    /**
     * 
     * @type {string}
     * @memberof GetWarrantiesPolicyResponseDataDatosTarificacion
     */
    codigoPropuesta?: string;
    /**
     * 
     * @type {number}
     * @memberof GetWarrantiesPolicyResponseDataDatosTarificacion
     */
    importeAcumuladoRecibo?: number;
    /**
     * 
     * @type {number}
     * @memberof GetWarrantiesPolicyResponseDataDatosTarificacion
     */
    importePrimerRecibo?: number;
    /**
     * 
     * @type {number}
     * @memberof GetWarrantiesPolicyResponseDataDatosTarificacion
     */
    importeRecibosSucesivos?: number;
    /**
     * 
     * @type {number}
     * @memberof GetWarrantiesPolicyResponseDataDatosTarificacion
     */
    importeTotalRecibo?: number;
    /**
     * 
     * @type {string}
     * @memberof GetWarrantiesPolicyResponseDataDatosTarificacion
     */
    formaPagoTarificacion?: GetWarrantiesPolicyResponseDataDatosTarificacionFormaPagoTarificacionEnum;
    /**
     * 
     * @type {boolean}
     * @memberof GetWarrantiesPolicyResponseDataDatosTarificacion
     */
    noPrimaMinima?: boolean;
}

/**
    * @export
    * @enum {string}
    */
export enum GetWarrantiesPolicyResponseDataDatosTarificacionFormaPagoTarificacionEnum {
    Anual = 'Anual',
    Unica = 'Unica',
    Semestral = 'Semestral',
    Trimestral = 'Trimestral',
    Mensual = 'Mensual'
}
/**
 * 
 * @export
 * @interface GetWarrantiesPolicyResponseDataElementosGarantias
 */
export interface GetWarrantiesPolicyResponseDataElementosGarantias {
    /**
     * 
     * @type {string}
     * @memberof GetWarrantiesPolicyResponseDataElementosGarantias
     */
    codigoElemento?: string;
    /**
     * 
     * @type {Array<GetWarrantiesPolicyResponseDataDatosPropuestas>}
     * @memberof GetWarrantiesPolicyResponseDataElementosGarantias
     */
    datosPropuestas?: Array<GetWarrantiesPolicyResponseDataDatosPropuestas>;
}/**
 * 
 * @export
 * @interface GetWarrantiesPolicyResponseDataGarantiaSeleccionada
 */
export interface GetWarrantiesPolicyResponseDataGarantiaSeleccionada {
    /**
     * 
     * @type {string}
     * @memberof GetWarrantiesPolicyResponseDataGarantiaSeleccionada
     */
    codigoPropuesta?: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetWarrantiesPolicyResponseDataGarantiaSeleccionada
     */
    garantiaSeleccionada?: boolean;
}/**
 * 
 * @export
 * @interface GetWarrantiesPolicyResponseDataLanzadorPorCheck
 */
export interface GetWarrantiesPolicyResponseDataLanzadorPorCheck {
    /**
     * 
     * @type {string}
     * @memberof GetWarrantiesPolicyResponseDataLanzadorPorCheck
     */
    condicionLanzamientoValorElemento?: string;
    /**
     * 
     * @type {string}
     * @memberof GetWarrantiesPolicyResponseDataLanzadorPorCheck
     */
    condicionLanzamientoRestriccionGarantia?: GetWarrantiesPolicyResponseDataLanzadorPorCheckCondicionLanzamientoRestriccionGarantiaEnum;
    /**
     * 
     * @type {Array<GetWarrantiesPolicyResponseDataCadenaLanzamiento>}
     * @memberof GetWarrantiesPolicyResponseDataLanzadorPorCheck
     */
    cadenaLanzamiento?: Array<GetWarrantiesPolicyResponseDataCadenaLanzamiento>;
}

/**
    * @export
    * @enum {string}
    */
export enum GetWarrantiesPolicyResponseDataLanzadorPorCheckCondicionLanzamientoRestriccionGarantiaEnum {
    Equal = 'Equal',
    NotEqual = 'NotEqual',
    Any = 'Any'
}
/**
 * 
 * @export
 * @interface GetWarrantiesPolicyResponseDataLanzadorPorValor
 */
export interface GetWarrantiesPolicyResponseDataLanzadorPorValor {
    /**
     * 
     * @type {string}
     * @memberof GetWarrantiesPolicyResponseDataLanzadorPorValor
     */
    condicionLanzamientoValorElemento?: string;
    /**
     * 
     * @type {string}
     * @memberof GetWarrantiesPolicyResponseDataLanzadorPorValor
     */
    condicionLanzamientoRestriccionGarantia?: GetWarrantiesPolicyResponseDataLanzadorPorValorCondicionLanzamientoRestriccionGarantiaEnum;
    /**
     * 
     * @type {GetWarrantiesPolicyResponseDataCondicionLanzamientoValorAnteriorElemento}
     * @memberof GetWarrantiesPolicyResponseDataLanzadorPorValor
     */
    condicionLanzamientoValorAnteriorElemento?: GetWarrantiesPolicyResponseDataCondicionLanzamientoValorAnteriorElemento;
    /**
     * 
     * @type {Array<GetWarrantiesPolicyResponseDataCadenaLanzamiento>}
     * @memberof GetWarrantiesPolicyResponseDataLanzadorPorValor
     */
    cadenaLanzamiento?: Array<GetWarrantiesPolicyResponseDataCadenaLanzamiento>;
}

/**
    * @export
    * @enum {string}
    */
export enum GetWarrantiesPolicyResponseDataLanzadorPorValorCondicionLanzamientoRestriccionGarantiaEnum {
    Equal = 'Equal',
    NotEqual = 'NotEqual',
    Any = 'Any'
}
/**
 * 
 * @export
 * @interface GetWarrantiesPolicyResponseDataTablaRestricciones
 */
export interface GetWarrantiesPolicyResponseDataTablaRestricciones {
    /**
     * 
     * @type {string}
     * @memberof GetWarrantiesPolicyResponseDataTablaRestricciones
     */
    nombreRestriccion?: string;
    /**
     * 
     * @type {string}
     * @memberof GetWarrantiesPolicyResponseDataTablaRestricciones
     */
    valorRestriccion?: string;
}/**
 * 
 * @export
 * @interface GetWarrantiesPolicyResponseDataVersionAnteriorData
 */
export interface GetWarrantiesPolicyResponseDataVersionAnteriorData {
    /**
     * 
     * @type {number}
     * @memberof GetWarrantiesPolicyResponseDataVersionAnteriorData
     */
    versionAnterior?: number;
}

type getWarrantiesPolicyOperationParams = {
  getWarrantiesPolicyRequest: GetWarrantiesPolicyRequest,
};

/**
 * GetWarrantiesPolicyApi - axios parameter creator
 * @param {Configuration} [configuration]
 * @returns {any}
 */
const GetWarrantiesPolicyApiAxiosParamCreator = function(configuration?: Configuration): any {
  return {
    
    /**
     * Recupera los datos de garantías para pólizas completas e incompletas
     * @param {GetWarrantiesPolicyRequest} getWarrantiesPolicyRequest 
     * @param {string} [acceptLanguage] El Accept-Language request HTTP header indica en que lenguaje debe responder el backend.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @returns {RequestArgs}
     */
    getWarrantiesPolicyOperation(params: getWarrantiesPolicyOperationParams, options: any = {}): RequestArgs {
      // Verify required parameter 'getWarrantiesPolicyRequest' is not null or undefined
      if (params.getWarrantiesPolicyRequest === null || params.getWarrantiesPolicyRequest === undefined) {
        throw new RequiredError('getWarrantiesPolicyRequest', 'Required parameter getWarrantiesPolicyRequest was null or undefined when calling getWarrantiesPolicyOperation.');
      }
      const localVarPath = `/v1/quoteAndBuy/getWarrantiesPolicyOperation`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true) as Partial<globalImportUrl.UrlWithParsedQuery>;
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;


  
      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
      // Fix override query string Detail: https://stackoverflow.com/a/7517673/1077943

      delete localVarUrlObj.search;
      localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
      const needsSerialization = ('GetWarrantiesPolicyRequest' as any !== 'string') ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization ? JSON.stringify(params.getWarrantiesPolicyRequest || {}) : params.getWarrantiesPolicyRequest || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * GetWarrantiesPolicyApi - functional programming interface
 * @param {Configuration} [configuration]
 * @returns {any}
 */
const GetWarrantiesPolicyApiFp = function(configuration?: Configuration): any {
  return {
    
    /**
     * Recupera los datos de garantías para pólizas completas e incompletas
     * @param {GetWarrantiesPolicyRequest} getWarrantiesPolicyRequest 
     * @param {string} [acceptLanguage] El Accept-Language request HTTP header indica en que lenguaje debe responder el backend.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @returns {any}
     */
    getWarrantiesPolicyOperation(params: getWarrantiesPolicyOperationParams, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetWarrantiesPolicyResponse> {
      const localVarAxiosArgs = GetWarrantiesPolicyApiAxiosParamCreator(configuration).getWarrantiesPolicyOperation(params, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH): any => {
        const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * GetWarrantiesPolicyApi - factory interface
 * @param {Configuration} configuration
 * @param {string} basePath
 * @param {AxiosInstance} axios
 * @export
 * @returns {any}
 */
const GetWarrantiesPolicyApiFactory = function(configuration?: Configuration, basePath?: string, axios?: AxiosInstance): any {
  return {
    
    /**
     * Recupera los datos de garantías para pólizas completas e incompletas
     * @param {GetWarrantiesPolicyRequest} getWarrantiesPolicyRequest 
     * @param {string} [acceptLanguage] El Accept-Language request HTTP header indica en que lenguaje debe responder el backend.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @returns {any}
     */
    getWarrantiesPolicyOperation(params: getWarrantiesPolicyOperationParams, options?: any): any {
      return GetWarrantiesPolicyApiFp(configuration).getWarrantiesPolicyOperation(params, options)(axios, basePath);
    },
  };
};

/** 
 * GetWarrantiesPolicyApi - object-oriented interface
 * @export
 * @class GetWarrantiesPolicyApi
 * @extends {BaseAPI}
 */
export class GetWarrantiesPolicyApi extends BaseAPI {
  
  /**
   * Recupera los datos de garantías para pólizas completas e incompletas
   * @param {GetWarrantiesPolicyRequest} getWarrantiesPolicyRequest 
   * @param {string} [acceptLanguage] El Accept-Language request HTTP header indica en que lenguaje debe responder el backend.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @returns {any}
   * @memberof GetWarrantiesPolicyApi
   */
  public getWarrantiesPolicyOperation(params: getWarrantiesPolicyOperationParams, options?: any): any {
    return GetWarrantiesPolicyApiFp(this.configuration).getWarrantiesPolicyOperation(params, options)(this.axios, this.basePath);
  }

}

/**
 * 
 * @export
 * @class EAgetWarrantiesPolicyOperationInputMessage
 */
export class EAgetWarrantiesPolicyOperationInputMessage {
  
  /**
   * 
   * @type {GetWarrantiesPolicyRequest}
   * @memberof EAgetWarrantiesPolicyOperationInputMessage
   */
  public getWarrantiesPolicyRequest: GetWarrantiesPolicyRequest;

  public constructor(getWarrantiesPolicyRequest: GetWarrantiesPolicyRequest, ){
  
  this.getWarrantiesPolicyRequest=getWarrantiesPolicyRequest;
  } 
}



/** 
 * EAGetWarrantiesPolicyApi - Architecture client for getWarrantiesPolicyOperation
 * @export
 * @class EAGetWarrantiesPolicyApi
 */
export class EAGetWarrantiesPolicyApi {

  /**
   * 
   * @param {GetWarrantiesPolicyRequest} getWarrantiesPolicyRequest
   
   * @param {Configuration} configuration
   * @throws {AsyncRequestError | RequiredError}
   * @returns {Promise<GetWarrantiesPolicyResponse | null>}
  */ 
  public async getWarrantiesPolicyOperation(params: getWarrantiesPolicyOperationParams, configuration: Configuration = {}): Promise<GetWarrantiesPolicyResponse | null> {
    return this.getWarrantiesPolicyOperationPromise(params, configuration);
  }

  /**
   * 
   * @param {GetWarrantiesPolicyRequest} getWarrantiesPolicyRequest
   
   * @param {Configuration} configuration
   * @param {Function} callback
   * @throws {AsyncRequestError | RequiredError}
   */
  public async getWarrantiesPolicyOperationSteps(params: getWarrantiesPolicyOperationParams, configuration: Configuration, callback: (res: AxiosResponse<GetWarrantiesPolicyResponse | null>) => void) {
    let done: boolean = false;
    const httpClient: AxiosInstance = EASDKConfig.getHttpClient('/v1/quoteAndBuy/getWarrantiesPolicyOperation', 'getWarrantiesPolicy', 'getWarrantiesPolicyOperation');
    const api = new GetWarrantiesPolicyApi(configuration, '', httpClient);
    const sleep = async(ms: number) => {
      return new Promise(resolve => setTimeout(resolve, ms));
    };
    const headers: { ['Async-Id']?: string } = {};
    while (!done) {
      const output = await api.getWarrantiesPolicyOperation(params, { headers }) as AxiosResponse<GetWarrantiesPolicyResponse | null>;
      callback(output);
      if (output.status === 202) {
        try {
          const retry = parseInt(output.headers['retry']);
          headers['Async-Id'] = output.headers['request-id'];
          if (isNaN(retry) || retry <= 0 || retry > 1000 * 3600) {
            throw new Error(`Retry time not acceptable (in milliseconds): ${retry}`);
          }
          await sleep(retry);
        } catch (err) {
          throw new Error(`Error in Async Request ${err}`);
        }
      } else {
        done = true;
      }
    }
  }

  /**
   * 
   * @param {GetWarrantiesPolicyRequest} getWarrantiesPolicyRequest
   
   * @param {Configuration} configuration
   * @throws {AsyncRequestError | RequiredError}
   * @returns {Promise<GetWarrantiesPolicyResponse | null>}
   */
  public async getWarrantiesPolicyOperationPromise(params: getWarrantiesPolicyOperationParams, configuration: Configuration): Promise<GetWarrantiesPolicyResponse | null> {
    return new Promise((resolve, reject) => {
      this.getWarrantiesPolicyOperationSteps(params, configuration, (response: AxiosResponse<GetWarrantiesPolicyResponse | null>) => {
        if (response.status !== 202) {
          resolve(response.data);
        }
      })
      .catch((err) => {
        reject(err);
      });
    });
  } 
}


