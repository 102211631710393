<template>
  <div class="m-b-16">
    <ea-card  shadow="hidden">
      <div slot="cardbody" class="fleet-info-card p-a-16">
        <h3 class="m-b-0">{{ $t('competencesFlow.competencesView.observations.title') }}</h3>
        <p class="m-b-0 t-weight-normal font-size-14">
          {{ $t('competencesFlow.competencesView.observations.subtitle') }}
        </p>
        <ea-row class="m-t-24 p-a-16">
          <ea-form
            ref="form"
            :model="model"
            :validationOptions="validationOptions"
            :validateOnRuleChange="false"
          >
            <ea-row>
              <ea-col :span="8">
                <ea-form-item
                  prop="title"
                  :label="$t('competencesFlow.competencesView.observations.label.title')"
                  required
                >
                  <ea-input-text
                    :maxlength="64"
                    :placeholder="$t('competencesFlow.competencesView.observations.label.title')"
                    v-model="model.title"
                    />
                </ea-form-item>
              </ea-col>
            </ea-row>
            <ea-row>
              <ea-col :span="12">
                <ea-form-item
                  prop="reasons"
                  :label="$t('competencesFlow.competencesView.observations.label.reasons')"
                  required
                >
                  <ea-input-textarea
                    :maxlength="1024"
                    :placeholder="$t('competencesFlow.competencesView.observations.label.reasons')"
                    v-model="model.reasons"
                    />
                </ea-form-item>
              </ea-col>
            </ea-row>
          </ea-form>

        </ea-row>
      </div>
    </ea-card>
  </div>
</template>

<script lang="ts">
import {
  Component
} from 'vue-property-decorator';

import {
  mixins
} from 'vue-class-component';

import {
  EABusinessComponent
} from '@zurich-es-npm/ea-front-web-core';

import QbObservationsAuthorizationModel from './qb-observations-authorization-model';
import {
  EAFormValidationOptions, eaRequiredValidation
} from '@zurich-es-npm/ea-front-web-ui';

@Component({
  name: 'qb-observations-authorization'
})

/**
 * Business Component qb-observations-authorization
 */
export default class QbObservationsAuthorizationBusiness
  extends mixins<EABusinessComponent<QbObservationsAuthorizationModel>>(EABusinessComponent) {


  /**
   * @returns {EAFormValidationOptions}
   */
  get validationOptions(): EAFormValidationOptions {
    return {
      rules: {
        reasons: [eaRequiredValidation('common.label.validation.fieldRequired')],
        title: [eaRequiredValidation('common.label.validation.fieldRequired')]
      }
    };
  }
}
</script>
