/* eslint-disable */

/**
 * zonazurich
 * Portal de Mediación Digital
 *
 * The version of the OpenAPI document: 1
 * Contact: digitalarchitecture@zurich.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import {
  EASDKConfig,
  EAUrlUtils
} from '@zurich-es-npm/ea-shared-utils-js';

import * as globalImportUrl from 'url';
import {
  Configuration
} from './configuration';
import globalAxios, {
  AxiosPromise,
  AxiosInstance,
  AxiosResponse
} from 'axios';

const BASE_PATH = EAUrlUtils.removeLastForwardSlash('http://localhost:3000');

/**
 *
 * @export
 */
export const COLLECTION_FORMATS = {
  csv: ',',
  ssv: ' ',
  tsv: '\t',
  pipes: '|',
};

/**
 *  
 * @export
 * @interface RequestArgs
 */
export interface RequestArgs {
  url: string;
  options: any;
}

/**
 * 
 * @export
 * @class BaseAPI
 */
export class BaseAPI {
  protected configuration: Configuration | undefined;

  /**
   *Creates an instance of BaseAPI.
   * @param {Configuration} [configuration]
   * @param {string} [basePath=BASE_PATH]
   * @param {AxiosInstance} [axios=globalAxios]
   * @memberof BaseAPI
   */
  public constructor(
    configuration?: Configuration,
    protected basePath: string = BASE_PATH,
    protected axios: AxiosInstance = globalAxios) {
    if (configuration) {
      this.configuration = configuration;
      this.basePath = configuration.basePath || this.basePath;
    }
  }
}

/**
 * 
 * @export
 * @class RequiredError
 * @extends {Error}
 */
export class RequiredError extends Error {
  public name: 'RequiredError' = 'RequiredError';

  public field: string;

  /**
   *Creates an instance of RequiredError.
   * @param {string} field
   * @param {string} [msg]
   * @memberof RequiredError
   */
  public constructor(field: string, msg?: string) {
    super(msg);
    this.field=field;
  }
}

/**
 * 
 * @export
 * @class AsyncRequestError
 * @extends {Error}
 */
export class AsyncRequestError extends Error {
  public name: 'AsyncRequestError' = 'AsyncRequestError';

  /**
   *Creates an instance of AsyncRequestError.
   * @param {string} field
   * @param {string} [msg]
   * @memberof AsyncRequestError
   */
  public constructor(msg?: string) {
    super(msg);
  }
}

/**
 * 
 * @export
 * @interface Error
 */
export interface Error {
    /**
     * 
     * @type {string}
     * @memberof Error
     */
    code: string;
    /**
     * 
     * @type {string}
     * @memberof Error
     */
    message: string;
    /**
     * 
     * @type {number}
     * @memberof Error
     */
    severity: number;
    /**
     * 
     * @type {Array<object>}
     * @memberof Error
     */
    childErrors?: Array<object>;
}/**
 * 
 * @export
 * @interface GetGeneralDataRequest
 */
export interface GetGeneralDataRequest {
    /**
     * 
     * @type {string}
     * @memberof GetGeneralDataRequest
     */
    codigoPoliza: string;
    /**
     * 
     * @type {number}
     * @memberof GetGeneralDataRequest
     */
    versionPoliza: number;
    /**
     * 
     * @type {string}
     * @memberof GetGeneralDataRequest
     */
    tipoPoliza: GetGeneralDataRequestTipoPolizaEnum;
    /**
     * 
     * @type {string}
     * @memberof GetGeneralDataRequest
     */
    codigoRamo?: GetGeneralDataRequestCodigoRamoEnum;
    /**
     * 
     * @type {string}
     * @memberof GetGeneralDataRequest
     */
    tipoOperacionOferta?: GetGeneralDataRequestTipoOperacionOfertaEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum GetGeneralDataRequestTipoPolizaEnum {
    Oferta = 'Oferta',
    Pliza = 'Póliza',
    Incompleta = 'Incompleta'
}
/**
    * @export
    * @enum {string}
    */
export enum GetGeneralDataRequestCodigoRamoEnum {
    THCO = 'THCO',
    COMU = 'COMU',
    LPAU = 'LPAU',
    THFL = 'THFL',
    ZTRC = 'ZTRC',
    LPHG = 'LPHG',
    HOME = 'HOME',
    THHG = 'THHG',
    THHD = 'THHD'
}
/**
    * @export
    * @enum {string}
    */
export enum GetGeneralDataRequestTipoOperacionOfertaEnum {
    CONSULTA = 'CONSULTA',
    MANTENIMIENTO = 'MANTENIMIENTO',
    NUEVA = 'NUEVA'
}
/**
 * 
 * @export
 * @interface GetGeneralDataResponse
 */
export interface GetGeneralDataResponse {
    /**
     * 
     * @type {GetGeneralDataResponseData}
     * @memberof GetGeneralDataResponse
     */
    data?: GetGeneralDataResponseData;
    /**
     * 
     * @type {Array<Error>}
     * @memberof GetGeneralDataResponse
     */
    errors?: Array<Error>;
}/**
 * 
 * @export
 * @interface GetGeneralDataResponseData
 */
export interface GetGeneralDataResponseData {
    /**
     * 
     * @type {GetGeneralDataResponseDataDatosCabecera}
     * @memberof GetGeneralDataResponseData
     */
    datosCabecera?: GetGeneralDataResponseDataDatosCabecera;
    /**
     * 
     * @type {Array<GetGeneralDataResponseDataDatosObjeto>}
     * @memberof GetGeneralDataResponseData
     */
    datosObjeto?: Array<GetGeneralDataResponseDataDatosObjeto>;
    /**
     * 
     * @type {Array<GetGeneralDataResponseDataDatosObjeto>}
     * @memberof GetGeneralDataResponseData
     */
    datosAdicionales?: Array<GetGeneralDataResponseDataDatosObjeto>;
    /**
     * 
     * @type {Array<GetGeneralDataResponseDataDatosCabeceraTablaRestricciones>}
     * @memberof GetGeneralDataResponseData
     */
    fraccionamientoFormasPago?: Array<GetGeneralDataResponseDataDatosCabeceraTablaRestricciones>;
    /**
     * 
     * @type {Array<GetGeneralDataResponseDataDatosPersonas>}
     * @memberof GetGeneralDataResponseData
     */
    datosPersonas?: Array<GetGeneralDataResponseDataDatosPersonas>;
    /**
     * 
     * @type {Array<GetGeneralDataResponseDataListaCanalesCobroSucesivos>}
     * @memberof GetGeneralDataResponseData
     */
    listaCanalesCobroSucesivos?: Array<GetGeneralDataResponseDataListaCanalesCobroSucesivos>;
    /**
     * 
     * @type {Array<GetGeneralDataResponseDataListaCanalesCobroSucesivos>}
     * @memberof GetGeneralDataResponseData
     */
    listaCanalesCobro1Recibo?: Array<GetGeneralDataResponseDataListaCanalesCobroSucesivos>;
    /**
     * 
     * @type {Array<GetGeneralDataResponseDataDatosCabeceraTablaRestricciones>}
     * @memberof GetGeneralDataResponseData
     */
    formasPago?: Array<GetGeneralDataResponseDataDatosCabeceraTablaRestricciones>;
}/**
 * 
 * @export
 * @interface GetGeneralDataResponseDataDatosCabecera
 */
export interface GetGeneralDataResponseDataDatosCabecera {
    /**
     * 
     * @type {GetGeneralDataResponseDataDatosCabeceraDatosPoliza}
     * @memberof GetGeneralDataResponseDataDatosCabecera
     */
    datosPoliza?: GetGeneralDataResponseDataDatosCabeceraDatosPoliza;
    /**
     * 
     * @type {GetGeneralDataResponseDataDatosCabeceraVersionAnterior}
     * @memberof GetGeneralDataResponseDataDatosCabecera
     */
    versionAnterior?: GetGeneralDataResponseDataDatosCabeceraVersionAnterior;
    /**
     * 
     * @type {GetGeneralDataResponseDataDatosCabeceraDatosIntermediario}
     * @memberof GetGeneralDataResponseDataDatosCabecera
     */
    datosIntermediario?: GetGeneralDataResponseDataDatosCabeceraDatosIntermediario;
    /**
     * 
     * @type {GetGeneralDataResponseDataDatosCabeceraDatosProducto}
     * @memberof GetGeneralDataResponseDataDatosCabecera
     */
    datosProducto?: GetGeneralDataResponseDataDatosCabeceraDatosProducto;
    /**
     * 
     * @type {Array<GetGeneralDataResponseDataDatosCabeceraDatosAdicionales>}
     * @memberof GetGeneralDataResponseDataDatosCabecera
     */
    datosAdicionales?: Array<GetGeneralDataResponseDataDatosCabeceraDatosAdicionales>;
    /**
     * 
     * @type {Array<GetGeneralDataResponseDataDatosCabeceraElementosPoliza>}
     * @memberof GetGeneralDataResponseDataDatosCabecera
     */
    elementosPoliza?: Array<GetGeneralDataResponseDataDatosCabeceraElementosPoliza>;
    /**
     * 
     * @type {boolean}
     * @memberof GetGeneralDataResponseDataDatosCabecera
     */
    indicadorDataQuality?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetGeneralDataResponseDataDatosCabecera
     */
    codigoNegocio?: string;
    /**
     * 
     * @type {string}
     * @memberof GetGeneralDataResponseDataDatosCabecera
     */
    nombreNegocio?: string;
    /**
     * 
     * @type {string}
     * @memberof GetGeneralDataResponseDataDatosCabecera
     */
    codigoObjeto?: string;
}/**
 * 
 * @export
 * @interface GetGeneralDataResponseDataDatosCabeceraDatosAdicionales
 */
export interface GetGeneralDataResponseDataDatosCabeceraDatosAdicionales {
    /**
     * 
     * @type {string}
     * @memberof GetGeneralDataResponseDataDatosCabeceraDatosAdicionales
     */
    descripcionElementoTp?: string;
    /**
     * 
     * @type {string}
     * @memberof GetGeneralDataResponseDataDatosCabeceraDatosAdicionales
     */
    valorElementoAdicional?: string;
}/**
 * 
 * @export
 * @interface GetGeneralDataResponseDataDatosCabeceraDatosIntermediario
 */
export interface GetGeneralDataResponseDataDatosCabeceraDatosIntermediario {
    /**
     * 
     * @type {string}
     * @memberof GetGeneralDataResponseDataDatosCabeceraDatosIntermediario
     */
    nombreIntermediario?: string;
}/**
 * 
 * @export
 * @interface GetGeneralDataResponseDataDatosCabeceraDatosPoliza
 */
export interface GetGeneralDataResponseDataDatosCabeceraDatosPoliza {
    /**
     * 
     * @type {string}
     * @memberof GetGeneralDataResponseDataDatosCabeceraDatosPoliza
     */
    codigoTecnicoProducto?: string;
    /**
     * 
     * @type {string}
     * @memberof GetGeneralDataResponseDataDatosCabeceraDatosPoliza
     */
    codigoComercialProducto?: string;
    /**
     * 
     * @type {string}
     * @memberof GetGeneralDataResponseDataDatosCabeceraDatosPoliza
     */
    fechaEfectoMovimiento?: string;
    /**
     * 
     * @type {string}
     * @memberof GetGeneralDataResponseDataDatosCabeceraDatosPoliza
     */
    fechaVencimiento?: string;
    /**
     * 
     * @type {string}
     * @memberof GetGeneralDataResponseDataDatosCabeceraDatosPoliza
     */
    codigoIntermediario?: string;
    /**
     * 
     * @type {string}
     * @memberof GetGeneralDataResponseDataDatosCabeceraDatosPoliza
     */
    codigoTipoMovimiento?: string;
}/**
 * 
 * @export
 * @interface GetGeneralDataResponseDataDatosCabeceraDatosProducto
 */
export interface GetGeneralDataResponseDataDatosCabeceraDatosProducto {
    /**
     * 
     * @type {string}
     * @memberof GetGeneralDataResponseDataDatosCabeceraDatosProducto
     */
    nombreProducto?: string;
}/**
 * 
 * @export
 * @interface GetGeneralDataResponseDataDatosCabeceraElementosPoliza
 */
export interface GetGeneralDataResponseDataDatosCabeceraElementosPoliza {
    /**
     * 
     * @type {string}
     * @memberof GetGeneralDataResponseDataDatosCabeceraElementosPoliza
     */
    codigoElemento?: string;
    /**
     * 
     * @type {string}
     * @memberof GetGeneralDataResponseDataDatosCabeceraElementosPoliza
     */
    tablaElemento?: string;
    /**
     * 
     * @type {string}
     * @memberof GetGeneralDataResponseDataDatosCabeceraElementosPoliza
     */
    valorElemento?: string;
    /**
     * 
     * @type {number}
     * @memberof GetGeneralDataResponseDataDatosCabeceraElementosPoliza
     */
    valorMinimoElemento?: number;
    /**
     * 
     * @type {number}
     * @memberof GetGeneralDataResponseDataDatosCabeceraElementosPoliza
     */
    valorMaximoElemento?: number;
    /**
     * 
     * @type {boolean}
     * @memberof GetGeneralDataResponseDataDatosCabeceraElementosPoliza
     */
    elementoModificable?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetGeneralDataResponseDataDatosCabeceraElementosPoliza
     */
    elementoRequerido?: boolean;
    /**
     * 
     * @type {number}
     * @memberof GetGeneralDataResponseDataDatosCabeceraElementosPoliza
     */
    longitudElemento?: number;
    /**
     * 
     * @type {number}
     * @memberof GetGeneralDataResponseDataDatosCabeceraElementosPoliza
     */
    numeroDecimalesElemento?: number;
    /**
     * 
     * @type {Array<GetGeneralDataResponseDataDatosCabeceraTablaRestricciones>}
     * @memberof GetGeneralDataResponseDataDatosCabeceraElementosPoliza
     */
    tablaRestricciones?: Array<GetGeneralDataResponseDataDatosCabeceraTablaRestricciones>;
}/**
 * 
 * @export
 * @interface GetGeneralDataResponseDataDatosCabeceraTablaRestricciones
 */
export interface GetGeneralDataResponseDataDatosCabeceraTablaRestricciones {
    /**
     * 
     * @type {string}
     * @memberof GetGeneralDataResponseDataDatosCabeceraTablaRestricciones
     */
    nombreRestriccion?: string;
    /**
     * 
     * @type {string}
     * @memberof GetGeneralDataResponseDataDatosCabeceraTablaRestricciones
     */
    valorRestriccion?: string;
}/**
 * 
 * @export
 * @interface GetGeneralDataResponseDataDatosCabeceraVersionAnterior
 */
export interface GetGeneralDataResponseDataDatosCabeceraVersionAnterior {
    /**
     * 
     * @type {string}
     * @memberof GetGeneralDataResponseDataDatosCabeceraVersionAnterior
     */
    fechaInicioPoliza?: string;
    /**
     * 
     * @type {string}
     * @memberof GetGeneralDataResponseDataDatosCabeceraVersionAnterior
     */
    fechaEfectoNatural?: string;
    /**
     * 
     * @type {string}
     * @memberof GetGeneralDataResponseDataDatosCabeceraVersionAnterior
     */
    fechaVencimiento?: string;
}/**
 * 
 * @export
 * @interface GetGeneralDataResponseDataDatosObjeto
 */
export interface GetGeneralDataResponseDataDatosObjeto {
    /**
     * 
     * @type {string}
     * @memberof GetGeneralDataResponseDataDatosObjeto
     */
    codigoElemento?: string;
    /**
     * 
     * @type {string}
     * @memberof GetGeneralDataResponseDataDatosObjeto
     */
    tablaElemento?: string;
    /**
     * 
     * @type {string}
     * @memberof GetGeneralDataResponseDataDatosObjeto
     */
    valorElemento?: string;
    /**
     * 
     * @type {number}
     * @memberof GetGeneralDataResponseDataDatosObjeto
     */
    valorMaximoElemento?: number;
    /**
     * 
     * @type {number}
     * @memberof GetGeneralDataResponseDataDatosObjeto
     */
    valorMinimoElemento?: number;
    /**
     * 
     * @type {boolean}
     * @memberof GetGeneralDataResponseDataDatosObjeto
     */
    elementoRequerido?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetGeneralDataResponseDataDatosObjeto
     */
    elementoModificable?: boolean;
    /**
     * 
     * @type {number}
     * @memberof GetGeneralDataResponseDataDatosObjeto
     */
    longitudElemento?: number;
    /**
     * 
     * @type {number}
     * @memberof GetGeneralDataResponseDataDatosObjeto
     */
    numeroDecimalesElemento?: number;
    /**
     * 
     * @type {string}
     * @memberof GetGeneralDataResponseDataDatosObjeto
     */
    tipoElemento?: GetGeneralDataResponseDataDatosObjetoTipoElementoEnum;
    /**
     * 
     * @type {string}
     * @memberof GetGeneralDataResponseDataDatosObjeto
     */
    tipoMascara?: GetGeneralDataResponseDataDatosObjetoTipoMascaraEnum;
    /**
     * 
     * @type {Array<GetGeneralDataResponseDataDatosCabeceraTablaRestricciones>}
     * @memberof GetGeneralDataResponseDataDatosObjeto
     */
    tablaRestricciones?: Array<GetGeneralDataResponseDataDatosCabeceraTablaRestricciones>;
}

/**
    * @export
    * @enum {string}
    */
export enum GetGeneralDataResponseDataDatosObjetoTipoElementoEnum {
    NUMERIC = 'NUMERIC',
    ALPHANUMERIC = 'ALPHANUMERIC'
}
/**
    * @export
    * @enum {string}
    */
export enum GetGeneralDataResponseDataDatosObjetoTipoMascaraEnum {
    A = 'A',
    C = 'C',
    D = 'D',
    F = 'F',
    I = 'I',
    M = 'M',
    P = 'P',
    R = 'R',
    T = 'T'
}
/**
 * 
 * @export
 * @interface GetGeneralDataResponseDataDatosPersonas
 */
export interface GetGeneralDataResponseDataDatosPersonas {
    /**
     * 
     * @type {string}
     * @memberof GetGeneralDataResponseDataDatosPersonas
     */
    rolCliente?: GetGeneralDataResponseDataDatosPersonasRolClienteEnum;
    /**
     * 
     * @type {string}
     * @memberof GetGeneralDataResponseDataDatosPersonas
     */
    codigoFiliacion?: string;
    /**
     * 
     * @type {string}
     * @memberof GetGeneralDataResponseDataDatosPersonas
     */
    nombrePropio?: string;
    /**
     * 
     * @type {string}
     * @memberof GetGeneralDataResponseDataDatosPersonas
     */
    primerApellido?: string;
    /**
     * 
     * @type {string}
     * @memberof GetGeneralDataResponseDataDatosPersonas
     */
    segundoApellido?: string;
    /**
     * 
     * @type {string}
     * @memberof GetGeneralDataResponseDataDatosPersonas
     */
    codigoIdentificacionFiscal?: string;
    /**
     * 
     * @type {string}
     * @memberof GetGeneralDataResponseDataDatosPersonas
     */
    tipoDocumento?: GetGeneralDataResponseDataDatosPersonasTipoDocumentoEnum;
    /**
     * 
     * @type {string}
     * @memberof GetGeneralDataResponseDataDatosPersonas
     */
    tipoPersona?: GetGeneralDataResponseDataDatosPersonasTipoPersonaEnum;
    /**
     * 
     * @type {string}
     * @memberof GetGeneralDataResponseDataDatosPersonas
     */
    sexo?: GetGeneralDataResponseDataDatosPersonasSexoEnum;
    /**
     * 
     * @type {string}
     * @memberof GetGeneralDataResponseDataDatosPersonas
     */
    fechaNacimiento?: string;
    /**
     * 
     * @type {string}
     * @memberof GetGeneralDataResponseDataDatosPersonas
     */
    nacionalidadPersona?: string;
    /**
     * 
     * @type {string}
     * @memberof GetGeneralDataResponseDataDatosPersonas
     */
    codigoResidencia?: string;
    /**
     * 
     * @type {string}
     * @memberof GetGeneralDataResponseDataDatosPersonas
     */
    codigoSecuencialDomicilio?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum GetGeneralDataResponseDataDatosPersonasRolClienteEnum {
    Tomador = 'Tomador',
    Asegurado = 'Asegurado',
    Pagador = 'Pagador'
}
/**
    * @export
    * @enum {string}
    */
export enum GetGeneralDataResponseDataDatosPersonasTipoDocumentoEnum {
    C = 'C',
    E = 'E',
    N = 'N',
    P = 'P',
    R = 'R',
    S = 'S',
    X = 'X'
}
/**
    * @export
    * @enum {string}
    */
export enum GetGeneralDataResponseDataDatosPersonasTipoPersonaEnum {
    F = 'F',
    J = 'J'
}
/**
    * @export
    * @enum {string}
    */
export enum GetGeneralDataResponseDataDatosPersonasSexoEnum {
    V = 'V',
    M = 'M'
}
/**
 * 
 * @export
 * @interface GetGeneralDataResponseDataListaCanalesCobroSucesivos
 */
export interface GetGeneralDataResponseDataListaCanalesCobroSucesivos {
    /**
     * 
     * @type {string}
     * @memberof GetGeneralDataResponseDataListaCanalesCobroSucesivos
     */
    tipoCanalCobro?: GetGeneralDataResponseDataListaCanalesCobroSucesivosTipoCanalCobroEnum;
    /**
     * 
     * @type {string}
     * @memberof GetGeneralDataResponseDataListaCanalesCobroSucesivos
     */
    tipoCobro?: GetGeneralDataResponseDataListaCanalesCobroSucesivosTipoCobroEnum;
    /**
     * 
     * @type {Array<GetGeneralDataResponseDataListaIntermediarios>}
     * @memberof GetGeneralDataResponseDataListaCanalesCobroSucesivos
     */
    listaIntermediarios?: Array<GetGeneralDataResponseDataListaIntermediarios>;
}

/**
    * @export
    * @enum {string}
    */
export enum GetGeneralDataResponseDataListaCanalesCobroSucesivosTipoCanalCobroEnum {
    BC = 'BC',
    IN = 'IN',
    CC = 'CC',
    CD = 'CD'
}
/**
    * @export
    * @enum {string}
    */
export enum GetGeneralDataResponseDataListaCanalesCobroSucesivosTipoCobroEnum {
    _1 = '1',
    S = 'S',
    T = 'T'
}
/**
 * 
 * @export
 * @interface GetGeneralDataResponseDataListaIntermediarios
 */
export interface GetGeneralDataResponseDataListaIntermediarios {
    /**
     * 
     * @type {string}
     * @memberof GetGeneralDataResponseDataListaIntermediarios
     */
    codigoIntermediario?: string;
    /**
     * 
     * @type {string}
     * @memberof GetGeneralDataResponseDataListaIntermediarios
     */
    nombreIntermediario?: string;
}

type getGeneralDataOperationParams = {
  getGeneralDataRequest: GetGeneralDataRequest,
};

/**
 * GetGeneralDataApi - axios parameter creator
 * @param {Configuration} [configuration]
 * @returns {any}
 */
const GetGeneralDataApiAxiosParamCreator = function(configuration?: Configuration): any {
  return {
    
    /**
     * Recupera los datos generales dada una oferta
     * @param {GetGeneralDataRequest} getGeneralDataRequest 
     * @param {string} [acceptLanguage] El Accept-Language request HTTP header indica en que lenguaje debe responder el backend.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @returns {RequestArgs}
     */
    getGeneralDataOperation(params: getGeneralDataOperationParams, options: any = {}): RequestArgs {
      // Verify required parameter 'getGeneralDataRequest' is not null or undefined
      if (params.getGeneralDataRequest === null || params.getGeneralDataRequest === undefined) {
        throw new RequiredError('getGeneralDataRequest', 'Required parameter getGeneralDataRequest was null or undefined when calling getGeneralDataOperation.');
      }
      const localVarPath = `/v1/quoteAndBuy/getGeneralDataOperation`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true) as Partial<globalImportUrl.UrlWithParsedQuery>;
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;


  
      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
      // Fix override query string Detail: https://stackoverflow.com/a/7517673/1077943

      delete localVarUrlObj.search;
      localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
      const needsSerialization = ('GetGeneralDataRequest' as any !== 'string') ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization ? JSON.stringify(params.getGeneralDataRequest || {}) : params.getGeneralDataRequest || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * GetGeneralDataApi - functional programming interface
 * @param {Configuration} [configuration]
 * @returns {any}
 */
const GetGeneralDataApiFp = function(configuration?: Configuration): any {
  return {
    
    /**
     * Recupera los datos generales dada una oferta
     * @param {GetGeneralDataRequest} getGeneralDataRequest 
     * @param {string} [acceptLanguage] El Accept-Language request HTTP header indica en que lenguaje debe responder el backend.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @returns {any}
     */
    getGeneralDataOperation(params: getGeneralDataOperationParams, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetGeneralDataResponse> {
      const localVarAxiosArgs = GetGeneralDataApiAxiosParamCreator(configuration).getGeneralDataOperation(params, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH): any => {
        const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * GetGeneralDataApi - factory interface
 * @param {Configuration} configuration
 * @param {string} basePath
 * @param {AxiosInstance} axios
 * @export
 * @returns {any}
 */
const GetGeneralDataApiFactory = function(configuration?: Configuration, basePath?: string, axios?: AxiosInstance): any {
  return {
    
    /**
     * Recupera los datos generales dada una oferta
     * @param {GetGeneralDataRequest} getGeneralDataRequest 
     * @param {string} [acceptLanguage] El Accept-Language request HTTP header indica en que lenguaje debe responder el backend.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @returns {any}
     */
    getGeneralDataOperation(params: getGeneralDataOperationParams, options?: any): any {
      return GetGeneralDataApiFp(configuration).getGeneralDataOperation(params, options)(axios, basePath);
    },
  };
};

/** 
 * GetGeneralDataApi - object-oriented interface
 * @export
 * @class GetGeneralDataApi
 * @extends {BaseAPI}
 */
export class GetGeneralDataApi extends BaseAPI {
  
  /**
   * Recupera los datos generales dada una oferta
   * @param {GetGeneralDataRequest} getGeneralDataRequest 
   * @param {string} [acceptLanguage] El Accept-Language request HTTP header indica en que lenguaje debe responder el backend.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @returns {any}
   * @memberof GetGeneralDataApi
   */
  public getGeneralDataOperation(params: getGeneralDataOperationParams, options?: any): any {
    return GetGeneralDataApiFp(this.configuration).getGeneralDataOperation(params, options)(this.axios, this.basePath);
  }

}

/**
 * 
 * @export
 * @class EAgetGeneralDataOperationInputMessage
 */
export class EAgetGeneralDataOperationInputMessage {
  
  /**
   * 
   * @type {GetGeneralDataRequest}
   * @memberof EAgetGeneralDataOperationInputMessage
   */
  public getGeneralDataRequest: GetGeneralDataRequest;

  public constructor(getGeneralDataRequest: GetGeneralDataRequest, ){
  
  this.getGeneralDataRequest=getGeneralDataRequest;
  } 
}



/** 
 * EAGetGeneralDataApi - Architecture client for getGeneralDataOperation
 * @export
 * @class EAGetGeneralDataApi
 */
export class EAGetGeneralDataApi {

  /**
   * 
   * @param {GetGeneralDataRequest} getGeneralDataRequest
   
   * @param {Configuration} configuration
   * @throws {AsyncRequestError | RequiredError}
   * @returns {Promise<GetGeneralDataResponse | null>}
  */ 
  public async getGeneralDataOperation(params: getGeneralDataOperationParams, configuration: Configuration = {}): Promise<GetGeneralDataResponse | null> {
    return this.getGeneralDataOperationPromise(params, configuration);
  }

  /**
   * 
   * @param {GetGeneralDataRequest} getGeneralDataRequest
   
   * @param {Configuration} configuration
   * @param {Function} callback
   * @throws {AsyncRequestError | RequiredError}
   */
  public async getGeneralDataOperationSteps(params: getGeneralDataOperationParams, configuration: Configuration, callback: (res: AxiosResponse<GetGeneralDataResponse | null>) => void) {
    let done: boolean = false;
    const httpClient: AxiosInstance = EASDKConfig.getHttpClient('/v1/quoteAndBuy/getGeneralDataOperation', 'getGeneralData', 'getGeneralDataOperation');
    const api = new GetGeneralDataApi(configuration, '', httpClient);
    const sleep = async(ms: number) => {
      return new Promise(resolve => setTimeout(resolve, ms));
    };
    const headers: { ['Async-Id']?: string } = {};
    while (!done) {
      const output = await api.getGeneralDataOperation(params, { headers }) as AxiosResponse<GetGeneralDataResponse | null>;
      callback(output);
      if (output.status === 202) {
        try {
          const retry = parseInt(output.headers['retry']);
          headers['Async-Id'] = output.headers['request-id'];
          if (isNaN(retry) || retry <= 0 || retry > 1000 * 3600) {
            throw new Error(`Retry time not acceptable (in milliseconds): ${retry}`);
          }
          await sleep(retry);
        } catch (err) {
          throw new Error(`Error in Async Request ${err}`);
        }
      } else {
        done = true;
      }
    }
  }

  /**
   * 
   * @param {GetGeneralDataRequest} getGeneralDataRequest
   
   * @param {Configuration} configuration
   * @throws {AsyncRequestError | RequiredError}
   * @returns {Promise<GetGeneralDataResponse | null>}
   */
  public async getGeneralDataOperationPromise(params: getGeneralDataOperationParams, configuration: Configuration): Promise<GetGeneralDataResponse | null> {
    return new Promise((resolve, reject) => {
      this.getGeneralDataOperationSteps(params, configuration, (response: AxiosResponse<GetGeneralDataResponse | null>) => {
        if (response.status !== 202) {
          resolve(response.data);
        }
      })
      .catch((err) => {
        reject(err);
      });
    });
  } 
}


