<template>
  <div>
    <ea-card
     shadow="hidden"
    >
      <div slot="cardbody" class="fleet-info-card p-a-16">
        <h2 class="m-b-16">{{ $t(`fleets.fleetsFlow.fleet-general-info.title`) }}</h2>
        <ea-form
          ref="refFleetGeneralInfoForm"
          :model="model.formValues"
          :validationOptions="formValidations">
          <ea-row>
            <ea-col :span="6">
              <ea-form-item
                :label="$t('fleets.fleetsFlow.fleet-general-info.vehiclesNumber.title')"
                prop="vehiclesNumber"
                :required="!readOnlyOperation">
                <div slot="tooltipIconInfo">
                  <div slot="content">
                    <span>{{$t('fleets.fleetsFlow.fleet-general-info.vehiclesNumber.tooltip')}}</span>
                  </div>
                </div>
                <ea-input-number
                  :readonly="readOnlyOperation"
                  v-model = "model.formValues.vehiclesNumber"
                 />
              </ea-form-item>
            </ea-col>

            <ea-col :span="6">
              <ea-form-item
                :label="$t('fleets.fleetsFlow.fleet-general-info.annualInsurancePremium.title')"
                prop="annualInsurancePremium"
                :required="!readOnlyOperation">
                <div slot="tooltipIconInfo">
                  <div slot="content">
                    <span>{{$t('fleets.fleetsFlow.fleet-general-info.annualInsurancePremium.tooltip')}}</span>
                  </div>
                </div>
                <ea-input-number
                :readonly="readOnlyOperation"
                v-model = "model.formValues.annualInsurancePremium" />
              </ea-form-item>
            </ea-col>
          </ea-row>

          <ea-row>
            <ea-col :span="6">
              <ea-form-item
                :label="$t('fleets.fleetsFlow.fleet-general-info.rentingVehicle.title')"
                prop="rentingVehicle"
                :required="!readOnlyOperation">
                <ea-radio-group
                  direction="horizontal"
                  v-model="model.formValues.rentingVehicle">
                  <ea-radio
                    :readonly="readOnlyOperation"
                    :label="true"
                    class="m-l-0">
                    {{ $t('common.label.yes') }}
                  </ea-radio>
                  <ea-radio
                    :readonly="readOnlyOperation"
                    :label="false"
                    class="m-8">
                    {{ $t('common.label.no') }}
                  </ea-radio>
                </ea-radio-group>
              </ea-form-item>
            </ea-col>

            <ea-col :span="6">
              <ea-form-item
              :label="$t('fleets.fleetsFlow.fleet-general-info.secondCategoryVehicleScope')"
              prop="secondCategoryVehicleScope"
              :required="!readOnlyOperation">
                <div slot="tooltipIconInfo">
                  {{ $t('fleets.fleetsFlow.fleet-general-info.secondCategoryTootltip') }}
                </div>
                <ea-select
                  v-model="model.formValues.secondCategoryVehicleScope"
                  :placeholder="$t('common.label.select')"
                  :readonly="readOnlyOperation">
                    <ea-option
                      v-for="item in secondCatVehiclesList"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    />
                </ea-select>
              </ea-form-item>
            </ea-col>
          </ea-row>

          <ea-row>
            <ea-col>
                <ea-form-item
                  :label="$t('fleets.fleetsFlow.fleet-general-info.underageConductor')"
                  prop="underageConductor"
                  :required="!readOnlyOperation">
                  <ea-radio-group
                    direction="horizontal"
                    v-model="model.formValues.underageConductor">
                    <ea-radio
                      :readonly="readOnlyOperation"
                      :label="true"
                      class="m-l-0">
                      {{ $t('common.label.yes') }}
                    </ea-radio>
                    <ea-radio
                      :readonly="readOnlyOperation"
                      :label="false"
                      class="m-8">
                      {{ $t('common.label.no') }}
                    </ea-radio>
                </ea-radio-group>
                </ea-form-item>
              </ea-col>
          </ea-row>
        </ea-form>
      </div>
    </ea-card>
  </div>
</template>

<script lang="ts">
import {
  Component, Prop
} from 'vue-property-decorator';

import {
  mixins
} from 'vue-class-component';

import {
  EABusinessComponent, EAMethod
} from '@zurich-es-npm/ea-front-web-core';

import FleetGeneralInfoModel from './fleet-general-info-model';
import {
  EAFormValidationOptions,
  eaRequiredNumberValidation,
  eaRequiredValidation,
  EAValidation, EAValidationTriggers, EAValidationTypes, Form
} from '@zurich-es-npm/ea-front-web-ui';
import {
  fetchCorporateTable, CorpTableNames, parseCorpTableDocuments, ParsedTableData
} from '@/utils/corporate-tables';
import {
  CorpTableData
} from '../../utils/corporate-tables';

@Component({
  name: 'fleet-general-info'
})

/**
 * Business Component fleet-general-info
 */
export default class FleetGeneralInfoBusiness extends mixins<
EABusinessComponent<FleetGeneralInfoModel>>(EABusinessComponent) {

  configFlowValidations = {};

  secondCatVehiclesList: ParsedTableData[] = [];

  @Prop({
    required: true
  })
    secondCatVehicles!: string[];

  @Prop()
    readOnlyOperation?: boolean;

  /**
   * Hook on created.
   *
   * @returns {Promise<void>}
   */
  @EAMethod({
    loading:true
  })
  public async created(): Promise<void> {
    if (this.secondCatVehiclesList.length === 0) {
      const data: CorpTableData = await fetchCorporateTable(CorpTableNames.InternationalTransport);
      this.secondCatVehiclesList = parseCorpTableDocuments(data).
        filter(result => this.secondCatVehicles.includes(result.value));
    }
  }

  /**
   * Getter for validation options
   * @returns { EAFormValidationOptions }
   */
  get formValidations(): EAFormValidationOptions {
    return {
      rules: {
        vehiclesNumber: [
          eaRequiredNumberValidation()
        ],
        annualInsurancePremium: [
          eaRequiredNumberValidation()
        ],
        rentingVehicle: [
          eaRequiredValidation(
            'common.label.validation.fieldRequired',
            EAValidationTriggers.BLUR,
            EAValidationTypes.BOOLEAN
          )
        ],
        secondCategoryVehicleScope: this.readOnlyOperation ? []: [eaRequiredValidation()],
        underageConductor: [
          eaRequiredValidation(
            'common.label.validation.fieldRequired',
            EAValidationTriggers.BLUR,
            EAValidationTypes.BOOLEAN
          )
        ]
      }
    };
  }

  /**
   * @description: method to validate form in parent component
   * @returns {EAValidation}
   */
  validation(): EAValidation {
    const formFleetGeneralInfo: Form = this.$refs.refFleetGeneralInfoForm as Form;
    return formFleetGeneralInfo.validation();
  }

}
</script>
