/** 
 * Model warranties-header-inline-selection
 */
class WarrantiesHeaderInlineSelectionModel {

  public selectedOption?: string = undefined;

  public multipleSelectedOptions: string[] = [];

}

export default WarrantiesHeaderInlineSelectionModel;
