<template>
  <div>
    <div>
      <fleet-data-header
        class="m-b-16"
        :data="model.qbNewOfferBusinessModel">
      </fleet-data-header>
      
      <fleet-search-policyholder
        ref="refFleetSearchPolicyholderBC"
        id="search-person"
        class="m-b-16"
        v-model="model.fleetSearchPolicyHolderModel"
        :intermediaryCode="model.qbNewOfferBusinessModel.intermediaryCode"
        :intermediaryName="model.qbNewOfferBusinessModel.intermediaryName"
        :commitContractPolicyTypes="fleetCommitContractPolicyTypes"
        :documentTypeList="showPolicyholderInfoDocumentTypes"
        :juridicalPersonDocumentType="fleetSearchPolicyholderJuridicalPersonDocumentType"
        :addressTypeList="addressTypeList"
        :readOnlyOperation="readOnlyOperation"
        :employeeMaxRiskAppetite="employeeMaxRiskAppetite"
        :intermediaryMaxRiskAppetite="intermediaryMaxRiskAppetite"
        @userWithCommitContrat="onUserWithCommitContrat"
        @validRiskApetite="onRiskApetitEval"
        @disableNextBtnOnNewSearch="onDisableNextButton">
      </fleet-search-policyholder>

      <fleet-general-info
        v-show="showFleetGeneralInfo"
        ref="refFleetGeneralInfoBC"
        id="fleet-general-info"
        v-model="model.fleetGeneralInfoModel"
        :readOnlyOperation="readOnlyOperation"
        :secondCatVehicles="fleetSearchPolicyholderSecondCatVehiclesList">
      </fleet-general-info>

      <risk-management
        ref="riskManagementBC"
        id="risk-management-info"
        :readOnlyOperation="readOnlyOperation"
        v-show="showFleetGeneralInfo"
        v-model="model.fleetsRiskManagementModel"/>
    </div>
    
    <ea-row extraClass="m-t-8">
      <ea-col :span="12" class="d-display-flex d-justify-flex-start">
        <ea-button type="secondary" @click="onGoBack()" v-if="model.hasParentFlow">
          {{ $t('common.label.back') }}
        </ea-button>
      </ea-col>
      <ea-col class="d-display-flex d-justify-flex-end" :span="model.hasParentFlow ? 12 : 24">
        <ea-button
          type="primary"
          @click="nextStep()"
          :disabled="disableNextButton"
        >
          {{ $t('common.label.next') }}
        </ea-button>
      </ea-col>
    </ea-row>
  </div>
</template>

<script lang="ts">
import {
  Component
} from 'vue-property-decorator';

import {
  mixins
} from 'vue-class-component';

import {
  EAView,
  EAMethod,
  throwIfResponseHasErrors,
  ResponseWithErrors,
  EAErrorManager
} from '@zurich-es-npm/ea-front-web-core';

import {
  FleetsModel, FlowHeaderStepsModel, FlowViewsStepsModel, OperationTypeEnum
} from '../fleets-model';
import FleetDataHeader from '../components/fleet-data-header.vue';
import FleetSearchPolicyHolderBusiness
  from '../../../business-components/fleets-search-policyholder/fleet-search-policyholder-business.vue';
import {
  EACompositeValidation
} from '@zurich-es-npm/ea-front-web-ui';
import FleetGeneralInfoBusiness from '@/business-components/fleet-general-info/fleet-general-info-business.vue';
import {
  LabelValueType
} from '../model/label-value-type-model';
import {
  RangeRestriction
} from '../model/range-restriction-model';
import FleetsRiskManagementBusiness
  from '@/business-components/fleets-risk-management/fleets-risk-management-business.vue';
import {
  EASaveOfferApi, SaveOfferRequest, SaveOfferRequestDatosGeneralesTomadorTipoDocumentoEnum as DocumentTypes,
} from '@/services/V1/fleets/saveOfferOperation/post';
import {
  NotificationsUtils
} from '@/utils/notifications/notifications-utils';
import {
  CorpTableNames, fetchCorporateTable, parseCorpTableDocuments, ParsedTableData
} from '@/utils/corporate-tables';
import FlowModelFillUtils from '../utils/flow-model-fill.utils';
import Utils from '@/utils/utils';

@Component({
  components: {
    'fleet-data-header': FleetDataHeader,
    'fleet-search-policyholder': FleetSearchPolicyHolderBusiness,
    'fleet-general-info': FleetGeneralInfoBusiness,
    'risk-management': FleetsRiskManagementBusiness
  }
})

/**
 * FleetData view
 *
 */
export default class GeneralDataView extends mixins<EAView<FleetsModel>>(EAView) {

  showFleetGeneralInfo = false;

  disableNextButton = true;
  
  addressTypeList: ParsedTableData[] = [];

  /**
   * Go to the next view
   */
  get fleetCommitContractPolicyTypes(): LabelValueType[] {
    return this.model.flowConfiguration.generalDataView?.fleetCommitContractPolicyTypes || [];
  }

  /**
   * Go to the next view
   */
  get showPolicyholderInfoDocumentTypes(): LabelValueType[] {
    return this.model.flowConfiguration.generalDataView?.showPolicyholderInfoDocumentTypes || [];
  }

  /**
   * Go to the next view
   */
  get fleetSearchPolicyholderJuridicalPersonDocumentType(): string[] {
    return this.model.flowConfiguration.generalDataView?.fleetSearchPolicyholderJuridicalPersonDocumentType || [];
  }
  
  /**
   * Go to the next view
   */
  get fleetSearchPolicyholderSecondCatVehiclesList(): string[] {
    return this.model.flowConfiguration.generalDataView?.fleetSearchPolicyholderSecondCatVehiclesList || [];
  }

  /**
   * Getter vehicles number rage validation
   */
  get vehiclesWarningRange(): RangeRestriction {
    return this.model.flowConfiguration.generalDataView?.fleetGeneralInfoRestrictions.vehiclesNumber.range || {};
  }
  
  /**
   * Getter annual insurance premium range validation
   */
  get annualInsurancePremiumRange(): RangeRestriction {
    return this.model.flowConfiguration.generalDataView?.
      fleetGeneralInfoRestrictions.annualInsurancePremium.range || {};
  }
  
  /**
   * Getter on employee max risk appetite
   */
  get employeeMaxRiskAppetite(): number {
    return this.model.flowConfiguration.generalDataView.
      fleetGeneralInfoRestrictions.allawedEmployeeRiskAppetite.range.max || 3;
  }

  /**
   * Getter on intermediary max risk appetite
   */
  get intermediaryMaxRiskAppetite(): number {
    return this.model.flowConfiguration.generalDataView.
      fleetGeneralInfoRestrictions.allawedIntermediaryRiskAppetite.range.max || 2;
  }

  /**
   * Checks if the operation type is readonly
   */
  get readOnlyOperation() {
    return this.model.operationType === OperationTypeEnum.MANTENIMIENTO ||
    this.model.operationType === OperationTypeEnum.CONSULTA;
  }

  /**
   * Hook created
   * 
   */
  @EAMethod()
  async created() {
    const promises = [fetchCorporateTable(CorpTableNames.AddressType)];
    const results = await Promise.all(promises);

    if (this.readOnlyOperation) {
      this.disableNextButton = !this.readOnlyOperation;
      await FlowModelFillUtils.loadFleetData(this.model, true);
      this.showFleetGeneralInfo = true;
    }
    this.addressTypeList = parseCorpTableDocuments(results[0]);
  }

  /**
   * Emit selectedValues
   * @param {boolean} commiContract
   */
  onUserWithCommitContrat(commiContract: boolean) {
    this.showFleetGeneralInfo = commiContract;
  }

  /**
   * When event is emitted onNewSearch disable next btn
   */
  onDisableNextButton() {
    this.disableNextButton = true;
  }

  /**
   * Emit when search policyholder
   * @param {boolean} validRiskApetite
   */
  onRiskApetitEval(validRiskApetite: boolean) {
    this.disableNextButton = !validRiskApetite;
  }

  /**
   * Go to the next step
   */
  @EAMethod({
    loading: true
  })
  async nextStep() {
    // Reset warning messages
    NotificationsUtils.clearNotifications();
    EAErrorManager.clearError();
    // Validación siguientes bloques
    try {
      await this.validateRequiredValuesAndUpdateModel();
    } catch (error) {
      NotificationsUtils.throwWarning(error as string);
      return;
    }
    if (this.comprobeLogicalBusiness()) {
      if (!this.readOnlyOperation) {
        await this.recordFleet();
        this.update();
      }
  
      NotificationsUtils.clearNotifications();
      this.$emit('changeStep',
        FlowViewsStepsModel.VehiclesDataStep,
        FlowHeaderStepsModel.VehiclesDataStep
      );
    }
  }


  /**
   * Validate components and update model
   */
  async validateRequiredValuesAndUpdateModel() {
    // Primer bloque: buscador tomador
    const fleetSearchPolicyHolderBC: FleetSearchPolicyHolderBusiness =
        this.$refs.refFleetSearchPolicyholderBC as FleetSearchPolicyHolderBusiness;
    // Segundo bloque: "Info general de la flota"
    const fleetGeneralInfoBC: FleetGeneralInfoBusiness =
        this.$refs.refFleetGeneralInfoBC as FleetGeneralInfoBusiness;
    // Tercer bloque: gerencia de riesgos
    const riskManagementBC: FleetsRiskManagementBusiness =
        this.$refs.riskManagementBC as FleetsRiskManagementBusiness;
    // Validations
    let validationForms;

    if (this.readOnlyOperation) {
      validationForms = new EACompositeValidation([
        fleetGeneralInfoBC.validation(),
        riskManagementBC.validation(),
      ],
      this.$tc.bind(this));
    } else {
      validationForms = new EACompositeValidation([
        fleetGeneralInfoBC.validation(),
        riskManagementBC.validation(),
        await fleetSearchPolicyHolderBC.validate()
      ],
      this.$tc.bind(this));
    }
    
    await validationForms.validate();
     
    // Update model => si NO lanza la línea anterior un throw, actualizamos el modelo
    fleetSearchPolicyHolderBC.update();
    fleetGeneralInfoBC.update();
    riskManagementBC.update();
    this.update();
  }

  /**
   * Logical business control
   * @returns {boolean}
   */
  comprobeLogicalBusiness(): boolean {
    let canContinue = true;
    const vehiclesNumber = this.model.fleetGeneralInfoModel.formValues.vehiclesNumber || 0;
    const annualInsurancePremium = this.model.fleetGeneralInfoModel.formValues.annualInsurancePremium || 0;
    const rentingVehicle = this.model.fleetGeneralInfoModel.formValues.rentingVehicle;
    if (vehiclesNumber < this.vehiclesWarningRange.min || vehiclesNumber > this.vehiclesWarningRange.max) {
      canContinue = false;
      
      const vehiclesWarninMessage = this.$t(`fleets.fleetsFlow.fleet-general-info.vehiclesNumber.warning`, {
        startValue: this.vehiclesWarningRange.min,
        endValue: this.vehiclesWarningRange.max
      }).toString();
      NotificationsUtils.throwWarning(vehiclesWarninMessage);
    }
    if (annualInsurancePremium > this.annualInsurancePremiumRange.max) {
      canContinue = false;
      const annualInsurancePremiumRange = {
        value: Utils.thousandsDotFormatter(this.annualInsurancePremiumRange.max.toString())
      };
      
      const annualPremiumWarningMsg = this.$t(`fleets.fleetsFlow.fleet-general-info.annualInsurancePremium.warning`,
        annualInsurancePremiumRange).toString();
       
      NotificationsUtils.throwWarning(annualPremiumWarningMsg);
    }
    if (rentingVehicle === true) {
      canContinue = false;
      const message = this.$t(`fleets.fleetsFlow.fleet-general-info.rentingVehicle.warning`).toString();
      NotificationsUtils.throwWarning(message);
    }

    if (canContinue === false) {
      const message = this.$t('fleets.fleetsFlow.generalDataView.logicalBusinessWarning').toString();
      NotificationsUtils.throwWarning(message);
      return false;
    }
    return true;
  }

  /**
   * Record fleet data information
   */
  async recordFleet() {
    const selectedPerson = this.model.fleetSearchPolicyHolderModel.selectedJuridicalPerson;
    const businessModel = this.model.qbNewOfferBusinessModel;
    const generalInfoValues = this.model.fleetGeneralInfoModel.formValues;
    const riskManagementInfoValues = this.model.fleetsRiskManagementModel.formValues;
    // TODO función para grabar
    const saveOfferRequest: SaveOfferRequest = {
      datosGenerales: {
        codigoPoliza: this.model.offerNumber,
        versionPoliza: this.model.offerVersion,
        tomador: {
          tipoDocumento: selectedPerson.tipoDocumento as unknown as DocumentTypes,
          codigoIdentificacionFiscal: selectedPerson.codigoIdentificacionFiscal,
          razonSocialMatriz: selectedPerson.razonSocialMatriz,
          tipoViaDireccionLarga: selectedPerson.tipoViaDireccionLarga,
          nombreDireccionTomador: selectedPerson.nombreDireccionTomador,
          numeroDireccionTomador: selectedPerson.numeroDireccionTomador,
          informacionAdicional: selectedPerson.informacionAdicional,
          codigoPostal: selectedPerson.codigoPostal,
          nombrePoblacionTomador: selectedPerson.nombrePoblacionTomador,
          nombreProvinciaTomador: selectedPerson.nombreProvinciaTomador
        },
        codigoIntermediario: businessModel.intermediaryCode,
        nombreIntermediario: businessModel.intermediaryName,
        // CodigoEstructura: businessModel.str
        codigoTecnicoProducto: businessModel.productTechnicalCode,
        codigoComercialProducto: businessModel.productComercialCode,
        nombreProducto: businessModel.productName,
        indicadorCompromisoContratacion: this.model.fleetSearchPolicyHolderModel.indicadorCompromisoContrato,
        compromisos: this.model.fleetSearchPolicyHolderModel.fleetCommitContract.formValues.selectedValues,
        numeroDeVehiculos: generalInfoValues.vehiclesNumber,
        primaNetaEstimada: generalInfoValues.annualInsurancePremium,
        vehiculosRenting: generalInfoValues.rentingVehicle,
        // eslint-disable-next-line max-len
        vehiculoSegundaCategoria: generalInfoValues.secondCategoryVehicleScope,
        conductorInexperto: generalInfoValues.underageConductor,
        sistemaConduccionAvanzado: riskManagementInfoValues.majorVehiclesHasAdvancedDriverTechnology,
        conductorReeducacion: riskManagementInfoValues.anyDriversCurrentlyDoingEfficiencyCourses,
        controlGerenciaRiesgo: riskManagementInfoValues.securityControlsRiskManagement,
      }
    };

    const api = new EASaveOfferApi();
    const saveOfferResponse = await api.saveOfferOperation({
      saveOfferRequest
    });
    throwIfResponseHasErrors(saveOfferResponse as ResponseWithErrors);
    this.model.offerNumber = saveOfferResponse?.codigoPoliza as string;
    this.model.offerVersion = saveOfferResponse?.versionPoliza as number;
  }


  /**
   * Handles back button
   */
  onGoBack() {
    this.$emit('goToPreviousFlow');
  }
}
</script>
