import {
  GetPersonAddressesResponseDomicilios
} from '@/services/V1/persons/getPersonAddressesOperation/post';
import AddEmailTomadorModel from '../../modals/add-email-tomador/add-email-tomador-model';

/**
 * Model add-edit-email
 */
class AddEditEmailModel {
  public selectedEmail?: GetPersonAddressesResponseDomicilios = undefined; // Initialised to make it reactive

  public selectedEmailCode?: string = undefined; // Initialised to make it reactive

  public emailList: GetPersonAddressesResponseDomicilios[] = [];

  public addEmailTomadorModel: AddEmailTomadorModel = new AddEmailTomadorModel();
}

export default AddEditEmailModel;
