<template>
  <div>
    <table class="qb-table" aria-describedby="table for general data extra information">
      <tr class="qb-table__header">
        <th v-for="header in headers" :key="header">{{ header }}</th>
      </tr>
      <tr v-for="elem  in bodyTable" :key="elem.key">
        <td class="qb-table__body-td">{{ elem.key }}</td>
        <td class="qb-table__body-td">{{ elem.value }}</td>
      </tr>
    </table>
  </div>
</template>

<script lang="ts">
import {
  Component, Prop
} from 'vue-property-decorator';

import Vue from 'vue';
import {
  BodyTable
} from '../qb-general-data-information-model';

@Component({
  name: 'qb-table',
})

/**
 * Simple component to show a table without  logic
 */
export default class SimpleTableComponent extends Vue {
  @Prop({
    required: true
  })
    headers?: string [];

  @Prop({
    required: true,
  })
    bodyTable?: BodyTable[];

}
</script>
