<template>
  <div>
    <ea-card class="m-t-16">
      <div slot="cardbody" class="p-a-24">
        <ea-heading level="4" class="m-b-16">
          {{ $t(`quoteBuyGenericFlow.offerIssuanceData.additionalData.title`) }}
        </ea-heading>
        <ea-form
          ref="form"
          :model="model"
        >
          <ea-row v-for="data in model.additionalData" :key="data.codigoElemento">
            <ea-col
              :span="6"
              v-if="data.codigoElemento && isElementPrintable([data.codigoElemento], datosObjeto, consultaOperation)">
              <ea-form-item
                :label="$t(`quoteBuyGenericFlow.offerIssuanceData.additionalData.${data.codigoElemento}.label`)"
                :prop="data.codigoElemento"
                class="false-required mw-4"
              >
                <ea-input-text
                  v-if="data.tipoElemento === elementType.ALPHANUMERIC"
                  v-model="data.valorElemento"
                  :maxlength="data.longitudElemento"
                  @change="onCessionRightsChange()"
                  :readonly="consultaOperation"
                />

                <ea-input-number
                  v-if="data.tipoElemento === elementType.NUMERIC"
                  v-model="data.valorElemento"
                  :max="data.valorMaximoElemento"
                  :min="data.valorMinimoElemento"
                  @change="onCessionRightsChange()"
                  :readonly="consultaOperation"
                />
              </ea-form-item>
            </ea-col>
          </ea-row>
        </ea-form>
      </div>
    </ea-card>
  </div>
</template>

<script lang="ts">
import {
  Component, Prop
} from 'vue-property-decorator';

import {
  mixins
} from 'vue-class-component';

import {
  EABusinessComponent
} from '@zurich-es-npm/ea-front-web-core';

import QbAdditionalDataModel from './qb-offer-issuance-additional-data-model';
import {
  GetGeneralDataResponseDataDatosObjeto,
  GetGeneralDataResponseDataDatosObjetoTipoElementoEnum
} from '@/services/V1/quoteAndBuy/getGeneralDataOperation/post';
import {
  isElementPrintable
} from '@/utils/object-utils';


@Component({
  name: 'qb-additional-data'
})

/**
 * Business Component qb-additional-data
 */
export default class QbOfferIssuanceAdditionalDataBusiness extends mixins<EABusinessComponent<QbAdditionalDataModel>>(
  EABusinessComponent
) {

  @Prop()
    consultaOperation?: boolean;

  @Prop()
    cessionRights?: GetGeneralDataResponseDataDatosObjeto;

  @Prop()
    loanNumber?: GetGeneralDataResponseDataDatosObjeto;

  public elementType = GetGeneralDataResponseDataDatosObjetoTipoElementoEnum;

  isElementPrintable = isElementPrintable;

  /**
   * On cession rights change
   */
  onCessionRightsChange() {
    this.$nextTick(() => {
      this.update();
    });
  }

  /**
   * Getter for datosObjeto parameter in isElementPrintable function
   */
  get datosObjeto(): GetGeneralDataResponseDataDatosObjeto[] {
    const objectData: GetGeneralDataResponseDataDatosObjeto[] = [];

    if (this.cessionRights) {
      objectData.push(this.cessionRights);
    }

    if (this.loanNumber) {
      objectData.push(this.loanNumber);
    }

    return objectData;
  }
}
</script>
