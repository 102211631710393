var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.restDocumentsList && _vm.restDocumentsList.length > 0)?_c('div',[_c('div',[_c('ea-heading',{staticClass:"d-display-inline",attrs:{"level":"5"}},[_vm._v(" "+_vm._s(_vm.$t('quoteBuyGenericFlow.titleDocumentation'))+" ")]),_c('ea-tooltip',{staticClass:"d-display-inline",attrs:{"placement":"top"}},[_c('ea-icon',{staticClass:"m-l-8",attrs:{"icon":"z-info"}}),_c('div',{attrs:{"slot":"content"},slot:"content"},[_vm._v(" "+_vm._s(_vm.$t('quoteBuyGenericFlow.generateDocumentation.tooltip.documentationEmit'))+" ")])],1)],1),_c('ea-form',{attrs:{"model":_vm.model}},[_c('div',{staticClass:"d-display-flex d-direction-column"},_vm._l((_vm.restDocumentsList),function(document,index){return _c('div',{key:index},[(document.nombreDocumento &&
            _vm.model.postalDigitalSelections &&
            _vm.model.postalDigitalSelections[document.nombreDocumento] &&
            document.indicadorEnvioPostal)?_c('ea-form-item',{staticClass:"radiogroup-horizontal m-b-0",attrs:{"prop":"radioBtnSelectedDocument","showLabel":false}},[_c('ea-radio-group',{attrs:{"direction":"vertical"},on:{"change":function($event){return _vm.onDocumentSelectionChange(document)}},model:{value:(_vm.model.postalDigitalSelections[document.nombreDocumento]),callback:function ($$v) {_vm.$set(_vm.model.postalDigitalSelections, document.nombreDocumento, $$v)},expression:"model.postalDigitalSelections[document.nombreDocumento]"}},[_c('ea-radio',{attrs:{"label":"Digital"}},[_vm._v(" "+_vm._s(document.nombreDocumento + ' ' + _vm.$t('quoteBuyGenericFlow.documentation.documentDigitalInsurance'))+" "),_c('ea-link',{staticClass:"m-l-16",on:{"click":function($event){return _vm.showDocumentPreview(document)}}},[_vm._v(" "+_vm._s(_vm.$t('common.link.preview'))+" ")])],1),_c('ea-radio',{attrs:{"label":"Postal"}},[_vm._v(" "+_vm._s(document.nombreDocumento + ' ' + _vm.$t('quoteBuyGenericFlow.documentation.documentPostalInsurance'))+" ")])],1)],1):_vm._e(),(document.nombreDocumento && (
            !_vm.model.postalDigitalSelections ||
            !_vm.model.postalDigitalSelections[document.nombreDocumento]
          ))?_c('div',{staticClass:"p-t-16",class:document.indicadorEnvioPostal ? 'p-b-16' : 'p-b-14'},[_c('span',{staticClass:"t-size-small"},[_vm._v(" "+_vm._s(document.nombreDocumento)+" ")]),(!_vm.isDocumentInNoPreviewList(document))?_c('ea-link',{staticClass:"m-l-16",on:{"click":function($event){return _vm.showDocumentPreview(document)}}},[_vm._v(" "+_vm._s(_vm.$t('common.link.preview'))+" ")]):_vm._e()],1):_vm._e(),(!document.indicadorEnvioPostal &&
            document.nombreDocumento &&
            _vm.model.postalDigitalSelections[document.nombreDocumento])?_c('div',[_c('p',{staticClass:"t-size-small"},[_vm._v(" "+_vm._s(document.nombreDocumento + ' ' + _vm.$t('quoteBuyGenericFlow.documentation.documentDigitalInsurance'))+" ")])]):_vm._e()],1)}),0)]),_c('ea-dialog',{attrs:{"title":_vm.$t('quoteBuyGenericFlow.issueOffer.postalSendingConfirmation.title'),"visible":_vm.saveEnvironmentVisible,"size":"small","type":"passive"},on:{"update:visible":function($event){_vm.saveEnvironmentVisible=$event},"close":function($event){_vm.saveEnvironmentVisible = false}}},[_c('p',[_vm._v(_vm._s(_vm.$t('quoteBuyGenericFlow.issueOffer.postalSendingConfirmation.description')))]),_c('div',{staticClass:"d-display-flex d-justify-flex-end",attrs:{"slot":"footer"},slot:"footer"},[_c('ea-button',{staticClass:"m-r-40",attrs:{"type":"secondary"},on:{"click":function($event){_vm.saveEnvironmentVisible = false}}},[_vm._v(" "+_vm._s(_vm.$t('common.label.no'))+" ")]),_c('ea-button',{attrs:{"type":"primary"},on:{"click":_vm.saveEnvironment}},[_vm._v(" "+_vm._s(_vm.$t('common.label.yes'))+" ")])],1)])],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }