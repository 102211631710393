var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('ea-card',{staticClass:"t-bg-color-primary-10 p-a-16 m-b-32"},[_c('div',{staticClass:"d-display-flex",attrs:{"slot":"cardbody"},slot:"cardbody"},[_vm._t("default"),_vm._v(" "),_c('div',{staticClass:"d-display-flex d-direction-column w-75 d-justify-space-between"},[_c('div',{staticClass:"d-display-flex d-justify-center m-t-24"},[(_vm.showWarrantiesGroups &&
          _vm.warrantiesGroupsDisplayMode === _vm.EXPOSE_WARRANTIES_HEADER_DISPLAY_MODE.INLINE &&
          !_vm.consultaOperation)?_c('warranties-header-inline-selection',{attrs:{"id":"warrantiesGroupInlineSelection","optionsToSelect":_vm.warrantiesGroups,"canSelectMultipleOptions":_vm.canSelectMultipleWarrantiesGroups},on:{"selectedOptionChange":_vm.onSelectedWarrantiesGroupsChange},model:{value:(_vm.model.warrantiesGroupsInlineSelectionModel),callback:function ($$v) {_vm.$set(_vm.model, "warrantiesGroupsInlineSelectionModel", $$v)},expression:"model.warrantiesGroupsInlineSelectionModel"}}):_vm._e(),(_vm.showPackagesGroups &&
          _vm.warrantiesGroupsDisplayMode === _vm.EXPOSE_WARRANTIES_HEADER_DISPLAY_MODE.INLINE &&
          !_vm.consultaOperation)?_c('warranties-header-inline-selection',{attrs:{"id":"packagesGroupsInlineSelection","optionsToSelect":_vm.packagesGroups,"canSelectMultipleOptions":_vm.canSelectMultiplePackagesGroups},on:{"selectedOptionChange":_vm.onSelectedPackagesGroupsChange},model:{value:(_vm.model.packagesGroupsInlineSelectionModel),callback:function ($$v) {_vm.$set(_vm.model, "packagesGroupsInlineSelectionModel", $$v)},expression:"model.packagesGroupsInlineSelectionModel"}}):_vm._e(),(_vm.showWarrantiesGroups &&
          _vm.warrantiesGroupsDisplayMode === _vm.EXPOSE_WARRANTIES_HEADER_DISPLAY_MODE.GRID &&
          !_vm.consultaOperation)?_c('warranties-header-grid-selection',{attrs:{"id":"warrantiesGroupGridSelection","optionsToSelect":_vm.warrantiesGroups,"canSelectMultipleOptions":_vm.canSelectMultipleWarrantiesGroups},on:{"selectedOptionChange":_vm.onSelectedWarrantiesGroupsChange},model:{value:(_vm.model.warrantiesGroupsGridSelectionModel),callback:function ($$v) {_vm.$set(_vm.model, "warrantiesGroupsGridSelectionModel", $$v)},expression:"model.warrantiesGroupsGridSelectionModel"}}):_vm._e(),(_vm.showPackagesGroups &&
          _vm.warrantiesGroupsDisplayMode === _vm.EXPOSE_WARRANTIES_HEADER_DISPLAY_MODE.GRID &&
          !_vm.consultaOperation)?_c('warranties-header-grid-selection',{attrs:{"id":"packagesGroupsGridSelection","optionsToSelect":_vm.packagesGroups,"canSelectMultipleOptions":_vm.canSelectMultiplePackagesGroups},on:{"selectedOptionChange":_vm.onSelectedPackagesGroupsChange},model:{value:(_vm.model.packagesGroupsGridSelectionModel),callback:function ($$v) {_vm.$set(_vm.model, "packagesGroupsGridSelectionModel", $$v)},expression:"model.packagesGroupsGridSelectionModel"}}):_vm._e()],1),(_vm.showTarificationButton)?_c('div',{staticClass:"d-display-flex d-justify-flex-end d-align-items-center"},[_c('ea-tooltip',{staticClass:"d-display-inline",attrs:{"placement":"top"}},[_c('ea-icon',{attrs:{"icon":"z-info","status":"warning","size":"large"}}),_c('div',{attrs:{"slot":"content"},slot:"content"},[_vm._v(" "+_vm._s(_vm.$t('warranties.header.tooltips.tarification.first'))),_c('br'),_vm._v(" "+_vm._s(_vm.$t('warranties.header.tooltips.tarification.second'))),_c('br'),_vm._v(" "+_vm._s(_vm.$t('warranties.header.tooltips.tarification.third'))+" ")])],1),_c('ea-button',{staticClass:"m-l-8",attrs:{"type":"warning","size":"medium"},on:{"click":_vm.callTarification}},[_vm._v(" "+_vm._s(_vm.$t('warranties.pricing'))+" ")])],1):_vm._e()])],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }