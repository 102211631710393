import QbBankDetailsModel from '../qb-bank-details/qb-bank-details-model';
import QbRiskSituationModel from '../qb-risk-situation/qb-risk-situation-model';

export interface LabelValue {
  label?: string;
  value?: string;
}

export interface MinMaxValue {
  valorMinimoElemento?: number;
  valorMaximoElemento?: number;
}

/**
 * Model qb-producto-asegurado-hogar
 */
class QbProductoAseguradoHogarModel {
  public FIELD_TYPES = {
    NUMBER: 'number',
    STRING: 'string',
    DEFAULT: 'default'
  };

  // Property Data Form

  public country?: string = '';

  public roadType?: string = '';

  public roadName?: string = '';

  public roadNumber?: number = 0;

  public blockStair?: string = '';

  public floor?: string = '';

  public stairwell?: string = '';

  public doorNumber?: string = '';

  public extraInfo?: string = '';

  public riskSituation: QbRiskSituationModel = new QbRiskSituationModel();

  public bankDetails: QbBankDetailsModel = new QbBankDetailsModel();
  
  // Remodeling Years Form
  
  public constructionYear?: number = 0;

  public remodelingYear?: number = 0;

  // General Features

  public buildingType?: string = '';
  
  public buildingLocation?: string = '';
  
  public ownerType?: string = '';

  public buildingUse?: string = '';
  
  public ownerOrRenter?: string = '';

  public hasTuristicUse?: boolean = false;

  // Construction Materials

  public quality?: string = '';

  // Surface Form

  public propertySize?: number = 0;

  public exteriorSize?: number = 0;

  public propertyTotalSize?: number = 0;

  public franchise?: string = '';

  // Complementary facilities

  public hasProtectionDoor?: boolean = false;

  public hasProtectionGrate?: boolean = false;

  public hasProtectionAlarm?: boolean = false;

  public protectionSafe?: string = '';

  public protectionDoor?: string = '';

  public protectionGrate?: string = '';

  //

  public warranty?: string = '';
  
  public warrantyGroupListIsModificable?: boolean = true;

  public warrantyGroupList?: LabelValue[] = [];

  public bffCodesFrontRelation = [
    {
      bffCode: 'DUCONTRA',
      frontCode: 'quoteBuyGenericFlow.newOffer.duration'
    },
    {
      bffCode: 'TCINFISC',
      frontCode: 'quoteBuyGenericFlow.searchPerson.field.taxTreatmentArea'
    },
    {
      bffCode: 'TCCANCO1',
      frontCode: 'quoteBuyGenericFlow.newOffer.paymentChannel'
    },
    {
      bffCode: 'FEEFTOMO',
      frontCode: 'quoteBuyGenericFlow.newOffer.efectMovementDate'
    },
    {
      bffCode: 'FEEFTONA',
      frontCode: 'quoteBuyGenericFlow.newOffer.efectMovementDate'
    },
    {
      bffCode: 'FEINIPOL',
      frontCode: 'quoteBuyGenericFlow.newOffer.efectMovementDate'
    },
    {
      bffCode: 'FEFINEFE',
      frontCode: 'quoteBuyGenericFlow.newOffer.movementDue'
    },
    {
      bffCode: 'FEVENCIM',
      frontCode: 'quoteBuyGenericFlow.newOffer.movementDue'
    },
    {
      bffCode: 'INIDIOMA',
      frontCode: 'quoteBuyGenericFlow.newOffer.documentationLanguage'
    },
    {
      bffCode: 'CDPAIS',
      frontCode: 'quoteBuyGenericFlow.propertyData.field.country'
    },
    {
      bffCode: 'TCCOPOST',
      frontCode: 'quoteBuyGenericFlow.searchPerson.field.zipCode'
    },
    {
      bffCode: 'NOPOBLSR',
      frontCode: 'quoteBuyGenericFlow.propertyData.field.city'
    },
    {
      bffCode: 'CDPOBLA',
      frontCode: 'quoteBuyGenericFlow.propertyData.field.city'
    },
    {
      bffCode: 'TCPROVIN',
      frontCode: 'quoteBuyGenericFlow.propertyData.field.provinceName'
    },
    {
      bffCode: 'DSPROVIN',
      frontCode: 'quoteBuyGenericFlow.propertyData.field.provinceName'
    },
    {
      bffCode: 'CDDIRESR',
      frontCode: 'quoteBuyGenericFlow.propertyData.field.roadType'
    },
    {
      bffCode: 'NODIRESR',
      frontCode: 'quoteBuyGenericFlow.propertyData.field.roadName'
    },
    {
      bffCode: 'NUDIRESR',
      frontCode: 'quoteBuyGenericFlow.propertyData.field.roadNumber'
    },
    {
      bffCode: 'CDBLOQSR',
      frontCode: 'quoteBuyGenericFlow.propertyData.field.blockStair'
    },
    {
      bffCode: 'CDPLANSR',
      frontCode: 'quoteBuyGenericFlow.propertyData.field.floor'
    },
    {
      bffCode: 'CDESCASR',
      frontCode: 'quoteBuyGenericFlow.propertyData.field.stairwell'
    },
    {
      bffCode: 'CDPUERSR',
      frontCode: 'quoteBuyGenericFlow.propertyData.field.doorNumber'
    },
    {
      bffCode: 'VAACCDSR',
      frontCode: 'quoteBuyGenericFlow.propertyData.field.extraInfo'
    },
    {
      bffCode: 'TCCLAVIV',
      frontCode: 'quoteBuyGenericFlow.propertyData.field.buildingType'
    },
    {
      bffCode: 'TCUBIVIV',
      frontCode: 'quoteBuyGenericFlow.propertyData.field.buildingLocation'
    },
    {
      bffCode: 'AACONSTR',
      frontCode: 'quoteBuyGenericFlow.generalCharacteristics.field.constructionYear'
    },
    {
      bffCode: 'AAREFORM',
      frontCode: 'quoteBuyGenericFlow.generalCharacteristics.field.remodelingYearHogarTitle'
    },
    {
      bffCode: 'TCCARASE',
      frontCode: 'quoteBuyGenericFlow.generalCharacteristics.field.ownerOrRenter'
    },
    {
      bffCode: 'INUSOVIV',
      frontCode: 'quoteBuyGenericFlow.generalCharacteristics.field.buildingUse'
    },
    {
      bffCode: 'INUSOSAT',
      frontCode: 'quoteBuyGenericFlow.generalCharacteristics.field.hasTuristicUse'
    },
    {
      bffCode: 'CDCALIVV',
      frontCode: 'quoteBuyGenericFlow.constructionMaterials.field.quality'
    },
    {
      bffCode: 'NUSUPVIV',
      frontCode: 'quoteBuyGenericFlow.surface.field.placeSize'
    },
    {
      bffCode: 'NUSUPJAR',
      frontCode: 'quoteBuyGenericFlow.surface.field.exteriorSize'
    },
    {
      bffCode: 'NUSUPTOT',
      frontCode: 'quoteBuyGenericFlow.surface.field.propertyTotalSize'
    },
    {
      bffCode: 'CDFRANQA',
      frontCode: 'quoteBuyGenericFlow.technicalConsiderationsBusiness.field.franchise'
    },
    {
      bffCode: 'INPUESEG',
      frontCode: 'quoteBuyGenericFlow.securityMeasures.field.extraProtectionDoor'
    },
    {
      bffCode: 'INREJASH',
      frontCode: 'quoteBuyGenericFlow.securityMeasures.field.extraProtectionGrate'
    },
    {
      bffCode: 'INALACON',
      frontCode: 'quoteBuyGenericFlow.securityMeasures.field.extraProtectionAlarm'
    },
    {
      bffCode: 'INCAJAFU',
      frontCode: 'quoteBuyGenericFlow.securityMeasures.field.extraProtectionSafe'
    },
    {
      bffCode: 'CDPUSEGU',
      frontCode: 'quoteBuyGenericFlow.securityMeasures.field.extraProtectionDoor'
    },
    {
      bffCode: 'CDREJASH',
      frontCode: 'quoteBuyGenericFlow.securityMeasures.field.extraProtectionGrate'
    },
    {
      bffCode: 'INDGGHOG',
      frontCode: 'warranties.shared.warrentiesGroup'
    }
  ];
}

export default QbProductoAseguradoHogarModel;
