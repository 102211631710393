import QbProductoAseguradoTrConstruccionMontajeModel
  // eslint-disable-next-line max-len
  from '@/business-components/qb-producto-asegurado-tr-construccion-montaje/qb-producto-asegurado-tr-construccion-montaje-model';
import {
  PolicyModel
} from '@/flows/policy/policy-model';

/**
 * TodoRiesgoConstruccionMontajeModel model
 *
 * @export
 * @class TodoRiesgoConstruccionMontajeModel
 * @extends {QuoteBuyModel}
 */
export default class TodoRiesgoConstruccionMontajePolicyModel extends PolicyModel {
  
  public productModel = new QbProductoAseguradoTrConstruccionMontajeModel();

}
