export interface OfferIssuanceDataModel {
  label: string;
  value: string;
}

/** 
 * Model qb-offer-issuance-data
 */
class QbOfferIssuanceDataModel {

  public productTranslationLabel: string = '';

  public offerIssuanceData: OfferIssuanceDataModel[] = [];

}

export default QbOfferIssuanceDataModel;
