/** 
 * Model multiple-email-input
 */
class MultipleEmailInputModel {

  public emailList: string[] = [''];

}

export default MultipleEmailInputModel;
