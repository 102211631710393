import {
  EAIntegrationConfig, EAEndpointConfig, EASDKCallbackConfig, EASDKCallback, EAApplicationError
} from '@zurich-es-npm/ea-front-web-core';

/**
 * SDK Config for security services
 */
export class EACSRFSDKConfig {

  /**
   * Set endpoint config and interceptors for each security service
   * 
   * @param {EAIntegrationConfig | undefined} integration
   */
  public static configure(integration: EAIntegrationConfig | undefined): void {

    if (integration) {

      // Configure CSRF service
      const retrieveCSRFTokenEndpoint: EAEndpointConfig | undefined = integration.retrieveCSRFToken;
      if (retrieveCSRFTokenEndpoint) {
        const securityConfig: EASDKCallbackConfig = {
          endpoint: retrieveCSRFTokenEndpoint,
          requestInterceptors: [
            'requestTimeTracesInterceptor',
            'requestHeaderInterceptor',
            'requestNoCacheHeadersInterceptor'
          ],
          responseInterceptors: [
            'responseTimeTracesInterceptor'
          ],
          errorInterceptor: 'errorInterceptor'
        };
        EASDKCallback.addConfiguration('retrieveCSRFToken', securityConfig);
      } else {
        throw new EAApplicationError(
          'ARC00012',
          ['Missing retrieveCSRFToken configuration']
        );
      }

      // Configure session (logout and touch) services
      const removeSessionOperation: EAEndpointConfig | undefined = integration.removeSessionOperation;
      if (removeSessionOperation) {
        const securityConfig: EASDKCallbackConfig = {
          endpoint: removeSessionOperation,
          requestInterceptors: [
            'requestTimeTracesInterceptor',
            'requestHeaderInterceptor'
          ],
          responseInterceptors: [
            'responseTimeTracesInterceptor'
          ],
          errorInterceptor: 'errorInterceptor'
        };
        EASDKCallback.addConfiguration('removeSessionOperation', securityConfig);
      } else {
        throw new EAApplicationError(
          'ARC00012',
          ['Missing removeSessionOperation configuration']
        );
      }

      // Configure session (logout and touch) services
      const touchSessionOperation: EAEndpointConfig | undefined = integration.touchSessionOperation;
      if (touchSessionOperation) {
        const securityConfig: EASDKCallbackConfig = {
          endpoint: touchSessionOperation,
          requestInterceptors: [
            'requestTimeTracesInterceptor',
            'requestSecurityInterceptor',
            'requestHeaderInterceptor'
          ],
          responseInterceptors: [
            'responseTimeTracesInterceptor'
          ],
          errorInterceptor: 'errorInterceptor'
        };
        EASDKCallback.addConfiguration('touchSessionOperation', securityConfig);
      } else {
        throw new EAApplicationError(
          'ARC00012',
          ['Missing touchSessionOperation configuration']
        );
      }
      
    } else {
      throw new EAApplicationError(
        'ARC00012',
        ['Missing configuration']
      );
    }

  }

}
