<template>
  <ea-dialog
    top="10vh"
    :visible="showModal"
    @close="closeModal"
    :closeOnClickModal="false"
    size="large"
    :title="$t('layout.searchModal.title')"
    height="70vh"
  >
    <ea-row
      class="m-t-16"
      extraClass="m-r-0">
      <ea-col :sm="24" :md="16" :lg="18" :xl="18">
          <label class="t-weight-semibold">{{$t('layout.searchModal.searchLabel')}}</label>
          <ea-input-text
            class="search-input"
            :id="inputId"
            :placeholder="$t('layout.searchModal.searchPlaceholder')"
            v-model="searchValue"
            @input="searchValueHandler($event)"
          />
      </ea-col>
      <ea-col class="m-t-16 m-t-sm-32" :sm="24" :md="6" :lg="6" :xl="6">
        <ea-button
          type="primary"
          :disabled="!searchValue || searchValue.trim().length < 3"
          @click="searchIntoMenu">{{$t('layout.searchModal.searchButton')}}</ea-button>
      </ea-col>
    </ea-row>
    <ea-row
      v-if="hasBeenSearched"
      extraClass="m-r-0">
      <ea-col :sm="24" :md="24" :lg="24" :xl="24">
        <h2>{{$t('layout.searchModal.searchResultText')}} "{{lastSearchValue}}"</h2>
      </ea-col>
      <template v-if="foundResults.length">
        <ea-col :sm="24" :md="24" :lg="24" :xl="24"
          class="m-b-8"
          v-for="(operation, index) in foundResults"
          :key="index">
          <ea-link
            @click="navigateToOperation(operation)">
            {{ operation.name }}
            </ea-link>
        </ea-col>
      </template>
      <template v-else>
        <ea-col :sm="24" :md="24" :lg="24" :xl="24">
          {{$t('layout.searchModal.noResultsText')}}
        </ea-col>
      </template>
    </ea-row>
    <div slot="footer" class="d-display-flex d-justify-flex-end">
      <ea-button type="secondary"
        @click="closeModal">
          {{$t('layout.searchModal.exitButtonText')}}
      </ea-button>
    </div>
  </ea-dialog>
</template>

<script lang="ts">
import {
  EAMenuStructure, EAMenuStructureItem
} from '@/layout/menu-structure-types';
import TextUtils from '@/utils/text-utils';
import {
  EAFlowNavigationUtils
} from '@zurich-es-npm/ea-front-web-core';
import {
  cloneDeep
} from 'lodash';
import Vue from 'vue';
import {
  Component, Prop, Watch
} from 'vue-property-decorator';

@Component({
  name: 'search-option-menu-modal'
})

/**
 * Search menu option modal component
 */
export default class SearchOptionMenuModal extends Vue {
  
  @Prop({
    required: true,
  })
    menuStructure!: EAMenuStructure;
    
  @Prop({
    required: true,
  })
  public showModal: boolean = false;

  public hasBeenSearched: boolean = false;

  searchValue: string = '';

  lastSearchValue: string = '';

  foundResults: EAMenuStructureItem[] = [];
  
  excludedFlows: string[] = ['Dashboard'];

  inputId = 'searchOptionMenuInput';


  /**
   * Hides modal
   */
  closeModal() {
    this.resetComponent();
    this.$emit('closeModal');
  }

  /**
   * @description Cierra el user menu después del click en sus botones
   */
  searchIntoMenu() {
    this.hasBeenSearched = true;
    this.lastSearchValue = this.searchValue.trim();
    const searchString = this.searchValue.trim();
    const results: EAMenuStructureItem[] = [];
    this.menuStructure.forEach((menuItem: EAMenuStructureItem) => {
      results.push(... this.searchIntoMenuStructureItem(menuItem, searchString));
    });
    this.foundResults = results;
  }

  /**
   * @description Cierra el user menu después del click en sus botones
   * @param {EAMenuStructureItem} menuStructure
   * @param {string} searchString
   * @param {string} breadAndCrumb
   * @return {EAMenuStructureItem} opciones encontradas
   */
  searchIntoMenuStructureItem(menuStructure: EAMenuStructureItem, searchString: string, breadAndCrumb: string = '') {
    const results: EAMenuStructureItem[] = [];
    
    if (menuStructure.children?.length) {
      menuStructure.children.forEach((child: EAMenuStructureItem) => {
        results.push(
          ...this.searchIntoMenuStructureItem(child, searchString, `${breadAndCrumb}${menuStructure.name} > `)
        );
      });
    } else {
      searchString = TextUtils.deleteAccentsAndSpaces(searchString).toLocaleLowerCase();
      const menuName = TextUtils.deleteAccentsAndSpaces(menuStructure.name).toLocaleLowerCase();
      if (menuName.includes(searchString) && !this.excludedFlows.includes(menuName)) {
        const menuStructureClone = cloneDeep(menuStructure);
        menuStructureClone.name = `${breadAndCrumb}${menuStructure.name}`;
        results.push(menuStructureClone);
      }
    }
    return results;
  }
  
  /**
   * @description Cada vez que se actualiza la estructura del menu se limpian las opciones excluidas de primer nivel
   * @param {EAMenuStructure} menuStructure
   */
  @Watch('menuStructure', {
    deep: true,
    immediate: true
  })
  changeMenuStructure(menuStructure: EAMenuStructure) {
    menuStructure.forEach((menuItem: EAMenuStructureItem, index: number) => {
      if (this.excludedFlows.includes(menuItem.flowName as string)) {
        menuStructure?.splice(index, 1);
      }
    });
  }

  /**
   * Focus search input automatically when modal becomes visible
   * @param {boolean} value
   */
  @Watch('showModal')
  async onShowModal(value: boolean) {
    if (value) {
      await this.$nextTick();
      const searchInputEl = document.getElementById(this.inputId);
      if (searchInputEl) {
        searchInputEl.getElementsByTagName('input')[0]?.focus();
      }
    }
  }

  /**
   * @param {string} newInputValue to select
   * work around while v-mask is fixed
   */
  async searchValueHandler(newInputValue: string) {
    await this.$nextTick();
    if (newInputValue) {
      //Elimina los números
      this.searchValue = newInputValue.replace(/\d/g, '');
      //Elimina los caracteres especiales
      this.searchValue = this.searchValue.replace(/["'#%&/·ª¨:;~±¢,´`=¿!¡@.*+\-_?^${}()|[\]\\]/g, '');
    }
  }

  /**
   * @description Emite la operacion clicada para navegar
   * @param {EAMenuStructureItem} item encontradas
   */
  navigateToOperation(item: EAMenuStructureItem) {
    this.closeModal();
    EAFlowNavigationUtils.navigate(this, item.flowName as string, item.params);
  }

  /**
   * @description Emite la operacion clicada para navegar
   */
  resetComponent() {
    this.searchValue = '';
    this.hasBeenSearched = false;
    this.lastSearchValue = '';
  }

}
</script>
