import { render, staticRenderFns } from "./warranties-view.vue?vue&type=template&id=e51eddbc&scoped=true"
import script from "./warranties-view.vue?vue&type=script&lang=ts"
export * from "./warranties-view.vue?vue&type=script&lang=ts"
import style0 from "./warranties-view.vue?vue&type=style&index=0&id=e51eddbc&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e51eddbc",
  null
  
)

export default component.exports